<i *ngIf="searchIconVisible"
  class="k-icon k-i-search"></i>
<input kendoTextBox
  #inputField
  [tabindex]="tabIndex"
  [attr.name]="name"
  [attr.type]="type"
  [placeholder]="placeholder"
  [(ngModel)]="innerValue"
  (blur)="onBlur()"
  (focus)="onFocus()" />
<i *ngIf="clearIconVisible"
  class="k-icon k-i-close"
  (click)="onClear()">
</i>