<div class="lv-scenario">
  <div class="lv-scenario-header">
    <div class="lv-scenario-header-item dimensions">
      <div class="lv-field lv-field--radio">
        <input type="radio" class="k-radio"
          [attr.id]="view.firstDimensionRadioId"
          [attr.name]="'dimensionRadio' + view.model.id"
          lvId="rb-first-dimension"
          value="1-d"
          [checked]="view.model.dimension === '1-d'"
          (change)="onDimensionChange($event)" />
        <label lvId="rb-first-dimension-label" class="k-radio-label" [attr.for]="view.firstDimensionRadioId">1 D</label>
      </div>

      <div class="lv-field lv-field--radio">
        <input type="radio" class="k-radio"
          [attr.id]="view.secondDimensionRadioId"
          [attr.name]="'dimensionRadio' + view.model.id"
          lvId="rb-second-dimension"
          value="2-d"
          [checked]="view.model.dimension === '2-d'"
          (change)="onDimensionChange($event)" />
        <label class="k-radio-label" [attr.for]="view.secondDimensionRadioId">2 D</label>
      </div>
    </div>

    <div class="lv-scenario-header-item inputs"
      [class.inputs--two-dimensional]="view.isTwoDimensional">
      <lv-scenario-inputs #firstDimension
        lvId="lv-scenario-first-dimension-inputs"
        [isNewIssueScenario]="view.model.isNewIssue"
        [inputs]="view.model.firstDimension"
        [convertible]="convertible"
        [shiftLabel]="firstDimensionShiftLabel"
        [otherDimensionShift]="view.model.secondDimension?.shift">
      </lv-scenario-inputs>
      <lv-scenario-inputs #secondDimension
        *ngIf="view.secondDimensionVisible"
        class="second-dimension"
        lvId="lv-scenario-second-dimension-inputs"
        [isNewIssueScenario]="view.model.isNewIssue"
        [inputs]="view.model.secondDimension"
        [convertible]="convertible"
        [shiftLabel]="secondDimensionShiftLabel"
        [otherDimensionShift]="view.model.firstDimension.shift">
      </lv-scenario-inputs>
    </div>

    <form #priceTalkForm="ngForm"
      class="lv-scenario-header-item price-talk">
      <ng-container *ngIf="view.model.isNewIssue">
        <label class="lv-label lv-label-field">Price Talk</label>
        <div class="lv-input-field">
          <kendo-multiselect
            class="k-multiselect--inline"
            [class.k-multiselect--not-empty]="view.priceTalkNotEmpty"
            name="priceTalk"
            [data]="view.priceTalkLookup.items"
            valueField="id"
            textField="text"
            [valuePrimitive]="true"
            [(ngModel)]="view.model.priceTalk"
            required>
          </kendo-multiselect>
        </div>
      </ng-container>
    </form>

    <div class="lv-scenario-header-item options">
      <div class="lv-field"
        (click)="onClear()">
          <i class="lv-icon k-icon k-i-cancel"></i>
      </div>
      <div class="lv-field"
        (click)="onOpenSettings()">
          <i class="lv-icon k-icon k-i-cog"></i>
      </div>
    </div>
  </div>

  <div class="lv-scenario-content">
    <lv-loader *ngIf="isLoading"></lv-loader>
    <kendo-splitter *ngIf="view.report && !isLoading">
      <kendo-splitter-pane
        [collapsible]="false"
        [resizable]="true">
        <div class="lv-scenario-splitter-container">
          <lv-scenario-grid [report]="view.report"></lv-scenario-grid>
        </div>
      </kendo-splitter-pane>

      <kendo-splitter-pane
        [collapsible]="true"
        [collapsed]="!view.report.graphExpandedByDefault"
        (collapsedChange)="onSplitterCollapsedChange($event)"
        [resizable]="true">
        <div class="lv-scenario-splitter-container">
          <lv-scenario-chart [report]="view.report"></lv-scenario-chart>
        </div>
      </kendo-splitter-pane>
    </kendo-splitter>
  </div>

  <lv-side-panel class="lv-scenario-settings"
    position="right"
    [width]="520">
    <div class="inner-row">
      <div class="col-6">
        <lv-fieldset-panel legend="Model output selection">
          <div *ngFor="let item of view.modelOutputs.items; let i = index"
            class="inner-row padded">
            <div class="col-9">
              <label [for]="item.id">{{ item.text }}</label>
            </div>
            <div class="col-3 scenario-settings-checkbox">
              <input class="k-checkbox"
                type="checkbox"
                [attr.id]="item.id"
                [(ngModel)]="view.modelOutputsDict[item.id]"
                (ngModelChange)="view.onModelOutputChange()" />
              <label class="k-checkbox-label" [attr.for]="item.id"></label>
            </div>
          </div>
        </lv-fieldset-panel>
      </div>
      <div class="col-6">
        <div class="inner-row">
          <div class="col-12">
            <lv-fieldset-panel legend="Scenario Settings">
              <div class="inner-row padded">
                <div class="col-9">
                  <label [for]="view.isNewIssueChackboxId">New Issue Scenario</label>
                </div>
                <div class="col-3 scenario-settings-checkbox">
                  <input class="k-checkbox"
                    type="checkbox"
                    [attr.id]="view.isNewIssueChackboxId"
                    [(ngModel)]="view.model.isNewIssue"
                    (ngModelChange)="onScenarioIsNewIssueChange()" />
                  <label class="k-checkbox-label" [attr.for]="view.isNewIssueChackboxId"></label>
                </div>
              </div>
            </lv-fieldset-panel>
          </div>
        </div>

        <div class="inner-row padded">
          <div class="col-12">
            <lv-fieldset-panel legend="Display Settings">
              <div class="inner-row padded">
                <div class="col-9">
                  <label [for]="view.showInputsAsPercentageCheckboxId">Show Input as % Change</label>
                </div>
                <div class="col-3 scenario-settings-checkbox">
                  <input class="k-checkbox"
                    type="checkbox"
                    [attr.id]="view.showInputsAsPercentageCheckboxId"
                    [(ngModel)]="view.model.showInputsAsPercentage" />
                  <label class="k-checkbox-label" [attr.for]="view.showInputsAsPercentageCheckboxId"></label>
                </div>
              </div>

              <div class="inner-row padded">
                <div class="col-9">
                  <label [for]="view.graphExpandedByDefaultCheckboxId">Expand Graph by Default</label>
                </div>
                <div class="col-3 scenario-settings-checkbox">
                  <input class="k-checkbox"
                    type="checkbox"
                    [attr.id]="view.graphExpandedByDefaultCheckboxId"
                    [(ngModel)]="view.model.graphExpandedByDefault" />
                  <label class="k-checkbox-label" [attr.for]="view.graphExpandedByDefaultCheckboxId"></label>
                </div>
              </div>
            </lv-fieldset-panel>
          </div>
        </div>
      </div>
    </div>
  </lv-side-panel>
</div>
