import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';
import { AccessScopeType } from '@lv-core-ui/models/enum/access-scope-type';

@Directive({
  selector: '[lvAccessScope]'
})
export class LvAccessScopeDirective implements OnChanges {

  @Input() accessScope: AccessScopeType;

  constructor(private _elRef: ElementRef, private _renderer: Renderer2) { }

  ngOnChanges() {
    this._renderer.removeAttribute(this._elRef.nativeElement, 'class');
    this._renderer.removeAttribute(this._elRef.nativeElement, 'title');

    if (this.accessScope === AccessScopeType.Company) {
      this._renderer.addClass(this._elRef.nativeElement, 'icon-internaly_shared');
      this._renderer.setAttribute(this._elRef.nativeElement, 'title', 'Shared Internally (available to your team)');
    }
    else if (this.accessScope === AccessScopeType.User) {
      this._renderer.addClass(this._elRef.nativeElement, 'icon-private');
      this._renderer.setAttribute(this._elRef.nativeElement, 'title', 'Private (available only to you)');
    }
  }
}
