import { Component, Input, HostListener, OnDestroy, ViewEncapsulation, OnChanges,
  ChangeDetectionStrategy, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';

import { Subscription } from 'rxjs';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ILvGridContextMenuItem } from './lv-grid-context-menu-item';

export const itemVisible = () => true;

@Component({
  selector: 'lv-grid-context-menu',
  templateUrl: './lv-grid-context-menu.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvGridContextMenuComponent  implements OnInit, OnChanges, OnDestroy {

  @Input()
  items: ILvGridContextMenuItem[];

  @Input()
  set for(grid: GridComponent) {
    this.unsubscribe();
    this.cellClickSubscription = grid.cellClick.subscribe(this.onCellClick.bind(this));
  }

  @Input() autoHide: boolean;

  @Output() didContextMenuOpen: EventEmitter<any>;

  itemsVisible: boolean;
  offset: any;

  selectedRecord: any;

  private cellClickSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.itemsVisible = false;
    this.items = [];
    this.autoHide = true;

    this.didContextMenuOpen = new EventEmitter<any>();
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    if (this.itemsVisible && this.autoHide) {
      this.itemsVisible = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnInit() {
    this.setMenuListItems();
  }

  ngOnChanges() {
    this.setMenuListItems();
  }

  setMenuListItems() {
    this.items = this.items.map((a, index) => {
      return {
        ...a,
        id: index,
        isVisible: a.isVisible || itemVisible
      };
    });

    this.changeDetectorRef.detectChanges();
  }

  hideItems(): void {
    this.setMenuListItems();
    this.itemsVisible = false;
  }

  showItems(): void {
    this.setMenuListItems();
    this.itemsVisible = true;
  }

  onItemSelect(item: ILvGridContextMenuItem): void {
    if (item.action) {
      item.action(this.selectedRecord);
    }
  }

  onMouseEnter(item: ILvGridContextMenuItem): void {
    if (item.mouseEnterAction) {
      item.mouseEnterAction();
    }
  }

  itemsTrackBy(index: number, item: ILvGridContextMenuItem) {
    return item.id;
  }

  private onCellClick({ dataItem, type, originalEvent }): void {
    if (type === 'contextmenu') {
      originalEvent.preventDefault();

      this.selectedRecord = dataItem;
      this.itemsVisible = true;
      this.offset = {
        left: originalEvent.pageX,
        top: originalEvent.pageY
      };

      this.didContextMenuOpen.emit(this.selectedRecord);

      this.changeDetectorRef.detectChanges();
    }
  }

  private unsubscribe(): void {
    if (this.cellClickSubscription) {
      this.cellClickSubscription.unsubscribe();
      this.cellClickSubscription = null;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
