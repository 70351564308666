import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
  ChangeDetectorRef, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { IMarketDataComponent } from '../../market-data-component';
import { LvCreditParametersComponent } from './lv-credit-parameters/lv-credit-parameters.component';
import { LvMarketDataPresenter } from '../lv-market-data.presenter';

import * as _ from 'lodash';
import { LvErrorService } from '@lv-core-ui/services';
import { LvLookupEnum } from '@lv-core-ui/util';
import { CreditModelFactor, CreditSource, CreditSourceDescription, ICredit, ICreditRequest,
         ICreditSessionRequest } from '@lv-analytics/models/market-data/credit';
import { IEnvironmentSettingsItem,
         LvEnvironmentSettingsComponent } from '@lv-analytics/components/lv-environment-settings/lv-environment-settings.component';
import { CreditModelType } from '@lv-analytics/models/model-customization/model-customization-enum';
import { PricingEnvironmentSections } from '@lv-analytics/models/enum/pricing-environment-sections';
import { MarketDataService } from '@lv-analytics/services';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { AnalyticsSettingsEvents } from '@lv-analytics/models/enum/analytics-settings-events';

/**
 * Credit component.
 */
@Component({
  selector: 'lv-credit',
  templateUrl: './lv-credit.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCreditComponent implements OnInit, OnDestroy, IMarketDataComponent<ICreditRequest> {

  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;
  @ViewChild('issuerCreditParameters') issuerCreditParameters: LvCreditParametersComponent;
  @ViewChild('underlyingCreditParameters') underlyingCreditParameters: LvCreditParametersComponent;

  get creditSettings(): ICredit {
    return this._credit;
  }

  get isCreditSectionDisabled(): boolean {
    return !this._analyticsPresenter.asHelper.instrumentLoaded;
  }

  get IsFlatSpread(): boolean {
    return this.creditSettings.creditSource === CreditSource.FlatSpread;
  }

  get IsFlatSpreadUnderlying(): boolean {
    return this.creditSettings.underlyingCreditSource === CreditSource.FlatSpread;
  }

  get CreditFactorModel() {
    return this.creditSettings.issuerCreditParameters.creditModelFactor;
  }

  get IsStochasticVisible(): boolean {
    return this.creditSettings.stochasticCreditModel.useStochasticCredit =
      this.creditSettings.issuerCreditParameters.creditModelFactor === CreditModelFactor.Factor2;
  }

  get isExchangeable() {
    return this._analyticsPresenter.cHelper.isExchangeable;
  }

  get isEquityLinkedVisible(): boolean {
    return this.creditSettings.equityLinkedCredit.equityToCreditModel =
      this.creditSettings.issuerCreditParameters.creditModelFactor === CreditModelFactor.Factor15;
  }

  get isAssetParametersVisible(): boolean {
    return this.creditModelType === CreditModelType.StructuralModel
      || this.creditModelType === CreditModelType.Hybrid;
  }

  @Output() didSessionUpdatedEvent: EventEmitter<void>;

  get sectionMinWidth(): number {
    switch (this.creditSettings.creditSource) {
      case CreditSource.CDSTermStructure:
        return 345;
      case CreditSource.DefaultRateTermStructure:
        return 375;
      case CreditSource.SurvivalProbabilityTermStructure:
        return 410;
      default:
        return 315;
    }
  }

  _credit: ICredit;
  originalValue: ICredit;
  creditSource: LvLookupEnum;

  creditModelType: string;

  creditSection = PricingEnvironmentSections.Credit;

  private _subscriptions: Subscription[];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _service: MarketDataService,
    private _presenter: LvMarketDataPresenter,
    private _analyticsPresenter: LvAnalyticsPresenter
  ) {
    this.creditSource = new LvLookupEnum(CreditSourceDescription);
    this.creditModelType = CreditModelType.None;

    this._credit = {
      assetParameters: {},
      issuerCreditParameters: {
        creditTermStructure: [],
        creditCDSTermStructure: [],
        creditDefaultRateTermStructure: [],
        creditSurvivalProbabilityTermStructure: []
      },
      underlyingCreditParameters: {
        creditTermStructure: [],
        creditCDSTermStructure: [],
        creditDefaultRateTermStructure: [],
        creditSurvivalProbabilityTermStructure: []
      },
      equityLinkedCredit: {},
      stochasticCreditModel: {},
      underlyingEquityLinkedCredit: {},
      underlyingStochasticCreditModel: {}
    } as ICredit;

    this.didSessionUpdatedEvent = new EventEmitter<void>();
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this._analyticsPresenter.onModelLoading.subscribe(isLoading => this.setLoadingState(isLoading)),

      this._analyticsPresenter.onModelUpdated.subscribe(evt => {
        if (evt) {
          if (evt.eventId === AnalyticsSettingsEvents.MarketDataUpdated) {
            this._credit = evt.data.marketData.credit;
            this.originalValue = _.cloneDeep(this._credit);
          }

          if (evt.eventId === AnalyticsSettingsEvents.ModelCustomizationUpdated
            || evt.eventId === AnalyticsSettingsEvents.MarketDataUpdated) {
              if (evt.data.valuationSession && evt.data.valuationSession.modelCustomization) {
                this.creditModelType = evt.data.valuationSession.modelCustomization.creditModelSettings.creditModelType;
              }
          }

          this._changeDetectorRef.detectChanges();
        }
      })
    ];
  }

  /**
   * Occurs on credit source change.
   */
  onCreditSourceChange() {
    this.overrideCredit();
  }

  /**
   * Overrides credit.
   */
  async overrideCredit() {
    try {
      this._changeDetectorRef.detectChanges();
      const request = {
        lwsIdentifier: this._analyticsPresenter.asHelper.lwsIdentifier,
        sessionId: this._analyticsPresenter.asHelper.sessionId,
        credit: this.creditSettings
      } as ICreditSessionRequest;

      await this._service.overrideCredit(request);

      this._analyticsPresenter.updateModel({
        eventId: AnalyticsSettingsEvents.CreditUpdated,
        data: this._credit
      });

      if (!_.isEqual(request.credit, this.originalValue)) {
        this.didSessionUpdatedEvent.next();
        this.originalValue = _.cloneDeep(request.credit);
      }
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on change environment and loads credit.
   * @param environment IEnvironmentSettingsItem object.
   */
  onChangedEnvironment(environment: IEnvironmentSettingsItem) {
    this.loadCredit(environment);
  }

  /**
   * Gets selected environment ID.
   * @returns Environment ID.
   */
  getSelectedEnvironmentId(): string {
    const env = this.envSettings.getSelectedEnvironment();
    return env.id;
  }

  /**
   * Gets settings.
   * @returns ICreditRequest object.
   */
  getSettings(): ICreditRequest {
    return this.getCreditSaveRequest();
  }

  /**
   * Applies current changes.
   */
  applyCurrentChanges() {
    if (!!this.issuerCreditParameters
      && (this.creditSettings.creditSource === CreditSource.CDSTermStructure
      || this.creditSettings.creditSource === CreditSource.TermStructure
      || this.creditSettings.creditSource === CreditSource.DefaultRateTermStructure
      || this.creditSettings.creditSource === CreditSource.SurvivalProbabilityTermStructure)) {
        this.issuerCreditParameters.creditTermStructureComponent.applyAdvancedGridChanges();
      }

    if (!!this.underlyingCreditParameters
      && (this.creditSettings.underlyingCreditSource === CreditSource.CDSTermStructure
      || this.creditSettings.underlyingCreditSource === CreditSource.TermStructure
      || this.creditSettings.underlyingCreditSource === CreditSource.DefaultRateTermStructure
      || this.creditSettings.underlyingCreditSource === CreditSource.SurvivalProbabilityTermStructure)) {
        this.underlyingCreditParameters.creditTermStructureComponent.applyAdvancedGridChanges();
      }
  }

  /**
   * Gets section.
   * @returns PricingEnvironmentSections object.
   */
  getSection(): PricingEnvironmentSections {
    return this.creditSection;
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy(): void {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Gets credit save request.
   * @returns ICreditRequest object.
   */
  private getCreditSaveRequest(): ICreditRequest {
    const env = this.envSettings.getSelectedEnvironment();

    return {
      lwsIdentifier: this._analyticsPresenter.asHelper.lwsIdentifier,
      environmentId: env.id,
      credit: this.creditSettings
    } as ICreditRequest;
  }

  /**
   * Loads credit.
   * @param environment IEnvironmentSettingsItem object.
   */
  private async loadCredit(environment: IEnvironmentSettingsItem) {
    try {
      this.setLoadingState(true);

      this._credit = await this._service.loadCredit(
        this._analyticsPresenter.asHelper.sessionId,
        this._analyticsPresenter.asHelper.lwsIdentifier,
        environment.id
      );

      this._analyticsPresenter.updateModel({
        eventId: AnalyticsSettingsEvents.CreditUpdated,
        data: this._credit
      });
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  private setLoadingState(isLoading: boolean) {
    this.envSettings.setLoadingState(isLoading);
    this._presenter.setLoadingState(isLoading);
    this._changeDetectorRef.detectChanges();
  }
}
