import { v4 } from 'uuid';

import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, ChangeDetectorRef, Optional, Type,
  HostBinding, Output, EventEmitter, HostListener, ViewChild, ElementRef, forwardRef, Injector, OnInit, ViewRef} from '@angular/core';
import { NgForm, FormGroupDirective, NgControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS,
  Validator, AbstractControl, ValidationErrors } from '@angular/forms';

export type LvTextBoxInputType = 'text' | 'password';

@Component({
  selector: 'lv-text-box',
  templateUrl: './lv-text-box.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LvTextBoxComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => LvTextBoxComponent),
    multi: true
  }]
})
export class LvTextBoxComponent implements ControlValueAccessor, Validator, OnInit {

  @ViewChild('inputField', { static: true }) inputField: ElementRef<HTMLElement>;

  @Input() name: string;
  @Input() type: LvTextBoxInputType;
  @Input() placeholder: string;

  @Input() disabled: boolean;
  @Input() readonly: boolean;

  private _tabIndex: number;
  @Input()
  set tabIndex(value: number) {
    this._tabIndex = value;
  }

  get tabIndex(): number {
    return this.disabled || this.readonly ? -1 : this._tabIndex;
  }

  @Input() searchIconVisible: boolean;

  @Output() didBlur: EventEmitter<void>;
  @Output() didFocus: EventEmitter<void>;
  @Output() didKeyDownEnter: EventEmitter<void>;
  @Output() didClear: EventEmitter<void>;

  private _innerValue: string;
  public get innerValue(): string {
    return this._innerValue;
  }

  public set innerValue(value: string) {
    this._innerValue = value;

    this.touchControl();

    this.onValueChange(this._innerValue);
  }

  public get invalid(): boolean {
    const isInvalid = this._control && this._control.invalid;
    const isTouched = this._control && this._control.touched;
    const isSubmitted = (this.parentFormGroup && this.parentFormGroup.submitted) || (this.parentForm && this.parentForm.submitted);

    if (!this.doValidateControl()) {
      return true;
    }

    return !!(isInvalid && (isTouched || isSubmitted));
  }

  public get touched(): boolean {
    return this._control && this._control.touched;
  }

  get clearIconVisible(): boolean {
    return this.innerValue && !this.readonly && !this.disabled;
  }

  public onValueChange: (value: any) => void;
  public onControlTouched: () => void;

  private _suppressBlur: boolean;

  private _control: NgControl;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private _injector: Injector,
    @Optional() private parentForm: NgForm,
    @Optional() private parentFormGroup: FormGroupDirective,
  ) {
    this._suppressBlur = false;

    this.name = `lv-text-box-${v4()}`;
    this.type = 'text';
    this.placeholder = '';

    this.disabled = false;
    this.readonly = false;
    this.tabIndex = 0;

    this.searchIconVisible = false;

    this.onValueChange = (value: any) => {};
    this.onControlTouched = () => {};

    this.didBlur = new EventEmitter();
    this.didFocus = new EventEmitter();
    this.didClear = new EventEmitter();
    this.didKeyDownEnter = new EventEmitter();
  }

  @HostBinding('class.lv-text-box')
  get baseClass(): boolean {
    return true;
  }

  @HostBinding('class.lv-invalid')
  get isLvInvalid(): boolean {
    return !this.disabled && !this.readonly && this.invalid;
  }

  @HostBinding('class.lv-text-box-searchable')
  get isSearchIconVisible(): boolean {
    return this.searchIconVisible;
  }

  @HostListener('keydown.enter')
  onKeyDownEnter() {
    if (!this.disabled && !this.readonly) {
      this._suppressBlur = true;

      this.onControlTouched();
      this.didBlur.next();

      this.didKeyDownEnter.next();
    }
  }

  ngOnInit() {
    this._control = this._injector.get<NgControl>(NgControl, null);

    if (this._control) {
      this._control.valueAccessor = this;
    }
  }

  // ControlValueAccessor START
  writeValue(value: string): void {
    // trim white space
    if (value) {
      value = value.toString().trim();
    }

    this._innerValue = value;
    if (!(this.changeDetectorRef as ViewRef).destroyed) {
    this.changeDetectorRef.detectChanges();
    }
  }

  registerOnChange(fn: any): void {
    this.onValueChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onControlTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.detectChanges();
  }
  // ControlValueAccessor END

  onBlur() {
    if (!this.disabled && !this.readonly && !this._suppressBlur) {
      this.onControlTouched();

      this.didBlur.next();
    }
    else {
      this._suppressBlur = false;
    }

    // trim whitespace
    if (this.innerValue) {
      const value = this.innerValue.trim();
      this.innerValue = value;
    }
  }

  onFocus() {
    if (!this.disabled && !this.readonly) {
      this.didFocus.next();
    }
  }

  onClear() {
    if (!this.disabled && !this.readonly) {
      this.innerValue = null;
      this.didClear.next();
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return null;
  }

  private doValidateControl(): boolean {
    return true;
  }

  private touchControl() {
    if (!this.touched && !this.disabled && !this.readonly) {
      this.onControlTouched();
    }
  }
}
