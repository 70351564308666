<lv-advanced-grid *ngIf="tabularScheduleContent"
  data-cy="DM-4564"
  lvId="tabularOutputsSchedule"
  [editDisabled]="editDisabled"
  [columns]="columns"
  [records]="tabularScheduleContent"
  [ngClass]="'lv-advanced-grid-stripped'"
  [hasThreeDecimalPlaces]="true"
  [useNegativeLabel]="true"
  class="lv-tabular-schedule-grid">
</lv-advanced-grid>
