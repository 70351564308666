

import { Frequency } from './Frequency';
import { CurrencyType } from './CurrencyType';
import { DividendProtectionScheduleItem } from './DividendProtectionScheduleItem';
import { ConversionRatioAdjustment } from './ConversionRatioAdjustment';
import { CashDistribution } from './CashDistribution';
import { DividendProtectionType, DividendProtectionTresholdType,
    DividendProtectionTresholdFormulaType, MinAdjustmentType } from './Enums (4)';

export class DividendProtectionData {
    
    startDate: Date;
    endDate: Date;
    type: DividendProtectionType;
    tresholdType: DividendProtectionTresholdType;
    tresholdPeriodFrequency: Frequency;
    cashTresholdCurrencyType: CurrencyType;
    tresholdFormulaType: DividendProtectionTresholdFormulaType;
    includeTrigger: boolean;
    isCurrentPeriodTriggerBreached: boolean;
    currentPeriodDividendsSum: number;
    carriedForwardValue: number;
    previousYearDividendSum: number;
    currentThresholdAdjustment: number;
    schedule: DividendProtectionScheduleItem[];
    conversionRatioAdjustment: ConversionRatioAdjustment;
    cashDistribution: CashDistribution;
    minAdjustmentType: MinAdjustmentType;
    addCarryForwardOnConversion: boolean;
}

