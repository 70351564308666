import { Pipe, PipeTransform } from '@angular/core';
import { PercentPipe } from '@angular/common';

@Pipe({
  name: 'lvPercent'
})
export class LvPercentPipe implements PipeTransform {

  constructor(
    private percentPipe: PercentPipe
  ) {}

  transform(value: number, referentValue: number, digitsInfo?: string, locale?: string): any {
    let percentValue = null;

    try {
      percentValue = (value / referentValue) - 1;
    }
    catch {
      percentValue = null;
    }

    return this.percentPipe.transform(percentValue, digitsInfo, locale);
  }

}
