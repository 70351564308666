import { SimpleChanges } from '@angular/core';

export class LvAngularUtil {
  public static inputChanged(changes: SimpleChanges, fieldName: string): boolean {
    const field = changes[fieldName];

    if (!field) {
      return false;
    }

    return field.previousValue !== field.currentValue;
  }
}
