<div class="lv-dialog lv-copy-and-paste-dialog">
  <div class="lv-copy-and-paste-dialog-content">

    <div class="lv-copy-and-paste-dialog-grid-wrapper">
      <div #gridWrapper class="lv-copy-and-paste-dialog-advanced-grid-wrapper">
        
        <lv-advanced-copy-paste-grid
          [records]="parsedData"
          [columns]="columns"
          [createFormGroup]="createFormGroup"
          [pasteItems]="dialogRadioLabels[componentName]"
          [selectionStart]="selectionStart"
          [selectionEnd]="selectionEnd"
          [firstColumnSelectedIndex]="firstColumnSelectedIndex"
          [secondColumnSelectedIndex]="secondColumnSelectedIndex">
        </lv-advanced-copy-paste-grid>
      </div>
    </div>

  </div>
  <div class="lv-dialog-actions">

    <button kendoButton
      lvId="copyAndPasteDialogCancel"
      (click)="onClose()">
        Cancel
    </button>
  
    <button kendoButton
      [primary]="true"
      lvId="copyAndPasteDialogPaste"
      (click)="onPaste()">
        Paste
    </button>
  
  </div>
</div>
