import { v4 } from 'uuid';

import { LvLookupEnum } from '@lv-core-ui/util';
import { getDefaultInputs } from './lv-scenario-inputs/lv-scenario-inputs.view';
import { ICalculateScenarioOutput, ICalculateScenarioOutputVector, ILvScenario, ScenarioOutput,
         IConvertible, ScenarioPriceTalk, ScenarioOutputDescription, ILvScenarioInputs } from '@lv-analytics/models';

export type CalculationOutput = ICalculateScenarioOutput | ICalculateScenarioOutputVector;

export interface ILvScenarioReport {
  isNewIssueScenario: boolean;
  isTwoDimensionalScenario: boolean;
  graphExpandedByDefault: boolean;
  underlyingCurrencyCode: string;
  underlyingCBCurrencyCode: string;
  showInputsAsPercentage: {
    firstDimension: boolean;
    firstDimensionChangeRef: number;
    secondDimension: boolean;
    secondDimensionChangeRef?: number;
  } | null;
  data: CalculationOutput[];
}

export const getDefaultScenarioReport = (report?: ILvScenarioReport): ILvScenarioReport => {
  return report || {
    isNewIssueScenario: false,
    isTwoDimensionalScenario: false,
    graphExpandedByDefault: false,
    underlyingCurrencyCode: null,
    underlyingCBCurrencyCode: null,
    showInputsAsPercentage: null,
    data: []
  };
};

export const getDefaultScenario = (scenario?: ILvScenario): ILvScenario => {
  const scInputs = getDefaultInputs();

  return scenario || {
    id: v4(),
    isSelected: true,
    name: 'New Scenario',
    dimension: '1-d',
    firstDimension: scInputs,
    secondDimension: null,
    priceTalk: [],
    isNewIssue: false,
    showInputsAsPercentage: false,
    graphExpandedByDefault: true,
    outputs: [
      ScenarioOutput.TheoreticalResult,
      ScenarioOutput.Delta,
      ScenarioOutput.BondValue
    ]
  } as ILvScenario;
};

/**
 * Scenario view.
 */
export class LvScenarioView {

  get secondDimensionVisible(): boolean {
    return this.model.dimension === '2-d';
  }

  get priceTalkNotEmpty(): boolean {
    return this.model && this.model.priceTalk.length > 0;
  }

  get isTwoDimensional(): boolean {
    return this.model.dimension === '2-d';
  }

  get underlyingCurrencyCode(): string {
    if (!this._convertible) {
      return null;
    }

    return this._convertible.underlying.currency;
  }

  get underlyingCBCurrencyCode(): string {
    if (!this._convertible) {
      return null;
    }

    return this._convertible.currencyCode;
  }

  model: ILvScenario;

  firstDimensionRadioId: string;
  secondDimensionRadioId: string;

  priceTalkLookup: LvLookupEnum;

  isNewIssueChackboxId: string;
  showInputsAsPercentageCheckboxId: string;
  graphExpandedByDefaultCheckboxId: string;

  modelOutputs: LvLookupEnum;
  modelOutputsDict: {
    [enumType: string]: boolean;
  };

  report: ILvScenarioReport;

  private _convertible?: IConvertible;

  constructor() {
    this.firstDimensionRadioId = v4();
    this.secondDimensionRadioId = v4();

    this.priceTalkLookup = new LvLookupEnum(ScenarioPriceTalk);

    this.isNewIssueChackboxId = v4();
    this.showInputsAsPercentageCheckboxId = v4();
    this.graphExpandedByDefaultCheckboxId = v4();

    this.modelOutputs = new LvLookupEnum(ScenarioOutputDescription);

    this.report = null;

    this.initModelOutputsDict();
  }

  /**
   * Does initialization.
   * @param scenario ILvScenario object.
   * @param convertible IConvertible object.
   */
  init(scenario?: ILvScenario, convertible?: IConvertible) {
    this._convertible = convertible;

    this.model = getDefaultScenario(scenario);
    this.initModelOutputsDict();

    this.clearReport();

    this.model.outputs.forEach(a => {
      this.modelOutputsDict[a] = true;
    });
  }

  /**
   * Gets shift label.
   * @param suffix Suffix.
   * @returns Shift label.
   */
  getShiftLabel(suffix: string): string {
    const prefix = 'Shift';
    return this.model.dimension === '1-d' ? prefix : `${prefix} (${suffix})`;
  }

  /**
   * Occurs on dimension change.
   */
  onDimensionChange() {
    if (this.model.dimension === '1-d') {
      this.model.secondDimension = null;
    }
    else {
      this.model.secondDimension = getDefaultInputs();
    }
  }

  /**
   * Occurs on first dimension inputs change.
   * @param inputs ILvScenarioInputs object.
   */
  onFirstDimensionInputsChange(inputs: ILvScenarioInputs) {
    Object.assign(this.model.firstDimension, inputs);
  }

  /**
   * Occurs on second dimension inputs change.
   * @param inputs ILvScenarioInputs object.
   */
  onSecondDimensionInputsChange(inputs: ILvScenarioInputs) {
    Object.assign(this.model.secondDimension, inputs);
  }

  /**
   * Occurs on scenario is new issue change.
   */
  onScenarioIsNewIssueChange(): any {
    if (!this.model.isNewIssue) {
      this.model.priceTalk = [];
    }
  }

  /**
   * Occurs model output change.
   */
  onModelOutputChange() {
    this.model.outputs = Object.keys(this.modelOutputsDict)
      .filter(key => this.modelOutputsDict[key]) as ScenarioOutput[];
  }

  /**
   * Resets price talk.
   */
  resetPriceTalk() {
    this.model.priceTalk = [];
  }

  /**
   * Clears report.
   */
  clearReport() {
    this.report = null;
  }

  /**
   * Sets report data.
   * @param data
   */
  setReportData(data: any[]) {

    this.report = {
      isNewIssueScenario: this.model.isNewIssue,
      isTwoDimensionalScenario: this.isTwoDimensional,
      graphExpandedByDefault: this.model.graphExpandedByDefault,
      underlyingCurrencyCode: this.underlyingCurrencyCode,
      underlyingCBCurrencyCode: this.underlyingCBCurrencyCode,
      showInputsAsPercentage: this.model.showInputsAsPercentage ? {
        firstDimension: this.model.firstDimension.change === 'pct',
        firstDimensionChangeRef: this.model.firstDimension.changeRef,
        secondDimension: this.model.dimension === '2-d' && this.model.secondDimension.change === 'pct',
        secondDimensionChangeRef: this.model.dimension === '2-d' ? this.model.secondDimension.changeRef : null
      } : null,
      data: data.map(a => a)
    };
  }

  /**
   * Initializes model outputs dict.
   */
  private initModelOutputsDict() {
    this.modelOutputsDict = {};

    this.modelOutputs.items.forEach(a => {
      this.modelOutputsDict[a.id] = false;
    });
  }
}
