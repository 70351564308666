import { LvScenarioGridView, ILvScenarioGridView, ILvScenarioGridViewGroup, ILvScenarioGridViewRecord } from '../lv-scenario-grid.view';
import { ILvScenarioReport } from '../../lv-scenario.view';
import { ICalculateScenarioOutput } from '@lv-analytics/models';

export interface IOneDimPriceTalkGrid extends ILvScenarioGridView {
  dimensionDescription: string;
  groups: ILvScenarioGridViewGroup[];
}

/**
 * Scenario one dim price talk grid view.
 */
export class LvScenarioOneDimPriceTalkGridView extends LvScenarioGridView<IOneDimPriceTalkGrid> {

  /**
   * Gets default model.
   * @returns IOneDimPriceTalkGrid object.
   */
  getDefaultModel(): IOneDimPriceTalkGrid {
    return {
      dimensionDescription: null,
      groups: [],
      records: []
    };
  }

  /**
   * Does initialization.
   * @param report ILvScenarioReport object.
   */
  doInit(report: ILvScenarioReport) {
    const mapped = (report.data as ICalculateScenarioOutput[]);

    mapped.forEach((fDim, i) => {
      if (this.isFirstIndex(i)) {
        this.model.dimensionDescription = this.getFirstDimensionDescription(fDim.dimension);
      }

      const rec: ILvScenarioGridViewRecord = {
        dimension: fDim.dimension,
        dimensionValue: fDim.value
      };

      fDim.outputs.forEach((output, j) => {
        if (this.isFirstIndex(i)) {
          this.model.groups.push({
            groupId: output.output,
            title: this.scenarioOutputDescription[output.output],
            width: this.defaultColumnWidth * output.values.length,
            columns: []
          });
        }

        output.values.forEach(value => {
          const valueId = `${output.output}_${value.priceTalk}`;

          if (this.isFirstIndex(i)) {
            this.model.groups[j].columns.push({
              columnId: valueId,
              title: this.getPriceTalkDescription(value.priceTalk),
              width: this.defaultColumnWidth
            });
          }

          rec[valueId] = value.value;
        });
      });

      this.model.records.push(rec);
    });
  }
}
