import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, TemplateRef, ContentChild, ViewChild,
  ElementRef, Input, OnDestroy, ViewRef} from '@angular/core';

import { ResizeHandlerService } from '@lv-core-ui/services';
import { LvGridLayoutCellDirective } from './lv-grid-layout-cell/lv-grid-layout-cell.directive';
import { LvGridLayout } from './lv-grid';

@Component({
  selector: 'lv-grid-layout',
  templateUrl: './lv-grid-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvGridLayoutComponent implements OnInit, OnDestroy {

  @ViewChild('gridElement', { static: true }) gridElement: ElementRef;
  @ContentChild(LvGridLayoutCellDirective, { read: TemplateRef }) cellTemplate;

  @Input() cells: any[];
  @Input() columnWidth: number;

  get gridLayout(): LvGridLayout {
    return this._gridLayout;
  }

  private _gridLayout: LvGridLayout;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private resizeHandlerService: ResizeHandlerService
  ) {
    this.columnWidth = 200;
    this._gridLayout = null;
  }

  ngOnInit() {
    this.resizeHandlerService.listenTo(this.gridElement, elementRect => {
      this.gridLayout.calculateColumns(elementRect.width);
      this.changeDetectorRef.detectChanges();
    });

    this._gridLayout = new LvGridLayout(this.columnWidth);
    this.gridLayout.initialize(this.cells);
    this.changeDetectorRef.detectChanges();
  }

  renderLayout(cells: any[]) {
    if (this.gridLayout) {
      this.gridLayout.initialize(cells);
      if (!(this.changeDetectorRef as ViewRef).destroyed) {
        this.changeDetectorRef.detectChanges();
        }
    }
  }

  updateLayout<T>(cells: T[], update: (previous: T, current: T) => void, fieldName: string = null) {
    if (this.gridLayout) {
      this.gridLayout.update(cells, update, fieldName);
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy() {
    if (this.gridElement) {
      this.resizeHandlerService.removeAllListeners(this.gridElement);
    }
  }
}
