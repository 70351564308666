import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

import { MetadataService } from './services/metadata/metadata.service';
import { DocumentsService } from './documents.service';
import { LvDocumentsComponent } from './components/lv-documents/lv-documents.component';
import { LvDocumentComponent } from './components/lv-document/lv-document.component';
import { LvDocumentsSlidingPanelComponent } from './components/lv-documents-sliding-panel/lv-documents-sliding-panel.component';
import { LeversysCoreUIModule } from '@lv-core-ui/leversys-core-ui.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';

/**
 * Document module definition that contains registration of providers, imports, exports declarations, entry components.
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PdfViewerModule,

    // Kendo Module
    InputsModule,
    ButtonsModule,

    LeversysCoreUIModule
  ],
  declarations: [
    LvDocumentComponent,
    LvDocumentsComponent,
    LvDocumentsSlidingPanelComponent
  ],
  exports: [
    LvDocumentComponent,
    LvDocumentsComponent,
    LvDocumentsSlidingPanelComponent
  ],
  providers: [
    MetadataService,
    DocumentsService
  ]
})
export class DocumentsModule { }
