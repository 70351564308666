<lv-flex-box kendoTooltip
  class="lv-environment-settings"
  direction="row">
  <kendo-buttongroup
    *ngIf="buttonsVisible"
    selection="single"
    [lvId]="lvId">
    <button *ngFor="let item of items"
      kendoButton
      [toggleable]="true"
      [selected]="item.isSelected"
      [class.lv-environment-settings-button--my]="item.name === 'My'"
      [class.lv-environment-settings-button--shared]="item.name === 'Shared'"
      (mousedown)="onSelectEnvironment(item)">
        {{ item.name }}
    </button>
  </kendo-buttongroup>

  <lv-flex-box flex="1" direction="row">
    <div *ngIf="showReorderEnvironment"
      class="lv-icon-square"
      title="Edit envrionment order"
      lvId="lv-environment-settings-edit"
      [class.lv-icon-square--disabled]="isDisabled"
      (mousedown)="onEditEnvironmentOrder()">
      <i class="k-icon k-i-edit"></i>
    </div>
    <ng-content></ng-content>
  </lv-flex-box>

  <div *ngIf="showControls"
    class="lv-environment-settings-controls">
    <div *ngIf="!saveToExcel"
      class="lv-icon-square"
      title="Save section"
      lvId="lv-environment-settings-save"
      [class.lv-icon-square--disabled]="isDisabled"
      (mousedown)="onSaveSection()">
      <i class="k-icon k-i-save"></i>
    </div>
    <div *ngIf="saveToExcel"
      class="lv-icon-square"
      title="Save section"
      lvId="lv-environment-settings-save"
      [class.lv-icon-square--disabled]="isDisabled"
      (mousedown)="onSaveSectionToExcel()">
      <i class="k-icon k-i-save"></i>
    </div>
    <div class="lv-icon-square"
      title="Reload section"
      lvId="lv-environment-settings-reload"
      [class.lv-icon-square--disabled]="isDisabled"
      (mousedown)="onReloadSection()">
      <i class="k-icon k-i-reload"></i>
    </div>
  </div>
</lv-flex-box>
