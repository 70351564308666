/**
 * Types of dividend frequency.
 */
export enum DividendFrequencyEnum {
  Monthly = 'Monthly',
  Bimonthly = 'Bimonthly',
  Quarterly = 'Quarterly',
  Triannual = 'Triannual',
  SemiAnnual = 'Semi Annual',
  Annual = 'Annual',
}
