<lv-flex-box class="lv-ratchet-schedule">
  <lv-flex-box direction="row" data-cy="DM-1437">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="compensationValueTypeSourceTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'compensationValueTypeSourceTooltip'">
        Compensation Value Type
      </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="ratchetScheduleValueType"
        [data]="ratchetScheduleValueTypeLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 125 }"
        [(ngModel)]="model.ratchetScheduleValueType"
        (ngModelChange)="doModelChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row">
    <div class="lv-label lv-label-field lv-label--title"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="ratchetScheduleSourceTooltipTemplate"
      filter=".lv-label--title">RATCHET SCHEDULE</div>
  </lv-flex-box>
  <lv-advanced-grid
    data-cy="DM-1438"
    lvId="takeoverProtectionRatchetSchedule"
    [columns]="columns"
    [records]="scheduleItems"
    [createFormGroup]="createFormGroup"
    [parseFn]="parseFn"
    sortBy="endDate"
    class="lv-takeover-protection-ratchet-schedule">
  </lv-advanced-grid>
</lv-flex-box>

<ng-template #compensationValueTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1437"></lv-tooltip>
</ng-template>
<ng-template #ratchetScheduleSourceTooltipTemplate>
  <lv-tooltip dm="dM-1438"></lv-tooltip>
</ng-template>

