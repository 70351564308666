import { LvUserFriendlyError } from '@lv-core-ui/models';

/**
 * User-friendly error for Security Management.
 */
export class LvSecurityManagementError extends LvUserFriendlyError {
  constructor(message: string) {
    super(message, 'Security Management');
  }
}
