import { OnInit, OnDestroy, Directive, ElementRef, Output, Input, ChangeDetectorRef } from '@angular/core';

import { Subject, Subscription, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

import { GridComponent } from '@progress/kendo-angular-grid';

import { LvError } from '../../models/lv-error/base';
import { LvGridUtil } from '../../util/lv-grid.util';

export interface IGridPasteEvent {
  records: any[];
  error?: LvError;
}

@Directive({
  selector: '[gridPaste]'
})
export class GridPasteDirective implements OnInit, OnDestroy {

  // tslint:disable-next-line: no-input-rename
  @Input('gridPaste')
  public parseFn: (records: string[]) => any[];

  @Output() didParse: Subject<IGridPasteEvent>;

  private _pasteSubscription: Subscription;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _grid: GridComponent,
    private _el: ElementRef
  ) {
    this.didParse = new Subject<IGridPasteEvent>();
  }

  public ngOnInit(): void {
    this._pasteSubscription = fromEvent(this._el.nativeElement, 'paste')
      .pipe(
        filter((evt: ClipboardEvent) => !!evt.clipboardData.getData('text'))
      )
      .subscribe((e: ClipboardEvent) => this.onPaste(e));
  }

  public ngOnDestroy(): void {
    this._pasteSubscription.unsubscribe();
  }

  private onPaste(evt: ClipboardEvent): void {
    try {
      if (LvGridUtil.closeEditor(this._grid)) {
        this._changeDetectorRef.detectChanges();
      }

      const clipboardData = evt.clipboardData.getData('text');
      const clipoboardRecords = clipboardData.split('\n')
        .map(r => r.trim())
        .filter(r => r.replace('\t', '') !== '');

      const parsed = this.parseFn(clipoboardRecords);
      this.didParse.next({
        records: parsed
      });
    }
    catch (error) {
      this.didParse.next({
        records: null,
        error: error
      });
    }
  }
}
