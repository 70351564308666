import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';

import { IConstants } from '@lv-core-ui/models';
import { constants } from '@lv-core-ui/util';
import { IEstimatesHistory, AdjustmentReasonDescription } from '@lv-analytics/models';
import { ConvertiblesService } from '@lv-analytics/services';

export interface IEstimatesHistoryGridResult extends GridDataResult {
  data: IEstimatesHistory[];
}

/**
 * Estimates history dialog component.
 */
@Component({
  selector: 'lv-estimates-history-dialog',
  templateUrl: './lv-estimates-history-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LvEstimatesHistoryDialogComponent implements OnInit {

  constants: IConstants;
  adjustmentReasonEnum = AdjustmentReasonDescription;

  identifer: string;
  sessionId: string;

  recordsView: IEstimatesHistoryGridResult;
  isLoading: boolean;
  selectableSettings: boolean;

  constructor(
    private dialogRef: DialogRef,
    private changeDetectorRef: ChangeDetectorRef,
    private convertiblesService: ConvertiblesService,
  ) {
      this.isLoading = false;
      this.constants = constants;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.loadData();
  }

  /**
   * Closes dialog.
   */
  onClose() {
    this.dialogRef.close();
  }

  /**
   * Loads data.
   */
  async loadData() {
    try {
      this.isLoading = true;
      this.changeDetectorRef.detectChanges();

      const result = await this.convertiblesService.getEstimatesHistory(this.identifer, this.sessionId, false);

      this.recordsView = {
        data: result,
        total: result.length
      } as IEstimatesHistoryGridResult;
    }
    catch (error) {
      throw error;
    }
    finally {
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
    }
  }
}
