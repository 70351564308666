<lv-advanced-grid
  data-cy="DM-1002"
  [columns]="columns"
  [records]="scheduleItems"
  [createFormGroup]="createFormGroup"
  [parseFn]="parseFn"
  sortBy="sinkingDate"
  lvId="lvTermsSinkingSchedule"
  [showXlLabel]="hasScheduleInExcelOverride"
  [excelFieldAlias]="excelFieldAlias"
  [isFieldFromExcelEnabled]="isFieldFromExcelEnabled"
  class="lv-general-sinking-schedule">
</lv-advanced-grid>
