import { ITakeoverProtectionSectionSettings } from '../takeover-protection-settings/takeover-protection-section-settings';
import { TakeoverPutValueType, TakeoverCompensationType, PremiumCompensationFormulaType, RatchetMatrixOfferValueType,
  RatchetMatrixCompensationValueType } from '@lv-convertible-bond/models';

export const getDefaultTakeoverProtectionSectionSettings = (): ITakeoverProtectionSectionSettings => {
  return {
    takeoverCompensationType: TakeoverCompensationType.PremiumCompensationFormula,
    takeoverPutValueType: TakeoverPutValueType.PercentOfPar,
    premiumCompensationFormulaType: PremiumCompensationFormulaType.Formula1,
    ratchetMatrixOfferValueType: RatchetMatrixOfferValueType.StockPrice,
    ratchetMatrixCompensationValueType: RatchetMatrixCompensationValueType.AdditionalSharesPercentOfCR,
    takeoverCompensationProtectionEndDate: null,
    takeoverCompensationProtectionStartDate: null,
    takeoverPut: false,
    useTakeoverProtection: false
  };
};
