import { LvScenarioChartView, ILvScatterLineChart } from '../lv-scenario-chart.view';
import { ILvScenarioReport } from '../../lv-scenario.view';
import { ICalculateScenarioOutput } from '@lv-analytics/models';

/**
 * Scenario one dim chart view.
 */
export class LvScenarioOneDimChartView extends LvScenarioChartView<ILvScatterLineChart> {

  /**
   * Gets defailt model.
   * @returns ILvScatterLineChart object.
   */
  getDefaultModel(): ILvScatterLineChart {
    return {
      xAxis: {},
      yAxis: {},
      series: [],
      records: []
    };
  }

  /**
   * Does initialization.
   * @param report ILvScenarioReport object.
   */
  doInit(report: ILvScenarioReport): void {
    const mapped = (report.data as ICalculateScenarioOutput[]);

    this.model.yAxis = this.getYAxis();

    mapped.forEach((fDim, i, fDimArray) => {
      if (this.isFirstIndex(i)) {
        this.model.xAxis = this.getXAxis(fDim.dimension);
        this.model.xAxis.min = fDim.value;
      }

      const rec = {
        [fDim.dimension]: fDim.value
      };

      fDim.outputs.forEach(output => {
        if (this.isFirstIndex(i)) {
          this.model.series.push(this.getSeries(fDim, output));
        }

        const assumed = output.values[0];
        rec[output.output] = assumed.value;

        this.setYAxisMinAndMax(assumed.value);
      });

      if (this.isLastIndex(fDimArray, i)) {
        this.model.xAxis.max = fDim.value;
      }

      this.model.records.push(rec);
    });
  }
}
