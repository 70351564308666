import { NgForm } from '@angular/forms';

export class LvFormUtil {

  static markAllControlsAsTouched(form: NgForm) {
    Object.keys(form.controls).forEach(key => {
      form.controls[key].markAsTouched();
    });
  }

  static markAllControlsAsUntouched(form: NgForm) {
    Object.keys(form.controls).forEach(key => {
      form.controls[key].markAsUntouched();
    });
  }

  static markAllControlsAsDirty(form: NgForm) {
    Object.keys(form.controls).forEach(key => {
      form.controls[key].markAsDirty();
      form.controls[key].markAsTouched();
    });
  }

  static markAllControlsAsPristine(form: NgForm) {
    Object.keys(form.controls).forEach(key => {
      form.controls[key].markAsUntouched();
      form.controls[key].markAsPristine();
    });
  }
}
