import { Directive, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { fromEvent, Observable } from 'rxjs';

import { CellClickEvent, InCellEditingDirective, CreateFormGroupArgs, CellCloseEvent } from '@progress/kendo-angular-grid';

import { LvGridUtil } from '../../util/lv-grid.util';

@Directive({
  selector: '[gridCellEditing]'
})
export class GridCellEditingDirective extends InCellEditingDirective implements OnInit {

  // tslint:disable-next-line: no-input-rename
  @Input('gridCellEditing')
  declare createFormGroup: (args: CreateFormGroupArgs) => FormGroup;

  @Input() dataItemKey: string;
  @Input() editorPopupOpened: Observable<boolean>;
  @Input() editDisabled: boolean;

  @Output() didSaveCell: EventEmitter<void> = new EventEmitter();
  @Output() didEditCell: EventEmitter<void> = new EventEmitter();
  @Output() didCloseCell: EventEmitter<void> = new EventEmitter();

  get currentRowIndex(): number {
    return this._currentRowIndex;
  }

  private _currentFormGroup: FormGroup;
  private _currentRowIndex: number;
  private _popupOpened: boolean;

  /**
   * @hidden
   */
  public ngOnInit(): void {
    super.ngOnInit();

    this.subscriptions.add(this.grid.cellClick.subscribe(this.cellClickHandler.bind(this)));
    this.subscriptions.add(fromEvent(document.body, 'click').subscribe(this.mouseClickHandler.bind(this)));

    this.subscriptions.add(this.editorPopupOpened.subscribe(opened => this._popupOpened = opened));
  }

  public applyChanges(): boolean {
    if (this.grid.isEditingCell() && this.currentRowIndex > -1) {
      LvGridUtil.save(this.grid, this.currentRowIndex);
      return true;
    }

    return false;
  }

  protected cellClickHandler(evt: CellClickEvent): void {
    if (this.editDisabled) {
      return;
    }

    if (evt.type === 'contextmenu') {
      return;
    }

    if (!evt.column.editable) {
      return;
    }

    if (!this.grid.isEditing() && !evt.isEdited && evt.dataItem[this.dataItemKey]) {
      this._currentFormGroup = this.createFormGroup({
        dataItem: evt.dataItem
      } as any);
      this._currentRowIndex = evt.rowIndex;

      this.grid.editCell(evt.rowIndex, evt.columnIndex, this._currentFormGroup);

      setTimeout(() => LvGridUtil.openCellEditor(this.grid, evt));

      this.didEditCell.next();
    }
  }

  protected cellCloseHandler(evt: CellCloseEvent): void {
    if (this.grid.isEditingCell() && this._currentFormGroup.valid) {
      if (evt.formGroup.dirty) {
        LvGridUtil.save(this.grid, this._currentRowIndex);
        this.didSaveCell.next();
      }
      else {
        LvGridUtil.endEdit(this.grid, this._currentRowIndex);
        this.didCloseCell.next();
      }
    }
  }

  private mouseClickHandler(evt: MouseEvent) {
    if (!this._popupOpened
      && this.grid.isEditingCell()
      && LvGridUtil.clickedOutsideHandler(this.grid, evt.target, this._currentRowIndex)) {
      this.didCloseCell.next();
    }
  }
}
