<lv-flex-box class="lv-instrument-source-settings-dialog-radio-buttons" direction="row">
  <div class="lv-label lv-label-field lv-checkbox-row-label"
    data-cy="DM-1106"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="defaultInstrumentOverridesSettingsTooltipTemplate"
    filter="label">
    <label>Default Instrument </label>
  </div>
  <div class="lv-input-field lv-field lv-field--radio">
    <input class="k-radio"
      type="radio"
      [disabled]="!overrideSystemDefaults"
      [attr.id]="useLeversysRadioButtonId"
      [name]="getCheckBoxIdentifier('useLeversys')"
      value="Leversys"
      [(ngModel)]="settings.instrumentSource">
    <label class="k-radio-label"
      [attr.for]="useLeversysRadioButtonId"
      lvId="lv-use-leversys">
        Use Leversys
    </label>
  </div>
  <div class="lv-input-field lv-field lv-field--radio">
    <input class="k-radio"
      type="radio"
      [disabled]="!overrideSystemDefaults"
      [attr.id]="useCustomRadioButtonId"
      [name]="getCheckBoxIdentifier('useCustom')"
      value="Custom"
      [(ngModel)]="settings.instrumentSource">
    <label class="k-radio-label"
      [attr.for]="useCustomRadioButtonId"
      lvId="lv-use-custom">
        Use Custom
    </label>
  </div>
</lv-flex-box>

<ng-template #defaultInstrumentOverridesSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1106"></lv-tooltip>
</ng-template>

