<div class="lv-checkbox-column-label"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="newIssueTermsTooltipTemplate"
    filter="div">
      New Issue Terms
</div>
<div data-cy="DM-1153">
  <div class="lv-field lv-field--radio">
    <input class="k-radio"
      type="radio"
      [disabled]="!overrideSystemDefaults"
      [attr.id]="useAssumedRadioButtonId"
      [name]="getName('assumed')"
      value="assumed"
      [(ngModel)]="settings.newIssueSetingsAssumptions">
    <label class="k-radio-label"
      [attr.for]="useAssumedRadioButtonId"
      lvId="lv-new-issue-settings-my-environment-use-assumed">
        Use Assumed
    </label>
  </div>
  <div class="lv-input-field lv-field lv-field--radio">
    <input class="k-radio"
      type="radio"
      [disabled]="!overrideSystemDefaults"
      [attr.id]="useBestRadioButtonId"
      [name]="getName('best')"
      value="best"
      [(ngModel)]="settings.newIssueSetingsAssumptions">
    <label class="k-radio-label"
      [attr.for]="useBestRadioButtonId"
      lvId="lv-new-issue-settings-my-environment-use-best">
        Use Best
    </label>
  </div>
  <div class="lv-input-field lv-field lv-field--radio">
    <input class="k-radio"
      type="radio"
      [disabled]="!overrideSystemDefaults"
      [attr.id]="useMidRadioButtonId"
      [name]="getName('mid')"
      value="mid"
      [(ngModel)]="settings.newIssueSetingsAssumptions">
    <label class="k-radio-label"
      [attr.for]="useMidRadioButtonId"
      lvId="lv-new-issue-settings-my-environment-use-mid">
        Use Mid
    </label>
  </div>
  <div class="lv-input-field lv-field lv-field--radio">
    <input class="k-radio"
      type="radio"
      [disabled]="!overrideSystemDefaults"
      [attr.id]="useWorstRadioButtonId"
      [name]="getName('worst')"
      value="worst"
      [(ngModel)]="settings.newIssueSetingsAssumptions">
    <label class="k-radio-label"
      [attr.for]="useWorstRadioButtonId"
      lvId="lv-new-issue-settings-my-environment-use-worst">
        Use Worst
    </label>
  </div>
</div>

<ng-template #newIssueTermsTooltipTemplate>
  <lv-tooltip dm="dM-1153"></lv-tooltip>
</ng-template>