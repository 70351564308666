<lv-flex-box class="lv-mail-notifications-settings">
  <lv-flex-box class="lv-mail-notifications-settings-content">
    <ng-container *ngFor="let item of userSettingsData.events">
      <ng-container *ngIf="eventVisible(item)">
        <label *ngIf="eventGroupVisible(item)" class="lv-label-field lv-label lv-label--title">{{ getEventGroupLabel(item) }}</label>
        <lv-lws-event 
          [lwsEvent]="item" 
          [regionPermisions]="regionPermisions" 
          [canReceiveAllNotifications]="canReceiveAllNotifications"
          [canReceiveNewIssueNotifications]="canReceiveNewIssueNotifications">
        </lv-lws-event>
      </ng-container>
    </ng-container>

    <label class="lv-label-field lv-label lv-label--title">NOTIFICATION TIME</label>
    <lv-flex-box direction="row">

      <lv-flex-box
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="mailNotificationsSettingsTooltipTemplate"
        class="lv-label-field lv-label lv-mail-notifications-settings-label"
        filter="span">
        <span> Bulk notification time </span>
      </lv-flex-box>

      <lv-flex-box class="lv-mail-notifications-settings-time-picker-wrapper">
        <kendo-timepicker
          class="lv-mail-notifications-settings-time-picker"
          placeholder=""
          [(ngModel)]="userSettingsData.triggerBulkAt">
        </kendo-timepicker>
      </lv-flex-box>

      <div class="lv-mail-notifications-settings-time-zones">
        <kendo-dropdownlist
          #timeZoneItemList
          class="lv-mail-notifications-settings-time-zones-dropdown"
          name="notificationsSettingsDropdown"
          lvId="notifications-settings-dropdown"
          valueField="value"
          textField="text"
          [kendoDropDownFilter]="filterSettings"
          [data]="listOfTimezones"
          [(ngModel)]="userSettingsData.timezone"
          [valuePrimitive]="true"
          [popupSettings]="{
            popupClass: 'lv-mail-notifications-settings-time-zones-dropdown-popup'
          }">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <div style="flex: 1;">&nbsp;</div>
  </lv-flex-box>
  <lv-flex-box class="lv-mail-notifications-settings-footer" direction="row">
    <button kendoButton
     class="reset-button"
     [primary]="true"
     lvId="reset-to-default-notifications-settings"
     (click)="onResetToDefault()">
     Reset To Default
    </button>
    <button kendoButton
      [primary]="true"
      lvId="cancel-mail-notifications"
      (click)="onCancel()">
      Cancel
    </button>
    <button kendoButton
      [primary]="true"
      lvId="save-mail-notifications"
      (click)="onSave()">
      Save
   </button>
  </lv-flex-box>
</lv-flex-box>

<ng-template #mailNotificationsSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1135"></lv-tooltip>
</ng-template>

