<div class="lv-dialog lv-envrionment-settings-dialog">
  <div class="lv-dialog-content">
    <kendo-sortable class="lv-sortable-list lv-envrionment-settings-dialog-list"
      [kendoSortableBinding]="items"
      [animation]="true"
      emptyText="">
      <ng-template let-item="item">
        <div class="lv-sortable-list-item">
          <i class="k-icon k-i-more-vertical"></i>
          <span>{{ item.name }}</span>
        </div>
      </ng-template>
    </kendo-sortable>
  </div>
  <div class="lv-dialog-actions">
    <button kendoButton
      (click)="onClose()">Cancel</button>
    <button kendoButton
      [primary]="true"
      (click)="onSave()">Save</button>
  </div>
</div>