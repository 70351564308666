<div #menuContainer
  class="lv-menu"
  (click)="onShowMenu()">
  <div *ngIf="iconClass"
    class="icon"
    [ngClass]="iconClass">        
  </div>
  <ng-content></ng-content>

  <div *ngIf="text"
    class="text"
    [innerHtml]="text">
  </div>
</div>
<kendo-popup #dropdownContainer
  *ngIf="itemsVisible"
  [anchor]="menuContainer"
  [anchorAlign]="anchorAlign"
  [popupAlign]="popupAlign"
  class="lv-menu-popup-holder"
  popupClass="lv-menu-popup"
  (open)="onPupupOpened()">
  <ul class="lv-menu-list">
    <ng-container *ngFor="let item of items; let i = index, trackBy:itemsTrackBy">
    <li *ngIf="!item.hidden"
      class="item"
      [lvId]="item.lvId"
      [class.disabled]="item.disabled"
      [class.subcomponent]="item.subcomponent"
      (click)="executeAction(item)">
      <i class="icon"
        *ngIf="item.iconClass"
        [ngClass]="item.iconClass">
      </i>
      <span class="text" *ngIf="!item.isSeparator"
        [innerHtml]="item.text">
      </span>
      <hr *ngIf="item.isSeparator">
    </li> 
    </ng-container>
  </ul>
</kendo-popup>