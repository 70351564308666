export enum LvErrorType {
  DEFAULT,
  APPLICATION,
  AUTHORIZATION,
  AUTHENTICATION,
  CONNECTION,
  FORBIDDEN,
  NOT_FOUND,
  REQUEST_TIMEOOUT,
  USER_FRIENDLY,
  CONFLICT
}
