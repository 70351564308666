
export enum CouponReferenceTenor {
	
	OneMonth = 'OneMonth',
	ThreeMonth = 'ThreeMonth',
	SixMonth = 'SixMonth',
	TwelveMonth = 'TwelveMonth',
	FiveYears = 'FiveYears',
	TenYears = 'TenYears'
}
export enum PikCouponType {
	
	None = 'None',
	PIKOnly = 'PIKOnly',
	PIKPlusCash = 'PIKPlusCash',
	PIKOrCash = 'PIKOrCash',
	Custom = 'Custom'
}
export enum PikType {
	
	PIKOnly = 'PIKOnly',
	PIKPlusCash = 'PIKPlusCash',
	PIKOrCash = 'PIKOrCash',
	PIKPlusCashOrPIK = 'PIKPlusCashOrPIK',
	PIKPlusCashOrCash = 'PIKPlusCashOrCash'
}
export enum PIKType {
	
	PIKOnly = 'PIKOnly',
	PIKPlusCash = 'PIKPlusCash',
	PIKOrCash = 'PIKOrCash',
	PIKPlusCashOrPIK = 'PIKPlusCashOrPIK',
	PIKPusCashOrCash = 'PIKPusCashOrCash'
}
export enum CoPayCouponType {
	
	PercOfMarketPrice = 'PercOfMarketPrice',
	MaxOfDivinidendsAndPercOfMarketPrice = 'MaxOfDivinidendsAndPercOfMarketPrice'
}
export enum CoPayTriggerType {
	
	PercOfPar = 'PercOfPar',
	PercOfAccretedValue = 'PercOfAccretedValue'
}