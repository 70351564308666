import { Injectable } from "@angular/core";
import { IconSettingsService } from "@progress/kendo-angular-icons";

@Injectable()
export class LvIconService extends IconSettingsService {
  private iconDictionary: { [key: string]: string } = {
    "x-circle": "k-icon k-i-x",
    "caret-alt-down": "k-icon k-i-kpi-trend-decrease",
  };

  public getCustomFontIconClass(iconName: string): string {
    return (
      this.iconDictionary[iconName] || super.getCustomFontIconClass(iconName)
    );
  }
}