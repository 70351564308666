<lv-flex-box class="lv-estimates-settings-section">
  <lv-flex-box
    class="lv-estimates-current-estimate-radio-buttons"
    direction="row"
    data-cy="DM-1156">
      <div class="lv-input-field lv-field lv-field--radio lv-estimates-settings-section-system-defaults">
        <input class="k-radio"
          type="radio"
          lvId="lv-estimates-radio-buttons-current"
          [disabled]="settingsDisabled"
          [attr.id]="useCurrentConversionPriceRadioButtonId"
          [name]="conversionPriceCcyRadioButtonName"
          [value]="false"
          [(ngModel)]="estimatesSetup.useEstimatedRatio">
        <label class="k-radio-label"
          [attr.for]="useCurrentConversionPriceRadioButtonId"
          lvId="lv-new-issue-settings-system-defaults-use-best">
            Use Current Conversion Price
        </label>
      </div>
      <div class="lv-input-field lv-field lv-field--radio lv-estimates-settings-section-system-defaults">
        <input class="k-radio"
          type="radio"
          lvId="lv-estimates-radio-buttons-estimate"
          [disabled]="settingsDisabled"
          [attr.id]="useEstimateConversionPriceRadioButtonId"
          [name]="conversionPriceCcyRadioButtonName"
          [value]="true"
          [(ngModel)]="estimatesSetup.useEstimatedRatio">
        <label class="k-radio-label"
          [attr.for]="useEstimateConversionPriceRadioButtonId"
          lvId="lv-new-issue-settings-system-defaults-use-worst">
            Use Estimated Conversion Price
        </label>
      </div>
    </lv-flex-box>

  <lv-flex-box
    class="lv-estimates-cp-cr-radio-buttons"
    direction="row"
      data-cy="DM-1157">
    <div class="lv-input-field lv-field lv-field--radio lv-estimates-settings-section-system-defaults">
      <input class="k-radio"
        type="radio"
        lvId="lv-estimates-radio-buttons-conversion-price"
        [disabled]="settingsDisabled"
        [attr.id]="displayConversionPriceByDefaultRadioButtonId"
        [name]="displayConversionPriceRationRadioButtonName"
        [value]="true"
        [(ngModel)]="estimatesSetup.showConversionPrice">
      <label class="k-radio-label"
        [attr.for]="displayConversionPriceByDefaultRadioButtonId"
        lvId="lv-new-issue-settings-system-defaults-use-best">
          Display Conversion Price by Default
      </label>
    </div>
    <div class="lv-input-field lv-field lv-field--radio lv-estimates-settings-section-system-defaults">
      <input class="k-radio"
        type="radio"
        lvId="lv-estimates-radio-buttons-conversion-ratio"
        [disabled]="settingsDisabled"
        [attr.id]="displayConversionRatioByDefaultRadioButtonId"
        [name]="displayConversionPriceRationRadioButtonName"
        [value]="false"
        [(ngModel)]="estimatesSetup.showConversionPrice">
      <label class="k-radio-label"
        [attr.for]="displayConversionRatioByDefaultRadioButtonId"
        lvId="lv-new-issue-settings-system-defaults-use-worst">
          Display Conversion Ratio by Default
      </label>
    </div>
  </lv-flex-box>

  <div class="lv-estimates-settings-section-label-wrapper">
    <label>Display Conversion Price In:</label>
  </div>

  <lv-flex-box
    class="lv-estimates-settings-section-price-format"
    direction="row"
    data-cy="DM-1154">
    <div class="lv-input-field lv-field lv-field--radio">
      <input class="k-radio"
        type="radio"
        lvId="lv-estimates-radio-buttons-convertible-ccy"
        [disabled]="settingsDisabled || ratioSelected"
        [attr.id]="convertibleCcyRadioButtonId"
        [name]="displayConversionPriceInRadioButtonName"
        [value]="'ConvertibleCurrency'"
        [(ngModel)]="estimatesSetup.selectedCurrency">
      <label class="k-radio-label"
        [attr.for]="convertibleCcyRadioButtonId"
        lvId="lv-new-issue-settings-system-defaults-use-best">
          Convertible CCY
      </label>
    </div>
    <div class="lv-input-field lv-field lv-field--radio">
      <input class="k-radio"
        type="radio"
        lvId="lv-estimates-radio-buttons-underlying-ccy"
        [disabled]="settingsDisabled || ratioSelected"
        [attr.id]="underlyingCcyRadioButtonId"
        [name]="displayConversionPriceInRadioButtonName"
        [value]="'UnderlyingCurrency'"
        [(ngModel)]="estimatesSetup.selectedCurrency">
      <label class="k-radio-label"
        [attr.for]="underlyingCcyRadioButtonId"
        lvId="lv-new-issue-settings-system-defaults-use-mid">
          Underlying CCY
      </label>
    </div>
    <div class="lv-input-field lv-field lv-field--radio">
      <input class="k-radio"
        type="radio"
        lvId="lv-estimates-radio-buttons-convertible-price-ccy"
        [disabled]="settingsDisabled || ratioSelected"
        [attr.id]="conversionPriceCcyRadioButtonId"
        [name]="displayConversionPriceInRadioButtonName"
        [value]="'ConversionPriceCurrency'"
        [(ngModel)]="estimatesSetup.selectedCurrency">
      <label class="k-radio-label"
        [attr.for]="conversionPriceCcyRadioButtonId"
        lvId="lv-new-issue-settings-system-defaults-use-worst">
          Conversion Price CCY
      </label>
    </div>
  </lv-flex-box>
</lv-flex-box>
