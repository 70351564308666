// tslint:disable-next-line:max-line-length
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, HostBinding, Input, ChangeDetectorRef, ViewChild, OnDestroy, Optional } from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { LvToolsCustomSelectionComponent } from '../lv-tools-custom-selection/lv-tools-custom-selection.component';
import { IToolsState } from '../../../models/widget-state/tools/tools-state';
import * as _ from 'lodash';
import { LvToolsComponent } from '../lv-tools/lv-tools.component';
import { LvBaseWidgetComponent } from '@lv-analytics/lv-base-widget.component';
import { WidgetStateManagerService } from '@lv-application-settings/services';
import { DefaultWidgetType } from '@lv-shared/models';
import { LvExcelService } from '@lv-excel/services';

/**
 * Tools widget component.
 */
@Component({
  selector: 'lv-tools-widget',
  templateUrl: './lv-tools-widget.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvToolsWidgetComponent extends LvBaseWidgetComponent<IToolsState> {

  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc && this._excelSvc.isInitialized();
  }

  get editWidgetButtonText(): string {
    return this.isOpenedFromExcel ? 'Edit Excel View' : 'Edit Custom View';
  }

  get saveWidgetButtonText(): string {
    return this.isOpenedFromExcel ? 'Save as Excel View' : 'Save as Default View';
  }

  constructor(
    public _changeDetectorRef: ChangeDetectorRef,
    private _dialogService: DialogService,
    public _widgetStateManagerService: WidgetStateManagerService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    super(
      _changeDetectorRef,
      _widgetStateManagerService,
      LvToolsWidgetComponent.getInitialState(!!_excelSvc?.isInitialized()),
      DefaultWidgetType.Tools
      );
  }

  @HostBinding('class.lv-tools-widget')
  get isLvMarketDataWidget(): boolean {
    return true;
  }

  @ViewChild(LvToolsComponent, { static: true }) toolsComponent: LvToolsComponent;

  /**
   * Occurs on edit custom view.
   */
  onEditCustomView() {
    const dialogRef = this._dialogService.open({
      title: 'Tools Custom Selection',
      content: LvToolsCustomSelectionComponent
    });

    const dialog = (dialogRef.content.instance as LvToolsCustomSelectionComponent);
    dialog.toolsState = _.cloneDeep(this.widgetState);

    dialog.didSave.subscribe(
      (updatedState) => {
        this.widgetState = updatedState;
        this.widgetState.useDefaultView = false;
        this._widgetStateManagerService.saveWidgetState(DefaultWidgetType.Tools, this.widgetState);
        this._widgetStateManagerService.useCustomView(this.widgetType);
        this.toolsComponent.state = this.widgetState;
        this._changeDetectorRef.detectChanges();
      }
    );
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  onDestroy(): void {
  }

  /**
   * Handles any additional initialization tasks.
   */
  onInit(): void {
  }

  /**
   * Initial state for tools widget component
   * @param {boolean} isOpenedFromExcel Boolean value whether is instrument opened in excel form or not
   * @returns IToolsState object
   */
  // tslint:disable-next-line:member-ordering
  public static getInitialState(isOpenedFromExcel: boolean): IToolsState {
    return {
      useDefaultView: true,
      deltaNeutralState: {
        isDeltaNeutral: true,
        isConvertibleAndStockValues: true,
        isFxDelta: true,
        isStockEQCcyAndCrossFxDn: true,
        isActualDelta: true,
        isPnLDn: true
      },
      differentDeltaExecutionState: {
        isDifferentDeltaExecution: true,
        isConvertibleValue: true,
        isStockEQCcyAndCrossFxFDde: true,
        isPriceChanges: true,
        isCommission: true,
        isPnLDde: true,
      },
      breakEvenState: {
        isBreakeven: true,
        isAtParity: true,
        isAtPar: true,
        isAtZeroStockPrice: true,
      },
      isOpenedFromExcel: isOpenedFromExcel
    };
  }
}
