import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, ChangeDetectionStrategy,
  ViewChild, Input, OnChanges, OnDestroy, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

import { Subject,  Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { Permissions } from './../../documents.permissions';
import { DocumentsService } from '../../documents.service';
import { LvDocumentsError } from '../../models/errors';
import { LvErrorService, LvPermissionService } from '@lv-core-ui/services';
import { PDFDocumentProxy, PDFProgressData, PdfViewerComponent } from 'ng2-pdf-viewer';

export interface IMatchesCount {
  current: number;
  total: number;
}

export interface IUpdateFindMatchesCountEvent {
  matchesCount: IMatchesCount;
}

/**
 * Document component.
 */
@Component({
  selector: 'lv-document',
  templateUrl: './lv-document.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvDocumentComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('searchElement', { static: true })
  private searchElement: ElementRef;

  @ViewChild(PdfViewerComponent, { static: true })
  private pdfViewerComponent: PdfViewerComponent;

  @ViewChild('anchorElement', { static: true })
  private anchorElement: ElementRef;

  @Input() documentId?: string;
  @Input() instrumentName?: string;

  @Output() doClose: EventEmitter<void>;

  pdfSrc: ArrayBuffer;
  pdf: any;
  zoom: number;

  progressData: PDFProgressData;
  outline: any[];

  isLoading: boolean;
  isOutlineLoaded: boolean;
  isError: boolean;

  resizeObservable: Subject<boolean>;
  resizeSubscription: Subscription;

  searchQuery: string;
  query: Subject<string>;

  searchMatch: IMatchesCount;

  isDocumentDownloading: boolean;

  get showClearIcon(): boolean {
    return this.searchQuery && this.searchQuery.length > 0;
  }

  get showSearchResult(): boolean {
    return this.searchQuery && this.searchQuery.length >= 3;
  }

  get showMatchCount(): boolean {
    return !!this.searchMatch;
  }

  get pdfViewerContainer(): HTMLElement {
    return ((this.pdfViewerComponent as any).element as ElementRef).nativeElement;
  }

  get canDownloadDocument(): boolean {
    return this.permissionService.hasPermission(Permissions.MODULE, Permissions.DOWNLOAD);
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private documentsService: DocumentsService,
    private errorService: LvErrorService,
    private permissionService: LvPermissionService
  ) {
    this.isLoading = true;
    this.isOutlineLoaded = false;
    this.isError = false;

    this.outline = [];
    this.zoom = 1.0;

    this.resizeObservable = new Subject<boolean>();

    this.searchQuery = null;
    this.query = new Subject<string>();

    this.searchMatch = null;

    this.isDocumentDownloading = false;

    this.doClose = new EventEmitter<void>();
  }

  /**
   * Handles keybord event that occurs on press Ctrl + F.
   * @param event Keyboard event.
   */
  @HostListener('keydown.control.f', ['$event'])
  onCtrlF(event: KeyboardEvent) {
    event.preventDefault();
    this.searchElement.nativeElement.focus();
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    // prevent right click on pdf viewer container
    this.pdfViewerContainer.oncontextmenu = (event: PointerEvent) => {
      event.preventDefault();
    };

    this.query.pipe(
      debounceTime(500),
      filter(query => query && query.length >= 3)
    ).subscribe(() => {
      this.onSearch();
    });

    if (this.documentId) {
      this.doView(this.documentId);
    }
  }

  /**
   * Handles changes.
   */
  ngOnChanges() {
    if (this.documentId && !this.isLoading) {
      this.doView(this.documentId);
    }
  }

  /**
   * Displays document in preview container.
   * @param documentId Document ID.
   */
  async doView(documentId: string) {
    try {
      this.isLoading = true;
      this.changeDetectorRef.markForCheck();

      this.pdfSrc = await this.documentsService.metadataService.viewDocument(documentId);

      this.focusContainer();

      this.isLoading = false;
      this.changeDetectorRef.markForCheck();
    }
    catch (error) {
      this.onError(error);
    }
  }

  /**
   * Focus container.
   */
  focusContainer() {
    if (this.pdfViewerContainer) {
      const focusEl = this.pdfViewerContainer.querySelector('div.ng2-pdf-viewer-container');

      if (focusEl) {
        (focusEl as any).setAttribute('tabindex', 0);

        setTimeout(() => {
          (focusEl as HTMLElement).focus();
        }, 10);
      }
    }
  }

  /**
   * Occurs after load is completed.
   * @param pdf PDFDocumentProxy object.
   */
  async onAfterLoadComplete(pdf: PDFDocumentProxy) {
    try {
      this.pdf = pdf;

      await this.loadOutline();

      this.isLoading = false;

      this.pdfViewerComponent.pdfFindController._eventBus.on('updatefindmatchescount',
        (event: IUpdateFindMatchesCountEvent) => {
          this.searchMatch = event.matchesCount;
          this.changeDetectorRef.markForCheck();
        }
      );

      this.pdfViewerComponent.pdfFindController._eventBus.on('updatefindcontrolstate',
        (event: IUpdateFindMatchesCountEvent) => {
          this.searchMatch = event.matchesCount;
          this.changeDetectorRef.markForCheck();
        }
      );

      this.changeDetectorRef.markForCheck();
    }
    catch (error) {
      this.onError(error);
    }
  }

  /**
   * Loads outline.
   */
  async loadOutline() {
    try {
      this.isOutlineLoaded = false;
      const outline = await this.pdf.getOutline();
      this.outline = outline;
      this.isOutlineLoaded = true;
    }
    catch (error) {
      this.isOutlineLoaded = false;
      this.outline = [];
      throw error;
    }
  }

  /**
   * Navigate.
   * @param destination Destination.
   */
  navigateTo(destination: any) {
    this.pdfViewerComponent.pdfLinkService.goToDestination(destination);
    this.focusContainer();
  }

  /**
   * Occurs on search query change.
   * @param event Keyboard event.
   * @param inputValue Input value.
   */
  onQueryChange(event: KeyboardEvent, inputValue: string) {
    this.searchQuery = inputValue || '';
    this.query.next(this.searchQuery);
  }

  /**
   * Occurs on search command.
   */
  onSearch(direction = false, next = '') {
    try {
      this.pdfViewerComponent.eventBus.dispatch('find', {
        query: this.searchQuery,
        type: 'again',
        caseSensitive: false,
        findPrevious: direction,
        highlightAll: true,
        phraseSearch: true
      });
    }
    catch (error) {
      this.onError(error);
    }
  }

  /**
   * Occurs on clear search.
   */
  onClearSearch() {
    this.searchElement.nativeElement.value = '';
    this.searchQuery = '';
    this.searchMatch = {
      total: 0,
      current: 0
    };
    this.onSearch();
  }

  /**
   * Occurs on search previous matching.
   */
  onSearchPrevious() {
    this.onSearch(true, 'again');
  }

  /**
   * Occurs on search next.
   */
  onSearchNext() {
    this.onSearch(false, 'again');
  }

  /**
   * Occurs on zoom.
   * @param increment Increment.
   */
  onZoom(increment: number) {
    this.zoom = this.zoom + increment;

    if (this.zoom < 0.1) {
      this.zoom = 0.1;
    }

    if (this.zoom > 5) {
      this.zoom = 5;
    }

    this.pdfViewerComponent.pdfViewer.currentScaleValue = this.zoom.toString();

    this.focusContainer();
  }

  /**
   * Occurs on zoom reset.
   */
  onResetZoom() {
    this.zoom = 1.0;
    this.pdfViewerComponent.pdfViewer.currentScaleValue = this.zoom.toString();

    this.focusContainer();
  }

  /**
   * Occurs on download document.
   */
  async onDownload() {
    try {
      if (!this.isDocumentDownloading && this.canDownloadDocument) {
        this.isDocumentDownloading = true;
        this.changeDetectorRef.markForCheck();

        const pdf = await this.documentsService.metadataService.downloadDocument(this.documentId, this.instrumentName);

        let blob = new Blob([pdf.data], {
          type: 'application/pdf'
        });

        const navigator = (window.navigator) as any;
        if (window.navigator && navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, pdf.fileName);
        }
        else {
          const url = window.URL.createObjectURL(blob);

          this.anchorElement.nativeElement.href = url;
          this.anchorElement.nativeElement.download = pdf.fileName;
          this.anchorElement.nativeElement.click();

          window.URL.revokeObjectURL(url);

          this.anchorElement.nativeElement.href = null;
          this.anchorElement.nativeElement.download = null;
        }

        blob = null;

        this.isDocumentDownloading = false;
      }
    }
    catch (error) {
      this.isDocumentDownloading = false;
      this.errorService.handleError(error);
    }
    finally {
      this.changeDetectorRef.markForCheck();
    }
  }

  /**
   * Occurs on close.
   */
  onClose() {
    this.doClose.emit();
  }

  /**
   * Handles error.
   * @param error Error.
   */
  onError(error: any) {
    this.isLoading = false;
    this.isError = true;
    this.changeDetectorRef.markForCheck();
    this.errorService.handleError(new LvDocumentsError('Unable to load document!'));
  }

  /**
   * Force rerender.
   */
  forceRerender() {
    let zoom = this.zoom;

    zoom += 0.1;
    this.pdfViewerComponent.pdfViewer.currentScaleValue = zoom.toString();

    zoom -= 0.1;
    this.pdfViewerComponent.pdfViewer.currentScaleValue = zoom.toString();

    this.focusContainer();
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this.pdfSrc = null;
  }
}
