import { OnInit, OnDestroy, Directive, ElementRef, Output, ChangeDetectorRef } from '@angular/core';

import { Subject, Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { GridComponent } from '@progress/kendo-angular-grid';

import { LvGridUtil } from '../../util/lv-grid.util';

@Directive({
  selector: '[gridKeyboardRemove]'
})
export class GridKeyboardRemoveDirective implements OnInit, OnDestroy {

  @Output() doRemove: Subject<number>;

  private _keyDownSubscription: Subscription;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _grid: GridComponent,
    private _el: ElementRef
  ) {
    this.doRemove = new Subject<number>();
  }

  public ngOnInit(): void {
    this._keyDownSubscription = fromEvent(this._el.nativeElement, 'keydown')
      .pipe(
        debounceTime(10)
      )
      .subscribe((e: KeyboardEvent) => this.onKeydown(e));
  }

  public ngOnDestroy(): void {
    this._keyDownSubscription.unsubscribe();
  }

  private onKeydown(e: KeyboardEvent): void {
    if (e.shiftKey) {
      if (e.key === '-' || e.key === '_' ) {
        if (LvGridUtil.closeEditor(this._grid)) {
          this._changeDetectorRef.detectChanges();
        }

        this.doRemove.next(this._grid.activeRow.dataRowIndex);
      }
    }
  }
}
