import { v4 } from 'uuid';
import { DecimalPipe } from '@angular/common';
import { constants } from '@lv-core-ui/util';
import { ConvertibleHelper } from '@lv-analytics/helpers';
import { ICrEstimateSettings, CrEstimateCurrency, IPricingWidgetState, IConvertible } from '@lv-analytics/models';

export const getDefaultConversionPriceEstimate = (settings?: ICrEstimateSettings): ICrEstimateSettings => {
  if (settings) {
    return settings;
  }

  return {
    useEstimatedRatio: false,
    selectedCurrency: 'ConversionPriceCurrency',
    showConversionPrice: false
  } as ICrEstimateSettings;
};

/**
 * Conversion price estimate view.
 */
export class LvConversionPriceEstimateView {

  get isVisible(): boolean {
    if (!this._cHelper.isValid) {
      return false;
    }

    return !!this._cHelper.convertible.expectedCR;
  }

  get convertibleId(): number {
    return this._cHelper.convertible.convertibleId;
  }

  get currentRatioLabel(): string {
    const label = `Use Current ${this.estimateType}`;

    if (this.pricingWidgetState && this.model.showConversionPrice) {
      return `${label} (${this.formatNumber(this.conversionPrice)} ${this.currentCurrency})`;
    }
    else {
      return `${label} (${this.formatNumber(this._cHelper.convertible.conversionRatio)})`;
    }
  }

  get estimatesbuttonLabel(): string {
    if (this.model.useEstimatedRatio) {
      return 'estimate';
    }
    else {
      return 'current';
    }
  }

  get expectedRatioLabel(): string {
    const label = `Use Expected ${this.estimateType}`;

    if (this.pricingWidgetState && this.model.showConversionPrice) {
      return `${label} (${this.formatNumber(this.expectedCP)} ${this.currentCurrency})`;
    }
    else {
      return `${label} (${this.formatNumber(this._cHelper.convertible.expectedCR)})`;
    }
  }

  get currentRatioLabelWithoutCcy(): string {
    const label = `Use Current ${this.estimateType}`;

    if (this.pricingWidgetState && this.model.showConversionPrice) {
      return `${label} (${this.formatNumber(this.conversionPrice)})`;
    }
    else {
      return `${label} (${this.formatNumber(this._cHelper.convertible.conversionRatio)})`;
    }
  }

  get expectedRatioLabelWithoutCcy(): string {
    const label = `Use Expected ${this.estimateType}`;

    if (this.pricingWidgetState && this.model.showConversionPrice) {
      return `${label} (${this.formatNumber(this.expectedCP)})`;
    }
    else {
      if (!this._cHelper.convertible) {
        return '';
      }
      return `${label} (${this.formatNumber(this._cHelper.convertible.expectedCR)})`;
    }
  }

  get estimateType(): string {
    const priceLabel = 'Price';
    const ratioLabel = 'Ratio';

    if (this.pricingWidgetState && this.model.showConversionPrice) {
      return this._cHelper.isPeps ? `${priceLabel}` : priceLabel;
    }

    return this._cHelper.isPeps ? `${ratioLabel}` : ratioLabel;
  }

  get convertibleCurrencyUsed(): boolean {
    return this.model.showConversionPrice
      && this.model.selectedCurrency === CrEstimateCurrency.ConvertibleCurrency;
  }

  get underlyingCurrencyUsed(): boolean {
    return this.model.showConversionPrice
      && this.model.selectedCurrency === CrEstimateCurrency.UnderlyingCurrency;
  }

  get conversionPriceCurrencyUsed(): boolean {
    return this.model.showConversionPrice
      && this.model.selectedCurrency === CrEstimateCurrency.ConversionPriceCurrency;
  }

  model: ICrEstimateSettings;
  conversionPrice: number;
  expectedCP: number;
  currentCurrency: string;

  currentRatioId: string;
  expectedRatioId: string;

  pricingWidgetState: IPricingWidgetState;

  _cHelper: ConvertibleHelper;

  constructor(
    public decimalPipe: DecimalPipe
  ) {
    this._cHelper = new ConvertibleHelper();

    this.currentRatioId = v4();
    this.expectedRatioId = v4();

    this.conversionPrice = null;
    this.expectedCP = null;
    this.model = getDefaultConversionPriceEstimate();

    this.init();
  }

  /**
   * Initializes model and pricing widget state, and updates convertible.
   * @param model ICrEstimateSettings object.
   * @param convertible IConvertible object.
   * @param pricinngWidgetState IPricingWidgetState object.
   */
  init(model?: ICrEstimateSettings, convertible?: IConvertible, pricinngWidgetState?: IPricingWidgetState) {
    this.model = getDefaultConversionPriceEstimate(model);
    this.pricingWidgetState = pricinngWidgetState;

    this.updateConvertible(convertible);
  }

  /**
   * Updates convertible.
   * @param convertible IConvertible object.
   */
  updateConvertible(convertible?: IConvertible) {
    this._cHelper.init(convertible);

    this.currentCurrency = this._cHelper.currencyCode;

    if (this._cHelper.isValid) {
      this.calculateConversionPrices();
    }
  }

  /**
   * Updates Cr estimate settings.
   * @param model ICrEstimateSettings object.
   */
  update(model: ICrEstimateSettings) {
    this.model = getDefaultConversionPriceEstimate(model);
  }

  /**
   * Sets show conversion price flag.
   * @param showConversionPrice A flag indicating if conversion price should be shown.
   */
  setShowConversionPrice(showConversionPrice: boolean) {
    this.model.showConversionPrice = showConversionPrice;
  }

  /**
   * Sets selected currency.
   * @param selectedCurrency CrEstimateCurrency.
   */
  setSelectedCurrency(selectedCurrency: CrEstimateCurrency) {
    this.model.selectedCurrency = selectedCurrency;
    this.calculateConversionPrices();
  }

  /**
   * Toggles use estimate ratio.
   */
  toggleUseEstimatedRatio() {
    this.model.useEstimatedRatio = !this.model.useEstimatedRatio;
  }

  /**
   * Calculates conversion prices.
   */
  private calculateConversionPrices(): void {
    let crossFxCurrencyUsed = false;

    switch (this.model.selectedCurrency) {
      case CrEstimateCurrency.ConvertibleCurrency: {
        this.currentCurrency = this._cHelper.currencyCode;
        break;
      }
      case CrEstimateCurrency.UnderlyingCurrency: {
        this.currentCurrency = this._cHelper.underlyingCurrencyCode;
        crossFxCurrencyUsed = true;
        break;
      }
      case CrEstimateCurrency.ConversionPriceCurrency: {
        if (this._cHelper.isDetachableWarrant || this._cHelper.hasDeltaNeutralConversionAmount) {
          this.currentCurrency = this._cHelper.underlyingCurrencyCode;
          crossFxCurrencyUsed = true;
        }
        else {
          this.currentCurrency = this._cHelper.currencyCode;
        }
        break;
      }
    }

    let factor = 1;

    if (crossFxCurrencyUsed && this._cHelper.convertible.fixedFxRate > 0) {
      factor = this._cHelper.convertible.fixedFxRate;
    }

    if (this._cHelper.isDetachableWarrant || this._cHelper.isDeltaNeutral) {
      if (this.model.selectedCurrency === CrEstimateCurrency.ConvertibleCurrency) {
        const factorForDetachableAndDeltaNeutral = this._cHelper.convertible.fixedFxRate;
        this.conversionPrice = this._cHelper.convertible.conversionPrice / factorForDetachableAndDeltaNeutral;
        this.expectedCP = this._cHelper.convertible.expectedCP / factorForDetachableAndDeltaNeutral;
      }
      else {
        this.conversionPrice = this._cHelper.convertible.conversionPrice;
        this.expectedCP = this._cHelper.convertible.expectedCP;
      }
    }
    else {
      this.conversionPrice = this._cHelper.convertible.conversionPrice * factor;
      this.expectedCP = this._cHelper.convertible.expectedCP * factor;
    }
  }

  /**
   * Formats number.
   * @param value Value.
   * @returns Formated number.
   */
  private formatNumber(value: number): string {
    return this.decimalPipe.transform(value, constants.numberFormat.threeDigits);
  }
}
