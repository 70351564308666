import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
  Input, Output, EventEmitter, ViewChild } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { IDividendsCopyAndPasteSectionSettings } from 'src/app/modules/analytics/models/company-and-user-settings/other-settings/copy-and-paste-settings/copy-and-paste-section-settings';
import { IDividends } from '@lv-analytics/models/market-data/dividends';
import { LvScheduleDividendsTableComponent } from '../lv-schedule-dividends-table/lv-schedule-dividends-table.component';

/**
 * Schedule dividends component.
 */
@Component({
  selector: 'lv-schedule-dividends',
  templateUrl: './lv-schedule-dividends.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvScheduleDividendsComponent implements OnInit {

  @ViewChild(LvScheduleDividendsTableComponent, { static: true }) tableComponent: LvScheduleDividendsTableComponent;

  @Input() dividendsSettings: IDividends;
  @Input() dividendsCopyAndPasteSettings: IDividendsCopyAndPasteSectionSettings;

  @Output() didDividendScheduleChange: EventEmitter<void>;
  @Output() didDisplayDividendUseChange: EventEmitter<void>;

  previousCurrencySelected: string;
  currentCurrencySelected: string;

  constructor(
  ) {
    this.didDividendScheduleChange = new EventEmitter<void>();
    this.didDisplayDividendUseChange = new EventEmitter<void>();
    this.dividendsCopyAndPasteSettings = {} as IDividendsCopyAndPasteSectionSettings;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Does dividend schedule change.
   */
  doDividendScheduleChange() {
    this.didDividendScheduleChange.emit();
  }

  /**
   * Displays dividend use button.
   */
  doDisplayDividendUseButtonClick() {
    this.didDisplayDividendUseChange.emit();
  }
}
