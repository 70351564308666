import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input,
  OnDestroy,
  OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
// tslint:disable-next-line: max-line-length
import { LvAdvancedCopyPasteGridComponent } from 'src/app/leversys-core-ui/components/lv-advanced-copy-paste-grid/lv-advanced-copy-paste-grid.component';
// tslint:disable-next-line: max-line-length
import { LvAdvancedGridColumn, LvAdvancedGridTextColumn } from 'src/app/leversys-core-ui/components/lv-advanced-grid/lv-advanced-grid';
import { LvError } from 'src/app/leversys-core-ui/models/lv-error/base';
import { IScheduledDividend } from '../../../../models/market-data/dividends/scheduled-dividend';

/**
 * Copy and paste dialog component.
 */
@Component({
  selector: 'lv-copy-and-paste-dialog',
  templateUrl: './lv-copy-and-paste-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCopyAndPasteDialogComponent implements OnInit, OnDestroy {

  @ViewChild(LvAdvancedCopyPasteGridComponent, { static: true }) advancedGrid: LvAdvancedCopyPasteGridComponent;
  @ViewChild('gridWrapper') gridWrapper: ElementRef;

  @Input() data: any;
  @Input() componentName: ComponentName;
  @Input() selectionStart: number;
  @Input() selectionEnd: number;
  @Input() firstColumnSelectedIndex: number;
  @Input() secondColumnSelectedIndex: number;

  @Output() doClose: EventEmitter<void>;
  @Output() didPaste: EventEmitter<any>;

  dialogRadioLabels;
  columns: LvAdvancedGridColumn[];
  selectedColumns: { date: number, value: number };

  numberOfColumns: number;
  parsedData: string[];
  fields: string[];

  private selectedData: number[];
  private _subscriptions: Subscription[];

  constructor(private dialogRef: DialogRef,
              private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.doClose = new EventEmitter<void>();
    this.didPaste = new EventEmitter<any>();
    this.componentName = 'dividends';
    this.selectedData = [];
    this.selectedColumns = {
      date: null,
      value: null
    };
    this.data = [];
    this.numberOfColumns = 0;
    this.parsedData = [];

    this.dialogRadioLabels = {
      dividends: ['date', 'value'],
      interestRates: ['tenor', 'rate']
    };

    this.selectionStart = 0;
    this.selectionEnd = 0;
    this.firstColumnSelectedIndex = 0;
    this.secondColumnSelectedIndex = 0;
    this.fields = [];
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this.advancedGrid.didDataChange.subscribe((records: IScheduledDividend[]) => this.onDataChange(records)),
      this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];

    this.numberOfColumns = this.getNumberOfColumnsAndSetFields();
    this.initData();
    this.initColumns();

    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on data change.
   * @param records List of IScheduledDividend object.
   */
  onDataChange(records: IScheduledDividend[]) {
    this.applyRecords(records);
  }

  /**
   * Handles error.
   * @param error LvError object.
   */
  onError(error: LvError) {
    throw error;
  }

  /**
   * Closes dialog.
   */
  public onClose(): void {
    this.doClose.emit();
    this.dialogRef.close();
  }

  /**
   * Occurs on paste.
   */
  public onPaste(): void {

    const selectedRowsData = this.parsedData.filter((item, index) => {
      return this.advancedGrid.selectedRows.findIndex(x => x === index) > -1;
    });

    const result = selectedRowsData.map(item => {
      const selectedFirstColumn = this.advancedGrid.selectedColumns[this.dialogRadioLabels[this.componentName][0]];
      const selectedSecondColumn = this.advancedGrid.selectedColumns[this.dialogRadioLabels[this.componentName][1]];
      return `${item['field' + selectedFirstColumn]}\t${item['field' + selectedSecondColumn]}`;
    });

    this.didPaste.next(result);
  }

  /**
   * Handles row selection.
   * @param event Event.
   */
  public handleRowSelection(event): void {
    this.selectedData.push(event.index as number);
  }

  /**
   * Handles row deselection.
   * @param event Event.
   */
  public handleRowDeselection(event): void {
    this.selectedData = this.selectedData.filter(x => x !== event.index);
  }

  /**
   * Creates form group.
   * @param args CreateFormGroupArgs
   * @returns FormGroup object.
   */
  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    const formGroup = {};

    Object.keys(args.dataItem).forEach(item => {
      formGroup[item] = new FormControl(args.dataItem[item]);
    });

    return new FormGroup(formGroup);
  }

  /**
   * Handles column selection.
   * @param value Value.
   * @param type Type.
   */
  public handleColumnSelection(value: number, type: string): void {
    this.selectedColumns[type] = value;
    this.selectedColumns = JSON.parse(JSON.stringify(this.selectedColumns));
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Applies records.
   * @param records List of records.
   */
  private applyRecords(records: any[]) {
    this.parsedData.splice(0, this.data.length);
    this.parsedData.push(...records);
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  /**
   * Gets number of columns and set fields.
   * @returns Column length.
   */
  getNumberOfColumnsAndSetFields(): number {
    let columnLength = 0;

    if (this.data && this.data.length) {
      this.data.forEach((element) => {
        const rowColumn = element.split('\t').length;

        if (rowColumn > columnLength) {
          columnLength = rowColumn;
        }
      });
    }

    for (let i = 0; i < columnLength; i++) {
      this.fields.push(`field${i}`);
    }

    return columnLength;
  }

  /**
   * Columns initialization.
   */
  private initColumns() {
    this.columns = [];

    for (let i = 0; i < this.numberOfColumns; i++) {
      const column = new LvAdvancedGridTextColumn();
      column.field = this.fields[i];
      column.title = '';
      column.width = 85;

      this.columns.push(column);
    }
  }

  /**
   * Data initialization.
   */
  private initData() {

    this.parsedData = this.data.map(item => {
      const row = item.split('\t');
      const response = {};

      for (let i = 0; i < this.numberOfColumns; i++) {
        response[this.fields[i]] = row[i] ? row[i] : '';
      }

      return response;
    });

  }
}

export type ComponentName = 'dividends' | 'interestRates';
