/**
 * Region enum
 */
export enum Region {
  AMERICA = 'america',
  EMEA = 'emea',
  APAC = 'apac',
  JAPAN = 'japan'
}

/**
 * Region enum description
 */
export enum RegionDescripton {
  america = 'America',
  emea = 'EMEA',
  apac = 'APAC',
  japan = 'Japan'
}


