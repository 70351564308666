import { ILvScenarioTemplateResponse } from './scenario-template-reponse';
import { ScenarioInputsShift } from '../enums/scenario-inputs-shift';
import { ILvScenarioTemplateCreateRequest, ILvScenarioTemplateUpdateRequest } from './scenario-template-request';
import { DateExtensions } from '@lv-core-ui/util';

/**
 * Scenario template mapper.
 */
export class LvScenarioTemplateMapper {

  /**
   * Maps scenario template request to API.
   * @param request ILvScenarioTemplateCreateRequest or ILvScenarioTemplateUpdateRequest object.
   */
  public static mapScenarioTemplateRequestToApi(request: ILvScenarioTemplateCreateRequest | ILvScenarioTemplateUpdateRequest) {
    request.template.scenarios.forEach(a => {
      if (a.firstDimension.shift === ScenarioInputsShift.ValuationDate) {
        a.firstDimension.min = DateExtensions.getTimeWithOffset(a.firstDimension.min as Date);
        a.firstDimension.max = DateExtensions.getTimeWithOffset(a.firstDimension.max as Date);
      }

      if (a.secondDimension && a.secondDimension.shift === ScenarioInputsShift.ValuationDate) {
        a.secondDimension.min = DateExtensions.getTimeWithOffset(a.secondDimension.min as Date);
        a.secondDimension.max = DateExtensions.getTimeWithOffset(a.secondDimension.max as Date);
      }
    });
  }

  /**
   * Maps scenario template response to UI.
   * @param response ILvScenarioTemplateResponse object.
   */
  public static mapScenarioTemplateResponseToUi(response: ILvScenarioTemplateResponse) {
    response.template.scenarios.forEach(a => {
      if (a.firstDimension.shift === ScenarioInputsShift.ValuationDate) {
        a.firstDimension.min = new Date(a.firstDimension.min);
        a.firstDimension.max = new Date(a.firstDimension.max);
      }

      if (a.secondDimension && a.secondDimension.shift === ScenarioInputsShift.ValuationDate) {
        a.secondDimension.min = new Date(a.secondDimension.min);
        a.secondDimension.max = new Date(a.secondDimension.max);
      }
    });
  }
}
