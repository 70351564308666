import { Component, ChangeDetectionStrategy, ViewEncapsulation, EventEmitter, Output, HostBinding } from '@angular/core';

/**
 * Component responsible for notification settings management
 */
@Component({
  selector: 'lv-notifications-settings',
  templateUrl: './lv-notifications-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvNotificationsSettingsComponent {

  @Output() didCancel: EventEmitter<void>;
  @Output() didSave: EventEmitter<void>;

  constructor() {
    this.didCancel = new EventEmitter<void>();
    this.didSave = new EventEmitter<void>();
  }

  @HostBinding('class.lv-notifications-settings')
  get isLvNotificationsSettingsComponent() {
    return true;
  }

  @HostBinding('class.default-settings-height')
  get isDefaultSettingsHeight() {
    return true;
  }

  /**
   * Cancel editing notification settings
   */
  onCancel() {
    this.didCancel.emit();
  }

  /**
   * Save notification settings
   */
  onSave() {
    this.didSave.emit();
  }
}
