
export enum CallValueType {
	
	PerOfPar = 'PerOfPar',
	GrossYield = 'GrossYield',
	NetYield = 'NetYield',
	AccretedValue = 'AccretedValue',
	PepsMinimumRatio = 'PepsMinimumRatio',
	PepsMaximumRatio = 'PepsMaximumRatio',
	PepsVariableRatio = 'PepsVariableRatio',
	PepsThresholdDependentRatio = 'PepsThresholdDependentRatio',
	AcquisitionNotice = 'AcquisitionNotice'
}