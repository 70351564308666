import {
  AdjustmentFormulaTypeDescription,
  CashDistributionDateTypeDescription, CurrencyType,
  DividendProtectionScheduleItem,
  DividendProtectionTresholdFormulaType,
  DividendThresholdDescription,
  DividendProtectionTypeDescription,
  FrequencyDescription, MinAdjustmentTypeDescripton, QuickAndFullTermsDocument,
  SpotLevelAtAdjustmentFormulaTypeDescription,
  DividendProtectionTresholdType,
  Frequency,
  DividendProtectionTresholdTypeDescription,
  DividendProtectionThresholdValueTypeDescription
} from '@lv-convertible-bond/models';
import { DateExtensions } from '@lv-core-ui/util';
import { ExcelFieldDataTypes } from '@lv-excel/models/enums/excel-field-data-types';
import { ConvertibleTermsMapHelper } from './convertible-terms-map';
import { calculateDateFromYear, getDateValue } from './date-time-helper';
import { LvDateService } from '@lv-core-ui/services';

export class TermsDividendProtectionMapper {

  _convertibleTermsMap: ConvertibleTermsMapHelper;

  constructor(private _lvDateService: LvDateService) {
    this._convertibleTermsMap = new ConvertibleTermsMapHelper(_lvDateService);
  }

  mapDividendProtection(fieldDictionary: any, model: QuickAndFullTermsDocument) {

    fieldDictionary['DVD_PROT'] = {
      mapFn: (value: any) => {
        model.fullTerms.issueAndRedemption.useDividendProtection = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.issueAndRedemption.useDividendProtection;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['DVD_PROT_TYPE'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.type = value;
      },
      reverseMapFn: () => {
        return DividendProtectionTypeDescription[model.fullTerms.dividendProtection.type];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: DividendProtectionTypeDescription
    };

    fieldDictionary['DVD_PROT_START_DATE'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.startDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(model.fullTerms.dividendProtection.startDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    fieldDictionary['DVD_PROT_END_DATE'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.endDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(model.fullTerms.dividendProtection.endDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    fieldDictionary['DVD_PROT_CASH_DATE'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.cashDistribution.dateType = value;
      },
      reverseMapFn: () => {
        return CashDistributionDateTypeDescription[model.fullTerms.dividendProtection.cashDistribution.dateType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: CashDistributionDateTypeDescription
    };

    fieldDictionary['DVD_PROT_CASH_PCTG'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.cashDistribution.percentOfPassThrough = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.dividendProtection.cashDistribution.percentOfPassThrough;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['DVD_PROT_CASH_MAX'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.cashDistribution.maximumPayment = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.dividendProtection.cashDistribution.maximumPayment;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['DVD_PROT_CASH_CRRY_FWD'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.cashDistribution.carriedForwardValue = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.dividendProtection.cashDistribution.carriedForwardValue;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['DVD_PROT_CONV_FRML_TYPE'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.conversionRatioAdjustment.formulaType = value;
      },
      reverseMapFn: () => {
        return AdjustmentFormulaTypeDescription[model.fullTerms.dividendProtection.conversionRatioAdjustment.formulaType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: AdjustmentFormulaTypeDescription
    };

    fieldDictionary['DVD_PROT_CONV_STK_LVL'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.conversionRatioAdjustment.spotLevelAtAdjustmentFormula = value;
      },
      reverseMapFn: () => {
        // tslint:disable-next-line: max-line-length
        return SpotLevelAtAdjustmentFormulaTypeDescription[model.fullTerms.dividendProtection.conversionRatioAdjustment.spotLevelAtAdjustmentFormula];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: SpotLevelAtAdjustmentFormulaTypeDescription
    };

    fieldDictionary['DVD_PROT_CONV_CR_CAP'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.conversionRatioAdjustment.conversionRatioCap = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.dividendProtection.conversionRatioAdjustment.conversionRatioCap;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['DVD_PROT_CONV_MIN_TYPE'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.minAdjustmentType = value;
      },
      reverseMapFn: () => {
        return MinAdjustmentTypeDescripton[model.fullTerms.dividendProtection.minAdjustmentType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: MinAdjustmentTypeDescripton
    };

    fieldDictionary['DVD_PROT_CONV_MIN_THRSHLD'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.conversionRatioAdjustment.minAdjustmentTreshold = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.dividendProtection.conversionRatioAdjustment.minAdjustmentTreshold;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['DVD_PROT_CONV_TWO_WAY'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.conversionRatioAdjustment.twoWayConversionPriceAdjustment = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.dividendProtection.conversionRatioAdjustment.twoWayConversionPriceAdjustment;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['DVD_PROT_THR_TYPE'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.tresholdType = value;
      },
      reverseMapFn: () => {
        return DividendProtectionTresholdTypeDescription[model.fullTerms.dividendProtection.tresholdType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: DividendProtectionTresholdTypeDescription
    };

    fieldDictionary['DVD_PROT_THR_FRQ'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.tresholdPeriodFrequency = value;
      },
      reverseMapFn: () => {
        return FrequencyDescription[model.fullTerms.dividendProtection.tresholdPeriodFrequency];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: FrequencyDescription
    };

    fieldDictionary['DVD_PROT_THR_CCY'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.cashTresholdCurrencyType = value;
      },
      reverseMapFn: () => {
        return CurrencyType[model.fullTerms.dividendProtection.cashTresholdCurrencyType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: CurrencyType
    };

    fieldDictionary['DVD_PROT_THR_FRML_TYPE'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.tresholdFormulaType = value;
      },
      reverseMapFn: () => {
        return DividendProtectionTresholdFormulaType[model.fullTerms.dividendProtection.tresholdFormulaType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: DividendProtectionTresholdFormulaType
    };

    fieldDictionary['DVD_PROT_INCL_TRRGR'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.includeTrigger = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.dividendProtection.includeTrigger;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['DVD_PROT_THR_TRGGR_BRCH'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.isCurrentPeriodTriggerBreached = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.dividendProtection.isCurrentPeriodTriggerBreached;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['DVD_PROT_THRSHLD_ADJ'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.currentThresholdAdjustment = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.dividendProtection.currentThresholdAdjustment;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['DVD_PROT_DIV_SUM'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.previousYearDividendSum = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.dividendProtection.previousYearDividendSum;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['DVD_PROT_CARRY_FWD'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.carriedForwardValue = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.dividendProtection.carriedForwardValue;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['DVD_PROT_ADD_CARRY_FWD'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.addCarryForwardOnConversion = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.dividendProtection.addCarryForwardOnConversion;
      },
      type: ExcelFieldDataTypes.String
    };

    /*fieldDictionary['DVD_PROT_THR'] = {
      mapFn: (value: any) => {
        model.quickTerms.dividendTresholdValue = value;
      },
      reverseMapFn: () => {
        return model.quickTerms.dividendTresholdValue;
      },
      type: ExcelFieldDataTypes.Number
    };
  
    fieldDictionary['DVD_PROT_THR_VALUE_TYPE'] = {
      mapFn: (value: any) => {
        model.quickTerms.dividendTresholdType = value;
      },
      reverseMapFn: () => {
        return DividendThresholdDescription[model.quickTerms.dividendTresholdType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: DividendThresholdDescription
    };
  
    fieldDictionary['DVD_PROT_THR_GROWTH'] = {
      mapFn: (value: any) => {
        model.quickTerms.tresholdGrowth = value;
      },
      reverseMapFn: () => {
        return model.quickTerms.tresholdGrowth;
      },
      type: ExcelFieldDataTypes.Number
    };*/

    fieldDictionary['DVD_PROT_THR_SCHED_RANGE'] = {
      mapFn: (value: any) => {
        model.fullTerms.dividendProtection.schedule = value.map((el: DividendProtectionScheduleItem) => {
          el.startDate = new Date(el.startDate);
          return el;
        });
      },
      reverseMapFn: () => {
        const value = model.fullTerms.dividendProtection.schedule.map(el => ({
          startDate: getDateValue(el.startDate),
          tresholdValue: el.tresholdValue,
          triggerValue: el.triggerValue,
          tresholdValueType: DividendProtectionThresholdValueTypeDescription[el.tresholdValueType],
          tresholdValue2: el.tresholdValue2,
          triggerValue2: el.triggerValue2,
          tresholdValue2Type: DividendProtectionThresholdValueTypeDescription[el.tresholdValue2Type],
        }));
        return value;
      },
      type: ExcelFieldDataTypes.Schedule
    };
  }

  // generateDividendProtectionSchedule(model: QuickAndFullTermsDocument,
  //                                    dividendTresholdValue: number,
  //                                    dividendTresholdType: string,
  //                                    tresholdPeriodFrequency: Frequency,
  //                                    tresholdGrowth: number,
  //                                    maturity: number,
  //                                    firstSettlementDate: Date) {

  //   const lengthFrequency = this._convertibleTermsMap.mapThresholdFrequency(tresholdPeriodFrequency);
  //   const divideNumber = this._convertibleTermsMap.mapThresholdFrequencyMonths(tresholdPeriodFrequency);
  //   const length = lengthFrequency * maturity;

  //   const firstSettlementDateForDvdProt = new Date(firstSettlementDate);
    
  //   const thresholdValues = this._convertibleTermsMap.getThresholdValues(dividendTresholdValue, tresholdGrowth, length);

  //   const schedule = [];

  //   if (tresholdGrowth > 0) {
  //     for (let i = 0; i < length; i++) {

  //       schedule.push({
  //         startDate: this._convertibleTermsMap.calculateDateForMonth(i * divideNumber, firstSettlementDateForDvdProt),
  //         tresholdValue: thresholdValues[i],
  //         tresholdValueType: DividendThresholdDescription[dividendTresholdType],
  //         triggerValue: null,
  //         tresholdValue2: null,
  //         triggerValue2: null,
  //         tresholdValue2Type: DividendThresholdDescription[dividendTresholdType]
  //       });
  //     }
  //   }
  //   else {
  //     schedule.push({
  //       startDate: calculateDateFromYear(0, firstSettlementDateForDvdProt),
  //       tresholdValue: dividendTresholdValue,
  //       tresholdValueType: DividendThresholdDescription[dividendTresholdType],
  //       triggerValue: null,
  //       tresholdValue2: null,
  //       triggerValue2: null,
  //       tresholdValue2Type: DividendThresholdDescription[dividendTresholdType]
  //     });
  //   }

  //   model.fullTerms.dividendProtection.tresholdType = DividendProtectionTresholdType.FlatForPeriod;
  //   model.fullTerms.dividendProtection.schedule = schedule;
  // }

  setEndDateInSchedule(model: QuickAndFullTermsDocument) {
    const schedule = model.fullTerms.put.scheduleItems;
    if (schedule.length > 0) {
      schedule.forEach((item) => {
        item.endDate = model.fullTerms.put.puttableDuringPeriod ? item.endDate : null;
        item.trigger = item.trigger > 0 ? item.trigger : 0;
      });
    }
  }
}