import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef,
  Input, OnChanges, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ITenor } from '@lv-analytics/models';
import { IBucketListItem } from '@lv-analytics/models/company-and-user-settings/valuation-settings/bucketing/bucket-list-item';
import { IBucketingSectionSettings } from '@lv-analytics/models/company-and-user-settings/valuation-settings/bucketing/bucketing-section-settings';
import { LvAdvancedGridColumn, LvAdvancedGridComponent, LvAdvancedGridListColumn, LvAdvancedGridTextColumn } from '@lv-core-ui/components';
import { LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LvUtil } from '@lv-core-ui/util';

/**
 * Bucketing section settings component.
 */
@Component({
  selector: 'lv-bucketing-section-settings',
  templateUrl: './lv-bucketing-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvBucketingSectionSettingsComponent implements OnInit, OnChanges {

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  @Input() settings: IBucketingSectionSettings;
  @Input() overrideSystemDefaults: boolean;
  @Input() tenors: ITenor[];

  columns: LvAdvancedGridColumn[];
  
  tenorFilterDict: {[code: string]: boolean };
  tenorDict: {[code: string]: ITenor };

  private _destroyRef = inject(DestroyRef)

  constructor(
    private _errorService: LvErrorService,
    private _changeDetector: ChangeDetectorRef
  ) {
    this.overrideSystemDefaults = true;
    this.tenors = [];

    this.settings = {
      bucketList: [],
    } as IBucketingSectionSettings;
  }

  ngOnInit() {
    this.advancedGrid.didDataChange
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((records: IBucketListItem[]) => this.onDataChange(records));
    
    this.advancedGrid.doReload
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => this.onReload());

    this.advancedGrid.didError
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((error: LvError) => this.onError(error));
  }

  ngOnChanges(): void {
    this.initializeTenorsFiltering();    
    this.initColumns();
  }

  /**
   * Occurs on reload.
   */
  public onReload() {
    this.settings.bucketList = this.settings.bucketList.map(x => ({ ...x }));
  }

  /**
   * Handles error.
   * @param error LvError object.
   */
  public onError(error: LvError) {
    this._errorService.handleError(error);
  }

  /**
   * Occurs on data change.
   * @param records List of IScheduledDividend objects.
   */
  public onDataChange(records: IBucketListItem[]) {
    records.forEach((record, index) => {
      record.bucket = `Bucket ${index + 1}`;
    })

    this.applyRecords(records);
  }

  /**
   * Creates form group.
   * @param args CreateFormGroupArgs object.
   * @returns FormGroup object.
   */
  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return new FormGroup({
      'bucket': new FormControl(args.dataItem.bucket),
      'tenor': new FormControl(args.dataItem.tenor, Validators.required),
    });
  }

  /**
   * Columns initialization.
   */
  private initColumns() {
    this.columns = [];
    const bucketColumn = new LvAdvancedGridTextColumn();
    bucketColumn.title = 'Bucket';
    bucketColumn.field = 'bucket';
    bucketColumn.width = 100;
    bucketColumn.editable = false;
    bucketColumn.alignment = 'right';
    bucketColumn.dmKey = 'DM-4561';
    bucketColumn.isOutputColumn = true;

    const tenorColumn = new LvAdvancedGridListColumn();
    tenorColumn.title = 'Tenor';
    tenorColumn.field = 'tenor';
    tenorColumn.width = 100;
    tenorColumn.editable = true;
    tenorColumn.dmKey = 'DM-4562';
    tenorColumn.displayField = 'name';
    tenorColumn.valueField = 'code';
    tenorColumn.alignment = 'right';
    tenorColumn.data = this.tenors ?? [];
    tenorColumn.useDefaultItem = true;
    tenorColumn.setFilterFn((tenor: ITenor) => {
      if (this.tenorFilterDict) {

        if (tenor.code === '1Y' && this.tenorFilterDict['12M']) {
          return false;
        }

        if (tenor.code === '12M' && this.tenorFilterDict['1Y']) {
          return false;
        }

        return !this.tenorFilterDict[tenor.code];
      }

      return false;
    });

    this.columns.push(bucketColumn);
    this.columns.push(tenorColumn);
  }

  /**
   * Applies records.
   * @param records List of records.
   */
  private applyRecords(records: any[]) {
    this.settings.bucketList.splice(0, this.settings.bucketList.length);
    this.settings.bucketList.push(...records);
    this.setTenorFilterDict(records);

    this.initColumns();
    this._changeDetector.detectChanges();
  }

  /**
   * Initialize tenors filtering.
   */
  private initializeTenorsFiltering() {
    if (this.tenors.length > 0) {
      this.tenorDict = LvUtil.toDictionary(this.tenors, 'code');
      this.setTenorFilterDict(this.settings.bucketList);
    }
  }

  /**
   * Sets tenor filter dict.
   * @param records List of ITermStructureItem objects.
   */
  private setTenorFilterDict(records: IBucketListItem[]) {
    this.tenorFilterDict = {};
    records.forEach(a => this.tenorFilterDict[a.tenor] = true);
  }
  
    /**
   * Converts tenor code to number.
   * @param tenorCode Tenor code.
   * @returns Tenor code converted to number.
   */
    public convertTenorToNumber(tenorCode: any): number {
      const factor = tenorCode[tenorCode.length - 1];
      const value = Number.parseInt(tenorCode.slice(0, -1), 10);
  
      switch (factor) {
        case 'D':
        return value * 1;
        case 'W':
        return value * 100;
        case 'M':
        return value * 1000;
        case 'Y':
        return value * 12000;
      }
  
      return 0;
    }
}
