<lv-flex-box class="lv-save-terms-detailed-modal">
    <lv-flex-box class="lv-save-terms-detailed-modal-input" direction="row">
      <div class="lv-label lv-label-field">
        <span>
          Instrument Name
        </span>
      </div>
      <div class=" lv-input-field lv-input-field--full">
        <lv-text-box #input
          class="lv-save-terms-detailed-modal-instrument"
          [(ngModel)]="customTermsDetailsData.instrumentName"
          name="instrument-name-general">
        </lv-text-box>
      </div>
    </lv-flex-box>
    <lv-flex-box class="accessScope-section">
      <lv-flex-box direction="row">
        <div class="lv-input-field lv-field lv-field--radio">
          <input class="k-radio"
            type="radio"
            [attr.id]="useUserRadioButtonId"
            name="userAccessScopeTermsDetails"
            [(ngModel)]="customTermsDetailsData.accessScope"
            value="User">
          <label class="k-radio-label"
            [attr.for]="useUserRadioButtonId"
            lvId="lv-use-private-terms-detailed">
              Private (available only to you)
          </label>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row">
        <div class="lv-input-field lv-field lv-field--radio">
          <input class="k-radio"
            type="radio"
            [attr.id]="useCompanyRadioButtonId"
            name="companyAccessScopeTermsDetails"
            [(ngModel)]="customTermsDetailsData.accessScope"
            value="Company">
          <label class="k-radio-label"
            [attr.for]="useCompanyRadioButtonId"
            lvId="lv-use-company-terms-detailed">
              Shared Internally (available to your team)
          </label>
        </div>
      </lv-flex-box>
    </lv-flex-box>
    <lv-flex-box class="lv-save-terms-detailed-modal-footer" direction="row">
        <button kendoButton
          [primary]="true"
          lvId="convertibleValuationOutputsCancel"
          (click)="onCancel()">
            Cancel
        </button>
        <button kendoButton
          [primary]="true"
          lvId="convertibleValuationOutputsSave"
          (click)="onSave()">
            Save
        </button>
      </lv-flex-box>
</lv-flex-box>