<kendo-chart *ngIf="isVisible"
  class="lv-scenario-chart"
  [zoomable]="true"
  [pannable]="true">
  <kendo-chart-legend position="bottom"></kendo-chart-legend>
  <kendo-chart-tooltip
    [background]="view.defaultTooltipBackground"
    [color]="view.defaultTooltipColor">
  </kendo-chart-tooltip>

  <kendo-chart-x-axis>
    <kendo-chart-x-axis-item
      [title]="view.model.xAxis.title"
      [labels]="view.model.xAxis.labels"
      [min]="view.model.xAxis.minOffset"
      [max]="view.model.xAxis.maxOffset">
    </kendo-chart-x-axis-item>
  </kendo-chart-x-axis>

  <kendo-chart-y-axis>
    <kendo-chart-y-axis-item
    [title]="view.model.yAxis.title"
    [labels]="view.model.yAxis.labels"
    [min]="view.model.yAxis.minOffset"
    [max]="view.model.yAxis.maxOffset">
    </kendo-chart-y-axis-item>
  </kendo-chart-y-axis>

  <kendo-chart-series>
    <kendo-chart-series-item *ngFor="let s of view.model.series"
      [data]="view.model.records"
      [type]="s.type"
      [name]="s.name"
      [xField]="s.xField"
      [yField]="s.yField"
      [tooltip]="s.tooltip">
    </kendo-chart-series-item>
  </kendo-chart-series>
</kendo-chart>