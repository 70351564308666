import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation,
  ChangeDetectorRef, ViewChild, EventEmitter, Output, ElementRef } from '@angular/core';

import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { LvErrorService, LvPermissionService, LvTimeZoneService } from '@lv-core-ui/services';
import { LvLocalization, LocalStorage } from '@lv-core-ui/util';
import { IUserSettingsDocument, EventType, ILWSEvent, EventFrequency, IUserSettings,
         IUpdateUserSettingsRequest} from '@lv-notifications/models';
import { UserSettingsService } from '@lv-notifications/services';
import { AuthorizationService } from '@lv-core-ui/services/authorization/authorization.service';

export const getDefaultUserSettingsData = (userSettings?: IUserSettingsDocument): IUserSettingsDocument => {
  const dateFormat = LvLocalization.getLocalDateFormat();

  if (userSettings) {
    userSettings.triggerBulkAt = new Date(userSettings.triggerBulkAt);
    userSettings.dateFormat = dateFormat;
    return userSettings;
  }

  const settings = {} as IUserSettingsDocument;
  settings.dateFormat = dateFormat;
  settings.events = [];

  // tslint:disable-next-line:forin
  for (const type in EventType) {
    const event = {} as ILWSEvent;
    event.frequency = EventFrequency.Single;
    event.regions = {
      america : false,
      emea : false,
      apac : false,
      japan : false
    };
    event.type = type as EventType;
    settings.events.push(event);
  }
  return settings;
};

/**
 * Component responsible for mail notifications settings
 */
@Component({
  selector: 'lv-mail-notifications-settings',
  templateUrl: './lv-mail-notifications-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvMailNotificationsSettingsComponent implements OnInit {
  @ViewChild('timeZoneItemList', { static: true }) timeZoneItemList;

  @Output() didCancel: EventEmitter<void>;
  @Output() didSave: EventEmitter<void>;

  isLoading: boolean;
  userSettings: IUserSettings;
  userSettingsData: IUserSettingsDocument;
  listOfTimezones: any[];
  filterSettings: DropDownFilterSettings;
  regionPermisions: string[];
  canReceiveAllNotifications: boolean;
  canReceiveNewIssueNotifications: boolean;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _userSettingsService: UserSettingsService,
    private _errorService: LvErrorService,
    private _permissionsService: LvPermissionService,
    private _timeZonesService: LvTimeZoneService,
    private _authorizationService: AuthorizationService
  ) {
    this.isLoading = false;
    this.userSettingsData = getDefaultUserSettingsData();
    this.listOfTimezones = [];
    this.didCancel = new EventEmitter<void>();
    this.didSave = new EventEmitter<void>();
    this.filterSettings = {
      caseSensitive: false,
      operator: 'contains'
    };
    this.canReceiveAllNotifications = false;
    this.canReceiveNewIssueNotifications = false;
    this.regionPermisions = [];
   }

  /**
   * Ng on init method
   */
  async ngOnInit() {
    try {
      this.isLoading = true;
      this._changeDetectorRef.detectChanges();
      this.listOfTimezones = this._timeZonesService.getTimeZones();

      const jwtUser = LocalStorage.getJwtUser();
      const dateFormat = LvLocalization.getLocalDateFormat();

      this.userSettings = await this._userSettingsService.getUserSettings(jwtUser.Name, jwtUser.Username, dateFormat);
      this.userSettingsData = getDefaultUserSettingsData(this.userSettings.document);
      this.userSettingsData.timezone = this._timeZonesService.getTimeZoneKey(this.userSettingsData.timezone);

      this.regionPermisions = await this._authorizationService.checkRegionPermission('NOTIFICATIONS', 'RECEIVE_NOTIFICATIONS');
      this.regionPermisions =  JSON.parse(JSON.stringify(this.regionPermisions));

      this.canReceiveAllNotifications = this._permissionsService.hasPermission('NOTIFICATIONS', 'RECEIVE_ALL_NOTIFICATIONS');
      this.canReceiveNewIssueNotifications = this._permissionsService.hasPermission('NOTIFICATIONS', 'RECEIVE_NEW_ISSUE_NOTIFICATIONS');
    }
    catch (e) {
      this._errorService.handleError(e);
    }
    finally {
      this.isLoading = false;
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Save notifications settings
   */
  async onSave() {
    try {
      await this._userSettingsService.updateUserSettings(this.getUpdateRequest());
      this._errorService.toastrService.success('Settings successfully updated');

      this.didSave.emit();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Cancel modifing notifications settings
   */
  onCancel() {
    this.didCancel.emit();
  }

  /**
   * Is event visible on user interface
   * @param lwsEvent Lws event
   * @returns Flag that describes if event is visible
   */
  eventVisible(lwsEvent: ILWSEvent): boolean {
    if (lwsEvent.type === EventType.NewIssueFixed || lwsEvent.type === EventType.NewIssueSetup
      || lwsEvent.type === EventType.NewIssueSetupCorrected) {
      return true;
    }

    return this.canReceiveAllNotifications || this.regionPermisions?.length > 0;
  }

  /**
   * Is event group visible on user interface
   * @param lwsEvent Lws event
   * @returns Flag that describes if event group is visible
   */
  eventGroupVisible(lwsEvent: ILWSEvent) {
    if (lwsEvent.type === EventType.NewIssueSetup || lwsEvent.type === EventType.ConversionRatioEstimate
      || lwsEvent.type === EventType.InstrumentStatusChanged) {
      return true;
    }

    return false;
  }

  /**
   * Create lws event group label
   * @param lwsEvent Lws event type
   * @returns Lws event group label
   */
  getEventGroupLabel(lwsEvent: ILWSEvent) {
    if (lwsEvent.type === EventType.NewIssueSetup) {
      return 'NEW ISSUE';
    }
    else if (lwsEvent.type === EventType.ConversionRatioEstimate) {
      return 'CORPORATE ACTION ADJUSTMENT';
    }
    else {
      return 'OTHER';
    }
  }

  /**
   * Create unique notificatins settings tooltip identifier
   * @param element Html element for tooltip area
   * @param sectionId Section identifier
   * @returns Unique string for tooltip identifier
   */
  getMailNotificationsSettingsTooltipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

  /**
   * Reset settings to initial data settings
   */
  async onResetToDefault() {
    try {
      this.listOfTimezones = this._timeZonesService.getTimeZones();
      const jwtUser = LocalStorage.getJwtUser();
      const dateFormat = LvLocalization.getLocalDateFormat();

      this.userSettings = await this._userSettingsService.resetUserSettings(jwtUser.Name, jwtUser.Username, dateFormat);
      this.userSettingsData = getDefaultUserSettingsData(this.userSettings.document);
      this.userSettingsData.timezone = this._timeZonesService.getTimeZoneKey(this.userSettingsData.timezone);

      this._errorService.toastrService.success('Notifications successfully reseted to default');
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.isLoading = false;
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Create update settings request
   * @returns Formated update settings request
   */
  private getUpdateRequest(): IUpdateUserSettingsRequest {
    return {
      id: this.userSettings.id,
      firstName: this.userSettings.firstName,
      lastName: this.userSettings.lastName,
      email: this.userSettings.email,
      document: {
        ...this.userSettingsData
      }
    } as IUpdateUserSettingsRequest;
  }
}
