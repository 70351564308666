/**
 * Event frequency type enum
 */
export enum EventFrequency {
  Single = 'Single',
  Bulk = 'Bulk',
  SingleAndBulk = 'SingleAndBulk'
}

/**
 * Event freqency type description enum
 */
export enum EventFrequencyDescription {
  Single = 'Single',
  Bulk = 'Bulk',
  SingleAndBulk = 'Single & Bulk'
}
