import { Injectable } from '@angular/core';
import { Route, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorage } from '@lv-core-ui/util';
import { SecurityService } from '@lv-security/security.service';


@Injectable()
export class AuthGuard   {

  constructor(
    private _service: SecurityService
  ) { }

  canLoad(route: Route): Promise<boolean> {
    return this.checkAuthentication();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.checkAuthentication();
  }

  async checkAuthentication(): Promise<boolean> {
    try {
      await this._service.tokenService.refreshToken();
      return true;
    }
    catch (error) {
      LocalStorage.clear();
      return false;
    }
  }
}
