<lv-flex-box class="make-whole-settings-column">

  <lv-flex-box 
    data-cy="DM-1584"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="callMakeWholeTooltipTemplate"
      filter="span">
      <span> Call Make Whole </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        [disabled]="!overrideDefaults"
        [id]="callMakeWholeCheckBoxId"
        name="callMakeWhole"
        [(ngModel)]="settings.settings.callMakeWhole"/>
      <label class="k-checkbox-label" lvId="callMakeWholeCheckBox" [attr.for]="callMakeWholeCheckBoxId"></label>
    </div>
  </lv-flex-box>
  
  <lv-flex-box 
    data-cy="DM-1585"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionMakeWholeTooltipTemplate"
      filter="span">
      <span> Conversion Make Whole </span>
    </div>
    <div class="lv-field lv-field--checkbox">
    <input class="k-checkbox"
      type="checkbox"
      [disabled]="!overrideDefaults"
      [id]="conversionMakeWholeCheckBoxId"
      name="conversionMakeWhole"
      [(ngModel)]="settings.settings.conversionMakeWhole"/>
      <label class="k-checkbox-label" lvId="conversionMakeWholeCheckBox" [attr.for]="conversionMakeWholeCheckBoxId"></label>
    </div>
  </lv-flex-box>
  
  <lv-flex-box 
    data-cy="DM-1586"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="startDateTooltipTemplate"
      filter="span">
      <span> Start Date </span>
    </div>
    <div class="lv-input-field align-sufix">
      <lv-numeric-text-box
        placeholder="T + days"
        class="lv-input-field"
        [disabled]="!overrideDefaults"
        [(ngModel)]="settings.settings.startDate"
        [format]="formatZero"
        [decimals]="decimalsZero"
        name="makeWholeStartDate">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">From First Settlement Date</span>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1587"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="endDateTooltipTemplate"
      filter="span">
      <span> End Date </span>
    </div>
    <div class="lv-input-field align-sufix">
      <lv-numeric-text-box
        placeholder="T - days"
        class="lv-input-field"
        [disabled]="!overrideDefaults"
        [(ngModel)]="settings.settings.endDate"
        [format]="formatZero"
        [decimals]="decimalsZero"
        name="makeWholeEndDate">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">From Maturity Date</span>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1588"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="makeWholeTypeTooltipTemplate"
      filter="span">
      <span> Make Whole Type </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        class="lv-make-whole-type"
        name="makeWholeType"
        [data]="makeWholeTypeLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [popupSettings]="{ width: 160 }"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.type">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1589"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="discountRateTypeTooltipTemplate"
      filter="span">
      <span> Discount Rate Type </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="discountRateType"
        [data]="discountRateTypeLookup.items"
        valueField="id"
        textField="text"
        [disabled]="!overrideDefaults"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.discountRateType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1590"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="offerValueTypeRatchetTooltipTemplate"
      filter="span">
      <span> Offer Value Type </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="offerValueTypeCall"
        [data]="offerValueTypeLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.ratchetMatrixOfferValueType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1591"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="compensationValueTypeRatchetTooltipTemplate"
      filter="span">
      <span> Compensation Value Type </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="compensationValueTypeConversion"
        [data]="compensationValueTypeLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 188 }"
        [(ngModel)]="settings.settings.ratchetMatrixCompensationValueType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
</lv-flex-box>

<ng-template #callMakeWholeTooltipTemplate>
  <lv-tooltip dm="dM-1584"></lv-tooltip>
</ng-template>
<ng-template #conversionMakeWholeTooltipTemplate>
  <lv-tooltip dm="dM-1585"></lv-tooltip>
</ng-template>
<ng-template #startDateTooltipTemplate>
  <lv-tooltip dm="dM-1586"></lv-tooltip>
</ng-template>
<ng-template #startDateTooltipTemplate>
  <lv-tooltip dm="dM-1586"></lv-tooltip>
</ng-template>
<ng-template #endDateTooltipTemplate>
  <lv-tooltip dm="dM-1587"></lv-tooltip>
</ng-template>
<ng-template #makeWholeTypeTooltipTemplate>
  <lv-tooltip dm="dM-1588"></lv-tooltip>
</ng-template>
<ng-template #discountRateTypeTooltipTemplate>
  <lv-tooltip dm="dM-1589"></lv-tooltip>
</ng-template>
<ng-template #offerValueTypeRatchetTooltipTemplate>
  <lv-tooltip dm="dM-1590"></lv-tooltip>
</ng-template>
<ng-template #compensationValueTypeRatchetTooltipTemplate>
  <lv-tooltip dm="dM-1591"></lv-tooltip>
</ng-template>
