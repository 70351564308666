<lv-flex-box class="dividend-protection-settings-column dividend-protection-settings-column--left">
  
  <lv-flex-box 
    data-cy="DM-1601"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="dividendProtectionTooltipTemplate"
      filter="span">
      <span> Dividend Protection </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input
        type="checkbox"
        [disabled]="!overrideDefaults"
        class="k-checkbox"
        [attr.id]="useDividendProtectionId"
        [(ngModel)]="settings.settings.useDividendProtection"/>
      <label lvId="lvDividendProtectionCheckbox" class="k-checkbox-label" [attr.for]="useDividendProtectionId"></label>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1602"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="dividendProtectionTypeTooltipTemplate"
      filter="span">
      <span> Dividend Protection Type </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        lvId="lvDividendProtectionType"
        [data]="protectionTypeLookup.items"
        valueField="id"
        textField="text"
        [disabled]="!overrideDefaults"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 193 }"
        [(ngModel)]="settings.settings.type">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1603"
    direction="row">

    <div class="lv-label lv-label-field lv-label-before-checkbox"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="protectionStartDateTooltipTemplate"
      filter="span">
      <span> Protection Start Date </span>
    </div>

    <div class="lv-input-field align-sufix">
      <lv-numeric-text-box
        placeholder="T + days"
        class="lv-input-field"
        [disabled]="!overrideDefaults"
        [(ngModel)]="settings.settings.startDate"
        [format]="formatZero"
        [decimals]="decimalsZero"
        name="dividendProtectionStartDate">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">From First Settlement Date</span>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1604"
    class="space-bottom"
    direction="row">

    <div class="lv-label lv-label-field lv-label-before-checkbox"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="protectionEndDateTooltipTemplate"
      filter="span">
      <span> Protection End Date </span>
    </div>
    <div class="lv-input-field align-sufix">
      <lv-numeric-text-box
        placeholder="T - days"
        [disabled]="!overrideDefaults"
        class="lv-input-field"
        [(ngModel)]="settings.settings.endDate"
        [format]="formatZero"
        [decimals]="decimalsZero"
        name="dividendProtectionEndDate">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">From Maturity Date</span>
    </div>
  </lv-flex-box>

  <div class="lv-label-field lv-label lv-label--title">CONVERSION RATIO ADJUSTMENT</div>

  <lv-flex-box 
    data-cy="DM-1605"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="adjustmentFormulaTypeTooltipTemplate"
      filter="span">
      <span> Adjustment Formula Type </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
      [data]="adjustmentFormulaTypeLookup.items"
      lvId="dvdPtAdjustmentFormulaType"
      valueField="id"
      textField="text"
      [disabled]="!overrideDefaults"
      [valuePrimitive]="true"
      [popupSettings]="{ width: 284 }"
      [(ngModel)]="settings.settings.conversionRatioAdjustment.formulaType">
    </kendo-dropdownlist>
  </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1606"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="spotLevelAtAdjustmentFormulaTooltipTemplate"
      filter="span">
      <span> Spot Level at Adjustment Formula </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        lvId="divProtectionSpotLevelFormula"
        [data]="spotLevelAtAdjustmentFormulaLookup.items"
        valueField="id"
        textField="text"
        [disabled]="!overrideDefaults"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 167 }"
        [(ngModel)]="settings.settings.conversionRatioAdjustment.spotLevelAtAdjustmentFormula">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1607"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="minimumAdjustmentTypeTooltipTemplate"
      filter="span">
      <span> Minimum Adjustment Type </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        [data]="minimumAdjustmentType.items"
        valueField="id"
        textField="text"
        [disabled]="!overrideDefaults"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 127 }"
        [(ngModel)]="settings.settings.minAdjustmentType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1608"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="twoWayConversionPriceAdjustmentTooltipTemplate"
      filter="span">
      <span> Two-Way Conversion Price Adjustment </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input
        type="checkbox"
        [disabled]="!overrideDefaults"
        class="k-checkbox"
        [attr.id]="twoWayConversionPriceAdjustmentId"
        [(ngModel)]="settings.settings.conversionRatioAdjustment.twoWayConversionPriceAdjustment"/>
      <label class="k-checkbox-label" [attr.for]="twoWayConversionPriceAdjustmentId"></label>
    </div>
  </lv-flex-box>
</lv-flex-box>

<lv-flex-box class="dividend-protection-settings-column">
  <div class="lv-label-field lv-label lv-label--title">THRESHOLD / TRIGGER PARAMETERS </div>

  <lv-flex-box 
    data-cy="DM-1609"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="thresholdTypeTooltipTemplate"
      filter="span">
      <span> Threshold Type </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        lvId="divProtectionThresholdType"
        [data]="thresholdTypeLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 115 }"
        [(ngModel)]="settings.settings.tresholdType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1610"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="thresholdPeriodFrequencyTooltipTemplate"
      filter="span">
      <span> Threshold Period Freq. </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        lvId="divProtectionThresholdPeriodFrequency"
        [data]="thresholdPeriodFrequencyLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.tresholdPeriodFrequency">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1611"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="cashThresholdCurrencyTooltipTemplate"
      filter="span">
      <span> Cash Threshold Currency </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        [data]="cashThresholdCurrencyTypeLookup.items"
        valueField="id"
        textField="text"
        [disabled]="!overrideDefaults"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.cashTresholdCurrencyType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1612"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="thresholdFormulaTypeTooltipTemplate"
      filter="span">
      <span> Threshold Formula Type </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        [data]="thresholdFormulaTypeLookup.items"
        valueField="id"
        textField="text"
        [disabled]="!overrideDefaults"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.tresholdFormulaType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1613"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="includeTriggerTooltipTemplate"
      filter="span">
      <span> Include Trigger </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input
        type="checkbox"
        class="k-checkbox"
        [disabled]="!overrideDefaults"
        [attr.id]="includeTriggerId"
        [(ngModel)]="settings.settings.includeTrigger" />
      <label
        class="k-checkbox-label"
        lvId="includeTriggerDvdProtection"
        [attr.for]="includeTriggerId">
      </label>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1614"
    class="space-bottom"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="addCarryForwardOnConversionTooltipTemplate"
      filter="span">
      <span> Add CF on Conversion </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input
        type="checkbox"
        [disabled]="!overrideDefaults"
        class="k-checkbox"
        [attr.id]="addCarryForwardOnConversionId"
        [(ngModel)]="settings.settings.addCarryForwardOnConversion"/>
      <label class="k-checkbox-label" [attr.for]="addCarryForwardOnConversionId"></label>
    </div>
  </lv-flex-box>

  <div class="lv-label-field lv-label lv-label--title">CASH DISTRIBUTION</div>

  <lv-flex-box 
    data-cy="DM-1615"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="cashDistributionDateTooltipTemplate"
      filter="span">
      <span> Cash Distribution Date </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        [data]="cashDistributionDateTypeLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 147 }"
        [(ngModel)]="settings.settings.cashDistribution.dateType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1616"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="percentageOfPassThroughTooltipTemplate"
      filter="span">
      <span> % of Pass Through </span>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="percentOfPassThrough"
        [format]="formatFour"
        [disabled]="!overrideDefaults"
        [decimals]="decimalsFour"
        [(ngModel)]="settings.settings.cashDistribution.percentOfPassThrough">
      </lv-numeric-text-box>
    </div>
  </lv-flex-box>
</lv-flex-box>

<ng-template #dividendProtectionTooltipTemplate>
  <lv-tooltip dm="dM-1601"></lv-tooltip>
</ng-template>
<ng-template #dividendProtectionTypeTooltipTemplate>
  <lv-tooltip dm="dM-1602"></lv-tooltip>
</ng-template>
<ng-template #protectionStartDateTooltipTemplate>
  <lv-tooltip dm="dM-1603"></lv-tooltip>
</ng-template>
<ng-template #protectionEndDateTooltipTemplate>
  <lv-tooltip dm="dM-1604"></lv-tooltip>
</ng-template>
<ng-template #protectionEndDateTooltipTemplate>
  <lv-tooltip dm="dM-1604"></lv-tooltip>
</ng-template>
<ng-template #adjustmentFormulaTypeTooltipTemplate>
  <lv-tooltip dm="dM-1605"></lv-tooltip>
</ng-template>
<ng-template #spotLevelAtAdjustmentFormulaTooltipTemplate>
  <lv-tooltip dm="dM-1606"></lv-tooltip>
</ng-template>
<ng-template #minimumAdjustmentTypeTooltipTemplate>
  <lv-tooltip dm="dM-1607"></lv-tooltip>
</ng-template>
<ng-template #twoWayConversionPriceAdjustmentTooltipTemplate>
  <lv-tooltip dm="dM-1608"></lv-tooltip>
</ng-template>
<ng-template #thresholdTypeTooltipTemplate>
  <lv-tooltip dm="dM-1609"></lv-tooltip>
</ng-template>
<ng-template #thresholdPeriodFrequencyTooltipTemplate>
  <lv-tooltip dm="dM-1610"></lv-tooltip>
</ng-template>
<ng-template #cashThresholdCurrencyTooltipTemplate>
  <lv-tooltip dm="dM-1611"></lv-tooltip>
</ng-template>
<ng-template #thresholdFormulaTypeTooltipTemplate>
  <lv-tooltip dm="dM-1612"></lv-tooltip>
</ng-template>
<ng-template #includeTriggerTooltipTemplate>
  <lv-tooltip dm="dM-1613"></lv-tooltip>
</ng-template>
<ng-template #addCarryForwardOnConversionTooltipTemplate>
  <lv-tooltip dm="dM-1614"></lv-tooltip>
</ng-template>
<ng-template #cashDistributionDateTooltipTemplate>
  <lv-tooltip dm="dM-1615"></lv-tooltip>
</ng-template>
<ng-template #percentageOfPassThroughTooltipTemplate>
  <lv-tooltip dm="dM-1616"></lv-tooltip>
</ng-template>
