import { LvScenarioChartView, ILvScatterLineChart, ILvScatterLineChartSeries, ILvAxisTitle } from '../lv-scenario-chart.view';
import { ILvScenarioReport } from '../../lv-scenario.view';
import { ScenarioOutput, ScenarioPriceTalk, ICalculateScenarioOutputVector } from '@lv-analytics/models';

export interface ITwoDimPriceTalkChartSeries extends ILvScatterLineChartSeries {
  output: ScenarioOutput;
  priceTalk: ScenarioPriceTalk;
}

export interface ITwoDimPriceTalkChart extends ILvScatterLineChart {
  outputs: ScenarioOutput[];
  currentOutput: ScenarioOutput;
  priceTalks: ScenarioPriceTalk[];
  currentPriceTalk: ScenarioPriceTalk;
  secondDimensionTitle: ILvAxisTitle;
  series: ITwoDimPriceTalkChartSeries[];
}

/**
 * Scenario two dim price talk chart view.
 */
export class LvScenarioTwoDimPriceTalkChartView extends LvScenarioChartView<ITwoDimPriceTalkChart> {

  /**
   * Gets default model.
   * @returns ITwoDimPriceTalkChart object.
   */
  getDefaultModel(): ITwoDimPriceTalkChart {
    return {
      yAxis: {},
      xAxis: {},
      secondDimensionTitle: {} as ILvAxisTitle,
      series: [],
      records: [],
      outputs: [],
      currentOutput: null,
      priceTalks: [],
      currentPriceTalk: null
    };
  }

  /**
   * Does initialization.
   * @param report ILvScenarioReport object.
   */
  doInit(report: ILvScenarioReport): void {
    const mapped = (report.data as ICalculateScenarioOutputVector[]);

    this.model.yAxis = this.getYAxis();

    mapped.forEach((fDim, i, fDimArray) => {
      if (this.isFirstIndex(i)) {
        this.model.xAxis = this.getXAxis(fDim.dimension);
        this.model.xAxis.min = fDim.value;
      }

      const rec = {
        [fDim.dimension]: fDim.value
      };

      fDim.outputs.forEach((sDim, j, arr) => {
        if (this.areFirstIndexes(i, j)) {
          this.model.secondDimensionTitle = this.getSecondDimensionTitle(sDim.dimension);
        }

        sDim.outputs.forEach((output, k) => {
          if (this.areFirstIndexes(i, j)) {
            this.model.outputs.push(output.output);
          }

          if (this.areFirstIndexes(i, j, k)) {
            this.model.currentOutput = output.output;
            this.setMultiValueYAxisCurrentValue(this.getAxisValue());
          }

          output.values.forEach((value, h) => {
            const valueId = `${sDim.value}_${output.output}_${value.priceTalk}`;

            if (this.isFirstIndex(i)) {
              const series = this.getSeries<ITwoDimPriceTalkChartSeries>(sDim, output);

              if (this.isValuationDateShift(sDim.dimension)) {
                series.name = this.datePipe.transform(sDim.value);
              }
              else {
                series.name = this.numberPipe.transform(sDim.value, this.numberFormat);
              }

              series.xField = fDim.dimension;
              series.yField = valueId;
              series.output = output.output;
              series.priceTalk = value.priceTalk;
              this.model.series.push(series);
            }

            if (this.areFirstIndexes(i, j, k)) {
              this.model.priceTalks.push(value.priceTalk);
            }

            if (this.areFirstIndexes(i, j, k, h)) {
              this.model.currentPriceTalk = value.priceTalk;
              this.setMultiValueYAxisCurrentValue(this.getAxisValue());
            }

            rec[valueId] = value.value;

            this.setMultiValueYAxisMinAndMax(`${output.output}_${value.priceTalk}`, value.value);
          });
        });
      });

      if (this.isLastIndex(fDimArray, i)) {
        this.model.xAxis.max = fDim.value;
      }

      this.model.records.push(rec);
    });
  }

  /**
   * Gets axis value.
   * @returns Axis value.
   */
  getAxisValue(): string {
    return `${this.model.currentOutput}_${this.model.currentPriceTalk}`;
  }

  /**
   * Checks if output is visible.
   * @param selected Scenario output selected value.
   * @returns A flag indicating if output is visible.
   */
  isOutputVisible(selected: ScenarioOutput) {
    return this.model.currentOutput === selected;
  }

  /**
   * Checks if price talk is visible.
   * @param selected Scenario price talk selected value.
   * @returns A flag indicating if price talk is visible.
   */
  isPriceTalkVisible(selected: ScenarioPriceTalk) {
    return this.model.currentPriceTalk === selected;
  }
}
