import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
  Output, EventEmitter, ChangeDetectorRef, HostBinding, ElementRef } from '@angular/core';
import { INewIssueSettings, INewIssueSectionSettings, ISaveNewIssueSettingsRequest,
         INewIssueEnvironmentSidePanelSettings } from '@lv-analytics/models';
import { CompanyAndUserSettingsService } from '@lv-analytics/services';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';

/**
 * New issue settings component.
 */
@Component({
  selector: 'lv-new-issue-settings',
  templateUrl: './lv-new-issue-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvNewIssueSettingsComponent implements OnInit {

  @Output() didCancel: EventEmitter<void>;
  @Output() didSave: EventEmitter<void>;

  settings: INewIssueSettings;

  constructor(
    private _companyAndUserSettingsService: CompanyAndUserSettingsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService
  ) {
    this.didCancel = new EventEmitter<void>();
    this.didSave = new EventEmitter<void>();

    this.settings = {
      systemDefaults: {} as INewIssueSectionSettings,
      environments: []
    } as INewIssueSettings;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-new-issue-settings')
  get isLvNewIssueSettingsComponent() {
    return true;
  }

  @HostBinding('class.default-settings-height')
  get isDefaultSettingsHeight() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.getSettings();
  }

  /**
   * Gets settings.
   */
  async getSettings() {
    try {
      this.settings = await this._companyAndUserSettingsService.getNewIssueSettings();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on save and saves new issue settings.
   */
  async onSave() {
    try {
      await this._companyAndUserSettingsService.saveNewIssueSettings(this.getRequest());

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1814'));
      this.didSave.emit();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
    this.didCancel.emit();
   }

    /**
     * Retrieves element's id
     * @param element reference to given element
     * @param id element's id
     * @returns falg- true if element with given id is found
     */
    getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
      return element.nativeElement.getAttribute('data-tooltip-id') === id;
    }

   /**
    * Occurs on Reset to defaults and reset new issue settings.
    */
  async onResetToDefault() {
    try {
      this.settings = await this._companyAndUserSettingsService.resetNewIssueSettings();
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1816',
        {'settings_section': 'New Issue Settings'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Gets save new issue settings request.
   * @returns ISaveNewIssueSettingsRequest object.
   */
  private getRequest(): ISaveNewIssueSettingsRequest {
    const request = {
      systemDefaults: {} as INewIssueEnvironmentSidePanelSettings,
      environments: []
    } as ISaveNewIssueSettingsRequest;

    request.systemDefaults.newIssueSetingsAssumptions = this.settings.systemDefaults.newIssueSetingsAssumptions;
    this.settings.environments.forEach(item => {
      const env = {
        environmentId: item.environmentId,
        environmentName: item.environmentName,
        overrideSystemDefaults: item.overrideSystemDefaults,
        newIssueSetingsAssumptions: item.settings.newIssueSetingsAssumptions

      } as INewIssueEnvironmentSidePanelSettings;

      request.environments.push(env);
    });

    return request;
  }
}
