import { Component, OnInit, OnChanges, Input, Output, EventEmitter,
         ViewEncapsulation, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { TakeoverProtection, PremiumCompensationFormulaTypeDescription, PremiumCompensationFormulaType, AveragingPeriodCalendarType } from '@lv-convertible-bond/models';
import { LvLookupEnum } from '@lv-core-ui/util';

@Component({
  selector: 'lv-premium-compensation-formula',
  templateUrl: './lv-premium-compensation-formula.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvPremiumCompensationFormulaComponent implements OnInit, OnChanges {
  @Input() model: TakeoverProtection;
  @Output() didSetPremiumCompensationFormula: EventEmitter<TakeoverProtection>;

  premiumCompensationFormulaTypeLookup: LvLookupEnum;
  isPremiumCompensationInitialPremiumPercentVisible: boolean;
  isPremiumCompensationReferencePriceCBCCYVisible: boolean;
  isPremiumCompensationInitialParityCBCCYVisible: boolean;
  isDaysBeforeMaturityVisible: boolean;
  averagingPeriodCalendarTypeLookup: LvLookupEnum;

  numberOfDecimals = '4';
  numberFormat = '#,###.####';

  constructor() {
    this.didSetPremiumCompensationFormula = new EventEmitter<TakeoverProtection>();
    this.premiumCompensationFormulaTypeLookup = new LvLookupEnum(PremiumCompensationFormulaTypeDescription);    
    this.averagingPeriodCalendarTypeLookup = new LvLookupEnum(AveragingPeriodCalendarType);
  }

  ngOnInit() {
    // tslint:disable-next-line:max-line-length
    this.model.premiumCompensationDaysBeforeMaturityCCalendar = this.model.premiumCompensationDaysBeforeMaturityCCalendar ?? AveragingPeriodCalendarType.Business;
    // tslint:disable-next-line:max-line-length
    this.model.premiumCompensationDaysBeforeMaturityTCalendar = this.model.premiumCompensationDaysBeforeMaturityTCalendar ?? AveragingPeriodCalendarType.Business;
  }

  ngOnChanges() {
    this.setFieldsVisibility();
  }

  private setFieldsVisibility() {
    // tslint:disable-next-line:max-line-length
    this.isPremiumCompensationInitialPremiumPercentVisible = this.model.premiumCompensationFormulaType === PremiumCompensationFormulaType.Formula1 ||  this.model.premiumCompensationFormulaType === PremiumCompensationFormulaType.Formula6;
    // tslint:disable-next-line:max-line-length
    this.isPremiumCompensationReferencePriceCBCCYVisible = this.model.premiumCompensationFormulaType === PremiumCompensationFormulaType.Formula2;
    // tslint:disable-next-line:max-line-length
    this.isPremiumCompensationInitialParityCBCCYVisible = this.model.premiumCompensationFormulaType === PremiumCompensationFormulaType.Formula4;
    // tslint:disable-next-line:max-line-length
    this.isDaysBeforeMaturityVisible = this.model.premiumCompensationFormulaType === PremiumCompensationFormulaType.Formula6;
  }

  doModelChange() {
    this.setFieldsVisibility();
    this.didSetPremiumCompensationFormula.next(this.model);
  }

  getPremiumCompensationTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
