<kendo-grid class="lv-scenario-grid"
  [data]="view.model.records"
  [resizable]="true">
  <kendo-grid-column
    [locked]="true"
    [width]="view.defaultDimensionColumnWidth">
    <ng-template kendoGridHeaderTemplate>
      <div class="aligned-left">{{ view.model.dimensionDescription }}</div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="input">
        <ng-container *ngIf="!view.showFirstDimensionAsPercentage">
          <ng-container *ngIf="!view.isValuationDateShift(dataItem.dimension)">
            {{ dataItem.dimensionValue | number:view.numberFormat }}
          </ng-container>
          <ng-container *ngIf="view.isValuationDateShift(dataItem.dimension)">
            {{ dataItem.dimensionValue | lvLocalDate }}
          </ng-container>
        </ng-container>

        <ng-container *ngIf="view.showFirstDimensionAsPercentage">
          {{ dataItem.dimensionValue | number:view.numberFormat }} ({{ dataItem.dimensionValue | lvPercent:view.firstDimensionChangeRef:view.percentFormat }})
        </ng-container>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngFor="let column of view.model.columns"
    [title]="column.title"
    [width]="column.width">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem[column.columnId] | number:view.numberFormat }}
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
