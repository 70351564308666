import { Component, Input, ChangeDetectionStrategy, HostBinding, ViewEncapsulation } from '@angular/core';
import { IInstrumentSelectedEvent } from '@lv-core-ui/models/instrument-selected-event';
import { IWorkspaceEvent } from '@lv-views/internal';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'workspace-panel-dialog',
  templateUrl: './workspace-panel-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class WorkspacePanelDialogComponent {

  @Input() eventToDelete: IWorkspaceEvent;
  @Input() event: IInstrumentSelectedEvent;

  get isInstrumentToBeDeleted(): any {
      return !!this.event;
  }

  constructor() { }

  @HostBinding('class.workspace-panel-dialog')
  get isWorkspacePanelDialog() {
    return true;
  }
}
