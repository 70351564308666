<lv-flex-box class="terms-puts-put-parameters">
  <lv-flex-box direction="row" data-cy="DM-902">
    <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="putValueTypeSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Put Value Type
        </span>
        <lv-xl-label [field]="'PUT_VALUE_TYPE'"></lv-xl-label>
      </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        class="lv-puts-value-type"
        name="putValueType"
        [data]="putValueTypeLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 114 }"
        [(ngModel)]="model.valueType"
        (ngModelChange)="onPutValueTypeChanged()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" *ngIf="areYieldFieldsVisible" data-cy="DM-903">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="putYieldFrequencySourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Put Yield Frequency
      </span>
      <lv-xl-label [field]="'PUT_YLD_FRQ'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="putYieldFrequency"
        [data]="putYieldFrequencyLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="model.yieldFrequency"
        (ngModelChange)="doModelChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" *ngIf="areYieldFieldsVisible" data-cy="DM-904">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="yieldDayCountSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Put Yield Day Count
      </span>
      <lv-xl-label [field]="'PUT_DAY_COUNT'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="yieldDayCount"
        [data]="yieldDayCountLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="model.yieldDaysCount"
        (ngModelChange)="doModelChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" *ngIf="isKeepAccruedAndForfeitCouponVisible" data-cy="DM-905">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="keepAccruedSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Keep Accrued
      </span>
      <lv-xl-label [field]="'PUT_KEEP_ACCRD'"></lv-xl-label>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        [attr.id]="keepAccruedCheckBoxId"
        name="keepAccrued"
        [(ngModel)]="model.keepAccrued"
        (ngModelChange)="doModelChange()"/>
      <label class="k-checkbox-label" [attr.for]="keepAccruedCheckBoxId"></label>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" *ngIf="isKeepAccruedAndForfeitCouponVisible" data-cy="DM-906">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="forfeitCouponSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Forfeit Coupon
      </span>
      <lv-xl-label [field]="'PUT_FRFT_CPN'"></lv-xl-label>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        [attr.id]="forfeitCouponCheckBoxId"
        name="forfeitCoupon"
        [(ngModel)]="model.forfeitCoupon"
        (ngModelChange)="doModelChange()"/>
      <label class="k-checkbox-label" [attr.for]="forfeitCouponCheckBoxId"></label>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-907">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="putNoticeDaysSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Put Notice (days)
      </span>
      <lv-xl-label [field]="'PUT_NTC_DAYS'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
      name="putNotice"
      [format]="numberFormat"
      [decimals]="numberOfDecimals"
      [(ngModel)]="model.notice"
      (didBlur)="doModelChange()"
      (didKeyDownEnter)="doModelChange()">
      </lv-numeric-text-box>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-908">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="putNoticeDaysTypeSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Put Notice (days type)
      </span>
      <lv-xl-label [field]="'PUT_NTC_DAYS_TYPE'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="putNoticeDayType"
        lvId="putNoticeDayType"
        [data]="noticePeriodLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="model.noticePeriod"
        (ngModelChange)="doModelChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-909">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="puttableDuringPeriodSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Puttable During Period
      </span>
      <lv-xl-label [field]="'PUT_DRNG_PERIOD'"></lv-xl-label>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input 
        class="k-checkbox"
        type="checkbox"
        [attr.id]="puttableDuringPeriodCheckBoxId"
        name="puttableDuringPeriod"
        [disabled]="isPuttableDuringPeriodDisabled"
        [(ngModel)]="model.puttableDuringPeriod"
        (ngModelChange)="doModelChange()"/>
      <label class="k-checkbox-label"
       [attr.for]="puttableDuringPeriodCheckBoxId"
       lvId="puttableDuringLabel"></label>
    </div>
  </lv-flex-box>
  <lv-flex-box 
    direction="row"
    data-cy="DM-4514">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="partialPutSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Partial Put
      </span>
      <lv-xl-label [field]="'PARTIAL_PUT'"></lv-xl-label>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input #partialPut
        class="k-checkbox"
        type="checkbox"
        [attr.id]="partialPutCheckBoxId"
        name="partialPut"
        [disabled]="isPartialPutDisabled"
        [(ngModel)]="model.partialPut"
        (ngModelChange)="doModelChange()"/>
      <label class="k-checkbox-label"
       [attr.for]="partialPutCheckBoxId"
       lvId="partialPutLabel"></label>
    </div>
  </lv-flex-box>
  <lv-flex-box
    *ngIf="isPartialPutSelected"
    direction="row"
    data-cy="DM-4515">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="currentNotionalPctSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Current Notional Pct
      </span>
      <lv-xl-label [field]="'PARTIAL_PUT_CRNT_NOT'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
      name="currentNotionalPct"
      [format]="fourDecimalNumberFormat"
      [decimals]="fourDecimals"
      [(ngModel)]="model.currentNotionalPct"
      (didBlur)="doModelChange()"
      (didKeyDownEnter)="doModelChange()">
      </lv-numeric-text-box>
    </div>
    <div class="lv-label lv-label-suffix">%</div>
  </lv-flex-box>
</lv-flex-box>
<lv-flex-box
  *ngIf="isPartialPutSelected"
  class="terms-puts-total-tranche-pct"
  data-cy="DM-4516"
  direction="row"
  showAfter="1500"
  kendoTooltip
  [tooltipTemplate]="totalTranchePctTooltip"
  filter=".lv-label">
  <div class="lv-label">Total Tranche Pct</div>
  <div class="lv-output lv-output--right">
    <lv-negative-label
        [value]="model.totalTranchePct"
        shouldShowBorder="true"
        lvId="totalTranchePct"
        suffix="%"
        [format]="fourDecimalNegativeLabelFormat">
      </lv-negative-label>
  </div>
  </lv-flex-box>

<ng-template #putValueTypeSourceTooltip>
  <lv-tooltip dm="dM-902"></lv-tooltip>
</ng-template>
<ng-template #putYieldFrequencySourceTooltip>
  <lv-tooltip dm="dM-903"></lv-tooltip>
</ng-template>
<ng-template #yieldDayCountSourceTooltip>
  <lv-tooltip dm="dM-904"></lv-tooltip>
</ng-template>
<ng-template #keepAccruedSourceTooltip>
  <lv-tooltip dm="dM-905"></lv-tooltip>
</ng-template>
<ng-template #forfeitCouponSourceTooltip>
  <lv-tooltip dm="dM-906"></lv-tooltip>
</ng-template>
<ng-template #putNoticeDaysSourceTooltip>
  <lv-tooltip dm="dM-907"></lv-tooltip>
</ng-template>
<ng-template #putNoticeDaysTypeSourceTooltip>
  <lv-tooltip dm="dM-908"></lv-tooltip>
</ng-template>
<ng-template #puttableDuringPeriodSourceTooltip>
  <lv-tooltip dm="dM-909"></lv-tooltip>
</ng-template>
<ng-template #partialPutSourceTooltip>
  <lv-tooltip dm="dM-4514"></lv-tooltip>
</ng-template>
<ng-template #currentNotionalPctSourceTooltip>
  <lv-tooltip dm="dM-4515"></lv-tooltip>
</ng-template>
<ng-template #totalTranchePctTooltip>
  <lv-tooltip dm="dM-4516"></lv-tooltip>
</ng-template>
