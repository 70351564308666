<lv-advanced-grid #advancedGrid *ngIf="allDataLoaded"
  [lvId]="lvId"
  class="lv-yield-curve-term-structure"
  [columns]="columns"
  [records]="yieldCurveScheduledItems"
  [createFormGroup]="createFormGroup"
  [parseFn]="parseFn"
  sortBy="tenor"
  [sortComparerFn]="sortFn"
  [showXlLabel]="hasOverrideFromExcel"
  [excelFieldAlias]="excelFieldAlias"
  [isFieldFromExcelEnabled]="isFieldFromExcelEnabled">
</lv-advanced-grid>
