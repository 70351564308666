<lv-advanced-grid
  data-cy="DM-987"
  lvId="coPaySchedule"
  [columns]="columns"
  [records]="scheduleItems"
  [createFormGroup]="createFormGroup"
  [parseFn]="parseFn"
  sortBy="startDate"
  [showXlLabel]="hasScheduleInExcelOverride"
  [excelFieldAlias]="excelFieldAlias"
  [isFieldFromExcelEnabled]="isFieldFromExcelEnabled"
  class="lv-co-pay-scheduile">
</lv-advanced-grid>
