import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IBaseTermsSettings, IGeneralSectionSettings } from '@lv-convertible-bond/models';

@Component({
  selector: 'lv-general-settings',
  templateUrl: './lv-general-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvGeneralSettingsComponent implements OnInit {

  @Input() systemDefaults: IBaseTermsSettings<IGeneralSectionSettings>;
  @Input() myDefaults: IBaseTermsSettings<IGeneralSectionSettings>;

  constructor() {
  }

  @HostBinding('class.lv-general-settings')
  get isLvGeneralSettingsComponent() {
    return true;
  }

  ngOnInit() {
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
  getTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
}
