import { ElementRef, ChangeDetectorRef, ViewRef } from '@angular/core';
import { ResizeHandlerService } from '@lv-core-ui/services';

export type Direction = 'row' | 'column';
export type LabelAndInputWifth = 264 | 160 | 104;

/**
 * Pricing util.
 */
export class LvPricingUtil {

  valuationInputDirection: Direction;
  labelAndInputBoxDirection: Direction;
  pricingSetsDirection: Direction;
  pricingNewIssueSetsDirection: Direction;
  cpeDirection: Direction;

  estimatesSettingsWidth: number;
  labelAndInputBoxWidth: LabelAndInputWifth;
  labelWidth: LabelAndInputWifth;

  inputFieldWidth: number;
  marginWidth: number;
  baseNiaGridWidth: number;
  offsetWidth: number;

  threeColumnsMaxWidth: number;
  twoColumnsMaxWidth: number;
  oneColumnMaxWidth: number;

  elementReactWidth: number;

  pricingSectionMarginBottom: number;
  estimatesButtonWideForm: boolean;

  constructor(
    private _resizeHandlerService: ResizeHandlerService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _elementRef: ElementRef,
    private _isFromExcel: boolean = false
  ) {
    this.valuationInputDirection = 'row';
    this.labelAndInputBoxDirection = 'column';
    this.pricingSetsDirection = 'column';
    this.pricingNewIssueSetsDirection = 'column';
    this.cpeDirection = 'row';
    this.estimatesButtonWideForm = true;

    this.labelAndInputBoxWidth = 104;
    this.labelWidth = 104;

    this.inputFieldWidth = 104;
    this.marginWidth = 10;
    this.baseNiaGridWidth = _isFromExcel ? 480 : 460;
    this.offsetWidth = 4;

    this.threeColumnsMaxWidth = 1250;
    this.twoColumnsMaxWidth = 900;
    this.oneColumnMaxWidth = 520;

    this.pricingSectionMarginBottom = 0;
    this.estimatesSettingsWidth = 230;
  }

  /**
   * Field initialization.
   * @param useDefaultBoundaryWidths A flag indicating if default boundary widths should be used.
   * @param isCrossFx  A flag indicating if cross FX is set.
   * @param isNewIssue A flag indicating if instrument is new issue.
   * @param downsideVolatilityVisible A flag indicating if downside volatility should be visible.
   * @param parityVisible A flag indicating if parity should be visible.
   * @param niaButtonVisible A flag indicating if nia button should be visible.
   * @param isMarketDataOverridesVisible A flag indicating if marketdata overrides volatility should be visible.
   */
  init(
    useDefaultBoundaryWidths: boolean,
    isCrossFx: boolean = false,
    isNewIssue: boolean = false,
    downsideVolatilityVisible: boolean = false,
    parityVisible: boolean = false,
    niaButtonVisible: boolean = false,
    isMarketDataOverridesVisible: boolean = false
  ) {
    if (useDefaultBoundaryWidths) {
      this.useDefaultWidth();
    }
    else {
      this.setElementWidths(isCrossFx,
        isNewIssue,
        downsideVolatilityVisible,
        parityVisible,
        niaButtonVisible,
        isMarketDataOverridesVisible);
    }

    this._resizeHandlerService.listenTo(this._elementRef, elementRect => {

      // posible fix
      if (elementRect.width !== 0) {
        this.elementReactWidth = elementRect.width;
      }

      if (this.elementReactWidth < this.threeColumnsMaxWidth) {
        this.pricingSetsDirection = 'column';
      }
      else {
        this.pricingSetsDirection = 'row';
      }

      if (this.elementReactWidth < this.twoColumnsMaxWidth) {
        this.pricingNewIssueSetsDirection = 'column';
      }
      else {
        this.pricingNewIssueSetsDirection = 'row';
      }

      if (this.elementReactWidth < this.oneColumnMaxWidth) {
        this.valuationInputDirection = 'column';
        this.cpeDirection = 'column';
        this.labelAndInputBoxDirection = 'row';
        this.pricingSectionMarginBottom = 10;
        this.estimatesButtonWideForm = false;
        this.labelWidth = 160;
        this.labelAndInputBoxWidth = 264;
      }
      else {
        this.valuationInputDirection = 'row';
        this.cpeDirection = 'row';
        this.labelAndInputBoxDirection = 'column';
        this.pricingSectionMarginBottom = 0;
        this.estimatesButtonWideForm = true;
        this.labelWidth = 104;
        this.labelAndInputBoxWidth = 104;
      }

      if (!(this._changeDetectorRef as ViewRef).destroyed) {
        this._changeDetectorRef.detectChanges();
      }
    });
  }

  // formula => a * this.inputFieldWidth + b * this.marginWidth + this.baseNiaGridWidth + this.offsetWidth
  // a => number of input fields in one row of this cb pricing representation
  // this.inputFieldWidth => input field width in pixels

  // b => number of margins between columns one row of this cb pricing representation
  // this.marginWidth => marginWidth width in pixels

  // this.baseNiaGridWidth => new issue assumptions grid width in pixels (if visible)
  // this.offsetWidth => every field has margin 1px between other field and we need to count that space (used middle-value 4px)

  /**
   * Sets element widths.
   * @param isCrossFx  A flag indicating if cross FX is set.
   * @param isNewIssue A flag indicating if instrument is new issue.
   * @param dowsideVolatilityVisible A flag indicating if downside volatility should be visible.
   * @param parityVisible A flag indicating if parity should be visible.
   * @param niaButtonVisible A flag indicating if nia button should be visible.
   * @param isMarketDataOverridesVisible A flag indicating if marketdata overrides volatility should be visible.
   */
  private setElementWidths(
    isCrossFx: boolean,
    isNewIssue: boolean,
    dowsideVolatilityVisible: boolean,
    parityVisible: boolean,
    niaButtonVisible: boolean,
    isMarketDataOverridesVisible: boolean
  ) {

    if (isNewIssue && isCrossFx) {

      if (niaButtonVisible && !isMarketDataOverridesVisible) {
        this.setColumnsWidthWithSameInputNumbers(5);
        return;
      }

      if (!isMarketDataOverridesVisible) {
        this.threeColumnsMaxWidth = this.baseNiaGridWidth + 5 * this.inputFieldWidth + this.marginWidth + this.offsetWidth;
        this.twoColumnsMaxWidth = this.baseNiaGridWidth + 5 * this.inputFieldWidth + this.marginWidth + this.offsetWidth;
        this.oneColumnMaxWidth = 5 * this.inputFieldWidth;
        return;
      }

      if (niaButtonVisible) {
        this.setColumnsWidthWithMarginAndOffset(9, 5);
        return;
      }

      this.threeColumnsMaxWidth = this.baseNiaGridWidth + 9 * this.inputFieldWidth + 2 * this.marginWidth + this.offsetWidth;
      this.twoColumnsMaxWidth = this.baseNiaGridWidth + 5 * this.inputFieldWidth + this.marginWidth + this.offsetWidth;
      this.oneColumnMaxWidth = 5 * this.inputFieldWidth;
      return;
    }

    if (isNewIssue) {

      if (niaButtonVisible && !isMarketDataOverridesVisible) {
        this.setColumnsWidthWithSameInputNumbers(3);
        return;
      }

      if (!isMarketDataOverridesVisible) {
        this.threeColumnsMaxWidth = this.baseNiaGridWidth + 3 * this.inputFieldWidth + this.marginWidth + this.offsetWidth;
        this.twoColumnsMaxWidth = this.baseNiaGridWidth + 3 * this.inputFieldWidth + this.marginWidth + this.offsetWidth;
        this.oneColumnMaxWidth = this.baseNiaGridWidth;
        return;
      }

      if (niaButtonVisible) {
        this.setColumnsWidthWithMarginAndOffset(7, 4);
        return;
      }

      this.threeColumnsMaxWidth = this.baseNiaGridWidth + 7 * this.inputFieldWidth + 2 * this.marginWidth + this.offsetWidth;
      this.twoColumnsMaxWidth = this.baseNiaGridWidth + 4 * this.inputFieldWidth + this.marginWidth + this.offsetWidth;
      this.oneColumnMaxWidth = this.baseNiaGridWidth;
      return;
    }

    if (isCrossFx) {

      if (!isMarketDataOverridesVisible) {
        this.setColumnsWidthWithSameInputNumbers(6);
        return;
      }

      this.setColumnsWidthWithMarginAndOffset(10, 6);
      return;
    }

    if (dowsideVolatilityVisible) {

      if (!isMarketDataOverridesVisible) {
        this.setColumnsWidthWithSameInputNumbers(3);
        return;
      }

      this.setColumnsWidthWithMarginAndOffset(8, 5);
      return;
    }

    if (!parityVisible) {

      if (!isMarketDataOverridesVisible) {
        this.setColumnsWidthWithSameInputNumbers(3);
        return;
      }

      this.setColumnsWidthWithMarginAndOffset(7, 4);
      return;
    }

    if (!isMarketDataOverridesVisible) {
      this.setColumnsWidthWithSameInputNumbers(4);
      return;
    }

    this.useDefaultWidth();
  }

  /**
   * Sets columns width with same input numbers.
   * @param numberOfInputFields Number of input fields.
   */
  private setColumnsWidthWithSameInputNumbers(numberOfInputFields: number) {
    this.threeColumnsMaxWidth = numberOfInputFields * this.inputFieldWidth;
    this.twoColumnsMaxWidth = numberOfInputFields * this.inputFieldWidth;
    this.oneColumnMaxWidth = numberOfInputFields * this.inputFieldWidth;
  }

  /**
   * Sets columns width with margin and offset.
   * @param numberOfInputFieldsForThreeAndTwoColumnsWidth Number of input fields for three and two columns width.
   * @param numberOfInputFieldsForOneColumnsWidth Number of input fields for one columns width.
   */
  private setColumnsWidthWithMarginAndOffset(
    numberOfInputFieldsForThreeAndTwoColumnsWidth: number,
    numberOfInputFieldsForOneColumnsWidth: number) {

    this.threeColumnsMaxWidth = numberOfInputFieldsForThreeAndTwoColumnsWidth * this.inputFieldWidth
      + this.marginWidth + this.offsetWidth;

    this.twoColumnsMaxWidth = numberOfInputFieldsForThreeAndTwoColumnsWidth * this.inputFieldWidth + this.marginWidth;
    this.oneColumnMaxWidth = numberOfInputFieldsForOneColumnsWidth * this.inputFieldWidth;
  }

  /**
   * Sets default width.
   */
  private useDefaultWidth() {
    this.threeColumnsMaxWidth = 8 * this.inputFieldWidth + this.marginWidth + this.offsetWidth;
    this.twoColumnsMaxWidth = 8 * this.inputFieldWidth + this.marginWidth;
    this.oneColumnMaxWidth = 4 * this.inputFieldWidth;
  }
}
