import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { IShiftParametersSectionSettings } from '@lv-analytics/models/company-and-user-settings';

/**
 * Shift parameters section settings component.
 */
@Component({
  selector: 'lv-shift-parameters-section-settings',
  templateUrl: './lv-shift-parameters-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvShiftParametersSectionSettingsComponent {

  @Input() settings: IShiftParametersSectionSettings;
  @Input() overrideSystemDefaults: boolean;

  numberOfDecimals = '4';
  numberFormat = '#.####';
  numberPctFormat = '#.00##';

  constructor() {
    this.overrideSystemDefaults = true;
  }
}
