import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Output,
         EventEmitter, Input, OnChanges, ElementRef, Optional } from '@angular/core';
import { CallMakeWholeData, IssueAndRedemption, CallMakeWholeTypeDescription, CallMakeWholeType } from '@lv-convertible-bond/models';
import { DiscountRateType } from '@lv-convertible-bond/models/convertible-bond-terms/Enums (2)';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';
import { DateExtensions, LvLookupEnum } from '@lv-core-ui/util';
import { LvExcelService } from '@lv-excel/services';

@Component({
  selector: 'lv-make-whole-call-parameters',
  templateUrl: './lv-make-whole-call-parameters.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvMakeWholeCallParametersComponent implements OnInit, OnChanges {
  @Input() model: CallMakeWholeData;
  @Input() issueAndRedemption: IssueAndRedemption;
  @Output() didSetParameters: EventEmitter<CallMakeWholeData>;

  makeWholeTypeLookup: LvLookupEnum;
  discountRateTypeLookup: LvLookupEnum;

  private _couponsIncludedUntil: Date;
  private _startDate: Date;
  private _endDate: Date;

  couponsIncludedUntilVisible: boolean;
  discountFieldsVisible: boolean;
  initialPremiumVisible: boolean;

  formatZero = 'n0';
  decimalsZero = '0';
  formatNumber = '#,###.###';
  decimalsNumber = '3';

  get couponsIncludedUntil(): Date {
    return this._couponsIncludedUntil;
  }

  set couponsIncludedUntil(value: Date) {
    this._couponsIncludedUntil = value;

    if (this.model && this.couponsIncludedUntilEnabled) {
      this.model.couponsIncludedUntil = value;
    }
  }

  get startDate(): Date {
    return this._startDate;
  }

  set startDate(value: Date) {
    this._startDate = value;

    if (this.model && this.startDateEnabled) {
      this.model.startDate = value;
    }
  }

  get endDate(): Date {
    return this._endDate;
  }

  set endDate(value: Date) {
    this._endDate = value;

    if (this.model && this.endDateEnabled) {
      this.model.endDate = value;
    }
  }

  get isExcelInstrument(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  get couponIncludedUntil(): string {
    return this.isExcelInstrument ? 'Coupons Incl. Until' : 'Coupons Included Until';
  }

  couponsIncludedUntilEnabled: boolean;
  startDateEnabled: boolean;
  endDateEnabled: boolean;

  dpStartDateCheckBoxId: string;
  dpStartDatePickerId: string;
  dpEndDateCheckBoxId: string;
  dpEndDatePickerId: string;

  constructor(
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.didSetParameters = new EventEmitter<CallMakeWholeData>();
    this.makeWholeTypeLookup = new LvLookupEnum(CallMakeWholeTypeDescription);
    this.discountRateTypeLookup = new LvLookupEnum(DiscountRateType);
    this.couponsIncludedUntilEnabled = false;
    this.startDateEnabled = false;
    this.endDateEnabled = false;
    this.dpEndDateCheckBoxId = 'cmwEndDateCheckBoxId';
    this.dpEndDatePickerId = 'cmwEndDatePickerId';
    this.dpStartDateCheckBoxId = 'cmwStartDateCheckBoxId';
    this.dpStartDatePickerId = 'cmwStartDatePickerId';
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.setFieldsVisibility();
    this.setMWDates();
  }

  makeWholeTypeChanged() {
    this.setFieldsVisibility();
    this.doModelChange();
  }

  private setFieldsVisibility() {
    if (this.model && this.model.type) {
      this.couponsIncludedUntilVisible = this.model.type === CallMakeWholeType.Coupon ||
                                       this.model.type === CallMakeWholeType.CouponPV;
      this.discountFieldsVisible = this.model.type === CallMakeWholeType.CouponPV;
      this.initialPremiumVisible = this.model.type === CallMakeWholeType.Premium ||
                                   this.model.type === CallMakeWholeType.PremiumLinDecreasing;
    }
  }

  setMWDates() {
    const makeWholeSettings = this._lvConvertibleBondTermsService.makeWholeSettings;

    if (!this.model.couponsIncludedUntil) {
      this._couponsIncludedUntil = this.issueAndRedemption.maturityDate;
      this.couponsIncludedUntilEnabled = false;
    } else {
      this._couponsIncludedUntil = this.model.couponsIncludedUntil;
      this.couponsIncludedUntilEnabled = true;
    }

    if (!this.model.startDate) {
      this._startDate = DateExtensions.addDays(this.issueAndRedemption.firstSettlementDate, makeWholeSettings?.startDate);
      this.startDateEnabled = false;
    } else {
      this._startDate = new Date(this.model.startDate);
      this.startDateEnabled = true;
    }

    if (!this.model.endDate) {
      this._endDate = DateExtensions.addDays(this.issueAndRedemption.maturityDate, -makeWholeSettings?.endDate);
      this.endDateEnabled = false;
    } else {
      this._endDate = new Date(this.model.endDate);
      this.endDateEnabled = true;
    }
  }

  doModelChange() {
    this.didSetParameters.next(this.model);
  }

  startDateCheckboxChange(value: boolean): void {
    this.startDateEnabled = value;

    if (this.model) {
      this.model.startDate = value ? this._startDate : null;
    }

    this.doModelChange();
  }

  endDateCheckboxChange(value: boolean): void {
    this.endDateEnabled = value;

    if (this.model) {
      this.model.endDate = value ? this._endDate : null;
    }

    this.doModelChange();
  }

  couponsIncludedUntilCheckboxChange(value: boolean): void {
    this.couponsIncludedUntilEnabled = value;
    if (this.model) {
      this.model.couponsIncludedUntil = value ? this._couponsIncludedUntil : null;
    }
    this.doModelChange();
  }

  getCallMakeWholeTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
