import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { IBorrow } from '@lv-analytics/models/market-data/borrow/borrow';

/**
 * Borrow rebate component.
 */
@Component({
  selector: 'lv-borrow-rebate',
  templateUrl: './lv-borrow-rebate.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvBorrowRebateComponent implements OnInit {

  @Input() borrowSettings: IBorrow;
  @Output() rebateValueChange: EventEmitter<void>;

  numberOfDecimals = '2';
  numberFormat = '#.##';

  constructor() {
    this.rebateValueChange = new EventEmitter();
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Occurs on value change.
   */
  onValueChange() {
    this.rebateValueChange.emit();
  }

  /**
   * Gets borrow tooltip ID.
   * @param element HTML element.
   * @param sectionId Section ID.
   * @returns Borrow tooltip ID.
   */
  getBorrowTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('borrow-tooltip-id') === sectionId;
  }
}
