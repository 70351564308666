import { Component, ElementRef, Input } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { IWarningsSectionSettings } from 'src/app/modules/analytics/models/company-and-user-settings/valuation-settings/warnings/warnings-section-settings';
// tslint:disable-next-line: max-line-length
import { IWarningsSettings } from 'src/app/modules/analytics/models/company-and-user-settings/valuation-settings/warnings/warnings-settings';

/**
 * Warnings settings component.
 */
@Component({
  selector: 'lv-warnings-settings',
  templateUrl: './lv-warnings-settings.component.html'
})
export class LvWarningsSettingsComponent {

  @Input() settings: IWarningsSettings;

  constructor() {
    this.settings = {
      systemDefaults: {} as IWarningsSectionSettings,
      environments: []
    } as IWarningsSettings;
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns flag true if element with given id is found
   */
    getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }
}
