import { ILvGlLayoutConfiguration } from './configuration';

export class LvGoldenLayoutError extends Error {
  message: string;
  name: string;

  constructor(message: string, name: string) {
    super(message);

    this.name = name;
  }
}

export class LvGoldenLayoutInitializaionError extends LvGoldenLayoutError {
  constructor(message: string) {
    super(message, 'Golden Layout Initialization');
  }
}

export class ILvGoldenLayoutError {
  name: string;
  message: string;
  stack: string;
  faultyConfiguration: ILvGlLayoutConfiguration;
}
