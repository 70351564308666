<lv-flex-box class="lv-convertible-bond-terms-accretion" data-cy="DM-1226">
  <lv-flex-box direction="row" data-cy="DM-934">
    <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="accretionTypeSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Accretion Type
        </span>
        <lv-xl-label [field]="'ACCR_TYPE'"></lv-xl-label>
      </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="accretionType"
        [data]="view.accretionTypeLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 144 }"
        [(ngModel)]="view.model.type"
        (ngModelChange)="onModelChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box *ngIf="view.isAccretionTypeFixed" direction="row" data-cy="DM-940">
    <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="fixedAccretionRateSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Fixed Accretion Rate
        </span>
        <lv-xl-label [field]="'ACCR_FXD_RATE'"></lv-xl-label>
      </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="fixedAccretionRate"
        [format]="view.numberFormat"
        [decimals]="view.numberOfDecimalsPercentage"
        [(ngModel)]="view.model.fixedAccretionRate"
        (didBlur)="onModelChange()"
        (didKeyDownEnter)="onModelChange()">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-935">
    <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="accretionDayCountSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Accretion Day Count
        </span>
        <lv-xl-label [field]="'ACCR_DAY_COUNT'"></lv-xl-label>
      </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="accretionDayCount"
        [data]="view.dayCountLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="view.model.dayCount"
        (ngModelChange)="onModelChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-936">
    <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="accretionFrequencySourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Accretion Frequency
        </span>
        <lv-xl-label [field]="'ACCR_FREQ'"></lv-xl-label>
      </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="accretionFrequency"
        [data]="view.frequencyLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="view.model.frequency"
        (ngModelChange)="onModelChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-937">
    <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="GrossNetSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Gross / Net
        </span>
        <lv-xl-label [field]="'ACCR_GROSS_NET'"></lv-xl-label>
      </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="grossNet"
        [data]="view.grossNetLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="view.model.grossNet"
        (ngModelChange)="onModelChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-938">
    <div class="lv-label lv-label-field lv-label-before-checkbox"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="accretionStartDateSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Accretion Start Date
        </span>
        <lv-xl-label [field]="'ACCR_START_DATE'"></lv-xl-label>
      </div>
    <lv-datepicker
      name="accrualStartDatePicker"
      [datePickerLvId] = "dpAccrualStartDatePickerId"
      [(ngModel)]="view.accretionStartDate"
      [checked]="view.useAccretionStartDate"
      (ngModelChange)="onModelChange()"
      (didCheckboxValueChange)="accretionStartDateCheckBoxChanged($event)">
    </lv-datepicker>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-939">
    <div class="lv-label lv-label-field lv-label-before-checkbox"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="accretionEndDateSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Accretion End Date
        </span>
        <lv-xl-label [field]="'ACCR_END_DATE'"></lv-xl-label>
      </div>
      <lv-datepicker
        name="accrualEndDatePicker"
        [datePickerLvId] = "dpAccrualEndDatePickerId"
        [(ngModel)]="view.accretionEndDate"
        [checked]="view.useAccretionEndDate"
        (ngModelChange)="onModelChange()"
        (didCheckboxValueChange)="accretionEndDateCheckBoxChanged($event)">
      </lv-datepicker>
    </lv-flex-box>
</lv-flex-box>

<ng-template #accretionTypeSourceTooltip>
  <lv-tooltip dm="dM-934"></lv-tooltip>
</ng-template>
<ng-template #fixedAccretionRateSourceTooltip>
  <lv-tooltip dm="dM-940"></lv-tooltip>
</ng-template>
<ng-template #accretionDayCountSourceTooltip>
  <lv-tooltip dm="dM-935"></lv-tooltip>
</ng-template>
<ng-template #accretionFrequencySourceTooltip>
  <lv-tooltip dm="dM-936"></lv-tooltip>
</ng-template>
<ng-template #GrossNetSourceTooltip>
  <lv-tooltip dm="dM-937"></lv-tooltip>
</ng-template>
<ng-template #accretionStartDateSourceTooltip>
  <lv-tooltip dm="dM-938"></lv-tooltip>
</ng-template>
<ng-template #accretionEndDateSourceTooltip>
  <lv-tooltip dm="dM-939"></lv-tooltip>
</ng-template>
