<lv-flex-box
  direction="row"
  data-cy="DM-1479">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="fundingMarginSettingsTooltipTemplate"
    filter="div">
      Funding Margin
  </div>

  <div class="lv-input-field">
    <lv-numeric-text-box
      lvId="funding-margin"
      [format]="numberFormat"
      [decimals]="numberOfDecimals"
      [(ngModel)]="settings.fundingMargin"
      [disabled]="!overrideSystemDefaults">
    </lv-numeric-text-box>
    <span class="lv-sufix-label">%</span>
  </div>
</lv-flex-box>

<lv-flex-box
  direction="row"
  data-cy="DM-1480">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="fundingRateSettingsTooltipTemplate"
    filter="div">
    Funding Rate
  </div>

  <div class="lv-input-field">
    <lv-numeric-text-box
    lvId="funding-rate"
      [format]="numberFormat"
      [decimals]="numberOfDecimals"
      [(ngModel)]="settings.fundingRate"
      [disabled]="!overrideSystemDefaults">
    </lv-numeric-text-box>
    <span class="lv-sufix-label">%</span>
  </div>
</lv-flex-box>

<ng-template #fundingMarginSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1479"></lv-tooltip>
</ng-template>
<ng-template #fundingRateSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1480"></lv-tooltip>
</ng-template>
