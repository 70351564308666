<kendo-tabstrip class="lv-tabstrip"
  [keepTabContent]="true">

  <kendo-tabstrip-tab
    selected="true"
    title="Setup"
    cssClass="DM-1277">
    <ng-template kendoTabContent>
      <lv-environment-setup-settings 
        data-cy="DM-1277"
        [settings]="settings"
        (onSavePricingEnvironmentSettings)="doSavePricingEnvironmentSettings($event)">
      </lv-environment-setup-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="System"
    cssClass="DM-1278"
    >
    <ng-template kendoTabContent>
      <lv-environment-system-settings
        data-cy="DM-1278" 
        [settings]="settings"
        (emitSavePricingEnvironmentSaveRequest)="onSavePricingEnvironmentSaveRequestEmitted($event)">
      </lv-environment-system-settings>
    </ng-template>
  </kendo-tabstrip-tab>

</kendo-tabstrip>
<lv-flex-box 
  class="lv-environments-settings-control-panel"
  direction="row"
  data-cy="DM-1276"
  >
  <button kendoButton
    [primary]="true"
    lvId="cancel-market-data-settings"
    (click)="onBack()">Back</button>
</lv-flex-box>
