<div class="workspace"
  [class.selected]="selected"
  [class.disabled]="disabled">
  <ng-container *ngIf="showEditMode">
    <input #inputElement
      [style.width.px]="nameWidth"
      [(ngModel)]="newName"      
      (keypress)="onInputKeyPress($event)"
      (keyup)="onInputKeyUp($event)"
      (blur)="onInputBlur($event)"/>
  </ng-container>
  <div #nameElement
    *ngIf="!showEditMode"
    class="name"    
    (click)="onSelect()"
    (dblclick)="onEdit()">
    <div>{{ name }}</div>
  </div>
  <div *ngIf="showOptions"
    class="options">
    <i *ngIf="showCloseIcon" 
      class="k-icon k-i-close"
      (click)="onClose()">
    </i>
  </div>
</div>