

import { CurrencyType } from './CurrencyType';
import { ResetAveragingData } from './ResetAveragingData';
import { ResetScheduleItem } from './ResetScheduleItem';
import { FloorExpressedAs, CapExpressedAs } from './Enums (2)';

export class Reset {

    conversionPriceCurrencyType: CurrencyType;
    initialConversionPrice: number;
    resetCurrencyType: CurrencyType;
    globalFloor: number;
    floorExpressedRelativeTo: FloorExpressedAs;
    globalCap: number;
    capExpressedRelativeTo: CapExpressedAs;
    useResetAveraging: boolean;
    resetAveraging: ResetAveragingData;
    schedule: ResetScheduleItem[];
    optionalReset: boolean;
    resettableDuringPeriod: boolean;
}

