



export class PercsConversion {
    
    conversionRatio: number;
    capPrice: number;
}

