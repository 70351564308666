import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, HostListener,
  ElementRef, ChangeDetectorRef, Renderer2, Output, EventEmitter } from '@angular/core';
import { slide } from './lv-side-panel-animations';

@Component({
  selector: 'lv-side-panel',
  templateUrl: './lv-side-panel.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    slide
  ]
})
export class LvSidePanelComponent implements OnInit {

  @Input() floating: boolean;
  @Input() expanded: boolean;
  @Input() position: string;
  @Input() width: number;
  @Input() manualClosing: boolean;
  @Output() didSlide: EventEmitter<boolean>;
  @Output() didClosed: EventEmitter<void>;

  get ngClass() {
    return {
      [`lv-side-panel-${this.position}`]: true,
      'lv-side-panel--floating': this.floating
    };
  }

  get animationDirection() {
    if (this.floating) {
      return 'top';
    }

    return this.position;
  }

  internalClick: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private element: ElementRef,
    private renderer: Renderer2
  ) {
    this.position = 'left';
    this.width = 220;
    this.expanded = false;
    this.floating = false;
    this.manualClosing = false;
    this.didSlide = new EventEmitter<boolean>();
    this.didClosed = new EventEmitter<void>();
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onDocumentClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement || this.manualClosing) {
        return;
    }

    const clickedInside = this.element.nativeElement.contains(targetElement);
    if (!clickedInside && !this.internalClick && this.expanded) {
      this.expanded = false;
      this.didClosed.next();
      this.changeDetectorRef.detectChanges();
    }

    this.internalClick = false;
  }

  ngOnInit() {
    this.renderer.setStyle(this.element.nativeElement.parentElement, 'position', 'relative');
    this.renderer.setStyle(this.element.nativeElement.parentElement, 'overflow-x', 'hidden');

    if (this.expanded) {
      this.internalClick = this.expanded;
      this.changeDetectorRef.detectChanges();
    }
  }

  onSlideDone() {
    this.didSlide.next(this.expanded);
  }

  togglePanel() {
    this.expanded = !this.expanded;
    this.internalClick = this.expanded;
    this.changeDetectorRef.detectChanges();
  }

}
