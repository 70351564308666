import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LvAnalyticsError } from '../../models/errors';

import { IPricingEnvrionment } from '../../models/company-and-user-settings/pricing-environment';
import { INewIssueSidePanelSettings, ISaveNewIssueSettingsRequest } from '../../models/company-and-user-settings/new-issue-settings';
import { ISaveEstimatesSidePanelSettingsRequest, IEstimatePanelSettings } from '../../models/company-and-user-settings/estimates-settings';
import { IMarketDataSettings } from '../../models/market-data-settings/market-data-settings';
import { ISaveValuationSettingsRequest } from '../../models/request/company-and-user-settings/save-valuation-settings-request';
import { IValuationSettingsResponse } from '../../models/response/company-and-user-settings/valuation-settings-response';
import { IModelCustomizationSettings } from '../../models/model-customization-settings/model-customization-settings';
// tslint:disable-next-line:max-line-length
import { ISaveModelCustomizationSettingsRequest } from '../../models/model-customization-settings/save-model-customization-settings-request';
import { INewIssueSettings } from '../../models/pricing-settings/new-issue-settings/new-issue-settings';
import { IEstimatesSettings } from '../../models/pricing-settings/estimates-settings/estimates-settings';
import { ISaveEstimatesSettingsRequest } from '../../models/pricing-settings/estimates-settings/save-estimates-settings-request';
import { HttpClientBase } from '@lv-core-ui/api';
import { IOtherSettings } from '../../models/company-and-user-settings/other-settings/other-settings';
import { v4 } from 'uuid';
import { ISaveOtherSettingsRequest } from '../../models/request/company-and-user-settings/save-other-settings-request';
import { IOtherEnvironmentSettings } from '../../models/company-and-user-settings/other-settings/other-environment-settings';
import { IPricingEnvironmentSettings } from '@lv-analytics/models/company-and-user-settings/environments-settings';
import { IPricingEnvironmentOrderSettings } from '@lv-analytics/models/company-and-user-settings/environments-settings/setup-settings/pricing-evironment-order-settings';
import { ISystemYieldCurvesSettingsView } from '@lv-analytics/models/market-data-settings/interest-rates/system-yield-curves-settings-view';
import { ITenor } from '@lv-analytics/models';
import { IValuationEnvironmentSettings } from '@lv-analytics/models/company-and-user-settings/valuation-settings/valuation-environment-settings';

const COMPANY_MARKET_DATA_SETTINGS_PATH = '/marketDataSettings';
const COMPANY_MODEL_CUSTOMIZATION_SETTINGS_PATH = '/modelCustomizationSettings';

/**
 * Company and user settings service.
 */
@Injectable()
export class CompanyAndUserSettingsService extends HttpClientBase {

  constructor(
    http: HttpClient
  ) {
    super(http, '/analytics/companySettings');
    this._svcInstanceId = v4();
  }

  get instanceId(): string {
    return this._svcInstanceId;
  }

  private _svcInstanceId: string;

  /**
   * Gets available environments.
   * @returns List of IPricingEnvrionment objects.
   */
  async getAvailableEnvrionments(): Promise<IPricingEnvrionment[]> {
    try {
      const result = await this.getAsync<IPricingEnvrionment[]>(null, '/availablePricingEnvironments');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Gets new issue side panel settings.
   * @returns INewIssueSidePanelSettings object.
   */
  async getNewIssueSidePanelSettings(): Promise<INewIssueSidePanelSettings> {
    try {
      const result = await this.getAsync<INewIssueSidePanelSettings>(null, '/newIssueSettings');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Gets new issue settings.
   * @returns INewIssueSettings object.
   */
  async getNewIssueSettings(): Promise<INewIssueSettings> {
    try {
      const result = await this.getAsync<INewIssueSettings>(null, '/settings/newIssueSettings');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Gets estimates side panel settings.
   * @param lwsIdentifier LWS identifier.
   * @returns IEstimatePanelSettings object.
   */
  async getEstimatesSidePanelSettings(lwsIdentifier: string): Promise<IEstimatePanelSettings> {
    try {
      const result = await this.getAsync<IEstimatePanelSettings>(null, `/estimatesSettings/${lwsIdentifier}`);
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Gets estimates settings.
   * @returns IEstimatesSettings object.
   */
  async getEstimatesSettings(): Promise<IEstimatesSettings> {
    try {
      const result = await this.getAsync<IEstimatesSettings>(null, `/settings/estimatesSettings`);
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Saves new issue settings.
   * @param request ISaveNewIssueSettingsRequest object.
   */
  async saveNewIssueSettings(request: ISaveNewIssueSettingsRequest) {
    try {
      await this.postAsync(request, '/newIssueSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Reset new issue settings.
   * @returns INewIssueSettings object.
   */
  async resetNewIssueSettings(): Promise<INewIssueSettings> {
    try {
      const result = await this.getAsync<INewIssueSettings>(null, '/settings/newIssueSettings/default');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Saves estimates side panel settings.
   * @param request ISaveEstimatesSidePanelSettingsRequest
   */
  async saveEstimatesSidePanelSettings(request: ISaveEstimatesSidePanelSettingsRequest) {
    try {
      await this.postAsync(request, '/estimatesSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Saves estimates settings.
   * @param request ISaveEstimatesSettingsRequest object.
   */
  async saveEstimatesSettings(request: ISaveEstimatesSettingsRequest) {
    try {
      await this.postAsync(request, '/settings/estimatesSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Resets estimates settings.
   * @returns IEstimatesSettings object.
   */
  async resetEstimatesSettings(): Promise<IEstimatesSettings> {
    try {
      const result = await this.getAsync<IEstimatesSettings>(null, `/settings/estimatesSettings/default`);
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Gets market data settings.
   * @returns IMarketDataSettings objects.
   */
  async getMarketDataSettings(): Promise<IMarketDataSettings> {
    try {
      const result = await this.getAsync<IMarketDataSettings>(null, `${COMPANY_MARKET_DATA_SETTINGS_PATH}`);
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Saves market data settings.
   * @param request IMarketDataSettings object.
   */
  async saveMarketDataSettings(request: IMarketDataSettings): Promise<void> {
    try {
      await this.postAsync(request, `${COMPANY_MARKET_DATA_SETTINGS_PATH}`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Resets market data settings.
   * @returns IMarketDataSettings object.
   */
  async resetMarketDataSettings(): Promise<IMarketDataSettings> {
    try {
      const result = await this.getAsync<IMarketDataSettings>(null, `${COMPANY_MARKET_DATA_SETTINGS_PATH}/default`);
      return result;
    }
    catch (error) {
      this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets model customization settings.
   * @returns IModelCustomizationSettings object.
   */
  async getModelCustomizationSettings(): Promise<IModelCustomizationSettings> {
    try {
      const result = await this.getAsync<IModelCustomizationSettings>(null, `${COMPANY_MODEL_CUSTOMIZATION_SETTINGS_PATH}`);
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Save model customization settings.
   * @param request ISaveModelCustomizationSettingsRequest object.
   */
  async saveModelCustomizationSettings(request: ISaveModelCustomizationSettingsRequest): Promise<void> {
    try {
      await this.postAsync(request, `${COMPANY_MODEL_CUSTOMIZATION_SETTINGS_PATH}`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Reset model customization settings.
   * @returns IModelCustomizationSettings object.
   */
  async resetModelCustomizationSettings(): Promise<IModelCustomizationSettings> {
    try {
      const result = await this.getAsync<IModelCustomizationSettings>(null, `${COMPANY_MODEL_CUSTOMIZATION_SETTINGS_PATH}/default`);
      return result;
    }
    catch (error) {
      this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets valuation settings.
   * @returns IValuationSettingsResponse object.
   */
  async getValuationSettings(): Promise<IValuationSettingsResponse> {
    try {
      const result = await this.getAsync<IValuationSettingsResponse>(null, '/valuationSettings');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Get valuation environment settings.
   * @returns 
   */
  async getValuationEnvironmentSettings(): Promise<IValuationEnvironmentSettings> {
    try {
      return await this.getAsync<IValuationEnvironmentSettings>(null, '/valuationEnvironmentSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Saves valuation settings.
   * @param request ISaveValuationSettingsRequest object.
   */
  async saveValuationSettings(request: ISaveValuationSettingsRequest): Promise<void> {
    try {
      await this.postAsync(request, '/valuationSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Resets valuation settings.
   * @returns IValuationSettingsResponse object.
   */
  async resetValuationSettings(): Promise<IValuationSettingsResponse> {
    try {
      const result = await this.getAsync<IValuationSettingsResponse>(null, '/valuationSettings/default');
      return result;
    }
    catch (error) {
      this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Get other settings from server
   */
  async getOtherSettings(): Promise<IOtherSettings> {
    try {
      return await this.getAsync<IOtherSettings>(null, '/otherSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Resets other settings.
   * @returns IOtherSettings object.
   */
  async resetOtherSettings(): Promise<IOtherSettings> {
    try {
      return await this.getAsync<IOtherSettings>(null, '/otherSettings/default');
    }
    catch (error) {
      this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Get other section settings from server
   */
  async getOtherEnvironmentSettings(): Promise<IOtherEnvironmentSettings> {
    try {
      return await this.getAsync<IOtherEnvironmentSettings>(null, '/otherEnvironmentSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Send save request and sets sourceId value
   * @param request Save other settings request
   */
  async saveOtherSettings(request: ISaveOtherSettingsRequest): Promise<void> {
    try {
      request.sourceId = this.instanceId;
      await this.postAsync(request, '/otherSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Get all pricing environments settings
   * @returns Array of pricing enviroments settings
   */
  async getEnvironmentsSettings(): Promise<IPricingEnvironmentSettings[]> {
    try {
      return await this.getAsync<IPricingEnvironmentSettings[]>(null, '/environmentSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Save pricing environment settings
   * @param request Save pricing environment settings request
   */
  async savePricingEnvironmentSettings(settings: IPricingEnvironmentSettings): Promise<void> {
    try {
      await this.putAsync(settings, '/environmentSettings');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Save pricing environment order settings
   * @param request Save pricing environment order settings request
   */
  async savePricingEnvironmentOrderSettings(settings: IPricingEnvironmentOrderSettings): Promise<void> {
    try {
      await this.putAsync(settings, '/environmentSettings/order');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets yield curve settings data.
   * @returns ISystemYieldCurvesSettingsView object.
   */
  async getYieldCurveSettingsData(): Promise<ISystemYieldCurvesSettingsView> {
    try {
      return await this.getAsync<ISystemYieldCurvesSettingsView>(null, '/systemYieldCurveSettingsData');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets all tenors.
   * @returns List of all tenors.
   */
  async getAllTenors(): Promise<ITenor[]> {
    try {
      return await this.getAsync<ITenor[]>(null, '/tenors');
    }
    catch (error) {
      this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }
}
