import { v4 } from 'uuid';

import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

// Tokens
import { APPLICATION_INSTANCE_ID } from '@lv-core-ui/models';

// Modules
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ApplicationSettingsModule } from '@lv-application-settings/application-settings.module';
import { SecurityModule } from '@lv-security/security.module';
import { environment } from 'src/environments/environment';

// External Components
import { AnotherInstanceComponent } from '@lv-views/external/another-instance/another-instance.component';
import { DefaultComponentStateService } from '@lv-views/internal/instrument/instrument.state';
import { ExternalComponent } from '@lv-views/external/external.component';
import { UnauthorizedComponent } from '@lv-views/external/unauthorized/unauthorized.component';
import { PageNotFoundComponent } from '@lv-views/external/page-not-found/page-not-found.component';
import { NotSupportedBrowserComponent } from '@lv-views/external/not-supported-browser/not-supported-browser.component';
import { AnotherInstanceModalComponent } from '@lv-views/external/another-instance-modal/another-instance-modal.component';

// Kendo Modules
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { TooltipSettings } from '@progress/kendo-angular-tooltip';

// Error Services
import { LvApplicationVersionService, LvDateService, LvErrorService, LvLoggerService, LvPermissionService } from '@lv-core-ui/services';
import { LvUnexpectedErrorHandler } from '@lv-core-ui/services/lv-error/lv-unexpected-error.handler';

// Other Services
import { LvLocalization } from '@lv-core-ui/util';
import { OnlinePlatformHubService } from './services/online-platform-hub/online-platform-hub.service';
import { LvSharedMarketDataService } from '@lv-shared/services/shared-market-data.service';
import { LvSharedUserActivityService } from '@lv-shared/services/shared-user-activity.service';
import { UserActivityService } from '@lv-user-activity/services/user-activity.service';
import { CommonDataService } from '@lv-common/common-data';

// Core UI Modals
import { LeversysCoreUIModalsModule } from '@lv-core-ui/leversys-core-ui-modals.module';

// Route caching
import { InternalModule } from '@lv-views/internal/internal.module';
import { RouteCacheService } from '@lv-cache/route-cache/route-cache.service';
import { RouteCacheStrategy } from '@lv-cache/route-cache/route-cache.strategy';

// Localization
import '@progress/kendo-angular-intl/locales/en/all';
import localeEn from '@angular/common/locales/en';
import '@progress/kendo-angular-intl/locales/en-GB/all';
import localeenGb from '@angular/common/locales/en-GB';
import '@progress/kendo-angular-intl/locales/sr/all';
import localeSr from '@angular/common/locales/sr';
import '@progress/kendo-angular-intl/locales/nb/all';
import localeNb from '@angular/common/locales/nb';
import '@progress/kendo-angular-intl/locales/sv/all';
import localeSv from '@angular/common/locales/sv';
import '@progress/kendo-angular-intl/locales/zh/all';
import localeZh from '@angular/common/locales/zh';
import '@progress/kendo-angular-intl/locales/fr/all';
import localeFr from '@angular/common/locales/fr';
import '@progress/kendo-angular-intl/locales/nl/all';
import localeNl from '@angular/common/locales/nl';
import '@progress/kendo-angular-intl/locales/it/all';
import localeIt from '@angular/common/locales/it';
import '@progress/kendo-angular-intl/locales/es/all';
import localeEs from '@angular/common/locales/es';
import '@progress/kendo-angular-intl/locales/de/all';
import localeDe from '@angular/common/locales/de';
import '@progress/kendo-angular-intl/locales/nb/all';
import localeNo from '@angular/common/locales/nb';
import '@progress/kendo-angular-intl/locales/af/all';
import localeAf from '@angular/common/locales/af';
import '@progress/kendo-angular-intl/locales/lb/all';
import localeLb from '@angular/common/locales/lb';
import '@progress/kendo-angular-intl/locales/he/all';
import localeHe from '@angular/common/locales/he';
import '@progress/kendo-angular-intl/locales/pt/all';
import localePt from '@angular/common/locales/pt';
import '@progress/kendo-angular-intl/locales/da/all';
import localeDa from '@angular/common/locales/da';
import '@progress/kendo-angular-intl/locales/fi/all';
import localeFi from '@angular/common/locales/fi';
import '@progress/kendo-angular-intl/locales/zh-Hans/all';
import localeZhHans from '@angular/common/locales/zh-Hans';
import '@progress/kendo-angular-intl/locales/zh-Hans-HK/all';
import localeZhHk from '@angular/common/locales/zh-Hans-HK';
import '@progress/kendo-angular-intl/locales/zh-Hans-SG/all';
import localeZhSg from '@angular/common/locales/zh-Hans-SG';
import '@progress/kendo-angular-intl/locales/ja/all';
import localeJa from '@angular/common/locales/ja';
import '@progress/kendo-angular-intl/locales/ko/all';
import localeKo from '@angular/common/locales/ko';
import '@progress/kendo-angular-intl/locales/hi/all';
import localeHi from '@angular/common/locales/hi';
import '@progress/kendo-angular-intl/locales/vi/all';
import localeVi from '@angular/common/locales/vi';
import '@progress/kendo-angular-intl/locales/en-AU/all';
import localeEnAu from '@angular/common/locales/en-AU';
import '@progress/kendo-angular-intl/locales/en-CA/all';
import localeEnCa from '@angular/common/locales/en-CA';



import { MarketDataService } from '@lv-market-data/services';
import { LvSharedPermissionsService } from '@lv-shared/services/shared-permissions-service';
import { LvPermissionsService } from '@lv-security/services/permissions/lv-permissions-service.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LvExcelService } from '@lv-excel/services';
import { ApiService } from '@lv-excel/services/api-service';
import { ICON_SETTINGS, IconSettingsService } from '@progress/kendo-angular-icons';
import { LvIconService } from '@lv-core-ui/services/lv-icon/lv-icon.service';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeenGb, 'en-GB');
registerLocaleData(localeSr, 'sr');
registerLocaleData(localeNb, 'nb');
registerLocaleData(localeSv, 'sv');
registerLocaleData(localeZh, 'zh');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeNo, 'nb');
registerLocaleData(localeAf, 'af');
// lb supported in Edge, but not in Chrome as preferred language
// registerLocaleData(localeLb, 'lb');
registerLocaleData(localeHe, 'he');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeDa, 'da');
registerLocaleData(localeFi, 'fi');
registerLocaleData(localeZhHans, 'zh-Hans');
// zh-Hans-HK,zh-Hans-SG  not supported neither in Edge nor in Chrome
// registerLocaleData(localeZhHk, 'zh-Hans-HK');
// registerLocaleData(localeZhSg, 'zh-Hans-SG');
registerLocaleData(localeJa, 'ja');
registerLocaleData(localeKo, 'ko');
registerLocaleData(localeVi, 'vi');
registerLocaleData(localeEnAu, 'en-AU');
registerLocaleData(localeEnCa, 'en-CA');
//hi- Object reference not set to an instance of an object- when trying to create Custom instrument;
// open Leversys convertibe, performing calculation works;
// registerLocaleData(localeHi, 'hi');


@NgModule({
  declarations: [
    AppComponent,
    ExternalComponent,
    UnauthorizedComponent,
    PageNotFoundComponent,
    NotSupportedBrowserComponent,
    AnotherInstanceComponent,
    AnotherInstanceModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    ToastrModule.forRoot({
      disableTimeOut: environment.ngxToastr.disableTimeOut,
      enableHtml: true
    }),

    // Kendo modules
    InputsModule,
    LabelModule,
    DropDownsModule,
    DateInputsModule,
    ButtonsModule,

    // Core UI Modals
    LeversysCoreUIModalsModule,

    // Internal Module
    InternalModule,

    // Other
    SecurityModule.forRoot(environment.identityServer.apiUrl),
    ApplicationSettingsModule.forRoot(),
    AppRoutingModule,
    LoggerModule.forRoot({
      serverLoggingUrl: environment.errorLoggerUrl,
      level: NgxLoggerLevel.ERROR,
      serverLogLevel: NgxLoggerLevel.INFO,
    })
  ],
  providers: [
    {
      provide: APPLICATION_INSTANCE_ID,
      useFactory: () => v4()
    },
    {
      provide: TooltipSettings,
      useFactory: (): TooltipSettings => ({
        showOn: environment.showTooltips ? 'hover' : 'none',
      } as TooltipSettings)
    },
    LvErrorService,
    LvDateService,
    LvLoggerService,
    LvApplicationVersionService,
    {
      provide: LOCALE_ID,
      useFactory: () => LvLocalization.getBrowserLocale()
    },
    RouteCacheService,
    {
      provide: RouteReuseStrategy,
      useClass: RouteCacheStrategy
    },
    LvPermissionService,
    OnlinePlatformHubService,
    {
      provide: LvSharedMarketDataService,
      useClass: MarketDataService
    },
    DeviceDetectorService,
    { provide: ErrorHandler,
      useClass: LvUnexpectedErrorHandler
    },
    {
      provide: LvSharedUserActivityService,
      useClass: UserActivityService
    },
    {
      provide: LvSharedPermissionsService,
      useClass: LvPermissionsService
    },
    // State Services
    DefaultComponentStateService,
    CommonDataService,
    LvExcelService,
    ApiService,
    { provide: ICON_SETTINGS, useValue: { type: 'font' } },
    { provide: IconSettingsService, useClass: LvIconService },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
