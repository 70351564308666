/**
 * Interest rate source description.
 */
export enum InterestRateSourceDescription {
    YieldCurve = 'Yield Curve',
    Flat = 'Flat Rate'
}

/**
 * Interest rates source.
 */
export enum InterestRateSource {
    YieldCurve = 'YieldCurve',
    Flat = 'Flat'
}

/**
 * Yield curve source.
 */
export enum YieldCurveSource {
    Leversys = 'Leversys',
    Custom = 'Custom'
}

/**
 * Yield curve source description.
 */
export enum YieldCurveSourceDescription {
    Leversys = 'Leversys ({0} Swap)',
    Custom = 'Custom'
}

export const formatLeversysYieldCurve = (currencyCode?: string): string => {
    return YieldCurveSourceDescription.Leversys.replace('{0}', currencyCode || 'Inst CCY');
};
