import { v4 } from 'uuid';

import { LvAdvancedGridNumericColumn } from '@lv-core-ui/components';
import { DateExtensions } from '@lv-core-ui/util';
import { IVolatilitySurfacePointItem, VolatilitySurfaceSchedule } from '@lv-analytics/models/market-data/volatility';

/**
 * Volatility surface view.
 */
export class LvVolatilitySurfaceView {

  useStrikeVolId: string;
  moneyneesSurface: string;
  strikeSurface: string;
  maturityDateSurface: string;
  tenorTypeSurface: string;

  constructor() {
    this.useStrikeVolId = v4();
    this.moneyneesSurface = v4();
    this.strikeSurface = v4();
    this.maturityDateSurface = v4();
    this.tenorTypeSurface = v4();
  }

  /**
   * Creates numeric column.
   * @param field Field.
   * @returns LvAdvancedGridNumericColumn object.
   */
  createNumericColumn(field: string, columnDmKey: string): LvAdvancedGridNumericColumn {
    const column = new LvAdvancedGridNumericColumn();
    column.field = field;
    column.title = ' ';
    column.width = 60;
    column.dmKey = columnDmKey;

    column.isNumberFormat = (rowIndex, columnIndex) => {
      if (rowIndex === 0) {
        return false;
      }

      return true;
    };

    column.isPercentFormat = (rowIndex, columnIndex) => {
      if (rowIndex === 0) {
        return true;
      }

      return false;
    };

    return column;
  }

  /**
   * Maps volatility surface points.
   * @param points List of IVolatilitySurfacePointItem objects.
   * @returns List of VolatilitySurfaceSchedule objects.
   */
  mapVolatilitySurfacePoints(points: IVolatilitySurfacePointItem[]): VolatilitySurfaceSchedule[] {
    const scheduleItems = [];
    const scheduleMap = new Map<number, number[]>();

    points.forEach(item => {
      if (!scheduleMap[item.date]) {
        scheduleMap[item.date] = [];
      }

      scheduleMap[item.date].push(item.value);
    });

    new Set(points.map(item => item.date)).forEach(dateInMs => {
      const schedule = new VolatilitySurfaceSchedule();
      schedule.date = DateExtensions.getDateWithoutOffset(dateInMs);

      const argumentValues = scheduleMap[dateInMs];

      argumentValues.forEach((a: number, i: number) => {
        schedule[this.getSurfacePointItemField(i)] = a;
      });

      scheduleItems.push(schedule);
    });

    return scheduleItems;
  }

  /**
   * Maps volatility surface schedule.
   * @param schedule List of VolatilitySurfaceSchedule objects.
   * @returns List of IVolatilitySurfacePointItem objects.
   */
  mapVolatilitySurfaceSchedule(schedule: VolatilitySurfaceSchedule[]): IVolatilitySurfacePointItem[] {
    const surfacePointItems = [];
    const surfacePointItemFields = Object.keys(new VolatilitySurfaceSchedule())
      .filter(key => key.startsWith('v'));

    const argumentsRow = schedule[0];
    const withoutArgumentsRows = schedule.filter(x => x !== argumentsRow);

    withoutArgumentsRows.forEach(x => {
      surfacePointItemFields.forEach(f => {
        surfacePointItems.push({
          date: DateExtensions.getTimeWithOffset(x.date),
          value: x[f],
          argument: argumentsRow[f]
        });
      });
    });

    return surfacePointItems;
  }

  /**
   * Maps surface point item arguments.
   * @param argumentValues Argument values.
   * @returns VolatilitySurfaceSchedule object.
   */
  mapSurfacePointItemArguments(argumentValues?: number[]): VolatilitySurfaceSchedule {
    const transformed = new VolatilitySurfaceSchedule();
    transformed.initializeDefaults();

    if (argumentValues && argumentValues.length > 0) {
      argumentValues.forEach((value: number, i: number) => {
        if (value) {
          transformed[this.getSurfacePointItemField(i)] = value;
        }
      });
    }

    transformed.date = new Date(0);
    return transformed;
  }

  /**
   * Gets surface point item field.
   * @param i
   * @returns Surface poitn item field.
   */
  private getSurfacePointItemField(i: number): string {
    return `v${80 + (i * 5)}`;
  }
}
