<lv-terms-section-settings
    [systemDefault]="systemDefaults"
    [myDefault]="myDefaults"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="takeoverProtectionOverrideSystemDefaultsSettingsTooltip"
    filter="div[data-tooltip-id]">
  <ng-template systemDefaultsSettings let-systemSettings>
    <lv-takeover-protection-section-settings
    [settings]="systemSettings"
    [makeWholeOfferAndCompensation]="systemMakeWholeOfferAndCompensation">
    </lv-takeover-protection-section-settings>
  </ng-template>

  <ng-template myDefaultsSettings let-settings>
    <lv-takeover-protection-section-settings
      [settings]="settings"
      [overrideDefaults]="settings.overrideSystemDefaults"
      [makeWholeOfferAndCompensation]="myMakeWholeOfferAndCompensation">
    </lv-takeover-protection-section-settings>
  </ng-template>
</lv-terms-section-settings>

<ng-template #takeoverProtectionOverrideSystemDefaultsSettingsTooltip let-anchor>
  <ng-container *ngIf="getTootlipId(anchor, 'overrideTermsSystemDefaultsLabel')">
    <lv-tooltip dm="dM-1515"></lv-tooltip>
  </ng-container>
</ng-template>
