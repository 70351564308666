<div class="lv-document"
  tabindex="0">
  <lv-loader *ngIf="isLoading"></lv-loader>
  <div class="lv-document-controls">
    <div class="lv-document-control lv-document-close"
      (click)="onClose()">
      <i class="k-icon k-i-arrow-chevron-left"></i>
    </div>
    <i class="lv-document-control k-icon k-i-plus"
        (click)="onZoom(0.1)"></i>
    <i class="lv-document-control k-icon k-i-minus"
      (click)="onZoom(-0.1)"></i>        
    <i class="lv-document-control k-icon k-i-full-screen"
      (click)="onResetZoom()"></i>

    <div class="lv-document-control-spacer"></div>

    <i *ngIf="canDownloadDocument" 
      class="lv-document-control k-icon k-i-download"
      [class.is-downloading]="isDocumentDownloading"
      (click)="onDownload()"></i>
  </div>
  <div class="lv-document-bookmarks">    
    <div class="lv-document-search">
      <input #searchElement
        kendoTextBox
        placeholder="Search" 
        (input)="onQueryChange($event, $event.target.value)"
        (keyup.enter)="onSearchNext()"/>
        <i *ngIf="showClearIcon"
          class="k-icon k-i-close"
          (click)="onClearSearch()"></i>    
    </div>
    <div *ngIf="showSearchResult"
      class="lv-document-search-navigator">        
      <span *ngIf="showMatchCount"
        class="lv-document-search-result">
        {{ searchMatch.current }} / {{ searchMatch.total }}
      </span>
      <i class="k-icon k-i-arrow-chevron-down"
        (click)="onSearchNext()">
      </i>
      <i class="k-icon k-i-arrow-chevron-up"
        (click)="onSearchPrevious()">
      </i>      
    </div>
    <ng-container
      *ngFor="let item of outline">
      <div class="lv-document-bookmark"
        (click)="navigateTo(item.dest)">
        {{ item.title }}
      </div>
      <ng-container *ngFor="let child of item.items">
        <div class="lv-document-bookmark-child"
          (click)="navigateTo(child.dest)">
          {{ child.title }}
        </div>
      </ng-container>
    </ng-container>
  </div>
  
  <pdf-viewer class="lv-document-pdf-viewer"
    [class.lv-document-can-select]="canDownloadDocument"
    [src]="pdfSrc"
    [render-text-mode]="2"
    (after-load-complete)="onAfterLoadComplete($event)"
    (error)="onError($event)">
  </pdf-viewer>  
  <a #anchorElement class="lv-document-download-anchor" ></a>
</div>
