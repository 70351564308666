import { LvUserFriendlyError } from '@lv-core-ui/models';

/**
 * User-friendly error for user activity module.
 */
export class LvUserActivityError extends LvUserFriendlyError {
  constructor(message: string) {
    super(message, 'User Activity');
  }
}
