
<lv-flex-box class="lv-equity-stochastic-credit-model">
  <div class="lv-label lv-label-field lv-label--title">Stochastic Credit Model</div>
  <form #form="ngForm">

    <lv-flex-box direction="row" data-cy="DM-247">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditVolatilityTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'creditVolatilityTooltip'">
            Credit Volatility
          </span>
          <lv-xl-label *ngIf="!hasCreditStochasticCreditRangeOverridedFromExcel" [field]="'CREDIT_VOL'"></lv-xl-label>
          <lv-xl-label *ngIf="hasCreditStochasticCreditRangeOverridedFromExcel" [field]="'CREDIT_STOCH_RANGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field ">
        <lv-numeric-text-box
          name="creditVol"
          [format]="numberFormat"
          [decimals]="numberOfDecimalsPercetenge"
          [(ngModel)]="stochasticCreditModel.creditVol"
          (didBlur)="onStochasticCreditChange()"
          (didKeyDownEnter)="onStochasticCreditChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>

    <lv-flex-box direction="row" data-cy="DM-248">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditStockCorrelationTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'creditStockCorrelationTooltip'">
            Credit-Stock Correlation
          </span>
          <lv-xl-label *ngIf="!hasCreditStochasticCreditRangeOverridedFromExcel" [field]="'CREDIT_STCK_CORR'"></lv-xl-label>
          <lv-xl-label *ngIf="hasCreditStochasticCreditRangeOverridedFromExcel" [field]="'CREDIT_STOCH_RANGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field ">
        <lv-numeric-text-box
          name="creditStockCorrelation"
          [format]="numberFormat"
          [decimals]="numberOfDecimalsPercetenge"
          [(ngModel)]="stochasticCreditModel.creditStockCorrelation"
          (didBlur)="onStochasticCreditChange()"
          (didKeyDownEnter)="onStochasticCreditChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>

    <lv-flex-box direction="row" data-cy="DM-249">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditRevertingIntensityTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'creditRevertingIntensityTooltip'">
            Credit Reverting Intensity
          </span>
          <lv-xl-label *ngIf="!hasCreditStochasticCreditRangeOverridedFromExcel" [field]="'CREDIT_RVR_INTENSITY'"></lv-xl-label>
          <lv-xl-label *ngIf="hasCreditStochasticCreditRangeOverridedFromExcel" [field]="'CREDIT_STOCH_RANGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field ">
        <lv-numeric-text-box
          name="creditMeanReversion"
          [format]="numberFormatIntensity"
          [decimals]="numberOfDecimalsIntensity"
          [(ngModel)]="stochasticCreditModel.creditMeanReversion"
          (didBlur)="onStochasticCreditChange()"
          (didKeyDownEnter)="onStochasticCreditChange()">
        </lv-numeric-text-box>
      </div>
    </lv-flex-box>

    <lv-flex-box direction="row" data-cy="DM-250">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditMeanValueTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'creditMeanValueTooltip'">
            Credit Mean Value
          </span>
          <lv-xl-label *ngIf="!hasCreditStochasticCreditRangeOverridedFromExcel" [field]="'CREDIT_MEAN'"></lv-xl-label>
          <lv-xl-label *ngIf="hasCreditStochasticCreditRangeOverridedFromExcel" [field]="'CREDIT_STOCH_RANGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="creditTarget"
          [format]="numberFormatBps"
          [decimals]="numberOfDecimalsBps"
          [(ngModel)]="stochasticCreditModel.creditTarget"
          (didBlur)="onStochasticCreditChange()"
          (didKeyDownEnter)="onStochasticCreditChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">bps</span>
      </div>
    </lv-flex-box>
  </form>
</lv-flex-box>

<ng-template #creditVolatilityTooltipTemplate>
  <lv-tooltip dm="dM-247"></lv-tooltip>
</ng-template>
<ng-template #creditStockCorrelationTooltipTemplate>
  <lv-tooltip dm="dM-248"></lv-tooltip>
</ng-template>
<ng-template #creditRevertingIntensityTooltipTemplate>
  <lv-tooltip dm="dM-249"></lv-tooltip>
</ng-template>
<ng-template #creditMeanValueTooltipTemplate>
  <lv-tooltip dm="dM-250"></lv-tooltip>
</ng-template>

