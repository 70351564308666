/**
 * Pricing environment sections.
 */
export enum PricingEnvironmentSections {
  Pricing = 'pricing',
  InterestRates = 'interestRates',
  Dividends = 'dividends',
  Borrow = 'borrow',
  Funding = 'funding',
  WitholdingTax = 'witholdingTax',
  ModelSettings = 'modelSettings',
  Volatility = 'volatility',
  Credit = 'credit',
  AveragingData = 'averagingData',
  Other = 'other'
}
