import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { InternalComponentService } from '@lv-views/internal';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'settings',
  templateUrl: './settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements OnInit {

  constructor(
    private _internalService: InternalComponentService
  ) { }

  ngOnInit() {
    this._internalService.toggleMenu(false);
  }

  redirectToDashboard() {
    location.href = '/dashboard/workspaces';
  }
}
