import { Component, OnInit, Input, ChangeDetectionStrategy, ViewEncapsulation, HostBinding } from '@angular/core';

import { ILvWorkspace } from '../lv-workspace/lv-workspace';

@Component({
  selector: 'lv-workspace-panel-dialog',
  templateUrl: './lv-workspace-panel-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvWorkspacePanelDialogComponent implements OnInit {

  @Input() item: ILvWorkspace;

  constructor() { }

  ngOnInit() {
  }

  @HostBinding('class.lv-workspace-panel-dialog')
  get isLvWorkspacePanelDialogComponent() {
    return true;
  }
}
