import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { TabStripModule } from '@progress/kendo-angular-layout';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LeversysCoreUIModule } from '@lv-core-ui/leversys-core-ui.module';
import { LvTermsGeneralComponent, LvConvertibleBondTermsComponent, LvAccretionComponent, LvPutsComponent, LvPutsPutParametersComponent,
         LvPutsPutScheduleComponent, LvMakeWholeComponent, LvMakeWholeCallParametersComponent, LvMakeWholeConversionParametersComponent,
         LvMakeWholeRatchetMatrixComponent, LvTermsGeneralNotesComponent,
         LvTermsGeneralFxInformationComponent, LvTermsGeneralSinkingScheduleComponent, LvCouponComponent, LvCoPayCouponComponent,
         LvFloatingCouponComponent, LvPikCouponComponent, LvCustomCouponComponent, LvFixedCouponComponent, LvProjectedCouponsComponent,
         LvCallsComponent, LvConversionComponent, LvDividendProtectionComponent, LvResetConversionComponent,
         LvContingentConversionComponent, LvVariableConversionComponent, LvPepsConversionComponent, LvPercsConversionComponent,
         LvConversionScheduleComponent, LvExerciseScheduleComponent, LvFloatingSpreadScheduleComponent, LvIdentifiersComponent,
         LvFloatingFixingHistoryScheduleComponent, LvFloatingCustomDatesScheduleComponent, LvStepUpCouponScheduleComponent,
         LvPikCustomScheduleComponent, LvCoPayScheduleComponent, LvCallsScheduleComponent, LvTakeoverProtectionComponent,
         LvAveragingPeriodDetailsComponent, LvPremiumCompensationFormulaComponent, LvRatchetScheduleComponent,
         LvTakeoverPutParametersComponent, LvQuickTermsEntryComponent, LvDividendProtectionScheduleComponent } from './components';
import { ConvertibleBondTermsService } from './services';
// tslint:disable-next-line: max-line-length
import { LvTermsGeneralIssueInformationComponent } from './components/lv-convertible-bond-terms/lv-terms-general/lv-terms-general-issue-information/lv-terms-general-issue-information.component';
import { LvGeneralSettingsComponent } from './components/lv-terms-settings/lv-general-settings/lv-general-settings.component';
import { LvCouponSettingsComponent } from './components/lv-terms-settings/lv-coupon-settings/lv-coupon-settings.component';
import { LvAccreationSettingsComponent } from './components/lv-terms-settings/lv-accreation-settings/lv-accreation-settings.component';
import { LvCallSettingsComponent } from './components/lv-terms-settings/lv-call-settings/lv-call-settings.component';
import { LvPutSettingsComponent } from './components/lv-terms-settings/lv-put-settings/lv-put-settings.component';
import { LvConversionSettingsComponent } from './components/lv-terms-settings/lv-conversion-settings/lv-conversion-settings.component';
// tslint:disable-next-line: max-line-length
import { LvDividendProtectionSettingsComponent } from './components/lv-terms-settings/lv-dividend-protection-settings/lv-dividend-protection-settings.component';
import { LvMakeWholeSettingsComponent } from './components/lv-terms-settings/lv-make-whole-settings/lv-make-whole-settings.component';
// tslint:disable-next-line: max-line-length
import { LvTakeoverProtectionSettingsComponent } from './components/lv-terms-settings/lv-takeover-protection-settings/lv-takeover-protection-settings.component';
// tslint:disable-next-line: max-line-length
import { LvGeneralSectionSettingsComponent } from './components/lv-terms-settings/lv-general-settings/lv-general-section-settings/lv-general-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvTermsSectionSettingsComponent } from './components/lv-terms-settings/lv-terms-section-settings/lv-terms-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvSystemDefaultsSettingsDirective } from './components/lv-terms-settings/lv-terms-section-settings/directives/lv-system-defaults.directive';
// tslint:disable-next-line: max-line-length
import { LvMyDefaultsSettingsDirective } from './components/lv-terms-settings/lv-terms-section-settings/directives/lv-my-defaults.directive';
// tslint:disable-next-line: max-line-length
import { LvCouponSectionSettingsComponent } from './components/lv-terms-settings/lv-coupon-settings/lv-coupon-section-settings/lv-coupon-section-settings.component';
import { LvOtherComponent } from './components/lv-convertible-bond-terms/lv-other/lv-other.component';
import { LvEstimatesComponent } from './components/lv-convertible-bond-terms/lv-estimates/lv-estimates.component';
// tslint:disable-next-line: max-line-length
import { LvConversionRatioHistoryComponent } from './components/lv-convertible-bond-terms/lv-conversion/lv-conversion-ratio-history/lv-conversion-ratio-history.component';
// tslint:disable-next-line: max-line-length
import { LvCarryForwardHistoryComponent } from './components/lv-convertible-bond-terms/lv-conversion/lv-carry-forward-history/lv-carry-forward-history.component';
// tslint:disable-next-line: max-line-length
import { LvAccretionSectionSettingsComponent } from './components/lv-terms-settings/lv-accreation-settings/lv-accretion-section-settings/lv-accretion-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvCallSectionSettingsComponent } from './components/lv-terms-settings/lv-call-settings/lv-call-section-settings/lv-call-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvPutSectionSettingsComponent } from './components/lv-terms-settings/lv-put-settings/lv-put-section-settings/lv-put-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvConversionSectionSettingsComponent } from './components/lv-terms-settings/lv-conversion-settings/lv-conversion-section-settings/lv-conversion-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvDividendProtectionSectionSettingsComponent } from './components/lv-terms-settings/lv-dividend-protection-settings/lv-dividend-protection-section-settings/lv-dividend-protection-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvMakeWholeSectionSettingsComponent } from './components/lv-terms-settings/lv-make-whole-settings/lv-make-whole-section-settings/lv-make-whole-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvTakeoverProtectionSectionSettingsComponent } from './components/lv-terms-settings/lv-takeover-protection-settings/lv-takeover-protection-section-settings/lv-takeover-protection-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvCocoSectionSettingsComponent } from './components/lv-terms-settings/lv-conversion-settings/lv-conversion-section-settings/lv-coco-section-settings/lv-coco-section-settings.component';
// tslint:disable-next-line: max-line-length
import { LvResetSectionSettingsComponent } from './components/lv-terms-settings/lv-conversion-settings/lv-conversion-section-settings/lv-reset-section-settings/lv-reset-section-settings.component';
import { ExcelModule } from '@lv-excel/excel.module';

@NgModule({
  declarations: [
    LvTermsGeneralComponent,
    LvConvertibleBondTermsComponent,
    LvTermsGeneralComponent,
    LvAccretionComponent,
    LvPutsComponent,
    LvPutsPutParametersComponent,
    LvPutsPutScheduleComponent,
    LvMakeWholeComponent,
    LvMakeWholeCallParametersComponent,
    LvMakeWholeConversionParametersComponent,
    LvMakeWholeRatchetMatrixComponent,
    LvTermsGeneralIssueInformationComponent,
    LvTermsGeneralNotesComponent,
    LvTermsGeneralFxInformationComponent,
    LvTermsGeneralSinkingScheduleComponent,
    LvCouponComponent,
    LvCoPayCouponComponent,
    LvFloatingCouponComponent,
    LvPikCouponComponent,
    LvCustomCouponComponent,
    LvFixedCouponComponent,
    LvProjectedCouponsComponent,
    LvCallsComponent,
    LvConversionComponent,
    LvDividendProtectionComponent,
    LvResetConversionComponent,
    LvContingentConversionComponent,
    LvVariableConversionComponent,
    LvPepsConversionComponent,
    LvPercsConversionComponent,
    LvConversionScheduleComponent,
    LvExerciseScheduleComponent,
    LvFloatingSpreadScheduleComponent,
    LvFloatingFixingHistoryScheduleComponent,
    LvFloatingCustomDatesScheduleComponent,
    LvStepUpCouponScheduleComponent,
    LvPikCustomScheduleComponent,
    LvCoPayScheduleComponent,
    LvCallsScheduleComponent,
    LvTakeoverProtectionComponent,
    LvAveragingPeriodDetailsComponent,
    LvPremiumCompensationFormulaComponent,
    LvRatchetScheduleComponent,
    LvTakeoverPutParametersComponent,
    LvQuickTermsEntryComponent,
    LvDividendProtectionScheduleComponent,
    LvIdentifiersComponent,
    LvGeneralSettingsComponent,
    LvCouponSettingsComponent,
    LvAccreationSettingsComponent,
    LvCallSettingsComponent,
    LvPutSettingsComponent,
    LvConversionSettingsComponent,
    LvDividendProtectionSettingsComponent,
    LvMakeWholeSettingsComponent,
    LvTakeoverProtectionSettingsComponent,
    LvGeneralSectionSettingsComponent,
    LvTermsSectionSettingsComponent,
    LvSystemDefaultsSettingsDirective,
    LvMyDefaultsSettingsDirective,
    LvCouponSectionSettingsComponent,
    LvOtherComponent,
    LvEstimatesComponent,
    LvConversionRatioHistoryComponent,
    LvCarryForwardHistoryComponent,
    LvAccretionSectionSettingsComponent,
    LvCallSectionSettingsComponent,
    LvPutSectionSettingsComponent,
    LvConversionSectionSettingsComponent,
    LvDividendProtectionSectionSettingsComponent,
    LvMakeWholeSectionSettingsComponent,
    LvTakeoverProtectionSectionSettingsComponent,
    LvCocoSectionSettingsComponent,
    LvResetSectionSettingsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    // Kendo Modules
    TabStripModule,
    DropDownsModule,
    GridModule,
    DateInputsModule,
    ButtonsModule,
    TooltipModule,
    ExcelModule,
    // Core UI
    LeversysCoreUIModule
  ],
  exports: [
    LvConvertibleBondTermsComponent,
    LvGeneralSettingsComponent,
    LvCouponSettingsComponent,
    LvAccreationSettingsComponent,
    LvCallSettingsComponent,
    LvPutSettingsComponent,
    LvConversionSettingsComponent,
    LvDividendProtectionSettingsComponent,
    LvMakeWholeSettingsComponent,
    LvTakeoverProtectionSettingsComponent,
    LvGeneralSectionSettingsComponent,
    LvTermsSectionSettingsComponent,
    LvSystemDefaultsSettingsDirective,
    LvMyDefaultsSettingsDirective,
    LvCouponSectionSettingsComponent
  ],
  providers: [
    ConvertibleBondTermsService
  ]
})
export class ConvertibleBondTermsModule { }
