<lv-flex-box 
  data-cy="DM-1577"
  direction="row">

  <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="putValueTypeSourceTooltipTemplate"
      filter="span">
      <span> Put Value Type </span>
    </div>
  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      class="lv-puts-value-type"
      name="putValueType"
      [data]="putValueTypeLookup.items"
      valueField="id"
      [disabled]="!overrideDefaults"
      textField="text"
      [valuePrimitive]="true"
      [popupSettings]="{ width: 114 }"
      [(ngModel)]="settings.settings.valueType">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1578"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="putYieldFrequencySourceTooltipTemplate"
    filter="span">
    <span> Put Yield Frequency </span>
  </div>
  <div class="lv-input-field">
    <kendo-dropdownlist
      name="putYieldFrequency"
      [data]="putYieldFrequencyLookup.items"
      valueField="id"
      textField="text"
      [disabled]="!overrideDefaults"
      [valuePrimitive]="true"
      [(ngModel)]="settings.settings.yieldFrequency">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1579"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="yieldDayCountSourceTooltipTemplate"
    filter="span">
    <span> Put Yield Day Count </span>
  </div>
  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      name="yieldDayCount"
      [data]="yieldDayCountLookup.items"
      valueField="id"
      textField="text"
      [disabled]="!overrideDefaults"
      [valuePrimitive]="true"
      [(ngModel)]="settings.settings.yieldDaysCount">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1580"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="keepAccruedSourceTooltipTemplate"
    filter="span">
    <span> Keep Accrued </span>
  </div>
  <div class="lv-field lv-field--checkbox">
    <input class="k-checkbox"
      type="checkbox"
      [attr.id]="keepAccruedCheckBoxId"
      name="keepAccrued"
      [disabled]="!overrideDefaults"
      [(ngModel)]="settings.settings.keepAccrued"/>
    <label class="k-checkbox-label" [attr.for]="keepAccruedCheckBoxId"></label>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1581"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="forfeitCouponSourceTooltipTemplate"
    filter="span">
    <span> Forfeit Coupon </span>
  </div>
  <div class="lv-field lv-field--checkbox">
    <input class="k-checkbox"
      type="checkbox"
      [disabled]="!overrideDefaults"
      [attr.id]="forfeitCouponCheckBoxId"
      name="forfeitCoupon"
      [(ngModel)]="settings.settings.forfeitCoupon"/>
    <label class="k-checkbox-label" [attr.for]="forfeitCouponCheckBoxId"></label>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1582"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="putNoticeDaysSourceTooltipTemplate"
    filter="span">
    <span> Put Notice (days) </span>
  </div>
  <div class="lv-input-field">
    <lv-numeric-text-box
    name="putNotice"
    [format]="numberFormat"
    [disabled]="!overrideDefaults"
    [decimals]="numberOfDecimals"
    [(ngModel)]="settings.settings.notice">
    </lv-numeric-text-box>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1583"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="putNoticeDaysTypeSourceTooltipTemplate"
    filter="span">
    <span> Put Notice (days type) </span>
  </div>
  <div class="lv-input-field">
    <kendo-dropdownlist
      name="putNoticeDayType"
      lvId="putNoticeDayType"
      [disabled]="!overrideDefaults"
      [data]="noticePeriodLookup.items"
      valueField="id"
      textField="text"
      [valuePrimitive]="true"
      [(ngModel)]="settings.settings.noticePeriod">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<ng-template #putValueTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1577"></lv-tooltip>
</ng-template>
<ng-template #putYieldFrequencySourceTooltipTemplate>
  <lv-tooltip dm="dM-1578"></lv-tooltip>
</ng-template>
<ng-template #yieldDayCountSourceTooltipTemplate>
  <lv-tooltip dm="dM-1579"></lv-tooltip>
</ng-template>
<ng-template #keepAccruedSourceTooltipTemplate>
  <lv-tooltip dm="dM-1580"></lv-tooltip>
</ng-template>
<ng-template #forfeitCouponSourceTooltipTemplate>
  <lv-tooltip dm="dM-1581"></lv-tooltip>
</ng-template>
<ng-template #putNoticeDaysSourceTooltipTemplate>
  <lv-tooltip dm="dM-1582"></lv-tooltip>
</ng-template>
<ng-template #putNoticeDaysTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1583"></lv-tooltip>
</ng-template>
