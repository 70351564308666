export interface IInstrumentSourceSectionSettings {
  instrumentSource: InstrumentSourceType;
}

/**
 * Instrument source type.
 */
export enum InstrumentSourceType {
  Leversys = 'Leversys',
  Custom = 'Custom'
}
