import { Injectable } from '@angular/core';
import { ICountry } from '..';
import countries from '../mocks/countries-mock.json';

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {

  constructor() {
  }

  /**
   * Find country object based on country code inside mocked data
   * Also transform country name to capitilize first letter since it's all uppercase
   * @param countryCode Country short code
   * @returns ICountry object
   */
  getCountry(countryCode: string): ICountry {
    const selectedCountry = countries.countries.filter((country: ICountry) => country.countryCode === countryCode)[0];
    selectedCountry.countryName = selectedCountry.countryName.toLowerCase()
                                                             .split(' ')
                                                             .map((word: string) => word[0].toUpperCase() + word.substring(1))
                                                             .join(' ');
    return selectedCountry;
  }
}
