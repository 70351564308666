/**
 * Basic terms enum.
 */
export enum BasicTermsEnum {
    issuerName = 'Issuer',
    underlying = 'Underlying',
    currencyCode = 'CCY',
    nominal = 'Nominal',
    maturity = 'Maturity',
    couponType = 'Coupon Type',
    coupon = 'Coupon',
    couponFrequency = 'Coupon Frequency',
    call = 'Callable',
    put = 'Puttable',
    conversionRatio = 'Conversion Ratio',
    expectedCR = 'Expected CR',
    conversionPrice = 'Conversion Price',
    expectedCP = 'Expected CP',
    mandatoryConversion = 'Mandatory Conversion',
    variableConversion = 'Variable Conversion',
    dividendProtection = 'Dividend Protection',
    useTakeoverProtection = 'Takeover Protection',
    issueSize = 'Issue Size (USD)',
    region = 'Region',

    riskCountry = 'Risk Country',
    initialPremium = 'Initial Premium',
    fixedFxRate = 'Fixed FX',
    shortName = 'Inst Short Name',
    firstSettlement = 'First Settlement',
    redemptionValue = 'Redemption Price',
    exchangeableType = 'Exchangeable',
    issueSeries = 'Issue Series',
    sinkable = 'Sinkable',
    accrualMethod = 'Accrual Method',
    firstCouponPaymentDate = 'First Coupon',
    resettable = 'Resettable',
    contigentConversion = 'Contingent Conversion',
    protectionType = 'Dividend Protection Type',
    isin = 'ISIN',
    countryCode = 'Country',
    issueValue = 'Issue Price',
    percentOfPledgedShares = 'Pledged Shares',
    minConversionPrice = 'Min. Conversion Price',
    maxConversionRatio = 'Max. Conversion Ratio',
    minExpectedConversionPrice = 'Min. Expected CP',
    maxExpectedConversionRatio = 'Max. Expected CR',
    leversysId = 'Leversys ID',
    hasCoPay = 'Co Pay',
    underlyingIsin = 'Underlying ISIN',
    underlyingExchange = 'Underlying MIC',
    couponDayCount = 'Coupon Day Count',
    underlyingBloombergTicker = 'Und BBG Ticker',
    cusip = 'CUSIP',
    underlyingCUSIP = 'Underlying CUSIP',
    figi = 'FIGI',
    underlyingFIGI = 'Underlying FIGI',
    underlyingCompositeTicker = 'Und Comp BBG Ticker',
    permId = 'Perm ID',
    underlyingPermId = 'Underlying Perm ID',
    bondCode = 'Bond Code',
 }
