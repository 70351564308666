import { IAnalyticsSettings } from '../models/valuation-session/analytics-settings';
import { IConvertible } from '../models/convertible/convertible';
import { IValuationSession } from '../models/valuation-session/valuation-session';
import { IPricingEnvrionment } from '../models/company-and-user-settings/pricing-environment';
import { IOutputsConfiguration } from '../models/valuation-session/outputs-configuration';
import { IValuationSettings } from '../models/valuation-session/valuation-settings';
import { INewIssueAssumptions } from '../models/new-issue-assumption/user-new-issue-assumptions';
import { ISelectedEnvrionments } from '../models/company-and-user-settings/selected-envrionments';
import { IInstrumentEnvironmentSettings } from '../models/company-and-user-settings/instrument-environment-settings';
import { PricingEnvironmentSections } from '../models/enum/pricing-environment-sections';
import { IPricing } from '../models/valuation-session/pricing';
import { IModelCustomization } from '../models/model-customization/model-customization';
import { IMarketData } from '../models/market-data/market-data';
import { ConvertibleSetupStatus } from '../models/convertible/enum/convertible-setup-status';
import { ICrEstimateSettings } from '../models/company-and-user-settings/estimates-settings';
import { LvDateUtil, LvMath } from '@lv-core-ui/util';
import { ConversionScheduleItem } from '@lv-convertible-bond/models';

export interface IEnvironmentSectionSettings {
  envrionmentIds: string[];
  selectedEnvrionmentId: string;
}

export const getDefaultAnalyticsSettings = (settings?: IAnalyticsSettings): IAnalyticsSettings => {
  const model: IAnalyticsSettings = {
    convertible: null,
    valuationSession: null,
    availablePricingEnvironments: [],
    marketData: null,
    otherSettings: null
  };

  if (settings) {
    model.convertible = settings.convertible;
    model.availablePricingEnvironments = settings.availablePricingEnvironments;
    model.valuationSession = settings.valuationSession;
    model.marketData = settings.marketData;
    model.cbCcyUsdFxRate = settings.cbCcyUsdFxRate;
    model.otherSettings = settings.otherSettings;
  }

  return model;
};

/**
 * Analytics settings helper.
 */
export class AnalyticsSettingsHelper {
  private _settings: IAnalyticsSettings;

  constructor() {
    this._settings = getDefaultAnalyticsSettings();
  }

  get settings(): IAnalyticsSettings {
    return this._settings;
  }

  get marketData(): IMarketData {
    if (!this.settings) {
      return null;
    }

    return this._settings.marketData;
  }

  get sessionId(): string {
    if (!this._settings.valuationSession) {
      return null;
    }

    return this._settings.valuationSession.sessionId;
  }

  get lwsIdentifier(): string {
    if (!this._settings.valuationSession) {
      return null;
    }

    return this._settings.valuationSession.lwsIdentifier;
  }

  get isPrivateInstrument(): boolean {
    if (!this._settings.valuationSession) {
      return false;
    }

    return this._settings.valuationSession.isPrivateInstrument;
  }

  get convertible(): IConvertible {
    if (!this._settings.convertible) {
      return null;
    }

    return this._settings.convertible;
  }

  get valuationSession(): IValuationSession {
    if (!this._settings.valuationSession) {
      return null;
    }

    return this._settings.valuationSession;
  }

  get availablePricingEnvironments(): IPricingEnvrionment[] {
    if (!this._settings.availablePricingEnvironments) {
      return [];
    }

    return this._settings.availablePricingEnvironments;
  }

  get selectedEnvironments(): ISelectedEnvrionments {
    if (!this.valuationSession) {
      return null;
    }

    if (!this.valuationSession.selectedEnvironments) {
      return null;
    }

    return this.valuationSession.selectedEnvironments;
  }

  get instrumentEnvironmentSettings(): IInstrumentEnvironmentSettings {
    if (!this.valuationSession) {
      return null;
    }

    if (!this.valuationSession.instrumentSettings) {
      return null;
    }

    return this.valuationSession.instrumentSettings;
  }

  get outputsConfiguration(): IOutputsConfiguration {
    if (!this.valuationSession) {
      return null;
    }

    return this.valuationSession.outputsConfiguration;
  }

  get valuationSettings(): IValuationSettings {
    if (!this.valuationSession) {
      return null;
    }

    if (!this.valuationSession.valuationSettings) {
      return null;
    }

    return this.valuationSession.valuationSettings;
  }

  get instrumentLoaded(): boolean {
    if (this.convertible && this.valuationSettings) {
      return true;
    }

    return false;
  }

  get cbCcyUsdFxRate(): number {
      return this.settings.cbCcyUsdFxRate;
  }

  get pricing(): IPricing {
    if (!this.valuationSession) {
      return null;
    }

    if (this.valuationSession.pricing && !this.valuationSession.pricing.valuationDate) {
      this.valuationSession.pricing.valuationDate = new Date();
    }


    return this.valuationSession.pricing;
  }

  get cpeSettings(): ICrEstimateSettings {
    if (!this.valuationSession) {
      return null;
    }

    return this.valuationSession.pricing.crEstimateSettings;
  }

  get niaSettings(): INewIssueAssumptions {
    if (!this.valuationSession) {
      return null;
    }

    return this.valuationSession.pricing.newIssueAssumptions;
  }

  get modelSettings(): IModelCustomization {
    if (!this.valuationSession) {
      return null;
    }

    return this.valuationSession.modelCustomization;
  }

  get currentLwsIdentifier(): string {
    return this._settings.convertible.lwsIdentifier;
  }

  get currentConvertibleInfoId(): number {
    return this._settings.convertible.convertibleInfoId;
  }

  get getConvertiblePosition(): number {
    if (!this.convertible) {
      return null;
    }

    const qsv = this.quantitySettingsValue;

    if (!qsv) {
      return this.convertible.isPriceAsPar ? this.convertible.nominal : 1;
    }

    return qsv;
  }

  get quantitySettingsValue(): number {
    const qsv = this.quantitySettings;

    if (!qsv) {
      return null;
    }

    const nominal = this.convertible.nominal;

    if (this.convertible.isPriceAsPar) {
      if (nominal >= qsv) {
        return nominal;
      }

      const divisionRest = qsv % nominal;

      if (divisionRest === 0) {
        return qsv;
      }
      else {
        return (Math.floor(qsv / nominal) + 1) * nominal;
      }
    }
    else {
      if (nominal > qsv) {
        return null;
      }
      else {
        return Math.round(qsv / nominal);
      }
    }
  }

  get quantitySettings(): number {
    const cbCcy = this._settings.convertible.currencyCode;
    if (!this._settings.otherSettings) {
      return null;
    }

    const qs = this._settings.otherSettings.quantitySettings;

    if (!qs.useQuantityValue) {
      return null;
    }

    const ccySettings = qs.currencyOverrides.find(x => x.currencyCode === cbCcy);
    if (ccySettings) {
      return ccySettings.value;
    }

    return qs.defaultValue;
  }

  get includeCashRebateInParity(): boolean {
    if (!this._settings.valuationSession) {
      return null;
    }
    return this._settings.valuationSession.modelCustomization.includeCashRebateInParity;
  }

  get rebate(): number {
    if (!this.convertible) {
      return null;
    }
    return this.settings.convertible.rebate;
  }

  get conversionSchedule(): ConversionScheduleItem[] {
    if (!this._settings.convertible) {
      return null;
    }
    return this._settings.convertible.conversionSchedule;
  }

  get isUnderlyingRebate(): boolean {
    return !!this.convertible && this.convertible.rebateCCYID === -2;
   }

   /**
    * Initializes default analytics settings.
    * @param settings IAnalyticsSettings object.
    */
  init(settings?: IAnalyticsSettings) {
    this._settings = getDefaultAnalyticsSettings(settings);
  }

  /**
   * Updates convertible.
   * @param convertible IConvertible object.
   */
  updateConvertilbe(convertible?: IConvertible) {
    this._settings.convertible = convertible;
  }

  /**
   * Checks if instruments are equal.
   * @param convertible IConvertible object.
   * @returns A flag indicating if instruments are equal.
   */
  isEqualInstrument(convertible?: IConvertible) {
    if (!convertible && !this.instrumentLoaded) {
      return true;
    }

    if (!convertible && this.instrumentLoaded) {
      return false;
    }

    if (convertible && !this.instrumentLoaded) {
      return false;
    }

    if (convertible && this.instrumentLoaded) {
      return convertible.lwsIdentifier === this.convertible.lwsIdentifier;
    }

    return true;
  }

  /**
   * getEnvrionmentSettingsForSection - helper function for get Environment Settings for section.
   * @param section PricingEnvironmentSections object.
   */
  getEnvrionmentSettingsForSection(section: PricingEnvironmentSections): IEnvironmentSectionSettings {
    const sectionSettings = {
      selectedEnvrionmentId: null,
      envrionmentIds: []
    } as IEnvironmentSectionSettings;

    if (this.instrumentLoaded) {
      sectionSettings.selectedEnvrionmentId = this.selectedEnvironments[section];
      sectionSettings.envrionmentIds = this.instrumentEnvironmentSettings[section].map(a => a);
    }

    return sectionSettings;
  }

  /**
   * updateEnvironmentSettingForSection - helper function for update Environment Settings for section after saving reorder.
   * @param section PricingEnvironmentSections object.
   * @param item IPricingEnvrionment[].
   */
  updateEnvironmentSettingForSection(section: PricingEnvironmentSections, items: IPricingEnvrionment[]) {
    this.instrumentEnvironmentSettings[section] = items.map(a => a.id);
  }

  /**
   * Checks if environment is selected.
   * @param environmentId Environment ID.
   * @returns A flag indicating if environment is selected.
   */
  isEnvironmentSelected(environmentId: string) {
    if (!this.instrumentLoaded) {
      return false;
    }

    const found = Object.keys(this.selectedEnvironments)
      .find(a => this.selectedEnvironments[a] === environmentId);

    return !!found;
  }

  /**
   * Gets conversion ratio.
   * @returns Conversion ratio value.
   */
  getConversionRatio(): number {
    let refStockPrice;
    let refFx;

    if (this.convertible === null) {
      return 0;
    }

    if (this.convertible.status === ConvertibleSetupStatus.NewIssue) {
      refStockPrice = this.convertible.isCrossFx ? this.pricing.stockPriceUndCcy : this.pricing.stockPriceCbCcy;   

      if (this.convertible.stockPriceReference?.fixedStockRef) {
        refStockPrice = this.convertible.stockPriceReference.fixedStockRef;
      }
      else if(this.pricing.newIssueAssumptions?.stockRef){
        refStockPrice = this.convertible.isCrossFx ? this.pricing.newIssueAssumptions.stockRef : this.pricing.newIssueAssumptions.stockRefCBCcy;
      }

      refFx = this.pricing.crossFx;

      if(this.convertible.isCrossFx){
        if(this.convertible.fixedFxRate){
          refFx = this.convertible.fixedFxRate;
        }
        else if(this.pricing.newIssueAssumptions?.fx){
          refFx = this.pricing.newIssueAssumptions?.fx;
        }
      }
      else {
       refFx = 1;
      }

      return this.getNewIssueConversionRatio(refStockPrice ?? 0, this.getPremium(this.pricing.newIssueAssumptions), refFx);
    }
    else {
      return this.getConversionRatioInternal(this.cpeSettings);
    }

  }

  /**
   * Gets conversation ratio interanal.
   * @param creSettings ICrEstimateSettings object.
   * @returns Conversion ratio internal.
   */
  private getConversionRatioInternal(creSettings: ICrEstimateSettings): number {
    if (!this.convertible) {
      return null;
    }

    if (!creSettings) {
      return this.convertible.conversionRatio;
    }

    if (this.convertible.expectedCR) {
      return creSettings.useEstimatedRatio ? this.convertible.expectedCR : this.convertible.conversionRatio;
    }

    return this.convertible.conversionRatio;
  }

  /**
   * Gets new issue conversion ratio.
   * @param stockEQT0 Stock EQT0.
   * @param premium Premium.
   * @param crossFxT0 Cross FXT0.
   * @returns New issue conversion ratio.
   */
  getNewIssueConversionRatio(stockEQT0: number, premium: number, crossFxT0: number): number {
    let result = 0;

    let conversionPrice = stockEQT0 * (1 + (premium / 100));

    if (crossFxT0 > 0) {
      conversionPrice = conversionPrice / crossFxT0;
    }

    if (conversionPrice > 0) {
        result = this.convertible.nominal / conversionPrice;
    }  

    return result;
  }

  /**
   * Gets premium.
   * @param asssumptions INewIssueAssumptions object.
   * @returns Assumption premium.
   */
  getPremium(asssumptions: INewIssueAssumptions): number {
    if (asssumptions.pricingTypeSelected === 'best' ) {
      return this.convertible.priceTalk.premiumBest;
    }
    if (asssumptions.pricingTypeSelected === 'worst') {
      return this.convertible.priceTalk.premiumWorst;
    }
    if (asssumptions.pricingTypeSelected === 'mid') {
      return this.convertible.priceTalk.premiumMid;
    }
    return asssumptions.premium;
  }

  /**
   * Calculates rebate.
   * @returns Rebate value.
   */
  calculateRebate(): number {
    let rebate = 0;
    if (this.conversionSchedule) {
      const everystartDateGreaterThanValDate = this.conversionSchedule.
      every(el => LvDateUtil.parse(this.pricing.valuationDate) < LvDateUtil.parse( el.startDate));
      if (everystartDateGreaterThanValDate ) {
        return rebate;
     }
      else  {
        if (this.conversionSchedule.length === 1) {
            rebate = this.conversionSchedule[0].rebate;
            return this.getRebate(rebate);
          }
           else {
              rebate = this.conversionSchedule.filter(el =>
              LvDateUtil.parse( el.startDate) <= LvDateUtil.parse(this.pricing.valuationDate) &&
               LvDateUtil.parse(this.pricing.valuationDate) <= LvDateUtil.parse( el.endDate))[0].rebate;
              return this.getRebate(rebate);
           }
      }
    }
    return rebate;
  }

  /**
   * Gets rebate
   * @param rebate Rebate.
   * @returns Rebate.
   */
  getRebate(rebate: number): number {
    return  this.convertible.isCrossFx && this.isUnderlyingRebate ?
    rebate / this.pricing.crossFx : rebate;
  }
}
