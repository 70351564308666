import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { IShiftParametersSettings, IShiftParametersSectionSettings as IShiftParametersSectionSettings } from '@lv-analytics/models/company-and-user-settings';

/**
 * Shift parameters settings component.
 */
@Component({
  selector: 'lv-shift-parameters-settings',
  templateUrl: './lv-shift-parameters-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvShiftParametersSettingsComponent {

  @Input() settings: IShiftParametersSettings;

  hasEnvironmentSettings: boolean;

  constructor() {
    this.settings = {
      systemDefaults: {} as IShiftParametersSectionSettings,
      environments: []
    };
    this.hasEnvironmentSettings = false;
  }

  @HostBinding('class.lv-shift-parameters-settings')
  get isLvShiftParametersSettingsComponent() {
    return true;
  }
}
