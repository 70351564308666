<div class="accretion-settings-column">
  <lv-flex-box 
    data-cy="DM-1554"
    direction="row">
  
    <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="accretionTypeSourceTooltipTemplate"
        filter="span">
        <span> Accretion Type </span>
      </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="accretionType"
        [data]="accretionTypeLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 144 }"
        [(ngModel)]="settings.settings.type">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1555"
    direction="row">

    <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="accretionDayCountSourceTooltipTemplate"
        filter="span">
        <span> Accretion Day Count </span>
      </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="accretionDayCount"
        [data]="dayCountLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.dayCount">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1556"
    direction="row">

    <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="accretionFrequencySourceTooltipTemplate"
        filter="span">
        <span [attr.data-tooltip-id]="'accretionFrequencySourceTooltip'">
          Accretion Frequency
        </span>
      </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="accretionFrequency"
        [data]="frequencyLookup.items"
        valueField="id"
        textField="text"
        [disabled]="!overrideDefaults"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.frequency">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1557"
    direction="row">

    <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="grossNetSourceTooltipTemplate"
        filter="span">
        <span> Gross / Net </span>
      </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="grossNet"
        [data]="grossNetLookup.items"
        valueField="id"
        textField="text"
        [disabled]="!overrideDefaults"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.grossNet">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1558"
    direction="row">

    <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="accretionStartDateSourceTooltipTemplate"
        filter="span">
        <span> Accretion Start Date </span>
      </div>

      <div class="lv-input-field align-sufix">
        <lv-numeric-text-box
          placeholder="T + days"
          class="lv-input-field"
          [(ngModel)]="settings.settings.accretionStartDate"
          [format]="formatZero"
          [decimals]="decimalsZero"
          [disabled]="!overrideDefaults"
          name="accretionStartDate">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">From First Settlement Date</span>
      </div>
  </lv-flex-box>
  
  <lv-flex-box 
    data-cy="DM-1559"
    direction="row">

    <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="accretionEndDateSourceTooltipTemplate"
        filter="span">
        <span [attr.data-tooltip-id]="'accretionEndDateSourceTooltip'">
          Accretion End Date
        </span>
      </div>

      <div class="lv-input-field align-sufix">
        <lv-numeric-text-box
          placeholder="T - days"
          class="lv-input-field"
          [(ngModel)]="settings.settings.accretionEndDate"
          [format]="formatZero"
          [disabled]="!overrideDefaults"
          [decimals]="decimalsZero"
          name="accretionEndDate">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">From Maturity Date</span>
      </div>
    </lv-flex-box>
</div>

<ng-template #accretionTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1554"></lv-tooltip>
</ng-template>
<ng-template #accretionDayCountSourceTooltipTemplate>
  <lv-tooltip dm="dM-1555"></lv-tooltip>
</ng-template>
<ng-template #accretionFrequencySourceTooltipTemplate>
  <lv-tooltip dm="dM-1556"></lv-tooltip>
</ng-template>
<ng-template #grossNetSourceTooltipTemplate>
  <lv-tooltip dm="dM-1557"></lv-tooltip>
</ng-template>
<ng-template #accretionStartDateSourceTooltipTemplate>
  <lv-tooltip dm="dM-1558"></lv-tooltip>
</ng-template>
<ng-template #accretionEndDateSourceTooltipTemplate>
  <lv-tooltip dm="dM-1559"></lv-tooltip>
</ng-template>

