import { IMarketDataBase } from '@lv-analytics/models';
import { FieldMapper } from '@lv-excel/helpers/field-mapper';
import { ExcelFieldDataTypes } from '@lv-excel/models/enums/excel-field-data-types';

/**
 * Flat market data Excel mapper.
 */
export class FlatMarketDataExcelMapper extends FieldMapper<IMarketDataBase> {


  constructor() {
    super();
  }

  /**
   * Creates map.
   */
  createMap() {
    this._fieldDictionary['DVD_FLAT'] = {
      mapFn: (value: any) => {
        this._model.flatDividendYield = value;
      },
      reverseMapFn: () => {
        return this._model.flatDividendYield;
      },
      type: ExcelFieldDataTypes.Number
    };
  }

  /**
   * Maps enum type.
   */
  mappEnumType() {

  }

  /**
   * Maps enum type V3.
   */
  mappEnumTypeV3() {

  }


  /**
   * Custom mapping.
   */
  customMapp() {

  }
}
