import { Component, ViewEncapsulation, ChangeDetectionStrategy,
   Input, ChangeDetectorRef, Output, EventEmitter, ViewChild, ElementRef, ViewRef, Optional } from '@angular/core';
import { LvCreditTermsStructureComponent } from './lv-credit-terms-structure/lv-credit-terms-structure.component';
import { AccruedOnDefaultDescription, CreditModelFactor, CreditModelFactorDisplay, CreditSource, DateAdjustment,
         DayCountDescription, ICreditParameters, PeriodFrequencyDescription } from '@lv-analytics/models/market-data/credit';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { LvLookupEnum } from '@lv-core-ui/util';
import { FrequencyDescription } from '@lv-analytics/models/convertible';
import { LvExcelService } from '@lv-excel/services';

/**
 * Credit parameters component.
 */
@Component({
  selector: 'lv-credit-parameters',
  templateUrl: './lv-credit-parameters.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCreditParametersComponent {
  @ViewChild(LvCreditTermsStructureComponent) creditTermStructureComponent: LvCreditTermsStructureComponent;

  @Input() creditParameters: ICreditParameters;

  @Input() creditParametersSource: string;

  @Input()
  set creditSource(value: CreditSource) {
    this._creditSource = value;
    if (this._creditSource) {
      this.setCreditFactorModel();
      if (!(this.changeDetectorRef as ViewRef).destroyed) {
      this.changeDetectorRef.detectChanges();
      }
    }
  }

  @Output() doSaveSession: EventEmitter<void>;

  get creditSource(): CreditSource {
    return this._creditSource;
  }

  get IsNotTermStructure(): boolean {
    return !(this.creditSource === CreditSource.TermStructure);
  }

  get IsNotSurProb(): boolean {
    return this.creditSource !== CreditSource.SurvivalProbabilityTermStructure;
  }

  get IsFlatSpread(): boolean {
    return this.creditSource === CreditSource.FlatSpread;
  }

  get IsDefaultRateFlat(): boolean {
    return this.creditSource === CreditSource.DefaultRateFlat;
  }

  get IsCds(): boolean {
    return this.creditSource === CreditSource.CDSFlat ||
      this.creditSource === CreditSource.CDSTermStructure;
  }

  get IsCdsFlat(): boolean {
    return this.creditSource === CreditSource.CDSFlat;
  }

  get IsTermStructure(): boolean {
    return this.creditSource === CreditSource.TermStructure;
  }

  get IsCDSTermStructure(): boolean {
    return this.creditSource === CreditSource.CDSTermStructure;
  }

  get IsDefaultRateTermStructure(): boolean {
    return this.creditSource === CreditSource.DefaultRateTermStructure;
  }

  get IsSurvivalProbabilityTermStructure(): boolean {
    return this.creditSource === CreditSource.SurvivalProbabilityTermStructure;
  }

  get IsRecoveryBondVisible(): boolean {
    if (this._analyticsPresenter.cHelper.isExchangeable) {
      return this.creditParametersSource === this.issuer;
    }
    else {
      return this.creditSource !== CreditSource.FlatSpread;
    }
  }

  get IsJumbToUnderlyingDefaultVisible(): boolean {
    if (this._analyticsPresenter.cHelper.isExchangeable) {
      return this.creditParametersSource === this.underlying;
    }
    return true;
  }

  get isCreditFactorModelVisible(): boolean {
    return this.creditParametersSource === this.issuer;
  }

  get isIssuerCredit(): boolean {
    return this.creditParametersSource === this.issuer;
  }

  get isXlLabelOnTermsStructureViseible(): boolean {
    return this.isIssuerCredit ? !!this._excelSvc?.containsField('CREDIT_SCHED_RANGE')
     : !!this._excelSvc?.containsField('CREDIT_SCHED_RANGE_UND');
  }

  get isXlLabelOnCDSTermsStructureViseible(): boolean {
    return this.isIssuerCredit ? !!this._excelSvc?.containsField('CREDIT_CDS_SCHED_RANGE')
      : !!this._excelSvc?.containsField('CREDIT_CDS_SCHED_RANGE_UND');
  }

  get isXlLabelOnDefaultRateTermsStructureViseible(): boolean {
    return this.isIssuerCredit ? !!this._excelSvc?.containsField('CREDIT_DFLT_SCHED_RANGE')
      : !!this._excelSvc?.containsField('CREDIT_DFLT_SCHED_RANGE_UND');
  }

  get isXlLabelOnSurvivalProbabilityTermsStructureViseible(): boolean {
    return this.isIssuerCredit ? !!this._excelSvc?.containsField('CREDIT_SRVPROB_SCHED_RANGE')
      : !!this._excelSvc?.containsField('CREDIT_SRVPROB_SCHED_RANGE_UND');
  }

  creditAccuredOnDefaultSource: LvLookupEnum;
  creditFrequencySource: LvLookupEnum;
  creditModelFactor: LvLookupEnum;
  creditBasisSource: LvLookupEnum;
  badDayConversion: LvLookupEnum;

  numberOfDecimalsPercetenge = '2';
  numberOfDecimalsBps = '0';
  numberFormat = '###,#.##';
  numberFormatBps = 'n0';

  private underlying = 'underlying';
  private issuer = 'issuer';

  private _creditSource: CreditSource;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private _analyticsPresenter: LvAnalyticsPresenter,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.creditAccuredOnDefaultSource = new LvLookupEnum(AccruedOnDefaultDescription);
    this.creditFrequencySource = new LvLookupEnum(PeriodFrequencyDescription);
    this.creditModelFactor = new LvLookupEnum(CreditModelFactorDisplay);
    this.creditBasisSource = new LvLookupEnum(DayCountDescription);
    this.badDayConversion = new LvLookupEnum(DateAdjustment);
    this.creditParameters = {
      creditTermStructure: [],
      creditCDSTermStructure: [],
      creditDefaultRateTermStructure: [],
      creditSurvivalProbabilityTermStructure: []
    } as ICreditParameters;
    this.doSaveSession = new EventEmitter<void>();
  }

  /**
   * Occurs on credit parameters change.
   */
  onCreditParametersChange() {
    this.doSaveSession.emit();
  }

  /**
   * Sets credit factor model.
   */
  setCreditFactorModel() {
    this.creditModelFactor.setFilterFn(item => {
      if (this.creditParametersSource === 'underlying' &&
          this._analyticsPresenter.cHelper.isExchangeable &&
          item.id === CreditModelFactor.Factor2) {
        return false;
      }
      return !(this.creditSource !== CreditSource.FlatSpread && item.id === CreditModelFactor.Factor2);
    });

    if (this.creditSource !== CreditSource.FlatSpread && this.creditParameters.creditModelFactor === CreditModelFactor.Factor2) {
      this.creditParameters.creditModelFactor = CreditModelFactor.Factor1;
    }
  }

  /**
   * Gets credit tooltip ID.
   * @param element HTML element.
   * @param sectionId Section ID.
   * @returns Credit tooltip ID.
   */
  getCreditTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('credit-tooltip-id') === sectionId;
  }

  /**
   * Gets LvId.
   * @param fieldName Field name.
   * @returns LvId.
   */
  getLvId(fieldName: string): string {
    return `lv-${this.creditParametersSource}-${fieldName}`;
  }
}
