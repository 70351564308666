
export enum RoleOrganisationDescription {

	LeadManager = 'Lead Manager',
	JointBookrunner = 'Joint Bookrunner',
	CoLeads = 'Co Leads',
	CalculationAgent = 'Calculation Agent',
	PayingAgent = 'Paying Agent',
	SoleGlobalCoordinator = 'Sole Global Coordinator',
	SoleBookrunner = 'Sole Bookrunner',
	JointLeadManagers = 'Joint Lead Managers',
	TransferAgent = 'Transfer Agent',
	ConversionExchangeAgent = 'Conversion Exchange Agent',
	DocumentationAgent = 'Documentation Agent',
	DomiciliaryAgent = 'Domiciliary Agent',
	FiscalAgent = 'Fiscal Agent',
	SalesAgent = 'Sales Agent',
	SettlementManager = 'Settlement Manager',
	StabilizationManager = 'Stabilization Manager',
	Registar = 'Registar',
	Custodian = 'Custodian',
	Depositary = 'Depositary',
	Trustee = 'Trustee',
	FinancialAdvisor = 'Financial Advisor'
}
