/**
 * Dividend types description.
 */
export enum DividendTypeEnumDescription {
  ProportionalCash = 'Proportional Cash',
  Cash = 'Cash',
  DiscreteYield = 'Discrete Yield',
  ContinuousYield = 'Continuous Yield'
}

/**
 * Dividend types.
 */
export enum DividendTypeEnum {
  ProportionalCash = 'ProportionalCash',
  Cash = 'Cash',
  DiscreteYield = 'DiscreteYield',
  ContinuousYield = 'ContinuousYield'
}

