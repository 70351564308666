import {
  Component, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, Output,
  EventEmitter, ChangeDetectorRef, Input, OnInit, OnDestroy, ViewRef
} from '@angular/core';

import { Subscription } from 'rxjs';

import { DialogService } from '@progress/kendo-angular-dialog';

import { LvEnvironmentSettingsDialogComponent } from './lv-environment-settings-dialog/lv-environment-settings-dialog.component';
import { EnvironmentSettingsService } from '../../services/environment-settings/environment-settings.service';
import { AnalyticsSettingsHelper } from '../../helpers/analytics-settings';
import { LvAnalyticsPresenter } from '../../lv-analytics.presenter';
import { LvErrorService } from '@lv-core-ui/services';
import { LvUtil } from '@lv-core-ui/util';
import { PricingEnvironmentSections } from '@lv-analytics/models/enum/pricing-environment-sections';
import { IAnalyticsSettings } from '@lv-analytics/models/valuation-session';
import { AnalyticsSettingsEvents } from '@lv-analytics/models/enum/analytics-settings-events';
import { IPricingEnvrionment } from '@lv-analytics/models/company-and-user-settings';

export interface IEnvironmentSettingsItem extends IPricingEnvrionment {
  isSelected: boolean;
}

/**
 * Environment settings component.
 */
@Component({
  selector: 'lv-environment-settings',
  templateUrl: './lv-environment-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEnvironmentSettingsComponent implements OnInit, OnDestroy {

  @ViewChild('sortable') sortable: any;

  @Input() section: PricingEnvironmentSections;
  @Input() showControls: boolean;
  @Input() lvId: string;
  @Input() saveToExcel: boolean;

  @Output() doSave: EventEmitter<PricingEnvironmentSections>;
  @Output() doReload: EventEmitter<PricingEnvironmentSections>;
  @Output() didChangeEnvironment: EventEmitter<IEnvironmentSettingsItem>;
  @Output() didReorderEnvironments: EventEmitter<IEnvironmentSettingsItem[]>;
  @Output() didLoad: EventEmitter<void>;
  @Output() doSaveToExcel: EventEmitter<PricingEnvironmentSections>;

  get isActionDisabled(): boolean {
    return this.isLoading || !this._asHelper.instrumentLoaded;
  }

  get isDisabled(): boolean {
    return !this._asHelper.instrumentLoaded;
  }

  get buttonsVisible(): boolean {
    return this.items.length > 0;
  }

  get showReorderEnvironment(): boolean {
    return this.items.length > 1;
  }

  isLoading: boolean;
  items: IEnvironmentSettingsItem[];
  svcInstance: string;

  private _asHelper: AnalyticsSettingsHelper;
  private _subscriptions: Subscription[];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _dialogService: DialogService,
    private _errorService: LvErrorService,
    private _service: EnvironmentSettingsService,
    private _presenter: LvAnalyticsPresenter
  ) {
    this._asHelper = new AnalyticsSettingsHelper();

    this.isLoading = false;
    this.items = [];
    this.showControls = false;
    this.saveToExcel = false;

    this.doSave = new EventEmitter<PricingEnvironmentSections>();
    this.doSaveToExcel = new EventEmitter<PricingEnvironmentSections>();
    this.doReload = new EventEmitter<PricingEnvironmentSections>();
    this.didChangeEnvironment = new EventEmitter<IEnvironmentSettingsItem>();
    this.didReorderEnvironments = new EventEmitter<IEnvironmentSettingsItem[]>();
    this.didLoad = new EventEmitter<void>();
    this.lvId = '';
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this._presenter.onAnalyticsSettingsUpdated.subscribe(evt => {
        if (evt) {
          this.loadEnvironmentSettings(this._presenter.getModelData());
          if (this.getSelectedEnvironment()) {
            this.didLoad.next();
          }
        }
        else {
          this.clearEnvironmentSettings();
          this._asHelper.init();
        }

        this._changeDetectorRef.detectChanges();
      }),

      this._presenter.onPricingEnvironmentsChanged.subscribe(evt => {
        this.loadEnvironmentSettings(this._presenter.getModelData());
        if (this.getSelectedEnvironment()) {
          this.setSelectedItem(this.items.filter(a => a.isSelected)[0]);
        }
        this._changeDetectorRef.detectChanges();
      })
    ];
    if (this._presenter.isModelLoaded()) {
      this.loadEnvironmentSettings(this._presenter.getModelData());
    }
  }

  /**
   * Loads environment settings.
   * @param analyticsSettings IAnalyticsSettings object.
   */
  private loadEnvironmentSettings(analyticsSettings: IAnalyticsSettings) {
    this._asHelper.init(analyticsSettings);
    const sectionSettings = this._asHelper.getEnvrionmentSettingsForSection(this.section);
    const availableEnvrionmentsDict = LvUtil.toDictionary(this._asHelper.availablePricingEnvironments, 'id');

    sectionSettings.envrionmentIds =
      sectionSettings.envrionmentIds.filter(x => this._asHelper.availablePricingEnvironments.findIndex(y => y.id === x) > -1);

    this.items = sectionSettings.envrionmentIds
      .map(envId => ({
        ...availableEnvrionmentsDict[envId],
        isSelected: sectionSettings.selectedEnvrionmentId === envId
      }));

  }

  /**
   * Clears environment settings.
   */
  private clearEnvironmentSettings() {
    this.items = [];
  }

  /**
   * Sets selected item.
   * @param environment IEnvironmentSettingsItem object.
   */
  setSelectedItem(environment: IEnvironmentSettingsItem) {
    this.items.forEach(a => a.isSelected = environment.id === a.id);
    if (!(this._changeDetectorRef as ViewRef).destroyed) {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Sets items.
   * @param items List of IEnvironmentSettingsItem objects.
   */
  setItems(items: IEnvironmentSettingsItem[]) {
    this.items = items.map(a => ({ ...a }));
    if (!(this._changeDetectorRef as ViewRef).destroyed) {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on edit environment order.
   */
  onEditEnvironmentOrder() {
    if (!this.isActionDisabled) {
      const dialogRef = this._dialogService.open({
        title: `Edit environments order for ${this.parseTitle(this.section)}`,
        content: LvEnvironmentSettingsDialogComponent
      });

      const instance = dialogRef.content.instance as LvEnvironmentSettingsDialogComponent;
      instance.items = this.items.map(a => ({ ...a }));

      instance.didSave.subscribe(
        async (items: IEnvironmentSettingsItem[]) => {
          try {
            this._presenter.validateBeforeSave();
            this.setLoadingState(true);

            await this._service.saveInstrumentEnvironmentSectionSettings({
              lwsIdentifier: this._asHelper.convertible.lwsIdentifier,
              section: this.section,
              environmentIds: items.map(a => a.id)
            });

            this.items = items.map(a => ({ ...a }));
            this._asHelper.updateEnvironmentSettingForSection(this.section, items);

            this.didReorderEnvironments.next(this.items);
          }
          catch (error) {
            this._errorService.handleError(error);
          }
          finally {
            this.setLoadingState(false);
          }
        }
      );
    }
  }

  /**
   * Occurs on save section.
   */
  onSaveSection() {
    if (!this.isActionDisabled) {
      this.doSave.emit(this.section);
    }
  }

  /**
   * Occurs on save section to Excel.
   */
  onSaveSectionToExcel() {
    if (!this.isActionDisabled) {
      this.doSaveToExcel.emit(this.section);
    }
  }

  /**
   * Occurs on reload section.
   */
  onReloadSection() {
    if (!this.isActionDisabled) {
      this.doReload.emit(this.section);
    }
  }

  /**
   * Occurs on select environment and updates model.
   * @param environment IEnvironmentSettingsItem object.
   */
  onSelectEnvironment(environment: IEnvironmentSettingsItem) {
    if (!this.isActionDisabled) {
      this.setSelectedItem(environment);

      this._presenter.updateModel({
        eventId: AnalyticsSettingsEvents.PricingEnvironmentUpdated,
        data: {
          section: this.section,
          environmentId: environment.id
        }
      });

      this.didChangeEnvironment.emit(environment);
    }
  }

  /**
   * Gets selected environment.
   * @returns IEnvironmentSettingsItem object.
   */
  getSelectedEnvironment(): IEnvironmentSettingsItem | null {
    return this.items.find(a => a.isSelected);
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;

    if (!(this._changeDetectorRef as ViewRef).destroyed) {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Parse title.
   * @param title Title.
   * @returns Parsed title.
   */
  private parseTitle(title: string): string {
    if (title === 'modelSettings') {
      title = 'modelCustomization';
    }
    let index = 0;
    const newTitle = title.charAt(0).toUpperCase() + title.slice(1);

    for (let i = 0; i < title.length; i++) {
      if (title[i] === title[i].toUpperCase()) {
        index = i;
      }
    }

    return index === 0 ? newTitle : `${newTitle.slice(0, index)} ${newTitle.slice(index)}`;
  }
}
