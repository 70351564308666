

import { StepUpCouponScheduleItem } from './StepUpCouponScheduleItem';

export class FixedCouponData {
    
    rate: number;
    useStepupCouponSchedule: boolean;
    stepUpCouponSchedule: StepUpCouponScheduleItem[];
}

