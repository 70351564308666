<form class="lv-scenario-inputs" #form="ngForm">
  <div class="lv-scenario-input shift">
    <label class="lv-label">{{ shiftLabel }}</label>
    <div class="lv-input-field lv-input-field--full">
      <kendo-dropdownlist
        name="shift"
        [data]="view.shiftLookup.items"
        valueField="id"
        textField="text"
        [itemDisabled]="shiftDisabled"
        [valuePrimitive]="true"
        [(ngModel)]="view.model.shift"
        (ngModelChange)="onShiftChange()"
        required>
      </kendo-dropdownlist>
    </div>
  </div>

  <div class="lv-scenario-input change">
    <div class="lv-field lv-field--radio">
      <input type="radio" class="k-radio"
        [attr.id]="view.absRadioId"
        [attr.name]="view.absRadioId"
        name="view.absRadioId"
        lvId="rbChangeAbs"
        value="abs"
        [disabled]="view.absRadioDisabled"
        [(ngModel)]="view.model.change"/>
      <label class="k-radio-label" [attr.for]="view.absRadioId">Abs</label>
    </div>

    <div class="lv-field lv-field--radio">
      <input type="radio" class="k-radio"
        [attr.id]="view.pctRadioId"
        [attr.name]="view.pctRadioId"
        name="view.pctRadioId"
        lvId="rbChangePct"
        value="pct"
        [disabled]="view.isValuationDateShiftSelected"
        [(ngModel)]="view.model.change"/>
      <label class="k-radio-label" [attr.for]="view.pctRadioId">%</label>
    </div>

    <div *ngIf="view.changeRefVisible"
      class="lv-input-field lv-input-field--right">
      <lv-numeric-text-box
        name="changeRef"
        placeholder="% Ref"
        [(ngModel)]="view.model.changeRef"
        required>
      </lv-numeric-text-box>
    </div>
  </div>

  <div class="lv-scenario-input range">
    <label class="lv-label">Range</label>
    <div class="lv-input-field lv-input-field--right">
      <lv-numeric-text-box *ngIf="!view.isValuationDateShiftSelected"
        name="minRange"
        placeholder="Min"
        [(ngModel)]="view.model.min"
        [max]="view.model.max"
        required>
      </lv-numeric-text-box>

      <kendo-datepicker *ngIf="view.isValuationDateShiftSelected"
        name="minRangeDate"
        placeholder="Min"
        [min]="view.minDate"
        [max]="view.model.max"
        [(ngModel)]="view.model.min"
        required>
      </kendo-datepicker>
    </div>
    <div class="range-spacer">-</div>
    <div class="lv-input-field lv-input-field--right">
      <lv-numeric-text-box *ngIf="!view.isValuationDateShiftSelected"
        name="maxRange"
        placeholder="Max"
        [(ngModel)]="view.model.max"
        [min]="view.model.min"
        required>
      </lv-numeric-text-box>


      <kendo-datepicker *ngIf="view.isValuationDateShiftSelected"
        name="maxRangeDate"
        placeholder="Max"
        [min]="view.model.min"
        [max]="view.maxDate"
        [(ngModel)]="view.model.max"
        required>
      </kendo-datepicker>
    </div>
    <label class="lv-label range-suffix">{{ view.rangeSuffix }}</label>
  </div>

  <div class="lv-scenario-input step">
    <label class="lv-label">Step</label>
    <div class="lv-input-field lv-input-field--right">
      <lv-numeric-text-box
        name="rangeStep"
        [min]="view.stepMin"
        [max]="view.stepMax"
        [(ngModel)]="view.model.step"
        required>
      </lv-numeric-text-box>
    </div>
  </div>
</form>
