import { AccrualMethod, ExCouponPeriodType, CouponReferenceTenor, PikCouponType, CoPayCouponType,
  CoPayTriggerType, DayCount, CouponType, Frequency} from '@lv-convertible-bond/models';
import { ICouponSectionSettings } from '../coupon-settings/coupon-section-settings';

export const getDefaultCouponSectionSettings = (): ICouponSectionSettings => {
  return {
    type: CouponType.ZeroCoupon,
    dayCount: DayCount.DayCount_ACT_ACT_ICMA,
    frequency: Frequency.SemiAnnual,
    accrualMethod: AccrualMethod.Regular,
    exCouponPeriodType: ExCouponPeriodType.Business,
    exCouponPeriod: 15,
    couponDatesEOM: true,
    accrualEndDate: null,
    accrualStartDate: null,
    guaranteedStartDate: null,
    guaranteedStartEndDate: null,
    hasCoPay: false,
    isGuaranteed: false,
    floating: {
        referenceTenor: CouponReferenceTenor.SixMonth,
        multiplier: 1,
        fixingDelay: 0,
        floor: 0
    },
    pik: {
        pikCouponType: PikCouponType.None,
    },
    coPay: {
        coPayFrequency: Frequency.SemiAnnual,
        coPayDayCount: DayCount.DayCount_ACT_360,
        coPayCouponType: CoPayCouponType.PercOfMarketPrice,
        coPayTriggerType: CoPayTriggerType.PercOfPar,
        coPayAccrualStartDate: null
    }
  };
};
