<div class="lv-dialog lv-outputs-dialog">
  <kendo-tabstrip
    class="lv-outputs-dialog-tabstrip"
    #tabStrip
    keepTabContent="true">
  
    <kendo-tabstrip-tab
       title="Outputs"
       [selected]="true"
       keepTabContent="true">
          <ng-template kendoTabContent>
            <ng-container class="lv-dialog-content">
              <lv-multi-select #outputsMultiSelect
              [availableItems]="outputs.availableItems"
              [selectedItems]="outputs.selectedItems"
              [showTooltips]="true"
              [tooltips]= "outputs.tooltips"
              valueField="type"
              displayField="name">
            </lv-multi-select>
            </ng-container>
          </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="Tabular Outputs"
      keepTabContent="true">
     <ng-template kendoTabContent>
      <ng-container class="lv-dialog-content">
        <lv-multi-select #tabularMultiSelect
        [availableItems]="tabularOutputs.availableItems"
        [selectedItems]="tabularOutputs.selectedItems"
        [showTooltips]="true"
        [tooltips]= "tabularOutputs.tooltips"
        valueField="type"
        displayField="name">
      </lv-multi-select>
      </ng-container>
     </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

  <div class="lv-dialog-actions">
    <button kendoButton
      [disabled]="isLoading"
      lvId="convertibleValuationOutputsCancel"
      (click)="onCancel()">Cancel</button>
    <button kendoButton
      [primary]="true"
      [disabled]="isLoading"
      lvId="convertibleValuationOutputsSave"
      (click)="onSave()">Save</button>
  </div>
</div>
