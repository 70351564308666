import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef,
  Input, OnChanges, ViewChildren, QueryList, ViewChild, ElementRef, ViewRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';

import { LvScrollToDirective } from '@lv-core-ui/directives';
import { LvErrorType } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { ITermsSummarySection } from '@lv-reference-data/models';
import { TermsSummaryService } from '@lv-reference-data/services';
import { ITermsSummaryLogEvent } from '@lv-shared/models';
import { LvSharedUserActivityService } from '@lv-shared/services';

@Component({
  selector: 'lv-terms-summary',
  templateUrl: './lv-terms-summary.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvTermsSummaryComponent implements OnInit, OnChanges {

  @ViewChildren(LvScrollToDirective) sectionElements: QueryList<LvScrollToDirective>;
  @ViewChild('container') containerElement: ElementRef<HTMLElement>;

  @Input() lwsIdentifier: string;

  get sectionsVisible(): boolean {
    return this.lwsIdentifier ? true : false;
  }

  sections: ITermsSummarySection[];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvAnalyticsPresenter,
    private _errorService: LvErrorService,
    private _service: TermsSummaryService,
    private _userActivityService: LvSharedUserActivityService,
    private _domSanitizer: DomSanitizer
  ) {
    this.sections = [];
  }

  ngOnInit() {
    this.sections = this._service.getSections();
    this.detectChanges();
    this.loadSections();
  }

  ngOnChanges() {
    this.loadSections();
  }

  scrollTo(sectionId: string) {
    LvScrollToDirective.scrollTo(this.sectionElements, this.containerElement, sectionId);
  }

  loadSections() {

    if (!this.sections || this.sections.length === 0) {
      return;
    }

    this.sections.forEach(a => a.base64 = '');
    this.detectChanges();

    if (!this.lwsIdentifier) {
      return;
    }

    this.logTermsSummaryEvent();

    this.sections.forEach(a => {
      this._service
        .loadSectionImage(a.url, this.lwsIdentifier)
        .then(base64 => {
          a.base64 = base64;
          this.detectChanges();
        })
        .catch(error => this._errorService.handleError(error, e => {
          if (e.type !== LvErrorType.AUTHORIZATION) {
            this._errorService.toastrService.error(e.message, e.name);
          }
        }));
      }
    );
  }

  private logTermsSummaryEvent() {
    if (this._presenter.isModelLoaded()) {
      const convertible = this._presenter.cHelper.convertible;
      if (convertible) {
        this._userActivityService.logTermsSummaryEvent({
          termsSummaryAccessTimestamp: new Date(),
          bondCountry: convertible.countryCode,
          bondType: convertible.status
        } as ITermsSummaryLogEvent);
      }
    }
  }

  private detectChanges() {
    if (!(this._changeDetectorRef as ViewRef).destroyed) {
      this._changeDetectorRef.detectChanges();
    }
  }
}
