import { Component, ChangeDetectorRef, Renderer2, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { mask, modal, LvModalComponent } from '@lv-core-ui/components';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'another-instance-modal',
  templateUrl: './another-instance-modal.component.html',
  animations: [
    mask,
    modal
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnotherInstanceModalComponent extends LvModalComponent {

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    renderer: Renderer2
  ) {
    super(changeDetectorRef, renderer);
  }

}
