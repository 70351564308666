import { Pipe, PipeTransform } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';

@Pipe({
  name: 'lvDecimal'
})
export class LvDecimalPipe implements PipeTransform {

  constructor(
    private intl: IntlService
  ) {}

  transform(value: number, format: string): any {

    return this.intl.formatNumber(!value ? 0 : value, format);
  }

}
