<div class="lv-modal-mask" [@mask]
  *ngIf="isOpened"
  [ngClass]="maskClass">
</div>
<div class="lv-modal" [@modal]
  *ngIf="isOpened"
  [ngClass]="modalClass">
  <div class="lv-modal-table">
    <div class="lv-modal-table-cell">
      <div class="lv-modal-table-cell-content">
        <div class="lv-modal-container">
            <div class="lv-modal-title">
              Application state
            </div>
            <div class="lv-modal-content">
              <ng-container *ngIf="!reloadMode">
                <div *ngIf="conflictMode">Your application state is out of date.</div>
                <div *ngIf="!conflictMode">There was a problem with saving your application state.</div>
                
                <div>Please, login again.</div>
              </ng-container>

              <ng-container *ngIf="reloadMode">
                <div>Your application state is outdated.</div>

                <div>Please, reaload the appliaction.</div>
              </ng-container>
            </div>
            <div class="lv-modal-footer">
              <button *ngIf="!reloadMode"
                kendoButton
                [primary]="true"
                (click)="onLogInAgain()">
                Log in again
              </button>

              <button *ngIf="reloadMode"
                kendoButton
                [primary]="true"
                (click)="onReload()">
                Reload Appliaction
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>