
export enum RelativeToPrice {
	
	InitialConversionPrice = 'InitialConversionPrice',
	PrevailingConversionPrice = 'PrevailingConversionPrice'
}
export enum FloorExpressedAs {
	
	PercOfInitialConversionPrice = 'PercOfInitialConversionPrice',
	PercOfPrevailingConversionPrice = 'PercOfPrevailingConversionPrice',
	CashInBondCCY = 'CashInBondCCY'
}
export enum CapExpressedAs {
	
	PercOfInitialConversionPrice = 'PercOfInitialConversionPrice',
	PercOfPrevailingConversionPrice = 'PercOfPrevailingConversionPrice'
}
export enum PepsConversionBeforeMaturityType {
	
	None = 'None',
	AtMaturity = 'AtMaturity',
	MinimumRatio = 'MinimumRatio',
	CustomRatio = 'CustomRatio'
}
export enum VariableConversionStrikeType {
	
	PercentOfConversionPrice = 'PercentOfConversionPrice',
	PercentOfAccretedConversionPrice = 'PercentOfAccretedConversionPrice',
	AbsolutePrice = 'AbsolutePrice'
}
export enum IncrementalShareFactorType {
	
	PercentOfConversionRatio = 'PercentOfConversionRatio',
	Absolute = 'Absolute'
}
export enum ContingentConversionScheduleTriggerType {
	
	AsPercentOfPar = 'AsPercentOfPar',
	AsPercOfAccretedValue = 'AsPercOfAccretedValue',
	AbsoluteUnderlyingPrice = 'AbsoluteUnderlyingPrice'
}
export enum ContingentConversionScheduleItemType {
	
	Quarterly_AnyTime = 'Quarterly_AnyTime',
	Quarterly_NextQuarter = 'Quarterly_NextQuarter',
	AnyTime_AnyTime = 'AnyTime_AnyTime',
	AnyTime_Immediate = 'AnyTime_Immediate'
}
export enum QuarterType {
	
	Calendar = 'Calendar',
	Fiscal = 'Fiscal'
}
export enum ContingentConversionPeriodType {
	
	NOutOfMDays = 'NOutOfMDays',
	AverageOfConsecutiveDays = 'AverageOfConsecutiveDays'
}
export enum ContingentConversionTriggerParityLimit {
	
	None = 'None',
	Par = 'Par'
}
export enum CallMakeWholeType {
	
	None = 'None',
	Premium = 'Premium',
	Coupon = 'Coupon',
	CouponPV = 'CouponPV',
	RatchetMatrix = 'RatchetMatrix',
	PremiumLinDecreasing = 'PremiumLinDecreasing'
}
export enum DiscountRateType {
	
	Spread = 'Spread',
	Flat = 'Flat'
}