import * as moment from 'moment';
import * as _ from 'lodash';

import { Injectable, Optional } from '@angular/core';

import { Subject, Observable } from 'rxjs';

import { LvDateService, LvErrorService } from '@lv-core-ui/services';
import { LvMath} from '@lv-core-ui/util';
import { Accretion, Call, CallMakeWholeData, CallMakeWholeDataConversion,
  CleanUpCall, ConversionData, ConvertibleBondNewIssueDocument, ConvertibleBondSubType,
  ConvertibleBondTermsDocument, ConvertibleBondTermsEvent, ConvertibleBondTermsSectionEvent, Coupon, CouponType, DividendProtectionData,
         DividendProtectionTresholdValueType, DividendThreshold, FixedCouponData, Frequency, Identifiers, IssueAndRedemption, OtherData,
         PriceTalk, PricingSectionEvent, PricingType, Put, QuickAndFullTermsDocument, QuickTerms, SetupStatus, SetupStatusQuickTerms, SourceType,
         StockPriceReference, StockReferenceType, TakeoverProtection } from '@lv-convertible-bond/models';
import { ConvertibleTermsMapHelper, QuickTermsMapHelper } from '@lv-convertible-bond/helpers';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';
import { LvSharedMarketDataService } from '@lv-shared/services/shared-market-data.service';
import { LvExcelService } from 'src/app/modules/excel/services/excel-service';
import { TermsExcelMapper } from '@lv-convertible-bond/helpers/terms-mapper';
import { CustomInstrumentsService } from '@lv-custom-instruments/services/custom-instruments.service';
import { ICalculateYieldAndRedemptionRequest } from '@lv-custom-instruments/models';

export interface IModelEvent<T> {
  eventId: ConvertibleBondTermsSectionEvent;
  data: T;
}

export interface IPricingSectionEvent {
  eventId: PricingSectionEvent;
  data: PricingType;
  source: SourceType;
}

export class PricingSectionCommand {
  type: PricingType;
  calculateValue: number;
  sourceType: SourceType;

  constructor(type: PricingType, calculateValue: number, sourceType: SourceType) {
    this.type = type;
    this.calculateValue = calculateValue;
    this.sourceType = sourceType;
  }
}

export class UpdateRatioCommand {
  ratio: number;

  constructor(ratio: number) {
    this.ratio = ratio;
  }
}

export type ConvertibleBondTermsPresenterCommand = PricingSectionCommand | UpdateRatioCommand;

@Injectable()
export class LvConvertibleBondTermsPresenter {

  public onModelUpdated: Subject<IModelEvent<QuickAndFullTermsDocument>>;
  public isLoading: Subject<boolean>;

  public get onCommandExecuted(): Observable<ConvertibleBondTermsPresenterCommand> {
    return this._commandExecuted.asObservable();
  }

  public get ratio(): number {
    return this._ratio;
  }

  /**
   * Is loaded cb cross fx.
   */
  get isCrossFx(): boolean {
    if (!this._model) {
      return false;
    }

    return this._model?.fullTerms.issueAndRedemption.currencyCode !== this._model?.fullTerms.issueAndRedemption.underlyingEquity.currencyCode;
  }

  public termsMapper: TermsExcelMapper;

  private _pricingSectionUpdated: Subject<IPricingSectionEvent>;
  private _convertibleBondTermsEvent: Subject<ConvertibleBondTermsEvent>;

  private _commandExecuted: Subject<ConvertibleBondTermsPresenterCommand>;

  private _model: QuickAndFullTermsDocument;

  private _ratio: number;

  _cbtmHelper: ConvertibleTermsMapHelper;
  _qtHelper: QuickTermsMapHelper;

  constructor(
    private _errorService: LvErrorService,
    private _service: ConvertibleBondTermsService,
    private _sharedMarketDataService: LvSharedMarketDataService,
    private _lvDateService: LvDateService,
    private _customInstrumentService: CustomInstrumentsService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.onModelUpdated = new Subject<IModelEvent<QuickAndFullTermsDocument>>();
    this.isLoading = new Subject<boolean>();

    this._convertibleBondTermsEvent = new Subject<ConvertibleBondTermsEvent>();
    this._pricingSectionUpdated = new Subject<IPricingSectionEvent>();
    this._commandExecuted = new Subject<ConvertibleBondTermsPresenterCommand>();

    this._cbtmHelper = new ConvertibleTermsMapHelper(_lvDateService);
    this._qtHelper = new QuickTermsMapHelper();
    this.termsMapper = new TermsExcelMapper(_lvDateService);
  }

  /**
   * Load quick and full terms into presenter
   * @param termsDocument Quick and full terms document
   */
  public async load(termsDocument: QuickAndFullTermsDocument) {
    try {
      this.isLoading.next(true);

      this._service.mapToUi(termsDocument.fullTerms);
      this._model = termsDocument;
      this._ratio = await this.getConversionRatio();

      this.onModelUpdated.next({
        eventId: ConvertibleBondTermsSectionEvent.ModelLoadedEvent,
        data: this._model
      });
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.isLoading.next(false);
    }
  }

  /**
   * Load quick and full terms into presenter from Excel
   * @param termsDocument Quick and full terms document
   * @param isDraft Flag that describes if instrument is draft
   */
  public async loadFromExcel(termsDocument: QuickAndFullTermsDocument, isDraft: boolean = false) {
    if (!!this._excelSvc?.isInitialized()) {
      if (isDraft) {
        termsDocument.fullTerms.issueAndRedemption.name = 'Draft';
      }

      const termsFields = this._excelSvc.getMappedFields('Terms');

      this.termsMapper.init(termsDocument, isDraft, () => {
        this.termsMapper.setUpNewIssue(termsFields);
      });

      // if instrument is draft new models will be retrieved from settings 
      // if instrument is private new models will be retrieved from default models
      this.termsMapper.setIsPrivateInstrument(!isDraft);
      this.termsMapper.mapp(termsFields, !!this._excelSvc?.isV3());

      const issuePremiumPrice = !!this._excelSvc?.getFieldValue('NI_PREM_PRC_RANGE');
      const coupon = !!this._excelSvc?.getFieldValue('NI_CPN_RT_RANGE');
      const issuePrice =  !!this._excelSvc?.getFieldValue('NI_ISSUE_PX_RANGE');
      const redemptionPrice =  !!this._excelSvc?.getFieldValue('NI_REDEM_PX_RANGE');
      const issueYield =  !!this._excelSvc?.getFieldValue('NI_YLD_RANGE');
      
      let isIssueYieldRecalculate;
  
      if (issueYield) {
        isIssueYieldRecalculate = false;
      }
      else if (redemptionPrice) {
        isIssueYieldRecalculate = true;
      }
      else {
        isIssueYieldRecalculate = null;
      }

      let redemptionPriceRecalculate;

      if(issueYield){
        redemptionPriceRecalculate = true;
      }

      if(isIssueYieldRecalculate){

        const yieldAndRedemptionRequest = {
          pricingType: PricingType.Best,
          terms: termsDocument.fullTerms
        } as ICalculateYieldAndRedemptionRequest;

        let bestIssueYield = 0;
        if (this.validateYieldOrRedemptiuonCalcRequest(yieldAndRedemptionRequest)) {
          bestIssueYield = await this._customInstrumentService.setYieldFromRedemption(yieldAndRedemptionRequest);
        }

        termsDocument.fullTerms.priceTalk.issueYieldBest = bestIssueYield;

        const worstYieldCalculateRequest = {
          pricingType: PricingType.Worst,
          terms: termsDocument.fullTerms
        } as ICalculateYieldAndRedemptionRequest;

        let worstIssueYield = 0;
        if (this.validateYieldOrRedemptiuonCalcRequest(worstYieldCalculateRequest)) {
          worstIssueYield = await this._customInstrumentService.setYieldFromRedemption(worstYieldCalculateRequest);
        }

        termsDocument.quickTerms.priceTalk.issueYieldWorst = worstIssueYield;
      }

      else{
        if (redemptionPriceRecalculate){
          const redemptionCalcRequest = {
            pricingType: PricingType.Best,
            terms: termsDocument.fullTerms
          } as ICalculateYieldAndRedemptionRequest;
  
          let bestRedemptionPrice = 0;
          if (this.validateYieldOrRedemptiuonCalcRequest(redemptionCalcRequest)) {
            bestRedemptionPrice = await this._customInstrumentService.setRedemptionFromYield(redemptionCalcRequest);
          }
  
          termsDocument.fullTerms.priceTalk.redemptionValueBest = bestRedemptionPrice;
  
          const worstRedemptionCalculateRequest = {
            pricingType: PricingType.Worst,
            terms: termsDocument.fullTerms
          } as ICalculateYieldAndRedemptionRequest;
  
          let worstRedemption = 0;
          if (this.validateYieldOrRedemptiuonCalcRequest(worstRedemptionCalculateRequest)) {
            worstRedemption = await this._customInstrumentService.setRedemptionFromYield(worstRedemptionCalculateRequest);
          }
  
          termsDocument.quickTerms.priceTalk.redemptionValueWorst = worstRedemption;
        }
      }

      this.load(termsDocument);
    }

    return termsDocument;
  }

  public isModelLoaded() {
    return !!this._model;
  }

  public getModel(): QuickAndFullTermsDocument {
    return this._model;
  }

  public updateModel(event: IModelEvent<any>, publishExternalEvent: boolean = true, isOpenedFromExcel: boolean = false) {
    const modelData = this.clone(event.data);

    if (event.eventId === ConvertibleBondTermsSectionEvent.TermsGeneralEvent) {
      this._model.fullTerms.issueAndRedemption = modelData as IssueAndRedemption;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.SetupStatusChanged) {
      this._model.fullTerms.issueAndRedemption = modelData as IssueAndRedemption;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.TermsGeneralDateEvent) {
      this._model.fullTerms.issueAndRedemption = modelData as IssueAndRedemption;
      const conversionSettings = this._service.conversionSettings;
      this._model.fullTerms = this._cbtmHelper.setStartEndDate(this._model, conversionSettings);
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.NominalChangedEvent) {
      this._model.fullTerms.issueAndRedemption = modelData as IssueAndRedemption;
      const termsDoc = Object.assign(new ConvertibleBondTermsDocument(), this._model.fullTerms);
      termsDoc.recalculateConversionRatioFromCP();
      this.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.ConversionRatioOrRebateUpdated);
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.CouponEvent) {
      this._model.fullTerms.coupon = modelData as Coupon;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.CouponDaysOrFrequencyChangedEvent) {
      this._model.fullTerms.coupon = modelData as Coupon;
      const termsDoc = Object.assign(new ConvertibleBondTermsDocument(), this._model.fullTerms);
      termsDoc.setCallYieldFields();
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.AccretionEvent) {
      this._model.fullTerms.accretion = modelData as Accretion;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.PutsEvent) {
      this._model.fullTerms.put = modelData as Put;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.ConversionEvent) {
      this._model.fullTerms.conversion = modelData as ConversionData;
      this.updateConversionRatioCommand();
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.CallsEvent) {
      this._model.fullTerms.call = modelData as Call;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.MWCallsEvent
      || event.eventId === ConvertibleBondTermsSectionEvent.MWInitCallsEvent) {
      this._model.fullTerms.call = modelData as Call;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.MakeWholeEvent) {
      if (this._model.fullTerms.call) {
        this._model.fullTerms.call.callMakeWhole = modelData as CallMakeWholeData;
      }
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.TakeOverCallMWEvent) {
      if (this._model.fullTerms.call) {
        this._model.fullTerms.call.callMakeWhole = modelData as CallMakeWholeData;
      }
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.DividendsProtectionEvent) {
        this._model.fullTerms.dividendProtection = modelData as DividendProtectionData;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.CleanUpCallEvent) {
        this._model.fullTerms.cleanUpCall = modelData as CleanUpCall;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.CallIssueAndRedemptionEvent) {
      this._model.fullTerms.issueAndRedemption = modelData as IssueAndRedemption;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.PutIssueAndRedemptionEvent) {
      this._model.fullTerms.issueAndRedemption = modelData as IssueAndRedemption;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.DividendProtectionIssueAndRedemption) {
      this._model.fullTerms.issueAndRedemption = modelData as IssueAndRedemption;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.TakeoverProtectionEvent) {
      this._model.fullTerms.takeoverProtection = modelData as TakeoverProtection;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.MWTakeoverProtectionEvent) {
      this._model.fullTerms.takeoverProtection = modelData as TakeoverProtection;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.MakeWholeConversionEvent) {
      this._model.fullTerms.conversion.callMakeWhole = modelData as CallMakeWholeDataConversion;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.OtherGeneralEvent) {
      this._model.fullTerms.other = modelData as OtherData;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.PriceTalkEvent ||
        event.eventId === ConvertibleBondTermsSectionEvent.UpdatePriceTalk) {
      this._model.fullTerms.priceTalk = modelData as PriceTalk;  
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.CouponPriceTalk) {
      this._model.fullTerms.priceTalk = modelData as PriceTalk;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.MWConversionEvent
      || event.eventId === ConvertibleBondTermsSectionEvent.MWInitConversionEvent) {
      this._model.fullTerms.conversion = modelData as ConversionData;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.StockPriceReference ||
        event.eventId === ConvertibleBondTermsSectionEvent.UpdateStockPriceReference) {
      this._model.fullTerms.stockPriceReference = (modelData || {}) as StockPriceReference;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.QuickTermsEntryEvent) {
      this._model.quickTerms = modelData as QuickTerms;
      const conversionSettings = this._service.conversionSettings;
      this._model.fullTerms = this._cbtmHelper.getConvertibleBondFromQuickTermsEntry(this._model, conversionSettings, isOpenedFromExcel);
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.QuickTermsEntryLoadEvent) {
      this._model.quickTerms = modelData as QuickTerms;
    }

    if (event.eventId === ConvertibleBondTermsSectionEvent.IdentifiersEvent) {
      this._model.identifiers = modelData as Identifiers[];
      // Sent event which will be formatted to analytics.
      this._convertibleBondTermsEvent.next(ConvertibleBondTermsEvent.InstrumentStatusUpdated);
    }

    this.onModelUpdated.next({
      eventId: event.eventId,
      data: this.clone(this._model)
    });

    if (publishExternalEvent && event.eventId !== ConvertibleBondTermsSectionEvent.ModelLoadedEvent
      && event.eventId !== ConvertibleBondTermsSectionEvent.MWInitCallsEvent
      && event.eventId !== ConvertibleBondTermsSectionEvent.MWInitConversionEvent) {

      this._convertibleBondTermsEvent.next(ConvertibleBondTermsEvent.OtherDataUpdated);
    }
  }

  /**
   * Update fx on model.
   * @param fx Fx value.
   */
  public updateFixedFx(fx: number): void {
    this._model.fullTerms.issueAndRedemption.fixedFXRate = fx;
  }

  public executeCommand(command: ConvertibleBondTermsPresenterCommand) {
    this._commandExecuted.next(command);
  }

  public getConvertibleBondTermsEvent(): Subject<ConvertibleBondTermsEvent> {
    return this._convertibleBondTermsEvent;
  }

  public publishConvertibleBondTermsEvent(event: ConvertibleBondTermsEvent) {
    this._convertibleBondTermsEvent.next(event);
  }

  public getPricingSectionUpdated(): Subject<IPricingSectionEvent> {
    return this._pricingSectionUpdated;
  }

  public publishPricingSectionUpdated<T>(event: IPricingSectionEvent) {
    this._pricingSectionUpdated.next(event);
  }

  public makeWholeEnabled() {
    if (!this._model) {
      return false;
    }

    if (this._model.fullTerms.issueAndRedemption.subType === ConvertibleBondSubType.ConvertibleWithDetachableWarrant) {
      return false;
    }

    // tslint:disable-next-line:max-line-length
    if (this._model.fullTerms.coupon && this._model.fullTerms.coupon.type !== CouponType.ZeroCoupon) {
      return true;
    }

    // tslint:disable-next-line:max-line-length
    if (this._model.fullTerms.issueAndRedemption.setupStatus === SetupStatus.NewIssue
      && this._model.fullTerms.priceTalk
      && this._model.fullTerms.priceTalk.couponBest > 0
      && this._model.fullTerms.priceTalk.couponWorst > 0) {
      return true;
    }

    if (this._model.fullTerms.issueAndRedemption.isCallable) {
      return true;
    }

    return false;
  }

  public getQuickAndFullTermsForApi(): QuickAndFullTermsDocument {
    const cloned = this.clone(this._model);

    this._service.mapToApi(cloned.fullTerms);

    if (!cloned.quickTerms) {
      cloned.quickTerms = new QuickTerms();
      cloned.quickTerms.priceTalk = new PriceTalk();
      cloned.quickTerms.stockPriceReference = new StockPriceReference();
      cloned.quickTerms = this._qtHelper.getQuickTermsEntryFromConvertibleBond(cloned.fullTerms, cloned.quickTerms);
    }

    return cloned;
  }

  public quickTermsVisibile() {
    // tslint:disable-next-line:max-line-length
    return this._model && (this._model.fullTerms.issueAndRedemption.setupStatus === SetupStatus.NewIssue || this._model.fullTerms.issueAndRedemption.setupStatus === SetupStatus.PartiallyCompleted);
  }

  public setIndentifiersSchedule(identifiers: Identifiers[]) {
    this._model.identifiers = identifiers;
  }

  private clone<T>(t: T): T {
    return _.cloneDeep(t);
  }

  public setDividendThresholdOnQuickTerms() {
    if (this._model.quickTerms) {
      this._model.quickTerms.dividendTresholdValue = 'Schedule';
      this._model.quickTerms.tresholdGrowth = null;
    }
  }

  public setDividendProtectionSchedule() {
    if (this._model.quickTerms && !LvMath.isZeroNumber(this._model.quickTerms.dividendTresholdValue) && 
      this._model.quickTerms.dividendTresholdType && this._model.fullTerms.dividendProtection) {

      const dividendTresholdValue = parseFloat(this._model.quickTerms.dividendTresholdValue.toString());

      if (dividendTresholdValue !== 0 && this._model.quickTerms.tresholdGrowth) {
        let thresholdValues = [];
        const maturity = this._model.fullTerms.issueAndRedemption.isPerpetual ? 10 : this._model.quickTerms.maturityDateYears;
        const lengthFrequency = this.mapThresholdFrequency(this._model.fullTerms.dividendProtection.tresholdPeriodFrequency);
        const divideNumber = this.mapThresholdFrequencyMonths(this._model.fullTerms.dividendProtection.tresholdPeriodFrequency);
        const length = lengthFrequency * maturity;

        const thresholdValueType = this.mapThresholdValueType(this._model.quickTerms.dividendTresholdType);
        thresholdValues = this.getThresholdValues(dividendTresholdValue, this._model.quickTerms.tresholdGrowth, length);

        this._model.fullTerms.dividendProtection.schedule = [];

        if (this._model.quickTerms.tresholdGrowth && this._model.quickTerms.tresholdGrowth > 0) {
          for (let i = 0; i < length; i++) {
            this._model.fullTerms.dividendProtection.schedule.push({
              startDate: this.calculateDateForMonth(i * divideNumber, this._model.fullTerms.issueAndRedemption.firstSettlementDate),
              tresholdValue: this._model.quickTerms.tresholdGrowth > 0 ? thresholdValues[i] : dividendTresholdValue,
              tresholdValueType: thresholdValueType,
              triggerValue: 0,
              tresholdValue2: 0,
              tresholdValue2Type: thresholdValueType,
              triggerValue2: 0
            });
          }
        } else {
          this._model.fullTerms.dividendProtection.schedule.push({
            startDate: this.calculateDateFromYear(0, this._model.fullTerms.issueAndRedemption.firstSettlementDate),
            tresholdValue: dividendTresholdValue,
            tresholdValueType: thresholdValueType,
            triggerValue: 0,
            tresholdValue2: 0,
            tresholdValue2Type: thresholdValueType,
            triggerValue2: 0
          });
        }
      }
    }
  }

  public async getConversionRatio(): Promise<number> {
    const crossFx = await this.getCrossFx();
    if (this._model && this._model.fullTerms) {
      const termsDoc = Object.assign(new ConvertibleBondNewIssueDocument(), this._model.fullTerms);
      return termsDoc.getConversionRatio(crossFx);
    }
    return 0;
  }

  async updateConversionRatioCommand() {
    try {
      const newRatio = await this.getConversionRatio();

      if (this._ratio !== newRatio) {
        this._ratio = newRatio;
        this.executeCommand(new UpdateRatioCommand(this._ratio));
      }
    }
    catch (error) {
      this._errorService.handleError(error);
    }
  }

  public setCouponType() {
    if (this._model.fullTerms.issueAndRedemption.setupStatus === SetupStatus.NewIssue && this._model.fullTerms.priceTalk) {

      if (this._model.fullTerms.priceTalk.couponBest > 0 || this._model.fullTerms.priceTalk.couponWorst > 0) {
            
        if (this._model.fullTerms.coupon && !this._model.fullTerms.coupon .fixed) {
          this._model.fullTerms.coupon.fixed = new FixedCouponData();
          this._model.fullTerms.coupon.fixed.stepUpCouponSchedule = [];
        }

        if (this._model.fullTerms.coupon.type === CouponType.ZeroCoupon || this._model.fullTerms.coupon .type === CouponType.Floating ||
          this._model.fullTerms.coupon.type === CouponType.Custom) {
            this._model.fullTerms.coupon.type = CouponType.Fixed;
        }
        
        this._model.fullTerms.coupon.fixed.rate = (this._model.fullTerms.priceTalk.couponWorst + this._model.fullTerms.priceTalk.couponBest) / 2;
      } else {
        this._model.fullTerms.coupon.type = CouponType.ZeroCoupon;
      }

    }
  }


  /**
   * Sets special case date of copay accrual start date.
   * Because this date does not have same look and model as other dates with days adjust in settings.
   */
  public setCoPayAccruedDate(): void {
    if (!this._model.fullTerms.coupon.hasCoPay && !!this._model.fullTerms.coupon?.coPay) {
      const coPayAccrualStartDateDaysAdjust = this._service.couponSettings?.coPay?.coPayAccrualStartDate;

      if (coPayAccrualStartDateDaysAdjust) {
        const firstSettlementDateCopy = new Date(this._model.fullTerms.issueAndRedemption.firstSettlementDate);
        firstSettlementDateCopy.setDate(firstSettlementDateCopy.getDate() + coPayAccrualStartDateDaysAdjust);
        this._model.fullTerms.coupon.coPay.coPayAccrualStartDate = firstSettlementDateCopy;
      }
      else {
        this._model.fullTerms.coupon.coPay.coPayAccrualStartDate = new Date(this._model.fullTerms.issueAndRedemption.firstSettlementDate);
      }
    }
  }

  public async getCrossFx(): Promise<number> {
    let crossFx = 1;
    if (this._model && this._model.fullTerms && 
      this._model.fullTerms.issueAndRedemption.underlyingEquity && this._model.fullTerms.issueAndRedemption.currencyCode &&
      this._model.fullTerms.issueAndRedemption.underlyingEquity.currencyCode && this._model.fullTerms.stockPriceReference &&
      this._model.fullTerms.stockPriceReference?.referenceType !== StockReferenceType.Fixed &&
      this._model.fullTerms.issueAndRedemption.currencyCode !== this._model.fullTerms.issueAndRedemption.underlyingEquity.currencyCode) {
        // tslint:disable-next-line:max-line-length
        crossFx = await this._sharedMarketDataService.getFxRate(this._model.fullTerms.issueAndRedemption.currencyCode, this._model.fullTerms.issueAndRedemption.underlyingEquity.currencyCode);
    }

    return 1 / crossFx;
  }

  updateQuickTermsModel(quickTerms: QuickTerms) {
    this._model.quickTerms = quickTerms;
  }

  getThresholdValues(thresholdValue: number, growth: number, length: number): number[] {
    const thresholdValues = [];

    for (let i = 0; i < length; i++) {
      if (i === 0 ) {
        thresholdValues.push(thresholdValue);
      }
      else {
        const percent = thresholdValue * growth / 100;
        thresholdValue = thresholdValue + percent;
        thresholdValues.push(thresholdValue);
      }
    }

    return thresholdValues;
  }

  // private getStockPrice(crossFx: number): number {
  //   if (!!this._excelSvc?.getFieldValue('ISSUE_STK_REF_CBCCY')) {
  //     return parseFloat(this._excelSvc.getFieldValue('ISSUE_STK_REF_CBCCY'));
  //   }
  //   else if (!!this._excelSvc?.getFieldValue('STK_REF_EQCCY')) {
  //     return parseFloat(this._excelSvc.getFieldValue('STK_REF_EQCCY'));
  //   }
  //   else if (!!this._excelSvc?.getFieldValue('STK_REF_CBCCY')) {
  //     return crossFx * parseFloat(this._excelSvc.getFieldValue('STK_REF_CBCCY'));
  //   }
  //   else if (!!this._excelSvc?.getFieldValue('STK_REF')) {
  //     return parseFloat(this._excelSvc.getFieldValue('STK_REF'));
  //   }

  //   return null;
  // }

  private calculateDateFromYear(year: number, firstSettlementDate: Date): Date {
    let mFirst;
    const inputYear = Math.floor(year);
    const inputMonth = Math.floor(12 * (year - inputYear));
    const diff = (12 * ((Math.round(year * 10) / 10) - inputYear)) - inputMonth;


    const today = moment.utc(new Date());
    const adjustDays = this.getAdjustDays(today.weekday());

    mFirst = firstSettlementDate ?  moment.utc(firstSettlementDate) : today.add(adjustDays, 'days');

    mFirst = mFirst.add(inputYear, 'y');
    mFirst = mFirst.add(inputMonth, 'M');

    if (diff !== 0) {
      const days = diff * mFirst.daysInMonth();
      mFirst = mFirst.add(Math.floor(days), 'd');
    }
    return mFirst.toDate();
  }

  private calculateDateForMonth(month: number, firstSettlementDate: Date): Date {
    const today = moment.utc(new Date());
    const adjustDays = this.getAdjustDays(today.weekday());
    const mFirst = firstSettlementDate ?  moment.utc(firstSettlementDate).add(month, 'M') : today.add(adjustDays, 'days').add(month, 'M');

    return mFirst.toDate();
  }

  private getAdjustDays(weekDay: number): number {
    if (weekDay === 6) {
      return 5;
    }
    if (weekDay === 7) {
      return 4;
    }

    return 3;
  }

  private mapThresholdValueType(thresholdType: DividendThreshold): DividendProtectionTresholdValueType {

    switch (thresholdType) {
      case DividendThreshold.Absolute:
      return DividendProtectionTresholdValueType.Cash;
      case DividendThreshold.Percentage:
      return DividendProtectionTresholdValueType.PercentOfStockPrice;
      case DividendThreshold.PercentOfEps:
      return DividendProtectionTresholdValueType.PercentOfEPS;
      case DividendThreshold.CashPerBond:
        return DividendProtectionTresholdValueType.CashPerBond;
    }

    return DividendProtectionTresholdValueType.Cash;
  }

  private mapThresholdFrequency(frequency: Frequency): number {
    switch (frequency) {
      case Frequency.Annual:
        return 1;
      case Frequency.SemiAnnual:
        return 2;
      case Frequency.Quarterly:
        return 4;
      case Frequency.Bimonthly:
        return 6;
      case Frequency.Monthly:
        return 12;
    }

    return 1;
  }

  private mapThresholdFrequencyMonths(frequency: Frequency): number {
    switch (frequency) {
      case Frequency.Annual:
        return 12;
      case Frequency.SemiAnnual:
        return 6;
      case Frequency.Quarterly:
        return 3;
      case Frequency.Bimonthly:
        return 2;
      case Frequency.Monthly:
        return 1;
    }

    return 12;
  }

  private validateYieldOrRedemptiuonCalcRequest(request: ICalculateYieldAndRedemptionRequest): boolean {
    let isValid = true;
    const terms = request.terms;
    const type = request.pricingType;

    if (terms.issueAndRedemption.subType === ConvertibleBondSubType.PEPS) {
      isValid = false;
    }
    if (!terms.issueAndRedemption.maturityDate) {
      isValid = false;
    }
    if (terms.coupon && terms.coupon.type === CouponType.Floating) {
        isValid = false;
    }
    if (type === PricingType.Best && terms.priceTalk.redemptionValueBest && terms.priceTalk.redemptionValueBest === 0) {
      isValid = false;
    }
    if (type === PricingType.Worst && terms.priceTalk.redemptionValueWorst && terms.priceTalk.redemptionValueWorst === 0) {
      isValid = false;
    }

    return isValid;
  }

}
