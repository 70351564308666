<lv-flex-box direction="row" *ngIf="isDividendYieldOverrideUsed">
  <div class="lv-label lv-label-field lv-dividend-yield-warning">Dividends from Dividend Override are used</div>
</lv-flex-box>
<lv-advanced-grid
  data-cy="DM-315"
  [columns]="columns"
  [records]="scheduleDividends"
  lvId="lvMarketDataDividendsScheduleGrid"
  [createFormGroup]="createFormGroup"
  [parseFn]="parseFn"
  [editable]="editEnabled"
  sortBy="date"
  class="lv-schedule-dividends-table"
  [showXlLabel]="hasScheduleInExcelOverride"
  [excelFieldAlias]="excelFieldAliasLabel"
  [isFieldFromExcelEnabled]="isFieldFromExcelEnabled">
</lv-advanced-grid>
