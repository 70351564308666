<div class="lv-modal-mask" [@mask]
  *ngIf="isOpened"
  [ngClass]="maskClass">
</div>
<div class="lv-modal" [@modal]
  *ngIf="isOpened"
  [ngClass]="modalClass">
  <div class="lv-modal-table">
    <div class="lv-modal-table-cell">
      <div class="lv-modal-table-cell-content">
        <div class="lv-modal-container">
            <div class="lv-modal-title">
              Application version
            </div>
            <div class="lv-modal-content">
              <div>Application has a new version.</div>
              <div>Please, reload page to get the latest features.</div>
            </div>
            <div class="lv-modal-footer">
              <button
                kendoButton
                [primary]="true"
                (click)="onReloadApplication()">
                Reload Application
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>