<div class="lv-workspace-panel">
  <div class="lv-workspace-panel-items">
    <lv-workspace *ngFor="let a of items"
      [state]="a"
      (doSelect)="onSelect($event)"
      (didEdit)="onEdit($event)"
      (doClose)="onClose($event)">
    </lv-workspace>
    <div class="lv-icon-square"
      [class.lv-icon-square--disabled]="isLoading"
      (click)="onAdd()">
      <i class="k-icon k-i-plus"></i>
    </div>
  </div>
  <div class="lv-workspace-panel-spacer"></div>
  <div class="lv-workspace-panel-item-content">
    <ng-content></ng-content>
  </div>
</div>