import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpClientBase2 } from '@lv-core-ui/api';
import { ITokenResponse, LvAuthorizationError } from '@lv-core-ui/models';
import { LocalStorage } from '@lv-core-ui/util';
import { IDENTITY_SERVER_BASE_URL, LvSecurityManagementError } from '@lv-security/models';

/**
 * Token service.
 */
@Injectable()
export class TokenService extends HttpClientBase2 {

  constructor(
    http: HttpClient,
    @Inject(IDENTITY_SERVER_BASE_URL) baseUrl: string
  ) {
    const resourceUrl = `${baseUrl}/token`;
    super(http, resourceUrl);
  }

  /**
   * Does logout from application.
   * @returns True indicating that logout is successful.
   */
  async logout(): Promise<boolean> {
    try {
      await this.deleteAsync<void>('/logout');

      LocalStorage.clear();
      return true;
    }
    catch (error) {
      throw this.handleError(error, e => new LvSecurityManagementError(e.message));
    }
  }

  /**
   * Gets new refresh token.
   * @returns ITokenResponse object
   */
  async refreshToken(): Promise<ITokenResponse> {
    try {
      let account = LocalStorage.getTokenResponse();

      account = await this.getAsync<ITokenResponse>(null, `/refresh`, true);

      LocalStorage.setTokenResponse(account);

      return account;
    }
    catch (error) {
      if (error.name === 'Authorization') {
        throw this.handleError(error, e => new LvAuthorizationError(e.message), true);
      }

      throw this.handleError(error, e => new LvSecurityManagementError(e.message));
    }
  }
}
