
export enum MinAdjustmentType {
	None = 'None',
	ConversionRatio = 'ConversionRatio',
	ConversionPrice = 'ConversionPrice'
}

export enum MinAdjustmentTypeDescripton {
	None = 'None',
	ConversionRatio = 'Conversion Ratio',
	ConversionPrice = 'Conversion Price'
}

export enum CashDistributionDateType {

	AtDividendDate = 'AtDividendDate',
	AtNextCouponDate = 'AtNextCouponDate',
	AtConversion = 'AtConversion'
}
export enum AdjustmentType {

	None = 'None',
	ConversionRatio = 'ConversionRatio',
	ConversionPrice = 'ConversionPrice'
}
export enum AdjustmentFormulaType {

	Type1 = 'Type1',
	Type2 = 'Type2',
	Type3 = 'Type3',
	Type4 = 'Type4',
	Type5 = 'Type5'
}
export enum AdjustmentFormulaTypeDescription {

	Type1 = 'Type1: CR*[S+max(D-T, 0)]/S',
	Type2 = 'Type2: CR*S/[S-max(D-T, 0)]',
	Type3 = 'Type3: CR*CP/[CP-max(D-T, 0)]',
	Type4 = 'Type4: CR*[S-max(T-sumPrevD, 0)]/(S-D)',
	Type5 = 'Type5: CR*(S-T)/(S-sumD)'
}

export enum SpotLevelAtAdjustmentFormulaType {

	BeforeDividendExDate = 'BeforeDividendExDate',
	AfterDividendExDate = 'AfterDividendExDate'
}
export enum DividendProtectionTresholdValueType {

	Cash = 'Cash',
	PercentOfStockPrice = 'PercentOfStockPrice',
	PercentOfEPS = 'PercentOfEPS',
	CashPerBond = 'CashPerBond'
}
export enum DividendProtectionType {

	CashDistribution = 'CashDistribution',
	ConversionRatioAdjustment = 'ConversionRatioAdjustment'
}
export enum DividendProtectionTresholdType {

	FullProtection = 'FullProtection',
	FlatForPeriod = 'FlatForPeriod',
	Schedule = 'Schedule'
}
export enum DividendProtectionPeriodFrequency {

	FullProtection = 'FullProtection',
	FlatForPeriod = 'FlatForPeriod',
	Schedule = 'Schedule'
}
export enum DividendProtectionTresholdFormulaType {

	Min = 'Min',
	Max = 'Max',
	Sum = 'Sum',
	Single = 'Single'
}

export enum DividendThreshold {
	Absolute = 'Absolute',
	Percentage = 'Percentage',
	PercentOfEps = 'PercentOfEps',
	CashPerBond = 'CashPerBond'
}

export enum DividendThresholdDescription {
	Absolute = 'Cash',
	Percentage = '% of Stock Price',
	PercentOfEps = '% of EPS',
	CashPerBond = 'Cash per Bond'
}
