import { Injectable } from '@angular/core';

// import { ItemType } from 'golden-layout';
import { v4 } from 'uuid';

import { LvGoldenLayoutComponent } from '../../components/lv-golden-layout/lv-golden-layout.component';
import { ILvGlComponent, ILvGlLayoutConfiguration } from '../../models/configuration';
import { LvGoldenLayoutInitializaionError } from '../../models/errors';
import { LvGoldenLayoutStorage } from '../../util/lv-golden-layout-storage';

import { ItemType, GoldenLayout, LayoutManager, ComponentItem, ComponentItemConfig,
  ItemConfig, RowOrColumnItemConfig } from 'src/app/leversys-golden-layout-source/src/index';

@Injectable()
export class LvGoldenLayoutService {

  private _layoutContainer: LvGoldenLayoutComponent;
  private _registeredComponents: {
    [componentName: string]: boolean;
  };

  constructor(
    private _lvGoldenLayoutStorage: LvGoldenLayoutStorage
  ) {
    this._registeredComponents = {};
  }

  get newGuid(): string {
    return v4();
  }

  /**
   * Create empty golden layout configuration
   * @param configurationId Configuration identifier
   * @returns Empty configuration
   */
  createEmptyLayout(configurationId?: string): ILvGlLayoutConfiguration {
    return {
      id: configurationId || `gl-container-${this.newGuid}`,
      layoutConfig: {
        root: {
          type: ItemType.row,
          isClosable: false,
          content: [],
        },
      }
    };
  }

  /**
   * Register golden layout container to service
   * @param container Golden layout container
   */
  registerLayoutContainer(container: LvGoldenLayoutComponent) {
    this._layoutContainer = container;
  }

  /**
   * Register container components and save layout
   */
  bootstrapLayout() {
    try {
      this._lvGoldenLayoutStorage
        .getComponents(this._layoutContainer.configuration.id)
        .forEach(c => this.registerComponent(c));

      this._layoutContainer.goldenLayout.loadLayout(this._layoutContainer.configuration.layoutConfig);
      this._layoutContainer.goldenLayout.saveLayout();
    }
    catch (error) {
      if (typeof(error) === 'string') {
        error = new LvGoldenLayoutInitializaionError(error);
      }

      throw error;
    }
  }

  /**
   * Creates initial component config and add component to layout
   * @param title Component title
   * @param componentName Component name (type)
   * @param state Component state
   * @returns Created component config
   */
  addComponent(title: string, componentName: string, state?: any): ILvGlComponent {
    const componentId = `gl-component-${this.newGuid}`;
    const componentConfig = {
      id: componentId,
      configurationId: this._layoutContainer.configuration.id,
      componentName: componentName,
      state: {
        ...(state || {}),
        id: componentId,
      }
    } as ILvGlComponent;

    this._lvGoldenLayoutStorage.addComponent(componentConfig);

    this.registerComponent(componentConfig);

    this.addComponentToLayout(this._layoutContainer.goldenLayout, componentConfig.id, title);

    return componentConfig;
  }

  /**
   * Creates empty layout if layout not exists and add componet
   * @param layout Curretn layout
   * @param componentName Component name
   * @param title Component title
   */
  addComponentToLayout(layout: GoldenLayout, componentName: string, title: string) {
    const item = {
      type: ItemType.component,
      title: title,
    } as ComponentItem;

    if (!layout.rootItem) {
      layout.loadLayout({
        root: {
          type: ItemType.row,
          isClosable: true,
          content: [],
        } as RowOrColumnItemConfig,
      });
    }

    if (layout.rootItem.type === 'stack') {
      layout.newComponentAtLocation(componentName, item, title,
        LayoutManager.defaultLocationSelectors.filter(x => x.typeId === 2));
    }

    layout.newComponentAtLocation(componentName, item, title,
      LayoutManager.defaultLocationSelectors.filter(x => x.typeId === 3));
  }

  /**
   * Set component title regardles of layout configuration.
   * This should be called before layout initialization.
   * @param componentName Unique identifier of the compoment in layout configuration
   * @param title Component title
   * @param content Either root's or item's content (children nodes)
   */
  updateComponentTitle(componentName: string, title: string, content?: ItemConfig[]) {
    if (Array.isArray(content)) {
      for (let i = 0; i < content.length; i++) {
        const item = content[i];
        if (item.type === 'component') {
          const c = item as ComponentItemConfig;
          if (c.componentType === componentName) {
            c.title = title;
            return true;
          }
        }

        if (this.updateComponentTitle(componentName, title, item.content)) {
          return true;
        }
      }
    }
  }

  /**
   * Unregister component and destroy service container
   */
  unregister() {
    this._layoutContainer = null;
    this._registeredComponents = {};
  }

  /**
   * Register component to layout cotainer
   * @param componentConfig Component config
   */
  private registerComponent(componentConfig: ILvGlComponent) {
    if (!this._registeredComponents[componentConfig.id]) {
      this._registeredComponents[componentConfig.id] = true;
      const registerCompFn = this._layoutContainer.registerComponentFactory(componentConfig);
      this._layoutContainer.goldenLayout.registerComponentFactoryFunction(componentConfig.id, registerCompFn);
    }
  }
}
