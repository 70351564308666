<kendo-grid class="lv-scenario-grid"
  [data]="view.model.records"
  [resizable]="true">
  <kendo-grid-column
    [locked]="true"
    [width]="view.defaultDimensionColumnWidth">
    <ng-template kendoGridHeaderTemplate>
      <div class="aligned-left">{{ view.model.dimensionDescription }}</div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="input">
        <ng-container *ngIf="!view.showFirstDimensionAsPercentage">
          {{ dataItem.dimensionValue | number:view.numberFormat }}
        </ng-container>
        
        <ng-container *ngIf="view.showFirstDimensionAsPercentage">
          {{ dataItem.dimensionValue | number:view.numberFormat }} ({{ dataItem.dimensionValue | lvPercent:view.firstDimensionChangeRef:view.percentFormat }})
        </ng-container>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column-group *ngFor="let group of view.model.groups"
    [title]="group.title"
    [width]="group.width">
      <kendo-grid-column *ngFor="let column of group.columns"
        [title]="column.title"
        [width]="column.width">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem[column.columnId] | number:view.numberFormat }}
        </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>
</kendo-grid>
