import { v4 } from 'uuid';
import * as _ from 'lodash';
import { IVolatility, VolatilityType } from '@lv-analytics/models/market-data/volatility';

/**
 * Volatility view.
 */
export class VolatilityView {

  volatilitySettings: IVolatility;
  originalValue: IVolatility;

  isFlatVolatility: boolean;
  isUpsideDownside: boolean;
  isTermStructure: boolean;
  isSurface: boolean;
  useLocalVolFromTwoVolsId: string;

  numberOfDecimals = '2';
  numberFormat = '#.##';

  constructor() {
    this.volatilitySettings = {
      volatilityTermStructureSchedule: [],
      volatilitySurface: {}
    } as IVolatility;

    this.useLocalVolFromTwoVolsId = v4();
  }

  /**
   * Does initialization.
   * @param volatilitySettings IVolatility object.
   */
  init(volatilitySettings: IVolatility) {
    this.volatilitySettings = volatilitySettings;
    this.originalValue = _.cloneDeep(volatilitySettings);
    this.onVolatilitySourceChange();
  }

  /**
   * Occurs on volatility source change.
   */
  onVolatilitySourceChange() {
    this.isFlatVolatility = this.volatilitySettings.volType === VolatilityType.Flat;
    this.isUpsideDownside = this.volatilitySettings.volType === VolatilityType.UpsideDownside;
    this.isTermStructure = this.volatilitySettings.volType === VolatilityType.TermStructure;
    this.isSurface = this.volatilitySettings.volType === VolatilityType.VolatilitySurface;
  }
}
