import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, HostBinding, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'instrument-authorization',
  templateUrl: './instrument-authorization.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstrumentAuthorizationComponent implements OnInit {

  @Input() instrumentName: string;

  constructor() { }

  @HostBinding('class.instrument-authorization')
  public get isInstrumentAuthorizationComponent(): boolean {
    return true;
  }

  ngOnInit() {
  }

}
