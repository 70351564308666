/**
 * Equity to credit calculation type.
 */
export enum EquityToCreditCalculationType {
  None = 'None',
  Credit = 'Credit',
  CreditAndVol = 'CreditAndVol'
}

/**
 * Equity to credit calculation type description.
 */
export enum EquityToCreditCalculationTypeDescription {
  None = 'None',
  Credit = 'Credit',
  CreditAndVol = 'Credit and Volatility'
}
