<div class="lv-grid-layout" #gridElement>
  <div *ngFor="let column of gridLayout.columns"
    class="lv-grid-layout-column"
    [class.lv-grid-layout-column--last]="column.isLast"
    [style.min-width.px]="columnWidth">
    <div *ngFor="let cell of column.cells; let i = index"
      class="lv-grid-layout-cell">
      <ng-container *ngTemplateOutlet="cellTemplate; context: { cell: cell, cellIndex: i }"></ng-container>
    </div>
  </div>
</div>