<lv-flex-box class="lv-convertible-bond-terms-conversion">
  <lv-flex-box direction="row" data-cy="DM-1229">
    <lv-flex-box class="lv-header-first-col">
      <lv-flex-box direction="row" *ngIf="!view.isInstrumentPepsOrPercs" data-cy="DM-897">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="deltaNeutralCASourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Delta Neutral Conversion Amount
          </span>
          <lv-xl-label [field]="'CONV_DLTA_NEUTRAL'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [id]="view.fxAdjustedConversionAmountCheckboxId"
            name="terms-general-conversion-delta-neutral-amount"
            [(ngModel)]="view.model.fxAdjustedConversionAmount"
            (ngModelChange)="onDeltaNeutralConversionChange()">
          <label class="k-checkbox-label" lvId="deltaNeutralConversionAmount" [for]="view.fxAdjustedConversionAmountCheckboxId"></label>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" *ngIf="!view.isZeroCouponType" data-cy="DM-890">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="keepAccruedSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Keep Accrued
          </span>
          <lv-xl-label [field]="'CONV_KEEP_ACCRD'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [id]="view.keepAccruedCheckboxId"
            name="terms-general-conversion-keep-accrued"
            [(ngModel)]="view.model.keepAccrued"
            (ngModelChange)="onModelChange()">
          <label class="k-checkbox-label" lvId="keepAccrued" [for]="view.keepAccruedCheckboxId"></label>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" *ngIf="!view.isZeroCouponType" data-cy="DM-891">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="forfeitCouponOnCatMSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span *ngIf="!isOpenedFromExcel">
            Forfeit Coupon on Conversion at Maturity
          </span>
          <span *ngIf="isOpenedFromExcel">
            Forfeit Coupon on Conversion at Mat.
          </span>
          <lv-xl-label [field]="'CONV_FRFT_CPN_MAT'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [id]="view.forfeitCouponOnConversionAtMaturityCheckboxId"
            name="terms-general-conversion-forfeit-coupon-maturity"
            [(ngModel)]="view.model.forfeitCouponOnConversionAtMaturity"
            (ngModelChange)="onModelChange()">
          <label
            class="k-checkbox-label"
            lvId="forfeitCouponOnConversionAtMaturity"
            [for]="view.forfeitCouponOnConversionAtMaturityCheckboxId"></label>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" *ngIf="!view.isZeroCouponType" data-cy="DM-892">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="forfeitCouponOnOCSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Forfeit Coupon on Optional Conversion
          </span>
          <lv-xl-label [field]="'CONV_FRFT_CPN_CONV'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [id]="view.forfeitCouponOnOptionalConversionCheckboxId"
            name="terms-general-conversion-forfeit-coupon-optional"
            [(ngModel)]="view.model.forfeitCouponOnOptionalConversion"
            (ngModelChange)="onModelChange()">
          <label class="k-checkbox-label" [for]="view.forfeitCouponOnOptionalConversionCheckboxId"></label>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" *ngIf="!view.isPeps && !view.isPercs" data-cy="DM-1415">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="exerciseScheduleSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Exercise Schedule
          </span>
          <lv-xl-label [field]="'CONV_EXCS_SCHED_RANGE'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [id]="view.exerciseScheduleCheckboxId"
            name="terms-general-conversion-into-new-shares"
            [(ngModel)]="view.isExerciseScheduleVisible"
            (ngModelChange)="onExcerciseScheduleChange()">
          <label class="k-checkbox-label" [for]="view.exerciseScheduleCheckboxId"></label>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-893">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="conversionIntoNSSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Conversion into New Shares
          </span>
          <lv-xl-label [field]="'CONV_NEW_SHARES'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [id]="view.conversionIntoNewSharesCheckboxId"
            name="terms-general-conversion-into-new-shares"
            [(ngModel)]="view.model.conversionIntoNewShares"
            (ngModelChange)="onModelChange()">
          <label class="k-checkbox-label" lvId="conversionIntoNewShares" [for]="view.conversionIntoNewSharesCheckboxId"></label>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" *ngIf="!view.isInstrumentPepsOrPercs" data-cy="DM-847">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="acquisitionSharesSettlementTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Acquisition Shares Settlement
          </span>
          <lv-xl-label [field]="'ACQ_SHRS_SETTLE'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [id]="view.acquisitionSharesSettlementId"
            name="terms-general-conversion-into-new-shares"
            [(ngModel)]="view.model.useAcquisitionSharesSettlement"
            (ngModelChange)="handleAcquisitionSharesChange()">
          <label class="k-checkbox-label" lvId="acquisitionSharesSettlement" [for]="view.acquisitionSharesSettlementId"></label>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-885">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="conversionSettlementSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Conversion Settlement
          </span>
          <lv-xl-label [field]="'CONV_STTLMNT_TYPE'"></lv-xl-label>
        </div>
        <div class="lv-input-field lv-input-field--auto">
          <kendo-dropdownlist
            name="conversion-settlement"
            [data]="view.conversionSettlementLookup.items"
            valueField="id"
            textField="text"
            valuePrimitive="true"
            [popupSettings]="{ width: 151 }"
            [(ngModel)]="view.model.conversionSettlement"
            (ngModelChange)="onModelChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box *ngIf="view.isNumberOfFixingDaysFieldVisible" direction="row" data-cy="DM-886">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="numberOfFixingDayTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Number of Fixing Days
          </span>
          <lv-xl-label [field]="'CONV_STTLMNT_DAYS'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            name="number-of-fixing-days"
            [format]="view.numberFormat"
            [decimals]="view.numberOfDecimals"
            [(ngModel)]="view.model.conversionSettlementNumberFixingDays"
            (didBlur)="onModelChange()"
            (didKeyDownEnter)="onModelChange()">
          </lv-numeric-text-box>
        </div>
      </lv-flex-box>
      <lv-flex-box *ngIf="view.isConversionSettlementNumberFixingVisible" direction="row" data-cy="DM-887">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="conversionSettlementObservPeriodStartBeforeMatDateTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Observation Period Start (Maturity Date)
          </span>
          <lv-xl-label [field]="'CONV_STTLMNT_OBS_MAT'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            name="observation-period-start-maturity-date"
            [format]="view.numberFormat"
            [decimals]="view.numberOfDecimals"
            [(ngModel)]="view.model.conversionSettlementObservPeriodStartBeforeMatDate"
            (didBlur)="onModelChange()"
            (didKeyDownEnter)="onModelChange()"
            lvId="observPeriodStartBeforeMatDate">
          </lv-numeric-text-box>
        </div>
      </lv-flex-box>
      <lv-flex-box *ngIf="view.isConversionSettlementNumberFixingVisible" direction="row" data-cy="DM-888">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="conversionSettlementObservPeriodStartBeforeIssuerCallTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Observation Period Start (Issuer Call)
          </span>
          <lv-xl-label [field]="'CONV_STTLMNT_OBS_CALL'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            name="observation-period-start-issuer-call"
            [format]="view.numberFormat"
            [decimals]="view.numberOfDecimals"
            [(ngModel)]="view.model.conversionSettlementObservPeriodStartBeforeIssuerCall"
            (didBlur)="onModelChange()"
            (didKeyDownEnter)="onModelChange()"
            lvId="observPeriodStartBeforeIssuerCall">
          </lv-numeric-text-box>
        </div>
      </lv-flex-box>
      <lv-flex-box *ngIf="view.isConversionSettlementNumberFixingVisible" direction="row" data-cy="DM-889">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="conversionSettlementObservationLagTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            {{observationPeriodLagLabel}}
          </span>
          <lv-xl-label [field]="'CONV_STTLMNT_CONV_LAG'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            name="early-conversion-observation-lag"
            [format]="view.numberFormat"
            [decimals]="view.numberOfDecimals"
            [(ngModel)]="view.model.conversionSettlementEarlyConversionObservationLag"
            (didBlur)="onModelChange()"
            (didKeyDownEnter)="onModelChange()"
            lvId="earlyConversionObservationLag">
          </lv-numeric-text-box>
        </div>
      </lv-flex-box>
      <!-- <lv-flex-box *ngIf="view.isConversionSettlementCashAmountVisible" direction="row">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="conversionTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'specifiedCashAmountTooltip'">
            Specified Cash Amount
          </span>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            name="specified-cash-amount"
            [format]="view.formatFour"
            [decimals]="view.decimalsFour"
            [(ngModel)]="view.model.conversionSettlementSpecifiedCashSettlementAmount"
            (didBlur)="onModelChange()"
            (didKeyDownEnter)="onModelChange()">
          </lv-numeric-text-box>
          <span class="lv-sufix-label">%</span>
        </div>
      </lv-flex-box> -->
    </lv-flex-box>
    <lv-flex-box *ngIf="!view.isPeps && !view.isPercs">
      <ng-container *ngIf="!view.isDetachable">
      <lv-flex-box direction="row" data-cy="DM-403">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="mandatoryConversionSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Mandatory Conversion
          </span>
          <lv-xl-label [field]="'CONV_MANDATORY'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [id]="view.mandatoryConversionCheckboxId"
            name="terms-general-conversion-mandatory-conversion"
            [(ngModel)]="view.model.mandatoryConversion"
            (ngModelChange)="onModelChange()">
          <label class="k-checkbox-label" [for]="view.mandatoryConversionCheckboxId"></label>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-411">
        <div  class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="contingentConversionSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Contingent Conversion
          </span>
          <lv-xl-label [field]="'CO_CO'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [id]="view.contigentConversionCheckboxId"
            name="terms-general-conversion-contingent-conversion"
            [(ngModel)]="view.model.isContigentConversion"
            (ngModelChange)="onContingentConversionChange()">
          <label lvId="contingentConversionCheckbox" class="k-checkbox-label" [for]="view.contigentConversionCheckboxId"></label>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-404">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="variableConversionSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Variable Conversion
          </span>
          <lv-xl-label [field]="'VAR_CONV'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [id]="view.variableConversionCheckboxId"
            name="terms-general-conversion-variable-conversion"
            [(ngModel)]="view.model.isVariableConversion"
            (ngModelChange)="onVariableConversionChange()">
          <label lvId="variableConversionCheckbox"  class="k-checkbox-label"  [for]="view.variableConversionCheckboxId"></label>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-407">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="resettableSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Resettable
          </span>
          <lv-xl-label [field]="'RESETTABLE'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [id]="view.resetableCheckboxId"
            name="terms-general-conversion-resetable"
            [(ngModel)]="view.model.isResetable"
            (ngModelChange)="onResettableChange()">
          <label lvId="resettableConversionCheckbox"  class="k-checkbox-label" [for]="view.resetableCheckboxId"></label>
        </div>
      </lv-flex-box>
    </ng-container>
      <lv-flex-box direction="row" data-cy="DM-894">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="conversionPriceCCYSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Conversion Price CCY
          </span>
          <lv-xl-label [field]="'CONV_PRICE_CCY'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <kendo-dropdownlist
            name="conversion-settlement"
            [data]="view.conversionPriceCCYLookup.items"
            valueField="id"
            textField="text"
            valuePrimitive="true"
            [(ngModel)]="view.conversionPriceCCY"
            (ngModelChange)="onConversionPriceCCYChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-895">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="rebateCCYSourceTooltip"
          filter="span">
          <span>
            Rebate CCY
          </span>
          <lv-xl-label [field]="'CONV_REBATE_CCY'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <kendo-dropdownlist
            name="conversion-rebateCcy"
            [data]="view.rebateCCYLookup.items"
            valueField="id"
            textField="text"
            valuePrimitive="true"
            [(ngModel)]="view.rebateCCY"
            (ngModelChange)="onRebatCurrencyChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
    </lv-flex-box>
  </lv-flex-box>
  <lv-flex-box direction="row" *ngIf="!view.isPeps && !view.isPercs" class="lv-conversion-schedule">
    <lv-flex-box *ngIf="view.isSchedulesVisible">
      <lv-flex-box direction="row">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="conversionScheduleTooltip"
        filter=".lv-label" 
        class="lv-label lv-label-field lv-label--title">CONVERSION SCHEDULE</div>
      </lv-flex-box>
      <lv-conversion-schedule #conversionSchedule
        [model]="view.model.schedule"
        [priceCurrency]="view.priceCurrency"
        [conversionCustomData] = "view.conversionCustomData"
        (didConversionScheduleChange)="onModelChange()">
      </lv-conversion-schedule>
    </lv-flex-box>
    <lv-flex-box class="lv-exercise-schedule" *ngIf="view.isExerciseScheduleVisible">
      <lv-flex-box direction="row">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="conversionExerciseScheduleTooltip"
        filter=".lv-label" 
        class="lv-label lv-label-field lv-label--title">
          <span>EXERCISE SCHEDULE</span> 
        </div>
      </lv-flex-box>
      <lv-exercise-schedule #exerciseSchedule
        [model]="view.model.exerciseSchedule"
        (didExerciseScheduleChange)="onModelChange()">
      </lv-exercise-schedule>
    </lv-flex-box>
  </lv-flex-box>

  <lv-flex-box class="lv-acquisition-shares" *ngIf="view.isAcquisitionSharesSettlementSelected && !view.isInstrumentPepsOrPercs">
    <lv-fieldset-panel legend="ACQUISITION SHARES SETTLEMENT">
      <lv-flex-box direction="row">
        <lv-flex-box class="lv-acquisition-shares-first-column">
          <lv-flex-box direction="row" data-cy="DM-848">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="acquisitionNoticeStartDateTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Notice Start Date
              </span>
              <lv-xl-label [field]="'ACQ_SHRS_START_DATE'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <kendo-datepicker
              name="acquisitionNoticeStartDate"
              [(ngModel)]="view.model.acquisitionNoticeStartDate"
              (ngModelChange)="onModelChange()">
            </kendo-datepicker>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-849">
            <div class="lv-label lv-label-field lv-label-checkbox-short"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="acquisitionNoticeEndDateTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Notice End Date
              </span>
              <lv-xl-label [field]="'ACQ_SHRS_END_DATE'"></lv-xl-label>
            </div>
            <lv-datepicker
              name="acquisitionNoticeEndDate"
              checkBoxLvId = "acquisitionNoticeEndDateCheckBoxId"
              [datePickerLvId] = "'acquisitionNoticeEndDatePickerId'"
              [(ngModel)]="view.model.acquisitionNoticeEndDate"
              [checked]="view.acquisitionNoticeEndDateEnabled"
              (didCheckboxValueChange)="acquisitionNoticeEndDateCheckboxChanged($event)"
              (ngModelChange)="onModelChange()">
            </lv-datepicker>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-850">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="acquisitionOptionDateInputTypeTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Option Date Input Type
              </span>
              <lv-xl-label [field]="'ACQ_SHRS_OPTION_DATE_TYPE'"></lv-xl-label>
            </div>
            <div class="lv-input-field lv-input-field--auto">
              <kendo-dropdownlist
                name="acquisitionOptionDateInputType"
                [data]="view.optionalDateInputTypeLookup.items"
                valueField="id"
                textField="text"
                [popupSettings]="{ width: 106 }"
                [valuePrimitive]="true"
                [(ngModel)]="view.model.acquisitionOptionDateInputType"
                (ngModelChange)="onModelChange()">
              </kendo-dropdownlist>
            </div>
          </lv-flex-box>
          <lv-flex-box *ngIf="!view.isOptionDateInputFixed" direction="row" data-cy="DM-1417">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="acquisitionNoticeMinDaysTooltip"
              filter="span">
              <span>
                Minimum Notice (days)
              </span>
              <lv-xl-label [field]="'ACQ_SHRS_MIN_NOTICE'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <lv-numeric-text-box
                name="acquisitionNoticeMinDays"
                [format]="formatZero"
                [decimals]="decimalsZero"
                [(ngModel)]="view.model.acquisitionNoticeMinDays"
                (didBlur)="onModelChange()"
                (didKeyDownEnter)="onModelChange()">
              </lv-numeric-text-box>
            </div>
          </lv-flex-box>
          <lv-flex-box *ngIf="!view.isOptionDateInputFixed" direction="row" data-cy="DM-1418">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="acquisitionNoticeMaxDaysTooltip"
              filter="span">
              <span>
                Maximum Notice (days)
              </span>
              <lv-xl-label [field]="'ACQ_SHRS_MAX_NOTICE'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <lv-numeric-text-box
                name="acquisitionNoticeMaxDays"
                [format]="formatZero"
                [decimals]="decimalsZero"
                [(ngModel)]="view.model.acquisitionNoticeMaxDays"
                (didBlur)="onModelChange()"
                (didKeyDownEnter)="onModelChange()">
              </lv-numeric-text-box>
            </div>
          </lv-flex-box>
          <lv-flex-box *ngIf="view.isOptionDateInputFixed" direction="row" data-cy="DM-851">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="conversionSettlementAcquisitionOptionDateTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Option Date
              </span>
              <lv-xl-label [field]="'ACQ_SHRS_OPTION_DATE_TYPE'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <kendo-datepicker
                name="conversionSettlementAcquisitionOptionDate"
                [(ngModel)]="view.model.conversionSettlementAcquisitionOptionDate"
                (ngModelChange)="onModelChange()">
              </kendo-datepicker>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-1419">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="acquisitionNoticeGivenTooltip"
              filter="span">
              <span>
                Acquisition Notice Given
              </span>
              <lv-xl-label [field]="'ACQ_SHRS_NOTICE_GIVEN'"></lv-xl-label>
            </div>
            <div class="lv-field lv-field--checkbox">
              <input class="k-checkbox"
                type="checkbox"
                [attr.id]="view.acquisitionNoticeGivenId"
                name="acquisitionNoticeGiven"
                [(ngModel)]="view.model.acquisitionNoticeGiven"
                (ngModelChange)="onAcquisitionNoticeGivenChange()">
              <label class="k-checkbox-label"
                [attr.for]="view.acquisitionNoticeGivenId">
              </label>
            </div>
          </lv-flex-box>
        </lv-flex-box>
        <lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-852">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="vwapInputTypeTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                VWAP Input Type
              </span>
              <lv-xl-label [field]="'ACQ_SHRS_VWAP_TYPE'"></lv-xl-label>
            </div>
            <div class="lv-input-field lv-input-field--auto">
              <kendo-dropdownlist
                name="acquisitionVWAPPeriodInputType"
                [data]="view.acquisitionVWAPPeriodInputTypeLookup.items"
                valueField="id"
                textField="text"
                [popupSettings]="{ width: 122 }"
                [valuePrimitive]="true"
                [(ngModel)]="view.model.acquisitionVWAPPeriodInputType"
                (ngModelChange)="onModelChange()">
              </kendo-dropdownlist>
            </div>
          </lv-flex-box>
          <lv-flex-box *ngIf="!view.isVWAPInputTypeFixed" direction="row" data-cy="DM-1416">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="vWAPPeriodObservationLagTooltip"
              filter="span">
              <span>
                VWAP Observation Lag
              </span>
              <lv-xl-label [field]="'ACQ_SHRS_VWAP_LAG'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <lv-numeric-text-box
                name="vWAPPeriodObservationLag"
                [format]="formatZero"
                [decimals]="decimalsZero"
                [(ngModel)]="view.model.vwapPeriodObservationLag"
                (didBlur)="onModelChange()"
                (didKeyDownEnter)="onModelChange()">
              </lv-numeric-text-box>
            </div>
          </lv-flex-box>
          <lv-flex-box *ngIf="view.isVWAPInputTypeFixed" direction="row" data-cy="DM-853">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="conversionSettlementVWAPPeriodStartDateTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                VWAP Start Date
              </span>
              <lv-xl-label [field]="'ACQ_SHRS_VWAP_START'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <kendo-datepicker
                name="conversionSettlementVWAPPeriodStartDate"
                [(ngModel)]="view.model.conversionSettlementVWAPPeriodStartDate"
                (ngModelChange)="onModelChange()">
              </kendo-datepicker>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-854">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="conversionSettlementMaximumAcquisitionSharesTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Maximum Shares (of CR)
              </span>
              <lv-xl-label [field]="'ACQ_SHRS_MAX_SHRS'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <lv-numeric-text-box
                name="conversionSettlementMaximumAcquisitionShares"
                [format]="'n2'"
                [decimals]="'2'"
                [(ngModel)]="view.model.conversionSettlementMaximumAcquisitionShares"
                (didBlur)="onModelChange()"
                (didKeyDownEnter)="onModelChange()">
              </lv-numeric-text-box>
              <span class="lv-sufix-label">%</span>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-855">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="acquisitionSettlementTypeTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Settlement Type
              </span>
              <lv-xl-label [field]="'ACQ_SHRS_TYPE'"></lv-xl-label>
            </div>
            <div class="lv-input-field lv-input-field--auto">
              <kendo-dropdownlist
                name="acquisitionSharesSettlType"
                [data]="view.acquisitionSharesSettlTypeLookup.items"
                valueField="id"
                [popupSettings]="{ width: 141 }"
                textField="text"
                [valuePrimitive]="true"
                [(ngModel)]="view.model.acquisitionSharesSettlType"
                (ngModelChange)="onModelChange()">
              </kendo-dropdownlist>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-856">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="acquisitionASVAdjustmentPercTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                ASV Adjustment
              </span>
              <lv-xl-label [field]="'ACQ_SHRS_ASV_ADJUSTMENT'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <lv-numeric-text-box
                name="acquisitionASVAdjustmentPerc"
                [format]="'n2'"
                [decimals]="'2'"
                [(ngModel)]="view.model.acquisitionASVAdjustmentPerc"
                (didBlur)="onModelChange()"
                (didKeyDownEnter)="onModelChange()">
              </lv-numeric-text-box>
              <span class="lv-sufix-label">%</span>
            </div>
          </lv-flex-box>
        </lv-flex-box>
      </lv-flex-box>
    </lv-fieldset-panel>
  </lv-flex-box>
  <lv-flex-box *ngIf="view.isResetSelected && !view.isPeps && !view.isPercs">
    <lv-fieldset-panel legend="RESET PARAMETERS">
      <lv-reset-conversion #resetConversion
        [model]="view.model.reset"
        [priceCurrency]="view.priceCurrency"
        [conversionCustomData] = "view.conversionCustomData"
        (didResetChange)="onModelChange()">
      </lv-reset-conversion>
    </lv-fieldset-panel>
  </lv-flex-box>
  <lv-flex-box class="lv-sub-fieldset-conversion" *ngIf="view.isContingentConversionSelected && !view.isPeps && !view.isPercs">
    <lv-fieldset-panel legend="COCO PARAMETERS">
      <lv-contingent-conversion #cocoConversion
        [model]="view.model.contingentConversion"
        (didContingentConversionChange)="onModelChange()">
      </lv-contingent-conversion>
    </lv-fieldset-panel>
  </lv-flex-box>
  <lv-flex-box class="lv-sub-fieldset-conversion" *ngIf="view.isVariableConversionSelected && !view.isPeps && !view.isPercs">
    <lv-fieldset-panel legend="VARIABLE CONVERSION">
      <lv-variable-conversion
      [model]="view.model.variableConversion"
      (didVariableConversionChange)="onModelChange()">
      </lv-variable-conversion>
    </lv-fieldset-panel>
  </lv-flex-box>
  <lv-flex-box *ngIf="view.isPeps">
    <lv-peps-conversion
      [model]="view.model.pepsConversion"
      [conversionCustomData] = "view.conversionCustomData"
      (didPepsConversionChange)="onModelChange()">
    </lv-peps-conversion>
  </lv-flex-box>
  <lv-flex-box *ngIf="view.isPercs">
    <lv-percs-conversion
      [model]="view.model.percsConversion"
      (didPercsConversionChange)="onModelChange()">
    </lv-percs-conversion>
  </lv-flex-box>
  <button kendoButton
    class="conversion-history-button"
    *ngIf="view.shouldShowConversionHistoryButton"
    [primary]="true"
    (click)="view.toggleShowConversionRatioAndCarryForwardHistory()">
    Display Conversion History
  </button>

  <lv-flex-box class="conversion-ratio-and-carry-forward-history-section" *ngIf="view.showConversionRatioAndCarryForwardHistory">
    <ng-container *ngIf="view.conversionRatioHistoryHasData">
      <lv-flex-box direction="row" class="space-top">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="crHistoryScheduleSourceTooltip"
        filter=".lv-label"
        class="lv-label lv-label-field lv-label--title lv-label--conversion-ratio-history">CONVERSION RATIO HISTORY</div>
      </lv-flex-box>
      <lv-conversion-ratio-history
      [model]="view.model.conversionRatioHistory"
      [isPeps]="view.isPeps"
      >
      </lv-conversion-ratio-history>
    </ng-container>

    <ng-container *ngIf="view.carryForwardHistoryHasData">
      <lv-flex-box direction="row" class="space-top">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="cfHistoryScheduleSourceTooltip"
        filter=".lv-label"
        class="lv-label lv-label-field lv-label--title">CARRY FORWARD HISTORY</div>
      </lv-flex-box>
      <lv-carry-forward-history
      [model]="view.model.carryForwardHistory">
      </lv-carry-forward-history>
    </ng-container>
  </lv-flex-box>
</lv-flex-box>

<ng-template #cfHistoryScheduleSourceTooltip>
  <lv-tooltip dm="dM-2307"></lv-tooltip>
</ng-template>
<ng-template #crHistoryScheduleSourceTooltip>
  <lv-tooltip dm="dM-1420"></lv-tooltip>
</ng-template>
<ng-template #forfeitCouponOnOCSourceTooltip>
  <lv-tooltip dm="dM-892"></lv-tooltip>
</ng-template>
<ng-template #deltaNeutralCASourceTooltip>
  <lv-tooltip dm="dM-897"></lv-tooltip>
</ng-template>
<ng-template #keepAccruedSourceTooltip>
  <lv-tooltip dm="dM-890"></lv-tooltip>
</ng-template>
<ng-template #forfeitCouponOnCatMSourceTooltip>
  <lv-tooltip dm="dM-891"></lv-tooltip>
</ng-template>
<ng-template #exerciseScheduleSourceTooltip>
  <lv-tooltip dm="dM-1415"></lv-tooltip>
</ng-template>
<ng-template #conversionIntoNSSourceTooltip>
  <lv-tooltip dm="dM-893"></lv-tooltip>
</ng-template>
<ng-template #acquisitionSharesSettlementTooltip>
  <lv-tooltip dm="dM-847"></lv-tooltip>
</ng-template>
<ng-template #numberOfFixingDayTooltip>
  <lv-tooltip dm="dM-886"></lv-tooltip>
</ng-template>
<ng-template #conversionSettlementObservPeriodStartBeforeMatDateTooltip>
  <lv-tooltip dm="dM-887"></lv-tooltip>
</ng-template>
<ng-template #conversionSettlementObservPeriodStartBeforeIssuerCallTooltip>
  <lv-tooltip dm="dM-888"></lv-tooltip>
</ng-template>
<ng-template #conversionSettlementObservationLagTooltip>
  <lv-tooltip dm="dM-889"></lv-tooltip>
</ng-template>
<ng-template #conversionSettlementSourceTooltip>
  <lv-tooltip dm="dM-885"></lv-tooltip>
</ng-template>
<ng-template #mandatoryConversionSourceTooltip>
  <lv-tooltip dm="dM-403"></lv-tooltip>
</ng-template>
<ng-template #contingentConversionSourceTooltip>
  <lv-tooltip dm="dM-411"></lv-tooltip>
</ng-template>
<ng-template #variableConversionSourceTooltip>
  <lv-tooltip dm="dM-404"></lv-tooltip>
</ng-template>
<ng-template #resettableSourceTooltip>
  <lv-tooltip dm="dM-407"></lv-tooltip>
</ng-template>
<ng-template #conversionPriceCCYSourceTooltip>
  <lv-tooltip dm="dM-894"></lv-tooltip>
</ng-template>
<ng-template #rebateCCYSourceTooltip>
  <lv-tooltip dm="dM-895"></lv-tooltip>
</ng-template>
<ng-template #acquisitionNoticeGivenTooltip>
  <lv-tooltip dm="dM-1419"></lv-tooltip>
</ng-template>
<ng-template #acquisitionNoticeMaxDaysTooltip>
  <lv-tooltip dm="dM-1418"></lv-tooltip>
</ng-template>
<ng-template #acquisitionNoticeMinDaysTooltip>
  <lv-tooltip dm="dM-1417"></lv-tooltip>
</ng-template>
<ng-template #acquisitionOptionDateInputTypeTooltip>
  <lv-tooltip dm="dM-850"></lv-tooltip>
</ng-template>
<ng-template #acquisitionNoticeStartDateTooltip>
  <lv-tooltip dm="dM-848"></lv-tooltip>
</ng-template>
<ng-template #acquisitionNoticeEndDateTooltip>
  <lv-tooltip dm="dM-849"></lv-tooltip>
</ng-template>
<ng-template #vwapInputTypeTooltip>
  <lv-tooltip dm="dM-852"></lv-tooltip>
</ng-template>
<ng-template #acquisitionSettlementTypeTooltip>
  <lv-tooltip dm="dM-855"></lv-tooltip>
</ng-template>
<ng-template #acquisitionASVAdjustmentPercTooltip>
  <lv-tooltip dm="dM-856"></lv-tooltip>
</ng-template>
<ng-template #conversionSettlementMaximumAcquisitionSharesTooltip>
  <lv-tooltip dm="dM-854"></lv-tooltip>
</ng-template>
<ng-template #vWAPPeriodObservationLagTooltip>
  <lv-tooltip dm="dM-1416"></lv-tooltip>
</ng-template>
<ng-template #conversionSettlementAcquisitionOptionDateTooltip>
  <lv-tooltip dm="dM-851"></lv-tooltip>
</ng-template>
<ng-template #conversionSettlementVWAPPeriodStartDateTooltip>
  <lv-tooltip dm="dM-853"></lv-tooltip>
</ng-template>
<ng-template #conversionScheduleTooltip>
  <lv-tooltip dm="dM-898"></lv-tooltip>
</ng-template>
<ng-template #conversionExerciseScheduleTooltip>
  <lv-tooltip dm="dM-899"></lv-tooltip>
</ng-template>
