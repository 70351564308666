import { saveAs } from 'file-saver';

import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2, EventEmitter, Output } from '@angular/core';
import { mask, modal } from '../lv-modal.animations';
import { LvModalComponent } from '../lv-modal';

import { LocalStorage } from '../../../../util/local-storage';

@Component({
  selector: 'lv-error-modal',
  templateUrl: './lv-error-modal.component.html',
  animations: [
    mask,
    modal
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvErrorModalComponent extends LvModalComponent {

  /**
   * Toggle span text based on user action
   */
  get spanText(): string {
    return `Show ${this.showStackTrace ? 'less' : 'more'}`;
  }

  /**
   * Retrun flag that indicates if error happened more than once
   */
  get isErrorHappenedMoreThanOneTime(): boolean {
    return this.closedModalCounter > 1;
  }

  unexpectedError: Error;
  showStackTrace: boolean;
  closedModalCounter: number;

  @Output() closedModal: EventEmitter<boolean>;
  @Output() destroyWorkspace: EventEmitter<boolean>;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    renderer: Renderer2
  ) {
    super(changeDetectorRef, renderer);
    this.closedModal = new EventEmitter<boolean>();
    this.destroyWorkspace = new EventEmitter<boolean>();
    this.unexpectedError = new Error();
    this.showStackTrace = true;
    this.closedModalCounter = 0;
  }

  // override from LvModalComponent
  public open(unexpectedError?: Error): void {
    if (!this.isOpened) {
      this.unexpectedError = unexpectedError || new Error();
      this.isOpened = true;

      this.disableScrolling();
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Closes error modal and emits output to parent so error counter can be incremented
   */
  onClose() {
    this.close();
    this.closedModal.emit(true);
  }

  /**
   * Closes error modal and emits output to parent so it can emit event to destroy active workspace
   */
  onDestroyWorkspace() {
    this.close();
    this.destroyWorkspace.emit(true);
  }

  /**
   * Shows/hide error stack trace
   */
  toggleShowStackTrace(): void {
    this.showStackTrace = !this.showStackTrace;
    this.changeDetectorRef.detectChanges();
  }

  onDownloadErrorReport() {
    try {
      const report = {
        user: LocalStorage.getJwtUser(),
        error: {
          name: this.unexpectedError.name,
          message: this.unexpectedError.message,
          stack: this.unexpectedError.stack
        }
      };

      const reportAsString = JSON.stringify(report);

      const blob = new Blob([reportAsString], { type: 'application/json;charset=utf-8' });
      saveAs(blob, `leversys_online_error_report.json`);
    }
    catch (error) {
      console.log(error);
    }
  }
}
