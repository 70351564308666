import { Injectable } from '@angular/core';
import { DetachedRouteHandle } from '@angular/router';

import { Subject, Observable } from 'rxjs';

const workspaceContentRouteId = 'workspace-content';
const workspaceContentRouteRegex = new RegExp(`\\b${workspaceContentRouteId}\\b`, 'ig');

@Injectable()
export class RouteCacheService {

  private _cache: Map<string, DetachedRouteHandle>;
  private _removedFromCache: Set<string>;

  private _workspaceReatached: Subject<string>;

  get workspaceReatached(): Observable<string> {
    return this._workspaceReatached.asObservable();
  }

  constructor() {
    this._cache = new Map<string, DetachedRouteHandle>();
    this._removedFromCache = new Set<string>();

    this._workspaceReatached = new Subject<string>();
  }

  public set(routeId: string, handle: DetachedRouteHandle): void {
    if (!handle) {
      return;
    }

    if (!this._removedFromCache.has(routeId)) {
      this._cache.set(routeId, handle);
    }
    else {
      this.destroyHandle(handle);
    }
  }

  public get(routeId: string): DetachedRouteHandle | null {
    if (!this.exists(routeId)) {
      return null;
    }

    return this._cache.get(routeId);
  }

  public remove(routeId: string): void {
    this._removedFromCache.add(routeId);

    const handle = this._cache.get(routeId);
    this.destroyHandle(handle);
    this._cache.delete(routeId);
  }

  public exists(routeId: string) {
    return this._cache.has(routeId);
  }

  public invalidate(): void {
    this._cache.forEach(handle => this.destroyHandle(handle));
    this._cache.clear();
  }

  // Workspace
  public removeWorkspace(workspaceId: string): void {
    this.remove(this.getWorkspaceCacheKey(workspaceId));
  }

  public getWorkspaceCacheKey(workspaceId: string): string {
    return `${workspaceContentRouteId}:${workspaceId}`;
  }

  public isWorkspaceRouteId(routeId: string): boolean {
    return routeId === workspaceContentRouteId;
  }

  public publishWorkspaceReatached(routeId: string) {
    if (routeId.match(workspaceContentRouteRegex)) {
      const split = routeId.split(':');
      this._workspaceReatached.next(split[1]);
    }
  }

  private destroyHandle(handle: DetachedRouteHandle) {
    if (handle) {
      (handle as any).componentRef.destroy();
    }
  }
}
