import { Directive, Input, HostListener } from '@angular/core';

export interface IGridCellContextMenu {
  onEditCellContextMenuClick: (event: MouseEvent) => void;
}

@Directive({
  selector: '[gridCellContextMenu]'
})
export class GridCellCotextMenuDirective {

  // tslint:disable-next-line:no-input-rename
  @Input('gridCellContextMenu') grid: IGridCellContextMenu;

  constructor() {}

  @HostListener('contextmenu', ['$event'])
  public onContextMenu(event: MouseEvent) {

    event.preventDefault();
    this.grid.onEditCellContextMenuClick(event);
  }
}
