import { ErrorHandler, Injector, Inject, Injectable } from '@angular/core';
import { LvErrorService } from './lv-error.service';
import { LvLoggerService } from '../lv-logger/lv-logger-service';


@Injectable()
export class LvUnexpectedErrorHandler implements ErrorHandler {

  constructor(
    @Inject(Injector) private injector: Injector
  ) {}

  handleError(error: Error | string) {
    /*
    * Here we handle all unexpected errors and uncaught promise rejections
    * and log stack trace on the server
    */
    const lvErrorService: LvErrorService = this.injector.get(LvErrorService);
    const lvLoggerService: LvLoggerService = this.injector.get(LvLoggerService);

    if (typeof(error) === 'string') {
      error = new Error(error);
      error.name = 'Unexpected Error';
    }

    lvErrorService.unexpectedError.next(error);
    lvLoggerService.logError(error);

    console.log(error);
  }
}
