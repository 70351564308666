import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
         Input, Output, EventEmitter, OnChanges, ChangeDetectorRef, OnDestroy, ElementRef, Optional, SimpleChanges } from '@angular/core';
import { v4 } from 'uuid';
import { Subscription } from 'rxjs';
import { LvLookupEnum, LvMath } from '@lv-core-ui/util';
import { ConvertibleSubType, ExchangeableType, StockReferenceType } from '@lv-analytics/models';
import { IssueAndRedemption, OtherData, PriceTalk, StockPriceReference, ConvertibleBondSubType, InstrumentStatusDescription,
         EquityData, ExhangeableParameters, ConvertibleBondSubTypeDescription, Currency, InstrumentStatusGeneralDescription,
         RecoveryUponDefaultDescription, ExchangeableTypeDescription, IsQuotedClean, SourceType, PricingType,
         ConvertibleBondTermsSectionEvent, PricingSectionEvent, SetupStatus, InstrumentStatus,
         ConvertibleBondTermsEvent,
         EquityTypeDescription,
         FxRates,
         FxRateTypes } from '@lv-convertible-bond/models';
import { LvConvertibleBondTermsPresenter, PricingSectionCommand } from '../..';
import { CommonDataService } from '@lv-common/common-data';
import { LvExcelService } from '@lv-excel/services';
import { LvTermsGeneralIssueInformationView } from './lv-terms-general-issue-information.view';
import { StockReferenceSignalStore } from '../../stores/lv-stock-reference.store';

@Component({
  selector: 'lv-terms-general-issue-information',
  templateUrl: './lv-terms-general-issue-information.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvTermsGeneralIssueInformationComponent implements OnInit, OnChanges, OnDestroy {
  @Output() didSetGeneralInformation: EventEmitter<IssueAndRedemption>;
  @Output() didNominalChanged: EventEmitter<IssueAndRedemption>;

  @Input() model: IssueAndRedemption;
  @Input() other: OtherData;
  @Input() priceTalk: PriceTalk;
  @Input() stockReference: StockPriceReference;
  @Input() isLvsConvertible: boolean;
  @Input() stockReferenceSignalStore: StockReferenceSignalStore;

  view: LvTermsGeneralIssueInformationView;

  private _modelSubscription: Subscription;
  private _isExcelNewIssueSet: boolean;

  get isPledgedShares(): boolean {
    return this.model.exhangeableParameters.exhangeableType === ExchangeableType.PledgedShares;
  }

  get isRedemptionHide(): boolean {
    return this.model.subType === ConvertibleBondSubType.PEPS
      || this.status === InstrumentStatusDescription.NewIssue || this.model.isPerpetual;
  }

  get isCrossFx(): boolean {
    return this.model && this.model.underlyingEquity.currencyCode && (this.model.currencyCode !== this.model.underlyingEquity.currencyCode);
  }

  get isPeps(): boolean {
    return this.model.subType === ConvertibleBondSubType.PEPS;
  }

  get isPercs(): boolean {
    return this.model.subType === ConvertibleBondSubType.PERCS;
  }

  get isPerpetual(): boolean {
    return this.model.isPerpetual;
  }

  get isDeatachable(): boolean {
    return this.model.subType === ConvertibleBondSubType.ConvertibleWithDetachableWarrant;
  }

  get underlyingType(): EquityTypeDescription {
    return EquityTypeDescription[this.model.underlyingEquity.type];
  }

  get prevailingRate(): FxRates {
    return FxRates[this.other.fxRate];
  }

  get fxRateTypes(): FxRateTypes {
    return FxRateTypes[this.other.fxRateType];
  }

  get dateFormated(): string {
    if (!(this.model && this.model.maturityDate)) {
      return '';
    }

    return new Date(this.model.maturityDate).toLocaleDateString('en-GB');
  }

  status: InstrumentStatusDescription;
  instrumentSubTypeLookup: LvLookupEnum;
  currencyLookup: LvLookupEnum;
  setupStatusLookup: LvLookupEnum;
  recoveryUponDefaultLookup: LvLookupEnum;
  exchangeableTypeLookup: LvLookupEnum;
  isQuotedCleanLookup: LvLookupEnum;
  instrumentStatus: LvLookupEnum;

  maturityDatePerpetualCheckboxId: string;
  issueValueNominalCheckboxId: string;
  issueValueRedemptionCheckboxId: string;
  priceAsParCheckboxId: string;
  accruedUponDefaultCheckboxId: string;
  fxQuantoCheckboxId: string;
  undCcyLinkedCheckboxId: string;
  quotedValue: string;
  stockRefCheckBoxId: string;
  sinkableCheckboxId: string;
  premiumLabel: string;

  numberOfDecimalsPercentageAssumption = '3';
  numberFormatAssumption = '#,###.###';
  decimalsFour = '4';
  formatFour = '#,###.####';
  decimalsTwo = '2';
  formatTwo = '#,###.##';

  isNewIssue: boolean;
  isStockRef: boolean;

  premiumMid: number;
  couponMid: number;
  issuePriceMid: number;
  redemptionValueMid: number;
  issueYieldMid: number;
  higherStrikePremiumMid: number;
  country: string;
  riskCountry: string;

  previousNominalValue: number;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvConvertibleBondTermsPresenter,
    private _commonService: CommonDataService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    if (!this.model) {
      this.model = new IssueAndRedemption();
      this.model.underlyingEquity = new EquityData();
      this.model.exhangeableParameters =  new ExhangeableParameters();
      this.status = InstrumentStatusDescription.NewIssue;
      this.isNewIssue = true;
      this.isStockRef = false;
      this._isExcelNewIssueSet = false;
      this.view = new LvTermsGeneralIssueInformationView();
    }

    this.didSetGeneralInformation = new EventEmitter<IssueAndRedemption>();
    this.didNominalChanged = new EventEmitter<IssueAndRedemption>();

    this.instrumentSubTypeLookup = new LvLookupEnum(ConvertibleBondSubTypeDescription);
    this.currencyLookup = new LvLookupEnum(Currency);
    this.setupStatusLookup = new LvLookupEnum(InstrumentStatusGeneralDescription);
    this.recoveryUponDefaultLookup = new LvLookupEnum(RecoveryUponDefaultDescription);
    this.exchangeableTypeLookup = new LvLookupEnum(ExchangeableTypeDescription);
    this.isQuotedCleanLookup = new LvLookupEnum(IsQuotedClean);
    this.instrumentStatus = new LvLookupEnum(InstrumentStatus);

    this.maturityDatePerpetualCheckboxId = v4();
    this.issueValueNominalCheckboxId = v4();
    this.issueValueRedemptionCheckboxId = v4();
    this.priceAsParCheckboxId = v4();
    this.accruedUponDefaultCheckboxId = v4();
    this.fxQuantoCheckboxId = v4();
    this.undCcyLinkedCheckboxId = v4();
    this.stockRefCheckBoxId = v4();
    this.sinkableCheckboxId = v4();
    this.country = '';
    this.riskCountry = '';
    this.isLvsConvertible = false;
  }

  ngOnInit() {
    this.init();
    this._modelSubscription = this._presenter.onCommandExecuted.subscribe(command => {
      if (command instanceof PricingSectionCommand && command.sourceType === SourceType.General) {
        switch (command.type) {
          case PricingType.Worst: {
            this.priceTalk.issueYieldWorst = command.calculateValue;
            this.issueYieldBestOrWorstChange();
            break;
          }
          case PricingType.Best: {
            this.priceTalk.issueYieldBest = command.calculateValue;
            this.issueYieldBestOrWorstChange();
            break;
          }
          case PricingType.WorstRedemption: {
            this.priceTalk.redemptionValueWorst = command.calculateValue;
            this.redemptionBestOrWorstChange();
            break;
          }
          case PricingType.BestRedemption: {
            this.priceTalk.redemptionValueBest = command.calculateValue;
            this.redemptionBestOrWorstChange();
            break;
          }
        }

        this.onPricingChange();
      }
      this._changeDetectorRef.detectChanges();
    });
  }

  ngOnChanges() {
    this.init();
    this.premiumBestOrWorstChange();
    this.couponBestOrWorstChange();
    this.redemptionBestOrWorstChange();
    this.issueYieldBestOrWorstChange();
    this.issuePriceBestOrWorstChange();

    
    if (!!this._excelSvc?.isInitialized() && this.isNewIssue && !this._isExcelNewIssueSet) {
      if (this._presenter.isModelLoaded()) {
        //this.calculatePriceTalkFieldsFromExcel();
        this._isExcelNewIssueSet = true;
      }
      this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.PriceTalkUpdated);
    }
  }

  quotedValueChange(value: IsQuotedClean) {
    this.model.isQuotedClean = value === IsQuotedClean.Clean;
    this.doModelChange();
  }

  onMaturityDateChange() {
    if (!!this.model.maturityDate) {
      this.onDateChange();
    }
  }

  onFirstSettlementDateChange() {
    if (!!this.model.firstSettlementDate) {
      this.onDateChange();
    }
  }

  onDateChange() {
    this._presenter.updateModel({
      data: this.model,
      eventId: ConvertibleBondTermsSectionEvent.TermsGeneralDateEvent
    });

    this._presenter.publishPricingSectionUpdated({
      eventId: PricingSectionEvent.IssueYield,
      data: PricingType.Assumed,
      source: SourceType.General
    });

    this._presenter.publishPricingSectionUpdated({
      eventId: PricingSectionEvent.IssueYield,
      data: PricingType.Best,
      source: SourceType.General
    });

    this._presenter.publishPricingSectionUpdated({
      eventId: PricingSectionEvent.IssueYield,
      data: PricingType.Worst,
      source: SourceType.General
    });
  }

  
  onDateBlur() {
    this._presenter.publishPricingSectionUpdated({
      eventId: PricingSectionEvent.DatesUpdated,
      data: null,
      source: SourceType.General
    });
  }

  onNominalChanged() {
    if (this.previousNominalValue !== this.model.nominalValue) {      
      this.previousNominalValue = this.model.nominalValue;
      this.didNominalChanged.next(this.model);
    }
  }

  doModelChange() {
    this.didSetGeneralInformation.next(this.model);
  }

  onIssueValueChange() {
    if (this.model.setupStatus === SetupStatus.NewIssue && this.priceTalk) {
      this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.PriceTalkUpdated);
    }
    this.doModelChange();
  }

  onCouponBestChange() {
    this.couponBestOrWorstChange();
    this.onPriceTalkChange(false);
    this.updateIssueYield(PricingType.Best);
  }

  onCouponWorstChange() {
    this.couponBestOrWorstChange();
    this.onPriceTalkChange(false);
    this.updateIssueYield(PricingType.Worst);
  }

  onIssuePriceBestChange() {
    this.issuePriceBestOrWorstChange();
    this.onPriceTalkChange(false);
    this.updateIssueYield(PricingType.Best);
  }

  onIssuePriceWorstChange() {
    this.issuePriceBestOrWorstChange();
    this.onPriceTalkChange(false);
    this.updateIssueYield(PricingType.Worst);
  }

  onRedemptionBestChange() {
    this.redemptionBestOrWorstChange();
    this.onPriceTalkChange(false);
    this.updateIssueYield(PricingType.Best);
  }

  onRedemptionWorstChange() {
    this.redemptionBestOrWorstChange();
    this.onPriceTalkChange(false);
    this.updateIssueYield(PricingType.Worst);
  }

  onIssueYieldBestChange() {
    this.issueYieldBestOrWorstChange();
    this.onPriceTalkChange(false);
    this.updateRedemption(PricingType.BestRedemption);
  }

  onIssueYieldWorstChange() {
    this.issueYieldBestOrWorstChange();
    this.onPriceTalkChange(false);
    this.updateRedemption(PricingType.WorstRedemption);
  }

  updateIssueYield(type: PricingType) {
    this._presenter.publishPricingSectionUpdated({
      eventId: PricingSectionEvent.IssueYield,
      data: type,
      source: SourceType.General
    });
  }

  updateRedemption(type: PricingType) {
    this._presenter.publishPricingSectionUpdated({
      eventId: PricingSectionEvent.Redemption,
      data: type,
      source: SourceType.General
    });
  }

  doPremiumBestOrWorstChange() {
    this.premiumBestOrWorstChange();
    this.onPricingChange();
  }

  doRedemptionBestOrWorstChange() {
    this.redemptionBestOrWorstChange();
    this.onPricingChange();
  }

  doIssueYieldBestOrWorstChange() {
    this.issueYieldBestOrWorstChange();
    this.onPricingChange();
  }

  doSetFxRate(fxRate: number) {
    this.model.fixedFXRate = fxRate;
    this._presenter.updateFixedFx(fxRate);

    if (this.model.setupStatus === SetupStatus.NewIssue) {
       this.onStockPriceReferenceChange();
    } else{
      this.doModelChange();
    }
  }

  /**
   * On premium best or worst change update values on price talk.
   * Whether instrument is peps or not we know what is changed so we can update
   * premium and higher strike premium values.  
   */
  premiumBestOrWorstChange() {
    if (this.model && this.model.subType !== ConvertibleBondSubType.PEPS) {
      this.priceTalk.higherStrikePremiumBest = this.priceTalk.premiumBest;
      this.priceTalk.higherStrikePremiumWorst = this.priceTalk.premiumWorst;

      if (this.model && LvMath.isNumber(this.priceTalk.premiumBest) && LvMath.isNumber(this.priceTalk.premiumWorst)) {
        this.premiumMid = this.bestOrWorstChange(this.priceTalk.premiumBest, this.priceTalk.premiumWorst);
        this.higherStrikePremiumMid = this.bestOrWorstChange(this.priceTalk.higherStrikePremiumBest, this.priceTalk.higherStrikePremiumWorst);
      } else {
        this.premiumMid = null;
      }
    }
    else {
      this.priceTalk.premiumBest = this.priceTalk.higherStrikePremiumBest;
      this.priceTalk.premiumWorst = this.priceTalk.higherStrikePremiumWorst;

      if (this.model && LvMath.isNumber(this.priceTalk.higherStrikePremiumBest) && LvMath.isNumber(this.priceTalk.higherStrikePremiumWorst)) {
        this.higherStrikePremiumMid = this.bestOrWorstChange(this.priceTalk.higherStrikePremiumBest, this.priceTalk.higherStrikePremiumWorst);
        this.premiumMid = this.bestOrWorstChange(this.priceTalk.premiumBest, this.priceTalk.premiumWorst);
      } else {
        this.higherStrikePremiumMid = null;
      }
    }
  }

  couponBestOrWorstChange() {
    if (this.model && LvMath.isNumber(this.priceTalk.couponBest) && LvMath.isNumber(this.priceTalk.couponWorst)) {
      this.couponMid = this.bestOrWorstChange(this.priceTalk.couponBest, this.priceTalk.couponWorst);
    } else {
      this.couponMid = null;
    }
  }

  issuePriceBestOrWorstChange() {
    if (this.model && LvMath.isNumber(this.priceTalk.issuePriceBest) && LvMath.isNumber(this.priceTalk.issuePriceWorst)) {
      this.issuePriceMid = this.bestOrWorstChange(this.priceTalk.issuePriceBest, this.priceTalk.issuePriceWorst);
    } else {
      this.issuePriceMid = null;
    }
  }

  redemptionBestOrWorstChange() {
    if (this.model && LvMath.isNumber(this.priceTalk.redemptionValueBest) && LvMath.isNumber(this.priceTalk.redemptionValueWorst)) {
      this.redemptionValueMid = this.bestOrWorstChange(this.priceTalk.redemptionValueBest, this.priceTalk.redemptionValueWorst);
    } else {
      this.redemptionValueMid = null;
    }
  }

  issueYieldBestOrWorstChange() {
    if (this.model && LvMath.isNumber(this.priceTalk.issueYieldBest) && LvMath.isNumber(this.priceTalk.issueYieldWorst)) {
      this.issueYieldMid = this.bestOrWorstChange(this.priceTalk.issueYieldBest, this.priceTalk.issueYieldWorst);
    } else {
      this.issueYieldMid = null;
    }
  }

  bestOrWorstChange(best: number, worst: number): number {
    return (best + worst) / 2;
  }

  /**
   * On stock reference change.
   * @param value New stock reference value.
   */
  onStockRefChange(value: number): void {
    this.stockReferenceSignalStore.setStockRef(value)
    this.onStockPriceReferenceChange();
  }

  /**
   * On stock reference equity change.
   * @param value New stock reference equity value.
   */
  onStockRefEquityChange(value: number): void {
    this.stockReferenceSignalStore.setStockRefEquity(value)
    this.onStockPriceReferenceChange();
  }

  onStockPriceReferenceChange() {
    if (!this.stockReference) {
      this.stockReference = {} as StockPriceReference;
    }

    if (this.isStockRef) {
      this.stockReference.referenceType = StockReferenceType.Fixed;
      const isCrossFx = this._presenter.getModel()?.fullTerms?.issueAndRedemption?.currencyCode !== this._presenter.getModel()?.fullTerms?.issueAndRedemption?.underlyingEquity?.currencyCode;
      this.stockReference.fixedFXRef = this.stockReferenceSignalStore.fx();
      this.stockReference.fixedStockRef = this.stockReferenceSignalStore.fx() === 0 || !isCrossFx ? this.stockReferenceSignalStore.stockRef() : this.stockReferenceSignalStore.stockRefEquity();
    }
    else {
      this.stockReference.referenceType = StockReferenceType.NotFixed;
    }

    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.StockPriceReference,
      data: this.stockReference
    }, false);

    this.onPricingChange();
  }

  doStatusChange() {
    this.isNewIssue = false;

    if (this.status === InstrumentStatusDescription.NewIssue) {
      this.model.setupStatus = SetupStatus.NewIssue;
      this.other.instrumentStatus = InstrumentStatus.Active;
      this.isNewIssue = true;

      this._presenter.updateModel({
        eventId: ConvertibleBondTermsSectionEvent.StockPriceReference,
        data: this.stockReference
      }, false);

      this.onPricingChange();
      
      if (this._excelSvc?.isInitialized() && this.priceTalk) {
        this._presenter.updateModel({
          eventId: ConvertibleBondTermsSectionEvent.SetupStatusChanged,
          data: this.model
        }, false);
      }
    }
    if (this.status === InstrumentStatusDescription.NewIssueFixed) {
      this.model.setupStatus = SetupStatus.PartiallyCompleted;
      this.other.instrumentStatus = InstrumentStatus.Active;
    }
    if (this.status === InstrumentStatusDescription.Active) {
      this.model.setupStatus = SetupStatus.FullyCompleted;
      this.other.instrumentStatus = InstrumentStatus.Active;
    }
    if (this.status === InstrumentStatusDescription.Called) {
      this.model.setupStatus = SetupStatus.FullyCompleted;
      this.other.instrumentStatus = InstrumentStatus.Called;
    }
    if (this.status === InstrumentStatusDescription.Defaulted) {
      this.model.setupStatus = SetupStatus.FullyCompleted;
      this.other.instrumentStatus = InstrumentStatus.Defaulted;
    }
    if (this.status === InstrumentStatusDescription.Redeemed) {
      this.model.setupStatus = SetupStatus.FullyCompleted;
      this.other.instrumentStatus = InstrumentStatus.Inactive;
    }
    if (this.status === InstrumentStatusDescription.Matured) {
      this.model.setupStatus = SetupStatus.FullyCompleted;
      this.other.instrumentStatus = InstrumentStatus.Matured;
    }

    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.OtherGeneralEvent,
      data: this.other
    }, false);

    this.setPremiumLabelText();
    this.doModelChange();

    this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.InstrumentStatusUpdated);
  }

  private init() {
    this.quotedValue = this.model.isQuotedClean ? IsQuotedClean.Clean : IsQuotedClean.Dirty;

    this.isNewIssue = false;
    this.previousNominalValue = this.model.nominalValue;
    this.isStockRef = false;

    if (this.model.setupStatus === SetupStatus.NewIssue) {
      this.status = InstrumentStatusDescription.NewIssue;
      this.isNewIssue = true;

      let forceStoreInitializeFromExcel = false; 
      
      if (this._excelSvc?.isInitialized()) {
        forceStoreInitializeFromExcel = !!this._excelSvc?.getFieldValue('FIXED_STK_REF_CBCCY')
        || !!this._excelSvc?.getFieldValue('FIXED_STK_REF_EQCCY') || !!this._excelSvc?.getFieldValue('FIXED_FX');

        if (!!this._excelSvc?.getFieldValue('FIXED_STK_REF_CBCCY') || !!this._excelSvc?.getFieldValue('FIXED_STK_REF_EQCCY')) {
          this.stockReference.referenceType = StockReferenceType.Fixed;
        }
      }

      if ((this.stockReference && this.stockReference.fixedStockRef >= 0) || forceStoreInitializeFromExcel) {
        this.isStockRef = this.stockReference?.referenceType === StockReferenceType.Fixed;
      }
      else if (this.stockReference?.referenceType === StockReferenceType.Fixed && this.stockReference.fixedStockRef === undefined) {
        this.isStockRef = this.stockReference?.referenceType === StockReferenceType.Fixed;
      }

    } else if (this.model.setupStatus === SetupStatus.PartiallyCompleted) {
      this.status = InstrumentStatusDescription.NewIssueFixed;
    } else {
      if (this.other.instrumentStatus === InstrumentStatus.Called) {
        this.status = InstrumentStatusDescription.Called;
      }
      if (this.other.instrumentStatus === InstrumentStatus.Defaulted) {
        this.status = InstrumentStatusDescription.Defaulted;
      }
      if (this.other.instrumentStatus === InstrumentStatus.Inactive) {
        this.status = InstrumentStatusDescription.Redeemed;
      }
      if (this.other.instrumentStatus === InstrumentStatus.Matured) {
        this.status = InstrumentStatusDescription.Matured;
      }
      if (this.other.instrumentStatus === InstrumentStatus.Active) {
        this.status = InstrumentStatusDescription.Active;
      }
    }

    if (this.model?.subType === ConvertibleBondSubType.ConvertibleWithDetachableWarrant) {
      this.model.percCoveredWarrants = this.model.percCoveredWarrants || 100;
    }

    this.setRedemptionValuesAndIssueYield();
    this.setPremiumLabelText();

    this.setupStatusLookup.setFilterFn(item => {
      if (item.id === InstrumentStatusGeneralDescription.Redeemed || item.id === InstrumentStatusGeneralDescription.Called ||
          item.id === InstrumentStatusGeneralDescription.Matured || item.id === InstrumentStatusGeneralDescription.Defaulted) {
        return false;
      }
      return true;
    });
    this.getCountry();
  }

  onConvertibleTypeChange() {
    this.doModelChange();

    this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.InstrumentTypeUpdated);

    this.setRedemptionValuesAndIssueYieldDefaultValue();
  }

  onCCYChange() {
    if (this.model.currencyCode && this.model.underlyingEquity.currencyCode &&
        this.model.currencyCode === this.model.underlyingEquity.currencyCode) {
      this.model.fixedFXRate = 1;
    }

    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.TermsGeneralEvent,
      data: this.model
    }, false);
    this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.CCYUpdated);
  }

  onPricingChange() {
    this.onPriceTalkChange(false);

    this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.PriceTalkUpdated);
  }

  onPriceTalkChange(publishExternalEvent: boolean = true) {
    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.PriceTalkEvent,
      data: this.priceTalk
    }, publishExternalEvent);
  }

  onPerpetualChange() {
    this.doModelChange();
    this._presenter.setDividendProtectionSchedule();
    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.TermsGeneralEvent,
      data: this.model
    }, false);
    this._changeDetectorRef.detectChanges();
  }

  onPriceAsParChange() {
    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.TermsGeneralEvent,
      data: this.model
    }, false);
    this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.PriceAsParUpdated);
  }

  onExchangeableTypeChange() {
    this.doModelChange();
    this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.ExchangeableTypeUpdated);
  }

  setPremiumLabelText() {
    this.premiumLabel = this.isPeps ? 'Higher Strike Premium' : 'Premium';
  }

  setRedemptionValuesAndIssueYield() {
    this.redemptionBestOrWorstChange();
    this.issuePriceBestOrWorstChange();
  }

  setRedemptionValuesAndIssueYieldDefaultValue() {
    const defaultValue = this.model.subType === ConvertibleBondSubType.PEPS
      || this.model.subType === ConvertibleBondSubType.PERCS ? 0 : 100;

    this.priceTalk.redemptionValueBest = defaultValue;
    this.priceTalk.redemptionValueWorst = defaultValue;
    this.redemptionBestOrWorstChange();
    this.issuePriceBestOrWorstChange();

    this.onPriceTalkChange();
  }

  ngOnDestroy() {
    this._modelSubscription.unsubscribe();
  }

  getGeneralIssueTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

  getCountry() {
    if (this.model && this.model.countryCode) {
      const country = this._commonService.getCountry(this.model.countryCode);
      this.country = country.countryName;
    }

    if (this.model && this.model.riskCountryCode) {
      const riskCountry = this._commonService.getCountry(this.model.riskCountryCode);
      this.riskCountry = riskCountry.countryName;
    }
  }  
}
