<lv-flex-box class="conversion-settings-column conversion-settings-column--left">
  <lv-flex-box 
    data-cy="DM-1617"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="deltaNeutralCASourceTooltipTemplate"
      filter="span">
      <span> Delta Neutral Conversion Amount </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        [disabled]="!overrideDefaults"
        [id]="fxAdjustedConversionAmountCheckboxId"
        name="terms-general-conversion-delta-neutral-amount"
        [(ngModel)]="settings.settings.fxAdjustedConversionAmount">
      <label class="k-checkbox-label" lvId="deltaNeutralConversionAmount" [for]="fxAdjustedConversionAmountCheckboxId"></label>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1618"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="keepAccruedSourceTooltipTemplate"
      filter="span">
      <span> Keep Accrued </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        [disabled]="!overrideDefaults"
        [id]="keepAccruedCheckboxId"
        name="terms-general-conversion-keep-accrued"
        [(ngModel)]="settings.settings.keepAccrued">
      <label class="k-checkbox-label" lvId="keepAccrued" [for]="keepAccruedCheckboxId"></label>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1619"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="forfeitCouponOnCatMSourceTooltipTemplate"
      filter="span">
      <span> Forfeit Coupon on Conversion at Maturity </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        [disabled]="!overrideDefaults"
        [id]="forfeitCouponOnConversionAtMaturityCheckboxId"
        name="terms-general-conversion-forfeit-coupon-maturity"
        [(ngModel)]="settings.settings.forfeitCouponOnConversionAtMaturity">
      <label
        class="k-checkbox-label"
        lvId="forfeitCouponOnConversionAtMaturity"
        [for]="forfeitCouponOnConversionAtMaturityCheckboxId"></label>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1620"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="forfeitCouponOnOCSourceTooltipTemplate"
      filter="span">
      <span> Forfeit Coupon on Optional Conversion </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        [disabled]="!overrideDefaults"
        [id]="forfeitCouponOnOptionalConversionCheckboxId"
        name="terms-general-conversion-forfeit-coupon-optional"
        [(ngModel)]="settings.settings.forfeitCouponOnOptionalConversion">
      <label class="k-checkbox-label" [for]="forfeitCouponOnOptionalConversionCheckboxId"></label>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1621"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionIntoNSSourceTooltipTemplate"
      filter="span">
      <span> Conversion into New Shares </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        [disabled]="!overrideDefaults"
        [id]="conversionIntoNewSharesCheckboxId"
        name="terms-general-conversion-into-new-shares"
        [(ngModel)]="settings.settings.conversionIntoNewShares">
      <label class="k-checkbox-label" lvId="conversionIntoNewShares" [for]="conversionIntoNewSharesCheckboxId"></label>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1622"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="acquisitionSharesSettlementTooltipTemplate"
      filter="span">
      <span> Acquisition Shares Settlement </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        [disabled]="!overrideDefaults"
        [id]="acquisitionSharesSettlementId"
        name="terms-general-conversion-into-new-shares"
        [(ngModel)]="settings.settings.useAcquisitionSharesSettlement">
      <label class="k-checkbox-label" lvId="acquisitionSharesSettlement" [for]="acquisitionSharesSettlementId"></label>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1623"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionSettlementSourceTooltipTemplate"
      filter="span">
      <span> Conversion Settlement </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="conversion-settlement"
        [data]="conversionSettlementLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        valuePrimitive="true"
        [popupSettings]="{ width: 151 }"
        [(ngModel)]="settings.settings.conversionSettlement">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1624"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="numberOfFixingDayTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'numberOfFixingDayTooltip'">
        Number of Fixing Days
      </span>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="number-of-fixing-days"
        [format]="formatZero"
        [disabled]="!overrideDefaults"
        [decimals]="decimalsZero"
        [(ngModel)]="settings.settings.conversionSettlementNumberFixingDays">
      </lv-numeric-text-box>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1625"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionSettlementObservPeriodStartBeforeMatDateTooltipTemplate"
      filter="span">
      <span> Observation Period Start (Maturity Date) </span>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="observation-period-start-maturity-date"
        [format]="formatZero"
        [disabled]="!overrideDefaults"
        [decimals]="decimalsZero"
        [(ngModel)]="settings.settings.conversionSettlementObservPeriodStartBeforeMatDate"
        lvId="observPeriodStartBeforeMatDate">
      </lv-numeric-text-box>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1626"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionSettlementObservPeriodStartBeforeIssuerCallTooltipTemplate"
      filter="span">
      <span> Observation Period Start (Issuer Call) </span>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="observation-period-start-issuer-call"
        [format]="formatZero"
        [disabled]="!overrideDefaults"
        [decimals]="decimalsZero"
        [(ngModel)]="settings.settings.conversionSettlementObservPeriodStartBeforeIssuerCall"
        lvId="observPeriodStartBeforeIssuerCall">
      </lv-numeric-text-box>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1627"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionSettlementEarlyConversionObservationLagTooltipTemplate"
      filter="span">
      <span> Observation Period Lag </span>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="early-conversion-observation-lag"
        [format]="formatZero"
        [disabled]="!overrideDefaults"
        [decimals]="decimalsZero"
        [(ngModel)]="settings.settings.conversionSettlementEarlyConversionObservationLag"
        lvId="earlyConversionObservationLag">
      </lv-numeric-text-box>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1628"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="contingentConversionSourceTooltipTemplate"
      filter="span">
      <span> Contingent Conversion </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        [disabled]="!overrideDefaults"
        [id]="contigentConversionCheckboxId"
        name="terms-general-conversion-contingent-conversion"
        [(ngModel)]="settings.settings.isContigentConversion">
      <label lvId="contingentConversionCheckbox" class="k-checkbox-label" [for]="contigentConversionCheckboxId"></label>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1629"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="resettableSourceTooltipTemplate"
      filter="span">
      <span> Resettable </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        [id]="resetableCheckboxId"
        [disabled]="!overrideDefaults"
        name="terms-general-conversion-resetable"
        [(ngModel)]="settings.settings.isResetable">
      <label lvId="resettableConversionCheckbox"  class="k-checkbox-label" [for]="resetableCheckboxId"></label>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1630"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionPriceCCYSourceTooltipTemplate"
      filter="span">
      <span> Conversion Price CCY </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="conversion-settlement"
        [data]="conversionPriceCCYLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        valuePrimitive="true"
        [(ngModel)]="settings.settings.conversionPriceCCY">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1631"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="rebateCCYSourceTooltipTemplate"
      filter="span">
      <span> Rebate CCY </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="conversion-settlement"
        [data]="rebateCCYLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        valuePrimitive="true"
        [(ngModel)]="settings.settings.rebateCCY">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1632"
    direction="row">
    
    <div class="lv-label lv-label-field lv-label-short"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionStartDateSystemDefaultsTooltipTemplate"
      filter="span">
      <span> Conversion Start Date </span>
    </div>

    <div class="lv-input-field align-sufix">
      <lv-numeric-text-box
        placeholder="T + days"
        class="lv-input-field"
        [disabled]="!overrideDefaults"
        [(ngModel)]="settings.settings.conversionStartDate"
        [format]="formatZero"
        [decimals]="decimalsZero"
        name="conversion-start-date">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">From First Settlement Date</span>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1633"
    class="space-bottom" 
    direction="row">
    
    <div class="lv-label lv-label-field lv-label-short"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionEndDateSystemDefaultsTooltipTemplate"
      filter="span">
      <span> Conversion End Date </span>
    </div>

    <div class="lv-input-field align-sufix">
      <lv-numeric-text-box
        placeholder="T - days"
        class="lv-input-field"
        [disabled]="!overrideDefaults"
        [(ngModel)]="settings.settings.conversionEndDate"
        [format]="formatZero"
        [decimals]="decimalsZero"
        name="conversion-end-date">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">From Maturity Date</span>
    </div>
  </lv-flex-box>

  <div class="lv-label-field lv-label lv-label--title">PEPS</div>

  <lv-flex-box 
    data-cy="DM-1634"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="parallelPEPSSourceTooltipTemplate"
      filter="span">
      <span> Parallel PEPS </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        [attr.id]="paralelPepsId"
        [disabled]="!overrideDefaults"
        name="peps-parallel-peps"
        [(ngModel)]="settings.settings.pepsConversion.parallelPeps">
      <label class="k-checkbox-label"
        lvId="parallel-peps-lv-id"
        [attr.for]="paralelPepsId"></label>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1635"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionRebateCurrencySourceTooltipTemplate"
      filter="span">
      <span> Conversion Rebate CCY </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="peps-conversion-rebateCCY"
        [data]="conversionRebateCCYLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.pepsConversion.conversionRebateCurrency">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1636"
    direction="row">
    
    <div class="lv-label lv-label-field lv-label-short"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionStartDatePepsTooltipTemplate"
      filter="span">
      <span> Conversion Start Date </span>
    </div>

    <div class="lv-input-field align-sufix">
      <lv-numeric-text-box
        placeholder="T + days"
        class="lv-input-field"
        [(ngModel)]="settings.settings.pepsConversion.startDate"
        [format]="formatZero"
        [disabled]="!overrideDefaults"
        [decimals]="decimalsZero"
        name="peps-conversion-start-date">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">From First Settlement Date</span>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1637"
    direction="row">
    
    <div class="lv-label lv-label-field lv-label-short"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionEndDatePepsTooltipTemplate"
      filter="span">
      <span> Conversion End Date </span>
    </div>

    <div class="lv-input-field align-sufix">
      <lv-numeric-text-box
        placeholder="T - days"
        class="lv-input-field"
        [(ngModel)]="settings.settings.pepsConversion.endDate"
        [format]="formatZero"
        [disabled]="!overrideDefaults"
        [decimals]="decimalsZero"
        name="peps-conversion-end-date">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">From Maturity Date</span>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1638"
    class="space-bottom" 
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionBeforeMaturitySourceTooltipTemplate"
      filter="span">
      <span> Conversion Before Maturity </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="peps-conversion-before-maturity"
        [data]="conversionBeforeMaturityLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.pepsConversion.conversionBeforeMaturityType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <div class="lv-label-field lv-label lv-label--title">VARIABLE CONVERSION</div>

  <lv-flex-box 
    data-cy="DM-1639"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="incrementalShareFactorSourceTooltipTemplate"
      filter="span">
      <span> Incremental Share Factor </span>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="variable-inc-share-factor"
        [format]="formatThree"
        [disabled]="!overrideDefaults"
        [decimals]="decimalsThree"
        [(ngModel)]="settings.settings.variableConversion.incrementalShareFactor">
      </lv-numeric-text-box>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1640"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="strikeCCYSourceTooltipTemplate"
      filter="span">
      <span> Strike CCY </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        lvId="lv-variable-conversion-strikeCCY"
        name="variable-strikeCCY"
        [data]="strikeCCYLookup.items"
        valueField="id"
        textField="text"
        [disabled]="!overrideDefaults"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.variableConversion.strikeCurrency">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

<lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1641"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="strikeSourceTooltipTemplate"
      filter="span">
      <span> Strike </span>
    </div>
    <div class="lv-input-field align-sufix">
      <lv-numeric-text-box
        name="variable-strike-percent"
        [format]="formatThree"
        [disabled]="!overrideDefaults"
        [decimals]="decimalsThree"
        [(ngModel)]="settings.settings.variableConversion.strike">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1642"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="capRatioSourceTooltipTemplate"
      filter="span">
      <span> Cap Ratio </span>
    </div>
    <div class="lv-input-field align-sufix">
      <lv-numeric-text-box
        [format]="formatThree"
        [decimals]="decimalsThree"
        [disabled]="!overrideDefaults"
        name="variable-cap-ratio"
        [(ngModel)]="settings.settings.variableConversion.capRatio">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>
  </lv-flex-box>

</lv-flex-box>
</lv-flex-box>

<lv-flex-box class="conversion-settings-column">
  <div class="lv-label-field lv-label lv-label--title">COCO PARAMETERS</div>
  <lv-coco-section-settings [overrideDefaults]="overrideDefaults" [cocoSettings]="settings.settings.contingentConversion"></lv-coco-section-settings>

  <div class="lv-label-field lv-label lv-label--title">ACQUISITION SHARES SETTLEMENT</div>

  <lv-flex-box 
    data-cy="DM-1652"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="acquisitionOptionDateInputTypeTooltipTemplate"
      filter="span">
      <span> Option Date Input Type </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="acquisitionOptionDateInputType"
        [data]="optionalDateInputTypeLookup.items"
        valueField="id"
        textField="text"
        [disabled]="!overrideDefaults"
        [popupSettings]="{ width: 106 }"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.acquisitionOptionDateInputType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1653"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="acquisitionNoticeMinDaysTooltipTemplate"
      filter="span">
      <span> Minimum Notice (days) </span>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="acquisitionNoticeMinDays"
        [format]="formatZero"
        [disabled]="!overrideDefaults"
        [decimals]="decimalsZero"
        [(ngModel)]="settings.settings.acquisitionNoticeMinDays">
      </lv-numeric-text-box>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1654"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="acquisitionNoticeMaxDaysTooltipTemplate"
      filter="span">
      <span> Maximum Notice (days) </span>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="acquisitionNoticeMaxDays"
        [format]="formatZero"
        [disabled]="!overrideDefaults"
        [decimals]="decimalsZero"
        [(ngModel)]="settings.settings.acquisitionNoticeMaxDays">
      </lv-numeric-text-box>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1655"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="acquisitionASVAdjustmentPercTooltipTemplate"
      filter="span">
      <span> ASV Adjustment </span>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="acquisitionASVAdjustmentPerc"
        [format]="formatZero"
        [disabled]="!overrideDefaults"
        [decimals]="decimalsZero"
        [(ngModel)]="settings.settings.acquisitionASVAdjustmentPerc">
      </lv-numeric-text-box>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1656"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="vwapInputTypeTooltipTemplate"
      filter="span">
      <span> VWAP Input Type </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="acquisitionVWAPPeriodInputType"
        [data]="acquisitionVWAPPeriodInputTypeLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [popupSettings]="{ width: 122 }"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.acquisitionVWAPPeriodInputType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1657"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="vWAPPeriodObservationLagTooltipTemplate"
      filter="span">
      <span> VWAP Observation Lag </span>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="vWAPPeriodObservationLag"
        [format]="formatZero"
        [disabled]="!overrideDefaults"
        [decimals]="decimalsZero"
        [(ngModel)]="settings.settings.vwapPeriodObservationLag">
      </lv-numeric-text-box>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1658"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionSettlementMaximumAcquisitionSharesTooltipTemplate"
      filter="span">
      <span> Maximum Shares (of CR) </span>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="conversionSettlementMaximumAcquisitionShares"
        [format]="formatZero"
        [disabled]="!overrideDefaults"
        [decimals]="decimalsZero"
        [(ngModel)]="settings.settings.conversionSettlementMaximumAcquisitionShares">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>
  </lv-flex-box>

  <lv-flex-box
    data-cy="DM-1659"
    class="space-bottom"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="acquisitionSettlementTypeTooltipTemplate"
      filter="span">
      <span> Settlement Type </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="acquisitionSharesSettlType"
        [data]="acquisitionSharesSettlTypeLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        [popupSettings]="{ width: 141 }"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.acquisitionSharesSettlType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <div class="lv-label-field lv-label lv-label--title">RESET PARAMETERS</div>
  <lv-reset-section-settings [overrideDefaults]="overrideDefaults" [resetSettings]="settings.settings.reset"></lv-reset-section-settings>
</lv-flex-box>

<ng-template #deltaNeutralCASourceTooltipTemplate>
  <lv-tooltip dm="dM-1617"></lv-tooltip>
</ng-template>
<ng-template #keepAccruedSourceTooltipTemplate>
  <lv-tooltip dm="dM-1618"></lv-tooltip>
</ng-template>
<ng-template #forfeitCouponOnCatMSourceTooltipTemplate>
  <lv-tooltip dm="dM-1619"></lv-tooltip>
</ng-template>
<ng-template #forfeitCouponOnOCSourceTooltipTemplate>
  <lv-tooltip dm="dM-1620"></lv-tooltip>
</ng-template>
<ng-template #conversionIntoNSSourceTooltipTemplate>
  <lv-tooltip dm="dM-1621"></lv-tooltip>
</ng-template>
<ng-template #acquisitionSharesSettlementTooltipTemplate>
  <lv-tooltip dm="dM-1622"></lv-tooltip>
</ng-template>
<ng-template #conversionSettlementSourceTooltipTemplate>
  <lv-tooltip dm="dM-1623"></lv-tooltip>
</ng-template>
<ng-template #numberOfFixingDayTooltipTemplate>
  <lv-tooltip dm="dM-1624"></lv-tooltip>
</ng-template>
<ng-template #conversionSettlementObservPeriodStartBeforeMatDateTooltipTemplate>
  <lv-tooltip dm="dM-1625"></lv-tooltip>
</ng-template>
<ng-template #conversionSettlementObservPeriodStartBeforeIssuerCallTooltipTemplate>
  <lv-tooltip dm="dM-1626"></lv-tooltip>
</ng-template>
<ng-template #conversionSettlementEarlyConversionObservationLagTooltipTemplate>
  <lv-tooltip dm="dM-1627"></lv-tooltip>
</ng-template>
<ng-template #contingentConversionSourceTooltipTemplate>
  <lv-tooltip dm="dM-1628"></lv-tooltip>
</ng-template>
<ng-template #resettableSourceTooltipTemplate>
  <lv-tooltip dm="dM-1629"></lv-tooltip>
</ng-template>
<ng-template #conversionPriceCCYSourceTooltipTemplate>
  <lv-tooltip dm="dM-1630"></lv-tooltip>
</ng-template>
<ng-template #rebateCCYSourceTooltipTemplate>
  <lv-tooltip dm="dM-1631"></lv-tooltip>
</ng-template>
<ng-template #conversionStartDateSystemDefaultsTooltipTemplate>
  <lv-tooltip dm="dM-1632"></lv-tooltip>
</ng-template>
<ng-template #conversionEndDateSystemDefaultsTooltipTemplate>
  <lv-tooltip dm="dM-1633"></lv-tooltip>
</ng-template>
<ng-template #parallelPEPSSourceTooltipTemplate>
  <lv-tooltip dm="dM-1634"></lv-tooltip>
</ng-template>
<ng-template #conversionRebateCurrencySourceTooltipTemplate>
  <lv-tooltip dm="dM-1635"></lv-tooltip>
</ng-template>
<ng-template #conversionBeforeMaturitySourceTooltipTemplate>
  <lv-tooltip dm="dM-1638"></lv-tooltip>
</ng-template>
<ng-template #conversionStartDatePepsTooltipTemplate>
  <lv-tooltip dm="dM-1636"></lv-tooltip>
</ng-template>
<ng-template #conversionEndDatePepsTooltipTemplate>
  <lv-tooltip dm="dM-1637"></lv-tooltip>
</ng-template>
<ng-template #incrementalShareFactorSourceTooltipTemplate>
  <lv-tooltip dm="dM-1639"></lv-tooltip>
</ng-template>
<ng-template #strikeCCYSourceTooltipTemplate>
  <lv-tooltip dm="dM-1640"></lv-tooltip>
</ng-template>
<ng-template #strikeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1641"></lv-tooltip>
</ng-template>
<ng-template #capRatioSourceTooltipTemplate>
  <lv-tooltip dm="dM-1642"></lv-tooltip>
</ng-template>
<ng-template #acquisitionOptionDateInputTypeTooltipTemplate>
  <lv-tooltip dm="dM-1652"></lv-tooltip>
</ng-template>
<ng-template #acquisitionNoticeMaxDaysTooltipTemplate>
  <lv-tooltip dm="dM-1654"></lv-tooltip>
</ng-template>
<ng-template #acquisitionNoticeMinDaysTooltipTemplate>
  <lv-tooltip dm="dM-1653"></lv-tooltip>
</ng-template>
<ng-template #acquisitionASVAdjustmentPercTooltipTemplate>
  <lv-tooltip dm="dM-1655"></lv-tooltip>
</ng-template>
<ng-template #vwapInputTypeTooltipTemplate>
  <lv-tooltip dm="dM-1656"></lv-tooltip>
</ng-template>
<ng-template #conversionSettlementMaximumAcquisitionSharesTooltipTemplate>
  <lv-tooltip dm="dM-1658"></lv-tooltip>
</ng-template>
<ng-template #vWAPPeriodObservationLagTooltipTemplate>
  <lv-tooltip dm="dM-1657"></lv-tooltip>
</ng-template>
<ng-template #acquisitionSettlementTypeTooltipTemplate>
  <lv-tooltip dm="dM-1659"></lv-tooltip>
</ng-template>

