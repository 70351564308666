import {
  Component, OnInit, Output, EventEmitter, Input, ViewChild,
  ChangeDetectorRef, OnDestroy, OnChanges, ViewEncapsulation, ChangeDetectionStrategy, Optional
} from '@angular/core';
import { CreateFormGroupArgs, DataStateChangeEvent, GridDataResult} from '@progress/kendo-angular-grid';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { constants } from '@lv-core-ui/util';
import {
  LvAdvancedGridComponent, LvAdvancedGridColumn,
  LvAdvancedGridDateColumn, LvAdvancedGridNumericColumn
} from '@lv-core-ui/components';
import { LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { MarketDataClipboard } from '@lv-analytics/components';
import { PutScheduleItem, PutValueType, PutValueTypeDescription } from '@lv-convertible-bond/models';
import { LvExcelService } from '@lv-excel/services';
import { AggregateDescriptor, AggregateResult, State, aggregateBy, process } from '@progress/kendo-data-query';
import { GridContextMenuItem, IGridContextMenuSelectEvent } from '@lv-core-ui/directives';

@Component({
  selector: 'lv-puts-put-schedule',
  templateUrl: './lv-puts-put-schedule.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class LvPutsPutScheduleComponent implements OnInit, OnDestroy, OnChanges {
  @Input() model: PutScheduleItem[];
  @Input() duringPeriod: boolean;
  @Input() valueType: PutValueType;
  @Input() partialPut: boolean;
  @Input() currentNotionalPct: number;

  @Output() didPutScheduleChange: EventEmitter<PutScheduleItem[]>;
  @Output() didTotalTranchePctSumChange: EventEmitter<number>;

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  columns: LvAdvancedGridColumn[];
  scheduleItems: PutScheduleItem[];
  parseFn: any;
  excelFieldAlias = 'PUT_SCHED_RANGE';
  state: State;
  gridData: GridDataResult;
  total: AggregateResult;
  aggregates: AggregateDescriptor[];
  contextMenuItems: GridContextMenuItem[];
  totalSum: number;

  private _modelSubscription: Subscription[];

  get hasScheduleInExcelOverride(): boolean {
    return this._excelService && this._excelService.containsField(this.excelFieldAlias);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelService?.getField(this.excelFieldAlias)?.editable;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    @Optional() private _excelService: LvExcelService) {
    this.initColumns();
    this.parseFn = this.parserFunction.bind(this);

    this.scheduleItems = [];
    this._modelSubscription = [];

    //The state of the data operations applied to the Grid component.
    this.state = {
      filter: {
        logic: "and",
        filters: [],
      },
    };
    
    //The aggregate operation. Takes field and aggregate operation into account.
    this.aggregates  = [
      { field: 'tranche', aggregate: "sum" },
    ];

    this.totalSum = 0;

    this.didPutScheduleChange = new EventEmitter<PutScheduleItem[]>();
    this.didTotalTranchePctSumChange = new EventEmitter<number>();
  }
  /**
   * Create form group.
   * @param args The argument that is passed to the createFormGroup function.
   * @returns FormGroup-tracks the value and validity state of a group of FormControl instances.
   */
  createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return new FormGroup({
      'startDate': new FormControl(args.isNew ? new Date() : args.dataItem.startDate, Validators.required),
      'endDate': new FormControl(args.isNew ? new Date() : args.dataItem.endDate),
      'price': new FormControl(args.dataItem.price),
      'trigger': new FormControl(args.dataItem.trigger),
      'tranche': new FormControl(args.dataItem.tranche)
    });
  }
  /**
   * A callback method that is invoked immediately after the default change detector has checked the directive's data-bound properties for the first time,
   * and before any of the view or content children have been checked.
   * It is invoked only once when the directive is instantiated.
   */
  ngOnInit() {
    this._modelSubscription = [
      this.advancedGrid.didDataChange.subscribe((records: PutScheduleItem[]) => {this.onScheduleChange(records)}),
      this.advancedGrid.doReload.subscribe(() => this.onScheduleReload()),
      this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];

    this.resetArrayValue( this.scheduleItems, this.model);
    this.calculateSumOfColumnValues(this.scheduleItems,'tranche');
    this.setGridIndexRowBackgroundColor();
    this._changeDetectorRef.detectChanges();
  }

  /**
   * A callback method that is invoked immediately after the default change detector has checked data-bound properties if at least one has changed,
   * and before the view and content children are checked.
   */
  ngOnChanges() {
    if (!this.model) {
      this.model = [];
    }
    this.setGridIndexRowBackgroundColor();
    this.scheduleItems = this.model.map((a, index) => {
      if (this.isAccretedPut()) {
        a.price = null;
      }

      a.index = index + 1;

      return { ...a };
    });

    this.addNewContextMenuItemsToGrid();
    this.initColumns();
  }

  /**
   * Add custom context menu item to advanced grid.
   */
  addNewContextMenuItemsToGrid() {
    const contextMenuItemsValue = [];

    if (this.partialPut) {
      const distributeTranchesEquallyContextMenuItem = new GridContextMenuItem();
      distributeTranchesEquallyContextMenuItem.actionId = 'distribute-tranches-equally';
      distributeTranchesEquallyContextMenuItem.actionText = 'Distribute Tranches Equally';
      distributeTranchesEquallyContextMenuItem.action = this.distributeTranchesEqually.bind(this);
      distributeTranchesEquallyContextMenuItem.actionVisible = (event: IGridContextMenuSelectEvent) => event.columnIndex !== 0 && this.model.length > 0;
      distributeTranchesEquallyContextMenuItem.showDivider = true;
      contextMenuItemsValue.push(distributeTranchesEquallyContextMenuItem);
    }

    this.contextMenuItems = contextMenuItemsValue;
  }

  /**
   * Method in charge for applying grid changes.
   */
  applyAdvancedGridChanges() {
    this.advancedGrid.applyChanges(records => this.onScheduleChange(records));
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately before a directive, pipe, or service instance is destroyed.
   */
  ngOnDestroy() {
    this._modelSubscription.forEach(s => s.unsubscribe());
  }

  /**
   * Reset Put Price Schedule
   * @param putType Put Value Type
   */
  resetPutPriceSchedule(putType: PutValueType) {
    this.scheduleItems = this.model.map((a) => {
      if (this.isAccretedPut()) {
        a.price = null;
      }
      return { ...a };
    });

    if (putType === PutValueType.AccretedValue) {
      this.onScheduleChange(this.scheduleItems);
    }
  }

  /**
   * Data State Change
   * @param state The operation descriptors that will be applied to the data.
   */
  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.scheduleItems, this.state);
  }

  /**
   * Distribute tranches equally to all items.
   */
  distributeTranchesEqually() {
      const equalTranche = this.currentNotionalPct / this.scheduleItems.length;

      this.scheduleItems = this.model.map(a => {
        a.tranche = equalTranche;
        return { ...a };
      });
    this.onScheduleChange(this.scheduleItems);
  }

  /**
   * Method for updating grid column.
   * @param c LvAdvancedGridColumn
   */
  private updateColumn(c: LvAdvancedGridColumn) {
    if (c.field === 'endDate') {
      c.visible = this.duringPeriod;
    }
  }

  /**
   * Method for parsing schedule data.
   * @param pastedDataRecords Put Schedule Items
   * @returns Parsed schedule items.
   */
  private parserFunction(pastedDataRecords: string[]): PutScheduleItem[] {
    const scheduleItems: PutScheduleItem[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');
      let endDate: Date;
      let price: number;
      let trigger: number;
      let priceValue;
      let triggerValue;

      const startDateValue = items[0];
      const startDate = MarketDataClipboard.parseDateValue(startDateValue, 'Start Date');

      if (this.duringPeriod) {
        const endDateValue = items[1];
        priceValue = items[2];
        triggerValue = items[3];

        endDate = MarketDataClipboard.parseDateValue(endDateValue, 'End Date');
        price = MarketDataClipboard.parseNumberValue(priceValue, 'Price');
        trigger = MarketDataClipboard.tryParseNumberValue(triggerValue, 'Trigger');

        scheduleItems.push({
          startDate: startDate,
          endDate: endDate,
          price: price,
          trigger: trigger,
        } as PutScheduleItem);

      } else {
        priceValue = items[1];
        triggerValue = items[2];

        price = MarketDataClipboard.parseNumberValue(priceValue, 'Price');
        trigger = MarketDataClipboard.tryParseNumberValue(triggerValue, 'Trigger');

        scheduleItems.push({
          startDate: startDate,
          price: price,
          trigger: trigger,
        } as PutScheduleItem);
      }
    });

    return scheduleItems;
  }

  /**
   * Initializes grid for put schedule
   */
  private initColumns() {
    this.columns = [];

    const indexColumn = new LvAdvancedGridNumericColumn();
    indexColumn.field = 'index';
    indexColumn.title = '';
    indexColumn.editable = false;
    indexColumn.visible = this.partialPut;
    indexColumn.width = 33;


    const startDateColumn = new LvAdvancedGridDateColumn();
    startDateColumn.title = this.duringPeriod ? 'Start Date' : 'Date';
    startDateColumn.field = 'startDate';
    startDateColumn.width = 102;
    startDateColumn.dmKey = 'DM-2284';

    const endDateColumn = new LvAdvancedGridDateColumn();
    endDateColumn.title = 'End Date';
    endDateColumn.field = 'endDate';
    endDateColumn.width = 102;
    endDateColumn.dmKey = 'DM-2285';
    this.updateColumn(endDateColumn);

    const priceColumn = new LvAdvancedGridNumericColumn();
    priceColumn.title = this.priceColumnLabel();
    priceColumn.field = 'price';
    priceColumn.width = 102;
    priceColumn.outputFormat = constants.numberFormat.upToFourDigits;
    priceColumn.format = '#,###.####';
    priceColumn.decimals = '4';
    priceColumn.editable = !this.isAccretedPut();
    priceColumn.dmKey = 'DM-2286';

    const triggerColumn = new LvAdvancedGridNumericColumn();
    triggerColumn.title = 'Trigger';
    triggerColumn.field = 'trigger';
    triggerColumn.width = 102;
    triggerColumn.outputFormat = constants.numberFormat.upToFourDigits;
    triggerColumn.format = '#,###.####';
    triggerColumn.decimals = '4';
    triggerColumn.dmKey = 'DM-2287';

    const trancheColumn = new LvAdvancedGridNumericColumn();
    trancheColumn.title = 'Tranche (%)';
    trancheColumn.field = 'tranche';
    trancheColumn.width = 102;
    trancheColumn.visible = this.partialPut;
    trancheColumn.outputFormat = constants.numberFormat.upToFourDigits;
    trancheColumn.format = '#,###.####';
    trancheColumn.decimals = '4';
    trancheColumn.dmKey = 'DM-4512';

    this.columns.push(indexColumn);
    this.columns.push(startDateColumn);
    this.columns.push(endDateColumn);
    this.columns.push(priceColumn);
    this.columns.push(triggerColumn);
    this.columns.push(trancheColumn);
  }

  /**
   * Method occurs on schedule change.
   * @param gridItems Put Schedule Items.
   */
  private onScheduleChange(gridItems: PutScheduleItem[]) {
    gridItems.forEach((record, index) => {
      record.index = index + 1;
    });

    this.resetArrayValue(this.model, gridItems);
    
    this.resetArrayValue(this.scheduleItems, this.model);
   
    this.calculateSumOfColumnValues(this.model,'tranche');
    this.initColumns();

    this.didPutScheduleChange.next(this.model);
  }

  /**
   * Method occurs on schedule reload.
   */
  private onScheduleReload() {
    this.scheduleItems = this.scheduleItems.map(a => ({ ...a }));
  }
  /**
   * Method occurs if error happens.
   * @param error Error.
   */
  private onError(error: LvError) {
    this._errorService.handleError(error);
  }

  /**
   * Sets column label depending on putValueType selection
   */
  private priceColumnLabel(): string {
    if (this.valueType === PutValueType.PerOfPar) {

      return PutValueTypeDescription[this.valueType];
    }
    else if (this.valueType === PutValueType.GrossYield) {
      if (this.hasScheduleInExcelOverride) {
        return `Gross Yld.(%)`;
      }
      else {
        return `${PutValueTypeDescription[this.valueType]} (%)`;
      }
    }
    else if (this.valueType === PutValueType.NetYield) {
      return `${PutValueTypeDescription[this.valueType]} (%)`;
    }
    return 'Value';
  }

  /**
   * Checks if putValueType is equal to Accreated
   */
  private isAccretedPut(): boolean {
    return this.valueType === PutValueType.AccretedValue;
  }

  /**
   * Method adds/removes certain class to set background color of index column in Put schedule.
   * @returns 
   */
  private setGridIndexRowBackgroundColor() {
    return  this.partialPut ? this.advancedGrid.grid.wrapper.nativeElement.classList.add('lv-advanced-grid-index-column'):
    this.advancedGrid.grid.wrapper.nativeElement.classList.remove('lv-advanced-grid-index-column');
  }

  /**
   * Calculate Sum Of Column Values
   * @param columnName Column field name
   */
  private calculateSumOfColumnValues(data: PutScheduleItem[], columnName: string) {
    this.totalSum = 0;
    if (this.model.length > 0) {
      this.gridData = process(data, this.state);
      this.total = aggregateBy(data, this.aggregates);
      this.totalSum = this.total[columnName].sum;
    }

    this.didTotalTranchePctSumChange.next(this.totalSum);
  }

  /**
   * Update data with corresponding data for update.
   * @param dataToUpdate Data that needs to be updated.
   * @param inputValue Data used for updating.
   */
  private resetArrayValue(dataToUpdate: PutScheduleItem[], dataForUpdate: PutScheduleItem[]): void {
    dataToUpdate.splice(0, dataToUpdate.length);
    dataToUpdate.push(... dataForUpdate);
  }
}
