import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';

import { LvScenarioOneDimGridView } from './lv-scenario-one-dim-grid.view';
import { ILvScenarioReport } from '../../lv-scenario.view';

/**
 * Scenario one dim grid component.
 */
@Component({
  selector: 'lv-scenario-one-dim-grid',
  templateUrl: './lv-scenario-one-dim-grid.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvScenarioOneDimGridComponent implements OnChanges {

  @Input() report: ILvScenarioReport;

  view: LvScenarioOneDimGridView;

  constructor() {
    this.view = new LvScenarioOneDimGridView();
    this.view.init();
  }

  /**
   * Does view initialization.
   */
  ngOnChanges() {
    this.view.init(this.report);
  }
}
