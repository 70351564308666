import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter, ElementRef, Optional } from '@angular/core';
import { IStochasticCreditModel } from '@lv-analytics/models';
import { LvExcelService } from '@lv-excel/services';
import { v4 } from 'uuid';

/**
 * Equity stochastic credit model component.
 */
@Component({
  selector: 'lv-equity-stochastic-credit-model',
  templateUrl: './lv-equity-stochastic-credit-model.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEquityStochasticCreditModelComponent implements OnInit {

  @Output() doSaveSession: EventEmitter<void>;

  @Input() stochasticCreditModel: IStochasticCreditModel;

  useStochasticCreditId: string;

  numberOfDecimalsPercetenge = '2';
  numberFormat = '#.##';
  numberOfDecimalsBps = '0';
  numberFormatBps = 'n0';
  numberOfDecimalsIntensity = '3';
  numberFormatIntensity = '#.###';

  constructor(@Optional() private _excelSvc: LvExcelService) {
    this.useStochasticCreditId = v4();
    this.doSaveSession = new EventEmitter<void>();
   }

  get hasCreditStochasticCreditRangeOverridedFromExcel(): boolean {
    return !!this._excelSvc?.hasCreditStochasticCreditRangeOverridedFromExcel();
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Occurs stochastic credit change.
   */
  onStochasticCreditChange() {
    this.doSaveSession.emit();
  }

  /**
   * Gets credit tooltip ID.
   * @param element HTML Element.
   * @param sectionId Section ID.
   * @returns Credit tooltip ID.
   */
  getCreditTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('credit-tooltip-id') === sectionId;
  }
}
