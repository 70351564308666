import { Directive, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { ContextMenuComponent, ContextMenuSelectEvent, MenuItem } from '@progress/kendo-angular-menu';
import { GridComponent, CellClickEvent } from '@progress/kendo-angular-grid';

export interface IGridContextMenuSelectEvent {
  item: MenuItem;
  dataItem: any;
  rowIndex: number;
  columnIndex: number;
}

export class GridContextMenuItem {

  public set actionId(value: string) {
    this._actionId = `external-action-${value}`;
  }

  public get actionId(): string {
    return this._actionId;
  }

  public action: (event: IGridContextMenuSelectEvent) => void;

  public actionVisible: (event: IGridContextMenuSelectEvent) => boolean;

  public actionText: string;

  // show divider after menu item.
  public showDivider?: boolean;

  private _actionId: string;

  constructor() {
    this.actionVisible = () => true;
    this.showDivider = false;
  }
}

@Directive({
  selector: '[gridContextMenu]'
})
export class GridContextMenuDirective implements OnInit, OnDestroy {

  // tslint:disable-next-line: no-input-rename
  @Input('gridContextMenu')
  public contextMenu: ContextMenuComponent;

  @Output() didSelectMenuItem: EventEmitter<IGridContextMenuSelectEvent>;
  @Output() didContextMenuOpen: EventEmitter<IGridContextMenuSelectEvent>;

  private _cellClickEvent: CellClickEvent;
  private _cellClickSubscription: Subscription;
  private _contextMenuSelectSubscription: Subscription;

  constructor(
    private _grid: GridComponent
  ) {
    this.didSelectMenuItem = new EventEmitter<IGridContextMenuSelectEvent>();
    this.didContextMenuOpen = new EventEmitter<IGridContextMenuSelectEvent>();
  }

  ngOnInit() {
    this._cellClickSubscription = this._grid.cellClick
      .subscribe((e: CellClickEvent) => {
        if (e.type === 'contextmenu') {
          const orgEvent = e.originalEvent as MouseEvent;

          orgEvent.preventDefault();

          this._cellClickEvent = e;

          this.didContextMenuOpen.next({
            item: null,
            dataItem: this._cellClickEvent.dataItem,
            rowIndex: this._cellClickEvent.rowIndex,
            columnIndex: this._cellClickEvent.columnIndex
          });

          this.contextMenu.show({
            top: orgEvent.pageY,
            left: orgEvent.pageX
          });
        }
      });

    this._contextMenuSelectSubscription = this.contextMenu.select
      .subscribe((evt: ContextMenuSelectEvent) => {
        this.didSelectMenuItem.next({
          item: evt.item,
          dataItem: this._cellClickEvent.dataItem,
          rowIndex: this._cellClickEvent.rowIndex,
          columnIndex: this._cellClickEvent.columnIndex
        });
      });
  }

  ngOnDestroy() {
    this._cellClickSubscription.unsubscribe();
    this._contextMenuSelectSubscription.unsubscribe();
  }
}
