import { Component, OnInit, Input, ChangeDetectionStrategy, ViewEncapsulation, HostBinding } from '@angular/core';
import { ICreditSectionSettings, CreditSourceDescription, PeriodFrequencyDescription, DayCountDescription,
         DateAdjustmentDescription, AccruedOnDefaultDescription, CreditModelFactorDisplay,
         EquityToCreditCalculationTypeDescription } from '@lv-analytics/models';
import { LvLookupEnum } from '@lv-core-ui/util';

/**
 * Credit section settings component.
 */
@Component({
  selector: 'lv-credit-section-settings',
  templateUrl: './lv-credit-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvCreditSectionSettingsComponent implements OnInit {

  @Input() settings: ICreditSectionSettings;
  @Input() overrideSystemDefaults: boolean;

  creditSource: LvLookupEnum;
  frequency: LvLookupEnum;
  basis: LvLookupEnum;
  badDayConvention: LvLookupEnum;
  accruedOnDefault: LvLookupEnum;
  creditFactorModel: LvLookupEnum;
  calibrationType: LvLookupEnum;

  numberOfDecimals = '4';
  numberFormat = '#.####';

  constructor() {
    this.creditSource = new LvLookupEnum(CreditSourceDescription);
    this.frequency = new LvLookupEnum(PeriodFrequencyDescription);
    this.basis = new LvLookupEnum(DayCountDescription);
    this.badDayConvention = new LvLookupEnum(DateAdjustmentDescription);
    this.accruedOnDefault = new LvLookupEnum(AccruedOnDefaultDescription);
    this.creditFactorModel = new LvLookupEnum(CreditModelFactorDisplay);
    this.calibrationType = new LvLookupEnum(EquityToCreditCalculationTypeDescription);

    this.overrideSystemDefaults = true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return false;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }
}

