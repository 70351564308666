import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[lvMinDate]'
})
export class LvMinDateDirective  implements OnInit {
  @Input() lvMinDate: any;
  minDatePickerDate: Date;
  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.minDatePickerDate = new Date(1900, 0, 1);
    this.onValueChanged();
  }

   @HostListener('blur')
   onValueChanged(): boolean {
     return (new Date(this.lvMinDate) instanceof Date &&
     !isNaN(new Date(this.lvMinDate).valueOf())) && (
     new Date(this.lvMinDate) > new Date(this.minDatePickerDate));
    }
}
