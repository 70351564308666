import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe,  } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LabelModule } from '@progress/kendo-angular-label';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { LvTermsSummaryComponent } from './components/lv-terms-summary/lv-terms-summary.component';
import { TermsSummaryService } from './services/terms-summary/terms-summary.service';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ReferenceDataHubService } from './hubs/reference-data-hub.service';
import { LvMultiSelectComponent } from '@lv-core-ui/components';
import { LeversysCoreUIModule } from '@lv-core-ui/leversys-core-ui.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    // Kendo modules
    LabelModule,
    PopupModule,
    InputsModule,
    DropDownsModule,
    ButtonsModule,
    DatePickerModule,
    DialogModule,
    SortableModule,
    TooltipModule,

    LeversysCoreUIModule
  ],

  declarations: [
    LvTermsSummaryComponent
  ],
  exports: [
    LvTermsSummaryComponent
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    LvMultiSelectComponent,
    TermsSummaryService,
    ReferenceDataHubService
  ]
}
)

export class ReferenceDataModule {}
