import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, HostBinding, Input } from '@angular/core';
import { IBorrowSectionSettings, BorrowSourceDescription } from '@lv-analytics/models';
import { LvLookupEnum } from '@lv-core-ui/util';

/**
 * Borrow section settings component.
 */
@Component({
  selector: 'lv-borrow-section-settings',
  templateUrl: './lv-borrow-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvBorrowSectionSettingsComponent implements OnInit {

  @Input() settings: IBorrowSectionSettings;
  @Input() overrideSystemDefaults: boolean;

  borrowSourceEnum: LvLookupEnum;

  constructor() {
    this.borrowSourceEnum = new LvLookupEnum(BorrowSourceDescription);

    this.overrideSystemDefaults = true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--row')
  get isFlexColumnComponent() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }
}
