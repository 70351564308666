import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Input,
  OnChanges, ViewChild } from '@angular/core';

import { slide } from './lv-documents-sliding-panel-animations';
import { IDocumentMetadata } from '../../models/document-metadata';
import { LvDocumentComponent } from '../lv-document/lv-document.component';

/**
 * Document sliding panel component.
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'lv-documents-sliding-panel',
  templateUrl: './lv-documents-sliding-panel.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    slide
  ]
})
export class LvDocumentsSlidingPanelComponent implements OnInit, OnChanges {

  @ViewChild(LvDocumentComponent) documentComponent: LvDocumentComponent;

  @Input() lwsIdentifier: string;
  @Input() isin: string;
  @Input() instrumentName: string;

  selectedDocument: IDocumentMetadata;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.selectedDocument = null;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Occurs on changes.
   */
  ngOnChanges() {
    this.onBack();
  }

  /**
   * Occurs on back.
   */
  onBack() {
    this.selectedDocument = null;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on select document.
   * @param document IDocumentMetadata object.
   */
  onSelectDocument(document: IDocumentMetadata) {
    this.selectedDocument = document;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Force rerender.
   */
  forceRerender() {
    if (this.selectedDocument) {
      this.documentComponent.forceRerender();
    }
  }
}
