import { Component, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';

import { Subscription, Observable, timer } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

import { Router } from '@angular/router';
import { v4 } from 'uuid';
import { LvApplicationVersionModalComponent,
         LvSignalrModalComponent,
         LvStateModalComponent,
         LvErrorModalComponent,
         LvAuthenticationModalComponent } from '@lv-core-ui/components';
import { LvAuthenticationError, LvError, LvErrorType } from '@lv-core-ui/models';
import { LvApplicationVersionService, LvErrorService, LvLoggerService } from '@lv-core-ui/services';
import { constants, ILvHubOptions, LocalStorage } from '@lv-core-ui/util';
import { ApplicationSettingsService } from '@lv-application-settings/application-settings.service';
import { SettingsService } from '@lv-application-settings/services';
import { RouteCacheService } from '@lv-cache/route-cache';
import { SecurityService } from '@lv-security/security.service';
import { environment } from 'src/environments/environment';
import { OnlinePlatformHubService } from './services';
import { LvExcelService } from '@lv-excel/services';
import { LvDataMasterService } from '@lv-core-ui/services/lv-data-master-service/lv-data-master-service.service';
import { LvDataMaster } from '@lv-core-ui/models/lv-data-master';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;
  @ViewChild(LvApplicationVersionModalComponent, { static: true }) appVersionModal: LvApplicationVersionModalComponent;
  @ViewChild(LvSignalrModalComponent, { static: true }) signalrModal: LvSignalrModalComponent;
  @ViewChild(LvStateModalComponent, { static: true }) stateModal: LvStateModalComponent;
  @ViewChild(LvErrorModalComponent, { static: true }) errorModal: LvErrorModalComponent;
  @ViewChild(LvAuthenticationModalComponent, { static: true }) authenticationModal: LvAuthenticationModalComponent;

  get shouldEstablishRealTimeConnection(): boolean {
    return environment.sockets.shouldConnect;
  }

  get isOpenedFromExcel(): boolean {
    return !!this._excelService?.isInitialized();
  }

  toastInvisible: boolean;

  public mode: ApplicationMode;
  public redirectToExternal: boolean;

  public instanceId: string;

  private _subscriptions: Subscription[];

  private _hubOptions: ILvHubOptions;

  private identifier: string;

  private _timer: Observable<number>;
  private closedModalCounter: number;
  private previousError: string;
  private closing: boolean;

  constructor(
    private _dataMasterService: LvDataMasterService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _toastrService: ToastrService,
    private _appVersion: LvApplicationVersionService,
    private _onlinePlatformHubService: OnlinePlatformHubService,
    private _applicationSettingsService: ApplicationSettingsService,
    private _errorService: LvErrorService,
    private _securityService: SecurityService,
    private _routeCache: RouteCacheService,
    private _settingsService: SettingsService,
    private _logger: LvLoggerService,
    private _router: Router,
    private _excelService: LvExcelService,
  ) {
    this.closing = false;
    this.closedModalCounter = 0;
    this.previousError = '';
    this.toastInvisible = environment.ngxToastr.toastInvisible;
    this.instanceId = v4();
    this.mode = ApplicationMode.Normal;
    this._hubOptions = {
      url: environment.sockets.url
    };

    this._timer = timer(1000 * 30, 60 * 1000);

    window.addEventListener('unload', async (e) => {
      navigator.sendBeacon(environment.apiUrl + '/v1/applicationSettings/applicationInstance/' + this.instanceId);
      e.preventDefault();
      return;
    });

    /*if url is from excel or notificaton */
    if (window.location.href.indexOf('workspaces?name=') > 0) {
      this.mode = ApplicationMode.SingleInstrument;
      this._applicationSettingsService.stopStateUpdates();
      this.redirectToExternal = true;
      const identifierString = window.location.href.substring(window.location.href.indexOf('identifier='));
      this.identifier = identifierString.substr(identifierString.indexOf('=') + 1);
    }
    else if (window.location.href.indexOf('dashboard/external-instrument') > 0) {
      this.mode = ApplicationMode.SingleInstrument;
      this._applicationSettingsService.stopStateUpdates();
    }
  }

  async ngOnInit() {

    this._toastrService.overlayContainer = this.toastContainer;

    if (environment.applicationVersion.shouldCheck) {
      this._appVersion.init(environment.applicationVersion.url);
    }

    this._subscriptions = [
      ...(environment.applicationVersion.shouldCheck ? [
        this._appVersion.didApplicationVersionChange.subscribe(() => {
          if (!this.appVersionModal.isOpened) {
            this.errorModal.close();
            this.signalrModal.close();
            this.stateModal.close();
            this.authenticationModal.close();

            this.appVersionModal.open();

            this._changeDetectorRef.markForCheck();
          }
        })
      ] : []),

      this._errorService.authenticationError.subscribe(error => {
        if (!this.authenticationModal.isOpened &&
          !this.appVersionModal.isOpened &&
          !this.errorModal.isOpened) {

          this.signalrModal.close();
          this.stateModal.close();
          this.stateModal.close();

          this.authenticationModal.open(error.type === LvErrorType.AUTHENTICATION);
        }
      }),

      this.stateModal.doLoginAgain.subscribe((value) => {
        this.stateModal.close();
        this.onLogInAgain();
      }),

      this.stateModal.doReload.subscribe(() => this.onReloadApplication()),

      ...(environment.showErrorModal ? [
        this._errorService.unexpectedError.subscribe(unexpectedError => {
          if (!this.appVersionModal.isOpened &&
            !this.errorModal.isOpened) {

            if (this.previousError !== unexpectedError.message) {
              this.previousError = unexpectedError.message;
              this.closedModalCounter = 0;
              this.closing = false;
            }

            this.authenticationModal.close();
            this.signalrModal.close();
            this.stateModal.close();

            this.errorModal.open(unexpectedError);
            this.errorModal.closedModalCounter = this.closedModalCounter;

            this.errorModal.closedModal.subscribe(res => {
              if (res) {
                ++this.closedModalCounter;
              }
            });

            this.errorModal.destroyWorkspace.subscribe(res => {
              if (res) {
                if (!this.closing) {
                  this.closing = true;
                  this._errorService.closeWorkspace.next(true);
                }
              }
            });
          }
        })
      ] : []),

      ...(this.shouldEstablishRealTimeConnection ? [
        this._onlinePlatformHubService.didError.subscribe(error => {
          if (!this.appVersionModal.isOpened &&
            !this.authenticationModal.isOpened &&
            !this.errorModal.isOpened &&
            !this.stateModal.isOpened &&
            !this.signalrModal.isOpened) {

            if (error.type === LvErrorType.AUTHENTICATION) {
              this.authenticationModal.open();
              this.signalrModal.close();
            }
            else {
              this.authenticationModal.close();
              this.signalrModal.open();
            }

            this._changeDetectorRef.markForCheck();
          }
        })
      ] : [])
    ];

    try {
      const oldUser = LocalStorage.getJwtUser();
      await this._securityService.tokenService.refreshToken();

      const account = LocalStorage.getTokenResponse();

      if (!oldUser || oldUser.UserId !== LocalStorage.getJwtUser().UserId) {
        LocalStorage.clearApplicationState();
      }

      this._hubOptions.transport = account.httpTransportType;

      await this._logger.log(`Origin url: ${window.location.href}, App mode ${this.mode},
       AppVersion: ${this._applicationSettingsService.stateVersion}`);

      if (this.shouldEstablishRealTimeConnection) {
        this.startHubConnection();
      }

      if (this.mode === ApplicationMode.SingleInstrument) {
        await this._applicationSettingsService.initialize(this.isOpenedFromExcel);

        if (this.redirectToExternal) {
          this._router.navigate([`dashboard/external-instrument/${this.identifier}`]);
        }
      }

      const instanceId = await this._settingsService.getInstanceId();

      // tslint:disable-next-line: max-line-length
      if (instanceId && instanceId !== 'null' && instanceId !== this.instanceId && this.mode !== ApplicationMode.SingleInstrument && !(document.location.href.indexOf('name=') > 0)) {
        this.mode = ApplicationMode.AnotherInstance;
        this._applicationSettingsService.stopStateUpdates();
        this._router.navigate(['/another-instance']);
      }
      // tslint:disable-next-line: max-line-length
      else if (this.mode === ApplicationMode.Normal && !(document.location.href.indexOf('name=') > 0) && !(document.location.href.indexOf('external-instrument') > 0)) {
        await this._settingsService.registerApplicationInstance(this.instanceId);
        this._subscriptions.push(this._timer.subscribe(
          async () => {
            try {
              this._settingsService.InstanceHeartBeat(this.instanceId);
            }
            catch (error) {
              console.log(error);
            }
          }));
      }

      if (document.location.href.indexOf('another-instance') > 0 && this.mode === ApplicationMode.Normal) {
        this._router.navigate(['dashboard']);
      }

      LvDataMaster.populateDictionary(await this._dataMasterService.fetchTooltipsFromAPI());
      LvDataMaster.populateErrors(await this._dataMasterService.fetchErrorsFromAPI());
      LvDataMaster.populateWarnings(await this._dataMasterService.fetchWarningsFromAPI());
      LvDataMaster.populateInfos(await this._dataMasterService.fetchInfosFromAPI());

      this._applicationSettingsService.populateComponentDictionary();
    }
    catch (error) {
      if (error instanceof LvAuthenticationError) {
        this.redirectTo(environment.identityServer.loginUrl);
      }
      else {
        this._logger.logError(error);
        this._errorService.handleError(new LvError(constants.errors.unexpectedError));
      }
    }
  }

  // App Version
  onReloadApplication() {
    window.location.reload();
  }

  // SignalR
  async startHubConnection(): Promise<void> {
    this._onlinePlatformHubService.startConnection(this._hubOptions)
      .catch(error => {});
  }

  onContinueAfterHubError() {
    this._onlinePlatformHubService.setShouldEmitError(false);
    this._changeDetectorRef.markForCheck();
  }

  async onReconnectHub() {
    try {
      this.signalrModal.setErrorMessage(null);
      this.signalrModal.isLoading = true;

      await this._onlinePlatformHubService.startConnection(this._hubOptions);

      this.signalrModal.close();
    }
    catch (error) {
      this.signalrModal.setErrorMessage('Unable to connect to server!');
    }
    finally {
      this.signalrModal.isLoading = false;
    }
  }

  redirectTo(url: string) {
    const encoded = encodeURIComponent(window.location.href);
    window.open(`${url}?returnUrl=${encoded}`, '_self');
  }

  // Region - Authentication Service Modal
  async onLogInAgain(sessionExpired: boolean = false) { // Applies to Application State Modal

    if (!!this._excelService?.isInitialized()) {
      this._excelService.onSessionExpired();
      return;
    }

    this._routeCache.invalidate();
    LocalStorage.clear();
    if (this.shouldEstablishRealTimeConnection) {
      this._onlinePlatformHubService.setShouldEmitError(false);
      await this._onlinePlatformHubService.stopConnection().catch(error => {});
      this._onlinePlatformHubService.setShouldEmitError(true);
    }
    if (sessionExpired) {
      this.redirectTo(`${environment.identityServer.loginUrl}?sessionExpired=true`);
    }
    else {
      this.redirectTo(`${environment.identityServer.loginUrl}`);
    }
  }

  async onRetry() {
    try {
      this.authenticationModal.isLoading = true;

      await this._securityService.tokenService.refreshToken();

      this.authenticationModal.close();
    }
    catch (error) {}
    finally {
      this.authenticationModal.isLoading = false;
    }
  }

  async onRetryLogInAgain() {
    try {
      this.authenticationModal.isLoading = true;

      await this._securityService.tokenService.logout();

      this.authenticationModal.close();
    }
    catch (error) {}
    finally {
      this.authenticationModal.isLoading = false;
      this.onLogInAgain();
    }
  }
  // Region End - Authentication Service Modalgit
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }
}

export enum ApplicationMode {
  Normal = 'Normal',
  SingleInstrument = 'SingleInstrument',
  AnotherInstance = 'AnotherInstance'
}
