<ng-container *ngIf="hasData">
  <lv-scenario-one-dim-chart #current
    *ngIf="isOneDimChart"
    [report]="report">
  </lv-scenario-one-dim-chart>
  
  <lv-scenario-one-dim-price-talk-chart #current
    *ngIf="isOneDimPriceTalkChart"
    [report]="report">
  </lv-scenario-one-dim-price-talk-chart>

  <lv-scenario-two-dim-chart #current
    *ngIf="isTwoDimChart"
    [report]="report">
  </lv-scenario-two-dim-chart>

  <lv-scenario-two-dim-price-talk-chart #current
    *ngIf="isTwoDimPriceTalkChart"
    [report]="report">
  </lv-scenario-two-dim-price-talk-chart>
</ng-container>