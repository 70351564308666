import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { v4 } from 'uuid';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { IEstimateSettingsUI, ICrEstimateEnvironmentSettingsUI } from './environment-settings-ui';
import { LvErrorService } from '@lv-core-ui/services';
import { LvUtil } from '@lv-core-ui/util';
import { IEstimatePanelSettings, ISaveEstimatesSidePanelSettingsRequest, IInstrumentCrEstimateSettingsDto } from '@lv-analytics/models';
import { CompanyAndUserSettingsService } from '@lv-analytics/services';
import { LvDataMaster } from '@lv-core-ui/models';

/**
 * Estimates settings dialog component.
 */
@Component({
  selector: 'lv-estimates-settings-dialog',
  templateUrl: './lv-estimates-settings-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEstimatesSettingsDialogComponent implements OnInit {
  @ViewChild('tabStrip') tabStrip: TabStripComponent;

  @Input() lwsIdentifier: string;
  @Input() convertibleInfoId: string;

  overrideSystemDefaultsCheckBoxId: string;
  instrumentLabelOverrideCheckBoxId: string;

  estimateSettings: IEstimateSettingsUI;
  estimateSettingsDto: IEstimatePanelSettings;

  isLoading: boolean;

  constructor(
    private dialogRef: DialogRef,
    private companySettingsService: CompanyAndUserSettingsService,
    private errorService: LvErrorService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.isLoading = false;
    this.overrideSystemDefaultsCheckBoxId = v4();
    this.instrumentLabelOverrideCheckBoxId = v4();

    this.estimateSettings = {
      systemDefaults : {
        useEstimatedRatio: true,
        selectedCurrency: 'ConvertibleCurrency',
        showConversionPrice: true,
      },
      environments : [],
    } as IEstimateSettingsUI;
  }

  /**
   * Handles any additional initialization tasks.
   */
  async ngOnInit() {
    try {
      this.estimateSettingsDto = await this.companySettingsService.getEstimatesSidePanelSettings(this.lwsIdentifier);
      this.estimateSettings.systemDefaults = this.estimateSettingsDto.systemDefaults;

      let isDefaultSet = false;
      this.estimateSettingsDto.environments.forEach(env => {

        if (!env.isDisabled) {

        const environmentSettings = env.instrumentOverrides.find(io => io.instrumentId === this.convertibleInfoId);
        const instrumentLevelOverride = environmentSettings ? environmentSettings.settings : null;
        const hasOverride = env.instrumentOverrides.some(io => io.instrumentId === this.convertibleInfoId);

        this.estimateSettings.environments.push({
          default: !isDefaultSet ? true : false,
          environmentId: env.environmentId,
          environmentName: env.environmentName,
          overrideSystemDefaults: env.overrideSystemDefaults,
          instrumentLevelOverride: hasOverride,
          instrument : hasOverride ? instrumentLevelOverride :  {
            useEstimatedRatio: true,
            selectedCurrency: 'ConvertibleCurrency',
            showConversionPrice: true,
          },
         settings : env.settings,
        } as ICrEstimateEnvironmentSettingsUI);
        isDefaultSet = true;
      }
      });

    }
    catch (error) {
      this.errorService.handleError(error);
    }
    finally {
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Saves estimates side panel settings.
   */
  onSave() {
    try {
      this.isLoading = true;
      const request = this.getSaveRequest();

      this.companySettingsService.saveEstimatesSidePanelSettings(request);

      this.errorService.toastrService.success(LvDataMaster.getInfo('dM-1813'));
      this.dialogRef.close();
    }
    catch (error) {
      this.errorService.handleError(error);
    }
    finally {
      this.isLoading = false;
      this.changeDetectorRef.markForCheck();
    }
  }

  /**
   * Closes dialog.
   */
  onCancel() {
    this.dialogRef.close();
  }

  /**
   * Gets override system defaults checkbox ID.
   * @param environmentName Environment name.
   * @returns Checkbox ID.
   */
  getOverrideSystemDefaultsMyCheckBoxId(environmentName: string): string {
    return `${environmentName}-${this.overrideSystemDefaultsCheckBoxId}`;
  }

  /**
   * Gets instrument label override checkbox ID.
   * @param environmentName Environment name.
   * @returns Checkbox ID.
   */
  getInstrumentLabelOverrideMyCheckBoxId(environmentName: string): string {
    return `${environmentName}-${this.instrumentLabelOverrideCheckBoxId}`;
  }

  /**
   * Gets save estimate side panel settings request.
   * @returns ISaveEstimatesSidePanelSettingsRequest object.
   */
  private getSaveRequest(): ISaveEstimatesSidePanelSettingsRequest {
    const request = LvUtil.jsonParse(LvUtil.jsonStringify(this.estimateSettingsDto)) as ISaveEstimatesSidePanelSettingsRequest;

    this.estimateSettings.environments.forEach(item => {
      request.environments.find(x => x.environmentId === item.environmentId).settings = item.settings;
      request.environments.find(x => x.environmentId === item.environmentId).overrideSystemDefaults = item.overrideSystemDefaults;

      if (item.instrumentLevelOverride) {
        if (!request.environments.find(x => x.environmentId === item.environmentId).instrumentOverrides) {
          request.environments.find(x => x.environmentId === item.environmentId).instrumentOverrides = [];
        }

        const settings = request.environments.find(x => x.environmentId === item.environmentId)
          .instrumentOverrides.find(x => x.instrumentId === this.convertibleInfoId);

        if (settings) {
          request.environments.find(x => x.environmentId === item.environmentId)
            .instrumentOverrides.find(x => x.instrumentId === this.convertibleInfoId).settings = item.instrument;
        }
        else {
          request.environments.find(x => x.environmentId === item.environmentId).instrumentOverrides.push({
            settings: item.instrument,
            instrumentId: this.convertibleInfoId
          } as IInstrumentCrEstimateSettingsDto);
        }
      }
      else {
        const settings = request.environments.find(x => x.environmentId === item.environmentId)
        .instrumentOverrides.find(x => x.instrumentId === this.convertibleInfoId);

        if (settings) {
          request.environments.find(x => x.environmentId === item.environmentId)
            .instrumentOverrides = request.environments.find(x => x.environmentId === item.environmentId)
            .instrumentOverrides.filter(x => x.instrumentId !== this.convertibleInfoId);
        }
      }
    });

    request.lwsIdentifier = this.lwsIdentifier;

    return request;
  }
}
