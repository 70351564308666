import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IAccretionSectionSettings, IBaseTermsSettings } from '@lv-convertible-bond/models';

@Component({
  selector: 'lv-accreation-settings',
  templateUrl: './lv-accreation-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvAccreationSettingsComponent implements OnInit {

  @Input() systemDefaults: IBaseTermsSettings<IAccretionSectionSettings>;
  @Input() myDefaults: IBaseTermsSettings<IAccretionSectionSettings>;

  constructor() { }

  @HostBinding('class.lv-accretion-settings')
  get isLvAccretionSettingsComponent() {
    return true;
  }

  ngOnInit() {
  }

 /**
  * Retrieves element's id
  * @param element reference to given element
  * @param id element's id
  * @returns falg- true if element with given id is found
  */
  getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }

}
