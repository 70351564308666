
import moment from 'moment';

export class LvDateUtil {

 /**
  * monthDiff checks for difference in months betweene given dates
  * @param to is this.dateTo
  * @param from is this.dateFrom
  * @returns difference in months between these dates
  */
    public static monthDiff(to: any, from: any): any {
      const toDate = new Date(to);
      const fromDate = new Date(from);

      const diff = (toDate.getFullYear() * 12 + toDate.getMonth()) - (fromDate.getFullYear() * 12 + fromDate.getMonth());
      return diff;
    }

/**
 * isValidDate method checks if sent date is less than minDate or null
 * value of minDate has value of min date sent from backend
 * it prevents sending request on backend if date does not fullfill conditions
 * @param value date value
 * @returns flag that describes if provided date is valid
 */
  public static isValidDate(value: any): boolean {
   const minDate = new Date('1/1/1753');
   if (value === null) {
      return false;
    }
   if (value < minDate) {
      return false;
    }
  }

  public static parse(value?: string | Date): any {
    if (!value) {
      return null;
    }

    if (value instanceof Date) {
      return value;
    }

    const momentUtc = moment(value, 'YYYY-MM-DD[T00:00:00Z]');
    return new Date(momentUtc.year(), momentUtc.month(), momentUtc.date());
  }

  public static parseDateTime(value?: string | Date): any {
    if (!value) {
      return null;
    }

    if (value instanceof Date) {
      return value;
    }

    const momentUtc = moment.utc(value);
    return new Date(momentUtc.year(), momentUtc.month(), momentUtc.date(), momentUtc.hour(), momentUtc.minute(), momentUtc.second());
  }

  public static toISOString(value?: string | Date): any {
    if (!value) {
      return null;
    }

    if (!(value instanceof Date)) {
      return value;
    }

    return moment(value).format('YYYY-MM-DD[T00:00:00Z]');
  }

  public static toISODateTimeString(value?: string | Date): any {
    if (!value) {
      return null;
    }

    if (!(value instanceof Date)) {
      return value;
    }

    return moment(value).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
  }

  public static calculateYearsFromDate(date: Date, firstSettlementDate: Date): number {
    if (date) {
      const mDate = moment.utc(date);
      const mFirst = moment.utc(firstSettlementDate);
      const duration = moment.duration(mDate.diff(mFirst));
      return parseFloat(duration.asYears().toFixed(2));
    } else {
      return null;
    }
  }
}
