import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Services
import { AuthGuard } from './common/guards/auth/auth.guard';
import { BrowserSupportGuard } from './common/guards/browser-support/browser-support.guard';
import { PermissionGuard } from 'src/app/common/guards/permission/permission-guard';

// External Components
import { PageNotFoundComponent } from './views/external/page-not-found/page-not-found.component';
import { NotSupportedBrowserComponent } from './views/external/not-supported-browser/not-supported-browser.component';

// Internal Components
import { InternalComponent } from './views/internal/internal.component';
import { WorkspacePanelComponent } from './views/internal/workspace-panel/workspace-panel.component';
import { WorkspaceContentComponent } from './views/internal/workspace-panel/workspace-content/workspace-content.component';
import { SettingsComponent } from './views/internal/settings/settings/settings.component';
// tslint:disable-next-line: max-line-length
import { ExternalInstrumentViewComponent } from './views/internal/external-instrument-view/external-instrument-view/external-instrument-view.component';
import { AnotherInstanceComponent } from './views/external/another-instance/another-instance.component';

const routes: Routes = [{
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  }, {
    path: 'dashboard',
    // loadChildren: './views/internal/internal.module#InternalModule',
    // canLoad: [BrowserSupportGuard, AuthGuard]
    component: InternalComponent,
    canActivate: [BrowserSupportGuard, AuthGuard],
    children: [{
      path: '',
      pathMatch: 'full',
      redirectTo: 'workspaces'
    }, {
      path: 'workspaces',
      component: WorkspacePanelComponent,
      children: [{
        path: ':id',
        component: WorkspaceContentComponent,
        data: {
          shouldReuse: true,
          routeId: 'workspace-content'
        }
      }],
      canActivate: [PermissionGuard]
    }, {
      path: 'settings',
      component: SettingsComponent,
      canActivate: [PermissionGuard]
    },
    {
      path: 'external-instrument/:id/:instSource',
      component: ExternalInstrumentViewComponent,
      canActivate: [PermissionGuard]
    },
    {
      path: 'external-instrument/:id',
      component: ExternalInstrumentViewComponent,
      canActivate: [PermissionGuard]
    }]
  }, {
    path: 'not-supported-browser',
    component: NotSupportedBrowserComponent
  },
  {
    path: 'another-instance',
    component: AnotherInstanceComponent
  }, {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [BrowserSupportGuard] // has to be last route, otherwise it would mask below routes
  }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthGuard, PermissionGuard]
})

export class AppRoutingModule {
}
