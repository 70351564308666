<div class="lv-modal-mask" [@mask]
  *ngIf="isOpened"
  [ngClass]="maskClass">
</div>
<div class="lv-modal" [@modal]
  *ngIf="isOpened"
  [ngClass]="modalClass">
  <div class="lv-modal-table">
    <div class="lv-modal-table-cell">
      <div class="lv-modal-table-cell-content">
        <div class="lv-modal-container">
            <div class="lv-modal-title">
              <ng-container *ngIf="sessionExpiredError">
                Session expired
              </ng-container>
              <ng-container *ngIf="!sessionExpiredError">
                Server connection problem
              </ng-container>
            </div>
            <div class="lv-modal-content">
              <ng-container *ngIf="sessionExpiredError">
                <div>Your session has expired.</div>
                <div>Please, login again.</div>
              </ng-container>
              <ng-container *ngIf="!sessionExpiredError">
                <div>There was a connection problem.</div>
                <div>Please, try to reconnect.</div>
              </ng-container>
            </div>
            <div class="lv-modal-footer">
              <button
                kendoButton
                [primary]="true"
                [disabled]="isLoading"
                (click)="onLogInAgain()">
                Log in again
              </button>
              <button *ngIf="!sessionExpiredError"
                kendoButton
                [primary]="true"
                [disabled]="isLoading"
                (click)="onRetry()">
                Retry
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>