import { IMarketData } from './../../models/market-data/market-data';
import { InterestRateSource, InterestRateSourceDescription, YieldCurveSource } from './../../models/market-data/interest-rates/interest-rates-enum';
import { ITermStructureItem } from './../../models/market-data/yield-curve/yield-curve-term-structure';
import { ExcelFieldDataTypes } from '@lv-excel/models/enums/excel-field-data-types';
import { ISystemYieldCurveInfo } from '@lv-analytics/models/market-data/yield-curve/system-yield-curve-info';

/**
 * Maps interest rates.
 * @param fieldDictionary  Fields dictionary.
 * @param model IMarketData object.
 * @param instrumentTermsStructure List of ITermStructureItem object.
 * @param underlyingTermsStructure List of ITermStructureItem object.
 * @param systemYieldCurves List of ISystemYieldCurveInfo object.
 */
export function mapInterestRates(
  fieldDictionary: any,
  model: IMarketData,
  instrumentTermsStructure: ITermStructureItem[],
  underlyingTermsStructure: ITermStructureItem[],
  systemYieldCurves: ISystemYieldCurveInfo[]): void {

  fieldDictionary['IR_BASE_DATE_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_CURVE_TYPE_INST'] = {
    mapFn: (value: any) => {
      model.interestRates.instrumentInterestRateSource = value;
    },
    reverseMapFn: () => {
      return InterestRateSourceDescription[model.interestRates.instrumentInterestRateSource];
    },
    type: ExcelFieldDataTypes.Enum,
    enumType: InterestRateSourceDescription
  };

  fieldDictionary['IR_CURVE_INTER_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_DATE_ADJ_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_SETTL_DAYS_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_CASH_RATES_BASIS_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_SWAP_RATES_BASIS_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_SWAP_RATES_FREQ_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_ZERO_FLAT_BASIS_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_ZERO_FLAT_FREQ_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_ASW_FREQ_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_ASW_BASIS_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_ASW_FIXING_RATE_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_BASE_DATE_UND_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_CURVE_TYPE_UND_INST'] = {
    mapFn: (value: any) => {
      model.interestRates.underlyingInterestRateSource = value;
    },
    reverseMapFn: () => {
      return InterestRateSourceDescription[model.interestRates.underlyingInterestRateSource];
    },
    type: ExcelFieldDataTypes.Enum,
    enumType: InterestRateSourceDescription
  };

  fieldDictionary['IR_CURVE_INTER_UND_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_DATE_ADJ_UND_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_SETTL_DAYS_UND_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_CASH_RATES_BASIS_UND_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_SWAP_RATES_BASIS_UND_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_SWAP_RATES_FREQ_UND_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_ZERO_FLAT_BASIS_UND_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_ZERO_FLAT_FREQ_UND_INST'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_BASE_DATE_DVD'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_CURVE_TYPE_DVD'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_CURVE_INTER_DVD'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_DATE_ADJ_DVD'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_SETTL_DAYS_DVD'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_CASH_RATES_BASIS_DVD'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_SWAP_RATES_BASIS_DVD'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_SWAP_RATES_FREQ_DVD'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_ZERO_FLAT_BASIS_DVD'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_ZERO_FLAT_FREQ_DVD'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_FLAT_INST'] = {
    mapFn: (value: any) => {
      model.interestRates.instrumentInterestRateSource = InterestRateSource.Flat;
      model.interestRates.instrumentFlatRate = value;
    },
    reverseMapFn: () => {
      return model.interestRates.instrumentFlatRate;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_FLAT_UND_INST'] = {
    mapFn: (value: any) => {
      model.interestRates.underlyingInterestRateSource = InterestRateSource.Flat;
      model.interestRates.underlyingFlatRate = value;
    },
    reverseMapFn: () => {
      return model.interestRates.underlyingFlatRate;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_FLAT_DVD'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_CURVE_RANGE_INST_SPECIAL'] = {
    mapFn: (value: any) => {
      model.interestRates.instrumentInterestRateSource = InterestRateSource.YieldCurve;
      model.interestRates.instrumentYieldCurveSource = YieldCurveSource.Custom;
      model.interestRates.yieldCurveId = -1;
    },
    reverseMapFn: () => {
      return mapInterestRatesTermStructure(model.interestRates.instrumentTermsStructure);
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['IR_CURVE_RANGE_INST'] = {
    mapFn: (value: any) => {
      model.interestRates.instrumentInterestRateSource = InterestRateSource.YieldCurve;
      model.interestRates.instrumentYieldCurveSource = YieldCurveSource.Custom;
      model.interestRates.yieldCurveId = -1;
    },
    reverseMapFn: () => {
      return mapInterestRatesTermStructure(model.interestRates.instrumentTermsStructure);
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['IR_CURVE_RANGE_UND_INST_SPECIAL'] = {
    mapFn: (value: any) => {
      model.interestRates.underlyingInterestRateSource = InterestRateSource.YieldCurve;
      model.interestRates.underlyingYieldCurveSource = YieldCurveSource.Custom;
      model.interestRates.underlyingYieldCurveId = -1;
    },
    reverseMapFn: () => {
      return mapInterestRatesTermStructure(model.interestRates.underlyingTermsStructure);
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_CURVE_RANGE_UND_INST'] = {
    mapFn: (value: any) => {
      model.interestRates.underlyingInterestRateSource = InterestRateSource.YieldCurve;
      model.interestRates.underlyingYieldCurveSource = YieldCurveSource.Custom;
      model.interestRates.underlyingYieldCurveId = -1;
    },
    reverseMapFn: () => {
      return mapInterestRatesTermStructure(model.interestRates.underlyingTermsStructure);
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_CURVE_RANGE_DVD'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {

    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['IR_CURVE_RANGE_T_INST'] = {
    mapFn: (value: any) => {
      model.interestRates.instrumentInterestRateSource = InterestRateSource.YieldCurve;
      model.interestRates.instrumentYieldCurveSource = YieldCurveSource.Custom;
      model.interestRates.yieldCurveId = -1;
    },
    reverseMapFn: () => {
      if (!!model.interestRates.instrumentTermsStructure) {
        return mapInterestRatesTermStructure(model.interestRates.instrumentTermsStructure);
      }
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['IR_CURVE_RANGE_R_INST'] = {
    mapFn: (value: any) => {
      model.interestRates.instrumentInterestRateSource = InterestRateSource.YieldCurve;
      model.interestRates.instrumentYieldCurveSource = YieldCurveSource.Custom;
      model.interestRates.yieldCurveId = -1;
    },
    reverseMapFn: () => {
      if (!!model.interestRates.instrumentTermsStructure) {
        return mapInterestRatesTermStructure(model.interestRates.instrumentTermsStructure);
      }
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['IR_CURVE_RANGE_DS_INST'] = {
    mapFn: (value: any) => {
      model.interestRates.instrumentInterestRateSource = InterestRateSource.YieldCurve;
      model.interestRates.instrumentYieldCurveSource = YieldCurveSource.Custom;
      model.interestRates.yieldCurveId = -1;
    },
    reverseMapFn: () => {
      if (!!model.interestRates.instrumentTermsStructure) {
        return mapInterestRatesTermStructure(model.interestRates.instrumentTermsStructure);
      }
    },
    type: ExcelFieldDataTypes.Schedule
  };
  fieldDictionary['IR_CURVE_RANGE_T_UND_INST'] = {
    mapFn: (value: any) => {
      model.interestRates.underlyingInterestRateSource = InterestRateSource.YieldCurve;
      model.interestRates.underlyingYieldCurveSource = YieldCurveSource.Custom;
      model.interestRates.underlyingYieldCurveId = -1;
    },
    reverseMapFn: () => {
      if (!!model.interestRates.underlyingTermsStructure) {
        return mapInterestRatesTermStructure(model.interestRates.underlyingTermsStructure);
      }
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['IR_CURVE_RANGE_R_UND_INST'] = {
    mapFn: (value: any) => {
      model.interestRates.underlyingInterestRateSource = InterestRateSource.YieldCurve;
      model.interestRates.underlyingYieldCurveSource = YieldCurveSource.Custom;
      model.interestRates.underlyingYieldCurveId = -1;
    },
    reverseMapFn: () => {
      if (!!model.interestRates.underlyingTermsStructure) {
        return mapInterestRatesTermStructure(model.interestRates.underlyingTermsStructure);
      }
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['IR_CURVE_RANGE_DS_UND_INST'] = {
    mapFn: (value: any) => {
      model.interestRates.underlyingInterestRateSource = InterestRateSource.YieldCurve;
      model.interestRates.underlyingYieldCurveSource = YieldCurveSource.Custom;
      model.interestRates.underlyingYieldCurveId = -1;
    },
    reverseMapFn: () => {
      if (!!model.interestRates.underlyingTermsStructure) {
        return mapInterestRatesTermStructure(model.interestRates.underlyingTermsStructure);
      }
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['IR_YC_INST'] = {
    mapFn: (value: any) => {

      if (!value) {
        return;
      }

      model.interestRates.instrumentInterestRateSource = InterestRateSource.YieldCurve;

      if (value.toLowerCase() === 'custom') {
        model.interestRates.instrumentYieldCurveSource = YieldCurveSource.Custom;
        model.interestRates.yieldCurveId = -1;
      }
      else {
        const systemCurve = systemYieldCurves.find(yc => yc.leversysId === value);
        if (systemCurve) {
          model.interestRates.yieldCurveId = systemCurve.id;
        }
      }
    },
    reverseMapFn: () => {
      if (!(model.interestRates.instrumentInterestRateSource === InterestRateSource.YieldCurve)) {
        return null;
      }

      if ( model.interestRates.yieldCurveId === -1) {
        return 'Custom';
      }
      else {
        const systemCurve = systemYieldCurves.find(yc => yc.id ===  model.interestRates.yieldCurveId);
        return systemCurve.leversysId;
      }
    },
    type: ExcelFieldDataTypes.String
  };

  fieldDictionary['IR_YC_UND_INST'] = {
    mapFn: (value: any) => {

      if (!value) {
        return;
      }

      model.interestRates.underlyingInterestRateSource = InterestRateSource.YieldCurve;

      if (value.toLowerCase() === 'custom') {
        model.interestRates.underlyingYieldCurveSource = YieldCurveSource.Custom;
        model.interestRates.underlyingYieldCurveId = -1;
      }
      else {
        const systemCurve = systemYieldCurves.find(yc => yc.leversysId === value);
        if (systemCurve) {
          model.interestRates.underlyingYieldCurveId = systemCurve.id;
        }
      }
    },
    reverseMapFn: () => {
      if (!(model.interestRates.underlyingInterestRateSource === InterestRateSource.YieldCurve)) {
        return null;
      }

      if ( model.interestRates.underlyingYieldCurveId === -1) {
        return 'Custom';
      }
      else {
        const systemCurve = systemYieldCurves.find(yc => yc.id ===  model.interestRates.underlyingYieldCurveId);
        return systemCurve.leversysId;
      }
    },
    type: ExcelFieldDataTypes.String
  };
}

/**
 * Maps interest rates terms structure.
 * @param instrumentTermsStructure ITermStructureItem object.
 * @returns List of ITermStructureItem objects.
 */
export function mapInterestRatesTermStructure(instrumentTermsStructure: ITermStructureItem[]) {
  if (!instrumentTermsStructure) {
    return [];
  }

  return instrumentTermsStructure.map(x => {
    return {
      tenor: x.tenor,
      rate: x.rate,
      type: x.type
    };
  });
}
