import { LvErrorType } from './error-type';

export interface ILvError {
  name: string;
  message: string;
  type: LvErrorType;
  displayMessage: string;
}

export class LvError extends Error {

  message: string;
  name: string;
  type: LvErrorType;

  constructor(message: string, name = 'Error', type = LvErrorType.DEFAULT) {
    super(message);

    this.message = message;
    this.name = name;
    this.type = type;
  }
}
