/**
 * Scenario output.
 */
export enum ScenarioOutput {
  TheoreticalResult = 'TheoreticalResult',
  CheapPercentage = 'CheapPercentage',
  Parity = 'Parity',
  ParityPremium = 'ParityPremium',
  BondValue = 'BondValue',
  BondPremium = 'BondPremium',
  YTM = 'YTM',
  YTP = 'YTP',
  ImpliedVolatility = 'ImpliedVolatility',
  ImpliedSpread = 'ImpliedSpread',
  Delta = 'Delta',
  EquitySensitivity = 'EquitySensitivity',
  Gamma = 'Gamma',
  Vega = 'Vega',
  Theta = 'Theta',
  Rho = 'Rho',
  AccruedInterest = 'AccruedInterest',
  EffectiveParityPremium = 'EffectiveParityPremium',
  ForwardUnderlyingLevel = 'ForwardUnderlyingLevel',
  RiskVolatility = 'RiskVolatility'
}

/**
 * Scenario output description.
 */
export enum ScenarioOutputDescription {
  TheoreticalResult = 'Theo Price',
  CheapPercentage = 'Cheap (%)',
  Parity = 'Parity',
  ParityPremium = 'Parity Premium (%)',
  BondValue = 'Bond Value',
  BondPremium = 'Bond Premium',
  YTM = 'YTM',
  YTP = 'YTP',
  ImpliedVolatility = 'Implied Volatility',
  ImpliedSpread = 'Implied Spread',
  Delta = 'Delta',
  EquitySensitivity = 'Equity Sensitivity',
  Gamma = 'Gamma',
  Vega = 'Vega',
  Theta = 'Theta',
  Rho = 'Rho (CB)',
  AccruedInterest = 'Accrued Interest',
  EffectiveParityPremium = 'Effective Parity Premium (%)',
  ForwardUnderlyingLevel = 'Forward Underlying Level',
  RiskVolatility = 'Risk Volatility'
}
