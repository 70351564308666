import * as momentTz from 'moment-timezone';

import { Injectable } from '@angular/core';

import { ITimeZone } from '../../models/time-zone';
import { LvUtil } from '../../util/util';

@Injectable()
export class LvTimeZoneService {

  private _timeZones: ITimeZone[];

  constructor() {
    this._timeZones = this.filterTimeZones()
      .sort(LvUtil.sortBy('utcOffsetInMinutes', false));
  }

  getTimeZones(): ITimeZone[] {
    return this._timeZones;
  }

  /**
   * If saved timezone does not exist, then find original offset
   * and use filtered with that offset
   */
  getTimeZoneKey(timeZone: string): string {
    const offset = momentTz.tz(timeZone).format('Z');
    const zone = this._timeZones.find(x => x.text.indexOf(offset) >= 0);

    return zone.value;
  }

  private filterTimeZones(): ITimeZone[] {
    const zones = momentTz.tz
      .names()
      .map(a => {
        const z = momentTz.tz(a);
        return {
          offset: z.format('Z'),
          zone: a,
          utcOffsetInMinutes: z.utcOffset()
        };
      });

    return [
      this.getZone(zones, '+00:00', 'Greenwich Mean Time'),
      this.getZone(zones, '+01:00', 'Central European Time'),
      this.getZone(zones, '+02:00', 'Eastern European Time'),
      this.getZone(zones, '+03:00', 'East African Time'),
      this.getZone(zones, '+04:00', 'Mauritius Time'),
      this.getZone(zones, '+05:00', 'Pakistan Standard Time'),
      this.getZone(zones, '+06:00', 'Bangladesh Standard Time'),
      this.getZone(zones, '+07:00', 'Christmas Island Time'),
      this.getZone(zones, '+08:00', 'China Standard Time'),
      this.getZone(zones, '+09:00', 'Japan Standard Time'),
      this.getZone(zones, '+10:00', 'East Australian Time'),
      this.getZone(zones, '+11:00', 'Sakhalin Time'),
      this.getZone(zones, '+12:00', 'New Zealand Standard Time'),
      this.getZone(zones, '-11:00', 'Midway Islands Time'),
      this.getZone(zones, '-10:00', 'Hawaii Standard Time'),
      this.getZone(zones, '-09:00', 'Alaska Standard Time'),
      this.getZone(zones, '-08:00', 'Pacific Standard Time'),
      this.getZone(zones, '-07:00', 'Mountain Standard Time'),
      this.getZone(zones, '-06:00', 'Central Standard Time'),
      this.getZone(zones, '-05:00', 'Eastern Standard Time'),
      this.getZone(zones, '-04:00', 'Atlantic Standard Time'),
      this.getZone(zones, '-03:00', 'Argentina Standard Time'),
      this.getZone(zones, '-02:00', 'Azores Time'),
      this.getZone(zones, '-01:00', 'West Africa Time')];
  }



  private getZone(timeZones: any[], offset: string, displayText: string, zone: string = ''): ITimeZone {
    const tz = timeZones.find(x => x.offset === offset && x.zone.indexOf(zone) >= 0);

    return {
      text: `GMT ${offset} - ${displayText}`,
      value: tz.zone,
      utcOffsetInMinutes: tz.utcOffsetInMinutes
    };
  }
}
