/**
 * Period frequency types.
 */
export enum PeriodFrequency {
  None = 'None',
  Annual = 'Annual',
  SemiAnnual = 'SemiAnnual',
  Quarterly = 'Quarterly',
  Bimonthly = 'Bimonthly',
  Monthly = 'Monthly'
}

/**
 * Period frequency description.
 */
export enum PeriodFrequencyDescription {
  None = 'None',
  Annual = 'Annual',
  SemiAnnual = 'Semi Annual',
  Quarterly = 'Quarterly',
  Bimonthly = 'Bimonthly',
  Monthly = 'Monthly'
}
