import { GridComponent, CellClickEvent } from '@progress/kendo-angular-grid';

export class LvGridUtil {
  public static endEdit(grid: GridComponent, rowIndex: number) {
    (grid as any).editService.endEdit(rowIndex);
  }

  public static save(grid: GridComponent, rowIndex: number) {
    (grid as any).editService.save(rowIndex);
  }

  public static clickedOutsideHandler(grid: GridComponent, target: EventTarget, rowIndex: number): boolean {
    const gridEl = grid.wrapper.nativeElement as HTMLElement;
    if (!gridEl.contains(target as HTMLElement)) {
      LvGridUtil.endEdit(grid, rowIndex);
      return true;
    }

    return false;
  }

  public static clickedOutsideEditedRow(grid: GridComponent, target: EventTarget): boolean {
    const gridEl = grid.wrapper.nativeElement as HTMLElement;
    const editableRow = gridEl.querySelector('tr.k-grid-edit-row');

    if (editableRow && !editableRow.contains(target as HTMLElement)) {
      return true;
    }

    return false;
  }

  public static closeEditor(grid: GridComponent): boolean {
    if (grid.isEditing() || grid.isEditingCell()) {
      let currentRowIndex: number;
      if (grid.activeRow) {
        currentRowIndex = grid.activeRow.dataRowIndex;
      }

      if (grid.activeCell) {
        currentRowIndex = grid.activeCell.dataRowIndex;
      }

      if (currentRowIndex !== -1) {
        LvGridUtil.endEdit(grid, currentRowIndex);
        return true;
      }
    }

    return false;
  }

  public static openCellEditor(grid: GridComponent, evt: CellClickEvent, removeValidation: boolean = false) {
    const gridEl = (grid.wrapper.nativeElement as HTMLElement);
    const cellEl = gridEl.querySelector(`.k-grid-edit-row > td:nth-child(${evt.columnIndex + 1})`);

    if (!cellEl) {
      return;
    }

    const editorEl = cellEl.children[0];

    // https://jira.leversys.local:8443/browse/SYSTEM-3353
    // Clicking on empty space at the position (0, 0) on advanced grid
    // returns error because editorEl in that case is null
    if (!editorEl) {
      return;
    }

    if (removeValidation) {
      editorEl.classList.remove('ng-touched', 'ng-invalid');
    }

    if (editorEl.nodeName === 'KENDO-DROPDOWNLIST') {
      (editorEl as HTMLElement).click();
    }

    if (editorEl.nodeName === 'KENDO-NUMERICTEXTBOX') {
      const input = editorEl.querySelector('input');
      input.focus();
    }
  }
}
