<lv-flex-box
  class="lv-new-issue-settings-dialog"
  data-cy="DM-4433">
  <lv-loader *ngIf="isLoading"></lv-loader>
  <lv-flex-box
    class="lv-new-issue-settings-dialog-content">
    <div class="lv-label-field lv-label lv-label--title">
        System Defaults
    </div>
    <lv-flex-box
      class="lv-new-issue-settings-dialog-radio-buttons"
      direction="row"
      data-cy="DM-1153">
      <div class="lv-input-field lv-field lv-field--radio">
        <input class="k-radio"
          type="radio"
          [attr.id]="systemDefaultsUseAssumedRadioButtonId"
          name="systemDefaultNewIssueSetingsAssumptions"
          value="assumed"
          [(ngModel)]="newIsssueSettings.systemDefaults.newIssueSetingsAssumptions">
        <label class="k-radio-label"
          [attr.for]="systemDefaultsUseAssumedRadioButtonId"
          lvId="lv-new-issue-settings-system-defaults-use-best">
            Use Assumed
        </label>
      </div>
      <div class="lv-input-field lv-field lv-field--radio">
        <input class="k-radio"
          type="radio"
          [attr.id]="systemDefaultsUseBestRadioButtonId"
          name="systemDefaultNewIssueSetingsAssumptions"
          value="best"
          [(ngModel)]="newIsssueSettings.systemDefaults.newIssueSetingsAssumptions">
        <label class="k-radio-label"
          [attr.for]="systemDefaultsUseBestRadioButtonId"
          lvId="lv-new-issue-settings-system-defaults-use-best">
            Use Best
        </label>
      </div>
      <div class="lv-input-field lv-field lv-field--radio">
        <input class="k-radio"
          type="radio"
          [attr.id]="systemDefaultsUseMidRadioButtonId"
          name="systemDefaultNewIssueSetingsAssumptions"
          value="mid"
          [(ngModel)]="newIsssueSettings.systemDefaults.newIssueSetingsAssumptions">
        <label class="k-radio-label"
          [attr.for]="systemDefaultsUseMidRadioButtonId"
          lvId="lv-new-issue-settings-system-defaults-use-mid">
            Use Mid
        </label>
      </div>
      <div class="lv-input-field lv-field lv-field--radio">
        <input class="k-radio"
          type="radio"
          [attr.id]="systemDefaultsUseWorstRadioButtonId"
          name="systemDefaultNewIssueSetingsAssumptions"
          value="worst"
          [(ngModel)]="newIsssueSettings.systemDefaults.newIssueSetingsAssumptions">
        <label class="k-radio-label"
          [attr.for]="systemDefaultsUseWorstRadioButtonId"
          lvId="lv-new-issue-settings-system-defaults-use-worst">
            Use Worst
        </label>
      </div>
    </lv-flex-box>
    <div class="lv-label-field lv-label lv-label--title">
      Environment Defaults
    </div>
  </lv-flex-box>

  <kendo-tabstrip class="lv-pricing-new-issue-settings-dialog-tabstrip" #tabstrip
    [keepTabContent]="true">

    <ng-container *ngIf="newIsssueSettings.environments.length">
      <ng-container *ngFor="let tab of newIsssueSettings.environments">
        <kendo-tabstrip-tab [title]="tab.environmentName"
          [selected]="tab.environmentName === 'My'">
          <ng-template kendoTabContent>
            <lv-flex-box>
              <div class="lv-field lv-field lv-field--checkbox" data-cy="DM-1136">
                <label class="lv-new-issue-settings-environment-checkbox-label">
                  Override System Defaults
                </label>
                <input name="overrideSystemDefaultsMyEnvironment"
                  [attr.id]="getCheckBoxIdForEnvironment(tab.environmentName)"
                  class="k-checkbox"
                  type="checkbox"
                  [(ngModel)]="tab.overrideSystemDefaults"/>
                <label class="k-checkbox-label"
                  lvId="new-issue-settings-override-my-environment"
                  [attr.for]="getCheckBoxIdForEnvironment(tab.environmentName)">
                </label>
              </div>
              <lv-flex-box
                class="lv-new-issue-settings-dialog-radio-buttons"
                direction="row"
                data-cy="DM-1153">
                <div class="lv-input-field lv-field lv-field--radio">
                  <input class="k-radio"
                    type="radio"
                    [disabled]="!tab.overrideSystemDefaults"
                    [attr.id]="getRadioButtonIdForEnvironmentAssumed(tab.environmentName)"
                    [name]="getRadioButtonNameForEnvironment(tab.environmentName)"
                    value="assumed"
                    [(ngModel)]="tab.newIssueSetingsAssumptions">
                  <label class="k-radio-label"
                    [attr.for]="getRadioButtonIdForEnvironmentAssumed(tab.environmentName)"
                    lvId="lv-new-issue-settings-my-environment-use-assumed">
                      Use Assumed
                  </label>
                </div>
                <div class="lv-input-field lv-field lv-field--radio">
                  <input class="k-radio"
                    type="radio"
                    [disabled]="!tab.overrideSystemDefaults"
                    [attr.id]="getRadioButtonIdForEnvironmentBest(tab.environmentName)"
                    [name]="getRadioButtonNameForEnvironment(tab.environmentName)"
                    value="best"
                    [(ngModel)]="tab.newIssueSetingsAssumptions">
                  <label class="k-radio-label"
                    [attr.for]="getRadioButtonIdForEnvironmentBest(tab.environmentName)"
                    lvId="lv-new-issue-settings-my-environment-use-best">
                      Use Best
                  </label>
                </div>
                <div class="lv-input-field lv-field lv-field--radio">
                  <input class="k-radio"
                    type="radio"
                    [disabled]="!tab.overrideSystemDefaults"
                    [attr.id]="getRadioButtonIdForEnvironmentMid(tab.environmentName)"
                    [name]="getRadioButtonNameForEnvironment(tab.environmentName)"
                    value="mid"
                    [(ngModel)]="tab.newIssueSetingsAssumptions">
                  <label class="k-radio-label"
                    [attr.for]="getRadioButtonIdForEnvironmentMid(tab.environmentName)"
                    lvId="lv-new-issue-settings-my-environment-use-mid">
                      Use Mid
                  </label>
                </div>
                <div class="lv-input-field lv-field lv-field--radio">
                  <input class="k-radio"
                    type="radio"
                    [disabled]="!tab.overrideSystemDefaults"
                    [attr.id]="getRadioButtonIdForEnvironmentWorst(tab.environmentName)"
                    [name]="getRadioButtonNameForEnvironment(tab.environmentName)"
                    value="worst"
                    [(ngModel)]="tab.newIssueSetingsAssumptions">
                  <label class="k-radio-label"
                    [attr.for]="getRadioButtonIdForEnvironmentWorst(tab.environmentName)"
                    lvId="lv-new-issue-settings-my-environment-use-worst">
                      Use Worst
                  </label>
                </div>
              </lv-flex-box>
            </lv-flex-box>
          </ng-template>
        </kendo-tabstrip-tab>
      </ng-container>
    </ng-container>

  </kendo-tabstrip>

  <lv-flex-box class="lv-pricing-new-issue-settings-dialog-footer" direction="row">
    <button kendoButton
      [primary]="true"
      lvId="convertibleValuationOutputsCancel"
      (click)="onCancel()">
        Cancel
    </button>
    <button kendoButton
      [primary]="true"
      lvId="convertibleValuationOutputsSave"
      (click)="onSave()">
        Save
    </button>
  </lv-flex-box>
</lv-flex-box>
