import { Injectable } from '@angular/core';
import { DateExtensions } from '@lv-core-ui/util';

import { IVolatility } from './volatility';
import { IVolatilitySurface } from './volatility-surface';
import { IVolatilityTermStructureScheduleItem } from './volatility-term-structure-schedule-item';

/**
 * Volatility mapper.
 */
@Injectable()
export class VolatilityMapper {

  /**
   * Maps volatility to API.
   * @param settings IVolatility object.
   * @returns IVolatility object.
   */
  mapVolatilityToApi(settings: IVolatility): IVolatility {
    const mapped = {
      ...settings,
      volatilityTermStructureSchedule: this.mapVolatilityTermStructureToApi(settings.volatilityTermStructureSchedule),
      volatilitySurface: this.mapVolatilitySurfaceToApi(settings.volatilitySurface)
    } as IVolatility;

    return mapped;
  }

  /**
   * Maps volatility to API.
   * @param settings IVolatility object.
   * @returns IVolatility object.
   */
  mapVolatilityToUi(settings: IVolatility): IVolatility {
    const mapped = {
      ...settings,
      volatilityTermStructureSchedule: this.mapVolatilityTermStructureToUi(settings.volatilityTermStructureSchedule),
      volatilitySurface: this.mapVolatilitySurfaceToUi(settings.volatilitySurface)
    } as IVolatility;

    return mapped;
  }

  /**
   * Maps volatility surface to API.
   * @param settings IVolatilitySurface object.
   * @returns IVolatilitySurface object.
   */
  private mapVolatilitySurfaceToApi(model: IVolatilitySurface): IVolatilitySurface {
    const mapped = {
      ...model,
      updateDate: DateExtensions.getTimeWithOffset(model.updateDate as Date)
    } as IVolatilitySurface;

    return mapped;
  }

  /**
   * Maps volatility surface to UI.
   * @param settings IVolatilitySurface object.
   * @returns IVolatilitySurface object.
   */
  private mapVolatilitySurfaceToUi(model: IVolatilitySurface): IVolatilitySurface {
    const mapped = {
      ...model,
      updateDate: DateExtensions.getDateWithoutOffset(model.updateDate as number)
    } as IVolatilitySurface;

    return mapped;
  }

  /**
   * Maps volatility term structure to API.
   * @param settings IVolatilityTermStructureScheduleItem object.
   * @returns IVolatilityTermStructureScheduleItem object.
   */
  private mapVolatilityTermStructureToApi(schedule: IVolatilityTermStructureScheduleItem[]): IVolatilityTermStructureScheduleItem[] {
    const scheduleRequest = schedule.map(a => ({
      ...a,
      date: DateExtensions.getTimeWithOffset(a.date as Date)
    }));

    return scheduleRequest;
  }

  /**
   * Maps volatility term structure to UI.
   * @param settings IVolatilityTermStructureScheduleItem object.
   * @returns IVolatilityTermStructureScheduleItem object.
   */
  private mapVolatilityTermStructureToUi(schedule: IVolatilityTermStructureScheduleItem[]): IVolatilityTermStructureScheduleItem[] {
    const scheduleRequest = schedule.map(a => ({
      ...a,
      date: DateExtensions.getDateWithoutOffset(a.date as number)
    }));

    return scheduleRequest;
  }
}
