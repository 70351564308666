import { CurrencyType } from './CurrencyType';
import { SetupStatus } from './Enums (5)';

export class ConversionCustomData {
  nominal: number;
  fixedFx: number;
  startDate: Date;
  endDate: Date;
  conversionPriceCurrency: CurrencyType;
  rebateCurrency: CurrencyType;
  currency: string;
  status: SetupStatus;
}