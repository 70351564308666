
export enum SetupStatus {
	
	FullyCompleted = 'FullyCompleted',
	PartiallyCompleted = 'PartiallyCompleted',
	Failed = 'Failed',
	ManuallyCreated = 'ManuallyCreated',
	NewIssue = 'NewIssue'
}
export enum IndexType {
	
	Equity = 'Equity',
	Volatility = 'Volatility'
}
export enum EquityType {
	
	Equity = 'Equity',
	ETF = 'ETF',
	Fund = 'Fund',
	InternalFund = 'InternalFund',
	DepositaryReceipt = 'DepositaryReceipt',
	Misc = 'Misc'
}