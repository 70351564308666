import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { ICreditModelSettings, CreditModelTypeDescription, EquityModelTypeDescription, StochasticCreditModelTypeDescription,
         StochasticCreditBCType } from '@lv-analytics/models';
import { LvLookupEnum } from '@lv-core-ui/util';

/**
 * Model type section settings component.
 */
@Component({
  selector: 'lv-model-type-section-settings',
  templateUrl: './lv-model-type-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvModelTypeSectionSettingsComponent implements OnInit {

  @Input() settings: ICreditModelSettings;
  @Input() overrideSystemDefaults: boolean;

  numberOfDecimals = '4';
  numberFormat = '#.####';

  creditModelType: LvLookupEnum;
  equityModelType: LvLookupEnum;
  stochasticCreditModelType: LvLookupEnum;
  stochasticCreditBCType: LvLookupEnum;

  constructor() {
    this.creditModelType = new LvLookupEnum(CreditModelTypeDescription);
    this.equityModelType = new LvLookupEnum(EquityModelTypeDescription);
    this.stochasticCreditModelType = new LvLookupEnum(StochasticCreditModelTypeDescription);
    this.stochasticCreditBCType = new LvLookupEnum(StochasticCreditBCType);

  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }
}
