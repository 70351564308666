import { v4 } from 'uuid';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LocalStorage } from '../util/local-storage';
import { LvAuthenticationError, LvConnectionError } from '../models/lv-error/auth';
import { ITokenResponse } from '../models/token-response';

export const defaultHeaders: {
  [header: string]: string | string[];
} =  {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '-1',
  'Content-Type': 'application/json',
  'X-Application-Id': environment.applicationId,
  'X-Request-Id': v4()
};

export const refreshAccessToken = async (httpClient: HttpClient): Promise<ITokenResponse> => {
  let tokenResponse = LocalStorage.getTokenResponse();
  let refreshTokenError = null;

  if (LocalStorage.isAccessTokenExpired()) {
    try {
      tokenResponse = await httpClient.get<ITokenResponse>(`${environment.refreshTokenUrl}`, {
        headers: defaultHeaders,
        params: {
          refreshToken: tokenResponse.refreshToken
        },
        withCredentials: true
      })
      .pipe(
        timeout(environment.requestTimeout)
      )
      .toPromise();
    }
    catch (error) {
      const e: HttpErrorResponse = error;
      refreshTokenError = e.status === 401 ? new LvAuthenticationError() : new LvConnectionError();
    }

    if (!refreshTokenError) {
      LocalStorage.setTokenResponse(tokenResponse);
    }
    else {
      throw refreshTokenError;
    }
  }

  return tokenResponse;
};
