<lv-flex-box class="lv-asset-parameters">
  <div class="lv-label lv-label-field lv-label--title">Asset Parameters</div>
  <form #form="ngForm"> 
    <lv-flex-box direction="row" data-cy="DM-254">
      <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="debtPerShare"
      filter="span:not(lv-xl-label span)">
        <span>Debt per Share</span>
        <lv-xl-label *ngIf="!hasCreditAssetParametersOverridedFromExcel" [field]="'CREDIT_DBT_PER_SHARE'"></lv-xl-label>
        <lv-xl-label *ngIf="hasCreditAssetParametersOverridedFromExcel" [field]="'CREDIT_ISS_ASST_PARM_RANGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field ">
        <lv-numeric-text-box
          name="debrPerShare"
          [(ngModel)]="assetParameters.debrPerShare"
          (didBlur)="onAssetParametersChange()"
          (didKeyDownEnter)="onAssetParametersChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">{{currency}}</span>
      </div>
    </lv-flex-box>  

    <lv-flex-box direction="row" data-cy="DM-255">
      <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="globalRecovery"
      filter="span:not(lv-xl-label span)">
        <span>Global Recovery</span>
        <lv-xl-label *ngIf="!hasCreditAssetParametersOverridedFromExcel" [field]="'CREDIT_GLB_RECVRY'"></lv-xl-label>
        <lv-xl-label *ngIf="hasCreditAssetParametersOverridedFromExcel" [field]="'CREDIT_ISS_ASST_PARM_RANGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field ">
        <lv-numeric-text-box
          name="globalRecovery"
          [(ngModel)]="assetParameters.globalRecovery"
          (didBlur)="onAssetParametersChange()"
          (didKeyDownEnter)="onAssetParametersChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>
  
    <lv-flex-box direction="row" data-cy="DM-256">
      <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="assetVolOverride"
      filter="span:not(lv-xl-label span)">
        <span>Asset Vol Override</span>
        <lv-xl-label *ngIf="!hasCreditAssetParametersOverridedFromExcel" [field]="'CREDIT_ASST_VOL_OVRRIDE'"></lv-xl-label>
        <lv-xl-label *ngIf="hasCreditAssetParametersOverridedFromExcel" [field]="'CREDIT_ISS_ASST_PARM_RANGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="assetVolOverride"
          [(ngModel)]="assetParameters.assetVolOverride"
          (didBlur)="onAssetParametersChange()"
          (didKeyDownEnter)="onAssetParametersChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>
  </form>
</lv-flex-box>

<ng-template #debtPerShare>
  <lv-tooltip dm="dM-254"></lv-tooltip>
</ng-template>
<ng-template #globalRecovery>
  <lv-tooltip dm="dM-255"></lv-tooltip>
</ng-template>
<ng-template #assetVolOverride>
  <lv-tooltip dm="dM-256"></lv-tooltip>
</ng-template>