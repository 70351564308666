import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { v4 } from 'uuid';

import { Subscription, Observable, timer, Subject } from 'rxjs';

import { IApplicationVersion } from '../../models/application-version';

const interval = 1000 * 30;

@Injectable()
export class LvApplicationVersionService implements OnDestroy {

  private _currentHash = '{{HASH_WILL_BE_REPLACED_IN_POST_BUILD_STEP}}';
  private _subscription?: Subscription;
  private _timer: Observable<number>;

  public didApplicationVersionChange: Subject<void>;
  public didThrowError: Subject<any>;

  constructor(
    private _httpClient: HttpClient
  ) {
    this._timer = timer(1000 * 10, interval);
    this.didApplicationVersionChange = new Subject<void>();
    this.didThrowError = new Subject<any>();
  }

  public init(url: string) {
    this._subscription = this._timer.subscribe(
      async () => {
        try {
          const version = await this._httpClient
            .get<IApplicationVersion>(`${url}?cb=${v4()}`)
            .toPromise();

          if (this.hashChanged(this._currentHash, version.hash)) {
            this.didApplicationVersionChange.next();
          }
        }
        catch (error) {
          this.didThrowError.next(error);
        }
    });
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  private hashChanged(previous: string, current: string) {
    if (!previous || current === '{{HASH_WILL_BE_REPLACED_IN_POST_BUILD_STEP}}') {
        return false;
    }

    return previous !== current;
  }
}
