import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { IEnvironmentSettingsItem } from 'src/app/modules/analytics/components/lv-environment-settings/lv-environment-settings.component';
import { IWorkspaceItem } from '../workspace/workspace';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'workspace-reorder-dialog',
  templateUrl: './workspace-reorder-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class WorkspaceReorderDialogComponent implements OnInit {

  @Input() workspaces: IWorkspaceItem[];
  @Output() didSave: EventEmitter<IWorkspaceItem[]>;

  constructor(private dialogRef: DialogRef) {
    this.didSave = new EventEmitter<IWorkspaceItem[]>();
  }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

  onSave() {
    this.dialogRef.close();
    this.didSave.next(this.workspaces);
  }

}
