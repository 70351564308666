import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LvAnalyticsError } from '../../../models/errors';
import { ILvScenarioTemplateResponse } from '../../../models/sensitivity-analysis/scenario-template/scenario-template-reponse';
// tslint:disable-next-line:max-line-length
import { ILvScenarioTemplateUpdateRequest, ILvScenarioTemplateCreateRequest } from '../../../models/sensitivity-analysis/scenario-template/scenario-template-request';
import { ILvScenarioTemplate } from '../../../models/sensitivity-analysis/scenario-template/scenario-template';
import { IScenarioTemplateInfoResponse } from '../../../models/sensitivity-analysis/scenario-template/scenario-template-info-response';
import { LvScenarioTemplateMapper } from '../../../models/sensitivity-analysis/scenario-template/scenario-template-mapper';
import { HttpClientBase } from '@lv-core-ui/api';

/**
 * Scenario template service.
 */
@Injectable()
export class ScenarioTemplateService extends HttpClientBase {

  constructor(
    http: HttpClient
  ) {
    const resourceUrl = '/analytics/sensitivity/scenarioTemplate';
    super(http, resourceUrl);
  }

  /**
   * Gets system scenario template.
   * @param id Identifier.
   * @returns ILvScenarioTemplateResponse object.
   */
  async getSystemScenarioTemplate(id: number): Promise<ILvScenarioTemplateResponse> {
    try {
      const response = await this.getAsync<ILvScenarioTemplateResponse>(null, `/systemScenarioTemplate/${id}`);
      response.template.id = response.id;

      LvScenarioTemplateMapper.mapScenarioTemplateResponseToUi(response);

      return response;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets custom scenario template.
   * @param id Identifier.
   * @returns ILvScenarioTemplateResponse object.
   */
  async getCustomScenarioTemplate(id: number): Promise<ILvScenarioTemplateResponse> {
    try {
      const response = await this.getAsync<ILvScenarioTemplateResponse>(null, `/customScenarioTemplate/${id}`);
      response.template.id = response.id;

      LvScenarioTemplateMapper.mapScenarioTemplateResponseToUi(response);

      return response;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Creates custom scenario template.
   * @param request ILvScenarioTemplateCreateRequest object.
   * @returns Template ID.
   */
  async createCustomScenarioTemplate(request: ILvScenarioTemplateCreateRequest): Promise<number> {
    try {
      LvScenarioTemplateMapper.mapScenarioTemplateRequestToApi(request);

      const id = await this.postAsync<number>({
        template: request.template
      });

      return id;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Updates custom scenario template.
   * @param request ILvScenarioTemplateUpdateRequest object.
   */
  async updateCustomScenarioTemplate(request: ILvScenarioTemplateUpdateRequest): Promise<void> {
    try {
      LvScenarioTemplateMapper.mapScenarioTemplateRequestToApi(request);

      await this.putAsync<ILvScenarioTemplate>(request);
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Deletes custom scenario template ID.
   * @param templateId Template ID.
   */
  async deleteCustomScenarioTemplate(templateId: number): Promise<void> {
    try {
      await this.deleteAsync(`/${templateId}`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets scenario template info.
   * @returns IScenarioTemplateInfoResponse object.
   */
  async getScenarioTemplateInfos(): Promise<IScenarioTemplateInfoResponse[]> {
    try {
      return await this.getAsync<IScenarioTemplateInfoResponse[]>();
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }
}
