import { ChangeDetectionStrategy, Component, ElementRef, Input, Optional, ViewEncapsulation } from '@angular/core';
import { LvExcelService } from 'src/app/modules/excel/services/excel-service';

@Component({
  selector: 'lv-xl-label',
  templateUrl: './lv-xl-label.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvXlLabelComponent {

  @Input() field: string;

  get isEditable(): boolean {
    const fields = this.field.split(',');
    let result = false;

    fields.forEach(item => {
      if (!result) {
        result = !!this._excelService?.getField(item)?.editable;
      }
    });

    return result;
  }

  get isFieldOverridedFromExcel(): boolean {
    let result = false;

    if (!this.field) {
      return result;
    }

    const fields = this.field.split(',');

    fields.forEach(item => {
      if (!result) {
        result = !!this._excelService?.containsField(item);
      }
    });

    return result;
  }

  constructor(
    @Optional() private _excelService: LvExcelService
  ) {
    this.field = '';
  }

  getXlTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('xl-tooltip-id') === sectionId;
  }
}
