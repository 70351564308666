import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AdjustmentReasonDescription, CarryForwardHistoryItem } from '@lv-convertible-bond/models';
import { LvAdvancedGridColumn, LvAdvancedGridDateColumn, LvAdvancedGridEnumColumn,
  LvAdvancedGridNumericColumn } from '@lv-core-ui/components';
import { constants } from '@lv-core-ui/util';

@Component({
  selector: 'lv-carry-forward-history',
  templateUrl: './lv-carry-forward-history.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCarryForwardHistoryComponent implements OnInit {

  @Input() model: CarryForwardHistoryItem[];
  columns: LvAdvancedGridColumn[];
  editDisabled: boolean;

  constructor(private _changeDetectorRef: ChangeDetectorRef) {
    this.columns = [];
    this.model = [];
    this.editDisabled = true;
  }

  ngOnInit() {
    this.initColumns();
    this._changeDetectorRef.detectChanges();
  }

  private initColumns(): void {
    const effectiveDate = new LvAdvancedGridDateColumn();
    effectiveDate.isOutputColumn = true;
    effectiveDate.title = 'Effect. Date';
    effectiveDate.field = 'effectiveDate';
    effectiveDate.width = 76;
    effectiveDate.editable = false;

    const adjustmentReason = new LvAdvancedGridEnumColumn();
    adjustmentReason.enumDescription = AdjustmentReasonDescription;
    adjustmentReason.isOutputColumn = true;
    adjustmentReason.title = 'Adjustment Reason';
    adjustmentReason.field = 'adjustmentReason';
    adjustmentReason.valuePrimitive = true;
    adjustmentReason.width = 137;
    adjustmentReason.editable = false;

    const carryForwardValue = new LvAdvancedGridNumericColumn();
    carryForwardValue.isOutputColumn = true;
    carryForwardValue.title = 'Carry Forward Value';
    carryForwardValue.field = 'carryForwardValue';
    carryForwardValue.outputFormat = constants.numberFormat.sixDigits;
    carryForwardValue.width = 123;
    carryForwardValue.alignment = 'left';
    carryForwardValue.editable= false;

    this.columns = [effectiveDate, adjustmentReason, carryForwardValue];
  }

}
