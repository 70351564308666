<div class="lv-dialog lv-basic-terms-dialog">
  <div class="lv-dialog-content">
    <lv-multi-select
      [availableItems]="availableItems"
      [selectedItems]="selectedItems"
      valueField="type"
      displayField="label">
    </lv-multi-select>
  </div>
  <div class="lv-dialog-actions">
    <button kendoButton
      [disabled]="isLoading"
      lvId="termsSummaryDialogCancel"
      (click)="onClose()">Cancel</button>
    <button kendoButton
      [primary]="true"
      [disabled]="isLoading"
      lvId="termsSummaryDialogSave"
      (click)="onSave()">Save</button>
  </div>
</div>
