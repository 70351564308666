import { v4 } from 'uuid';

export interface ILvWorkspace {
  id?: string;
  name?: string;
  isSelected?: boolean;
  isDisabled?: boolean;

  isEditable?: boolean;
  isClosable?: boolean;
}

export const getDefaultWorkspace = (config?: ILvWorkspace): ILvWorkspace => {
  const id = v4();
  return Object.assign({
    id: `${id}`,
    name: `${id}`,
    isSelected: false,
    isDisabled: false,
    isEditable: true,
    isClosable: true,
  } as ILvWorkspace, config || {});
};
