import { LvError } from './base';
import { LvErrorType } from './error-type';
import { constants } from './../../util/constants';

export class LvApplicationError extends LvError {
  constructor(message = constants.errors.unexpectedError, name = 'Application') {
    super(message, name, LvErrorType.APPLICATION);
  }
}

export class LvInternalServerError extends LvApplicationError {
  constructor(message = constants.errors.unexpectedError, name = 'Internal Server') {
    super(message, name);
  }
}

export class LvMissingArgumentsError extends LvApplicationError {
  constructor(message = constants.errors.unexpectedError) {
    super(message, 'Missing Arguments');
  }
}
