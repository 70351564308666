  <lv-advanced-grid
    data-cy="DM-981"
    [columns]="columns"
    [records]="scheduleItems"
    [createFormGroup]="createFormGroup"
    [parseFn]="parseFn"
    sortBy="date"
    [showXlLabel]="hasScheduleInExcelOverride"
    [excelFieldAlias]="excelFieldAlias"
    [isFieldFromExcelEnabled]="isFieldFromExcelEnabled"
    lvId="customCouponSchedule"
    class="lv-custom-coupon-schedule">
  </lv-advanced-grid>
