<lv-flex-box class="takeover-protection-settings-column">

  <lv-flex-box 
    data-cy="DM-1592"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="takeoverCompensationTooltipTemplate"
      filter="span">
      <span> Takeover Compensation </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        [disabled]="!overrideDefaults"
        [attr.id]="takeOverCompensationCheckBoxId"
        name="takeoverCompensation"
        [(ngModel)]="settings.settings.useTakeoverProtection"/>
      <label class="k-checkbox-label" [attr.for]="takeOverCompensationCheckBoxId"></label>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1593"
    direction="row">

    <div class="lv-label lv-label-field"
       showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="takeoverPutTooltipTemplate"
      filter="span">
      <span> Takeover Put </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        name="takeoverPut"
        [disabled]="!overrideDefaults"
        [attr.id]="takeoverPutCheckBoxId"
        [(ngModel)]="settings.settings.takeoverPut"/>
      <label class="k-checkbox-label" [attr.for]="takeoverPutCheckBoxId"></label>
    </div>
  </lv-flex-box>
  
  <lv-flex-box 
    data-cy="DM-1594"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="takeoverPutValueTypeTooltipTemplate"
      filter="span">
      <span> Takeover Put Value Type </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="takeoverPutValueType"
        [data]="takeoverPutValueTypeLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 114 }"
        [(ngModel)]="settings.settings.takeoverPutValueType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1595"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="protectionStartDateTooltipTemplate"
      filter="span">
      <span> Protection Start Date </span>
    </div>
    <div class="lv-input-field align-sufix">
      <lv-numeric-text-box
        placeholder="T + days"
        class="lv-input-field"
        [disabled]="!overrideDefaults"
        [(ngModel)]="settings.settings.takeoverCompensationProtectionStartDate"
        [format]="formatZero"
        [decimals]="decimalsZero"
        name="protectionStartDate">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">From First Settlement Date</span>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1596"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="protectionEndDateTooltipTemplate"
      filter="span">
      <span> Protection End Date </span>
    </div>
    <div class="lv-input-field align-sufix">
      <lv-numeric-text-box
        placeholder="T - days"
        class="lv-input-field"
        [(ngModel)]="settings.settings.takeoverCompensationProtectionEndDate"
        [format]="formatZero"
        [decimals]="decimalsZero"
        [disabled]="!overrideDefaults"
        name="protectionEndDate">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">From Maturity Date</span>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1597"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="compensationTypeTooltipTemplate"
      filter="span">
      <span> Compensation Type </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="takeoverCompensationType"
        [data]="takeoverCompensationTypeLookup.items"
        valueField="id"
        textField="text"
        [disabled]="!overrideDefaults"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 217 }"
        [(ngModel)]="settings.settings.takeoverCompensationType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1598"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="offerValueTypeTooltipTemplate"
      filter="span">
      <span> Offer Value Type </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="offerValueType"
        [data]="offerValueTypeLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.ratchetMatrixOfferValueType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1599"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="compensationValueTypeTooltipTemplate"
      filter="span">
      <span> Compensation Value Type </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="compensationValueType"
        [data]="compensationValueTypeLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 188 }"
        [(ngModel)]="settings.settings.ratchetMatrixCompensationValueType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  
  <lv-flex-box 
    data-cy="DM-1600"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="compensationFormulaTooltipTemplate"
      filter="span">
      <span> Compensation Formula </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="premiumCompensationFormulaType"
        [data]="premiumCompensationFormulaTypeLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 405 }"
        [(ngModel)]="settings.settings.premiumCompensationFormulaType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
</lv-flex-box>

<ng-template #takeoverCompensationTooltipTemplate>
  <lv-tooltip dm="dM-1592"></lv-tooltip>
</ng-template>
<ng-template #takeoverPutTooltipTemplate>
  <lv-tooltip dm="dM-1593"></lv-tooltip>
</ng-template>
<ng-template #takeoverPutValueTypeTooltipTemplate>
  <lv-tooltip dm="dM-1594"></lv-tooltip>
</ng-template>
<ng-template #protectionStartDateTooltipTemplate>
  <lv-tooltip dm="dM-1595"></lv-tooltip>
</ng-template>
<ng-template #protectionEndDateTooltipTemplate>
  <lv-tooltip dm="dM-1596"></lv-tooltip>
</ng-template>
<ng-template #compensationTypeTooltipTemplate>
  <lv-tooltip dm="dM-1597"></lv-tooltip>
</ng-template>
<ng-template #offerValueTypeTooltipTemplate>
  <lv-tooltip dm="dM-1598"></lv-tooltip>
</ng-template>
<ng-template #compensationValueTypeTooltipTemplate>
  <lv-tooltip dm="dM-1599"></lv-tooltip>
</ng-template>
<ng-template #compensationFormulaTooltipTemplate>
  <lv-tooltip dm="dM-1600"></lv-tooltip>
</ng-template>
