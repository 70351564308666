import { Directive, TemplateRef } from '@angular/core';

/**
 * System defaults settings directive.
 */
@Directive({
    selector: '[systemDefaultsSettings]'
})

export class LvSystemDefaultsSettingsDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}
