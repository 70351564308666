import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
         ChangeDetectorRef, OnDestroy, ViewChild, ElementRef, Optional } from '@angular/core';
import { LvConvertibleBondTermsPresenter } from '../lv-convertible-bond-terms.presenter';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LvConversionView } from './lv-conversion.view';
import { LvConversionScheduleComponent } from './lv-conversion-schedule/lv-conversion-schedule.component';
import { LvContingentConversionComponent } from './lv-contingent-conversion/lv-contingent-conversion.component';
import { LvExerciseScheduleComponent } from './lv-exercise-schedule/lv-exercise-schedule.component';
import { LvResetConversionComponent } from './lv-reset-conversion/lv-reset-conversion.component';
import { ConvertibleBondTermsSectionEvent, ConvertibleBondTermsEvent } from '@lv-convertible-bond/models';
import { LvExcelService } from '@lv-excel/services';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';

@Component({
  selector: 'lv-conversion',
  templateUrl: './lv-conversion.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LvConversionComponent  implements OnInit, OnDestroy {

  @ViewChild('conversionSchedule') conversionSchedule: LvConversionScheduleComponent;
  @ViewChild('cocoConversion') cocoConversion: LvContingentConversionComponent;
  @ViewChild('exerciseSchedule') exerciseSchedule: LvExerciseScheduleComponent;
  @ViewChild('resetConversion') resetConversion: LvResetConversionComponent;

  public view: LvConversionView;
  public formatZero = 'n0';
  public decimalsZero = '0';
  private _modelSubscription: Subscription[];

  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  get observationPeriodLagLabel(): string {
    return this.isOpenedFromExcel ? 'Observation Period Lag' : 'Observation Period Lag';
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvConvertibleBondTermsPresenter,
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService,
    @Optional() private _excelSvc: LvExcelService) {

    this.view = new LvConversionView(_lvConvertibleBondTermsService);
  }

  ngOnInit() {
    this._modelSubscription = [
      this._presenter.onModelUpdated
        .pipe(
          filter(event =>
            (event.eventId !== ConvertibleBondTermsSectionEvent.ConversionEvent &&
            event.eventId !== ConvertibleBondTermsSectionEvent.MWInitCallsEvent))
        )
        .subscribe(event => {
          if (event.eventId === ConvertibleBondTermsSectionEvent.MWInitConversionEvent) {
            this.view.initMakeWholeData(event.data.fullTerms.conversion);
          }
          else {
            if (event.data) {
              this.view.init(event.data.fullTerms.conversion,
                event.data.fullTerms.coupon,
                event.data.fullTerms.issueAndRedemption,
                this.isOpenedFromExcel);
              this._changeDetectorRef.detectChanges();
            }
          }
        }),
      ];

    if (this._presenter.isModelLoaded()) {
      const fullTerms = this._presenter.getModel().fullTerms;
      this.view.init(fullTerms.conversion,
        fullTerms.coupon,
        fullTerms.issueAndRedemption,
        this.isOpenedFromExcel);

      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Map fields to defaults if Acquisition Shares is checked
   */
  handleAcquisitionSharesChange(): void {
    if (this.view.model.useAcquisitionSharesSettlement) {
      this.view.setAcquisitionDefaults();
    }
    this.onModelChange();
  }

  /**
   * Based on checked status sets value to current date or null
   * @param {boolean} value Is Notice end date checkbox checked
   */
  acquisitionNoticeEndDateCheckboxChanged(value: boolean) {
    this.view.acquisitionNoticeEndDateCheckboxChanged(value);
    this.onModelChange();
  }
  /**
   * If Acquisition notice given is checked
   * Update model fields that are dependant on it
   */
  onAcquisitionNoticeGivenChange() {
    this.view.onAcquisitionNoticeGivenChange();
    this.onModelChange();
  }

  onRebatCurrencyChange() {
    this.view.changeScheduleCurrency();
    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.ConversionEvent,
      data: this.view.model 
    }, false);

    this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.RebateCCYUpdated);
  }

  onConversionPriceCCYChange() {
    this.view.changeScheduleCurrency();
    this.onModelChange();
  }

  onResettableChange() {
    this.view.changeResettable();
    this.onModelChange();
  }

  onContingentConversionChange() {
    this.view.changeContingentConversion();
    this.onModelChange();
  }

  onVariableConversionChange() {
    this.view.changeVariableConversion();
    this.onModelChange();
  }

  onExcerciseScheduleChange() {
    this.view.changeExerciseSchedule();
    this.onModelChange();
  }

  applyScheduleChanges() {
    if (this.conversionSchedule) {
      this.conversionSchedule.applyAdvancedGridChanges();
    }
    if (this.cocoConversion) {
      this.cocoConversion.applyAdvancedGridChanges();
    }
    if (this.exerciseSchedule) {
      this.exerciseSchedule.applyAdvancedGridChanges();
    }
    if (this.resetConversion) {
      this.resetConversion.applyAdvancedGridChanges();
    }
  }

  onDeltaNeutralConversionChange() {
    this.onModelChange();
    this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.DeltaNeutralConversionAmountUpdated);
  }

  onModelChange() {
    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.ConversionEvent,
      data: this.view.model
    });
  }

  ngOnDestroy() {
    this._modelSubscription.forEach(s => s.unsubscribe());
  }

  getConversionTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
}
