import { v4 } from 'uuid';

import { Component, OnInit, EventEmitter, ChangeDetectorRef, ViewEncapsulation, ChangeDetectionStrategy, Output,
  HostListener,
  OnDestroy,
  ViewRef} from '@angular/core';

import { Subject, Subscription } from 'rxjs';

import { DialogRef } from '@progress/kendo-angular-dialog';
import { IPaginatedResult } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { IWatchListPanelDialogEvent, IInstrumentInfo, IWatchList } from '@lv-instrument-monitor/models';
import { WatchListService } from '@lv-instrument-monitor/services';
import { ILvWatchListPanelDialogItem } from '..';

@Component({
  selector: 'lv-watch-list-panel-dialog',
  templateUrl: './lv-watch-list-panel-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvWatchListPanelDialogComponent implements OnInit, OnDestroy {

  @Output() didSave: EventEmitter<IWatchListPanelDialogEvent>;
  @Output() didCancel: EventEmitter<IWatchListPanelDialogEvent>;

  get showWatchlistView(): boolean {
    return this.viewType === 'watchlists';
  }

  viewType: 'watchlists' | 'instruments';

  rbWatchlistView: string;
  rbInstumentView: string;

  isLoading: boolean;

  availableInstrumentInfos: IPaginatedResult<IInstrumentInfo>;

  wlInstruments: ILvWatchListPanelDialogItem;
  iWatchLists: ILvWatchListPanelDialogItem;

  continueEditingVisible: boolean;
  continueEditingItemName: string;
  doContinueEditing: Subject<boolean>;

  private panelEvent: IWatchListPanelDialogEvent;

  private continueEditingSubscription: Subscription;
  private doContinueEditingInternal: Subject<boolean>;
  private internalContinueEditingFired: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialogRef: DialogRef,
    private errorService: LvErrorService,
    private service: WatchListService
  ) {
    this.viewType = 'watchlists';

    this.rbWatchlistView = `watchlist-view-${v4()}`;
    this.rbInstumentView = `instrument-view-${v4()}`;

    this.isLoading = true;

    this.availableInstrumentInfos = {
      records: [],
      totalRecords: 0
    };

    this.panelEvent = {
      updated: [],
      deleted: []
    };

    this.continueEditingVisible = false;
    this.doContinueEditing = new Subject<boolean>();

    this.doContinueEditingInternal = new Subject<boolean>();
    this.internalContinueEditingFired = false;

    this.didSave = new EventEmitter<IWatchListPanelDialogEvent>();
    this.didCancel = new EventEmitter<IWatchListPanelDialogEvent>();
  }

  @HostListener('keydown.control.a', ['$event'])
  onCtrlA(event: KeyboardEvent) {
    event.preventDefault();
    if (this.viewType === 'watchlists') {
      this.wlInstruments.onCtrlA(event);
    }
    else if (this.viewType === 'instruments') {
      this.iWatchLists.onCtrlA(event);
    }
  }

  @HostListener('keydown.control.r', ['$event'])
  onCtrlR(event: KeyboardEvent) {
    event.preventDefault();
    if (this.viewType === 'watchlists') {
      this.wlInstruments.onCtrlR(event);
    }
    else if (this.viewType === 'instruments') {
      this.iWatchLists.onCtrlR(event);
    }
  }

  ngOnInit() {
    this.refresh();
  }

  onViewChange(event: Event) {
    const selectedView = (event.target as any).value;
    const wliCondition = this.viewType === 'watchlists' && this.wlInstruments.isDirty();
    const iwlCondition = this.viewType === 'instruments' && this.iWatchLists.isDirty();
    const changeView = () => {
      this.viewType = selectedView;
      this.changeDetectorRef.detectChanges();
    };

    if (wliCondition || iwlCondition) {
      this.internalContinueEditingFired = true;
      // tslint:disable-next-line:max-line-length
      const itemName = this.viewType === 'watchlists' ? this.wlInstruments.getCurrentRecordEditingItemName() : this.iWatchLists.getCurrentRecordEditingItemName();
      this.continueEditingSubscription = this.showContinueEditingPanel(itemName)
        .subscribe((continueEditing: boolean) => {
          if (continueEditing) {
            this.hideContinueEditingPanel();
          }
          else {
            changeView();
          }

          this.internalContinueEditingFired = false;
        });

      event.preventDefault();
      return false;
    }
    else {
      changeView();
      return true;
    }
  }

  showContinueEditingPanel(name: string): Subject<boolean> {
    this.continueEditingItemName = name;
    this.continueEditingVisible = true;
    this.changeDetectorRef.detectChanges();

    if (this.internalContinueEditingFired) {
      return this.doContinueEditingInternal;
    }
    else {
      return this.doContinueEditing;
    }
  }

  hideContinueEditingPanel() {
    this.continueEditingVisible = false;
    this.changeDetectorRef.detectChanges();
  }

  onContinueEditing(continueEditing: boolean) {
    if (this.internalContinueEditingFired) {
      this.doContinueEditingInternal.next(continueEditing);
    }
    else {
      this.doContinueEditing.next(continueEditing);
    }

    this.continueEditingVisible = false;
    this.changeDetectorRef.detectChanges();
  }

  onCancel() {
    if (!this.isLoading) {
      this.didCancel.next(this.panelEvent);
      this.dialogRef.close();
    }
  }

  async onSave(exit: boolean) {
    try {
      this.setLoadingState(true);

      if (this.viewType === 'watchlists' && this.wlInstruments.isDirty()) {
        await this.wlInstruments.saveItem();
      }
      else if (this.viewType === 'instruments' && this.iWatchLists.isDirty()) {
        await this.iWatchLists.saveItem();
      }

      if (exit) {
        this.didSave.next(this.panelEvent);
        this.dialogRef.close();
      }
    }
    catch (error) {
      this.errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  addDeletedWatchList(wl: IWatchList) {
    this.panelEvent.deleted.push(wl);
  }

  addUpdatedWatchList(wl: IWatchList) {
    this.panelEvent.updated.push(wl);
  }

  setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;

    if (!(this.changeDetectorRef as ViewRef).destroyed) {
      this.changeDetectorRef.detectChanges();
    }
  }

  private async refresh() {
    try {
      this.setLoadingState(true);

      this.availableInstrumentInfos = await this.service.getAvailableUserWatchListInstruments();
    }
    catch (error) {
      this.availableInstrumentInfos = {
        records: [],
        totalRecords: 0
      };

      this.errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  ngOnDestroy() {
    if (this.continueEditingSubscription) {
      this.continueEditingSubscription.unsubscribe();
    }
  }

}
