<kendo-tabstrip
  class="lv-tools-tabstrip"
  #tabStrip
  [keepTabContent]="true">

  <kendo-tabstrip-tab 
    *ngIf="state.deltaNeutralState.isDeltaNeutral"
     title="Delta Neutral"
     cssClass="DM-1216"
     [selected]="true">
    <ng-template kendoTabContent>
      <lv-delta-neutral
        data-cy="DM-1216"
        *ngIf="state.deltaNeutralState.isDeltaNeutral"
        [state]="state.deltaNeutralState">
      </lv-delta-neutral>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab 
    *ngIf="state.differentDeltaExecutionState.isDifferentDeltaExecution" 
    title="Different Delta Execution"
    cssClass="DM-1217">
    <ng-template kendoTabContent>
      <lv-different-delta-execution
      [state]="state.differentDeltaExecutionState"
      data-cy="DM-1217"
      ></lv-different-delta-execution>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    *ngIf="state.breakEvenState.isBreakeven"
    title="Breakeven"
    cssClass="DM-1218">
    <ng-template kendoTabContent>
      <lv-break-even
        [state]="state.breakEvenState"
        data-cy="DM-1218"
        ></lv-break-even>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>