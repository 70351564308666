import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { IPricingEnvironmentSettings } from '@lv-analytics/models/company-and-user-settings/environments-settings';
import { IPricingEnvironmentOrderSettings } from '@lv-analytics/models/company-and-user-settings/environments-settings/setup-settings/pricing-evironment-order-settings';
import { DialogRef } from '@progress/kendo-angular-dialog';

/**
 * Edit system environment settings dialog component.
 */
@Component({
  selector: 'lv-edit-system-environment-settings-dialog',
  templateUrl: './lv-edit-system-environment-settings-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEditSystemEnvironmentSettingsDialogComponent {

  @Input() settings: IPricingEnvironmentSettings[];

  @Output() doEmitSavePricingEnvironmentSaveRequest: EventEmitter<IPricingEnvironmentOrderSettings>;

  applyChangesToExistingInstruments: boolean;
  dragInProcces: boolean;

  constructor(
    private _dialogRef: DialogRef,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.settings = [];
    this.applyChangesToExistingInstruments = false;
    this.dragInProcces = false;
    this.doEmitSavePricingEnvironmentSaveRequest = new EventEmitter<IPricingEnvironmentOrderSettings>();
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-edit-system-environment-settings-dialog')
  get isLvEditSystemEnvironmentSettingsDialogComponent() {
    return true;
  }

  /**
   * Cancel edit environment order
   */
  onCancel() {
    this._dialogRef.close();
  }

  /**
   * Save environment order
   */
  onSave() {
    this.doEmitSavePricingEnvironmentSaveRequest.next({
      applyChangesToExistingInstruments: this.applyChangesToExistingInstruments,
      pricingEnvironmentOrder: this.settings.map(x => x.id)
    } as IPricingEnvironmentOrderSettings);

    this._dialogRef.close();
  }

  /**
   * Event emited on start drag and set flag that describes if drag is in procces to true
   */
  onDragStart() {
    this.dragInProcces = true;
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Event emited on drag end and set flag that describes if drag is in procces to false
   */
  onDragEnd() {
    this.dragInProcces = false;
    this._changeDetectorRef.detectChanges();
  }
}
