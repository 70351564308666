import { ViewChild, ChangeDetectorRef, Directive } from '@angular/core';
import { ChartComponent } from '@progress/kendo-angular-charts';

/**
 * Scenario chart.
 */
@Directive()
// tslint:disable-next-line: directive-class-suffix
export class LvScenarioChart {

  @ViewChild(ChartComponent) chart: ChartComponent;

  isVisible: boolean;

  constructor(
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    this.isVisible = true;
  }

  /**
   * Chart toggle.
   * @param collapsed A flag indicating if chart is collapsed.
   */
  toggle(collapsed: boolean) {
    this.isVisible = !collapsed;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Rerenders chart.
   */
  rerender() {
    if (this.isVisible) {
      this.isVisible = false;
      this.changeDetectorRef.detectChanges();

      setTimeout(() => {
        this.isVisible = true;
        this.changeDetectorRef.detectChanges();
      }, 10);
    }
  }
}
