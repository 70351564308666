import { Injectable } from '@angular/core';
import { IUserAccessRightsData } from '@lv-core-ui/models/user-permissions-and-policies';
import { LvUtil } from '@lv-core-ui/util';

import { LocalStorage } from '../../util/local-storage';

export const ALL = 'ALL';

@Injectable()
export class LvPermissionService {
  constructor() {}

  public hasPermission(moduleName: string, actionName: string): boolean {
    const jwtUser = LocalStorage.getJwtUser();

    if (!jwtUser) {
      return false;
    }

    const perm = jwtUser.Permissions.find(
      x => {
        const modulePermission = x.Module; // toLowerCase();
        const actionPermission = x.Action; // toLowerCase();

        return (modulePermission === moduleName && actionPermission === actionName) ||
        (modulePermission === moduleName && actionPermission === ALL) ||
        (modulePermission === ALL && actionPermission === ALL);
      }
    );

    if (perm) {
      return true;
    }

    const permissions = LvUtil.jsonParse<IUserAccessRightsData>(LocalStorage.getPermissions());
    if (permissions) {
      const result = permissions.lvPolicies.findIndex(
        x => x.statements.findIndex(
          y => y.actions.findIndex(
            z => z.split(':')[0] === moduleName && z.split(':')[1] === actionName
          ) > -1
        ) > -1
      ) > -1;

      return result;
    }

    return false;
  }

  public hasAnyModule(modules: string[]): boolean {
    const jwtUser = LocalStorage.getJwtUser();

    if (!jwtUser) {
      return false;
    }

    const mDict = modules.reduce(
      (acc: any, current: string, index: number) => {
        acc[current.toLowerCase()] = true;
        return acc;
      }, {});

    const anyModule = jwtUser.Permissions
      .filter(a => {
        const mLower = a.Module.toLowerCase();
        return mDict[mLower] || mLower === ALL;
      });

    return anyModule.length > 0;
  }
}
