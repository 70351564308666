import { Component, OnInit, Input, Output, EventEmitter, ViewChild,
   ChangeDetectorRef, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy, Optional, OnChanges } from '@angular/core';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { constants } from '@lv-core-ui/util';
import { LvAdvancedGridComponent, LvAdvancedGridColumn, LvAdvancedGridDateColumn,
         LvAdvancedGridNumericColumn } from '@lv-core-ui/components';
import { LvError, LvNotImplementedError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { MarketDataClipboard } from '@lv-analytics/components';
import { FixingHistoryItem } from '@lv-convertible-bond/models';
import { LvExcelService } from '@lv-excel/services';

@Component({
  selector: 'lv-floating-fixing-history-schedule',
  templateUrl: './lv-floating-fixing-history-schedule.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class LvFloatingFixingHistoryScheduleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() model: FixingHistoryItem[];
  @Output() didFixingHistoryScheduleChange: EventEmitter<FixingHistoryItem[]>;

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  private _modelSubscription: Subscription[];

  columns: LvAdvancedGridColumn[];
  fixingHistoryScheduleItems: FixingHistoryItem[];
  parseFn: any;
  excelFieldAlias = 'CPN_FXNG_SCHED_RANGE';

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAlias)?.editable;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    @Optional() private _excelSvc: LvExcelService) {
      this.initColumns();
      this.parseFn = this.parserFunction.bind(this);

      this.fixingHistoryScheduleItems = [];
      this._modelSubscription = [];
      this.didFixingHistoryScheduleChange = new EventEmitter<FixingHistoryItem[]>();
    }

    ngOnInit() {
      this._modelSubscription = [
          this.advancedGrid.didDataChange.subscribe((records: FixingHistoryItem[]) => this.onScheduleChange(records)),
          this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
      ];

      this.fixingHistoryScheduleItems.splice(0, this.fixingHistoryScheduleItems.length);
      this.fixingHistoryScheduleItems.push(...this.model);

      this._changeDetectorRef.detectChanges();
    }

    ngOnChanges() {
      if (this.hasScheduleInExcelOverride) {
        this.initColumns();
      }
      this.fixingHistoryScheduleItems = this.fixingHistoryScheduleItems.map(a => ({ ...a }));
      this.fixingHistoryScheduleItems.splice(0, this.fixingHistoryScheduleItems.length);
      this.fixingHistoryScheduleItems.push(...this.model);
    }

    createFormGroup(args: CreateFormGroupArgs): FormGroup {
      return  new FormGroup({
        'date': new FormControl(args.isNew ? new Date() : args.dataItem.date, Validators.required),
        'value': new FormControl(args.dataItem.value, Validators.required)
      });
    }

    applyAdvancedGridChanges() {
      this.advancedGrid.applyChanges(records => this.onScheduleChange(records));
    }

    private initColumns() {
      this.columns = [];

      const dateColumn = new LvAdvancedGridDateColumn();
      dateColumn.title = 'Fixing Date';
      dateColumn.field = 'date';
      dateColumn.dmKey = 'DM-2267';

      const valueColumn = new LvAdvancedGridNumericColumn();
      valueColumn.title = 'Coupon (%)';
      valueColumn.field = 'value';
      valueColumn.outputFormat = constants.numberFormat.upToFourDigits;
      valueColumn.format = '#,###.####';
      valueColumn.decimals = '4';
      valueColumn.dmKey = 'DM-2268';

      this.columns.push(dateColumn);
      this.columns.push(valueColumn);
    }

    private parserFunction(pastedDataRecords: string[]): FixingHistoryItem[] {
      const scheduleItems: FixingHistoryItem[] = [];

      pastedDataRecords.forEach(r => {
        const items = r.split('\t');
        const dateValue = items[0];
        const valueValue = items[1];

        const date = MarketDataClipboard.parseDateValue(dateValue, 'Fixing Date');
        const value = MarketDataClipboard.parseNumberValue(valueValue, 'Coupon (%)');

        scheduleItems.push({
          date: date,
          value: value
        } as FixingHistoryItem);
      });

      return scheduleItems;
    }

    private onScheduleChange(scheduleItems: FixingHistoryItem[]) {
      this.model.splice(0, this.model.length);
      this.model.push(...scheduleItems);
      this.didFixingHistoryScheduleChange.next(this.model);
    }

    private onError(error: LvError) {
      this._errorService.handleError(error);
    }

    ngOnDestroy() {
      this._modelSubscription.forEach(s => s.unsubscribe());
    }

}
