<router-outlet></router-outlet>
<div [class.toast-invisible]="toastInvisible" toastContainer></div>
<lv-application-version-modal
  modalClass="public-application-modal"
  (doReloadApplication)="onReloadApplication()">
</lv-application-version-modal>
<lv-authentication-modal
  modalClass="public-application-modal"
  (doLogInAgain)="onLogInAgain($event)"
  (doRetry)="onRetry()"
  (doRetryLogInAgain)="onRetryLogInAgain()">
</lv-authentication-modal>
<lv-signalr-modal
  modalClass="public-application-modal"
  (doReconnect)="onReconnectHub()"
  (didContinue)="onContinueAfterHubError()">
</lv-signalr-modal>
<lv-state-modal modalClass="public-application-modal">
</lv-state-modal>
<lv-error-modal modalClass="public-application-modal">
</lv-error-modal>
