import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, Input, ChangeDetectorRef,
  Output, EventEmitter, ElementRef, OnDestroy, ViewRef } from '@angular/core';

import { Subscription } from 'rxjs';

import { DialogService } from '@progress/kendo-angular-dialog';

import { LvSensitivityAnalysisPanelView } from './lv-sensitivity-analysis-panel.view';
import { LvScenarioComponent } from './lv-scenario/lv-scenario.component';
import { LvSensitivityAnalysysPanelUtil } from './lv-sensitivity-analysis-panel.util';
// tslint:disable-next-line:max-line-length
import { LvSensitivityAnalysisPanelDialogComponent } from './lv-sensitivity-analysis-panel-dialog/lv-sensitivity-analysis-panel-dialog.component';

import { LvPricingComponent, PricingSectionEventSource } from '../lv-pricing/lv-pricing.component';
import { LvSensitivityAnalysisPanelService } from './lv-sensitivity-analysis-panel.service';
import { LvAnalyticsPresenter } from '../../lv-analytics.presenter';
import { LvSidePanelComponent, ILvWorkspace } from '@lv-core-ui/components';
import { LvErrorService } from '@lv-core-ui/services';
import { LvFormUtil } from '@lv-core-ui/util';
import { AnalyticsSettingsHelper, ConvertibleHelper } from '@lv-analytics/helpers';
import { AnalyticsCommands, ILvScenarioTemplateUpdateRequest, ILvScenarioTemplateCreateRequest, IAnalyticsSettings,
         LvAnalyticsError, ILvScenarioTemplateResponse, IScenarioTemplateInfoResponse,
         ScenarioTemplateCommands } from '@lv-analytics/models';
import { ScenarioTemplateService } from '@lv-analytics/services';
import { LvDataMaster } from '@lv-core-ui/models';

export interface ILvSensitivityAnalysisPanelState {
  scenarioTemplateId?: number;
  isSystem: boolean;
}

/**
 * Sensitivity analysis panel component.
 */
@Component({
  selector: 'lv-sensitivity-analysis-panel',
  templateUrl: './lv-sensitivity-analysis-panel.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    LvSensitivityAnalysisPanelService
  ]
})
export class LvSensitivityAnalysisPanelComponent implements OnInit, OnDestroy {

  @ViewChild(LvPricingComponent, { static: true }) pricingComponent: LvPricingComponent;
  @ViewChild(LvScenarioComponent, { static: true }) currentScenario: LvScenarioComponent;
  @ViewChild('scenarioTemplateListPanel', { static: true }) scenarioTemplateListPanel: LvSidePanelComponent;
  @ViewChild('scenarioTemplateEditPanel', { static: true }) scenarioTemplateEditPanel: LvSidePanelComponent;
  @ViewChild('scenarioTemplateNameElement') scenarioTemplateNameElement: ElementRef<HTMLElement>;

  @Input() state: ILvSensitivityAnalysisPanelState;

  @Output() didUpdateState: EventEmitter<ILvSensitivityAnalysisPanelState>;

  get scenarioTemplateName(): string {
    return `Sensitivity Analysis (${this.view.originalModel.name})`;
  }

  get isNewIssue(): boolean {
    return this.cHelper.isNewIssue;
  }

  get calculateDisabled(): boolean {
    return this.isLoading || !this.asHelper.valuationSettings;
  }

  get editEnabled(): boolean {
    return this.isLoading || this.scenarioTemplateEditPanel.expanded || !this.view.selectedScenarioTemplateId;
  }

  isLoading: boolean;
  view: LvSensitivityAnalysisPanelView;
  util: LvSensitivityAnalysysPanelUtil;

  showDeleteScenarioTemplate: boolean;

  asHelper: AnalyticsSettingsHelper;
  cHelper: ConvertibleHelper;
  eventSource: PricingSectionEventSource;

  private _subscriptions: Subscription[];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _dialogService: DialogService,
    private _errorService: LvErrorService,
    private _service: ScenarioTemplateService,
    private _sas: LvSensitivityAnalysisPanelService,
    private _presenter: LvAnalyticsPresenter
  ) {
    this.isLoading = false;

    this.state = {
      scenarioTemplateId: null,
      isSystem: false
    };

    this.didUpdateState = new EventEmitter<ILvSensitivityAnalysisPanelState>();
    this.showDeleteScenarioTemplate = false;

    this.eventSource = PricingSectionEventSource.SensitivityAnalysis;

    this.asHelper = new AnalyticsSettingsHelper();
    this.cHelper = new ConvertibleHelper();

    this.view = new LvSensitivityAnalysisPanelView();
    this.util = new LvSensitivityAnalysysPanelUtil(this._service);
    this.view.init();
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this._presenter.onModelLoading.subscribe(isLoading => this.setLoadingState(isLoading)),

      this._presenter.onAnalyticsSettingsUpdated.subscribe(evt => {
        if (evt) {
          this.init(this._presenter.getModelData());
        }
      }),

      this._presenter.onCommandExecuted.subscribe(command => {
        if (command === AnalyticsCommands.ReloadAssumptionsAndToolsAndAnalysis) {
          this.init(this._presenter.getModelData());
        }
      })
    ];

    if (this._presenter.isModelLoaded()) {
      this.init(this._presenter.getModelData());
    }

    this.load();
  }

  /**
   * Calculates valuation.
   */
  async onCalculate() {
    try {
      this.setLoadingState(true);

      const analysisInputsValid = this.pricingComponent.isValid();
      const currentScenarioValid = this.currentScenario.isValid();
      if (analysisInputsValid && currentScenarioValid) {
        await this.currentScenario.calculate({
          analysisInputs: this.pricingComponent.view.getValuation(),
          lwsIdentifier: this.asHelper.valuationSession.lwsIdentifier,
          sessionId: this.asHelper.valuationSession.sessionId,
          scenario: null
        });
      }
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on cancel edit templete.
   */
  onCancelEditTemplate() {
    this.scenarioTemplateEditPanel.togglePanel();
    this.view.onCancelEditScenarioTemplate();
  }

  /**
   * Saves custom scenario template.
   */
  async onSaveTemplate() {
    try {
      this.setLoadingState(true);

      if (this.view.scenarioTemplateCommand === ScenarioTemplateCommands.Save) {
        this.validateCurrentScenario();

        const request: ILvScenarioTemplateUpdateRequest = {
          id: this.view.model.id,
          template: {
            name: this.view.model.name,
            scenarios: this.view.model.scenarios.map(a => ({
              ...a,
              firstDimension: { ...a.firstDimension },
              secondDimension: a.secondDimension ? { ...a.secondDimension } : null
            }))
          }
        };

        await this._service.updateCustomScenarioTemplate(request);

        this.view.updateSelectedScenarioTemplate();
      }
      else { // Copy or SaveDraft
        this.validateCurrentScenario();

        const request: ILvScenarioTemplateCreateRequest = {
          template: this.view.getDefaultTemplate()
        };

        request.template.name = this.view.model.name;
        request.template.scenarios = this.view.model.scenarios.map(a => ({
          ...a,
          firstDimension: { ...a.firstDimension },
          secondDimension: a.secondDimension ? { ...a.secondDimension } : null
        }));

        this.view.model.id = await this._service.createCustomScenarioTemplate(request);

        this.view.scenarioTemplateInfoList.push(this.view.getScenarioTemplateInfoFromModel());

        this.view.setSelectedScenarioTemplate(this.view.model.id);
      }

      this.hideScenarioTemplateEditPanel();

      this.currentScenario.firstDimension.view.setOriginalValues(true);
      if (this.currentScenario.secondDimension) {
        this.currentScenario.secondDimension.view.setOriginalValues(true);
      }

      this.didUpdateState.next({
        scenarioTemplateId: this.view.model.id,
        isSystem: false
      });

      this.view.setOriginalModel();
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1817'));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Deletes scenario template.
   */
  async onDeleteScenarioTemplate() {
    try {
      this.setLoadingState(true);

      await this._service.deleteCustomScenarioTemplate(this.view.model.id);
      this.view.deleteScenarioTemplateFromList(this.view.model.id);

      this.onToggleScenarioTemplateEditPanel();

      this.didUpdateState.next({
        scenarioTemplateId: null,
        isSystem: false
      });

      this.view.init();
      this.view.selectedScenarioTemplateId = null;

      this.showDeleteScenarioTemplate = false;
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1818'));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  // Workspace Events

  /**
   * Occurs on select scenario.
   * @param w ILvWorkspace object.
   */
  onSelectScenario(w: ILvWorkspace) {
    this.invalidateFormFields();
    this.view.onSelectScenario(w);

    this.detectChanges();
  }

  /**
   * Occurs on add scenario.
   * @param w ILvWorkspace object.
   */
  onAddScenario(w: ILvWorkspace) {
    this.invalidateFormFields();
    this.view.onAddScenario(w);

    this.detectChanges();
  }

  /**
   * Occurs on edit scenario.
   * @param w ILvWorkspace object.
   */
  onEditScenario(w: ILvWorkspace) {
    this.view.onEditScenario(w);

    this.detectChanges();
  }

  /**
   * Occurs on close scenario.
   * @param w ILvWorkspace object.
   */
  onCloseScenario(w: ILvWorkspace) {
    this.view.onCloseScenario(w);

    this.detectChanges();
  }

  // region sliding panel settings
  /**
   * Occurs on toggle scenario template edit panel.
   */
  onToggleScenarioTemplateEditPanel() {
    this.scenarioTemplateEditPanel.togglePanel();
  }

  /**
   * Occurs on scenario template edit panel open.
   */
  onScenarioTemplateEditPanelOpen() {
    if (!this.showDeleteScenarioTemplate) {
      this.scenarioTemplateNameElement.nativeElement.focus();
    }
  }

  /**
   * Occurs on toggle scenario template panel.
   */
  onToggleScenarioTemplatePanel() {
    this.hideScenarioTemplateEditPanel();
    this.scenarioTemplateListPanel.togglePanel();
  }

  /**
   * Occurs on show save scenario template.
   * @param isDraft A flag indicating if instrument is Draft.
   */
  onShowSaveScenarioTemplate(isDraft: boolean) {
    if (isDraft) {
      this.view.setCommand(ScenarioTemplateCommands.SaveDraft);
      this.showDeleteScenarioTemplate = false;
      this.onToggleScenarioTemplateEditPanel();
    }
    else {
      this.view.setCommand(ScenarioTemplateCommands.Save);
      this.onSaveTemplate();
    }
  }

  /**
   * Occurs on show edit scenario template.
   */
  onShowEditScenarioTemplate() {
    if (!this.editEnabled) {
      this.view.setCommand(ScenarioTemplateCommands.Save);
      this.showDeleteScenarioTemplate = false;
      this.onToggleScenarioTemplateEditPanel();
    }
  }

  /**
   * Occurs on show copy scenario template.
   */
  onShowCopyScenarioTemplate() {
    if (!this.editEnabled) {
      this.view.setCommand(ScenarioTemplateCommands.Copy);
      this.showDeleteScenarioTemplate = false;
      this.onToggleScenarioTemplateEditPanel();
    }
  }

  /**
   * Occurs on show delete scenario template.
   */
  onShowDeleteScenarioTemplate() {
    if (!this.editEnabled) {
      this.showDeleteScenarioTemplate = true;
      this.onToggleScenarioTemplateEditPanel();
    }
  }

  /**
   * Occurs on select scenario template.
   * @param st IScenarioTemplateInfoResponse object.
   */
  onSelectScenarioTemplate(st: IScenarioTemplateInfoResponse) {
    if (st.isGroup) {
      return false;
    }

    if (!this.view.selectedScenarioTemplateId && st.isDraft) {
      return false;
    }

    if (this.view.selectedScenarioTemplateId === st.id) {
      return false;
    }

    if (this.view.isEdited(this.view.selectedScenarioTemplateId)) {
      const dialogRef = this._dialogService.open({
        title: 'Scenario Template',
        content: LvSensitivityAnalysisPanelDialogComponent
      });

      const dialog = dialogRef.content.instance as LvSensitivityAnalysisPanelDialogComponent;
      dialog.selectedScenarioTemplate = this.view.getScenarioTemplateInfo(this.view.selectedScenarioTemplateId);

      dialog.didContinueEditing.subscribe(() => {
        dialogRef.close();
      });

      dialog.didDiscardChanges.subscribe(() => {
        this.onChangeSelectedScenario(st);
        dialogRef.close();
      });
    }
    else {
      this.onChangeSelectedScenario(st);
    }
  }
  // end region

  /**
   * Occurs on change selected scenario.
   * @param st IScenarioTemplateInfoResponse object.
   */
  async onChangeSelectedScenario(st: IScenarioTemplateInfoResponse) {
    try {
      this.setLoadingState(true);

      if (!st.isDraft) {
        let response: ILvScenarioTemplateResponse = {
          id: null,
          template: null
        };

        if (st.isSystem) {
          response = await this._service.getSystemScenarioTemplate(st.id);
        }
        else {
          response = await this._service.getCustomScenarioTemplate(st.id);
        }

        this.state.scenarioTemplateId = st.id;
        this.view.init(response.template);
      }
      else {
        this.state.scenarioTemplateId = null;
        this.view.init();
      }

      this.view.setOriginalModel();

      this._sas.invalidate();

      this.didUpdateState.next({
        scenarioTemplateId: st.isDraft ? null : this.view.model.id,
        isSystem: st.isSystem
      });

      this.invalidateFormFields();

      this.view.setSelectedScenarioTemplate(st.id);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  // Reports

  /**
   * Rerenders chart.
   */
  rerenderChart() {
    if (this.currentScenario) {
      this.currentScenario.rerenderChart();
    }
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Loads scenario template.
   */
  private async load() {
    try {
      this.setLoadingState(true);

      let response: ILvScenarioTemplateResponse = {
        id: null,
        template: null
      };

      this.view.scenarioTemplateInfoList = await this.util.getScenarioTemplatesList();

      if (this.state.scenarioTemplateId) {
        if (this.state.isSystem) {
          response = await this._service.getSystemScenarioTemplate(this.state.scenarioTemplateId);
        }
        else {
          response = await this._service.getCustomScenarioTemplate(this.state.scenarioTemplateId);
        }
      }

      this.view.init(response.template);
      this.view.selectedScenarioTemplateId = response.id;
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Does initialization.
   * @param settings IAnalyticsSettings object.
   */
  private init(settings: IAnalyticsSettings) {
    this.asHelper.init(settings);
    this.cHelper.init(this.asHelper.convertible);

    this._sas.invalidate();
    this.currentScenario.clearReport();

    this._changeDetectorRef.detectChanges();
  }

  /**
   * Detects changes.
   */
  private detectChanges() {
    if (!(this._changeDetectorRef as ViewRef).destroyed) {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  private setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
    this.detectChanges();
  }

  /**
   * Hides scenario template edit panel.
   */
  private hideScenarioTemplateEditPanel() {
    if (this.scenarioTemplateEditPanel.expanded) {
      this.scenarioTemplateEditPanel.togglePanel();
    }
  }

  /**
   * Invalidates form fields.
   */
  private invalidateFormFields() {
    LvFormUtil.markAllControlsAsPristine(this.currentScenario.priceTalkForm);
    LvFormUtil.markAllControlsAsPristine(this.currentScenario.firstDimension.form);
    if (this.currentScenario.secondDimension) {
      LvFormUtil.markAllControlsAsPristine(this.currentScenario.secondDimension.form);
    }
  }

  /**
   * Validates current scenario.
   */
  private validateCurrentScenario() {
    const currentScenarioValid = this.currentScenario.isValid();
    if (!currentScenarioValid) {
      throw new LvAnalyticsError(LvDataMaster.getError('dM-3390', {'value': 'Scenario Template'}));
    }
  }
}
