import { LvScenarioChartView, ILvScatterLineChart, ILvScatterLineChartSeries } from '../lv-scenario-chart.view';
import { ILvScenarioReport } from '../../lv-scenario.view';
import { ScenarioPriceTalk, ICalculateScenarioOutput } from '@lv-analytics/models';

export interface IOneDimPriceTalkChartSeries extends ILvScatterLineChartSeries {
  priceTalk: ScenarioPriceTalk;
}

export interface IOneDimPriceTalkChart extends ILvScatterLineChart {
  priceTalks: ScenarioPriceTalk[];
  currentPriceTalk: ScenarioPriceTalk;
  series: IOneDimPriceTalkChartSeries[];
}

/**
 * Scenario one dim price talk chart view.
 */
export class LvScenarioOneDimPriceTalkChartView extends LvScenarioChartView<IOneDimPriceTalkChart> {

  /**
   * Gets default model.
   * @returns IOneDimPriceTalkChart object.
   */
  getDefaultModel(): IOneDimPriceTalkChart {
    return {
      xAxis: {},
      yAxis: {},
      series: [],
      records: [],
      priceTalks: [],
      currentPriceTalk: null
    };
  }

  /**
   * Does initialization.
   * @param report ILvScenarioReport object.
   */
  doInit(report: ILvScenarioReport): void {
    const mapped = (report.data as ICalculateScenarioOutput[]);

    this.model.yAxis = this.getYAxis();

    mapped.forEach((fDim, i, fDimArray) => {
      if (this.isFirstIndex(i)) {
        this.model.xAxis = this.getXAxis(fDim.dimension);
        this.model.xAxis.min = fDim.value;
      }

      const rec = {
        [fDim.dimension]: fDim.value
      };

      fDim.outputs.forEach((output, j) => {
        output.values.forEach((value, k) => {
          const valueId = `${output.output}_${value.priceTalk}`;

          if (this.isFirstIndex(i)) {
            const series = this.getSeries<IOneDimPriceTalkChartSeries>(fDim, output);
            series.yField = valueId;
            series.priceTalk = value.priceTalk;
            this.model.series.push(series);
          }

          if (this.areFirstIndexes(i, j)) {
            this.model.priceTalks.push(value.priceTalk);
          }

          if (this.areFirstIndexes(i, j, k)) {
            this.model.currentPriceTalk = value.priceTalk;
          }

          rec[valueId] = value.value;

          this.setYAxisMinAndMax(value.value);
        });
      });

      if (this.isLastIndex(fDimArray, i)) {
        this.model.xAxis.max = fDim.value;
      }

      this.model.records.push(rec);
    });
  }

  /**
   * Checks if price talk is visible.
   * @param selected Scenario price talk selected value.
   * @returns A flag indicating if price talk is visible.
   */
  isPriceTalkVisible(selected: ScenarioPriceTalk) {
    return this.model.currentPriceTalk === selected;
  }
}
