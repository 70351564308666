import { Injectable, Optional } from '@angular/core';
import * as moment from 'moment';
import { LvDateUtil } from '@lv-core-ui/util';
import { ConvertibleBondNewIssueDocument, ConvertibleBondTermsDocument, IAccretionSectionSettings,
  IConversionSectionSettings,
  ICouponSectionSettings,
  IDividendProtectionSectionSettings,
  IMakeWholeSectionSettings,  ITakeoverProtectionSectionSettings,  ITermsSectionSettings } from '@lv-convertible-bond/models';
import { LvExcelService } from '@lv-excel/services';
import { ContingentConversionScheduleTriggerType } from '@lv-convertible-bond/models/convertible-bond-terms/Enums (2)';
import { ICallSectionSettings } from './../../models/terms-settings/call-settings/call-section-settings';

@Injectable()
export class ConvertibleBondTermsService {

  get convertibleBondDocument(): ConvertibleBondTermsDocument {
    return this._convertibleBondTermsDocumentFromSettings;
  }
  
  get convertibleBondNewIssueDocument(): ConvertibleBondNewIssueDocument {
    return this._convertibleBondTermsDocumentFromSettings as ConvertibleBondNewIssueDocument;
  }

  get triggerTypeFromSettings(): ContingentConversionScheduleTriggerType {
    if (this._termsSettings?.myDefaults.conversionSettings.overrideSystemDefaults) {
      return this._termsSettings.myDefaults.conversionSettings.settings.contingentConversion.triggerType;
    }

    return this._termsSettings?.systemDefaults.conversionSettings.settings.contingentConversion.triggerType;
  }

  get callSettings(): ICallSectionSettings {
    if (this._termsSettings?.myDefaults.callSettings.overrideSystemDefaults) {
      return this._termsSettings.myDefaults.callSettings.settings;
    }

    return this._termsSettings?.systemDefaults.callSettings.settings;
  }

  get makeWholeSettings(): IMakeWholeSectionSettings {
    if (this._termsSettings?.myDefaults.makeWholeSettings.overrideSystemDefaults) {
      return this._termsSettings.myDefaults.makeWholeSettings.settings;
    }

    return this._termsSettings?.systemDefaults.makeWholeSettings.settings;
  }

  get accretionSettings(): IAccretionSectionSettings {
    if (this._termsSettings?.myDefaults.accretionSettings.overrideSystemDefaults) {
      return this._termsSettings.myDefaults.accretionSettings.settings;
    }

    return this._termsSettings?.systemDefaults.accretionSettings.settings;
  }

  get couponSettings(): ICouponSectionSettings {
    if (this._termsSettings?.myDefaults.couponSettings.overrideSystemDefaults) {
      return this._termsSettings.myDefaults.couponSettings.settings;
    }

    return this._termsSettings?.systemDefaults.couponSettings.settings;
  }

  get conversionSettings(): IConversionSectionSettings {
    if (this._termsSettings?.myDefaults.conversionSettings.overrideSystemDefaults) {
      return this._termsSettings.myDefaults.conversionSettings.settings;
    }

    return this._termsSettings?.systemDefaults.conversionSettings.settings;
  }

  get dividendProtectionSettings(): IDividendProtectionSectionSettings {
    if (this._termsSettings?.myDefaults.dividendProtectionSettings.overrideSystemDefaults) {
      return this._termsSettings.myDefaults.dividendProtectionSettings.settings;
    }

    return this._termsSettings?.systemDefaults.dividendProtectionSettings.settings;
  }

  get takeOverProtection(): ITakeoverProtectionSectionSettings {
    if (this._termsSettings?.myDefaults.takeoverProtectionSettings.overrideSystemDefaults) {
      return this._termsSettings.myDefaults.takeoverProtectionSettings.settings;
    }

    return this._termsSettings?.systemDefaults.takeoverProtectionSettings.settings;
  }

  private _convertibleBondTermsDocumentFromSettings: ConvertibleBondTermsDocument | ConvertibleBondNewIssueDocument;
  private _termsSettings: ITermsSectionSettings;

  constructor(
    @Optional() private _excelSvc: LvExcelService
  ) {}

  mapToUi(model: ConvertibleBondTermsDocument | ConvertibleBondNewIssueDocument) {
    // Accretion
    if (model.accretion) {
      model.accretion.accretionEndDate = LvDateUtil.parse(model.accretion.accretionEndDate);
      model.accretion.accretionStartDate = LvDateUtil.parse(model.accretion.accretionStartDate);
    }

    // IsssueAndRedemption
    model.issueAndRedemption.maturityDate = LvDateUtil.parse(model.issueAndRedemption.maturityDate);
    model.issueAndRedemption.firstSettlementDate = LvDateUtil.parse(model.issueAndRedemption.firstSettlementDate);
    // tslint:disable-next-line:max-line-length
    model.issueAndRedemption.fiscalYearStartDate = model.issueAndRedemption.fiscalYearStartDate ? LvDateUtil.parse(model.issueAndRedemption.fiscalYearStartDate) : moment.utc(new Date(1900, 0, 1)).toDate();

    if (model.issueAndRedemption.sinkingFundSchedule) {
      model.issueAndRedemption.sinkingFundSchedule.forEach(a => a.sinkingDate = LvDateUtil.parse(a.sinkingDate));
    }

    // Coupon
    model.coupon.accrualEndDate = LvDateUtil.parse(model.coupon.accrualEndDate);
    model.coupon.typeChangeDate = LvDateUtil.parse(model.coupon.typeChangeDate);
    model.coupon.accrualStartDate = LvDateUtil.parse(model.coupon.accrualStartDate);
    model.coupon.guaranteedStartDate = LvDateUtil.parse(model.coupon.guaranteedStartDate);
    model.coupon.guaranteedStartEndDate = LvDateUtil.parse(model.coupon.guaranteedStartEndDate);
    model.coupon.firstCouponPaymentDate = LvDateUtil.parse(model.coupon.firstCouponPaymentDate);
    model.coupon.penultimateCouponPaymentDate = LvDateUtil.parse(model.coupon.penultimateCouponPaymentDate);

    if (model.coupon.fixed && model.coupon.fixed.stepUpCouponSchedule) {
      model.coupon.fixed.stepUpCouponSchedule.forEach(a => a.endDate = LvDateUtil.parse(a.endDate));
    }

    if (model.coupon.floating && model.coupon.floating.floatingSpreadSchedule) {
      model.coupon.floating.floatingSpreadSchedule.forEach(a => a.endDate = LvDateUtil.parse(a.endDate));
    }

    if (model.coupon.floating && model.coupon.floating.fixingHistoryItems) {
      model.coupon.floating.fixingHistoryItems.forEach(a => a.date = LvDateUtil.parse(a.date));
    }

    if (model.coupon.floating && model.coupon.floating.floatingCustomDates) {
      model.coupon.floating.floatingCustomDates = model.coupon.floating.floatingCustomDates.map(a => LvDateUtil.parse(a));
    }

    if (model.coupon.custom && model.coupon.custom.schedule) {
      model.coupon.custom.schedule.forEach(a => a.date = LvDateUtil.parse(a.date));
    }

    if (model.coupon.pik) {
      model.coupon.pik.pikEndDate = LvDateUtil.parse(model.coupon.pik.pikEndDate);
      if (model.coupon.pik.pikCustomSchedule) {
        model.coupon.pik.pikCustomSchedule.forEach(a => a.date = LvDateUtil.parse(a.date));
      }
    }

    if (model.coupon.coPay) {
      model.coupon.coPay.coPayAccrualStartDate = LvDateUtil.parse(model.coupon.coPay.coPayAccrualStartDate);
      if (model.coupon.coPay.coPayDownsideUseSchedule) {
        model.coupon.coPay.coPayDownsideTriggerSchedule.forEach(a => a.startDate = LvDateUtil.parse(a.startDate));
      }
    }

    // Call
    if (model.call) {
      model.call.callDate = LvDateUtil.parse(model.call.callDate);

      if (model.call.scheduleItems) {
        model.call.scheduleItems.forEach(a => {
          a.startDate = LvDateUtil.parse(a.startDate);
          a.endDate = LvDateUtil.parse(a.endDate);
        });
      }

      if (model.call.callMakeWhole) {
        model.call.callMakeWhole.endDate = LvDateUtil.parse(model.call.callMakeWhole.endDate);
        model.call.callMakeWhole.startDate = LvDateUtil.parse(model.call.callMakeWhole.startDate);
        model.call.callMakeWhole.couponsIncludedUntil = LvDateUtil.parse(model.call.callMakeWhole.couponsIncludedUntil);

        if (model.call.callMakeWhole.ratchetMatrixMembers) {
           model.call.callMakeWhole.ratchetMatrixMembers.forEach(a => {
            if (!(typeof(a.startDate) === 'string')) {
              a.startDate = LvDateUtil.toISOString(a.startDate)
            }
          });
        }
      }
    }

    // Put
    if (model.put && model.put.scheduleItems) {
      model.put.scheduleItems.forEach( a => {
        a.startDate = LvDateUtil.parse(a.startDate);
        a.endDate = LvDateUtil.parse(a.endDate);
      });
    }

    // Conversion
    if (model.conversion.recieveMakeWholeConversion || model.conversion.mwStartDate || model.conversion.mwEndDate) {
      model.conversion.mwStartDate = LvDateUtil.parse(model.conversion.mwStartDate);
      model.conversion.mwEndDate = LvDateUtil.parse(model.conversion.mwEndDate);
    }

    if (model.conversion.schedule) {
      model.conversion.schedule.forEach(a => {
        a.startDate = LvDateUtil.parse(a.startDate);
        a.endDate = LvDateUtil.parse(a.endDate);
      });
    }

    if (model.conversion.exerciseSchedule) {
      model.conversion.exerciseSchedule.forEach(a => {
        a.startDate = LvDateUtil.parse(a.startDate);
        a.endDate = LvDateUtil.parse(a.endDate);
      });
    }

    if (model.conversion.reset && model.conversion.reset.schedule) {
      model.conversion.reset.schedule.forEach(a => {
        a.startDate = LvDateUtil.parse(a.startDate);
        a.endDate = LvDateUtil.parse(a.endDate);
      });
    }

    if (model.conversion.contingentConversion &&
      model.conversion.contingentConversion.schedule) {
        model.conversion.contingentConversion.schedule.forEach(a => {
          a.startDate = LvDateUtil.parse(a.startDate);
          a.endDate = LvDateUtil.parse(a.endDate);
        });
    }

    if (model.conversion.variableConversion) {
      model.conversion.variableConversion.lockinDate = LvDateUtil.parse(model.conversion.variableConversion.lockinDate);
    }

    if (model.conversion.pepsConversion) {
      model.conversion.pepsConversion.startDate = LvDateUtil.parse(model.conversion.pepsConversion.startDate);
      model.conversion.pepsConversion.endDate = LvDateUtil.parse(model.conversion.pepsConversion.endDate);
    }

    if (model.conversion.conversionRatioHistory) {
      model.conversion.conversionRatioHistory.forEach(a => a.effectiveDate = LvDateUtil.parse(a.effectiveDate));
    }

    if (model.conversion.carryForwardHistory) {
      model.conversion.carryForwardHistory.forEach(a => a.effectiveDate = LvDateUtil.parse(a.effectiveDate));
    }

    if (model.conversion.callMakeWhole) {
      model.conversion.callMakeWhole.couponsIncludedUntil = LvDateUtil.parse(model.conversion.callMakeWhole.couponsIncludedUntil);

      if (model.conversion.callMakeWhole.ratchetMatrixMembers) {
        model.conversion.callMakeWhole.ratchetMatrixMembers.forEach(a => {
          if (!(typeof(a.startDate) === 'string')) {
            a.startDate = LvDateUtil.toISOString(a.startDate)
          }
        });
      }
    }

    if (model.conversion.acquisitionNoticeStartDate) {
      model.conversion.acquisitionNoticeStartDate = LvDateUtil.parse(model.conversion.acquisitionNoticeStartDate);
    }

    if (model.conversion.conversionSettlementAcquisitionOptionDate) {
      // tslint:disable-next-line: max-line-length
      model.conversion.conversionSettlementAcquisitionOptionDate = LvDateUtil.parse(model.conversion.conversionSettlementAcquisitionOptionDate);
    }

    if (model.conversion.conversionSettlementVWAPPeriodStartDate) {
      // tslint:disable-next-line: max-line-length
      model.conversion.conversionSettlementVWAPPeriodStartDate = LvDateUtil.parse(model.conversion.conversionSettlementVWAPPeriodStartDate);
    }

    if (model.conversion.acquisitionNoticeEndDate) {
      // tslint:disable-next-line: max-line-length
      model.conversion.acquisitionNoticeEndDate = LvDateUtil.parse(model.conversion.acquisitionNoticeEndDate);
    }

    // TakeoverProtection
    if (model.takeoverProtection) {
      // tslint:disable-next-line:max-line-length
      model.takeoverProtection.takeoverCompensationProtectionStartDate = LvDateUtil.parse(model.takeoverProtection.takeoverCompensationProtectionStartDate);
      // tslint:disable-next-line:max-line-length
      model.takeoverProtection.takeoverCompensationProtectionEndDate = LvDateUtil.parse(model.takeoverProtection.takeoverCompensationProtectionEndDate);
      if (model.takeoverProtection.ratchetScheduleMembers) {
        model.takeoverProtection.ratchetScheduleMembers.forEach(a => a.endDate = LvDateUtil.parse(a.endDate));
      }
      if (model.takeoverProtection.ratchetMatrixMembers) {
        model.takeoverProtection.ratchetMatrixMembers.forEach(a => {
          if (!(typeof(a.startDate) === 'string')) {
            a.startDate = LvDateUtil.toISOString(a.startDate)
          }
        });
      }
    }

    // Dividend Protection
    if (model.dividendProtection) {
      model.dividendProtection.startDate = LvDateUtil.parse(model.dividendProtection.startDate);
      model.dividendProtection.endDate = LvDateUtil.parse(model.dividendProtection.endDate);

      if (model.dividendProtection.schedule) {
        model.dividendProtection.schedule.forEach(a => a.startDate = LvDateUtil.parse(a.startDate));
      }
    }

    // Other
    model.other.fxRateTime = LvDateUtil.parse(model.other.fxRateTime);
    model.other.pricingDate = LvDateUtil.parse(model.other.pricingDate);

    // Expected CR
    if (model.expectedCR && model.expectedCR.schedule) {
      model.expectedCR.schedule.forEach(a => a.startDate = LvDateUtil.parse(a.startDate));
    }
  }

  mapToApi(model: ConvertibleBondTermsDocument | ConvertibleBondNewIssueDocument) {

    // Accretion
    if (model.accretion) {
      model.accretion.accretionStartDate = LvDateUtil.toISOString(model.accretion.accretionStartDate);
      model.accretion.accretionEndDate = LvDateUtil.toISOString(model.accretion.accretionEndDate);
      model.accretion.accretionStartDate = LvDateUtil.toISOString(model.accretion.accretionStartDate);
    }

    // IssueAndRedemption
    model.issueAndRedemption.maturityDate = LvDateUtil.toISOString(model.issueAndRedemption.maturityDate);
    model.issueAndRedemption.firstSettlementDate = LvDateUtil.toISOString(model.issueAndRedemption.firstSettlementDate);
    model.issueAndRedemption.fiscalYearStartDate = LvDateUtil.toISOString(model.issueAndRedemption.fiscalYearStartDate);

    if (model.issueAndRedemption.sinkingFundSchedule) {
      model.issueAndRedemption.sinkingFundSchedule.forEach(a => a.sinkingDate = LvDateUtil.toISOString(a.sinkingDate));
    }

    // Coupon
    model.coupon.accrualEndDate = LvDateUtil.toISOString(model.coupon.accrualEndDate);
    model.coupon.typeChangeDate = LvDateUtil.toISOString(model.coupon.typeChangeDate);
    model.coupon.accrualStartDate = LvDateUtil.toISOString(model.coupon.accrualStartDate);
    model.coupon.guaranteedStartDate = LvDateUtil.toISOString(model.coupon.guaranteedStartDate);
    model.coupon.guaranteedStartEndDate = LvDateUtil.toISOString(model.coupon.guaranteedStartEndDate);
    model.coupon.firstCouponPaymentDate = LvDateUtil.toISOString(model.coupon.firstCouponPaymentDate);
    model.coupon.penultimateCouponPaymentDate = LvDateUtil.toISOString(model.coupon.penultimateCouponPaymentDate);

    if (model.coupon.fixed && model.coupon.fixed.stepUpCouponSchedule) {
      model.coupon.fixed.stepUpCouponSchedule.forEach(a => a.endDate = LvDateUtil.toISOString(a.endDate));
    }

    if (model.coupon.floating && model.coupon.floating.floatingSpreadSchedule) {
      model.coupon.floating.floatingSpreadSchedule.forEach(a => a.endDate = LvDateUtil.toISOString(a.endDate));
    }

    if (model.coupon.floating && model.coupon.floating.fixingHistoryItems) {
      model.coupon.floating.fixingHistoryItems.forEach(a => a.date = LvDateUtil.toISOString(a.date));
    }

    if (model.coupon.floating && model.coupon.floating.floatingCustomDates) {
      model.coupon.floating.floatingCustomDates = model.coupon.floating.floatingCustomDates.map(a => LvDateUtil.toISOString(a));
    }

    if (model.coupon.custom && model.coupon.custom.schedule) {
      model.coupon.custom.schedule.forEach(a => a.date = LvDateUtil.toISOString(a.date));
    }

    if (model.coupon.pik) {
      model.coupon.pik.pikEndDate = LvDateUtil.toISOString(model.coupon.pik.pikEndDate);
      if (model.coupon.pik.pikCustomSchedule) {
        model.coupon.pik.pikCustomSchedule.forEach(a => a.date = LvDateUtil.toISOString(a.date));
      }
    }

    if (model.coupon.coPay) {
      model.coupon.coPay.coPayAccrualStartDate = LvDateUtil.toISOString(model.coupon.coPay.coPayAccrualStartDate);
      if (model.coupon.coPay.coPayDownsideUseSchedule) {
        model.coupon.coPay.coPayDownsideTriggerSchedule.forEach(a => a.startDate = LvDateUtil.toISOString(a.startDate));
      }
    }

    // Call
    if (model.call) {
      model.call.callDate = LvDateUtil.toISOString(model.call.callDate);

      if (model.call.scheduleItems) {
        model.call.scheduleItems.forEach(a => {
          a.startDate = LvDateUtil.toISOString(a.startDate);
          a.endDate = LvDateUtil.toISOString(a.endDate);
        });
      }

      if (model.call.callMakeWhole) {
        model.call.callMakeWhole.endDate = LvDateUtil.toISOString(model.call.callMakeWhole.endDate);
        model.call.callMakeWhole.startDate = LvDateUtil.toISOString(model.call.callMakeWhole.startDate);
        model.call.callMakeWhole.couponsIncludedUntil = LvDateUtil.toISOString(model.call.callMakeWhole.couponsIncludedUntil);

        if (model.call.callMakeWhole.ratchetMatrixMembers) {
          model.call.callMakeWhole.ratchetMatrixMembers.forEach(a => a.startDate = LvDateUtil.toISOString(a.startDate));
        }
      }
    } else {
      model.call = null;
    }

    // Put
    if (!!model.put) {
      if (model.put.scheduleItems) {
        model.put.scheduleItems.forEach( a => {
          a.startDate = LvDateUtil.toISOString(a.startDate);
          a.endDate = LvDateUtil.toISOString(a.endDate);
        });
      }
    } else {
      model.put = null;
    }

    // TakeoverProtection
    if (model.takeoverProtection) {
      // tslint:disable-next-line:max-line-length
      model.takeoverProtection.takeoverCompensationProtectionStartDate = LvDateUtil.toISOString(model.takeoverProtection.takeoverCompensationProtectionStartDate);
      // tslint:disable-next-line:max-line-length
      model.takeoverProtection.takeoverCompensationProtectionEndDate = LvDateUtil.toISOString(model.takeoverProtection.takeoverCompensationProtectionEndDate);
      if (model.takeoverProtection.ratchetScheduleMembers) {
        model.takeoverProtection.ratchetScheduleMembers.forEach(a => a.endDate = LvDateUtil.toISOString(a.endDate));
      }
      // if (model.takeoverProtection.ratchetMatrixMembers) {
      //   model.takeoverProtection.ratchetMatrixMembers.forEach(a => a.startDate = LvDateUtil.toISOString(a.startDate));
      // }
    }

    // Conversion
    if (model.conversion.recieveMakeWholeConversion) {
      model.conversion.mwStartDate = LvDateUtil.toISOString(model.conversion.mwStartDate);
      model.conversion.mwEndDate = LvDateUtil.toISOString(model.conversion.mwEndDate);
    }

    if (model.conversion.schedule) {
      model.conversion.schedule.forEach(a => {
        a.startDate = LvDateUtil.toISOString(a.startDate);
        a.endDate = LvDateUtil.toISOString(a.endDate);
      });
    }

    if (model.conversion.exerciseSchedule) {
      model.conversion.exerciseSchedule.forEach(a => {
        a.startDate = LvDateUtil.toISOString(a.startDate);
        a.endDate = LvDateUtil.toISOString(a.endDate);
      });
    }

    if (model.conversion.reset && model.conversion.reset.schedule) {
      model.conversion.reset.schedule.forEach(a => {
        a.startDate = LvDateUtil.toISOString(a.startDate);
        a.endDate = LvDateUtil.toISOString(a.endDate);
      });
    }

    if (model.conversion.contingentConversion && model.conversion.contingentConversion.schedule) {
      model.conversion.contingentConversion.schedule.forEach(a => {
        a.startDate = LvDateUtil.toISOString(a.startDate);
        a.endDate = LvDateUtil.toISOString(a.endDate);
      });
    }

    if (model.conversion.variableConversion) {
      model.conversion.variableConversion.lockinDate = LvDateUtil.toISOString(model.conversion.variableConversion.lockinDate);
    }

    if (model.conversion.pepsConversion) {
      model.conversion.pepsConversion.startDate = LvDateUtil.toISOString(model.conversion.pepsConversion.startDate);
      model.conversion.pepsConversion.endDate = LvDateUtil.toISOString(model.conversion.pepsConversion.endDate);
    }

    if (model.conversion.conversionRatioHistory) {
      model.conversion.conversionRatioHistory.forEach(a => a.effectiveDate = LvDateUtil.toISOString(a.effectiveDate));
    }

    if (model.conversion.carryForwardHistory) {
      model.conversion.carryForwardHistory.forEach(a => a.effectiveDate = LvDateUtil.toISOString(a.effectiveDate));
    }

    if (model.conversion.callMakeWhole) {
      model.conversion.callMakeWhole.couponsIncludedUntil = LvDateUtil.toISOString(model.conversion.callMakeWhole.couponsIncludedUntil);

      if (model.conversion.callMakeWhole.ratchetMatrixMembers) {
        model.conversion.callMakeWhole.ratchetMatrixMembers.forEach(a => a.startDate = LvDateUtil.toISOString(a.startDate));
      }
    }

    if (model.conversion.acquisitionNoticeStartDate) {
      model.conversion.acquisitionNoticeStartDate = LvDateUtil.toISOString(model.conversion.acquisitionNoticeStartDate);
    }
    if (model.conversion.conversionSettlementAcquisitionOptionDate) {
      // tslint:disable-next-line: max-line-length
      model.conversion.conversionSettlementAcquisitionOptionDate = LvDateUtil.toISOString(model.conversion.conversionSettlementAcquisitionOptionDate);
    }
    if (model.conversion.conversionSettlementVWAPPeriodStartDate) {
      // tslint:disable-next-line: max-line-length
      model.conversion.conversionSettlementVWAPPeriodStartDate = LvDateUtil.toISOString(model.conversion.conversionSettlementVWAPPeriodStartDate);
    }
    if (model.conversion.acquisitionNoticeEndDate) {
      // tslint:disable-next-line: max-line-length
      model.conversion.acquisitionNoticeEndDate = LvDateUtil.toISOString(model.conversion.acquisitionNoticeEndDate);
    }

    // Dividend Protection
    if (model.dividendProtection) {
      model.dividendProtection.startDate = LvDateUtil.toISOString(model.dividendProtection.startDate);
      model.dividendProtection.endDate = LvDateUtil.toISOString(model.dividendProtection.endDate);

      if (model.dividendProtection.schedule) {
        model.dividendProtection.schedule.forEach(a => a.startDate = LvDateUtil.toISOString(a.startDate));
      }
    }

    // Other
    model.other.fxRateTime = LvDateUtil.toISOString(model.other.fxRateTime);
    model.other.pricingDate = LvDateUtil.toISOString(model.other.pricingDate);

    // Expected CR
    if (model.expectedCR && model.expectedCR.schedule) {
      model.expectedCR.schedule.forEach(a => a.startDate = LvDateUtil.toISOString(a.startDate));
    }
  }
  
  /**
   * Set convertible bond terms data from settings
   * @param convertibleBondTermsDocumentFromSettings Convertible bond terms data 
   */
  setConvertibleBondTermsFromSettings(
    convertibleBondTermsDocumentFromSettings: ConvertibleBondTermsDocument,
    termsSettigns: ITermsSectionSettings
  ): void {

    this._convertibleBondTermsDocumentFromSettings = convertibleBondTermsDocumentFromSettings;
    this._termsSettings = termsSettigns;
  }
}
