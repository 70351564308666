import { Component, OnChanges, ViewEncapsulation, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';

import { NumberPipe } from '@progress/kendo-angular-intl';

import { LvLocalDatePipe } from '@lv-core-ui/pipes';

import { LvScenarioOneDimChartView } from './lv-scenario-one-dim-chart.view';
import { ILvScenarioReport } from '../../lv-scenario.view';
import { LvScenarioChart } from '../lv-scenario-chart';

/**
 * Scenario one dim chart component.
 */
@Component({
  selector: 'lv-scenario-one-dim-chart',
  templateUrl: './lv-scenario-one-dim-chart.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvScenarioOneDimChartComponent extends LvScenarioChart implements OnChanges {

  @Input() report: ILvScenarioReport;

  view: LvScenarioOneDimChartView;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    numberPipe: NumberPipe,
    lvDatePipe: LvLocalDatePipe
  ) {
    super(changeDetectorRef);

    this.view = new LvScenarioOneDimChartView(numberPipe, lvDatePipe);
    this.view.init();
  }

  /**
   * Does view initialization.
   */
  ngOnChanges() {
    this.view.init(this.report);
  }
}
