<div class="lv-terms-summary">
  <ng-container *ngIf="sectionsVisible">
    <div class="lv-terms-summary-header">
      <div *ngFor="let s of sections"
        (click)="scrollTo(s.id)">
          {{ s.title }}
        </div>
    </div>
    <div #container
      class="lv-terms-summary-content">
      <div *ngFor="let s of sections"
        [lvScrollTo]="s.id">
        <img [src]="s.base64" />
      </div>
    </div>
  </ng-container>
  <div *ngIf="!sectionsVisible" class="lv-terms-summary-content-no-data">
    Please select an instrument.
  </div>
</div>