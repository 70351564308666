import { WatchListService } from '../services/watch-list/watch-list.service';
import { IWatchList } from '../models/watch-lists/watch-list';
import { LvPermissionService } from '@lv-core-ui/services';
import { LocalStorage, LvUtil } from '@lv-core-ui/util';
import { ContingentConversionScheduleItem } from '@lv-convertible-bond/models';
import { AuthorizationService } from '@lv-core-ui/services/authorization/authorization.service';

export interface IWatchListViewConfig {
  excludeSystemWatchLists?: boolean;
  excludeSharedWatchLists?: boolean;
  excludeWatchListGroups?: boolean;
}

export class WatchListView {

  private readonly _dataServiceTrial = 'Data Service Trial';
  constructor(
    private _authorizationService: AuthorizationService,
    private _permissionService: LvPermissionService,
    private watchListService: WatchListService
  ) {}

  async getView(viewConfig?: IWatchListViewConfig, hideDSTrialWL: boolean = false): Promise<IWatchList[]> {
    try {
      const config: IWatchListViewConfig = viewConfig || this.getDefaultConfig();

      const user = LocalStorage.getJwtUser();
      const userId = user.UserId.toString();

      const response = await this.watchListService.getAvailableWatchLists();

      let favourites = response.watchLists.filter(a => a.ownerId === userId && a.isFavourite);
      const my = response.watchLists.filter(a => a.ownerId === userId && !a.isFavourite);

      // todo will be refactored to use user permissions
      const canSeeDataServiceTrialWl = this._permissionService.hasPermission('INSTRUMENT_MONITOR', 'DATA_SERVICE_TRIAL_WATCH_LIST');
      if (canSeeDataServiceTrialWl && !hideDSTrialWL && response.watchLists.filter(x => x.isSystem && !x.ownerId).length > 0) {
        my.push(response.watchLists.filter(x => x.isSystem && !x.ownerId && x.listName === this._dataServiceTrial)[0]);
      }

      let shared = [] as IWatchList[];
      const system = [] as IWatchList[];

      if (!config.excludeSharedWatchLists) {
        favourites = response.watchLists.filter(a => a.isFavourite);
        shared = response.watchLists.filter(a => a.ownerId !== userId && a.isShared && !a.isFavourite);
      }

      const hasAnyModule = this._permissionService.hasAnyModule(['ANALYTICS', 'MEMBERSHIP']);
      const canSeeAllInstruments = this._permissionService.hasPermission('INSTRUMENT_MONITOR', 'ALL_INSTRUMENTS');
      if (!config.excludeSystemWatchLists && (hasAnyModule || canSeeAllInstruments)) {
        // if (this.permissionService.hasPermission(Permissions.MODULE, Permissions.ALL_INSTRUMENTS)) {
        const allInstr = await this.watchListService.getAllInstrumentsWatchList();
        allInstr.isSystemAll = true;

        if (allInstr.isFavourite) {
          favourites.push(allInstr);
        }
        else {
          system.push(allInstr);
        }
        // }

        // if (this.permissionService.hasPermission(Permissions.MODULE, Permissions.NEW_ISSUE)) {
        const newIssue = await this.watchListService.getNewIssueWatchList();
        newIssue.isSystemNewIssue = true;

        if (newIssue.isFavourite) {
          favourites.push(newIssue);
        }
        else {
          system.push(newIssue);
        }
        // }

        const hasEstimatePolicy = await this._authorizationService.hasPermission('API', 'ESTIMATE');

        if (hasEstimatePolicy) {
          const estimates = await this.watchListService.getEstimatesWatchList();
          estimates.isSystem = true;

          if (estimates.isFavourite) {
            favourites.push(estimates);
          }
          else {
            system.push(estimates);
          }
        }
      }

      favourites.sort(LvUtil.sortBy('listName', false));
      my.sort(LvUtil.sortBy('listName', false));
      shared.sort(LvUtil.sortBy('listName', false));
      system.sort(LvUtil.sortBy('listName', false));

      if (config.excludeWatchListGroups) {
        return [
          ...favourites,
          ...my,
          ...shared,
          ...system
        ] as IWatchList[];
      }
      else {
        return [
          ...(favourites.length > 0 ? [this.getGroupItem('Favourites'), ...favourites] : []),
          ...(my.length > 0 ? [this.getGroupItem('My'), ...my] : []),
          ...(shared.length > 0 ? [this.getGroupItem('Shared'), ...shared] : []),
          ...(system.length > 0 ? [this.getGroupItem('Leversys'), ...system] : [])
        ] as IWatchList[];
      }
    }
    catch (error) {
      throw error;
    }
  }

  private getDefaultConfig(): IWatchListViewConfig {
    return {
      excludeSystemWatchLists: false,
      excludeSharedWatchLists: false
    };
  }

  private getGroupItem(name: string) {
    return {
      id: Math.ceil(Math.random() * 100000000),
      listName: name,
      isGroup: true
    } as IWatchList;
  }
}
