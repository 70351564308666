import { Input, ChangeDetectorRef, Renderer2, Directive } from '@angular/core';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export class LvModalComponent {
  @Input() maskClass: string;
  @Input() modalClass: string;

  isOpened: boolean;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public renderer: Renderer2
  ) {
    this.isOpened = false;
  }

  public open(): void {
    if (!this.isOpened) {
      this.isOpened = true;

      this.disableScrolling();
      this.changeDetectorRef.detectChanges();
    }
  }

  public close(): void {
    if (this.isOpened) {
      this.isOpened = false;

      this.enableScrolling();
      this.changeDetectorRef.detectChanges();
    }
  }

  public enableScrolling() {
    this.renderer.setStyle(document.body, 'overflow', 'visible');
  }

  public disableScrolling() {
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }
}
