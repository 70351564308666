
<lv-flex-box class="lv-credit-parameters">
  <form #form="ngForm">
    <lv-flex-box *ngIf="IsCdsFlat"
      data-cy="DM-241"
      data-cy-second="DM-258"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditCdsSpreadTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'cdsSpreadTooltip'">
            CDS Spread
          </span>
          <lv-xl-label *ngIf="isIssuerCredit" [field]="'CREDIT_CDS'"></lv-xl-label>
          <lv-xl-label *ngIf="!isIssuerCredit" [field]="'CREDIT_CDS_UND'"></lv-xl-label>
      </div>

      <div class="lv-input-field">
        <lv-numeric-text-box
          name="cdsSpread"
          [format]="numberFormatBps"
          [lvId]="getLvId('cds-spread')"
          [decimals]="numberOfDecimalsBps"
          [(ngModel)]="creditParameters.cdsSpread"
          (didBlur)="onCreditParametersChange()"
          (didKeyDownEnter)="onCreditParametersChange()"
          required>
        </lv-numeric-text-box>
        <span class="lv-sufix-label">bps</span>
      </div>
    </lv-flex-box>

    <lv-flex-box *ngIf="IsCdsFlat"
      data-cy="DM-242"
      data-cy-second="DM-259"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditCdsUpfrontTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'cdsUpfrontTooltip'">
            CDS Upfront
          </span>
          <lv-xl-label *ngIf="isIssuerCredit" [field]="'CREDIT_CDS_UPFRONT'"></lv-xl-label>
          <lv-xl-label *ngIf="!isIssuerCredit" [field]="'CREDIT_CDS_UPFRONT_UND'"></lv-xl-label>
      </div>

      <div class="lv-input-field">
        <lv-numeric-text-box
          name="cdsUpfront"
          [lvId]="getLvId('cds-up-front')"
          [format]="numberFormat"
          [decimals]="numberOfDecimalsPercetenge"
          [(ngModel)]="creditParameters.cdsUpfront"
          (didBlur)="onCreditParametersChange()"
          (didKeyDownEnter)="onCreditParametersChange()"
          required>
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>

    <lv-flex-box *ngIf="IsCds"
      data-cy="DM-243"
      data-cy-second="DM-260"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditRecoveryCdsTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'recoveryCdsTooltip'">
            Recovery (CDS)
          </span>
          <lv-xl-label *ngIf="isIssuerCredit" [field]="'CREDIT_REC_CDS'"></lv-xl-label>
          <lv-xl-label *ngIf="!isIssuerCredit" [field]="'CREDIT_REC_CDS_UND'"></lv-xl-label>
      </div>

      <div class="lv-input-field">
        <lv-numeric-text-box
          name="recoveryRateCDS"
          [lvId]="getLvId('recovery-rate-cds')"
          [format]="numberFormat"
          [decimals]="numberOfDecimalsPercetenge"
          [(ngModel)]="creditParameters.recoveryRateCDS"
          (didBlur)="onCreditParametersChange()"
          (didKeyDownEnter)="onCreditParametersChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box >

    <lv-flex-box  *ngIf="IsCds"
      data-cy="DM-276"
      data-cy-second="DM-284"
      class="lv-input-field"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditBadDayConventionTooltipTemplate"
        filter="span:not(lv-xl-label span)">
        <span [attr.credit-tooltip-id]="'badDayConventionTooltip'">
          Bad Day Convention
        </span>
        <lv-xl-label *ngIf="isIssuerCredit" [field]="'CREDIT_BAD_DAY'"></lv-xl-label>
        <lv-xl-label *ngIf="!isIssuerCredit" [field]="'CREDIT_BAD_DAY_UND'"></lv-xl-label>
      </div>

      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          [data]="badDayConversion.items"
          [lvId]="getLvId('bad-day-convention')"
          valueField="id"
          textField="text"
          [valuePrimitive]="true"
          name="badDayConversion"
          [(ngModel)]="creditParameters.badDayConversion"
          (ngModelChange)="onCreditParametersChange()"
          [popupSettings]="{ width: 136 }">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>

    <lv-flex-box *ngIf="IsCds"
      data-cy="DM-277"
      data-cy-second="DM-285"
      class="lv-input-field"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditAccuredOnDefaultTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'accuredOnDefaultTooltip'">
            Accrued on Default
          </span>
          <lv-xl-label *ngIf="isIssuerCredit" [field]="'CREDIT_ACC_DFLT'"></lv-xl-label>
          <lv-xl-label *ngIf="!isIssuerCredit" [field]="'CREDIT_ACC_DFLT_UND'"></lv-xl-label>
      </div>

      <kendo-dropdownlist name="accruedOnDefault"
        [data]="creditAccuredOnDefaultSource.items"
        [lvId]="getLvId('accrued-on-default')"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="creditParameters.accruedOnDefault"
        (ngModelChange)="onCreditParametersChange()">
      </kendo-dropdownlist>
    </lv-flex-box>

    <lv-flex-box *ngIf="IsDefaultRateFlat"
      data-cy="DM-246"
      data-cy-second="DM-261"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditDefaultRateTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'defaultRateTooltip'">
            Default Rate
          </span>
          <lv-xl-label *ngIf="isIssuerCredit" [field]="'CREDIT_DFLT_FLAT'"></lv-xl-label>
          <lv-xl-label *ngIf="!isIssuerCredit" [field]="'CREDIT_DFLT_FLAT_UND'"></lv-xl-label>
      </div>

      <div class="lv-input-field">
        <lv-numeric-text-box
          name="defaultRate"
          [lvId]="getLvId('default-rate')"
          [format]="numberFormat"
          [decimals]="numberOfDecimalsPercetenge"
          [(ngModel)]="creditParameters.defaultRate"
          (didBlur)="onCreditParametersChange()"
          (didKeyDownEnter)="onCreditParametersChange()"
          required>
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>

    <lv-flex-box *ngIf="IsFlatSpread"
      data-cy="DM-240"
      data-cy-second="DM-257"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'flatSpreadTooltip'">
            Flat Spread
          </span>
          <lv-xl-label data-cy="CREDIT_FLAT" *ngIf="isIssuerCredit" [field]="'CREDIT_FLAT'"></lv-xl-label>
          <lv-xl-label *ngIf="!isIssuerCredit" [field]="'CREDIT_FLAT_UND'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="flatCreditSpread"
          [lvId]="getLvId('flat-spread')"
          [format]="numberFormatBps"
          [decimals]="numberOfDecimalsBps"
          [(ngModel)]="creditParameters.flatCreditSpread"
          (didBlur)="onCreditParametersChange()"
          (didKeyDownEnter)="onCreditParametersChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">bps</span>
      </div>

    </lv-flex-box>

    <lv-flex-box *ngIf="IsNotSurProb"
      data-cy="DM-274"
      data-cy-second="DM-282"
      class="lv-input-field"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditFrequencyTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'frequencyTooltip'">
            Frequency
          </span>
          <lv-xl-label *ngIf="isIssuerCredit" [field]="'CREDIT_FREQ'"></lv-xl-label>
          <lv-xl-label *ngIf="!isIssuerCredit" [field]="'CREDIT_FREQ_UND'"></lv-xl-label>
      </div>

      <kendo-dropdownlist name="frequency"
        [data]="creditFrequencySource.items"
        [lvId]="getLvId('frequency')"
        valueField="id"
        textField="text"
        [(ngModel)]="creditParameters.frequency"
        [valuePrimitive]="true"
        (ngModelChange)="onCreditParametersChange()">
      </kendo-dropdownlist>
    </lv-flex-box>

    <lv-flex-box *ngIf="IsNotSurProb"
      data-cy="DM-275"
      data-cy-second="DM-283"
      class="lv-input-field"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditbasisTooltipTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'basisTooltip'">
            Basis
          </span>
          <lv-xl-label *ngIf="isIssuerCredit" [field]="'CREDIT_BASIS'"></lv-xl-label>
          <lv-xl-label *ngIf="!isIssuerCredit" [field]="'CREDIT_BASIS_UND'"></lv-xl-label>
      </div>

      <kendo-dropdownlist name="basis"
        [data]="creditBasisSource.items"
        [lvId]="getLvId('basis')"
        valueField="id"
        textField="text"
        [(ngModel)]="creditParameters.basis"
        [valuePrimitive]="true"
        (ngModelChange)="onCreditParametersChange()">
      </kendo-dropdownlist>
    </lv-flex-box>

    <lv-flex-box *ngIf="IsJumbToUnderlyingDefaultVisible"
      data-cy-second="DM-245"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditJumpToUndDefaultTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'jumpToUndDefaultTooltip'">
            Jump to Und. Default
          </span>
          <lv-xl-label [field]="'CREDIT_UND_DEF_JUMP'"></lv-xl-label>
      </div>

      <div class="lv-input-field">
        <lv-numeric-text-box
          name="jumpOnUnderlyingDefault"
          [lvId]="getLvId('jump-on-underlying-default')"
          [format]="numberFormat"
          [decimals]="numberOfDecimalsPercetenge"
          [(ngModel)]="creditParameters.jumpOnUnderlyingDefault"
          (didBlur)="onCreditParametersChange()"
          (didKeyDownEnter)="onCreditParametersChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>

    <lv-flex-box *ngIf="IsRecoveryBondVisible"
      data-cy="DM-244"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditrecoveryBondTooltipTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'recoveryBondTooltip'">
            Recovery (Bond)
          </span>
          <lv-xl-label [field]="'CREDIT_REC_BOND'"></lv-xl-label>
      </div>

      <div class="lv-input-field">
        <lv-numeric-text-box
          name="recoveryRateBond"
          [lvId]="getLvId('recovery-bond')"
          [format]="numberFormat"
          [decimals]="numberOfDecimalsPercetenge"
          [(ngModel)]="creditParameters.recoveryRateBond"
          (didBlur)="onCreditParametersChange()"
          (didKeyDownEnter)="onCreditParametersChange()"
          required>
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>

    <lv-flex-box *ngIf="isCreditFactorModelVisible"
      data-cy="DM-278"
      class="lv-input-field"
      direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditFactorModelTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'creditFactorModelTooltip'">
            Credit Factor Model
          </span>
          <lv-xl-label *ngIf="isIssuerCredit" [field]="'CREDIT_FACT_MODEL'"></lv-xl-label>
          <lv-xl-label *ngIf="!isIssuerCredit" [field]="'CREDIT_FACT_MODEL'"></lv-xl-label>
      </div>
      <kendo-dropdownlist name="creditModelFactor"
        [data]="creditModelFactor.items"
        [lvId]="getLvId('credit-factor-model')"
        valueField="id"
        textField="text"
        [(ngModel)]="creditParameters.creditModelFactor"
        (ngModelChange)="onCreditParametersChange()"
        [valuePrimitive]="true"
        required>
      </kendo-dropdownlist>
    </lv-flex-box>

    <lv-credit-terms-structure
      *ngIf="IsTermStructure"
      data-cy="DM-262"
      data-cy-second="DM-266"
      [lvId]="getLvId('credit-term-structure')"
      [scheduleItems]="creditParameters.creditTermStructure"
      [creditSource]="creditSource"
      [creditParametersSource]="creditParametersSource"
      (creditScheduleValueChange)="onCreditParametersChange()"
      [isXlLabelVisible]="isXlLabelOnTermsStructureViseible">
    </lv-credit-terms-structure>

    <lv-credit-terms-structure
      *ngIf="IsCDSTermStructure"
      data-cy="DM-263"
      data-cy-second="DM-267"
      [lvId]="getLvId('cds-credit-term-structure')"
      [scheduleItems]="creditParameters.creditCDSTermStructure"
      [creditSource]="creditSource"
      [creditParametersSource]="creditParametersSource"
      (creditScheduleValueChange)="onCreditParametersChange()"
      [isXlLabelVisible]="isXlLabelOnCDSTermsStructureViseible">
    </lv-credit-terms-structure>

    <lv-credit-terms-structure
      *ngIf="IsDefaultRateTermStructure"
      data-cy="DM-264"
      data-cy-second="DM-268"
      [lvId]="getLvId('default-rate-credit-term-structure')"
      [scheduleItems]="creditParameters.creditDefaultRateTermStructure"
      [creditSource]="creditSource"
      [creditParametersSource]="creditParametersSource"
      (creditScheduleValueChange)="onCreditParametersChange()"
      [isXlLabelVisible]="isXlLabelOnDefaultRateTermsStructureViseible">
    </lv-credit-terms-structure>

    <lv-credit-terms-structure
      *ngIf="IsSurvivalProbabilityTermStructure"
      data-cy="DM-265"
      data-cy-second="DM-269"
      [lvId]="getLvId('survival-probability-credit-term-structure')"
      [scheduleItems]="creditParameters.creditSurvivalProbabilityTermStructure"
      [creditSource]="creditSource"
      [creditParametersSource]="creditParametersSource"
      (creditScheduleValueChange)="onCreditParametersChange()"
      [isXlLabelVisible]="isXlLabelOnSurvivalProbabilityTermsStructureViseible">
    </lv-credit-terms-structure>
  </form>
</lv-flex-box>

<ng-template #creditTooltipTemplate>
  <ng-container *ngIf="isIssuerCredit">
    <lv-tooltip dm="dM-240"></lv-tooltip>
  </ng-container>
  <ng-container *ngIf="!isIssuerCredit">
    <lv-tooltip dm="dM-257"></lv-tooltip>
  </ng-container>
</ng-template>
<ng-template #creditJumpToUndDefaultTooltipTemplate>
  <lv-tooltip dm="dM-245"></lv-tooltip>
</ng-template>
<ng-template #creditrecoveryBondTooltipTooltipTemplate>
  <lv-tooltip dm="dM-244"></lv-tooltip>
</ng-template>
<ng-template #creditFactorModelTooltipTemplate>
  <lv-tooltip dm="dM-278"></lv-tooltip>
</ng-template>
<ng-template #creditDefaultRateTooltipTemplate>
  <ng-container *ngIf="isIssuerCredit">
    <lv-tooltip dm="dM-246"></lv-tooltip>
  </ng-container>
  <ng-container *ngIf="!isIssuerCredit">
    <lv-tooltip dm="dM-261"></lv-tooltip>
  </ng-container>
</ng-template>
<ng-template #creditAccuredOnDefaultTooltipTemplate>
  <ng-container *ngIf="isIssuerCredit">
    <lv-tooltip dm="dM-277"></lv-tooltip>
  </ng-container>
  <ng-container *ngIf="!isIssuerCredit">
    <lv-tooltip dm="dM-285"></lv-tooltip>
  </ng-container>
</ng-template>
<ng-template #creditBadDayConventionTooltipTemplate>
  <ng-container *ngIf="isIssuerCredit">
    <lv-tooltip dm="dM-276"></lv-tooltip>
  </ng-container>
  <ng-container *ngIf="!isIssuerCredit">
    <lv-tooltip dm="dM-284"></lv-tooltip>
  </ng-container>
</ng-template>
<ng-template #creditRecoveryCdsTooltipTemplate>
  <ng-container *ngIf="isIssuerCredit">
    <lv-tooltip dm="dM-243"></lv-tooltip>
  </ng-container>
  <ng-container *ngIf="!isIssuerCredit">
    <lv-tooltip dm="dM-260"></lv-tooltip>
  </ng-container>
</ng-template>
<ng-template #creditCdsUpfrontTooltipTemplate>
  <ng-container *ngIf="isIssuerCredit">
    <lv-tooltip dm="dM-242"></lv-tooltip>
  </ng-container>
  <ng-container *ngIf="!isIssuerCredit">
    <lv-tooltip dm="dM-259"></lv-tooltip>
  </ng-container>
</ng-template>
<ng-template #creditCdsSpreadTooltipTemplate>
  <ng-container *ngIf="isIssuerCredit">
    <lv-tooltip dm="dM-241"></lv-tooltip>
  </ng-container>
  <ng-container *ngIf="!isIssuerCredit">
    <lv-tooltip dm="dM-258"></lv-tooltip>
  </ng-container>
</ng-template>
<ng-template #creditFrequencyTooltipTemplate>
  <ng-container *ngIf="isIssuerCredit">
    <lv-tooltip dm="dM-274"></lv-tooltip>
  </ng-container>
  <ng-container *ngIf="!isIssuerCredit">
    <lv-tooltip dm="dM-282"></lv-tooltip>
  </ng-container>
</ng-template>
<ng-template #creditbasisTooltipTooltipTemplate>
  <ng-container *ngIf="isIssuerCredit">
    <lv-tooltip dm="dM-275"></lv-tooltip>
  </ng-container>
  <ng-container *ngIf="!isIssuerCredit">
    <lv-tooltip dm="dM-283"></lv-tooltip>
  </ng-container>
</ng-template>
