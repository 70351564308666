<lv-flex-box class="lv-takeover-protection" data-cy="DM-1233">
  <lv-flex-box direction="row" data-cy="DM-420">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="takeoverCompensationSourceTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'takeoverCompensationSourceTooltip'">
        Takeover Compensation
      </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        [attr.id]="view.takeOverCompensationCheckBoxId"
        name="callMakeWhole"
        [(ngModel)]="view.model.useTakeoverProtection"
        (ngModelChange)="takeOverCompensationChange()"/>
      <label class="k-checkbox-label" [attr.for]="view.takeOverCompensationCheckBoxId"></label>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-1421">
    <div class="lv-label lv-label-field"
       showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="takeoverPutSourceTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'takeoverPutSourceTooltip'">
        Takeover Put
      </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        name="takeoverPut"
        [attr.id]="view.takeoverPutCheckBoxId"
        [(ngModel)]="view.model.takeoverPut"
        (ngModelChange)="takeOverPutChange()"/>
      <label class="k-checkbox-label" [attr.for]="view.takeoverPutCheckBoxId"></label>
    </div>
  </lv-flex-box>
  <lv-flex-box>
    <lv-flex-box *ngIf="view.model.useTakeoverProtection" direction="column">
      <lv-fieldset-panel legend="TAKEOVER COMPENSATION">
        <lv-flex-box *ngIf="view.areProtectionDateFieldsVisible" direction="row" data-cy="DM-1424">
          <div class="lv-label lv-label-field lv-label-before-checkbox"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="protectionStartDateSourceTooltipTemplate"
            filter="span">
            <span [attr.data-tooltip-id]="'protectionStartDateSourceTooltip'">
              Protection Start Date
            </span>
          </div>
          <lv-datepicker
            [(ngModel)]="view.startDate"
            [checked]="view.startDateEnabled"
            (didCheckboxValueChange)="protectionStartDateCheckBoxChange($event)"
            (ngModelChange)="onModelChange()">
          </lv-datepicker>
        </lv-flex-box>
        <lv-flex-box *ngIf="view.areProtectionDateFieldsVisible" direction="row" data-cy="DM-1425">
          <div class="lv-label lv-label-field lv-label-before-checkbox"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="protectionEndDateSourceTooltipTemplate"
            filter="span">
            <span [attr.data-tooltip-id]="'protectionEndDateSourceTooltip'">
              Protection End Date
            </span>
          </div>
          <lv-datepicker
            [(ngModel)]="view.endDate"
            [checked]="view.endDateEnabled"
            (didCheckboxValueChange)="protectionEndDateCheckBoxChange($event)"
            (ngModelChange)="onModelChange()">
          </lv-datepicker>
        </lv-flex-box>
        <lv-flex-box direction="row" data-cy="DM-1426">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="compensationTypeSourceTooltipTemplate"
            filter="span">
            <span [attr.data-tooltip-id]="'compensationTypeSourceTooltip'">
              Compensation Type
            </span>
          </div>
          <div class="lv-input-field lv-input-field--auto">
            <kendo-dropdownlist
              name="takeoverCompensationType"
              [data]="view.takeoverCompensationTypeLookup.items"
              valueField="id"
              textField="text"
              [valuePrimitive]="true"
              [popupSettings]="{ width: 217 }"
              [(ngModel)]="view.model.takeoverCompensationType"
              (ngModelChange)="onModelChange()">
            </kendo-dropdownlist>
          </div>
        </lv-flex-box>
        <lv-premium-compensation-formula *ngIf="view.isPremiumCompensationFormulaVisible"
          [model]="view.model"
          (didSetPremiumCompensationFormula)="onModelChange()">
        </lv-premium-compensation-formula>
        <lv-averaging-period-details *ngIf="view.isAveragingPeriodDetailsVisible"
          [model]="view.model"
          (didSetAveragingPeriodDetails)="onModelChange()">
        </lv-averaging-period-details>
        <lv-flex-box *ngIf="view.isRatchetMatrixVisible">
          <lv-flex-box direction="row" data-cy="DM-2437">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="offerValueTypeSourceTooltipTemplate"
              filter="span">
              <span [attr.data-tooltip-id]="'offerValueTypeSourceTooltip'">
                Offer Value Type
              </span>
            </div>
              <div class="lv-input-field">
                <kendo-dropdownlist
                  name="offerValueType"
                  [data]="view.offerValueTypeLookup.items"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.model.ratchetMatrixOfferValueType"
                  (ngModelChange)="onOfferValueTypeChange()">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>
            <lv-flex-box direction="row" data-cy="DM-2438">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="compensationValueTypeSourceTooltipTemplate"
                filter="span">
                <span [attr.data-tooltip-id]="'compensationValueTypeSourceTooltip'">
                  Compensation Value Type
                </span>
              </div>
              <div class="lv-input-field lv-input-field--auto">
                <kendo-dropdownlist
                  name="compensationValueType"
                  [data]="view.compensationValueTypeLookup.items"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [popupSettings]="{ width: 188 }"
                  [(ngModel)]="view.model.ratchetMatrixCompensationValueType"
                  (ngModelChange)="onModelChange()">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>
            <lv-flex-box direction="row" class="lv-label-ratchet-matrix">
              <div class="lv-label lv-label-field lv-label--title"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="ratchetMatrixSourceTooltipTemplate"
                filter=".lv-label--title">RATCHET MATRIX</div>
            </lv-flex-box>
            <lv-flex-box direction="row" data-cy="DM-2439">
              <div class="lv-vertical-text"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="ratchetMatrixSourceTooltipTemplate"
                filter="span">
                <span [attr.data-tooltip-id]="'startDateRatchetSourceTooltip'">
                  Start Date
                </span>
              </div>
              <lv-make-whole-ratchet-matrix
                class="lv-ratchet-matrix"
                [model]="view.model.ratchetMatrixMembers"
                [customMakeWholeData]="view.customMakeWholeData"
                (didSetRatchetMatrix)="onModelChange()">
              </lv-make-whole-ratchet-matrix>
            </lv-flex-box>
        </lv-flex-box>
        <lv-ratchet-schedule *ngIf="view.isRatchetScheduleVisible"
          [model]="view.model"
          (didSetRatchetSchedule)="onModelChange()">
        </lv-ratchet-schedule>
      </lv-fieldset-panel>
    </lv-flex-box>

    <lv-flex-box class="lv-takeover-put-fieldset" *ngIf="view.model.takeoverPut">
      <lv-fieldset-panel legend="TAKEOVER PUT">
        <lv-takeover-put-parameters
          [model]="view.model"
          (didSetTakeoverPutParameters)="onModelChange()">
        </lv-takeover-put-parameters>
      </lv-fieldset-panel>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<ng-template #takeoverCompensationSourceTooltipTemplate>
  <lv-tooltip dm="dM-420"></lv-tooltip>
</ng-template>
<ng-template #takeoverPutSourceTooltipTemplate>
  <lv-tooltip dm="dM-1421"></lv-tooltip>
</ng-template>
<ng-template #protectionStartDateSourceTooltipTemplate>
  <lv-tooltip dm="dM-1424"></lv-tooltip>
</ng-template>
<ng-template #protectionEndDateSourceTooltipTemplate>
  <lv-tooltip dm="dM-1425"></lv-tooltip>
</ng-template>
<ng-template #compensationTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1426"></lv-tooltip>
</ng-template>
<ng-template #offerValueTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-2437"></lv-tooltip>
</ng-template>
<ng-template #compensationValueTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-2438"></lv-tooltip>
</ng-template>
<ng-template #ratchetMatrixSourceTooltipTemplate>
  <lv-tooltip dm="dM-2439"></lv-tooltip>
</ng-template>
