import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'lv-editor-panel',
  templateUrl: './lv-editor-panel.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEditorPanelComponent implements OnInit {

  @Input() isLoading: boolean;
  @Input() showError: boolean;

  @Output() didSave: EventEmitter<void>;
  @Output() didCancel: EventEmitter<void>;

  get isSaveDisabled(): boolean {
    return this.isLoading || this.showError;
  }

  constructor() {
    this.didSave = new EventEmitter<any>();
    this.didCancel = new EventEmitter<any>();
    this.showError = false;
  }

  ngOnInit() {
  }

  save() {
    this.didSave.emit();
  }

  cancel() {
    this.didCancel.emit();
  }
}
