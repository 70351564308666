<div class="lv-notification-panel"
  [class.lv-notification-panel-floating-header]="floatingHeader">
  <div *ngIf="headerVisible"
    class="lv-notification-panel-header">
    <div class="lv-notification-panel-header-content">
      <ng-content select="[header]"></ng-content>
    </div>
    <div class="lv-notification-panel-header-options"
      [ngClass]="optionsPosition">
      <ng-content select="[options]"></ng-content>
      <div class="lv-notification-panel-header-option"
        (click)="onLinkOptionClick()">
          <div class="lv-notification-panel-link"
            [style.background-color]="backgroundLinkColor"
            title="Request link">
            <i class="k-icon k-i-link-vertical rotate-left"></i>
          </div>
      </div>
    </div>
  </div>
  <div class="lv-notification-panel-content">
    <ng-content select="[content]"></ng-content>
  </div>
</div>