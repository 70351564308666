
export class DateExtensions {
  static getTimeWithOffset(date: Date): number {
    const jsDate = new Date(date);
    const time = jsDate.getTime(); // in ms
    const timeOffset = jsDate.getTimezoneOffset() * 60 * 1000; // in ms

    return time - timeOffset;
  }

  static getDateWithoutOffset(ms: number): Date {
    const withTimeZone = new Date(ms);
    const time = withTimeZone.getTime(); // in ms
    const timeOffset = withTimeZone.getTimezoneOffset() * 60 * 1000; // in ms

    return new Date(time + timeOffset);
  }

  static getTimeAtStartOfDay(date: Date): number {
    const dateWithoutOffset = DateExtensions.getDateWithoutOffset(date.getTime());

    const start = new Date(dateWithoutOffset.getFullYear(), dateWithoutOffset.getMonth(), dateWithoutOffset.getDate(), 0, 0, 0);

    return DateExtensions.getTimeWithOffset(start);
  }

  static toOADate(date: Date) {
    if (!date) {
      return null;
    }
    const utc18991230 = Date.UTC(1899, 11, 30);
    const msPerDay = 24 * 60 * 60 * 1000;

    if (date instanceof Date) {
      const dateInNumberFormat = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
      return (dateInNumberFormat - utc18991230) / msPerDay;
    }
  }

  /**
   * Adds number of days to existing date
   * @param date Date value
   * @param days Number of days to add
   * @returns Shifted date value
   */
  static addDays(date: Date, days: number) {
    if (!date) {
      return null;
    }

    const daysToMove = new Date(date);

    if (!days) {
      return daysToMove;
    }

    daysToMove.setDate(daysToMove.getDate() + days);
    return daysToMove;
  }

  /**
   * If date is represented as a utc string with z at the end, remove z from the
   * end of the value and create date string in local time representation
   * @param date Date string value
   * @returns Date string value in local time representation
   */
  static getDateInUtcFromUtcString(date: string): Date {
    return new Date(date.replace('Z', ''));
  }
}
