import { EquityType } from './Enums (5)';


export class EquityData {
    
    name: string;
    issuerName: string;
    isin: string;
    countryCode: string;
    exchangeCode: string;
    currencyCode: string;
    tradingUnit: number;
    priceScale: number;
    riskCountryCode: string;
    type: EquityType;
    cusip: string;
    figi: string;
}

