import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, Input, Output, EventEmitter,
  ElementRef, ChangeDetectorRef, OnChanges, ViewRef } from '@angular/core';

import { ILvWorkspace, getDefaultWorkspace } from './lv-workspace';

@Component({
  selector: 'lv-workspace',
  templateUrl: './lv-workspace.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvWorkspaceComponent implements OnInit, OnChanges {
  @ViewChild('inputElement') inputElement: ElementRef;
  @ViewChild('nameElement') nameElement: ElementRef;

  @Input() state: ILvWorkspace;

  @Output() doSelect: EventEmitter<ILvWorkspace>;
  @Output() didEdit: EventEmitter<ILvWorkspace>;
  @Output() doClose: EventEmitter<ILvWorkspace>;

  newName: string;
  nameWidth: number;
  editMode: boolean;

  get showOptions(): boolean {
    if (this.editMode) {
      return false;
    }

    return this.state.isEditable || this.state.isClosable;
  }

  get showCloseIcon(): boolean {
    return this.state.isClosable && !this.state.isDisabled && this.state.isSelected;
  }

  get showEditMode(): boolean {
    if (!this.editMode) {
      return false;
    }

    return this.state.isSelected;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.editMode = false;
    this.state = getDefaultWorkspace();

    this.doSelect = new EventEmitter<ILvWorkspace>();
    this.didEdit = new EventEmitter<ILvWorkspace>();
    this.doClose = new EventEmitter<ILvWorkspace>();
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (!this.state.isSelected) {
      this.editMode = false;
    }
  }

  onSelect() {
    if (!this.state.isDisabled && !this.state.isSelected) {
      this.doSelect.emit({ ...this.state });
    }
  }

  onEdit() {
    if (!this.state.isDisabled) {
      this.newName = this.state.name;
      this.editMode = true;

      const nameRect = this.nameElement.nativeElement.getBoundingClientRect();
      if (nameRect.width > 126) {
        this.nameWidth = 126;
      }
      else {
        this.nameWidth = nameRect.width + 14;
      }

      this.changeDetectorRef.detectChanges();

      setTimeout(() => {
        this.inputElement.nativeElement.focus();
      }, 10);
    }
  }

  onClose() {
    if (!this.state.isDisabled && this.showCloseIcon) {
      this.doClose.emit({ ...this.state });
    }
  }

  doUpdateName(name: string) {
    this.state.name = name;
    this.newName = this.state.name;
    this.editMode = false;

    this.didEdit.emit({ ...this.state });
    if (!(this.changeDetectorRef as ViewRef).destroyed) {
      this.changeDetectorRef.detectChanges();
    }
  }

  onInputKeyPress(event: KeyboardEvent) {
    // tslint:disable-next-line
    if (!this.state.isDisabled && event.keyCode === 13) { // return key
      this.doUpdateName(this.newName || this.state.name);
    }
  }

  onInputKeyUp(event: KeyboardEvent) {
    // tslint:disable-next-line
    if (!this.state.isDisabled && event.keyCode === 27) { // esc key
      this.doUpdateName(this.state.name);
    }
  }

  onInputBlur(event: KeyboardEvent) {
    this.doUpdateName(this.newName || this.state.name);
  }
}
