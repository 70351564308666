<lv-flex-box class="coupon-settings-column">
  <lv-flex-box 
    data-cy="DM-1531"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="couponTypeSourceTooltipTemplate"
      filter="span">
      <span> Coupon Type </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        lvId="couponCouponType"
        [disabled]="!overrideDefaults"
        name="coupon-type"
        [data]="couponTypeLookup.items"
        [(ngModel)]="settings.settings.type"
        valueField="id"
        textField="text"
        valuePrimitive="true"
        [popupSettings]="{ width: 120 }">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <ng-container>
    <lv-flex-box  
      data-cy="DM-1532"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="couponDayCountSourceTooltipTemplate"
        filter="span">
        <span> Coupon Day Count </span>
      </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          lvId="couponDayCountCmb"
          name="coupon-day-count"
          [disabled]="!overrideDefaults"
          [data]="couponDayCountLookup.items"
          [(ngModel)]="settings.settings.dayCount"
          valueField="id"
          textField="text"
          valuePrimitive="true">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>

    <lv-flex-box 
      data-cy="DM-1533"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="couponFrequencySourceTooltipTemplate"
        filter="span">
        <span> Coupon Frequency </span>
      </div>
      <div class="lv-input-field">
        <kendo-dropdownlist name="coupon-frequency"
          lvId="couponFrequencyCmb"
          [disabled]="!overrideDefaults"
          [data]="couponFrequencyLookup.items"
          [(ngModel)]="settings.settings.frequency"
          valueField="id"
          textField="text"
          valuePrimitive="true">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>

    <lv-flex-box 
      data-cy="DM-1534"
      direction="row"
      class="lv-coupon-date">

      <div class="lv-label lv-label-field lv-label-before-checkbox"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="accrualStartDateSourceTooltipTemplate"
        filter="span">
        <span> Accrual Start Date </span>
      </div>
      <div class="lv-input-field align-sufix">
        <lv-numeric-text-box
          placeholder="T + days"
          class="lv-input-field"
          [disabled]="!overrideDefaults"
          [(ngModel)]="settings.settings.accrualStartDate"
          [format]="formatZero"
          [decimals]="decimalsZero"
          name="accrualStartDate">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">From First Settlement Date</span>
      </div>
    </lv-flex-box>

    <lv-flex-box 
      data-cy="DM-1535"
      direction="row"
      class="lv-coupon-date">

      <div class="lv-label lv-label-field lv-label-before-checkbox"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="accrualEndDateSourceTooltipTemplate"
        filter="span">
        <span> Accrual End Date </span>
      </div>
      <div class="lv-input-field align-sufix">
        <lv-numeric-text-box
          placeholder="T - days"
          class="lv-input-field"
          [(ngModel)]="settings.settings.accrualEndDate"
          [format]="formatZero"
          [disabled]="!overrideDefaults"
          [decimals]="decimalsZero"
          name="accrualEndDate">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">From Maturity Date</span>
      </div>
    </lv-flex-box>

    <lv-flex-box 
      data-cy="DM-1536"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="couponDatesEOMSourceTooltipTemplate"
        filter="span">
        <span> Coupon Dates EOM </span>
      </div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [disabled]="!overrideDefaults"
          [(ngModel)]="settings.settings.couponDatesEOM"
          [attr.id]="couponDatesEomCheckboxId"
          name="coupon-dates-eom">
        <label class="k-checkbox-label"
          [attr.for]="couponDatesEomCheckboxId">
        </label>
      </div>
    </lv-flex-box>

    <lv-flex-box 
      data-cy="DM-1537"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="accrualMethodSourceTooltipTemplate"
        filter="span">
        <span> Accrual Method </span>
      </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          lvId="couponAccrualMethodCmb"
          name="accrual-method"
          [disabled]="!overrideDefaults"
          [data]="accrualMethodLookup.items"
          [(ngModel)]="settings.settings.accrualMethod"
          valueField="id"
          textField="text"
          valuePrimitive="true"
          [popupSettings]="{ width: 235 }">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>

    <lv-flex-box 
      data-cy="DM-1538"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="exCouponPeriodSourceTooltipTemplate"
        filter="span">
        <span> Ex Coupon Period </span>
      </div>
      <lv-numeric-text-box
        class="lv-input-field"
        [(ngModel)]="settings.settings.exCouponPeriod"
        lvId="exCouponPeriod"
        [format]="formatZero"
        [disabled]="!overrideDefaults"
        [decimals]="decimalsZero"
        name="coupon-ex-coupon-per">
      </lv-numeric-text-box>
    </lv-flex-box>

    <lv-flex-box 
      data-cy="DM-1539"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="exCouponPeriodDaysTypeSourceTooltipTemplate"
        filter="span">
        <span> Ex Coupon Period (days type) </span>
      </div>
      <div class="lv-input-field">
        <kendo-dropdownlist
          name="coupon-ex-coupon-per-dd"
          lvId="exCouponPeriodDays"
          [disabled]="!overrideDefaults"
          [data]="exCouponPerLookup.items"
          [(ngModel)]="settings.settings.exCouponPeriodType"
          valueField="id"
          textField="text"
          valuePrimitive="true">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>

    <lv-flex-box 
      data-cy="DM-1540"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="guaranteedCouponsSourceTooltipTemplate"
        filter="span">
        <span> Guaranteed Coupons </span>
      </div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [disabled]="!overrideDefaults"
          [(ngModel)]="settings.settings.isGuaranteed"
          [attr.id]="guaranteedCouponsCheckboxId"
          name="guaranteed-coupons">
        <label class="k-checkbox-label"
          [attr.for]="guaranteedCouponsCheckboxId">
        </label>
      </div>
    </lv-flex-box>

    <lv-flex-box  
      data-cy="DM-1541"
      direction="row"
      class="lv-coupon-date">

      <div class="lv-label lv-label-field lv-label-before-checkbox"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="guaranteedStartDateSourceTooltipTemplate"
        filter="span">
        <span> Guaranteed Start Date </span>
      </div>
      <div class="lv-input-field align-sufix">
        <lv-numeric-text-box
          placeholder="T + days"
          class="lv-input-field"
          [(ngModel)]="settings.settings.guaranteedStartDate"
          [format]="formatZero"
          [disabled]="!overrideDefaults"
          [decimals]="decimalsZero"
          name="guaranteedStartDateEnabled">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">From First Settlement Date</span>
      </div>
    </lv-flex-box>

    <lv-flex-box  
      data-cy="DM-1542"
      direction="row"
      class="lv-coupon-date">

      <div class="lv-label lv-label-field lv-label-before-checkbox"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="guaranteedEndDateSourceTooltipTemplate"
        filter="span">
        <span> Guaranteed End Date </span>
      </div>
      <div class="lv-input-field align-sufix">
        <lv-numeric-text-box
          placeholder="T - days"
          class="lv-input-field"
          [disabled]="!overrideDefaults"
          [(ngModel)]="settings.settings.guaranteedStartEndDate"
          [format]="formatZero"
          [decimals]="decimalsZero"
          name="guaranteedEndDate">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">From Maturity Date</span>
      </div>
    </lv-flex-box>

    <lv-flex-box 
      data-cy="DM-1543"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="hasCoPaySourceTooltipTemplate"
        filter="span">
        <span [attr.data-tooltip-id]="'hasCoPaySourceTooltip'">
          Has Co Pay
        </span>
      </div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          [disabled]="!overrideDefaults"
          type="checkbox"
          [(ngModel)]="settings.settings.hasCoPay"
          [attr.id]="hasCoPayCheckboxId"
          name="coupon-has-co-pay">
        <label class="k-checkbox-label"
          [attr.for]="hasCoPayCheckboxId">
        </label>
      </div>
    </lv-flex-box>
  </ng-container>
</lv-flex-box>

<lv-flex-box class="coupon-settings-column">
  <div class="lv-label-field lv-label lv-label--title">FLOATING COUPON</div>

  <lv-flex-box 
    data-cy="DM-1544"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="referenceTenorSourceTooltipTemplate"
      filter="span">
      <span> Reference Tenor </span>
    </div>
    <div class="lv-input-field ">
      <kendo-dropdownlist
        name="coupon-reference-tenor"
        lvId="referenceTenor"
        [disabled]="!overrideDefaults"
        [data]="referenceTenor.items"
        [(ngModel)]="settings.settings.floating.referenceTenor"
        valueField="id"
        textField="text"
        valuePrimitive="true">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1545"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="fixingDelaySourceTooltipTemplate"
      filter="span">
      <span> Fixing Delay </span>
    </div>
    <lv-numeric-text-box
      class="lv-input-field"
      [disabled]="!overrideDefaults"
      [(ngModel)]="settings.settings.floating.fixingDelay"
      [format]="formatZero"
      [decimals]="decimalsZero"
      name="coupon-floating-fixing-delay">
    </lv-numeric-text-box>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1546"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="multiplierSourceTooltipTemplate"
      filter="span">
      <span> Multiplier </span>
    </div>
    <lv-numeric-text-box
      class="lv-input-field"
      [disabled]="!overrideDefaults"
      [format]="formatFour"
      [(ngModel)]="settings.settings.floating.multiplier"
      [decimals]="decimalsFour"
      name="coupon-floating-multiplier">
    </lv-numeric-text-box>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1547"
    class="space-bottom"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="floorSourceTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'floorSourceTooltip'">
        Floor
      </span>
    </div>
    <div class="lv-input-field align-sufix">
    <lv-numeric-text-box
      name="terms-general-coupon-floating-floor"
      [(ngModel)]="settings.settings.floating.floor"
      [format]="formatFour"
      [disabled]="!overrideDefaults"
      [decimals]="decimalsFour">
    </lv-numeric-text-box>
    <span class="lv-sufix-label">%</span>
  </div>
  </lv-flex-box>
  <div class="lv-label-field lv-label lv-label--title">PIK COUPON</div>

  <lv-flex-box 
    data-cy="DM-1548"
    class="space-bottom"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="PIKCouponTypeSourceTooltipTemplate"
      filter="span">
      <span> PIK Coupon Type </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        [disabled]="!overrideDefaults"
        name="pik-coupon-type"
        [data]="pikCouponTypeLookup.items"
        [(ngModel)]="settings.settings.pik.pikCouponType"
        valueField="id"
        textField="text"
        valuePrimitive="true">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <div class="lv-label-field lv-label lv-label--title">CO PAY</div>

  <lv-flex-box 
    data-cy="DM-1549"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="coPayAccrualStartDateSourceTooltipTemplate"
      filter="span">
      <span> Accrual Start Date </span>
    </div>
    <div class="lv-input-field align-sufix">
      <lv-numeric-text-box
        placeholder="T + days"
        [disabled]="!overrideDefaults"
        class="lv-input-field"
        [(ngModel)]="settings.settings.coPay.coPayAccrualStartDate"
        [format]="formatZero"
        [decimals]="decimalsZero"
        name="coPayAccrualStartDate">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">From First Settlement Date</span>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1550"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="couponFrequencyTooltipTemplate"
      filter="span">
      <span> Frequency </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="copay-frequency"
        [disabled]="!overrideDefaults"
        [(ngModel)]="settings.settings.coPay.coPayFrequency"
        [data]="coPayFrequencyLookup.items"
        valueField="id"
        valuePrimitive="true"
        textField="text">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1551"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="dayCountSourceTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'dayCountSourceTooltip'">
        Day Count
      </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="copay-day-count"
        [(ngModel)]="settings.settings.coPay.coPayDayCount"
        [data]="coPayDayCountLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        valuePrimitive="true"
        textField="text">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1552"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="coPayCouponTypeSourceTooltipTemplate"
      filter="span">
      <span> Co Pay Coupon Type </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="copay-coupon-type"
        [data]="coPayCouponTypeLookup.items"
        [(ngModel)]="settings.settings.coPay.coPayCouponType"
        valueField="id"
        [disabled]="!overrideDefaults"
        valuePrimitive="true"
        textField="text">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1553"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="coPayTriggerTypeSourceTooltipTemplate"
      filter="span">
      <span> Co Pay Trigger Type </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="copay-trigger-type"
        [(ngModel)]="settings.settings.coPay.coPayTriggerType"
        [data]="coPayTriggerTypeLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        valuePrimitive="true"
        textField="text">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
</lv-flex-box>

<ng-template #couponTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1531"></lv-tooltip>
</ng-template>
<ng-template #couponDayCountSourceTooltipTemplate>
  <lv-tooltip dm="dM-1532"></lv-tooltip>
</ng-template>
<ng-template #couponFrequencySourceTooltipTemplate>
  <lv-tooltip dm="dM-1533"></lv-tooltip>
</ng-template>
<ng-template #accrualStartDateSourceTooltipTemplate>
  <lv-tooltip dm="dM-1534"></lv-tooltip>
</ng-template>
<ng-template #accrualEndDateSourceTooltipTemplate>
  <lv-tooltip dm="dM-1535"></lv-tooltip>
</ng-template>
<ng-template #couponDatesEOMSourceTooltipTemplate>
  <lv-tooltip dm="dM-1536"></lv-tooltip>
</ng-template>
<ng-template #accrualMethodSourceTooltipTemplate>
  <lv-tooltip dm="dM-1537"></lv-tooltip>
</ng-template>
<ng-template #exCouponPeriodSourceTooltipTemplate>
  <lv-tooltip dm="dM-1538"></lv-tooltip>
</ng-template>
<ng-template #exCouponPeriodDaysTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1539"></lv-tooltip>
</ng-template>
<ng-template #guaranteedCouponsSourceTooltipTemplate>
  <lv-tooltip dm="dM-1540"></lv-tooltip>
</ng-template>
<ng-template #guaranteedStartDateSourceTooltipTemplate>
  <lv-tooltip dm="dM-1541"></lv-tooltip>
</ng-template>
<ng-template #guaranteedEndDateSourceTooltipTemplate>
  <lv-tooltip dm="dM-1542"></lv-tooltip>
</ng-template>
<ng-template #hasCoPaySourceTooltipTemplate>
  <lv-tooltip dm="dM-1543"></lv-tooltip>
</ng-template>
<ng-template #referenceTenorSourceTooltipTemplate>
  <lv-tooltip dm="dM-1544"></lv-tooltip>
</ng-template>
<ng-template #fixingDelaySourceTooltipTemplate>
  <lv-tooltip dm="dM-1545"></lv-tooltip>
</ng-template>
<ng-template #multiplierSourceTooltipTemplate>
  <lv-tooltip dm="dM-1546"></lv-tooltip>
</ng-template>
<ng-template #multiplierSourceTooltipTemplate>
  <lv-tooltip dm="dM-1546"></lv-tooltip>
</ng-template>
<ng-template #floorSourceTooltipTemplate>
  <lv-tooltip dm="dM-1547"></lv-tooltip>
</ng-template>
<ng-template #PIKCouponTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1548"></lv-tooltip>
</ng-template>
<ng-template #coPayAccrualStartDateSourceTooltipTemplate>
  <lv-tooltip dm="dM-1549"></lv-tooltip>
</ng-template>
<ng-template #couponFrequencyTooltipTemplate>
  <lv-tooltip dm="dM-1550"></lv-tooltip>
</ng-template>
<ng-template #dayCountSourceTooltipTemplate>
  <lv-tooltip dm="dM-1551"></lv-tooltip>
</ng-template>
<ng-template #dayCountSourceTooltipTemplate>
  <lv-tooltip dm="dM-1551"></lv-tooltip>
</ng-template>
<ng-template #coPayCouponTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1552"></lv-tooltip>
</ng-template>
<ng-template #coPayTriggerTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1553"></lv-tooltip>
</ng-template>
