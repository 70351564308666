import { Component, OnInit, Input, Output, EventEmitter, ViewChild,
         ChangeDetectorRef, OnChanges, ViewEncapsulation, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { constants, LvLookupEnum } from '@lv-core-ui/util';
import { LvAdvancedGridComponent, LvAdvancedGridColumn, LvAdvancedGridDateColumn,
         LvAdvancedGridNumericColumn } from '@lv-core-ui/components';
import { LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { MarketDataClipboard } from '@lv-analytics/components';
import { TakeoverProtection, TakeoverProtectionRatchetScheduleItem,
         RatchetScheduleValueTypeDescription } from '@lv-convertible-bond/models';

@Component({
  selector: 'lv-ratchet-schedule',
  templateUrl: './lv-ratchet-schedule.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LvRatchetScheduleComponent implements OnInit, OnChanges {
  @Input() model: TakeoverProtection;
  @Output() didSetRatchetSchedule: EventEmitter<TakeoverProtection>;
  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  private _modelSubscription: Subscription[];

  columns: LvAdvancedGridColumn[];
  scheduleItems: TakeoverProtectionRatchetScheduleItem[];
  parseFn: any;

  ratchetScheduleValueTypeLookup: LvLookupEnum;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService
  ) {

    this.initColumns();
    this.parseFn = this.parserFunction.bind(this);

    this.scheduleItems = [];
    this._modelSubscription = [];
    this.didSetRatchetSchedule = new EventEmitter<TakeoverProtection>();

    this.ratchetScheduleValueTypeLookup = new LvLookupEnum(RatchetScheduleValueTypeDescription);
  }

  private initColumns() {
    this.columns = [];

    const endDateColumn = new LvAdvancedGridDateColumn();
    endDateColumn.title = 'End Date';
    endDateColumn.field = 'endDate';
    endDateColumn.dmKey = 'DM-2340';

    const valueColumn = new LvAdvancedGridNumericColumn();
    valueColumn.title = 'Value';
    valueColumn.field = 'value';
    valueColumn.outputFormat = constants.numberFormat.upToFourDigits;
    valueColumn.decimals = '4';
    valueColumn.format = '#,###.####';
    valueColumn.dmKey = 'DM-2341';

    this.columns.push(endDateColumn);
    this.columns.push(valueColumn);
  }

  private parserFunction(pastedDataRecords: string[]): TakeoverProtectionRatchetScheduleItem[] {
    const scheduleItems: TakeoverProtectionRatchetScheduleItem[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');

      const endDateValue = items[0];
      const valueValue = items[1];

      const endDate = MarketDataClipboard.parseDateValue(endDateValue, 'End Date');
      const value = MarketDataClipboard.parseNumberValue(valueValue, 'Value');

      scheduleItems.push({
        endDate: endDate,
        value: value,
      } as TakeoverProtectionRatchetScheduleItem);
    });

    return scheduleItems;
  }

  createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return  new FormGroup({
      'endDate': new FormControl(args.isNew ? new Date() : args.dataItem.endDate, Validators.required),
      'value': new FormControl(args.dataItem.value, Validators.required)
    });
  }

  ngOnInit() {
    this._modelSubscription = [
        this.advancedGrid.didDataChange.subscribe((records: TakeoverProtectionRatchetScheduleItem[]) => this.onScheduleChange(records)),
        this.advancedGrid.doReload.subscribe(() => this.onScheduleReload()),
        this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];

    this.scheduleItems.splice(0, this.scheduleItems.length);
    this.scheduleItems.push(...this.model.ratchetScheduleMembers);

    this._changeDetectorRef.detectChanges();

  }

  ngOnChanges() {
    this.scheduleItems = this.model.ratchetScheduleMembers.map(a => {
      return { ...a };
    });

    this._changeDetectorRef.detectChanges();
  }

  private onScheduleChange(scheduleItems: TakeoverProtectionRatchetScheduleItem[]) {
    this.model.ratchetScheduleMembers.splice(0, this.model.ratchetScheduleMembers.length);
    this.model.ratchetScheduleMembers.push(...scheduleItems);
    this.didSetRatchetSchedule.next(this.model);
  }

  private onScheduleReload() {
    this.scheduleItems = this.scheduleItems.map(a => ({ ...a }));
  }

  private onError(error: LvError) {
    this._errorService.handleError(error);
  }

  doModelChange() {
    this.didSetRatchetSchedule.next(this.model);
  }

  getRatchetTakeOverTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
