<lv-subscriber-panel
  [state]="state"
  [accessScope]="state.accessScope"
  (didReceiveMessage)="onMessageReceived($event)"
  (didStateChange)="onStateChange($event)">
  <ng-container header>
    <lv-flex-box kendoTooltip
      class="instrument-header"
      direction="row">
      <lv-instrument-search [instrumentName]="state.instrumentName" [readonly]="defaultStateOnly"
        (didSelectInstrument)="onInstrumentSelect($event)">
      </lv-instrument-search>
      <lv-flex-box flex="1"></lv-flex-box>
      <lv-flex-box *ngIf="instrumentReloadVisible"
        class="instrument-header-options"
        direction="row"
        alignItems="center">
        <div class="reload-info">{{ hubReloadEventMessage }}</div>
        <div class="lv-icon-square"
          [class.lv-icon-square--disabled]="isLoading"
          title="Dismiss"
          lvId="instrument-dismiss-reload"
          (click)="onDismissInstrumentReload()">
          <i class="k-icon k-i-non-recurrence"></i>
        </div>
        <div class="lv-icon-square"
          [class.lv-icon-square--disabled]="isLoading"
          title="Reload"
          lvId="instrument-reload"
          (click)="onReloadInstrument()">
          <i class="k-icon k-i-reload"></i>
        </div>
      </lv-flex-box>
    </lv-flex-box>
  </ng-container>
  <ng-container content>

    <!-- todo fix authorization of instrument -->

    <instrument-authorization *ngIf="notAuthorized"
      [instrumentName]="state.instrumentName">
    </instrument-authorization>

    <ng-container *ngIf="!notAuthorized">
      <kendo-tabstrip class="instrument-tab" #tabStrip
      [class.instrument-two-rows]="instrumentUtil.hasInstrumentTwoRows"
      [class.instrument-three-rows]="instrumentUtil.hasInstrumentThreeRows"
      [class.instrument-four-rows]="instrumentUtil.hasInstrumentFourRows"
      [class.instrument-five-rows]="instrumentUtil.hasInstrumentFiveRows"
      [class.instrument-six-rows]="instrumentUtil.hasInstrumentSixRows"
      [class.instrument-seven-rows]="instrumentUtil.hasInstrumentSevenRows"
      [keepTabContent]="keepTabContent"
      (tabSelect)="onTabSelect($event)">

          <kendo-tabstrip-tab *ngIf="view.getWidgetVisibilityAsTab('valuation') && showAnalytisWidgets"
            title="Valuation"
            cssClass="DM-1202">
            <ng-template kendoTabContent>
              <analytics
                [editable]="model.editCustomView"
                [widgets]="view.widgets">
              </analytics>
            </ng-template>
          </kendo-tabstrip-tab>

          <kendo-tabstrip-tab *ngIf="view.getWidgetVisibilityAsTab('market_data') && showAnalytisWidgets"
            title="Market Data">
              <ng-template kendoTabContent>
                <lv-market-data-widget></lv-market-data-widget>
              </ng-template>
          </kendo-tabstrip-tab>

          <kendo-tabstrip-tab *ngIf="view.getWidgetVisibilityAsTab('model_customization') && showAnalytisWidgets"
            title="Model Customization"
            cssClass="DM-1214">
              <ng-template kendoTabContent>
                <lv-model-customization
                  data-cy="DM-1214">
                </lv-model-customization>
              </ng-template>
          </kendo-tabstrip-tab>

          <kendo-tabstrip-tab *ngIf="view.getWidgetVisibilityAsTab('tools') && showAnalytisWidgets"
            title="Tools">
              <ng-template kendoTabContent>
                <lv-tools-widget></lv-tools-widget>
              </ng-template>
          </kendo-tabstrip-tab>

          <kendo-tabstrip-tab *ngIf="view.getWidgetVisibilityAsTab('terms')"
            title="Terms"
            cssClass="terms-class DM-1220">
            <ng-template kendoTabContent>
              <ng-container *ngIf="hasFullTermsAccess || state.isPrivateInstrument;else showTermsSummary">
                <ng-container *ngTemplateOutlet="fullTerms;"></ng-container>
              </ng-container>

            </ng-template>
          </kendo-tabstrip-tab>

          <kendo-tabstrip-tab *ngIf="view.getWidgetVisibilityAsTab('sensitivity_analysis') && showAnalytisWidgets"
            title="Sensitivity Analysis">
            <ng-template kendoTabContent>
              <lv-sensitivity-analysis-panel
                [state]="state.sensitivityAnalysisState"
                (didUpdateState)="onSensitivityAnalysisStateUpdated($event)">
              </lv-sensitivity-analysis-panel>
            </ng-template>
          </kendo-tabstrip-tab>

          <kendo-tabstrip-tab *ngIf="view.getWidgetVisibilityAsTab('documents') && showDocuments"
            title="Documents">
            <ng-template kendoTabContent>
              <lv-documents-sliding-panel
                [lwsIdentifier]="state.instrumentLwsIdentifier"
                [isin]="state.instrumentIsin"
                [instrumentName]="state.instrumentName">
              </lv-documents-sliding-panel>
            </ng-template>
          </kendo-tabstrip-tab>
      </kendo-tabstrip>
      <div class="instrument-options" *ngIf="!defaultStateOnly || isOpenedFromExcel">
          <i class="k-icon k-i-gear"
            (click)="onShowSettings()">
          </i>
      </div>

      <lv-side-panel class="instrument-options-panel"
        [width]="getSidePanelWidth(model.editCustomView)"
        position="right"
        [floating]="true">

        <ng-container *ngIf="!model.editCustomView">
          <div  *ngIf="!isOpenedFromExcel"
            class="lv-side-panel-link-item"
            lvId="lv-tools-display-default-view"
            (click)="onDisplayDefaultView()">
            <span>Display Default View</span>
            <i *ngIf="state.useDefaultView"
              class="k-icon k-i-check"></i>
          </div>

          <div *ngIf="!isOpenedFromExcel"
            class="lv-side-panel-link-item lv-side-panel-link-item--separator"
            lvId="lv-tools-display-custom-view"
            (click)="onDisplayCustomView()">
            <span>Display Custom View</span>
            <i *ngIf="!state.useDefaultView"
              class="k-icon k-i-check"></i>
          </div>

          <div class="lv-side-panel-link-item"
            [class.lv-side-panel-link-item--separator]="state.useDefaultView && shouldShowEditAccessScopeLink"
            lvId="lv-tools-edit-custom-view"
            (click)="onEditCustomView($event)">
            <span>{{editWidgetButtonText}}</span>
          </div>

          <div *ngIf="!state.useDefaultView"
            class="lv-side-panel-link-item"
            [class.lv-side-panel-link-item--separator]="shouldShowEditAccessScopeLink"
            lvId="lv-tools-save-as-default-view"
            (click)="onSaveAsDefaultView()">
            <span>{{saveWidgetButtonText}}</span>
          </div>
          <div *ngIf="shouldShowEditAccessScopeLink"
            class="lv-side-panel-link-item"
            lvId="lv-tools-edit-instrument-access-rights"
            (click)="onEditInstrumentAccessRights()">
            <span>Edit Instrument Access Rights</span>
          </div>
        </ng-container>

        <ng-container *ngIf="model.editCustomView">
          <div *ngFor="let w of view.widgets"
            class="instrument-options-panel-item">
            <div class="instrument-options-panel-item-title"
              [class.instrument-options-panel-item--only-widget]="view.isOnlyWidget(w)">
              {{w.title}}
            </div>

            <kendo-buttongroup selection="single">
              <button *ngFor="let vo of w.visibilityOptions"
                kendoButton
                [disabled]="isOpenedFromExcel && disableFieldsInEditCustomView(w.id)"
                [lvId]="view.getVisibilityLvId(vo)"
                [iconClass]="view.getVisibilityIconClass(vo)"
                [toggleable]="true"
                [selected]="view.getIsVisibilityOptionSelected(vo, w)"
                (click)="onSetWidgetVisibility(vo, w)">
              </button>
            </kendo-buttongroup>
          </div>

          <lv-flex-box class="instrument-options-panel-edit-custom-fotter"
            direction="row">
            <button kendoButton
              [primary]="true"
              (click)="onCancelEditCustomView($event)">
              Cancel
            </button>

            <button kendoButton
              [primary]="true"
              (click)="onApplyEditCustomView($event)">
              Apply
            </button>
          </lv-flex-box>
        </ng-container>
      </lv-side-panel>
      </ng-container>
  </ng-container>
</lv-subscriber-panel>

<ng-template #fullTerms>
  <lv-custom-instrument
    data-cy="DM-1220"
    [instrumentIdentifier]="_customInstrumentIdentifier"
    [sessionId]="state.sessionId"
    [isPrivateInstrument]="state.isPrivateInstrument"
    [hasFullTermsAccess] ="hasFullTermsAccess"
    [region]="state.region"
    (didPrivateInstrumentChange)="onPrivateInstrumentChanged($event)"
    (didPrivateInstrumentLoad)="onPrivateInstrumentLoaded($event)"
    (didPrivateInstrumentReload)="onPrivateInstrumentReloaded($event)"
    (didDraftReload)="onDraftReloaded()"
    (didTermsDetailedSaved)="onTermsDetailedSaved($event)"
    (didInstrumentNotFoundReturned)="onPrivateInstrumentDeleted()"
    (didDraftChanged)="onDraftChanged()">
  </lv-custom-instrument>
</ng-template>

<ng-template #showTermsSummary>
  <ng-container *ngIf="showAnalytisWidgets && isPermissionChecked">
    <lv-terms-summary
      [lwsIdentifier]="state.instrumentLwsIdentifier">
    </lv-terms-summary>
  </ng-container>
</ng-template>
