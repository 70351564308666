import { Component, OnInit, Input, Output, EventEmitter, ViewChild,
   OnDestroy, OnChanges, ViewEncapsulation, ChangeDetectionStrategy, ElementRef, Optional } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { constants, LvLookupEnum } from '@lv-core-ui/util';
import { v4 } from 'uuid';
import { LvAdvancedGridComponent, LvAdvancedGridColumn, LvAdvancedGridDateColumn,
         LvAdvancedGridNumericColumn } from '@lv-core-ui/components';
import { LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { MarketDataClipboard } from '@lv-analytics/components';
import { Reset, ConversionCustomData, SetupStatus, ResetScheduleItem, CurrencyType, FloorExpressedAsDescripton,
         CapExpressedAsDescription } from '@lv-convertible-bond/models';
import { FloorExpressedAs } from '@lv-convertible-bond/models/convertible-bond-terms/Enums (2)';
import { ResetAveragingData } from 'src/app/modules/convertible-bond-terms/models/convertible-bond-terms/ResetAveragingData';
import { LvExcelService } from '@lv-excel/services';

@Component({
  selector: 'lv-reset-conversion',
  templateUrl: './lv-reset-conversion.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvResetConversionComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  @Input() priceCurrency: string;
  @Input() model: Reset;
  @Input() conversionCustomData: ConversionCustomData;

  @Output() didResetChange: EventEmitter<Reset>;

  get isInitalCPDisabled(): boolean {
    return this.conversionCustomData.status === SetupStatus.NewIssue;
  }

  conversionPriceCCYLookup: LvLookupEnum;
  floorExpressedRelativeToLookup: LvLookupEnum;
  capExpressedRelativeToLookup: LvLookupEnum;

  columns: LvAdvancedGridColumn[];
  scheduleItems: ResetScheduleItem[];
  parseFn: any;

  issuerOptionalResetId: string;
  resetableDuringPeriod: string;
  currentCCY: string;

  formatZero = 'n0';
  decimalsZero = '0';

  formatSix = '#,###.######';
  decimalsSix = '6';

  formatThree = '#,###.###';
  decimalsThree = '3';

  formatFour = '#,###.####';
  decimalsFour = '4';

  excelFieldAlias = 'RES_SCHED_RANGE';

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAlias)?.editable;
  }
  
  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  private subscriptions: Subscription[];

  constructor(
    private _errorService: LvErrorService,
    @Optional() private _excelSvc: LvExcelService) {
    this.conversionPriceCCYLookup = new LvLookupEnum(CurrencyType);
    this.floorExpressedRelativeToLookup = new LvLookupEnum(FloorExpressedAsDescripton);
    this.capExpressedRelativeToLookup = new LvLookupEnum(CapExpressedAsDescription);
    this.didResetChange = new EventEmitter<Reset>();

    this.parseFn = this.parserFunction.bind(this);
    this.issuerOptionalResetId = v4();
    this.resetableDuringPeriod = v4();

    this.scheduleItems = [];
    this.currentCCY = '%';
  }

  onResetChange() {
    this.updateColumns();
    this.didResetChange.next(this.model);
  }

  onResetAveragingChange() {
    this.setResetAveraging();
    this.onResetChange();
  }

  ngOnInit() {
    this.subscriptions = [
      this.advancedGrid.didDataChange.subscribe((records: ResetScheduleItem[]) => this.onScheduleChange(records)),
      this.advancedGrid.doReload.subscribe(() => this.onScheduleReload()),
      this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error)),
    ];

    this.setResetAveraging();

    this.init();
  }

  ngOnChanges() {
    if (this.isOpenedFromExcel) {
      this.initColumns();
    }
    this.init();
  }

  createFormGroup(args: CreateFormGroupArgs): FormGroup {
    args.dataItem.cap = args.dataItem.cap || 100;
    args.dataItem.multiplier = args.dataItem.multiplier || 1;
    return  new FormGroup({
      'startDate': new FormControl(args.isNew ? new Date() : args.dataItem.startDate, Validators.required),
      'endDate': new FormControl(args.isNew ? new Date() : args.dataItem.endDate),
      'floor': new FormControl(args.dataItem.floor),
      'cap': new FormControl(args.dataItem.cap),
      'multiplier': new FormControl(args.dataItem.multiplier),
      'trigger': new FormControl(args.dataItem.trigger)
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  applyAdvancedGridChanges() {
    this.advancedGrid.applyChanges(records => this.applyRecords(records));
  }

  private updateColumns() {
    this.advancedGrid.updateColumns(c => this.updateColumn(c));
  }

  private updateColumn(c: LvAdvancedGridColumn) {
    if (c.field === 'endDate') {
      c.visible = this.model.resettableDuringPeriod;
    }
    if (c.field === 'startDate') {
      c.title = this.model.resettableDuringPeriod ? 'Start Date' : 'Date';
    }
    if (c.field === 'floor') {
      c.title = this.model.floorExpressedRelativeTo === FloorExpressedAs.CashInBondCCY ? 'Floor (' + this.priceCurrency + ')' : 'Floor (%)';
    }
  }

  private init() {
    this.scheduleItems = this.model.schedule.map(a => ({ ...a }));
    this.initColumns();
    this.setFloorValues();
  }

  private setFloorValues() {
     this.currentCCY = this.model.floorExpressedRelativeTo === FloorExpressedAs.CashInBondCCY ?  this.conversionCustomData.currency : '%';
  }

  private initColumns() {
    this.columns = [];

    const startDateColumn = new LvAdvancedGridDateColumn();
    startDateColumn.title = this.model.resettableDuringPeriod ? 'Start Date' : 'Date';
    startDateColumn.field = 'startDate';
    startDateColumn.dmKey = 'DM-2316';

    const endDateColumn = new LvAdvancedGridDateColumn();
    endDateColumn.title = 'End Date';
    endDateColumn.field = 'endDate';
    endDateColumn.dmKey = 'DM-2317';

    this.updateColumn(endDateColumn);

    const floorColumn = new LvAdvancedGridNumericColumn();
    floorColumn.title = 'Floor (%)';
    floorColumn.field = 'floor';
    floorColumn.outputFormat = constants.numberFormat.upToFourDigits;
    floorColumn.format = this.formatFour;
    floorColumn.decimals = this.decimalsFour;
    floorColumn.dmKey = 'DM-2312';

    this.updateColumn(floorColumn);

    const capColumn = new LvAdvancedGridNumericColumn();
    capColumn.title = 'Cap (%)';
    capColumn.field = 'cap';
    capColumn.outputFormat = constants.numberFormat.upToFourDigits;
    capColumn.format = this.formatFour;
    capColumn.decimals = this.decimalsFour;
    capColumn.dmKey = 'DM-2313';

    const mulitplierColumn = new LvAdvancedGridNumericColumn();
    mulitplierColumn.title = 'Multiplier';
    mulitplierColumn.field = 'multiplier';
    mulitplierColumn.outputFormat = constants.numberFormat.upToFourDigits;
    mulitplierColumn.format = this.formatFour;
    mulitplierColumn.decimals = this.decimalsFour;
    mulitplierColumn.dmKey = 'DM-2314';

    const triggerColumn = new LvAdvancedGridNumericColumn();
    triggerColumn.title = 'Trigger (%)';
    triggerColumn.field = 'trigger';
    triggerColumn.outputFormat = constants.numberFormat.upToThreeDigits;
    triggerColumn.format = this.formatThree;
    triggerColumn.decimals = this.decimalsThree;
    triggerColumn.dmKey = 'DM-2315';

    this.columns.push(startDateColumn);
    this.columns.push(endDateColumn);
    this.columns.push(floorColumn);
    this.columns.push(capColumn);
    this.columns.push(mulitplierColumn);
    this.columns.push(triggerColumn);
  }

  private parserFunction(pastedDataRecords: string[]): ResetScheduleItem[] {
    const scheduleItems: ResetScheduleItem[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');

      const startDateValue = items[0];
      const endDateValue = items[1];
      const floorValue = items[2];
      const capValue = items[3];
      const multiplierValue = items[4];
      const triggerValue = items[5];

      const startDate = MarketDataClipboard.parseDateValue(startDateValue, 'Start Date');
      const endDate = MarketDataClipboard.parseDateValue(endDateValue, 'End Date');
      const floor = MarketDataClipboard.parseNumberValue(floorValue, 'Floor (%)');
      const cap = MarketDataClipboard.parseNumberValue(capValue, 'Cap (%)');
      const multiplier = MarketDataClipboard.parseNumberValue(multiplierValue, 'Multiplier');
      const trigger = MarketDataClipboard.parseNumberValue(triggerValue, 'Trigger');

      scheduleItems.push({
        startDate: startDate,
        endDate: endDate,
        floor: floor,
        cap: cap,
        multiplier: multiplier,
        trigger: trigger
      } as ResetScheduleItem);
    });

    return scheduleItems;
  }

  private onScheduleChange(scheduleItems: ResetScheduleItem[]) {
    this.applyRecords(scheduleItems);
    // this.didResetChange.next(this.model);
  }

  private applyRecords(records: any[]) {
    this.model.schedule.splice(0, this.model.schedule.length);
    this.model.schedule.push(...records);

    this.updateColumns();
    this.didResetChange.next(this.model);
  }

  private onScheduleReload() {
    this.scheduleItems = this.scheduleItems.map(a => ({ ...a }));
  }

  private onError(error: LvError) {
    this._errorService.handleError(error);
  }

  /**
   * Sets resetAveraging property on reset model if it's null
   */
  private setResetAveraging(): void {
    this.model.useResetAveraging = false;
    if (!this.model.resetAveraging) {
      this.model.resetAveraging = {} as ResetAveragingData;
    } else {
      if (this.model.resetAveraging.numberOfAveragingDays || this.model.resetAveraging.startLag) {
        this.model.useResetAveraging = true;
      }
    }
  }

  onFlorExpresedRelativeToChange() {
    this.setFloorValues();
    this.onResetChange();
  }

}
