import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IBaseTermsSettings } from '@lv-convertible-bond/models';
import { ICouponSectionSettings } from '@lv-convertible-bond/models/terms-settings/coupon-settings/coupon-section-settings';

@Component({
  selector: 'lv-coupon-settings',
  templateUrl: './lv-coupon-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvCouponSettingsComponent implements OnInit {

  @Input() systemDefaults: IBaseTermsSettings<ICouponSectionSettings>;
  @Input() myDefaults: IBaseTermsSettings<ICouponSectionSettings>;

  constructor() {
  }

  @HostBinding('class.lv-coupon-settings')
  get isLvCouponSettingsComponent() {
    return true;
  }

  ngOnInit() {
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
   getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }

}
