/**
 * Volatility types.
 */
export enum VolatilityType {
    Flat = 'Flat',
    UpsideDownside = 'UpsideDownside',
    TermStructure = 'TermStructure',
    VolatilitySurface = 'VolatilitySurface'
}

/**
 * Volatility type descriptions.
 */
export enum VolatilityTypeDescription {
    Flat = 'Flat',
    UpsideDownside = 'Upside / Downside',
    TermStructure = 'Term Structure',
    VolatilitySurface = 'Surface'
}

/**
 * Volatility surface price type.
 */
export enum VolatilitySurfacePriceType {
    Moneyness = 'Moneyness',
    Strike = 'Strike'
}

/**
 * Volatility surface date type.
 */
export enum VolatilitySurfaceDateType {
    Maturity = 'Maturity',
    Tenor = 'Tenor'
}

/**
 * Volatility surface risky type description.
 */
export enum VolatilitySurfaceRiskyTypeDescription {
    Risky = 'Risky',
    RiskFree = 'Risk Free'
}

/**
 * Volatility surface risky type.
 */
export enum VolatilitySurfaceRiskyType {
    Risky = 'Risky',
    RiskFree = 'RiskFree'
}
