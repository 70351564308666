import { LvUserFriendlyError } from '@lv-core-ui/models';

/**
 * User-friendly error for analytics module.
 */
export class LvAnalyticsError extends LvUserFriendlyError {
  constructor(message: string) {
    super(message, 'Analytics');
  }
}
