import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { LabelModule } from '@progress/kendo-angular-label';
import { PopupModule } from '@progress/kendo-angular-popup';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { IntlModule } from '@progress/kendo-angular-intl';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

import { ConvertibleInfoService } from './services/convertible-info/convertible-info.service';
import { LookupService } from './services/lookup/lookup.service';
import { WatchListService } from './services/watch-list/watch-list.service';
import { WidgetService } from './services/widget/widget.service';
import { InstrumentMonitorService } from './instrument-monitor.service';

import { LvInstrumentSearchComponent } from './components/lv-instrument-search/lv-instrument-search.component';

import { LvInstrumentMonitorPanelComponent } from './components/lv-instrument-monitor-panel/lv-instrument-monitor-panel.component';
// tslint:disable-next-line:max-line-length
import { LvInstrumentMonitorComponent } from './components/lv-instrument-monitor-panel/lv-instrument-monitor/lv-instrument-monitor.component';
// tslint:disable-next-line:max-line-length
import { LvInstrumentMonitorFilterComponent } from './components/lv-instrument-monitor-panel/lv-instrument-monitor-filter/lv-instrument-monitor-filter.component';
import { LvWatchListPanelDialogComponent } from './components/lv-watch-list-panel-dialog/lv-watch-list-panel-dialog.component';
// tslint:disable-next-line:max-line-length
import { LvWatchListInstrumentsComponent } from './components/lv-watch-list-panel-dialog/lv-watch-list-instruments/lv-watch-list-instruments.component';
// tslint:disable-next-line:max-line-length
import { LvInstrumentWatchListsComponent } from './components/lv-watch-list-panel-dialog/lv-instrument-watch-lists/lv-instrument-watch-lists.component';
import { InstrumentMonitorHubService } from './hubs/instrument-monitor-hub.service';
import { LeversysCoreUIModule } from '@lv-core-ui/leversys-core-ui.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    // Kendo modules
    LabelModule,
    PopupModule,
    InputsModule,
    DropDownsModule,
    ButtonsModule,
    GridModule,
    IntlModule,
    TooltipModule,

    // Leversys
    LeversysCoreUIModule
  ],
  declarations: [
    LvInstrumentMonitorComponent,
    LvInstrumentSearchComponent,
    LvInstrumentMonitorFilterComponent,
    LvInstrumentMonitorPanelComponent,
    LvWatchListPanelDialogComponent,
    LvWatchListInstrumentsComponent,
    LvInstrumentWatchListsComponent
  ],
  exports: [
    LvInstrumentMonitorComponent,
    LvInstrumentSearchComponent,
    LvInstrumentMonitorFilterComponent,
    LvInstrumentMonitorPanelComponent,
    LvWatchListPanelDialogComponent,
    LvWatchListInstrumentsComponent,
    LvInstrumentWatchListsComponent
  ],
  providers: [
    ConvertibleInfoService,
    LookupService,
    WatchListService,
    WidgetService,
    InstrumentMonitorService,
    InstrumentMonitorHubService
  ]
})
export class InstrumentMonitorModule { }
