import { Directive, Input, ElementRef, Renderer2, OnChanges, OnInit } from '@angular/core';
import { v4 } from 'uuid';

@Directive({
  selector: '[lvId]'
})
export class LeversysIdDirective implements OnInit, OnChanges {

  @Input() lvId: string;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) {
    this.lvId = v4();
  }

  setAttribute() {
    this.renderer.setAttribute(this.element.nativeElement, 'lv-id', this.lvId);
    this.renderer.removeAttribute(this.element.nativeElement, 'lvId');
  }

  ngOnInit() {
    this.setAttribute();
  }

  ngOnChanges() {
    this.setAttribute();
  }

}
