import { Directive, EventEmitter, OnInit, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[detectScroll]'
})
export class LvDetectScrollDirective implements OnInit, OnDestroy {

  @Output() scrollStarted: EventEmitter<void>;

  private _scrollStarted: boolean;

  constructor() {
    this.scrollStarted = new EventEmitter<void>();
    this._scrollStarted = false;
  }

  ngOnInit() {
    window.addEventListener('scroll', () => {
      this.scroll(this._scrollStarted);
    }, true);

    setTimeout(() => {
      this._scrollStarted = true;
    }, 300);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', () => {
      this.scroll(false);
    }, true);
  }

  scroll(scrollStarted: boolean): void {
    if (scrollStarted && !(window as any).Cypress) {
      this.scrollStarted.next();
    }
  }
}
