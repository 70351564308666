import { IndexType } from './Enums (5)';

export class Index {
    
    name: string;
    type: IndexType;
    currencyCode: string;
    bloombergTicker: string;
    bloombergUniqueId: string;
}

