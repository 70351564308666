<lv-grid-loader [isLoading]="isLoading">
  <kendo-grid #grid
    class="lv-instrument-monitor"
    [data]="recordsView"
    [skip]="skip"
    [pageSize]="pageSize"
    [scrollable]="scrollable"
    [selectable]="selectableSettings"
    [rowHeight]="rowHeight"
    kendoGridSelectBy="id"
    [selectedKeys]="selectedKeys"
    (selectedKeysChange)="onSelectedKeysChange($event)">
    <kendo-grid-column field="name" title="Instrument Name" [width]="430">
      <ng-template kendoGridCellTemplate let-dataItem>
        <lv-flex-box direction="row">
          <div style="flex: 1;">{{dataItem.name}}</div>
          <div *ngIf="dataItem.isPrivateInstrument" class="lv-instrument-monitor-icon" kendoTooltip>
            <i lvAccessScope [accessScope]="dataItem.accessScope"></i>
          </div>
        </lv-flex-box>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="underlyingIssuer" title="Underlying" [width]="200"></kendo-grid-column>
    <kendo-grid-column field="underlyingBloombergTicker" title="Und BBG Ticker" [width]="150"></kendo-grid-column>
    <kendo-grid-column *ngIf="false" field="instrumentType" title="Instrument Type" [width]="100"></kendo-grid-column>
    <kendo-grid-column field="region" title="Region" [width]="80"></kendo-grid-column>
    <kendo-grid-column field="country" title="Country" [width]="110"></kendo-grid-column>
    <kendo-grid-column field="isin" title="ISIN" [width]="115"></kendo-grid-column>
    <kendo-grid-column field="leversysId" title="Leversys ID" [width]="115"></kendo-grid-column>
    <kendo-grid-column field="currency" title="CCY" [width]="50"></kendo-grid-column>
    <kendo-grid-column field="couponValue" title="Coupon" [width]="70"></kendo-grid-column>
    <kendo-grid-column field="maturityDate" title="Maturity" [width]="90">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.maturityDate ? (dataItem.maturityDate | lvLocalDate) : 'Perpetual'}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="series" title="Issue Series" [width]="90"></kendo-grid-column>
  </kendo-grid>
  <lv-grid-context-menu [for]="grid"
    [autoHide]="autoHideContextMenu"
    [items]="contextMenuItems"
    (didContextMenuOpen)="onContextMenuOpen($event)">
  </lv-grid-context-menu>
</lv-grid-loader>

<ng-template #instrumentWatchListsTemplate let-item="item">
  <div #textAnchor
    class="lv-instrument-monitor-watch-lists-menu-item"
    (mouseenter)="onOpenSubMenu(textAnchor)">
    <span>{{ item.text }}</span>
    <i class="k-icon k-i-arrow-chevron-right"></i>
  </div>
  <div #watchListsAnchor
    *ngIf="watchListsConfig.visible"
    class="lv-instrument-monitor-watch-lists"
    [style.width.px]="watchListsConfig.width"
    [style.height.px]="watchListsConfig.height"
    [style.top.px]="watchListsConfig.top"
    [style.left.px]="watchListsConfig.left">
    <div *ngFor="let wl of watchLists"
      [class.lv-watch-list-item]="!wl.isGroup"
      [class.lv-watch-list-group]="wl.isGroup"
      [class.lv-watch-list-item--selected]="isWatchListSelected(wl)"
      [class.lv-watch-list-item--disabled]="watchListsDisabled"
      (click)="onChooseWatchList(wl)">
      {{ wl.listName }}
    </div>

    <div *ngIf="watchListsEmpty"
      class="k-nodata">
      <lv-dropdown-loader [isLoading]="watchListsLoading"></lv-dropdown-loader>
    </div>
  </div>
</ng-template>
