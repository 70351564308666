import { OtherData } from '@lv-convertible-bond/models';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';

export class LvOtherView {
  public model: OtherData;

  constructor(
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService
  ) {
    this.model = {
      involvedParties: [],
    } as OtherData;

    this.init(this.model);
  }

  init(model?: OtherData) {
    this.model = model ?? this._lvConvertibleBondTermsService.convertibleBondDocument.other;
  }
}
