import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LvXlLabelComponent } from './components/lv-xl-label/lv-xl-label.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { LvExcelSaveModalComponent } from './components/lv-excel-save-modal/lv-excel-save-modal.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LeversysCoreUIModule } from '@lv-core-ui/leversys-core-ui.module';

@NgModule({
  declarations: [
    LvXlLabelComponent,
    LvExcelSaveModalComponent
  ],
  imports: [
    CommonModule,
    TooltipModule,
    ButtonsModule,
    LeversysCoreUIModule
  ],
  exports: [
    LvXlLabelComponent,
    LvExcelSaveModalComponent
  ]
})
export class ExcelModule { }
