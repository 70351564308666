<lv-advanced-grid
  data-cy="DM-305"
  [columns]="columns"
  [records]="scheduleItems"
  [createFormGroup]="createFormGroup"
  [parseFn]="parseFn"
  sortBy="endDate"
  class="lv-borrow-term-structure-table"
  lvId="lvMarketDataBorrowTermStructuresGrid"
  [showXlLabel]="hasScheduleInExcelOverride"
  [excelFieldAlias]="excelFieldAlias"
  [isFieldFromExcelEnabled]="isFieldFromExcelEnabled">
</lv-advanced-grid>
