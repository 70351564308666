export const environment = {
  production: true,
  apiUrl: 'https://api.marketspace.leversys.com/public/api',
  applicationId: 'online-public',
  apiVersion: 'v1',
  refreshTokenUrl: 'https://api.login.leversys.com/loginapi/api/token/refresh',
  identityServer: {
    apiUrl: 'https://api.login.leversys.com/loginapi/api',
    refreshTokenUrl: 'https://api.login.leversys.com/loginapi/api/token/refresh',
    loginUrl: 'https://login.leversys.com/login',
    userUrl: 'https://login.leversys.com/dashboard/user-profile',
    accountUrl: 'https://login.leversys.com/dashboard/account-profile',
  },
  errorLoggerUrl: 'https://api.marketspace.leversys.com/public/api/v1/applicationSettings/clientErrorLogger/nglogger',
  sockets: {
    url: 'https://sockets.marketspace.leversys.com/realtime/sockets',
    shouldConnect: true
  },
  supportCenterUrl: 'https://www.leversys.com/support',
  userKey: 'account',
  stateKey: 'application-settings',
  permissionsKey: 'permissions',
  applicationSettings: {
    stateKey: 'application-settings',
    versionKey: 'application-settings-version',
    numberOfAllowedInstrumentMonitorsPerWorkspace: 5,
    numberOfAllowedInstrumentsPerWorkspace: 10,
  },
  cookieKeys: {
    session: 'session-id',
    cookieDomain: '.leversys.com',
  },
  requestTimeout: 20000,
  ngxToastr: {
    toastInvisible: false,
    disableTimeOut: false
  },
  applicationVersion: {
    url: 'https://marketspace.leversys.com/version.json',
    shouldCheck: true
  },
  showTooltips: true,
  showErrorModal: true,
  playwrightVariable: false
};
