import { DayCount, Frequency, NoticePeriod, PutValueType } from '@lv-convertible-bond/models/convertible-bond-terms';
import { IPutSectionSettings } from '../put-settings/put-section-settings';

export const getDefaultPutSectionSettings = (): IPutSectionSettings => {
  return {
    valueType: PutValueType.PerOfPar,
    yieldFrequency: Frequency.Annual,
    keepAccrued: true,
    forfeitCoupon: false,
    notice: null,
    noticePeriod: NoticePeriod.Calendar,
    yieldDaysCount: DayCount.DayCount_ACT_ACT_ICMA
  };
};
