// tslint:disable-next-line:max-line-length
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation, Optional, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MarketDataClipboard } from '@lv-analytics/components/market-data/market-data-clipboard';
import { IBorrowScheduleItem } from '@lv-analytics/models/market-data/borrow/borrow-schedule-item';
import { LvAdvancedGridComponent, LvAdvancedGridColumn, LvAdvancedGridDateColumn,
         LvAdvancedGridNumericColumn } from '@lv-core-ui/components';
import { LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { LvExcelService } from '@lv-excel/services';

import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';

/**
 * Borrow terms structure component.
 */
@Component({
  selector: 'lv-borrow-term-structure',
  templateUrl: './lv-borrow-term-structure.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvBorrowTermStructureComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  @Input() scheduleItems: IBorrowScheduleItem[];
  @Output() borrowScheduleValueChange: EventEmitter<void>;

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAlias)?.editable;
  }

  columns: LvAdvancedGridColumn[];
  parseFn: any;
  excelFieldAlias = 'BRW_SCHED_RANGE';

  private _subscriptions: Subscription[];

  constructor(
    private _errorService: LvErrorService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.scheduleItems = [];
    this.parseFn = this.parsePastedData.bind(this);
    this.borrowScheduleValueChange = new EventEmitter();
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this.advancedGrid.didDataChange.subscribe((records: IBorrowScheduleItem[]) => this.onDataChange(records)),
      this.advancedGrid.doReload.subscribe(() => this.onReload()),
      this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];

    this.initColumns();
  }

  /**
   * Occurs on changes and initializes columns.
   */
  ngOnChanges() {
    if (this.hasScheduleInExcelOverride) {
      this.initColumns();
    }
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe);
  }

  /**
   * Occurs on data change.
   * @param records List of IBorrowScheduleItem objects.
   */
  public onDataChange(records: IBorrowScheduleItem[]) {
    this.applyRecords(records);
    this.borrowScheduleValueChange.next();
  }

  /**
   * Applies advanced grid changes.
   */
  public applyAdvancedGridChanges() {
    this.advancedGrid.applyChanges(records => this.applyRecords(records));
  }

  /**
   * Occurs on reload and reloads schedule items.
   */
  public onReload() {
    this.scheduleItems = this.scheduleItems.map(x => ({ ...x }));
  }

  /**
   * Handles error.
   * @param error LvError object.
   */
  public onError(error: LvError) {
    this._errorService.handleError(error);
  }

  /**
   * Create form group.
   * @param args CreateFormGroupArgs object.
   * @returns FormGroup object.
   */
  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return new FormGroup({
      'endDate': new FormControl(args.isNew ? new Date() : args.dataItem.endDate, Validators.required),
      'borrowFee': new FormControl(args.dataItem.borrowFee, Validators.required)
    });
  }

  /**
   * Applies records.
   * @param records List of records.
   */
  private applyRecords(records: any[]) {
    this.scheduleItems.splice(0, this.scheduleItems.length);
    this.scheduleItems.push(...records);
  }

  /**
   * Column initialization.
   */
  private initColumns() {
    this.columns = [];
    const borrowEndDateColumn = new LvAdvancedGridDateColumn();
    borrowEndDateColumn.title = 'End Date';
    borrowEndDateColumn.field = 'endDate';
    borrowEndDateColumn.width = 104;
    borrowEndDateColumn.dmKey = 'DM-631';

    const borrowFeeColumn = new LvAdvancedGridNumericColumn();
    borrowFeeColumn.title = 'Borrow Fee (bps)';
    borrowFeeColumn.field = 'borrowFee';
    borrowFeeColumn.decimals = '0';
    borrowFeeColumn.format = 'n0';
    borrowFeeColumn.width = 104;
    borrowFeeColumn.dmKey = 'DM-632';

    this.columns.push(borrowEndDateColumn);
    this.columns.push(borrowFeeColumn);
  }

  /**
   * Parse pasted data.
   * @param pastedDataRecords Pasted data records.
   * @returns IBorrowScheduleItem object.
   */
  private parsePastedData(pastedDataRecords: string[]): IBorrowScheduleItem[] {
    const borrowScheduleItems: IBorrowScheduleItem[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');
      const endDateValue = items[0];
      const borrowFeeValue = items[1];

      const endDate = MarketDataClipboard.parseDateValue(endDateValue, 'End Date');
      const borrowFee = MarketDataClipboard.parseNumberValue(borrowFeeValue, 'Borrow Fee');

      borrowScheduleItems.push({
        endDate: endDate,
        borrowFee: borrowFee
      });
    });

    return borrowScheduleItems;
  }
}
