import { v4 } from 'uuid';
import { Component, ChangeDetectorRef, ChangeDetectionStrategy, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';

import { LvSubscriberPanelComponent } from '@lv-core-ui/components';
import { IInstrumentSelectedEvent } from '@lv-core-ui/models';
import { ILvSubscriberState } from '@lv-core-ui/services';
import { IBasicTermsInstrumentInfo } from '@lv-analytics/components';
import { ApplicationSettingsService } from '@lv-application-settings/application-settings.service';
import { WidgetStateManagerService } from '@lv-application-settings/services';
import { NotificationPanelComponent } from '@lv-common/views/notification-panel.component';
import { IInstrumentLookup } from '@lv-instrument-monitor/models';

export interface IBasicTermsComponentState extends ILvSubscriberState {
  instrumentId: number;
  lwsIdenitifier: string;
  instrumentName: string;
  isPrivateInstrument: boolean;
  instrumentInfoState: InstrumentInfoState;
}

export interface InstrumentInfoState {
  state: any;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'basic-terms',
  templateUrl: './basic-terms.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    WidgetStateManagerService
  ]
})
export class BasicTermsComponent extends NotificationPanelComponent<IBasicTermsComponentState> implements OnDestroy {
  @ViewChild(LvSubscriberPanelComponent, { static: true }) notificationPanel: LvSubscriberPanelComponent;

  instrumentInfo: IBasicTermsInstrumentInfo;

  notAuthorized: boolean;

  private _sessionId: string;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    applicationSettingsService: ApplicationSettingsService,
    private widgetStateManagerService: WidgetStateManagerService,
  ) {
    super(changeDetectorRef, applicationSettingsService);

    this.state = {
      instrumentId: null,
      instrumentInfoState: {state: null},
      instrumentName: null,
      isPrivateInstrument: null,
      lwsIdenitifier: null,
      id: null,
      type: null,
    };

    this.widgetStateManagerService.setDashboardStateAndSetStateFunction(
      this.state,
      'terms-summary',
      (dashboardState) => {
        return dashboardState.instrumentInfoState;
      });

    this.instrumentInfo = null;

    this.notAuthorized = false;

    this._sessionId = v4();
  }

  doOnInit() {
    this.widgetStateManagerService.setStateId(this.stateId);

    this.widgetStateManagerService.setDashboardStateAndSetStateFunction(
      this.state,
      'terms-summary',
      (dashboardState) => {
        return dashboardState.instrumentInfoState;
      });

    this.setInstrumentInfo();

    return;
  }

  onInstrumentSelect(info: IInstrumentLookup) {
    this.state.instrumentId = info.id;
    this.state.instrumentName = info.text;
    this.state.lwsIdenitifier = info.lwsIdentifier;
    this.state.isPrivateInstrument = info.isPrivateInstrument;

    this.setGoldenLayoutContainerTitle(info.text || 'Instrument Info');

    this.saveState();

    this.setInstrumentInfo();
  }

  // Implementations from parent class
  didReceiveMessage(monitor: IInstrumentSelectedEvent) {
    this.state.instrumentId = monitor.id;
    this.state.instrumentName = monitor.name;
    this.state.lwsIdenitifier = monitor.lwsIdentifier;
    this.state.isPrivateInstrument = monitor.isPrivateInstrument;

    this.saveState();

    this.setGoldenLayoutContainerTitle(monitor.name);

    this.setInstrumentInfo();
  }

  onNotAuthorized(notAuthorized: boolean) {
    this.notAuthorized = notAuthorized;

    if (notAuthorized) {
      this.instrumentInfo = null;
    }
  }

  didlvGlOnDestroy() {
    this.notificationPanel.unregister();
  }

  ngOnDestroy() {
    this.unsubscribeFromWorkspaceRemoved();
  }

  private setInstrumentInfo() {
    this.notAuthorized = false;
    this.changeDetectorRef.detectChanges();

    this.instrumentInfo = {
      lwsIdentifier: this.state.lwsIdenitifier,
      sessionId: this._sessionId,
      isPrivateInstrument: this.state.isPrivateInstrument
    };
  }
}
