import { Injectable } from '@angular/core';
import { LvGoldenLayoutStorage } from '../../util/lv-golden-layout-storage';
import { ILvGlComponent, ILvGlLayoutConfiguration } from '../../models/configuration';

@Injectable()
export class LvGoldenLayoutStorageService implements LvGoldenLayoutStorage {
  private _components: ILvGlComponent[];
  private _configurations: ILvGlLayoutConfiguration[];

  constructor() {
    this._components = [];
    this._configurations = [];
  }

  /**
   * Get configuration by id
   * @param configurationId Configuration identifier
   * @returns Found configuration
   */
  getLayoutConfiguration(configurationId: string): ILvGlLayoutConfiguration {
    const found = this._configurations.find(a => a.id === configurationId);
    return found;
  }

  /**
   * Get all components from configuration
   * @param configurationId Configuration id
   * @returns List of components
   */
  getComponents(configurationId: string): ILvGlComponent[] {
    return this._components.filter(a => a.configurationId === configurationId);
  }

  /**
   * Add component to components list
   * @param component Golden layout component
   */
  addComponent(component: ILvGlComponent): void {
    const found = this._components.find(a => a.id === component.id);

    if (!found) {
      this._components.push(component);
    }
  }

  /**
   * Removes component from components list
   * @param componentId Component identifier
   */
  removeComponent(componentId: string): void {
    this._components = this._components.filter(a => a.id !== componentId);
  }
}
