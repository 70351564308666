<lv-flex-box direction="row">
  <div class="lv-input-field lv-field lv-field--radio"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="conversionAveragingSettingsTooltipTemplate"
    filter="label">
    <label class="k-radio-label">
      Conversion Averaging
    </label>
  </div>
  <ng-container *ngFor="let option of options">
    <div class="lv-input-field lv-field lv-field--radio">
      <input class="k-radio"
        type="radio"
        [attr.id]="getId(option)"
        [name]="getId(option)"
        [value]="option"
        [disabled]="!overrideSystemDefaults"
        [(ngModel)]="settings.conversionAveraging">
      <label
        class="k-radio-label"
        [lvId]="getLvId(option)"
        [attr.for]="getId(option)">
        {{option}}
      </label>
    </div>
  </ng-container>
</lv-flex-box>

<ng-template #conversionAveragingSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1143"></lv-tooltip>
</ng-template>
