import { Injectable } from '@angular/core';

/**
 * Service for date manipulation.
 */
@Injectable({
  providedIn: 'root'
})
export class LvDateService {

  constructor() { }

  /**
   * Create UTC date from regular date.
   * @param date Date value.
   * @returns Date value in UTC format.
   */
  getUtcDateValue(date: Date): Date {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    const dateUtcValue = new Date(Date.UTC(year, month, day));
  
    return dateUtcValue;
  }
}
