<div class="lv-settings-widget">
  <lv-loader *ngIf="isLoading"></lv-loader>
  <div #dropdownAnchor class="lv-settings-widget-header">
    <div class="lv-settings-widget-title">
      {{ titleText }}
    </div>
    <div *ngIf="showWarrning" 
      class="lv-settings-widget-warrning"
      (click)="onWarrningClick()">
      <i class="lv-toastr-icons lv-toastr-icon-warning"></i>
    </div>
    <div *ngIf="showSettings"
      class="lv-settings-widget-settings"
      [class.loading]="isLoading"
      (click)="onShowSettings()">
      <i class="k-icon k-i-cog"></i>
    </div>
  </div>
  <div class="lv-settings-widget-content gridster-item-content"
    [class.loading]="isLoading">
    <ng-content select="[content]"></ng-content>
  </div>
  <ng-container *ngIf="showSettings">
    <lv-settings-side-panel
      [width]="sidePanelWidth"
      position="right"
      [floating]="true"
      [lvId]="sidePanelLvId"
      [dropdownAnchor]="dropdownAnchor"
      (didClosed)="onSliderClosed()"
      (didSlide)="onSlide($event)">
      <ng-content select="[settings]"></ng-content>
    </lv-settings-side-panel>
  </ng-container>
</div>
