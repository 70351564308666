import { Directive, Input, ElementRef, Renderer2, Self, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';

import { Subscription } from 'rxjs';

@Directive({
  selector: '[lvDirty]'
})
export class LvDirtyDirective implements OnDestroy {

  @Input() lvDirty: any;

  private _controlValueChanges: Subscription;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    @Self() private ngControl: NgControl
  ) {
    this.lvDirty = undefined;

    this._controlValueChanges = this.ngControl.valueChanges.subscribe(
      () => {
        if (this.ngControl.value && this.ngControl.control.value !== this.lvDirty) {
          this.renderer.addClass(this.element.nativeElement, 'lv-dirty');
        }
        else {
          this.renderer.removeClass(this.element.nativeElement, 'lv-dirty');
        }
      });
  }

  ngOnDestroy() {
    this._controlValueChanges.unsubscribe();
    this._controlValueChanges = null;
  }
}
