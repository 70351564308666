import { Component, Output, EventEmitter, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { LvModalComponent } from '../lv-modal';
import { mask, modal } from './../lv-modal.animations';

@Component({
  selector: 'lv-application-version-modal',
  templateUrl: './lv-application-version-modal.component.html',
  animations: [
    mask,
    modal
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvApplicationVersionModalComponent extends LvModalComponent {

  @Output() doReloadApplication: EventEmitter<void>;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    renderer: Renderer2
  ) {
    super(changeDetectorRef, renderer);

    this.doReloadApplication = new EventEmitter<void>();
  }

  onReloadApplication() {
    this.doReloadApplication.emit();
  }
}
