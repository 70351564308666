import { v4 } from 'uuid';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, HostBinding, OnChanges,
  ContentChild } from '@angular/core';
import { LvSystemDefaultsSettingsDirective } from './directives/lv-system-defaults.directive';
import { LvEnvironmentSettingsDirective } from './directives/lv-environment-settings.directive';
import { IBaseSectionSettings, IBaseSectionEnvironmentSettings, PricingEnvironmentCodes } from '@lv-analytics/models';

export type SectionSettings = IBaseSectionSettings<any, IBaseSectionEnvironmentSettings<any>>;

/**
 * Section settings component.
 */
@Component({
  selector: 'lv-section-settings',
  templateUrl: './lv-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvSectionSettingsComponent implements OnInit, OnChanges {

  @ContentChild(LvSystemDefaultsSettingsDirective)
  public systemDefaults: LvSystemDefaultsSettingsDirective;

  @ContentChild(LvEnvironmentSettingsDirective)
  public environmentSettings: LvEnvironmentSettingsDirective;

  @Input() settings: SectionSettings;
  @Input() hasEnvironmentSettings: boolean;
  @Input() showSingleEnvironment: boolean;

  public envDict: {
    [environmentCode: string]: {
      overrideSystemDefaultsCbId: string;
      selected: boolean;
    }
  };

  constructor() {
    this.envDict = {};
    this.hasEnvironmentSettings = true;
    this.showSingleEnvironment = false;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.setEnvDict(this.settings);
  }

  /**
   * Occurs on changes.
   */
  ngOnChanges() {
    this.setEnvDict(this.settings);
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-section-settings-base')
  get isLvMarketDataSectionSettingsComponent() {
    return true;
  }

  /**
   * Sets dictionary with environments.
   * @param settings SectionSettings objects.
   */
  private setEnvDict(settings?: SectionSettings) {
    this.envDict = {};

    const indexOfEnabledEnvironment = settings.environments.findIndex(x => x.isDisabled !== true);

    if (settings) {
      settings.environments
      .forEach((a, index) => {
        this.envDict[a.environmentCode] = {
          selected: index === indexOfEnabledEnvironment,
          overrideSystemDefaultsCbId: v4()
        };
      });
    }
  }

}
