import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

import { IDENTITY_SERVER_BASE_URL } from './models/tokens';

import { SecurityService } from './security.service';
import { TokenService } from './services/token/token.service';
import { UserService } from './services/user/user.service';
import { LeversysCoreUIModule } from '@lv-core-ui/leversys-core-ui.module';

/**
 * Security module.
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    // Kendo modules
    LabelModule,
    InputsModule,
    ButtonsModule,
    LeversysCoreUIModule
  ],
})
export class SecurityModule {

  constructor(@Optional() @SkipSelf() parentModule: SecurityModule) {
    if (parentModule) {
      throw new Error('SecurityModule is already loaded. It should only be imported in your application\'s main module.');
    }
  }

  /**
   * Register services for security module.
   * @param identityServerBaseUrl Identity server base URL
   * @returns SecurityModule object
   */
  public static forRoot(identityServerBaseUrl: string): ModuleWithProviders<SecurityModule> {
    return {
      ngModule: SecurityModule,
      providers: [
        TokenService,
        UserService,
        SecurityService,
        {
          provide: IDENTITY_SERVER_BASE_URL,
          useValue: identityServerBaseUrl
        }
      ]
    };
  }
}
