<lv-flex-box direction="row" class="lv-convertible-bond-terms-coupon-fixed">
  <lv-flex-box direction="row">
    <lv-flex-box class="lv-fixed-coupon-first-column">
      <lv-flex-box direction="row" data-cy="DM-372">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="fixedCouponRateSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Fixed Coupon Rate
          </span>
          <lv-xl-label [field]="'CPN_RT'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            name="terms-general-fixed-coupon-rate"
            [format]="formatNumber"
            [decimals]="decimalsNumber"
            [disabled]="isNewIssueRange"
            [(ngModel)]="model.rate"
            (didBlur)="onFixedCouponModelChange()"
            (didKeyDownEnter)="onFixedCouponModelChange()">
          </lv-numeric-text-box>
          <span class="lv-sufix-label">%</span>
        </div>
      </lv-flex-box>
    </lv-flex-box>
    <lv-flex-box>
      <lv-flex-box class="fixed-step-up" direction="row" data-cy="DM-980">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="stepUpCouponScheduleSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Step-Up Coupon Schedule
          </span>
          <lv-xl-label [field]="'CPN_USE_STEPUP_SCHED'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="useStepupCouponScheduleCheckBoxId"
            name="terms-general-fixed-coupon-use-setup"
            [(ngModel)]="model.useStepupCouponSchedule"
            (ngModelChange)="onFixedCouponModelChange()">
          <label class="k-checkbox-label"
            [attr.for]="useStepupCouponScheduleCheckBoxId">
          </label>
        </div>
      </lv-flex-box>
      <lv-flex-box  direction="row" *ngIf="model.useStepupCouponSchedule">
        <lv-step-up-coupon-schedule
          [model]="model.stepUpCouponSchedule"
          (didStepUpCouponScheduleChange)="onFixedCouponModelChange()">
        </lv-step-up-coupon-schedule>
      </lv-flex-box>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<ng-template #fixedCouponRateSourceTooltip>
  <lv-tooltip dm="dM-372"></lv-tooltip>
</ng-template>
<ng-template #stepUpCouponScheduleSourceTooltip>
  <lv-tooltip dm="dM-982"></lv-tooltip>
</ng-template>
