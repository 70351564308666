import { LvScenarioGridView, ILvScenarioGridView, ILvScenarioGridViewGroup, ILvScenarioGridViewRecord } from '../lv-scenario-grid.view';
import { ILvScenarioReport } from '../../lv-scenario.view';
import { ScenarioOutput, ICalculateScenarioOutputVector } from '@lv-analytics/models';

export interface ITwoDimOutputValue {
  output: ScenarioOutput;
  value: number;
}

export interface ITwoDimPriceTalkGrid extends ILvScenarioGridView {
  dimensionDescription: string;
  groups: ILvScenarioGridViewGroup[];
  outputs: ScenarioOutput[];
  currentOutput: ScenarioOutput;
}

/**
 * Scenario two dim price talk grid view.
 */
export class LvScenarioTwoDimPriceTalkGridView extends LvScenarioGridView<ITwoDimPriceTalkGrid> {

  /**
   * Gets default model/
   * @returns ITwoDimPriceTalkGrid object.
   */
  getDefaultModel(): ITwoDimPriceTalkGrid {
    return {
      dimensionDescription: null,
      groups: [],
      outputs: [],
      currentOutput: null,
      records: []
    };
  }

  /**
   * Does initialization.
   * @param report ILvScenarioReport object.
   */
  doInit(report: ILvScenarioReport) {
    const mapped = (report.data as ICalculateScenarioOutputVector[]);

    mapped.forEach((fDim, i) => {
      if (this.isFirstIndex(i)) {
        this.model.dimensionDescription = this.getFirstDimensionDescription(fDim.dimension);
      }

      const rec: ILvScenarioGridViewRecord = {
        dimension: fDim.dimension,
        dimensionValue: fDim.value
      };

      fDim.outputs.forEach((sDim, j) => {
        if (this.areFirstIndexes(i, j)) { // root group
          this.model.groups.push({
            groupId: sDim.dimension,
            title: this.getFirstDimensionDescription(sDim.dimension),
            width: this.defaultColumnWidth,
            subGroups: []
          });
        }

        if (this.isFirstIndex(i)) {
          this.model.groups[i].subGroups.push({ // second dim group
            groupId: `${sDim.value}`,
            data: sDim.value,
            width: this.defaultColumnWidth,
            columns: []
          });
        }

        sDim.outputs.forEach((output, k) => {
          if (this.areFirstIndexes(i, j)) {
            this.model.outputs.push(output.output);
            this.model.groups[i].subGroups[j].width = output.values.length * this.defaultColumnWidth;
          }

          if (this.areFirstIndexes(i, j, k)) {
            this.model.currentOutput = output.output;
            this.model.groups[i].width = fDim.outputs.length * output.values.length * this.defaultColumnWidth;
          }

          output.values.forEach(value => {
            const columnId = `${sDim.value}_${value.priceTalk}`;

            if (this.areFirstIndexes(i, k)) {
              this.model.groups[i].subGroups[j].columns.push({
                columnId: columnId,
                title: this.getPriceTalkDescription(value.priceTalk),
                width: this.defaultColumnWidth
              });
            }

            if (!rec[columnId]) {
              rec[columnId] = [];
            }

            rec[columnId].push({
              output: output.output,
              value: value.value
            } as ITwoDimOutputValue);
          });
        });
      });

      this.model.records.push(rec);
    });
  }

  /**
   * Checks if output is visible.
   * @param output Scenario output.
   * @returns A flag indicating if output is visible.
   */
  isOutputVisible(output: ScenarioOutput) {
    return this.model.currentOutput === output;
  }
}
