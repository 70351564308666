import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { LvExcelService } from '@lv-excel/services';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'lv-excel-save-modal',
  templateUrl: './lv-excel-save-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvExcelSaveModalComponent {

  @Input() isTermsSave: boolean;

  @Output() doSaveToApplication: EventEmitter<void>;
  @Output() doSaveToExcel: EventEmitter<void>;
  @Output() doSaveToAll: EventEmitter<void>;

  constructor(
    private _dialogRef: DialogRef
  ) {
    this.doSaveToApplication = new EventEmitter<void>();
    this.doSaveToExcel = new EventEmitter<void>();
    this.doSaveToAll = new EventEmitter<void>();
    this.isTermsSave = false;
  }

  saveToApplication(): void {
    this.doSaveToApplication.emit();
    this._dialogRef.close();
  }

  saveToExcel(): void {
    this.doSaveToExcel.emit();
    this._dialogRef.close();
  }

  saveToAll(): void {
    this.doSaveToAll.emit();
    this._dialogRef.close();
  }
}
