import { OnInit, OnDestroy, Directive, ElementRef, Output } from '@angular/core';

import { Subject, Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { GridComponent } from '@progress/kendo-angular-grid';

export interface IGridAreaSelection {
  rows: {
    from: number;
    to: number;
  };
  cols: {
    from: number;
    to: number;
  };
}

export interface IGridAreaSelectionMap {
  [rowColIndex: string]: boolean; // "0-1": true
}

export interface IGridAreaSelectionChangeEvent {
  rowFrom: number;
  rowTo: number;
  colFrom: number;
  colTo: number;
  rowColsMap: IGridAreaSelectionMap;
}

@Directive({
  selector: '[gridAreaSelection]'
})
export class GridAreaSelectionDirective implements OnInit, OnDestroy {

  @Output() didSelectionAreaChanged: Subject<IGridAreaSelectionChangeEvent>;

  private _selectedArea: IGridAreaSelection;

  private _keyDownSubscription: Subscription;
  private _keyUpSubscription: Subscription;

  constructor(
    private _grid: GridComponent,
    private _el: ElementRef
  ) {
    this._selectedArea = null;
    this.didSelectionAreaChanged = new Subject<IGridAreaSelectionChangeEvent>();
  }

  public ngOnInit(): void {
    this._keyDownSubscription = fromEvent(this._el.nativeElement, 'keydown')
      .pipe(
        debounceTime(10)
      )
      .subscribe((e: KeyboardEvent) => this.onKeydown(e));

    this._keyUpSubscription = fromEvent(this._el.nativeElement, 'keyup')
      .pipe(
        debounceTime(10)
      )
      .subscribe((e: KeyboardEvent) => this.onKeyup(e));
  }

  public clearSelection() {
    this._selectedArea = null;
    this.fireEvent();
  }

  public ngOnDestroy(): void {
    this._keyDownSubscription.unsubscribe();
    this._keyUpSubscription.unsubscribe();
  }

  private onKeydown(e: KeyboardEvent): void {
    if (this._grid.isEditing()) {
      return;
    }

    if (e.key === 'Shift') {
      const activeCell = this._grid.activeCell;
      const activeRow = this._grid.activeRow;
      const rowIndex = activeRow.dataRowIndex;
      const colIndex = activeCell.colIndex;

      this._selectedArea = {
        rows: {
          from: rowIndex || 0,
          to: rowIndex
        },
        cols: {
          from: colIndex,
          to: colIndex
        }
      };
    }

    if (this._selectedArea &&
      (e.key === 'ArrowUp' || e.key === 'ArrowDown'
        || e.key === 'ArrowLeft' || e.key === 'ArrowRight')
     ) {
      const activeCell = this._grid.activeCell;
      const activeRow = this._grid.activeRow;
      this._selectedArea.rows.to = activeRow.dataRowIndex;
      this._selectedArea.cols.to = activeCell.colIndex;

      this.fireEvent();
    }
  }

  private onKeyup(e: KeyboardEvent): void {
    if (this._grid.isEditing()) {
      return;
    }

    if (e.key === 'Shift') {
      this.clearSelection();
    }
  }

  private fireEvent() {
    if (!this._selectedArea) {
      this.didSelectionAreaChanged.next(null);
      return;
    }

    const event: IGridAreaSelectionChangeEvent = {
      rowFrom: this._selectedArea.rows.from,
      rowTo: this._selectedArea.rows.to,
      colFrom: this._selectedArea.cols.from,
      colTo: this._selectedArea.cols.to,
      rowColsMap: {}
    };

    if (event.rowFrom > event.rowTo) {
      event.rowFrom = this._selectedArea.rows.to;
      event.rowTo = this._selectedArea.rows.from;
    }

    if (event.colFrom > event.colTo) {
      event.colFrom = this._selectedArea.cols.to;
      event.colTo = this._selectedArea.cols.from;
    }

    for (let i = event.rowFrom; i <= event.rowTo; i++) {
      for (let k = event.colFrom; k <= event.colTo; k++) {
        event.rowColsMap[`${i}-${k}`] = true;
      }
    }

    this.didSelectionAreaChanged.next(event);
  }
}
