<kendo-grid
  [dataItemKey]="recordIdField"
  [editorPopupOpened]="editorPopupOpened"
  [editDisabled]="editDisabled"
  [gridCellEditing]="createFormGroup"
  [gridRowEditing]="createFormGroup"
  gridAreaSelection
  gridKeyboardRemove
  [gridContextMenu]="gridMenu"
  [gridPaste]="parseFn"
  [data]="recordItems"
  [navigable]="true"
  scrollable="none"
  [lvId]="lvId"
  [class.lv-advanced-grid-scrollable]="isScrollable"
  [scrollable]="scrollMode"
  [style.height]="gridHeight">

  <ng-container *ngFor="let c of columnItems; let columnIndex = index">
    <kendo-grid-column *ngIf="c.visible"
      [class]="c.ngClass"
      [title]="c.title"
      [width]="c.width"
      [field]="c.field"
      [editable]="c.editable">

      <ng-template kendoGridHeaderTemplate let-column>
        <div class="lv-advanced-grid-header-wrapper">
          <div class="lv-advanced-grid-header-cell"
          [class.lv-advanced-grid-cell--output]="c.isOutputColumn"
          kendoTooltip
          [tooltipTemplate]="editDisabled ? null : headerCellTemplate"
          filter="div">
          <div>{{ column.title }}</div>
          </div>
          <label *ngIf="showXlLabel"
            kendoTooltip
            filter="span"
            [tooltipTemplate]="excelFieldTemplate"
            class="lv-xl-label"
            [class.lv-xl-label--not-editable]="!isFieldFromExcelEnabled">
            <span *ngIf="shouldShowXlLabel(columnIndex)"
              [attr.xl-tooltip-id]="'xlSourceTooltip'"
              [attr.columnIndex]="columnIndex">
              XL
            </span>
          </label>
        </div>
      </ng-template>

      <ng-template kendoGridCellTemplate
        let-dataItem
        let-rowIndex="rowIndex">
        <div *ngIf="c.isCellVisible(rowIndex, columnIndex)"
          class="lv-advanced-grid-cell"
          [class.lv-advanced-grid-cell--right]="c.isRightAligned"
          [class.lv-advanced-grid-cell--focused]="rowColsMap[rowIndex + '-' + columnIndex]"
          [class.lv-advanced-grid-cell--negative]="isNegative(dataItem[c.field])"

          [attr.data-cy]="c.dmKey"

          [ngStyle]="c.getStyleWidth(c.width)">
          <ng-container *ngIf="dataItem[recordIdField]">

            <ng-container *ngIf="c.isTextColumn">{{ dataItem[c.field] }}</ng-container>

            <ng-container *ngIf="c.isNumericColumn">
              <ng-container *ngIf="hasThreeDecimalPlaces, else twoDecimalPlaces">
                {{ dataItem[c.field] | number: c.threeDecimalOutputFormat }}
              </ng-container>

              <ng-container *ngIf="c.isPercentFormat(rowIndex, columnIndex)">
                {{ dataItem[c.field ] }}%
              </ng-container>
            </ng-container>

            <ng-container *ngIf="c.isDateColumn">{{ dataItem[c.field] | lvLocalDate }}</ng-container>

            <ng-container *ngIf="c.isListColumn">
              <div *ngIf="c.valuePrimitive">{{ c.dataMap[dataItem[c.field]] ? c.dataMap[dataItem[c.field]][c.displayField] : '' }}</div>
            </ng-container>

            <ng-container *ngIf="c.isEnumColumn">
              <div *ngIf="c.valuePrimitive">{{ dataItem[c.field] | enum: c.enumDescription }}</div>
            </ng-container>

            <ng-template #twoDecimalPlaces>
              <ng-container *ngIf="c.isNumberFormat(rowIndex, columnIndex)">
                {{ dataItem[c.field] | number: c.outputFormat }}
            </ng-container>
            </ng-template>
        </ng-container>
        </div>
        <div *ngIf="!c.isCellVisible(rowIndex, columnIndex) && c.isCellPlaceholder(rowIndex, columnIndex)"
          class="right-align-placeholder">
          {{ c.isCellPlaceholder(rowIndex, columnIndex) }}
        </div>
      </ng-template>

      <ng-template kendoGridEditTemplate
        let-dataItem="dataItem"
        let-formGroup="formGroup"
        let-column="column"
        let-rowIndex="rowIndex">
        <ng-container *ngIf="c.isCellVisible(rowIndex, columnIndex)">
          <lv-text-box *ngIf="c.isTextColumn"
            [gridCellContextMenu]="self"
            [ngStyle]="c.getStyleWidth(c.width)"
            [attr.name]="column.field"
            [placeholder]="c.placeholder"
            [searchIconVisible]="false"
            [formControl]="formGroup.get(column.field)"
            [attr.data-cy]="c.dmKey">
          </lv-text-box>

          <kendo-numerictextbox *ngIf="c.isNumericColumn"
            [gridCellContextMenu]="self"
            [ngStyle]="c.getStyleWidth(c.width)"
            kendoGridFocusable
            [attr.name]="column.field"
            [format]="c.format"
            [decimals]="c.decimals"
            [formControl]="formGroup.get(column.field)"
            [spinners]="false"
            [attr.data-cy]="c.dmKey">
          </kendo-numerictextbox>

          <kendo-datepicker *ngIf="c.isDateColumn"
            [ngStyle]="c.getStyleWidth(c.width)"
            [gridCellContextMenu]="self"
            kendoGridFocusable
            [formatPlaceholder]="{ year: 'yyyy', month: 'mm', day: 'dd' }"
            [attr.name]="column.field"
            [formControl]="formGroup.get(column.field)"
            [attr.data-cy]="c.dmKey"
            (open)="onPopupToggled(true)"
            (close)="onPopupToggled(false)">
          </kendo-datepicker>

          <kendo-dropdownlist *ngIf="c.isListColumn || c.isEnumColumn"
            kendoGridFocusable
            [gridCellContextMenu]="self"
            [ngStyle]="c.getStyleWidth(c.width)"
            [attr.name]="column.field"
            [formControl]="formGroup.get(column.field)"
            [data]="c.data"
            [valueField]="c.valueField"
            [textField]="c.displayField"
            [defaultItem]="c.getDefaultDropdownItem(dataItem[c.field])"
            [valuePrimitive]="c.valuePrimitive"
            [attr.data-cy]="c.dmKey"
            (valueChange)="onDropdownCellValueCange($event)"
            (open)="onPopupToggled(true)"
            (close)="onPopupToggled(false)">
          </kendo-dropdownlist>
        </ng-container>
        <ng-container *ngIf="!c.isCellVisible(rowIndex, columnIndex) && c.isCellPlaceholder(rowIndex, columnIndex)">
          <div class="right-align-placeholder">
          {{ c.isCellPlaceholder(rowIndex, columnIndex) }}
        </div>
        </ng-container>
      </ng-template>

    </kendo-grid-column>
  </ng-container>

</kendo-grid>
<kendo-contextmenu #gridMenu>
  <ng-container *ngIf="contextMenuItems">
    <ng-container *ngFor="let item of contextMenuItems">
      <kendo-menu-item *ngIf="isContextMenuItemVisible(item.actionVisible) && item.showDivider"
        cssClass="lv-context-item-show-border"
        [text]="item.actionText"
        [data]="item.actionId">
      </kendo-menu-item>
      <kendo-menu-item *ngIf="isContextMenuItemVisible(item.actionVisible) && !item.showDivider"
        [text]="item.actionText"
        [data]="item.actionId">
      </kendo-menu-item>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!editDisabled">
    <kendo-menu-item *ngIf="isDeleteMenuItemVisible"
      text="Delete Row"
      data="delete">
    </kendo-menu-item>
    <kendo-menu-item *ngIf="isDeleteMenuItemVisible"
      text="Delete All"
      data="delete-all">
    </kendo-menu-item>
  </ng-container>
</kendo-contextmenu>

<ng-template #headerCellTemplate>
  <div>Enter values and press <strong>Enter</strong> to confirm</div>
  <div>Press <strong>Ctrl</strong>&nbsp;<strong>V</strong> to paste data to the table</div>
  <div>Press <strong>Shift</strong>&nbsp;<strong>-</strong> to delete selected row</div>
  <div>Use <strong>Right</strong>&nbsp;<strong>Click</strong> to delete selected or all rows</div>
</ng-template>

<ng-template #excelFieldTemplate let-anchor>
  <div class="popup-xl-content">
    {{getExcelFieldAlias(anchor.nativeElement.getAttribute('columnIndex'))}}
  </div>
</ng-template>
