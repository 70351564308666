import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { LvOutputsWidgetComponent } from '@lv-analytics/components/lv-outputs/lv-outputs-widget/lv-outputs-widget.component';
import { LvAdvancedGridColumn, LvAdvancedGridDateColumn, LvAdvancedGridNumericColumn, LvAdvancedGridTextColumn } from '@lv-core-ui/components';

/**
 * Tabular schedule component is responsible for presenting Tabular Outputs in grid
 */
@Component({
  selector: 'lv-tabular-schedule',
  templateUrl: './lv-tabular-schedule.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LvTabularScheduleComponent implements OnChanges {

  @Input() tabularScheduleContent: [];
  @Input() outputType: string;
  @Input() showAsPriceAsPar: boolean;

  columns: LvAdvancedGridColumn[];
  editDisabled: boolean;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _outputsWidgetComponent: LvOutputsWidgetComponent,
  ) {
    this.columns = [];
    this.tabularScheduleContent = []
    this.editDisabled = true;
    this.showAsPriceAsPar = false;
  }

  /**
   * Detects changes in component lifecycle (e.g. input changes).
   */
  ngOnChanges() {
    this.initColumns();
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Method for initializing grid columns.
   */
  private initColumns(): void {

    const keyColumn = this.getColumnType(this.outputType);
    keyColumn.isOutputColumn = true;
    keyColumn.title = this.getColumnTitle(this.outputType, true);
    keyColumn.field = this.getKeyColumnName(this.outputType);
    keyColumn.width = 122;
    keyColumn.editable = false;

    const valueColumn = new LvAdvancedGridNumericColumn();
    valueColumn.isOutputColumn = true;
    valueColumn.title = this.getColumnTitle(this.outputType, false);
    valueColumn.field = this.getValueColumnName(this.outputType);
    valueColumn.width = 131;
    valueColumn.editable = false;
    valueColumn.alignment = 'left';

    this.columns = [
      keyColumn,
      valueColumn,
    ];
  }

  /**
   * Get column type.
   * @param outputType Output type value. 
   * @returns Column type.
   */
  private getColumnType(outputType: string): LvAdvancedGridColumn {
    if (outputType === 'accretedPutValue' || outputType === 'presentValuePartialPuts') {
      return new LvAdvancedGridDateColumn();
    }

    return new LvAdvancedGridTextColumn();
  }

  /**
   * Get key column name.
   * @param outputType Output type value.
   * @returns Key column name.
   */
  private getKeyColumnName(outputType: string): string {
    switch (outputType) {
      case 'accretedPutValue':
      case 'presentValuePartialPuts':
        return 'date';
      case 'rhoBucket':
      case 'cSSensBucket':
        return 'tenor';
      default:
        return 'key'
    }
  }

  /**
   * Get value column name.
   * @param outputType Output type value.
   * @returns Value column name.
   */
  private getValueColumnName(outputType: string): string {
    switch (outputType) {
      case 'cSSensBucket':
        return 'sensitivity';
      default:
        return 'value'
    }
  }

  /**
   * Method return column title based on output type.
   * @param outputType Output type.
   * @param keyColumn Flag true for title column, false for value column.
   * @returns Column title.
   */
  private getColumnTitle(outputType: string, keyColumn: boolean): string {
    if (outputType === 'accretedPutValue') {
      return keyColumn ? 'Put Date' : 'Put Value (%)';
    }
    else if (outputType === 'rhoBucket') {
      return keyColumn ? 'Tenor' : this.showAsPriceAsPar ?
        `Rho (${this._outputsWidgetComponent.view.asHelper.convertible.currencyCode})` : 'Rho (pts)';
    }
    else if (outputType === 'presentValuePartialPuts') {
      return keyColumn ? 'Put Date' : 'Present Value';
    }
    else if (outputType === 'cSSensBucket') {
      return keyColumn ? 'Tenor' : this.showAsPriceAsPar ?
        `CS Sensitivity (${this._outputsWidgetComponent.view.asHelper.convertible.currencyCode})` : 'CS Sensitivity (pts)';
    }
  }
}
