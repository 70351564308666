import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Input} from '@angular/core';
import { LvTermsGeneralView } from './lv-terms-general.view';
import { Subscription } from 'rxjs';
import { LvConvertibleBondTermsPresenter } from '../lv-convertible-bond-terms.presenter';
import { filter } from 'rxjs/operators';
import { ConvertibleBondSubType } from '../../../models/convertible-bond-terms/ConvertibleBondSubType';
import { LvErrorService } from '@lv-core-ui/services';
import { ConvertibleBondTermsSectionEvent, IssueAndRedemption, SinkingFundScheduleItem } from '@lv-convertible-bond/models';
import { LvDataMaster } from '@lv-core-ui/models';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';
import { StockReferenceSignalStore } from '../stores/lv-stock-reference.store';

@Component({
  selector: 'lv-terms-general',
  templateUrl: './lv-terms-general.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvTermsGeneralComponent implements OnInit, OnDestroy {

  @Input() isLvsConvertible: boolean;
  @Input() stockReferenceSignalStore: StockReferenceSignalStore;

  public view: LvTermsGeneralView;
  isLoading: boolean;
 
  private _modelSubscription: Subscription;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvConvertibleBondTermsPresenter,
    private _errorService: LvErrorService,
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService
  ) {
    this.view = new LvTermsGeneralView(_lvConvertibleBondTermsService);
    this.isLoading = false;
    this.isLvsConvertible = true;
  }

  ngOnInit() {

   this._modelSubscription = this._presenter.onModelUpdated
    .pipe(
      filter(event => event.eventId !== ConvertibleBondTermsSectionEvent.TermsGeneralEvent &&
        event.eventId !== ConvertibleBondTermsSectionEvent.OtherGeneralEvent &&
        event.eventId !== ConvertibleBondTermsSectionEvent.PriceTalkEvent &&
        event.eventId !== ConvertibleBondTermsSectionEvent.StockPriceReference &&
        event.eventId !== ConvertibleBondTermsSectionEvent.MWInitCallsEvent &&
        event.eventId !== ConvertibleBondTermsSectionEvent.MWInitConversionEvent)
    )
    .subscribe(model => {
      if (model.data) {
        this.view.init(model.data.fullTerms.issueAndRedemption,
                       model.data.fullTerms.other,
                       model.data.fullTerms.priceTalk,
                       model.data.fullTerms.stockPriceReference,
                       model.data.fullTerms.conversion);
        this._changeDetectorRef.detectChanges();
      }
    });

   if (this._presenter.isModelLoaded()) {
      const fullTerms = this._presenter.getModel().fullTerms;
      this.view.init(fullTerms.issueAndRedemption,
        fullTerms.other,
        fullTerms.priceTalk,
        fullTerms.stockPriceReference,
        fullTerms.conversion);
      this._changeDetectorRef.detectChanges();
    }
  }

  doSetGeneralInformation(issueAndRedemption: IssueAndRedemption) {
    this.view.doSetGeneralInformation(issueAndRedemption);
    this.onIssueAndRedemptionChange();
  }

  onNominalChanged(issueAndRedemption: IssueAndRedemption) {
    this.view.doSetGeneralInformation(issueAndRedemption);
    this._errorService.toastrService.warning(LvDataMaster.getWarning('dM-1861'));
    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.NominalChangedEvent,
      data: this.view.issueAndRedemption
    }, true);
  }

  doSetSchedule(schedule: SinkingFundScheduleItem[]) {
    this.view.doSetSchedule(schedule);
    this.onIssueAndRedemptionChange();
  }

  onIssueAndRedemptionChange() {
    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.TermsGeneralEvent,
      data: this.view.issueAndRedemption
    });

    if (this.view.issueAndRedemption.subType !==  ConvertibleBondSubType.Regular) {
        this.view.conversion.isContigentConversion = false;
        this.view.conversion.isResetable = false;
        this.view.conversion.isVariableConversion = false;
      }

    if (this.view.issueAndRedemption.subType ===  ConvertibleBondSubType.PEPS ||
        this.view.issueAndRedemption.subType ===  ConvertibleBondSubType.PERCS) {
        this.view.conversion.useAcquisitionSharesSettlement = false;
      }

    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.ConversionEvent,
      data: this.view.conversion
    });
  }

  ngOnDestroy() {
    this._modelSubscription.unsubscribe();
  }
}
