import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpClientBase } from '@lv-core-ui/api';
import { IPaginatedResult } from '@lv-core-ui/models';
import { IWatchList, LvInstrumentMonitorError, IWatchListInstrumentInfo, IInstrumentInfo,
         IInstrumentInfoWatchList, IWatchListResponse } from '@lv-instrument-monitor/models';

@Injectable()
export class WatchListService extends HttpClientBase {

  constructor(
    http: HttpClient
  ) {
    const resourceUrl = '/instrumentMonitor/watchLists';
    super(http, resourceUrl);
  }

  async createUserWatchList(listName: string): Promise<IWatchList> {
    try {
      const response = await this.postAsync<IWatchList>({
        listName: listName || ''
      });

      return response;
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }

  async updateUserWatchList(watchList: IWatchList): Promise<IWatchList> {
    try {
      const response = await this.putAsync<any>({
        id: watchList.id,
        listName: watchList.listName
      });

      return response;
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }

  async deleteUserWatchList(watchList: IWatchList): Promise<void> {
    try {
      await this.deleteAsync(`/${watchList.id}`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }

  async getUserWatchListInstruments(userWatchLitsId: number): Promise<IPaginatedResult<IWatchListInstrumentInfo>> {
    try {
      const result = await this.getAsync<IPaginatedResult<IWatchListInstrumentInfo>>(null, `/${userWatchLitsId}/instruments`);

      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }

  async updateUserWatchListInstruments(userWatchListId: number, instrumentInfos: IInstrumentInfo[]): Promise<void> {
    try {
      await this.putAsync<IPaginatedResult<IInstrumentInfo>>({
        id: userWatchListId,
        instrumentInfoIds: instrumentInfos.map(a => a.id)
      }, `/${userWatchListId}/instruments`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }

  async getUserInstrumentWatchLists(instrumentInfoId: number): Promise<IPaginatedResult<IInstrumentInfoWatchList>> {
    try {
      const result = await this.getAsync<IPaginatedResult<IInstrumentInfoWatchList>>(null, `/${instrumentInfoId}/watchlists`);

      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }

  async updateUserInstrumentWatchLists(instrumentInfoId: number, userWatchLists: IInstrumentInfoWatchList[]): Promise<void> {
    try {
      await this.putAsync<any>({
        id: instrumentInfoId,
        userWatchListIds: userWatchLists.map(a => a.id)
      }, `/${instrumentInfoId}/watchlists`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }

  async updateUserInstrumentWatchList(instrumentInfoId: number, userWatchListId: number, added: boolean): Promise<void> {
    try {
      await this.putAsync<any>({
        id: instrumentInfoId,
        userWatchListId: userWatchListId,
        added: added
      }, `/${instrumentInfoId}/watchlist`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }

  async getAvailableUserWatchListInstruments(): Promise<IPaginatedResult<IInstrumentInfo>> {
    try {
      const result = await this.getAsync<IPaginatedResult<IInstrumentInfo>>(null, '/instruments/available');

      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }

  async getAllInstrumentsWatchList(): Promise<IWatchList> {
    try {
      const result: IWatchListResponse = await this.getAsync<IWatchListResponse>(null, '/user/allInstruments');
      return result.watchLists[0];
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }

  async getNewIssueWatchList(): Promise<IWatchList> {
    try {
      const result: IWatchListResponse = await this.getAsync<IWatchListResponse>(null, '/user/newIssue');
      return result.watchLists[0];
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }

  async getEstimatesWatchList(): Promise<IWatchList> {
    try {
      const result: IWatchListResponse = await this.getAsync<IWatchListResponse>(null, '/user/estimates');
      return result.watchLists[0];
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }

  async getAvailableWatchLists(): Promise<IWatchListResponse> {
    try {
      const result: IWatchListResponse = await this.getAsync<IWatchListResponse>(null, '/user/available');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }
}
