import { v4 } from 'uuid';

import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, ChangeDetectorRef, ElementRef} from '@angular/core';
import { LvLookupEnum } from '@lv-core-ui/util';
import { ILWSEvent, EventTypeDescription, RegionDescripton, EventType } from '@lv-notifications/models';

/**
 * Component responsible for managing lws events
 */
@Component({
  selector: 'lv-lws-event',
  templateUrl: './lv-lws-event.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvLwsEventComponent {

  @Input() lwsEvent: ILWSEvent;
  @Input() regionPermisions: string[];
  @Input() canReceiveAllNotifications: boolean;
  @Input() canReceiveNewIssueNotifications: boolean;

  get eventType(): string {
    if (!this.lwsEvent || !this.lwsEvent.type) {
      return '';
    }
    return EventTypeDescription[this.lwsEvent.type];
  }

  get getLwsEventTooltipIdValue(): string {
    return `lwsEvent${this.lwsEvent.type}`;
  }

  checkBoxId: string;
  radiButtonId: string;
  regionsEnum: LvLookupEnum;

  constructor(
    private changeDetectorRef: ChangeDetectorRef) {
    this.lwsEvent = {} as ILWSEvent;
    this.checkBoxId = v4();
    this.radiButtonId = v4();
    this.regionsEnum = new LvLookupEnum(RegionDescripton);
    this.canReceiveAllNotifications = false;
    this.canReceiveNewIssueNotifications = false;
    this.regionPermisions = [];
  }

  /**
   * Check if check-buttons needs to be disabled for some users based on his access rights
   * @param region Name of region for that type of notification check box
   * @returns Flag that describes if check box should be disabled
   */
  isDisabled(region: string) {
    let isDisabled = !this.canReceiveAllNotifications;

    if (this.regionPermisions != null && this.regionPermisions.length > 0) {
      isDisabled = !(this.regionPermisions?.includes(region) || this.canReceiveAllNotifications);
    }

    if (this.canReceiveNewIssueNotifications &&
      (this.lwsEvent.type === EventType.NewIssueFixed
      || this.lwsEvent.type === EventType.NewIssueSetup
      || this.lwsEvent.type === EventType.NewIssueSetupCorrected)) {
      isDisabled = !this.canReceiveNewIssueNotifications;
    }

    return isDisabled;
  }

  /**
   * Generate check-box unique identifier
   * @param key Check-box key
   * @returns formated unique check-box identifier
   */
  getCheckBoxId(key: string): string {
    return `${key}-${this.checkBoxId}`;
  }

  /**
   * Generate radio-button unique identifier
   * @param key Radio-button key
   * @returns formated unique radio-button identifier
   */
  getRadioButtonId(key: string): string {
    return `${key}-${this.radiButtonId}`;
  }

  /**
   * Generate lws event tooltip unique identifier
   * @param element Html element for tooltip area
   * @param sectionId Section identifier
   * @returns formated unique lws event tooltip identifier
   */
  getLwsEventTooltipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
}
