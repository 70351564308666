import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, ElementRef,
  OnDestroy, Type, NgZone, Input, EventEmitter, Output, ViewContainerRef, ComponentFactoryResolver,
  Inject, ComponentRef } from '@angular/core';

import { Subject } from 'rxjs';

import { LvGoldenLayoutService } from '../../services/lv-golden-layout/lv-golden-layout.service';
import { LvGoldenLayoutStorage } from '../../util/lv-golden-layout-storage';
import { LvGlOnResize, LvGlOnShow, LvGlOnHide, LvGlOnTab, LvGlOnClose, LvGlOnDestroy, LvGlOnDrop } from '../../models/hooks';
import { ILvGlLayoutConfiguration, ILvGlComponent, ILvGoldenLayoutContainer, ILvGoldenLayoutComponent } from '../../models/configuration';
import { ILvGlComponentMap, LVGL_COMPONENT_MAP } from '../../models/token';
import { ILvGoldenLayoutError } from '../../models/errors';
import { LvGlComponent } from '../../models/component';
import { ResizeHandlerService } from '@lv-core-ui/services';
import { LvUtil } from '@lv-core-ui/util';

// golden layout imports
import { LayoutConfig, ComponentContainer, ComponentItem, ResolvedComponentItemConfig,
  GoldenLayout } from 'src/app/leversys-golden-layout-source/src/index';

@Component({
  selector: 'lv-golden-layout',
  templateUrl: './lv-golden-layout.component.html',
  styleUrls: ['./lv-golden-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvGoldenLayoutComponent implements OnInit, OnDestroy {

  @ViewChild('container', { static: true })
  private _container: ElementRef;

  @Input() configurationId: string;

  @Output() didStateChange: EventEmitter<ILvGlLayoutConfiguration>;
  @Output() didError: EventEmitter<ILvGoldenLayoutError>;
  @Output() didItemDestroyed: EventEmitter<ILvGoldenLayoutComponent>;

  public get configuration(): ILvGlLayoutConfiguration {
    return this._configuration;
  }

  public goldenLayout: GoldenLayout;
  public componentMap: ILvGlComponentMap;
  public componentRefDict: Map<string, ComponentRef<any>>;

  private _configuration: ILvGlLayoutConfiguration;
  private _goldenLayoutItemDropped: Subject<ILvGoldenLayoutComponent>;
  private _resizeHandlerService: ResizeHandlerService;

  constructor(
    private _ngZone: NgZone,
    private _viewContainerRef: ViewContainerRef,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _lvGoldenLayoutService: LvGoldenLayoutService,
    private _lvGoldenLayoutStorage: LvGoldenLayoutStorage,
    @Inject(LVGL_COMPONENT_MAP) map: ILvGlComponentMap
  ) {
    this.componentMap = map;
    this.componentRefDict = new Map();

    this._goldenLayoutItemDropped = new Subject<ILvGoldenLayoutComponent>();
    this._resizeHandlerService = new ResizeHandlerService();

    this.didStateChange = new EventEmitter<ILvGlLayoutConfiguration>();
    this.didError = new EventEmitter<ILvGoldenLayoutError>();
    this.didItemDestroyed = new EventEmitter<ILvGoldenLayoutComponent>();
  }

  ngOnInit() {
    this._configuration = this._lvGoldenLayoutStorage.getLayoutConfiguration(this.configurationId);

    this.goldenLayout = new GoldenLayout(this._container.nativeElement);
    this.goldenLayout.releaseComponentEvent = (container, component) => this.handleReleaseComponentEvent(container, component);

    this._resizeHandlerService.listenTo(this._container, this.updateLayout.bind(this));
    this.goldenLayout.on('itemDestroyed', this.itemDestroyed.bind(this));
    this.goldenLayout.on('stateChanged', this.stateChanged.bind(this));
    this.goldenLayout.on('itemDropped', this.itemDropped.bind(this));

    if (this.goldenLayout.layoutConfig.root && this.goldenLayout.layoutConfig.root.content.length <= 2) {
      (this.goldenLayout.layoutConfig.root as any)._isClosable = true;
      this.goldenLayout.saveLayout();
    }

    try {
      this._lvGoldenLayoutService.registerLayoutContainer(this);
      this._lvGoldenLayoutService.bootstrapLayout();
    }
    catch (error) {
      this.didError.next({
        name: error.name,
        message: error.message,
        stack: error.stack,
        faultyConfiguration: { ...this._configuration }
      });
    }
  }

  /**
   * Register component factory and bind functions and events
   * @param component component instance
   * @returns function that register component events
   */
  registerComponentFactory(component: ILvGlComponent) {
    const me = this;
    // tslint:disable-next-line: only-arrow-functions
    return function(container: ILvGoldenLayoutContainer) {
      me._ngZone.run(() => {
        const componentType = me.componentMap[component.componentName];
        const componentFactory = me._componentFactoryResolver.resolveComponentFactory(componentType);
        const componentRef = me._viewContainerRef.createComponent(componentFactory);

        me.bindComponentStateId(componentRef.instance, component);
        me.bindComponentEvents(container, componentRef.instance, component);
        me.bindComponentMethods(container, componentRef.instance);

        container.element.append(componentRef.location.nativeElement);

        container.lvComponentId = component.id;

        componentRef.changeDetectorRef.detectChanges();

        me.componentRefDict.set(component.id, componentRef);
      });
    };
  }

  ngOnDestroy() {
    this._resizeHandlerService.removeAllListeners(this._container);

    this.componentRefDict.forEach(cmpRef => {
      if (LvUtil.implementsInterface<LvGlOnDrop>(cmpRef.instance, 'lvGlOnDrop') && cmpRef.instance.lvGlDropSubscription) {
        cmpRef.instance.lvGlDropSubscription.unsubscribe();
      }

      cmpRef.destroy();
    });

    this.componentRefDict = null;

    this._lvGoldenLayoutService.unregister();
  }

  /**
   * Upadate layout
   * @param elementRect Component dom element
   */
  private updateLayout(elementRect: ClientRect | DOMRect) {
    if (this.goldenLayout) {
      this.goldenLayout.setSize(elementRect.width, elementRect.height);
    }
  }

  /**
   * On golden layout state changed save config and send event
   */
  private stateChanged() {
    if (this.goldenLayout.isInitialised) {
      const layoutState = this.goldenLayout.saveLayout();
      this._configuration.layoutConfig = LayoutConfig.fromResolved(layoutState);
      this.didStateChange.emit(this._configuration);
    }
  }

  /**
   * On component destroyed remove componend from app state and destroy instance
   * @param item Destroyed item
   */
  private itemDestroyed(item: any) {
    const container = item.target.container;

    if (container && container.lvComponentId) {
      if (this.componentRefDict.has(container.lvComponentId)) {
        this.componentRefDict.get(container.lvComponentId).destroy();
        this.componentRefDict.delete(container.lvComponentId);

        this._lvGoldenLayoutStorage.removeComponent(container.lvComponentId);
        this.didItemDestroyed.next(item);
      }
    }
  }

  /**
   * On item droped send event
   * @param item Droped item
   */
  private itemDropped(item: ILvGoldenLayoutComponent) {
    this._goldenLayoutItemDropped.next(item);
  }

  /**
   * Bind state id to component
   * @param component Particular component instance
   * @param glComponent Golden layout component
   */
  private bindComponentStateId(component: Type<any>, glComponent: ILvGlComponent) {
    (component as any).stateId = glComponent.id;
    // (<any>component).setStateId(glComponent.id);
  }

  /**
   * Bind events to component
   * @param componentContainer Component container
   * @param component Particular component instance
   * @param glComponent Golden layout component
   */
  private bindComponentEvents(componentContainer: ComponentContainer, component: Type<any>, glComponent: ILvGlComponent): void {
    if (LvUtil.implementsInterface<LvGlOnDrop>(component, 'lvGlOnDrop')) {
      component.lvGlDropSubscription = this._goldenLayoutItemDropped.subscribe(item => {
        const state = (component as any).state;
        if (state && item.container.lvComponentId === state.id) {
          component.lvGlOnDrop(item);
        }
      });
    }

    if (LvUtil.implementsInterface<LvGlOnResize>(component, 'lvGlOnResize')) {
      componentContainer.on('resize', () => {
        component.lvGlOnResize();
      });
    }

    if (LvUtil.implementsInterface<LvGlOnShow>(component, 'lvGlOnShow')) {
      componentContainer.on('show', () => {
        component.lvGlOnShow();
      });
    }

    if (LvUtil.implementsInterface<LvGlOnHide>(component, 'lvGlOnHide')) {
      componentContainer.on('hide', () => {
        component.lvGlOnHide();
      });
    }

    if (LvUtil.implementsInterface<LvGlOnTab>(component, 'lvGlOnTab')) {
      componentContainer.on('tab', () => {
        component.lvGlOnTab();
      });
    }

    if (LvUtil.implementsInterface<LvGlOnClose>(component, 'lvGlOnClose')) {
      componentContainer.on('close', () => {
        component.lvGlOnClose();
      });
    }

    if (LvUtil.implementsInterface<LvGlOnDestroy>(component, 'lvGlOnDestroy')) {
      componentContainer.on('destroy', () => {
        component.lvGlOnDestroy();
      });
    }
  }

  /**
   * Bind component methods
   * @param container Conponent container
   * @param component Particular golden layout component
   */
  private bindComponentMethods(componentContainer: ComponentContainer, component: Type<any>): void {
    ((component as any) as LvGlComponent).setGoldenLayoutContainerTitle = (title: string) => {
      componentContainer.setTitle(title);
      this.stateChanged();
    };
  }

  /**
   * On component released set is closable to true
   * @param container Conponent container
   * @param component Particular golden layout component
   */
  private handleReleaseComponentEvent(container: ComponentContainer, component: ComponentItem.Component) {
    (container.parent.parentItem.parent as any)._isClosable = true;
  }
}
