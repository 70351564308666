import { Injectable } from '@angular/core';
import { IWebViewMessage } from '@lv-core-ui/models/web-view-message';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebViewService {

  public webViewMessage = new Subject<any>();

  constructor() {
    if (window.chrome && window.chrome.webview) {
      window.chrome.webview.addEventListener('message', event => {
        this.webViewMessage.next(event.data);
      });
    }
  }

  public postMessage(message: IWebViewMessage) {
    if (window.chrome && window.chrome.webview) {
      window.chrome.webview.postMessage(message);
    }
  }
}

// add chrome to the Window context so typescript stops complaining
declare global {
  interface Window {
    chrome: any;
  }
}
