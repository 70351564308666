
import {
  Component, OnInit, ViewChild, Input, Output,
  EventEmitter, ViewEncapsulation, ChangeDetectionStrategy, OnChanges
} from '@angular/core';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { LvAdvancedGridComponent,
         LvAdvancedGridColumn,
         LvAdvancedGridListColumn,
         LvAdvancedGridNumericColumn,
         LvAdvancedGridEnumColumn,
         LvAdvancedGridTextColumn } from '@lv-core-ui/components';
import { LvDataMaster, LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { LvUtil, LvLookupEnum, constants } from '@lv-core-ui/util';
import { YieldCurveTermsStructureSettings } from '@lv-analytics/models/market-data/yield-curve/yield-curve-terms-structure-settings';
import { ITermStructureItem } from '@lv-analytics/models/market-data/yield-curve/yield-curve-term-structure';
import { ITenor } from '@lv-analytics/models/market-data/tenor';
import { MarketDataService } from '@lv-analytics/services/market-data/market-data.service';
import { MarketDataClipboard } from '@lv-analytics/components/market-data/market-data-clipboard';
import { RateType } from '@lv-analytics/models/market-data/yield-curve/yield-curve-enums';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
// tslint:disable-next-line: max-line-length
import { LvCopyAndPasteDialogComponent } from '@lv-analytics/components/market-data/lv-market-data/lv-copy-and-paste-dialog/lv-copy-and-paste-dialog.component';
import { IInterestRatesCopyAndPasteSectionSettings } from '@lv-analytics/models';
import { LvMarketDataError } from '@lv-market-data/models';

/**
 * Yield curve terms structure component.
 */
@Component({
  selector: 'lv-yield-curve-terms-structure',
  templateUrl: './lv-yield-curve-terms-structure.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvYieldCurveTermsStructureComponent implements OnInit, OnChanges {
  @ViewChild(LvAdvancedGridComponent) advancedGrid: LvAdvancedGridComponent;

  @Input() settings: YieldCurveTermsStructureSettings;
  @Input() yieldCurveScheduledItems: ITermStructureItem[];
  @Input() interestRatesCopyAndPasteSettings: IInterestRatesCopyAndPasteSectionSettings;
  @Input() hasOverrideFromExcel: boolean;
  @Input() excelFieldAlias: string;
  @Input() isFieldFromExcelEnabled: boolean;
  @Input() lvId: string;

  @Output() yieldCurveScheduleChange: EventEmitter<void>;
  @Output() isLoadingStateChange: EventEmitter<boolean>;

  tenorFilterDict: {
    [code: string]: boolean
  };

  tenorDict: {
    [code: string]: ITenor
  };

  yieldCurveInstrumentType: string; // todo one column name

  columns: LvAdvancedGridColumn[];
  parseFn: any;
  sortFn: any;

  allDataLoaded: boolean;
  isInitialized: boolean;

  private _subscriptions: Subscription[];

  constructor(
    private _errorService: LvErrorService,
    private _marketDataService: MarketDataService,
    private dialogService: DialogService
  ) {
    this.allDataLoaded = false;
    this.yieldCurveScheduleChange = new EventEmitter<void>();
    this.isLoadingStateChange = new EventEmitter<boolean>();

    this.yieldCurveScheduledItems = [];
    this.columns = [];
    this._subscriptions = [];
    this.isInitialized = false;
    this.parseFn = this.parsePastedData.bind(this);
    this.sortFn = this._marketDataService.convertTenorToNumber;
    this.interestRatesCopyAndPasteSettings = {} as IInterestRatesCopyAndPasteSectionSettings;
    this.hasOverrideFromExcel = false;
    this.excelFieldAlias = 'IR_CURVE_RANGE_INST';
    this.lvId = 'lv-yield-curve-terms-structure';
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Occurs on changes.
   */
  ngOnChanges() {
    if (this.settings) {

      this.initialize();

      if (!this.isInitialized) {
        this.initColumns();
        this.isInitialized = true;
      }

      if (this.settings.tenors.length > 0) {
        if (this.advancedGrid && this._subscriptions.length === 0) {

          this._subscriptions = [
            this.advancedGrid.didDataChange.subscribe((records: ITermStructureItem[]) => this.onDataChange(records)),
            this.advancedGrid.doReload.subscribe(() => this.onReload()),
            this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
          ];
        }
      }

    }
  }

  /**
   * Does initialization.
   */
  async initialize() {
    try {
      this.isLoadingStateChange.next(true);

      this.tenorDict = LvUtil.toDictionary(this.settings.tenors, 'code');
      this.setTenorFilterDict(this.yieldCurveScheduledItems);

      this.yieldCurveInstrumentType = this.settings.instrumentTypeTitle;
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.isLoadingStateChange.next(false);
    }
  }

  /**
   * Occurs on paste.
   * @param event ClipboardEvent object.
   */
  onPaste(event: ClipboardEvent) {
    try {
      MarketDataClipboard.onPaste(event, this.yieldCurveScheduledItems, this.parsePastedData.bind(this));
      this.sortCurve();
      this.yieldCurveScheduleChange.emit();
    }
    catch (error) {
      this._errorService.toastrService.error(error.message);
    }
  }

  /**
   * Gets yield curve.
   * @returns List of ITermsStructureItem objects.
   */
  getYieldCurve() {
    return this.yieldCurveScheduledItems;
  }

  /**
   * Creates form group.
   * @param args CreateFormGroupArgs object.
   * @returns FormGroup object.
   */
  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return new FormGroup({
      'tenor': new FormControl(args.dataItem.tenor, Validators.required),
      'rate': new FormControl(args.dataItem.rate, Validators.required),
      'type': new FormControl(args.dataItem.type, Validators.required)
    });
  }

  /**
   * Occurs on data change.
   * @param records List of ITermStructureItem objects.
   */
  public onDataChange(records: ITermStructureItem[]) {
    this.applyRecords(records);
    this.yieldCurveScheduleChange.next();
  }

  /**
   * Applies advanced grid changes.
   */
  public applyAdvancedGridChanges() {
    this.advancedGrid.applyChanges(records => this.applyRecords(records));
  }

  /**
   * Occurs on reload.
   */
  public onReload() {
    this.yieldCurveScheduledItems = this.yieldCurveScheduledItems.map(x => ({ ...x }));
  }

  /**
   * Handles error.
   * @param error LvError object.
   */
  public onError(error: LvError) {
    this._errorService.handleError(error);
  }

  /**
   * Applies records.
   * @param records List of records.
   */
  private applyRecords(records: any[]) {
    this.yieldCurveScheduledItems.splice(0, this.yieldCurveScheduledItems.length);
    this.yieldCurveScheduledItems.push(...records);
    this.setTenorFilterDict(records);
  }

  /**
   * Sorts curve.
   */
  private sortCurve() {
    this.yieldCurveScheduledItems.sort(LvUtil.sortBy('tenor', false, this._marketDataService.convertTenorToNumber.bind(this)));
  }

  /**
   * Column initialization.
   */
  private initColumns() {
    this.columns = [];

    const tenorColumn = new LvAdvancedGridListColumn();
    tenorColumn.title = 'Tenor';
    tenorColumn.field = 'tenor';
    tenorColumn.displayField = 'name';
    tenorColumn.valueField = 'code';
    tenorColumn.data = this.settings ? this.settings.tenors : [];
    tenorColumn.setFilterFn((b: ITenor) => {
      if (this.tenorFilterDict) {
        return !this.tenorFilterDict[b.code];
      }

      return true;
    });
    tenorColumn.useDefaultItem = true;
    tenorColumn.width = 90;
    tenorColumn.dmKey = this.lvId === 'lv-instrument-yield-curve-term-structure' ? 'DM-643' : 'DM-646';

    const rateColumn = new LvAdvancedGridNumericColumn();
    rateColumn.title = 'Rate (%)';
    rateColumn.field = 'rate';
    rateColumn.decimals = '4';
    rateColumn.format = '#.####';
    rateColumn.outputFormat = constants.numberFormat.upToFourDigits;
    rateColumn.width = 79;
    rateColumn.dmKey = this.lvId === 'lv-instrument-yield-curve-term-structure' ? 'DM-644' : 'DM-647';
    

    const typeColumn = new LvAdvancedGridEnumColumn();
    typeColumn.enumDescription = RateType;
    typeColumn.title = 'Type';
    typeColumn.field = 'type';
    typeColumn.data = new LvLookupEnum(RateType).items;
    typeColumn.width = 76;
    typeColumn.dmKey = this.lvId === 'lv-instrument-yield-curve-term-structure' ? 'DM-645' : 'DM-648';

    const updateTimeColumn = new LvAdvancedGridTextColumn();
    updateTimeColumn.title = 'Update';
    updateTimeColumn.field = 'updatedRateDate';
    updateTimeColumn.width = 69;
    updateTimeColumn.editable = false;
    updateTimeColumn.isOutputColumn = true;
    updateTimeColumn.alignment = 'right';

    this.columns.push(tenorColumn);
    this.columns.push(rateColumn);
    this.columns.push(typeColumn);
    this.columns.push(updateTimeColumn);

    this.allDataLoaded = true;
  }

  /**
   * Sets tenor filter dict.
   * @param records List of ITermStructureItem objects.
   */
  private setTenorFilterDict(records: ITermStructureItem[]) {
    this.tenorFilterDict = {};
    records.forEach(a => this.tenorFilterDict[a.tenor] = true);
  }

  /**
   * Based on saved copy-paste settings this method can show dialog with copied elements
   * from outside of app and select which element to paste to app or directly paste data to grid
   * @param pastedDataRecords records copied outside of application
   */
  private parsePastedData(pastedDataRecords: string[]): ITermStructureItem[] {
    if (this.interestRatesCopyAndPasteSettings.displayValuesBeforePaste) {

      try {

        let termStructureItems: ITermStructureItem[] = [];

        if (pastedDataRecords.length <= 1 && pastedDataRecords[0].split('\t').length <= 1) {
          throw new LvError('');
        }

        const dialogRef: DialogRef = this.dialogService.open({
          title: 'Interest Rates Copy & Paste',
          content: LvCopyAndPasteDialogComponent,
          width: 584,
          height: 657
        });

        const dialog = (dialogRef.content.instance as LvCopyAndPasteDialogComponent);
        dialog.data = pastedDataRecords;
        dialog.componentName = 'interestRates';
        dialog.selectionStart = this.interestRatesCopyAndPasteSettings.startFromRow;
        dialog.selectionEnd = pastedDataRecords.length;
        dialog.firstColumnSelectedIndex = this.interestRatesCopyAndPasteSettings.forTenorValueUseColumn;
        dialog.secondColumnSelectedIndex = this.interestRatesCopyAndPasteSettings.forRateValueUseColumn;

        this._subscriptions.push(dialog.didPaste.subscribe(res => {
          termStructureItems = [];

          try {
            this.parseData(res, termStructureItems);

            this.advancedGrid.onParseDataDialog(termStructureItems);
            dialogRef.close();
          }
          catch (error) {
            this._errorService.handleError(new LvMarketDataError(error.message));
          }
        }));

        return this.advancedGrid.records;
      }
      catch (error) {
        this._errorService.handleError(new LvMarketDataError(LvDataMaster.getError('dM-3393')));
      }
    }
    else {
      if (pastedDataRecords.length <= 1 && pastedDataRecords[0].split('\t').length <= 1) {
        throw new LvMarketDataError(LvDataMaster.getError('dM-3393'));
      }

      const termStructureItems: ITermStructureItem[] = [];

      const startFromRow = this.interestRatesCopyAndPasteSettings.startFromRow;
      const selectionEnd = pastedDataRecords.length;
      const firstColumnSelectedIndex = this.interestRatesCopyAndPasteSettings.forTenorValueUseColumn - 1;
      const secondColumnSelectedIndex = this.interestRatesCopyAndPasteSettings.forRateValueUseColumn - 1;

      const fields = [];

      const columnLength =
        MarketDataClipboard.getNumberOfColumnsAndSetFieldsForCopyAndPasteScheduledData(pastedDataRecords, fields);
      const formattedPastedDataRecords =
        MarketDataClipboard.preparePastedDataToParse(pastedDataRecords, fields, columnLength);

      const selectedRowsData = formattedPastedDataRecords.filter((item, index) => {
        return index >= startFromRow - 1 && index <= selectionEnd;
      });

      const result = selectedRowsData.map(item => {
        return `${item['field' + firstColumnSelectedIndex]}\t${item['field' + secondColumnSelectedIndex]}`;
      });

      this.parseData(result, termStructureItems);

      return termStructureItems;
    }
  }

  /**
   * Parse formated data into interest rates table
   * @param formatedData Formated pasted data
   * @param scheduledDividends Scheduled dividends tabel data
   */
  private parseData(formatedData: string[], termStructureItems: ITermStructureItem[]) {
    formatedData.forEach(r => {
      const items = r.split('\t');
      let tenorCode: string;
      let type: RateType;

      const tenorValue = items[0];
      const rateValue = items[1];

      const rate = MarketDataClipboard.tryParseNumberValue(rateValue, 'Rate');
      if (rate) {
        tenorCode = this.parseTenor(tenorValue);

        type = this.parseRateTypeDialog(tenorCode);

        this.validateUniqueTenor(termStructureItems, tenorCode);

        termStructureItems.push({
          tenor: tenorCode,
          rate: rate,
          type: type,
          updatedRateDate: new Date(Date.now()).toLocaleDateString()
        });
      }
    });
  }

  /**
   * Parses tenor.
   * @param tenorValue Tenor value.
   * @returns Tenor code.
   */
  private parseTenor(tenorValue: string): string {
    MarketDataClipboard.validateNotEmpty(tenorValue, 'Tenor');

    const simplifiedTenorValue = this.simplifyTenor(tenorValue);

    // allowed tenor values in format: 2M, 2 M, 2m, 2 m, 2 Month, 2Month, 2 Months, 2Months, 2 month, 2month, 2 months, 2months
    const tenorByValue = Object.values(this.tenorDict).find(a => this.simplifyTenor(a.name).includes(simplifiedTenorValue));
    const tenorByCode = Object.values(this.tenorDict).find(a => this.simplifyTenor(a.code) === simplifiedTenorValue);
    if (tenorByValue !== undefined) {
      return tenorByValue.code;
    } else if (tenorByCode !== undefined) {
      return tenorByCode.code;
    } else {
      throw MarketDataClipboard.throwPasteError(LvDataMaster.getError('dM-3394', {'tenorValue': tenorValue}));
    }
  }

  /**
   * Simplifies tenor.
   * @param tenor Tenor.
   * @returns Simplified tenor.
   */
  private simplifyTenor(tenor: string) {
    return tenor.toLowerCase().replace(' ', '');
  }

  /**
   * Parses rate type dialog.s
   * @param tenor Tenor.
   * @returns Rate type.
   */
  private parseRateTypeDialog(tenor: string): RateType {
    const oneYearTenor = this._marketDataService.convertTenorToNumber('1Y');
    const currentTenor = this._marketDataService.convertTenorToNumber(tenor);

    return currentTenor <= oneYearTenor && tenor !== '1Y' ? RateType.Deposit : RateType.Swap;
  }

  /**
   * Validates unique tenor.
   * @param termStructureItems List of ITermStructureItem objects.
   * @param tenorCode Tenor code.
   */
  private validateUniqueTenor(termStructureItems: ITermStructureItem[], tenorCode: string) {
    const duplicatedTenorDetected = termStructureItems.find(a => a.tenor === tenorCode) !== undefined;
    if (duplicatedTenorDetected) {
      throw MarketDataClipboard.throwPasteError(LvDataMaster.getError('dM-3327', {'duplicateTenors': this.tenorDict[tenorCode].name}));
    }
  }
}
