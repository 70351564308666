import { Component, OnInit, ViewChild, ChangeDetectorRef, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { AnotherInstanceModalComponent } from '..';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'another-instance',
  templateUrl: './another-instance.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnotherInstanceComponent implements OnInit {

  @ViewChild(AnotherInstanceModalComponent, { static: true }) modal: AnotherInstanceModalComponent;
  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.modal.open();

    this._changeDetectorRef.markForCheck();

  }

}
