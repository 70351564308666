import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IPricingEnvironmentSettings } from '@lv-analytics/models/company-and-user-settings/environments-settings';
import { PricingEnvironmentScopeType } from '@lv-analytics/models/enum/pricing-environments-scope-type';
import { DialogRef } from '@progress/kendo-angular-dialog';

/**
 * Edit environment settings dialog component.
 */
@Component({
  selector: 'lv-edit-environment-settings-dialog',
  templateUrl: './lv-edit-environment-settings-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEditEnvironmentSettingsDialogComponent implements OnInit {

  @Input() pricingEnvironmentSettings: IPricingEnvironmentSettings;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onSavePricingEnvironmentSettings: EventEmitter<IPricingEnvironmentSettings>;

  get isSystem(): boolean {
    if (!this.pricingEnvironmentSettings) {
      return true;
    }

    return this.pricingEnvironmentSettings.scopeType === PricingEnvironmentScopeType.System;
  }

  isEnvironmentEnabled: boolean;

  constructor(
    private _dialogRef: DialogRef,
  ) {
    this.pricingEnvironmentSettings = {} as IPricingEnvironmentSettings;
    this.isEnvironmentEnabled = false;
    this.onSavePricingEnvironmentSettings = new EventEmitter<IPricingEnvironmentSettings>();
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-edit-environment-settings-dialog')
  get isLvEditEnvironmentSettingsDialogComponent() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit(): void {
    this.isEnvironmentEnabled = !this.pricingEnvironmentSettings.isDisabled;
  }

  /**
   * Update pricing environment accesability
   */
  changePricingEnvironmentAccessibility() {
    this.pricingEnvironmentSettings.isDisabled = this.isEnvironmentEnabled;
  }

  /**
   * Emit on save event with pricing environment data
   */
  onSave() {
    this.onSavePricingEnvironmentSettings.next(this.pricingEnvironmentSettings);
    this._dialogRef.close();
  }

  /**
   * Emit on cancel event
   */
  onCancel() {
    this._dialogRef.close();
  }
}
