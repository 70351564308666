import { Component, ViewEncapsulation, ChangeDetectionStrategy, ViewChild,
  ChangeDetectorRef, Output, EventEmitter, Input, ElementRef, Optional } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { CurrencyTypeEnum, DividendFrequencyEnum, DividendTypeEnum, DividendTypeEnumDescription,
         IDividends } from '@lv-analytics/models/market-data/dividends';
import { LvMinDateDirective } from '@lv-core-ui/directives/date-formatting/lv-date-formatting.directive';
import { LvLookupEnum, LvFormUtil } from '@lv-core-ui/util';
import { LvExcelService } from '@lv-excel/services';

/**
 * Parameters dividends component.
 */
@Component({
  selector: 'lv-parameters-dividends',
  templateUrl: './lv-parameters-dividends.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LvParametersDividendsComponent {
  @ViewChild(NgForm, { static: true }) form: NgForm;
  @ViewChild(LvMinDateDirective) dateDirective: LvMinDateDirective;

  @Input() dividendsSettings: IDividends;
  @Input() dividendCCY: any;

  @Output() didGenerateSchedule: EventEmitter<void>;
  @Output() didDividendParametersChange: EventEmitter<void>;

  get isContinousYieldDividendType() {
    return this.dividendsSettings.dividendParameters.dividendType === DividendTypeEnum.ContinuousYield;
  }

  get dividendValueSuffix() {
    if (!this._analyticsPresenter.asHelper.instrumentLoaded) {
      return null;
    }

    if (this.dividendsSettings.dividendParameters.dividendType === DividendTypeEnum.DiscreteYield) {
      return '%';
    }

    if (this.dividendCCY === CurrencyTypeEnum.Convertible) {
      return this._analyticsPresenter.cHelper.currencyCode;
    }
    else if (this.dividendCCY === CurrencyTypeEnum.Underlying) {
      return this._analyticsPresenter.cHelper.underlyingCurrencyCode;
    }
    else {
      return this.dividendCCY;
    }
  }

  get dividendValueLabel() {
    if (this.dividendsSettings.dividendParameters &&
      this.dividendsSettings.dividendParameters.dividendType === DividendTypeEnum.DiscreteYield) {
        return this.isOpenedFromExcel ? 'Dvd Value (annualised)' : 'Dividend Value (annualised)';
      }

    return this.isOpenedFromExcel ? 'Dvd Value (per period)' : 'Dividend Value (per period)';
  }

  get dateFormated(): string {
    if (!(this.dividendsSettings && this.dividendsSettings.dividendParameters &&
          this.dividendsSettings.dividendParameters.firstDividendDate) || (this.dateDirective && !this.dateDirective.onValueChanged())) {
      return '';
    }
   else {
      return new Date(this.dividendsSettings.dividendParameters.firstDividendDate).toLocaleDateString('en-GB');
    }
  }

  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  get hasDividendsParamRangeOverridedFromExcel(): boolean {
    return !!this._excelSvc?.hasDividendsParamRangeOverridedFromExcel();
  }

  dividendTypeSource: LvLookupEnum;
  dividendFrequencySource: LvLookupEnum;
  dividendCurrencyAdditionSource: LvLookupEnum;

  numberOfDecimals = '4';
  numberFormat = '#.####';
  minDatePickerDate: Date;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private _analyticsPresenter: LvAnalyticsPresenter,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.dividendFrequencySource = new LvLookupEnum(DividendFrequencyEnum);
    this.dividendTypeSource = new LvLookupEnum(DividendTypeEnumDescription);

    this.dividendsSettings = {} as IDividends;
    this.didGenerateSchedule = new EventEmitter<void>();
    this.didDividendParametersChange = new EventEmitter<void>();
  }

  /**
   * Occurs on generate schedule.
   */
  onGenerateSchedule() {
    if (this.form.valid) {
      this.didGenerateSchedule.emit();
    }
    else {
      LvFormUtil.markAllControlsAsTouched(this.form);
    }

    this.changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on dividend parameters change.
   */
  onDividendParametersChange() {
    this.isFirstDividendDateValid();
    this.setDividendScheduleCcy(this.dividendsSettings.dividendCurrencySelected);
    this.didDividendParametersChange.emit();

    this.changeDetectorRef.detectChanges();
  }

  /**
   * Gets dividend tooltip ID.
   * @param element HTML element.
   * @param sectionId Section ID.
   * @returns Dividend tooltip ID.
   */
  getDividendTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('dividend-tooltip-id') === sectionId;
  }

  /**
   * Sets dividend schedule CCY.
   * @param ccyId CCY Id.
   */
  private setDividendScheduleCcy(ccyId: any) {
    this.dividendsSettings.dividendsCcy = ccyId;

    if (ccyId !== CurrencyTypeEnum.Convertible || ccyId !== CurrencyTypeEnum.Underlying) {
      this.dividendsSettings.dividendsCcy = CurrencyTypeEnum.Convertible;
    }
  }

  /**
   * method checks if entered date is valid
   * if date is incomplete, in order to reset its value, it is necessary to recreate date object to set its value  back to null
   * if date is less than min date, firstDividendDate is set to null
   */
  private isFirstDividendDateValid(): void {
    if (this.dividendsSettings.dividendParameters.firstDividendDate === null) {
      this.dividendsSettings.dividendParameters.firstDividendDate = new Date();
      this.changeDetectorRef.detectChanges();
      this.dividendsSettings.dividendParameters.firstDividendDate = null;
    }
    else if (!this.dateFormated) {
      this.dividendsSettings.dividendParameters.firstDividendDate = null;
    }
  }
}
