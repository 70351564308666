import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, Optional, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { LvGlOnDrop, ILvGoldenLayoutComponent } from '@lv-golden-layout/models';
import { LvGoldenLayoutService } from '@lv-golden-layout/services';
import { LvPublisherPanelComponent } from '@lv-core-ui/components';
import { IInstrumentSelectedEvent, ILvError, LvDataMaster, LvErrorType } from '@lv-core-ui/models';
import { LvErrorService, ILvSubscriberState } from '@lv-core-ui/services';
import { ApplicationSettingsService } from '@lv-application-settings/application-settings.service';
import { RouteCacheService } from '@lv-cache/route-cache';
import { NotificationPanelComponent } from '@lv-common/views/notification-panel.component';
import { CustomInstrumentsService } from '@lv-custom-instruments/services/custom-instruments.service';
import { IDocumentsComponentState, IWorkspaceEvent, WorkspacePanelDialogComponent } from '..';
import { DefaultComponentStateService } from '../instrument/instrument.state';
// tslint:disable-next-line: max-line-length
import { ILvInstrumentMonitorPanelState, LvInstrumentMonitorPanelComponent } from '@lv-instrument-monitor/components/lv-instrument-monitor-panel/lv-instrument-monitor-panel.component';
import { DialogService } from '@progress/kendo-angular-dialog';
import { LvAccessRightsModalComponent } from '@lv-custom-instruments/components/lv-custom-instrument/lv-access-rights-modal/lv-access-rights-modal.component';
import { AccessScopeType } from '@lv-core-ui/models/enum/access-scope-type';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'instrument-monitor',
  templateUrl: './instrument-monitor.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
// tslint:disable-next-line:max-line-length
export class InstrumentMonitorComponent extends NotificationPanelComponent<ILvInstrumentMonitorPanelState> implements OnDestroy, LvGlOnDrop {

  @ViewChild(LvPublisherPanelComponent, { static: true }) notificationPanel: LvPublisherPanelComponent;
  @ViewChild(LvInstrumentMonitorPanelComponent, { static: true }) instrumentMonitorPanel: LvInstrumentMonitorPanelComponent;

  private _workspaceReatachedSubscription: Subscription;
  private _subscriptions: Subscription[];

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    applicationSettingsService: ApplicationSettingsService,
    private _router: Router,
    private lvGoldenLayoutService: LvGoldenLayoutService,
    private _routeCacheService: RouteCacheService,
    private _privateInstrumentsService: CustomInstrumentsService,
    private _errorService: LvErrorService,
    private _instrumentStateService: DefaultComponentStateService,
    private _dialogService: DialogService
  ) {
    super(changeDetectorRef, applicationSettingsService);

    this._workspaceReatachedSubscription = this._routeCacheService.workspaceReatached
      .subscribe(wsId => {
        const regex = new RegExp(`\\b${wsId}\\b`, 'ig');
        if (this._router.url.match(regex) && this.applicationSettingsService.isWidgetInWorkspace(wsId, this.stateId)) {
          this.lvGlOnDrop(null);
        }
      });
  }

  doOnInit() {
    this._subscriptions = [];
  }

  onSelectInstrument(event: IInstrumentSelectedEvent) {
    this.notificationPanel.sendMessage(event);
  }

  onOpenInstrument(event: IInstrumentSelectedEvent) {
    this.doOpenComponent(event, 'analytics', this._instrumentStateService.getDefaultInstrumentComponentState(s => {
      s.instrumentIsin = event.isin;
      s.instrumentLwsIdentifier = event.lwsIdentifier;
      s.instrumentName = event.name;
      s.isPrivateInstrument = event.isPrivateInstrument;
      s.instrumentId = event.id;
      s.region = event.region;
    }));
  }

  onOpenDocuments(event: IInstrumentSelectedEvent) {
    this.doOpenComponent(event, 'documents', {
      instrumentId: event.id,
      instrumentIsin: event.isin,
      instrumentLwsIdentifier: event.lwsIdentifier,
      instrumentName: event.name
    } as IDocumentsComponentState);
  }

  onOpenBasicTerms(event: IInstrumentSelectedEvent) {
    this.doOpenComponent(event, 'terms-summary', this._instrumentStateService.getDefaultBasicTermsComponentState(s => {
      s.instrumentId = event.id;
      s.instrumentName = event.name;
      s.lwsIdenitifier = event.lwsIdentifier;
      s.isPrivateInstrument = event.isPrivateInstrument;
    }));
  }

  async onCreateNewIssueFromExistingInstrument(event: IInstrumentSelectedEvent, accessScope: AccessScopeType) {
    this.doOpenComponent(event, 'analytics', this._instrumentStateService.getDefaultInstrumentComponentState(s => {
      s.instrumentLwsIdentifier = event.lwsIdentifier;
      s.createPrivateInstrument = {
        isPrivateIntrument: event.isPrivateInstrument,
        isExactCopy: false,
        isUserAccessScope: accessScope === AccessScopeType.User
      };
      s.isPrivateInstrument = true;
    }));
  }

  onDeleteInstrument(event: IInstrumentSelectedEvent) {
    const dialogRef = this._dialogService.open({
      title: 'Delete Custom Instrument',
      content: WorkspacePanelDialogComponent  ,
      actions: [{
        text: 'No',
        primary: true
      }, {
        text: 'Yes',
        doClose: true,
      }]
    });

    dialogRef.dialog.location.nativeElement.classList.add('workspace-panel-dialog');
    (dialogRef.content.instance as WorkspacePanelDialogComponent).event = event;

    dialogRef.result.subscribe(result => {
      if ((result as any).doClose) {
       this.deleteInstrument(event);
      }
    });
   }

  async deleteInstrument(event: IInstrumentSelectedEvent) {
    try {
      await this._privateInstrumentsService.deletePrivateInstrument(event.lwsIdentifier);

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1832'));
    }
    catch (error) {
      const e: ILvError = error;
      if (e.type === LvErrorType.USER_FRIENDLY) {
        e.message = LvDataMaster.getInfo('dM-1833');
      }

      this._errorService.handleError(error);
    }

  }

  async accessScopeCopyInstrument(event: IInstrumentSelectedEvent, isNewIssue: boolean) {
    const dialogRef = this._dialogService.open({
      title: 'Save Instrument As',
      content: LvAccessRightsModalComponent,
      width: 400,
      height: 164
    });

    dialogRef.dialog.location.nativeElement.classList.add('lv-access-rights-modal');
    const instance = dialogRef.content.instance as LvAccessRightsModalComponent;
    instance.accessScope = AccessScopeType.User;

    this._subscriptions.push(instance.didSave.subscribe(async (accessScope) => {
      // tslint:disable-next-line: max-line-length
      isNewIssue ? await this.onCreateNewIssueFromExistingInstrument(event, accessScope as AccessScopeType) : await this.onCopyInstrument(event, accessScope as AccessScopeType);
      dialogRef.close();
    }));

    this._subscriptions.push(instance.didCancel.subscribe(() => {
      dialogRef.close();
    }));
  }

  async onCopyInstrument(event: IInstrumentSelectedEvent, accessScope: AccessScopeType) {
    this.doOpenComponent(event, 'analytics', this._instrumentStateService.getDefaultInstrumentComponentState(s => {
      s.instrumentLwsIdentifier = event.lwsIdentifier;
      s.createPrivateInstrument = {
        isPrivateIntrument: event.isPrivateInstrument,
        isExactCopy: true,
        isUserAccessScope: accessScope === AccessScopeType.User
      };
      s.isPrivateInstrument = true;
    }));
  }

  // Implementations from parent class
  didReceiveMessage(): void {} // noop

  didlvGlOnDestroy() {
    this.notificationPanel.unregister();
    this.instrumentMonitorPanel.deleteWidget();
  }


  // Golden Layout Component Events
  lvGlOnDrop(component: ILvGoldenLayoutComponent) {
    this.instrumentMonitorPanel.instrumentMonitor.refresh(true);
  }

  ngOnDestroy() {
    this.unsubscribeFromWorkspaceRemoved();
    this._workspaceReatachedSubscription.unsubscribe();
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * opens new golden layout component dinamically.
   * It had also linked new component to IM as subsicrberto instrument select events but that was changed * in JIRA SYSTEM-2079
   * @param instrumentSelectEvent data about selected instrument in IM
   * @param componentName name of the component that will be opened (created)
   * @param componentState inital state of the newely opened component
   */
  private doOpenComponent(instrumentSelectEvent: IInstrumentSelectedEvent, componentName: string, componentState: any = {}) {
    try {
      const cConfig = this.lvGoldenLayoutService.addComponent(instrumentSelectEvent.name, componentName, {
        ...componentState,
        type: 'subscriber',
        color: null, // this.notificationPanel.color,
        linkedPublisher: null, // this.notificationPanel.id
      } as ILvSubscriberState);
    }
    catch (error) {
      this._errorService.handleError(error);
    }

    // SYSTEM-2078
    // this.notificationPanel.addSubscriber(cConfig.id);
  }
}
