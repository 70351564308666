import { Directive } from '@angular/core';

@Directive({
  selector: '[lvGridLayoutCell]'
})
export class LvGridLayoutCellDirective {

  constructor() { }

}
