import * as _ from 'lodash';

import { Injectable, OnDestroy, Optional } from '@angular/core';

import { BehaviorSubject, Subject, Subscription, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IAnalyticsSettings } from './models/valuation-session/analytics-settings';
import { ValuationSessionService } from './services/valuation-session/valuation-session.service';
import { ConvertiblesService } from './services/convertibles/convertibles.service';
import { CompanyAndUserSettingsService } from './services/company-settings/company-settings.service';
import { AnalyticsSettingsEvents, AnalyticsEvents, AnalyticsCommands } from './models/enum/analytics-settings-events';
import { PricingSectionEventSource } from './components/lv-pricing/lv-pricing.component';
import { IValuationInputs } from './models/pricing/valuation-inputs';
import { IEnvironmentSettingsItem } from './components/lv-environment-settings/lv-environment-settings.component';
import { AnalyticsSettingsHelper } from './helpers/analytics-settings';
import { ConvertibleHelper } from './helpers/convertible';
import { IPricing } from './models/valuation-session/pricing';
import { IValuationSession } from './models/valuation-session/valuation-session';
import { StockReferenceType } from './models/enum/stock-reference-type';
import { IOverrideNewIssueAssumptionsRequest } from './models/new-issue-assumption/new-issue-assumption-request';
import { ISaveMarketDataResponse } from './models/response/save-market-data-response';
import { ILoadSessionResult } from './models/load-session-result';
import { LvDataMaster, LvError, LvErrorType } from '@lv-core-ui/models';
import { LvErrorService, LvLoggerService } from '@lv-core-ui/services';
import { LvSharedMarketDataService } from '@lv-shared/services/shared-market-data.service';
import { LvExcelService } from 'src/app/modules/excel/services/excel-service';
import { IValuationSettings } from './models/valuation-session/valuation-settings';
import { PricingExcelMapper } from './helpers/pricing-mapper';
import { FlatMarketDataExcelMapper } from './helpers/flat-market-data-mapper';
import { MarketDataExcelMapper } from './helpers/market-data-mapper';
import { ModelCustomizationExcelMapper } from './helpers/model-customization-excel-mapper';
import { MarketDataService, ModelCustomizationService } from './services';
import { IMarketData, IReloadMarketDataRequest } from './models/market-data';
import { IOverrideModelCustomizationRequest } from './models/model-customization/model-customization-request';
import { IMarketDataBase } from './models/valuation-session';
import { ConvertibleSetupStatus, IModelCustomization } from './models';
import { ConvertibleSubType } from './../analytics/models/convertible/enum/convertible-sub-type';
import { ISaveInterestRatesRequest } from './models/market-data/interest-rates/interest-rates-request';
import { ITermStructureItem } from './models/market-data/yield-curve/yield-curve-term-structure';
import { LvMath } from '@lv-core-ui/util';
import { IPricingEnvironmentOverrides } from './models/company-and-user-settings/pricing-environment-overrides';

export interface IAnalyticsPresenterEvent<T> {
  eventId: AnalyticsSettingsEvents | AnalyticsEvents;
  data: T;
}

export interface IInstrumentConfig {
  identifier: string;
  isPrivate: boolean;
  sessionId: string;
  draftId: string;
}

/**
 * Analytics presenter event.
 */
export class AnalyticsPresenterEvent<T> implements IAnalyticsPresenterEvent<T> {
  public eventId: AnalyticsSettingsEvents | AnalyticsEvents;
  public data: T;

  public set instrumentLoaded(value: boolean) {
    this._instrumentLoaded = value;
  }

  private _instrumentLoaded: boolean;

  constructor() {
    this.eventId = null;
    this.data = null;
    this._instrumentLoaded = false;
  }

  /**
   * Checks if event is safe.
   * @param eventId AnalyticsSettingsEvents or AnalyticsEvents
   * @returns A flag indicating if event is safe.
   */
  isSafeEvent(eventId: AnalyticsSettingsEvents | AnalyticsEvents): boolean {
    return this.eventId === eventId && this._instrumentLoaded;
  }

  /**
   * Checks if event is model customization event.
   * @returns A flag indicating if event is model customization event.
   */
  isModelCustomizationEvent(): boolean {
    return this.eventId === AnalyticsSettingsEvents.ModelCustomizationUpdated;
  }

  /**
   * Checks if event is market data event.
   * @returns A flag indicating if event is market data event.
   */
  isMarketDataEvent(): boolean {
    switch (this.eventId) {
      case AnalyticsSettingsEvents.MarketDataUpdated:
      case AnalyticsSettingsEvents.BorrowUpdated:
      case AnalyticsSettingsEvents.CreditUpdated:
      case AnalyticsSettingsEvents.DividendsUpdated:
      case AnalyticsSettingsEvents.InterestRatesUpdated:
      case AnalyticsSettingsEvents.VolatilityUpdated:
      case AnalyticsSettingsEvents.FundingUpdated:
      case AnalyticsSettingsEvents.WithholdingTaxUpdated:
      case AnalyticsSettingsEvents.OtherMarketDataUpdated:
      {
        return true;
      }
      default: {
        return false;
      }
    }
  }
}

export interface IAnalyticsPresenterPricingEvent {
  source: PricingSectionEventSource;
  data: {
    viInputs: IValuationInputs;
    pricing: IPricing,
    environments: IEnvironmentSettingsItem[];
  };
}

/**
 * Object that is used to connetc all analytics components together and to maintain shared state.
 *
 * Contains various events that components can subscribe to. It also contains some helpers
 * and properties allowing components to access data from different components on the same
 * level.
 */
@Injectable()
export class LvAnalyticsPresenter implements OnDestroy {

  get onModelLoading(): Observable<boolean> {
    return this._modelLoading;
  }

  get onAnalyticsSettingsUpdated(): Observable<boolean> {
    return this._analyticsSettingsUpdated;
  }

  get onModelUpdated(): Observable<AnalyticsPresenterEvent<IAnalyticsSettings>> {
    return this._modelUpdated.asObservable();
  }

  get onPricingModelUpdated(): Observable<IAnalyticsPresenterPricingEvent> {
    return this._pricingModelUpdated.asObservable();
  }

  get onEventPublished(): Observable<IAnalyticsPresenterEvent<any>> {
    return this._eventPublihed.asObservable();
  }

  get onCommandExecuted(): Observable<AnalyticsCommands> {
    return this._commandExecuted.asObservable();
  }

  get onPricingEnvironmentsChanged(): Observable<boolean> {
    return this._pricingEnvironmentsChanged.asObservable();
  }

  get onOtherSettingsChanged(): Observable<void> {
    return this._otherSettingsChanged.asObservable();
  }

  get isExcelDataLoaded(): boolean {
    return this._isExcelDataLoaded;
  }

  get asHelper(): AnalyticsSettingsHelper {
    return this._asHelper;
  }

  get cHelper(): ConvertibleHelper {
    return this._cHelper;
  }

  get isPrivateInstrument(): boolean {
    return this._isPrivateInstrument;
  }

  get sessionId(): string {
    return this._sessionId;
  }

  get draftId(): string {
    return this._draftId;
  }

  get isOpenedFromExcel(): boolean {
    return this._excelSvc && this._excelSvc.isInitialized();
  }

  get isInstrumentDraft(): boolean {
    if (!(this.cHelper && this.cHelper.convertible)) {
      return false;
    }

    return this.cHelper.convertible.isDraft;
  }

  /**
   * Returns true if all required pricing fields for calculation are filled, false otherwise.
   * Currently, required fiels are price, stock price (CB CCY), stock price (und CCY) and valuation date.
   */
  get isPricingValid(): boolean {
    if (!(this._analyticsSettingsData && this._analyticsSettingsData.valuationSession &&
      this._analyticsSettingsData.valuationSession.pricing)) {
        return false;
      }

    const pricing = this._analyticsSettingsData.valuationSession.pricing;

    if (!(pricing.stockPriceCbCcy && pricing.stockPriceUndCcy &&
      pricing.valuationDate >= this._cHelper.firstSettlementDate)) {
      return false;
    }

    return true;
  }

  get marketData(): IMarketData {
    return this._analyticsSettingsData.marketData;
  }

  get modelCustomization(): IModelCustomization {
    return this._analyticsSettingsData.valuationSession.modelCustomization;
  }

  get areTenorTabularOutputsVisible(): boolean {
    return this._analyticsSettingsData?.valuationSettings?.bucketingSettings?.bucketList.any();
  }

  pricingMapper: PricingExcelMapper;
  flatMarketDataMapper: FlatMarketDataExcelMapper;
  marketDataMapper: MarketDataExcelMapper;
  modelCustomizationMapper: ModelCustomizationExcelMapper;

  private _analyticsSettingsData: IAnalyticsSettings;
  private _analyticsSettingsUpdated: Subject<boolean>;
  private _pricingEnvironmentsChanged: Subject<boolean>;
  private _otherSettingsChanged: Subject<void>;

  private _modelLoading: BehaviorSubject<boolean>;
  private _modelUpdated: BehaviorSubject<AnalyticsPresenterEvent<IAnalyticsSettings>>;
  private _pricingModelUpdated: Subject<IAnalyticsPresenterPricingEvent>;
  private _eventPublihed: Subject<IAnalyticsPresenterEvent<any>>;
  private _commandExecuted: Subject<AnalyticsCommands>;

  private _updateModel: Subject<IAnalyticsPresenterEvent<any>>;

  private _subscriptions: Subscription[];

  private _asHelper: AnalyticsSettingsHelper;
  private _cHelper: ConvertibleHelper;

  private _isPrivateInstrument: boolean;
  private _sessionId: string;
  private _instrumentIdentifier: string;
  private _draftId: string;
  private _originalMarketDataForExcelMapp: IMarketData;
  private _originalModelCustomizationForExcelMapp: IModelCustomization;
  private _isExcelDataLoaded: boolean;

  constructor(
    private _errorService: LvErrorService,
    private _vsService: ValuationSessionService,
    private _cService: ConvertiblesService,
    private _companyAndUserSettingsService: CompanyAndUserSettingsService,
    private _sharedMarketDataService: LvSharedMarketDataService,
    private _logger: LvLoggerService,
    private _marketDataService: MarketDataService,
    private _modelCustomizationSettingsService: ModelCustomizationService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this._analyticsSettingsUpdated = new Subject<boolean>();
    this._modelLoading = new BehaviorSubject<boolean>(false);
    this._modelUpdated = new BehaviorSubject<AnalyticsPresenterEvent<IAnalyticsSettings>>(null);
    this._otherSettingsChanged = new Subject<void>();
    this._pricingModelUpdated = new Subject<IAnalyticsPresenterPricingEvent>();
    this._eventPublihed = new Subject<AnalyticsPresenterEvent<any>>();
    this._commandExecuted = new Subject<AnalyticsCommands>();
    this._pricingEnvironmentsChanged = new Subject<boolean>();

    this._updateModel = new Subject<IAnalyticsPresenterEvent<IAnalyticsSettings>>();

    this._asHelper = new AnalyticsSettingsHelper();
    this._cHelper = new ConvertibleHelper();

    this._isPrivateInstrument = false;
    this.pricingMapper = new PricingExcelMapper();
    this.flatMarketDataMapper = new FlatMarketDataExcelMapper();
    this.marketDataMapper = new MarketDataExcelMapper();
    this.modelCustomizationMapper = new ModelCustomizationExcelMapper();

    this._isExcelDataLoaded = false;

    this._subscriptions = [
      this._updateModel.pipe(
        map(evt => {
          const clonedData = evt.data;
          const clonedModel = this._analyticsSettingsData;

          this._asHelper.init(clonedModel);
          this._cHelper.init(clonedModel.convertible);

          switch (evt.eventId) {
            case AnalyticsSettingsEvents.PricingEnvironmentUpdated: {
              if (this._asHelper.valuationSession) {
                clonedModel.valuationSession.selectedEnvironments[clonedData.section] = clonedData.environmentId;
              }

              break;
            }
            // Market Data
            case AnalyticsSettingsEvents.MarketDataUpdated: {
              if (this._asHelper.settings) {
                clonedModel.marketData = clonedData;
              }

              break;
            }
            case AnalyticsSettingsEvents.BorrowUpdated: {
              if (this._asHelper.marketData) {
                clonedModel.marketData.borrow = clonedData;
              }

              break;
            }
            case AnalyticsSettingsEvents.CreditUpdated: {
              if (this._asHelper.marketData) {
                clonedModel.marketData.credit = clonedData;
              }

              break;
            }
            case AnalyticsSettingsEvents.VolatilityUpdated: {
              if (this._asHelper.marketData) {
                clonedModel.marketData.volatility = clonedData;
              }

              break;
            }
            case AnalyticsSettingsEvents.DividendsUpdated: {
              if (this._asHelper.marketData) {
                clonedModel.marketData.dividends = clonedData;
              }

              break;
            }
            case AnalyticsSettingsEvents.FundingUpdated: {
              if (this._asHelper.marketData) {
                clonedModel.marketData.funding = clonedData;
              }

              break;
            }
            case AnalyticsSettingsEvents.InterestRatesUpdated: {
              if (this._asHelper.marketData) {
                clonedModel.marketData.interestRates = clonedData;
              }

              break;
            }
            case AnalyticsSettingsEvents.VolatilityUpdated: {
              if (this._asHelper.marketData) {
                clonedModel.marketData.volatility = clonedData;
              }

              break;
            }
            case AnalyticsSettingsEvents.WithholdingTaxUpdated: {
              if (this._asHelper.marketData) {
                clonedModel.marketData.witholdingTax = clonedData;
              }

              break;
            }
            case AnalyticsSettingsEvents.OtherMarketDataUpdated: {
              if (this._asHelper.marketData) {
                clonedModel.marketData.other = clonedData;
              }

              break;
            }
            // End Market Data
            case AnalyticsSettingsEvents.ModelCustomizationUpdated: {
              if (this._asHelper.valuationSettings) {
                clonedModel.valuationSession.modelCustomization = clonedData;
              }

              break;
            }
          }

          const event = new AnalyticsPresenterEvent<IAnalyticsSettings>();
          event.eventId = evt.eventId;
          event.data = clonedModel;
          event.instrumentLoaded = this._asHelper.instrumentLoaded;

          this._analyticsSettingsData = event.data;

          return event;
        })
      )
      .subscribe(evt => {
        this._modelUpdated.next(evt);
      })
    ];
  }

  /**
   * Sets draft ID.
   * @param draftId Draft ID.
   */
  public setDraftId(draftId: string) {
    this._draftId = draftId;
  }

  /**
   * Gets model data.
   * @returns IAnalyticsSettings object.
   */
  public getModelData(): IAnalyticsSettings {
    return this._analyticsSettingsData;
  }

  /**
   * Gets instrument identifier.
   * @returns Instrument identifier.
   */
  public getInstrumentIdentifier(): string {
    return this._instrumentIdentifier;
  }

  /**
   * Checks if model is loaded.
   * @returns A flag indicating if model is loaded.
   */
  public isModelLoaded() {
    return this._asHelper.instrumentLoaded;
  }

  /**
   * Validates before save.
   */
  public validateBeforeSave() {
    if (this.isPrivateInstrument && !LvMath.isNotGUIDIdentifier(this._instrumentIdentifier)) {
      throw new LvError(LvDataMaster.getError('dM-3385'), 'Analytics', LvErrorType.USER_FRIENDLY);
    }
  }

  /**
   * Load instrument and it's data
   * @param {IInstrumentConfig} config instrument configuration
   * @returns promise based ILoadSessionResult
   */
  public async load(config: IInstrumentConfig): Promise<ILoadSessionResult> {
    try {
      this._modelLoading.next(true);
      this._pricingModelUpdated.next(null);

      this._isPrivateInstrument = config.isPrivate;
      this._sessionId = config.sessionId;
      this._instrumentIdentifier = config.identifier;
      this._draftId = config.draftId;

      let convertible = null;
      if (this._instrumentIdentifier) {
        convertible = await this._cService.getConvertible(
          this._instrumentIdentifier,
          this._sessionId,
          this._isPrivateInstrument,
          config.draftId);
      }

      if (!convertible) {
        this._instrumentIdentifier = null;
      }

      let primaryEnvironment = null;
      const environmentsFromExcel: any = {};

      if (!!this._excelSvc?.isInitialized()) {
        if (this._excelSvc.getData().environment) {
          primaryEnvironment = this._excelSvc.getData().environment;
        }

        this.setEnvironmentsOverridesFromExcel(environmentsFromExcel);
      }

      let valuationSession: IValuationSession = null;
      if (!this._sessionId) {
        valuationSession = await this._vsService.createValuationSession(this._instrumentIdentifier,
          this._isPrivateInstrument,
          primaryEnvironment,
          environmentsFromExcel,
          this.isOpenedFromExcel);

        this._sessionId = valuationSession.sessionId;
      }
      else {
        valuationSession = await this._vsService
          .loadInstrumentInSession(this._sessionId,
                                   this._instrumentIdentifier,
                                   this._isPrivateInstrument,
                                   primaryEnvironment,
                                   this.isOpenedFromExcel);
      }

      const availablePricingEnvironments = await this._companyAndUserSettingsService.getAvailableEnvrionments();

      let mData = null;

      if (valuationSession.sessionId && valuationSession.lwsIdentifier) {
        mData = await this._marketDataService.getMarketData(valuationSession.sessionId, valuationSession.lwsIdentifier);
      }


      const data = {
        convertible: convertible,
        valuationSession: valuationSession,
        availablePricingEnvironments: availablePricingEnvironments,
        marketData: mData,
        cbCcyUsdFxRate: null,
        otherSettings: null,
        valuationSettings: null,
      };

      this._analyticsSettingsData = data;

      this._cHelper.init(this._analyticsSettingsData.convertible);

      if (this._excelSvc?.isInitialized()) {

        // load if market data is not set
        if (!!this._analyticsSettingsData.marketData) {
          await this.mapMarketDataFromExcel();
          this._originalMarketDataForExcelMapp = _.clone(mData);
        }

        await this.mappModelCustomizationFromExcel();
        this._originalModelCustomizationForExcelMapp = _.clone(valuationSession.modelCustomization);

        this._isExcelDataLoaded = true;
      }

      await this.loadOtherSettings();
      await this.loadValuationSettings();

      this._asHelper.init(this._analyticsSettingsData);      

      if (convertible) {
        // tslint:disable-next-line:max-line-length
        try {
          this._analyticsSettingsData.cbCcyUsdFxRate = await this._sharedMarketDataService.getFxRate(this._cHelper.currencyCode, 'USD');
        } catch (error) {
          console.log(error);
        }
      }

      this._analyticsSettingsUpdated.next(this.asHelper.instrumentLoaded);

      if (valuationSession) {
        return {
          sessionId: valuationSession.sessionId,
          convertible: convertible
        } as ILoadSessionResult;

      } else {
        return null;
      }
    }
    catch (error) {
      this._logger.logError(error);

      throw error;
    }
    finally {
      this._modelLoading.next(false);
    }
  }

  /**
   * Reloads market data.
   */
  async reloadMarketData() {
    if (this._excelSvc?.isInitialized()) {
      this._analyticsSettingsData.marketData = this._originalMarketDataForExcelMapp;
    }

    try {
      if (this._analyticsSettingsData.valuationSession.sessionId && this._analyticsSettingsData.valuationSession.lwsIdentifier) {
        const mData = await this._marketDataService.reloadMarketData({
          sessionId: this._analyticsSettingsData.valuationSession.sessionId,
          lwsIdentifier: this._analyticsSettingsData.valuationSession.lwsIdentifier,
          sectionEnvironments: this._analyticsSettingsData.valuationSession.selectedEnvironments
        } as IReloadMarketDataRequest);

        this._analyticsSettingsData.marketData = mData;

        if (this._excelSvc?.isInitialized()) {
          await this.mapMarketDataFromExcel();
        }
      }
    }
    catch (error) {
      this._errorService.handleError(error, e => {
        if (e.type === LvErrorType.CONFLICT) {
          this._logger.logError(e);
        }
      });
    }
  }

  /**
   * Reloads model customization.
   */
  async reloadModelCustomization() {
    if (this._excelSvc?.isInitialized()) {
      this._analyticsSettingsData.valuationSession.modelCustomization = this._originalModelCustomizationForExcelMapp;
    }

    try {
      if (this._analyticsSettingsData.valuationSession.sessionId && this._analyticsSettingsData.valuationSession.lwsIdentifier) {
        const modelCustomization = await this._vsService.loadModelCustomization(this._analyticsSettingsData.valuationSession.sessionId,
          this._analyticsSettingsData.valuationSession.lwsIdentifier,
          this._analyticsSettingsData.valuationSession.selectedEnvironments.modelSettings);

        this._analyticsSettingsData.valuationSession.modelCustomization = modelCustomization;

        if (this._excelSvc?.isInitialized()) {
          this.mappModelCustomizationFromExcel();
          this.overrideModelCustomizationInSession();
        }
      }
    }
    catch (error) {
      this._errorService.handleError(error, e => {
        if (e.type === LvErrorType.CONFLICT) {
          this._logger.logError(e);
        }
      });
    }
  }

  /**
   * Closes valuation session.
   */
  public async closeSession(): Promise<void> {
    try {
      await this._vsService.closeValuationSession(this._asHelper.sessionId);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
  }

  /**
   * Load other settings for environment into analytics settings
   */
  async loadOtherSettings() {
    try {
      this._analyticsSettingsData.otherSettings = await this._companyAndUserSettingsService.getOtherEnvironmentSettings();
      this.notifyOtherSettingsChanged();
      this._otherSettingsChanged.next();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
  }

  async loadValuationSettings(): Promise<void> {
    try {
      this._analyticsSettingsData.valuationSettings = await this._companyAndUserSettingsService.getValuationEnvironmentSettings();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
  }

  /**
   * Updates market data.
   * @param saveMarketDataResult ISaveMarketDataResponse object.
   */
  updateMarketData(saveMarketDataResult: ISaveMarketDataResponse, shouldSendUpdateMarketDataEvent: boolean = false) {
    this._analyticsSettingsData.valuationSession.selectedEnvironments = saveMarketDataResult.selectedEnvironments;

    if (shouldSendUpdateMarketDataEvent) {
      this._updateModel.next({
        eventId: AnalyticsSettingsEvents.MarketDataUpdated,
        data: saveMarketDataResult.marketData,
      });
      this._pricingEnvironmentsChanged.next(true);
    }
  }

  /**
   * Updates model.
   * @param event IAnalyticsPresenterEvent object.
   */
  public updateModel(event: IAnalyticsPresenterEvent<any>) {
    this._updateModel.next(event);
  }

  /**
   * Updates pricing model.
   * @param event IAnalyticsPresenterPricingEvent object.
   */
  public updatePricingModel(event: IAnalyticsPresenterPricingEvent) {
    this._analyticsSettingsData.valuationSession.pricing = event.data.pricing;
    this._analyticsSettingsData.valuationSession.pricing.valuationDate = event.data.viInputs.valuationDate;
    this._pricingModelUpdated.next(event);
  }

  /**
   * Publishes event.
   * @param event IAnalyticsPresenterEvent object.
   */
  public publishEvent(event: IAnalyticsPresenterEvent<any>) {
    this._eventPublihed.next(event);
  }

  /**
   * Executes command.
   * @param command AnalyticsCommands object.
   */
  public async executeCommand(command: AnalyticsCommands) {
    try {
      if (this._instrumentIdentifier) {
        const convertible = await this._cService.getConvertible(
          this._instrumentIdentifier,
          this._sessionId,
          this.isPrivateInstrument,
          this._draftId);

        this._analyticsSettingsData.convertible = convertible;

        if (this.asHelper.instrumentLoaded && convertible.priceTalk) {
          this._analyticsSettingsData.valuationSession.pricing.newIssueAssumptions = {
            ...this._analyticsSettingsData.valuationSession.pricing.newIssueAssumptions,
            coupon: convertible.priceTalk.couponAssumed,
            higherStrikePremium: convertible.priceTalk.higherStrikePremiumAssumed,
            issueYield: convertible.priceTalk.issueYieldAssumed,
            premium: convertible.priceTalk.premiumAssumed,
            redemptionValue: convertible.priceTalk.redemptionValueAssumed,
            spread: convertible.priceTalk.spreadAssumed,
            issuePrice: convertible.priceTalk.issuePriceAssumed
          };

          // Comment with Milan about this fix !
          // task - jira.leversys.local:8080/browse/SYSTEM-2530
          if (convertible.stockPriceReference && convertible.stockPriceReference?.referenceType === StockReferenceType.Fixed) {
            this._analyticsSettingsData.valuationSession.pricing.newIssueAssumptions.stockRefCBCcy =
              convertible.stockPriceReference.fixedStockRef / convertible.stockPriceReference.fixedFXRef;

            this._analyticsSettingsData.valuationSession.pricing.newIssueAssumptions.stockRef =
              convertible.stockPriceReference.fixedStockRef;
             
            this._analyticsSettingsData.valuationSession.pricing.newIssueAssumptions.fx =
              convertible.stockPriceReference.fixedFXRef;
          }

          this.asHelper.init(this._analyticsSettingsData);

          await this.overrideNewIssueAssumptions();
        }

        this._cHelper.init(this._analyticsSettingsData.convertible);
      }

      this._commandExecuted.next(command);
    }
    catch (error) {
      this._logger.logError(error);
    }
  }

  private async overrideNewIssueAssumptions() {
    const envId = this.getModelData().valuationSession.selectedEnvironments.pricing;

    const request = {
      sessionId: this._analyticsSettingsData.valuationSession.sessionId,
      lwsIdentifier: this._instrumentIdentifier,
      environmentId: envId,
      userAssumptions: { ...this._analyticsSettingsData.valuationSession.pricing.newIssueAssumptions }
    } as IOverrideNewIssueAssumptionsRequest;

    await this._vsService.overrideNewIssueAssumptions(request);
  }

  /**
   * Publishes event to notify that settings are changed.
   */
  public notifySettingsChanged() {
    this._eventPublihed.next({
      eventId: AnalyticsSettingsEvents.ValuationSettingsModelUpdated
    } as IAnalyticsPresenterEvent<void>);
  }

  /**
   * Publishes event to notify that other settings are changed.
   */
  public notifyOtherSettingsChanged() {
    this._eventPublihed.next({
      eventId: AnalyticsSettingsEvents.OtherSettingsUpdated
    } as IAnalyticsPresenterEvent<void>);
  }

  /**
   * Checks if field is from Excel.
   * @param alias Alias.
   * @returns A flag indicating if field is from Excel.
   */
  IsFieldFromExcel(alias: string): boolean {
    if (!!this._excelSvc?.isInitialized()) {
      return this._excelSvc.containsField(alias);
    }
    return false;
  }

  /**
   * Overwrites original market data.
   */
  overwriteMarketdataExcelOriginalData() {
    this._originalMarketDataForExcelMapp = _.cloneDeep(this._analyticsSettingsData.marketData);
  }

  /**
   * Overwrites initial model customization.
   */
  overwriteModelCustomizationExcelInitialData() {
    this._originalModelCustomizationForExcelMapp = _.cloneDeep(this._analyticsSettingsData.valuationSession.modelCustomization);
  }

  /**
   * Sets model customization environment.
   * @param env IEnvironmentSettingsItem object.
   */
  setModelCustomizationEnv(env: IEnvironmentSettingsItem) {
    this._analyticsSettingsData.valuationSession.selectedEnvironments.modelSettings = env.id;
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Maps model customization from Excel.
   */
  private async mappModelCustomizationFromExcel() {
    if (this._analyticsSettingsData.valuationSession.modelCustomization) {
      this.modelCustomizationMapper.init(this._analyticsSettingsData.valuationSession.modelCustomization);
      this.modelCustomizationMapper.mapp(this._excelSvc.getMappedFields('Model Customization'));

      await this.overrideModelCustomizationInSession();
    }
  }

  /**
   * Maps market data from Excel.
   */
  private async mapMarketDataFromExcel() {

    if (!this._analyticsSettingsData.valuationSession.valuationSettings) {
      this._analyticsSettingsData.valuationSession.valuationSettings = {
        marketData: {} as IMarketDataBase
      } as IValuationSettings;
    }

    if (this._analyticsSettingsData.valuationSession.valuationSettings.marketData) {
      this.flatMarketDataMapper.init(this._analyticsSettingsData.valuationSession.valuationSettings.marketData);
      this.flatMarketDataMapper.mapp(this._excelSvc.getFlatMarketDataFields());

      this.pricingMapper.init(this._analyticsSettingsData.valuationSession.pricing || {} as IPricing);
      this.pricingMapper.setIsCrossFx(!!this._analyticsSettingsData.convertible?.isCrossFx);
      this.pricingMapper.setIsPeps(this._analyticsSettingsData.convertible?.subType === ConvertibleSubType.PEPS);
      this.pricingMapper.setIsConvertableDeltaNeutral(!!this._analyticsSettingsData.convertible?.isDeltaNeutral);
      this.pricingMapper.setIsConvertableDetachable(!!this._analyticsSettingsData.convertible
        && this._analyticsSettingsData.convertible.subType === ConvertibleSubType.ConvertibleWithDetachableWarrant);
      this.pricingMapper.mapp(this._excelSvc.getPricesDataFields());


      console.log(this.cHelper.isNewIssue);
     

      if(this.cHelper.isNewIssue &&
        this._analyticsSettingsData?.valuationSession?.pricing?.newIssueAssumptions &&
        this._analyticsSettingsData?.valuationSession?.sessionId){
          await this.overrideNewIssueAssumptions();
      }

      let fields = this._excelSvc.getMappedFields('Market Data');
      const issueStockRef = this._excelSvc.getField('ISSUE_STK_REF_CBCCY');
      const stockRef = this._excelSvc.getFieldValue('ISSUE_STK_REF_CBCCY');
      const volUpside = this._excelSvc.getField('VOL_UP');
      const volDownside = this._excelSvc.getField('VOL_UP');
      const volLcl = this._excelSvc.getField('VOL_LCL');

      if (issueStockRef && !stockRef) {
        fields.push({
          key: issueStockRef.alias,
          value: issueStockRef.value,
          editable: issueStockRef.editable
        });
      }

      if (this._analyticsSettingsData.convertible.subType !== ConvertibleSubType.PEPS && (volUpside || volDownside || volLcl)) {
        fields = fields.filter(x => x.key !== 'VOL_UP');
        fields = fields.filter(x => x.key !== 'VOL_DOWN');
        fields = fields.filter(x => x.key !== 'VOL_LCL');
      }

      if (this._analyticsSettingsData.marketData) {
        this._analyticsSettingsData.marketData.interestRates.instrumentTermsStructure = this.loadInstrumentYieldCurves();
        this._analyticsSettingsData.marketData.interestRates.underlyingTermsStructure = this.loadUnderlyingYieldCurves();
        const allSystemYieldCurves = await this._marketDataService.GetAllSystemYieldCurves();
        this.marketDataMapper.setSystemYieldCurves(allSystemYieldCurves);
        this.marketDataMapper.init(this._analyticsSettingsData.marketData);
        this.marketDataMapper.mapp(fields);

        await this.overrideMarketDataInSession();
      }
    }
  }

  /**
   * Overrides market data in session.
   */
  private async overrideMarketDataInSession() {
    if (this._analyticsSettingsData.marketData) {
      await this._marketDataService.overrideMarketData(
        this._analyticsSettingsData.valuationSession.lwsIdentifier,
        this._analyticsSettingsData.valuationSession.sessionId,
        this._analyticsSettingsData.marketData
      );

      await this.overrideInterestRatesInSession();
    }
  }

  /**
   * Overrides model customization in session.
   */
  private async overrideModelCustomizationInSession() {
    await this._vsService.overrideModelCustomization({
      sessionId: this._analyticsSettingsData.valuationSession.sessionId,
      lwsIdentifier: this._analyticsSettingsData.valuationSession.lwsIdentifier,
      modelCustomization: {
        ...this._analyticsSettingsData.valuationSession.modelCustomization,
        callAdjustmentModelSettings: { ...this._analyticsSettingsData.valuationSession.modelCustomization.callAdjustmentModelSettings },
        creditModelSettings: { ...this._analyticsSettingsData.valuationSession.modelCustomization.creditModelSettings }
      },
      sourceId: this._modelCustomizationSettingsService.instanceId
    } as IOverrideModelCustomizationRequest);
  }

  /**
   * Loads instrument yield curves.
   * @returns Instrument yield curve scheduled items.
   */
  private loadInstrumentYieldCurves() {
    try {

      let instrumentYieldCurveScheduledItems = null;

      if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_INST')) {
        instrumentYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_INST') as ITermStructureItem[];
      }
      else if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_T_INST') && !this._excelSvc.isV3()) {
        instrumentYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_T_INST') as ITermStructureItem[];
      }
      else if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_R_INST') && !this._excelSvc.isV3()) {
        instrumentYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_R_INST') as ITermStructureItem[];
      }
      else if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_DS_INST') && !this._excelSvc.isV3()) {
        instrumentYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_DS_INST') as ITermStructureItem[];
      }
      else if (this._excelSvc?.isV3() && !!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_INST_SPECIAL')) {
        instrumentYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_INST_SPECIAL') as ITermStructureItem[];
      }

      return instrumentYieldCurveScheduledItems;
    }
    catch (e) {
      throw e;
    }
    finally {

    }
  }

  /**
   * Loads underlying yield curves.
   * @returns Underlying yield curve scheduled items.
   */
  private loadUnderlyingYieldCurves() {
    try {

      let underlyingYieldCurveScheduledItems = null;

      if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_UND_INST')) {
        underlyingYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_UND_INST') as ITermStructureItem[];
      }
      else if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_T_UND_INST') && !this._excelSvc.isV3()) {
        underlyingYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_T_UND_INST') as ITermStructureItem[];
      }
      else if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_R_UND_INST') && !this._excelSvc.isV3()) {
        underlyingYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_R_UND_INST') as ITermStructureItem[];
      }
      else if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_DS_UND_INST') && !this._excelSvc.isV3()) {
        underlyingYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_DS_UND_INST') as ITermStructureItem[];
      }
      else if (this._excelSvc?.isV3() && !!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_UND_INST_SPECIAL')) {
        underlyingYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_UND_INST_SPECIAL') as ITermStructureItem[];
      }

      return underlyingYieldCurveScheduledItems;
    }
    catch (e) {
      throw e;
    }
    finally {
    }
  }

  /**
   * Overrides interest rates in session.
   */
  private async overrideInterestRatesInSession() {
    const request = {
      environmentId: this._analyticsSettingsData.valuationSession.selectedEnvironments.interestRates,
      lwsIdentifier: this._analyticsSettingsData.valuationSession.lwsIdentifier,
      instrumentCurrencyCode: this._analyticsSettingsData.convertible.currencyCode,
      underlyingCurrencyCode: this._analyticsSettingsData.convertible.underlying.currency,
      interestRates: this._analyticsSettingsData.marketData.interestRates,
      sessionId: this._analyticsSettingsData.valuationSession.sessionId,
      instrumentTermsStructure: this._analyticsSettingsData.marketData.interestRates.instrumentTermsStructure,
      underlyingTermsStructure: this._analyticsSettingsData.marketData.interestRates.underlyingTermsStructure
    } as ISaveInterestRatesRequest;

    await this._marketDataService.overrideInterestRates(request);
  }

  /**
   * Set pricing environments overrides from excel
   * @param environmentsFromExcel Environments overrides from excel
   * @param availablePricingEnvironments List of available pricing environments
   */
  private setEnvironmentsOverridesFromExcel(environmentsFromExcel: IPricingEnvironmentOverrides) {
    environmentsFromExcel.environmentPricing = this._excelSvc.getFieldValue('ENV_PRICING');
    environmentsFromExcel.environmentCredit = this._excelSvc.getFieldValue('ENV_CREDIT');
    environmentsFromExcel.environmentVolatility = this._excelSvc.getFieldValue('ENV_VOL');
    environmentsFromExcel.environmentBorrow = this._excelSvc.getFieldValue('ENV_BORROW');
    environmentsFromExcel.environmentDividends = this._excelSvc.getFieldValue('ENV_DVD');
    environmentsFromExcel.environmentFunding = this._excelSvc.getFieldValue('ENV_FUND');
    environmentsFromExcel.environmentWithholding = this._excelSvc.getFieldValue('ENV_WT');
    environmentsFromExcel.environmentInterestRates = this._excelSvc.getFieldValue('ENV_INT_RATE');
    environmentsFromExcel.environmentAveraging = this._excelSvc.getFieldValue('ENV_AVG');
    environmentsFromExcel.environmentModelCustomization = this._excelSvc.getFieldValue('ENV_MODEL_CUST');
  }
}
