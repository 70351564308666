<lv-section-settings [settings]="settings"
    [showSingleEnvironment]="true"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="quantityOverrideSystemDefaultsSettingsTooltip"
    filter="div[data-tooltip-id]">
  <ng-template systemDefaultsSettings let-systemSettings>
    <lv-quantity-section-settings
    [settings]="systemSettings"
    [env]="'system'"
    [lvId]="'lv-quantity-section-settings-system-defaults'">
    </lv-quantity-section-settings>
  </ng-template>

  <ng-template environmentSettings let-settings let-overrideSystemDefaults="overrideSystemDefaults">
    <lv-quantity-section-settings
      [lvId]="'lv-quantity-section-settings-my-defaults'"
      [settings]="settings"
      [env]="'my'"
      [overrideSystemDefaults]="overrideSystemDefaults">
    </lv-quantity-section-settings>
  </ng-template>
</lv-section-settings>

<ng-template #quantityOverrideSystemDefaultsSettingsTooltip let-anchor>
  <ng-container *ngIf="getTootlipId(anchor, 'overrideSystemDefaultsLabel')">
    <lv-tooltip dm="dM-1123"></lv-tooltip>
  </ng-container>
</ng-template>