import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IInstrumentLookup, LvInstrumentMonitorError } from '../../models';
import { HttpClientBase } from '@lv-core-ui/api';
import { InstrumentSourceType } from '@lv-instrument-monitor/models/enums/instrument-source';

@Injectable()
export class LookupService extends HttpClientBase {

  private convertibles: IInstrumentLookup[];

  constructor(
    http: HttpClient
  ) {
    const resourceUrl = '/instrumentMonitor/lookup';
    super(http, resourceUrl);
  }

  async LoadAllConvertibleLookps() {
    this.convertibles = await this.getConvertibleLookup('', 0, 10000);
  }

  /**
   * Retrieves list of instrumetns
   * @param query Searcg query for instrument select
   * @param skip Number of instruments to skip
   * @param take Number of instruments to retrive
   * @returns List of matching instruments
   */
  async getConvertibleLookup(query: string, skip: number, take: number): Promise<IInstrumentLookup[]> {
    try {
      const result: IInstrumentLookup[] = await this.getAsync<IInstrumentLookup[]>({
        query: query || '',
        skip: skip,
        take: take
      }, '/convertibleInfos');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }

  /**
   * Retrieves instrument for given query string.
   * @param query instrument identifier used to lookup instrument
   * @param instrumentSource Instrument source type
   */
  async getConvertible(query: string, instrumentSource: InstrumentSourceType): Promise<IInstrumentLookup> {
    try {
      const result: IInstrumentLookup = await this.getAsync<IInstrumentLookup>({
        query: query || '',
        instrumentSource: instrumentSource
      }, '/convertibleInfo');
      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvInstrumentMonitorError(e.message));
    }
  }
}
