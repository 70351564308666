<lv-advanced-grid
  class="lv-credit-terms-structure-grid"
  [columns]="columns"
  [records]="scheduleItems"
  [createFormGroup]="createFormGroup"
  [parseFn]="parseFn"
  sortBy="date"
  [lvId]="getLvId()"
  [showXlLabel]="isXlLabelVisible"
  [excelFieldAlias]="excelFieldAliasLabel"
  [isFieldFromExcelEnabled]="isFieldFromExcelEnabled">
</lv-advanced-grid>
