import { LvUserFriendlyError } from '@lv-core-ui/models';

/**
 * User-friendly error for custom instrument module.
 */
export class LvPrivateInstrumentsError extends LvUserFriendlyError {
  constructor(message: string) {
    super(message, 'Custom Instruments');
  }
}
