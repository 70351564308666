import { LvEnum } from '../../util/lv-lookup-enum';
import { LvUtil } from '../../util/util';

export type LvAdvancedGridColumnType = 'text' | 'numeric' | 'date' | 'list' | 'enum';
export type LvAdvancedGridColumnAlignment = 'left' | 'right';

export class LvAdvancedGridColumn {
  title: string;
  field: string;
  dmKey: string;
  type: LvAdvancedGridColumnType;
  width: number;
  editable: boolean;
  visible: boolean;
  alignment: LvAdvancedGridColumnAlignment;
  isOutputColumn: boolean;

  get isTextColumn(): boolean {
    return this.type === 'text';
  }

  get isNumericColumn(): boolean {
    return this.type === 'numeric';
  }

  get isDateColumn(): boolean {
    return this.type === 'date';
  }

  get isListColumn(): boolean {
    return this.type === 'list';
  }

  get isEnumColumn(): boolean {
    return this.type === 'enum';
  }

  get isRightAligned(): boolean {
    return this.alignment === 'right';
  }

  get ngClass(): any {
    return {
      'lv-input-field': true,
      'lv-input-field--full': true,
      'lv-input-field--right': this.isRightAligned,
      'lv-input-field--output': this.isOutputColumn,
      'lv-input-field-disabled': !this.editable,
    };
  }

  constructor() {
    this.field = null;
    this.width = 104;
    this.editable = true;
    this.dmKey = null;
    this.visible = true;
    this.alignment = 'left';
    this.isOutputColumn = false;
  }

  public isCellVisible(rowIndex: number, columnIndex: number): boolean {
    return true;
  }

  public getStyleWidth(width: string): any {
    return {'width': width + 'px', 'max-width': width + 'px'} as any;
  }

  public isCellPlaceholder(rowIndex: number, columnIndex: number): string {
    return null;
  }
}

export class LvAdvancedGridTextColumn extends LvAdvancedGridColumn {

  placeholder: string;

  constructor() {
    super();

    this.type = 'text';
    this.title = 'Text Column';
  }
}

export class LvAdvancedGridNumericColumn extends LvAdvancedGridColumn {

  format: string;
  outputFormat: string;
  decimals: string;
  threeDecimalOutputFormat: string;

  constructor() {
    super();

    this.type = 'numeric';
    this.title = 'Numeric Column';
    this.alignment = 'right';
    this.format = '#.##';
    this.decimals = '2';
    this.outputFormat = '1.0-2';
    this.threeDecimalOutputFormat = '1.0-3';
  }

  isNumberFormat(rowIndex: number, columnIndex: number) {
    return true;
  }

  isPercentFormat(rowIndex: number, columnIndex: number) {
    return false;
  }
}

export class LvAdvancedGridDateColumn extends LvAdvancedGridColumn {

  constructor() {
    super();

    this.type = 'date';
    this.title = 'Date Column';
  }
}

export class LvAdvancedGridListColumn extends LvAdvancedGridColumn {

  displayField: string;
  valueField: string;
  valuePrimitive: boolean;
  useDefaultItem: boolean;

  dataMap: any;

  set data(value: any[]) {
    this._data = value.map(a => ({ ...a }));
    this.dataMap = LvUtil.toDictionary(this.data, this.valueField);
  }

  get data(): any[] {
    return this._data.filter(this._filterFn);
  }

  private _data: any[];
  private _filterFn: (item: any) => boolean;

  constructor() {
    super();

    this.type = 'list';
    this.title = 'List Column';

    this.valueField = 'id';
    this.displayField = 'text';
    this.valuePrimitive = true;
    this.useDefaultItem = false;

    this.dataMap = {};

    this._filterFn = (item: any) => true;
  }

  setFilterFn(filter: (item: any) => boolean) {
    this._filterFn = filter;
  }

  getDefaultDropdownItem(dataItem: any): any {
    if (!this.useDefaultItem) {
      return null;
    }

    const item = this._data.find(x => x.code === dataItem);
    return item;
  }
}

export class LvAdvancedGridEnumColumn extends LvAdvancedGridListColumn {

  enumDescription: typeof LvEnum;

  constructor() {
    super();

    this.type = 'enum';
    this.title = 'Enum Column';
  }
}
