import { ScenarioInputsShift, ScenarioOutputDescription, formatUnderlyingPrice, formatUnderlyingCBPrice,
         ScenarioInputsShiftDescription, ScenarioPriceTalk } from '@lv-analytics/models';
import { ILvScenarioReport, getDefaultScenarioReport } from '../lv-scenario.view';

export interface ILvScenarioGridViewRecord {
  dimension: ScenarioInputsShift;
  dimensionValue: number;
  [columnId: string]: any;
}

export interface ILvScenarioGridViewColumn {
  columnId: string;
  width: number;
  title?: string;
  data?: any;
}

export interface ILvScenarioGridViewGroup {
  groupId: string;
  width: number;
  title?: string;
  data?: any;
  subGroups?: ILvScenarioGridViewGroup[];
  columns?: ILvScenarioGridViewColumn[];
  [other: string]: any;
}

export interface ILvScenarioGridView {
  records: ILvScenarioGridViewRecord[];
}

/**
 * Scenario grid view.
 */
export abstract class LvScenarioGridView<T extends ILvScenarioGridView> {
  scenarioOutputDescription = ScenarioOutputDescription;

  get showInputsAsPercentage(): boolean {
    return !!this.report.showInputsAsPercentage;
  }

  get showFirstDimensionAsPercentage(): boolean {
    return this.showInputsAsPercentage && this.report.showInputsAsPercentage.firstDimension;
  }

  get firstDimensionChangeRef(): number {
    return this.report.showInputsAsPercentage.firstDimensionChangeRef;
  }

  get showSecondDimensionAsPercentage(): boolean {
    return this.showInputsAsPercentage && this.report.showInputsAsPercentage.secondDimension;
  }

  get secondDimensionChangeRef(): number {
    return this.report.showInputsAsPercentage.secondDimensionChangeRef;
  }

  report: ILvScenarioReport;
  model: T;

  numberFormat: string;
  percentFormat: string;
  defaultColumnWidth: number;
  defaultDimensionColumnWidth: number;
  defaultScrollbarWidth: number;

  constructor() {
    this.numberFormat = '1.3-3';
    this.percentFormat = '1.0-3';
    this.defaultDimensionColumnWidth = 160;
    this.defaultColumnWidth = 120;
    this.defaultScrollbarWidth = 20;
  }

  /**
   * Does initialization.
   * @param report ILvScenarioReport object.
   */
  init(report?: ILvScenarioReport): void {
    this.report = getDefaultScenarioReport(report);
    this.model = this.getDefaultModel();

    if (this.report.data && this.report.data.length > 0) {
      this.doInit(this.report);
    }
  }

  /**
   * Checks if index is first.
   * @param index Index.
   * @returns A flag indicating if index is first.
   */
  isFirstIndex(index: number) {
    return index === 0;
  }

  /**
   * Checks if value is index.
   * @param index Index.
   * @param toCompare Number to compare.
   * @returns A flag indicating if value is index.
   */
  isIndex(index: number, toCompare: number) {
    return index === toCompare;
  }

  /**
   * Checks if index is last.
   * @param index Index.
   * @returns A flag indicating if index is last.
   */
  isLastIndex(array: any[], index: number) {
    return array.length - 1 === index;
  }

  /**
   * Checks if values are first indexes.
   * @param indexes List of indexes.
   * @returns A flag indicating if values are first indexes.
   */
  areFirstIndexes(...indexes: number[]) {
    for (let i = 0; i < indexes.length; i++) {
      if (indexes[i] > 0) {
        return false;
      }
    }

    return true;
  }

  /**
   * Gets first dimension description.
   * @param dimension Scenario inputs shift.
   * @returns First dimension description.
   */
  getFirstDimensionDescription(dimension: ScenarioInputsShift): string {
    if (dimension === ScenarioInputsShift.UnderlyingPrice) {
      return formatUnderlyingPrice(this.report.underlyingCurrencyCode);
    }

    if (dimension === ScenarioInputsShift.UnderlyingPriceCB
      && this.report.underlyingCBCurrencyCode !== this.report.underlyingCurrencyCode) {
      return formatUnderlyingCBPrice(this.report.underlyingCBCurrencyCode);
  }

    return ScenarioInputsShiftDescription[dimension];
  }

  /**
   * Gets price talk description.
   * @param priceTalk Scenario price talk.
   * @returns Price talk description.
   */
  getPriceTalkDescription(priceTalk: ScenarioPriceTalk): string {
    return ScenarioPriceTalk[priceTalk];
  }

  /**
   * Checks if valuation date is shift.
   * @param dimension Scenario inputs shift.
   * @returns A flag indicating if valuation date is shift.
   */
  isValuationDateShift(dimension: ScenarioInputsShift): boolean {
    return dimension === ScenarioInputsShift.ValuationDate;
  }

  /**
   * Gets default model.
   */
  abstract getDefaultModel(): T;

  /**
   * Does initialization.
   */
  abstract doInit(report: ILvScenarioReport): void;
}
