import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LvPermissionService } from '@lv-core-ui/services';

import { Observable } from 'rxjs';

@Injectable()
export class PermissionGuard  {
  constructor(
    private router: Router,
    private permissionService: LvPermissionService
  ) {}

  /**
   * Determines whether passed route is allowed or not.
   * @param route route to be checked
   * @param state current state of the router
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean  {
    if (state.url.match(/\/dashboard\/workspaces/i)) {
      return true;
    }

    const canEditCompany = this.permissionService.hasPermission('AccountManagement', 'EditCompany');
    const canEditUserProfile = this.permissionService.hasPermission('AccountManagement', 'EditProfile');

    if (canEditCompany) {
      if (state.url.match(/^\/dashboard\/company\/user\/[0-9]+$/i)) {
        return true;
      }

      if (state.url.match(/^\/dashboard\/company\/user\/new$/i)) {
        return true;
      }

      if (state.url.match(/^\/dashboard\/company$/i)) {
        return true;
      }

    }

    if (state.url.match(/^\/dashboard\/settings$/i)) {
      return true;
    }

    if (state.url.match(/^\/dashboard\/user$/i)
      && canEditUserProfile) {
      return true;
    }

    if (state.url.match(/^\/dashboard\/external-instrument\/[0-9a-zA-Z%-./]+$/i) ||
      state.url.match(/^\/dashboard\/external-instrument\/[0-9a-zA-Z%-./?=]+$/i)) {
      return true;
    }

    return false;
  }
}
