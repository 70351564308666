import {
  trigger,
  style,
  animate,
  transition, AnimationTriggerMetadata
} from '@angular/animations';

export const slide: AnimationTriggerMetadata = trigger('slide', [
  transition('void => left', [
    style({
      transform: 'translate3d(-100%, 0, 0)'
    }),
    animate('250ms ease-in-out', style({
      transform: 'translate3d(0, 0, 0)'
    }))
  ]),
  transition('left => void', [
    animate('250ms ease-in-out', style({
      transform: 'translate3d(-100%, 0, 0)'
    }))
  ]),
  transition('void => right', [
    style({
      transform: 'translate3d(100%, 0, 0)'
    }),
    animate('250ms ease-in-out', style({
      transform: 'translate3d(0, 0, 0)'
    }))
  ]),
  transition('right => void', [
    animate('250ms ease-in-out', style({
      transform: 'translate3d(100%, 0, 0)'
    }))
  ]),
  transition('void => top', [
    style({
      transform: 'translate3d(0, -100%, 0)'
    }),
    animate('250ms ease-in-out', style({
      transform: 'translate3d(0, 0, 0)'
    }))
  ]),
  transition('top => void', [
    animate('250ms ease-in-out', style({
      transform: 'translate3d(0, -100%, 0)'
    }))
  ])
]);
