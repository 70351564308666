import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchArray'
})
export class SearchArrayPipe implements PipeTransform {

  transform(array: any[], filter: string, field: string, include?: string): any {
    if (filter) {
      return array.filter((item) => {
        if (include && item[include]) {
          return item[include];
        }

        return (item[field] || '').toLowerCase().lastIndexOf(filter.toLowerCase()) !== -1;
      });
    }

    return array;
  }
}
