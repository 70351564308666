import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IBaseTermsSettings, IMakeWholeSectionSettings, OfferAndCompensation } from '@lv-convertible-bond/models';

@Component({
  selector: 'lv-make-whole-settings',
  templateUrl: './lv-make-whole-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvMakeWholeSettingsComponent implements OnInit {

  @Input() systemDefaults: IBaseTermsSettings<IMakeWholeSectionSettings>;
  @Input() myDefaults: IBaseTermsSettings<IMakeWholeSectionSettings>;
  @Input() systemTakeOverOfferAndCompensation: OfferAndCompensation;
  @Input() myTakeOverOfferAndCompensation: OfferAndCompensation;

  constructor() { }

  ngOnInit() {
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
   getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }
}
