<lv-flex-box 
  data-cy="DM-1660"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="conversionPriceCCYSourceTooltipTemplate"
    filter="span">
    <span> Conversion Price CCY </span>
  </div>
  <div class="lv-input-field ">
    <kendo-dropdownlist
      name="reset-conversion-price"
      [data]="conversionPriceCCYLookup.items"
      valueField="id"
      [disabled]="!overrideDefaults"
      textField="text"
      [valuePrimitive]="true"
      [(ngModel)]="resetSettings.conversionPriceCurrencyType">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1661"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="resetCurrencySourceTooltipTemplate"
    filter="span">
    <span> Reset Currency </span>
  </div>
  <div class="lv-input-field">
    <kendo-dropdownlist
      name="reset-currency"
      [data]="conversionPriceCCYLookup.items"
      valueField="id"
      [disabled]="!overrideDefaults"
      textField="text"
      [valuePrimitive]="true"
      [(ngModel)]="resetSettings.resetCurrencyType">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1662"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="numberAveragingDaysTooltipTemplate"
    filter="span">
    <span> Number of Averaging Days </span>
  </div>
  <div class="lv-input-field">
    <lv-numeric-text-box
      [format]="formatZero"
      [disabled]="!overrideDefaults"
      [decimals]="decimalsZero"
      name="number-averaging-days"
      [(ngModel)]="resetSettings.resetAveraging.numberOfAveragingDays">
    </lv-numeric-text-box>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1663"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="resetFixingLagTooltipTemplate"
    filter="span">
    <span> Reset Fixing Lag </span>
  </div>
  <div class="lv-input-field">
    <lv-numeric-text-box
      [format]="formatZero"
      [disabled]="!overrideDefaults"
      [decimals]="decimalsZero"
      name="reset-fixing-lag"
      [(ngModel)]="resetSettings.resetAveraging.startLag">
    </lv-numeric-text-box>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1664"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="floorExpressedSourceTooltipTemplate"
    filter="span">
    <span> Floor Expressed Relative To </span>
  </div>
  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      name="reset-floor-expressed-as"
      [disabled]="!overrideDefaults"
      [data]="floorExpressedRelativeToLookup.items"
      valueField="id"
      textField="text"
      [valuePrimitive]="true"
      [popupSettings]="{ width: 211 }"
      [(ngModel)]="resetSettings.floorExpressedRelativeTo">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1665"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="capExpressedSourceTooltipTemplate"
    filter="span">
    <span> Cap Expressed Relative To </span>
  </div>
  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      name="reset-cap-expressed-as"
      [data]="capExpressedRelativeToLookup.items"
      valueField="id"
      [disabled]="!overrideDefaults"
      textField="text"
      [valuePrimitive]="true"
      [popupSettings]="{ width: 211 }"
      [(ngModel)]="resetSettings.capExpressedRelativeTo">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1666"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="globalFloorSourceTooltipTemplate"
    filter="span">
    <span> Global Floor </span>
  </div>
  <div class="lv-input-field align-sufix">
    <lv-numeric-text-box
      name="reset-global-floor"
      [format]="formatThree"
      [disabled]="!overrideDefaults"
      [decimals]="decimalsThree"
      [(ngModel)]="resetSettings.globalFloor">
    </lv-numeric-text-box>
    <span class="lv-sufix-label">%</span>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1667"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="globalCapSourceTooltipTemplate"
    filter="span">
    <span> Global Cap </span>
  </div>
  <div class="lv-input-field align-sufix">
    <lv-numeric-text-box
      name="reset-global-cap"
      [format]="formatThree"
      [disabled]="!overrideDefaults"
      [decimals]="decimalsThree"
      [(ngModel)]="resetSettings.globalCap">
    </lv-numeric-text-box>
    <span class="lv-sufix-label">%</span>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1668"
  class="space-bottom"
  direction="row">
  
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="optionalResetSourceTooltipTemplate"
    filter="span">
    <span> Optional Reset </span>
  </div>
  <div class="lv-field lv-field--checkbox">
    <input class="k-checkbox"
      type="checkbox"
      [attr.id]="issuerOptionalResetId"
      [disabled]="!overrideDefaults"
      name="reset-issuer-optional-reset"
      [(ngModel)]="resetSettings.optionalReset">
    <label class="k-checkbox-label"
      [attr.for]="issuerOptionalResetId">
    </label>
  </div>
</lv-flex-box>

<ng-template #conversionPriceCCYSourceTooltipTemplate>
  <lv-tooltip dm="dM-1660"></lv-tooltip>
</ng-template>
<ng-template #resetCurrencySourceTooltipTemplate>
  <lv-tooltip dm="dM-1661"></lv-tooltip>
</ng-template>
<ng-template #numberAveragingDaysTooltipTemplate>
  <lv-tooltip dm="dM-1662"></lv-tooltip>
</ng-template>
<ng-template #resetFixingLagTooltipTemplate>
  <lv-tooltip dm="dM-1663"></lv-tooltip>
</ng-template>
<ng-template #floorExpressedSourceTooltipTemplate>
  <lv-tooltip dm="dM-1664"></lv-tooltip>
</ng-template>
<ng-template #capExpressedSourceTooltipTemplate>
  <lv-tooltip dm="dM-1665"></lv-tooltip>
</ng-template>
<ng-template #globalFloorSourceTooltipTemplate>
  <lv-tooltip dm="dM-1666"></lv-tooltip>
</ng-template>
<ng-template #globalCapSourceTooltipTemplate>
  <lv-tooltip dm="dM-1667"></lv-tooltip>
</ng-template>
<ng-template #optionalResetSourceTooltipTemplate>
  <lv-tooltip dm="dM-1668"></lv-tooltip>
</ng-template>
