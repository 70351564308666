/**
 * Types of convertible setup status.
 */
export enum ConvertibleSetupStatus {
  FullyCompleted = 'FullyCompleted',
  PartiallyCompleted = 'PartiallyCompleted',
  Failed = 'Failed',
  ManuallyCreated = 'ManuallyCreated',
  NewIssue = 'NewIssue',
}
