<lv-flex-box 
  data-cy="DM-1643"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="convertibleOnCallSourceTooltipTemplate"
    filter="span">
    <span> Convertible on Call </span>
  </div>
  <div class="lv-field lv-field--checkbox">
    <input class="k-checkbox"
      type="checkbox"
      [disabled]="!overrideDefaults"
      [attr.id]="convertibleOnCall"
      name="contingent-convertible-on-call"
      [(ngModel)]="cocoSettings.convertibleOnCall">
    <label class="k-checkbox-label" [attr.for]="convertibleOnCall"></label>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1644"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="conversionPriceTriggerSourceTooltipTemplate"
    filter="span">
    <span> Convertible Price Trigger </span>
  </div>
  <div class="lv-input-field align-sufix">
    <lv-numeric-text-box
      name="contingent-conversion-price-trigger"
      [format]="formatThree"
      [disabled]="!overrideDefaults"
      [decimals]="decimalsThree"
      [(ngModel)]="cocoSettings.convPriceTrigger">
    </lv-numeric-text-box>
    <span class="lv-sufix-label">%</span>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1645"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="buyoutAtCoCoTriggerSourceTooltipTemplate"
    filter="span">
    <span> Buyout at CoCo Trigger </span>
  </div>
  <div class="lv-field lv-field--checkbox">
    <input class="k-checkbox"
      type="checkbox"
      [disabled]="!overrideDefaults"
      [attr.id]="buyoutAtCocoTrigger"
      name="contingent-buyout-at-coco-trigger"
      [(ngModel)]="cocoSettings.buyoutAtCoCoTrigger">
    <label class="k-checkbox-label" [attr.for]="buyoutAtCocoTrigger"></label>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1646"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="quarterTypeSourceTooltipTemplate"
    filter="span">
    <span> Quarter Type </span>
  </div>
  <div class="lv-input-field">
    <kendo-dropdownlist
      name="contingent-quarter-type"
      [data]="quarterTypeLookup.items"
      valueField="id"
      [disabled]="!overrideDefaults"
      textField="text"
      [valuePrimitive]="true"
      [(ngModel)]="cocoSettings.quarterType">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1647"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="triggerPeriodTypeSourceTooltipTemplate"
    filter="span">
    <span> Trigger Period Type </span>
  </div>
  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      name="contingent-trigger-period-type"
      [data]="triggerPeriodTypeLookup.items"
      valueField="id"
      textField="text"
      [disabled]="!overrideDefaults"
      [valuePrimitive]="true"
      [popupSettings]="{ width: 191 }"
      [(ngModel)]="cocoSettings.triggerPeriodType">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1648"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="triggerPeriodDaysSourceTooltipTemplate"
    filter="span">
    <span> Trigger Period Days </span>
  </div>
  <div class="lv-input-field">
    <lv-numeric-text-box
      [format]="formatZero"
      [decimals]="decimalsZero"
      [disabled]="!overrideDefaults"
      name="contingent-trigger-period-days"
      [(ngModel)]="cocoSettings.triggerPeriodDays">
    </lv-numeric-text-box>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1649"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="outOfDaysSourceTooltipTemplate"
    filter="span">
    <span> Out of Days </span>
  </div>
  <div class="lv-input-field">
    <lv-numeric-text-box
      [format]="formatZero"
      [decimals]="decimalsZero"
      [disabled]="!overrideDefaults"
      name="contingent-out-of-days"
      [(ngModel)]="cocoSettings.outOfDays">
    </lv-numeric-text-box>
  </div>
</lv-flex-box>

<lv-flex-box 
  data-cy="DM-1650"
  direction="row">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="cbPriceTriggerParityLimitSourceTooltipTemplate"
    filter="span">
    <span> CB Price Trigger Parity Limit </span>
  </div>
  <div class="lv-input-field">
    <kendo-dropdownlist
      name="contingent-cb-price-trigger-parity"
      [data]="cbPriceTriggerParityLimitLookup.items"
      valueField="id"
      textField="text"
      [disabled]="!overrideDefaults"
      [valuePrimitive]="true"
      [(ngModel)]="cocoSettings.cbPriceTriggerParityLimit">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box
  class="space-bottom"
  data-cy="DM-1651"
  direction="row">
  
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="triggerTypeSourceTooltipTemplate"
    filter="span">
    <span> Trigger Type </span>
  </div>
  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      name="contingent-quarter-type"
      [data]="triggerTypeLookup.items"
      valueField="id"
      [disabled]="!overrideDefaults"
      textField="text"
      [valuePrimitive]="true"
      [popupSettings]="{ width: 175 }"
      [(ngModel)]="cocoSettings.triggerType">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<ng-template #convertibleOnCallSourceTooltipTemplate>
  <lv-tooltip dm="dM-1643"></lv-tooltip>
</ng-template>
<ng-template #conversionPriceTriggerSourceTooltipTemplate>
  <lv-tooltip dm="dM-1644"></lv-tooltip>
</ng-template>
<ng-template #buyoutAtCoCoTriggerSourceTooltipTemplate>
  <lv-tooltip dm="dM-1645"></lv-tooltip>
</ng-template>
<ng-template #quarterTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1646"></lv-tooltip>
</ng-template>
<ng-template #triggerPeriodTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1647"></lv-tooltip>
</ng-template>
<ng-template #triggerPeriodDaysSourceTooltipTemplate>
  <lv-tooltip dm="dM-1648"></lv-tooltip>
</ng-template>
<ng-template #outOfDaysSourceTooltipTemplate>
  <lv-tooltip dm="dM-1649"></lv-tooltip>
</ng-template>
<ng-template #cbPriceTriggerParityLimitSourceTooltipTemplate>
  <lv-tooltip dm="dM-1650"></lv-tooltip>
</ng-template>
<ng-template #triggerTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1651"></lv-tooltip>
</ng-template>
