<lv-flex-box direction="row" class="lv-variable-conversion">
  <lv-flex-box class="lv-variable-first-col">
    <lv-flex-box direction="row" data-cy="DM-868">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="incrementalShareFactorSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Incremental Share Factor
        </span>
        <lv-xl-label [field]="'VAR_CONV_INC_SHR_FACTOR'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="variable-inc-share-factor"
          [format]="formatThree"
          [decimals]="decimalsThree"
          [(ngModel)]="model.incrementalShareFactor"
          (didBlur)="onVariableConversionChange()"
          (didKeyDownEnter)="onVariableConversionChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-869">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="strikeCCYSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Strike CCY
        </span>
        <lv-xl-label [field]="'VAR_CONV_STRIKE_CCY'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <kendo-dropdownlist
          lvId="lv-variable-conversion-strikeCCY"
          name="variable-strikeCCY"
          [data]="strikeCCYLookup.items"
          valueField="id"
          textField="text"
          [valuePrimitive]="true"
          [(ngModel)]="model.strikeCurrency"
          (ngModelChange)="onVariableConversionChange()">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-872">
      <div class="lv-label lv-label-field lv-label-short"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="lockInDateSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span 
          lvId="variable-lockIn-date-checkbox">
          Lock-In Date
        </span>
        <lv-xl-label [field]="'VAR_CONV_LOCK_IN_DATE'"></lv-xl-label>
      </div>
      <lv-datepicker
        name ="lockInDate"
        checkBoxLvId= "variable-lockIn-date"
        [(ngModel)]="lockInDate"
        [checked]="useLockInDate"
        (didCheckboxValueChange)="onUseLockinChange($event)"
        (ngModelChange)="onVariableConversionChange()">
      </lv-datepicker>
    </lv-flex-box>
  </lv-flex-box>
  <lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-870">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="strikeSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Strike
        </span>
        <lv-xl-label [field]="'VAR_CONV_STRIKE_RT'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="variable-strike-percent"
          [format]="formatThree"
          [decimals]="decimalsThree"
          [(ngModel)]="model.strike"
          (didBlur)="onVariableConversionChange()"
          (didKeyDownEnter)="onVariableConversionChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-871">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="capRatioSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Cap Ratio
        </span>
        <lv-xl-label [field]="'VAR_CONV_CAP_RATIO'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          [format]="formatThree"
          [decimals]="decimalsThree"
          name="variable-cap-ratio"
          [(ngModel)]="model.capRatio"
          (didBlur)="onVariableConversionChange()"
          (didKeyDownEnter)="onVariableConversionChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<ng-template #incrementalShareFactorSourceTooltip>
  <lv-tooltip dm="dM-868"></lv-tooltip>
</ng-template>
<ng-template #strikeCCYSourceTooltip>
  <lv-tooltip dm="dM-869"></lv-tooltip>
</ng-template>
<ng-template #lockInDateSourceTooltip>
  <lv-tooltip dm="dM-872"></lv-tooltip>
</ng-template>
<ng-template #strikeSourceTooltip>
  <lv-tooltip dm="dM-870"></lv-tooltip>
</ng-template>
<ng-template #capRatioSourceTooltip>
  <lv-tooltip dm="dM-871"></lv-tooltip>
</ng-template>
