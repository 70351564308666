<lv-advanced-grid
data-cy="DM-834"
lvId="lvTermsDividendProtectionSchedule"
[columns]="columns"
[records]="dividendProtectionScheduleItems"
[createFormGroup]="createFormGroup"
[parseFn]="parseFn"
sortBy="startDate"
[showXlLabel]="hasScheduleInExcelOverride"
[excelFieldAlias]="excelFieldAlias"
[isFieldFromExcelEnabled]="isFieldFromExcelEnabled"
class="lv-dividend-protection-schedule">
</lv-advanced-grid>
