import { ChangeDetectorRef, OnInit, Directive } from '@angular/core';
import { ApplicationSettingsService } from '@lv-application-settings/application-settings.service';
import { ILvNotifierState } from '@lv-core-ui/services';
import { LvGlComponent, LvGlOnDestroy } from '@lv-golden-layout/models';

import { Subscription } from 'rxjs';


// tslint:disable-next-line:max-line-length
@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class NotificationPanelComponent<T> extends LvGlComponent implements OnInit, LvGlOnDestroy {

  stateId: string;
  state: T;

  private _workspaceRemovedSusbscription: Subscription;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public applicationSettingsService: ApplicationSettingsService
  ) {
    super();

    this._workspaceRemovedSusbscription = this.applicationSettingsService.didRemoveWorkspace
      .subscribe((removedComponetsMap: any) => {
        if (removedComponetsMap[this.stateId]) {
          this.didlvGlOnDestroy();
        }
      });
  }

  ngOnInit() {
    const wgt = this.applicationSettingsService.getWidget(this.stateId);

    if (wgt) {
      this.state = { ...wgt.state } as any;
    }
    this.doOnInit();
  }

  setStateId(stateId: string) {
    this.stateId = stateId;
  }

  saveState(state?: T) {
    if (state) {
      Object.assign(this.state, state);
    }

    this.applicationSettingsService.updateWidget(this.stateId, wgt => {
      wgt.state = {
        ...wgt.state,
        ...this.state
      };
    });
  }

  // Notification Panel Events
  onStateChange(state: ILvNotifierState) {
    this.saveState(state as any);
  }

  onMessageReceived(message: any) {
    this.didReceiveMessage(message);

    this.saveState();

    this.changeDetectorRef.markForCheck();
  }

  // Golden Layout Component Events
  lvGlOnDestroy(): void {
    this.didlvGlOnDestroy();
  }

  unsubscribeFromWorkspaceRemoved() {
    this._workspaceRemovedSusbscription.unsubscribe();
  }

  abstract doOnInit(): any;
  abstract didReceiveMessage(message: any): void;
  abstract didlvGlOnDestroy(): void;
}
