/**
 * Permissions for documents module.
 */
export enum Permissions {
  MODULE = 'DOCUMENTS',
  ALL = 'ALL',
  VIEW = 'VIEW',
  LIST_DOCUMENTS = 'LIST_DOCUMENTS',
  DOWNLOAD = 'DOWNLOAD',
  CONTRIBUTE = 'CONTRIBUTE',
}
