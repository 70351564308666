import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IConvertible } from '../../models/convertible/convertible';
import { LvAnalyticsError } from '../../models/errors';
import { IEstimatesHistory } from '../../models/estimated-ratio/cr-estimates-history';
import { IBasicTerms } from '../../models/basic-terms/basic-terms';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvErrorType, ILvError, LvDataMaster } from '@lv-core-ui/models';

/**
 * Convertibles service.
 */
@Injectable()
export class ConvertiblesService  extends HttpClientBase {

  constructor(
    http: HttpClient
  ) {
    const resourceUrl = '/analytics/convertibles';
    super(http, resourceUrl);
  }

  /**
   * Gets convertible.
   * @param lwsIdentifier LWS identifier.
   * @param sessionId Session ID.
   * @param isPrivateInstrument A flag indicating if instrument is private.
   * @param draftId Draft ID.
   * @returns IConvertible object.
   */
  async getConvertible(lwsIdentifier: string, sessionId: string, isPrivateInstrument: boolean, draftId: string): Promise<IConvertible> {
    try {
      let queryParams = null;
      if (draftId) {
       queryParams =  {
          lwsIdentifier: lwsIdentifier,
          sessionId: sessionId,
          isPrivateInstrument: isPrivateInstrument,
          draftId: draftId
        };
      }
      else {
        queryParams =  {
          lwsIdentifier: lwsIdentifier,
          sessionId: sessionId,
          isPrivateInstrument: isPrivateInstrument
        };
      }
      const result = await this.getAsync<IConvertible>(queryParams, '/analyticsConvertible' );

      if (!result) {
         throw new LvAnalyticsError(LvDataMaster.getError('dM-3025'));
       }

      return result;
    }
    catch (error) {
      if (error.type === LvErrorType.NOT_FOUND) {
        return null;
      }
      throw this.handleError(error, this.handleUnauthorizedError);
    }
  }

  /**
   * Gets estimates history.
   * @param lwsIdentifier LWS identifier.
   * @param sessionId Session ID.
   * @param isPrivateInstrument A flag indicating if instrument is private.
   * @returns IEstimatesHistory object.
   */
  async getEstimatesHistory(lwsIdentifier: string, sessionId: string, isPrivateInstrument: boolean): Promise<IEstimatesHistory[]> {
    try {
      return await this.getAsync<IEstimatesHistory[]>({
        lwsIdentifier: lwsIdentifier,
        sessionId: sessionId,
        isPrivateInstrument: isPrivateInstrument
      }, '/estimatesHistory/');
    }
    catch (error) {
      throw this.handleError(error, this.handleUnauthorizedError);
    }
  }

  /**
   * Gets instrument info.
   * @param lwsIdentifier LWS identifier.
   * @param sessionId Session ID.
   * @param isPrivateInstrument A flag indicating if instrument is private.
   * @param draftId Draft ID.
   * @returns IBasicTerms object.
   */
  async getInstrumentInfo(lwsIdentifier: string, sessionId: string, isPrivateInstrument: boolean, draftId: string): Promise<IBasicTerms> {
    try {
      let queryParams = null;
      if (draftId) {
       queryParams =  {
          lwsIdentifier: lwsIdentifier,
          sessionId: sessionId,
          isPrivateInstrument: isPrivateInstrument,
          draftId: draftId
        };
      }
      else {
          queryParams =  {
            lwsIdentifier: lwsIdentifier,
            sessionId: sessionId,
            isPrivateInstrument: isPrivateInstrument
          };
      }
      const termsSummary = await this.getAsync<IBasicTerms>(queryParams, '/instrumentInfo');
      return termsSummary;
    }
    catch (error) {
      throw this.handleError(error, this.handleUnauthorizedError);
    }
  }

  /**
   * Handles unauthorized error.
   * @param e ILvError object.
   * @returns LvAnalyticsError object.
   */
  private handleUnauthorizedError(e: ILvError): LvAnalyticsError {
    const error = new LvAnalyticsError(e.message);

    if (e.type === LvErrorType.AUTHORIZATION) {
      error.type = e.type;
    }

    return error;
  }
}
