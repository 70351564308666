import { Injectable, Inject, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

import { State } from './util/state';
import { IApplicationSettings, IWorkspaceState, IWidgetState } from './models/application-settings';
import { SettingsService } from './services/settings/settings.service';
import { APPLICATION_INSTANCE_ID, LvDataMaster } from '@lv-core-ui/models';
import { DefaultWidgetType } from '@lv-shared/models';
import { LvSharedDefaultWidgetStateService } from '@lv-shared/services';
import { LvApplicationSettingsError } from './models/errors';
import { environment } from 'src/environments/environment';
import { ILvGlLayoutConfiguration } from '@lv-golden-layout/models';

/**
 * Application settings service that contains methods for managing
 * widgets, workspaces and publishing its events
 */
@Injectable()
export class ApplicationSettingsService extends State implements OnDestroy {

  public didRemoveWorkspace: Subject<any>;
  public didSavedWidgetDefault: Subject<DefaultWidgetType>;
  public didSelectDefaultWidgetState: Subject<void>;

  private readonly INSTRUMENT_MONITOR: string = 'instrument-monitor';
  private readonly ANALYTICS: string = 'analytics';

  private _componentDict: {[key: string]: {
    numberOfAllowedComponents: number,
    errorMessage: string
  }};

  private maxInstrumentMonitorNumber: number;
  private maxInstrumentNumber: number;

  constructor(
    service: SettingsService,
    widgetService: LvSharedDefaultWidgetStateService,
    @Inject(APPLICATION_INSTANCE_ID) applicationInstanceId: string
  ) {
    super(service, widgetService, applicationInstanceId);

    this.didRemoveWorkspace = new Subject<any>();
    this.didSavedWidgetDefault = new Subject<DefaultWidgetType>();
    this.didSelectDefaultWidgetState = new Subject<void>();

    this._componentDict = {};

    this.maxInstrumentMonitorNumber = environment.applicationSettings.numberOfAllowedInstrumentMonitorsPerWorkspace;
    this.maxInstrumentNumber = environment.applicationSettings.numberOfAllowedInstrumentsPerWorkspace;

    this._componentDict[this.INSTRUMENT_MONITOR] = {
      numberOfAllowedComponents: this.maxInstrumentMonitorNumber,
      errorMessage: null
    };

    this._componentDict[this.ANALYTICS] = {
      numberOfAllowedComponents: this.maxInstrumentNumber,
      errorMessage: null
    };
  }

  /**
   * Populate components dictionary errors
   */
  populateComponentDictionary() {
    // tslint:disable-next-line: max-line-length
    this._componentDict[this.INSTRUMENT_MONITOR].errorMessage = LvDataMaster.getError('dM-3346', {'maxInstrumentMonitorNumber': this.maxInstrumentMonitorNumber.toString()});
    // tslint:disable-next-line: max-line-length
    this._componentDict[this.ANALYTICS].errorMessage = LvDataMaster.getError('dM-3345', {'maxInstrumentNumber': this.maxInstrumentNumber.toString()});
  }

  /**
   * Send save default widget type event
   * @param {DefaultWidgetType} type Type of a widget
   */
  publishDidSavedDefault(type: DefaultWidgetType): void {
    this.didSavedWidgetDefault.next(type);
  }

  /**
   * Send select default widget state event
   */
  publishDidSelectDefaultWidgetState(): void {
    this.didSelectDefaultWidgetState.next();
  }

  /**
   * Save current application setting state
   */
  public async saveCurrentState(): Promise<void> {
    try {
      await this.saveState(this.getState());
    }
    catch (error) {
      throw error;
    }
  }

  // REGION Widget

  /**
   * Get widget by id
   * @param {string} widgetId Id of a widget
   * @returns IWidgetState object
   */
  getWidget(widgetId: string): IWidgetState {
    const currentState = this.getState();
    const wgt = currentState.widgets.find(
      (a: IWidgetState) => a.id === widgetId
    );

    return wgt;
  }

  /**
   * Adds widget to application settings state
   * @param {IWidgetState} state IWidgetState object
   */
  addWidget(state: IWidgetState): void {
    this.canAdd(state);

    this.setState(
      (previousState: IApplicationSettings) => {
        const wState = previousState.widgets.find(
          (a: IWidgetState) => a.id === state.id
        );

        if (!wState) {
          previousState.widgets.push(state);
        }
    });
  }

  /**
   * Update widget by id
   * @param {string} widgetId Id of a widget
   * @param update Callback function that accepts IWidgetState as argument
   */
  updateWidget(widgetId: string, update: (widget: IWidgetState) => void): void {
    this.setState(
      (previousState: IApplicationSettings) => {
        const found = previousState.widgets.find(
          (a: IWidgetState) => a.id === widgetId
        );

        if (found) {
          update(found);
        }
    });
  }

  /**
   * Update widget except of one with specified ID
   * @param {string} widgetType Type of a widget
   * @param {any} widgetId Id of a widget
   * @param update Callback function that accepts IWidgetState as argument
   */
  updateOtherWidgets(widgetType: string, widgetId: any, update: (widget: IWidgetState) => void): void {
    this.setState(
      (previousState: IApplicationSettings) => {
        const widgets = previousState.widgets.filter(
          (a: IWidgetState) => a.id !== widgetId && a.widgetType === widgetType
        );

        if (widgets) {
          widgets.forEach(w => update(w));
        }
    });
  }

  /**
   * Remove widget from state
   * @param {string} widgetId Id of a widget
   */
  removeWidget(widgetId: string): void {
    this.setState(
      (previousState: IApplicationSettings) => {
        previousState.widgets = previousState.widgets.filter(a => a.id !== widgetId);
    });
  }

  /**
   * Checks if widget is in workspace
   * @param {string} workspaceId Id of a workspace
   * @param {string} widgetId Id of a widget
   * @returns Boolean value wether widget is in workspace or not
   */
  isWidgetInWorkspace(workspaceId: string, widgetId: string): boolean {
    const currentState = this.getState();
    const cmpState = currentState.widgets.find(
      (a: IWidgetState) => a.workspaceId === workspaceId && a.id === widgetId
    );

    if (cmpState) {
      return true;
    }

    return false;
  }

  // END_REGION Widget

  /**
   * Get golden layout state based on id
   * @param {string} id Id of a goldeen layout state
   * @returns ILvGlLayoutConfiguration object if exists or null if doesn't
   */
  getGoldenLayoutState(id: string): ILvGlLayoutConfiguration | null {
    const currentState = this.getState();
    const wsState = currentState.workspaces.find(
      (a: IWorkspaceState) => a.goldenLayoutState.id === id
    );

    if (wsState) {
      return wsState.goldenLayoutState;
    }

    return null;
  }

  // REGION Workspace

  /**
   * Get workspace by ID
   * @param {string} workspaceId Id of a workspace
   * @returns IWorkspaceState object
   */
  getWorkspace(workspaceId: string): IWorkspaceState {
    const currentState = this.getState();
    const wsState = currentState.workspaces.find(a => a.id === workspaceId);
    return wsState;
  }

  /**
   * Get widgets for specific workspace
   * @param {string} workspaceId Id of a workspace
   * @returns List of IWidgetState objects
   */
  getWorkspaceWidgets(workspaceId: string): IWidgetState[] {
    const currentState = this.getState();
    const widgets = currentState.widgets.filter(a => a.workspaceId === workspaceId);

    return widgets;
  }

  /**
   * Add workspace to application state
   * @param update Callback function that accepts IWorkspaceState as argument
   */
  addWorkspace(update: (workspace: IWorkspaceState) => void): void {
    this.setState(previousState => {
      const newWorkspace: IWorkspaceState = {
        id: null,
        name: null,
        selected: false,
        goldenLayoutState: null
      };

      update(newWorkspace);

      previousState.workspaces.push(newWorkspace);
    });
  }

  /**
   * Update specific workspace
   * @param {string} workspaceId Id of a workspace
   * @param update Callback function that accepts IWorkspaceState as argument
   */
  updateWorkspace(workspaceId: string, update: (workspace: IWorkspaceState) => void): void {
    this.setState(previousState => {
      const found = previousState.workspaces.find(a => a.id === workspaceId);

      if (found) {
        update(found);
      }
    });
  }

  /**
   * Remove specific workspace from state
   * @param {string} workspaceId Id of a workspace
   */
  removeWorkspace(workspaceId: string): void {
    const removedComponetsMap: {
      [componentId: string]: boolean;
    } = {};

    this.setState(previousState => {
      previousState.widgets = previousState.widgets.filter(a => {
        if (a.workspaceId === workspaceId) {
          removedComponetsMap[a.id] = true;
          return false;
        }

        return true;
      });

      previousState.workspaces = previousState.workspaces.filter(a => a.id !== workspaceId);
      previousState.widgets = previousState.widgets.filter(a => a.workspaceId !== workspaceId);
    });

    this.didRemoveWorkspace.next(removedComponetsMap);
  }

  /**
   * Update workspaces
   * @param update Callback function that accepts IWorkspaceState as argument
   */
  updateWorkspaces(update: (workspace: IWorkspaceState) => void): void {
    this.setState(previousState => {
      previousState.workspaces.forEach(w => {
        update(w);
      });
    });
  }

  /**
   * Assign goldenLayoutState to reorderd workspaces and update state
   * @param {string[]} reordered Array of reordered workspace ids
   */
  reorderWorspaces(reordered: string[]): void {
    this.setState(previousState => {
      previousState.workspaces = previousState.workspaces.sort((a, b) => {
        const A = a.id;
        const B = b.id;

        return (reordered.indexOf(A) > reordered.indexOf(B)) ? 1 : -1;
      });
    });
  }

  // END_REGION Workspace
  /**
   * Update terms summary state
   * @param availableItemsList All items available for selection
   * @param selectedItemsList Initial or selected items
   */
  updateTermsSummaryState(availableItemsList: any, selectedItemsList: any) {
    this.setState(previousState => {
      previousState.termSummaryConfig = {
        availableItems: availableItemsList,
        selectedItems: selectedItemsList,
        useDefaultView: true,
        isOpenedFromExcel: false
      };
    });
  }

  /**
   * Lifecycle hook that is triggered immediately before service is destroyed
   */
  ngOnDestroy() {
    this.doDestroy();
  }

  /**
   * Checks if component state can be added
   * @param {IWidgetState} newComponentState New component state
   */
  private canAdd(newComponentState: IWidgetState): void {
    if (newComponentState.widgetType === this.INSTRUMENT_MONITOR || newComponentState.widgetType === this.ANALYTICS) {
      const currentState = this.getState();

      if (currentState.widgets.filter(x => x.workspaceId === newComponentState.workspaceId
        && x.widgetType === newComponentState.widgetType).length
        >= this._componentDict[newComponentState.widgetType].numberOfAllowedComponents) {
          throw new LvApplicationSettingsError(this._componentDict[newComponentState.widgetType].errorMessage);
        }
    }
  }
}
