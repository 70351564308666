<div class="lv-workspace"
  [class.lv-workspace--selected]="state.isSelected"
  [class.lv-workspace--disabled]="state.isDisabled">
  <input #inputElement
    *ngIf="showEditMode"
    [style.width.px]="nameWidth"
    [(ngModel)]="newName"      
    (keypress)="onInputKeyPress($event)"
    (keyup)="onInputKeyUp($event)"
    (blur)="onInputBlur($event)"/>
  <div #nameElement
    *ngIf="!showEditMode"
    class="lv-workspace-name"
    (click)="onSelect()"
    (dblclick)="onEdit()">
    <div>{{ state.name }}</div>
  </div>
  <div *ngIf="showOptions"
    class="lv-workspace-options">
    <i *ngIf="showCloseIcon" 
      class="k-icon k-i-close"
      (click)="onClose()"></i>
  </div>
</div>