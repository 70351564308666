import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, ChangeDetectorRef, ViewChild, HostBinding } from '@angular/core';
import { ILvScenarioReport } from '../lv-scenario.view';
import { LvScenarioChart } from './lv-scenario-chart';

/**
 * Scenario chart component.
 */
@Component({
  selector: 'lv-scenario-chart',
  templateUrl: './lv-scenario-chart.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvScenarioChartComponent {

  @ViewChild('current') chart: LvScenarioChart;

  @Input() report: ILvScenarioReport;

  get hasData(): boolean {
    return !!this.report;
  }

  get isOneDimChart(): boolean {
    return !this.report.isTwoDimensionalScenario && !this.report.isNewIssueScenario;
  }

  get isOneDimPriceTalkChart(): boolean {
    return !this.report.isTwoDimensionalScenario && this.report.isNewIssueScenario;
  }

  get isTwoDimChart(): boolean {
    return this.report.isTwoDimensionalScenario && !this.report.isNewIssueScenario;
  }

  get isTwoDimPriceTalkChart(): boolean {
    return this.report.isTwoDimensionalScenario && this.report.isNewIssueScenario;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.report = null;
  }

  @HostBinding('class.lv-scenario-chart-wrapper')
  get isScenarioChart() {
    return true;
  }

  /**
   * Chart toggle.
   * @param collapsed A flag indicating if chart is collapsed.
   */
  toggle(collapsed: boolean) {
    this.chart.toggle(collapsed);
  }

  /**
   * Rerenders chart.
   */
  rerender() {
    this.chart.rerender();
  }
}
