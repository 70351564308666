import { Injectable } from '@angular/core';
import { ApplicationSettingsService } from '../../../application-settings/application-settings.service';
import { ITermsSummaryState } from '../../../application-settings/models/application-settings';

/**
 * Basic terms settings service.
 */
@Injectable({
  providedIn: 'root'
})
export class BasicTermsSettingsService {

  initialSelectedItems: string[];
  initialAvailableItems: string[];

  constructor(
    private applicationStateService: ApplicationSettingsService,
  ) {
    this.initialSelectedItems = [
      'nominal', 'currencyCode', 'region', 'conversionRatio', 'maturity', 'coupon', 'couponFrequency', 'issueSize',
      'conversionPrice', 'expectedCP', 'couponType', 'put', 'call', 'expectedCR', 'underlying', 'issuerName', 'mandatoryConversion',
      'variableConversion', 'dividendProtection', 'useTakeoverProtection', 'minConversionPrice', 'maxConversionRatio',
      'minExpectedConversionPrice', 'maxExpectedConversionRatio'];

    this.initialAvailableItems = [
      'accrualMethod', 'contigentConversion', 'countryCode', 'protectionType', 'exchangeableType', 'firstCouponPaymentDate',
      'firstSettlement', 'fixedFxRate', 'isin', 'initialPremium', 'issueValue', 'issueSeries', 'percentOfPledgedShares',
      'redemptionValue', 'resettable', 'riskCountry', 'shortName', 'sinkable', 'leversysId'
    ];
  }

  /**
   * Gets basic terms settings.
   * @returns ITermsSummaryState object.
   */
  getBasicTermsSettings(): ITermsSummaryState {
    const appSettings = this.applicationStateService.getState();

    appSettings.termSummaryConfig = appSettings.termSummaryConfig || { availableItems: [], selectedItems: [] } as ITermsSummaryState;

    if (appSettings.termSummaryConfig.availableItems.length === 0 && appSettings.termSummaryConfig.selectedItems.length === 0) {
      this.updateTermsSummaryState(this.initialAvailableItems, this.initialSelectedItems);

      return {
        availableItems: this.initialAvailableItems.map(a => a),
        selectedItems: this.initialSelectedItems.map(a => a)
      } as ITermsSummaryState;
    }

    return appSettings.termSummaryConfig;
  }

  /**
   * Updates terms summary state.
   * @param availableItemsList List of available items.
   * @param selectedItemsList List of selected items.
   */
  updateTermsSummaryState(availableItemsList: any, selectedItemsList: any) {
    this.applicationStateService.updateTermsSummaryState(availableItemsList, selectedItemsList);
  }
}
