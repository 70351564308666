import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, Output,
  EventEmitter, ViewChild, OnDestroy, ChangeDetectorRef, OnChanges, ElementRef, ViewRef, Optional } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { LvVolatilitySurfaceView } from './lv-volatility-surface.view';
import { LvAdvancedGridComponent, LvAdvancedGridColumn, LvAdvancedGridDateColumn } from '@lv-core-ui/components';
import { LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { DateExtensions } from '@lv-core-ui/util';
import { IVolatilitySurface, VolatilitySurfaceDateType, VolatilitySurfacePriceType,
         VolatilitySurfaceSchedule } from '@lv-analytics/models/market-data/volatility';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { MarketDataClipboard } from '@lv-analytics/components/market-data/market-data-clipboard';
import { LvExcelService } from '@lv-excel/services';

/**
 * Volatility surface component.
 */
@Component({
  selector: 'lv-volatility-surface',
  templateUrl: './lv-volatility-surface.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvVolatilitySurfaceComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  @Input() volatilitySurface: IVolatilitySurface;

  @Output() volatilitySurfaceChange: EventEmitter<void>;

  get currencyCode(): string {
    return this._analyticsPresenter.cHelper.isCrossFx ?
      this._analyticsPresenter.cHelper.underlyingCurrencyCode : this._analyticsPresenter.cHelper.currencyCode;
  }

  get dateFormated(): string {
    if (!(this.volatilitySurface && this.volatilitySurface.updateDate)) {
      return '';
    }

    return new Date(this.volatilitySurface.updateDate).toLocaleDateString('en-GB');
  }

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAlias)?.editable;
  }

  columns: LvAdvancedGridColumn[];
  parseFn: any;
  skipRecordOnDeleteFn: (record: VolatilitySurfaceSchedule) => boolean;

  volatilitySurfaceSchedule: VolatilitySurfaceSchedule[]; // Derived from VolatilitySurface

  isHidegMoneyness: boolean;
  view: LvVolatilitySurfaceView;
  limitDate: Date;
  excelFieldAlias = 'VOL_SURF_RANGE';

  private _subscriptions: Subscription[];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _analyticsPresenter: LvAnalyticsPresenter,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.view = new LvVolatilitySurfaceView();

    this.volatilitySurface = {} as IVolatilitySurface;
    this.volatilitySurfaceSchedule = [];
    this.isHidegMoneyness = false;
    this.limitDate = new Date(0);

    // delete all rows that have date field value above 1.1.1970.
    this.skipRecordOnDeleteFn = r => r.date <= this.limitDate;

    this.parseFn = this.parsePastedData.bind(this);

    this.volatilitySurfaceChange = new EventEmitter<void>();
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this.advancedGrid.didDataChange.subscribe((records: VolatilitySurfaceSchedule[]) => this.onDataChange(records)),
      this.advancedGrid.doReload.subscribe(() => this.onReload()),
      this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error)),

      this._analyticsPresenter.onAnalyticsSettingsUpdated.subscribe(evt => {
        if (evt) {
          if (!(this._changeDetectorRef as ViewRef).destroyed) {
            this._changeDetectorRef.detectChanges();
          }
        }
      })
    ];

    this.initColumns();
  }

  /**
   * Occurs on changes.
   */
  ngOnChanges() {
    if (this.hasScheduleInExcelOverride) {
      this.initColumns();
    }
    if (this.volatilitySurface) {
      this.transformAndSetSurfaceSchedule();
    }
    else {
      this.initialize();
    }
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe);
  }

  /**
   * Occurs on change surface setup.
   */
  onChangeSurfaceSetup() {
    this.volatilitySurfaceChange.next();
  }

  /**
   * Occurs on data change.
   * @param records List of VolatilitySurfaceSchedule objects.
   */
  public onDataChange(records: VolatilitySurfaceSchedule[]) {
    this.applyRecords(records);
    this.volatilitySurfaceChange.next();
  }

  /**
   * Applies advanced grid changes.
   */
  applyAdvancedGridChanges() {
    this.advancedGrid.applyChanges(records => this.applyRecords(records));
  }

  /**
   * Occurs on reload.
   */
  public onReload() {
    this.volatilitySurfaceSchedule = this.volatilitySurfaceSchedule.map(x => ({ ...x } as VolatilitySurfaceSchedule));
  }

  /**
   * Handles error.
   * @param error LvError object.
   */
  public onError(error: LvError) {
    this._errorService.handleError(error);
  }

  /**
   * Columns initializations.
   */
  initColumns() {
    this.columns = [];

    const dateColumn = new LvAdvancedGridDateColumn();
    dateColumn.field = 'date';
    dateColumn.title = ' ';
    dateColumn.dmKey = 'DM-621'
    dateColumn.isCellVisible = (rowIndex: number, columnIndex: number) => {
      if (rowIndex === 0 && columnIndex === 0) {
        return false;
      }

      return true;
    };

    dateColumn.isCellPlaceholder = (rowIndex: number, columnIndex: number) => {
      if (rowIndex === 0 && columnIndex === 0) {
        return null;
      }
    };

    this.columns.push(dateColumn);
    this.columns.push(this.view.createNumericColumn('v80', 'DM-622'));
    this.columns.push(this.view.createNumericColumn('v85', 'DM-622'));
    this.columns.push(this.view.createNumericColumn('v90', 'DM-622'));
    this.columns.push(this.view.createNumericColumn('v95', 'DM-622'));
    this.columns.push(this.view.createNumericColumn('v100', 'DM-622'));
    this.columns.push(this.view.createNumericColumn('v105', 'DM-622'));
    this.columns.push(this.view.createNumericColumn('v110', 'DM-622'));
    this.columns.push(this.view.createNumericColumn('v115', 'DM-622'));
    this.columns.push(this.view.createNumericColumn('v120', 'DM-622'));
  }

  /**
   * Creates form group.
   * @param args CreateFormGroupArgs object.
   * @returns FormGroup object.
   */
  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return new FormGroup({
      'date': new FormControl(args.isNew ? new Date() : args.dataItem.date, Validators.required),
      'v80': new FormControl(args.dataItem.v80, Validators.nullValidator),
      'v85': new FormControl(args.dataItem.v85, Validators.nullValidator),
      'v90': new FormControl(args.dataItem.v90, Validators.nullValidator),
      'v95': new FormControl(args.dataItem.v95, Validators.nullValidator),
      'v100': new FormControl(args.dataItem.v100, Validators.nullValidator),
      'v105': new FormControl(args.dataItem.v105, Validators.nullValidator),
      'v110': new FormControl(args.dataItem.v110, Validators.nullValidator),
      'v115': new FormControl(args.dataItem.v115, Validators.nullValidator),
      'v120': new FormControl(args.dataItem.v120, Validators.nullValidator)
    });
  }

  /**
   * Gets volatility tooltip ID.
   * @param element HTML element.
   * @param sectionId Section ID.
   * @returns Volatility tooltip ID.
   */
  public getVolatilityTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('volatility-tooltip-id') === sectionId;
  }

  /**
   * Applies records.
   * @param records List of IVolatilitySurfacePointItem objects.
   */
  private applyRecords(records: any[]) {
    this.volatilitySurface.volatilitySurfacePoints = this.view.mapVolatilitySurfaceSchedule(records);
  }

  /**
   * Parse pasted data records.
   * @param pastedDataRecords List of pasted data records.
   * @returns List of VolatilitySurfaceSchedule objects.
   */
  private parsePastedData(pastedDataRecords: string[]): VolatilitySurfaceSchedule[] {
    const volatilitySurfaceSchedule: VolatilitySurfaceSchedule[] = [];

    volatilitySurfaceSchedule.push(this.volatilitySurfaceSchedule[0]);

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');

      const dateValue = items[0];
      const v80Value = items[1];
      const v85Value = items[2];
      const v90Value = items[3];
      const v95Value = items[4];
      const v100Value = items[5];
      const v105Value = items[6];
      const v110Value = items[7];
      const v115Value = items[8];
      const v120Value = items[9];

      const date = MarketDataClipboard.parseDateValue(dateValue, 'Date');
      const v80 = MarketDataClipboard.parseNumberValue(v80Value, 'v80');
      const v85 = MarketDataClipboard.parseNumberValue(v85Value, 'v85');
      const v90 = MarketDataClipboard.parseNumberValue(v90Value, 'v90');
      const v95 = MarketDataClipboard.parseNumberValue(v95Value, 'v95');
      const v100 = MarketDataClipboard.parseNumberValue(v100Value, 'v100');
      const v105 = MarketDataClipboard.parseNumberValue(v105Value, 'v105');
      const v110 = MarketDataClipboard.parseNumberValue(v110Value, 'v110');
      const v115 = MarketDataClipboard.parseNumberValue(v115Value, 'v115');
      const v120 = MarketDataClipboard.parseNumberValue(v120Value, 'v120');

      volatilitySurfaceSchedule.push({
        date: date,
        dateValue: DateExtensions.getTimeWithOffset(date),
        v80: v80,
        v85: v85,
        v90: v90,
        v95: v95,
        v100: v100,
        v105: v105,
        v110: v110,
        v115: v115,
        v120: v120
      } as VolatilitySurfaceSchedule);
    });

    return volatilitySurfaceSchedule;
  }

  /**
   * Does initialization.
   */
  private initialize() {
    this.volatilitySurface = {
      dateTenorType: VolatilitySurfaceDateType[VolatilitySurfaceDateType.Maturity],
      moneynessStrikeType: VolatilitySurfacePriceType[VolatilitySurfacePriceType.Moneyness],
      useStrikeVol: false,
      updateDate: new Date(),
      volatilitySurfacePoints: []
    } as IVolatilitySurface;

    this.volatilitySurfaceSchedule = [
      this.view.mapSurfacePointItemArguments()
    ];
  }

  /**
   * Transforms and sets surface schedule.
   */
  private transformAndSetSurfaceSchedule() {
    this.volatilitySurfaceSchedule = [];

    if (this.volatilitySurface.volatilitySurfacePoints) {

      const firstDatesValue = this.volatilitySurface.volatilitySurfacePoints.length > 0 ? this.volatilitySurface.volatilitySurfacePoints[0].date : null;
      const argumentValues = firstDatesValue ? this.volatilitySurface.volatilitySurfacePoints.filter(a => a.date === firstDatesValue).map(a => a.argument) : [];

      this.volatilitySurfaceSchedule.push(this.view.mapSurfacePointItemArguments(argumentValues));

      const schedule = this.view.mapVolatilitySurfacePoints(this.volatilitySurface.volatilitySurfacePoints);
      this.volatilitySurfaceSchedule.push(...schedule);
    }
    else {
      this.volatilitySurfaceSchedule.push(this.view.mapSurfacePointItemArguments());
    }
  }
}
