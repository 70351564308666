import { IConstants } from '../models/constants';

export const constants: IConstants = {
  dateFormat: {
    short: 'dd/MM/yyyy'
  },
  numberFormat: {
    twoDigits: '1.2-2',
    threeDigits: '1.3-3',
    fourDigits: '1.4-4',
    sixDigits: '1.6-6',
    upToTwoDigits: '1.0-2',
    upToThreeDigits: '1.0-3',
    upToFourDigits: '1.0-4',
    upToSixDigits: '1.0-6',
    zeroDigits: '1.0-0'
  },
  errors: {
    unexpectedError: 'An unexpected error occurred, please contact the System Administrator.', // DM-1917
    passwordDontMatch: 'Confirmed password doesn\'t match.', // DM-2949
    invalidMfa: 'MFA flow is invalid, please try again.', // DM-2937
    mobilePushFailed: 'MFA mobile push failed (signature).', // DM-3135
    passwordRule: 'Password should contain at least one number, at least one lowercase and one uppercase letter, at least one special character and have a minimum length of 8 characters. The following special characters are supported #?!@$%^&*+-', // DM-2978
  }
};
