import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { PercsConversion } from '@lv-convertible-bond/models/convertible-bond-terms/PERCSConversion';

@Component({
  selector: 'lv-percs-conversion',
  templateUrl: './lv-percs-conversion.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvPercsConversionComponent implements OnInit {
  @Input() model: PercsConversion;
  @Output() didPercsConversionChange: EventEmitter<PercsConversion>;

  formatSix = '#,###.######';
  decimalsSix = '6';

  formatTwo = '#,###.##';
  decimalsTwo = '2';

  constructor() {
    this.didPercsConversionChange = new EventEmitter<PercsConversion>();
  }

  ngOnInit() {
  }

  onPercsConversionChange() {
    this.didPercsConversionChange.next(this.model);
  }
}
