import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Input, HostBinding, ElementRef } from '@angular/core';
import { ICreditSettings, ICreditSectionSettings } from '@lv-analytics/models';

/**
 * Credit settings component.
 */
@Component({
  selector: 'lv-credit-settings',
  templateUrl: './lv-credit-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvCreditSettingsComponent implements OnInit {

  @Input() settings: ICreditSettings;

  constructor() {
    this.settings = {
      systemDefaults: {} as ICreditSectionSettings,
      environments: []
    } as ICreditSettings;
  }

  @HostBinding('class.lv-credit-settings')
  get isLvCreditSettingsComponent() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
    getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
      return element.nativeElement.getAttribute('data-tooltip-id') === id;
    }
}
