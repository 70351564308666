<div
  class="lv-tabular-outputs"
  *ngFor="let tabularOutput of tabularOutputsContent">
  <ng-container *ngIf="isOutputVisible(tabularOutput.type)">
    <lv-flex-box
      direction="row"
      class="lv-tabular-outputs-type">
      <div
        kendoTooltip
        showAfter="1500"
        filter=".lv-label"
        [tooltipTemplate]="tooltipTemplate">
        <div 
          class="lv-label"
          [ngClass]="{'lv-label-before-calculation ': !tabularOutput.value}"
          [attr.tooltip-id]="tabularOutput.type">
          {{tabularOutput.name}}
        </div>
      </div>
    </lv-flex-box>

    <lv-tabular-schedule
      [tabularScheduleContent]="tabularOutput.value"
      [outputType]="tabularOutput.type"
      [showAsPriceAsPar]="showAsPriceAsPar">
    </lv-tabular-schedule>
  </ng-container>
</div>

<ng-template #tooltipTemplate let-anchor>
  <ng-container
    [ngTemplateOutlet]= 'tabularTooltips'
    [ngTemplateOutletContext]="{ $implicit: anchor}">
  </ng-container>
</ng-template>