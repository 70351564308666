import { v4 } from 'uuid';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
  ViewChild, Input, ChangeDetectorRef, Output, EventEmitter, ViewRef, OnDestroy,
  OnChanges, ElementRef, SimpleChanges, Optional } from '@angular/core';

import { Subscription } from 'rxjs';
import { LvSidePanelComponent } from '@lv-core-ui/components';
import { LvErrorService } from '@lv-core-ui/services';
import { LvMath, LvUtil } from '@lv-core-ui/util';
import { CouponType } from '@lv-analytics/models';
import { LvConvertibleBondTermsComponent, PricingSectionCommand } from '@lv-convertible-bond/components';
import { ConvertibleBondTermsDocument, Identifiers, QuickAndFullTermsDocument } from '@lv-convertible-bond/models';
import { ConvertibleBondNewIssueDocument } from '@lv-convertible-bond/models/convertible-bond-terms/ConvertibleBondNewIssueDocument';
import { ConvertibleBondSubType } from '@lv-convertible-bond/models/convertible-bond-terms/ConvertibleBondSubType';
import { ConvertibleBondTermsEvent, IdentifierType, PricingSectionEvent,
         PricingType, SourceType } from '@lv-convertible-bond/models/convertible-bond-terms/custom-enum';
import { SetupStatus } from '@lv-convertible-bond/models/convertible-bond-terms/Enums (5)';
import { ICalculateYieldAndRedemptionRequest } from '@lv-custom-instruments/models';
import { CustomInstrumentsService } from '@lv-custom-instruments/services/custom-instruments.service';
import { TermsSettingsService } from '@lv-custom-instruments/services/terms-settings/terms-settings.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { LvSaveTermsDetailedModalComponent } from './lv-save-terms-detailed-modal/lv-save-terms-detailed-modal.component';
import { IFullTermsDataEvent } from '@lv-custom-instruments/models/full-terms-data-event';
import { LvDataMaster, LvErrorType } from '@lv-core-ui/models';
import { AuthorizationService } from '@lv-core-ui/services/authorization/authorization.service';
import { IResource } from '@lv-core-ui/models/authorization/resource';
import { LvExcelService } from '@lv-excel/services/excel-service';
import { LvExcelSaveModalComponent } from '@lv-excel/components/lv-excel-save-modal/lv-excel-save-modal.component';
import { CustomInstrument } from '@lv-custom-instruments/models/custom-instrument';
import { LvAccessRightsModalComponent } from './lv-access-rights-modal/lv-access-rights-modal.component';
import { AccessScopeType } from '@lv-core-ui/models/enum/access-scope-type';
import { ICustomTermsDetailsData } from '@lv-custom-instruments/models/custom-terms-details-data';
import { RestrictedResourceType } from '@lv-core-ui/models/authorization/restricted-resource-type-enum';
import { getFirstSettlementDate, getMaturityDate } from '@lv-convertible-bond/helpers/date-time-helper';
import * as moment from 'moment';

/**
 * Private instrument event.
 */
export enum PrivateInstrumentEvent {
  InstrumentLoaded = 'InstrumentLoaded',
  DraftSaved = 'DraftSaved',
  InstrumentSaved = 'InstrumentSaved'
}

/**
 * Custom instrument component.
 */
@Component({
  selector: 'lv-custom-instrument',
  templateUrl: './lv-custom-instrument.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCustomInstrumentComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(LvConvertibleBondTermsComponent, { static: true }) convertibleBondTermsComponent: LvConvertibleBondTermsComponent;
  @ViewChild(LvSidePanelComponent, { static: true }) settingsPanel: LvSidePanelComponent;

  @Input() sessionId: string;
  @Input() instrumentIdentifier: string;
  @Input() isPrivateInstrument: boolean;
  @Input() hasFullTermsAccess: boolean;
  @Input() region: string;

  @Output() didPrivateInstrumentChange: EventEmitter<PrivateInstrumentEvent | ConvertibleBondTermsEvent>;
  @Output() didPrivateInstrumentLoad: EventEmitter<QuickAndFullTermsDocument>;
  @Output() didPrivateInstrumentReload: EventEmitter<QuickAndFullTermsDocument>;
  @Output() didDraftReload: EventEmitter<void>;
  @Output() didTermsDetailedSaved: EventEmitter<IFullTermsDataEvent>;
  @Output() didInstrumentNotFoundReturned: EventEmitter<void>;
  @Output() didDraftChanged: EventEmitter<void>;

  originalIdentifier: string;

  get isDraft(): boolean {
    return this._isDraft;
  }

  get draftId(): string {
    return this.isDraft ? this.instrumentIdentifier : null;
  }

  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  isFixDates: boolean;
  isFixDatesVisible: boolean;
  fixDatesId: string;
  updateDatesId: string;
  isLoading: boolean;

  notAuthorized: boolean;
  canSaveTermsDetailed: boolean;

  private _isDraft: boolean;
  private _subscriptions: Subscription[];
  private _originalInstrumentId: string;

  private get _isDraftSaved(): boolean {
    return !!this.instrumentIdentifier;
  }
  private _isDraftSaving: boolean;

  constructor(
    private _service: CustomInstrumentsService,
    private _termsSettings: TermsSettingsService,
    private _errorService: LvErrorService,
    private _dialogService: DialogService,
    private _changeDetector: ChangeDetectorRef,
    private _authorizationService: AuthorizationService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.fixDatesId = v4();
    this.updateDatesId = v4();
    this.isFixDates = false;
    this.isFixDatesVisible = true;

    this.instrumentIdentifier = null;
    this.sessionId = null;
    this._isDraft = false;

    // if this is false draft is needed to be created from reference data convertible
    this.isPrivateInstrument = true;

    this.didPrivateInstrumentChange = new EventEmitter<PrivateInstrumentEvent | ConvertibleBondTermsEvent>();
    this.didPrivateInstrumentLoad = new EventEmitter<QuickAndFullTermsDocument>();
    this.didPrivateInstrumentReload = new EventEmitter<QuickAndFullTermsDocument>();
    this.didTermsDetailedSaved = new EventEmitter<IFullTermsDataEvent>();
    this.didInstrumentNotFoundReturned = new EventEmitter<void>();
    this.didDraftChanged  = new EventEmitter<void>();
    this.didDraftReload = new EventEmitter<void>();

    this.canSaveTermsDetailed = false;
    this.notAuthorized = false;
    this.isLoading = false;
    this._originalInstrumentId = null;
  }

  /**
   * Handles any additional initialization tasks.
   */
  async ngOnInit() {
    this._subscriptions = [
      this.convertibleBondTermsComponent.getConvertibleBondTermsEvent().subscribe(
        event => {
          this.saveDraft(event);
        }
      ),

      this.convertibleBondTermsComponent.getPricingSectionUpdated().subscribe(
        event => {
          if (event.eventId === PricingSectionEvent.IssueYield) {
              this.onIssueYieldUpdate(event.data, event.source);
          }

          if (event.eventId === PricingSectionEvent.Redemption) {
              this.onRedemptionUpdate(event.data, event.source);
          }

          if (event.eventId === PricingSectionEvent.DatesUpdated) {
            this.updateTermsFromSettingsAndSettingsData();
          }
        }
      )
    ];
  }

  /**
   * Handles the changes.
   * @param changes SimpleChanges object.
   */
  ngOnChanges(changes: SimpleChanges) {
    const change = changes['instrumentIdentifier'];
    if (change && change.currentValue !== change.previousValue && (this.hasFullTermsAccess || this.isPrivateInstrument)) {
      this.loadTerms();
      this.originalIdentifier = this.instrumentIdentifier;
    }
  }

  /**
   * Occurs on show settings.
   */
  onShowSettings() {
    this.settingsPanel.togglePanel();
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Occurs on issue yield update.
   * @param type Pricing type.
   * @param sourceType Source type.
   */
  async onIssueYieldUpdate(type: PricingType, sourceType: SourceType) {
    try {
      if (this._isDraftSaved || this.isOpenedFromExcel) {
        this.convertibleBondTermsComponent.applyGridChanges();
        const termsDocument = this.convertibleBondTermsComponent.getQuickAndFullTermsForApi();

        const yieldAndRedemptionRequest = {
          pricingType: type,
          terms: termsDocument.fullTerms
        } as ICalculateYieldAndRedemptionRequest;

        let issueYield = 0;
        if (this.validatePricingSection(yieldAndRedemptionRequest)) {
          issueYield = await this._service.setYieldFromRedemption(yieldAndRedemptionRequest);
        }
        this.convertibleBondTermsComponent.executeCommand(new PricingSectionCommand(type, issueYield, sourceType));

      }
    }
    catch (error) {
      // this._errorService.toastrService.warning(error); hidden message on request of the analytical team
    }
  }

  /**
   * Occurs on redemption update.
   * @param type Pricing type.
   * @param sourceType Source type.
   */
  async onRedemptionUpdate(type: PricingType, sourceType: SourceType) {
    try {
      if (this._isDraftSaved || this.isOpenedFromExcel) {
        this.convertibleBondTermsComponent.applyGridChanges();
        const termsDocument = this.convertibleBondTermsComponent.getQuickAndFullTermsForApi();

        const yieldAndRedemptionRequest = {
          pricingType: type,
          terms: termsDocument.fullTerms
        } as ICalculateYieldAndRedemptionRequest;

        let redemption = 0;
        if (!termsDocument.fullTerms.issueAndRedemption.isPerpetual) {
          redemption = await this._service.setRedemptionFromYield(yieldAndRedemptionRequest);
        }

        this.convertibleBondTermsComponent.executeCommand(new PricingSectionCommand(type, redemption, sourceType));
      }
    }
    catch (error) {
      // this._errorService.toastrService.warning(error); hidden message on request of the analytical team
    }
  }

  /**
   * Occurs on save terms detailed.
   */
  onSaveTermsDetailed() {
    const dialogRef = this._dialogService.open({
      title: 'Save As Copy',
      content: LvSaveTermsDetailedModalComponent,
    });

    const instance = dialogRef.content.instance as LvSaveTermsDetailedModalComponent;
    instance.customTermsDetailsData = {
      instrumentName: this.convertibleBondTermsComponent.getQuickAndFullTermsForApi().fullTerms.issueAndRedemption.name,
      accessScope: AccessScopeType.User
    } as ICustomTermsDetailsData;

    this._subscriptions.push(instance.didSave.subscribe(async (customTermsDetailsData) => {
      await this.onSaveAsCopy(customTermsDetailsData);
      dialogRef.close();
    }));

    this._subscriptions.push(instance.didCancel.subscribe(() => {
      dialogRef.close();
    }));
  }

  /**
   * Open dialog for choosing Access Scope
   * @param accessScopeType - Access Scope
   */
  onSaveAccessRigths(accessScopeType: AccessScopeType, title: string) {
    const dialogRef = this._dialogService.open({
      title: title,
      content: LvAccessRightsModalComponent,
      width: 400,
      height: 164,
    });

    dialogRef.dialog.location.nativeElement.classList.add('lv-access-rights-modal');
    const instance = dialogRef.content.instance as LvAccessRightsModalComponent;
    instance.accessScope = accessScopeType;

    this._subscriptions.push(instance.didSave.subscribe(async (accessScope) => {
      await this.onSave(accessScope as AccessScopeType);
      dialogRef.close();
    }));

    this._subscriptions.push(instance.didCancel.subscribe(() => {
      dialogRef.close();
    }));
  }

  /**
   * Occurs on save terms detailed from Excel.
   */
  onSaveTermsDetailedFromExcel() {
    const dialogRef = this._dialogService.open({
      title: 'Save Destination',
      content: LvExcelSaveModalComponent,
      width: 240,
      height: this._isDraft ? 90 : 180
    });

    dialogRef.dialog.location.nativeElement.classList.add('lv-pricing-save-excel');

    const dialog = dialogRef.content.instance as LvExcelSaveModalComponent;
    dialog.isTermsSave = this._isDraft;

    dialog.doSaveToApplication.subscribe(() => {
      this.onSaveTermsDetailed();
    });

    dialog.doSaveToAll.subscribe(() => {
      this.onSaveTermsDetailed();
      this.saveDataToExcel();
    });

    dialog.doSaveToExcel.subscribe(() => {
      this.saveDataToExcel();
    });
  }

  /**
   * Occurs on save.
   * @param accessScope Access scope type.
   */
  async onSave(accessScope: AccessScopeType = null) {
    try {
      const privateInstrument = await this.preparePrivateInstrumentForSave();

      if (privateInstrument) {
        await LvUtil.timeout(350);

        if (accessScope) {
          privateInstrument.privateInstrumentDocument.accessScope = accessScope;
        }

        const response = await this._service.saveTermsDetailed(privateInstrument);

        this.instrumentIdentifier = response.id;
        this._isDraft = false;

        if (!privateInstrument.leversysId) {

          privateInstrument.leversysId = response.leversysId;

          const identifier: Identifiers = {
            identifier : response.leversysId,
            identifierType: IdentifierType.LeversysID,
          };

          privateInstrument.privateInstrumentDocument.identifiers.push(identifier);
          this.convertibleBondTermsComponent.loadTerms(privateInstrument.privateInstrumentDocument);
          this._changeDetector.detectChanges();
        }

        this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1826'));
        this.didPrivateInstrumentChange.next(PrivateInstrumentEvent.InstrumentSaved);
      }
    }
    catch (error) {
       this._errorService.handleError(error);
    }
  }

  /**
   * Occurs on save instrument.
   */
  onSaveInstrument() {
    if (LvMath.isNotGUIDIdentifier(this.instrumentIdentifier)) {
      this.onSave();
    }
    else {
      this.onSaveAccessRigths(AccessScopeType.User, 'Save Instrument As');
    }
  }

  /**
   * Occurs on save from Excel.
   */
  async onSaveFromExcel() {
    const dialogRef = this._dialogService.open({
      title: 'Save Destination',
      content: LvExcelSaveModalComponent,
      width: 240,
      height: this._isDraft ? 90 : 180
    });

    dialogRef.dialog.location.nativeElement.classList.add('lv-pricing-save-excel');

    const dialog = dialogRef.content.instance as LvExcelSaveModalComponent;

    dialog.isTermsSave = this._isDraft;

    dialog.doSaveToApplication.subscribe(() => {
      this.onSave();
    });

    dialog.doSaveToAll.subscribe(() => {
      this.onSave();
      this.saveDataToExcel();
    });

    dialog.doSaveToExcel.subscribe(() => {
      this.saveDataToExcel();
    });
  }

  /**
   * Save a instrument as copy,
   * @param customTermsDetailsData Custom terms details data.
   */
  async onSaveAsCopy(customTermsDetailsData: ICustomTermsDetailsData) {
    try {
      const privateInstrument = await this.preparePrivateInstrumentForSave();
      privateInstrument.originalInstrumentId = this._originalInstrumentId;

      if (privateInstrument) {
        await LvUtil.timeout(350);
        privateInstrument.privateInstrumentDocument.fullTerms.issueAndRedemption.name = customTermsDetailsData.instrumentName;
        privateInstrument.privateInstrumentDocument.accessScope = customTermsDetailsData.accessScope;

        const response = await this._service.saveTermsDetailedAsCopy(privateInstrument);
        this.instrumentIdentifier = response.id;
        this._isDraft = false;
        this.isPrivateInstrument = true;
        this.onReload();
        this.didPrivateInstrumentChange.next(PrivateInstrumentEvent.InstrumentSaved);
        this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1826'));
      }
    }
    catch (error) {
       this._errorService.handleError(error);
    }
  }

  /**
   * Reload instrument Terms tab.
   * @param skipInstrumentReloadEvent - the parameter is true when we reload instrument after approving notification from LWS.
   * If this parameter is true we will skip emitting reload event.
   */
  async onReload(skipInstrumentReloadEvent: boolean = false) {
    try {

      this.convertibleBondTermsComponent.applyGridChanges();

      if (this.instrumentIdentifier && !this.isPrivateInstrument) {
        this.instrumentIdentifier = this.originalIdentifier;
        const terms = await this.loadTerms();

        if (!this._excelSvc?.isInitialized() && !skipInstrumentReloadEvent) {
          this.didPrivateInstrumentReload.next(terms);
        }
        else {
          this.didDraftReload.next();
        }
      }
      else if (this.instrumentIdentifier && !this._isDraft && this.sessionId) {
        const privateInstrument = await this._service.reloadTermsDetailed(this.instrumentIdentifier, this.sessionId);

        if (privateInstrument.privateInstrumentDocument.fullTerms.issueAndRedemption.setupStatus !== SetupStatus.NewIssue &&
          privateInstrument.privateInstrumentDocument.fullTerms.issueAndRedemption.setupStatus !== SetupStatus.PartiallyCompleted) {
            privateInstrument.privateInstrumentDocument.fixDatesInQuickTermsEntry = false;
            this.isFixDates = false;
            this.isFixDatesVisible = false;
          }
          else {
            this.isFixDatesVisible = true;
            this.isFixDates = privateInstrument.privateInstrumentDocument.fixDatesInQuickTermsEntry;
          }

        privateInstrument.sessionId = this.sessionId;
        this.convertibleBondTermsComponent.loadTerms(privateInstrument.privateInstrumentDocument);
        this.didPrivateInstrumentReload.next(privateInstrument.privateInstrumentDocument);
      }
      else {
        if (this.isOpenedFromExcel) {
          const firstSettlement = getFirstSettlementDate(this._excelSvc?.getFieldValue('FRST_STL'));
          const maturity = getMaturityDate(this._excelSvc?.getFieldValue('MAT'));

          // tslint:disable-next-line: max-line-length
          const cbTerms = await this._termsSettings.createTermsFromSettings(firstSettlement, maturity, false) as ConvertibleBondNewIssueDocument;
          this.convertibleBondTermsComponent.loadTermsFromExcel(cbTerms);
        } 
        else {
          const firstSettlement = getFirstSettlementDate(moment.utc(new Date()).toDate());
          const cbTerms = await this._termsSettings.createTermsFromSettings(firstSettlement, null, true) as ConvertibleBondNewIssueDocument;
          const settings = await this._termsSettings.getTermsSettings();

          this.convertibleBondTermsComponent.createTerms(cbTerms, settings);
          this.instrumentIdentifier = null;
          this.didPrivateInstrumentReload.next(null);
        }
      }
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.detectChanges();
    }
  }

  /**
   * Loads terms detailed.
   * @returns Quick and full terms document.
   */
  async loadTerms(): Promise<QuickAndFullTermsDocument> {
    let result = null;
    try {
      this.isLoading = true;
      this.detectChanges();

      this.canSaveTermsDetailed = await this._authorizationService.hasResourcePermission('TERMS', 'COPY_TERMS',
      {
        region: this.region,
        restrictedResourceType: RestrictedResourceType.Instrument,
        identifier: this.instrumentIdentifier
      } as IResource);

      this.detectChanges();

      if (!this.sessionId) {
        this.sessionId = v4();
      }

      /* loading leversys convertible */
      if (this.instrumentIdentifier && !this.isPrivateInstrument) {

        const instrumentResponse = await this._service.getTermsDetailedFromLvsInstrument(this.instrumentIdentifier, this.sessionId);

        await this.populateTermsSettings(instrumentResponse.instrument);

        instrumentResponse.instrument.sessionId = this.sessionId;
        this._originalInstrumentId = instrumentResponse.instrument.originalInstrumentId;

        this.setCustomInstrument(instrumentResponse.instrument);

        this.instrumentIdentifier = instrumentResponse.draftId;

        result = instrumentResponse.instrument.privateInstrumentDocument;

        this._isDraft = true;
        this._isDraftSaving = false;
      }
      /* loading custom instrument for the existing sessoion id (and draft) */
      else if (this.instrumentIdentifier && this.sessionId && this.isPrivateInstrument) {
        const privateInstrument = await this._service.getTermsDetailed(this.instrumentIdentifier, this.sessionId);
        privateInstrument.sessionId = this.sessionId;
        result = privateInstrument.privateInstrumentDocument;

        await this.populateTermsSettings(privateInstrument);

        this.setCustomInstrument(privateInstrument);
      }
      else {
        this.isFixDates = true;
        if (this.isOpenedFromExcel) {
          const firstSettlement = getFirstSettlementDate(this._excelSvc?.getFieldValue('FRST_STL'));
          const maturity = getMaturityDate(this._excelSvc?.getFieldValue('MAT'));
          // tslint:disable-next-line: max-line-length
          const cbTerms = await this._termsSettings.createTermsFromSettings(firstSettlement, maturity, false) as ConvertibleBondNewIssueDocument;
          const settings = await this._termsSettings.getTermsSettings();
          this.convertibleBondTermsComponent.populateServiceConvertibleBondTermsData(cbTerms, settings);

          this.convertibleBondTermsComponent.loadTermsFromExcel(cbTerms);
        }
        else {
          const firstSettlement = getFirstSettlementDate(moment.utc(new Date()).toDate());
          const cbTerms =
            await this._termsSettings.createTermsFromSettings(firstSettlement, null, true) as ConvertibleBondNewIssueDocument;
          const settings = await this._termsSettings.getTermsSettings();

          this.convertibleBondTermsComponent.populateServiceConvertibleBondTermsData(cbTerms, settings);

          this.convertibleBondTermsComponent.createTerms(cbTerms, settings);
        }
      }
    }
    catch (error) {
      if (error.type !== LvErrorType.AUTHORIZATION && error.type !== LvErrorType.NOT_FOUND) {
        this._errorService.handleError(error);
      }

      if (error.type === LvErrorType.NOT_FOUND) {
        this.didInstrumentNotFoundReturned.next();
      }
    }
    finally {
      this.isLoading = false;
      this.detectChanges();
    }

    return result;
  }

  /**
   * Refresh terms from settings and settings data
   */
  async updateTermsFromSettingsAndSettingsData() {
    if (this.instrumentIdentifier && !this.isPrivateInstrument) {
      const instrumentResponse = await this._service.getTermsDetailed(this.instrumentIdentifier, this.sessionId);
      await this.populateTermsSettings(instrumentResponse);
    }
  }

  /**
   * Populate terms from settings and and settings data
   * @param instrument Custom instrument data
   */
  private async populateTermsSettings(instrument: CustomInstrument) {
    const firstSettlement = getFirstSettlementDate(instrument.privateInstrumentDocument.fullTerms.issueAndRedemption.firstSettlementDate);
    const maturity = getMaturityDate(instrument.privateInstrumentDocument.fullTerms.issueAndRedemption.maturityDate);
    const isNewIssue = instrument.privateInstrumentDocument.fullTerms.issueAndRedemption.subType === ConvertibleBondSubType.PEPS;

    const cbTerms = await this._termsSettings.createTermsFromSettings(firstSettlement, maturity, isNewIssue) as ConvertibleBondNewIssueDocument;
    const settings = await this._termsSettings.getTermsSettings();

    this.convertibleBondTermsComponent.populateServiceConvertibleBondTermsData(cbTerms, settings);
  }

  /**
   * Clears draft.
   * @param identifier Draft identifier.
   */
  async clearDraft(identifier: string) {
    if (identifier && this.sessionId) {
      await this._service.clearDraft(identifier, this.sessionId);
      this._isDraft = false;
    }
  }

  /**
   * Saves data to Excel.
   */
  private async saveDataToExcel() {
    if (this._excelSvc.isV3()) {
      await this.saveDraft(ConvertibleBondTermsEvent.OtherDataUpdated, true);
      const fields = this._excelSvc.getMappedFieldsForSave('Terms');
      await this._excelSvc.saveDataToExcelV2(fields,
        this.sessionId,
        this.instrumentIdentifier,
        'Terms',
        this.draftId ?? this.draftId,
        this.convertibleBondTermsComponent.isQuickTermsVisible,
        this.convertibleBondTermsComponent.isCrossFx);
    }
    else {
      this._excelSvc.saveDataToExcel(
        this.convertibleBondTermsComponent.getMappedExcelFieldsFromExcel()
      );
    }
  }

  /**
   * Validates draft terms.
   * @param terms ConvertibleBondNewIssueDocument object.
   * @returns A flag indicating if terms are valid.
   */
  private validateDraftTerms(terms: ConvertibleBondNewIssueDocument): boolean {
    let isValid: boolean;
    isValid = true;

    if (!terms.issueAndRedemption.name) {
      isValid = false;
    }
    if (!terms.issueAndRedemption.currencyCode) {
      isValid = false;
    }
    if (terms.issueAndRedemption.underlyingEquity && !terms.issueAndRedemption.underlyingEquity.currencyCode) {
      isValid = false;
    }
    if (!terms.issueAndRedemption.maturityDate && !terms.issueAndRedemption.isPerpetual) {
      isValid = false;
    }

    return isValid;
  }

  /**
  * Saves draft.
  * @param event Convetible bond terms event.
  */
  async saveDraft(event?: ConvertibleBondTermsEvent, forceUpdate: boolean = false) {
    // Update draft for all events that are different from OtherDataUpdated if forceUpdate is not true.
    // OtherDataUpdated is the most frequent event so we want to update draft for that event only when tab is changed.
    if (!this._isDraftSaved || (forceUpdate && event === ConvertibleBondTermsEvent.OtherDataUpdated) || event !== ConvertibleBondTermsEvent.OtherDataUpdated) {
      try {
        this.convertibleBondTermsComponent.applyGridChanges();
        const terms = this.convertibleBondTermsComponent.getQuickAndFullTermsForApi();
        if (terms.fullTerms.issueAndRedemption.setupStatus !== SetupStatus.NewIssue &&
            terms.fullTerms.issueAndRedemption.setupStatus !== SetupStatus.PartiallyCompleted) {
              terms.fixDatesInQuickTermsEntry = false;
              this.isFixDates = false;
              this.isFixDatesVisible = false;
            }
            else {
              this.isFixDatesVisible = true;
              terms.fixDatesInQuickTermsEntry = this.isFixDates;
            }
  
        if (this.validateDraftTerms(terms.fullTerms)) {
          if (this._isDraftSaved) {
            await this.updateDraft(terms);
          }
          else {
            await this.createDraft(terms);
          }
  
          if (event) {
            this.didPrivateInstrumentChange.next(event);
          }
        }
      }
      catch (error) {
        this._errorService.handleError(error);
      }
    }
    else {
      // Notify instrument component that draft was updated.
      this.didDraftChanged.next();
    }
  }

  /**
   * Prepares custom instrument for save.
   * @returns CustomInstrument object.
   */
  private preparePrivateInstrumentForSave(): CustomInstrument {
    this.convertibleBondTermsComponent.applyGridChanges();
    const privateInstrument = new CustomInstrument();
    privateInstrument.privateInstrumentDocument = this.convertibleBondTermsComponent.getQuickAndFullTermsForApi();

    if (privateInstrument.privateInstrumentDocument.fullTerms.issueAndRedemption.setupStatus !== SetupStatus.NewIssue &&
        privateInstrument.privateInstrumentDocument.fullTerms.issueAndRedemption.setupStatus !== SetupStatus.PartiallyCompleted) {
      this.isFixDates = false;
    }

    privateInstrument.privateInstrumentDocument.fixDatesInQuickTermsEntry = this.isFixDates;

    const termsDoc = Object.assign(new ConvertibleBondTermsDocument(), privateInstrument.privateInstrumentDocument.fullTerms);
    const validationData = termsDoc.validateTerms();
    if (validationData[0]) {

      privateInstrument.id = this.instrumentIdentifier ? Number(this.instrumentIdentifier) : 0;
      privateInstrument.draftId = this.instrumentIdentifier;
      privateInstrument.sessionId = this.sessionId;

      return privateInstrument;

    } else {
      this._errorService.toastrService.warning(validationData[1]);
      return null;
    }
  }


  /**
   * Creates draft.
   * @param terms QuickAndFullTermsDocument object.
   */
  private async createDraft(terms: QuickAndFullTermsDocument) {
    try {
      if (!this._isDraftSaving) {
        this._isDraftSaving = true;

        this.instrumentIdentifier = await this._service.createDraft({
          sessionId: this.sessionId,
          terms: terms
        });

        this.didPrivateInstrumentChange.next(PrivateInstrumentEvent.DraftSaved);

        this._isDraft = true;
        this._isDraftSaving = false;
      }
    }
    catch (error) {
      this._isDraftSaving = false;
      throw error;
    }
  }

  /**
   * Updates draft.
   * @param terms QuickAndFullTermsDocument object.
   */
  private async updateDraft(terms: QuickAndFullTermsDocument) {
    try {
      await this._service.updateDraft({
        draftId: this.instrumentIdentifier,
        sessionId: this.sessionId,
        terms: terms
      });
    }
    catch (error) {
      throw error;
    }
  }

  /**
   * Validates pricing section.
   * @param request ICalculateYieldAndRedemptionRequest object.
   * @returns A flag indicating if pricing section is valid.
   */
  private validatePricingSection(request: ICalculateYieldAndRedemptionRequest): boolean {
    let isValid = true;
    const terms = request.terms;
    const type = request.pricingType;

    if (terms.issueAndRedemption.subType === ConvertibleBondSubType.PEPS) {
      isValid = false;
    }
    if (!terms.issueAndRedemption.maturityDate) {
      isValid = false;
    }
    if (terms.coupon && terms.coupon.type === CouponType.Floating) {
        isValid = false;
    }
    if (type === PricingType.Best && terms.priceTalk.redemptionValueBest && terms.priceTalk.redemptionValueBest === 0) {
      isValid = false;
    }
    if (type === PricingType.Worst && terms.priceTalk.redemptionValueWorst && terms.priceTalk.redemptionValueWorst === 0) {
      isValid = false;
    }

    return isValid;
  }

  /**
   * Detects changes.
   */
  private detectChanges() {
    if (!(this._changeDetector as ViewRef).destroyed) {
      this._changeDetector.detectChanges();
    }
  }

  /**
   * Sets custom instrument.
   * @param customInstrument CustomInstrument object.
   */
  private setCustomInstrument(customInstrument: CustomInstrument, termsSettingsDocument?: ConvertibleBondTermsDocument) {
    if (customInstrument.privateInstrumentDocument.fullTerms.issueAndRedemption.setupStatus !== SetupStatus.NewIssue &&
      customInstrument.privateInstrumentDocument.fullTerms.issueAndRedemption.setupStatus !== SetupStatus.PartiallyCompleted) {

      customInstrument.privateInstrumentDocument.fixDatesInQuickTermsEntry = false;
      this.isFixDates = false;
      this.isFixDatesVisible = false;
    }
    else {
      this.isFixDatesVisible = true;
      this.isFixDates = customInstrument.privateInstrumentDocument.fixDatesInQuickTermsEntry;
    }

    this.convertibleBondTermsComponent.loadTerms(customInstrument.privateInstrumentDocument);

    this.didPrivateInstrumentLoad.next(customInstrument.privateInstrumentDocument);
  }

  /**
   * Gets custom instrument tooltip ID.
   * @param element HTML element.
   * @param sectionId Section ID.
   * @returns Section ID.
   */
  getPrivateInstrumentTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
}

