

import { Frequency } from './Frequency';
import { PutScheduleItem } from './PutScheduleItem';
import { PutValueType } from './Enums (7)';
import { NoticePeriod } from './PutNoticePeriod';
import { DayCount } from './CouponDayCount';

export class Put {
    
    valueType: PutValueType;
    yieldFrequency: Frequency;
    keepAccrued: boolean;
    forfeitCoupon: boolean;
    notice: number;
    noticePeriod: NoticePeriod;
    yieldDaysCount: DayCount;
    puttableDuringPeriod: boolean;
    scheduleItems: PutScheduleItem[];
    partialPut: boolean
    currentNotionalPct: number;
    totalTranchePct: number;
}
