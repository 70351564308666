import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef,  AfterViewInit } from '@angular/core';

@Component({
  selector: 'image-logo',
  templateUrl: './image-logo.html', 
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageLogoComponent implements AfterViewInit  {
  @Input() imageSrc: string = '';

  constructor(private ref: ChangeDetectorRef){  }
  
  ngAfterViewInit() {
    // Detach this component from the change detection tree after initialization
    this.ref.detach();
  }
}