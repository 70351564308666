<lv-flex-box
  direction="row"
  data-cy="DM-1474">

  <div class="lv-label lv-label-field"
       showAfter="1500"
       kendoTooltip
       [tooltipTemplate]="borrowSourceSettingsTooltip"
       filter="div">
        Borrow Source
  </div>
  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      name="cbBorrowSource"
      valueField="id"
      textField="text"
      [data]="borrowSourceEnum.items"
      [valuePrimitive]="true"
      [(ngModel)]="settings.borrowSource"
      [disabled]="!overrideSystemDefaults">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<ng-template #borrowSourceSettingsTooltip>
  <lv-tooltip dm="dM-1474"></lv-tooltip>
</ng-template>