import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IBaseTermsSettings, ICallSectionSettings } from '@lv-convertible-bond/models';

@Component({
  selector: 'lv-call-settings',
  templateUrl: './lv-call-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvCallSettingsComponent implements OnInit {

  @Input() systemDefaults: IBaseTermsSettings<ICallSectionSettings>;
  @Input() myDefaults: IBaseTermsSettings<ICallSectionSettings>;

  constructor() { }

  @HostBinding('class.lv-call-settings')
  get isLvCallSettingsComponent() {
    return true;
  }

  ngOnInit() {
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
   getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }

}
