import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, Input } from '@angular/core';
import { LvScenarioTwoDimPriceTalkGridView } from './lv-scenario-two-dim-price-talk-grid.view';
import { ILvScenarioReport } from '../../lv-scenario.view';
import { ScenarioOutput } from '@lv-analytics/models';

/**
 * Scenario two dim price talk grid component.
 */
@Component({
  selector: 'lv-scenario-two-dim-price-talk-grid',
  templateUrl: './lv-scenario-two-dim-price-talk-grid.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvScenarioTwoDimPriceTalkGridComponent implements OnChanges {

  @Input() report: ILvScenarioReport;

  view: LvScenarioTwoDimPriceTalkGridView;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.view = new LvScenarioTwoDimPriceTalkGridView();
    this.view.init();
  }

  /**
   * Does view initialization.
   */
  ngOnChanges() {
    this.view.init(this.report);
  }

  /**
   * Occurs on current output click.
   * @param output Scenario output.
   */
  onCurrentOutputClick(output: ScenarioOutput) {
    this.view.model.currentOutput = output;
    this.changeDetectorRef.detectChanges();
  }
}
