<lv-flex-box class="lv-private-instrument">
  <lv-loader-panel *ngIf="isLoading"></lv-loader-panel>

  <lv-flex-box direction="row">
    <div class="lv-environment-settings-controls" kendoTooltip data-cy="DM-1220">
      <div *ngIf="!isPrivateInstrument && isOpenedFromExcel"
        class="lv-icon-square"
        [class.lv-icon-disabled]="!canSaveTermsDetailed"
        title="Save"
        lvId="onSaveTermsDetailed"
        (click)="onSaveTermsDetailedFromExcel()">
        <i class="k-icon k-i-save"></i>
      </div>
      <div *ngIf="!isPrivateInstrument && !isOpenedFromExcel"
        class="lv-icon-square"
        [class.lv-icon-disabled]="!canSaveTermsDetailed"
        title="Save"
        lvId="onSaveTermsDetailed"
        (click)="onSaveTermsDetailed()">
        <i class="k-icon k-i-save"></i>
      </div>
      <div *ngIf="isPrivateInstrument && isOpenedFromExcel"
        class="lv-icon-square"
        title="Save"
        lvId="privateInstrumentSaveAll"
        (click)="onSaveFromExcel()">
        <i class="k-icon k-i-save"></i>
      </div>
      <div *ngIf="isPrivateInstrument && !isOpenedFromExcel"
        class="lv-icon-square"
        title="Save"
        lvId="privateInstrumentSaveAll"
        (click)="onSaveInstrument()">
        <i class="k-icon k-i-save"></i>
      </div>
      <div class="lv-icon-square"
        title="Reload"
        lvId="privateInstrumentReloadAll"
        (click)="onReload(true)">
        <i class="k-icon k-i-reload"></i>
      </div>
      <div class="lv-dates-logic" lvId="privateInstrumentSettings">
        <i class="k-icon k-i-gear"
          (click)="onShowSettings()">
        </i>
    </div>
  </div>
</lv-flex-box>
<lv-flex-box>

  <lv-convertible-bond-terms class="lv-private-instrument-convertible-bond-terms"
    [isPrivateInstrument]="isPrivateInstrument">
  </lv-convertible-bond-terms>

  <lv-side-panel
    width="246"
    height="74"
    position="right"
    [floating]="true">
    <ng-container>
      <div *ngIf="isFixDatesVisible" class="lv-side-panel-link-item"
            lvId="lv-tools-display-fixed-dates">
        <input class="k-radio"
          type="radio"
          [attr.id]="fixDatesId"
          [attr.name]="fixDatesId"
          [value]="true"
          [(ngModel)]="isFixDates"
          (ngModelChange)="onSave()">
        <label class="k-radio-label"
          [attr.for]="fixDatesId"
          lvId="lv-quick-terms-fix-dates"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="privateInstrumentTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'rollingDatesSourceTooltip'">
            Rolling Dates
          </span>   
        </label>
      </div>

      <div class="lv-side-panel-link-item"
          lvId="lv-tools-display-update-dates">
        <input class="k-radio"
          type="radio"
          [attr.id]="updateDatesId"
          [attr.name]="updateDatesId"
          [value]="false"
          [(ngModel)]="isFixDates"
          (ngModelChange)="onSave()">
        <label class="k-radio-label"
          [attr.for]="updateDatesId"
          lvId="lv-quick-terms-update-dates"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="privateInstrumentTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'fixedDatesSourceTooltip'">
            Fixed Dates
          </span>   
        </label>
      </div>
    </ng-container>
  </lv-side-panel>
</lv-flex-box>

<ng-template #privateInstrumentTooltipTemplate let-anchor>
  <div class="popup-private-instrument-content">
    <ng-container *ngIf="getPrivateInstrumentTootlipId(anchor, 'rollingDatesSourceTooltip')">
      Dates will be rolled as time passes, using today as the first settlement date.
    </ng-container>
    <ng-container *ngIf="getPrivateInstrumentTootlipId(anchor, 'fixedDatesSourceTooltip')">
      Dates will be fixed as time passes.
    </ng-container>
  </div>
</ng-template>
