import { Injectable } from '@angular/core';
import { ILvHubClient, LvHub } from '@lv-core-ui/util';

import { Observable, Subject } from 'rxjs';

import { ICustomValuationSettingsUpdatedEvent } from './events/custom-valuation-settings-updated-event';
import { ICustomYieldCurveUpdatedEvent } from './events/custom-yield-curve-updated-event';
import { IInstrumentEnvironmentSettingsUpdatedEvent } from './events/instrument-environment-settings-updated-event';
import { IOtherSettingsUpdatedEvent } from './events/instrument-settings-updated-event';

/**
 * Analytics hub service.
 */
@Injectable()
export class AnalyticsHubService implements ILvHubClient {

  private _valuationSettingsUpdatedEvent: Subject<ICustomValuationSettingsUpdatedEvent>;
  private _customYieldCurveUpdatedEvent: Subject<ICustomYieldCurveUpdatedEvent>;
  private _instrumentEnvironmentSettingsUpdatedEvent: Subject<IInstrumentEnvironmentSettingsUpdatedEvent>;
  private _otherSettingsUpdatedEvent: Subject<IOtherSettingsUpdatedEvent>;

  constructor() {
    this._valuationSettingsUpdatedEvent = new Subject<ICustomValuationSettingsUpdatedEvent>();
    this._customYieldCurveUpdatedEvent = new Subject<ICustomYieldCurveUpdatedEvent>();
    this._instrumentEnvironmentSettingsUpdatedEvent = new Subject<IInstrumentEnvironmentSettingsUpdatedEvent>();
    this._otherSettingsUpdatedEvent = new Subject<IOtherSettingsUpdatedEvent>();
  }

  get valuationSettingsUpdatedEvent(): Observable<ICustomValuationSettingsUpdatedEvent> {
    return this._valuationSettingsUpdatedEvent;
  }

  get customYieldCurveUpdatedEvent(): Observable<ICustomYieldCurveUpdatedEvent> {
    return this._customYieldCurveUpdatedEvent;
  }

  get instrumentEnvironmentSettingsUpdatedEvent(): Observable<IInstrumentEnvironmentSettingsUpdatedEvent> {
    return this._instrumentEnvironmentSettingsUpdatedEvent;
  }

  get otherSettingsUpdatedEvent(): Observable<IOtherSettingsUpdatedEvent> {
    return this._otherSettingsUpdatedEvent;
  }

  /**
   * Attaches events.
   * @param hub LvHub object.
   */
  attachEvents(hub: LvHub): void {
    hub.subscribeOn('valuationSettingsUpdatedEvent', this._valuationSettingsUpdatedEvent);
    hub.subscribeOn('customYieldCurveUpdatedEvent', this._customYieldCurveUpdatedEvent);
    hub.subscribeOn('instrumentEnvironmentSettingsUpdatedEvent', this._instrumentEnvironmentSettingsUpdatedEvent);
    hub.subscribeOn('otherSettingsUpdatedEvent', this._otherSettingsUpdatedEvent);
  }
}
