import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ConvertibleClassificationDescription, RoleOrganisationDescription } from '@lv-convertible-bond/models';
import { Subscription } from 'rxjs';
import { LvConvertibleBondTermsPresenter } from '..';
import { LvOtherView } from './lv-other.view';
import { constants } from '@lv-core-ui/util';
import { IConstants } from '@lv-core-ui/models';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';

@Component({
  selector: 'lv-other',
  templateUrl: './lv-other.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvOtherComponent implements OnInit, OnDestroy {

  public view: LvOtherView;
  public convertibleClassificationDescription = ConvertibleClassificationDescription;
  public roleOrganisationDescription = RoleOrganisationDescription;
  public constants: IConstants;
  private _modelSubscription: Subscription;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvConvertibleBondTermsPresenter,
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService
  ) {
    this.view = new LvOtherView(_lvConvertibleBondTermsService);
    this.constants = constants;
  }

  ngOnInit() {
    this._modelSubscription = this._presenter.onModelUpdated
    .subscribe(event => {
      if (event.data) {
        this.view.init(event.data.fullTerms.other);
        this._changeDetectorRef.detectChanges();
      }
    });

    if (this._presenter.isModelLoaded()) {
      const fullTerms = this._presenter.getModel().fullTerms;
      this.view.init(fullTerms.other);
      this._changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this._modelSubscription.unsubscribe();
  }
}
