import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild,
  OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { LvGoldenLayoutComponent } from '@lv-golden-layout/components/lv-golden-layout/lv-golden-layout.component';
import { ILvGlLayoutConfiguration, ILvGoldenLayoutError, ILvGoldenLayoutComponent } from '@lv-golden-layout/models';
import { LvGoldenLayoutService } from '@lv-golden-layout/services';
import { LvAuthenticationError } from '@lv-core-ui/models';
import { LvNotificationManagerService, LvErrorService, LvLoggerService } from '@lv-core-ui/services';
import { ApplicationSettingsService } from '@lv-application-settings/application-settings.service';
import { IWorkspaceState } from '@lv-application-settings/models';
import { RouteCacheService } from '@lv-cache/route-cache';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'workspace-content',
  templateUrl: './workspace-content.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    LvNotificationManagerService
  ]
})
export class WorkspaceContentComponent implements OnInit, OnDestroy {

  @ViewChild(LvGoldenLayoutComponent) goldenLayoutComponent: LvGoldenLayoutComponent;

  get loaderVisible(): boolean {
    return this.isLoading;
  }

  get layoutVisible(): boolean {
    if (this.isLoading) {
      return false;
    }

    return !!this.state;
  }

  id?: string;
  state?: IWorkspaceState;
  isLoading: boolean;

  private _subscriptions: Subscription[];

  constructor(
    private _changeDetectionRef: ChangeDetectorRef,
    private _activatedRoute: ActivatedRoute,
    private _errorService: LvErrorService,
    private _applicationSettingsService: ApplicationSettingsService,
    private _routeCacheService: RouteCacheService,
    private _goldenLayoutService: LvGoldenLayoutService,
    private _logger: LvLoggerService
  ) {}

  ngOnInit() {
    this._subscriptions = [
      this._activatedRoute.paramMap.subscribe(
        params => {
          try {
            this.isLoading = true;
            this._changeDetectionRef.markForCheck();

            const id = params.get('id');
            this.id = id;
            this.state = this._applicationSettingsService.getWorkspace(id);

            this.isLoading = false;
            this._changeDetectionRef.markForCheck();
          }
          catch (error) {
            this._errorService.handleError(new LvAuthenticationError());
          }
        }
      ),

      this._routeCacheService.workspaceReatached.subscribe(
        wsId => {
          if (this.id === wsId) {
            this._goldenLayoutService.registerLayoutContainer(this.goldenLayoutComponent);
          }
      })
    ];
  }

  onGoldenLayoutStateChange(configuration: ILvGlLayoutConfiguration) {
    this._applicationSettingsService.updateWorkspace(configuration.id,
      w => {
        w.goldenLayoutState.layoutConfig = { ...configuration.layoutConfig };
      }
    );
  }

  onGoldenLayoutError(error: ILvGoldenLayoutError) {
    setTimeout(() => {
      // this._errorService.handleError(new LvUserFriendlyError(error.message, error.name));
      this._errorService.unexpectedError.next({ ...error });
    }, 100);
  }

  onGoldenLayoutItemDestroyed(item: ILvGoldenLayoutComponent) {
    let container = item.container;

    if (!container) {
      container = (item as any).origin.container;
    }

    this._logger.log(`Component '${container.lvComponentId}' removed from '${this.state.id}' workspace`);
  }

  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }
}
