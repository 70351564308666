import { AnalyticsSettingsHelper, ConvertibleHelper } from '@lv-analytics/helpers';
import { Outputs, IOutputsConfiguration, IAnalyticsSettings, IValuationResult, OutputsLabel,
         OutputsPercentOfPar,
         CreditSource,
         CreditModelFactor,
         VolatilityType} from '@lv-analytics/models';
import { ILvOutput } from '@lv-analytics/models/outputs/lv-output';
import { PutValueType } from '@lv-convertible-bond/models';

export const getDefaultOutputsConfiguration = (configuration?: IOutputsConfiguration): IOutputsConfiguration => {
  const oc = {
    customOutputs: [],
    outputs: [],
    useDefault: false
  } as IOutputsConfiguration;

  if (configuration) {
    oc.customOutputs = configuration.customOutputs.map(a => a);
    oc.outputs = configuration.outputs.map(a => a);
    oc.useDefault = configuration.useDefault;
  }
  return oc;
};

/**
 * Outputs view.
 */
export class LvOutputsWidgetView {

  get showAsPriceAsParVisible(): boolean {
    if (!this._cHelper.isValid) {
      return false;
    }

    return !this._cHelper.isPriceAsPar;
  }

  get isPeps(): boolean {
    if (!this._cHelper) {
      return false;
    }

    return this._cHelper.isPeps;
  }

  regularOutputs: ILvOutput[];
  tabularOutputs: ILvOutput[];
  showAsPriceAsPar: boolean;
  configuration: IOutputsConfiguration;
  asHelper: AnalyticsSettingsHelper;

  private _cHelper: ConvertibleHelper;

  private putsDependant: string[] = [Outputs.aSWSpreadNextPut, Outputs.yieldToPut, Outputs.yearsToPut, Outputs.zSpreadNextPut];
  private callDendant: string[] = [Outputs.yieldToCall, Outputs.yearsToCall, Outputs.zSpreadCall];
  private callTriggerDependant: string[] = [Outputs.probBreachCallTrigger, Outputs.currentEffectiveCallTrigger];
  private pepsDependant: string[] = [
    Outputs.carryValue
  ];
  private mandatoryConversionDependant: string[] = [Outputs.yieldToMaturity, Outputs.redemptionValue];
  private fixedFxDependant: string[] = [
    Outputs.rhoUndImplVol,
    Outputs.rhoUndImplSpread,
    Outputs.rhoUnd,
    Outputs.fXDelta,
    Outputs.deltaFXImplVol,
    Outputs.deltaFXImplSpread
  ];
  private couponDependant: string[] = [Outputs.accruedInterest, Outputs.accruedDays];
  private makeWholeDependant: string[] = [Outputs.remainingMW];
  private issuePriceDependant: string[] = [Outputs.accretedValue, Outputs.accretingYield];
  private flatSpreadAndFactorModel2Dependant: string[] = [
    Outputs.creditVega,
    Outputs.creditVegaImplVol,
    Outputs.creditVegaImplSpread
  ];
  private isExchangeableDependant: string[] = [
    Outputs.cSSensImplVol,
    Outputs.cSSensImplSpread,
    Outputs.cSSens
  ];
  private upsideDownsideVolatilityAndPepsDependant: string[] = [
    Outputs.upImpVol,
    Outputs.dnImpVol,
    Outputs.higherCall,
    Outputs.lowerCall,
    Outputs.fwdStock,
    Outputs.skewPerThen,
    Outputs.totalSkew
  ];

  private crossFxDependant: string[] = [Outputs.forwardFXRate];

  private tabularPutsDependant: string[] = [Outputs.accretedPutValue];
  private tabularPVPutsDependant: string[] = [Outputs.presentValuePartialPuts];

  constructor() {
    this.asHelper = new AnalyticsSettingsHelper();
    this._cHelper = new ConvertibleHelper();
  }

  /**
   * Fields initialization.
   * @param analyticsSettings IAnalyticsSettings object.
   */
  init(analyticsSettings?: IAnalyticsSettings) {
    this.asHelper.init(analyticsSettings);
    this._cHelper.init(this.asHelper.convertible);

    this.configuration = getDefaultOutputsConfiguration(this.asHelper.outputsConfiguration);
    this.regularOutputs = [];
    this.showAsPriceAsPar = false;

    this.calculateOutputs({
      outputs: {},
      warning: []
    });
  }

  /**
   * Calculates outputs.
   * @param valuationResult IValuationResult object.
   */
  calculateOutputs(valuationResult: IValuationResult): any {
    let usedOutputs = this.configuration.outputs;

    if (!this.configuration.useDefault) {
      usedOutputs = this.configuration.customOutputs;
    }

    this.regularOutputs = usedOutputs
      .filter(x => x.isSelected && this.shouldDisplayOutput(Outputs[x.type]) && !x.isTabular)
      .map(a => {
        const mapped = {
          type: a.type,
          name: Outputs[a.type],
          value: valuationResult.outputs[a.type],
          suffix: OutputsLabel[a.type]
        } as ILvOutput;

        if (OutputsLabel[a.type] === 'pts' && !this._cHelper.isPriceAsPar) {
          mapped.suffix = this._cHelper.currencyCode;
        }

        if (Outputs[a.type] === Outputs.currentNominalValue.toString()) {
          mapped.suffix = this._cHelper.currencyCode;
        }

        if (Outputs[a.type] === Outputs.conversionRebateProjected.toString()) {
          mapped.suffix = this._cHelper.currencyCode;
        }

        if (this.showAsPriceAsPar) {
          this.calculatePtsOutput(mapped);
        }

        if (this.isPeps && Outputs[a.type] === Outputs.conversionRatioProjected) {
          this.calculateProjectedCr(mapped);
        }

        /**
         * checks for Ratchet Matrix Multiple,
         * if its value is different from zero, and only then displays it.
         * more information is provided in this task:
         * https://leversys.atlassian.net/browse/SYSTEM-4064
         */
        if (Outputs[a.type] === Outputs.ratchetMatrixMultiple) {
          if (mapped.value === 0) {
            mapped.value = null;
          }
        }

      return mapped;
    });

    this.tabularOutputs = usedOutputs
      .filter(x => x.isSelected && this.shouldDisplayTabularOutput(Outputs[x.type]) && x.isTabular)
      .map(a => {
        const mapped = {
          type: a.type,
          name: Outputs[a.type],
          value: valuationResult.outputs[a.type],
        } as ILvOutput;
        return mapped;
    });
  }

  /**
   * Occurs on show as price as par and calculates Pts output.
   */
  onShowAsPriceAsPar(): void {
    if (this._cHelper.isValid) {
      this.showAsPriceAsPar = !this.showAsPriceAsPar;

      this.regularOutputs.forEach(x => this.calculatePtsOutput(x));
    }
  }

  /**
   * Gets mapped custom outputs.
   * @returns Mapped custom outputs.
   */
  getMappedCustomOutputsOutputs(tabTitle: string): {
    availableOutputs: any[],
    selectedOutputs: any[]
  } {
    const mapped = {
       availableOutputs: [],
       selectedOutputs: []
    };
    let mappedOutputs = [];

    if (tabTitle === 'Outputs') {
      mappedOutputs = this.configuration.customOutputs.filter(el => !el.isTabular);
    }
    else if (tabTitle === 'Tabular Outputs') {
      mappedOutputs = this.configuration.customOutputs.filter(el => el.isTabular);
    }

    mappedOutputs.forEach(output => {
        if (output.isSelected) {
          mapped.selectedOutputs.push({
            type: output.type,
            name: Outputs[output.type],
            status: output.isSelected,
            order: output.order,
          });
        }
        else {
          mapped.availableOutputs.push({
            type: output.type,
            name: Outputs[output.type],
            status: output.isSelected,
            order: output.order
          });
        }
      });
    return mapped;
  }

  /**
   * Checks based on condition should output be displayed
   * @param {ILvOutput} cell Ouptut object to be displayed
   * @returns {boolean} whether or not output should be displayed
   */
  shouldDisplayOutput(cellName: string): boolean {
    if (this._cHelper.convertible) {

      if (this.putsDependant.includes(cellName)) {
        return this._cHelper.puttable;
      }
      else if (this.callDendant.includes(cellName)) {
        return this._cHelper.isCallable;
      }
      else if (this.callTriggerDependant.includes(cellName)) {
        return this._cHelper.isCallable && this._cHelper.isCallTriggerNotZero;
      }
      else if (this.pepsDependant.includes(cellName)) {
        return this._cHelper.isPeps;
      }
      else if (this.mandatoryConversionDependant.includes(cellName)) {
        return ((this._cHelper.isRegular && !this._cHelper.isMandatoryConversion) || this._cHelper.isDetachableWarrant);
      }
      else if (this.fixedFxDependant.includes(cellName)) {
        return (this._cHelper.fixedFx !== 0 && this._cHelper.isCrossFx);
      }
      else if (this.couponDependant.includes(cellName)) {
        return !this._cHelper.isZeroCoupon;
      }
      else if (this.makeWholeDependant.includes(cellName)) {
        return ((!this._cHelper.isRatchetMatrixCallMakeWhole && this._cHelper.isCallMakeWhole) ||
        (!this._cHelper.isRatchetMatrixConversionMakeWhole && this._cHelper.isConversionMakeWhole));
      }
      else if (this.issuePriceDependant.includes(cellName)) {
        return ((this._cHelper.isDetachableWarrant || (this._cHelper.isRegular && !this._cHelper.isMandatoryConversion)) &&
              !this._cHelper.isIssueAndRedemptionDifferent);
      }
      else if (this.flatSpreadAndFactorModel2Dependant.includes(cellName)) {
        return (this.asHelper.marketData?.credit.creditSource === CreditSource.FlatSpread) &&
        (this.asHelper.marketData?.credit.issuerCreditParameters.creditModelFactor === CreditModelFactor.Factor2);
      }
      else if (this.isExchangeableDependant.includes(cellName)) {
        return this.asHelper.convertible.isExchangeable;
      }
      else if (this.upsideDownsideVolatilityAndPepsDependant.includes(cellName)) {
        return this.asHelper.marketData?.volatility.volType === VolatilityType.UpsideDownside && this._cHelper.isPeps;
      }
      else if (this.crossFxDependant.includes(cellName)) {
        return this._cHelper.isCrossFx;
      }
    }
    return true;
  }

  /**
   * Checks based on condition should Tabular Output be displayed
   * @param tabularOutputType Tabular output type.
   * @return {boolean} whether or not Tabular Output should be displayed
   */
  shouldDisplayTabularOutput(tabularOutputType: string): boolean {
    if (this._cHelper.convertible) {
      if (this.tabularPutsDependant.includes(tabularOutputType)) {
        return  this._cHelper.puttable && (this._cHelper.putValueType === PutValueType.AccretedValue ||
          this._cHelper.putValueType === PutValueType.GrossYield || this._cHelper.putValueType === PutValueType.NetYield);
      }
      if (this.tabularPVPutsDependant.includes(tabularOutputType)) {
        return this._cHelper.puttable && this._cHelper.partialPut;
      }
    }

    return true;
  }

  /**
   * Calculates Pts factor.
   * @returns Pts factor.
   */
  private calculatePtsFactor(): number {
    const nominal = this._cHelper.convertible.nominal;
    return this.showAsPriceAsPar ? 100 / nominal : nominal / 100;
  }

  /**
   * Calculates Pts output.
   * @param x ILvOutput object.
   */
  private calculatePtsOutput(x: ILvOutput): void {
    const factor = this.calculatePtsFactor();

    if (OutputsPercentOfPar[x.type]) {
      x.value = x.value * factor;
      x.suffix = this.showAsPriceAsPar ? 'pts' : this._cHelper.currencyCode;
    }
  }

  /**
   * Calculates projected CR.
   * @param output ILvOutput object.
   */
  private calculateProjectedCr(output: ILvOutput) {
    if (this.asHelper && this.asHelper.pricing.valuationDate <= new Date() && output.value) {
      output.value = 0;
    }
  }
}
