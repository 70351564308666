<kendo-popup *ngIf="expanded" 
  detectScroll
  (scrollStarted)="closePanel()"
  [anchor]="dropdownAnchor" 
  [anchorAlign]="anchorAlign" 
  [popupAlign]="popupAlign" 
  class="lv-side-panel"
  [ngClass]="ngClass"
  [style.width.px]="width"
  (@slide.done)="onSlideDone()">
  <ng-content></ng-content>
</kendo-popup>
