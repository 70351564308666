<div class="lv-watch-list-panel-dialog-item">
  <div class="lv-watch-list-panel-dialog-item-column wl-column">
    <div class="lv-watch-list-panel-dialog-item-column-header">
      <label>Watchlists</label>
      <lv-text-box
        class="lv-watch-list-panel-dialog-item-column-header-search-input"
        name="editWatchListsSearchAllWatchLists"
        [searchIconVisible]="true"
        placeholder="Search for Watchlist"
        [(ngModel)]="view.watchListsQuery">
      </lv-text-box>
    </div>
    <div class="lv-watch-list-panel-dialog-item-column-content watch-list">
      <div lvId="edit-watch-lists-all-watch-lists"
        class="watch-list-container">
        <div *ngFor="let wl of view.watchLists | searchArray : view.watchListsQuery : 'listName' : 'isGroup'; trackBy:view.watchListsTrackBy"
          [class.lv-watch-list-item]="!wl.isGroup"
          [class.lv-watch-list-group]="wl.isGroup"
          [class.lv-watch-list-item--selected]="view.isWatchListSelected(wl)"
          (click)="onSelectWatchList(wl)">
          {{ wl.listName }}
        </div>
      </div>

      <lv-side-panel [width]="220"
        [manualClosing]="true">
        <form #form="ngForm"
          *ngIf="!showDeleteWatchList"
          class="lv-watch-lists-editor">
          <div class="lv-watch-lists-editor-header">
            {{ view.selectedWatchList?.original.listName }}
          </div>
          <div class="lv-watch-lists-editor-content">
            <div class="inner-row">
              <div class="col-5 lv-label">
                Name
              </div>
              <div class="lv-watch-lists-editor-content-new-watch-list-input">
                <input #watchListName
                  kendoTextBox
                  name="editWatchListsSelectedListName"
                  [(ngModel)]="view.selectedWatchList?.current.listName">
              </div>
            </div>
          </div>
          <div class="lv-watch-lists-editor-footer">
            <button kendoButton
              type="button"
              look="flat"
              lvId="edit-watch-lists-cancel-wl-edit"
              (click)="onCancelWatchListEdit()">
              Cancel
            </button>
            <button kendoButton
              type="button"
              [primary]="true"
              lvId="edit-watch-lists-save-wl"
              (click)="onSaveWatchListEdit()">
              Save
            </button>
          </div>
        </form>
        <div *ngIf="showDeleteWatchList"
          class="lv-watch-lists-editor">
          <div class="lv-watch-lists-editor-content">
            <div class="delete-info">
              <span>Are you sure you want to delete </span>
              <span class="lv-output">{{ view.selectedWatchList?.current.listName }}</span>
              <span> watchlist</span>?</div>
          </div>
          <div class="lv-watch-lists-editor-footer">
            <button kendoButton
              type="button"
              look="flat"
              lvId="edit-watch-lists-cancel-wl-delete"
              (click)="onCancelWatchListDelete()">
              Cancel
            </button>
            <button kendoButton
              type="button"
              [primary]="true"
              lvId="edit-watch-lists-delete-wl"
              (click)="onDeleteWatchList()">
              Delete
            </button>
          </div>
        </div>
      </lv-side-panel>
    </div>
    <div class="lv-watch-list-panel-dialog-item-column-footer">
      <div class="lv-icon-square"
        [class.lv-icon-square--disabled]="!addEnabled"
        title="Add watchlist"
        lvId="edit-watch-lists-add-wl"
        (click)="onAddWatchList()">
        <i class="k-icon k-i-plus"></i>
      </div>

      <div class="lv-icon-square"
        [class.lv-icon-square--disabled]="!editEnabled"
        title="Edit watchlist"
        lvId="edit-watch-lists-edit-wl"
        (click)="onEditWatchList()">
        <i class="k-icon k-i-pencil"></i>
      </div>

      <div class="lv-watch-list-instruments-footer-spacer"></div>

      <div class="lv-icon-square lv-icon-square--danger"
        *ngIf="!view.isMyInstrumentsWatchListSelected"
        [class.lv-icon-square--disabled]="!editEnabled"
        title="Delete watchlist"
        lvId="edit-watch-lists-delete-wl"
        (click)="onShowDeleteWatchList()">
        <i class="k-icon k-i-delete"></i>
      </div>
    </div>
  </div>

  <div class="lv-watch-list-panel-dialog-item-column-spacer"></div>

  <div class="lv-watch-list-panel-dialog-item-column">
    <div class="lv-watch-list-panel-dialog-item-column-header">
      <label>Available Instruments</label>
      <lv-text-box
        class="lv-watch-list-panel-dialog-item-column-header-search-input"
        name="editWatchListsSearchAvailableInstruments"
        [searchIconVisible]="true"
        placeholder="Search for instrument"
        [(ngModel)]="view.availableInstrumentsQuery"
        (ngModelChange)="onFilterAvailableGrid()">
      </lv-text-box>
    </div>
    <div class="lv-watch-list-panel-dialog-item-column-content">
      <kendo-grid
        lvId="edit-watch-lists-available-instruments"
        [data]="view.availableGridView.recordsView"
        [skip]="view.availableGridView.skip"
        [pageSize]="view.availableGridView.pageSize"
        scrollable="virtual"
        [rowHeight]="24"
        (pageChange)="view.availableGridView.onPageChange($event)"
        [selectable]="view.availableGridView.selectableSettings"
        [kendoGridSelectBy]="view.availableGridView.recordIdentifier"
        [selectedKeys]="view.availableGridView.selectedIdentifiers"
        (selectedKeysChange)="onAvailableGridSelectedKeysChange($event)"
        [navigable]="true">
        <kendo-grid-column title="Instrument Name">
          <ng-template kendoGridCellTemplate let-dataItem>
            <lv-flex-box direction="row">
              <div class="text-overflow-ellipsis" kendoTooltip [tooltipTemplate]="tooltipTemplate" filter="span">
                <span>{{ dataItem.name }}</span>
              </div>
              <div *ngIf="dataItem.isPrivateInstrument" class="lv-instrument-monitor-icon" kendoTooltip>
                <i lvAccessScope [accessScope]="dataItem.accessScope"></i>
              </div>
            </lv-flex-box>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column class="lv-icon-grid-column"
          [width]="30">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="!view.isMyInstrumentsWatchListSelected || dataItem.isPrivateInstrument"
              class="lv-icon-square"
              [class.lv-icon-square--disabled]="!editEnabled"
              lvId="edit-watch-lists-add-instr-to-wl"
              (click)="onAddInstrumentToWatchList(dataItem)">
              <i class="k-icon k-i-add"></i>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>

  <div class="lv-watch-list-panel-dialog-item-column-spacer"></div>

  <div class="lv-watch-list-panel-dialog-item-column"
    [class.lv-watch-list-panel-dialog-item-column--disabled]="!editEnabled">
    <div class="lv-watch-list-panel-dialog-item-column-header">
      <label>Selected Instruments</label>
      <lv-text-box
        class="lv-watch-list-panel-dialog-item-column-header-search-input"
        name="editWatchListsSearchSelectedInstruments"
        [searchIconVisible]="true"
        placeholder="Search for instrument"
        [(ngModel)]="view.selectedInstrumentsQuery"
        (ngModelChange)="onFilterSelectedGrid()">
      </lv-text-box>
    </div>
    <div class="lv-watch-list-panel-dialog-item-column-content">
      <kendo-grid #selectedGridElement
        lvId="edit-watch-lists-selected-instruments"
        [data]="view.selectedGridView.recordsView"
        [skip]="view.selectedGridView.skip"
        [pageSize]="view.selectedGridView.pageSize"
        scrollable="virtual"
        [rowHeight]="24"
        (pageChange)="view.selectedGridView.onPageChange($event)"
        [selectable]="view.selectedGridView.selectableSettings"
        [kendoGridSelectBy]="view.selectedGridView.recordIdentifier"
        [selectedKeys]="view.selectedGridView.selectedIdentifiers"
        (selectedKeysChange)="onSelectedGridSelectedKeysChange($event)"
        [navigable]="true">
        <kendo-grid-column title="Instrument Name">
          <ng-template kendoGridCellTemplate let-dataItem>
            <lv-flex-box direction="row">
              <div class="text-overflow-ellipsis" kendoTooltip [tooltipTemplate]="tooltipTemplate" filter="span[data-title]">
                <span [attr.data-title]="dataItem.name">{{ dataItem.name }}</span>
              </div>
              <div *ngIf="dataItem.isPrivateInstrument"
                class="lv-instrument-monitor-icon"
                kendoTooltip>
                <i lvAccessScope [accessScope]="dataItem.accessScope"></i>
              </div>
            </lv-flex-box>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column class="lv-icon-grid-column"
          [width]="30">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="lv-icon-square lv-icon-square--danger"
              [class.lv-icon-square--disabled]="!editEnabled"
              lvId="edit-watch-lists-remove-instr-from-wl"
              (click)="onRemoveInstrumentFromWatchList(dataItem)">
              <i class="k-icon k-i-delete"></i>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>

<ng-template #tooltipTemplate let-anchor>
  {{ anchor.nativeElement.innerHTML }}
</ng-template>
