import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ConvertibleBondTermsSectionEvent } from '../../../models/convertible-bond-terms/custom-enum/convertible-bond-terms-section-event';
import { LvConvertibleBondTermsPresenter } from '../lv-convertible-bond-terms.presenter';
import { LvEstimatesView } from './lv-estimates.view';

/**
 * Estimates tab component for Leversys Convertibles
 * Component shuld be visible if there is Conversion Ratio with expected status
 * then "new values" should be used in calculation
 */
@Component({
  selector: 'lv-estimates',
  templateUrl: './lv-estimates.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEstimatesComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription;

  view: LvEstimatesView;

  constructor(
    private _presenter: LvConvertibleBondTermsPresenter,
    private _changeDetectorRef: ChangeDetectorRef,
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService
    ) {
    this.view = new LvEstimatesView(_lvConvertibleBondTermsService);
  }

  ngOnInit() {
    this.subscriptions = this._presenter.onModelUpdated
    .pipe(
      filter(event => event.eventId === ConvertibleBondTermsSectionEvent.DividendsProtectionEvent ||
        event.eventId === ConvertibleBondTermsSectionEvent.DividendProtectionIssueAndRedemption ||
        event.eventId === ConvertibleBondTermsSectionEvent.ModelLoadedEvent ||
        event.eventId === ConvertibleBondTermsSectionEvent.ConversionEvent)
    )
    .subscribe(model => {
      if (model.data) {
        this.view.init(model.data.fullTerms.dividendProtection, model.data.fullTerms.conversion.reset, model.data.fullTerms.expectedCR);
        this._changeDetectorRef.detectChanges();
      }
    });

    if (this._presenter.isModelLoaded()) {
      const fullTerms = this._presenter.getModel().fullTerms;
      this.view.init(fullTerms.dividendProtection,
                      fullTerms.conversion.reset,
                      fullTerms.expectedCR);
      this._changeDetectorRef.detectChanges();
    }
    this.view.initColumns();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
