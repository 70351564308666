

import { CurrencyType } from './CurrencyType';
import { PepsConversionBeforeMaturityType } from './Enums (2)';

export class PepsConversion {
    
    startDate: Date;
    endDate: Date;
    maxRatio: number;
    conversionRebate: number;
    conversionRebateCurrency: CurrencyType;
    lowerStrike: number;
    lowerStrikePremium: number;
    higherStrike: number;
    conversionBeforeMaturityType: PepsConversionBeforeMaturityType;
    customMaxRatio: number;
    customMinRatio: number;
    parallelPeps: boolean;
}

