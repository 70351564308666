import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, EventEmitter, ChangeDetectorRef,
  Input, Output, OnChanges, SimpleChanges } from '@angular/core';

import { DialogService } from '@progress/kendo-angular-dialog';

import { ILvWorkspace, getDefaultWorkspace } from './lv-workspace/lv-workspace';
import { LvWorkspacePanelDialogComponent } from './lv-workspace-panel-dialog/lv-workspace-panel-dialog.component';
import { LvAngularUtil } from '../../util/angular';

@Component({
  selector: 'lv-workspace-panel',
  templateUrl: './lv-workspace-panel.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvWorkspacePanelComponent implements OnInit, OnChanges {

  @Input() items: ILvWorkspace[];

  @Input() defaultName: string;

  @Input() isLoading: boolean;

  @Output() didSelect: EventEmitter<ILvWorkspace>;
  @Output() didAdd: EventEmitter<ILvWorkspace>;
  @Output() didEdit: EventEmitter<ILvWorkspace>;
  @Output() didClose: EventEmitter<ILvWorkspace>;

  get current(): ILvWorkspace {
    return this.items.length > 0 ? this.items[this._currentIndex] : null;
  }

  private _currentIndex: number;

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private dialogService: DialogService
  ) {
    this.defaultName = 'Workspace';
    this.items = [];
    this._currentIndex = -1;

    this.isLoading = false;

    this.didSelect = new EventEmitter<ILvWorkspace>();
    this.didAdd = new EventEmitter<ILvWorkspace>();
    this.didEdit = new EventEmitter<ILvWorkspace>();
    this.didClose = new EventEmitter<ILvWorkspace>();
  }

  ngOnInit() {
    this.updateSelection();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (LvAngularUtil.inputChanged(changes, 'items')) {
      this.updateSelection();
    }
  }

  onSelect(lw: ILvWorkspace) {
    this.isLoading = true;
    this.changeDetectionRef.detectChanges();

    this.items = this.items.map((a, i) => {
      a.isSelected = a.id === lw.id;
      if (a.isSelected) {
        this._currentIndex = i;
      }

      return { ...a };
    });

    this.didSelect.next({ ...lw });

    this.isLoading = false;
    this.changeDetectionRef.detectChanges();
  }

  onEdit(lw: ILvWorkspace) {
    this.items = this.items.map((a, i) => {
      if (a.id === lw.id) {
        a.name = lw.name;
        this._currentIndex = i;
      }

      return { ...a };
    });

    this.didEdit.emit({ ...lw });

    this.changeDetectionRef.detectChanges();
  }

  onClose(lw: ILvWorkspace) {
    const dialogRef = this.dialogService.open({
      title: `Delete ${this.defaultName}`,
      content: LvWorkspacePanelDialogComponent,
      actions: [{
        text: 'No',
        primary: true
      }, {
        text: 'Yes',
        doClose: true,
      }]
    });

    dialogRef.dialog.location.nativeElement.classList.add('workspace-panel-dialog');
    (dialogRef.content.instance as LvWorkspacePanelDialogComponent).item = lw;

    dialogRef.result.subscribe(result => {
      if ((result as any).doClose) {
        this.doClose(lw);
      }
    });
  }

  doClose(lw: ILvWorkspace) {
    this.items = this.items.filter(a => a.id !== lw.id);

    this.didClose.next(lw);

    const foundIndex = this._currentIndex - 1; // because the item is removed from array
    if (this.items.length > 0) {
      // use next
      let found: ILvWorkspace = this.items[foundIndex + 1];
      // use previous
      if (!found) {
        found = this.items[foundIndex];
      }
      // use first
      if (!found) {
        found = this.items[0];
      }

      this.onSelect(found);
    }
    else {
      this.onAdd();
    }
  }

  onAdd() {
    this.isLoading = true;
    this.changeDetectionRef.detectChanges();

    const newItem = getDefaultWorkspace({
      name: `${this.defaultName} ${this.items.length + 1}`,
      isSelected: true
    });

    this.items = this.items.map(a => {
      a.isSelected = false;

      return { ...a };
    });

    this.items.push(newItem);

    this._currentIndex = this.items.length - 1;

    this.didAdd.next({ ...newItem });

    this.isLoading = false;
    this.changeDetectionRef.detectChanges();
  }

  private updateSelection() {
    if (this.items.length === 0) {
      this.onAdd();
    }
    else {
      let selected = this.items.find(a => a.isSelected);

      if (!selected) {
        selected = this.items[0];
      }

      this.onSelect(selected);
    }
  }
}
