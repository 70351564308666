<div class="lv-scenario-2d-grid">
  <kendo-grid class="lv-scenario-grid"
    [data]="view.model.records"
    [resizable]="true">
    <kendo-grid-column
      [locked]="true"
      [width]="view.defaultDimensionColumnWidth">
      <ng-template kendoGridHeaderTemplate>
        <div class="aligned-left">{{ view.model.dimensionDescription }}</div>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="input">
          <ng-container *ngIf="!view.showFirstDimensionAsPercentage">
            <ng-container *ngIf="!view.isValuationDateShift(dataItem.dimension)">
              {{ dataItem.dimensionValue | number:view.numberFormat }}
            </ng-container>
            <ng-container *ngIf="view.isValuationDateShift(dataItem.dimension)">
              {{ dataItem.dimensionValue | lvLocalDate }}
            </ng-container>
          </ng-container>
          
          <ng-container *ngIf="view.showFirstDimensionAsPercentage">
            {{ dataItem.dimensionValue | number:view.numberFormat }} ({{ dataItem.dimensionValue | lvPercent:view.firstDimensionChangeRef:view.percentFormat }})
          </ng-container>
        </span>
      </ng-template>
    </kendo-grid-column>
  
    <kendo-grid-column-group *ngFor="let group of view.model.groups"
      [width]="group.width">
        <ng-template kendoGridHeaderTemplate>
          <div class="aligned-left">{{ group.title }}</div>
        </ng-template>

        <kendo-grid-column-group *ngFor="let subGroup of group.subGroups"
          [width]="subGroup.width">
          <ng-template kendoGridHeaderTemplate>
            <span class="input">
              <ng-container *ngIf="!view.showSecondDimensionAsPercentage">
                {{ subGroup.data | number:view.numberFormat }}
              </ng-container>
              <ng-container *ngIf="view.showSecondDimensionAsPercentage">
                {{ subGroup.data | number:view.numberFormat }} ({{ subGroup.data | lvPercent:view.secondDimensionChangeRef:view.percentFormat }})
              </ng-container>
            </span>
          </ng-template>
          <kendo-grid-column *ngFor="let column of subGroup.columns"
            [title]="column.title"
            [width]="column.width">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="output-invisible" *ngFor="let output of dataItem[column.columnId]"
                [class.output-visible]="view.isOutputVisible(output.output)">
                {{ output.value | number:view.numberFormat }}
              </span>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid-column-group>
    </kendo-grid-column-group>
  </kendo-grid>

  <kendo-buttongroup class="lv-scenario-grid-actions"
    selection="single">
    <button *ngFor="let output of view.model.outputs"
      type="button"
      kendoButton
      [toggleable]="true"
      [selected]="view.isOutputVisible(output)"
      (click)="onCurrentOutputClick(output)">
      {{ output | enum:view.scenarioOutputDescription }}
    </button>
  </kendo-buttongroup>
</div>
