<lv-advanced-grid
  data-cy="DM-898"
  lvId="lvTermsConversionSchedule"
  [columns]="columns"
  [records]="conversionScheduleItems"
  [createFormGroup]="createFormGroup"
  [parseFn]="parseFn"
  sortBy="startDate"
  [showXlLabel]="hasScheduleInExcelOverride"
  [excelFieldAlias]="excelFieldAlias"
  [isFieldFromExcelEnabled]="isFieldFromExcelEnabled"
  class="lv-conversion-schedule-grid">
</lv-advanced-grid>