<label *ngIf="isFieldOverridedFromExcel"
    class="lv-xl-label"
    [class.lv-xl-label--not-editable]="!isEditable"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="xlTooltipTemplate"
    filter="span">
    <span [attr.xl-tooltip-id]="'xlSourceTooltip'">
        XL
    </span>
</label>

<ng-template #xlTooltipTemplate let-anchor>
    <div class="popup-xl-content">
        <ng-container *ngIf="getXlTootlipId(anchor, 'xlSourceTooltip')">
        {{field}}
        </ng-container>
    </div>
</ng-template>
