import { InstrumentStatusGeneralDescription } from '@lv-convertible-bond/models';

export class LvTermsGeneralIssueInformationView {

  /**
   * Get enum description of setup status
   * @param status Setup status value
   * @returns Description of setup status value
   */
  getStatusDescription(status: string): string {
    return InstrumentStatusGeneralDescription[status];
  }
}
