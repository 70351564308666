import { Injectable } from '@angular/core';
import { MetadataService } from './services/metadata/metadata.service';

/**
 * Documents service.
 */
@Injectable()
export class DocumentsService {

  constructor(
    public metadataService: MetadataService
  ) { }

}
