<lv-flex-box class="lv-access-rights-modal">
  <lv-flex-box class="lv-access-rights-content">
   <lv-flex-box direction="row">
     <div class="lv-input-field lv-field lv-field--radio lv-access-rights-content-input-private-label">
       <input class="k-radio"
          type="radio"
          [attr.id]="useUserRadioButtonId"
          name="userAccessScope"
          [(ngModel)]="accessScope"
          value="User">
       <label class="k-radio-label"
          [attr.for]="useUserRadioButtonId"
          lvId="lv-use-private">
          Private (available only to you)
       </label>
     </div>
   </lv-flex-box>
   <lv-flex-box direction="row">
     <div class="lv-input-field lv-field lv-field--radio lv-access-rights-content-input-private-radio">
        <input class="k-radio"
          type="radio"
          [attr.id]="useCompanyRadioButtonId"
          name="companyAccessScope"
          [(ngModel)]="accessScope"
          value="Company">
       <label class="k-radio-label"
          [attr.for]="useCompanyRadioButtonId"
          lvId="lv-use-company">
          Shared Internally (available to your team)
       </label>
      </div>
   </lv-flex-box>
 </lv-flex-box>
  <lv-flex-box class="lv-access-rights-modal-footer" direction="row">
    <button kendoButton
      [primary]="true"
      lvId="accessRightsCancel"
      (click)="onCancel()">
        Cancel
    </button>
    <button kendoButton
      [primary]="true"
      lvId="accessRightsSave"
      (click)="onSave()">
        Save
    </button>
  </lv-flex-box>
</lv-flex-box>
