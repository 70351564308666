import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter,
         Input, OnChanges, ElementRef, Optional } from '@angular/core';
import { v4 } from 'uuid';
import { LvLookupEnum } from '@lv-core-ui/util';
import { CallMakeWholeDataConversion, IssueAndRedemption, CallMakeWholeTypeDescription,
         CallMakeWholeType } from '@lv-convertible-bond/models';
import { DiscountRateType } from '@lv-convertible-bond/models/convertible-bond-terms/Enums (2)';
import { LvExcelService } from '@lv-excel/services';

@Component({
  selector: 'lv-make-whole-conversion-parameters',
  templateUrl: './lv-make-whole-conversion-parameters.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvMakeWholeConversionParametersComponent implements OnInit, OnChanges {
  @Input() model: CallMakeWholeDataConversion;
  @Input() issueAndRedemption: IssueAndRedemption;
  @Output() didSetParameters: EventEmitter<CallMakeWholeDataConversion>;

  get couponsIncludedUntil(): Date {
    return this._couponsIncludedUntil;
  }

  set couponsIncludedUntil(value: Date) {
    this._couponsIncludedUntil = value;

    if (this.model && this.couponsIncludedUntilEnabled) {
      this.model.couponsIncludedUntil = value;
    }
  }

  get isExcelInstrument(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  get couponIncludedUntil(): string {
    return this.isExcelInstrument ? 'Coupons Incl. Until' : 'Coupons Included Until';
  }

  get rollingMWPeriod(): string {
    return this.isExcelInstrument ? 'Rolling MW Period' : 'Rolling Make-Whole Period';
  }

  get rollingCouponMWPeriod(): string {
    return this.isExcelInstrument ? 'Rolling Cpn. MW Period' : 'Rolling Coupon MW Period';
  }

  private _couponsIncludedUntil: Date;

  couponsIncludedUntilEnabled: boolean;

  makeWholeTypeLookup: LvLookupEnum;
  discountRateTypeLookup: LvLookupEnum;

  rollingMakeWholePeriodCheckBoxId: string;

  remainingPeriodVisible: boolean;
  couponsIncludedUntilVisible: boolean;
  discountFieldsVisible: boolean;
  initialPremiumVisible: boolean;

  formatZero = 'n0';
  decimalsZero = '0';
  formatNumber = '#,###.###';
  decimalsNumber = '3';

  constructor(@Optional() private _excelSvc: LvExcelService) {
    if (!this.model) {
      this.model = new CallMakeWholeDataConversion();
    }

    this.didSetParameters = new EventEmitter<CallMakeWholeDataConversion>();

    this.makeWholeTypeLookup = new LvLookupEnum(CallMakeWholeTypeDescription);
    this.discountRateTypeLookup = new LvLookupEnum(DiscountRateType);

    this.rollingMakeWholePeriodCheckBoxId = v4();
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.setFieldsVisibility();
  }

  makeWholeTypeChanged() {
    this.setFieldsVisibility();
    this.doModelChange();
  }

  private setFieldsVisibility() {
    if (!this.model.couponsIncludedUntil) {
      this._couponsIncludedUntil = this.issueAndRedemption.maturityDate;
      this.couponsIncludedUntilEnabled = false;
    } else {
      this._couponsIncludedUntil = this.model.couponsIncludedUntil;
      this.couponsIncludedUntilEnabled = true;
    }

    this.remainingPeriodVisible = this.model.rollingMWPeriod;
    this.couponsIncludedUntilVisible = this.model.type === CallMakeWholeType.Coupon ||
                                       this.model.type === CallMakeWholeType.CouponPV;

    this.discountFieldsVisible = this.model.type === CallMakeWholeType.CouponPV;
    this.initialPremiumVisible = this.model.type === CallMakeWholeType.Premium ||
                                 this.model.type === CallMakeWholeType.PremiumLinDecreasing;
  }

  doModelChange() {
    this.didSetParameters.next(this.model);
  }

  couponIncludedUntilCheckBoxChanged(value: boolean): void {
    this.couponsIncludedUntilEnabled = value;
    if (this.model) {
      this.model.couponsIncludedUntil = value ? this._couponsIncludedUntil : null;
    }
    this.doModelChange();
  }

  getConversionMWTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
