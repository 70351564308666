import { Injectable } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router/';
import { ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

import { RouteCacheService } from './route-cache.service';
import { LocalStorage } from '@lv-core-ui/util';

@Injectable()
export class RouteCacheStrategy implements RouteReuseStrategy {

  constructor(
    private _cache: RouteCacheService
  ) {}

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if (!LocalStorage.getTokenResponse()) {
      return false;
    }

    return this.getShouldReuse(route);
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (this.getShouldReuse(route)) {
      this._cache.set(this.getCacheKey(route), handle);
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const cacheKey = this.getCacheKey(route);
    const shouldAttach = this.getShouldReuse(route) && this._cache.exists(cacheKey);

    if (shouldAttach) {
      this._cache.publishWorkspaceReatached(cacheKey);
    }

    return shouldAttach;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!this.getShouldReuse(route)) {
      return null;
    }

    return this._cache.get(this.getCacheKey(route));
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (!LocalStorage.getTokenResponse()) {
      return false;
    }

    return this.getShouldReuse(future);
  }

  private getShouldReuse(route: ActivatedRouteSnapshot): boolean {
    return !!route.data['shouldReuse'];
  }

  private getRouteId(route: ActivatedRouteSnapshot): string {
    return route.data['routeId'];
  }

  private getCacheKey(route: ActivatedRouteSnapshot): string {
    let routeId = this.getRouteId(route);

    if (this._cache.isWorkspaceRouteId(routeId)) {
      routeId = this._cache.getWorkspaceCacheKey(route.paramMap.get('id'));
    }

    return routeId;
  }
}
