import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enum'
})
export class EnumPipe implements PipeTransform {

  transform(enumValue: any, enumType: any): any {
    const description = enumType[enumValue];
    return description || enumValue;
  }

}
