import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, Input, OnChanges,
  SimpleChanges, OnDestroy, ElementRef} from '@angular/core';

import { Subscription } from 'rxjs';

import { GridComponent } from '@progress/kendo-angular-grid';
import { LvSidePanelComponent } from '@lv-core-ui/components';
import { IPaginatedResult } from '@lv-core-ui/models';
import { LvErrorService, LvPermissionService } from '@lv-core-ui/services';
import { IInstrumentInfo, IWatchList, IWatchListInstrumentInfo } from '@lv-instrument-monitor/models';
import { WatchListService } from '@lv-instrument-monitor/services';
import { ILvWatchListPanelDialogItem } from '../lv-watch-list-panel-dialog-item';
import { LvWatchListPanelDialogComponent } from '../lv-watch-list-panel-dialog.component';
import { LvWatchListInstrumentsView } from './lv-watch-list-instruments.view';
import { AuthorizationService } from '@lv-core-ui/services/authorization/authorization.service';


@Component({
  selector: 'lv-watch-list-instruments',
  templateUrl: './lv-watch-list-instruments.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvWatchListInstrumentsComponent implements OnInit, OnChanges, OnDestroy, ILvWatchListPanelDialogItem {

  @ViewChild(LvSidePanelComponent, { static: true }) wlEditor: LvSidePanelComponent;
  @ViewChild('watchListName') watchListNameElement: ElementRef<HTMLInputElement>;
  @ViewChild('selectedGridElement', { static: true }) selectedGridElement: GridComponent;

  @Input() availableInstrumentInfos: IPaginatedResult<IInstrumentInfo>;

  get addEnabled(): boolean {
    return !this.dialog.isLoading && !this.wlEditor.expanded;
  }

  get editEnabled(): boolean {
    return this.addEnabled && !!this.view.selectedWatchList && !this.view.selectedWatchList.isPhantom;
  }

  view: LvWatchListInstrumentsView;

  showDeleteWatchList: boolean;

  continueEditingSubscription: Subscription;

  constructor(
    private authorizationService: AuthorizationService,
    private changeDetectorRef: ChangeDetectorRef,
    private errorService: LvErrorService,
    private permissionService: LvPermissionService,
    private watchListService: WatchListService,
    private dialog: LvWatchListPanelDialogComponent
  ) {
    this.view = new LvWatchListInstrumentsView(
      this.authorizationService,
      this.permissionService,
      this.watchListService);

    this.showDeleteWatchList = false;

    // TODO: hack to avoid circular dependency warning,
    // TODO: should be done via service
    this.dialog.wlInstruments = this;
  }

  ngOnInit() {
    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.availableInstrumentInfos.previousValue !== changes.availableInstrumentInfos.currentValue) {
      this.view.availableGridView.initView(this.availableInstrumentInfos.records);
      this.changeDetectorRef.detectChanges();
    }
  }

  async refresh(): Promise<void> {
    try {
      this.dialog.setLoadingState(true);

      const wls = await this.view.watchListView.getView({
        excludeSystemWatchLists: true,
        excludeSharedWatchLists: true
      });

      this.view.watchLists = wls.filter(a => !a.isSystem);

      const filtered = this.view.watchLists.filter(a => !a.isGroup && !a.isSystem);

      if (filtered.length > 0) {
        await this.onSelectWatchList(filtered[0]);
      }
    }
    catch (error) {
      this.errorService.handleError(error);
    }
    finally {
      this.dialog.setLoadingState(false);
    }
  }

  onSelectWatchList(wl: IWatchList) {
    if (wl.isGroup) {
      return false;
    }

    if (this.view.selectedWatchList && this.view.selectedWatchList.id === wl.id) {
      return false;
    }

    this.validateWatchList(async () => {
      try {
        this.dialog.setLoadingState(true);

        await this.view.setSelectedWatchList(wl);
      }
      catch (error) {
        this.errorService.handleError(error);
      }
      finally {
        this.dialog.setLoadingState(false);

        this.changeDetectorRef.detectChanges();
      }
    });
  }

  onAddWatchList() {
    if (this.addEnabled) {
      this.validateWatchList(() => {
        this.view.addWatchList();
        this.wlEditor.togglePanel();

        this.changeDetectorRef.detectChanges();

        setTimeout(() => {
          if (this.watchListNameElement) {
            this.watchListNameElement.nativeElement.focus();
            this.watchListNameElement.nativeElement.select();
          }
        }, 250);
      });
    }
  }

  onEditWatchList() {
    if (this.editEnabled) {
      this.wlEditor.togglePanel();
      this.changeDetectorRef.detectChanges();
    }
  }

  onShowDeleteWatchList() {
    if (this.editEnabled) {
      this.showDeleteWatchList = true;
      this.wlEditor.togglePanel();
    }
  }

  async onDeleteWatchList() {
    try {
      this.dialog.setLoadingState(true);

      const toDelete = { ...this.view.selectedWatchList.original };

      await this.view.deleteWatchList();

      this.dialog.addDeletedWatchList(toDelete);
      this.dialog.setLoadingState(false);

      this.onCancelWatchListDelete();
    }
    catch (error) {
      this.errorService.handleError(error);
      this.dialog.setLoadingState(false);
    }
  }

  onCancelWatchListEdit() {
    this.view.cancelWatchListEdit();

    this.wlEditor.togglePanel();
    this.changeDetectorRef.detectChanges();
  }

  onCancelWatchListDelete() {
    this.wlEditor.togglePanel();

    setTimeout(() => {
      // await for panel to close
      this.showDeleteWatchList = false;
      this.changeDetectorRef.detectChanges();
    }, 250);
  }

  async onSaveWatchListEdit() {
    try {
      this.dialog.setLoadingState(true);

      this.wlEditor.togglePanel();

      const isPhantom = this.view.selectedWatchList.isPhantom;

      await this.view.saveWatchList();

      if (!isPhantom) {
        this.dialog.addUpdatedWatchList(this.view.selectedWatchList.current);
      }
    }
    catch (error) {
      this.errorService.handleError(error);
      this.wlEditor.togglePanel();
      this.changeDetectorRef.detectChanges();
    }
    finally {
      this.dialog.setLoadingState(false);

      this.changeDetectorRef.detectChanges();
    }
  }

  onFilterAvailableGrid() {
    this.view.availableGridView.reloadView();
    this.changeDetectorRef.detectChanges();
  }

  onFilterSelectedGrid() {
    this.view.selectedGridView.reloadView();
    this.changeDetectorRef.detectChanges();
  }

  onAvailableGridSelectedKeysChange(recordIdentifiers: number[]) {
    this.view.availableGridView.setSelectedIdentifiers(recordIdentifiers);
    this.changeDetectorRef.detectChanges();
    this.view.selectedGridView.setSelectedIdentifiers();
    this.changeDetectorRef.detectChanges();
  }

  onSelectedGridSelectedKeysChange(recordIdentifiers: number[]) {
    this.view.selectedGridView.setSelectedIdentifiers(recordIdentifiers);
    this.changeDetectorRef.detectChanges();
    this.view.availableGridView.setSelectedIdentifiers();
    this.changeDetectorRef.detectChanges();
  }

  onAddInstrumentToWatchList(instrumentInfo: IInstrumentInfo) {
    this.view.addInstrumentToSelectedGridView(instrumentInfo);

    this.onFilterAvailableGrid();

    setTimeout(() => {
      this.scrollGridToBottom();
    }, 10);
  }

  onRemoveInstrumentFromWatchList(instrumentInfo: IWatchListInstrumentInfo) {
    this.view.removeInstrumentFromSelectedGridView(instrumentInfo);

    this.onFilterAvailableGrid();
  }

  // Panel Item Interface
  isDirty(): boolean {
    return this.view.selectedWatchList && this.view.selectedWatchList.isDirty
      || this.view.selectedInstruments && this.view.selectedInstruments.isDirty;
  }

  async saveItem(): Promise<void> {
    try {
      await this.view.updateWatchListInstruments();

      this.view.selectedInstruments.update();

      this.changeDetectorRef.detectChanges();
    }
    catch (error) {
      throw error;
    }
  }

  onCtrlA(event: KeyboardEvent) {
    if (this.view.availableGridView.selectedIdentifiers.length > 0) {
      const records = this.view.availableGridView.getSelectedRecords();

      this.view.availableGridView.setSelectedIdentifiers();

      this.onAddInstrumentToWatchList(records[0]);
    }
  }

  onCtrlR(event: KeyboardEvent) {
    if (this.view.selectedGridView.selectedIdentifiers.length > 0) {
      const records = this.view.selectedGridView.getSelectedRecords();

      this.view.selectedGridView.setSelectedIdentifiers();

      this.onRemoveInstrumentFromWatchList(records[0]);
    }
  }

  getCurrentRecordEditingItemName(): string {
    if (this.view.selectedWatchList) {
      return this.view.selectedWatchList.current.listName;
    }

    return null;
  }

  ngOnDestroy() {
    if (this.continueEditingSubscription) {
      this.continueEditingSubscription.unsubscribe();
    }
  }

  private scrollGridToBottom() {
    const element = this.getGridScrollableElement();
    if (element.scrollHeight > element.clientHeight) {
      element.scroll({
        behavior: 'smooth',
        top: element.scrollHeight
      });
    }
  }

  private getGridScrollableElement(): HTMLElement {
    const el = (this.selectedGridElement.wrapper.nativeElement as HTMLElement).querySelector('div.k-grid-content');
    return el as HTMLElement;
  }

  private validateWatchList(action: () => void) {
    if (this.isDirty()) {
      this.continueEditingSubscription = this.dialog.showContinueEditingPanel(this.view.selectedWatchList.current.listName)
        .subscribe(continueEditing => {
          if (continueEditing) {
            this.dialog.hideContinueEditingPanel();

            if (this.view.selectedWatchList) {
              this.view.selectedGridView.setSelectedIdentifiers([this.view.selectedWatchList.id]);
              this.changeDetectorRef.detectChanges();
            }
          }
          else {
            action();
          }
        });
    }
    else {
      action();
    }
  }
}
