import { Subject } from 'rxjs';

import { LvNotifier, ILvNotifierState, ILvNotificationEvent, ILvNotificationEventType, LvNotifierType } from './lv-notifier';
import { LvNotificationManagerService } from './lv-notification-manager.service';

export interface ILvPublisherState extends ILvNotifierState {
  id: string;
  type: LvNotifierType;
  color?: string;
  linkedSubscribers: {
    [key: string]: boolean;
  };
}

export class LvPublisher extends LvNotifier {

  linkedSubscribers: {
    [key: string]: boolean;
  };

  subscriberAccepted: Subject<ILvNotificationEvent>;
  subsciberCanceled: Subject<ILvNotificationEvent>;

  constructor(lvNotificationManagerService: LvNotificationManagerService) {
    super(lvNotificationManagerService);

    this.type = 'publisher';

    this.linkedSubscribers = {};

    this.subscriberAccepted = new Subject<ILvNotificationEvent>();
    this.subsciberCanceled = new Subject<ILvNotificationEvent>();

    this.receive.subscribe(event => {
      switch (event.type) {
        case ILvNotificationEventType.LINK_ACCEPTED: {
          if (event.to[this.id] && !this.linkedSubscribers[event.from.id]) {
            this.linkedSubscribers[event.from.id] = true;
            this.subscriberAccepted.next(event);
          }
          break;
        }
        case ILvNotificationEventType.LINK_CANCELED: {
          if (event.to[this.id]) {
            delete this.linkedSubscribers[event.from.id];
            this.subsciberCanceled.next(event);
          }
          break;
        }
      }
    });
  }

  public linkSubscriber(subscriberId: string) {
    this.linkedSubscribers[subscriberId] = true;
  }

  public sendCancelLink() {
    const to = {
      ...this.linkedSubscribers
    };

    this.linkedSubscribers = {};

    this.notify.next({
      type: ILvNotificationEventType.LINK_CANCELED,
      from: {
        id: this.id
      },
      to: to
    } as ILvNotificationEvent);
  }

  public sendReadyToLink() {
    this.notify.next({
      type: ILvNotificationEventType.READY_TO_LINK,
      from: {
        id: this.id,
        color: this.color
      }
    } as ILvNotificationEvent);
  }

  public sendCancelReadyToLink() {
    this.notify.next({
      type: ILvNotificationEventType.READY_TO_LINK_CANCELED,
      from: {
        id: this.id,
        color: this.color
      }
    } as ILvNotificationEvent);
  }

  public sendMessage(messageData: any) {
    if (Object.keys(this.linkedSubscribers).length > 0) {
      this.notify.next({
        type: ILvNotificationEventType.MESSAGE,
        from: {
          id: this.id,
          color: this.color
        },
        to: this.linkedSubscribers,
        data: messageData
      } as ILvNotificationEvent);
    }
  }
}
