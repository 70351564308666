import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { IEstimatesSectionSettings } from '@lv-analytics/models';
import { v4 } from 'uuid';

/**
 * Estimates section settings component.
 */
@Component({
  selector: 'lv-estimates-section-settings',
  templateUrl: './lv-estimates-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEstimatesSectionSettingsComponent implements OnInit {

  @Input() settings: IEstimatesSectionSettings;
  @Input() overrideSystemDefaults: boolean;

  get ratioSelected(): boolean {
    return !this.settings.showConversionPrice;
  }

  useCurrentConversionPriceRadioButtonId: string;
  useEstimateConversionPriceRadioButtonId: string;
  displayConversionPriceByDefaultRadioButtonId: string;
  displayConversionRatioByDefaultRadioButtonId: string;
  convertibleCcyRadioButtonId: string;
  underlyingCcyRadioButtonId: string;
  conversionPriceCcyRadioButtonId: string;

  conversionPriceCcyRadioButtonName: string;
  displayConversionPriceRationRadioButtonName: string;
  displayConversionPriceInRadioButtonName: string;

  constructor() {
    this.useCurrentConversionPriceRadioButtonId = v4();
    this.useEstimateConversionPriceRadioButtonId = v4();
    this.displayConversionPriceByDefaultRadioButtonId = v4();
    this.displayConversionRatioByDefaultRadioButtonId = v4();
    this.convertibleCcyRadioButtonId = v4();
    this.underlyingCcyRadioButtonId = v4();
    this.conversionPriceCcyRadioButtonId = v4();

    this.conversionPriceCcyRadioButtonName = v4();
    this.displayConversionPriceRationRadioButtonName = v4();
    this.displayConversionPriceInRadioButtonName = v4();

    this.overrideSystemDefaults = true;
  }

  @HostBinding('class.lv-estimates-section-settings')
  get isEstimatesSectionSettingsComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return false;
  }
  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {

  }
}
