import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { v4 } from 'uuid';
import { LvLookupEnum } from '@lv-core-ui/util';
import { TakeoverProtection, AveragingPeriodPeriodType, AveragingPeriodCalendarType } from '@lv-convertible-bond/models';
import { FxRateTypeDescription } from '@lv-convertible-bond/models/convertible-bond-terms/FxRateType';

@Component({
  selector: 'lv-averaging-period-details',
  templateUrl: './lv-averaging-period-details.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvAveragingPeriodDetailsComponent implements OnInit {
  @Input() model: TakeoverProtection;
  @Output() didSetAveragingPeriodDetails: EventEmitter<TakeoverProtection>;

  averagingPeriodAveragingPeriodPeriodLookup: LvLookupEnum;
  averagingPeriodAveragingPeriodCalendarLookup: LvLookupEnum;
  averagingPeriodTimeBeforeReferenceDatePeriodLookup: LvLookupEnum;
  averagingPeriodTimeBeforeReferenceDateCalendarLookup: LvLookupEnum;
  averagingPeriodFxRateUsedLookup: LvLookupEnum;
  averagingPeriodCompleteMonthCheckBoxId: string;
  averagingPeriodUseFixingHistoryCheckBoxId: string;

  numberOfDecimals = '0';
  numberFormat = 'n0';

  constructor() {
    this.didSetAveragingPeriodDetails = new EventEmitter<TakeoverProtection>();
    this.averagingPeriodAveragingPeriodPeriodLookup = new LvLookupEnum(AveragingPeriodPeriodType);
    this.averagingPeriodAveragingPeriodCalendarLookup = new LvLookupEnum(AveragingPeriodCalendarType);
    this.averagingPeriodTimeBeforeReferenceDatePeriodLookup = new LvLookupEnum(AveragingPeriodPeriodType);
    this.averagingPeriodTimeBeforeReferenceDateCalendarLookup = new LvLookupEnum(AveragingPeriodCalendarType);
    this.averagingPeriodFxRateUsedLookup = new LvLookupEnum(FxRateTypeDescription);
    this.averagingPeriodCompleteMonthCheckBoxId = v4();
    this.averagingPeriodUseFixingHistoryCheckBoxId = v4();
  }

  ngOnInit() {
  }

  doModelChange() {
    this.didSetAveragingPeriodDetails.next(this.model);
  }

  getAveragingPeriodTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
