/**
 * Outputs label.
 */
export enum OutputsLabel {
  theoreticalValue = 'pts',
  cheapness = 'pts',
  impliedVolatility = '%',
  impliedCredit = 'bps',
  percentOfTheory = '%',
  cheapnessPercent = '%',
  expectedLife = 'yrs',
  cheapnessPerYear = 'pts',
  cheapnessPerExpLife = 'pts',
  forwardUnderlyingLevel = ' ',
  parity = 'pts',
  parityPremium = 'pts',
  parityPremiumPercent = '%',
  effParityPremiumPercent = '%',
  parityPremiumClean = 'pts',
  parityPremiumCleanPercent = '%',
  netParity = 'pts' ,
  netParityPremium = 'pts',
  bondValue = 'pts',
  bondPremium = 'pts',
  bondPremiumPercent = '%',
  currentNominalValue = 'curr',
  accretedValue = 'pts',
  redemptionValue = 'pts',
  accretingYield = '%',
  currentYield = '%',
  yieldToMaturity = '%',
  yieldToCall = '%',
  yieldToPut = '%',
  yieldToWorst = '%',
  yieldToOptimal = '%',
  divYieldEst = '%',
  breakeven = 'yrs',
  incomePickup = '%',
  lastFixDate = ' ',
  nextFixDate = ' ',
  modDuration = ' ',
  effDuration = ' ',
  modConvexity = ' ',
  effConvexity = ' ',
  accruedInterest = 'pts',
  riskFreeRate = '%',
  currentEffectiveCallTrigger = '%',
  riskVolatility = '%',
  yearsToPut = ' ',
  yearsToCall = ' ',
  zSpreadMaturity = 'bps',
  zSpreadNextPut = 'bps',
  zSpreadOptimal = 'bps',
  zSpreadCall = 'bps',
  zSpreadWorst = 'bps',
  aSWSpreadMaturity = 'bps',
  aSWSpreadNextPut = 'bps',
  interestSensBucket = ' ',
  delta = '%',
  deltaShares = ' ',
  fXDelta = 'pts',
  eqSensitivity = '%',
  gamma = '%',
  gammaShares = ' ',
  vega = 'pts',
  volga = 'pts',
  vanna = '%',
  theta = 'pts',
  rho = 'pts',
  cSSensIss = 'pts',
  rhoUnd = 'pts',
  cSSens = 'pts',
  creditVega = 'pts',
  stockBorrowSens = 'pts',
  dividendSens = 'pts',
  recoveryRateSens = 'pts',
  lowerCall = ' ',
  higherCall = ' ',
  fwdStock = ' ',
  carryValue = ' ',
  upImpVol = ' ',
  dnImpVol = ' ',
  totalSkew = ' ',
  skewPerThen = ' ',
  deltaImplVol = '%',
  deltaSharesImplVol = ' ',
  deltaFXImplVol = 'pts',
  eqSensitivityImplVol = '%',
  gammaImplVol = '%',
  gammaSharesImplVol = ' ',
  vegaImplVol = 'pts',
  volgaImplVol = 'pts',
  vannaImplVol = '%',
  thetaImplVol = 'pts',
  rhoImplVol = 'pts',
  cSSensIssImplVol = 'pts',
  creditVegaImplVol = 'pts',
  rhoUndImplVol = 'pts',
  cSSensImplVol = 'pts',
  stockBorrowSensImplVol = 'pts',
  dividendSensImplVol = 'pts',
  recoveryRateSensImplVol = 'pts',
  deltaImplSpread = '%',
  deltaSharesImplSpread = ' ',
  deltaFXImplSpread = 'pts',
  eqSensitivityImplSpread = '%',
  gammaImplSpread = '%',
  gammaSharesImplSpread = ' ',
  vegaImplSpread = 'pts',
  volgaImplSpread = 'pts',
  vannaImplSpread = '%',
  thetaImplSpread = 'pts',
  rhoImplSpread = 'pts',
  cSSensIssImplSpread = 'pts',
  creditVegaImplSpread = 'pts',
  rhoUndImplSpread = 'pts',
  cSSensImplSpread = 'pts',
  stockBorrowSensImplSpread = 'pts',
  dividendSensImplSpread = 'pts',
  recoveryRateSensImplSpread = 'pts',
  accruedDays = ' ',
  remainingMW = 'pts',
  probBreachCallTrigger = '%',
  conversionRatioProjected = ' ',
  conversionRebateProjected = 'curr',
  ratchetMatrixMultiple = ' ',
  riskyDiscountFactor = ' ',
  riskFreeDiscountFactor = ' ',
  forwardFXRate = ' ',
}
