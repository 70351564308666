import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input,
  ViewChild, Output, EventEmitter, OnDestroy, Optional, OnChanges } from '@angular/core';

import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LvAdvancedGridComponent, LvAdvancedGridColumn, LvAdvancedGridDateColumn,
         LvAdvancedGridNumericColumn } from '@lv-core-ui/components';
import { LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { IVolatilityTermStructureSchedule, IVolatilityTermStructureScheduleItem } from '@lv-analytics/models/market-data/volatility';
import { MarketDataClipboard } from '@lv-analytics/components/market-data/market-data-clipboard';
import { LvExcelService } from '@lv-excel/services';

/**
 * Volatility term structure component.
 */
@Component({
  selector: 'lv-volatility-term-structure',
  templateUrl: './lv-volatility-term-structure.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvVolatilityTermStructureComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  @Input() volatilityTermStructureSchedule: IVolatilityTermStructureScheduleItem[];

  @Output() volatilityTermStructureChange: EventEmitter<void>;

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAlias)?.editable;
  }

  columns: LvAdvancedGridColumn[];
  parseFn: any;
  excelFieldAlias = 'VOL_SCHED_RANGE';

  private _subscriptions: Subscription[];

  constructor(
    private _errorService: LvErrorService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.volatilityTermStructureSchedule = [];
    this.volatilityTermStructureChange = new EventEmitter<void>();
    this.parseFn = this.parsePastedData.bind(this);
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this.advancedGrid.didDataChange.subscribe((records: IVolatilityTermStructureScheduleItem[]) => this.onDataChange(records)),
      this.advancedGrid.doReload.subscribe(() => this.onReload()),
      this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];

    this.initColumns();
  }

  /**
   * Occurs on changes.
   */
  ngOnChanges() {
    if (this.hasScheduleInExcelOverride) {
      this.initColumns();
    }
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe);
  }

  /**
   * Occurs on data change.
   * @param records List of IVolatilityTermStructureScheduleItem object.
   */
  onDataChange(records: IVolatilityTermStructureScheduleItem[]) {
    this.applyRecords(records);
    this.volatilityTermStructureChange.next();
  }

  /**
   * Applies advanced grid changes.
   */
  applyAdvancedGridChanges() {
    this.advancedGrid.applyChanges(records => this.applyRecords(records));
  }

  /**
   * Occures on reload component.
   */
  onReload() {
    this.volatilityTermStructureSchedule = this.volatilityTermStructureSchedule.map(x => ({ ...x }));
  }

  /**
   * Handles error.
   * @param error LvError object.
   */
  onError(error: LvError) {
    this._errorService.handleError(error);
  }

  /**
   * Creates form group.
   * @param args CreateFormGroupArgs object.
   * @returns FormGroup
   */
  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return new FormGroup({
      'date': new FormControl(args.isNew ? new Date() : args.dataItem.date, Validators.required),
      'value': new FormControl(args.dataItem.value, Validators.required),
    });
  }

  /**
   * Columns initialization.
   */
  initColumns() {
    this.columns = [];

    const endDateColumn = new LvAdvancedGridDateColumn();
    endDateColumn.title = 'End Date';
    endDateColumn.field = 'date';
    endDateColumn.dmKey = 'DM-2343';

    const valueColumn = new LvAdvancedGridNumericColumn();
    valueColumn.title = 'Volatility';
    valueColumn.field = 'value';
    valueColumn.dmKey = 'DM-2344';

    this.columns.push(endDateColumn);
    this.columns.push(valueColumn);
  }

  /**
   * Parse pasted data.
   * @param pastedDataRecords Pasted data records.
   * @returns List of IVolatilityTermStructureSchedule objects.
   */
  parsePastedData(pastedDataRecords: string[]): IVolatilityTermStructureSchedule[] {
    const volatilityTermStructureScheduleItems: IVolatilityTermStructureSchedule[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');

      const dateValue = items[0];
      const valueValue = items[1];

      const date = MarketDataClipboard.parseDateValue(dateValue, 'End Date');
      const value = MarketDataClipboard.parseNumberValue(valueValue, 'Volatility');

      volatilityTermStructureScheduleItems.push({
        date: date,
        value: value
      });
    });

    return volatilityTermStructureScheduleItems;
  }

  /**
   * Applies records.
   * @param records List of IVolatilityTermStructureScheduleItem objects.
   */
  private applyRecords(records: any[]) {
    this.volatilityTermStructureSchedule.splice(0, this.volatilityTermStructureSchedule.length);
    this.volatilityTermStructureSchedule.push(...records);
  }
}
