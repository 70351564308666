import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Kendo
import { ButtonModule } from '@progress/kendo-angular-buttons';

// tslint:disable-next-line: max-line-length
import { LvApplicationVersionModalComponent } from './components/layout/modal/lv-application-version-modal/lv-application-version-modal.component';
import { LvSignalrModalComponent } from './components/layout/modal/lv-signalr-modal/lv-signalr-modal.component';
import { LvStateModalComponent } from './components/layout/modal/lv-state-modal/lv-state-modal.component';
import { LvErrorModalComponent } from './components/layout/modal/lv-error-modal/lv-error-modal.component';
import { LvAuthenticationModalComponent } from './components/layout/modal/lv-authentication-modal/lv-authentication-modal.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule
  ],
  declarations: [
    LvApplicationVersionModalComponent,
    LvSignalrModalComponent,
    LvStateModalComponent,
    LvErrorModalComponent,
    LvAuthenticationModalComponent
  ],
  exports: [
    LvApplicationVersionModalComponent,
    LvSignalrModalComponent,
    LvStateModalComponent,
    LvErrorModalComponent,
    LvAuthenticationModalComponent
  ]
})
export class LeversysCoreUIModalsModule { }
