<lv-flex-box class="lv-takeover-put">
  <lv-flex-box *ngIf="!isAccreated" direction="row" data-cy="DM-1422">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="takeoverPutValueSourceTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'takeoverPutValueSourceTooltip'">
        Takeover Put Value
      </span>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="takeoverPutValuePercent"
        [format]="numberFormat"
        [decimals]="numberOfDecimals"
        [(ngModel)]="model.takeoverPutValuePercent"
        (didBlur)="doModelChange()"
        (didKeyDownEnter)="doModelChange()">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-1423">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="takeoverPutValueTypeSourceTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'takeoverPutValueTypeSourceTooltip'">
        Takeover Put Value Type
      </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="takeoverPutValueType"
        [data]="takeoverPutValueTypeLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 114 }"
        [(ngModel)]="model.takeoverPutValueType"
        (ngModelChange)="doModelChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
</lv-flex-box>

<ng-template #takeoverPutValueSourceTooltipTemplate>
  <lv-tooltip dm="dM-1422"></lv-tooltip>
</ng-template>
<ng-template #takeoverPutValueTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1423"></lv-tooltip>
</ng-template>
