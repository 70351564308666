<lv-flex-box class="lv-convertible-bond-terms-coupon">
  <lv-flex-box direction="row" data-cy="DM-1225">
    <lv-flex-box class="lv-coupon-first-col">
      <lv-flex-box direction="row" data-cy="DM-24">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="couponTypeSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Coupon Type
          </span>
          <lv-xl-label [field]="'CPN_TYPE'"></lv-xl-label>
        </div>
        <div class="lv-input-field lv-input-field--auto">
          <kendo-dropdownlist
            lvId="couponCouponType"
            name="terms-general-coupon-coupon-type"
            [data]="view.couponTypeLookup.items"
            valueField="id"
            textField="text"
            valuePrimitive="true"
            [popupSettings]="{ width: 120 }"
            [(ngModel)]="view.model.type"
            (ngModelChange)="couponTypeChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <ng-container *ngIf="!view.isZeroCouponSelected">
        <lv-flex-box direction="row" data-cy="DM-376">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="couponDayCountSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Coupon Day Count
            </span>
            <lv-xl-label [field]="'CPN_DAY_COUNT'"></lv-xl-label>
          </div>
          <div class="lv-input-field lv-input-field--auto">
            <kendo-dropdownlist
              lvId="couponDayCountCmb"
              name="terms-general-coupon-coupon-day-count"
              [data]="view.couponDayCountLookup.items"
              valueField="id"
              textField="text"
              valuePrimitive="true"
              [(ngModel)]="view.model.dayCount"
              (ngModelChange)="onCouponDaysOrFrequencyChanged()">
            </kendo-dropdownlist>
          </div>
        </lv-flex-box>
        <lv-flex-box direction="row" data-cy="DM-373">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="couponFrequencySourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Coupon Frequency
            </span>
            <lv-xl-label [field]="'CPN_FQ'"></lv-xl-label>
          </div>
          <div class="lv-input-field">
            <kendo-dropdownlist name="terms-general-coupon-coupon-frequency"
              lvId="couponFrequencyCmb"
              [data]="view.couponFrequencyLookup.items"
              valueField="id"
              textField="text"
              valuePrimitive="true"
              [(ngModel)]="view.model.frequency"
              (ngModelChange)="onCouponDaysOrFrequencyChanged()">
            </kendo-dropdownlist>
          </div>
        </lv-flex-box>
        <lv-flex-box direction="row" class="lv-coupon-date" data-cy="DM-973">
          <div class="lv-label lv-label-field lv-label-before-checkbox"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="accrualStartDateSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Accrual Start Date
            </span>
            <lv-xl-label [field]="'CPN_ACC_START_DATE'"></lv-xl-label>
          </div>
          <lv-datepicker
            name="accrualStartDate"
            [checkBoxLvId] = "dpAccStartDateCheckBoxId"
            [datePickerLvId] = "dpAccStartDatePickerId"
            [(ngModel)]="view.accrualStartDate"
            [checked]="view.accrualStartDateEnabled"
            (didCheckboxValueChange)="accrualStartDateCheckboxChanged($event)"
            (ngModelChange)="onModelChange()">
          </lv-datepicker>
        </lv-flex-box>
        <lv-flex-box direction="row" class="lv-coupon-date" data-cy="DM-974">
          <div class="lv-label lv-label-field lv-label-before-checkbox"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="accrualEndDateSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Accrual End Date
            </span>
            <lv-xl-label [field]="'CPN_ACC_END_DATE'"></lv-xl-label>
          </div>
          <lv-datepicker
            name="accrualEndDate"
            [checkBoxLvId] = "dpAccEndDateCheckBoxId"
            [datePickerLvId] = "dpAccEndDatePickerId"
            [(ngModel)]="view.accrualEndDate"
            [checked]="view.accrualEndDateEnabled"
            (didCheckboxValueChange)="accrualEndDateCheckboxChanged($event)"
            (ngModelChange)="onModelChange()">
          </lv-datepicker>
        </lv-flex-box>
        <lv-flex-box direction="row" class="lv-coupon-date" data-cy="DM-375">
          <div class="lv-label lv-label-field lv-label-before-checkbox"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="firstPaymentDateSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              First Payment Date
            </span>
            <lv-xl-label [field]="'CPN_FRST'"></lv-xl-label>
          </div>
          <lv-datepicker
            name="firstCouponPaymentDate"
            [checkBoxLvId] = "dpFirstPaymentDateCheckBoxId"
            [datePickerLvId] = "dpFirstPaymentDatePickerId"
            [(ngModel)]="view.firstCouponPaymentDate"
            [checked]="view.firstCouponPaymentDateEnabled"
            (didCheckboxValueChange)="firstCouponPaymentDateCheckboxChanged($event)"
            (ngModelChange)="onModelChange()">
          </lv-datepicker>
        </lv-flex-box>
        <lv-flex-box direction="row" class="lv-coupon-date" data-cy="DM-975">
          <div class="lv-label lv-label-field lv-label-before-checkbox"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="penPaymentDateSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Pen. Payment Date
            </span>
            <lv-xl-label [field]="'CPN_PEN'"></lv-xl-label>
          </div>
          <lv-datepicker
            name="penultimateCouponPaymentDate"
            [checkBoxLvId] = "dpPenPaymentDateCheckBoxId"
            [datePickerLvId] = "dpPenPaymentDatePickerId"
            [(ngModel)]="view.penultimateCouponPaymentDate"
            [checked]="view.penultimateCouponPaymentDateEnabled"
            (didCheckboxValueChange)="penultimateCouponPaymentDateCheckboxChanged($event)"
            (ngModelChange)="onPenultimateCouponPaymentDateChaged()">
          </lv-datepicker>
        </lv-flex-box>
        <lv-flex-box direction="row" data-cy="DM-976">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="couponDatesEOMSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Coupon Dates EOM
            </span>
            <lv-xl-label [field]="'CPN_DATE_EOM'"></lv-xl-label>
          </div>
          <div class="lv-field lv-field--checkbox">
            <input class="k-checkbox"
              type="checkbox"
              [attr.id]="view.couponDatesEomCheckboxId"
              name="terms-general-coupon-coupon-dates-eom"
              [(ngModel)]="view.model.couponDatesEOM"
              (ngModelChange)="onModelChange()">
            <label class="k-checkbox-label"
              [attr.for]="view.couponDatesEomCheckboxId">
            </label>
          </div>
        </lv-flex-box>
        <lv-flex-box direction="row" class="lv-coupon-date" *ngIf="view.typeChangeDateEnabled" data-cy="DM-988">
          <div class="lv-label lv-label-field lv-label-before-checkbox"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="couponChangedDateSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Coupon Change Date
            </span>
            <lv-xl-label [field]="'CPN_TYPE_CHNG_DATE'"></lv-xl-label>
          </div>
          <lv-datepicker
            name="couponChangedDate"
            [checkBoxLvId] = "dpCouponChangedDateCheckBoxId"
            [datePickerLvId] = "dpCouponChangedDatePickerId"
            [(ngModel)]="view.couponChangedDate"
            [checked]="view.couponChangedDateEnabled"
            (didCheckboxValueChange)="couponChangedDateCheckboxChanged($event)"
            (ngModelChange)="onModelChange()">
          </lv-datepicker>
        </lv-flex-box>
      </ng-container>
    </lv-flex-box>
    <lv-flex-box class="lv-coupon-second-col" *ngIf="!view.isZeroCouponSelected">
      <lv-flex-box direction="row">
        <div class="lv-label lv-label-field">&nbsp;</div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-378">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="accrualMethodSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Accrual Method
          </span>
          <lv-xl-label [field]="'CPN_ACC_METHOD'"></lv-xl-label>
        </div>
        <div class="lv-input-field lv-input-field--auto">
          <kendo-dropdownlist
            lvId="couponAccrualMethodCmb"
            name="terms-general-coupon-accrual-method"
            [data]="view.accrualMethodLookup.items"
            valueField="id"
            textField="text"
            valuePrimitive="true"
            [popupSettings]="{ width: 235 }"
            [(ngModel)]="view.model.accrualMethod"
            (ngModelChange)="onModelChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-971">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="exCouponPeriodSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Ex Coupon Period
          </span>
          <lv-xl-label [field]="'CPN_EX_PERIOD'"></lv-xl-label>
        </div>
        <lv-numeric-text-box
          class="lv-input-field"
          lvId="exCouponPeriod"
          [format]="view.numberFormat"
          [decimals]="view.numberOfDecimals"
          name="terms-general-coupon-ex-coupon-per"
          [(ngModel)]="view.model.exCouponPeriod"
          (didBlur)="onModelChange()"
          (didKeyDownEnter)="onModelChange()">
        </lv-numeric-text-box>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-972">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="exCouponPeriodDaysTypeSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span *ngIf="!isOpenedFromExcel">
            Ex Coupon Period (days type)
          </span>
          <span *ngIf="isOpenedFromExcel">
            Ex Cpn. Period (days type)
          </span>
          <lv-xl-label [field]="'CPN_EX_DATE_TYPE'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <kendo-dropdownlist name="terms-general-coupon-ex-coupon-per-dd"
            lvId="exCouponPeriodDays"
            [data]="view.exCouponPerLookup.items"
            valueField="id"
            name="terms-general-coupon-ex-coupon-per-dd"
            textField="text"
            valuePrimitive="true"
            [(ngModel)]="view.model.exCouponPeriodType"
            (ngModelChange)="onModelChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-977">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="guaranteedCouponsSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Guaranteed Coupons
          </span>
          <lv-xl-label [field]="'CPN_GRNTED'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="view.guaranteedCouponsCheckboxId"
            name="terms-general-coupon-guaranteed-coupons"
            [(ngModel)]="view.model.isGuaranteed"
            (ngModelChange)="onGuarantedCouponChange()">
          <label class="k-checkbox-label"
            [attr.for]="view.guaranteedCouponsCheckboxId">
          </label>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" class="lv-coupon-date" *ngIf="view.model.isGuaranteed" data-cy="DM-978">
        <div class="lv-label lv-label-field lv-label-before-checkbox"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="guaranteedStartDateSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Guaranteed Start Date
          </span>
          <lv-xl-label [field]="'CPN_GRNTED_FRST'"></lv-xl-label>
        </div>
        <lv-datepicker
          name="guaranteedStartDateEnabled"
          [datePickerLvId] = "dpGuaranteedStartDateId"
          [(ngModel)]="view.guaranteedStartDate"
          [checked]="view.guaranteedStartDateEnabled"
          (didCheckboxValueChange)="guaranteedStartCheckBoxChange($event)"
          (ngModelChange)="onModelChange()">
        </lv-datepicker>
      </lv-flex-box>
      <lv-flex-box direction="row" class="lv-coupon-date" *ngIf="view.model.isGuaranteed" data-cy="DM-979">
        <div class="lv-label lv-label-field lv-label-before-checkbox"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="guaranteedEndDateSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Guaranteed End Date
          </span>
          <lv-xl-label [field]="'CPN_GRNTED_LAST'"></lv-xl-label>
        </div>
        <lv-datepicker
          name="guaranteedEndDate"
          [datePickerLvId] = "dpGuaranteedEndDateId"
          [(ngModel)]="view.guaranteedEndDate"
          [checked]="view.guaranteedEndDateEnabled"
          (didCheckboxValueChange)="guaranteedEndCheckBoxChange($event)"
          (ngModelChange)="onModelChange()">
        </lv-datepicker>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-377">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="hasCoPaySourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Has Co Pay
          </span>
          <lv-xl-label [field]="'CPN_COPAY'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="view.hasCoPayCheckboxId"
            name="terms-general-coupon-has-co-pay"
            [(ngModel)]="view.model.hasCoPay"
            (ngModelChange)="hasCoPayChange()">
          <label class="k-checkbox-label"
            [attr.for]="view.hasCoPayCheckboxId">
          </label>
        </div>
      </lv-flex-box>
    </lv-flex-box>
  </lv-flex-box>
  <lv-flex-box *ngIf="!view.isZeroCouponSelected">
    <lv-flex-box class="lv-coupon-fieldset" *ngIf="view.isFixedTypeVisible" direction="row">
      <lv-fieldset-panel legend="FIXED COUPON">
        <lv-fixed-coupon
          [model]="view.model.fixed"
          [instrumentStatus]="view.instrumentStatus"
          (didFixedCouponModelChange)="onModelChange()">
        </lv-fixed-coupon>
      </lv-fieldset-panel>
    </lv-flex-box>
    <lv-flex-box class="lv-coupon-fieldset" *ngIf="view.isCoPayVisible" direction="row">
      <lv-fieldset-panel legend="CO PAY">
        <lv-co-pay-coupon
          [model]="view.model.coPay"
          [accrualStartDateCoPay]="view.accrualStartDateCoPay"
          (didCouponCoPayChange)="onModelChange()">
        </lv-co-pay-coupon>
      </lv-fieldset-panel>
    </lv-flex-box>
    <lv-flex-box class="lv-coupon-fieldset" *ngIf="view.isFloatingTypeVisible" direction="row">
      <lv-fieldset-panel legend="FLOATING COUPON">
          <lv-floating-coupon #floatingComponent
          [model]="view.model.floating"
          [couponType]=view.model.type
          (didFloatingCouponChange)="onModelChange()">
        </lv-floating-coupon>
      </lv-fieldset-panel>
    </lv-flex-box>
    <lv-flex-box class="lv-coupon-fieldset" *ngIf="view.isPikTypeVisible" direction="row">
      <lv-fieldset-panel legend="PIK COUPON">
        <lv-pik-coupon
        [model]="view.model.pik"
        (didPikCouponChange)="onModelChange()">
      </lv-pik-coupon>
      </lv-fieldset-panel>
    </lv-flex-box>
    <lv-flex-box showAfter="1500"
      class="lv-coupon-fieldset" 
      *ngIf="view.isCustomTypeVisible" 
      kendoTooltip 
      [tooltipTemplate]="customCouponScheduleTooltip"
      filter=".lv-coupon-fieldset"
      direction="row">
      <lv-fieldset-panel legend="CUSTOM COUPON SCHEDULE">
        <lv-custom-coupon
          [model]="view.model.custom"
          (didCustomCouponChange)="onModelChange()">
        </lv-custom-coupon>
      </lv-fieldset-panel>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

  <ng-template #couponTypeSourceTooltip>
    <lv-tooltip dm="dM-24"></lv-tooltip>
  </ng-template>
  <ng-template #couponDayCountSourceTooltip>
    <lv-tooltip dm="dM-376"></lv-tooltip>
  </ng-template>
  <ng-template #couponFrequencySourceTooltip>
    <lv-tooltip dm="dM-373"></lv-tooltip>
  </ng-template>
  <ng-template #accrualStartDateSourceTooltip>
    <lv-tooltip dm="dM-973"></lv-tooltip>
  </ng-template>
  <ng-template #accrualEndDateSourceTooltip>
    <lv-tooltip dm="dM-974"></lv-tooltip>
  </ng-template>
  <ng-template #firstPaymentDateSourceTooltip>
    <lv-tooltip dm="dM-375"></lv-tooltip>
  </ng-template>
  <ng-template #penPaymentDateSourceTooltip>
    <lv-tooltip dm="dM-975"></lv-tooltip>
  </ng-template>
  <ng-template #couponDatesEOMSourceTooltip>
    <lv-tooltip dm="dM-976"></lv-tooltip>
  </ng-template>
  <ng-template #couponChangedDateSourceTooltip>
    <lv-tooltip dm="dM-988"></lv-tooltip>
  </ng-template>
  <ng-template #accrualMethodSourceTooltip>
    <lv-tooltip dm="dM-378"></lv-tooltip>
  </ng-template>
  <ng-template #exCouponPeriodSourceTooltip>
    <lv-tooltip dm="dM-971"></lv-tooltip>
  </ng-template>
  <ng-template #exCouponPeriodDaysTypeSourceTooltip>
    <lv-tooltip dm="dM-972"></lv-tooltip>
  </ng-template>
  <ng-template #guaranteedCouponsSourceTooltip>
    <lv-tooltip dm="dM-977"></lv-tooltip>
  </ng-template>
  <ng-template #guaranteedStartDateSourceTooltip>
    <lv-tooltip dm="dM-978"></lv-tooltip>
  </ng-template>
  <ng-template #guaranteedEndDateSourceTooltip>
    <lv-tooltip dm="dM-979"></lv-tooltip>
  </ng-template>
  <ng-template #hasCoPaySourceTooltip>
    <lv-tooltip dm="dM-377"></lv-tooltip>
  </ng-template>
  <ng-template #customCouponScheduleTooltip>
    <lv-tooltip dm="dM-981"></lv-tooltip>
  </ng-template>