import { v4 } from 'uuid';
import { LvLookupEnum, LvMath } from '@lv-core-ui/util';
import { FrequencyDescription } from '@lv-analytics/models';
import { QuickTermsMapHelper } from '@lv-convertible-bond/helpers';
import { SetupStatusQuickTerms, ConvertibleBondSubType, QuickTerms, PriceTalk, ConvertibleBondSubTypeDescription,
         Currency, SetupStatusQuickTermsDescription, CallMakeWholeTypeDescription, DividendProtectionTypeDescription,
         DividendThresholdDescription, ConvertibleBondTermsDocument, ConvertibleBondNewIssueDocument, CallMakeWholeType,
         CurrencyType, 
         StockPriceReference} from '@lv-convertible-bond/models';

export class QuickTermsEntryFieldsVisibilityState {
  discountFieldsVisible: boolean;
  isCocoEndDateVisible: boolean;
  isCocoTriggerVisible: boolean;
  isResetVisible: boolean;
  isResetFloorVisible: boolean;
  isResetMultipliersVisible: boolean;
  isHyperIncrFactorVisible: boolean;
  isHyperStrikeVisible: boolean;

  static getDefault(): QuickTermsEntryFieldsVisibilityState {
    const quickTermsEntryVisibilityState = new QuickTermsEntryFieldsVisibilityState();
    quickTermsEntryVisibilityState.discountFieldsVisible = false;
    quickTermsEntryVisibilityState.isCocoEndDateVisible = true;
    quickTermsEntryVisibilityState.isCocoTriggerVisible = true;
    quickTermsEntryVisibilityState.isResetVisible = true;
    quickTermsEntryVisibilityState.isResetFloorVisible = true;
    quickTermsEntryVisibilityState.isResetMultipliersVisible = true;
    quickTermsEntryVisibilityState.isHyperIncrFactorVisible = true;
    quickTermsEntryVisibilityState.isHyperStrikeVisible = true;

    return quickTermsEntryVisibilityState;
  }

  setPepsOrPercsOrDetachableWarrantVisibility(isPepsOrPercsOrDetachableWarrant: boolean) {
    this.isCocoEndDateVisible = !isPepsOrPercsOrDetachableWarrant;
    this.isCocoTriggerVisible = !isPepsOrPercsOrDetachableWarrant;
    this.isResetVisible = !isPepsOrPercsOrDetachableWarrant;
    this.isResetFloorVisible = !isPepsOrPercsOrDetachableWarrant;
    this.isResetMultipliersVisible = !isPepsOrPercsOrDetachableWarrant;
    this.isHyperIncrFactorVisible = !isPepsOrPercsOrDetachableWarrant;
    this.isHyperStrikeVisible = !isPepsOrPercsOrDetachableWarrant;
}
}

export class LvQuickTermsEntry {

  get isFixed(): boolean {
    return this.quickTerms.setupStatus === SetupStatusQuickTerms.Fixed;
  }

  get isPeps(): boolean {
    return this.quickTerms.convertibleType === ConvertibleBondSubType.PEPS;
  }

  get isPercs(): boolean {
    return this.quickTerms.convertibleType === ConvertibleBondSubType.PERCS;
  }

  get isPerpetual(): boolean {
    return this.quickTerms.perpetual;
  }

  get isDetachableWarrant(): boolean {
    return this.quickTerms.convertibleType === ConvertibleBondSubType.ConvertibleWithDetachableWarrant;
  }

  get isCrossFx(): boolean {
    return this.quickTerms.currency !== this.quickTerms.underlyingCurrency;
  }

  convertibleTypeLookup: LvLookupEnum;
  currencyLookup: LvLookupEnum;
  setupStatusLookup: LvLookupEnum;
  makeWholeTypeLookup: LvLookupEnum;
  protectionTypeLookup: LvLookupEnum;
  dividendThresholdLookup: LvLookupEnum;
  thresholdFrequencyLookup: LvLookupEnum;

  quickTerms: QuickTerms;
  _qtmHelper: QuickTermsMapHelper;

  maturityDatePerpetualCheckboxId: string;
  exchangeableCheckBoxId: string;
  dividendProtectionCheckBoxId: string;
  stockRefCheckBoxId: string;
  premiumLabel: string;
  premiumMid: number;
  higherStrikePremiumMid: number;
  couponMid: number;
  redemptionMid: number;
  issueYieldMid: number;
  issuePriceMid: number;
  isUnderlyingConversionPriceCurrency: boolean;

  fieldsVisibilityState: QuickTermsEntryFieldsVisibilityState;

  numberOfDecimalsPercentageAssumption = '3';
  numberFormatAssumption = '#,###.###';
  numberOfFourDecimalPlaces = '4';
  numberFourFormatAssumption = '#,###.####';
  numberOfDecimalsPercetenge = '2';
  numberOfDecimalsBps = '0';
  numberFormat = '#,###.##';
  numberFormatBps = 'n0';

  constructor() {
    this._qtmHelper = new QuickTermsMapHelper();
    this.quickTerms = new QuickTerms();
    this.quickTerms.priceTalk = new PriceTalk();
    this.quickTerms.stockPriceReference = new StockPriceReference();
    this.quickTerms.setupStatus = SetupStatusQuickTerms.NewIssue;

    this.maturityDatePerpetualCheckboxId = v4();
    this.exchangeableCheckBoxId = v4();
    this.dividendProtectionCheckBoxId = v4();
    this.stockRefCheckBoxId = v4();

    this.convertibleTypeLookup = new LvLookupEnum(ConvertibleBondSubTypeDescription);
    this.currencyLookup = new LvLookupEnum(Currency);
    this.setupStatusLookup = new LvLookupEnum(SetupStatusQuickTermsDescription);
    this.makeWholeTypeLookup = new LvLookupEnum(CallMakeWholeTypeDescription);
    this.protectionTypeLookup = new LvLookupEnum(DividendProtectionTypeDescription);
    this.dividendThresholdLookup = new LvLookupEnum(DividendThresholdDescription);
    this.thresholdFrequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.premiumLabel = 'Premium';
    this.isUnderlyingConversionPriceCurrency = false;

    this.fieldsVisibilityState = QuickTermsEntryFieldsVisibilityState.getDefault();

    this.init();
  }

  init(terms?: ConvertibleBondTermsDocument | ConvertibleBondNewIssueDocument) {
    if (terms) {
      this.quickTerms = this._qtmHelper.getQuickTermsEntryFromConvertibleBond(terms, this.quickTerms);
      this.setVisibleFields();
      this.setPremiumLabelText();
      this.premiumBestOrWorstChange();
      this.couponBestOrWorstChange();
      this.redemptionBestOrWorstChange();
      this.issueYieldBestOrWorstChange();
      this.issuePriceBestOrWorstChange();
      this.setConversionPriceCurrencyType(terms);
    }
  }

  applyConvertibleTypeFieldsVisibility() {
    if (this.quickTerms && this.quickTerms.convertibleType) {
      this.fieldsVisibilityState.setPepsOrPercsOrDetachableWarrantVisibility(this.isPeps || this.isPercs || this.isDetachableWarrant);
      this.setRedemptionValuesAndIssueYield();
    }
  }

  setVisibleFields() {
    this.fieldsVisibilityState.discountFieldsVisible = this.quickTerms.callMakeWholeType === CallMakeWholeType.CouponPV;
    this.applyConvertibleTypeFieldsVisibility();
  }

  setPremiumLabelText() {
    this.premiumLabel = 'Premium';
    if (this.quickTerms.setupStatus === SetupStatusQuickTerms.NewIssue &&
        this.quickTerms.convertibleType === ConvertibleBondSubType.PEPS) {
      this.premiumLabel = 'Higher Strike Premium';
    }
  }

  /**
   * On premium best or worst change update values on price talk.
   * Whether instrument is peps or not we know what is changed so we can update
   * premium and higher strike premium values.  
   */
  premiumBestOrWorstChange() {
    if (this.quickTerms.convertibleType !== ConvertibleBondSubType.PEPS) {

      this.quickTerms.priceTalk.higherStrikePremiumBest = this.quickTerms.priceTalk.premiumBest;
      this.quickTerms.priceTalk.higherStrikePremiumWorst = this.quickTerms.priceTalk.premiumWorst;
      
      if (LvMath.isNumber(this.quickTerms.priceTalk.premiumBest) && LvMath.isNumber(this.quickTerms.priceTalk.premiumWorst)) {
        this.premiumMid = this.bestOrWorstChange(this.quickTerms.priceTalk.premiumBest, this.quickTerms.priceTalk.premiumWorst);
        this.higherStrikePremiumMid =
          this.bestOrWorstChange(this.quickTerms.priceTalk.higherStrikePremiumBest, this.quickTerms.priceTalk.higherStrikePremiumWorst);
      } else {
        this.premiumMid = null;
      }
    }
    else {
      this.quickTerms.priceTalk.premiumBest = this.quickTerms.priceTalk.higherStrikePremiumBest;
      this.quickTerms.priceTalk.premiumWorst = this.quickTerms.priceTalk.higherStrikePremiumWorst;

      if (LvMath.isNumber(this.quickTerms.priceTalk.higherStrikePremiumBest)
        && LvMath.isNumber(this.quickTerms.priceTalk.higherStrikePremiumWorst)) {
    
        this.higherStrikePremiumMid =
          this.bestOrWorstChange(this.quickTerms.priceTalk.higherStrikePremiumBest, this.quickTerms.priceTalk.higherStrikePremiumWorst);

        this.premiumMid = this.bestOrWorstChange(this.quickTerms.priceTalk.premiumBest, this.quickTerms.priceTalk.premiumWorst);
      } else {
        this.higherStrikePremiumMid = null;
      }
      
    }
  }

  couponBestOrWorstChange() {
    if (LvMath.isNumber(this.quickTerms.priceTalk.couponBest) && LvMath.isNumber(this.quickTerms.priceTalk.couponWorst)) {
      this.couponMid = this.bestOrWorstChange(this.quickTerms.priceTalk.couponBest, this.quickTerms.priceTalk.couponWorst);
    } else {
      this.couponMid = null;
    }
  }

  issuePriceBestOrWorstChange() {
    if (LvMath.isNumber(this.quickTerms.priceTalk.issuePriceBest) && LvMath.isNumber(this.quickTerms.priceTalk.issuePriceWorst)) {
      this.issuePriceMid = this.bestOrWorstChange(this.quickTerms.priceTalk.issuePriceBest, this.quickTerms.priceTalk.issuePriceWorst);
    } else {
      this.issuePriceMid = null;
    }
  }

  redemptionBestOrWorstChange() {
    if (LvMath.isNumber(this.quickTerms.priceTalk.redemptionValueBest) && LvMath.isNumber(this.quickTerms.priceTalk.redemptionValueWorst)) {
      // tslint:disable-next-line:max-line-length
      this.redemptionMid = this.bestOrWorstChange(this.quickTerms.priceTalk.redemptionValueBest, this.quickTerms.priceTalk.redemptionValueWorst);
    } else {
      this.redemptionMid = null;
    }
  }

  issueYieldBestOrWorstChange() {
    if (LvMath.isNumber(this.quickTerms.priceTalk.issueYieldBest) && LvMath.isNumber(this.quickTerms.priceTalk.issueYieldWorst)) {
      this.issueYieldMid = this.bestOrWorstChange(this.quickTerms.priceTalk.issueYieldBest, this.quickTerms.priceTalk.issueYieldWorst);
    } else {
      this.issueYieldMid = null;
    }
  }

  bestOrWorstChange(best: number, worst: number): number {
    return (best + worst) / 2;
  }

  setCouponValue() {
    if (this.quickTerms.setupStatus === SetupStatusQuickTerms.Fixed && this.quickTerms.priceTalk) {
        this.quickTerms.coupon = this.quickTerms.priceTalk.couponBest && this.quickTerms.priceTalk.couponWorst
          ? (this.quickTerms.priceTalk.couponBest + this.quickTerms.priceTalk.couponWorst) / 2 : this.quickTerms.coupon;
      } else {
        this.quickTerms.coupon = 'Zero Coupon';
      }
  }

  setRedemptionValuesAndIssueYield() {
    this.redemptionBestOrWorstChange();
    this.issuePriceBestOrWorstChange();
  }

  setRedemptionValuesAndIssueYieldDefaultValue() {
    const defaultValue = this.quickTerms.convertibleType === ConvertibleBondSubType.PEPS
    || this.quickTerms.convertibleType === ConvertibleBondSubType.PERCS ? 0 : 100;

    this.quickTerms.priceTalk.redemptionValueBest = defaultValue;
    this.quickTerms.priceTalk.redemptionValueWorst = defaultValue;
    this.redemptionBestOrWorstChange();
    this.issuePriceBestOrWorstChange();
  }

  private setConversionPriceCurrencyType(terms: ConvertibleBondTermsDocument) {
    if (terms && terms.conversion && terms.conversion.schedule && terms.conversion.schedule.length > 0) {
      this.isUnderlyingConversionPriceCurrency = terms.conversion.schedule[0].conversionPriceCCY === CurrencyType.Underlying;
    } else {
      this.isUnderlyingConversionPriceCurrency = false;
    }
  }
}
