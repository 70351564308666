import { Component, OnInit, Input, Output, EventEmitter, ViewChild,
  OnDestroy, OnChanges, ViewEncapsulation, ChangeDetectionStrategy, ElementRef, Optional } from '@angular/core';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { constants, LvLookupEnum } from '@lv-core-ui/util';
import { v4 } from 'uuid';
import { LvAdvancedGridComponent, LvAdvancedGridColumn, LvAdvancedGridDateColumn,
         LvAdvancedGridEnumColumn, LvAdvancedGridNumericColumn } from '@lv-core-ui/components';
import { LvDataMaster, LvError, LvErrorType } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { MarketDataClipboard } from '@lv-analytics/components';
import { ContingentConversion, ContingentConversionScheduleItem, ContingentConversionPeriodTypeDescription,
         ContingentConversionScheduleTriggerTypeDescription,
         ContingentConversionScheduleItemTypeDescription } from '@lv-convertible-bond/models';
import { ContingentConversionScheduleTriggerType, QuarterType, ContingentConversionTriggerParityLimit,
         ContingentConversionScheduleItemType } from '@lv-convertible-bond/models/convertible-bond-terms/Enums (2)';
import { LvExcelService } from '@lv-excel/services';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';

@Component({
  selector: 'lv-contingent-conversion',
  templateUrl: './lv-contingent-conversion.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvContingentConversionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() model: ContingentConversion;
  @Output() didContingentConversionChange: EventEmitter<ContingentConversion>;

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  columns: LvAdvancedGridColumn[];
  scheduleItems: ContingentConversionScheduleItem[];
  parseFn: any;
  
  quarterTypeLookup: LvLookupEnum;
  triggerPeriodTypeLookup: LvLookupEnum;
  cbPriceTriggerParityLimitLookup: LvLookupEnum;
  triggerType: ContingentConversionScheduleTriggerType;
  triggerTypeLookup: LvLookupEnum;
  
  lastCocoTriggerId: string;
  convertibleOnCall: string;
  buyoutAtCocoTrigger: string;
  isLoading: boolean;
  
  numberOfDecimals = '0';
  numberFormat = 'n0';
  
  formatThree = '#,###.###';
  decimalsThree = '3';
  
  formatFour = '#,###.####';
  decimalsFour = '4';
  excelFieldAlias = 'CO_CO_SCHED_RANGE';
  
  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias);
  }
  
  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAlias)?.editable;
  }
  
  get isExcelInstrument(): boolean {
    return this._excelSvc && this._excelSvc.isInitialized();
  }
  
  get triggerParityLimitLabel(): string {
    return this.isExcelInstrument ? 'CB Price Trigg. Pty Limit' : 'CB Price Trigger Parity Limit';
  }
  
  private subscriptions: Subscription[];

  constructor(
    private _errorService: LvErrorService,
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService,
    @Optional() private _excelSvc: LvExcelService) {
    this.quarterTypeLookup = new LvLookupEnum(QuarterType);
    this.triggerPeriodTypeLookup = new LvLookupEnum(ContingentConversionPeriodTypeDescription);
    this.cbPriceTriggerParityLimitLookup = new LvLookupEnum(ContingentConversionTriggerParityLimit);
    this.triggerTypeLookup = new LvLookupEnum(ContingentConversionScheduleTriggerTypeDescription);
    this.didContingentConversionChange = new EventEmitter<ContingentConversion>();

    this.lastCocoTriggerId = v4();
    this.convertibleOnCall = v4();
    this.buyoutAtCocoTrigger = v4();

    this.initColumns();
    this.parseFn = this.parserFunction.bind(this);
    this.scheduleItems = [];
    this.isLoading = false;
  }

  onContingentConversionChange() {
    this.applyRecords(this.model.schedule);
    this.didContingentConversionChange.next(this.model);
  }

  ngOnInit() {
    this.subscriptions = [
      this.advancedGrid.didDataChange.subscribe((records: ContingentConversionScheduleItem[]) => this.onScheduleChange(records)),
      this.advancedGrid.doReload.subscribe(() => this.onScheduleReload()),
      this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];

    this.init();
  }

  ngOnChanges() {
    if (this.isExcelInstrument) {
      this.initColumns();
    }
    this.init();
  }

  createFormGroup(args: CreateFormGroupArgs): FormGroup {
    args.dataItem.type =  args.dataItem.type ? args.dataItem.type : ContingentConversionScheduleItemType.Quarterly_NextQuarter;
    // tslint:disable-next-line:max-line-length
    args.dataItem.triggerType = args.dataItem.triggerType ? args.dataItem.triggerType : ContingentConversionScheduleTriggerType.AsPercentOfPar;
    return  new FormGroup({
      'startDate': new FormControl(args.isNew ? new Date() : args.dataItem.startDate, Validators.required),
      'endDate': new FormControl(args.isNew ? new Date() : args.dataItem.endDate),
      'type': new FormControl(args.dataItem.type),
      'initialTrigger': new FormControl(args.dataItem.initialTrigger, Validators.required),
      'triggerType': new FormControl(args.dataItem.triggerType),
      'changeRate': new FormControl(args.dataItem.changeRate),
      'triggerCapFloor': new FormControl(args.dataItem.triggerCapFloor)
    });
  }

  applyAdvancedGridChanges() {
    this.advancedGrid.applyChanges(records => this.applyRecords(records));
  }

  private init() {

    this.scheduleItems = this.model.schedule.map(a => ({
      ...a,
      // tslint:disable-next-line: max-line-length
      triggerType: a.triggerType && a.triggerType.toString() !== '0' ? a.triggerType : ContingentConversionScheduleTriggerType.AsPercentOfPar
    }));

    if (this.scheduleItems && this.scheduleItems.length > 0) {
      // tslint:disable-next-line:max-line-length
      this.triggerType = this.scheduleItems[0].triggerType.toString() !== '0' ? this.scheduleItems[0].triggerType : ContingentConversionScheduleTriggerType.AsPercentOfPar;
    } 
    else if (this._lvConvertibleBondTermsService.triggerTypeFromSettings) {
      this.triggerType = this._lvConvertibleBondTermsService.triggerTypeFromSettings;
    }
    else {
      this.triggerType = ContingentConversionScheduleTriggerType.AsPercentOfPar;
    }
  }

  private initColumns() {
    this.columns = [];

    const startDateColumn = new LvAdvancedGridDateColumn();
    startDateColumn.title = 'Start Date';
    startDateColumn.field = 'startDate';
    startDateColumn.dmKey = 'DM-2318';

    const endDateColumn = new LvAdvancedGridDateColumn();
    endDateColumn.title = 'End Date';
    endDateColumn.field = 'endDate';
    endDateColumn.dmKey = 'DM-2319';

    const typeColumn = new LvAdvancedGridEnumColumn();
    typeColumn.enumDescription = ContingentConversionScheduleItemTypeDescription;
    typeColumn.title = 'Type';
    typeColumn.field = 'type';
    typeColumn.displayField = 'text';
    typeColumn.valueField = 'id';
    typeColumn.valuePrimitive = true;
    typeColumn.width = 164;
    typeColumn.dmKey = 'DM-2320';
    typeColumn.data = new LvLookupEnum(ContingentConversionScheduleItemTypeDescription).items;

    const initialTriggerColumn = new LvAdvancedGridNumericColumn();
    initialTriggerColumn.title = this._excelSvc?.isInitialized() ? 'Initial Trigg. (%)' : 'Initial Trigger (%)';
    initialTriggerColumn.field = 'initialTrigger';
    initialTriggerColumn.outputFormat = constants.numberFormat.upToFourDigits;
    initialTriggerColumn.format = this.formatFour;
    initialTriggerColumn.decimals = this.decimalsFour;
    initialTriggerColumn.dmKey = 'DM-2321';

    const triggerTypeColumn = new LvAdvancedGridEnumColumn();
    triggerTypeColumn.enumDescription = ContingentConversionScheduleTriggerTypeDescription;
    triggerTypeColumn.title = 'Trigger Type';
    triggerTypeColumn.field = 'triggerType';
    triggerTypeColumn.displayField = 'text';
    triggerTypeColumn.valueField = 'id';
    triggerTypeColumn.valuePrimitive = true;
    triggerTypeColumn.visible = false;
    triggerTypeColumn.data = new LvLookupEnum(ContingentConversionScheduleTriggerTypeDescription).items;

    const changeRateColumn = new LvAdvancedGridNumericColumn();
    changeRateColumn.title = 'Change Rate';
    changeRateColumn.field = 'changeRate';
    changeRateColumn.outputFormat = constants.numberFormat.upToFourDigits;
    changeRateColumn.format = this.formatFour;
    changeRateColumn.decimals = this.decimalsFour;
    changeRateColumn.dmKey = 'DM-2322';

    const triggerCapFloorColumn = new LvAdvancedGridNumericColumn();
    triggerCapFloorColumn.title = 'Trig. Cap/Floor (%)';
    triggerCapFloorColumn.field = 'triggerCapFloor';
    triggerCapFloorColumn.outputFormat = constants.numberFormat.upToThreeDigits;
    triggerCapFloorColumn.format = this.formatThree;
    triggerCapFloorColumn.decimals = this.decimalsThree;
    triggerCapFloorColumn.dmKey = 'DM-2323';

    this.columns.push(startDateColumn);
    this.columns.push(endDateColumn);
    this.columns.push(typeColumn);
    this.columns.push(initialTriggerColumn);
    this.columns.push(triggerTypeColumn);
    this.columns.push(changeRateColumn);
    this.columns.push(triggerCapFloorColumn);
  }

  private parserFunction(pastedDataRecords: string[]): ContingentConversionScheduleItem[] {
    const scheduleItems: ContingentConversionScheduleItem[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');

      const startDateValue = items[0];
      const endDateValue = items[1];
      const typeValue = items[2];
      const initialTriggerValue = items[3];
      const triggerTypeValue = items[4];
      const changeRateValue = items[5];
      const triggerCapFloorValue = items[6];

      const startDate = MarketDataClipboard.parseDateValue(startDateValue, 'Start Date');
      const endDate = MarketDataClipboard.parseDateValue(endDateValue, 'End Date');
      const type = this.parseTypeValue(typeValue);
      const initialTrigger = MarketDataClipboard.parseNumberValue(initialTriggerValue, 'Initial Trigger (%)');
      const triggerType = this.parseTriggerTypeValue(triggerTypeValue);
      const changeRate = MarketDataClipboard.parseNumberValue(changeRateValue, 'Change Rate');
      const triggerCapFloor = MarketDataClipboard.parseNumberValue(triggerCapFloorValue, 'Trigger Cap/Floor (%)');

      scheduleItems.push({
        startDate: startDate,
        endDate: endDate,
        type: type,
        initialTrigger: initialTrigger,
        triggerType: triggerType,
        changeRate: changeRate,
        triggerCapFloor: triggerCapFloor
      } as ContingentConversionScheduleItem);
    });

    return scheduleItems;
  }

  private parseTypeValue(typeValue: string): ContingentConversionScheduleItemType {

    if (MarketDataClipboard.isEmpty(typeValue)) {
      return ContingentConversionScheduleItemType.Quarterly_AnyTime;
    }

    const lvTypeDescription = new LvLookupEnum(ContingentConversionScheduleItemTypeDescription);
    const lvTypeDescriptionEnum = lvTypeDescription.items.find(
      a => a.text === typeValue
    );
    if (!lvTypeDescriptionEnum) {
      const errorMessage = LvDataMaster.getErrorWithParameters('dM-2003', new Map([['{ value}', typeValue], ['{field}', 'Contingent Conversion Schedule Item Type']]));
      throw new LvError(errorMessage, 'Paste Error', LvErrorType.USER_FRIENDLY);
    }

    return ContingentConversionScheduleItemType[lvTypeDescriptionEnum.id];
  }

  private parseTriggerTypeValue(triggerTypeValue: string): ContingentConversionScheduleTriggerType {

    if (MarketDataClipboard.isEmpty(triggerTypeValue)) {
      return ContingentConversionScheduleTriggerType.AsPercentOfPar;
    }

    const lvTriggerTypeDescription = new LvLookupEnum(ContingentConversionScheduleTriggerTypeDescription);
    const lvTriggerTypeDescriptionEnum = lvTriggerTypeDescription.items.find(
      a => a.text === triggerTypeValue
    );
    if (!lvTriggerTypeDescriptionEnum) {
      const errorMessage = LvDataMaster.getErrorWithParameters('dM-2003', new Map([['{ value}', triggerTypeValue], ['{field}', 'Contingent Conversion Schedule Trigger']]));
      throw new LvError(errorMessage, 'Paste Error', LvErrorType.USER_FRIENDLY);
    }

    return ContingentConversionScheduleTriggerType[lvTriggerTypeDescriptionEnum.id];
  }

  private onScheduleChange(scheduleItems: ContingentConversionScheduleItem[]) {
    this.applyRecords(scheduleItems);
  }

  private applyRecords(records: any[]) {
    this.scheduleItems = records.map(a => ({
      ...a,
      triggerType: this.triggerType
    }));

    this.model.schedule.splice(0, this.model.schedule.length);
    this.model.schedule.push(...this.scheduleItems);
    this.didContingentConversionChange.next(this.model);
  }

  private onScheduleReload() {
    this.scheduleItems = this.scheduleItems.map(a => ({ ...a }));
  }

  private onError(error: LvError) {
    this._errorService.handleError(error);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
