import { SeriesType, XAxis, YAxis, SeriesTooltip, XAxisLabels, Margin, Padding } from '@progress/kendo-angular-charts';
import { NumberPipe } from '@progress/kendo-angular-intl';

import { ILvScenarioReport, getDefaultScenarioReport } from '../lv-scenario.view';
import { LvLocalDatePipe } from '@lv-core-ui/pipes';
import { LvMath } from '@lv-core-ui/util';
import { ScenarioPriceTalk, ScenarioOutputDescription, ScenarioInputsShift, ICalculateScenarioOutput, IOutputValue,
         formatUnderlyingPrice, formatUnderlyingCBPrice, ScenarioInputsShiftDescription } from '@lv-analytics/models';

export interface AxisOffset {
  minOffset?: number;
  maxOffset?: number;
  isTimeAxis?: boolean;
}

export type LvXAxis = XAxis & AxisOffset;
export type LvYAxis = YAxis & AxisOffset;

interface IMultiValueAxis<T> {
  axisValues?: {
    [axisValue: string]: T;
  };
  currentAxisValue?: string;
}

export type LvMultiValueYAxis = LvYAxis & IMultiValueAxis<LvYAxis>;

export interface ILvAxisTitle {
  text: string;
  margin: Margin;
  padding: Padding;
  font: string;
}

export interface ILvScatterLineChartSeries {
  type: SeriesType;
  name: string;
  xField: string;
  yField: string;
  tooltip?: SeriesTooltip;
  format?: string;
}

export interface ILvScatterLineChart {
  records: any[];
  xAxis: LvXAxis;
  yAxis: LvMultiValueYAxis;
  series: ILvScatterLineChartSeries[];
}

/**
 * Scenario chart view.
 */
export abstract class LvScenarioChartView<T extends ILvScatterLineChart> {
  scenarioPriceTalk = ScenarioPriceTalk;
  scenarioOutputDescription = ScenarioOutputDescription;

  get showInputsAsPercentage(): boolean {
    return !!this.report.showInputsAsPercentage;
  }

  get showFirstDimensionAsPercentage(): boolean {
    return this.showInputsAsPercentage && this.report.showInputsAsPercentage.firstDimension;
  }

  get firstDimensionChangeRef(): number {
    return this.report.showInputsAsPercentage.firstDimensionChangeRef;
  }

  get showSecondDimensionAsPercentage(): boolean {
    return this.showInputsAsPercentage && this.report.showInputsAsPercentage.secondDimension;
  }

  get secondDimensionChangeRef(): number {
    return this.report.showInputsAsPercentage.secondDimensionChangeRef;
  }

  report: ILvScenarioReport;
  model: T;

  numberFormat: string;
  percentFormat: string;
  defaultRotation: number;
  defaultOutputsLabel: string;

  defaultTooltipBackground: string;
  defaultTooltipColor: string;

  constructor(
    public numberPipe: NumberPipe,
    public datePipe: LvLocalDatePipe
  ) {
    this.numberFormat = 'n3';
    this.percentFormat = '1.0-3';
    this.defaultRotation = -45;
    this.defaultOutputsLabel = 'Outputs';

    this.defaultTooltipBackground = '#1E8FE1';
    this.defaultTooltipColor = '#FFFFFF';
  }

  /**
   * Does initialization.
   * @param report ILvScenarioReport object.
   */
  init(report?: ILvScenarioReport): void {
    this.report = getDefaultScenarioReport(report);
    this.model = this.getDefaultModel();

    if (this.report.data && this.report.data.length > 0) {
      this.doInit(this.report);

      this.setAxisOffset(this.model.xAxis);

      this.setAxisOffset(this.model.yAxis);
      this.setMultiValueAxisOffsets(this.model.yAxis);
      this.setMultiValueAxisCurrentOffset(this.model.yAxis);
    }
  }

  /**
   * Checks if index is first.
   * @param index Index.
   * @returns A flag indicating if index is first.
   */
  isFirstIndex(index: number) {
    return index === 0;
  }

  /**
   * Checks if value is index.
   * @param index Index.
   * @param toCompare Number to compare.
   * @returns A flag indicating if value is index.
   */
  isIndex(index: number, toCompare: number) {
    return index === toCompare;
  }

  /**
   * Checks if index is last.
   * @param index Index.
   * @returns A flag indicating if index is last.
   */
  isLastIndex(array: any[], index: number) {
    return array.length - 1 === index;
  }

  /**
   * Checks if values are first indexes.
   * @param indexes List of indexes.
   * @returns A flag indicating if values are first indexes.
   */
  areFirstIndexes(...indexes: number[]) {
    for (let i = 0; i < indexes.length; i++) {
      if (indexes[i] > 0) {
        return false;
      }
    }

    return true;
  }

  /**
   * Gets Y axis.
   * @returns LvYAxis object.
   */
  getYAxis(): LvYAxis {
    return {
      title: {
        text: this.defaultOutputsLabel
      },
      labels: {
        format: this.numberFormat
      } as XAxisLabels
    };
  }

  /**
   * Gets X axis.
   * @param dimension Scenario inputs shift.
   * @returns LvYAxis object.
   */
  getXAxis(dimension: ScenarioInputsShift): LvXAxis {
    const axis = {
      title: {
        text: this.getFirstDimensionDescription(dimension)
      },
      labels: {
        rotation: this.defaultRotation
      } as XAxisLabels
    } as LvXAxis;

    if (this.isValuationDateShift(dimension)) {
      axis.labels.content = ({value}: { value: number }) => {
        return this.datePipe.transform(value);
      };

      axis.isTimeAxis = true;
    }
    else {
      axis.labels.format = this.numberFormat;
    }

    return axis;
  }

  /**
   * Gets second dimension title.
   * @param dimension Scenario inputs shift.
   * @returns ILvAxisTitle object.
   */
  getSecondDimensionTitle(dimension: ScenarioInputsShift): ILvAxisTitle {
    return {
      text: this.getFirstDimensionDescription(dimension),
      margin: {
        bottom: -10
      },
      padding: {
        left: 60
      },
      font: 'inherit'
    };
  }

  /**
   * Gets series.
   * @param dimension ICalculateScenarioOutput object.
   * @param output IOutputValue object.
   * @returns ILvScatterLineChartSeries object.
   */
  getSeries<S extends ILvScatterLineChartSeries>(dimension: ICalculateScenarioOutput, output: IOutputValue): S {
    return {
      type: 'scatterLine',
      name: this.scenarioOutputDescription[output.output],
      xField: dimension.dimension,
      yField: output.output,
      tooltip: {
        format: `${this.scenarioOutputDescription[output.output]} {1:n3}`
      }
    } as S;
  }

  /**
   * Gets first dimension description.
   * @param dimension Scenario inputs shift.
   * @returns First dimension description.
   */
  getFirstDimensionDescription(dimension: ScenarioInputsShift): string {
    if (dimension === ScenarioInputsShift.UnderlyingPrice) {
      return formatUnderlyingPrice(this.report.underlyingCurrencyCode);
    }

    if (dimension === ScenarioInputsShift.UnderlyingPriceCB
        && this.report.underlyingCBCurrencyCode !== this.report.underlyingCurrencyCode) {
        return formatUnderlyingCBPrice(this.report.underlyingCBCurrencyCode);
    }

    return ScenarioInputsShiftDescription[dimension];
  }

  /**
   * Gets price talk description.
   * @param priceTalk Scenario price talk.
   * @returns Price talk description.
   */
  getPriceTalkDescription(priceTalk: ScenarioPriceTalk): string {
    return ScenarioPriceTalk[priceTalk];
  }

  /**
   * Checks if valuation date is shift.
   * @param dimension Scenario inputs shift.
   * @returns A flag indicating if valuation date is shift.
   */
  isValuationDateShift(dimension: ScenarioInputsShift): boolean {
    return dimension === ScenarioInputsShift.ValuationDate;
  }

  /**
   * Set Y axis min and max.
   * @param value
   */
  setYAxisMinAndMax(value: number) {
    this.setAxisMinAndMax(this.model.yAxis, value);
  }

  /**
   * Sets multi value Y axis min and max.
   * @param axisValue Axis value.
   * @param value Value.
   */
  setMultiValueYAxisMinAndMax(axisValue: string, value: number) {
    this.setMultiValueAxisMinAndMax(this.model.yAxis, axisValue, value);
  }

  /**
   * Sets multivalue Y axis current value.
   * @param axisValue Axis value.
   */
  setMultiValueYAxisCurrentValue(axisValue: string) {
    this.model.yAxis.currentAxisValue = axisValue;
  }

  /**
   * Sets multivalue Y axis current offset.
   * @param axisValue Axis value.
   */
  setMultiValueYAxisCurrentOffset(axisValue: string) {
    this.setMultiValueYAxisCurrentValue(axisValue);
    this.setMultiValueAxisCurrentOffset(this.model.yAxis);
  }

  /**
   * Sets multivalue Y axis min and max value.
   * @param axis IMultiValueAxis<LvYAxis> value.
   * @param outputByPriceTalk Output by price talk.
   * @param value Axis value.
   */
  private setMultiValueAxisMinAndMax(axis: IMultiValueAxis<LvYAxis>, outputByPriceTalk: string, value: number) {
    if (!axis.axisValues) {
      axis.axisValues = {};
    }

    if (!axis.axisValues[outputByPriceTalk]) {
      axis.axisValues[outputByPriceTalk] = {};
    }

    this.setAxisMinAndMax(axis.axisValues[outputByPriceTalk], value);
  }

  /**
   * Sets multivalue axis current offset.
   * @param axis LvMultiValueYAxis object.
   */
  private setMultiValueAxisCurrentOffset(axis: LvMultiValueYAxis) {
    if (axis.axisValues) {
      const axisVal = axis.axisValues[axis.currentAxisValue];
      axis.minOffset = axisVal.minOffset;
      axis.maxOffset = axisVal.maxOffset;
    }
  }

  /**
   * Sets multivalue axis offsets.
   * @param axis LvMultiValueYAxis object.
   */
  private setMultiValueAxisOffsets(axis: LvMultiValueYAxis) {
    if (axis.axisValues) {
      Object.keys(axis.axisValues).forEach(a => this.setAxisOffset(axis.axisValues[a]));
    }
  }

  /**
   * Sets axis min and max value.
   * @param axis LvYAxis object.
   * @param value Value.
   */
  private setAxisMinAndMax(axis: LvYAxis, value: number) {
    if (!LvMath.isNumber(axis.min)) {
      axis.min = value;
    }

    if (!LvMath.isNumber(axis.max)) {
      axis.max = value;
    }

    if (value > axis.min && value < axis.max) {
      return;
    }

    if (value < axis.min) {
      axis.min = value;
    }

    if (value > axis.max) {
      axis.max = value;
    }
  }

  /**
   * Sets axis offset.
   * @param axis LvXAxis object.
   */
  private setAxisOffset(axis: LvXAxis | LvYAxis) {
    axis.minOffset = axis.isTimeAxis ? this.addDateOffset(axis.min, -1) : Math.floor(this.addOffset(axis.min, 0.9));
    axis.maxOffset = axis.isTimeAxis ? this.addDateOffset(axis.max, 1) : Math.ceil(this.addOffset(axis.max, 1.1));
  }

  /**
   * Add offset.
   * @param value Value.
   * @param offset Offset.
   * @returns Value multiplied by offset.
   */
  private addOffset(value: number, offset: number) {
    return value * offset;
  }

  /**
   * Adds date offset.
   * @param date Date.
   * @param days Number of days.
   * @returns Date with offset.
   */
  private addDateOffset(date: number, days: number) {
    const d = new Date(date);
    d.setDate(d.getDate() + days);
    return d.getTime();
  }

  /**
   * Gets default model.
   */
  abstract getDefaultModel(): T;

  /**
   * Does initialization.
   * @param report ILvScenarioReport object.
   */
  abstract doInit(report: ILvScenarioReport): void;
}
