import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, EventEmitter,
  Output, ChangeDetectorRef, HostBinding, OnChanges } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { IShiftParametersSectionSettings } from '@lv-analytics/models/company-and-user-settings/valuation-settings/shift-parameters/shift-parameters-section-settings';
import { IValuationSettings } from '@lv-analytics/models/company-and-user-settings/valuation-settings/valuation-settings';
import { CompanyAndUserSettingsService } from '@lv-analytics/services/company-settings/company-settings.service';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { IWarningsSectionSettings } from '../../../models/company-and-user-settings/valuation-settings/warnings/warnings-section-settings';
import { IWarningsSettings } from '../../../models/company-and-user-settings/valuation-settings/warnings/warnings-settings';
import { IShiftParametersSettings, ITenor } from '@lv-analytics/models';
import { IBucketingSettings } from '@lv-analytics/models/company-and-user-settings/valuation-settings/bucketing/bucketing-settings';
import { IBucketingSectionSettings } from '@lv-analytics/models/company-and-user-settings/valuation-settings/bucketing/bucketing-section-settings';

/**
 * Valuation settings component.
 */
@Component({
  selector: 'lv-valuation-settings',
  templateUrl: './lv-valuation-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvValuationSettingsComponent implements OnInit {

  @Output() didCancel: EventEmitter<void>;
  @Output() didSave: EventEmitter<void>;

  settings: IValuationSettings;
  tenors: ITenor[];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _companyAndUserSettingsService: CompanyAndUserSettingsService
  ) {
    this.didCancel = new EventEmitter<void>();
    this.didSave = new EventEmitter<void>();

    this.settings = {
      shiftParametersSettings: {
        systemDefaults: {} as IShiftParametersSectionSettings,
        environments: []
      } as IShiftParametersSettings,
      warningsSettings: {
        systemDefaults: {} as IWarningsSectionSettings,
        environments: []
      } as IWarningsSettings,
      bucketingSettings: {
        systemDefaults: {
          bucketList: [],
        } as IBucketingSectionSettings,
        environments: []
      } as IBucketingSettings
    };

    this.tenors = [];
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-valuation-settings')
  get isLvValuationSettingsComponent() {
    return true;
  }

  @HostBinding('class.default-settings-height')
  get isDefaultSettingsHeight() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.loadData();
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
    this.didCancel.emit();
  }

  /**
   * Saves valuation settings.
   */
  async onSave() {
    try {
      await this._companyAndUserSettingsService.saveValuationSettings({
        settings: this.settings
      });
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-3388',
        {'value': 'Valuation Settings'}));

      this.didSave.emit();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
  }

  /**
   * Resets settings to default settings.
   */
  async onResetToDefault() {
    try {
      const response = await this._companyAndUserSettingsService.resetValuationSettings();

      this.settings.shiftParametersSettings = response.settings.shiftParametersSettings;
      this.settings.warningsSettings = response.settings.warningsSettings;
      this.settings.bucketingSettings = response.settings.bucketingSettings;

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1816',
        {'settings_section': 'Valuation Settings'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Loads valuation settings.
   */
  private async loadSettings() {
    try {
      const response = await this._companyAndUserSettingsService.getValuationSettings();
      this.settings = response.settings;
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Load all tenors from api.
   */
  private async loadTenors() {
    try {
      this.tenors = await this._companyAndUserSettingsService.getAllTenors();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  } 

  /**
   * Load data for tab components.
   */
  private async loadData() {
    Promise.all([this.loadTenors(), this.loadSettings()]);
  }
}
