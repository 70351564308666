import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { IFundingSectionSettings } from '@lv-analytics/models';

/**
 * Funding section settings component.
 */
@Component({
  selector: 'lv-funding-section-settings',
  templateUrl: './lv-funding-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvFundingSectionSettingsComponent implements OnInit {

  @Input() settings: IFundingSectionSettings;
  @Input() overrideSystemDefaults: boolean;

  numberOfDecimals = 2;
  numberFormat = '#.##';

  constructor() {
    this.overrideSystemDefaults = true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }
}
