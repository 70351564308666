import { LvScenarioChartView, ILvScatterLineChart, ILvScatterLineChartSeries, ILvAxisTitle } from '../lv-scenario-chart.view';
import { ILvScenarioReport } from '../../lv-scenario.view';
import { ScenarioOutput, ICalculateScenarioOutputVector } from '@lv-analytics/models';

export interface ITwoDimChartSeries extends ILvScatterLineChartSeries {
  output: ScenarioOutput;
}

export interface ITwoDimChart extends ILvScatterLineChart {
  outputs: ScenarioOutput[];
  currentOutput: ScenarioOutput;
  secondDimensionTitle: ILvAxisTitle;
  series: ITwoDimChartSeries[];
}

/**
 * Scenario two dim chart view.
 */
export class LvScenarioTwoDimChartView extends LvScenarioChartView<ITwoDimChart> {

  /**
   * Gets default model.
   * @returns ITwoDimChart object.
   */
  getDefaultModel(): ITwoDimChart {
    return {
      xAxis: {},
      yAxis: {},
      secondDimensionTitle: {} as ILvAxisTitle,
      series: [],
      records: [],
      outputs: [],
      currentOutput: null
    };
  }

  /**
   * Does initialization.
   * @param report ILvScenarioReport object.
   */
  doInit(report: ILvScenarioReport): void {
    const mapped = (report.data as ICalculateScenarioOutputVector[]);

    this.model.yAxis = this.getYAxis();

    mapped.forEach((fDim, i, fDimArray) => {
      if (this.isFirstIndex(i)) {
        this.model.xAxis = this.getXAxis(fDim.dimension);
        this.model.xAxis.min = fDim.value;
      }

      const rec = {
        [fDim.dimension]: fDim.value
      };

      fDim.outputs.forEach((sDim, j, arr) => {
        if (this.areFirstIndexes(i, j)) {
          this.model.secondDimensionTitle = this.getSecondDimensionTitle(sDim.dimension);
        }

        sDim.outputs.forEach((output, k) => {
          const valueId = `${sDim.value}_${output.output}`;

          if (this.isFirstIndex(i)) {
            const series = this.getSeries<ITwoDimChartSeries>(sDim, output);

            if (this.isValuationDateShift(sDim.dimension)) {
              series.name = this.datePipe.transform(sDim.value);
            }
            else {
              series.name = this.numberPipe.transform(sDim.value, this.numberFormat);
            }

            series.xField = fDim.dimension;
            series.yField = valueId;
            series.output = output.output;
            this.model.series.push(series);
          }

          if (this.areFirstIndexes(i, j)) {
            this.model.outputs.push(output.output);
          }

          if (this.areFirstIndexes(i, j, k)) {
            this.model.currentOutput = output.output;
            this.setMultiValueYAxisCurrentValue(output.output);
          }

          const assumed = output.values[0];
          rec[valueId] = assumed.value;

          this.setMultiValueYAxisMinAndMax(output.output, assumed.value);
        });
      });

      if (this.isLastIndex(fDimArray, i)) {
        this.model.xAxis.max = fDim.value;
      }

      this.model.records.push(rec);
    });
  }

  /**
   * Checks if output is visible.
   * @param selected Scenario output selected value.
   * @returns A flag indicating if output is visible.
   */
  isOutputVisible(selected: ScenarioOutput) {
    return this.model.currentOutput === selected;
  }
}
