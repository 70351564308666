import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AccessScopeType } from '@lv-core-ui/models/enum/access-scope-type';
import { v4 } from 'uuid';

/**
 * Access right modal component.
 */
@Component({
  selector: 'lv-access-rights-modal',
  templateUrl: './lv-access-rights-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvAccessRightsModalComponent implements OnInit {

  @Output() didSave: EventEmitter<string>;
  @Output() didCancel: EventEmitter<void>;

  @Input() accessScope: AccessScopeType;

  useUserRadioButtonId: string;
  useCompanyRadioButtonId: string;

  constructor() {
    this.didCancel = new EventEmitter<void>();
    this.didSave = new EventEmitter<string>();
    this.useUserRadioButtonId = v4();
    this.useCompanyRadioButtonId = v4();
   }

   @HostBinding('class.lv-access-rights-modal')
   get isLvAccessRightsModalComponent() {
     return true;
   }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit(): void {
  }

  /**
   * Occurs on save.
   */
  onSave() {
    this.didSave.emit(this.accessScope);
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
    this.didCancel.emit();
  }
}
