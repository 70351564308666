<lv-advanced-grid
  data-cy="DM-899"
  lvId="lvExerciseConversionSchedule"
  [columns]="columns"
  [records]="exerciseScheduleItems"
  [createFormGroup]="createFormGroup"
  [parseFn]="parseFn"
  sortBy="startDate"
  [showXlLabel]="hasScheduleInExcelOverride"
  [excelFieldAlias]="excelFieldAlias"
  [isFieldFromExcelEnabled]="isFieldFromExcelEnabled"
  class="lv-exercise-schedule-grid">
</lv-advanced-grid>