import { LvUtil } from '../util/util';

export interface ILvRecord<K, T> {
  id: K;
  idField: string;
  isPhantom: boolean;
  original: T;
  current: T;
}

export class LvRecord<K, T> implements ILvRecord<K, T> {

  id: K;
  idField: string;
  isPhantom: boolean;
  original: T;
  current: T;

  get isDirty(): boolean {
    let current: any = null;
    let original: any = null;

    if (Array.isArray(this.current) && Array.isArray(this.original)) {
      current = [...this.current].sort(LvUtil.sortBy(this.idField, false));
      original = [...this.original].sort(LvUtil.sortBy(this.idField, false));
    }
    else {
      current = { ...(this.current as any) };
      original = { ...(this.original as any) };
    }

    return LvUtil.jsonStringify(current) !== LvUtil.jsonStringify(original);
  }

  constructor(record: ILvRecord<K, T>) {
    Object.assign(this, record);
  }

  update() {
    if (Array.isArray(this.current) && Array.isArray(this.original)) {
      (this.original as any[]) = [...(this.current as any)];
    }
    else {
      (this.original as any) = {
        ...(this.current as any)
      };
    }
  }

}
