import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvPrivateInstrumentsError, ICreateDraftRequest, IUpdateDraftRequest, CustomInstrument,
         ICalculateYieldAndRedemptionRequest } from '@lv-custom-instruments/models';
import { ITermsDetailedDraftResponse } from '@lv-custom-instruments/models/responses/terms-detailed-draft-response';
import { ILvError, LvDataMaster, LvErrorType } from '@lv-core-ui/models';
import { ISaveInstrumentResponse } from '@lv-custom-instruments/models/responses/saveInstrumentResponse';

/**
 * Custom instrument service contains methods for managing custom instruments.
 */
@Injectable()
export class CustomInstrumentsService extends HttpClientBase {

  constructor(
    http: HttpClient
  ) {
    const resourceUrl = '/privateInstruments';
    super(http, resourceUrl);
  }

  /**
   * Copies from existing instrument.
   * @param instrumentIdentifier Instrument identifier.
   * @param isLeversysConvertible A flag indicating if convertible is Leversys.
   * @param isUserAccessScope A flag indicating whether the user's access scope.
   * @returns ISaveInstrumentResponse object.
   */
  async copyFromExistingInstrument(instrumentIdentifier: string,
                                   isLeversysConvertible: boolean,
                                   isUserAccessScope: boolean): Promise<ISaveInstrumentResponse> {
    try {
      const result = await this.postAsync<ISaveInstrumentResponse>({}, '/privateInstrument/copyInstrument', {
        instrumentId: instrumentIdentifier,
        isLeversysConvertible: isLeversysConvertible,
        isUserAccessScope: isUserAccessScope
      });

      if (!result) {
        throw new LvPrivateInstrumentsError(LvDataMaster.getError('dM-1917'));
      }

      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }

  }

  /**
   * Copies the instrument.
   * @param instrumentIdentifier Instrument identifier.
   * @param isLeversysConvertible A flag indicating if convertible is Leversys.
   * @param isUserAccessScope A flag indicating whether the user's access scope.
   * @returns ISaveInstrumentResponse object.
   */
  async copyInstrument(instrumentIdentifier: string,
                       isLeversysConvertible: boolean,
                       isUserAccessScope: boolean): Promise<ISaveInstrumentResponse> {
    try {
      const result = await this.postAsync<ISaveInstrumentResponse>({}, '/privateInstrument/exactCopyInstrument', {
        instrumentId: instrumentIdentifier,
        isLeversysConvertible: isLeversysConvertible,
        isUserAccessScope: isUserAccessScope
      });

      if (!result) {
        throw new LvPrivateInstrumentsError(LvDataMaster.getError('dM-1917'));
      }

      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }

  }

  /**
   * Creates draft.
   * @param request ICreateDraftRequest object.
   * @returns Draft ID.
   */
  async createDraft(request: ICreateDraftRequest): Promise<string> {
    try {
      return await this.postAsyncWihTextResponseType(request, '/privateInstrument/createDraft');
    }
    catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }
  }

  /**
   * Updates draft.
   * @param request IUpdateDraftRequest object.
   */
  async updateDraft(request: IUpdateDraftRequest): Promise<void> {
    try {
      return await this.postAsync(request, '/privateInstrument/updateDraft');
    }
    catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }
  }

  /**
   * Gets terms detailed.
   * @param privateInstrumentId Custom instrument ID.
   * @param sessionId Session ID.
   * @returns CustomInstrument object.
   */
  async getTermsDetailed(privateInstrumentId: string, sessionId: string): Promise<CustomInstrument> {
    try {
      const result = await this.getAsync<CustomInstrument>({
        privateInstrumentId: privateInstrumentId,
        sessionId: sessionId
      }, '/privateInstrument/getTermsDetailed');

      return result;
    }
    catch (error) {
      if (error.type !== LvErrorType.NOT_FOUND) {
        throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
      }
      else {
        throw error;
      }
    }
  }

  /**
   * Gets terms detailed from LVS instrument.
   * @param lwsIdentifier LWS identifier.
   * @param sessionId Session ID.
   * @returns ITermsDetailedDraftResponse object.
   */
  async getTermsDetailedFromLvsInstrument(lwsIdentifier: string, sessionId: string): Promise<ITermsDetailedDraftResponse>  {
    try {
      const result = await this.getAsync<ITermsDetailedDraftResponse>({
        lwsIdentifier: lwsIdentifier,
        sessionId: sessionId
      }, '/privateInstrument/getTermsDetailedFromLvsInstrument');

      if (!result) {
        throw new LvPrivateInstrumentsError(LvDataMaster.getError('dM-3025'));
      }

      return result;
    }
    catch (error) {
      throw this.handleError(error, this.handleUnauthorizedError);
    }
  }

  /**
   * Reloads terms detailed.
   * @param privateInstrumentId Custom instrument ID.
   * @param sessionId Session ID.
   * @returns CustomInstrument object.
   */
  async reloadTermsDetailed(privateInstrumentId: string, sessionId: string): Promise<CustomInstrument> {
    try {
      const result = await this.getAsync<CustomInstrument>({
        privateInstrumentId: privateInstrumentId,
        sessionId: sessionId
      }, '/privateInstrument/reloadTermsDetailed');

      if (!result) {
        throw new LvPrivateInstrumentsError(LvDataMaster.getError('dM-3025'));
      }

      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }
  }

  /**
   * Deletes custom instrument.
   * @param draftId Draft ID.
   */
  async deletePrivateInstrument(draftId: string): Promise<void> {
    try {
      await this.postAsync<any>(null, `/privateInstrument/delete/${draftId}`);
    }
    catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }
  }

  /**
   * Clears draft.
   * @param privateInstrumentId Custom instrument ID.
   * @param sessionId Session ID.
   */
  async clearDraft(privateInstrumentId: string, sessionId: string): Promise<void> {
    try {
      await this.postAsync<void>({}, '/privateInstrument/clearDraft', {
        privateInstrumentId: privateInstrumentId,
        sessionId: sessionId,
      });
    }
    catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }
  }

  /**
   * Sets yield from redemption.
   * @param request ICalculateYieldAndRedemptionRequest object.
   * @returns Yield issue.
   */
   async setYieldFromRedemption(request: ICalculateYieldAndRedemptionRequest): Promise<number> {
    try {
      return await this.postAsync<number>(request, '/cbFunction/yieldFromRedemption');
    }
    catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }
  }

  /**
   * Sets redemption from yield.
   * @param request ICalculateYieldAndRedemptionRequest object.
   * @returns Redemption value.
   */
  async setRedemptionFromYield(request: ICalculateYieldAndRedemptionRequest): Promise<number> {
    try {
      return await this.postAsync<number>(request, '/cbFunction/redemptionFromYield');
    }
    catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }
  }

  /**
   * Saves terms detailed.
   * @param request CustomInstrument object.
   * @returns ISaveInstrumentResponse object.
   */
  async saveTermsDetailed(request: CustomInstrument): Promise<ISaveInstrumentResponse> {
    try {
      const id = await this.postAsync<ISaveInstrumentResponse>(request, '/privateInstrument/saveTermsDetailed');
      return id;
    } catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }
  }

  /**
   * Saves terms detailed as copy.
   * @param request CustomInstrument object.
   * @returns ISaveInstrumentResponse object.
   */
  async saveTermsDetailedAsCopy(request: CustomInstrument): Promise<ISaveInstrumentResponse> {
    try {
      const id = await this.postAsync<ISaveInstrumentResponse>(request, '/privateInstrument/saveAsCopy');
      return id;
    } catch (error) {
      throw this.handleError(error, e => new LvPrivateInstrumentsError(e.message));
    }
  }

  /**
   * Handles unauthorized error.
   * @param e ILvError object.
   * @returns LvPrivateInstrumentsError object.
   */
  private handleUnauthorizedError(e: ILvError): LvPrivateInstrumentsError {
    const error = new LvPrivateInstrumentsError(e.message);

    if (e.message === 'Unauthorized Access') {
      error.type = LvErrorType.AUTHORIZATION;
    }

    return error;
  }
}
