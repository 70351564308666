export class LvDataMaster {

    static metaDataDictionary: {
        [key: string]: string,
    };

    static errorsDictionary: {
      [key: string]: string,
    };

    static warningsDictionary: {
      [key: string]: string,
    };

    static infosDictionary: {
      [key: string]: string,
    };

/**
 * populate dictionary with dm fields metadata
 * @param dict data fetched from API or PublicMetaData in browser database
 */
    static populateDictionary(dict: any ) {
        this.metaDataDictionary = dict;
    }

/**
 * get specific tooltip text value in metadata dictionary by data master field key
 * @param key  data master field key
 * @returns  data master field value
 */
    static getTooltip(key: string) {
        return this.metaDataDictionary[key];
    }

/**
 * populate dictionary with dm errors
 * @param dict errors fetched from API or errors from browser database
 */
  static populateErrors(dict: any ) {
    this.errorsDictionary = dict;
  }

/**
 * get specific error text value in errors dictionary by data master field key
 * @param key  data master field key
 * @returns  data master field value
 */
  static getError(key: string, parameters: {[key: string]: string} = null) {
      let error = this.errorsDictionary[key];

      if (parameters !== null && Object.keys(parameters).length > 0) {

          // tslint:disable-next-line: forin
          for (const param in parameters) {
              error = error.replace(`{${param}}`, parameters[param]);
          }
      }

      return `${error}<br>Error ID: ${key.toUpperCase()}`;
  }

  /**
   * Gets specific error text value in errors dictionary by data master field key and replaces parameters in text.
   * @param key Data master field key.
   * @param parameters Dictionary that contains list of parameters.
   * @returns  Data master field value.
   */
  static getErrorWithParameters(key: string, parameters: Map<string, string>) {
    let error = this.errorsDictionary[key];
    if (error != null && parameters != null) {
      parameters.forEach((value: string, placeholder: string) => {
        error = error.replace(placeholder, value);
      });
    }

    return `${error}<br>Error ID: ${key.toUpperCase()}`;
  }

/**
 * populate dictionary with dm warnings
 * @param dict warnings fetched from API or warnings from browser database
 */
   static populateWarnings(dict: any ) {
    this.warningsDictionary = dict;
  }

/**
 * get specific warning text value in warnings dictionary by data master field key
 * @param key  data master field key
 * @returns  data master field value
 */
  static getWarning(key: string) {
    return `${this.warningsDictionary[key]}<br>Warning ID: ${key.toUpperCase()}`;
  }

/**
 * populate dictionary with dm infos
 * @param dict infos fetched from API or infos from browser database
 */
   static populateInfos(dict: any ) {
    this.infosDictionary = dict;
  }

/**
 * get specific info text value in infos dictionary by data master field key
 * @param key  data master field key
 * @returns  data master field value
 */
  static getInfo(key: string, parameters: {[key: string]: string} = null) {
    let info = this.infosDictionary[key];

    if (parameters !== null && Object.keys(parameters).length > 0) {

        // tslint:disable-next-line: forin
        for (const param in parameters) {
          info = info.replace(`{${param}}`, parameters[param]);
        }
    }

    return `${info}<br>Info ID: ${key.toUpperCase()}`;
  }

  /**
   * Gets specific info text value in Infos dictionary by data master field key and replaces parameters in text.
   * @param key Data master field key.
   * @param parameters Dictionary that contains list of parameters.
   * @returns  Data master field value.
   */
   static getInfoWithParameters(key: string, parameters: Map<string, string>) {
    let info = this.infosDictionary[key];
    if (info != null && parameters != null) {
      parameters.forEach((value: string, placeholder: string) => {
        info = info.replace(placeholder, value);
      });
    }
    return `${info}<br>Info ID: ${key.toUpperCase()}`;
  }
}
