/**
 * Dividend source description.
 */
export enum DividendsSourceEnumDescription {
  NoDividends = 'No Dividends',
  // FlatYield = 'Flat Yield',
  Parameters = 'Parameters',
  Schedule = 'Schedule',
  // historyProjection = 'Leversys - History Projection'
}

/**
 * Types of dividends source/
 */
export enum DividendsSourceEnum {
  NoDividends = 'NoDividends',
  // FlatYield = 'FlatYield',
  Parameters = 'Parameters',
  Schedule = 'Schedule',
  // historyProjection = 'Leversys - HistoryProjection'
}

