import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { LvLicencingUtil } from '@lv-core-ui/util';

import { environment } from './../../../environments/environment';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'external',
  templateUrl: './external.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalComponent implements OnInit {

  public buildNumber: string;
  public copyright: string;

  constructor() {
    this.buildNumber = LvLicencingUtil.parseBuildNumber(!environment.production);
    this.copyright = LvLicencingUtil.getCopyright();
  }

  ngOnInit() {
  }
}
