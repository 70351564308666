import { IssueAndRedemption } from './IssueAndRedemption';
import { Coupon } from './Coupon';
import { Accretion } from './Accretion';
import { Call } from './Call';
import { Put } from './Put';
import { ConversionData } from './ConversionData';
import { DividendProtectionData } from './DividendProtectionData';
import { OtherData } from './OtherData';
import { TakeoverProtection } from './TakeoverProtection';
import { ExpectedCR } from './ExpectedCR';
import { CleanUpCall } from './CleanUpCall';
import { CurrencyType } from './CurrencyType';
import { ConvertibleBondSubType } from './ConvertibleBondSubType';
import { CouponType } from './CouponType';
import { SetupStatus } from './Enums (5)';
import { AcquisitionOptionDateInputType } from './enum-descriptions/conversion/AcquisitionOptionDateInputType';
import { AcquisitionVWAPPeriodInputType } from './enum-descriptions/conversion/AcquisitionVWAPPeriodInputType';
import { LvDataMaster } from '@lv-core-ui/models';

export class ConvertibleBondTermsDocument {

  issueAndRedemption: IssueAndRedemption;
  coupon: Coupon;
  accretion: Accretion;
  call: Call;
  put: Put;
  conversion: ConversionData;
  dividendProtection: DividendProtectionData;
  other: OtherData;
  takeoverProtection: TakeoverProtection;
  expectedCR: ExpectedCR;
  cleanUpCall: CleanUpCall;
   

  /**
   * Get if term is Cross FX
   */
  public isCrossFx() {
    return this.issueAndRedemption.currencyCode !== this.issueAndRedemption.underlyingEquity.currencyCode;
  }

  /**
   * Get Conversion Ratio From Conversion Price
   */
  public calculateConversionRatioFromCP(conversionPrice: number) {
    let conversionPriceCCY = CurrencyType.Convertible;
    if (this.conversion.schedule && this.conversion.schedule.length > 0) {
      conversionPriceCCY = this.conversion.schedule[0].conversionPriceCCY;
    }

    const fixedFxRate = this.issueAndRedemption.fixedFXRate;
    const nominalValue = this.issueAndRedemption.nominalValue;

    if (conversionPriceCCY === CurrencyType.Underlying && fixedFxRate) {
      return (fixedFxRate * nominalValue) / conversionPrice;
    } else {
      return nominalValue / conversionPrice;
    }
  }

  /**
   * Get Conversion Ratio From Conversion Price
   */
  public recalculateConversionRatioFromCP() {
    if (this.conversion && this.conversion.schedule) {
      this.conversion.schedule.forEach(item => {
        item.ratio = this.calculateConversionRatioFromCP(item.conversionPrice);
      });
    }
  }

  /**
   * Get Conversion Ratio From Premium
   */
  public recalculateConversionRatioFromPremium(premium: number, stockPrice: number, crossFx: number, nominal: number) {
    this.conversion.schedule = this.conversion.schedule.map(a => ({
      ...a,
      ratio: this.calculateRatio(premium, stockPrice, crossFx, nominal),
    }));
  }

  /**
   * Get Lower Strike from Peps conversion
   */
  public getLowerStrike(stockPrice: number) {
    this.conversion.pepsConversion.lowerStrike = (1 + (this.conversion.pepsConversion.lowerStrike / 100)) * stockPrice;
  }

  /**
   * Get Higher Strike from Peps conversion
   */
  public getHigherStrike(premium: number) {
    this.conversion.pepsConversion.higherStrike = (1 + (premium / 100)) * this.conversion.pepsConversion.lowerStrike;
  }

  /**
   * Changed Call yield frequency and Call yield day count field based on changing Coupon Frequncy and Coupon Day Count from Coupon tab
   */
     setCallYieldFields() {
      if (this.issueAndRedemption.isCallable && this.call && this.coupon && this.coupon.type !== CouponType.ZeroCoupon) {
        this.call.yieldFrequency = this.coupon.frequency;
        this.call.yieldDayCount = this.coupon.dayCount;
      }
    }

  /**
   * Validate Terms before Save
   */
  public validateTerms(): [boolean, string] {
    let isValid = true;
    let validateMessage = '';

    if (!this.issueAndRedemption.name) {
      validateMessage += '<div>' + LvDataMaster.getWarning('dM-1862') + '</div>';
      isValid = false;
    }
    if (!this.issueAndRedemption.currencyCode) {
      validateMessage += '<div>' + LvDataMaster.getWarning('dM-1863') + '</div>';
      isValid = false;
    }
    if (!this.issueAndRedemption.maturingCurrencyCode) {
      validateMessage += '<div>' + LvDataMaster.getWarning('dM-1864') + '</div>';
      isValid = false;
    }
    if (!this.issueAndRedemption.issuerName) {
      validateMessage += '<div>' + LvDataMaster.getErrorWithParameters('dM-3383', new Map([['{fieldName}', 'Issuer Name']])) + '</div>';
      isValid = false;
    }
    if (this.issueAndRedemption.underlyingEquity && !this.issueAndRedemption.underlyingEquity.name) {
      validateMessage += '<div>' + LvDataMaster.getErrorWithParameters('dM-3383', new Map([['{fieldName}', 'Underlying Name']])) + '</div>';
      isValid = false;
    }
    if (this.issueAndRedemption.underlyingEquity && !this.issueAndRedemption.underlyingEquity.currencyCode) {
      validateMessage += '<div>' + LvDataMaster.getWarning('dM-1865') + '</div>';
      isValid = false;
    }
    if (this.issueAndRedemption.setupStatus !== SetupStatus.NewIssue && this.issueAndRedemption.subType !== ConvertibleBondSubType.PEPS &&
        !this.issueAndRedemption.redemptionValue && !this.issueAndRedemption.isPerpetual) {
      validateMessage += '<div>' + LvDataMaster.getWarning('dM-1866') + '</div>';
      isValid = false;
    }
    if (this.issueAndRedemption.setupStatus !== SetupStatus.NewIssue && this.coupon && this.coupon.type === CouponType.Fixed &&
        !this.coupon.fixed.useStepupCouponSchedule && !this.coupon.fixed.rate) {
      validateMessage += '<div>' + LvDataMaster.getWarning('dM-1867') + '</div>';
      isValid = false;
    }
    if (this.issueAndRedemption.isCallable && this.call && this.call.scheduleItems.length === 0) {
      validateMessage += '<div>' + LvDataMaster.getWarning('dM-1868') + '</div>';
      isValid = false;
    }
    if (this.issueAndRedemption.isPuttable && this.put && this.put.scheduleItems.length === 0) {
      validateMessage += '<div>' + LvDataMaster.getWarning('dM-1869') + '</div>';
      isValid = false;
    }
    if (!this.issueAndRedemption.isPerpetual && !this.issueAndRedemption.maturityDate) {
      validateMessage += '<div>' + LvDataMaster.getWarning('dM-1870') + '</div>';
      isValid = false;
    }
    if (this.conversion.useAcquisitionSharesSettlement) {
      if (!this.conversion.acquisitionNoticeStartDate) {
        validateMessage += '<div>' + LvDataMaster.getWarning('dM-1871') + '</div>';
        isValid = false;
      }
      if (this.conversion.acquisitionOptionDateInputType === AcquisitionOptionDateInputType.FixedDate &&
        !this.conversion.conversionSettlementAcquisitionOptionDate) {
        validateMessage += '<div>' + LvDataMaster.getWarning('dM-1872') + '</div>';
        isValid = false;
      }
      if (this.conversion.acquisitionOptionDateInputType === AcquisitionOptionDateInputType.NoticePeriod) {
        if (!this.conversion.acquisitionNoticeMinDays) {
          validateMessage += '<div>' + LvDataMaster.getWarning('dM-1873') + '</div>';
          isValid = false;
        }
        if (!this.conversion.acquisitionNoticeMaxDays) {
          validateMessage += '<div>' + LvDataMaster.getWarning('dM-1874') + '</div>';
          isValid = false;
        }
      }
      if (this.conversion.acquisitionVWAPPeriodInputType === AcquisitionVWAPPeriodInputType.FixedDate &&
        !this.conversion.conversionSettlementVWAPPeriodStartDate) {
        validateMessage += '<div>' + LvDataMaster.getWarning('dM-1875') + '</div>';
        isValid = false;
      }
      if (this.conversion.acquisitionVWAPPeriodInputType === AcquisitionVWAPPeriodInputType.ObservationLag &&
        !this.conversion.vwapPeriodObservationLag) {
        validateMessage += '<div>' + LvDataMaster.getWarning('dM-1876') + '</div>';
        isValid = false;
      }
    }

    return [isValid, validateMessage];
  }

  private calculateRatio(premium: number, stockPrice: number, crossFx: number, nominal: number): any {
    let ratio = 0;
    const conversionPrice = stockPrice * (1 + (premium / 100));

    if (conversionPrice !== 0) {
      ratio = nominal / conversionPrice * crossFx;
    }

    return ratio;
  }
}

