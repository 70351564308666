import { ILvScenarioInputs } from './scenario-inputs';
import { ScenarioInputsShift } from '../enums/scenario-inputs-shift';
import { DateExtensions } from '@lv-core-ui/util';

/**
 * Scenario inputs mapper.
 */
export class LvScenarioInputsMapper {

  /**
   * Maps scenario onputs to API.
   * @param dimension ILvScenarioInputs object.
   */
  public static mapScenarioInputsToApi(dimension: ILvScenarioInputs): void {
    if (dimension.shift === ScenarioInputsShift.ValuationDate) {
      dimension.min = DateExtensions.getTimeWithOffset(new Date(dimension.min));
      dimension.max = DateExtensions.getTimeWithOffset(new Date(dimension.max));
    }
  }

  /**
   * Maps scenario inputs to UI.
   * @param dimension ILvScenarioInputs object.
   */
  public static mapScenarioInputsToUi(dimension: ILvScenarioInputs): void {
    if (dimension.shift === ScenarioInputsShift.ValuationDate) {
      dimension.min = new Date(dimension.min);
      dimension.max = new Date(dimension.max);
    }
  }
}
