import { NumberPipe } from '@progress/kendo-angular-intl';
import { Component, OnChanges, Input, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { LvLocalDatePipe } from '@lv-core-ui/pipes';
import { LvScenarioTwoDimPriceTalkChartView } from './lv-scenario-two-dim-price-talk-chart.view';
import { ILvScenarioReport } from '../../lv-scenario.view';
import { LvScenarioChart } from '../lv-scenario-chart';
import { ScenarioOutput, ScenarioPriceTalk } from '@lv-analytics/models';

/**
 * Scenario two dim price talk chart component.
 */
@Component({
  selector: 'lv-scenario-two-dim-price-talk-chart',
  templateUrl: './lv-scenario-two-dim-price-talk-chart.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvScenarioTwoDimPriceTalkChartComponent extends LvScenarioChart implements OnChanges {

  @Input() report: ILvScenarioReport;

  view: LvScenarioTwoDimPriceTalkChartView;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    numberPipe: NumberPipe,
    lvDatePipe: LvLocalDatePipe
  ) {
    super(changeDetectorRef);

    this.view = new LvScenarioTwoDimPriceTalkChartView(numberPipe, lvDatePipe);
    this.view.init();
  }

  /**
   * Does view initialization.
   */
  ngOnChanges() {
    this.view.init(this.report);
  }

  /**
   * Occurs on current output click.
   * @param output Scenario output.
   */
  onCurrentOutputClick(output: ScenarioOutput) {
    this.view.model.currentOutput = output;

    this.view.setMultiValueYAxisCurrentOffset(this.view.getAxisValue());

    this.changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on current price talk click.
   * @param selected Scenario price talk selected value.
   */
  onCurrentPriceTalkClick(priceTalk: ScenarioPriceTalk) {
    this.view.model.currentPriceTalk = priceTalk;

    this.view.setMultiValueYAxisCurrentOffset(this.view.getAxisValue());

    this.changeDetectorRef.detectChanges();
  }
}
