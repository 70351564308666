/**
 * Permissions for analytics module.
 */
export enum Permissions {
  MODULE = 'ANALYTICS',
  ALL = 'ALL',
  INSTRUMENT_VALUATION = 'INSTRUMENT_VALUATION',
  SCENARIO_ANALYSIS = 'SCENARIO_ANALYSIS',
  INSTRUMENT_INFO = 'INSTRUMENT_INFO'
}
