import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClientBase2 } from '@lv-core-ui/api';
import { LvAuthenticationError, LvConnectionError } from '@lv-core-ui/models';
import { IResource } from '@lv-core-ui/models/authorization/resource';
import { RestrictedResourceType } from '@lv-core-ui/models/authorization/restricted-resource-type-enum';
import { IUserAccessRightsData } from '@lv-core-ui/models/user-permissions-and-policies';
import { LocalStorage, LvUtil } from '@lv-core-ui/util';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService  extends HttpClientBase2 {

  constructor(
    _httpClient: HttpClient
  ) {
    super(_httpClient, `${environment.identityServer.apiUrl}`);
  }

  async getUserAccessRightsData() {
    let permissions = LvUtil.jsonParse<IUserAccessRightsData>(LocalStorage.getPermissions());
    let errorResponse = null;
    let restrictedResourceExpiry = null;

    if (permissions) {
      restrictedResourceExpiry = Date.parse(permissions.restrictedResourceExpiration);
    }

    const currentTime = Date.parse((new Date()).toISOString());
    if (!permissions || currentTime >= restrictedResourceExpiry) {
      try {
        permissions = await this.getAsync<IUserAccessRightsData>
          (null, `/permissions/userAccessRightsData`);
      }
      catch (error) {
        const e: HttpErrorResponse = error;
        errorResponse = e.status === 401 ? new LvAuthenticationError() : new LvConnectionError();
      }

      if (!errorResponse) {
        LocalStorage.setPermissions(LvUtil.jsonStringify(permissions));
      }
      else {
        throw errorResponse;
      }
    }
  }

  async hasPermission(module: string, action: string): Promise<boolean> {
    let result = false;
    try {
      result = await this.getAsync<boolean>({module: module, action: action },
        '/permissions');
    }
    catch (error) {
      throw error;
    }
    return result;
  }

  async hasResourcePermission(module: string, action: string, resource: IResource): Promise<boolean> {
    let result = false;
    try {
      result = await this.getAsync<boolean>({module: module, action: action,
        identifier: resource.identifier,
        region: resource.region },
        '/permissions/resourcePermission');
    }
    catch (error) {
      throw error;
    }
    return result;
  }

  async checkRegionPermission(module: string, action: string): Promise<string[]> {
    try {
      return await this.getAsync<string[]>({module: module, action: action},
        '/permissions/checkRegionPermissions');
    }
    catch (error) {
      throw error;
    }
  }

  /**
   * Checks if user has rights for given module/action and resource.
   * @param module Module name.
   * @param action Action name.
   * @param resource IResource instance.
   * @returns A flag indicating if user has rights for given module/action and resource.
   */
  authorize(module: string, action: string, resource: IResource) {
    const permissions = LvUtil.jsonParse<IUserAccessRightsData>(LocalStorage.getPermissions());
    if (permissions) {

      const lvPolicies = permissions.lvPolicies.filter((lvPolicy) =>
        lvPolicy.statements.find((statement) =>
        statement.actions.find(z => z.split(':')[0] === module && z.split(':')[1] === action)));

      if (!lvPolicies || lvPolicies.length === 0) {
        return false;
      }

      const lvResources = lvPolicies.find((lvPolicy) =>
        lvPolicy.statements.find((statement) =>
        (statement.resources === null || statement.resources.length === 0) &&
        statement.actions.find(z => z.split(':')[0] === module && z.split(':')[1] === action)));

      if (lvResources) {
        return true;
      }

      if (resource) {
        let result = false;

        if (resource.identifier && permissions.restrictedResources.length > 0) {
          result = permissions.restrictedResources
          .some(x => x.identifier === resource.identifier
            && x.restrictedResourceType.toString() === RestrictedResourceType[resource.restrictedResourceType]);
        }

        if (!result) {
          result = permissions.restrictedResources.some(x => x.restrictionGroup = resource.restrictionGroup);
        }

        if (!result && resource.region) {
          result = lvPolicies.findIndex(
            x => x.statements.findIndex(
              y => y.resources.findIndex(
                z => z.region === resource.region
              ) > -1
            ) > -1
          ) > -1;
        }

        return result;
      }

      return true;
    }
  }
}
