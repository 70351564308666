import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy, Input, HostListener,
  ElementRef, ChangeDetectorRef, Renderer2, Output, EventEmitter, HostBinding, ViewRef } from '@angular/core';
import { Align, Margin } from '@progress/kendo-angular-popup';
import { slide } from '../lv-side-panel/lv-side-panel-animations';

@Component({
  selector: 'lv-settings-side-panel',
  templateUrl: './lv-settings-side-panel.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    slide
  ]
})
export class LvSettingsSidePanelComponent implements OnInit {

  @Input() floating: boolean;
  @Input() expanded: boolean;
  @Input() position: string;
  @Input() width: number;
  @Input() manualClosing: boolean;
  @Input() dropdownAnchor: ElementRef;
  @Output() didSlide: EventEmitter<boolean>;
  @Output() didClosed: EventEmitter<void>;


  get ngClass() {
    return {
      [`lv-side-panel-${this.position}`]: true,
      'lv-side-panel--floating': this.floating
    };
  }

  get animationDirection() {
    if (this.floating) {
      return 'top';
    }

    return this.position;
  }

  get anchorAlign(): Align {
    return {
      horizontal: 'right',
      vertical: 'top'
    };
  }

  get popupAlign(): Align {
    return {
      horizontal: 'right',
      vertical: 'top'
    };
  }

  internalClick: boolean;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _element: ElementRef,
    private _renderer: Renderer2
  ) {
    this.position = 'left';
    this.width = 220;
    this.expanded = false;
    this.floating = false;
    this.manualClosing = false;
    this.didSlide = new EventEmitter<boolean>();
    this.didClosed = new EventEmitter<void>();
    this.dropdownAnchor = new ElementRef(null);
  }

  @HostBinding('class.lv-settings-side-panel')
  get isLvSettingsSidePanel(): boolean {
    return true;
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onDocumentClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement || this.manualClosing) {
        return;
    }

    const clickedInside = this._element.nativeElement.contains(targetElement);
    if (!clickedInside && !this.internalClick && this.expanded) {
      this.expanded = false;
      this.didClosed.next();
      this._changeDetectorRef.detectChanges();
    }

    this.internalClick = false;
  }

  ngOnInit() {
    document.body.appendChild(this._element.nativeElement);
    this._renderer.setStyle(this._element.nativeElement.parentElement, 'position', 'relative');
    this._renderer.setStyle(this._element.nativeElement.parentElement, 'overflow-x', 'hidden');

    if (this.expanded) {
      this.internalClick = this.expanded;

      if (!(this._changeDetectorRef as ViewRef).destroyed) {
        this._changeDetectorRef.detectChanges();
      }
    }
  }

  onSlideDone() {
    this.didSlide.next(this.expanded);
  }

  togglePanel() {
    this.expanded = !this.expanded;
    this.internalClick = this.expanded;
    if (!(this._changeDetectorRef as ViewRef).destroyed) {
      this._changeDetectorRef.detectChanges();
    }
  }

  closePanel() {
    this.expanded = false;
    this.internalClick = false;
  }

  /**
   * After pricing component is destroyed removed settings panel from body
   * because  panel is direct child of body
   */
  forceClose() {
    document.body.removeChild(this._element.nativeElement);
  }
}
