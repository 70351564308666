import { Component, OnInit, Input, Output, EventEmitter, ViewChild,
   ChangeDetectorRef, OnDestroy, OnChanges, ViewEncapsulation, ChangeDetectionStrategy, Optional } from '@angular/core';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LvAdvancedGridComponent, LvAdvancedGridColumn, LvAdvancedGridDateColumn } from '@lv-core-ui/components';
import { LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { MarketDataClipboard } from '@lv-analytics/components';
import { ExerciseScheduleItem } from '@lv-convertible-bond/models';
import { LvExcelService } from '@lv-excel/services';

@Component({
  selector: 'lv-exercise-schedule',
  templateUrl: './lv-exercise-schedule.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvExerciseScheduleComponent implements OnInit, OnDestroy, OnChanges {
  @Input() model: ExerciseScheduleItem[];
  @Output() didExerciseScheduleChange: EventEmitter<ExerciseScheduleItem[]>;

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  columns: LvAdvancedGridColumn[];
  exerciseScheduleItems: ExerciseScheduleItem[];
  parseFn: any;
  excelFieldAlias = 'CONV_EXCS_SCHED_RANGE';

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAlias)?.editable;
  }
  
  private _modelSubscription: Subscription[];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.initColumns();
    this.parseFn = this.parserFunction.bind(this);

    this.exerciseScheduleItems = [];
    this._modelSubscription = [];
    this.didExerciseScheduleChange = new EventEmitter<ExerciseScheduleItem[]>();
  }

  ngOnInit() {
    this._modelSubscription = [
      this.advancedGrid.didDataChange.subscribe((records: ExerciseScheduleItem[]) => this.onScheduleChange(records)),
      this.advancedGrid.doReload.subscribe(() => this.onScheduleReload()),
      this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];

    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return  new FormGroup({
      'startDate': new FormControl(args.isNew ? new Date() : args.dataItem.startDate, Validators.required),
      'endDate': new FormControl(args.isNew ? new Date() : args.dataItem.endDate, Validators.required)
    });
  }

  ngOnDestroy() {
    this._modelSubscription.forEach(s => s.unsubscribe());
  }

  applyAdvancedGridChanges() {
    this.advancedGrid.applyChanges(records => this.applyRecords(records));
  }

  private init() {
    this.exerciseScheduleItems = this.model.map(a => ({ ...a }));
  }

  private initColumns() {
    this.columns = [];

    const startDateColumn = new LvAdvancedGridDateColumn();
    startDateColumn.title = 'Start Date';
    startDateColumn.field = 'startDate';
    startDateColumn.dmKey = 'DM-2293';

    const endDateColumn = new LvAdvancedGridDateColumn();
    endDateColumn.title = 'End Date';
    endDateColumn.field = 'endDate';
    endDateColumn.dmKey = 'DM-2294';

    this.columns.push(startDateColumn);
    this.columns.push(endDateColumn);
  }

  private parserFunction(pastedDataRecords: string[]): ExerciseScheduleItem[] {
    const scheduleItems: ExerciseScheduleItem[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');

      const startDateValue = items[0];
      const endDateValue = items[1];

      const startDate = MarketDataClipboard.parseDateValue(startDateValue, 'Start Date');
      const endDate = MarketDataClipboard.parseDateValue(endDateValue, 'End Date');

      scheduleItems.push({
        startDate: startDate,
        endDate: endDate
      } as ExerciseScheduleItem);
    });

    return scheduleItems;
  }

  private onScheduleChange(scheduleItems: ExerciseScheduleItem[]) {
    this.applyRecords(scheduleItems);
    // this.didExerciseScheduleChange.next(this.model);
  }

  private applyRecords(records: any[]) {
    this.model.splice(0, this.model.length);
    this.model.push(...records);
    this.didExerciseScheduleChange.next(this.model);
  }

  private onScheduleReload() {
    this.exerciseScheduleItems = this.exerciseScheduleItems.map(a => ({ ...a }));
  }

  private onError(error: LvError) {
    this._errorService.handleError(error);
  }

}
