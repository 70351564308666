import { CapExpressedAsDescription, ContingentConversionPeriodTypeDescription, ContingentConversionScheduleItem,
  ContingentConversionScheduleItemTypeDescription,
  ContingentConversionScheduleTriggerTypeDescription, ConversionData,
  ConversionScheduleItem,
  ConversionSettlement,
  ConversionSettlementDescripton,
  ConvertibleBondSubType,
  CurrencyType,
  FloorExpressedAsDescripton, PepsConversionBeforeMaturityTypeDescription, QuickAndFullTermsDocument } from '@lv-convertible-bond/models';
import { AcquisitionOptionDateInputTypeDescription } from '@lv-convertible-bond/models/convertible-bond-terms/enum-descriptions/conversion/AcquisitionOptionDateInputType';
import { AcquisitionSharesSettlTypeDescription } from '@lv-convertible-bond/models/convertible-bond-terms/enum-descriptions/conversion/AcquisitionSharesSettlType';
import { AcquisitionVWAPPeriodInputTypeDescription } from '@lv-convertible-bond/models/convertible-bond-terms/enum-descriptions/conversion/AcquisitionVWAPPeriodInputType';
import { ContingentConversionTriggerParityLimit, QuarterType } from '@lv-convertible-bond/models/convertible-bond-terms/Enums (2)';
import { ExcelFieldDataTypes } from '@lv-excel/models/enums/excel-field-data-types';
import { getDateValue } from './date-time-helper';

export class TermsConversionMapper {

  mapConversionFields(fieldDictionary: any,
                      model: QuickAndFullTermsDocument,
                      initialModel: QuickAndFullTermsDocument,
                      isPrivateInstrument: boolean,
                      isNewIssue: boolean) {

    //#region Conversion General
    fieldDictionary['CONV_MANDATORY'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.mandatoryConversion = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.mandatoryConversion;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['CONV_KEEP_ACCRD'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.keepAccrued = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.keepAccrued;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['CONV_FRFT_CPN_MAT'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.forfeitCouponOnConversionAtMaturity = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.forfeitCouponOnConversionAtMaturity;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['CONV_FRFT_CPN_CONV'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.forfeitCouponOnOptionalConversion = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.forfeitCouponOnOptionalConversion;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['CONV_NEW_SHARES'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.conversionIntoNewShares = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.conversionIntoNewShares;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['CONV_EXCS_SCHED_RANGE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.exerciseSchedule = value.map(item => {
          return {
            startDate: new Date(item.startDate),
            endDate: new Date(item.endDate),
          };
        });
      },
      reverseMapFn: () => {
        const result = model.fullTerms.conversion.exerciseSchedule.map(item => {
          return {
            startDate: getDateValue(item.startDate),
            endDate: getDateValue(item.endDate)
          };
        });

        return result;
      },
      type: ExcelFieldDataTypes.Schedule
    };

    fieldDictionary['CONV_PRICE_CCY'] = {
      mapFn: (value: any) => {
        this.mapConversionPriceCCY(value, model.fullTerms.conversion);
      },
      reverseMapFn: () => {
        return this.getConversionPrice(model.fullTerms.conversion);
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['RESETTABLE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.isResetable = value;
        if (value) {
          this.setDefaultConversionReset(model, initialModel, isPrivateInstrument);
        }
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.isResetable;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['CONV_DLTA_NEUTRAL'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.fxAdjustedConversionAmount = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.fxAdjustedConversionAmount;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['CONV_REBATE_CCY'] = {
      mapFn: (value: any) => {
        this.mapConversionRebateCCY(value, model.fullTerms.conversion);
      },
      reverseMapFn: () => {
        return this.getConversionRebate(model.fullTerms.conversion);
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['CONV_STTLMNT_TYPE'] = {
      mapFn: (value: any) => {
        if (value === '') {
          model.fullTerms.conversion.conversionSettlement = ConversionSettlement.None;
        }
        else {
          model.fullTerms.conversion.conversionSettlement = value;
        }
      },
      reverseMapFn: () => {
        if (ConversionSettlementDescripton[model.fullTerms.conversion.conversionSettlement] === '') {
          return 'None';
        }
        return ConversionSettlementDescripton[model.fullTerms.conversion.conversionSettlement];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: ConversionSettlementDescripton
    };

    fieldDictionary['CONV_STTLMNT_DAYS'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.conversionSettlementNumberFixingDays = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.conversionSettlementNumberFixingDays;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['CONV_STTLMNT_OBS_MAT'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.conversionSettlementObservPeriodStartBeforeMatDate = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.conversionSettlementObservPeriodStartBeforeMatDate;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['CONV_STTLMNT_OBS_CALL'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.conversionSettlementObservPeriodStartBeforeIssuerCall = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.conversionSettlementObservPeriodStartBeforeIssuerCall;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['CONV_STTLMNT_CONV_LAG'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.conversionSettlementEarlyConversionObservationLag = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.conversionSettlementEarlyConversionObservationLag;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['CONV_SCHED_RANGE'] = {
      mapFn: (value: any) => {
        // tslint:disable-next-line: max-line-length
        model.fullTerms.conversion.schedule = this.mapConversionSchedule(value, model.fullTerms.conversion.schedule);
      },
      reverseMapFn: () => {
        const result = model.fullTerms.conversion.schedule.map(item => {
          return {
            startDate: getDateValue(item.startDate),
            endDate: getDateValue(item.endDate),
            ratio: isNewIssue ? null : item.ratio,
            conversionPrice: isNewIssue ? null : item.conversionPrice,
            rebate: isNewIssue ? null : item.rebate
          };
        });

        return result.reverse();
      },
      type: ExcelFieldDataTypes.Schedule
    };

    fieldDictionary['CONV_MW'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.recieveMakeWholeConversion = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.recieveMakeWholeConversion;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['CONV_MW_START_DATE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.mwStartDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(model.fullTerms.conversion.mwStartDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    fieldDictionary['CONV_MW_END_DATE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.mwEndDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(model.fullTerms.conversion.mwEndDate);
      },
      type: ExcelFieldDataTypes.Date
    };
    //#endregion

    //#region Contigent Conversion
    fieldDictionary['CO_CO'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.isContigentConversion = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.isContigentConversion;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['CO_CO_TRGGR_TYPE'] = {
      mapFn: (value: any) => {
        this.mapTriggerType(value, model);
      },
      reverseMapFn: () => {
        return this.getTriggerType(model);
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: ContingentConversionScheduleTriggerTypeDescription
    };

    fieldDictionary['CO_CO_CONV_ON_CALL'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.contingentConversion.convertibleOnCall = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.contingentConversion.convertibleOnCall;
      },
      type: ExcelFieldDataTypes.String,
    };

    fieldDictionary['CO_CO_PRICE_TRGGR'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.contingentConversion.convPriceTrigger = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.contingentConversion.convPriceTrigger;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['CO_CO_BUYOUT'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.contingentConversion.buyoutAtCoCoTrigger = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.contingentConversion.buyoutAtCoCoTrigger;
      },
      type: ExcelFieldDataTypes.String,
    };

    fieldDictionary['CO_CO_QTR_TYPE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.contingentConversion.quarterType = value;
      },
      reverseMapFn: () => {
        return QuarterType[model.fullTerms.conversion.contingentConversion.quarterType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: QuarterType
    };

    fieldDictionary['CO_CO_TRGGR_PERIOD_TYPE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.contingentConversion.triggerPeriodType = value;
      },
      reverseMapFn: () => {
        return ContingentConversionPeriodTypeDescription[model.fullTerms.conversion.contingentConversion.triggerPeriodType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: ContingentConversionPeriodTypeDescription
    };

    fieldDictionary['CO_CO_DAYS'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.contingentConversion.triggerPeriodDays = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.contingentConversion.triggerPeriodDays;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['CO_CO_OUT_OF_DAYS'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.contingentConversion.outOfDays = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.contingentConversion.outOfDays;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['CO_CO_TRGGR_LIMIT'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.contingentConversion.cbPriceTriggerParityLimit = value;
      },
      reverseMapFn: () => {
        return ContingentConversionTriggerParityLimit[model.fullTerms.conversion.contingentConversion.cbPriceTriggerParityLimit];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: ContingentConversionTriggerParityLimit
    };

    fieldDictionary['CO_CO_SCHED_RANGE'] = {
      mapFn: (value: any) => {
        // tslint:disable-next-line: max-line-length
        model.fullTerms.conversion.contingentConversion.schedule = this.mapCoCoSchedule(value, model.fullTerms.conversion.contingentConversion.schedule);
      },
      reverseMapFn: () => {
        const result = model.fullTerms.conversion.contingentConversion.schedule.map(item => {
          return {
            startDate: getDateValue(item.startDate),
            endDate: getDateValue(item.endDate),
            type: ContingentConversionScheduleItemTypeDescription[item.type],
            initialTrigger: item.initialTrigger,
            changeRate: item.changeRate,
            triggerCapFloor: item.triggerCapFloor,
          };
        }).sortBy('startDate');

        return result;
      },
      type: ExcelFieldDataTypes.Schedule
    };
    //#endregion

    //#region PEPS & PERCS
    fieldDictionary['PEPS_START_DATE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.pepsConversion.startDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(model.fullTerms.conversion.pepsConversion.startDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    fieldDictionary['PEPS_END_DATE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.pepsConversion.endDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(model.fullTerms.conversion.pepsConversion.endDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    fieldDictionary['PEPS_HGR_CP'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.pepsConversion.higherStrike = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.pepsConversion.higherStrike;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['PEPS_MIN_CR'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.pepsConversion.customMinRatio = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.pepsConversion.customMinRatio;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['PEPS_LWR_CP'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.pepsConversion.lowerStrike = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.pepsConversion.lowerStrike;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['PEPS_MAX_CR'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.pepsConversion.maxRatio = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.pepsConversion.maxRatio;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['PEPS_PARALEL'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.pepsConversion.parallelPeps = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.pepsConversion.parallelPeps;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['PEPS_REBATE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.pepsConversion.conversionRebate = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.pepsConversion.conversionRebate;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['PEPS_REBATE_CCY'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.pepsConversion.conversionRebateCurrency = value;
      },
      reverseMapFn: () => {
        return CurrencyType[model.fullTerms.conversion.pepsConversion.conversionRebateCurrency];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: CurrencyType
    };

    fieldDictionary['PEPS_EARLY_CONV'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.pepsConversion.conversionBeforeMaturityType = value;
      },
      reverseMapFn: () => {
        return PepsConversionBeforeMaturityTypeDescription[model.fullTerms.conversion.pepsConversion.conversionBeforeMaturityType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: PepsConversionBeforeMaturityTypeDescription
    };

    fieldDictionary['PERCS_CR'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.percsConversion.conversionRatio = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.percsConversion.conversionRatio ;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['PERCS_CAP_CP'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.percsConversion.capPrice = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.percsConversion.capPrice ;
      },
      type: ExcelFieldDataTypes.Number
    };
    //#endregion

    //#region Variable Conversion
    fieldDictionary['VAR_CONV'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.isVariableConversion = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.isVariableConversion;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['VAR_CONV_INC_SHR_FACTOR'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.variableConversion.incrementalShareFactor = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.variableConversion.incrementalShareFactor;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['VAR_CONV_STRIKE_CCY'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.variableConversion.strikeCurrency = value;
      },
      reverseMapFn: () => {
        return CurrencyType[model.fullTerms.conversion.variableConversion.strikeCurrency];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: CurrencyType
    };

    fieldDictionary['VAR_CONV_LOCK_IN_DATE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.variableConversion.lockinDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(model.fullTerms.conversion.variableConversion.lockinDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    fieldDictionary['VAR_CONV_STRIKE_RT'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.variableConversion.strike = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.variableConversion.strike;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['VAR_CONV_CAP_RATIO'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.variableConversion.capRatio = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.variableConversion.capRatio;
      },
      type: ExcelFieldDataTypes.Number
    };
    //#endregion
    //#region Reset
    fieldDictionary['RES_PRICE_INTL'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.reset.initialConversionPrice = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.reset.initialConversionPrice;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['RES_CCY'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.reset.resetCurrencyType = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.reset.resetCurrencyType;
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: CurrencyType
    };

    fieldDictionary['RES_FIX_LAG'] = {
       mapFn: (value: any) => {
         model.fullTerms.conversion.reset.resetAveraging.startLag = value;
       },
       reverseMapFn: () => {
         if (model.fullTerms.conversion.reset.resetAveraging.startLag > 0) {
           return model.fullTerms.conversion.reset.resetAveraging.startLag;
         }
       },
       type: ExcelFieldDataTypes.Number
     };

    fieldDictionary['RES_NUM_AVG_DAYS'] = {
       mapFn: (value: any) => {
         model.fullTerms.conversion.reset.resetAveraging.numberOfAveragingDays = value;
       },
       reverseMapFn: () => {
         if (model.fullTerms.conversion.reset.resetAveraging.numberOfAveragingDays > 0) {
           return model.fullTerms.conversion.reset.resetAveraging.numberOfAveragingDays;
         }
       },
       type: ExcelFieldDataTypes.Number
     };

    fieldDictionary['RES_CONV_PRICE_CCY'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.reset.conversionPriceCurrencyType = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.reset.conversionPriceCurrencyType;
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: CurrencyType
    };

    fieldDictionary['RES_CONV_DRNG_PERIOD'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.reset.resettableDuringPeriod = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.reset.resettableDuringPeriod;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['RES_CONV_FLOOR_AS'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.reset.floorExpressedRelativeTo = value;
      },
      reverseMapFn: () => {
        return FloorExpressedAsDescripton[model.fullTerms.conversion.reset.floorExpressedRelativeTo];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: FloorExpressedAsDescripton
    };

    fieldDictionary['RES_CONV_CAP_AS'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.reset.capExpressedRelativeTo = value;
      },
      reverseMapFn: () => {
        return CapExpressedAsDescription[model.fullTerms.conversion.reset.capExpressedRelativeTo];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: CapExpressedAsDescription
    };

    fieldDictionary['RES_CONV_GLBL_FLOOR'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.reset.globalFloor = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.reset.globalFloor;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['RES_CONV_GLBL_CAP'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.reset.globalCap = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.reset.globalCap;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['RES_CONV_OPTIONAL'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.reset.optionalReset = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.reset.optionalReset;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['RES_SCHED_RANGE'] = {
      mapFn: (value: any) => {

      },
      reverseMapFn: () => {
        const result = model.fullTerms.conversion.reset.schedule.map(item => {
          return {
            startDate: getDateValue(item.startDate),
            endDate: getDateValue(item.endDate),
            floor: item.floor,
            cap: item.cap,
            mulltiplier: item.multiplier,
            trigger: item.trigger
          };
        }).sortBy('startDate');
        return result;
      },
      type: ExcelFieldDataTypes.Schedule
    };
    //#endregion

    //#region  Acquisition Shares
    fieldDictionary['ACQ_SHRS_SETTLE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.useAcquisitionSharesSettlement = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.useAcquisitionSharesSettlement;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['ACQ_SHRS_START_DATE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.acquisitionNoticeStartDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(model.fullTerms.conversion.acquisitionNoticeStartDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    fieldDictionary['ACQ_SHRS_END_DATE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.acquisitionNoticeEndDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(model.fullTerms.conversion.acquisitionNoticeEndDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    fieldDictionary['ACQ_SHRS_OPTION_DATE_TYPE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.acquisitionOptionDateInputType = value;
      },
      reverseMapFn: () => {
        return AcquisitionOptionDateInputTypeDescription[model.fullTerms.conversion.acquisitionOptionDateInputType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: AcquisitionOptionDateInputTypeDescription
    };

    fieldDictionary['ACQ_SHRS_OPTION_DATE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.conversionSettlementAcquisitionOptionDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(model.fullTerms.conversion.conversionSettlementAcquisitionOptionDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    fieldDictionary['ACQ_SHRS_VWAP_TYPE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.acquisitionVWAPPeriodInputType = value;
      },
      reverseMapFn: () => {
        return AcquisitionVWAPPeriodInputTypeDescription[model.fullTerms.conversion.acquisitionVWAPPeriodInputType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: AcquisitionVWAPPeriodInputTypeDescription
    };

    fieldDictionary['ACQ_SHRS_VWAP_START'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.conversionSettlementVWAPPeriodStartDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(model.fullTerms.conversion.conversionSettlementVWAPPeriodStartDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    fieldDictionary['ACQ_SHRS_VWAP_LAG'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.vwapPeriodObservationLag = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.vwapPeriodObservationLag;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['ACQ_SHRS_MIN_NOTICE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.acquisitionNoticeMinDays = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.acquisitionNoticeMinDays;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['ACQ_SHRS_MAX_NOTICE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.acquisitionNoticeMaxDays = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.acquisitionNoticeMaxDays;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['ACQ_SHRS_NOTICE_GIVEN'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.acquisitionNoticeGiven = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.acquisitionNoticeGiven;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['ACQ_SHRS_MAX_SHRS'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.conversionSettlementMaximumAcquisitionShares = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.conversionSettlementMaximumAcquisitionShares;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['ACQ_SHRS_TYPE'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.acquisitionSharesSettlType = value;
      },
      reverseMapFn: () => {
        return AcquisitionSharesSettlTypeDescription[model.fullTerms.conversion.acquisitionSharesSettlType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: AcquisitionSharesSettlTypeDescription
    };

    fieldDictionary['ACQ_SHRS_ASV_ADJUSTMENT'] = {
      mapFn: (value: any) => {
        model.fullTerms.conversion.acquisitionASVAdjustmentPerc = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.conversion.acquisitionASVAdjustmentPerc;
      },
      type: ExcelFieldDataTypes.Number
    };
    //#endregion
  }

  // tslint:disable-next-line: max-line-length
  mapCoCoSchedule(value: ContingentConversionScheduleItem[], schedule: ContingentConversionScheduleItem[]): ContingentConversionScheduleItem[] {
    if (schedule && schedule.length > 0) {
      const triggerType = schedule[0].triggerType;
      return value.map(a => ({
        ...a,
        startDate: new Date(a.startDate),
        endDate: new Date(a.endDate),
        triggerType: triggerType,
      }));
    }
    else {
      return value.map(a => ({
        ...a,
        startDate: new Date(a.startDate),
        endDate: a.endDate ? new Date(a.endDate) : null,
      }));
    }
  }

  mapConversionSchedule(value: ConversionScheduleItem[], schedule: ConversionScheduleItem[]): ConversionScheduleItem[] {
    if (schedule && schedule.length > 0) {
      const conversionPriceCCY = schedule[0].conversionPriceCCY;
      const rebateCurrencyType = schedule[0].rebateCurrencyType;
      return value.map(a => ({
        ...a,
        startDate: new Date(a.startDate),
        endDate: a.endDate ? new Date(a.endDate) : null,
        conversionPriceCCY: conversionPriceCCY,
        rebateCurrencyType: rebateCurrencyType,
      }));
    }
    else {
      return value;
    }
  }

  mapConversionPriceCCY(value: any, conversion: ConversionData) {
    if (conversion.schedule && conversion.schedule.length > 0) {
      conversion.schedule = conversion.schedule.map(a => ({
        ...a,
        conversionPriceCCY: value,
      }));
    }
  }

  mapConversionRebateCCY(value: any, conversion: ConversionData) {
    if (conversion.schedule && conversion.schedule.length > 0) {
      conversion.schedule = conversion.schedule.map(a => ({
        ...a,
        rebateCurrencyType: value,
      }));
    }
  }

  mapTriggerType(value: any, model: QuickAndFullTermsDocument) {
    if (model.fullTerms.conversion.contingentConversion.schedule && model.fullTerms.conversion.contingentConversion.schedule.length > 0) {
      model.fullTerms.conversion.contingentConversion.schedule = model.fullTerms.conversion.contingentConversion.schedule.map(a => ({
        ...a,
        // tslint:disable-next-line: max-line-length
        triggerType:  value,
      }));
    }
  }

  getConversionPrice(conversion: ConversionData): string {
    return conversion.schedule ? conversion.schedule[0].conversionPriceCCY : 'Convertible';
  }

  getConversionRebate(conversion: ConversionData): string {
    return conversion.schedule ? conversion.schedule[0].rebateCurrencyType : 'Convertible';
  }

  getTriggerType(model: QuickAndFullTermsDocument): string {
    // tslint:disable-next-line: max-line-length
    return model.fullTerms.conversion.contingentConversion.schedule &&
    model.fullTerms.conversion.contingentConversion.schedule.length > 0 ?
    ContingentConversionScheduleTriggerTypeDescription[model.fullTerms.conversion.contingentConversion.schedule[0].triggerType] : 'As % of Par';
  }

  setDefaultConversionReset(model: QuickAndFullTermsDocument, initialModel: QuickAndFullTermsDocument, isPrivateInstrument: boolean) {
    if (!model.fullTerms.conversion.reset) {
      model.fullTerms.conversion.reset = initialModel.fullTerms.conversion.reset;
    }
  }

  disableConversionSectionsForNonRegularInstruments(model: QuickAndFullTermsDocument) {
    if (model.fullTerms.issueAndRedemption.subType !== ConvertibleBondSubType.Regular) {
      model.fullTerms.conversion.isResetable = false;
      model.fullTerms.conversion.isContigentConversion = false;
      model.fullTerms.conversion.isVariableConversion = false;
      model.fullTerms.conversion.mandatoryConversion = false;
    }

    if (model.fullTerms.issueAndRedemption.subType === ConvertibleBondSubType.ConvertibleWithDetachableWarrant) {
      model.fullTerms.conversion.useAcquisitionSharesSettlement = false;
      model.fullTerms.conversion.fxAdjustedConversionAmount = false;
    }
  }

  /**
   * Recalculate conversion price or ration in conversion schedule
   * @param model Quick and full terms document model
   * @param conversionPriceCCY Conversion price currency
   * @param isCrossFx Flag that describes if bond is cross fx
   */
  recalculateConversionPriceOrRatio(model: QuickAndFullTermsDocument, conversionPriceCCY: string, isCrossFx: boolean) {
    // tslint:disable-next-line: max-line-length
    const fixedFX = isCrossFx && model.fullTerms.issueAndRedemption.fixedFXRate > 0 ? model.fullTerms.issueAndRedemption.fixedFXRate : 0;
    const nominal = model.fullTerms.issueAndRedemption.nominalValue;

    model.fullTerms.conversion.schedule = model.fullTerms.conversion.schedule.map(a => {
      if (!a.ratio && !!a.conversionPrice) {
        return {...a,
          ratio: a.conversionPrice > 0 ?
            conversionPriceCCY === 'Underlying' ?
              nominal * fixedFX / a.conversionPrice :
              nominal / a.conversionPrice : 0,
        };
      }
      else {
        return {...a,
          conversionPrice: a.ratio > 0 ?
            conversionPriceCCY === 'Underlying' ?
              nominal * fixedFX / a.ratio :
              nominal / a.ratio : 0,
        };
      }
    });
  }
}
