<kendo-tabstrip class="lv-tabstrip"
  [keepTabContent]="true">
  <kendo-tabstrip-tab
    selected="true"
    title="Mail Notifications">
    <ng-template kendoTabContent>
      <lv-mail-notifications-settings
        (didCancel)="onCancel()"
        (didSave)="onSave()">
      </lv-mail-notifications-settings>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
