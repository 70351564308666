<kendo-numerictextbox
  [class.lv-negative-label-color]="isNegative"
  [attr.name]="name"
  [placeholder]="placeholder"
  [spinners]="false"
  [format]="format"
  [decimals]="decimals"
  [disabled]="disabled"
  [readonly]="readonly"
  [tabindex]="tabIndex"
  [(value)]="innerValue"
  (blur)="onBlur()"
  (focus)="onFocus()">
</kendo-numerictextbox>