<lv-flex-box class="lv-credit"
  [style.min-width.px]="sectionMinWidth">

  <lv-environment-settings
    [section]="creditSection"
    lvId="marketDataCreditEnvironmentSettings"
    (didChangeEnvironment)="onChangedEnvironment($event)">
  </lv-environment-settings>
  <lv-flex-box class="lv-market-data-section-content">
    <ng-template *ngIf="!isExchangeable"
      [ngTemplateOutlet]="issuerCreditSection"
      class="lv-credit-issuer">
    </ng-template>
  
    <kendo-tabstrip *ngIf="isExchangeable" class="lv-credit-tabstrip" keepTabContent="true">
      <kendo-tabstrip-tab title="Issuer" [selected]="true">
        <ng-template kendoTabContent>
  
          <ng-template [ngTemplateOutlet]="issuerCreditSection"></ng-template>
  
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Underlying">
        <ng-template kendoTabContent>
          <lv-flex-box data-cy="DM-281"
            direction="row">
            <div class="lv-label lv-label-field"
              kendoTooltip
              [showAfter]="1500"
              filter="span"
              [tooltipTemplate]="creditSourceTooltipTemplate">
                <span>Credit Source</span>
            </div>
            <div class="lv-input-field lv-input-field--auto">
              <kendo-dropdownlist name="creditSource"
                lvId="lv-underlying-credit-source"
                [data]="creditSource.items"
                valueField="id"
                textField="text"
                [disabled]="isCreditSectionDisabled"
                [(ngModel)]="creditSettings.underlyingCreditSource"
                [valuePrimitive]="true"
                (valueChange)="onCreditSourceChange()"
                [popupSettings]="{
                  width: 225
                }">
              </kendo-dropdownlist>
            </div>
          </lv-flex-box>
          <lv-flex-box class="lv-credit-content underlyingTab" direction="row">
            <lv-credit-parameters *ngIf="!isCreditSectionDisabled"
              class="lv-credit-parameters-section lv-credit-section"
              [creditSource]="creditSettings.underlyingCreditSource"
              [creditParameters]="creditSettings.underlyingCreditParameters"
              [creditParametersSource]="'underlying'"
              (doSaveSession)="onCreditSourceChange()"
              #underlyingCreditParameters>
            </lv-credit-parameters>
          </lv-flex-box>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </lv-flex-box>

</lv-flex-box>

<ng-template #creditSourceTooltipTemplate>
  <lv-tooltip dm="dM-254"></lv-tooltip>
</ng-template>

<ng-template #issuerCreditSection>
  <lv-flex-box data-cy="DM-280" direction="row">
    <div class="lv-label lv-label-field"
      kendoTooltip
      [showAfter]="1500"
      filter="span"
      [tooltipTemplate]="creditSourceTooltipTemplate">
        <span>Credit Source</span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist name="creditSource"
        class="lv-credit-credit-source"
        lvId="lv-issuer-credit-source"
        [data]="creditSource.items"
        valueField="id"
        textField="text"
        [disabled]="isCreditSectionDisabled"
        [(ngModel)]="creditSettings.creditSource"
        [valuePrimitive]="true"
        (valueChange)="onCreditSourceChange()"
        [popupSettings]="{
          width: 225
        }">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box class="lv-credit-content issuerTab" direction="row">
    <lv-credit-parameters *ngIf="!isCreditSectionDisabled"
      class="lv-credit-parameters-section lv-credit-section"
      [creditSource]="creditSettings.creditSource"
      [creditParameters]="creditSettings.issuerCreditParameters"
      [creditParametersSource]="'issuer'"
      (doSaveSession)="onCreditSourceChange()"
      #issuerCreditParameters>
    </lv-credit-parameters>

    <lv-flex-box *ngIf="!isCreditSectionDisabled"
      class="lv-credit-factor-models"
      flex="1">
      <lv-asset-parameters *ngIf="isAssetParametersVisible"
        class="lv-credit-section"
        [assetParameters]="creditSettings.assetParameters"
        (doSaveSession)="onCreditSourceChange()">
      </lv-asset-parameters>
      <lv-equity-stochastic-credit-model *ngIf="IsStochasticVisible"
        class="lv-credit-section"
        [stochasticCreditModel]="creditSettings.stochasticCreditModel"
        (doSaveSession)="onCreditSourceChange()">
      </lv-equity-stochastic-credit-model>
      <lv-equity-linked-credit *ngIf="isEquityLinkedVisible"
        class="lv-credit-section"
        [equityLinkedCredit]="creditSettings.equityLinkedCredit"
        (doSaveSession)="onCreditSourceChange()">
      </lv-equity-linked-credit>
    </lv-flex-box>
  </lv-flex-box>
</ng-template>
