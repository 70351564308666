import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientBase } from '@lv-core-ui/api';
import { IApplicationSettingsResponse, LvApplicationSettingsError, ISaveApplicationSettingsRequest } from '@lv-application-settings/models';

/**
 * Settings service class that manages application state settings actions
 */
@Injectable()
export class SettingsService extends HttpClientBase {

  constructor(
    http: HttpClient
  ) {
    const resourceUrl = '/applicationSettings';
    super(http, resourceUrl);
  }

  /**
   * Gets application state from database
   * @returns Promise based IApplicationSettingsResponse object
   */
  async getApplicationState(): Promise<IApplicationSettingsResponse> {
    try {
      const response = await this.getAsync<IApplicationSettingsResponse>();

      return response;
    }
    catch (error) {
      throw this.handleError(
        error,
        e => new LvApplicationSettingsError(e.message),
        true);
    }
  }

  /**
   * Saves application state to database
   * @param {ISaveApplicationSettingsRequest} request Request object with userid and state
   * @returns Promise based IApplicationSettingsResponse object
   */
  async saveApplicationState(request: ISaveApplicationSettingsRequest): Promise<IApplicationSettingsResponse> {
    try {
      const response = await this.postAsync<IApplicationSettingsResponse>(request, null, null, null, 60000);

      return response;
    }
    catch (error) {
      throw this.handleError(
        error,
        e => new LvApplicationSettingsError(e.message),
        true);
    }
  }

  /**
   * Registers application instance in database
   * @param {string} instanceId Id of a instance
   */
  async registerApplicationInstance(instanceId: string): Promise<void> {
    try {
      await this.postAsync<any>({instanceId: instanceId}, '/applicationInstance');
    }
    catch (error) {
      throw this.handleError(
        error,
        e => new LvApplicationSettingsError(e.message),
        true);
    }
  }

  /**
   * Unregisters application instance in database
   * @param {string} instanceId Id of a instance
   */
  async unregisterApplicationInstance(instanceId: string): Promise<void> {
    try {
      await this.deleteAsync<any>(`/applicationInstance/${instanceId}`);
    }
    catch (error) {
      throw this.handleError(
        error,
        e => new LvApplicationSettingsError(e.message),
        true);
    }
  }

  /**
   * Get application instance based on id
   */
  async getInstanceId(): Promise<any> {
    try {
      const response = await this.getAsyncWihTextResponseType(null, '/applicationInstance', null);
      return response;
    }
    catch (error) {
      throw this.handleError(
        error,
        e => new LvApplicationSettingsError(e.message),
        true);
    }
  }

  /**
   * Checks if application instance is available and extends it
   * @param {string} instanceId Id of a instance
   */
  async InstanceHeartBeat(instanceId: string): Promise<void> {
    try {
      await this.postAsync<any>(null, `/applicationInstance/heartBeat/${instanceId}`);
    }
    catch (error) {
      /*Swallow this error by purpose. If hartbeat sometimes fails, we don't want to bother user */
      console.log(error);
    }
  }
}
