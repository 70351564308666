/**
 * Permissions.
 */
export enum Permissions {
  MODULE = 'PRIVATE_INSTRUMENTS',

  CREATE_PRIVATE_INSTRUMENT = 'CREATE_PRIVATE_INSTRUMENT',
  SHARE_PRIVATE_INSTRUMENT = 'SHARE_PRIVATE_INSTRUMENT',

  COPY_AMERICA_INSTRUMENT = 'COPY_AMERICA_INSTRUMENT',
  COPY_ASIA_INSTRUMENT = 'COPY_ASIA_INSTRUMENT',
  COPY_EMEA_INSTRUMENT = 'COPY_EMEA_INSTRUMENT'
}
