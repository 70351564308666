import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Input,
  ViewChild, OnChanges, SimpleChanges, ViewRef } from '@angular/core';
import { NgForm } from '@angular/forms';

import { LvScenarioInputsComponent } from './lv-scenario-inputs/lv-scenario-inputs.component';
import { LvScenarioView } from './lv-scenario.view';
import { LvScenarioChartComponent } from './lv-scenario-chart/lv-scenario-chart.component';
import { LvSensitivityAnalysisPanelService } from '../lv-sensitivity-analysis-panel.service';
import { ToastrService } from 'ngx-toastr';
import { LvSidePanelComponent } from '@lv-core-ui/components';
import { LvDataMaster, LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { LvAngularUtil, LvFormUtil } from '@lv-core-ui/util';
import { ILvScenario, IConvertible, LvScenarioDimension, ICalculateScenarioRequest,
         ICalculateScenarioResponse } from '@lv-analytics/models';
import { ScenarioCalculationService } from '@lv-analytics/services';

/**
 * Scenario component.
 */
@Component({
  selector: 'lv-scenario',
  templateUrl: './lv-scenario.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvScenarioComponent implements OnInit, OnChanges {

  @ViewChild('firstDimension', { static: true }) firstDimension: LvScenarioInputsComponent;
  @ViewChild('secondDimension') secondDimension: LvScenarioInputsComponent;

  @ViewChild(LvScenarioChartComponent) chart: LvScenarioChartComponent;

  @ViewChild(LvSidePanelComponent, { static: true }) settingsPanel: LvSidePanelComponent;
  @ViewChild('priceTalkForm', { static: true }) priceTalkForm: NgForm;

  @Input() scenario: ILvScenario;
  @Input() convertible: IConvertible;

  get firstDimensionShiftLabel(): string {
    return this.view.getShiftLabel('Rows');
  }

  get secondDimensionShiftLabel(): string {
    return this.view.getShiftLabel('Columns');
  }

  view: LvScenarioView;
  isLoading: boolean;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private errorService: LvErrorService,
    private service: ScenarioCalculationService,
    private _sas: LvSensitivityAnalysisPanelService,
    private toastrService: ToastrService
  ) {
    this.isLoading = false;

    this.view = new LvScenarioView();

    // set defaults
    this.view.init();
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Does view initialization and sets report data.
   * @param changes SimpleChanges object.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (LvAngularUtil.inputChanged(changes, 'scenario') || LvAngularUtil.inputChanged(changes, 'convertible')) {
      this.view.init(this.scenario, this.convertible);

      const reportData = this._sas.getResult(this.view.model.id);
      if (reportData) {
        this.view.setReportData(reportData);
      }
    }
  }

  /**
   * Occurs on dimension change.
   * @param ev Event.
   */
  onDimensionChange(ev: Event) {
    this.view.model.dimension = ((ev.target as HTMLInputElement).value as LvScenarioDimension);
    this.view.onDimensionChange();

    this.detectChanges();
  }

  /**
   * Occurs on clear.
   */
  onClear() {
    this.view.resetPriceTalk();
    LvFormUtil.markAllControlsAsPristine(this.priceTalkForm);

    this.detectChanges();
    this.firstDimension.reset();

    if (this.view.model.dimension === '2-d') {
      this.secondDimension.reset();
    }

    this.clearReport();
  }

  /**
   * Occurs on scenario new issue change.
   */
  onScenarioIsNewIssueChange() {
    this.view.onScenarioIsNewIssueChange();

    this.detectChanges();
  }

  /**
   * Occurs on splitter collapsed change.
   * @param collapsed A flag indicating if chart is collapsed.
   */
  onSplitterCollapsedChange(collapsed: boolean) {
    this.chart.toggle(collapsed);
  }

  /**
   * Occurs on open settings.
   */
  onOpenSettings() {
    if (!this.isLoading) {
      this.settingsPanel.togglePanel();
    }
  }

  /**
   * Checks if price talk form, first dimension and second dimension are valid.
   * @returns A flag indicating if price talk form, first dimension and second dimension are valid.
   */
  isValid(): boolean {
    let valid = this.priceTalkForm.valid;

    if (!valid) {
      LvFormUtil.markAllControlsAsDirty(this.priceTalkForm);
    }

    valid = valid && this.firstDimension.isValid();

    if (this.secondDimension) {
      const secondDimensionValid = this.secondDimension.isValid();
      valid = valid && secondDimensionValid;
    }

    this.detectChanges();
    return valid;
  }

  /**
   * Calculates scenario request.
   * @param request ICalculateScenarioRequest object.
   */
  async calculate(request: ICalculateScenarioRequest) {
    try {
      this.isLoading = true;
      this.detectChanges();

      if (this.view.model.outputs.length === 0) {
        this.errorService.toastrService.warning(LvDataMaster.getWarning('dM-1858'), 'Calclulate Scenario');
      }
      else {
        request.scenario = {
          ...this.view.model,
          firstDimension: { ...this.view.model.firstDimension },
          secondDimension: this.view.model.secondDimension ? { ...this.view.model.secondDimension } : null
        };

        let response: ICalculateScenarioResponse<any> = null;

        if (!this.view.isTwoDimensional) {
          response = await this.service.calculateOneDimensionalScenario(request);
        }
        else {
          response = await this.service.calculateTwoDimensionalScenario(request);
        }

        this._sas.setResult(this.view.model.id, response.outputs.map(a => ({ ...a })));

        this.view.setReportData(response.outputs);
      }
    }
    catch (error) {
      const lvError = error as LvError;
      if (lvError.name === 'Analytics' && error.message === 'Unauthorized Access') {
        this.toastrService.error(LvDataMaster.getError('dM-3389', {'value': 'sensitivity analysis'}), error.name);
      } else {
        this.errorService.handleError(error);
      }

      this.view.report = null;
    }
    finally {
      this.isLoading = false;
      this.detectChanges();
    }
  }

  /**
   * Cleans report.
   */
  clearReport() {
    this._sas.removeResult(this.view.model.id);

    this.view.clearReport();

    this.detectChanges();
  }

  /**
   * Rerenders shart.
   */
  rerenderChart() {
    if (this.chart) {
      this.chart.rerender();
    }
  }

  /**
   * Detects changes.
   */
  private detectChanges() {
    if (!(this._changeDetectorRef as ViewRef).destroyed) {
      this._changeDetectorRef.detectChanges();
    }
  }
}
