import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { IPricingEnvironmentSettings } from '@lv-analytics/models/company-and-user-settings/environments-settings';
import { IPricingEnvironmentOrderSettings } from '@lv-analytics/models/company-and-user-settings/environments-settings/setup-settings/pricing-evironment-order-settings';
import { DialogService } from '@progress/kendo-angular-dialog';
import { LvEditSystemEnvironmentSettingsDialogComponent } from './lv-edit-system-environment-settings-dialog/lv-edit-system-environment-settings-dialog.component';

/**
 * Environment system settings component.
 */
@Component({
  selector: 'lv-environment-system-settings',
  templateUrl: './lv-environment-system-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEnvironmentSystemSettingsComponent {

  @Input() settings: IPricingEnvironmentSettings[];

  @Output() emitSavePricingEnvironmentSaveRequest: EventEmitter<IPricingEnvironmentOrderSettings>;

  constructor(
    private _dialogService: DialogService,
    private _changeDetectorRef: ChangeDetectorRef) {
    this.settings = [];
    this.emitSavePricingEnvironmentSaveRequest = new EventEmitter<IPricingEnvironmentOrderSettings>();
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-environment-system-settings')
  get isLvEnvironmentSetupSettingsComponent() {
    return true;
  }

  get availableSettings(): IPricingEnvironmentSettings[] {
    if (!this.settings) {
      return [];
    }

    if (this.settings.length > 0) {
      return this.settings.filter(x => !x.isDisabled);
    }

    return [];
  }

  /**
   * Open dialog for edit environment settings
   */
  openEnvironmentOrderEdit() {
    const dialogRef = this._dialogService.open({
      title: 'Edit Environments Order',
      content: LvEditSystemEnvironmentSettingsDialogComponent,
      width: 317,
      height: 187
    });

    dialogRef.dialog.location.nativeElement.classList.add('lv-edit-system-environment-settings-dialog-wrapper');

    const dialog = dialogRef.content.instance as LvEditSystemEnvironmentSettingsDialogComponent;
    dialog.settings = this.availableSettings;

    dialog.doEmitSavePricingEnvironmentSaveRequest.subscribe(event => {
      this.emitSavePricingEnvironmentSaveRequest.next(event);
      this._changeDetectorRef.detectChanges();
    });
  }
}
