import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnChanges,
  SimpleChanges, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';

import { LvScenarioInputsView } from './lv-scenario-inputs.view';
import { Align } from '@progress/kendo-angular-popup';
import { LvAngularUtil, LvFormUtil } from '@lv-core-ui/util';
import { ILvScenarioInputs, IConvertible, ScenarioInputsShift } from '@lv-analytics/models';

/**
 * Scenario inputs component.
 */
@Component({
  selector: 'lv-scenario-inputs',
  templateUrl: './lv-scenario-inputs.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvScenarioInputsComponent implements OnInit, OnChanges {

  @ViewChild(NgForm, { static: true }) form: NgForm;

  @Input() shiftLabel: string;
  @Input() isNewIssueScenario: boolean;
  @Input() inputs: ILvScenarioInputs;
  @Input() convertible: IConvertible;
  @Input() otherDimensionShift: ScenarioInputsShift;

  shiftDisabled: (itemArgs: { dataItem: string, index: number }) => boolean;
  view: LvScenarioInputsView;

  get anchorAlign(): Align {
    return {
      horizontal: 'left',
      vertical: 'bottom'
    };
  }
  get popupAlign(): Align {
    return {
      horizontal: 'left',
      vertical: 'top'
    };
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.shiftLabel = 'Shift';

    this.view = new LvScenarioInputsView();

    this.shiftDisabled = (itemArgs: { dataItem: any }) => {

      if ((this.otherDimensionShift === ScenarioInputsShift.UnderlyingPrice
          || this.otherDimensionShift === ScenarioInputsShift.UnderlyingPriceCB)
        && (itemArgs.dataItem.id === ScenarioInputsShift.UnderlyingPrice
          || itemArgs.dataItem.id === ScenarioInputsShift.UnderlyingPriceCB)) {
        return true;
      }

      if (this.otherDimensionShift === itemArgs.dataItem.id) {
        return true;
      }

      return false;
    };
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Does view initialization.
   * @param changes SimpleChanges object.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (LvAngularUtil.inputChanged(changes, 'isNewIssueScenario')
      || LvAngularUtil.inputChanged(changes, 'inputs')
      || LvAngularUtil.inputChanged(changes, 'convertible')) {
      this.view.init(this.isNewIssueScenario, this.inputs, this.convertible);
    }
  }

  /**
   * Resets view.
   */
  reset(): void {
    this.view.reset();

    LvFormUtil.markAllControlsAsUntouched(this.form);

    this.changeDetectorRef.detectChanges();
  }

  /**
   * Checks if form is valid.
   */
  isValid(): boolean {
    let valid = false;

    if (this.form.valid) {
      valid = true;
    }
    else {
      LvFormUtil.markAllControlsAsDirty(this.form);
    }

    this.changeDetectorRef.detectChanges();
    return valid;
  }

  /**
   * Occurs on shift change.
   */
  onShiftChange() {
    this.view.onShiftChange();

    LvFormUtil.markAllControlsAsUntouched(this.form);

    this.changeDetectorRef.detectChanges();
  }
}
