import { Input, Component, OnInit, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'lv-negative-label',
  templateUrl: './lv-negative-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvNegativeLabelComponent implements OnInit, OnChanges {
  @Input() value?: number;
  @Input() format?: string;
  @Input() prefix?: string;
  @Input() suffix?: string;
  @Input() autoSuffixWidth: boolean;
  @Input() shouldShowBorder: boolean;

  transformed: string;

  get visible(): boolean {
    if (this.value === null || typeof(this.value) === 'undefined') {
      return false;
    }

    return true;
  }

  get isSuffixVisible(): boolean {
    if (this.shouldShowBorder) {
      return this.visible || (this.suffix !== null);
    } else {
      return this.visible && (this.suffix !== null);
    }
  }

  get isNegative(): boolean {
    return this.value ? this.value < 0 : false;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private decimalPipe: DecimalPipe
  ) {
    this.format = '1.0-3';
    this.prefix = null;
    this.suffix = null;
    this.autoSuffixWidth = false;
    this.shouldShowBorder = false;
  }

  transformValue() {
    this.transformed = this.decimalPipe.transform(this.value, this.format);
    this._changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    if (this.visible) {
      this.transformValue();
    }
  }

  ngOnChanges() {
    if (this.visible) {
      this.transformValue();
    }
  }
}
