import { ChangeDetectorRef, OnInit, OnDestroy, Directive } from '@angular/core';
import { IBaseWidgetState } from './models/widget-state/base-widget-state';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { WidgetStateManagerService } from '@lv-application-settings/services';
import { DefaultWidgetType } from '@lv-shared/models';

/**
 * Base widget component.
 */
@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class LvBaseWidgetComponent<T extends IBaseWidgetState> implements  OnInit, OnDestroy {

  widgetState: T;
  private _defaultStateUpdatedSubscription: Subscription;
  private _defaultWidgetStateSelectedSubscription: Subscription;

  public showSettings: boolean;

  constructor(
    public _changeDetectorRef: ChangeDetectorRef,
    public _widgetStateManagerService: WidgetStateManagerService,
    public initialState: T,
    public widgetType: DefaultWidgetType,
  ) {
    this._widgetStateManagerService.registerWidget(widgetType, this.initialState);
    this.widgetState = this._widgetStateManagerService.getWidgetState(widgetType);
    this.widgetState.isOpenedFromExcel = this.initialState.isOpenedFromExcel;
    this.showSettings = true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._defaultStateUpdatedSubscription = this._widgetStateManagerService.didDefaultStateUpdated().subscribe((type) => {
      if (type === this.widgetType) {
        const widgetStateBuackup = _.cloneDeep(this.widgetState);

        const dashboardStateSet = this._widgetStateManagerService.refreshDashboardState();

        if (dashboardStateSet) {
          this.widgetState = this._widgetStateManagerService.getWidgetState(this.widgetType);
          if (!this.widgetState.useDefaultView && widgetStateBuackup.useDefaultView) {
            this.widgetState = {
              ...widgetStateBuackup,
              useDefaultView: this.widgetState.useDefaultView
            };
            this._widgetStateManagerService.useCustomView(this.widgetType);
          }
        }
        this.widgetState.isOpenedFromExcel = this.initialState.isOpenedFromExcel;
        this._changeDetectorRef.detectChanges();
      }
    });

    this._defaultWidgetStateSelectedSubscription = this._widgetStateManagerService.didSelectDefaultWidgetState().subscribe(() => {
      this.widgetState.useDefaultView = true;
    });

    if (this.widgetState.useDefaultView) {
      const dfState = this._widgetStateManagerService.getDefaultWidgetState(this.widgetType);
      if (dfState) {
        this.widgetState = dfState;
        this.widgetState.isOpenedFromExcel = this.initialState.isOpenedFromExcel;
      }
    }

    this._widgetStateManagerService.saveWidgetStateIfNotExist(this.widgetType, this.widgetState);
    if (!this._widgetStateManagerService.dashboardStateId && !this.widgetState.isOpenedFromExcel) {
      this.showSettings = false;
    }
    this.onInit();
  }

  /**
   * Occurs on display custom view, gets widget state and set custom view.
   */
  onDisplayCustomView() {
    this.widgetState = this._widgetStateManagerService.getWidgetState(this.widgetType);
    this.widgetState.useDefaultView = false;
    this._widgetStateManagerService.useCustomView(this.widgetType);
    this.viewChanged();
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on display default view, gets default widget state and set default view.
   */
  onDisplayDefaultView() {
    this.widgetState = this._widgetStateManagerService.getDefaultWidgetState(this.widgetType);
    this.widgetState.useDefaultView = true;
    this._widgetStateManagerService.useDefaultView(this.widgetType);
    this.viewChanged();
    this._changeDetectorRef.detectChanges();
  }

  /**
   *  Occurs on save as default view and saves default view.
   */
  onSaveAsDefaultView() {
    this.widgetState.useDefaultView = true;
    this.widgetState.isOpenedFromExcel = this.initialState.isOpenedFromExcel;
    this._widgetStateManagerService.saveAsDefaultView(this.widgetType, this.widgetState);
    this.viewChanged();
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy(): void {
    this._defaultStateUpdatedSubscription.unsubscribe();
    this._defaultWidgetStateSelectedSubscription.unsubscribe();
    this.onDestroy();
  }

  /**
   * Handles view changes.
   */
  viewChanged() { }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  abstract onDestroy(): void;

  /**
   * Handles any additional initialization tasks.
   */
  abstract onInit(): void;
}
