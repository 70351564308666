import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NGXLogger, NgxLoggerLevel, INGXLoggerConfig } from 'ngx-logger';

import { environment } from 'src/environments/environment';

import { LocalStorage } from '../../util/local-storage';
import { refreshAccessToken } from '../../api/http-client-base.helpers';
import { ITokenResponse } from '../../models/token-response';


@Injectable()
export class LvLoggerService {

  loggerConfig: INGXLoggerConfig;

  constructor(
   private _logger: NGXLogger,
   private _httpClient: HttpClient
  ) {
    this.loggerConfig = this._logger.getConfigSnapshot();
  }

  async log(message: any) {
    try {
      const tokenResponse = await this.getTokenResponse();

      this._logger.setCustomHttpHeaders(this.getHttpHeaders(tokenResponse.accessToken));
      this._logger.setWithCredentialsOptionValue(true);

      this._logger.log(message);
    }
    catch (error) {
      this.logToConsole(error);
    }
  }

  async logError(message: any) {
    try {
      const tokenResponse = await this.getTokenResponse();

      this._logger.setCustomHttpHeaders(this.getHttpHeaders(tokenResponse.accessToken));
      this._logger.setWithCredentialsOptionValue(true);

      this._logger.error(message);
    }
    catch (error) {
      this.logToConsole(error);
    }
  }

  private async getTokenResponse(): Promise<ITokenResponse> {
    try {
      let tokenResponse = LocalStorage.getTokenResponse();

      if (LocalStorage.isAccessTokenExpired()) {
        tokenResponse = await refreshAccessToken(this._httpClient);
      }

      return tokenResponse;
    }
    catch (error) {
      throw error;
    }
  }

  private logToConsole(error: any) {
    this._logger.updateConfig({
      ...this.loggerConfig,
      serverLogLevel: NgxLoggerLevel.OFF
    });

    this._logger.error(error);
  }

  private getHttpHeaders(authHandle: string): HttpHeaders {
    return new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '-1',
      'Content-Type': 'application/json',
      'X-Application-Id': environment.applicationId,
      'X-Request-Id': 'client-logger',
      'Authorization': `Bearer ${authHandle}`,
    });
  }
}
