<div class="lv-modal-mask" [@mask]
  *ngIf="isOpened"
  [ngClass]="maskClass">
</div>
<div class="lv-modal" [@modal]
  *ngIf="isOpened"
  [ngClass]="modalClass">
  <div class="lv-modal-table">
    <div class="lv-modal-table-cell">
      <div class="lv-modal-table-cell-content">
        <div class="lv-modal-container">
            <div class="lv-modal-title">
              Real Time Communication
            </div>
            <div class="lv-modal-content">
              <div>Server communication has stopped.</div>
              <div>You will not be able to receive immediate updates.</div>
              <div class="lv-modal-error-message">&nbsp;{{ errorMessage }}</div>
            </div>
            <div class="lv-modal-footer">
              <button
                kendoButton
                [primary]="true"
                [disabled]="isLoading"
                (click)="onReconnect()">
                Reconnect
              </button>
              <button
                kendoButton
                [primary]="true"
                [disabled]="isLoading"
                (click)="onContinue()">
                Continue
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>