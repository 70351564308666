import { IListener } from './resize-handler.service';

export class ResizeHandler {
  public static break(listener: IListener, width: number, action: (brokeBelow: boolean) => void) {
    if (width < listener.breakingPoint && width < listener.previousWidth && listener.previousWidth > listener.breakingPoint) {
      listener.previousWidth = width;
      action(true);
    }
    else if (width > listener.breakingPoint && width > listener.previousWidth && listener.previousWidth < listener.breakingPoint) {
      listener.previousWidth = width;
      action(false);
    }
  }
}
