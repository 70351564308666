import { LvPermissionService } from '@lv-core-ui/services';
import { AuthorizationService } from '@lv-core-ui/services/authorization/authorization.service';
import { LvUtil } from '@lv-core-ui/util';
import { IQuickWatchList, IWatchListPanelDialogEvent, IWatchList, IWidgetState } from '@lv-instrument-monitor/models';
import { WatchListService } from '@lv-instrument-monitor/services';
import { WatchListView } from '@lv-instrument-monitor/views';

export class LvInstrumentMonitorFilterView {

  filter: IWidgetState;
  watchListView: WatchListView;

  quickWatchListDict: {
    [id: string]: IQuickWatchList;
  };

  get quickWatchListsVisible(): boolean {
    return !this.filter.selected.length;
  }

  constructor(
    private _authorizationService: AuthorizationService,
    private _permissionService: LvPermissionService,
    private watchListService: WatchListService
  ) {
    this.filter = {
      query: null,
      quick: [],
      selected: []
    };

    this.quickWatchListDict = {};

    this.watchListView = new WatchListView(this._authorizationService, this._permissionService, this.watchListService);
  }

  init(filter?: IWidgetState) {
    if (filter) {
      const canSeeDataServiceTrialWl = this._permissionService.hasPermission('INSTRUMENT_MONITOR', 'DATA_SERVICE_TRIAL_WATCH_LIST');

      if (!canSeeDataServiceTrialWl) {
        filter.quick = filter.quick.filter(x => x.listName !== 'Data Service Trial');
        filter.selected = filter.selected.filter(x => x.listName !== 'Data Service Trial');
      }

      this.filter = {
        ...filter,
        quick: [...filter.quick],
        selected: [...filter.selected]
      };

      this.setQuickWatchListDictionary();
    }
  }

  updateFilter(panelEvent: IWatchListPanelDialogEvent) {
    const deletedDict = LvUtil.toDictionary(panelEvent.deleted, 'id');
    const updatedDict = LvUtil.toDictionary(panelEvent.updated, 'id');

    this.filter.selected = this.filter.selected.filter(a => !deletedDict[a.id]);
    this.filter.quick = this.filter.quick.filter(a => !deletedDict[a.id]);

    if (panelEvent.updated.length > 0) {
      this.filter.selected = this.filter.selected.map(a => {
        if (updatedDict[a.id]) {
          return {
            ...updatedDict[a.id]
          };
        }
        else {
          return a;
        }
      });

      this.filter.quick = this.filter.quick.map(a => {
        if (updatedDict[a.id]) {
          return {
            ...updatedDict[a.id],
            isSelected: a.isSelected
          };
        }
        else {
          return a;
        }
      });
    }

    this.setQuickWatchListDictionary();
  }

  onQueryChange(query: string) {
    this.filter.query = query;
  }

  onWatchListMultiSelect() {
    this.filter.quick.forEach(a => a.isSelected = false);
    this.setQuickWatchListDictionary();
  }

  onQuickWatchListSelect(wl: IQuickWatchList, isSelected: boolean) {
    const found = this.filter.quick.find(a => a.id === wl.id);
    found.isSelected = isSelected;
    this.filter.selected = [];
    this.setQuickWatchListDictionary();
  }

  onQuickWatchListClose(wl: IQuickWatchList) {
    this.filter.quick = this.filter.quick.filter(a => a.id !== wl.id);
    this.setQuickWatchListDictionary();
  }

  onClearAllQuickWatchLists() {
    this.filter.quick.forEach(a => a.isSelected = false);
    this.setQuickWatchListDictionary();
  }

  isQuickWatchListSelected(wl: IWatchList): boolean {
    return !!this.quickWatchListDict[wl.id];
  }

  onChooseQuickWatchList(wl: IWatchList) {
    if (this.quickWatchListDict[wl.id]) {
      delete this.quickWatchListDict[wl.id];
    }
    else {
      this.quickWatchListDict[wl.id] = {
        ...wl,
        isSelected: false
      };
    }
  }

  onApplyQuickWatchListEdit() {
    this.filter.quick = Object.keys(this.quickWatchListDict).map(a => this.quickWatchListDict[a]);
    this.setQuickWatchListDictionary();
  }

  setQuickWatchListDictionary() {
    this.quickWatchListDict = LvUtil.toDictionary(this.filter.quick, 'id');
  }
}
