import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

/**
 * Market data presenter.
 */
@Injectable()
export class LvMarketDataPresenter {

  get isLoading(): Observable<boolean> {
    return this._isLoading.asObservable();
  }

  private _isLoading: Subject<boolean>;

  constructor() {
    this._isLoading = new Subject<boolean>();
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  public setLoadingState(isLoading: boolean) {
    this._isLoading.next(isLoading);
  }
}
