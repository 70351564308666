<lv-flex-box class="lv-borrow-flat">
  <lv-flex-box direction="row" data-cy="DM-303">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="borrowRebateTooltipTemplate"
      filter="span:not(lv-xl-label span)">
        <span [attr.borrow-tooltip-id]="'rebateRateTooltip'">
          Rebate Rate
        </span>
        <lv-xl-label [field]="'BRW_REBATE'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        #borrowRebateRate
        name="rebateRate"
        [format]="numberFormat"
        [decimals]="numberOfDecimals"
        [(ngModel)]="borrowSettings.rebateRate"
        (didKeyDownEnter)="onValueChange()"
        (didBlur)="onValueChange()">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-304">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="borrowFoundingRateTooltipTemplate"
      filter="span:not(lv-xl-label span)">
        <span [attr.borrow-tooltip-id]="'fundingRateTooltip'">
          Funding Rate
        </span>
        <lv-xl-label [field]="'BRW_FUND_RATE'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        #borrowFundingRate
        name="fundingRate"
        [format]="numberFormat"
        [decimals]="numberOfDecimals"
        [(ngModel)]="borrowSettings.fundingRate"
        (didKeyDownEnter)="onValueChange()"
        (didBlur)="onValueChange()">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>
  </lv-flex-box>
</lv-flex-box>

<ng-template #borrowRebateTooltipTemplate>
  <lv-tooltip dm="dM-303"></lv-tooltip>
</ng-template>
<ng-template #borrowFoundingRateTooltipTemplate>
  <lv-tooltip dm="dM-304"></lv-tooltip>
</ng-template>