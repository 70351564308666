import { ICreditTermStructureItem } from './credit-term-structure-item';
import { CreditSpreadType } from './enums/credit-spread-type';
import { DateAdjustment } from './enums/date-adjustment';
import { AccruedOnDefault } from './enums/accrued-on-default';
import { PeriodFrequency } from './enums/period-frequency';
import { DayCount } from './enums/day-count';

export interface ICreditParameters {
  flatCreditSpread: number;

  defaultRate: number;

  recoveryRateCDS: number;

  creditSpreadType: CreditSpreadType;

  recoveryRateBond: number;

  cdsSpread: number;

  cdsUpfront: number;

  valuationDate: Date;

  badDayConversion: DateAdjustment;

  accruedOnDefault: AccruedOnDefault;

  frequency: PeriodFrequency;

  basis: DayCount;

  creditTermStructure: ICreditTermStructureItem[];

  creditCDSTermStructure: ICreditTermStructureItem[];

  creditDefaultRateTermStructure: ICreditTermStructureItem[];

  creditSurvivalProbabilityTermStructure: ICreditTermStructureItem[];

  cdsIndexLoss: number;

  jumpOnUnderlyingDefault: number;

  creditModelFactor: CreditModelFactor;
}

/**
 * Credit model factor display.
 */
export enum CreditModelFactorDisplay {
  Factor1 = '1 Factor',
  Factor15 = '1.5 Factor',
  Factor2 = '2 Factor'
}

/**
 * Credit model factor display underlying.
 */
export enum CreditModelFactorDisplayUnderlying {
  Factor1 = '1 Factor'
}

/**
 * Credit model factor.
 */
export enum CreditModelFactor {
  Factor1 = 'Factor1',
  Factor15 = 'Factor15',
  Factor2 = 'Factor2'
}
