import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IInstrumentSourceSectionSettings } from '@lv-analytics/models';
import { v4 } from 'uuid';

/**
 * Instrument source section settings component .
 */
@Component({
  selector: 'lv-instrument-source-section-settings',
  templateUrl: './lv-instrument-source-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvInstrumentSourceSectionSettingsComponent implements OnInit {

  @Input() settings: IInstrumentSourceSectionSettings;
  @Input() overrideSystemDefaults: boolean;

  useLeversysRadioButtonId: string;
  useCustomRadioButtonId: string;
  radioButtonName: string;

  constructor() {
    this.useCustomRadioButtonId = v4();
    this.useLeversysRadioButtonId = v4();
    this.radioButtonName = v4();
    this.overrideSystemDefaults = true;
   }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit(): void {
  }

  /**
   * Gets checkbox identifier.
   * @param name Checkbox name.
   * @returns Checkbox identifier.
   */
  getCheckBoxIdentifier(name: string): string {
    return `${name}-${this.useLeversysRadioButtonId}`;
  }
}
