import { v4 } from 'uuid';

import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation, ChangeDetectorRef, HostListener, Optional,
  ViewChild, HostBinding, Output, EventEmitter, forwardRef, Injector, OnInit, Type, ViewRef } from '@angular/core';
import { ControlValueAccessor, NgForm, FormGroupDirective, NgControl,
  Validator, NG_VALIDATORS, ValidationErrors, AbstractControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';

@Component({
  selector: 'lv-numeric-text-box',
  templateUrl: './lv-numeric-text-box.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LvNumericTextBoxComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => LvNumericTextBoxComponent),
    multi: true
  }]
})

export class LvNumericTextBoxComponent implements ControlValueAccessor, Validator, OnInit {
  @ViewChild(NumericTextBoxComponent, { static: true }) kendoInput: NumericTextBoxComponent;

  @Input() name: string;
  @Input() placeholder: string;

  @Input() min: number;
  @Input() max: number;

  @Input() format: string | NumberFormatOptions | null | undefined;
  @Input() decimals: number;
  @Input() showNegativeLabel: boolean;

  @Input() disabled: boolean;
  @Input() readonly: boolean;

  @Input() autoWidth: boolean;

  @Input()
  set tabIndex(value: number) {
    this._tabIndex = value;
  }

  get tabIndex(): number {
    return this.disabled || this.readonly ? -1 : this._tabIndex;
  }
  public get innerValue(): number {
    return this._innerValue;
  }

  @Output() didBlur: EventEmitter<void>;
  @Output() didFocus: EventEmitter<void>;
  @Output() didKeyDownEnter: EventEmitter<void>;

  public set innerValue(value: number) {
    this._innerValue = value;

    this.touchControl();

    this.onValueChange(this._innerValue);
  }

  public get invalid(): boolean {
    const isInvalid = this._control && this._control.invalid;
    const isTouched = this._control && this._control.touched;

    const isSubmitted = (this.parentFormGroup && this.parentFormGroup.submitted) || (this.parentForm && this.parentForm.submitted);

    if (!this.doValidateControl()) {
      return true;
    }

    return !!(isInvalid && (isTouched || isSubmitted));
  }

  public get touched(): boolean {
    return this._control && this._control.touched;
  }

  private _tabIndex: number;
  private _innerValue: number;

  public onValueChange: (value: any) => void;
  public onControlTouched: () => void;

  private _focused: boolean;
  private _suppressBlur: boolean;

  private _control: NgControl;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _injector: Injector,
    @Optional() private parentForm: NgForm,
    @Optional() private parentFormGroup: FormGroupDirective
  ) {
    this._focused = false;
    this._suppressBlur = false;

    this.name = `lv-numeric-text-box-${v4()}`;
    this.placeholder = null;

    this.min = null;
    this.max = null;

    this.format = 'n4';
    this.decimals = 4;
    this.showNegativeLabel = false;

    this.disabled = false;
    this.readonly = false;

    this.autoWidth = false;
    this.tabIndex = 0;

    this.onValueChange = (value: any) => {};
    this.onControlTouched = () => {};

    this.didBlur = new EventEmitter();
    this.didFocus = new EventEmitter();
    this.didKeyDownEnter = new EventEmitter();
  }

  @HostBinding('class.lv-numeric-text-box')
  get baseClass(): boolean {
    return true;
  }

  @HostBinding('class.lv-invalid')
  get isLvInvalid(): boolean {
    return !this.disabled && !this.readonly && this.invalid;
  }

  @HostBinding('class.lv-input-field')
  get isLvInputField(): boolean {
    return true;
  }

  @HostBinding('class.lv-input-field--right')
  get isLvRightInputField(): boolean {
    return true;
  }

  @HostBinding('class.lv-input-field--full')
  get isLvAutoWidthInputField(): boolean {
    return this.autoWidth;
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    if (!this.readonly && !this.disabled && this._focused) {
      let multiplicator = 1;
      // tslint:disable-next-line
      switch (event.which || event.keyCode) {
        case 66: { // B Key
          multiplicator = 1000 * 1000 * 1000;
          event.preventDefault();
          break;
        }
        case 75: { // K Key
          multiplicator = 1000;
          event.preventDefault();
          break;
        }
        case 77: { // M Key
          multiplicator = 1000 * 1000;
          event.preventDefault();
          break;
        }
      }

      if (multiplicator !== 1) {
        this.kendoInput.blur();
        this.innerValue = this.innerValue * multiplicator;
      }
    }
  }

  @HostListener('keydown.enter')
  onKeyDownEnter() {
    if (!this.disabled && !this.readonly) {
      this._suppressBlur = true;

      this.onControlTouched();
      this._focused = false;
      this.kendoInput.blur();

      this.didKeyDownEnter.next();
    }
  }

  ngOnInit() {
    this._control = this._injector.get<NgControl>(NgControl, null);

    if (this._control) {
      this._control.valueAccessor = this;
    }
  }

  // ControlValueAccessor START
  writeValue(value: number): void {
    this._innerValue = value;

    if (!(this._changeDetectorRef as ViewRef).destroyed) {
      this._changeDetectorRef.detectChanges();
    }
  }

  registerOnChange(fn: any): void {
    this.onValueChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onControlTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (!(this._changeDetectorRef as ViewRef).destroyed) {
    this._changeDetectorRef.detectChanges();
    }
  }
  // ControlValueAccessor END

  // Validator START
  validate(c: AbstractControl): ValidationErrors | null {
    const isMinValid = this.minValidator();
    const isMaxValid = this.maxValidator();

    if (isMinValid !== null) {
      return isMinValid;
    }

    if (isMaxValid !== null) {
      return isMaxValid;
    }

    return null;
  }
  // Validator END

  onBlur() {
    if (!this.disabled && !this.readonly && !this._suppressBlur) {
      this.onControlTouched();
      this._focused = false;

      this.didBlur.next();
    }
    else {
      this._suppressBlur = false;
    }
  }

  onFocus() {
    if (!this.disabled && !this.readonly) {
      this._focused = true;
      this.didFocus.next();
    }
  }

  get isNegative() {
    return (this.innerValue && this.showNegativeLabel) ? this.innerValue < 0 : false;
  }

  private doValidateControl(): boolean {
    if (this.touched && this.min && this.min > this.innerValue) {
      return false;
    }

    if (this.touched && this.max && this.max < this.innerValue) {
      return false;
    }

    return true;
  }

  private touchControl() {
    if (!this.touched && !this.disabled && !this.readonly) {
      this.onControlTouched();
    }
  }

  private minValidator(): any {
    const minErr: any = {
        minError: {
            minValue: this.min,
            value: this._control.value
        }
    };

    return (this._control.value !== null && this.min && this._control.value < this.min) ? minErr : null;
  }

  private maxValidator(): any {
    const maxErr: any = {
        maxError: {
            maxValue: this.max,
            value: this._control.value
        }
    };

    return (this._control.value !== null && (this.max || this.max === 0) && this._control.value > this.max) ? maxErr : null;
  }
}
