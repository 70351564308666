/**
 * Scenario inputs shift.
 */
export enum ScenarioInputsShift {
  ConvertiblePrice = 'ConvertiblePrice',
  UnderlyingPrice = 'UnderlyingPrice',
  UnderlyingPriceCB = 'UnderlyingPriceCB',
  CreditSpread = 'CreditSpread',
  Volatility = 'Volatility',
  Borrow = 'Borrow',
  InterestRate = 'InterestRate',
  DividendYield = 'DividendYield',
  Coupon = 'Coupon',
  Premium = 'Premium',
  ValuationDate = 'ValuationDate'
}

/**
 * Scenario inputs shift description.
 */
export enum ScenarioInputsShiftDescription {
  ConvertiblePrice = 'Convertible Price',
  UnderlyingPrice = 'Underlying Price ({0})',
  UnderlyingPriceCB = 'Underlying Price ({0})',
  CreditSpread = 'Flat Credit Spread',
  Volatility = 'Flat Volatility',
  Borrow = 'Flat Borrow',
  InterestRate = 'Flat Interest Rate',
  DividendYield = 'Flat Dividend Yield',
  Coupon = 'Coupon',
  Premium = 'Premium',
  ValuationDate = 'Valuation Date'
}

export const formatUnderlyingPrice = (currencyCode?: string): string => {
  return ScenarioInputsShiftDescription.UnderlyingPrice.replace('{0}', currencyCode || 'Inst CCY');
};

export const formatUnderlyingCBPrice = (currencyCode?: string): string => {
  return ScenarioInputsShiftDescription.UnderlyingPriceCB.replace('{0}', currencyCode || 'Und CCY');
};
