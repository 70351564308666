import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, ChangeDetectorRef,
  ViewChild } from '@angular/core';
import { v4 } from 'uuid';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { LvErrorService } from '@lv-core-ui/services';
import { LvUtil } from '@lv-core-ui/util';
import { INewIssueSidePanelSettings, ISaveNewIssueSettingsRequest } from '@lv-analytics/models';
import { CompanyAndUserSettingsService } from '@lv-analytics/services';
import { LvDataMaster } from '@lv-core-ui/models';

/**
 * New issue settings dialog component.
 */
@Component({
  selector: 'lv-new-issue-settings-dialog',
  templateUrl: './lv-new-issue-settings-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvNewIssueSettingsDialogComponent implements OnInit {
  @ViewChild('tabStrip') tabStrip: TabStripComponent;

  systemDefaultsUseAssumedRadioButtonId: string;
  systemDefaultsUseBestRadioButtonId: string;
  systemDefaultsUseMidRadioButtonId: string;
  systemDefaultsUseWorstRadioButtonId: string;

  environmentOverridSystemDefaultsCheckboxId: string;
  environmentUseAssumedRadioButtonId: string;
  environmentUseBestRadioButtonId: string;
  environmentUseMidRadioButtonId: string;
  environmentUseWorstRadioButtonId: string;

  newIsssueSettings: INewIssueSidePanelSettings;

  isLoading: boolean;

  constructor(
    private dialogRef: DialogRef,
    private companySettingsService: CompanyAndUserSettingsService,
    private changeDetectorRef: ChangeDetectorRef,
    private errorService: LvErrorService
  ) {
    this.systemDefaultsUseAssumedRadioButtonId = v4();
    this.systemDefaultsUseBestRadioButtonId = v4();
    this.systemDefaultsUseMidRadioButtonId = v4();
    this.systemDefaultsUseWorstRadioButtonId = v4();

    this.environmentOverridSystemDefaultsCheckboxId = v4();
    this.environmentUseAssumedRadioButtonId = v4();
    this.environmentUseBestRadioButtonId = v4();
    this.environmentUseMidRadioButtonId = v4();
    this.environmentUseWorstRadioButtonId = v4();

    this.isLoading = false;

    this.newIsssueSettings = {
      environments: [],
      systemDefaults: {}
    } as INewIssueSidePanelSettings;
  }

  /**
   * Handles any additional initialization tasks.
   */
  async ngOnInit() {
    try {
      this.isLoading = true;
      this.newIsssueSettings = await this.companySettingsService.getNewIssueSidePanelSettings();
    }
    catch (error) {
      this.errorService.handleError(error);
    }
    finally {
      this.isLoading = false;
      this.changeDetectorRef.markForCheck();
    }
  }

  /**
   * Closes dialog.
   */
  onCancel() {
    this.dialogRef.close();
  }

  /**
   * Saves new issue settings.
   */
  onSave() {
    try {
      this.isLoading = true;
      const request = LvUtil.jsonParse(LvUtil.jsonStringify(this.newIsssueSettings)) as ISaveNewIssueSettingsRequest;

      this.companySettingsService.saveNewIssueSettings(request);

      this.errorService.toastrService.success(LvDataMaster.getInfo('dM-1814'));
      this.dialogRef.close();
    }
    catch (error) {
      this.errorService.handleError(error);
    }
    finally {
      this.isLoading = false;
      this.changeDetectorRef.markForCheck();
    }
  }

  /**
   * Gets radio button name for environment.
   * @param environmentName Environment name.
   * @returns Radio button name.
   */
  getRadioButtonNameForEnvironment(environmentName: string): string {
    return `${environmentName}-newIssueAssumptions`;
  }

  /**
   * Gets radio button ID for environment assumed.
   * @param environmentName Environment name.
   * @returns Radio button ID.
   */
  getRadioButtonIdForEnvironmentAssumed(environmentName: string): string {
    return `${environmentName}-${this.environmentUseAssumedRadioButtonId}`;
  }

  /**
   * Gets radio button ID for environment best.
   * @param environmentName Environment name.
   * @returns Radio button ID.
   */
  getRadioButtonIdForEnvironmentBest(environmentName: string): string {
    return `${environmentName}-${this.environmentUseBestRadioButtonId}`;
  }

  /**
   * Gets radio button ID for environment mid.
   * @param environmentName Environment name.
   * @returns Radio button ID.
   */
  getRadioButtonIdForEnvironmentMid(environmentName: string): string {
    return `${environmentName}-${this.environmentUseMidRadioButtonId}`;
  }

  /**
   * Gets radio button ID for environment worst.
   * @param environmentName Environment name.
   * @returns Radio button ID.
   */
  getRadioButtonIdForEnvironmentWorst(environmentName: string): string {
    return `${environmentName}-${this.environmentUseWorstRadioButtonId}`;
  }

  /**
   * Gets checkbox ID for environment.
   * @param environmentName Environment name.
   * @returns Checkbox ID.
   */
  getCheckBoxIdForEnvironment(environmentName: string): string {
    return `${environmentName}-${this.environmentOverridSystemDefaultsCheckboxId}`;
  }
}
