import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { DialogRef } from '@progress/kendo-angular-dialog';

import { IEnvironmentSettingsItem } from '../lv-environment-settings.component';

/**
 * Environment settings dialog component.
 */
@Component({
  selector: 'lv-environment-settings-dialog',
  templateUrl: './lv-environment-settings-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEnvironmentSettingsDialogComponent implements OnInit {

  @Input() items: IEnvironmentSettingsItem[];

  @Output() didSave: EventEmitter<IEnvironmentSettingsItem[]>;

  constructor(
    private dialogRef: DialogRef
  ) {
    this.items = [];

    this.didSave = new EventEmitter<IEnvironmentSettingsItem[]>();
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Closes dialog.
   */
  onClose() {
    this.dialogRef.close();
  }

  /**
   * Saves environment settings.
   */
  onSave() {
    this.dialogRef.close();
    this.didSave.next(this.items);
  }
}
