import { signal } from "@angular/core";

/**
 * Stock reference data signal store.
 */
export class StockReferenceSignalStore {

  stockRef = signal(null);
  stockRefEquity = signal(null);
  fx = signal(null);
  isInitialized: boolean;

  constructor() {
    this.isInitialized = false;
  }

  /**
   * Set store default values.
   * @param stockRefValue Default value for stock reference.
   * @param fx Default value for fx.
   * @param forceInitialize Should we force initialization.
   */
  initializeState(stockRefValue: number, fx: number, forceInitialize: boolean = false): void {
    if (!this.isInitialized || forceInitialize) {
      this.stockRefEquity.set(stockRefValue);
      this.fx.set(fx);
      if (fx === 0) {
        this.stockRef.set(stockRefValue);
      }
      else if (stockRefValue){
        this.stockRef.set(this.stockRefEquity() / this.fx());
      }
      this.isInitialized = true;
    }
  }

  /**
   * Set stock ref value.
   * @param value New stock ref value.
   */
  setStockRef(value: number): void {
    this.stockRef.set(value);
    if (!!this.stockRef() && !!this.fx()) {
      this.stockRefEquity.set(this.stockRef() * this.fx());
    }
  }

  /**
   * Set stock equity value.
   * @param value New Stock equity value.
   */
  setStockRefEquity(value: number): void {
    this.stockRefEquity.set(value);
    if (!!this.stockRefEquity() && !!this.fx()) {
      this.stockRef.set(this.stockRefEquity() / this.fx());
    }
  }

  /**
   * Set fx value.
   * @param value New fx value.
   */
  setFx(value: number): void {
    this.fx.set(value);
    if (!!this.stockRefEquity() && !!this.fx()) {
      this.stockRef.set(this.stockRefEquity() / this.fx());
    }
  }
}