import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter,
  ChangeDetectorRef, Input, Renderer2 } from '@angular/core';
import { mask, modal } from '../lv-modal.animations';
import { LvModalComponent } from '../lv-modal';

@Component({
  selector: 'lv-signalr-modal',
  templateUrl: './lv-signalr-modal.component.html',
  animations: [
    mask,
    modal
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvSignalrModalComponent extends LvModalComponent implements OnInit {

  @Input()
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.changeDetectorRef.detectChanges();
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  @Output() doReconnect: EventEmitter<void>;
  @Output() didContinue: EventEmitter<void>;

  public errorMessage: string;

  private _isLoading: boolean;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    renderer: Renderer2
  ) {
    super(changeDetectorRef, renderer);

    this.errorMessage = null;

    this.doReconnect = new EventEmitter<void>();
    this.didContinue = new EventEmitter<void>();
  }

  ngOnInit() {
    this.isLoading = false;
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
    this.changeDetectorRef.detectChanges();
  }

  onReconnect() {
    this.doReconnect.next();
  }

  onContinue() {
    this.close();
    this.didContinue.next();
  }

}
