import { GridDataResult, PageChangeEvent, SelectableSettings, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process, State, CompositeFilterDescriptor } from '@progress/kendo-data-query';

import { LvUtil } from './util';

export class LvVirtualGridView<Id, M> {

  get recordsView(): GridDataResult {
    return this._recordsView;
  }

  get recordIdentifier(): string {
    return this._recordIdentifier;
  }

  get pageSize(): number {
    return this._pageSize;
  }

  get skip(): number {
    return this._skip;
  }

  get selectedIdentifiers(): Id[] {
    return this._selectedIdentifiers;
  }

  get selectableSettings(): SelectableSettings {
    return this._selectableSettings;
  }

  get filterable(): boolean {
    return this._filterable;
  }

  get state(): State {
    return this._state;
  }

  private _recordIdentifier: string;
  private _recordsView: GridDataResult;

  private records: M[];
  private filterFn: (record: M) => boolean;

  private _pageSize: number;
  private _skip: number;

  private  _selectedIdentifiers: Id[];

  private _selectableSettings: SelectableSettings;

  private _filterable: boolean;
  private _state: State;

  constructor(recordIdentifier: string, pageSize?: number, skip?: number) {
    this._recordIdentifier = recordIdentifier;

    this.records = [];

    this._recordsView = {
      data: [],
      total: 0
    };

    this._pageSize = pageSize || 100;
    this._skip = skip || 0;

    this._selectableSettings = {
      enabled: true,
      mode: 'single'
    };

    this._filterable = false;

    this._state = {
      skip: this._skip,
      take: this._pageSize,
      filter: {
        logic: 'and',
        filters: []
      }
    };

    this._selectedIdentifiers = [];

    this.filterFn = (record: M) => true;
  }

  initView(records: M[]) {
    this._skip = 0;

    this.records = [...records];

    if (this._filterable) {
      this.loadFilterableView();
    }
    else {
      this.loadView();
    }
  }

  setSelectableSettings(settings: SelectableSettings) {
    this._selectableSettings = { ...settings };
  }

  setSelectedIdentifiers(identifiers?: Id[]) {
    this._selectedIdentifiers = identifiers ? [...identifiers] : [];

  }

  /**
   * Update selected identifiers on virtual grid data.
   * @param selectedItems Selected identifiers.
   * @param deselectedItems Deselected identifiers.
   */
  changeSelectionData(selectedItems: Id[], deselectedItems: Id[]) {
    if (!!selectedItems) {
      this._selectedIdentifiers.push(...selectedItems);
    }

    if (!!deselectedItems) {
      this._selectedIdentifiers = this._selectedIdentifiers.filter(item => !deselectedItems.includes(item));
    }
  }

  getSelectedRecords(): M[] {
    const selectedIdentifiersDict = LvUtil.toDictionary(this.selectedIdentifiers, null, true);
    return this._recordsView.data.filter(a => selectedIdentifiersDict[a.id]);
  }

  reloadView() {
    if (this._filterable) {
      this._state.skip = 0;
      this.loadFilterableView();
    }
    else {
      this._skip = 0;
      this.loadView();
    }
  }

  // when using external filter
  setFilterFn(fn: (record: M) => boolean) {
    this.filterFn = fn;
  }

  onPageChange(e: PageChangeEvent) {
    if (!this._filterable) {
      this._skip = e.skip;

      this.loadView();
    }
  }

  // when using internal kendo filter
  setFilterable(filterable: boolean) {
    this._filterable = filterable;
  }

  setFilter(filter: CompositeFilterDescriptor) {
    this._state.filter = { ...filter };
  }

  onDataStateChange(state: DataStateChangeEvent): void {
    this._state = { ...state };
    this.loadFilterableView();
  }

  private loadFilterableView() {
    this._recordsView = process(this.records, this._state);
  }

  private loadView() {
    const filtered = this.records.filter(this.filterFn);
    const data = filtered.slice(this._skip, this._skip + this._pageSize);

    this._recordsView = {
      data: data,
      total: filtered.length
    };
  }
}
