import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LvAnalyticsError } from '../../models/errors';
import { IOutput } from '../../models/outputs/output';
import { SaveUserDefaultOutputsRequest } from '../../models/outputs/save-user-default-outputs-request';
import { HttpClientBase } from '@lv-core-ui/api';

/**
 * Outputs service.
 */
@Injectable()
export class OutputsService extends HttpClientBase {

  constructor(
    http: HttpClient
  ) {
    const resourceUrl = '/analytics/outputs';
    super(http, resourceUrl);
  }

  /**
   * Save user outputs state
   * @param {IOutput[]} userDefaultOutputs default outputs
   * @param {boolean} isOpenedFromExcel is application opened from excel
   */
  async saveUserDefaultOutputs(userDefaultOutputs: IOutput[], isOpenedFromExcel: boolean) {
    try {

      const saveUserDefaultOutputsRequest = {
        outputs: userDefaultOutputs,
        isOpenedFromExcel: isOpenedFromExcel
      } as SaveUserDefaultOutputsRequest;

      await this.postAsync<any>(saveUserDefaultOutputsRequest, '/saveUserDefaultOutputs');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }
}
