/**
 * Outputs.
 */
export enum Outputs {
  theoreticalValue = 'Theoretical Value',
  cheapness = 'Cheapness',
  impliedVolatility = 'Implied Volatility',
  impliedCredit = 'Implied Credit',
  percentOfTheory = '% of Theory',
  cheapnessPercent = 'Cheapness (%)',
  expectedLife = 'Expected Life',
  cheapnessPerYear = 'Cheapness per Year',
  cheapnessPerExpLife = 'Cheapness per Exp Life',
  forwardUnderlyingLevel = 'Fwd Underlying Level',
  parity = 'Parity',
  parityPremium = 'Parity Premium',
  parityPremiumPercent = 'Parity Premium (%)',
  effParityPremiumPercent = 'Eff. Parity Premium (%)',
  parityPremiumClean = 'Parity Premium Clean',
  parityPremiumCleanPercent = 'Parity Prem. Clean (%)',
  netParity = 'Net Parity' ,
  netParityPremium = 'Net Parity Premium',
  bondValue = 'Bond Value',
  bondPremium = 'Bond Premium',
  bondPremiumPercent = 'Bond Premium (%)',
  currentNominalValue = 'Current Nominal Value',
  accretedValue = 'Accreted Value',
  redemptionValue = 'Redemption Value',
  accretingYield = 'Accreting Yield',
  currentYield = 'Current Yield',
  yieldToMaturity = 'Yield to Maturity',
  yieldToCall = 'Yield to Call',
  yieldToPut = 'Yield to Put',
  yieldToWorst = 'Yield to Worst',
  yieldToOptimal = 'Yield to Optimal',
  divYieldEst = 'Dividend Yield (Est.)',
  breakeven = 'Breakeven (in yrs)',
  incomePickup = 'Income Pickup',
  lastFixDate = 'Last Fixing Date',
  nextFixDate = 'Next Fixing Date',
  modDuration = 'Modified Duration',
  effDuration = 'Effective Duration',
  modConvexity = 'Modified Convexity',
  effConvexity = 'Effective Convexity',
  accruedInterest = 'Accrued Interest',
  riskFreeRate = 'Risk Free Rate',
  currentEffectiveCallTrigger = 'Current Eff. Call Trigger',
  riskVolatility = 'Risk Volatility',
  yearsToPut = 'Years to Put',
  yearsToCall = 'Years to Call',
  zSpreadMaturity = 'Z Spread - Maturity',
  zSpreadOptimal = 'Z Spread - Optimal',
  zSpreadWorst = 'Z Spread - Worst',
  zSpreadCall = 'Z Spread - Next Hard Call',
  zSpreadNextPut = 'Z Spread - Next Put',
  aSWSpreadMaturity = 'ASW Spread - Maturity',
  aSWSpreadNextPut = 'ASW Spread - Next Put',
  delta = 'Delta',
  deltaShares = 'Delta Shares',
  fXDelta = 'FX Delta',
  eqSensitivity = 'Equity Sensitivity',
  gamma = 'Gamma',
  gammaShares = 'Gamma Shares',
  vega = 'Vega',
  volga = 'Volga',
  vanna = 'Vanna',
  theta = 'Theta',
  rho = 'Rho',
  cSSensIss = 'CS Sensitivity',
  rhoUnd = 'Rho (und)',
  cSSens = 'CS Sensitivity (und)',
  creditVega = 'Credit Vega',
  stockBorrowSens = 'Borrow Sensitivity',
  dividendSens = 'Dividend Sensitivity',
  recoveryRateSens = 'Recovery Rate Sens.',
  lowerCall = 'Lower Call Value',
  higherCall = 'Higher Call Value',
  fwdStock = 'Forward Stock',
  carryValue = 'Carry Value',
  upImpVol = 'Upside Implied Vol',
  dnImpVol = 'Downside Implied Vol',
  totalSkew = 'Total Skew',
  skewPerThen = 'Skew per 10%',
  deltaImplVol = 'Delta (IV)',
  deltaSharesImplVol = 'Delta Shares (IV)',
  deltaFXImplVol = 'FX Delta (IV)',
  eqSensitivityImplVol = 'Equity Sensitivity (IV)',
  gammaImplVol = 'Gamma (IV)',
  gammaSharesImplVol = 'Gamma Shares (IV)',
  vegaImplVol = 'Vega (IV)',
  volgaImplVol = 'Volga (IV)',
  vannaImplVol = 'Vanna (IV)',
  thetaImplVol = 'Theta (IV)',
  rhoImplVol = 'Rho (IV)',
  cSSensIssImplVol = 'CS Sensitivity (IV)',
  creditVegaImplVol = 'Credit Vega (IV)',
  rhoUndImplVol = 'Rho (und) (IV)',
  cSSensImplVol = 'CS Sensitivity (und) (IV)',
  stockBorrowSensImplVol = 'Borrow Sensitivity (IV)',
  dividendSensImplVol = 'Dividend Sensitivity (IV)',
  recoveryRateSensImplVol = 'Recovery Rate Sens. (IV)',
  deltaImplSpread = 'Delta (ICS)',
  deltaSharesImplSpread = 'Delta Shares (ICS)',
  deltaFXImplSpread = 'FX Delta (ICS)',
  eqSensitivityImplSpread = 'Equity Sensitivity (ICS)',
  gammaImplSpread = 'Gamma (ICS)',
  gammaSharesImplSpread = 'Gamma Shares (ICS)',
  vegaImplSpread = 'Vega (ICS)',
  volgaImplSpread = 'Volga (ICS)',
  vannaImplSpread = 'Vanna (ICS)',
  thetaImplSpread = 'Theta (ICS)',
  rhoImplSpread = 'Rho (ICS)',
  cSSensIssImplSpread = 'CS Sensitivity (ICS)',
  creditVegaImplSpread = 'Credit Vega (ICS)',
  rhoUndImplSpread = 'Rho (und) (ICS)',
  cSSensImplSpread = 'CS Sensitivity (und) (ICS)',
  stockBorrowSensImplSpread = 'Borrow Sensitivity (ICS)',
  dividendSensImplSpread = 'Dividend Sensitivity (ICS)',
  recoveryRateSensImplSpread = 'Recovery Rate Sens. (ICS)',
  accruedDays = 'Accrued Days',
  remainingMW = 'Remaining MW Amount',
  probBreachCallTrigger = 'Probability of Call Trigger',
  conversionRatioProjected = 'Projected CR',
  conversionRebateProjected = 'Projected Rebate',
  ratchetMatrixMultiple = 'Ratchet Matrix Multiple',
  rhoBucket = 'Rho Buckets',
  presentValuePartialPuts = 'Present Value Partial Puts',
  accretedPutValue = 'Accreted Put Value',
  riskyDiscountFactor = 'Risky Discount Factor',
  riskFreeDiscountFactor = 'Risk-Free Discount Factor',
  forwardFXRate = 'Forward FX Rate',
  cSSensBucket = 'CS Sensitivity Buckets',
}
