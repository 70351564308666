import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { IBorrow } from '@lv-analytics/models/market-data/borrow/borrow';

/**
 * Borrow flat component.
 */
@Component({
  selector: 'lv-borrow-flat',
  templateUrl: './lv-borrow-flat.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvBorrowFlatComponent implements OnInit {

  @Input() borrowSettings: IBorrow;
  @Output() borrowValueChange: EventEmitter<void>;

  numberOfDecimals = '0';
  numberFormat = 'n0';

  constructor() {
    this.borrowValueChange = new EventEmitter();
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Occurs on borrow change.
   */
  onBorrowChange() {
    this.borrowValueChange.emit();
  }

  /**
   * Gets borrow tooltip ID.
   * @param element HTML element.
   * @param sectionId Section ID.
   * @returns Borrow tooltip ID.
   */
  getBorrowTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('borrow-tooltip-id') === sectionId;
  }
}
