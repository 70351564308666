<lv-flex-box class="lv-equity-linked-credit">
  <div class="lv-label lv-label-field lv-label--title">Equity Linked Credit</div>
  <form #form="ngForm">
    <lv-flex-box direction="row" *ngIf="false">
      <div class="lv-label lv-label-field">
        Use Equity-Linked Credit
      </div>
      <div class="lv-input-field">
        <input name="equityToCreditModel"
          class="k-checkbox"
          type="checkbox"
          [attr.id]="equityToCreditModelId"
          [(ngModel)]="equityLinkedCredit.equityToCreditModel" />
        <label class="k-checkbox-label"
          lvId="equityToCreditModel"
          [attr.for]="equityToCreditModelId">
        </label>
      </div>
    </lv-flex-box>

    <lv-flex-box direction="row" data-cy="DM-279">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="calibrationTypeTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'calibrationTypeTooltip'">
            Calibration Type
          </span>
          <lv-xl-label *ngIf="!hasCreditEquityLinkedRangeOverridedFromExcel" [field]="'CREDIT_CALIB_TYPE'"></lv-xl-label>
          <lv-xl-label *ngIf="hasCreditEquityLinkedRangeOverridedFromExcel" [field]="'CREDIT_EQLNKD_RANGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist name="equityLinkedCreditCalibrationType"
          [data]="equityToCreditCalibrationTypeSource.items"
          valueField="id"
          textField="text"
          [(ngModel)]="equityLinkedCredit.equityToCreditCalibrationType"
          (ngModelChange)="onEquityLinkedCreditChange()"
          [valuePrimitive]="true"
          lvId="lv-equity-linked-credit-calibration-type"
          [popupSettings]="{
            width: 140
          }">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>

    <lv-flex-box direction="row" data-cy="DM-251">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditParameterTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'parameterTooltip'">
            Parameter
          </span>
          <lv-xl-label *ngIf="!hasCreditEquityLinkedRangeOverridedFromExcel" [field]="'CREDIT_PARM'"></lv-xl-label>
          <lv-xl-label *ngIf="hasCreditEquityLinkedRangeOverridedFromExcel" [field]="'CREDIT_EQLNKD_RANGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field ">
        <lv-numeric-text-box
          name="equityToCreditParameter"
          [format]="numberFormatBps"
          [decimals]="numberOfDecimalsBps"
          [(ngModel)]="equityLinkedCredit.equityToCreditParameter"
          (didBlur)="onEquityLinkedCreditChange()"
          (didKeyDownEnter)="onEquityLinkedCreditChange()">
        </lv-numeric-text-box>
      </div>
    </lv-flex-box>

    <lv-flex-box direction="row" data-cy="DM-252">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditReferenceSpotTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'referenceSpotTooltip'">
            Reference Spot
          </span>
          <lv-xl-label *ngIf="!hasCreditEquityLinkedRangeOverridedFromExcel" [field]="'CREDIT_REF_SPOT'"></lv-xl-label>
          <lv-xl-label *ngIf="hasCreditEquityLinkedRangeOverridedFromExcel" [field]="'CREDIT_EQLNKD_RANGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field ">
        <lv-numeric-text-box
          name="equityToCreditRefStock"
          [format]="numberFormat"
          [decimals]="numberOfDecimalsPercetenge"
          [(ngModel)]="equityLinkedCredit.equityToCreditRefStock"
          (didBlur)="onEquityLinkedCreditChange()"
          (didKeyDownEnter)="onEquityLinkedCreditChange()">
        </lv-numeric-text-box>
      </div>
    </lv-flex-box>

    <lv-flex-box direction="row" data-cy="DM-253">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="creditFloorBondTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.credit-tooltip-id]="'floorBondTooltip'">
            Floor (Bond)
          </span>
          <lv-xl-label *ngIf="!hasCreditEquityLinkedRangeOverridedFromExcel" [field]="'CREDIT_BOND_FLOOR'"></lv-xl-label>
          <lv-xl-label *ngIf="hasCreditEquityLinkedRangeOverridedFromExcel" [field]="'CREDIT_EQLNKD_RANGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="equityToCreditFloor"
          [format]="numberFormat"
          [decimals]="numberOfDecimalsPercetenge"
          [(ngModel)]="equityLinkedCredit.equityToCreditFloor"
          (didBlur)="onEquityLinkedCreditChange()"
          (didKeyDownEnter)="onEquityLinkedCreditChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>
  </form>
</lv-flex-box>

<ng-template #calibrationTypeTooltipTemplate>
  <lv-tooltip dm="dM-279"></lv-tooltip>
</ng-template>
<ng-template #creditParameterTooltipTemplate>
  <lv-tooltip dm="dM-251"></lv-tooltip>
</ng-template>
<ng-template #creditReferenceSpotTooltipTemplate>
  <lv-tooltip dm="dM-252"></lv-tooltip>
</ng-template>
<ng-template #creditFloorBondTooltipTemplate>
  <lv-tooltip dm="dM-253"></lv-tooltip>
</ng-template>
