<lv-flex-box class="lv-borrow">
  <lv-environment-settings
    [section]="borrowSection"
    lvId="marketDataBorrowEnvironmentSettings"
    (didChangeEnvironment)="onChangedEnvironment($event)">
  </lv-environment-settings>

  <lv-flex-box class="lv-borrow-content lv-market-data-section-content">
    <lv-flex-box data-cy="DM-307" direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="borrowTooltipTemplate"
        filter="span">
          <span [attr.borrow-tooltip-id]="'borrowSourceTooltip'">
            Borrow Source
          </span>
      </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          name="cbBorrowSource"
          valueField="id"
          textField="text"
          [data]="borrowSource.items"
          [valuePrimitive]="true"
          [disabled]="isBorrowSectionDisabled"
          [(ngModel)]="borrowSettings.borrowSource"
          (ngModelChange)="onBorrowSourceChange()">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>

    <ng-container *ngIf="!isBorrowSectionDisabled">
      <lv-borrow-flat *ngIf="isFlatSelected"
        [borrowSettings]="borrowSettings"
        (borrowValueChange)="onBorrowSectionChanged()">
      </lv-borrow-flat>
      <lv-borrow-rebate *ngIf="isRebateSelected"
        [borrowSettings]="borrowSettings"
        (rebateValueChange)="onBorrowSectionChanged()">
      </lv-borrow-rebate>
      <lv-borrow-term-structure class="lv-borrow-term-structure" *ngIf="isTermStructureSelected"
        [scheduleItems]="borrowSettings.termStructureItems"
        (borrowScheduleValueChange)="onBorrowSectionChanged()">
      </lv-borrow-term-structure>
    </ng-container>
  </lv-flex-box>
</lv-flex-box>

<ng-template #borrowTooltipTemplate>
  <lv-tooltip dm="dM-307"></lv-tooltip>
</ng-template>
