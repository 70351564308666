
export enum AccrualMethodDescription {
	Regular = 'Regular',
	Swedish = 'Swedish',
	Norwegian = 'Norwegian',
	Japanese = 'Japanese',
	Malaysian = 'Malaysian',
	Canadian = 'Canadian',
	Singapore = 'Singapore',
	USPreferred = 'US Preferred',
	SouthAfrican = 'South African',
	NorwegianSeparateAccrualPeriods = 'Norwegian Separate Accrual Periods',
	HongKong = 'Hong Kong'
}