
export enum DayCount {
	
	DayCount_ACT_ACT_ICMA = 'DayCount_ACT_ACT_ICMA',
	DayCount_ACT_365F = 'DayCount_ACT_365F',
	DayCount_ACT_360 = 'DayCount_ACT_360',
	DayCount_30_360 = 'DayCount_30_360',
	DayCount_30E_360 = 'DayCount_30E_360',
	DayCount_NL_365 = 'DayCount_NL_365',
	DayCount_30E_360_ISDA = 'DayCount_30E_360_ISDA',
	DayCount_ACT_365 = 'DayCount_ACT_365',
}