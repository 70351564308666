import * as moment from 'moment';
import { ConvertibleBondTermsDocument, ConvertibleBondNewIssueDocument, QuickTerms, DividendProtectionType, DividendThreshold,
         DividendProtectionTresholdType, DividendProtectionTresholdValueType, SetupStatus, SetupStatusQuickTerms, CallMakeWholeType,
         CallScheduleItem, ContingentConversionScheduleItem, CouponType, ExchangeableType, Frequency, PutScheduleItem, ResetScheduleItem,
         StockPriceReference, StockReferenceType, ConvertibleBondSubType, CurrencyType, CouponTypeDescription } from '@lv-convertible-bond/models';
import { PepsConversionBeforeMaturityType } from '@lv-convertible-bond/models/convertible-bond-terms/Enums (2)';
import { PepsConversion } from '@lv-convertible-bond/models/convertible-bond-terms/PEPSConversion';
import { PercsConversion } from '@lv-convertible-bond/models/convertible-bond-terms/PERCSConversion';

export class QuickTermsMapHelper {

  constructor() {}
  // tslint:disable-next-line:max-line-length
  getQuickTermsEntryFromConvertibleBond(terms: ConvertibleBondTermsDocument | ConvertibleBondNewIssueDocument, quickTerms: QuickTerms): QuickTerms {
    const firstSettlementDate = terms.issueAndRedemption.firstSettlementDate;
    quickTerms.issueName = terms.issueAndRedemption.name;
    quickTerms.convertibleType = terms.issueAndRedemption.subType;
    quickTerms.currency = terms.issueAndRedemption.currencyCode;
    quickTerms.underlyingCurrency = terms.issueAndRedemption.underlyingEquity.currencyCode;
    quickTerms.redemption = terms.issueAndRedemption.redemptionValue;
    quickTerms.maturityDateYears = this.calculateYearsFromDate(terms.issueAndRedemption.maturityDate, firstSettlementDate);
    quickTerms.setupStatus = this.getSetupStatusQuickTerms(terms.issueAndRedemption.setupStatus);
    quickTerms.perpetual = terms.issueAndRedemption.isPerpetual;
    quickTerms.callMakeWholeType = CallMakeWholeType.None;
    quickTerms.dividendProtection = DividendProtectionType.ConversionRatioAdjustment;
    quickTerms.dividendTresholdType = DividendThreshold.Absolute;
    quickTerms.tresholdFrequency = Frequency.Annual;

    if (terms.issueAndRedemption.isPuttable && terms.put && terms.put.scheduleItems && terms.put.scheduleItems.length > 0) {
      quickTerms.puts = this.setQuickTermsPuts(terms.put.scheduleItems, firstSettlementDate);
      quickTerms.putPrice = this.setQuickTermsPutsPrices(terms.put.scheduleItems);
    } else {
      quickTerms.puts = null;
      quickTerms.putPrice = null;
    }

    if (terms.issueAndRedemption.isCallable && terms.call) {
      if (terms.call.scheduleItems && terms.call.scheduleItems.length > 0) {
        quickTerms.softCall = this.setQuickTermsSoftCall(terms.call.scheduleItems, firstSettlementDate);
        quickTerms.softCallTriggers = this.setQuickTermsCallTrigger(terms.call.scheduleItems);
        quickTerms.callProtection = this.setQuickTermsCallProtection(terms.call.scheduleItems, firstSettlementDate);
      } else {
        quickTerms.softCall = null;
        quickTerms.softCallTriggers = null;
        quickTerms.callProtection = null;
      }
      if (terms.call.callMakeWhole && terms.call.recieveMakeWhole) {
        quickTerms.callMakeWholeType = terms.call.callMakeWhole.type;
        quickTerms.callMakeWholeSpread = terms.call.callMakeWhole.discountSpread;
      } else {
        quickTerms.callMakeWholeType = CallMakeWholeType.None;
      }
    } else {
      quickTerms.softCall = null;
      quickTerms.softCallTriggers = null;
      quickTerms.callProtection = null;
    }

    if (terms.conversion.isVariableConversion && terms.conversion.variableConversion) {
      quickTerms.hyperIncrementalFactor = terms.conversion.variableConversion.incrementalShareFactor;
      quickTerms.hyperStrike = terms.conversion.variableConversion.strike;
    } else {
      quickTerms.hyperIncrementalFactor = null;
      quickTerms.hyperStrike = null;
    }

    if (terms.coupon.type === CouponType.Fixed) {
      quickTerms.coupon = terms.coupon.fixed ? terms.coupon.fixed.rate : null;
    } else {
      quickTerms.coupon = CouponTypeDescription[terms.coupon.type];
    }

    if (terms.conversion.schedule && terms.conversion.schedule.length > 0) {
      quickTerms.conversionPrice = terms.conversion.schedule[0].conversionPrice;
    }

    if (terms.issueAndRedemption.subType === ConvertibleBondSubType.PEPS) {
      if (!terms.conversion.pepsConversion) {
        terms.conversion.pepsConversion = new PepsConversion();
        terms.conversion.pepsConversion.conversionRebateCurrency = CurrencyType.Convertible;
        terms.conversion.pepsConversion.conversionBeforeMaturityType = PepsConversionBeforeMaturityType.MinimumRatio;
      }
      quickTerms.lowerStrike = terms.conversion.pepsConversion.lowerStrike;
      quickTerms.higherStrike = terms.conversion.pepsConversion.higherStrike;
    }

    if (terms.issueAndRedemption.subType === ConvertibleBondSubType.PERCS) {
      if (!terms.conversion.percsConversion) {
        terms.conversion.percsConversion = new PercsConversion();
      }
      quickTerms.conversionRatioCap = terms.conversion.percsConversion.capPrice;
      quickTerms.conversionRatio = terms.conversion.percsConversion.conversionRatio;

    }

    if (terms.conversion.isContigentConversion && terms.conversion.contingentConversion && terms.conversion.contingentConversion.schedule) {
      // tslint:disable-next-line:max-line-length
      const startDate = terms.conversion.schedule && terms.conversion.schedule.length > 0 ? terms.conversion.schedule[0].startDate : firstSettlementDate;
      quickTerms.coCoEndDate = this.setQuickTermsCoCoDates(terms.conversion.contingentConversion.schedule, startDate);
      quickTerms.coCoTrigger = this.setQuickTermsCoCoTriggers(terms.conversion.contingentConversion.schedule);
    } else {
      quickTerms.coCoEndDate = null;
      quickTerms.coCoTrigger = null;
    }

    if (terms.issueAndRedemption.exhangeableParameters) {
      quickTerms.exchangeable = terms.issueAndRedemption.exhangeableParameters.exhangeableType !== ExchangeableType.NonExchangeable;
      quickTerms.pledgedShares = terms.issueAndRedemption.exhangeableParameters.exhangeableType === ExchangeableType.PledgedShares ?
                                 terms.issueAndRedemption.exhangeableParameters.percentOfPledgedShares : null;
    }

    if (terms.conversion.isResetable && terms.conversion.reset && terms.conversion.reset.schedule.length > 0) {
       quickTerms.reset = this.setQuickTermsReset(terms.conversion.reset.schedule, firstSettlementDate);
       quickTerms.resetFloor = this.setQuickTermsResetFloor(terms.conversion.reset.schedule);
       quickTerms.resetMultiplier = this.setQuickTermsResetMultiplier(terms.conversion.reset.schedule);
    } else {
       quickTerms.reset = '';
       quickTerms.resetFloor = '';
       quickTerms.resetMultiplier = '';
    }

    quickTerms.useDividendProtection = terms.issueAndRedemption.useDividendProtection;

    if (terms.dividendProtection && terms.issueAndRedemption.useDividendProtection) {
     quickTerms.dividendProtection = terms.dividendProtection.type;
     quickTerms.tresholdFrequency = terms.dividendProtection.tresholdPeriodFrequency;

     if (terms.dividendProtection.tresholdType === DividendProtectionTresholdType.FullProtection) {
       quickTerms.dividendTresholdValue = '0';
       quickTerms.tresholdGrowth = null;
     } else {
       if (terms.dividendProtection.schedule && terms.dividendProtection.schedule.length > 0) {
         const firstRow = terms.dividendProtection.schedule[0];
         quickTerms.dividendTresholdType = this.mapDividendThresholdType(firstRow.tresholdValueType);

         if (terms.dividendProtection.tresholdType === DividendProtectionTresholdType.FlatForPeriod) {
           quickTerms.dividendTresholdValue = firstRow.tresholdValue;
         } else {
           if (!quickTerms.tresholdGrowth) {
             quickTerms.dividendTresholdValue = 'Schedule';
             quickTerms.tresholdGrowth = null;
           }
         }
       } else {
         if (terms.dividendProtection.tresholdType === DividendProtectionTresholdType.FlatForPeriod) {
          quickTerms.dividendTresholdValue = null;
         } else {
          if (!quickTerms.tresholdGrowth) {
            quickTerms.dividendTresholdValue = 'Schedule';
            quickTerms.tresholdGrowth = null;
          }
         }
       }
     }
    }

    if (terms as ConvertibleBondNewIssueDocument) {
      const priceTalk = (terms as ConvertibleBondNewIssueDocument).priceTalk;
      const stockPrice = (terms as ConvertibleBondNewIssueDocument).stockPriceReference;

      if (priceTalk) {

        quickTerms.priceTalk.higherStrikePremiumBest = priceTalk.higherStrikePremiumBest;
        quickTerms.priceTalk.higherStrikePremiumWorst = priceTalk.higherStrikePremiumWorst;
        quickTerms.priceTalk.premiumBest = priceTalk.premiumBest;
        quickTerms.priceTalk.premiumWorst = priceTalk.premiumWorst;
        quickTerms.priceTalk.couponBest = priceTalk.couponBest;
        quickTerms.priceTalk.couponWorst = priceTalk.couponWorst;
        quickTerms.priceTalk.redemptionValueBest = priceTalk.redemptionValueBest === 0 ? 0 : priceTalk.redemptionValueBest || 100;
        quickTerms.priceTalk.redemptionValueWorst = priceTalk.redemptionValueWorst === 0 ? 0 : priceTalk.redemptionValueWorst || 100;
        quickTerms.priceTalk.issueYieldBest = priceTalk.issueYieldBest;
        quickTerms.priceTalk.issueYieldWorst = priceTalk.issueYieldWorst;
        quickTerms.priceTalk.issuePriceBest = priceTalk.issuePriceBest === 0 ? 0 : priceTalk.issuePriceBest || 100;
        quickTerms.priceTalk.issuePriceWorst = priceTalk.issuePriceWorst === 0 ? 0 : priceTalk.issuePriceWorst || 100;
      }

      if (stockPrice) {
        quickTerms.fixedStockRef = stockPrice?.referenceType === StockReferenceType.Fixed;
        quickTerms.stockPriceReference = stockPrice;
      }
    }

    return quickTerms;
  }

  private setQuickTermsPuts(schedule: PutScheduleItem[], firstDate: Date): string {
    const puts = [];

    schedule.forEach(element => {
      const year = this.calculateYearsFromDate(element.startDate, firstDate);
      puts.push(Math.round(year * 10) / 10);
    });

    return puts.join();
  }

  private setQuickTermsPutsPrices(schedule: PutScheduleItem[]): string {
    const prices = [];

    schedule.forEach(element => {
      prices.push(element.price);
    });

    return prices.join();
  }

  private setQuickTermsSoftCall(schedule: CallScheduleItem[], firstDate: Date): string {
    const softCall = [];

    schedule.forEach(element => {
      if (element.trigger && element.trigger !== 0) {
        const year = this.calculateYearsFromDate(element.startDate, firstDate);
        softCall.push(Math.round(year * 10) / 10);
      }

    });

    return softCall.join();
  }

  private setQuickTermsCallTrigger(schedule: CallScheduleItem[]): string {
    const triggers = [];

    schedule.forEach(element => {
      if (element.trigger && element.trigger !== 0 ) {
        triggers.push(element.trigger);
      }
    });

    return triggers.join();
  }

  private setQuickTermsCallProtection(schedule: CallScheduleItem[], firstDate: Date): string {
    const softCall = [];

    schedule.forEach(element => {
      if (!element.trigger || element.trigger === 0) {
        const year = this.calculateYearsFromDate(element.startDate, firstDate);
        softCall.push(Math.round(year * 10) / 10);
      }

    });

    return softCall.join();
  }

  private setQuickTermsCoCoDates(schedule: ContingentConversionScheduleItem[], firstDate: Date): string {
    const cocoDates = [];

    schedule.forEach(element => {
      const year = this.calculateYearsFromDate(element.endDate, firstDate);
      cocoDates.push(Math.round(year * 10) / 10);
    });

    return cocoDates.join();
  }

  private setQuickTermsCoCoTriggers(schedule: ContingentConversionScheduleItem[]): string {
    const triggers = [];

    schedule.forEach(element => {
      if (element.initialTrigger && element.initialTrigger !== 0 ) {
        triggers.push(element.initialTrigger);
      }
    });

    return triggers.join();
  }

  private mapDividendThresholdType(dividendThresholdType: DividendProtectionTresholdValueType): DividendThreshold {

    switch (dividendThresholdType) {
      case DividendProtectionTresholdValueType.Cash:
        return DividendThreshold.Absolute;
      case  DividendProtectionTresholdValueType.PercentOfStockPrice:
        return DividendThreshold.Percentage;
      case DividendProtectionTresholdValueType.PercentOfEPS:
        return DividendThreshold.PercentOfEps;
      case DividendProtectionTresholdValueType.CashPerBond:
        return DividendThreshold.CashPerBond;
    }

    return DividendThreshold.Absolute;
  }

  private setQuickTermsReset(schedule: ResetScheduleItem[], firstDate: Date): string {
    const reset = [];

    schedule.forEach(element => {
      const year = this.calculateYearsFromDate(element.startDate, firstDate);
      reset.push(Math.round(year * 10) / 10);
    });

    return reset.join();
  }

  private setQuickTermsResetFloor(schedule: ResetScheduleItem[]): string {
    const floor = [];

    schedule.forEach(element => {
      floor.push(element.floor);
    });

    return floor.join();
  }

  private setQuickTermsResetMultiplier(schedule: ResetScheduleItem[]): string {
    const multiplier = [];

    schedule.forEach(element => {
      multiplier.push(element.multiplier);
    });

    return multiplier.join();
  }

  private getSetupStatusQuickTerms(setupStatus: SetupStatus): SetupStatusQuickTerms {
    return setupStatus === SetupStatus.NewIssue ? SetupStatusQuickTerms.NewIssue : SetupStatusQuickTerms.Fixed;
  }

  private calculateYearsFromDate(date: Date, firstSettlementDate: Date): number {
    if (date) {
      const mDate = moment.utc(date);
      const mFirst = moment.utc(firstSettlementDate);
      const duration = moment.duration(mDate.diff(mFirst));
      return parseFloat(duration.asYears().toFixed(1));
      // return Math.round(duration.asYears() * 10) / 10;
    } else {
      return null;
    }
  }
}
