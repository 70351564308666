/**
 * Accrued on default descriptions.
 */
export enum AccruedOnDefaultDescription {
  DoNotPay = 'Do Not Pay',
  Pay = 'Pay'
}

/**
 * Accrued on default.
 */
export enum AccruedOnDefault {
  DoNotPay = 'DoNotPay',
  Pay = 'Pay'
}
