

import { CallValueType } from './CallValueType';
import { Frequency } from './Frequency';
import { SoftCallData } from './SoftCallData';
import { CallMakeWholeData } from './CallMakeWholeData';
import { CallScheduleItem } from './CallScheduleItem';
import { DayCount } from './CouponDayCount';
import { NoticePeriod } from './PutNoticePeriod';

export class Call {
    
    valueType: CallValueType;
    yieldFrequency: Frequency;
    yieldDayCount: DayCount;
    keepAccruedRedemption: boolean;
    keepAccruedConversion: boolean;
    forfeitCoupon: boolean;
    notice: number;
    noticePeriod: NoticePeriod;
    callDate: Date;
    callNoticeGiven: boolean;
    softCall: SoftCallData;
    callMakeWhole: CallMakeWholeData;
    scheduleItems: CallScheduleItem[];
    recieveMakeWhole: boolean;
    callNoticeDaysMax: number;
    considerationDaysMax: number;
    considerationDaysBusinessCalendar: NoticePeriod;
    callFirstCallConsiderationDate: Date;
    callTriggerObservationStartDate: Date;
}

