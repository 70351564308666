import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LvAnalyticsError } from '../../models/errors';
import { IValuationSession } from '../../models/valuation-session/valuation-session';
import { IOverrideCrEstimateRequest } from '../../models/estimated-ratio/cr-estimate-settings-request';
import { IOverrideModelCustomizationRequest } from '../../models/model-customization/model-customization-request';
import { IOverrideNewIssueAssumptionsRequest } from '../../models/new-issue-assumption/new-issue-assumption-request';
import { IOutput } from '../../models/outputs/output';
import { IConvertibleValuation } from '../../models/valuation-session/convertible-valuation';
import { IValuationResult } from '../../models/valuation-session/valuation-result';
import { IModelCustomization } from '../../models/model-customization/model-customization';
import { ILoadPricingResponse } from '../../models/valuation-session/load-pricing-response';
import { ICalculateYieldOrRedemptionRequest } from '../../models/new-issue-assumption/calculate-yield-or-redemption-request';
import { ModelCustomizationMapper } from '../../models/model-customization/model-customization-mapper';
import { HttpClientBase } from '@lv-core-ui/api';
import { ILvError, LvDataMaster, LvErrorType } from '@lv-core-ui/models';
import { ISelectedEnvrionments } from '@lv-analytics/models';
import { IPricingEnvironmentOverrides } from '@lv-analytics/models/company-and-user-settings/pricing-environment-overrides';

/**
 * Valuation session service.
 */
@Injectable()
export class ValuationSessionService extends HttpClientBase {

  constructor(
    http: HttpClient,
    private modelCustomizationMapper: ModelCustomizationMapper,
  ) {
    super(http, '/analytics/valuationSession');
  }

  /**
   * Create valuation session if there isn't one
   * @param {string} lwsIdentifier lws id
   * @param {boolean} isPrivateInstrument is instrument private
   * @param {string} pricingEnvironmentCode pricing environment
   * @param {IPricingEnvironmentOverrides} pricingEnvironmentOverrides Override
   * @param {boolean} isOpenedFromExcel is application opened from excel
   * @returns promise based IValuationSession
   */
  async createValuationSession(lwsIdentifier: string,
                               isPrivateInstrument: boolean,
                               pricingEnvironmentCode: string = '',
                               pricingEnvironmentOverrides: IPricingEnvironmentOverrides = null,
                               isOpenedFromExcel: boolean
                               ): Promise<IValuationSession> {
    try {
      const result = await this.postAsync<IValuationSession>({
        lwsIdentifier: lwsIdentifier || '',
        isPrivateInstrument: isPrivateInstrument,
        pricingEnvironmentCode: pricingEnvironmentCode,
        pricingEnvironmentOverrides: pricingEnvironmentOverrides,
        isOpenedFromExcel: isOpenedFromExcel
      }, '/createValuationSession');

      if (!result) {
        throw new LvAnalyticsError(LvDataMaster.getError('dM-3356',
        {
          'timezone': 'Valuation Session'
        }));
      }

      return result;
    }
    catch (error) {
      throw this.handleError(error, this.handleUnauthorizedError);
    }
  }

  /**
   * Closes valuation session.
   * @param sessionId Session ID.
   */
  async closeValuationSession(sessionId: string): Promise<void> {
    try {
      await this.postAsync<any>({
        sessionId: sessionId
      }, '/closeValuationSession');
    }
    catch (error) {
      throw this.handleError(error, this.handleUnauthorizedError);
    }
  }

  /**
   * Loads instrument in session.
   * @param sessionId Session ID.
   * @param lwsIdentifier LWS identifier.
   * @param isPrivateInstrument A flag indicating if instrument is private.
   * @param pricingEnviromentCode Pricing environment code.
   * @param isOpenedFromExcel A flag indicating if instrument is opened from Excel.
   * @returns IValuationSession object.
   */
  async loadInstrumentInSession(sessionId: string,
                                lwsIdentifier: string,
                                isPrivateInstrument: boolean,
                                pricingEnviromentCode: string = null,
                                isOpenedFromExcel: boolean
                                ): Promise<IValuationSession> {
    try {
      const result = await this.postAsync<IValuationSession>({
        lwsIdentifier: lwsIdentifier,
        sessionId: sessionId,
        isPrivateInstrument : isPrivateInstrument,
        pricingEnvironmentCode: pricingEnviromentCode || '',
        isOpenedFromExcel: isOpenedFromExcel
      }, '/loadInstrumentInSession');

      if (!result) {
        throw new LvAnalyticsError(LvDataMaster.getError('dM-3356',
        {
          'timezone': 'Valuation Session'
        }));
      }

      if (result.modelCustomization) {
        // tslint:disable-next-line: max-line-length
        result.modelCustomization = this.modelCustomizationMapper.mapModelCustomizationToUi(result.modelCustomization);
      }

      return result;
    }
    catch (error) {
      throw this.handleError(error, this.handleUnauthorizedError);
    }
  }

  /**
   * Loads pricing.
   * @param sessionId Session ID.
   * @param lwsIdentifier LWS identifier.
   * @param environmentId Environment ID.
   * @returns ILoadPricingResponse object.
   */
  async loadPricing(sessionId: string, lwsIdentifier: string, environmentId: string, draftId: string): Promise<ILoadPricingResponse> {
    try {
      let pricingSettings: ILoadPricingResponse;

      if (!!draftId) {
        pricingSettings = await this.postAsync<ILoadPricingResponse>(null, '/loadPricing', {
          sessionId: sessionId,
          lwsIdentifier: lwsIdentifier,
          environmentId: environmentId,
          draftId: draftId
        });
      }
      else {
        pricingSettings = await this.postAsync<ILoadPricingResponse>(null, '/loadPricing', {
          sessionId: sessionId,
          lwsIdentifier: lwsIdentifier,
          environmentId: environmentId
        });
      }

      return pricingSettings;
    }
    catch (error) {
      throw this.handleError(error, (e) => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Overrides CR estimate.
   * @param request IOverrideCrEstimateRequest object.
   */
  async overrideCrEstimate(request: IOverrideCrEstimateRequest) {
    try {
      await this.postAsync<IOverrideCrEstimateRequest>(request, '/overrideCrEstimate');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Override model customization.
   * @param request IOverrideModelCustomizationRequest object.
   */
  async overrideModelCustomization(request: IOverrideModelCustomizationRequest) {
    try {
      // tslint:disable-next-line: max-line-length
      request.modelCustomization = this.modelCustomizationMapper.mapModelCustomizationToApi(request.modelCustomization);
      await this.postAsync<IOverrideModelCustomizationRequest>(request, '/overrideModelCustomization');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Loads model customization.
   * @param sessionId Session ID.
   * @param lwsIdentifier LWS identifier.
   * @param environmentId Environment ID.
   * @returns IModelCustomization object.
   */
  async loadModelCustomization(
    sessionId: string,
    lwsIdentifier: string,
    environmentId: string): Promise<IModelCustomization> {
    try {
      const response = await this.postAsync<IModelCustomization>(null, '/loadModelCustomization', {
        sessionId: sessionId,
        lwsIdentifier: lwsIdentifier,
        environmentId: environmentId
      });

      return  this.modelCustomizationMapper.mapModelCustomizationToUi(response);
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Sets yield from redemption.
   * @param request ICalculateYieldOrRedemptionRequest object.
   */
  async setYieldFromRedemption(request: ICalculateYieldOrRedemptionRequest): Promise<number> {
    try {
      return await this.postAsync<number>(request, '/yieldFromRedemption');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Sets redemption from yield.
   * @param request ICalculateYieldOrRedemptionRequest object.
   */
  async setRedemptionFromYield(request: ICalculateYieldOrRedemptionRequest): Promise<number> {
    try {
      return await this.postAsync<number>(request, '/redemptionFromYield');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Overrides new issue assumptions.
   * @param request IOverrideNewIssueAssumptionsRequest object.
   */
  async overrideNewIssueAssumptions(request: IOverrideNewIssueAssumptionsRequest) {
    try {
      await this.postAsync<IOverrideNewIssueAssumptionsRequest>(request, '/overrideNewIssueAssumptions');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Saves custom outputs.
   * @param sessionId Session ID.
   * @param updatedOutputs List of IOutput objects.
   */
  async saveCustomOutputs(sessionId: string, updatedOutputs: IOutput[]): Promise<void> {
    try {
      await this.postAsync<any>({
        sessionId: sessionId,
        customOutputs: updatedOutputs
      }, '/customOutputs');
    }
    catch (error) {
      throw error;
    }
  }

  /**
   * Selects session outputs.
   * @param sessionId Session ID.
   * @param useDefault Use default flag.
   */
  async selectSessionOutputs(sessionId: string, useDefault: boolean): Promise<void> {
    try {
      await this.postAsync<any>({
        sessionId: sessionId,
        useDefault: useDefault
      }, '/selectOutputs');
    }
    catch (error) {
      throw error;
    }
  }

  /**
   * Calculates valuation.
   * @param request IConvertibleValuation object.
   * @returns IValuationResult object.
   */
  async calculateValuation(request: IConvertibleValuation): Promise<IValuationResult> {
    try {
      const valuation = await this.postAsync<IValuationResult>(request, '/calculate');
      return valuation;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Handles unauthorizes error.
   * @param e ILvError object.
   * @returns LvAnalyticsError object.
   */
  private handleUnauthorizedError(e: ILvError): LvAnalyticsError {
    const error = new LvAnalyticsError(e.message);

    if (e.type === LvErrorType.AUTHORIZATION) {
      error.type = e.type;
    }

    return error;
  }
}
