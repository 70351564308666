import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IBaseTermsSettings, IConversionSectionSettings } from '@lv-convertible-bond/models';

@Component({
  selector: 'lv-conversion-settings',
  templateUrl: './lv-conversion-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvConversionSettingsComponent implements OnInit {

  @Input() systemDefaults: IBaseTermsSettings<IConversionSectionSettings>;
  @Input() myDefaults: IBaseTermsSettings<IConversionSectionSettings>;

  constructor() { }

  @HostBinding('class.lv-conversion-settings')
  get isLvConversionSettingsComponent() {
    return true;
  }

  ngOnInit() {
  }

   /**
    * Retrieves element's id
    * @param element reference to given element
    * @param id element's id
    * @returns falg- true if element with given id is found
    */
    getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
      return element.nativeElement.getAttribute('data-tooltip-id') === id;
    }
}
