import { ITenor } from '../tenor';
import { ITermStructureItem } from './yield-curve-term-structure';
import { YieldCurveSource } from '../interest-rates/interest-rates-enum';

/**
 * Yield curve terms structure settings.
 */
export class YieldCurveTermsStructureSettings {
  curveId: number;
  source: YieldCurveSource;
  instrumentTypeTitle: string;
  currencyCode: string;
  environmentId: string;
  tenors: ITenor[];
  termStructureItems: ITermStructureItem[];

  constructor() {
    this.setInstrumentTypeTitle('N/A');
    this.tenors = [];
  }

  /**
   * Sets instrument type title.
   * @param currencyCode Currency code.
   */
  public setInstrumentTypeTitle(currencyCode: string) {
    this.currencyCode = currencyCode;
    this.instrumentTypeTitle = `Rate (${currencyCode})`;
  }
}
