<lv-flex-box data-cy="DM-302"
  class="lv-borrow-flat-yield"
  direction="row">
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="borrowTooltipTemplate"
    filter="span:not(lv-xl-label span)">
      <span [attr.borrow-tooltip-id]="'borrowTooltip'">
        Borrow
      </span>
      <lv-xl-label [field]="'BRW_FLAT'"></lv-xl-label>
  </div>
  <div class="lv-input-field">
    <lv-numeric-text-box
      #borrowFlat
      name="borrow"
      [format]="numberFormat"
      [decimals]="numberOfDecimals"
      [(ngModel)]="borrowSettings.borrow"
      (didKeyDownEnter)="onBorrowChange()"
      (didBlur)="onBorrowChange()">
    </lv-numeric-text-box>
    <span class="lv-sufix-label">bps</span>
  </div>
</lv-flex-box>

<ng-template #borrowTooltipTemplate>
  <lv-tooltip dm="dM-302"></lv-tooltip>
</ng-template>
