<lv-flex-box class="lv-percs-conversion">
  <lv-flex-box direction="row" data-cy="DM-835">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionRatioSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
         Conversion Ratio
      </span>
      <lv-xl-label [field]="'PERCS_CR'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="percs-conversion-ratio"
        [format]="formatSix"
        [decimals]="decimalsSix"
        [(ngModel)]="model.conversionRatio"
        (didBlur)="onPercsConversionChange()"
        (didKeyDownEnter)="onPercsConversionChange()">
      </lv-numeric-text-box>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-836">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="capPriceSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Cap Price
      </span>
      <lv-xl-label [field]="'PERCS_CAP_CP'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        [format]="formatTwo"
        [decimals]="decimalsTwo"
        name="percs-cap-ratio"
        [(ngModel)]="model.capPrice"
        (didBlur)="onPercsConversionChange()"
        (didKeyDownEnter)="onPercsConversionChange()">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>
  </lv-flex-box>
</lv-flex-box>

<ng-template #conversionRatioSourceTooltip>
  <lv-tooltip dm="dM-835"></lv-tooltip>
</ng-template>
<ng-template #capPriceSourceTooltip>
  <lv-tooltip dm="dM-836"></lv-tooltip>
</ng-template>
