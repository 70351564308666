
import { AccruedOnDefaultDescription } from './../../models/market-data/credit/enums/accrued-on-default';
import { CreditModelFactorDisplay } from './../../models/market-data/credit/credit-parameters';
import { DateAdjustmentDescription } from './../../models/market-data/credit/enums/date-adjustment';
import { DayCountDescription } from './../../models/market-data/credit/enums/day-count';
import { DateExtensions } from '@lv-core-ui/util';
import { ExcelFieldDataTypes } from '@lv-excel/models/enums/excel-field-data-types';
import { EquityToCreditCalculationTypeDescription } from './../../models/market-data/credit/enums/equity-to-credit-calculation-type';
import { IMarketData } from './../../models/market-data/market-data';
import { PeriodFrequencyDescription } from './../../models/market-data/credit/enums/period-frequency';

/**
 * Maps credit.
 * @param fieldDictionary Field dictionary.
 * @param model IMarketData object.
 */
export function mapCredit(fieldDictionary: any, model: IMarketData): void {
  fieldDictionary['CREDIT_FLAT'] = {
    mapFn: (value: any) => {
      model.credit.issuerCreditParameters.flatCreditSpread = value;
    },
    reverseMapFn: () => {
      return model.credit.issuerCreditParameters.flatCreditSpread;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_CDS'] = {
    mapFn: (value: any) => {
      model.credit.issuerCreditParameters.cdsSpread = value;
    },
    reverseMapFn: () => {
      return model.credit.issuerCreditParameters.cdsSpread;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_CDS_UPFRONT'] = {
    mapFn: (value: any) => {
      model.credit.issuerCreditParameters.cdsUpfront = value;
    },
    reverseMapFn: () => {
      return model.credit.issuerCreditParameters.cdsUpfront;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_DFLT_FLAT'] = {
    mapFn: (value: any) => {
      model.credit.issuerCreditParameters.defaultRate = value;
    },
    reverseMapFn: () => {
      return model.credit.issuerCreditParameters.defaultRate;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_FREQ'] = {
    mapFn: (value: any) => {
      model.credit.issuerCreditParameters.frequency = value;
    },
    reverseMapFn: () => {
      return PeriodFrequencyDescription[model.credit.issuerCreditParameters.frequency];
    },
    type: ExcelFieldDataTypes.Enum,
    enumType: PeriodFrequencyDescription
  };

  fieldDictionary['CREDIT_BASIS'] = {
    mapFn: (value: any) => {
      model.credit.issuerCreditParameters.basis = value;
    },
    reverseMapFn: () => {
      return DayCountDescription[model.credit.issuerCreditParameters.basis];
    },
    type: ExcelFieldDataTypes.Enum,
    enumType: DayCountDescription
  };

  fieldDictionary['CREDIT_FACT_MODEL'] = {
    mapFn: (value: any) => {
      model.credit.issuerCreditParameters.creditModelFactor = value;
    },
    reverseMapFn: () => {
      return CreditModelFactorDisplay[model.credit.issuerCreditParameters.creditModelFactor];
    },
    type: ExcelFieldDataTypes.Enum,
    enumType: CreditModelFactorDisplay
  };

  fieldDictionary['CREDIT_UND_DEF_JUMP'] = {
    mapFn: (value: any) => {
      model.credit.underlyingCreditParameters.jumpOnUnderlyingDefault = value;
    },
    reverseMapFn: () => {
      return model.credit.underlyingCreditParameters.jumpOnUnderlyingDefault;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_REC_CDS'] = {
    mapFn: (value: any) => {
      model.credit.issuerCreditParameters.recoveryRateCDS = value;
    },
    reverseMapFn: () => {
      return model.credit.issuerCreditParameters.recoveryRateCDS;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_REC_BOND'] = {
    mapFn: (value: any) => {
      model.credit.issuerCreditParameters.recoveryRateBond = value;
    },
    reverseMapFn: () => {
      return model.credit.issuerCreditParameters.recoveryRateBond;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_ACC_DFLT'] = {
    mapFn: (value: any) => {
      model.credit.issuerCreditParameters.accruedOnDefault = value;
    },
    reverseMapFn: () => {
      return AccruedOnDefaultDescription[model.credit.issuerCreditParameters.accruedOnDefault];
    },
    type: ExcelFieldDataTypes.Enum,
    enumType: AccruedOnDefaultDescription
  };

  fieldDictionary['CREDIT_BAD_DAY'] = {
    mapFn: (value: any) => {
      model.credit.issuerCreditParameters.badDayConversion = value;
    },
    reverseMapFn: () => {
      return DateAdjustmentDescription[model.credit.issuerCreditParameters.badDayConversion];
    },
    type: ExcelFieldDataTypes.Enum,
    enumType: DateAdjustmentDescription
  };

  fieldDictionary['CREDIT_FLAT_UND'] = {
    mapFn: (value: any) => {
      model.credit.underlyingCreditParameters.flatCreditSpread = value;
    },
    reverseMapFn: () => {
      return model.credit.underlyingCreditParameters.flatCreditSpread;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_CDS_UND'] = {
    mapFn: (value: any) => {
      model.credit.underlyingCreditParameters.cdsSpread = value;
    },
    reverseMapFn: () => {
      return model.credit.underlyingCreditParameters.cdsSpread;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_CDS_UPFRONT_UND'] = {
    mapFn: (value: any) => {
      model.credit.underlyingCreditParameters.cdsUpfront = value;
    },
    reverseMapFn: () => {
      return model.credit.underlyingCreditParameters.cdsUpfront;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_DFLT_FLAT_UND'] = {
    mapFn: (value: any) => {
      model.credit.underlyingCreditParameters.defaultRate = value;
    },
    reverseMapFn: () => {
      return model.credit.underlyingCreditParameters.defaultRate;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_FREQ_UND'] = {
    mapFn: (value: any) => {
      model.credit.underlyingCreditParameters.frequency = value;
    },
    reverseMapFn: () => {
      return PeriodFrequencyDescription[model.credit.underlyingCreditParameters.frequency];
    },
    type: ExcelFieldDataTypes.Enum,
    enumType: PeriodFrequencyDescription
  };

  fieldDictionary['CREDIT_BASIS_UND'] = {
    mapFn: (value: any) => {
      model.credit.underlyingCreditParameters.basis = value;
    },
    reverseMapFn: () => {
      return DayCountDescription[model.credit.underlyingCreditParameters.basis];
    },
    type: ExcelFieldDataTypes.Enum,
    enumType: DayCountDescription
  };

  fieldDictionary['CREDIT_REC_CDS_UND'] = {
    mapFn: (value: any) => {
      model.credit.underlyingCreditParameters.recoveryRateCDS = value;
    },
    reverseMapFn: () => {
      return model.credit.underlyingCreditParameters.recoveryRateCDS;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_ACC_DFLT_UND'] = {
    mapFn: (value: any) => {
      model.credit.underlyingCreditParameters.accruedOnDefault = value;
    },
    reverseMapFn: () => {
      return AccruedOnDefaultDescription[model.credit.underlyingCreditParameters.accruedOnDefault];
    },
    type: ExcelFieldDataTypes.Enum,
    enumType: AccruedOnDefaultDescription
  };

  fieldDictionary['CREDIT_BAD_DAY_UND'] = {
    mapFn: (value: any) => {
      model.credit.underlyingCreditParameters.badDayConversion = value;
    },
    reverseMapFn: () => {
      return DateAdjustmentDescription[model.credit.underlyingCreditParameters.badDayConversion];
    },
    type: ExcelFieldDataTypes.Enum,
    enumType: DateAdjustmentDescription
  };

  fieldDictionary['CREDIT_CALIB_TYPE'] = {
    mapFn: (value: any) => {
      model.credit.equityLinkedCredit.equityToCreditCalibrationType = value;
    },
    reverseMapFn: () => {
      return EquityToCreditCalculationTypeDescription[model.credit.equityLinkedCredit.equityToCreditCalibrationType];
    },
    type: ExcelFieldDataTypes.Enum,
    enumType: EquityToCreditCalculationTypeDescription
  };

  fieldDictionary['CREDIT_PARM'] = {
    mapFn: (value: any) => {
      model.credit.equityLinkedCredit.equityToCreditParameter = value;
    },
    reverseMapFn: () => {
      return model.credit.equityLinkedCredit.equityToCreditParameter;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_REF_SPOT'] = {
    mapFn: (value: any) => {
      model.credit.equityLinkedCredit.equityToCreditRefStock = value;
    },
    reverseMapFn: () => {
      return model.credit.equityLinkedCredit.equityToCreditRefStock;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_BOND_FLOOR'] = {
    mapFn: (value: any) => {
      model.credit.equityLinkedCredit.equityToCreditFloor = value;
    },
    reverseMapFn: () => {
      return model.credit.equityLinkedCredit.equityToCreditFloor;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_VOL'] = {
    mapFn: (value: any) => {
      model.credit.stochasticCreditModel.creditVol = value;
    },
    reverseMapFn: () => {
      return model.credit.stochasticCreditModel.creditVol;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_STCK_CORR'] = {
    mapFn: (value: any) => {
      model.credit.stochasticCreditModel.creditStockCorrelation = value;
    },
    reverseMapFn: () => {
      return model.credit.stochasticCreditModel.creditStockCorrelation;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_RVR_INTENSITY'] = {
    mapFn: (value: any) => {
      model.credit.stochasticCreditModel.creditMeanReversion = value;
    },
    reverseMapFn: () => {
      return model.credit.stochasticCreditModel.creditMeanReversion;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_MEAN'] = {
    mapFn: (value: any) => {
      model.credit.stochasticCreditModel.creditTarget = value;
    },
    reverseMapFn: () => {
      return model.credit.stochasticCreditModel.creditTarget;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_DBT_PER_SHARE'] = {
    mapFn: (value: any) => {
      model.credit.assetParameters.debrPerShare = value;
    },
    reverseMapFn: () => {
      return model.credit.assetParameters.debrPerShare;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_GLB_RECVRY'] = {
    mapFn: (value: any) => {
      model.credit.assetParameters.globalRecovery = value;
    },
    reverseMapFn: () => {
      return model.credit.assetParameters.globalRecovery;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_ASST_VOL_OVRRIDE'] = {
    mapFn: (value: any) => {
      model.credit.assetParameters.assetVolOverride = value;
    },
    reverseMapFn: () => {
      return model.credit.assetParameters.assetVolOverride;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['CREDIT_SCHED_RANGE'] = {
    mapFn: (value: any) => {
      model.credit.issuerCreditParameters.creditTermStructure = value.map(item => {
        return {
          ...item,
          date: DateExtensions.getDateInUtcFromUtcString(item.date)
        };
      });
    },
    reverseMapFn: () => {
      const result = model.credit.issuerCreditParameters.creditTermStructure.map(item => {
        return {
          ...item,
          date: DateExtensions.toOADate(item.date as Date)
        };
      });
      return result;
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['CREDIT_CDS_SCHED_RANGE'] = {
    mapFn: (value: any) => {
      model.credit.issuerCreditParameters.creditCDSTermStructure = value.map(item => {
        return {
          ...item,
          date: DateExtensions.getDateInUtcFromUtcString(item.date)
        };
      });
    },
    reverseMapFn: () => {
      const result = model.credit.issuerCreditParameters.creditCDSTermStructure.map(item => {
        return {
          ...item,
          date: DateExtensions.toOADate(item.date as Date)
        };
      });
      return result;
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['CREDIT_DFLT_SCHED_RANGE'] = {
    mapFn: (value: any) => {
      model.credit.issuerCreditParameters.creditDefaultRateTermStructure = value.map(item => {
        return {
          ...item,
          date: DateExtensions.getDateInUtcFromUtcString(item.date)
        };
      });
    },
    reverseMapFn: () => {
      const result = model.credit.issuerCreditParameters.creditDefaultRateTermStructure.map(item => {
        return {
          ...item,
          date: DateExtensions.toOADate(item.date as Date)
        };
      });

      return result;
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['CREDIT_SRVPROB_SCHED_RANGE'] = {
    mapFn: (value: any) => {
      model.credit.issuerCreditParameters.creditSurvivalProbabilityTermStructure = value.map(item => {
        return {
          ...item,
          date: DateExtensions.getDateInUtcFromUtcString(item.date)
        };
      });
    },
    reverseMapFn: () => {
      const result = model.credit.issuerCreditParameters.creditSurvivalProbabilityTermStructure.map(item => {
        return {
          ...item,
          date: DateExtensions.toOADate(item.date as Date)
        };
      });

      return result;
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['CREDIT_SCHED_RANGE_UND'] = {
    mapFn: (value: any) => {
      model.credit.underlyingCreditParameters.creditTermStructure = value.map(item => {
        return {
          ...item,
          date: DateExtensions.getDateInUtcFromUtcString(item.date)
        };
      });
    },
    reverseMapFn: () => {
      const result = model.credit.underlyingCreditParameters.creditTermStructure.map(item => {
        return {
          ...item,
          date: DateExtensions.toOADate(item.date as Date)
        };
      });

      return result;
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['CREDIT_CDS_SCHED_RANGE_UND'] = {
    mapFn: (value: any) => {
      model.credit.underlyingCreditParameters.creditCDSTermStructure = value.map(item => {
        return {
          ...item,
          date: DateExtensions.getDateInUtcFromUtcString(item.date)
        };
      });
    },
    reverseMapFn: () => {
      const result = model.credit.underlyingCreditParameters.creditCDSTermStructure.map(item => {
        return {
          ...item,
          date: DateExtensions.toOADate(item.date as Date)
        };
      });

      return result;
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['CREDIT_DFLT_SCHED_RANGE_UND'] = {
    mapFn: (value: any) => {
      model.credit.underlyingCreditParameters.creditDefaultRateTermStructure = value.map(item => {
        return {
          ...item,
          date: DateExtensions.getDateInUtcFromUtcString(item.date)
        };
      });
    },
    reverseMapFn: () => {
      const result = model.credit.underlyingCreditParameters.creditDefaultRateTermStructure.map(item => {
        return {
          ...item,
          date: DateExtensions.toOADate(item.date as Date)
        };
      });

      return result;
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['CREDIT_SRVPROB_SCHED_RANGE_UND'] = {
    mapFn: (value: any) => {
      model.credit.underlyingCreditParameters.creditSurvivalProbabilityTermStructure = value.map(item => {
        return {
          ...item,
          date: DateExtensions.getDateInUtcFromUtcString(item.date)
        };
      });
    },
    reverseMapFn: () => {
      const result = model.credit.underlyingCreditParameters.creditSurvivalProbabilityTermStructure.map(item => {
        return {
          ...item,
          date: DateExtensions.toOADate(item.date as Date)
        };
      });

      return result;
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['CREDIT_EQLNKD_RANGE'] = {
    mapFn: (value: any) => {
      model.credit.equityLinkedCredit = value;
      model.credit.equityLinkedCredit.equityToCreditCalibrationType = value.equityToCreditCalibrationType;
      model.credit.equityLinkedCredit.equityToCreditParameter = value.equityToCreditParameter;
      model.credit.equityLinkedCredit.equityToCreditFloor = value.equityToCreditFloor;
      model.credit.equityLinkedCredit.equityToCreditRefStock = value.equityToCreditRefStock;
    },
    reverseMapFn: () => {

      return [{
        equityToCreditCalibrationType: model.credit.equityLinkedCredit.equityToCreditCalibrationType,
        equityToCreditParameter: model.credit.equityLinkedCredit.equityToCreditParameter,
        equityToCreditFloor: model.credit.equityLinkedCredit.equityToCreditFloor,
        equityToCreditRefStock: model.credit.equityLinkedCredit.equityToCreditRefStock,
      }];
    },
    type: ExcelFieldDataTypes.Schedule,
  };

  fieldDictionary['CREDIT_STOCH_RANGE'] = {
    mapFn: (value: any) => {
      model.credit.stochasticCreditModel = value;
      model.credit.stochasticCreditModel.creditVol = value.creditVol;
      model.credit.stochasticCreditModel.creditStockCorrelation = value.creditStockCorrelation;
      model.credit.stochasticCreditModel.creditMeanReversion = value.creditMeanReversion;
      model.credit.stochasticCreditModel.creditTarget = value.creditTarget;
    },
    reverseMapFn: () => {

      return [{
        creditVol: model.credit.stochasticCreditModel.creditVol,
        creditStockCorrelation: model.credit.stochasticCreditModel.creditStockCorrelation,
        creditMeanReversion: model.credit.stochasticCreditModel.creditMeanReversion,
        creditTarget: model.credit.stochasticCreditModel.creditTarget,
      }];
    },
    type: ExcelFieldDataTypes.Schedule,
  };

  fieldDictionary['CREDIT_ISS_ASST_PARM_RANGE'] = {
    mapFn: (value: any) => {
      model.credit.assetParameters = value;
      model.credit.assetParameters.debrPerShare = value.debrPerShare;
      model.credit.assetParameters.globalRecovery = value.globalRecovery;
      model.credit.assetParameters.assetVolOverride = value.assetVolOverride;
    },
    reverseMapFn: () => {

      return [{
        debrPerShare: model.credit.assetParameters.debrPerShare,
        globalRecovery: model.credit.assetParameters.globalRecovery,
        assetVolOverride: model.credit.assetParameters.assetVolOverride,
      }];
    },
    type: ExcelFieldDataTypes.Schedule,
  };
}
