import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, ViewChild,
  ChangeDetectorRef, ElementRef, TemplateRef, HostListener, Output, EventEmitter, ViewRef } from '@angular/core';
import { Align, PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { LvSettingsSidePanelComponent } from '../lv-settings-side-panel/lv-settings-side-panel.component';

@Component({
  selector: 'lv-settings-widget',
  templateUrl: './lv-settings-widget.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvSettingsWidgetComponent {

  @ViewChild('dropdownAnchor', { static: true }) dropdownAnchor: ElementRef;
  @ViewChild(LvSettingsSidePanelComponent) settingsPanel: LvSettingsSidePanelComponent;

  @Output() didClosePanel: EventEmitter<void>;
  @Output() didPanelSlide: EventEmitter<boolean>;
  @Output() didWarrningClicked: EventEmitter<void>;

  @Input() titleText: string;
  @Input() isLoading: boolean;
  @Input() showSettings: boolean;
  @Input() showWarrning: boolean;
  @Input() width: number;
  @Input() floatingSidePanel: boolean;
  @Input() sidePanelLvId: string;

  @Input() dropdownTemplate: TemplateRef<any>;
  @Input() dropdownTemplateClass: string;

  private popupRef: PopupRef;
  sidePanelWidth: number;
  internalClick: boolean;

  get anchorAlign(): Align {
    return {
      horizontal: 'right',
      vertical: 'top'
    };
  }
  get popupAlign(): Align {
    return {
      horizontal: 'right',
      vertical: 'top'
    };
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private popupService: PopupService
  ) {
    this.titleText = 'Title';
    this.showSettings = true;
    this.showWarrning = false;
    this.width = 225;
    this.sidePanelWidth = 225;
    this.internalClick = false;
    this.isLoading = false;
    this.floatingSidePanel = false;
    this.sidePanelLvId = '';
    this.didClosePanel = new EventEmitter<void>();
    this.didPanelSlide = new EventEmitter<boolean>();
    this.didWarrningClicked = new EventEmitter<void>();
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onDocumentClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (this.popupRef && !this.internalClick) {
      this.popupRef.close();
      this.popupRef = null;
      this.didClosePanel.next();
    }

    this.internalClick = false;
  }

  onShowSettings(sidePanelWidth: number = null) {
    if (!this.isLoading) {

      if (sidePanelWidth) {
        this.sidePanelWidth = sidePanelWidth;
      }
      else {
        this.sidePanelWidth = this.width;
      }

      if (this.dropdownTemplate) {

        if (this.popupRef) { // close on outside click
          this.popupRef.close();
          this.popupRef = null;
          this.internalClick = false;
          this.didClosePanel.next();
        }
        else {
          this.popupRef = this.popupService.open({
            anchor: this.dropdownAnchor,
            content: this.dropdownTemplate,
            popupClass: `lv-settings-dropdown ${this.dropdownTemplateClass}`,
            anchorAlign: {
              horizontal: 'right',
              vertical: 'bottom'
            } as Align,
            popupAlign: {
              horizontal: 'right',
              vertical: 'top'
            } as Align
          });
          this.internalClick = true;
        }
      }
      else {
        this.settingsPanel.togglePanel();
      }
      if (!(this.changeDetectorRef as ViewRef).destroyed) {
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  onSliderClosed() {
    this.didClosePanel.next();
  }

  onSlide(isExpanded: boolean) {
    this.didPanelSlide.next(isExpanded);
  }

  onWarrningClick() {
    this.didWarrningClicked.next();
  }
}
