import { v4 } from 'uuid';

import { DateExtensions, LvLookupEnum } from '@lv-core-ui/util';
import { CustomMakeWholeData, ConversionData, Call, RatchetMatrixOfferValueTypeDescription, RatchetMatrixCompensationValueTypeDescription,
         IssueAndRedemption, TakeoverCompensationTypeDescription, PremiumCompensationFormulaType,
         TakeoverCompensationType, TakeoverProtection} from '@lv-convertible-bond/models';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';

export class LvTakeoverProtectionView {

  get isPremiumCompensationFormulaVisible() {
    return this.model.takeoverCompensationType === TakeoverCompensationType.PremiumCompensationFormula;
  }

  get isRatchetScheduleVisible() {
    return this.model.takeoverCompensationType === TakeoverCompensationType.RatchetSchedule;
  }

  get isRatchetMatrixVisible() {
    return this.model.takeoverCompensationType === TakeoverCompensationType.RatchetMatrix;
  }

  get isAveragingPeriodDetailsVisible() {
    return this.model.takeoverCompensationType === TakeoverCompensationType.PremiumCompensationFormula &&
      this.model.premiumCompensationFormulaType === PremiumCompensationFormulaType.Formula3;
  }

  get endDate(): Date {
    return this.endDateEnabled ? this._endDate : this.maturityDate;
  }
  set endDate(value: Date) {
    this._endDate = value;

    if (this.model && this.endDateEnabled) {
      this.model.takeoverCompensationProtectionEndDate = value;
    }
  }

  get startDate(): Date {
    return this.startDateEnabled ? this._startDate : this.firstSettlementDate;
  }
  set startDate(value: Date) {
    this._startDate = value;

    if (this.model && this.startDateEnabled) {
      this.model.takeoverCompensationProtectionStartDate = value;
    }
  }

  endDateEnabled: boolean;
  startDateEnabled: boolean;

  model: TakeoverProtection;
  customMakeWholeData: CustomMakeWholeData;
  issueAndRedemption: IssueAndRedemption;
  conversion: ConversionData;
  call: Call;

  takeOverCompensationCheckBoxId: string;
  takeoverPutCheckBoxId: string;
  areProtectionDateFieldsVisible: boolean;

  takeoverCompensationTypeLookup: LvLookupEnum;
  offerValueTypeLookup: LvLookupEnum;
  compensationValueTypeLookup: LvLookupEnum;

  private _endDate: Date;
  private _startDate: Date;
  private firstSettlementDate: Date;
  private maturityDate: Date;

  constructor(
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService,
  ) {
    this.takeOverCompensationCheckBoxId = v4();
    this.takeoverPutCheckBoxId = v4();
    this.takeoverCompensationTypeLookup = new LvLookupEnum(TakeoverCompensationTypeDescription);
    this.offerValueTypeLookup = new LvLookupEnum(RatchetMatrixOfferValueTypeDescription);
    this.compensationValueTypeLookup = new LvLookupEnum(RatchetMatrixCompensationValueTypeDescription);
    this.areProtectionDateFieldsVisible = false;

    this.init({} as TakeoverProtection, {} as IssueAndRedemption, {} as ConversionData, {} as Call, null);
  }

  init(
    model?: TakeoverProtection,
    issueAndRedemption?: IssueAndRedemption,
    conversion?: ConversionData,
    call?: Call,
    conversionRatio?: number
  ) {
    this.model = model ?? this._lvConvertibleBondTermsService.convertibleBondDocument.takeoverProtection;
    this.issueAndRedemption = issueAndRedemption;
    this.conversion = conversion;
    this.call = call;

    this.setDefaultDates(this.issueAndRedemption);
    this.setFieldsVisibility();

    if (this.call && this.call.recieveMakeWhole && this.call.callMakeWhole) {
      this.model.ratchetMatrixOfferValueType = this.call.callMakeWhole.ratchetMatrixOfferValueType;
      this.model.ratchetMatrixCompensationValueType = this.call.callMakeWhole.ratchetMatrixCompensationValueType;
    }

    if (this.conversion && this.conversion.recieveMakeWholeConversion && this.conversion.callMakeWhole) {
      this.model.ratchetMatrixOfferValueType = this.conversion.callMakeWhole.ratchetMatrixOfferValueType;
      this.model.ratchetMatrixCompensationValueType = this.conversion.callMakeWhole.ratchetMatrixCompensationValueType;
    }

    this.updateRatio(conversionRatio);
  }

  updateRatio(conversionRatio: number) {
    if (this.issueAndRedemption) {
      this.setCustomMakeWholeData(conversionRatio);
    }
  }

  setDefaultDates(issueAndRedemption: IssueAndRedemption) {
    if (issueAndRedemption) {

      const takeoverProtection = this._lvConvertibleBondTermsService.takeOverProtection;

      if (!this.model.takeoverCompensationProtectionStartDate) {
        this._startDate = DateExtensions.addDays(issueAndRedemption.firstSettlementDate, takeoverProtection?.takeoverCompensationProtectionStartDate);
        this.startDateEnabled = !!takeoverProtection?.takeoverCompensationProtectionStartDate;
      } else {
        this._startDate = new Date(this.model.takeoverCompensationProtectionStartDate);
        this.startDateEnabled = true;
      }

      if (!this.model.takeoverCompensationProtectionEndDate) {
        this._endDate = DateExtensions.addDays(issueAndRedemption.maturityDate, -takeoverProtection?.takeoverCompensationProtectionEndDate);
        this.endDateEnabled = !!takeoverProtection?.takeoverCompensationProtectionEndDate;
      } else {
        this._endDate = new Date(this.model.takeoverCompensationProtectionEndDate);
        this.endDateEnabled = true;
      }

      if (this.model.takeoverCompensationType ===  TakeoverCompensationType.None) {
        this.model.useTakeoverProtection = false;
        this.model.takeoverCompensationType = TakeoverCompensationType.PremiumCompensationFormula;
      }

      this.firstSettlementDate =
        DateExtensions.addDays(issueAndRedemption.firstSettlementDate, takeoverProtection?.takeoverCompensationProtectionStartDate);
      this.maturityDate =
        DateExtensions.addDays(issueAndRedemption.maturityDate, -takeoverProtection?.takeoverCompensationProtectionEndDate);
    }
  }

  setFieldsVisibility() {
    this.areProtectionDateFieldsVisible = this.model.takeoverCompensationType !== TakeoverCompensationType.None;
  }

  setCustomMakeWholeData(conversionRatio: number) {
    this.customMakeWholeData = new CustomMakeWholeData();
    this.customMakeWholeData.conversionRatio = conversionRatio;
    this.customMakeWholeData.currency = this.issueAndRedemption.currencyCode ? this.issueAndRedemption.currencyCode : '';
    this.customMakeWholeData.nominal = this.issueAndRedemption.nominalValue;
    this.customMakeWholeData.offerValueType = this.model.ratchetMatrixOfferValueType;
  }

  protectionStartDateCheckBoxChange(value: boolean): void {
    this.startDateEnabled = value;

    if (this.model) {
      this.model.takeoverCompensationProtectionStartDate = value ? this._startDate : null;
    }
  }

  protectionEndDateCheckBoxChange(value: boolean): void {
    this.endDateEnabled = value;

    if (this.model) {
      this.model.takeoverCompensationProtectionEndDate = value ? this._endDate : null;
    }
  }

  setCustomOfferValueType() {
    if (this.customMakeWholeData) {
      this.customMakeWholeData.offerValueType = this.model.ratchetMatrixOfferValueType;
      this.customMakeWholeData = {...this.customMakeWholeData};
    }
  }
}
