import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { v4 } from 'uuid';

@Component({
  selector: 'lv-input-field',
  templateUrl: './lv-input-field.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvInputFieldComponent implements OnInit {

  @Input() label: string;
  @Input() labelWidth: number;
  @Input() suffix: string;
  @Input() checkbox: boolean;
  @Input() checked: boolean;
  @Input() inputValue: any;
  @Input() disabled: boolean;

  @Output() didCheckboxChange: EventEmitter<boolean>;

  checkboxId: string;

  constructor() {
    this.checkboxId = v4();
    this.checked = true;
    this.disabled = false;
    this.didCheckboxChange = new EventEmitter<boolean>();
  }

  ngOnInit() {
  }

  onCheckboxChange() {
    this.didCheckboxChange.emit(this.checked);
  }

}
