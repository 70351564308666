import { NumberPipe } from '@progress/kendo-angular-intl';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, OnChanges, Input, ChangeDetectorRef } from '@angular/core';

import { LvLocalDatePipe } from '@lv-core-ui/pipes';
import { LvScenarioTwoDimChartView } from './lv-scenario-two-dim-chart.view';
import { ILvScenarioReport } from '../../lv-scenario.view';
import { LvScenarioChart } from '../lv-scenario-chart';
import { ScenarioOutput } from '@lv-analytics/models';

/**
 * Scenario two dim chart component.
 */
@Component({
  selector: 'lv-scenario-two-dim-chart',
  templateUrl: './lv-scenario-two-dim-chart.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvScenarioTwoDimChartComponent extends LvScenarioChart implements OnChanges {

  @Input() report: ILvScenarioReport;

  view: LvScenarioTwoDimChartView;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    numberPipe: NumberPipe,
    lvDatePipe: LvLocalDatePipe
  ) {
    super(changeDetectorRef);

    this.view = new LvScenarioTwoDimChartView(numberPipe, lvDatePipe);
    this.view.init();
  }

  /**
   * Does view initialization.
   */
  ngOnChanges() {
    this.view.init(this.report);
  }

  /**
   * Occurs on current output click.
   * @param output Scenario output.
   */
  onCurrentOutputClick(output: ScenarioOutput) {
    this.view.model.currentOutput = output;

    this.view.setMultiValueYAxisCurrentOffset(output);

    this.changeDetectorRef.detectChanges();
  }
}
