import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';

import { SettingsService } from './services/settings/settings.service';
import { ApplicationSettingsService } from './application-settings.service';
import { DefaultWidgetStateService } from './services/default-widget-state/default-widget-state.service';
import { LvSharedDefaultWidgetStateService } from '../shared/services/default-widget-state.service';

/**
 * Application settings module deffinition that contains registration of providers
 */
@NgModule()
export class ApplicationSettingsModule {
  constructor(@Optional() @SkipSelf() parentModule: ApplicationSettingsModule) {
    if (parentModule) {
      throw new Error('ApplicationSettingsModule is already loaded. It should only be imported in your application\'s main module.');
    }
  }

  /**
   * Provides and configures services for Application Settings module
   * @returns Module provider for ApplicationSettingsModule
   */
  public static forRoot(): ModuleWithProviders<ApplicationSettingsModule> {
    return {
      ngModule: ApplicationSettingsModule,
      providers: [
        SettingsService,
        {
          provide: LvSharedDefaultWidgetStateService,
          useClass: DefaultWidgetStateService
        },
        ApplicationSettingsService
      ]
    };
  }
}
