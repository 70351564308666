import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnInit, Output,
  ViewEncapsulation } from '@angular/core';
import { ITermsSettings, ITermsSectionSettings, IBaseTermsSettings, IGeneralSectionSettings, ICouponSectionSettings,
  IAccretionSectionSettings,
  ICallSectionSettings,
  IPutSectionSettings,
  IConversionSectionSettings,
  IDividendProtectionSectionSettings,
  IMakeWholeSectionSettings,
  ITakeoverProtectionSectionSettings,
  OfferAndCompensation} from '@lv-convertible-bond/models';
import { getDefaultAccretionSectionSettings, getDefaultCallSectionSettings, getDefaultConversionSectionSettings,
  getDefaultCouponSectionSettings, getDefaultDividendProtectionSectionSettings, getDefaultGeneralSectionSettings,
  getDefaultMakeWholeSectionSettings, getDefaultPutSectionSettings,
  getDefaultTakeoverProtectionSectionSettings} from '@lv-convertible-bond/models/terms-settings/default-terms-settings';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { TermsSettingsService } from '@lv-custom-instruments/services/terms-settings/terms-settings.service';

/**
 * Terms settings component.
 */
@Component({
  selector: 'lv-terms-settings',
  templateUrl: './lv-terms-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvTermsSettingsComponent implements OnInit {

  get systemMakeWholeOfferAndCompensation(): OfferAndCompensation {
    return {
      offerValueType: this.settings.systemDefaults.makeWholeSettings.settings.ratchetMatrixOfferValueType,
      compensationValueType: this.settings.systemDefaults.makeWholeSettings.settings.ratchetMatrixCompensationValueType
    };
  }

  get myMakeWholeOfferAndCompensation(): OfferAndCompensation {
    return {
      offerValueType: this.settings.myDefaults.makeWholeSettings.settings.ratchetMatrixOfferValueType,
      compensationValueType: this.settings.myDefaults.makeWholeSettings.settings.ratchetMatrixCompensationValueType
    };
  }

  get systemTakeOverOfferAndCompensation(): OfferAndCompensation {
    return {
      offerValueType: this.settings.systemDefaults.takeoverProtectionSettings.settings.ratchetMatrixOfferValueType,
      compensationValueType: this.settings.systemDefaults.takeoverProtectionSettings.settings.ratchetMatrixCompensationValueType
    };
  }

  get myTakeOverOfferAndCompensation(): OfferAndCompensation {
    return {
      offerValueType: this.settings.myDefaults.takeoverProtectionSettings.settings.ratchetMatrixOfferValueType,
      compensationValueType: this.settings.myDefaults.takeoverProtectionSettings.settings.ratchetMatrixCompensationValueType
    };
  }

  @Output() didCancel: EventEmitter<void>;
  @Output() didSave: EventEmitter<void>;

  settings: ITermsSectionSettings;

  constructor(private _errorService: LvErrorService,
              private _termsSettingsService: TermsSettingsService,
              private _changeDetectorRef: ChangeDetectorRef) {
    this.didCancel = new EventEmitter<void>();
    this.didSave = new EventEmitter<void>();
    this.settings = {

      systemDefaults: {
        generalSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultGeneralSectionSettings()
        } as IBaseTermsSettings<IGeneralSectionSettings>,
        couponSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultCouponSectionSettings()
        } as IBaseTermsSettings<ICouponSectionSettings>,
        accretionSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultAccretionSectionSettings()
        } as IBaseTermsSettings<IAccretionSectionSettings>,
        callSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultCallSectionSettings()
        } as IBaseTermsSettings<ICallSectionSettings>,
        putSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultPutSectionSettings()
        } as IBaseTermsSettings<IPutSectionSettings>,
        conversionSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultConversionSectionSettings()
        } as IBaseTermsSettings<IConversionSectionSettings>,
        dividendProtectionSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultDividendProtectionSectionSettings()
        } as IBaseTermsSettings<IDividendProtectionSectionSettings>,
        makeWholeSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultMakeWholeSectionSettings()
        } as IBaseTermsSettings<IMakeWholeSectionSettings>,
        takeoverProtectionSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultTakeoverProtectionSectionSettings()
        } as IBaseTermsSettings<ITakeoverProtectionSectionSettings>
      } as ITermsSettings,
      myDefaults: {
        generalSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultGeneralSectionSettings()
        } as IBaseTermsSettings<IGeneralSectionSettings>,
        couponSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultCouponSectionSettings()
        } as IBaseTermsSettings<ICouponSectionSettings>,
        accretionSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultAccretionSectionSettings()
        } as IBaseTermsSettings<IAccretionSectionSettings>,
        callSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultCallSectionSettings()
        } as IBaseTermsSettings<ICallSectionSettings>,
        putSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultPutSectionSettings()
        } as IBaseTermsSettings<IPutSectionSettings>,
        conversionSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultConversionSectionSettings()
        } as IBaseTermsSettings<IConversionSectionSettings>,
        dividendProtectionSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultDividendProtectionSectionSettings()
        } as IBaseTermsSettings<IDividendProtectionSectionSettings>,
        makeWholeSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultMakeWholeSectionSettings()
        } as IBaseTermsSettings<IMakeWholeSectionSettings>,
        takeoverProtectionSettings: {
          overrideSystemDefaults: false,
          settings: getDefaultTakeoverProtectionSectionSettings()
        } as IBaseTermsSettings<ITakeoverProtectionSectionSettings>
      } as ITermsSettings
    };
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-terms-settings')
  get isLvTermsSettingsComponent() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.getTermsSettings();
  }

  /**
   * Gets terms settings.
   */
  async getTermsSettings() {
    try {
      this.settings = await this._termsSettingsService.getTermsSettings();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
    this.didCancel.emit();
  }

  /**
   * Occurs on save and saves term settings.
   */
  async onSave() {
    try {
      await this._termsSettingsService.saveTermsSettings(this.settings);
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1827'));
      this.didSave.emit();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
  }

  /**
   * Resets settings to default.
   */
  async onResetToDefault() {
    try {
      this.settings = await this._termsSettingsService.resetTermsSettings();
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1828'));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }
}

