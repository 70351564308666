import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Input, Output,
  EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef, Renderer2, HostListener } from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { PopupSettings } from '@progress/kendo-angular-dropdowns';
import { Align, PopupComponent } from '@progress/kendo-angular-popup';

import { LvInstrumentMonitorFilterView } from './lv-instrument-monitor-filter.view';
import { LvErrorService, ResizeHandlerService, LvPermissionService, IListener, ResizeHandler } from '@lv-core-ui/services';
import { IWatchList, IWatchListPanelDialogEvent, IQuickWatchList, IWidgetState } from '@lv-instrument-monitor/models';
import { WatchListService } from '@lv-instrument-monitor/services';
import { AuthorizationService } from '@lv-core-ui/services/authorization/authorization.service';

@Component({
  selector: 'lv-instrument-monitor-filter',
  templateUrl: './lv-instrument-monitor-filter.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvInstrumentMonitorFilterComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('secondFilterRow', { static: true }) secondFilterRowElement: ElementRef<HTMLElement>;
  @ViewChild('quickWatchList') quickWatchListElement: ElementRef<HTMLElement>;
  @ViewChild('quickWatchListMultiSelect') quickWatchListMultiSelectElement: ElementRef<HTMLElement>;
  @ViewChild(PopupComponent) quickWatchListPopupElement: PopupComponent;

  @Input() filter: IWidgetState;

  @Output() didFilterChange: EventEmitter<IWidgetState>;

  get anchorAlign(): Align {
    return {
      horizontal: 'left',
      vertical: 'bottom'
    };
  }
  get popupAlign(): Align {
    return {
      horizontal: 'left',
      vertical: 'top'
    };
  }

  get isMultiSelectWatchListEmpty(): boolean {
    return this.multiSelectWatchLists && this.multiSelectWatchLists.length === 0;
  }

  get isQuickWatchListEmpty(): boolean {
    return this.view.filter.quick.length === 0;
  }

  query: Subject<string>;
  querySubscription: Subscription;

  searchQuery: string;

  // watch list multi select
  multiSelectWatchLists: IWatchList[];
  watchListMultiSelectPopupSettings: PopupSettings;
  watchListMultiSelectIsLoading: boolean;

  // quick watch list multi select
  quickWatchListMultiSelectVisible: boolean;

  view: LvInstrumentMonitorFilterView;

  constructor(
    private authorizationService: AuthorizationService,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer2: Renderer2,
    private errorService: LvErrorService,
    private resizeHandlerService: ResizeHandlerService,
    private permissionService: LvPermissionService,
    private watchListService: WatchListService
  ) {
    this.searchQuery = null;

    this.view = new LvInstrumentMonitorFilterView(this.authorizationService, this.permissionService, this.watchListService);

    this.multiSelectWatchLists = [];
    this.watchListMultiSelectPopupSettings = {
      width: 460,
      height: 300,
      popupClass: 'lv-watch-list-multi-select'
    };
    this.watchListMultiSelectIsLoading = false;

    this.quickWatchListMultiSelectVisible = false;

    this.query = new Subject<string>();
    this.didFilterChange = new EventEmitter<IWidgetState>();
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onDocumentClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (this.quickWatchListMultiSelectVisible) {
      const clickedInside = this.quickWatchListPopupElement.container.nativeElement.contains(targetElement);
      const clickedInsideContainerEl = this.quickWatchListMultiSelectElement.nativeElement.contains(targetElement);
      if (!clickedInside && !clickedInsideContainerEl) {
        this.quickWatchListMultiSelectVisible = false;
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  ngOnInit() {
    this.querySubscription = this.query
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.view.onQueryChange(value);
        this.fireFilterChanged();
      });

    const listener = {
      breakingPoint: 10,
      previousWidth: -1,
      listener: null
    } as IListener;

    this.resizeHandlerService.listenTo(this.secondFilterRowElement, elementRect => {
      if (this.quickWatchListElement) {
        const qwlRect = this.quickWatchListElement.nativeElement.getBoundingClientRect();

        if (listener.previousWidth === -1) {
          // Break on first render
          listener.previousWidth = qwlRect.left < listener.breakingPoint ? 11 : qwlRect.left;
        }

        ResizeHandler.break(listener, qwlRect.left, (brokeBelow: boolean) => {
        if (brokeBelow) {
          this.renderer2.addClass(this.quickWatchListElement.nativeElement, 'lv-quick-watch-lists--padded-top');
        }
        else {
          this.renderer2.removeClass(this.quickWatchListElement.nativeElement, 'lv-quick-watch-lists--padded-top');
        }
      });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filter.previousValue !== changes.filter.currentValue) {
      this.doInitView();
    }
  }

  updateFilter(panelEvent: IWatchListPanelDialogEvent) {
    this.view.updateFilter(panelEvent);
    this.fireFilterChanged();

    this.changeDetectorRef.detectChanges();
  }

  onSearchQueryChange() {
    this.query.next(this.searchQuery);
  }

  // watch list multi select
  async onWatchListMultiSelectOpen() {
    this.changeDetectorRef.detectChanges();

    try {
      this.watchListMultiSelectIsLoading = true;
      this.changeDetectorRef.detectChanges();

      this.multiSelectWatchLists = [];
      this.multiSelectWatchLists = await this.view.watchListView.getView();
    }
    catch (error) {
      this.multiSelectWatchLists = [];
      this.errorService.handleError(error);
    }
    finally {
      this.watchListMultiSelectIsLoading = false;
      this.view.setQuickWatchListDictionary();
      this.changeDetectorRef.detectChanges();
    }
  }

  watchListMultiSelectItemDisabled(itemArgs: {dataItem: IWatchList, index: number}): boolean {
    return !!itemArgs.dataItem.isGroup;
  }

  onWatchListMultiSelectValueChange(selected: IWatchList[]) {
    this.view.onWatchListMultiSelect();
    this.fireFilterChanged();
  }

  // quick watch lists
  onQuickWatchListSelect(wl: IQuickWatchList, isSelected: boolean) {
    this.view.onQuickWatchListSelect(wl, isSelected);
    this.fireFilterChanged();
  }

  onQuickWatchListClose(wl: IQuickWatchList) {
    this.view.onQuickWatchListClose(wl);
    this.fireFilterChanged();
  }

  async onOpenQuickWatchListMultiSelect() {
    try {
      this.quickWatchListMultiSelectVisible = true;
      this.changeDetectorRef.detectChanges();

      await this.onWatchListMultiSelectOpen();
    }
    catch (error) {
      this.errorService.handleError(error);
    }
  }

  isQuickWatchListSelected(wl: IWatchList): boolean {
    return this.view.isQuickWatchListSelected(wl);
  }

  onChooseQuickWatchList(wl: IWatchList) {
    if (!wl.isGroup) {
      this.view.onChooseQuickWatchList(wl);
    }
  }

  onCancelQuickWatchListEdit() {
    this.quickWatchListMultiSelectVisible = false;
    this.changeDetectorRef.detectChanges();
  }

  onApplyQuickWatchListEdit() {
    this.view.onApplyQuickWatchListEdit();
    this.fireFilterChanged();
    this.quickWatchListMultiSelectVisible = false;
    this.changeDetectorRef.detectChanges();
  }

  onClearAllQuickWatchLists() {
    this.view.onClearAllQuickWatchLists();
    this.fireFilterChanged();
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
    this.resizeHandlerService.removeAllListeners(this.secondFilterRowElement);
  }

  private fireFilterChanged() {
    this.didFilterChange.next(this.view.filter);
  }

  private doInitView() {
    this.view.init(this.filter);
    this.searchQuery = this.filter ? this.filter.query : null;
    this.changeDetectorRef.detectChanges();
  }
}
