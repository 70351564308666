import { Injectable } from '@angular/core';

import { ConvertibleInfoService } from './services/convertible-info/convertible-info.service';
import { WatchListService } from './services/watch-list/watch-list.service';
import { WidgetService } from './services/widget/widget.service';
import { LookupService } from './services/lookup/lookup.service';

@Injectable()
export class InstrumentMonitorService {

  constructor(
    public convertibleInfoService: ConvertibleInfoService,
    public lookupService: LookupService,
    public watchListService: WatchListService,
    public widgetService: WidgetService,
  ) {}
}
