import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter,
  HostBinding, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IInstrumentSourceSectionSettings, InstrumentSourceType } from '@lv-analytics/models';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService } from 'src/app/leversys-core-ui/services/lv-error/lv-error.service';
// tslint:disable-next-line: max-line-length
import { ICopyAndPasteSectionSettings, IDividendsCopyAndPasteSectionSettings, IInterestRatesCopyAndPasteSectionSettings } from '../../../models/company-and-user-settings/other-settings/copy-and-paste-settings/copy-and-paste-section-settings';
import { IOtherSettings } from '../../../models/company-and-user-settings/other-settings/other-settings';
// tslint:disable-next-line: max-line-length
import { IQuantitySectionSettings } from '../../../models/company-and-user-settings/other-settings/quantity-settings/quantity-section-settings';
import { ISaveOtherSettingsRequest } from '../../../models/request/company-and-user-settings/save-other-settings-request';
import { CompanyAndUserSettingsService } from '../../../services/company-settings/company-settings.service';

/**
 * Other settings component.
 */
@Component({
  selector: 'lv-other-settings',
  templateUrl: './lv-other-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvOtherSettingsComponent implements OnInit {

  @Output() didCancel: EventEmitter<void>;
  @Output() didSave: EventEmitter<void>;

  settings: IOtherSettings;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _companyAndUserSettingsService: CompanyAndUserSettingsService
  ) {
    this.didCancel = new EventEmitter<void>();
    this.didSave = new EventEmitter<void>();

    this.settings = {
      quantitySettings: {
        systemDefaults: {
          currencyOverrides: []
        } as IQuantitySectionSettings,
        environments: []
      },
      copyAndPasteSettings: {
        systemDefaults: {
          dividends: {},
          interestRates: {}
        } as ICopyAndPasteSectionSettings,
        environments: []
      },
      instrumentSourceSettings: {
        systemDefaults: {
          instrumentSource: InstrumentSourceType.Leversys
        } as IInstrumentSourceSectionSettings,
        environments: []
      },
    } as IOtherSettings;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-model-customization-settings')
  get isLvModelCustomizationSettingsComponent() {
    return true;
  }

  @HostBinding('class.default-settings-height')
  get isDefaultSettingsHeight() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.loadSettings();
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
    this.didCancel.emit();
  }

  /**
   * If other settings are valid, save other settings and emit event thar other settings are updated
   */
  async onSave() {
    if (this.validateOtherSettings()) {
      try {
        await this._companyAndUserSettingsService.saveOtherSettings({
          settings: this.settings
        } as ISaveOtherSettingsRequest);
        this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1824'));
        this.didSave.emit();
      }
      catch (error) {
        this._errorService.handleError(error);
      }
    } else {
      this._errorService.toastrService.warning(LvDataMaster.getInfo('dM-1859'));
    }
  }

  /**
   * Resets other settings.
   */
  async onResetToDefault() {
    try {
      const response = await this._companyAndUserSettingsService.resetOtherSettings();
      this.settings = response;

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1825'));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Loads settings.
   */
  private async loadSettings() {
    try {
      const response = await this._companyAndUserSettingsService.getOtherSettings();
      this.settings = response;
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Validates other settings.
   * @returns A flag indicating if other settings are valid.
   */
  private validateOtherSettings(): boolean {
    let valid = true;

    if (!this.settings) {
      valid = false;
    }

    const quantityMyEnvironment = this.settings.quantitySettings.environments.filter(env => env.environmentCode === 'MY')[0];
    const quantitySystemDefaults = this.settings.quantitySettings.systemDefaults;

    if (quantitySystemDefaults.useQuantityValue && quantitySystemDefaults.currencyOverrides && !quantitySystemDefaults.defaultValue) {
      valid = false;
    }

    if (quantityMyEnvironment?.settings.useQuantityValue && !quantityMyEnvironment?.settings.defaultValue) {
      valid = false;
    }

    return valid;
  }

}
