import { LvRecord } from '@lv-core-ui/models';
import { LvPermissionService } from '@lv-core-ui/services';
import { AuthorizationService } from '@lv-core-ui/services/authorization/authorization.service';
import { LvVirtualGridView, LvUtil, LocalStorage } from '@lv-core-ui/util';
import { IWatchList, IWatchListInstrumentInfo, IInstrumentInfo } from '@lv-instrument-monitor/models';
import { WatchListService } from '@lv-instrument-monitor/services';
import { WatchListView } from '@lv-instrument-monitor/views';

export class LvWatchListInstrumentsView {

  get isMyInstrumentsWatchListSelected(): boolean {
    if (!this.selectedWatchList) {
      return false;
    }

    return this.selectedWatchList.current.listName === 'Custom Instruments';
  }

  watchListsQuery: string;
  availableInstrumentsQuery: string;
  selectedInstrumentsQuery: string;

  watchLists: IWatchList[];
  selectedWatchList: LvRecord<number, IWatchList>;

  selectedInstruments: LvRecord<number, IWatchListInstrumentInfo[]>;
  selectedInstrumentsDict: {
    [id: number]: IWatchListInstrumentInfo
  };

  availableGridView: LvVirtualGridView<number, IInstrumentInfo>;
  selectedGridView: LvVirtualGridView<number, IWatchListInstrumentInfo>;

  watchListView: WatchListView;

  padlockTitle = 'This Instrument is custom created and can only be seen by company user';

  constructor(
    private authorizationService: AuthorizationService,
    private permissionService: LvPermissionService,
    private watchListService: WatchListService
  ) {
    this.watchLists = [];
    this.selectedWatchList = null;
    this.selectedInstruments = null;
    this.selectedInstrumentsDict = {};

    this.watchListView = new WatchListView(this.authorizationService, this.permissionService, this.watchListService);

    this.availableGridView = new LvVirtualGridView('id');
    this.selectedGridView = new LvVirtualGridView('id');

    this.availableGridView.setFilterFn(a => {
      let condition = true;

      if (this.availableInstrumentsQuery) {
        const name = (a.name || '').toLowerCase();
        condition = name.indexOf(this.availableInstrumentsQuery.toLowerCase()) !== -1;
      }

      if (this.selectedInstruments && this.selectedInstruments.current.length > 0) {
        condition = condition && !this.selectedInstrumentsDict[a.id];
      }

      return condition;
    });

    this.selectedGridView.setFilterFn(a => {
      if (!this.selectedInstrumentsQuery) {
        return true;
      }

      const name = (a.name || '').toLowerCase();
      return name.indexOf(this.selectedInstrumentsQuery.toLowerCase()) !== -1;
    });
  }

  isWatchListSelected(wl: IWatchList) {
    if (!this.selectedWatchList) {
      return false;
    }

    return this.selectedWatchList.id === wl.id;
  }

  async setSelectedWatchList(wl: IWatchList) {
    try {
      this.selectedWatchList = this.getDefaultWatchListRecord(wl);

      await this.getWatchListInstruments(this.selectedWatchList.current);

      this.selectedGridView.initView(this.selectedInstruments.current);
      this.availableGridView.reloadView();
    }
    catch (error) {
      throw error;
    }
  }

  addWatchList() {
    this.selectedWatchList = this.getDefaultWatchListRecord();
    this.selectedInstruments = this.getDefaultSelectedInstrumentsRecord();

    this.selectedGridView.initView(this.selectedInstruments.current);
  }

  async deleteWatchList() {
    try {
      await this.watchListService.deleteUserWatchList(this.selectedWatchList.current);

      this.watchLists = this.watchLists.filter(a => a.id !== this.selectedWatchList.id);
      this.selectedWatchList = null;
    }
    catch (error) {
      throw error;
    }
  }

  async saveWatchList() {
    try {
      let wl: IWatchList = null;

      if (this.selectedWatchList.isPhantom) {
        wl = await this.watchListService.createUserWatchList(this.selectedWatchList.current.listName);
        this.watchLists.push(wl);
        // this.watchLists.sort(LvUtil.sortBy('listName', false));
      }
      else {
        wl = await this.watchListService.updateUserWatchList(this.selectedWatchList.current);
        const index = this.watchLists.findIndex(a => a.id === this.selectedWatchList.id);
        this.watchLists[index] = {
          ...wl
        };
      }

      this.selectedWatchList = this.getDefaultWatchListRecord(wl);

      await this.getWatchListInstruments(this.selectedWatchList.current);
      this.watchListsQuery = null;
    }
    catch (error) {
      throw error;
    }
  }

  cancelWatchListEdit() {
    this.selectedWatchList.current = {
      ...this.selectedWatchList.original
    };
  }

  watchListsTrackBy(index: number, item: IWatchList) {
    return item.id;
  }

  addInstrumentToSelectedGridView(instrumentInfo: IInstrumentInfo) {
    if (!this.selectedWatchList || this.selectedWatchList.id < 0) {
      return;
    }
    if (!this.selectedInstrumentsDict[instrumentInfo.id]) {
      this.selectedInstruments.current.push({
        ...instrumentInfo,
        userWatchListId: this.selectedWatchList.id,
        ownerId: this.selectedWatchList.original.ownerId,
        subjectId: this.selectedWatchList.original.subjectId
      });

      this.selectedGridView.initView(this.selectedInstruments.current);
      this.selectedInstrumentsDict = LvUtil.toDictionary(this.selectedInstruments.current, 'id');

      this.selectedGridView.setSelectedIdentifiers([instrumentInfo.id]);
    }
  }

  removeInstrumentFromSelectedGridView(instrumentInfo: IWatchListInstrumentInfo) {
    this.selectedInstruments.current = this.selectedInstruments.current.filter(a => a.id !== instrumentInfo.id);
    this.selectedGridView.initView(this.selectedInstruments.current);
    this.selectedInstrumentsDict = LvUtil.toDictionary(this.selectedInstruments.current, 'id');

    this.availableGridView.setSelectedIdentifiers([instrumentInfo.id]);
  }

  async updateWatchListInstruments() {
    try {
      await this.watchListService.updateUserWatchListInstruments(this.selectedWatchList.id, this.selectedInstruments.current);
    }
    catch (error) {
      throw error;
    }
  }

  private async getWatchListInstruments(wl: IWatchList) {
    try {
      const result = await this.watchListService.getUserWatchListInstruments(wl.id);
      this.selectedInstruments = this.getDefaultSelectedInstrumentsRecord(wl, result.records);
    }
    catch (error) {
      throw error;
    }
  }

  private getDefaultSelectedInstrumentsRecord(
      wl?: IWatchList,
      instrumentInfos?: IWatchListInstrumentInfo[]
    ): LvRecord<number, IWatchListInstrumentInfo[]> {
      const iis = instrumentInfos || [];

      this.selectedInstrumentsDict = LvUtil.toDictionary(iis, 'id');

      return new LvRecord({
        id: wl ? wl.id : -1,
        idField: 'id',
        isPhantom: false,
        current: [...iis],
        original: [...iis]
      });
  }

  private getDefaultWatchListRecord(wl?: IWatchList): LvRecord<number, IWatchList> {
    const user = LocalStorage.getJwtUser();

    return new LvRecord({
      id: wl ? wl.id : -1,
      isPhantom: !wl,
      idField: 'id',
      current: wl ? { ...wl } : {
        id: -1,
        isFavourite: false,
        isGroup: false,
        isShared: false,
        isSystemAll: false,
        isSystemNewIssue: false,
        listName: 'New watchlist',
        ownerId: user.UserId.toString(),
        subjectId: user.SubjectId
      },
      original: wl ? { ...wl } : {
        listName: 'New watchlist'
      } as any
    });
  }
}
