import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Output,
  EventEmitter, ChangeDetectorRef, HostBinding } from '@angular/core';
import { IEstimatesSettings, IEstimatesSectionSettings, ISaveEstimatesSettingsRequest } from '@lv-analytics/models';
import { CompanyAndUserSettingsService } from '@lv-analytics/services';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { LvUtil } from '@lv-core-ui/util';

/**
 * Estimates settings component.
 */
@Component({
  selector: 'lv-estimates-settings',
  templateUrl: './lv-estimates-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEstimatesSettingsComponent implements OnInit {

  @Output() didCancel: EventEmitter<void>;
  @Output() didSave: EventEmitter<void>;

  settings: IEstimatesSettings;

  constructor(
    private _companyAndUserSettingsService: CompanyAndUserSettingsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService
  ) {
    this.didCancel = new EventEmitter<void>();
    this.didSave = new EventEmitter<void>();

    this.settings = {
      systemDefaults: {} as IEstimatesSectionSettings,
      environments: []
    } as IEstimatesSettings;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-estimates-settings')
  get isLvEstimatesSettingsComponent() {
    return true;
  }

  @HostBinding('class.default-settings-height')
  get isDefaultSettingsHeight() {
    return true;
  }

  @HostBinding('class.lv-section-settings-estimates-section-settings-additional-margin-environment-defaults')
  get isMyDefaultTitleAdjusted() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.getSettings();
  }

  /**
   * Gets settings.
   */
  async getSettings() {
    try {
      this.settings = await this._companyAndUserSettingsService.getEstimatesSettings();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on save and saves estimate settings.
   */
  async onSave() {
    try {
      await this._companyAndUserSettingsService.saveEstimatesSettings(this.getSaveRequest());

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1813'));
      this.didSave.emit();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.markForCheck();
    }
  }

  /**
   * Occurs on reset to default and resets estimates settings.
   */
  async onResetToDefault() {
    try {
      this.settings = await this._companyAndUserSettingsService.resetEstimatesSettings();
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1815'));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
    this.didCancel.emit();
  }

  /**
   * Gets save request.
   * @returns ISaveEstimatesSettingsRequest object.
   */
  private getSaveRequest(): ISaveEstimatesSettingsRequest {
    return LvUtil.jsonParse(LvUtil.jsonStringify(this.settings)) as ISaveEstimatesSettingsRequest;
  }
}
