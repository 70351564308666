export enum ConvertibleBondTermsEvent {
  InstrumentTypeUpdated = 'InstrumentTypeUpdated',
  CCYUpdated = 'CCYUpdated',
  PriceTalkUpdated = 'PriceTalkUpdated',
  DeltaNeutralConversionAmountUpdated = 'DeltaNeutralConversionAmountUpdated',
  InstrumentStatusUpdated = 'InstrumentStatusUpdated',
  ConversionRatioOrRebateUpdated = 'ConversionRatioOrRebateUpdated',
  HigherOrLowerStrikeUpdated = 'HigherOrLowerStrikeUpdated',
  FixedFXUpdated = 'FixedFXUpdated',
  OtherDataUpdated = 'OtherDataUpdated',
  TermsSettingsLoaded = 'TermsSettingsLoaded',
  RebateCCYUpdated = 'RebateCCYUpdated',
  PriceAsParUpdated = 'PriceAsParUpdated',
  ExchangeableTypeUpdated = 'ExchangeableTypeUpdated'
}
