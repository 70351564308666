import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpClientBase2 } from '@lv-core-ui/api';
import { IDENTITY_SERVER_BASE_URL, LvSecurityManagementError } from '@lv-security/models';
import { LvSharedPermissionsService } from '@lv-shared/services/shared-permissions-service';
import { IPermissionsRequest } from '@lv-security/models/permissions-request';

/**
 * LvPermission service.
 */
@Injectable({
  providedIn: 'root'
})
export class LvPermissionsService
  extends HttpClientBase2
  implements LvSharedPermissionsService {

  constructor(
    http: HttpClient,
    @Inject(IDENTITY_SERVER_BASE_URL) baseUrl: string
  ) {
    super(http, `${baseUrl}`);
  }

  /**
   * Checks for permission for resource
   * @param module Module name
   * @param action Action name
   * @param lwsIdenitifier LWS identifier
   * @returns Flag indicating whether or not there is permission
   */
  async hasResourcePermission(module: string, action: string, lwsIdenitifier: string): Promise<boolean> {
    try {
      return await this.getAsync<boolean>({
        module: module,
        action: action,
        resourceId: lwsIdenitifier
      }, '/permissions/resource');
    }
    catch (error) {
      throw this.handleError(error, e => new LvSecurityManagementError(e.message));
    }
  }

  /**
   * Checks for permission in list of permissions
   * @param permission Name of permission
   * @returns Flag indicating whether or not there is permission
   */
  async hasPermission(permission: string): Promise<boolean> {
    try {
      const permissions = permission.split(',');
      const request = [];

      permissions.forEach(onePermission => {
        const items = onePermission.split(':');
        request.push({
          module: items[0],
          action: items[1],
          resourceId: items[2] ? items[2] : ''
        });
      });

      const res = await this.postAsync<boolean>(request, '/permissions/');
      return res;
    }
    catch (error) {
      return new Promise<boolean>(resolve => resolve(false));
    }
  }
}
