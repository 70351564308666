import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvErrorService } from '../lv-error/lv-error.service';
@Injectable({
  providedIn: 'root',
})
export class LvDataMasterService extends HttpClientBase {
  private fileVersion: string;

  constructor(
    http: HttpClient,
    private _errorService: LvErrorService
  ) {
    super(http, '/dataMaster', 'v1');
    this.fileVersion = 'fileVersion';
  }

  /**
   * Get file version from API
   */
  async getFileVersion(): Promise<void> {
    this.fileVersion = await this.getAsync<any>(null, '/fileVersion');
  }

  /**
   * Get file version from API
   */
  async getAnonymousFileVersion(): Promise<void> {
    this.fileVersion = await this.getAsync<any>(
      null,
      '/anonymousFileVersion',
      true
    );
  }

  /**
   * fetch data fromAPI and save data to in browser database
   * @returns data from from specific table on in-browser database
   */
  async fetchTooltipsFromAPI(): Promise<any> {
    return this.getDataFromApi('tooltips');
  }

  /**
   * fetch errors fromAPI and save data to in browser database
   * @returns errors from specific table on in-browser database
   */
  async fetchErrorsFromAPI(): Promise<any> {
    return this.getDataFromApi('errors');
  }

  /**
   * fetch login errors fromAPI and save data to in browser database
   * @returns login errors from specific table on in-browser database
   */
  async fetchLoginErrorsFromAPI(): Promise<any> {
    return this.getDataFromApi('loginErrors');
  }

  /**
   * fetch admin errors fromAPI and save data to in browser database
   * @returns admin errors from specific table on in-browser database
   */
  async fetchAdminErrorsFromAPI(): Promise<any> {
    return this.getDataFromApi('adminErrors');
  }

  /**
   * fetch warnings fromAPI and save data to in browser database
   * @returns warnings from specific table on in-browser database
   */
  async fetchWarningsFromAPI(): Promise<any> {
    return this.getDataFromApi('warnings');
  }

  /**
   * fetch infos fromAPI and save data to in browser database
   * @returns infos from specific table on in-browser database
   */
  async fetchInfosFromAPI(): Promise<any> {
    return this.getDataFromApi('infos');
  }

  /**
   * fetch login infos fromAPI and save data to in browser database
   * @returns login infos from specific table on in-browser database
   */
  async fetchLoginInfosFromAPI(): Promise<any> {
    return this.getDataFromApi('loginInfos');
  }

  /**
   * fetch admin infos fromAPI and save data to in browser database
   * @returns admin infos from specific table on in-browser database
   */
  async fetchAdminInfosFromAPI(): Promise<any> {
    return this.getDataFromApi('adminInfos');
  }

  /**
   * fetch admin warnings fromAPI and save data to in browser database
   * @returns admin warnings from specific table on in-browser database
   */
  async fetchAdminWarningsFromAPI(): Promise<any> {
    return this.getDataFromApi('adminWarnings');
  }

  private async getDataFromApi(tableName: string, excludeAuthenticationHeaders: boolean = false): Promise<any> {
    try {
      return await this.getAsync<any>(null, `/${tableName}`, excludeAuthenticationHeaders);
    } catch (err) {
      this._errorService.handleError(err.message);
    }
  }
}
