import * as moment from 'moment';

export class LvLocalization {
  public static getBrowserLocale(): string {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return 'en-US';
    }

    return window.navigator.language || window.navigator['browserLanguage'];
  }

  public static getLocalDateFormat(): string {
    return this.getLocalizedDateFormat('L');
  }

  public static getLocalDateTimeFormat(): string {
    return this.getLocalizedDateFormat('LLL');
  }

  private static getLocalizedDateFormat(momentFormat: moment.LongDateFormatKey): string {
    moment.locale(LvLocalization.getBrowserLocale());
    const dateFormat = moment.localeData().longDateFormat(momentFormat);
    return dateFormat;
  }
}
