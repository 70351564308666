import { Injectable } from '@angular/core';
import { ILvHubClient, LvHub } from '@lv-core-ui/util';

import { Observable, Subject } from 'rxjs';

import { IConvertibleTermsUpdatedEvent } from './events/convertible-terms-updated-event';

@Injectable()
export class ReferenceDataHubService implements ILvHubClient {

  private _convertibleTermsUpdatedEvent: Subject<IConvertibleTermsUpdatedEvent>;

  constructor() {
    this._convertibleTermsUpdatedEvent = new Subject<IConvertibleTermsUpdatedEvent>();
  }

  get convertibleTermsUpdatedEvent(): Observable<IConvertibleTermsUpdatedEvent> {
    return this._convertibleTermsUpdatedEvent;
  }

  attachEvents(hub: LvHub): void {
    hub.subscribeOn('convertibleTermsUpdatedEvent', this._convertibleTermsUpdatedEvent);
  }
}
