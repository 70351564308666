<div data-cy="DM-355" class="lv-convertible-bond-terms-general-fx-information">
  <lv-flex-box  direction="row">
    <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="GeneralFXRateTooltipTemplate"
        filter="span:not(lv-xl-label span)">
        <span [attr.data-tooltip-id]="'fixedFXRateSourceTooltip'">
          Fixed FX Rate
        </span>
        <lv-xl-label [field]="'FIXED_FX'"></lv-xl-label>
      </div>
      <lv-numeric-text-box class="lv-input-field"
        [ngModel]="stockReferenceSignalStore.fx()"
        (ngModelChange)="doEmitFxRate($event)"
        name="fixed-fx-rate">
      </lv-numeric-text-box>
  </lv-flex-box>
</div>

<ng-template #GeneralFXRateTooltipTemplate>
  <lv-tooltip dm="dM-355"></lv-tooltip>
</ng-template>

