/**
 * Types of coupon.
 */
export enum CouponType {
    ZeroCoupon = 'ZeroCoupon',
    Fixed = 'Fixed',
    Custom = 'Custom',
    Floating = 'Floating',
    FixToFloating = 'FixToFloating',
    FloatingToFix = 'FloatingToFix',
    PIK = 'PIK'
}
