import { IssueAndRedemption, SetupStatus, ExhangeableParameters, EquityData, OtherData, PriceTalk,
         StockPriceReference, ConversionData, SinkingFundScheduleItem, ConvertibleBondSubType,
         CurrencyType } from '@lv-convertible-bond/models';
import { PepsConversionBeforeMaturityType } from '@lv-convertible-bond/models/convertible-bond-terms/Enums (2)';
import { PepsConversion } from '@lv-convertible-bond/models/convertible-bond-terms/PEPSConversion';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';

export class LvTermsGeneralView {
  public issueAndRedemption: IssueAndRedemption;
  public other: OtherData;
  public priceTalk: PriceTalk;
  public stockReference: StockPriceReference;
  public conversion: ConversionData;

  constructor(
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService
  ) {
    this.issueAndRedemption = {
      sinkingFundSchedule: [],
      setupStatus: SetupStatus.NewIssue,
      exhangeableParameters: {} as ExhangeableParameters,
      underlyingEquity: {} as EquityData
    } as IssueAndRedemption;

    this.init(this.issueAndRedemption, {} as OtherData, {} as PriceTalk, {} as StockPriceReference, {} as ConversionData);
  }

  // tslint:disable-next-line:max-line-length
  init(issueAndRedemption?: IssueAndRedemption, other?: OtherData, priceTalk?: PriceTalk, stockReference?: StockPriceReference, conversion?: ConversionData) {

    this.issueAndRedemption = issueAndRedemption;
    this.other = other;

    if (priceTalk) {
      this.priceTalk = priceTalk;
    }
    else if (!!this._lvConvertibleBondTermsService.convertibleBondNewIssueDocument?.priceTalk) {
      this.priceTalk = this._lvConvertibleBondTermsService.convertibleBondNewIssueDocument.priceTalk;
    }
    else {
      this.priceTalk = {} as PriceTalk;
      this.priceTalk.issuePriceBest = 100;
      this.priceTalk.issuePriceWorst = 100;
      this.priceTalk.redemptionValueBest = 100;
      this.priceTalk.redemptionValueWorst = 100;
    }

    this.stockReference = stockReference;
    this.conversion = conversion;
  }

  doSetSchedule(schedule: SinkingFundScheduleItem[]) {
    this.issueAndRedemption.sinkingFundSchedule = schedule;
  }

  doSetGeneralInformation(generalInformation: IssueAndRedemption) {
    this.issueAndRedemption = {
      ...generalInformation,
      fixedFXRate: this.issueAndRedemption.fixedFXRate,
      sinkingFundSchedule: this.issueAndRedemption.sinkingFundSchedule
    };

    if (this.issueAndRedemption.subType === ConvertibleBondSubType.PEPS && !this.conversion.pepsConversion) {
      this.conversion.pepsConversion = new PepsConversion();
      this.conversion.pepsConversion.conversionBeforeMaturityType = PepsConversionBeforeMaturityType.MinimumRatio;
      this.conversion.pepsConversion.conversionBeforeMaturityType = PepsConversionBeforeMaturityType.MinimumRatio;
      this.conversion.pepsConversion.conversionRebateCurrency = CurrencyType.Convertible;
    }
  }
}
