import { Component, ViewEncapsulation, ChangeDetectionStrategy, OnChanges, Input, ChangeDetectorRef } from '@angular/core';
import { NumberPipe } from '@progress/kendo-angular-intl';
import { LvLocalDatePipe } from '@lv-core-ui/pipes';
import { LvScenarioOneDimPriceTalkChartView } from './lv-scenario-one-dim-price-talk-chart.view';
import { ILvScenarioReport } from '../../lv-scenario.view';
import { LvScenarioChart } from '../lv-scenario-chart';
import { ScenarioPriceTalk } from '@lv-analytics/models';

/**
 * Scenario one dim priceTalkChartComponent
 */
@Component({
  selector: 'lv-scenario-one-dim-price-talk-chart',
  templateUrl: './lv-scenario-one-dim-price-talk-chart.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvScenarioOneDimPriceTalkChartComponent extends LvScenarioChart implements OnChanges {

  @Input() report: ILvScenarioReport;

  view: LvScenarioOneDimPriceTalkChartView;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    numberPipe: NumberPipe,
    lvDatePipe: LvLocalDatePipe
  ) {
    super(changeDetectorRef);

    this.view = new LvScenarioOneDimPriceTalkChartView(numberPipe, lvDatePipe);
    this.view.init();
  }

  /**
   * Does view initialization.
   */
  ngOnChanges() {
    this.view.init(this.report);
  }

  /**
   * Occurs on current price talk click.
   * @param selected Scenario price talk selected value.
   */
  onCurrentPriceTalkClick(selected: ScenarioPriceTalk) {
    this.view.model.currentPriceTalk = selected;
    this.changeDetectorRef.detectChanges();
  }
}
