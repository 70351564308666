<lv-flex-box class="lv-send-valuation-query-dialog">
  <div class="lv-send-valuation-query-dialog-content">
    <div class="lv-label-field important">
      Instrument ID and valuation Inputs and Outputs will be sent to Leversys Support!
    </div>

    <div class="lv-label-field">
      Add notes for Leversys Support (optional)
    </div>

    <div class="lv-notes-text-area">
      <div class="lv-input-field">
        <textarea kendoTextArea
          autocomplete="off" 
          autocorrect="off" 
          autocapitalize="off" 
          spellcheck="false"
          class="k-textarea notes-text-area"
          placeholder="Notes"
          [(ngModel)]="valuationQueryNotes">
        </textarea>
      </div>
    </div>
  </div>

  <lv-flex-box class="lv-send-valuation-query-dialog-footer" direction="row">
    <button kendoButton
      [primary]="false"
      lvId="sendValuationQueryCancel"
      (click)="onCancel()">
        Cancel
    </button>
    <button kendoButton
      [primary]="true"        
      lvId="sendValuationQueryConfirm"
      (click)="onSend()">
        Send
    </button>
  </lv-flex-box>
  </lv-flex-box>