<lv-loader-panel *ngIf="isLoading"></lv-loader-panel>
<div *ngIf="continueEditingVisible"
  class="lv-continue-editing-panel">
  <div class="lv-continue-editing-panel-mask"></div>
  <div class="lv-continue-editing-panel-content">
    <div>You have unsaved changes on <span class="continue-editing-item-name">{{ continueEditingItemName }}</span>.</div>
    <div>You can continue editing <span class="continue-editing-item-name">{{ continueEditingItemName }}</span> or discard it's changes.</div>

    <div class="lv-continue-editing-panel-options">
        <button kendoButton
          look="flat"
          lvId="edit-watch-lists-continue-editing"
          [disabled]="isLoading"
          (click)="onContinueEditing(true)">Continue editing</button>
        <button kendoButton
          [primary]="true"
          lvId="edit-watch-lists-discard-changes"
          [disabled]="isLoading"
          (click)="onContinueEditing(false)">Discard changes</button>
      </div>
  </div>
</div>
<div class="lv-dialog lv-watch-list-dialog"
  tabindex="0">
  <div class="lv-dialog-content">
    <div class="lv-watch-list-panel-dialog">
      <div class="lv-watch-list-panel-dialog-header">
        <div class="lv-watch-list-panel-dialog-header-spacer"></div>
        <div class="lv-watch-list-panel-dialog-header-option">
          <input type="radio" class="k-radio"
            [attr.id]="rbWatchlistView"
            [name]="rbWatchlistView"
            [attr.name]="rbWatchlistView"
            value="watchlists"
            [checked]="showWatchlistView"
            (click)="onViewChange($event)"/>
          <label class="k-radio-label"
            lvId="edit-watch-lists-watch-list-view"
            [attr.for]="rbWatchlistView">
              Watchlist View
          </label>
        </div>
        <div class="lv-watch-list-panel-dialog-header-option">
          <input type="radio" class="k-radio"
            [attr.id]="rbInstumentView"
            [name]="rbInstumentView"
            [attr.name]="rbInstumentView"
            value="instruments"
            [checked]="!showWatchlistView"
            (click)="onViewChange($event)"/>
          <label class="k-radio-label"
            lvId="edit-watch-lists-instrument-view"
            [attr.for]="rbInstumentView">
            Instrument View
          </label>
        </div>
      </div>
      <div class="lv-watch-list-panel-dialog-content">
        <lv-watch-list-instruments *ngIf="showWatchlistView"
          [availableInstrumentInfos]="availableInstrumentInfos">
        </lv-watch-list-instruments>

        <lv-instrument-watch-lists *ngIf="!showWatchlistView"
          [availableInstrumentInfos]="availableInstrumentInfos">
        </lv-instrument-watch-lists>
      </div>
    </div>
  </div>
  <div class="lv-dialog-actions lv-watch-list-panel-dialog-actions">
    <button kendoButton
      look="flat"
      lvId="edit-watch-lists-cancel"
      [disabled]="isLoading"
      (click)="onCancel()">Cancel</button>
    <button kendoButton
      [primary]="true"
      lvId="edit-watch-lists-save"
      [disabled]="isLoading"
      (click)="onSave(false)">Save</button>
    <button kendoButton
      class="save-and-exit"
      lvId="edit-watch-lists-save-exit"
      [primary]="true"
      [disabled]="isLoading"
      (click)="onSave(true)">Save and Exit</button>
  </div>
</div>