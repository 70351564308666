import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Output, EventEmitter,
  ChangeDetectorRef, HostBinding } from '@angular/core';
import { IMarketDataSettings, IBorrowSectionSettings, IBorrowSettings, IDividendsSectionSettings, IDividendsSettings,
         IInterestRatesSectionSettings, IInterestRatesSettings, ICreditSectionSettings, ICreditSettings, IFundingSectionSettings,
         IFundingSettings, IWithholdingTaxSectionSettings, IWithholdingTaxSettings, IVolatilitySectionSettings,
         IVolatilitySettings } from '@lv-analytics/models';
import { ISystemYieldCurvesSettingsView } from '@lv-analytics/models/market-data-settings/interest-rates/system-yield-curves-settings-view';
import { CompanyAndUserSettingsService } from '@lv-analytics/services';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';

/**
 * Market data settings component.
 */
@Component({
  selector: 'lv-market-data-settings',
  templateUrl: './lv-market-data-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvMarketDataSettingsComponent implements OnInit {

  @Output() didCancel: EventEmitter<void>;
  @Output() didSave: EventEmitter<void>;

  settings: IMarketDataSettings;
  systemYieldCurvesSettingsData: ISystemYieldCurvesSettingsView;

  constructor(
    private _companyAndUserSettingsService: CompanyAndUserSettingsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService
  ) {
    this.didCancel = new EventEmitter<void>();
    this.didSave = new EventEmitter<void>();
    this.settings = {
      borrowSettings: {
        systemDefaults: {} as IBorrowSectionSettings,
        environments: []
      } as IBorrowSettings,
      dividendsSettings: {
        systemDefaults: {} as IDividendsSectionSettings,
        environments: []
      } as IDividendsSettings,
      interestRatesSettings: {
        systemDefaults: {
          yieldCurveSettings: []
        } as IInterestRatesSectionSettings,
        environments: []
      } as IInterestRatesSettings,
      creditSettings: {
        systemDefaults: {} as ICreditSectionSettings,
        environments: []
      } as ICreditSettings,
      fundingSettings: {
        systemDefaults: {} as IFundingSectionSettings,
        environments: []
      } as IFundingSettings,
      withholdingTaxSettings: {
        systemDefaults: {} as IWithholdingTaxSectionSettings,
        environments: []
      } as IWithholdingTaxSettings,
      volatilitySettings: {
        systemDefaults: {} as IVolatilitySectionSettings,
        environments: []
      } as IVolatilitySettings
    } as IMarketDataSettings;

    this.systemYieldCurvesSettingsData = {} as ISystemYieldCurvesSettingsView;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-market-data-settings')
  get isLvMarketDataSettingsComponent() {
    return true;
  }

  @HostBinding('class.default-settings-height')
  get isDefaultSettingsHeight() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.getSettings();
  }

  /**
   * Gets settings.
   */
  async getSettings() {
    try {
      this.settings = await this._companyAndUserSettingsService.getMarketDataSettings();
      this.systemYieldCurvesSettingsData = await this._companyAndUserSettingsService.getYieldCurveSettingsData();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
    this.didCancel.emit();
  }

  /**
   * Saves market data settings.
   */
  async onSave() {
    try {
      await this._companyAndUserSettingsService.saveMarketDataSettings(this.settings);
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1820'));
      this.didSave.emit();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
  }

  /**
   * Resets market data settings.
   */
  async onResetToDefault() {
    try {
      this.settings = await this._companyAndUserSettingsService.resetMarketDataSettings();
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1821'));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }
}
