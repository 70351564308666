import { Injectable, isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { loadAsync } from 'jszip';

import { HttpClientBase } from '@lv-core-ui/api';
import { IDocumentMetadata, IDocumentsResponse, LvDocumentsError } from '@lv-documents/models';
import { environment } from 'src/environments/environment';

export interface IPdf {
  fileName: string;
  data: ArrayBuffer;
}

/**
 * Metadata service responsible for managing documents.
 */
@Injectable()
export class MetadataService extends HttpClientBase {

  constructor(
    http: HttpClient
  ) {
    const resourceUrl = '/documents/metadata';
    super(http, resourceUrl);
  }

  /**
   * Gets document metadata.
   * @param lwsIdentifier LWS identifier.
   * @returns List of IDocumentMetadata objects.
   */
  async getDocumentsMetadata(lwsIdentifier: string): Promise<IDocumentMetadata[]> {
    try {
      const documents = await this.getAsync<IDocumentsResponse>(null, `/all/${lwsIdentifier}`);
      return documents.documents;
    }
    catch (error) {
      throw this.handleError(error, e => new LvDocumentsError(e.message));
    }
  }

  /**
   * Displays document.
   * @param documentId Document identifier.
   * @returns ArrayBuffer object.
   */
  async viewDocument(documentId: string): Promise<ArrayBuffer> {
    try {
      const httpResponse = await this.downloadAsync(null, `/view/${documentId}`, false, 60000);

      const pdf = await this.unZipData(httpResponse.body);

      return pdf.data;
    }
    catch (error) {
      throw this.handleError(error, e => new LvDocumentsError(e.message));
    }
  }

  /**
   * Downloads document.
   * @param documentId Document identifier.
   * @param instrumentName Instrument name.
   * @returns IPdf object.
   */
  async downloadDocument(documentId: string, instrumentName: string): Promise<IPdf> {
    try {
      // tslint:disable-next-line:max-line-length
      const httpResponse = await this.downloadAsync({ documentId: documentId, instrumentName: instrumentName}, `/download`, false, 60000);

      const pdf = await this.unZipData(httpResponse.body);

      pdf.fileName = this.getContentDispositionFileName(httpResponse);

      return pdf;
    }
    catch (error) {
      throw this.handleError(error, e => new LvDocumentsError(e.message));
    }
  }

  /**
   * Extracts compressed data.
   * @param arrayBuffer A raw buffer of binary data.
   * @returns IPdf object.
   */
  private async unZipData(arrayBuffer: ArrayBuffer): Promise<IPdf> {
    try {
      let jszip = await loadAsync(arrayBuffer);
      const fileNames = Object.keys(jszip.files);
      const pdfFileName = fileNames[0];
      const pdf = await jszip.file(pdfFileName).async('arraybuffer');
      jszip = null;

      return {
        fileName: pdfFileName,
        data: pdf
      };
    }
    catch (error) {
      throw new Error('Cannot unzip data');
    }
  }
}
