import { LvRecord } from '@lv-core-ui/models';
import { LvPermissionService } from '@lv-core-ui/services';
import { AuthorizationService } from '@lv-core-ui/services/authorization/authorization.service';
import { LvVirtualGridView, LvUtil } from '@lv-core-ui/util';
import { IInstrumentInfo, IWatchList, IInstrumentInfoWatchList } from '@lv-instrument-monitor/models';
import { WatchListService } from '@lv-instrument-monitor/services';
import { WatchListView } from '@lv-instrument-monitor/views';

export class LvInstrumentWatchListsView {
  get isSelectAllDisabled() {
    return this.watchLists.length < 1;
  }

  get isClearAllDisabled() {
    if (!this.selectedWatchLists) {
      return true;
    }
    return this.selectedWatchLists.current.length < 1;
  }

  get isSelectedInstrumentPrivate(): boolean {
    if (!this.selectedInstrumentInfo) {
      return false;
    }

    return this.selectedInstrumentInfo.isPrivateInstrument;
  }

  instrumentsGridQuery: string;
  instrumentsGridView: LvVirtualGridView<number, IInstrumentInfo>;

  watchListsQuery: string;
  watchLists: IWatchList[];
  initialWatchLists: IWatchList[];

  selectedWatchListsQuery: string;
  selectedWatchLists: LvRecord<number, IInstrumentInfoWatchList[]>;
  selectedWatchListsDict: {
    [id: number]: IInstrumentInfoWatchList
  };

  selectedInstrumentInfo: IInstrumentInfo;
  selectedWatchList: IWatchList;

  watchListView: WatchListView;

  padlockTitle = 'This Instrument is custom created and can only be seen by company user';

  constructor(
    private authorizationService: AuthorizationService,
    private permissionService: LvPermissionService,
    private watchListService: WatchListService
  ) {

    this.instrumentsGridView = new LvVirtualGridView('id');

    this.watchLists = [];
    this.initialWatchLists = [];

    this.selectedWatchLists = null;
    this.selectedWatchListsDict = {};

    this.selectedInstrumentInfo = null;
    this.selectedWatchList = null;

    this.watchListView = new WatchListView(this.authorizationService, this.permissionService, this.watchListService);

    this.instrumentsGridView.setFilterFn(a => {
      let condition = true;

      if (this.instrumentsGridQuery) {
        const name = (a.name || '').toLowerCase();
        condition = name.indexOf(this.instrumentsGridQuery.toLowerCase()) !== -1;
      }

      if (this.selectedWatchLists && this.selectedWatchLists.current.length > 0) {
        condition = condition && !this.selectedWatchListsDict[a.id];
      }

      return condition;
    });
  }

  setWatchLists() {
    this.watchLists = this.initialWatchLists.filter(a => !this.selectedWatchListsDict[a.id]);
    if (!this.isSelectedInstrumentPrivate) {
      this.watchLists = this.watchLists.filter(x => x.listName !== 'Custom Instruments');
    }
  }

  async setSelectedInstrumentInfo(instrumentInfoId: number) {
    try {
      const selectedInstrument = this.instrumentsGridView.recordsView.data.find(a => a.id === instrumentInfoId);
      if (selectedInstrument) {
        this.selectedInstrumentInfo = { ...selectedInstrument };

        const paginatedResult = await this.watchListService.getUserInstrumentWatchLists(instrumentInfoId);
        const instrumentWatchLists = paginatedResult.records;

        this.selectedWatchList = null;
        this.selectedWatchLists = this.getDefaultSelectedWatchListsRecord(instrumentInfoId, instrumentWatchLists);
        this.setWatchLists();
      }
    }
    catch (error) {
      throw error;
    }
  }

  addInstrumentToWatchList(wl: IWatchList) {
    this.addSingleInstrumentToWatchList(wl);
    this.selectedWatchListsDict = LvUtil.toDictionary(this.selectedWatchLists.current, 'id');
  }

  addInstrumentsToWatchList(wlArray: IWatchList[]) {
    wlArray.forEach(wl => {
      this.addSingleInstrumentToWatchList(wl);
    });
    this.selectedWatchListsDict = LvUtil.toDictionary(this.selectedWatchLists.current, 'id');
  }

  removeInstrumentFromWatchList(wl: IInstrumentInfoWatchList) {
    this.selectedWatchLists.current = this.selectedWatchLists.current.filter(a => a.id !== wl.id);
    this.selectedWatchListsDict = LvUtil.toDictionary(this.selectedWatchLists.current, 'id');
  }

  removeInstrumentsFromWatchList(wlArray: IInstrumentInfoWatchList[]) {
    const removedDict = LvUtil.toDictionary(wlArray, 'id');
    this.selectedWatchLists.current = this.selectedWatchLists.current.filter(a => !removedDict[a.id]);

    this.selectedWatchListsDict = LvUtil.toDictionary(this.selectedWatchLists.current, 'id');
  }

  watchListsTrackBy(index: number, item: IWatchList) {
    return item.id;
  }

  isWatchListSelected(wl: IWatchList) {
    if (!this.selectedWatchList) {
      return false;
    }
    return this.selectedWatchList.id === wl.id;
  }

  setSelectedWatchList(wl: IWatchList) {
    this.selectedWatchList = { ...wl };
  }

  async updateInstrumentWatchLists() {
    try {
      await this.watchListService.updateUserInstrumentWatchLists(this.selectedInstrumentInfo.id, this.selectedWatchLists.current);
    }
    catch (error) {
      throw error;
    }
  }

  private getDefaultSelectedWatchListsRecord(
    instrumentInfoId?: number,
    instrumentWatchLists?: IInstrumentInfoWatchList[]
  ): LvRecord<number, IInstrumentInfoWatchList[]> {
    const wls = instrumentWatchLists || [];

    this.selectedWatchListsDict = LvUtil.toDictionary(wls, 'id');

    return new LvRecord({
      id: instrumentInfoId ? instrumentInfoId : -1,
      idField: 'id',
      isPhantom: false,
      current: [...wls],
      original: [...wls]
    });
  }

  private addSingleInstrumentToWatchList(wl: IWatchList) {
    this.selectedWatchLists.current.push({
      id: wl.id,
      instrumentInfoId: this.selectedInstrumentInfo.id,
      subjectId: wl.subjectId,
      ownerId: wl.ownerId,
      listName: wl.listName
    });
  }
}
