import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ElementRef, Optional, inject, Input } from '@angular/core';
import { LvQuickTermsEntry } from './lv-quick-terms-entry.view';
import { Subscription } from 'rxjs';
import { LvConvertibleBondTermsPresenter, PricingSectionCommand } from '../lv-convertible-bond-terms.presenter';
import { filter } from 'rxjs/operators';
import { ConvertibleBondTermsSectionEvent } from '../../../models/convertible-bond-terms/custom-enum/convertible-bond-terms-section-event';
import { ConvertibleBondTermsEvent } from '../../../models/convertible-bond-terms/custom-enum/convertible-bond-terms-event';
import { PricingSectionEvent } from '../../../models/convertible-bond-terms/custom-enum/pricing-section-event';
import { PricingType, SourceType } from '../../../models/convertible-bond-terms/custom-enum/pricing-type-enum';
import { StockReferenceType } from 'src/app/modules/analytics/models/enum/stock-reference-type';
import { LvExcelService } from '@lv-excel/services';
import { ConvertibleBondSubType, StockPriceReference } from '@lv-convertible-bond/models';
import { StockReferenceSignalStore } from '../stores/lv-stock-reference.store';

@Component({
  selector: 'lv-quick-terms-entry',
  templateUrl: './lv-quick-terms-entry.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvQuickTermsEntryComponent implements OnInit, OnDestroy {

  @Input() stockReferenceSignalStore: StockReferenceSignalStore;

  public view: LvQuickTermsEntry;
  private _modelSubscription: Subscription[];

  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc?.isInitialized();
  }


  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvConvertibleBondTermsPresenter,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.view = new LvQuickTermsEntry();
  }

  ngOnInit() {
    this._modelSubscription = [
      this._presenter.onModelUpdated
        .pipe(
          filter(event => event.eventId !== ConvertibleBondTermsSectionEvent.QuickTermsEntryEvent &&
             event.eventId !== ConvertibleBondTermsSectionEvent.QuickTermsEntryLoadEvent &&
             event.eventId !== ConvertibleBondTermsSectionEvent.StockPriceReference &&
             event.eventId !== ConvertibleBondTermsSectionEvent.MWInitCallsEvent &&
             event.eventId !== ConvertibleBondTermsSectionEvent.MWInitConversionEvent)
        )
        .subscribe(model => {
          if (model.data) {
            this.view.init(model.data.fullTerms);
            this._presenter.updateQuickTermsModel(this.view.quickTerms);
            this._changeDetectorRef.detectChanges();
          }
        }),
      
      this._presenter.onCommandExecuted.subscribe(command => {
        if (command instanceof PricingSectionCommand && command.sourceType === SourceType.QuickTerms) {
          switch (command.type) {
            case PricingType.Best: {
              this.view.quickTerms.priceTalk.issueYieldBest = command.calculateValue;
              this.view.issueYieldBestOrWorstChange();
              break;
            }
            case PricingType.Worst: {
                this.view.quickTerms.priceTalk.issueYieldWorst = command.calculateValue;
                this.view.issueYieldBestOrWorstChange();
                break;
            }
            case PricingType.BestRedemption: {
              this.view.quickTerms.priceTalk.redemptionValueBest = command.calculateValue;
              this.view.redemptionBestOrWorstChange();
              break;
            }
            case PricingType.WorstRedemption: {
              this.view.quickTerms.priceTalk.redemptionValueWorst = command.calculateValue;
              this.view.redemptionBestOrWorstChange();
              break;
            }
          }

          if (this.isOpenedFromExcel) {
            // We use setTimeout to load the NewIssueAssumption section on the Pricing form (instrument not yet saved in draft)
            setTimeout(() => {
              this.onPriceTalkChange(this.isOpenedFromExcel);
            }, 500);
          }
          else {
            this.onPriceTalkChange();
          }


          this._changeDetectorRef.detectChanges();
        }
     })
    ];
    
    const presenterModel = this._presenter.getModel();

    if (presenterModel && presenterModel.fullTerms) {
      this.view.init(presenterModel.fullTerms);
      this._changeDetectorRef.detectChanges();
    }

    if (this._presenter.isModelLoaded()) {
      this.view.init(this._presenter.getModel().fullTerms);
      this._presenter.updateQuickTermsModel(this.view.quickTerms);
      this._changeDetectorRef.detectChanges();
    }
  }

  onConvertibleTypeChange() {
    this.view.setRedemptionValuesAndIssueYieldDefaultValue();
    this.view.applyConvertibleTypeFieldsVisibility();
    this.onConvertibleTypeChanged();
    this.doModelChange();
    this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.InstrumentTypeUpdated);
  }

  onSoftCallChange() {
    if (this.view.quickTerms.softCall && this.view.quickTerms.softCallTriggers) {
      this.doModelChange();
    }
  }

  onCoCoChange() {
    if (this.view.quickTerms.coCoEndDate && this.view.quickTerms.coCoTrigger) {
      this.doModelChange();
    }
  }

  onResetChange() {
    if (this.view.quickTerms.reset) {
      this.doModelChange();
    }
  }

  onMaturityDateChange() {
    this.doModelChange();

    this._presenter.publishPricingSectionUpdated({
      eventId: PricingSectionEvent.IssueYield,
      data: PricingType.Best,
      source: SourceType.QuickTerms
    });

    this._presenter.publishPricingSectionUpdated({
      eventId: PricingSectionEvent.IssueYield,
      data: PricingType.Worst,
      source: SourceType.QuickTerms
    });
  }

  onCallMWTypeChanged() {
    this.view.setVisibleFields();
    this.doModelChange();
  }

  /**
   * method fired when changed Redemption value
   */
  onRedemptionChange() {
    this.doModelChange(); 
  }

  onCouponBestChange() {
    this.doCouponBestOrWorstChange();
    this.updateIssueYield(PricingType.Best);
  }

  onCouponWorstChange() {
    this.doCouponBestOrWorstChange();
    this.updateIssueYield(PricingType.Worst);
  }

  onIssuePriceBestChange() {
    this.doIssuePriceBestOrWorstChange();
    this.updateIssueYield(PricingType.Best);
  }

  onIssuePriceWorstChange() {
    this.doIssuePriceBestOrWorstChange();
    this.updateIssueYield(PricingType.Worst);
  }

  onRedemptionBestChange() {
    this.doRedemptionBestOrWorstChange();
    this.updateIssueYield(PricingType.Best);
  }

  onRedemptionWorstChange() {
    this.doRedemptionBestOrWorstChange();
    this.updateIssueYield(PricingType.Worst);
  }

  onIssueYieldBestChange() {
    this.doIssueYieldBestOrWorstChange();
    this.updateRedemption(PricingType.BestRedemption);
  }

  onIssueYieldWorstChange() {
    this.doIssueYieldBestOrWorstChange();
    this.updateRedemption(PricingType.WorstRedemption);
  }

  doPremiumBestOrWorstChange() {
    this.view.premiumBestOrWorstChange();
    this.onPriceTalkChange();
  }

  doCouponBestOrWorstChange() {
    this.view.couponBestOrWorstChange();
    this.doModelChange(false);
  }

  doIssuePriceBestOrWorstChange() {
    this.view.issuePriceBestOrWorstChange();
    this.doModelChange(false);
  }

  doRedemptionBestOrWorstChange() {
    this.view.redemptionBestOrWorstChange();
    this.doModelChange(false);
  }

  doIssueYieldBestOrWorstChange() {
    this.view.issueYieldBestOrWorstChange();
    this.doModelChange(false);
  }

  onStrikeChange() {
    this.doModelChange();
    this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.HigherOrLowerStrikeUpdated);
  }

  onCCYChange() {
    this.doModelChange(false);
    this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.CCYUpdated);
  }

  onStatusChange() {
    this.view.setCouponValue();
    this.doModelChange();
    this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.InstrumentStatusUpdated);
  }

  onPriceTalkChange(isOpenedFromExcel: boolean = false) {
    this.doModelChange(false, isOpenedFromExcel);
    this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.PriceTalkUpdated);
  }

  /**
   * On stock reference change.
   * @param value New stock reference value.
   */
  onStockRefChange(value: number): void {
    this.stockReferenceSignalStore.setStockRef(value)
    this.onStockPriceReferenceChange();
  }

  /**
   * On stock reference equity change.
   * @param value New stock reference equity value.
   */
  onStockRefEquityChange(value: number): void {
    this.stockReferenceSignalStore.setStockRefEquity(value)
    this.onStockPriceReferenceChange();
  }

  /**
   * On stock reference fx change.
   * @param value New stock reference fx value.
   */
  onStockRefFxChange(value: number): void {
    this.stockReferenceSignalStore.setFx(value);
    this.onStockPriceReferenceChange();
  }

  /**
   * On one of stock reference fields change.
   */
  onStockPriceReferenceChange() {
    if (!this.view.quickTerms.stockPriceReference) {
      this.view.quickTerms.stockPriceReference = {} as StockPriceReference;
    }

    if (this.view.quickTerms.fixedStockRef) {
      this.view.quickTerms.stockPriceReference.referenceType = StockReferenceType.Fixed;

      if (this._presenter.getModel()?.quickTerms.currency !== this._presenter.getModel()?.quickTerms.underlyingCurrency) {
        this.view.quickTerms.stockPriceReference.fixedStockRef = this.stockReferenceSignalStore.stockRefEquity();
        this.view.quickTerms.stockPriceReference.fixedFXRef = this.stockReferenceSignalStore.fx();
        this._presenter.updateFixedFx(this.stockReferenceSignalStore.fx());
      }
      else {
        this.view.quickTerms.stockPriceReference.fixedStockRef = this.stockReferenceSignalStore.stockRef();
      }
    }
    else {
      this.view.quickTerms.stockPriceReference.referenceType = StockReferenceType.NotFixed;
    }

    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.StockPriceReference,
      data: this.view.quickTerms.stockPriceReference
    }, false);

    this.onPriceTalkChange();
  }

  updateIssueYield(type: PricingType) {
    this._presenter.publishPricingSectionUpdated({
      eventId: PricingSectionEvent.IssueYield,
      data: type,
      source: SourceType.QuickTerms
    });
  }

  updateRedemption(type: PricingType) {
    this._presenter.publishPricingSectionUpdated({
      eventId: PricingSectionEvent.Redemption,
      data: type,
      source: SourceType.QuickTerms
    });
  }

  doSetupStatusChanged() {
    this.view.setPremiumLabelText();
    this.view.setCouponValue();
    this.doModelChange();
  }

  doModelChange(publishExternalEvent: boolean = true, isOpenedFromExcel: boolean = false) {
    this.view.setPremiumLabelText();
    this.view.setRedemptionValuesAndIssueYield();
     
    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.QuickTermsEntryEvent,
      data: this.view.quickTerms
    }, publishExternalEvent, isOpenedFromExcel);
  }

  /**
   * On Exchangeable Change method
   */
  onExchangeableChange() {
    this.doModelChange();
    this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.ExchangeableTypeUpdated);
  }

  /**
   * method checks which convertible bond subtype is selected and reset quick terms fields accordingly
   */
  onConvertibleTypeChanged() {
    if (this.view.quickTerms.convertibleType !==  ConvertibleBondSubType.Regular) {
       this.view.quickTerms.coCoEndDate = null;
       this.view.quickTerms.coCoTrigger = null;
       this.view.quickTerms.reset = null;
       this.view.quickTerms.resetFloor = null;
       this.view.quickTerms.resetMultiplier = null;
       this.view.quickTerms.hyperIncrementalFactor = null;
       this.view.quickTerms.hyperStrike = null;
    }
  }

  ngOnDestroy() {
    this._modelSubscription.forEach(a => a.unsubscribe());
  }

  getQuickTermsTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
}
