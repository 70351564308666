

import { RunningCocoTriggerPeriodData } from './RunningCocoTriggerPeriodData';
import { ContingentConversionScheduleItem } from './ContingentConversionScheduleItem';
import { QuarterType, ContingentConversionPeriodType, ContingentConversionTriggerParityLimit, ContingentConversionScheduleTriggerType } from './Enums (2)';

export class ContingentConversion {
    
    lastCocoTriggerSatisfied: boolean;
    convertibleOnCall: boolean;
    convPriceTrigger: number;
    quarterType: QuarterType;
    triggerPeriodType: ContingentConversionPeriodType;
    triggerPeriodDays: number;
    outOfDays: number;
    buyoutAtCoCoTrigger: boolean;
    runningTriggerPeriod: RunningCocoTriggerPeriodData;
    schedule: ContingentConversionScheduleItem[];
    cbPriceTriggerParityLimit: ContingentConversionTriggerParityLimit;
}
