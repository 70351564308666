<lv-flex-box class="lv-interest-rates">
  <lv-environment-settings
    [section]="interestRatesSection"
    lvId="marketDataInterestRatesEnvironmentSettings"
    (didChangeEnvironment)="onChangeEnvironment($event)">
  </lv-environment-settings>

  <lv-flex-box class="lv-interest-rates-content lv-market-data-section-content" direction="row">
    <lv-flex-box class="lv-interest-rates-currency-section">
      <div *ngIf="isCrossFx" class="lv-label lv-label-field lv-label--title">Instrument CCY ({{ cbCurrency }})</div>
      <lv-flex-box data-cy="DM-553" direction="row">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="interestRatesSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.interest-rates-tooltip-id]="'interestRatesTooltip'">
            Interest Rate Source
          </span>
          <lv-xl-label [field]="'IR_CURVE_TYPE_INST'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <kendo-dropdownlist
            name="cbInstrumentInterestRatesSource"
            valueField="id"
            textField="text"
            [data]="view.instrumentInterestRatesSource.items"
            [disabled]="!instrumentLoaded"
            [valuePrimitive]="true"
            [(ngModel)]="view.interestRatesSettings.instrumentInterestRateSource"
            (ngModelChange)="onInstrumnentInterestRateSourceChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>

      <lv-flex-box *ngIf="view.isInstrumentInterestRateSourceFlat"
        data-cy="DM-551"
        direction="row">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="interestRatesFlatRateTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.interest-rates-tooltip-id]="'flatRateTooltip'">
            Flat Rate
          </span>
          <lv-xl-label [field]="'IR_FLAT_INST'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box name="instrumentFlatRate"
            [format]="numberFormatPercentage"
            [decimals]="numberOfDecimalsPercentage"
            [(ngModel)]="view.interestRatesSettings.instrumentFlatRate"
            (didBlur)="onInstrumnentInterestRateSourceChange()"
            (didKeyDownEnter)="onInstrumnentInterestRateSourceChange()"
            required>
          </lv-numeric-text-box>
          <span class="lv-sufix-label">%</span>
        </div>
      </lv-flex-box>

      <lv-flex-box
        direction="row"
        *ngIf="!view.isInstrumentInterestRateSourceFlat"
        data-cy="DM-554">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="interestRatesYieldCurveTooltipTemplate"
          filter="span">
          <span [attr.interest-rates-tooltip-id]="'yieldCurveTooltip'">
            Yield Curve
          </span>
          <lv-xl-label [field]="'IR_YC_INST'"></lv-xl-label>
        </div>
        <div class="lv-input-field lv-input-field--auto">
          <kendo-dropdownlist
            class="lv-interest-rates-currency-section-yield-curve"
            name="cbInstrumentYieldCurve"
            valueField="id"
            textField="name"
            [data]="view.instrumentYieldCurve"
            [disabled]="!instrumentLoaded"
            [valuePrimitive]="true"
            [popupSettings]="{ width: 210 }"
            [(ngModel)]="view.interestRatesSettings.yieldCurveId"
            (ngModelChange)="onInstrumentYieldCurveChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>

      <lv-flex-box
        *ngIf="!view.isInstrumentInterestRateSourceFlat && instrumentLoaded"
        data-cy="DM-561">
        <lv-yield-curve-terms-structure #instrumentYieldCurveTerms
          [settings]="view.instrumentYieldCurveSettings"
          [yieldCurveScheduledItems]="instrumentYieldCurveScheduledItems"
          [interestRatesCopyAndPasteSettings]="interestRatesCopyAndPasteSettings"
          (yieldCurveScheduleChange)="onInterestRatesSectionChange()"
          (isLoadingStateChange)="setLoadingState($event)"
          [hasOverrideFromExcel]="hasScheduleInExcelOverride"
          [isFieldFromExcelEnabled]="isScheduleInExcelEditable"
          [excelFieldAlias]="scheduleInExcelOverrideAlias"
          [lvId]="'lv-instrument-yield-curve-term-structure'">
        </lv-yield-curve-terms-structure>
      </lv-flex-box>
    </lv-flex-box>

    <lv-flex-box *ngIf="isCrossFx"
      class="lv-interest-rates-currency-section">
      <div class="lv-label lv-label-field lv-label--title">Underlying CCY ({{ eqCurrency }})</div>
      <lv-flex-box data-cy="DM-555" direction="row">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="interestRatesSourceUndTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.interest-rates-tooltip-id]="'interestRatesTooltip'">
            Interest Rate Source
          </span>
          <lv-xl-label [field]="'IR_CURVE_TYPE_UND_INST'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <kendo-dropdownlist
            name="cbUnderlyingInterestRatesSource"
            valueField="id"
            textField="text"
            [disabled]="!instrumentLoaded"
            [data]="view.underlyingInterestRatesSource.items"
            [valuePrimitive]="true"
            [(ngModel)]="view.interestRatesSettings.underlyingInterestRateSource"
            (ngModelChange)="onUnderlyingInterestRateSourceChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>

      <lv-flex-box *ngIf="view.isUnderlyingInterestRateSourceFlat">
        <lv-flex-box data-cy="DM-552" direction="row">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="interestRatesFlatRateUndTooltipTemplate"
            filter="span:not(lv-xl-label span)">
            <span [attr.interest-rates-tooltip-id]="'interestRateFlatRateTooltip'">
              Flat Rate
            </span>
            <lv-xl-label [field]="'IR_FLAT_UND_INST'"></lv-xl-label>
          </div>
          <div class="lv-input-field">
            <lv-numeric-text-box name="underlyingFlatRate"
              [format]="numberFormatPercentage"
              [decimals]="numberOfDecimalsPercentage"
              [(ngModel)]="view.interestRatesSettings.underlyingFlatRate"
              (didBlur)="onUnderlyingInterestRateSourceChange()"
              (didKeyDownEnter)="onUnderlyingInterestRateSourceChange()">
            </lv-numeric-text-box>
            <span class="lv-sufix-label">%</span>
          </div>
        </lv-flex-box>
      </lv-flex-box>

      <lv-flex-box *ngIf="!view.isUnderlyingInterestRateSourceFlat">
        <lv-flex-box direction="row" data-cy="DM-556">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="interestRatesYieldCurveUndTooltipTemplate"
            filter="span">
            <span [attr.interest-rates-tooltip-id]="'yieldCurveTooltip'">
              Yield Curve
            </span>
            <lv-xl-label [field]="'IR_YC_UND_INST'"></lv-xl-label>
          </div>
          <div class="lv-input-field lv-input-field--auto">
            <kendo-dropdownlist
              class="lv-interest-rates-currency-section-yield-curve"
              name="cbUnderlyingYieldCurve"
              valueField="id"
              textField="name"
              [data]="view.underlyingYieldCurve"
              [valuePrimitive]="true"
              [popupSettings]="{ width: 210 }"
              [(ngModel)]="view.interestRatesSettings.underlyingYieldCurveId"
              (ngModelChange)="onUnderlyingYieldCurveChange()">
            </kendo-dropdownlist>
          </div>
        </lv-flex-box>
      </lv-flex-box>

      <lv-flex-box *ngIf="!view.isUnderlyingInterestRateSourceFlat" data-cy="DM-557">
        <lv-yield-curve-terms-structure #underlyingYieldCurveTerms
          [settings]="view.underlyingYieldCurveSettings"
          [yieldCurveScheduledItems]="underlyingYieldCurveScheduledItems"
          [interestRatesCopyAndPasteSettings]="interestRatesCopyAndPasteSettings"
          (yieldCurveScheduleChange)="onInterestRatesSectionChange()"
          (isLoadingStateChange)="setLoadingState($event)"
          [hasOverrideFromExcel]="hasScheduleInExcelOverrideUnderlying"
          [isFieldFromExcelEnabled]="isScheduleInExcelEditableUnderlying"
          [excelFieldAlias]="scheduleInExcelOverrideUnderlyingAlias"
          [lvId]="'lv-underlying-yield-curve-term-structure'">
        </lv-yield-curve-terms-structure>
      </lv-flex-box>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<ng-template #interestRatesSourceTooltipTemplate>
  <lv-tooltip dm="dM-553"></lv-tooltip>
</ng-template>
<ng-template #interestRatesSourceUndTooltipTemplate>
  <lv-tooltip dm="dM-555"></lv-tooltip>
</ng-template>
<ng-template #interestRatesFlatRateTooltipTemplate>
  <lv-tooltip dm="dM-551"></lv-tooltip>
</ng-template>
<ng-template #interestRatesFlatRateUndTooltipTemplate>
  <lv-tooltip dm="dM-552"></lv-tooltip>
</ng-template>
<ng-template #interestRatesYieldCurveTooltipTemplate>
  <lv-tooltip dm="dM-554"></lv-tooltip>
</ng-template>
<ng-template #interestRatesYieldCurveUndTooltipTemplate>
  <lv-tooltip dm="dM-556"></lv-tooltip>
</ng-template>
