import { Injectable } from '@angular/core';
import { ApplicationSettingsService } from '@lv-application-settings/application-settings.service';
import { ILvGlLayoutConfiguration, ILvGlComponent } from '@lv-golden-layout/models';
import { LvGoldenLayoutStorage } from '@lv-golden-layout/util/lv-golden-layout-storage';

@Injectable()
export class LayoutStateStorageService implements LvGoldenLayoutStorage {

  constructor(
    private _appSettingsService: ApplicationSettingsService
  ) {}

  getLayoutConfiguration(configurationId: string): ILvGlLayoutConfiguration {
    const layoutState = this._appSettingsService.getGoldenLayoutState(configurationId);
    return layoutState;
  }

  getComponents(configurationId: string): ILvGlComponent[] {
    const cmps = this._appSettingsService
      .getWorkspaceWidgets(configurationId)
      .map(a => ({
        id: a.id,
        configurationId: a.workspaceId,
        componentName: a.widgetType,
        state: { ...a.state }
      } as ILvGlComponent));

    return cmps;
  }

  addComponent(component: ILvGlComponent): void {
    this._appSettingsService.addWidget({
      id: component.id,
      workspaceId: component.configurationId,
      widgetType: component.componentName,
      state: { ...component.state }
    });
  }

  removeComponent(componentId: string): void {
    this._appSettingsService.removeWidget(componentId);
  }
}
