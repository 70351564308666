import { CouponType } from '../convertible-bond-terms/CouponType';
import { Frequency } from '../convertible-bond-terms/Frequency';
import { CallMakeWholeType } from '../convertible-bond-terms/Enums (2)';
// tslint:disable-next-line:max-line-length
import { DividendProtectionType, DividendThreshold } from '../convertible-bond-terms/Enums (4)';
import { ConvertibleBondSubType } from '../convertible-bond-terms/ConvertibleBondSubType';
import { SetupStatusQuickTerms } from '../convertible-bond-terms/enum-descriptions/SetupStatusQuickTerms';
import { PriceTalk } from '../convertible-bond-terms/PriceTalk';
import { StockPriceReference } from '../convertible-bond-terms/StockPriceReference';

export class QuickTerms {
  constructor() {
    this.stockPriceReference = new StockPriceReference();
  }

  issueName: string;
  currency: string;
  convertibleType: ConvertibleBondSubType;
  underlyingCurrency: string;
  maturityDateYears: number;
  perpetual: boolean;
  setupStatus: SetupStatusQuickTerms;
  termsFixed: boolean;
  puts: string;
  putPrice: string;
  callProtection: string;
  softCall: string;
  softCallTriggers: string;
  callMakeWholeType: CallMakeWholeType;
  callMakeWholeSpread: number;
  reset: string;
  resetFloor: string;
  resetMultiplier: string;
  hyperIncrementalFactor: number;
  hyperStrike: number;
  conversionPrice: number;
  coupon: number | string;
  redemption: number;
  coCoEndDate: string;
  coCoTrigger: string;
  exchangeable: boolean;
  pledgedShares: number;
  dividendProtection: DividendProtectionType;
  dividendTresholdValue: number | string;
  dividendTresholdType: DividendThreshold;
  priceTalk: PriceTalk;
  tresholdFrequency: Frequency;
  tresholdGrowth: number;
  fixedStockRef: boolean;
  stockRef: number;
  stockPriceReference: StockPriceReference;
  lowerStrike: number;
  higherStrike: number;
  conversionRatio: number;
  conversionRatioCap: number;

  couponType: CouponType;
  couponFrequency: Frequency;
  useDividendProtection: boolean;
}
