<lv-flex-box class="lv-model-customization-section-column">
  <lv-model-type-section-settings
    [settings]="settings.creditModelSettings"
    [overrideSystemDefaults]="overrideSystemDefaults">
  </lv-model-type-section-settings>
</lv-flex-box>

<lv-flex-box class="lv-model-customization-section-column second">
  <div class="lv-model-customization-section-column-include-terms">
    <div class="lv-label lv-label-field lv-label--title">
      INCLUDE TERMS
    </div>

    <lv-flex-box
      class="lv-input-field"
      direction="row"
      data-cy="DM-1448">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="forwardConvAveragingSettingsTooltipTemplate"
        filter="div">
          Forward Conv. Averaging
      </div>
      <div class="lv-field lv-field--checkbox">
        <input type="checkbox"
          name="modelCustomizationIncludeTermsForwardConvAveraging"
          class="k-checkbox"
          [disabled]="!overrideSystemDefaults"
          [attr.id]="getCheckBoxId('forward-conversion-averaging')"
          [(ngModel)]="settings.forwardConversionAveraging"/>
        <label class="k-checkbox-label"
          [attr.for]="getCheckBoxId('forward-conversion-averaging')"></label>
      </div>
    </lv-flex-box>

    <lv-flex-box
      class="lv-input-field"
      direction="row"
      data-cy="DM-1449">

      <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="forwardResetAveragingSettingsTooltipTemplate"
          filter="div">
            Forward Reset Averaging
      </div>
      <div class="lv-field lv-field--checkbox">
        <input type="checkbox"
          name="modelCustomizationIncludeTermsForwardResetAveraging"
          class="k-checkbox"
          [disabled]="!overrideSystemDefaults"
          [attr.id]="getCheckBoxId('forward-reset-averaging')"
          [(ngModel)]="settings.forwardResetAveraging"/>
        <label class="k-checkbox-label"
          [attr.for]="getCheckBoxId('forward-reset-averaging')"></label>
      </div>
    </lv-flex-box>
  </div>

  <lv-call-adjustment-section-settings
    [settings]="settings.callAdjustmentModelSettings"
    [overrideSystemDefaults]="overrideSystemDefaults">
  </lv-call-adjustment-section-settings>
</lv-flex-box>

<lv-flex-box class="lv-model-customization-section-column third">
  <div class="lv-label lv-label-field lv-label--title">
    Other Parameters
  </div>
  <lv-flex-box
    direction="row"
    data-cy="DM-1454">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="dividendProtectionModelSettingsTemplateTemplate"
      filter="span">
      <span> Dividend Protection Model </span>
    </div>

    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="modelCustomizationSettingsDividendProtectionModel"
        valueField="id"
        textField="text"
        [data]="dividendProtectionModelEnum.items"
        [valuePrimitive]="true"
        [(ngModel)]="settings.dividendProtectionModel"
        [disabled]="!overrideSystemDefaults"
        [popupSettings]="{ width: 155 }">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box
    direction="row"
    data-cy="DM-1455">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="pepsParityConventionSettingsTooltipTemplate"
      filter="span">
      <span> PEPS Parity Convention </span>
    </div>

    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="modelCustomizationSettingsPEPSParityConvention"
        valueField="id"
        textField="text"
        [data]="pepsParityConvention.items"
        [valuePrimitive]="true"
        [(ngModel)]="settings.pepsParityConvention"
        [disabled]="!overrideSystemDefaults"
        [popupSettings]="{ width: 174 }">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box
    class="lv-input-field"
    direction="row"
    data-cy="DM-1456">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="expectedLifeExclDefaultSettingsTooltipTemplate"
      filter="div">
        Expected Life Excl. Default
    </div>
    <div class="lv-field lv-field--checkbox">
      <input type="checkbox"
        class="k-checkbox"
        [disabled]="!overrideSystemDefaults"
        name="modelCustomizationSettingsExpectedLifeExclDefault"
        [attr.id]="getCheckBoxId('expected-life-excl-default')"
        [(ngModel)]="settings.expLifeExcludesDefault"/>
      <label class="k-checkbox-label"
        [attr.for]="getCheckBoxId('expected-life-excl-default')"></label>
    </div>
  </lv-flex-box>

  <lv-flex-box
    class="lv-input-field"
    direction="row"
    data-cy="DM-1457">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="exchangeableAsConvertSettingsTooltipTemplate"
      filter="div">
        Exchangeable as Convert.
    </div>
    <div class="lv-field lv-field--checkbox">
      <input type="checkbox"
        class="k-checkbox"
        [disabled]="!overrideSystemDefaults"
        name="modelCustomizationSettingsExchangeableAsConvert"
        [attr.id]="getCheckBoxId('exchangeable-convertible')"
        [(ngModel)]="settings.priceExchangeableAsConvertible"/>
      <label class="k-checkbox-label"
        [attr.for]="getCheckBoxId('exchangeable-convertible')"></label>
    </div>
  </lv-flex-box>

  <lv-flex-box
    class="lv-input-field"
    direction="row"
    data-cy="DM-1458">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="defaultOnLowParitySettingsTooltipTemplate"
      filter="div">
        Default on Low Parity
    </div>
    <div class="lv-field lv-field--checkbox">
      <input type="checkbox"
        class="k-checkbox"
        [disabled]="!overrideSystemDefaults"
        name="modelCustomizationSettingsDefaultOnLowParity"
        [attr.id]="getCheckBoxId('default-low-parity')"
        [(ngModel)]="settings.defaultOnLowParity"/>
      <label class="k-checkbox-label"
        [attr.for]="getCheckBoxId('default-low-parity')"></label>
    </div>
  </lv-flex-box>

  <lv-flex-box
    class="lv-input-field"
    direction="row"
    data-cy="DM-1459">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="inclCashRebateInParitySettingsTooltipTemplate"
      filter="div">
        Incl. Cash Rebate in Parity
    </div>
    <div class="lv-field lv-field--checkbox">
      <input type="checkbox"
        class="k-checkbox"
        [disabled]="!overrideSystemDefaults"
        name="modelCustomizationSettingsInclCashRebateInParity"
        [attr.id]="getCheckBoxId('cash-rebate-parity')"
        [(ngModel)]="settings.includeCashRebateInParity"/>
      <label class="k-checkbox-label"
        [attr.for]="getCheckBoxId('cash-rebate-parity')"></label>
    </div>
  </lv-flex-box>

  <lv-flex-box
    class="lv-input-field"
    direction="row"
    data-cy="DM-1460">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="fwdDividProtectionAdjstSettingsTooltipTemplate"
      filter="div">
      Fwd. Divid. Protection Adjst.
    </div>
    <div class="lv-field lv-field--checkbox">
      <input type="checkbox"
        class="k-checkbox"
        [disabled]="!overrideSystemDefaults"
        name="modelCustomizationSettingsFwdDividProtectionAdjst."
        [attr.id]="getCheckBoxId('fwd-divid-protect')"
        [(ngModel)]="settings.fwdDividProtectionAdjst"/>
      <label class="k-checkbox-label"
        [attr.for]="getCheckBoxId('fwd-divid-protect')"></label>
    </div>
  </lv-flex-box>

  <lv-flex-box
    class="lv-input-field"
    direction="row"
    data-cy="DM-1461">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="assumeDvdUntilMaturitySettingsTooltipTemplate"
      filter="div">
        Assume Dvd Until Maturity
    </div>
    <div class="lv-field lv-field--checkbox">
      <input type="checkbox"
        class="k-checkbox"
        [disabled]="!overrideSystemDefaults"
        name="modelCustomizationSettingsAssumeDvdUntilMaturity"
        [attr.id]="getCheckBoxId('assume-dvd-until-maturity')"
        [(ngModel)]="settings.assumeDividendYieldUntilMaturity"/>
      <label class="k-checkbox-label"
        [attr.for]="getCheckBoxId('assume-dvd-until-maturity')"></label>
    </div>
</lv-flex-box>

<ng-template #forwardConvAveragingSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1448"></lv-tooltip>
</ng-template>
<ng-template #forwardResetAveragingSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1449"></lv-tooltip>
</ng-template>
<ng-template #dividendProtectionModelSettingsTemplateTemplate>
  <lv-tooltip dm="dM-1454"></lv-tooltip>
</ng-template>
<ng-template #pepsParityConventionSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1455"></lv-tooltip>
</ng-template>
<ng-template #expectedLifeExclDefaultSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1456"></lv-tooltip>
</ng-template>
<ng-template #exchangeableAsConvertSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1457"></lv-tooltip>
</ng-template>
<ng-template #defaultOnLowParitySettingsTooltipTemplate>
  <lv-tooltip dm="dM-1458"></lv-tooltip>
</ng-template>
<ng-template #inclCashRebateInParitySettingsTooltipTemplate>
  <lv-tooltip dm="dM-1459"></lv-tooltip>
</ng-template>
<ng-template #fwdDividProtectionAdjstSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1460"></lv-tooltip>
</ng-template>
<ng-template #assumeDvdUntilMaturitySettingsTooltipTemplate>
  <lv-tooltip dm="dM-1461"></lv-tooltip>
</ng-template>
