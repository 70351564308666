export enum Permissions {
  MODULE = 'INSTRUMENT_MONITOR',
  ALL = 'ALL',

  ALL_INSTRUMENTS = 'ALL_INSTRUMENTS',
  NEW_ISSUE = 'NEW_ISSUE',
  INSTRUMENT_SELECT = 'INSTRUMENT_SELECT',
  INSTRUMENT_MONITOR_VIEW = 'INSTRUMENT_MONITOR_VIEW',
  MANAGE_WATCH_LISTS = 'MANAGE_WATCH_LISTS'
}
