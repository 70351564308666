

import { CallTriggerType } from './CallTriggerType';
import { CurrencyType } from './CurrencyType';
import { CallTriggerPeriodType } from './Enums (1)';

export class SoftCallData {

    triggerType: CallTriggerType;
    currencyOfTrigger: CurrencyType;
    triggerPeriodType: CallTriggerPeriodType;
    triggerPeriodDays: number;
    outOfDays: number;
    hasTriggerPeriodActive: boolean;
    currentStockAverage: number;
    daysAboveTrigger: number;
    runningPeriodDays: number;
    callTriggerBasedOnFullExchgProperty: boolean;
}

