export enum ConvertibleBondTermsSectionEvent {
    ModelLoadedEvent = 'ModelLoadedEvent',
    TermsGeneralEvent = 'TermsGeneralEvent',
    TermsGeneralDateEvent = 'TermsGeneralDateEvent',
    CallsEvent = 'CallsEvent',
    MWCallsEvent = 'MWCallsEvent',
    MWInitCallsEvent = 'MWInitCallsEvent',
    PutsEvent = 'PutsEvent',
    MakeWholeEvent = 'MakeWholeEvent',
    CouponEvent = 'CouponEvent',
    AccretionEvent = 'AccretionEvent',
    ConversionEvent = 'ConversionEvent',
    DividendsProtectionEvent = 'DividendsProtection',
    TakeoverProtectionEvent = 'TakeoverProtectionEvent',
    CleanUpCallEvent = 'CleanUpCallEvent',
    CallIssueAndRedemptionEvent = 'CallIssueAndRedemptionEvent',
    PutIssueAndRedemptionEvent = 'PutIssueAndRedemptionEvent',
    MakeWholeConversionEvent = 'MakeWholeConversionEvent',
    QuickTermsEntryEvent = 'QuickTermsEntryEvent',
    OtherGeneralEvent = 'OtherGeneralEvent',
    PriceTalkEvent = 'PriceTalkEvent',
    StockPriceReference = 'StockPriceReference',
    DividendProtectionIssueAndRedemption = 'DividendProtectionIssueAndRedemption',
    MWConversionEvent = 'MWConversionEvent',
    MWInitConversionEvent = 'MWInitConversionEvent',
    MWTakeoverProtectionEvent = 'MWTakeoverProtectionEvent',
    QuickTermsEntryLoadEvent = 'QuickTermsEntryLoadEvent',
    NominalChangedEvent = 'NominalChangedEvent',
    CouponPriceTalk = 'CouponPriceTalk',
    IdentifiersEvent = 'IdentifiersEvent',
    CouponDaysOrFrequencyChangedEvent = 'CouponDaysOrFrequencyChangedEvent',
    UpdatePriceTalk = 'UpdatePriceTalk',
    UpdateStockPriceReference = 'UpdateStockPriceReference',
    SetupStatusChanged = 'SetupStatusChanged',
    TakeOverCallMWEvent = 'TakeOverCallMWEvent'
}
