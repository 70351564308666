<div class="lv-instrument-monitor-filter">
  <div class="lv-instrument-monitor-filter-row">
    <lv-text-box class="lv-instrument-monitor-filter-search"
      name="instrumentMonitorSearch"
      placeholder="Search by Name or ISIN or Underlying or Lev ID"
      [searchIconVisible]="true"
      [(ngModel)]="searchQuery"
      (ngModelChange)="onSearchQueryChange()">
    </lv-text-box>
  </div>
  <div #secondFilterRow
    class="lv-instrument-monitor-filter-row">
    <kendo-multiselect
      class="lv-watch-list-multi-select"
      [class.not-empty]="!view.quickWatchListsVisible"
      [data]="multiSelectWatchLists"
      textField="listName"
      valueField="id"
      placeholder="Select Watchlist"
      [autoClose]="false"
      [popupSettings]="watchListMultiSelectPopupSettings"
      [listHeight]="300"
      [itemDisabled]="watchListMultiSelectItemDisabled"
      (open)="onWatchListMultiSelectOpen()"
      [(ngModel)]="view.filter.selected"
      (valueChange)="onWatchListMultiSelectValueChange($event)">
        <ng-template kendoMultiSelectItemTemplate let-dataItem>
          <div [class.lv-watch-list-item]="!dataItem.isGroup"
            [class.lv-watch-list-group]="dataItem.isGroup">
            {{ dataItem.listName }}
          </div>
        </ng-template>
        <ng-template kendoMultiSelectNoDataTemplate>
          <lv-dropdown-loader [isLoading]="watchListMultiSelectIsLoading"></lv-dropdown-loader>
        </ng-template>
    </kendo-multiselect>

    <div #quickWatchList
      *ngIf="view.quickWatchListsVisible"
      class="lv-quick-watch-lists">
      <div *ngFor="let quick of view.filter.quick"
        class="lv-quick-watch-list">
        <button kendoButton
          [toggleable]="true"
          [selected]="quick.isSelected"
          (selectedChange)="onQuickWatchListSelect(quick, $event)">
            {{ quick.listName }}
        </button>
        <div class="lv-quick-watch-list-close"
          (click)="onQuickWatchListClose(quick)">
          <i class="k-icon k-i-close"></i>
        </div>
      </div>
      <div class="lv-quick-watch-lists-options">
        <div #quickWatchListMultiSelect
          class="lv-icon-square lv-icon-square--round"
          [class.lv-icon-square--disabled]="false"
          title="Add quick watchlist"
          (click)="onOpenQuickWatchListMultiSelect()">
          <i class="k-icon k-i-add"></i>
        </div>
        <kendo-popup
          *ngIf="quickWatchListMultiSelectVisible"
          [anchor]="quickWatchListMultiSelect"
          [anchorAlign]="anchorAlign"
          [popupAlign]="popupAlign"
          popupClass="lv-quick-watch-lists-popup">
          <div class="lv-quick-watch-lists-popup-container">
            <ng-container *ngIf="!isMultiSelectWatchListEmpty">
              <div *ngFor="let wl of multiSelectWatchLists"
                [class.lv-watch-list-item]="!wl.isGroup"
                [class.lv-watch-list-group]="wl.isGroup"
                [class.lv-watch-list-item--selected]="isQuickWatchListSelected(wl)"
                (click)="onChooseQuickWatchList(wl)">
                {{ wl.listName }}
              </div>
            </ng-container>
            <div *ngIf="isMultiSelectWatchListEmpty"
              class="k-nodata">
              <lv-dropdown-loader [isLoading]="watchListMultiSelectIsLoading"></lv-dropdown-loader>
            </div>
            <div class="lv-quick-watch-lists-popup-options">
              <button kendoButton
                look="flat"
                (click)="onCancelQuickWatchListEdit()">
                Cancel
              </button>
              <button kendoButton
                *ngIf="!isMultiSelectWatchListEmpty"
                [primary]="true"
                (click)="onApplyQuickWatchListEdit()">
                Apply
              </button>
            </div>
          </div>
        </kendo-popup>

        <div *ngIf="!isQuickWatchListEmpty"
          class="lv-icon-square lv-icon-square--inverted"
          [class.lv-icon-square--disabled]="false"
          title="Clear all quick watchlists"
          (click)="onClearAllQuickWatchLists()">
          <i class="k-icon k-i-cancel"></i>
        </div>
      </div>
    </div>
  </div>
</div>
