<div class="lv-dialog lv-tools-custom-selection-dialog">
  <lv-flex-box class="lv-dialog-content">

    <!-- DELTA NEUTRAL -->
    <lv-flex-box direction="row" class="lv-custom-tools-selection-section">
      <div class="lv-label lv-label-field lv-label--title">DELTA NEUTRAL</div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="deltaNeutralId"
          name="deltaNeutralCheckBox"
          [(ngModel)]="toolsState.deltaNeutralState.isDeltaNeutral"
          (ngModelChange)="onDeltaNeutralCheckChange()" />
          <label class="k-checkbox-label"
            lvId="deltaNeutralCheckBox"
            [attr.for]="deltaNeutralId"></label>
      </div>
    </lv-flex-box>

    <!-- Convertible and Stock Values -->
    <lv-flex-box direction="row">
      <div class="lv-label lv-label-field">Convertible and Stock Values</div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="convertibleAndStockValuesId"
          name="convertibleAndStockValuesCheckBox"
          [(ngModel)]="toolsState.deltaNeutralState.isConvertibleAndStockValues"
          (ngModelChange)="onCheckChange()" />
          <label class="k-checkbox-label"
            lvId="convertibleAndStockValuesCheckBox"
            [attr.for]="convertibleAndStockValuesId"></label>
      </div>
    </lv-flex-box>

    <!-- FX Delta -->
    <lv-flex-box direction="row">
      <div class="lv-label lv-label-field">FX Delta</div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="fxDeltaId"
          name="fxDeltaCheckBox"
          [(ngModel)]="toolsState.deltaNeutralState.isFxDelta"
          (ngModelChange)="onCheckChange()" />
          <label class="k-checkbox-label"
            lvId="fxDeltaCheckBox"
            [attr.for]="fxDeltaId"></label>
      </div>
    </lv-flex-box>

    <!-- Stock EQ CCY and Cross FX -->
    <lv-flex-box direction="row">
      <div class="lv-label lv-label-field">Stock EQ CCY and Cross FX</div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="stockEQCcyAndCrossFxDnId"
          name="stockEQCcyAndCrossFxDnCheckBox"
          [(ngModel)]="toolsState.deltaNeutralState.isStockEQCcyAndCrossFxDn"
          (ngModelChange)="onCheckChange()" />
          <label class="k-checkbox-label"
            lvId="stockEQCcyAndCrossFxDnCheckBox"
            [attr.for]="stockEQCcyAndCrossFxDnId"></label>
      </div>
    </lv-flex-box>

    <!-- Actual Delta -->
    <lv-flex-box direction="row">
      <div class="lv-label lv-label-field">Actual Delta</div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="actualDeltaId"
          name="actualDeltaCheckBox"
          [(ngModel)]="toolsState.deltaNeutralState.isActualDelta"
          (ngModelChange)="onCheckChange()" />
          <label class="k-checkbox-label"
            lvId="actualDeltaCheckBox"
            [attr.for]="actualDeltaId"></label>
      </div>
    </lv-flex-box>

    <!-- P&L -->
    <lv-flex-box direction="row">
      <div class="lv-label lv-label-field">P&L</div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="pnlDnId"
          name="pnlDnCheckBox"
          [(ngModel)]="toolsState.deltaNeutralState.isPnLDn"
          (ngModelChange)="onCheckChange()" />
          <label class="k-checkbox-label"
            lvId="pnlDnCheckBox"
            [attr.for]="pnlDnId"></label>
      </div>
    </lv-flex-box>

    <!-- DIFFERENT DELTA EXECUTION -->
    <lv-flex-box direction="row" class="lv-custom-tools-selection-section">
      <div class="lv-label lv-label-field lv-label--title">DIFFERENT DELTA EXECUTION</div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="differentDeltaExecutionId"
          name="differentDeltaExecutionCheckBox"
          [(ngModel)]="toolsState.differentDeltaExecutionState.isDifferentDeltaExecution"
          (ngModelChange)="onDifferentDeltaExecutionCheckChange()" />
          <label class="k-checkbox-label"
            lvId="differentDeltaExecutionCheckBox"
            [attr.for]="differentDeltaExecutionId"></label>
      </div>
    </lv-flex-box>

    <!-- Convertible Value -->
    <lv-flex-box direction="row">
      <div class="lv-label lv-label-field">Convertible Value</div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="convertibleValueId"
          name="convertibleValueCheckBox"
          [(ngModel)]="toolsState.differentDeltaExecutionState.isConvertibleValue"
          (ngModelChange)="onCheckChange()" />
          <label class="k-checkbox-label"
            lvId="convertibleValueCheckBox"
            [attr.for]="convertibleValueId"></label>
      </div>
    </lv-flex-box>

    <!-- Stock EQ CCY and Cross FX -->
    <lv-flex-box direction="row">
      <div class="lv-label lv-label-field">Stock EQ CCY and Cross FX</div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="stockEQCcyAndCrossFxDdeId"
          name="stockEQCcyAndCrossFxDdnCheckBox"
          [(ngModel)]="toolsState.differentDeltaExecutionState.isStockEQCcyAndCrossFxFDde"
          (ngModelChange)="onCheckChange()" />
          <label class="k-checkbox-label"
            lvId="stockEQCcyAndCrossFxDdeCheckBox"
            [attr.for]="stockEQCcyAndCrossFxDdeId"></label>
      </div>
    </lv-flex-box>

    <!-- Price Changes -->
    <lv-flex-box direction="row">
      <div class="lv-label lv-label-field">Price Changes</div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="priceChangesId"
          name="priceChangesCheckBox"
          [(ngModel)]="toolsState.differentDeltaExecutionState.isPriceChanges"
          (ngModelChange)="onCheckChange()" />
          <label class="k-checkbox-label"
            lvId="priceChangesCheckBox"
            [attr.for]="priceChangesId"></label>
      </div>
    </lv-flex-box>

    <!-- Commision -->
    <lv-flex-box direction="row">
      <div class="lv-label lv-label-field">Commision</div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="commisionId"
          name="commisionCheckBox"
          [(ngModel)]="toolsState.differentDeltaExecutionState.isCommission"
          (ngModelChange)="onCheckChange()" />
          <label class="k-checkbox-label"
            lvId="commisionCheckBox"
            [attr.for]="commisionId"></label>
      </div>
    </lv-flex-box>

    <!-- P&L -->
    <lv-flex-box direction="row">
      <div class="lv-label lv-label-field">P&L</div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="pnlDdeId"
          name="pnlDdnCheckBox"
          [(ngModel)]="toolsState.differentDeltaExecutionState.isPnLDde"
          (ngModelChange)="onCheckChange()" />
          <label class="k-checkbox-label"
            lvId="pnlDdeCheckBox"
            [attr.for]="pnlDdeId"></label>
      </div>
    </lv-flex-box>

    <!-- BREAK EVEN -->
    <lv-flex-box direction="row" class="lv-custom-tools-selection-section">
      <div class="lv-label lv-label-field lv-label--title">BREAKEVEN</div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="breakEvenId"
          name="breakEvenCheckBox"
          [(ngModel)]="toolsState.breakEvenState.isBreakeven"
          (ngModelChange)="onBreakEvenCheckChange()" />
          <label class="k-checkbox-label"
            lvId="breakEvenCheckBox"
            [attr.for]="breakEvenId"></label>
      </div>
    </lv-flex-box>

    <!-- At Parity -->
    <lv-flex-box direction="row">
      <div class="lv-label lv-label-field">At Parity</div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="atParityId"
          name="atParityCheckBox"
          [(ngModel)]="toolsState.breakEvenState.isAtParity"
          (ngModelChange)="onCheckChange()" />
          <label class="k-checkbox-label"
            lvId="atParityCheckBox"
            [attr.for]="atParityId"></label>
      </div>
    </lv-flex-box>

    <!-- At Par -->
    <lv-flex-box direction="row">
      <div class="lv-label lv-label-field">At Par</div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="atParId"
          name="atParCheckBox"
          [(ngModel)]="toolsState.breakEvenState.isAtPar"
          (ngModelChange)="onCheckChange()" />
          <label class="k-checkbox-label"
            lvId="atParCheckBox"
            [attr.for]="atParId"></label>
      </div>
    </lv-flex-box>

    <!-- At 0 Stock Price -->
    <lv-flex-box direction="row">
      <div class="lv-label lv-label-field">At 0 Stock Price</div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="atZeroStockPriceId"
          name="atZeroStockPriceCheckBox"
          [(ngModel)]="toolsState.breakEvenState.isAtZeroStockPrice"
          (ngModelChange)="onCheckChange()" />
          <label class="k-checkbox-label"
            lvId="atZeroStockPriceCheckBox"
            [attr.for]="atZeroStockPriceId"></label>
      </div>
    </lv-flex-box>

  </lv-flex-box>

  <div class="lv-dialog-actions">
    <button kendoButton
      [disabled]="isLoading"
      lvId="toolsCustomSelectionCancel"
      (click)="onCancel()">Cancel</button>
    <button kendoButton
      [primary]="true"
      [disabled]="isLoading"
      lvId="toolsCustomSelectionSave"
      (click)="onSave()">Save</button>
  </div>
</div>
