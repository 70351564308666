<lv-advanced-grid
  data-cy="DM-982"
  [columns]="columns"
  [records]="stepUpCouponScheduleItems"
  [createFormGroup]="createFormGroup"
  [parseFn]="parseFn"
  sortBy="endDate"
  [showXlLabel]="hasScheduleInExcelOverride"
  [excelFieldAlias]="excelFieldAlias"
  [isFieldFromExcelEnabled]="isFieldFromExcelEnabled"
  lvId = "fixed-stepup-coupon-schedule"
  class="lv-fixed-stepup-coupon-schedule">
</lv-advanced-grid>
