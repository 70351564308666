import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LvNotificationsSettingsComponent } from './components/lv-notifications-settings/lv-notifications-settings.component';
import { FormsModule } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LvMailNotificationsSettingsComponent } from './components/lv-mail-notifications-settings/lv-mail-notifications-settings.component';
import { LvLwsEventComponent } from './components/lv-mail-notifications-settings/lv-lws-event/lv-lws-event.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { UserSettingsService } from './services/notifications-settings/user-settings.service';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { LeversysCoreUIModule } from '@lv-core-ui/leversys-core-ui.module';

/**
 * Notifications module
 */
@NgModule({
  declarations: [
    LvNotificationsSettingsComponent,
    LvMailNotificationsSettingsComponent,
    LvLwsEventComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LeversysCoreUIModule,
    DropDownsModule,
    LayoutModule,
    ButtonsModule,
    DateInputsModule,
    TooltipModule
  ],
  exports: [
    LvNotificationsSettingsComponent
  ],
  providers: [
    UserSettingsService
  ]
})
export class NotificationsModule { }
