export {};

declare global {
  interface String {
    toTitleCase(): string;
  }
}

String.prototype.toTitleCase =  function(): string {
  return this.toLowerCase().replace(/\b\w/g, (c: string) => c.toUpperCase());
};
