import { v4 } from 'uuid';
import { CouponType } from '@lv-analytics/models';
import { Put, IssueAndRedemption, Coupon, PutScheduleItem, PutValueType } from '@lv-convertible-bond/models';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';

export class LvPutsView {

  public model: Put;
  public issueAndRedemption: IssueAndRedemption;
  couponType: CouponType;
  puttableId: string;

  constructor(
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService
  ) {
    this.model = {
      valueType: PutValueType.AccretedValue,
      scheduleItems: []
    } as Put;
    
    this.init(this.model, {} as IssueAndRedemption, {} as Coupon);
    this.puttableId = v4();
  }

  init(model?: Put, issueAndRedemption?: IssueAndRedemption, coupon?: Coupon, isFromExcel = false) {
    this.model = model ?? this._lvConvertibleBondTermsService.convertibleBondDocument?.put;

    if (isFromExcel && !this.model) {
      this.model.scheduleItems = [];
    }

    this.issueAndRedemption = issueAndRedemption;
    this.setCouponType(coupon);
  }

  putChange(isFromExcel = false) {
    if (this.issueAndRedemption.isPuttable && !this.model) {
      if (isFromExcel) {
        this.model = {
          scheduleItems: []
        } as Put;
      }
      else {
        this.model = this._lvConvertibleBondTermsService.convertibleBondDocument.put;
      }
    }
  }

  setCouponType(coupon: Coupon) {
    if (coupon && coupon.type) {
      this.couponType = coupon.type;
    }
  }
}
