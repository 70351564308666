<lv-flex-box class="lv-dividends-dividend-parameters">
  <form  #form="ngForm"
    (keydown.enter)="$event.preventDefault()">
    <lv-flex-box data-cy="DM-31" direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="dividendTypeTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.dividend-tooltip-id]="'dividendTypeTooltip'">
            Dividend Type
          </span>
          <lv-xl-label *ngIf="!hasDividendsParamRangeOverridedFromExcel" [field]="'DVD_TYPE'"></lv-xl-label>
          <lv-xl-label *ngIf="hasDividendsParamRangeOverridedFromExcel" [field]="'DVD_PARAM_RANGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist name="dividendType"
          [data]="dividendTypeSource.items"
          valueField="id"
          textField="text"
          [(ngModel)]="dividendsSettings.dividendParameters.dividendType"
          [valuePrimitive]="true"
          [popupSettings]="{ width: 131 }"
          (ngModelChange)="onDividendParametersChange()">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
  <ng-container *ngIf="!isContinousYieldDividendType">
    <lv-flex-box data-cy="DM-309" direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="dividendValueTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.dividend-tooltip-id]="'dividendValueTooltip'">
            {{dividendValueLabel}}
          </span>
          <lv-xl-label *ngIf="!hasDividendsParamRangeOverridedFromExcel" [field]="'DVD_VALUE'"></lv-xl-label>
          <lv-xl-label *ngIf="hasDividendsParamRangeOverridedFromExcel" [field]="'DVD_PARAM_RANGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field lv-input-field--right">
        <lv-numeric-text-box
          name="dividendValue"
          [format]="numberFormat"
          [decimals]="numberOfDecimals"
          [(ngModel)]="dividendsSettings.dividendParameters.dividendValue"
          (didKeyDownEnter)="onDividendParametersChange()"
          (didBlur)="onDividendParametersChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">{{dividendValueSuffix}}</span>
      </div>
    </lv-flex-box>
    <lv-flex-box data-cy="DM-310" direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="dividendFrequencyTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.dividend-tooltip-id]="'dividendFrequencyTooltip'">
            Dividend Frequency
          </span>
          <lv-xl-label *ngIf="!hasDividendsParamRangeOverridedFromExcel" [field]="'DVD_FREQ'"></lv-xl-label>
          <lv-xl-label *ngIf="hasDividendsParamRangeOverridedFromExcel" [field]="'DVD_PARAM_RANGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field ">
        <kendo-dropdownlist name="dividendFrequency"
          [data]="dividendFrequencySource.items"
          valueField="id"
          textField="text"
          [(ngModel)]="dividendsSettings.dividendParameters.frequency"
          [valuePrimitive]="true"
          (ngModelChange)="onDividendParametersChange()">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box data-cy="DM-311" direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="dividendGrowthTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.dividend-tooltip-id]="'dividendGrowthTooltip'">
            Dividend Growth
          </span>
          <lv-xl-label *ngIf="!hasDividendsParamRangeOverridedFromExcel" [field]="'DVD_GROWTH'"></lv-xl-label>
          <lv-xl-label *ngIf="hasDividendsParamRangeOverridedFromExcel" [field]="'DVD_PARAM_RANGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field lv-input-field--right">
        <lv-numeric-text-box
          name="dividendGrowth"
          [format]="numberFormat"
          [decimals]="numberOfDecimals"
          [(ngModel)]="dividendsSettings.dividendParameters.dividendGrowth"
          (didKeyDownEnter)="onDividendParametersChange()"
          (didBlur)="onDividendParametersChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>
    <lv-flex-box data-cy="DM-312" direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="firstDividendDateTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.dividend-tooltip-id]="'firstDividendDateTooltip'">
            First Dividend Date
          </span>
          <lv-xl-label *ngIf="!hasDividendsParamRangeOverridedFromExcel" [field]="'DVD_FIRST_DATE'"></lv-xl-label>
          <lv-xl-label *ngIf="hasDividendsParamRangeOverridedFromExcel" [field]="'DVD_PARAM_RANGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field lv-datePicker-container">
        <kendo-datepicker
          name="firsDividendDate"
          [(ngModel)]="dividendsSettings.dividendParameters.firstDividendDate"
          (blur)="onDividendParametersChange()"
          (keydown.enter)="onDividendParametersChange()"
          [lvMinDate]="dividendsSettings.dividendParameters.firstDividendDate">
        </kendo-datepicker>
      </div>
    </lv-flex-box>
    <lv-flex-box data-cy="DM-313" direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="fixedYieldStartTimeTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.dividend-tooltip-id]="'fixedYieldStartTimeTooltip'">
            Fixed Yield Start Time
          </span>
          <lv-xl-label *ngIf="!hasDividendsParamRangeOverridedFromExcel" [field]="'DVD_YLD_START'"></lv-xl-label>
          <lv-xl-label *ngIf="hasDividendsParamRangeOverridedFromExcel" [field]="'DVD_PARAM_RANGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field lv-input-field--right ">
        <lv-numeric-text-box
          name="fixedDividendStartTime"
          [format]="numberFormat"
          [decimals]="numberOfDecimals"
          [(ngModel)]="dividendsSettings.dividendParameters.fixedDividendStartTime"
          (didKeyDownEnter)="onDividendParametersChange()"
          (didBlur)="onDividendParametersChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">yrs</span>
      </div>
    </lv-flex-box>

  </ng-container>
  <ng-container *ngIf="isContinousYieldDividendType">
    <lv-flex-box data-cy="DM-309" direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="dividendYieldTooltipTemplate"
        filter="span:not(lv-xl-label span)">
          <span [attr.dividend-tooltip-id]="'dividendYieldTooltip'">
            Dividend Value
          </span>
          <lv-xl-label *ngIf="!hasDividendsParamRangeOverridedFromExcel" [field]="'DVD_VALUE'"></lv-xl-label>
          <lv-xl-label *ngIf="hasDividendsParamRangeOverridedFromExcel" [field]="'DVD_PARAM_RANGE'"></lv-xl-label>
      </div>
      <div class="lv-input-field lv-input-field--right">
        <lv-numeric-text-box
          name="dividendYield"
          [format]="numberFormat"
          [decimals]="numberOfDecimals"
          [(ngModel)]="dividendsSettings.dividendParameters.dividendYield"
          (didKeyDownEnter)="onDividendParametersChange()"
          (didBlur)="onDividendParametersChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>
    <lv-flex-box class="lv-dividend-yield-note">
      <lv-flex-box>
        <div class="lv-label lv-label-field lv-header-label">IMPORTANT NOTE</div>
      </lv-flex-box>
      <lv-flex-box direction="row">
        <div class="lv-label lv-label-field lv-dividend-yield-note-content">
          Please note that Continuous Yield option does not take into account dividend protection.
          To use dividend protection with dividend yield as input, please select Discrete Yield option.
        </div>
      </lv-flex-box>
    </lv-flex-box>
  </ng-container>
  </form>
</lv-flex-box>

<ng-template #dividendTypeTooltipTemplate>
  <lv-tooltip dm="dM-31"></lv-tooltip>
</ng-template>
<ng-template #dividendValueTooltipTemplate>
  <lv-tooltip dm="dM-309"></lv-tooltip>
</ng-template>
<ng-template #dividendFrequencyTooltipTemplate>
  <lv-tooltip dm="dM-310"></lv-tooltip>
</ng-template>
<ng-template #dividendGrowthTooltipTemplate>
  <lv-tooltip dm="dM-311"></lv-tooltip>
</ng-template>
<ng-template #firstDividendDateTooltipTemplate>
  <lv-tooltip dm="dM-312"></lv-tooltip>
</ng-template>
<ng-template #fixedYieldStartTimeTooltipTemplate>
  <lv-tooltip dm="dM-313"></lv-tooltip>
</ng-template>
<ng-template  #dividendYieldTooltipTemplate>
  <lv-tooltip dm="dM-309"></lv-tooltip>
</ng-template>
