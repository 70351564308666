import { Directive, ViewContainerRef, Input } from '@angular/core';

@Directive({
  selector: '[componentLocation]'
})
export class ComponentLocationDirective {

  @Input() componentLocation: any;

  constructor(
    public viewContainerRef: ViewContainerRef
  ) { }

}
