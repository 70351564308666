import { getDefaultScenario } from './lv-scenario/lv-scenario.view';
import { ILvWorkspace, getDefaultWorkspace } from '@lv-core-ui/components';
import { LvUtil } from '@lv-core-ui/util';
import { ILvScenarioTemplate, IScenarioTemplateInfoResponse, ILvScenario, ScenarioTemplateCommands } from '@lv-analytics/models';

/**
 * Sensitivity analysis panel view.
 */
export class LvSensitivityAnalysisPanelView {

  model: ILvScenarioTemplate;
  originalModel: ILvScenarioTemplate;

  scenarioTemplateInfoList: IScenarioTemplateInfoResponse[];

  workspaceItems: ILvWorkspace[];
  currentScenario: ILvScenario;

  selectedScenarioTemplateId: number;
  scenarioTemplateCommand: ScenarioTemplateCommands;

  /**
   * Does initialization.
   * @param model ILvScenarioTemplate object.
   */
  init(model?: ILvScenarioTemplate) {
    this.model = this.getDefaultTemplate(model);
    this.setOriginalModel();

    this.getDefaultWorkspaceItems();
  }

  /**
   * Occurs on selected scenario.
   * @param w ILvWorkspace object.
   */
  onSelectScenario(w: ILvWorkspace) {
    this.model.scenarios.forEach(a => {
      a.isSelected = a.id === w.id;
      if (a.isSelected) {
        this.currentScenario = a;
      }
    });
  }

  /**
   * Occurs on add scenario.
   * @param w ILvWorkspace object.
   */
  onAddScenario(w: ILvWorkspace) {
    const sc = getDefaultScenario();

    sc.id = w.id;
    sc.name = w.name;
    sc.isSelected = w.isSelected;

    this.model.scenarios.forEach(a => a.isSelected = false);

    this.model.scenarios.push(sc);
    this.currentScenario = this.model.scenarios[this.model.scenarios.length - 1];

    this.onSelectScenario(w);
  }

  /**
   * Occurs on edit scenario.
   * @param w ILvWorkspace object.
   */
  onEditScenario(w: ILvWorkspace) {
    const found = this.model.scenarios.find(a => a.id === w.id);
    found.name = w.name;
  }

  /**
   * Occurs on close scenario.
   * @param w ILvWorkspace object.
   */
  onCloseScenario(w: ILvWorkspace) {
    this.model.scenarios = this.model.scenarios.filter(a => a.id !== w.id);
  }

  /**
   * Occurs on cancel edit scenario template.
   */
  onCancelEditScenarioTemplate() {
    this.model.name = this.originalModel.name;
  }

  /**
   * Checks if scenario template is selected.
   * @param st IScenarioTemplateInfoResponse object.
   * @returns A flag indicating if scenario template is selected.
   */
  isScenarioTemplateSelected(st: IScenarioTemplateInfoResponse) {
    if (!this.selectedScenarioTemplateId && st.isDraft) {
      return true;
    }

    return this.selectedScenarioTemplateId === st.id;
  }

  /**
   * Sets selected scenario template.
   * @param scenarioTemplateId Scenario template ID.
   */
  setSelectedScenarioTemplate(scenarioTemplateId: number) {
    this.selectedScenarioTemplateId = scenarioTemplateId;
  }

  /**
   * Updates selected scenario template.
   */
  updateSelectedScenarioTemplate() {
    const found = this.scenarioTemplateInfoList.find(x => x.id === this.model.id);
    found.scenarioTemplateName = this.model.name;
  }

  /**
   * Delete scenario template from list.
   * @param scenarioTemplateId Scenario template ID.
   */
  deleteScenarioTemplateFromList(scenarioTemplateId: number) {
    const foundIndex = this.scenarioTemplateInfoList.findIndex(x => x.id === scenarioTemplateId);
    this.scenarioTemplateInfoList.splice(foundIndex, 1);
  }

  /**
   * Checks if scenario template is edited.
   * @param scenarioTemplateId Scenario template ID.
   * @returns A flag indicating if scenario template is edited.
   */
  isEdited(scenarioTemplateId?: number): boolean {
    // This is a Draft
    if (!scenarioTemplateId) {
      return false;
    }

    if (scenarioTemplateId !== this.model.id) {
      return false;
    }

    const opt1 = LvUtil.jsonParse(LvUtil.jsonStringify(this.model)) as ILvScenarioTemplate;
    const opt2 = LvUtil.jsonParse(LvUtil.jsonStringify(this.originalModel)) as ILvScenarioTemplate;

    opt1.scenarios.sort(LvUtil.sortBy('id', false));
    opt2.scenarios.sort(LvUtil.sortBy('id', false));

    const result = LvUtil.jsonStringify(opt1) !== LvUtil.jsonStringify(opt2);

    return result;
  }

  /**
   * Checks if save icon should be shown.
   * @param scenarioTemplateId Scenario template ID.
   * @returns A flag indicating if save icon should be shown.
   */
  shoowSaveIcon(scenarioTemplateId?: number): boolean {
    if (!scenarioTemplateId) {
      if (this.model.id) {
        return false;
      }
      return true;
    }
    return this.isEdited(scenarioTemplateId);
  }

  /**
   * Sets original model.
   */
  setOriginalModel() {
    this.originalModel = LvUtil.jsonParse(LvUtil.jsonStringify(this.model));

    if (!this.originalModel.id) {
      this.originalModel.name = 'Create New Scenario Template';
    }
  }

  /**
   * Gets default template.
   * @param model ILvScenarioTemplate object.
   * @returns ILvScenarioTemplate object.
   */
  getDefaultTemplate(model?: ILvScenarioTemplate): ILvScenarioTemplate {
    return model || {
      id: null,
      name: null,
      scenarios: []
    };
  }

  /**
   * Gets scenario template info from model.
   * @returns IScenarioTemplateInfoResponse object.
   */
  getScenarioTemplateInfoFromModel(): IScenarioTemplateInfoResponse {
    return {
      scenarioTemplateName: this.model.name,
      isDraft: false,
      isGroup: false,
      isShared: false,
      isSystem: false,
      id: this.model.id,
    } as IScenarioTemplateInfoResponse;
  }

  /**
   * Sets command.
   * @param command ScenarioTemplateCommands object.
   */
  setCommand(command: ScenarioTemplateCommands) {
    if (command === ScenarioTemplateCommands.SaveDraft) {
      this.model.name = null;
    }
    else if (command === ScenarioTemplateCommands.Copy) {
      this.model.name = this.originalModel.name + ' - Copy';
    }
    else if (command === ScenarioTemplateCommands.Save) {
      this.model.name = this.originalModel.name;
    }

    this.scenarioTemplateCommand = command;
  }

  /**
   * Gets scenario template info.
   * @param scenarioTemplateId Scenario template ID.
   * @returns IScenarioTemplateInfoResponse object.
   */
  getScenarioTemplateInfo(scenarioTemplateId: number): IScenarioTemplateInfoResponse {
    return this.scenarioTemplateInfoList.find(x => x.id === scenarioTemplateId);
  }

  /**
   * Gets default workspace items.
   */
  private getDefaultWorkspaceItems() {
    this.workspaceItems = this.model.scenarios.map(a => {
      const w = getDefaultWorkspace();
      w.id = a.id;
      w.name = a.name;
      w.isSelected = a.isSelected;
      return w;
    });
  }
}
