import { Component, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef, Renderer2 } from '@angular/core';

import { LvModalComponent } from '../lv-modal';
import { mask, modal } from '../lv-modal.animations';

export enum LvStateModalMode {
  ERROR,
  CONFLICT,
  RELOAD
}

@Component({
  selector: 'lv-state-modal',
  templateUrl: './lv-state-modal.component.html',
  animations: [
    mask,
    modal
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvStateModalComponent extends LvModalComponent {

  @Output() doLoginAgain: EventEmitter<void>;
  @Output() doReload: EventEmitter<void>;

  conflictMode: boolean;
  reloadMode: boolean;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    renderer: Renderer2
  ) {
    super(changeDetectorRef, renderer);

    this.conflictMode = false;
    this.reloadMode = false;

    this.doLoginAgain = new EventEmitter<void>();
    this.doReload = new EventEmitter<void>();
  }

  // override from LvModalComponent
  public open(mode?: LvStateModalMode): void {
    if (!this.isOpened) {

      if (mode) {
        this.conflictMode = mode === LvStateModalMode.CONFLICT;
        this.reloadMode = mode === LvStateModalMode.RELOAD;
      }

      this.isOpened = true;

      this.disableScrolling();
      this.changeDetectorRef.detectChanges();
    }
  }

  onLogInAgain() {
    this.doLoginAgain.emit();
  }

  onReload() {
    this.doReload.emit();
  }
}
