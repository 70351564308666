<lv-flex-box
  class="lv-input-field"
  direction="row"
  data-cy="DM-1120">
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="useQuantityValueSettingsTooltipTemplate"
    filter="div">
    Use Quantity Value
  </div>
  <div class="lv-field lv-field--checkbox">
    <input type="checkbox"
      name="useQuantityValue"
      class="k-checkbox"
      [attr.id]="getCheckBoxId('use-quantity-value')"
      [disabled]="!overrideSystemDefaults"
      [(ngModel)]="settings.useQuantityValue"/>
    <label class="k-checkbox-label"
      [lvId]="env + '-useQuantityValue'"
      [attr.for]="getCheckBoxId('use-quantity-value')"></label>
  </div>
</lv-flex-box>

<lv-flex-box
  direction="row"
  data-cy="DM-1121">
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="defaultValueSettingsTooltipTemplate"
    filter="div">
    Default Value
  </div>

  <div class="lv-input-field">
    <lv-numeric-text-box
      name="quantityDefaultValue"
      [lvId]="env + '-quantityDefaultValueInput'"
      [decimals]="'0'"
      [format]="'n0'"
      [disabled]="isDisabled"
      [required]="settings.useQuantityValue"
      [(ngModel)]="settings.defaultValue">
    </lv-numeric-text-box>
  </div>
</lv-flex-box>

<lv-flex-box direction="row">
  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="currencyOverridesSettingsTooltipTemplate"
    filter="div">
    Currency Overrides
  </div>
  <div class="lv-input-field">
  <lv-advanced-grid
    data-cy="DM-1122"
    [lvId]="env + '-currencyOverrides'"
    class="lv-quantity-section-settings-currency-overrides"
    [class.lv-advanced-grid-disabled-values]="isDisabled"
    [columns]="columns"
    [records]="settings.currencyOverrides"
    [height]="84"
    [scrollRows]="3"
    [createFormGroup]="createFormGroup"
    [editDisabled]="isDisabled">
  </lv-advanced-grid>
  </div>
</lv-flex-box>

<ng-template #useQuantityValueSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1120"></lv-tooltip>
</ng-template>
<ng-template #defaultValueSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1121"></lv-tooltip>
</ng-template>
<ng-template #currencyOverridesSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1122"></lv-tooltip>
</ng-template>
