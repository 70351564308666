import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ContingentConversionPeriodTypeDescription, ContingentConversionScheduleTriggerTypeDescription,
  ContingentConversionSectionSettings } from '@lv-convertible-bond/models';
import { QuarterType, ContingentConversionTriggerParityLimit } from '@lv-convertible-bond/models/convertible-bond-terms/Enums (2)';
import { LvLookupEnum } from '@lv-core-ui/util';
import { v4 } from 'uuid';

@Component({
  selector: 'lv-coco-section-settings',
  templateUrl: './lv-coco-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvCocoSectionSettingsComponent implements OnInit {

  @Input() cocoSettings: ContingentConversionSectionSettings;
  @Input() overrideDefaults: boolean;

  quarterTypeLookup: LvLookupEnum;
  triggerPeriodTypeLookup: LvLookupEnum;
  cbPriceTriggerParityLimitLookup: LvLookupEnum;
  triggerTypeLookup: LvLookupEnum;

  convertibleOnCall: string;
  buyoutAtCocoTrigger: string;

  formatThree = '#,###.###';
  decimalsThree = '3';
  decimalsZero = '0';
  formatZero = 'n0';

  constructor() {
    this.quarterTypeLookup = new LvLookupEnum(QuarterType);
    this.triggerPeriodTypeLookup = new LvLookupEnum(ContingentConversionPeriodTypeDescription);
    this.cbPriceTriggerParityLimitLookup = new LvLookupEnum(ContingentConversionTriggerParityLimit);
    this.triggerTypeLookup = new LvLookupEnum(ContingentConversionScheduleTriggerTypeDescription);

    this.convertibleOnCall = v4();
    this.buyoutAtCocoTrigger = v4();
    this.overrideDefaults = true;
  }

  ngOnInit() {
  }

  getCocoSettingsTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
