import { v4 } from 'uuid';
import { DateExtensions, LvLookupEnum } from '@lv-core-ui/util';
import {
  ConversionData, CurrencyType, ConversionCustomData, ConversionSettlementDescripton, Coupon, IssueAndRedemption,
  ConvertibleBondSubType, ResetAveragingData, Reset, ContingentConversion, VariableConversion, ConversionSettlement, CouponType
} from '@lv-convertible-bond/models';
import {
  PepsConversionBeforeMaturityType, FloorExpressedAs, CapExpressedAs, QuarterType, ContingentConversionPeriodType,
  ContingentConversionTriggerParityLimit
} from '@lv-convertible-bond/models/convertible-bond-terms/Enums (2)';
import { PepsConversion } from '@lv-convertible-bond/models/convertible-bond-terms/PEPSConversion';
import { PercsConversion } from '@lv-convertible-bond/models/convertible-bond-terms/PERCSConversion';
// tslint:disable-next-line: max-line-length
import { AcquisitionOptionDateInputType, AcquisitionOptionDateInputTypeDescription } from '../../../models/convertible-bond-terms/enum-descriptions/conversion/AcquisitionOptionDateInputType';
// tslint:disable-next-line: max-line-length
import { AcquisitionVWAPPeriodInputType, AcquisitionVWAPPeriodInputTypeDescription } from '../../../models/convertible-bond-terms/enum-descriptions/conversion/AcquisitionVWAPPeriodInputType';
// tslint:disable-next-line: max-line-length
import { AcquisitionSharesSettlType, AcquisitionSharesSettlTypeDescription } from '../../../models/convertible-bond-terms/enum-descriptions/conversion/AcquisitionSharesSettlType';
import * as moment from 'moment';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';

export class LvConversionView {

  get isResetSelected(): boolean {
    return this.model.isResetable;
  }

  get isAcquisitionSharesSettlementSelected(): boolean {
    return this.model.useAcquisitionSharesSettlement;
  }

  get isContingentConversionSelected(): boolean {
    return this.model.isContigentConversion;
  }

  get isVariableConversionSelected(): boolean {
    return this.model.isVariableConversion;
  }

  get isConversionRatioHistoryVisible(): boolean {
    return false;
  }

  get isCarryForwardHistoryVisible(): boolean {
    return false;
  }

  get isInstrumentPepsOrPercs(): boolean {
    return this.isPeps || this.isPercs;
  }

  get isSchedulesVisible(): boolean {
    return !this.isPeps && !this.isPercs;
  }

  get isConversionSettlementNumberFixingVisible(): boolean {
    // tslint:disable-next-line:max-line-length
    return this.model.conversionSettlement === ConversionSettlement.Cash || this.model.conversionSettlement === ConversionSettlement.CashOrShares || this.model.conversionSettlement === ConversionSettlement.SharesBasedOnAveraging;
  }

  get isConversionSettlementCashAmountVisible(): boolean {
    // tslint:disable-next-line:max-line-length
    return this.model.conversionSettlement === ConversionSettlement.CashOrShares;
  }

  get isOptionDateInputFixed(): boolean {
    return this.model.acquisitionOptionDateInputType === AcquisitionOptionDateInputType.FixedDate;
  }

  get isVWAPInputTypeFixed(): boolean {
    return this.model.acquisitionVWAPPeriodInputType === AcquisitionVWAPPeriodInputType.FixedDate;
  }

  get isLvsConvertible(): boolean {
    return true;
  }

  get conversionRatioHistoryHasData(): boolean {
    if (!this.model.conversionRatioHistory) {
      return false;
    }
    return this.model.conversionRatioHistory.length > 0;
  }

  get carryForwardHistoryHasData(): boolean {
    if (!this.model.carryForwardHistory) {
      return false;
    }
    return this.model.carryForwardHistory.length > 0;
  }

  get shouldShowConversionHistoryButton(): boolean {
    return this.isLvsConvertible && this.conversionRatioHistoryHasData || this.carryForwardHistoryHasData;
  }

  get detachableSubtype(): boolean {
    if (ConvertibleBondSubType.ConvertibleWithDetachableWarrant) {
      return true;
    }
  }

  get isNumberOfFixingDaysFieldVisible(): boolean {
    return this.isConversionSettlementNumberFixingVisible ||
     (this.model.useAcquisitionSharesSettlement && !this.isInstrumentPepsOrPercs);
  }

  public model: ConversionData;

  conversionSettlementLookup: LvLookupEnum;
  conversionPriceCCYLookup: LvLookupEnum;
  rebateCCYLookup: LvLookupEnum;
  optionalDateInputTypeLookup: LvLookupEnum;
  acquisitionVWAPPeriodInputTypeLookup: LvLookupEnum;
  acquisitionSharesSettlTypeLookup: LvLookupEnum;

  conversionPriceCCY: CurrencyType;
  rebateCCY: CurrencyType;
  conversionCustomData: ConversionCustomData;

  mandatoryConversionCheckboxId: string;
  keepAccruedCheckboxId: string;
  forfeitCouponOnConversionAtMaturityCheckboxId: string;
  forfeitCouponOnOptionalConversionCheckboxId: string;
  acquisitionNoticeGivenId: string;
  conversionIntoNewSharesCheckboxId: string;
  acquisitionSharesSettlementId: string;
  fxAdjustedConversionAmountCheckboxId: string;
  contigentConversionCheckboxId: string;
  variableConversionCheckboxId: string;
  resetableCheckboxId: string;
  makeWholeStartDateId: string;
  makeWholeEndDateId: string;
  exerciseScheduleCheckboxId: string;
  priceCurrency: string;
  currency: string;
  underlyingCurrency: string;

  isZeroCouponType: boolean;
  isPeps: boolean;
  isPercs: boolean;
  isDetachable: boolean;
  isExerciseScheduleVisible: boolean;
  nominalValue: number;

  numberOfDecimals = '0';
  numberFormat = 'n0';
  formatFour = '#,###.####';
  decimalsFour = '4';

  acquisitionNoticeEndDateEnabled: boolean;
  showConversionRatioAndCarryForwardHistory: boolean;

  constructor(
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService
  ) {
    this.mandatoryConversionCheckboxId = v4();
    this.keepAccruedCheckboxId = v4();
    this.forfeitCouponOnConversionAtMaturityCheckboxId = v4();
    this.forfeitCouponOnOptionalConversionCheckboxId = v4();
    this.conversionIntoNewSharesCheckboxId = v4();
    this.acquisitionSharesSettlementId = v4();
    this.fxAdjustedConversionAmountCheckboxId = v4();
    this.contigentConversionCheckboxId = v4();
    this.variableConversionCheckboxId = v4();
    this.resetableCheckboxId = v4();
    this.makeWholeStartDateId = v4();
    this.makeWholeEndDateId = v4();
    this.exerciseScheduleCheckboxId = v4();
    this.acquisitionNoticeGivenId = v4();

    this.conversionSettlementLookup = new LvLookupEnum(ConversionSettlementDescripton);
    this.conversionPriceCCYLookup = new LvLookupEnum(CurrencyType);
    this.rebateCCYLookup = new LvLookupEnum(CurrencyType);
    this.optionalDateInputTypeLookup = new LvLookupEnum(AcquisitionOptionDateInputTypeDescription);
    this.acquisitionVWAPPeriodInputTypeLookup = new LvLookupEnum(AcquisitionVWAPPeriodInputTypeDescription);
    this.acquisitionSharesSettlTypeLookup = new LvLookupEnum(AcquisitionSharesSettlTypeDescription);
    this.conversionCustomData = new ConversionCustomData();
    this.isZeroCouponType = false;
    this.isPeps = false;
    this.isPercs = false;
    this.isDetachable = false;
    this.isExerciseScheduleVisible = false;
    this.showConversionRatioAndCarryForwardHistory = false;

    this.model = {
      schedule: [],
      exerciseSchedule: [],
    } as ConversionData;

    this.init(this.model, {} as Coupon, {} as IssueAndRedemption, false);
  }

  init(
    model?: ConversionData,
    coupon?: Coupon,
    issueAndRedemption?: IssueAndRedemption,
    isOpenedFromExcel: boolean = false
  ) {
    this.model = model ?? this._lvConvertibleBondTermsService.convertibleBondDocument.conversion;
    const couponData = coupon;

    if (couponData && couponData.type) {
      this.changeOnCouponType(couponData.type);
    }

    if (issueAndRedemption && issueAndRedemption.subType) {
       const conversionSettings = this._lvConvertibleBondTermsService.conversionSettings;

      this.changeOnInstrumentSubtype(issueAndRedemption);
      this.nominalValue = issueAndRedemption.nominalValue;
      this.currency = issueAndRedemption.currencyCode;

      this.underlyingCurrency = issueAndRedemption.underlyingEquity ?
        issueAndRedemption.underlyingEquity.currencyCode : issueAndRedemption.currencyCode;

      this.conversionCustomData.nominal = issueAndRedemption.nominalValue;
      this.conversionCustomData.fixedFx = issueAndRedemption.fixedFXRate;
      this.conversionCustomData.startDate =
        DateExtensions.addDays(issueAndRedemption.firstSettlementDate, conversionSettings?.pepsConversion.startDate);
      this.conversionCustomData.endDate =
        DateExtensions.addDays(issueAndRedemption.maturityDate, -conversionSettings?.pepsConversion.endDate);

      if (!!this.model.pepsConversion &&
        !!conversionSettings?.pepsConversion?.startDate &&
        !!issueAndRedemption.firstSettlementDate) {

        this.model.pepsConversion.startDate = this.conversionCustomData.startDate;
      }

      if (!!this.model.pepsConversion &&
        !!conversionSettings?.pepsConversion?.endDate &&
        !!issueAndRedemption.maturityDate) {

        this.model.pepsConversion.endDate = this.conversionCustomData.endDate;
      }

      this.conversionCustomData.conversionPriceCurrency = this.conversionPriceCCY ? this.conversionPriceCCY : CurrencyType.Convertible;
      this.conversionCustomData.rebateCurrency = this.rebateCCY ? this.rebateCCY : CurrencyType.Convertible;
      this.conversionCustomData.currency = issueAndRedemption.currencyCode;
      this.conversionCustomData.status = issueAndRedemption.setupStatus;
    }

    this.setDefaultFields(isOpenedFromExcel);
    this.filterAcquisitionDropdowns();
  }

  /**
   * Init make whole data.
   */
  initMakeWholeData(model?: ConversionData) {
    this.model.recieveMakeWholeConversion = model.recieveMakeWholeConversion;
    this.model.callMakeWhole = model.callMakeWhole;
  }

  /**
   * Based on checked status sets value to current date or null
   * @param {boolean} value Is Notice end date checkbox checked
   */
  acquisitionNoticeEndDateCheckboxChanged(value: boolean) {
    this.acquisitionNoticeEndDateEnabled = value;

    if (this.model) {
      this.model.acquisitionNoticeEndDate = value ? new Date() : null;
    }
  }

  /**
   * Filter Acquisition section dropdowns based on acquisition notice given checkbox
   */
  filterAcquisitionDropdowns() {
    this.optionalDateInputTypeLookup.setFilterFn(item => {
      if (this.model.acquisitionNoticeGiven && item.id === AcquisitionOptionDateInputType.NoticePeriod) {
        return false;
      }
      return true;
    });
    this.acquisitionVWAPPeriodInputTypeLookup.setFilterFn(item => {
      if (this.model.acquisitionNoticeGiven && item.id === AcquisitionVWAPPeriodInputType.ObservationLag) {
        return false;
      }
      return true;
    });
  }

  setDefaultFields(isOpenedFromExcel: boolean) {
    this.conversionPriceCCY = CurrencyType.Convertible;
    this.rebateCCY = CurrencyType.Convertible;
    this.priceCurrency = this.currency;

    this.setIsExcersizeScheduleVisible();

    if (this.model) {
      if (this.model.schedule && this.model.schedule.length > 0) {
        this.rebateCCY = this.model.schedule[0].rebateCurrencyType ? this.model.schedule[0].rebateCurrencyType : CurrencyType.Convertible;
        if (this.model.schedule[0].conversionPriceCCY) {
          this.conversionPriceCCY = this.model.schedule[0].conversionPriceCCY;
          this.priceCurrency = this.conversionPriceCCY === CurrencyType.Convertible ? this.currency : this.underlyingCurrency;
        }
        else {
          this.conversionPriceCCY = CurrencyType.Convertible;
          this.priceCurrency = this.currency;
        }

        this.conversionCustomData.conversionPriceCurrency = this.conversionPriceCCY;
        this.conversionCustomData.rebateCurrency = this.rebateCCY;
      }
      if (this.model.exerciseSchedule && this.model.exerciseSchedule.length > 0) {
        this.isExerciseScheduleVisible = true;
      }
      if (this.model.isResetable && this.model.reset && !isOpenedFromExcel) {
        this.model.reset.useResetAveraging = false;
        if (!this.model.reset.resetAveraging) {
          this.model.reset.resetAveraging = new ResetAveragingData();
        } else {
          this.setUseResetAveraging();
        }
      }
      if (this.model.useAcquisitionSharesSettlement) {
        this.setAcquisitionDefaults();
      }
    }
  }

  changeScheduleCurrency() {
    this.model.schedule = this.model.schedule.map(a => ({
      ...a,
      conversionPriceCCY: this.conversionPriceCCY,
      rebateCurrencyType: this.rebateCCY,
      ratio: this.calculateRatio(a.ratio, a.conversionPrice),
      conversionPrice: this.calculatePrice(a.ratio, a.conversionPrice)
    }));

    // tslint:disable-next-line:max-line-length
    this.priceCurrency = this.conversionPriceCCY === CurrencyType.Convertible ? this.currency : this.underlyingCurrency;
    this.conversionCustomData.conversionPriceCurrency = this.conversionPriceCCY;
    this.conversionCustomData.rebateCurrency = this.rebateCCY;
  }

  calculateRatio(ratio: number, price: number): number {
    if (!ratio && price > 0) {
      // tslint:disable-next-line:max-line-length
      return this.conversionPriceCCY === CurrencyType.Underlying && this.conversionCustomData.fixedFx ?
        (this.conversionCustomData.fixedFx * this.conversionCustomData.nominal) / price : this.conversionCustomData.nominal / price;
    } else {
      return ratio;
    }
  }

  calculatePrice(ratio: number, price: number): number {
    if (ratio > 0) {
      return this.conversionPriceCCY === CurrencyType.Underlying && this.conversionCustomData.fixedFx ?
        (this.conversionCustomData.fixedFx * this.conversionCustomData.nominal) / ratio : this.conversionCustomData.nominal / ratio;
    } else {
      return price;
    }
  }

  changeOnCouponType(couponType: CouponType) {
    this.isZeroCouponType = couponType === CouponType.ZeroCoupon;
  }

  changeOnInstrumentSubtype(issueAndRedemption: IssueAndRedemption) {
    if (issueAndRedemption.subType === ConvertibleBondSubType.PEPS) {
      this.isPeps = true;
      this.isPercs = false;
      this.isDetachable = false;
      if (!this.model.pepsConversion) {
        this.model.pepsConversion = new PepsConversion();
        this.model.pepsConversion.conversionBeforeMaturityType = PepsConversionBeforeMaturityType.MinimumRatio;
        this.model.pepsConversion.conversionBeforeMaturityType = PepsConversionBeforeMaturityType.MinimumRatio;
        this.model.pepsConversion.conversionRebateCurrency = CurrencyType.Convertible;
      }
    }
    else if (issueAndRedemption.subType === ConvertibleBondSubType.PERCS) {
      this.isPercs = true;
      this.isPeps = false;
      this.isDetachable = false;
      if (!this.model.percsConversion) {
        this.model.percsConversion = new PercsConversion();
      }
    }
    else if (issueAndRedemption.subType === ConvertibleBondSubType.ConvertibleWithDetachableWarrant) {
     this.isDetachable = true;
     this.isPeps = false;
     this.isPercs = false;
    }
    else {
      this.isPercs = false;
      this.isPeps = false;
      this.isDetachable = false;
    }
  }

  changeResettable() {
    if (!this.model.reset) {
      this.model.reset = {
        globalCap: 100,
        floorExpressedRelativeTo: FloorExpressedAs.PercOfInitialConversionPrice,
        capExpressedRelativeTo: CapExpressedAs.PercOfPrevailingConversionPrice,
        resetCurrencyType: CurrencyType.Convertible,
        conversionPriceCurrencyType: CurrencyType.Convertible,
        schedule: [],
        useResetAveraging: false,
        resetAveraging: {} as ResetAveragingData,
      } as Reset;
    }
    else {
      this.setUseResetAveraging();
    }
  }

  changeContingentConversion() {
    if (!this.model.contingentConversion) {
      this.model.contingentConversion = new ContingentConversion();
      this.model.contingentConversion.convertibleOnCall = true;
      this.model.contingentConversion.quarterType = QuarterType.Calendar;
      this.model.contingentConversion.triggerPeriodType = ContingentConversionPeriodType.NOutOfMDays;
      this.model.contingentConversion.cbPriceTriggerParityLimit = ContingentConversionTriggerParityLimit.None;
      this.model.contingentConversion.schedule = [];
    }
  }

  changeVariableConversion() {
    if (!this.model.variableConversion) {
      this.model.variableConversion = new VariableConversion();
      this.model.variableConversion.strike = 100;
      this.model.variableConversion.incrementalShareFactor = 100;
      this.model.variableConversion.strikeCurrency = CurrencyType.Convertible;
    }
  }

  changeExerciseSchedule() {
    if (!this.isExerciseScheduleVisible) {
      this.model.exerciseSchedule = [];
    }
  }

  /**
   * If Acquisition notic given is checked
   * Update Option date input type and VWAP Period input type
   */
  onAcquisitionNoticeGivenChange() {
    if (this.model.acquisitionNoticeGiven) {
      this.model.acquisitionOptionDateInputType = AcquisitionOptionDateInputType.FixedDate;
      this.model.acquisitionVWAPPeriodInputType = AcquisitionVWAPPeriodInputType.FixedDate;
    }
  }

  /**
   * Set default values for acquisition section if visible
   */
  setAcquisitionDefaults(): void {
    if (!this.model.acquisitionOptionDateInputType) {
      this.model.acquisitionOptionDateInputType = AcquisitionOptionDateInputType.FixedDate;
    }
    if (!this.model.acquisitionVWAPPeriodInputType) {
      this.model.acquisitionVWAPPeriodInputType = AcquisitionVWAPPeriodInputType.FixedDate;
    }
    if (!this.model.conversionSettlementMaximumAcquisitionShares) {
      this.model.conversionSettlementMaximumAcquisitionShares = 100;
    }
    if (!this.model.acquisitionASVAdjustmentPerc) {
      this.model.acquisitionASVAdjustmentPerc = 100;
    }
    if (!this.model.acquisitionSharesSettlType) {
      this.model.acquisitionSharesSettlType = AcquisitionSharesSettlType.PrincipalPlusAS;
    }

    if (!this.model.acquisitionNoticeStartDate) {
      this.model.acquisitionNoticeStartDate = moment.utc(new Date()).toDate();
    }

    if (!this.model.conversionSettlementAcquisitionOptionDate) {
      this.model.conversionSettlementAcquisitionOptionDate = moment.utc(new Date()).toDate();
    }

    if (!this.model.conversionSettlementVWAPPeriodStartDate) {
      this.model.conversionSettlementVWAPPeriodStartDate = moment.utc(new Date()).toDate();
    }

    if (this.model.acquisitionNoticeEndDate) {
      this.acquisitionNoticeEndDateEnabled = true;
    }
  }

  /**
   * Toggle visibility of Conversion Ratio and Carry Forward History tables
   */
  toggleShowConversionRatioAndCarryForwardHistory(): void {
    this.showConversionRatioAndCarryForwardHistory = !this.showConversionRatioAndCarryForwardHistory;
  }

  /**
   * Set UseResetAveraginig flag on Resrt section
   */
  setUseResetAveraging() {
    if (this.model.reset.resetAveraging &&
       (this.model.reset.resetAveraging.numberOfAveragingDays ||
        this.model.reset.resetAveraging.startLag)) {
      this.model.reset.useResetAveraging = true;
    } else {
      this.model.reset.useResetAveraging = false;
    }
  }

  private setIsExcersizeScheduleVisible(): void {
    this.isExerciseScheduleVisible = this.model && this.model.exerciseSchedule.length ? true : false;
  }
}
