<lv-settings-widget
  titleText="Tools"
  [floatingSidePanel]="true"
  [showSettings]="showSettings"
  sidePanelLvId="lv-tools-widget-side-panel"
  cssClass="DM-1215">
  <ng-container content>
    <lv-tools
      [state]="widgetState">
    </lv-tools>
  </ng-container>

  <ng-container settings>
    <div *ngIf="!isOpenedFromExcel"
      class="lv-side-panel-link-item"
      lvId="lv-tools-display-default-view"
      (click)="onDisplayDefaultView()">
      <span>Display Default View</span>
      <i *ngIf="widgetState.useDefaultView"
        class="k-icon k-i-check"></i>
    </div>

    <div *ngIf="!isOpenedFromExcel"
      class="lv-side-panel-link-item lv-side-panel-link-item--separator"
      lvId="lv-tools-display-custom-view"
      (click)="onDisplayCustomView()">
      <span>Display Custom View</span>
      <i *ngIf="!widgetState.useDefaultView"
        class="k-icon k-i-check"></i>
    </div>

    <div class="lv-side-panel-link-item"
      lvId="lv-tools-edit-custom-view"
      (click)="onEditCustomView()">
      <span>{{editWidgetButtonText}}</span>
    </div>

    <div class="lv-side-panel-link-item"
      lvId="lv-tools-edit-custom-view"
      (click)="onSaveAsDefaultView()"
      *ngIf="!widgetState.useDefaultView">
      <span>{{saveWidgetButtonText}}</span>
    </div>
  </ng-container>
</lv-settings-widget>
