<lv-flex-box class="lv-contingent-conversion">
  <lv-flex-box direction="row">
    <lv-flex-box class="lv-contingent-first-col">
      <!-- <lv-flex-box direction="row">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="cocoCoversionTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'lastCoCoTriggerSatisfiedSourceTooltip'">
            Last CoCo Trigger Satisfied
          </span>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [id]="lastCocoTriggerId"
            name="contingent-last-coco-trigger"
            [(ngModel)]="model.lastCocoTriggerSatisfied"
            (ngModelChange)="onContingentConversionChange()">
          <label class="k-checkbox-label" [for]="lastCocoTriggerId"></label>
        </div>
      </lv-flex-box> -->
      <lv-flex-box direction="row" data-cy="DM-874">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="convertibleOnCallSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Convertible on Call
          </span>
          <lv-xl-label [field]="'CO_CO_CONV_ON_CALL'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="convertibleOnCall"
            name="contingent-convertible-on-call"
            [(ngModel)]="model.convertibleOnCall"
            (ngModelChange)="onContingentConversionChange()">
          <label class="k-checkbox-label" [attr.for]="convertibleOnCall"></label>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-875">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="conversionPriceTriggerSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Convertible Price Trigger
          </span>
          <lv-xl-label [field]="'CO_CO_PRICE_TRGGR'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            name="contingent-conversion-price-trigger"
            [format]="formatThree"
            [decimals]="decimalsThree"
            [(ngModel)]="model.convPriceTrigger"
            (didBlur)="onContingentConversionChange()"
            (didKeyDownEnter)="onContingentConversionChange()">
          </lv-numeric-text-box>
          <span class="lv-sufix-label">%</span>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-876">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="buyoutAtCoCoTriggerSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Buyout at CoCo Trigger
          </span>
          <lv-xl-label [field]="'CO_CO_BUYOUT'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="buyoutAtCocoTrigger"
            name="contingent-buyout-at-coco-trigger"
            [(ngModel)]="model.buyoutAtCoCoTrigger"
            (ngModelChange)="onContingentConversionChange()">
          <label class="k-checkbox-label" [attr.for]="buyoutAtCocoTrigger"></label>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-877">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="quarterTypeSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Quarter Type
          </span>
          <lv-xl-label [field]="'CO_CO_QTR_TYPE'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <kendo-dropdownlist
            name="contingent-quarter-type"
            [data]="quarterTypeLookup.items"
            valueField="id"
            textField="text"
            [valuePrimitive]="true"
            [(ngModel)]="model.quarterType"
            (ngModelChange)="onContingentConversionChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
    </lv-flex-box>
    <lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-878">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="triggerPeriodTypeSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Trigger Period Type
          </span>
          <lv-xl-label [field]="'CO_CO_TRGGR_PERIOD_TYPE'"></lv-xl-label>
        </div>
        <div class="lv-input-field lv-input-field--auto">
          <kendo-dropdownlist
            name="contingent-trigger-period-type"
            [data]="triggerPeriodTypeLookup.items"
            valueField="id"
            textField="text"
            [valuePrimitive]="true"
            [popupSettings]="{ width: 191 }"
            [(ngModel)]="model.triggerPeriodType"
            (ngModelChange)="onContingentConversionChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-879">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="triggerPeriodDaysSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Trigger Period Days
          </span>
          <lv-xl-label [field]="'CO_CO_DAYS'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            [format]="numberFormat"
            [decimals]="numberOfDecimals"
            name="contingent-trigger-period-days"
            [(ngModel)]="model.triggerPeriodDays"
            (didBlur)="onContingentConversionChange()"
            (didKeyDownEnter)="onContingentConversionChange()">
          </lv-numeric-text-box>
        </div>
      </lv-flex-box>
        <lv-flex-box direction="row" data-cy="DM-880">
          <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="outOfDaysSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Out of Days
          </span>
          <lv-xl-label [field]="'CO_CO_OUT_OF_DAYS'"></lv-xl-label>
        </div>
          <div class="lv-input-field">
            <lv-numeric-text-box
              [format]="numberFormat"
              [decimals]="numberOfDecimals"
              name="contingent-out-of-days"
              [(ngModel)]="model.outOfDays"
              (didBlur)="onContingentConversionChange()"
              (didKeyDownEnter)="onContingentConversionChange()">
            </lv-numeric-text-box>
          </div>
        </lv-flex-box>
        <lv-flex-box direction="row" data-cy="DM-881">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="cbPriceTriggerParityLimitSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              {{triggerParityLimitLabel}}
            </span>
            <lv-xl-label [field]="'CO_CO_TRGGR_LIMIT'"></lv-xl-label>
          </div>
          <div class="lv-input-field">
            <kendo-dropdownlist
              name="contingent-cb-price-trigger-parity"
              [data]="cbPriceTriggerParityLimitLookup.items"
              valueField="id"
              textField="text"
              [valuePrimitive]="true"
              [(ngModel)]="model.cbPriceTriggerParityLimit"
              (ngModelChange)="onContingentConversionChange()">
            </kendo-dropdownlist>
          </div>
        </lv-flex-box>
        <lv-flex-box direction="row" data-cy="DM-873">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="triggerTypeSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Trigger Type
            </span>
            <lv-xl-label [field]="'CO_CO_TRGGR_TYPE'"></lv-xl-label>
          </div>
          <div class="lv-input-field lv-input-field--auto">
            <kendo-dropdownlist
              name="contingent-quarter-type"
              [data]="triggerTypeLookup.items"
              valueField="id"
              textField="text"
              [valuePrimitive]="true"
              [popupSettings]="{ width: 175 }"
              [(ngModel)]="triggerType"
              (ngModelChange)="onContingentConversionChange()">
            </kendo-dropdownlist>
          </div>
        </lv-flex-box>
    </lv-flex-box>
  </lv-flex-box>
  <lv-flex-box class="lv-contingent-schedule">
    <lv-flex-box direction="row">
      <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="cocoScheduleSourceTooltip"
        filter=".lv-label"
        class="lv-label lv-label-field lv-label--title">
        <span>COCO SCHEDULE</span>
      </div>
    </lv-flex-box>
    <lv-flex-box>
      <lv-advanced-grid
        data-cy="DM-900"
        lvId="lvTermsContingentConversionSchedule"
        [columns]="columns"
        [records]="scheduleItems"
        [createFormGroup]="createFormGroup"
        [parseFn]="parseFn"
        sortBy="startDate"
        [showXlLabel]="hasScheduleInExcelOverride"
        [excelFieldAlias]="excelFieldAlias"
        [isFieldFromExcelEnabled]="isFieldFromExcelEnabled"
        class="lv-contingent-conversion-schedule">
      </lv-advanced-grid>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<ng-template #cocoScheduleSourceTooltip>
  <lv-tooltip dm="dM-900"></lv-tooltip>
</ng-template>
<ng-template #convertibleOnCallSourceTooltip>
  <lv-tooltip dm="dM-874"></lv-tooltip>
</ng-template>
<ng-template #conversionPriceTriggerSourceTooltip>
  <lv-tooltip dm="dM-875"></lv-tooltip>
</ng-template>
<ng-template #buyoutAtCoCoTriggerSourceTooltip>
  <lv-tooltip dm="dM-876"></lv-tooltip>
</ng-template>
<ng-template #quarterTypeSourceTooltip>
  <lv-tooltip dm="dM-877"></lv-tooltip>
</ng-template>
<ng-template #triggerPeriodTypeSourceTooltip>
  <lv-tooltip dm="dM-878"></lv-tooltip>
</ng-template>
<ng-template #triggerPeriodDaysSourceTooltip>
  <lv-tooltip dm="dM-879"></lv-tooltip>
</ng-template>
<ng-template #outOfDaysSourceTooltip>
  <lv-tooltip dm="dM-880"></lv-tooltip>
</ng-template>
<ng-template #cbPriceTriggerParityLimitSourceTooltip>
  <lv-tooltip dm="dM-881"></lv-tooltip>
</ng-template>
<ng-template #triggerTypeSourceTooltip>
  <lv-tooltip dm="dM-873"></lv-tooltip>
</ng-template>
