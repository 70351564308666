<div *ngIf="!applicationStateInitialized"
  class="centered-spinner">
  <div class="spinner">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>
</div>

<lv-flex-box *ngIf="applicationStateInitialized"
  class="internal-wrapper"
  tabindex="0">

  <lv-flex-box class="header"
    direction="row">
    <div class="menu" *ngIf="menuVisible">
      <lv-menu lvId="menu-leversys"
        [items]="menuItems">
        <image-logo [imageSrc]="'app/leversys-core-ui/assets/img/logo-dark.svg'"></image-logo>
        <!-- <img class="img-icoAn" 
             src ="app/leversys-core-ui/assets/img/logo-dark.svg"/> -->
      </lv-menu>
    </div>

    <div *ngIf="false" class="search">
      <i class="k-icon k-i-search"></i>
      <input
        placeholder="Search"
        (input)="onQueryChange($event, $event.target.value)" />
    </div>

    <lv-flex-box class="messages"
      flex="1">
    </lv-flex-box>

    <lv-flex-box class="options"
      direction="row"
      alignItems="center"
      kendoTooltip
      position="left">
      <lv-flex-box *ngIf="shouldEstablishRealTimeConnection"
        class="hub-connection"
        direction="row"
        alignItems="center">
        <div *ngIf="manualReconnectVisible"
          class="lv-icon k-icon k-i-reset"
          title="Reconnect"
          (click)="onManualReconnectHub()">
        </div>
        <div class="k-icon k-i-circle"
          [ngClass]="hubConnectionStatusClass">
        </div>
        <div>{{ hubConnectionStatus }}</div>
      </lv-flex-box>

      <div class="lv-icon k-icon k-i-help"
        title="Support Center"
        (click)="onOpenSupportCenter()">
      </div>

      <lv-menu iconClass="k-icon k-i-cog" [lvId]="'lv-settings-icon'" *ngIf="cogMenuVisible"
        menuAlignment = "right"
        [items]="cogMenuItems"
        data-cy="DM-1162">
      </lv-menu>
    </lv-flex-box>
  </lv-flex-box>

  <div class="content">
    <router-outlet></router-outlet>
  </div>

</lv-flex-box>

<div kendoDialogContainer></div>
