import { IBreakEvenCalculationOutput, IBrakeEvenCalculateInput } from '@lv-analytics/models';

/**
 * Break-even calculation.
 */
export class BreakEvenCalculation {

  beAtParityCalculationOutput: IBreakEvenCalculationOutput;
  beAtParCalculationOutput: IBreakEvenCalculationOutput;
  beAtZeroCalculationOutput: IBreakEvenCalculationOutput;

  constructor() {
  }

  /**
   * Calculates at parity.
   */
  public calculateAtParity(calcInput: IBrakeEvenCalculateInput): IBreakEvenCalculationOutput {
    this.beAtParityCalculationOutput = {} as IBreakEvenCalculationOutput;
    this.initalizeCalculationOutput(this.beAtParityCalculationOutput);

    if (calcInput.nominal !== 0) {
      this.beAtParityCalculationOutput.eqPrice = this.getEQPriceAtParity(calcInput, calcInput.accruedInterest);
      this.beAtParityCalculationOutput.parity = this.getParityAtParity(calcInput, this.beAtParityCalculationOutput.eqPrice);
    }
    this.beAtParityCalculationOutput.eqPriceChange = this.getEqPriceChangeAtParity(calcInput, this.beAtParityCalculationOutput.eqPrice);

    return this.beAtParityCalculationOutput;
  }

  /**
   * Calculates at par.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @returns IBreakEvenCalculationOutput object.
   */
  public calculateAtPar(calcInput: IBrakeEvenCalculateInput): IBreakEvenCalculationOutput {
    this.beAtParCalculationOutput = {} as IBreakEvenCalculationOutput;
    this.initalizeCalculationOutput(this.beAtParCalculationOutput);

    if (calcInput.nominal !== 0) {
      this.beAtParCalculationOutput.eqPrice = this.getEQPriceAtPar(calcInput, calcInput.accruedInterest);
      this.beAtParCalculationOutput.parity = this.getParityAtParity(calcInput, this.beAtParCalculationOutput.eqPrice);
    }
    this.beAtParCalculationOutput.premium = this.getPremiumAtPar(calcInput, this.beAtParCalculationOutput.parity);
    this.beAtParCalculationOutput.eqPriceChange = this.getEqPriceChangeAtParity(calcInput, this.beAtParCalculationOutput.eqPrice);

    return this.beAtParCalculationOutput;
  }

  /**
   * Calculates at zero.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @returns IBreakEvenCalculationOutput object.
   */
  public calculateAtZero(calcInput: IBrakeEvenCalculateInput): IBreakEvenCalculationOutput {
    this.beAtZeroCalculationOutput = {} as IBreakEvenCalculationOutput;
    this.initalizeCalculationOutput(this.beAtParCalculationOutput);

    this.beAtZeroCalculationOutput.cbPrice = this.getCBPriceAtZero(calcInput);

    return this.beAtZeroCalculationOutput;
  }

  /**
   * Initializes calculation output.
   * @param calcOutput IBreakEvenCalculationOutput object.
   */
  private initalizeCalculationOutput(calcOutput: IBreakEvenCalculationOutput) {
    calcOutput.cbPrice = 0;
    calcOutput.eqPrice = 0;
    calcOutput.eqPriceChange = 0;
    calcOutput.parity = 0;
    calcOutput.premium = 0;
  }

  /**
   * Gets delta value.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @returns Delta value.
   */
  private getDelta(calcInput: IBrakeEvenCalculateInput): number {
    if (calcInput.cbPosition === 0) {
      return calcInput.delta;
    }

    const deltaDenominator = (calcInput.conversionRatio * calcInput.cbPosition / calcInput.nominal);
    let multiplicator = 1;

    if (calcInput.priceAsPar) {
      multiplicator = calcInput.eqPosition / deltaDenominator;
    } else {
      multiplicator = calcInput.eqPosition / (calcInput.conversionRatio * calcInput.cbPosition);
    }

    return calcInput.conversionRatio !== 0 ? -1 * multiplicator : 0;
  }

  /**
   * Gets equity price change at parity.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @param eqPrice Equity price value.
   * @returns Equity price.
   */
  private getEqPriceChangeAtParity(calcInput: IBrakeEvenCalculateInput, eqPrice: number) {
    return calcInput.stockRef !== 0 ? (eqPrice - calcInput.stockRef) * 100 / calcInput.stockRef : 0;
  }

  /**
   * Gets premium at par.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @param parity Parity value.
   * @returns Premium value.
   */
  private getPremiumAtPar(calcInput: IBrakeEvenCalculateInput, parity: number) {
    let multiplicator = 1;

    if (calcInput.priceAsPar) {
      multiplicator =  ((100 - parity) / parity) * 100 ;
    } else {
      multiplicator =  ((calcInput.nominal - parity) / parity) * 100;
    }

    return parity !== 0 ? multiplicator : 0;
  }

  /**
   * Gets equity price at parity.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @param accInterest Accrued interest.
   * @returns Equity price.
   */
  private getEQPriceAtParity(calcInput: IBrakeEvenCalculateInput, accInterest: number): number {
    let eqPrice;
    const fxFactor = calcInput.refFX > 0 ? calcInput.refFX : calcInput.crossFX;
    const cp = calcInput.conversionRatio !== 0 ? calcInput.nominal / calcInput.conversionRatio * fxFactor : 0;
    const delta = this.getDelta(calcInput);

    if (calcInput.detachable) {
      eqPrice = this.getDetachablePriceAtParity(calcInput, delta, cp, accInterest);
    } else {
      if (calcInput.priceAsPar) {
        eqPrice = this.getNotDetachablePriceAtParity(calcInput, accInterest);
      } else {
        eqPrice = this.getNotDetachableNotPriceAtParity(calcInput, accInterest);
      }
    }

    return eqPrice;
  }

  /**
   * Gets equity price when detachable is set to true and price at parity.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @param delta Delta value.
   * @param cp Conversion price.
   * @param accInterest Accrued interest.
   * @returns Equity price.
   */
  private getDetachablePriceAtParity(calcInput: IBrakeEvenCalculateInput, delta: number, cp: number, accInterest: number): number {
    let eqPrice;
    let ratioFactor;

    if (calcInput.priceAsPar) {
      ratioFactor = calcInput.conversionRatio * 100 / (calcInput.crossFX * calcInput.nominal);
      const nominator = calcInput.cbRef - 100 + accInterest;
      eqPrice =  delta !== 1 && ratioFactor !== 0 ? ((nominator) / ratioFactor + cp - calcInput.stockRef * delta) / (1 - delta) : 0;
    } else {
      ratioFactor = calcInput.conversionRatio / calcInput.crossFX;
      const deltaNominator = ((calcInput.cbRef - calcInput.nominal + accInterest) + ratioFactor * (cp - delta * calcInput.stockRef));
      eqPrice = delta !== 1 && ratioFactor !== 0 ? deltaNominator / ((1 - delta) * ratioFactor) : 0;
    }

    return eqPrice;
  }

  /**
   * Gets equity price for convertible that have detachible and price as par set to false.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @param accInterest Accrued interest.
   * @returns Equity price.
   */
  private getNotDetachableNotPriceAtParity(calcInput: IBrakeEvenCalculateInput, accInterest: number): number {
    let eqPrice;

    if (calcInput.cbPosition === 0) {
      // tslint:disable-next-line: max-line-length
      const priceNominator = (calcInput.cbRef + accInterest - calcInput.rebate - calcInput.stockRef * calcInput.conversionRatio * calcInput.delta);
      const priceDenominator = calcInput.conversionRatio - calcInput.conversionRatio * calcInput.delta;
      eqPrice = calcInput.conversionRatio !== 0 && calcInput.delta !== 1 ? priceNominator / priceDenominator : 0;
    } else {
      // tslint:disable-next-line: max-line-length
      const priceNominator = ((calcInput.cbRef + accInterest) - calcInput.rebate - calcInput.stockRef * calcInput.eqPosition / calcInput.cbPosition);
      const priceDenominator = calcInput.conversionRatio - calcInput.eqPosition / calcInput.cbPosition;
      eqPrice = calcInput.conversionRatio !== calcInput.eqPosition / calcInput.cbPosition ? priceNominator / priceDenominator : 0;
    }

    return eqPrice;
  }

  /**
   * Gets equity price for convertible that have detachible set to false and price as par set to true.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @param accInterest Accrued interest.
   * @returns Equity price.
   */
  private getNotDetachablePriceAtParity(calcInput: IBrakeEvenCalculateInput, accInterest: number): number {
    let eqPrice;

    if (calcInput.cbPosition === 0) {
      const cbRefNominal = calcInput.cbRef * calcInput.nominal;
      const accInterestNominal = accInterest * calcInput.nominal;
      // tslint:disable-next-line: max-line-length
      const priceNominator = cbRefNominal + accInterestNominal - calcInput.rebate - calcInput.stockRef * calcInput.conversionRatio * calcInput.delta * 100;
      const priceDenominator = calcInput.conversionRatio * 100 - calcInput.conversionRatio * calcInput.delta * 100;
      eqPrice = calcInput.conversionRatio !== 0 && calcInput.delta !== 1 ? priceNominator / priceDenominator : 0;
    } else {
      const cbPositionNominal = calcInput.cbPosition / calcInput.nominal;
      const stockRefEq = calcInput.stockRef * calcInput.eqPosition;
      const cbPositonNominal = calcInput.cbPosition / calcInput.nominal;
      // tslint:disable-next-line: max-line-length
      const priceNominator = ((calcInput.cbRef + accInterest) / 100) * calcInput.nominal - calcInput.rebate - stockRefEq / cbPositionNominal;
      const priceDenominator = calcInput.conversionRatio - calcInput.eqPosition / (calcInput.cbPosition / calcInput.nominal);
      eqPrice = calcInput.conversionRatio !== calcInput.eqPosition / cbPositonNominal ? priceNominator / priceDenominator : 0;
    }

    return eqPrice;
  }

  /**
   * Gets parity at parity.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @param eqPrice Equity price value.
   * @returns Parity.
   */
  private getParityAtParity(calcInput: IBrakeEvenCalculateInput, eqPrice: number): number {
    let parity;
    const fxFactor = calcInput.refFX > 0 ? calcInput.refFX : calcInput.crossFX;
    const cp = calcInput.conversionRatio !== 0 ? calcInput.nominal / calcInput.conversionRatio * fxFactor : 0;

    if (calcInput.detachable) {
      parity = this.getDetachableParity(calcInput, eqPrice, cp);
    } else {
      parity = this.getNonDetachableParity(calcInput, eqPrice, cp);
    }

    return parity;
  }

  /**
   * Gets parity when detachable is set to true.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @param eqPrice Equity price value.
   * @param cp Conversion price.
   * @returns Parity.
   */
  private getDetachableParity(calcInput: IBrakeEvenCalculateInput, eqPrice: number, cp: number): number {
    let parity;

    if (calcInput.priceAsPar) {
      parity =  100 + (eqPrice - cp) / calcInput.crossFX * calcInput.conversionRatio * 100 / calcInput.nominal;
    } else {
      parity = calcInput.nominal + (eqPrice - cp) / calcInput.crossFX * calcInput.conversionRatio;
    }

    return parity;
  }

  /**
   * Gets patity when detachable is set to false.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @param eqPrice Equity price value.
   * @param cp Conversion price.
   * @returns Parity.
   */
  private getNonDetachableParity(calcInput: IBrakeEvenCalculateInput, eqPrice: number, cp: number): number {
    let parity;

    const parityTemp = eqPrice * calcInput.conversionRatio;
    if (calcInput.priceAsPar) {
      parity = (parityTemp + calcInput.rebate) * 100 / calcInput.nominal;
    } else {
      parity =  parityTemp + calcInput.rebate;
    }

    return parity;
  }

  /**
   * Gets EQ price at par.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @param accInterest Accrued interest.
   * @returns Equity price.
   */
  private getEQPriceAtPar(calcInput: IBrakeEvenCalculateInput, accInterest: number): number {
    let eqPrice;
    const delta = this.getDelta(calcInput);

    if (calcInput.detachable) {
      eqPrice = this.getDetachablePriceAtPar(calcInput, delta, accInterest);
    } else {
      if (calcInput.priceAsPar) {
        eqPrice = this.getNotDetachablePriceAtPar(calcInput, accInterest);
      } else {
        eqPrice = this.getNotDetachableNotPriceAtPar(calcInput, accInterest);
      }
    }

    return eqPrice;
  }

  /**
   * Gets equity price when detachable and price at par are set to true.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @param delta Delta value.
   * @param accInterest Accrued interest.
   * @returns Equity price.
   */
  private getDetachablePriceAtPar(calcInput: IBrakeEvenCalculateInput, delta: number, accInterest: number): number {
    let eqPrice;
    let ratioFactor;

    if (calcInput.priceAsPar) {
      ratioFactor = calcInput.conversionRatio * 100 / (calcInput.crossFX * calcInput.nominal);
      eqPrice = delta * ratioFactor !== 0 ? calcInput.stockRef + (100 - calcInput.cbRef - accInterest) / (delta * ratioFactor) : 0;
    } else {
      ratioFactor = calcInput.conversionRatio / calcInput.crossFX;
      const denominator = delta * ratioFactor;
      eqPrice = delta * ratioFactor !== 0 ? calcInput.stockRef + (calcInput.nominal - calcInput.cbRef - accInterest) / (denominator) : 0;
    }

    return eqPrice;
  }

  /**
   * Gets equity price for not detachable and price at par.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @param accInterest Accrued interest.
   * @returns Equity price.
   */
  private getNotDetachablePriceAtPar(calcInput: IBrakeEvenCalculateInput, accInterest: number): number {
    let eqPrice;

    if (calcInput.cbPosition === 0) {
      const stockRefRatioDelta = calcInput.stockRef * calcInput.conversionRatio * calcInput.delta * 100;
      const cbRefNominal = calcInput.cbRef * calcInput.nominal;
      const priceNominator = (100 * calcInput.nominal + stockRefRatioDelta - accInterest * calcInput.nominal - cbRefNominal);
      const priceDenominator = calcInput.conversionRatio * calcInput.delta * 100;
      eqPrice = calcInput.conversionRatio * calcInput.delta !== 0 ? priceNominator / priceDenominator : 0;
    } else {
      const priceNominator = calcInput.nominal * (1 - (accInterest + calcInput.cbRef) / 100);
      const priceDenominator = calcInput.eqPosition / (calcInput.cbPosition / calcInput.nominal);
      const expression = calcInput.eqPosition !== 0 ? priceNominator / priceDenominator : 0;
      eqPrice = calcInput.stockRef + expression;
    }

    return eqPrice;
  }

  /**
   * Gets equity price when detachable and price at par are set to false.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @param accInterest Accrued interest.
   * @returns Equity price.
   */
  private getNotDetachableNotPriceAtPar(calcInput: IBrakeEvenCalculateInput, accInterest: number): number {
    let eqPrice;

    if (calcInput.cbPosition === 0) {
      const stockRatioDelta = calcInput.stockRef * calcInput.conversionRatio * calcInput.delta;
      const priceNominator = (calcInput.nominal + stockRatioDelta - accInterest - calcInput.cbRef);
      eqPrice = calcInput.conversionRatio * calcInput.delta !== 0 ? priceNominator / (calcInput.conversionRatio * calcInput.delta) : 0;
    } else {
      const priceNominator = calcInput.nominal - (accInterest + calcInput.cbRef);
      const expression = calcInput.eqPosition !== 0 ? priceNominator / (calcInput.eqPosition / calcInput.cbPosition) : 0;
      eqPrice = calcInput.stockRef + expression;
    }

    return eqPrice;
  }

  /**
   * Gets CB price at zero.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @returns CB price.
   */
  private getCBPriceAtZero(calcInput: IBrakeEvenCalculateInput): number {
    let cbPrice;

    if (calcInput.detachable) {
      if (calcInput.priceAsPar) {
        cbPrice = this.getDetachablePriceAsParZero(calcInput);
      } else {
        cbPrice = this.getDetachableNotPriceAsParZero(calcInput);
      }
    } else {
      if (calcInput.priceAsPar) {
        cbPrice = this.getNotDetachablePriceAsParZero(calcInput);
      } else {
        cbPrice = this.getNotDetachableNotPriceAsParZero(calcInput);
      }
    }

    return cbPrice;
  }

  /**
   * Gets CB price when detachable and price as par are set to true.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @returns CB price.
   */
  private getDetachablePriceAsParZero(calcInput: IBrakeEvenCalculateInput): number {
    if (calcInput.cbPosition === 0) {
      const cbRefAcc = calcInput.cbRef + calcInput.accruedInterest;
      const stockCross = calcInput.stockRef / calcInput.crossFX;
      return cbRefAcc - calcInput.delta * stockCross * calcInput.conversionRatio / calcInput.nominal * 100;
    } else {
      const eqPoscbPos = calcInput.eqPosition / calcInput.cbPosition;
      return calcInput.cbRef + calcInput.accruedInterest + calcInput.stockRef * eqPoscbPos * (100 / calcInput.crossFX);
    }
  }

  /**
   * Gets CB price when detachable is set to true and price as par is set to false.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @returns CB price.
   */
  private getDetachableNotPriceAsParZero(calcInput: IBrakeEvenCalculateInput): number {
    if (calcInput.cbPosition === 0) {
      const deltStockRatio = calcInput.delta * calcInput.stockRef * calcInput.conversionRatio;
      return calcInput.cbRef + calcInput.accruedInterest - deltStockRatio / calcInput.crossFX;
    } else {
      const stockCross = calcInput.stockRef / calcInput.crossFX;
      return calcInput.cbRef + calcInput.accruedInterest + (stockCross) * (calcInput.eqPosition / calcInput.cbPosition);
    }
  }

  /**
   * Gets CB price when detachable is set to false and price as par is set to true.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @returns CB price.
   */
  private getNotDetachablePriceAsParZero(calcInput: IBrakeEvenCalculateInput): number {
    if (calcInput.cbPosition === 0) {
      const cbRefAcc = calcInput.stockRef * calcInput.conversionRatio * calcInput.delta * 100;
      return calcInput.nominal !== 0 ? calcInput.cbRef - cbRefAcc / calcInput.nominal * calcInput.accruedInterest : 0;
    } else {
      return calcInput.cbRef + calcInput.accruedInterest - 100 * calcInput.stockRef * calcInput.eqPosition / calcInput.cbPosition;
    }
  }

  /**
   * Gets CB price when detachable and price as par are set to false.
   * @param calcInput IBrakeEvenCalculateInput object.
   * @returns CB price.
   */
  private getNotDetachableNotPriceAsParZero(calcInput: IBrakeEvenCalculateInput): number {
    if (calcInput.cbPosition === 0) {
      return calcInput.cbRef - calcInput.stockRef * calcInput.conversionRatio * calcInput.delta + calcInput.accruedInterest;
    } else {
      return calcInput.cbRef + calcInput.accruedInterest - calcInput.stockRef * calcInput.eqPosition / calcInput.cbPosition;
    }
  }
}

