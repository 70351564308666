import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
  Input, ChangeDetectorRef, OnChanges, HostListener, ViewChild} from '@angular/core';

import { Align, PopupComponent } from '@progress/kendo-angular-popup';

import { ILvMenuItem } from './lv-menu-item';

@Component({
  selector: 'lv-menu',
  templateUrl: './lv-menu.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvMenuComponent implements OnInit, OnChanges {
  @ViewChild('dropdownContainer') dropdownContainer: PopupComponent;

  @Input() text: string;
  @Input() iconClass: string;
  @Input() menuAlignment: 'left' | 'right';

  @Input() items: ILvMenuItem[];

  get anchorAlign(): Align {
    return {
      horizontal: this.menuAlignment,
      vertical: 'bottom'
    };
  }
  get popupAlign(): Align {
    return {
      horizontal: this.menuAlignment,
      vertical: 'top'
    };
  }

  itemsVisible: boolean;
  internalClick: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.itemsVisible = false;
    this.internalClick = false;
    this.menuAlignment = 'left';
    this.items = [];
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onDocumentClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (this.itemsVisible && !this.internalClick) {
      this.itemsVisible = false;
      this.changeDetectorRef.detectChanges();
    }

    this.internalClick = false;
  }

  setMenuListItems() {
    this.items = this.items.map((a, index) => {
      return {
        ...a,
        id: index
      };
    });

    this.changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this.setMenuListItems();
  }

  ngOnChanges() {
    this.setMenuListItems();
  }

  onShowMenu() {
    this.itemsVisible ? this.hideItems() : this.showItems();
    this.internalClick = this.itemsVisible;
  }

  hideItems(): void {
    this.setMenuListItems();
    this.itemsVisible = false;
  }

  showItems(): void {
    this.setMenuListItems();
    this.itemsVisible = true;
  }

  itemsTrackBy(index: number, item: ILvMenuItem) {
    return item.id;
  }

  executeAction(item: ILvMenuItem): void {
    if (!item.disabled && item.action) {
      item.action();
    }

    this.internalClick = false;
  }

  onPupupOpened() {
     this.changeDetectorRef.detectChanges();
  }
}
