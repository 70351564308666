export class LvMath {

  static isNumber(value: any): boolean {
    if (Number.isNaN(Number.parseFloat(value))) {
      return false;
    }

    return true;
  }

  static isZeroNumber(value: any): boolean {
    const isNumber = LvMath.isNumber(value);

    if (!isNumber || (isNumber && value === 0)) {
      return true;
    }

    return false;
  }

  static isNotGUIDIdentifier(n) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  }
}
