

import { DiscountRateType } from './DiscountRateType';
import { TakeoverProtectionRatchetMatrixItem } from './TakeoverProtectionRatchetMatrixItem';
import { CallMakeWholeType } from './Enums (1)';
import { RatchetMatrixOfferValueType } from './RatchetMatrixOfferValueType';
import { RatchetMatrixCompensationValueType } from './RatchetMatrixCompensationValueType';

export class CallMakeWholeData {
    
    type: CallMakeWholeType;
    startDate: Date;
    endDate: Date;
    initialPremium: number;
    couponsIncludedUntil: Date;
    discountRateType: DiscountRateType;
    discountSpread: number;
    ratchetMatrixMembers: TakeoverProtectionRatchetMatrixItem[];
    ratchetMatrixOfferValueType: RatchetMatrixOfferValueType;
    ratchetMatrixCompensationValueType: RatchetMatrixCompensationValueType;
}

