<lv-flex-box class="lv-dividend-protection" data-cy="DM-1231">
  <lv-flex-box direction="row" data-cy="DM-414">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="dividendProtectionSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Dividend Protection
      </span>
      <lv-xl-label [field]="'DVD_PROT'"></lv-xl-label>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input
        type="checkbox"
        class="k-checkbox"
        [attr.id]="view.useDividendProtectionId"
        [(ngModel)]="view.issueAndRedemption.useDividendProtection"
        (ngModelChange)="onDividendProtectionChange()" />
      <label lvId="lvDividendProtectionCheckbox" class="k-checkbox-label" [attr.for]="view.useDividendProtectionId"></label>
    </div>
  </lv-flex-box>
  <lv-flex-box *ngIf="view.issueAndRedemption.useDividendProtection">
    <lv-flex-box direction="row" data-cy="DM-415">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="dividendProtectionTypeSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Dividend Protection Type
        </span>
        <lv-xl-label [field]="'DVD_PROT_TYPE'"></lv-xl-label>
      </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          lvId="lvDividendProtectionType"
          [data]="view.protectionTypeLookup.items"
          valueField="id"
          textField="text"
          [valuePrimitive]="true"
          [popupSettings]="{ width: 193 }"
          [(ngModel)]="view.dividendProtection.type"
          (ngModelChange)="onModelChange()">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-832">
      <div class="lv-label lv-label-field lv-label-before-checkbox"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="protectionStartDateSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Protection Start Date
        </span>
        <lv-xl-label [field]="'DVD_PROT_START_DATE'"></lv-xl-label>
      </div>
      <lv-datepicker
        checkBoxLvId = "dvdProtectionStartDateCheckBoxId"
        name="dividendProtectionStartDate"
        [(ngModel)]="view.dividendStartDate"
        [checked]="view.dividendStartDateEnabled"
        (ngModelChange)="onModelChange()"
        (didCheckboxValueChange)="dividendStartDateCheckBoxChanged($event)">
      </lv-datepicker>

    </lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-833">
      <div class="lv-label lv-label-field lv-label-before-checkbox"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="protectionEndDateSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Protection End Date
        </span>
        <lv-xl-label [field]="'DVD_PROT_END_DATE'"></lv-xl-label>
      </div>

      <lv-datepicker
        checkBoxLvId = "dvdProtectionEndDateCheckBoxId"
        name="dividendProtectionEndDate"
        [(ngModel)]="view.dividendEndDate"
        [checked]="view.dividendEndDateEnabled"
        (ngModelChange)="onModelChange()"
        (didCheckboxValueChange)="dividendEndDateCheckBoxChanged($event)">
      </lv-datepicker>

    </lv-flex-box>
    <lv-flex-box *ngIf="!view.isCashDistribution" class="lv-conversion-ratio-fieldset" direction="row">
      <lv-fieldset-panel legend="CONVERSION RATIO ADJUSTMENT">
        <lv-flex-box direction="row" data-cy="DM-822">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="adjustmentFormulaTypeSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Adjustment Formula Type
            </span>
            <lv-xl-label [field]="'DVD_PROT_CONV_FRML_TYPE'"></lv-xl-label>
          </div>
          <div class="lv-input-field lv-input-field--auto">
            <kendo-dropdownlist
            [data]="view.adjustmentFormulaTypeLookup.items"
            lvId="dvdPtAdjustmentFormulaType"
            valueField="id"
            textField="text"
            [valuePrimitive]="true"
            [popupSettings]="{ width: 284 }"
            [(ngModel)]="view.dividendProtection.conversionRatioAdjustment.formulaType"
            (ngModelChange)="onModelChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-823">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="spotLevelAtAdjustmentFormulaSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Spot Level at Adjustment Formula
          </span>
          <lv-xl-label [field]="'DVD_PROT_CONV_STK_LVL'"></lv-xl-label>
        </div>
        <div class="lv-input-field lv-input-field--auto">
          <kendo-dropdownlist
            lvId="divProtectionSpotLevelFormula"
            [data]="view.spotLevelAtAdjustmentFormulaLookup.items"
            valueField="id"
            textField="text"
            [valuePrimitive]="true"
            [popupSettings]="{ width: 167 }"
            [(ngModel)]="view.dividendProtection.conversionRatioAdjustment.spotLevelAtAdjustmentFormula"
            (ngModelChange)="onModelChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-824">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="conversionRatioCapSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Conversion Ratio Cap
          </span>
          <lv-xl-label [field]="'DVD_PROT_CONV_CR_CAP'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            name="conversionRatioCapDvd"
            [format]="view.formatSix"
            [decimals]="view.decimalsSix"
            [(ngModel)]="view.dividendProtection.conversionRatioAdjustment.conversionRatioCap"
            (didBlur)="onModelChange()"
            (didKeyDownEnter)="onModelChange()">
          </lv-numeric-text-box>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-825">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="minimumAdjustmentTypeSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Minimum Adjustment Type
          </span>
          <lv-xl-label [field]="'DVD_PROT_CONV_MIN_TYPE'"></lv-xl-label>
        </div>
        <div class="lv-input-field lv-input-field--auto">
          <kendo-dropdownlist
            [data]="view.minimumAdjustmentType.items"
            valueField="id"
            textField="text"
            [valuePrimitive]="true"
            [popupSettings]="{ width: 127 }"
            [(ngModel)]="view.dividendProtection.minAdjustmentType"
            (ngModelChange)="onModelChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box  direction="row" data-cy="DM-826">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="minimumAdjustmentThresholdSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Minimum Adjustment Threshold
          </span>
          <lv-xl-label [field]="'DVD_PROT_CONV_MIN_THRSHLD'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            name="minAdjustmentThreshold"
            [format]="view.formatFour"
            [decimals]="view.decimalsFour"
            [(ngModel)]="view.dividendProtection.conversionRatioAdjustment.minAdjustmentTreshold"
            (didBlur)="onModelChange()"
            (didKeyDownEnter)="onModelChange()">
          </lv-numeric-text-box>
          <span class="lv-sufix-label">%</span>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-827">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="twoWayConversionPriceAdjustmentSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span *ngIf="!isOpenedFromExcel">
            Two-Way Conversion Price Adjustment
          </span>
          <span *ngIf="isOpenedFromExcel">
            Two-Way Conversion Price Adjust.
          </span>
          <lv-xl-label [field]="'DVD_PROT_CONV_TWO_WAY'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input
            type="checkbox"
            class="k-checkbox"
            [attr.id]="view.twoWayConversionPriceAdjustmentId"
            [(ngModel)]="view.dividendProtection.conversionRatioAdjustment.twoWayConversionPriceAdjustment"
            (ngModelChange)="onModelChange()" />
          <label class="k-checkbox-label" [attr.for]="view.twoWayConversionPriceAdjustmentId"></label>
        </div>
      </lv-flex-box>
      </lv-fieldset-panel>
    </lv-flex-box>
    <lv-flex-box *ngIf="view.isCashDistribution">
      <lv-fieldset-panel legend="CASH DISTRIBUTION">
        <lv-flex-box direction="row">
          <lv-flex-box class="lv-cash-distribution-left-column">
            <lv-flex-box
              direction="row"
              data-cy="DM-828">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="cashDistributionDateSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Cash Distribution Date
                </span>
                <lv-xl-label [field]="'DVD_PROT_CASH_DATE'"></lv-xl-label>
              </div>
              <div class="lv-input-field lv-input-field--auto">
                <kendo-dropdownlist
                  [data]="view.cashDistributionDateTypeLookup.items"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [popupSettings]="{ width: 147 }"
                  [(ngModel)]="view.dividendProtection.cashDistribution.dateType"
                  (ngModelChange)="onModelChange()">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>
            <lv-flex-box direction="row" data-cy="DM-829">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="percentageOfPassThroughSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  % of Pass Through
                </span>
                <lv-xl-label [field]="'DVD_PROT_CASH_PCTG'"></lv-xl-label>
              </div>
              <div class="lv-input-field">
                <lv-numeric-text-box
                  name="percentOfPassThrough"
                  [format]="view.formatFour"
                  [decimals]="view.decimalsFour"
                  [(ngModel)]="view.dividendProtection.cashDistribution.percentOfPassThrough"
                  (didBlur)="onModelChange()"
                  (didKeyDownEnter)="onModelChange()">
                </lv-numeric-text-box>
                <span class="lv-sufix-label">%</span>
              </div>
            </lv-flex-box>
          </lv-flex-box>
          <lv-flex-box>
            <lv-flex-box
              direction="row"
              data-cy="DM-830">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="maximumPaymentSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Maximum Payment
                </span>
                <lv-xl-label [field]="'DVD_PROT_CASH_MAX'"></lv-xl-label>
              </div>
              <div class="lv-input-field">
                <lv-numeric-text-box
                  name="maximumPayment"
                  [format]="view.formatSix"
                  [decimals]="view.decimalsSix"
                  [(ngModel)]="view.dividendProtection.cashDistribution.maximumPayment"
                  (didBlur)="onModelChange()"
                  (didKeyDownEnter)="onModelChange()">
                </lv-numeric-text-box>
              </div>
            </lv-flex-box>
            <lv-flex-box direction="row" data-cy="DM-831">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="carriedForwardValueSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Carried Forward Value
                </span>
                <lv-xl-label [field]="'DVD_PROT_CASH_CRRY_FWD'"></lv-xl-label>
              </div>
              <div class="lv-input-field">
                <lv-numeric-text-box
                  name="carriedForwardValue"
                  [format]="view.formatSix"
                  [decimals]="view.decimalsSix"
                  [(ngModel)]="view.dividendProtection.cashDistribution.carriedForwardValue"
                  (didBlur)="onModelChange()"
                  (didKeyDownEnter)="onModelChange()">
                </lv-numeric-text-box>
              </div>
            </lv-flex-box>
          </lv-flex-box>
        </lv-flex-box>
      </lv-fieldset-panel>
    </lv-flex-box>
    <lv-flex-box direction="row">
      <lv-fieldset-panel legend="THRESHOLD / TRIGGERS PARAMETERS">
        <lv-flex-box *ngIf="!view.isFullProtection" direction="row">
          <lv-flex-box class="lv-threshold-left-column">
            <lv-flex-box direction="row" data-cy="DM-417">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="thresholdTypeSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Threshold Type
                </span>
                <lv-xl-label [field]="'DVD_PROT_THR_TYPE'"></lv-xl-label>
              </div>
              <div class="lv-input-field lv-input-field--auto">
                <kendo-dropdownlist
                  lvId="divProtectionThresholdType"
                  [data]="view.thresholdTypeLookup.items"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [popupSettings]="{ width: 115 }"
                  [(ngModel)]="view.dividendProtection.tresholdType"
                  (ngModelChange)="onThresholdTypeOrScheduleChange()">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>
            <lv-flex-box direction="row" data-cy="DM-418">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="thresholdPeriodFrequencySourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span  *ngIf="!isOpenedFromExcel">
                  Threshold Period Freq.
                </span>
                <span  *ngIf="isOpenedFromExcel">
                  Threshold Period Freq.
                </span>
                <lv-xl-label [field]="'DVD_PROT_THR_FRQ'"></lv-xl-label>
              </div>
              <div class="lv-input-field">
                <kendo-dropdownlist
                  lvId="divProtectionThresholdPeriodFrequency"
                  [data]="view.thresholdPeriodFrequencyLookup.items"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.dividendProtection.tresholdPeriodFrequency"
                  (ngModelChange)="onModelChange()">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>
            <lv-flex-box direction="row" data-cy="DM-819">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="cashThresholdCurrencySourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Cash Threshold Currency
                </span>
                <lv-xl-label [field]="'DVD_PROT_THR_CCY'"></lv-xl-label>
              </div>
              <div class="lv-input-field">
                <kendo-dropdownlist
                  [data]="view.cashThresholdCurrencyTypeLookup.items"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.dividendProtection.cashTresholdCurrencyType"
                  (ngModelChange)="onModelChange()">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>
            <lv-flex-box direction="row" data-cy="DM-821">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="thresholdFormulaTypeSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Threshold Formula Type
                </span>
                <lv-xl-label [field]="'DVD_PROT_THR_FRML_TYPE'"></lv-xl-label>
              </div>
              <div class="lv-input-field">
                <kendo-dropdownlist
                  lvId="divProtectionThresholdFormulaType"
                  [data]="view.thresholdFormulaTypeLookup.items"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.dividendProtection.tresholdFormulaType"
                  (ngModelChange)="onIncludeColumnsChange()">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>
            <lv-flex-box direction="row" data-cy="DM-820">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="includeTriggerSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Include Trigger
                </span>
                <lv-xl-label [field]="'DVD_PROT_INCL_TRRGR'"></lv-xl-label>
              </div>
              <div class="lv-field lv-field--checkbox">
                <input
                  type="checkbox"
                  class="k-checkbox"
                  [attr.id]="view.includeTriggerId"
                  [(ngModel)]="view.dividendProtection.includeTrigger"
                  (ngModelChange)="onIncludeColumnsChange()" />
                <label
                  class="k-checkbox-label"
                  lvId="includeTriggerDvdProtection"
                  [attr.for]="view.includeTriggerId">
                </label>
              </div>
            </lv-flex-box>
          </lv-flex-box>
          <lv-flex-box class="lv-threshold-right-column">
            <lv-flex-box direction="row" data-cy="DM-814">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="currentPeriodTriggerBreachedSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span *ngIf="!isOpenedFromExcel">
                  Current Period Trigger Breached
                </span>
                <span *ngIf="isOpenedFromExcel">
                  Curr. Period Trigger Breached
                </span>
                <lv-xl-label [field]="'DVD_PROT_THR_TRGGR_BRCH'"></lv-xl-label>
              </div>
              <div class="lv-field lv-field--checkbox">
                <input
                  type="checkbox"
                  class="k-checkbox"
                  [attr.id]="view.currentPeriodTriggerBreachedId"
                  [(ngModel)]="view.dividendProtection.isCurrentPeriodTriggerBreached"
                  (ngModelChange)="onModelChange()" />
                <label class="k-checkbox-label" [attr.for]="view.currentPeriodTriggerBreachedId"></label>
              </div>
            </lv-flex-box>
            <lv-flex-box *ngIf="!view.dividendProtection.isCurrentPeriodTriggerBreached" direction="row" data-cy="DM-815">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="currentThresholdAdjustmentSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Current Threshold Adjustment
                </span>
                <lv-xl-label [field]="'DVD_PROT_THRSHLD_ADJ'"></lv-xl-label>
              </div>
              <div class="lv-input-field">
                <lv-numeric-text-box
                  name="currentThresholdAdjustment"
                  [format]="view.formatFour"
                  [decimals]="view.decimalsFour"
                  [(ngModel)]="view.dividendProtection.currentThresholdAdjustment"
                  (didBlur)="onModelChange()"
                  (didKeyDownEnter)="onModelChange()">
                </lv-numeric-text-box>
              </div>
            </lv-flex-box>
            <lv-flex-box *ngIf="!view.isCashDistribution" direction="row" data-cy="DM-817">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="carriedForwardValuePercOfCRSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Carried Forward Value (% of CR)
                </span>
                <lv-xl-label [field]="'DVD_PROT_CARRY_FWD'"></lv-xl-label>
              </div>
              <div class="lv-input-field">
                <lv-numeric-text-box
                  name="carriedForwardValue"
                  [format]="view.formatFour"
                  [decimals]="view.decimalsFour"
                  [(ngModel)]="view.dividendProtection.carriedForwardValue"
                  (didBlur)="onModelChange()"
                  (didKeyDownEnter)="onModelChange()">
                </lv-numeric-text-box>
              </div>
            </lv-flex-box>
            <lv-flex-box *ngIf="!view.isCashDistribution" direction="row" data-cy="DM-818">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="addCarryForwardOnConversionSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Add CF on Conversion
                </span>
                <lv-xl-label [field]="'DVD_PROT_ADD_CARRY_FWD'"></lv-xl-label>
              </div>
              <div class="lv-field lv-field--checkbox">
                <input
                  type="checkbox"
                  class="k-checkbox"
                  [attr.id]="view.addCarryForwardOnConversionId"
                  [(ngModel)]="view.dividendProtection.addCarryForwardOnConversion"
                  (ngModelChange)="onModelChange()" />
                <label class="k-checkbox-label" [attr.for]="view.addCarryForwardOnConversionId"></label>
              </div>
            </lv-flex-box>
            <lv-flex-box direction="row" data-cy="DM-816">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="previousYearDividendsSumSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Previous Year Dividend Sum
                </span>
                <lv-xl-label [field]="'DVD_PROT_DIV_SUM'"></lv-xl-label>
              </div>
              <div class="lv-input-field">
                <lv-numeric-text-box
                  name="previousYearDividendSum"
                  [format]="view.formatFour"
                  [decimals]="view.decimalsFour"
                  [(ngModel)]="view.dividendProtection.previousYearDividendSum"
                  (didBlur)="onModelChange()"
                  (didKeyDownEnter)="onModelChange()">
                </lv-numeric-text-box>
              </div>
            </lv-flex-box>
          </lv-flex-box>
        </lv-flex-box>
        <lv-flex-box *ngIf="view.isFullProtection" direction="row">
          <lv-flex-box class="lv-threshold-left-column">
            <lv-flex-box direction="row" data-cy="DM-417">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="thresholdTypeSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Threshold Type
                </span>
                <lv-xl-label [field]="'DVD_PROT_THR_TYPE'"></lv-xl-label>
              </div>
                <div class="lv-input-field lv-input-field--auto">
                  <kendo-dropdownlist
                    lvId="divProtectionThresholdType"
                    [data]="view.thresholdTypeLookup.items"
                    valueField="id"
                    textField="text"
                    [valuePrimitive]="true"
                    [popupSettings]="{ width: 115 }"
                    [(ngModel)]="view.dividendProtection.tresholdType"
                    (ngModelChange)="onThresholdTypeOrScheduleChange()">
                  </kendo-dropdownlist>
                </div>
              </lv-flex-box>
          </lv-flex-box>
          <lv-flex-box class="lv-threshold-right-column">
            <lv-flex-box *ngIf="!view.isCashDistribution" direction="row" data-cy="DM-817">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="carriedForwardValuePercOfCRSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Carried Forward Value (% of CR)
                </span>
                <lv-xl-label [field]="'DVD_PROT_CARRY_FWD'"></lv-xl-label>
              </div>
                <div class="lv-input-field">
                  <lv-numeric-text-box
                    name="carriedForwardValue"
                    [format]="view.formatFour"
                    [decimals]="view.decimalsFour"
                    [(ngModel)]="view.dividendProtection.carriedForwardValue"
                    (didBlur)="onModelChange()"
                    (didKeyDownEnter)="onModelChange()">
                  </lv-numeric-text-box>
                </div>
              </lv-flex-box>
              <lv-flex-box *ngIf="!view.isCashDistribution" direction="row" data-cy="DM-818">
                <div class="lv-label lv-label-field"
                  showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="addCarryForwardOnConversionSourceTooltip"
                  filter="span:not(lv-xl-label span)">
                  <span>
                    Add CF on Conversion
                  </span>
                  <lv-xl-label [field]="'DVD_PROT_ADD_CARRY_FWD'"></lv-xl-label>
                </div>
                <div class="lv-field lv-field--checkbox">
                  <input
                    type="checkbox"
                    class="k-checkbox"
                    [attr.id]="view.addCarryForwardOnConversionId"
                    [(ngModel)]="view.dividendProtection.addCarryForwardOnConversion"
                    (ngModelChange)="onModelChange()" />
                  <label class="k-checkbox-label" [attr.for]="view.addCarryForwardOnConversionId"></label>
                </div>
              </lv-flex-box>
          </lv-flex-box>
        </lv-flex-box>
        <lv-flex-box *ngIf="!view.isFullProtection">
          <lv-flex-box direction="row">
            <div showAfter="1500"
            kendoTooltip 
            [tooltipTemplate]="dvdProtectionScheduleTooltip"
             class="lv-label lv-label-field lv-label--title"
             filter=".lv-label"> DIVIDEND PROTECTION SCHEDULE</div>
          </lv-flex-box>
          <lv-dividend-protection-schedule #dividendProtectionSchedule
            [model]="view.dividendProtection.schedule"
            [dividendProtectionCustom]="view.dividendProtectionCustom"
            (didDividendProtectionScheduleChange)="onThresholdTypeOrScheduleChange()">
          </lv-dividend-protection-schedule>
        </lv-flex-box>
      </lv-fieldset-panel>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<ng-template #dvdProtectionScheduleTooltip>
  <lv-tooltip dm="dM-834"></lv-tooltip>
</ng-template>
<ng-template #dividendProtectionSourceTooltip>
  <lv-tooltip dm="dM-414"></lv-tooltip>
</ng-template>
<ng-template #dividendProtectionTypeSourceTooltip>
  <lv-tooltip dm="dM-415"></lv-tooltip>
</ng-template>
<ng-template #protectionStartDateSourceTooltip>
  <lv-tooltip dm="dM-832"></lv-tooltip>
</ng-template>
<ng-template #protectionEndDateSourceTooltip>
  <lv-tooltip dm="dM-833"></lv-tooltip>
</ng-template>
<ng-template #adjustmentFormulaTypeSourceTooltip>
  <lv-tooltip dm="dM-822"></lv-tooltip>
</ng-template>
<ng-template #spotLevelAtAdjustmentFormulaSourceTooltip>
  <lv-tooltip dm="dM-823"></lv-tooltip>
</ng-template>
<ng-template #conversionRatioCapSourceTooltip>
  <lv-tooltip dm="dM-824"></lv-tooltip>
</ng-template>
<ng-template #minimumAdjustmentTypeSourceTooltip>
  <lv-tooltip dm="dM-825"></lv-tooltip>
</ng-template>
<ng-template #minimumAdjustmentThresholdSourceTooltip>
  <lv-tooltip dm="dM-826"></lv-tooltip>
</ng-template>
<ng-template #twoWayConversionPriceAdjustmentSourceTooltip>
  <lv-tooltip dm="dM-827"></lv-tooltip>
</ng-template>
<ng-template #cashDistributionDateSourceTooltip>
  <lv-tooltip dm="dM-828"></lv-tooltip>
</ng-template>
<ng-template #percentageOfPassThroughSourceTooltip>
  <lv-tooltip dm="dM-829"></lv-tooltip>
</ng-template>
<ng-template #maximumPaymentSourceTooltip>
  <lv-tooltip dm="dM-830"></lv-tooltip>
</ng-template>
<ng-template #carriedForwardValueSourceTooltip>
  <lv-tooltip dm="dM-831"></lv-tooltip>
</ng-template>
<ng-template #thresholdTypeSourceTooltip>
  <lv-tooltip dm="dM-417"></lv-tooltip>
</ng-template>
<ng-template #thresholdPeriodFrequencySourceTooltip>
  <lv-tooltip dm="dM-418"></lv-tooltip>
</ng-template>
<ng-template #cashThresholdCurrencySourceTooltip>
  <lv-tooltip dm="dM-819"></lv-tooltip>
</ng-template>
<ng-template #thresholdFormulaTypeSourceTooltip>
  <lv-tooltip dm="dM-821"></lv-tooltip>
</ng-template>
<ng-template #includeTriggerSourceTooltip>
  <lv-tooltip dm="dM-820"></lv-tooltip>
</ng-template>
<ng-template #currentPeriodTriggerBreachedSourceTooltip>
  <lv-tooltip dm="dM-814"></lv-tooltip>
</ng-template>
<ng-template #addCarryForwardOnConversionSourceTooltip>
  <lv-tooltip dm="dM-818"></lv-tooltip>
</ng-template>
<ng-template #currentThresholdAdjustmentSourceTooltip>
  <lv-tooltip dm="dM-815"></lv-tooltip>
</ng-template>
<ng-template #carriedForwardValuePercOfCRSourceTooltip>
  <lv-tooltip dm="dM-817"></lv-tooltip>
</ng-template>
<ng-template #previousYearDividendsSumSourceTooltip>
  <lv-tooltip dm="dM-816"></lv-tooltip>
</ng-template>

