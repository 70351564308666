import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, PercentPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Kendo
import { PopupModule } from '@progress/kendo-angular-popup';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { MenusModule } from '@progress/kendo-angular-menu';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

// Services
import { ResizeHandlerService } from '@lv-core-ui/services';
import { LvTimeZoneService } from './services/lv-time-zone/lv-time-zone.service';

// Layout
import { LvFlexBoxComponent } from './components/layout/lv-flex-box/lv-flex-box.component';

// Components
import { LvNegativeLabelComponent } from './components/lv-negative-label/lv-negative-label.component';
import { LvValidationMessageComponent } from './components/lv-validation-message/lv-validation-message.component';
import { EmailValidatorDirective } from './validators/email.directive';
import { PasswordValidatorDirective } from './validators/password.directive';
import { EqualValidatorDirective } from './validators/equal.directive';
import { LvGridContextMenuComponent } from './components/lv-grid-context-menu/lv-grid-context-menu.component';
import { LvMenuComponent } from './components/lv-menu/lv-menu.component';
import { BreakGridDirective } from './directives/break-grid/break-grid.directive';
import { LvPublisherPanelComponent } from './components/lv-notification-panel/lv-publisher-panel/lv-publisher-panel.component';
import { LvSubscriberPanelComponent } from './components/lv-notification-panel/lv-subscriber-panel/lv-subscriber-panel.component';
import { LvGridLoaderComponent } from './components/lv-grid-loader/lv-grid-loader.component';
import { LvDropdownLoaderComponent } from './components/lv-dropdown-loader/lv-dropdown-loader.component';
import { LvLoaderComponent } from './components/lv-loader/lv-loader.component';
import { LvMultiSelectComponent } from './components/lv-multi-select/lv-multi-select.component';
import { LvFloatingLabelComponent } from './components/lv-floating-label/lv-floating-label.component';
import { LvSidePanelComponent } from './components/lv-side-panel/lv-side-panel.component';
import { LvSettingsWidgetComponent } from './components/lv-settings-widget/lv-settings-widget.component';
import { LvInputFieldComponent } from './components/lv-input-field/lv-input-field.component';
import { LvGridLayoutComponent } from './components/lv-grid-layout/lv-grid-layout.component';
import { LvEditorPanelComponent } from './components/lv-editor-panel/lv-editor-panel.component';
import { LvNumericTextBoxComponent } from './components/lv-numeric-text-box/lv-numeric-text-box.component';
import { LvTextBoxComponent } from './components/lv-text-box/lv-text-box.component';
import { LvLoaderPanelComponent } from './components/lv-loader-panel/lv-loader-panel.component';
import { LvWorkspacePanelComponent } from './components/lv-workspace-panel/lv-workspace-panel.component';
import { LvWorkspaceComponent } from './components/lv-workspace-panel/lv-workspace/lv-workspace.component';
// tslint:disable-next-line:max-line-length
import { LvWorkspacePanelDialogComponent } from './components/lv-workspace-panel/lv-workspace-panel-dialog/lv-workspace-panel-dialog.component';
import { LvFieldsetPanelComponent } from './components/lv-fieldset-panel/lv-fieldset-panel.component';
import { LvAdvancedGridComponent } from './components/lv-advanced-grid/lv-advanced-grid.component';
import { LvDatepickerComponent } from './components/lv-datepicker/lv-datepicker.component';
import { LvSettingsSidePanelComponent } from './components/lv-settings-side-panel/lv-settings-side-panel.component';
import { LvAdvancedCopyPasteGridComponent } from './components/lv-advanced-copy-paste-grid/lv-advanced-copy-paste-grid.component';

// Directives
import { ComponentLocationDirective } from './directives/component-location/component-location.directive';
import { LeversysIdDirective } from './directives/leversys-id/leversys-id.directive';
import { LvAuthorizeDirective } from './directives/lv-authorize/lv-authorize.directive';
import { LvGridLayoutCellDirective } from './components/lv-grid-layout/lv-grid-layout-cell/lv-grid-layout-cell.directive';
import { LvScrollToDirective } from './directives/lv-scroll-to/lv-scroll-to.directive';
import { LvDirtyDirective } from './directives/lv-dirty/lv-dirty.directive';
import { GridCellCotextMenuDirective } from './directives/advanced-grid/grid-cell-context-menu.directive';

// Advanced Grid Directives
import { GridCellEditingDirective } from './directives/advanced-grid/grid-cell-editing.directive';
import { GridKeyboardRemoveDirective } from './directives/advanced-grid/grid-keyboard-remove.directive';
import { GridRowEditingDirective } from './directives/advanced-grid/grid-row-editing.directive';
import { GridAreaSelectionDirective } from './directives/advanced-grid/grid-area-selection.directive';
import { GridContextMenuDirective } from './directives/advanced-grid/grid-context-menu.directive';
import { GridPasteDirective } from './directives/advanced-grid/grid-paste.directive';
import { LvDetectScrollDirective } from './directives/lv-detect-scroll/lv-detect-scroll.directive';
// Pipes
import { SearchArrayPipe } from './pipes/search-array/search-array.pipe';
import { EnumPipe } from './pipes/enum/enum.pipe';
import { LvPercentPipe } from './pipes/lv-percent/lv-percent.pipe';
import { LvLocalDatePipe } from './pipes/lv-local-date/lv-local-date.pipe';
import { LvDecimalPipe } from './pipes/lv-decimal/lv-decimal.pipe';
import { LvYesNoPipe } from './pipes/lv-yes-no/lv-yes-no.pipe';
import { LvAccessScopeDirective } from './directives/lv-access-scope/lv-access-scope.directive';
import { LvTooltipComponent } from './components/lv-tooltip/lv-tooltip.component';
import { LvMinDateDirective } from './directives/date-formatting/lv-date-formatting.directive';

export const VALIDATORS: any[] = [
  EmailValidatorDirective,
  PasswordValidatorDirective,
  EqualValidatorDirective
];

export const DIRECTIVES: any[] = [
  LvAccessScopeDirective,
  BreakGridDirective,
  ComponentLocationDirective,
  LeversysIdDirective,
  LvAuthorizeDirective,
  LvGridLayoutCellDirective,
  LvScrollToDirective,
  LvDirtyDirective,
  LvDetectScrollDirective,
  // Advanced Grid Directives
  GridAreaSelectionDirective,
  GridCellEditingDirective,
  GridKeyboardRemoveDirective,
  GridRowEditingDirective,
  GridContextMenuDirective,
  GridPasteDirective,
  GridCellCotextMenuDirective,
  LvMinDateDirective
];

export const ENTRY_COMPONENTS: any[] = [
  LvPublisherPanelComponent,
  LvSubscriberPanelComponent,
  LvWorkspacePanelDialogComponent
];

export const COMPONENTS = [
  // Layout Components
  LvFlexBoxComponent,

  // Components
  LvNegativeLabelComponent,
  LvValidationMessageComponent,
  LvGridContextMenuComponent,
  LvMenuComponent,
  LvGridLoaderComponent,
  LvDropdownLoaderComponent,
  LvLoaderComponent,
  LvMultiSelectComponent,
  LvFloatingLabelComponent,
  LvSidePanelComponent,
  LvSettingsSidePanelComponent,
  LvSettingsWidgetComponent,
  LvInputFieldComponent,
  LvGridLayoutComponent,
  LvEditorPanelComponent,
  LvNumericTextBoxComponent,
  LvTextBoxComponent,
  LvLoaderPanelComponent,
  LvWorkspacePanelComponent,
  LvWorkspaceComponent,
  LvWorkspacePanelDialogComponent,
  LvFieldsetPanelComponent,
  LvAdvancedGridComponent,
  LvAdvancedCopyPasteGridComponent,
  LvDatepickerComponent,
  LvTooltipComponent,
  // Entry Components
  ...ENTRY_COMPONENTS,
  // Directives
  ...DIRECTIVES,
  // Validators
  ...VALIDATORS
];

export const PIPES: any[] = [
  SearchArrayPipe,
  EnumPipe,
  LvPercentPipe,
  LvLocalDatePipe,
  LvYesNoPipe,
  LvLocalDatePipe,
  LvDecimalPipe
];

export const PROVIDERS = [
  ResizeHandlerService,
  LvTimeZoneService
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Kendo Modules
    PopupModule,
    TextBoxModule,
    SortableModule,
    ButtonModule,
    InputsModule,
    GridModule,
    DateInputsModule,
    DropDownsModule,
    MenusModule,
    TooltipModule
  ],
  declarations: [
    ...COMPONENTS,
    ...PIPES
  ],
  exports: [
    ...COMPONENTS,
    ...PIPES
  ],
  providers: [
    ...PROVIDERS,
    ...PIPES,
    DecimalPipe,
    PercentPipe
  ]
})
export class LeversysCoreUIModule { }
