/**
 * Types of borrow source.
 */
export enum BorrowSource {
  Flat = 'Flat',
  Rebate = 'Rebate',
  TermStructure = 'TermStructure'
}

/**
 * Borrow source descriptions.
 */
export enum BorrowSourceDescription {
  Flat = 'Flat',
  Rebate = 'Rebate',
  TermStructure = 'Term Structure'
}
