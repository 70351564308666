import { ChangeDetectionStrategy, Component, HostBinding, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IInstrumentSourceSectionSettings, IInstrumentSourceSettings } from '@lv-analytics/models';

/**
 * Instrument source settings component.
 */
@Component({
  selector: 'lv-instrument-source-settings',
  templateUrl: './lv-instrument-source-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvInstrumentSourceSettingsComponent implements OnInit {

  @Input() settings: IInstrumentSourceSettings;

  constructor() {
    this.settings = {
      systemDefaults: {} as IInstrumentSourceSectionSettings,
      environments: []
    } as IInstrumentSourceSettings;
   }

    @HostBinding('class.lv-section-settings-instrument-source-settings-additional-padding-bottom')
    get isSectionSettingsAdditionalPaddingBottom() {
      return true;
    }

    @HostBinding('class.lv-section-settings-instrument-source-settings-padding-margin')
    get isSectionSettingsAdditionalPaddingTop() {
      return true;
    }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit(): void {
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
   getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }
}
