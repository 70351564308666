import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';

import { Observable } from 'rxjs';

import { DeviceDetectorService, BROWSERS } from 'ngx-device-detector';
import { LocalStorage } from '@lv-core-ui/util';

@Injectable({
  providedIn: 'root'
})
export class BrowserSupportGuard  {

  constructor(
    private router: Router,
    private deviceDetectorService: DeviceDetectorService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkBrowser();
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkBrowser();
  }

  private checkBrowser(): boolean {
    const deviceInfo = this.deviceDetectorService.getDeviceInfo();

    if (deviceInfo.browser !== BROWSERS.CHROME
      && deviceInfo.browser !== BROWSERS.FIREFOX
      && deviceInfo.browser !== BROWSERS.MS_EDGE_CHROMIUM) {
      LocalStorage.clear();
      this.router.navigate(['/not-supported-browser']);
      return false;
    }

    return true;
  }
}
