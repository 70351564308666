import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input,
  HostBinding, ViewChild, OnDestroy, ChangeDetectorRef, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Subscription, Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { CreateFormGroupArgs, GridComponent, CellClickEvent } from '@progress/kendo-angular-grid';

import { constants } from './../../util/constants';
import { LvUtil } from '../../util/util';
import { LvError } from '../../models/lv-error/base';
import { LvAdvancedGridColumn } from './lv-advanced-grid';
import { GridAreaSelectionDirective, IGridAreaSelectionMap,
  IGridAreaSelectionChangeEvent } from '../../directives/advanced-grid/grid-area-selection.directive';
import { GridKeyboardRemoveDirective } from '../../directives/advanced-grid/grid-keyboard-remove.directive';
import { GridRowEditingDirective } from '../../directives/advanced-grid/grid-row-editing.directive';
import { GridCellEditingDirective } from '../../directives/advanced-grid/grid-cell-editing.directive';
import { GridContextMenuDirective, IGridContextMenuSelectEvent,
  GridContextMenuItem } from '../../directives/advanced-grid/grid-context-menu.directive';
import { GridPasteDirective, IGridPasteEvent } from '../../directives/advanced-grid/grid-paste.directive';
import { IGridCellContextMenu } from '../../directives/advanced-grid/grid-cell-context-menu.directive';

@Component({
  selector: 'lv-advanced-grid',
  templateUrl: './lv-advanced-grid.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvAdvancedGridComponent implements OnInit, OnChanges, OnDestroy, IGridCellContextMenu {

  @ViewChild(GridAreaSelectionDirective, { static: true }) areaSelection: GridAreaSelectionDirective;
  @ViewChild(GridKeyboardRemoveDirective, { static: true }) keyboardRemove: GridKeyboardRemoveDirective;
  @ViewChild(GridCellEditingDirective, { static: true }) cellEditing: GridCellEditingDirective;
  @ViewChild(GridRowEditingDirective, { static: true }) rowEditing: GridRowEditingDirective;
  @ViewChild(GridContextMenuDirective, { static: true }) contextMenu: GridContextMenuDirective;
  @ViewChild(GridPasteDirective, { static: true }) paste: GridPasteDirective;
  @ViewChild(GridComponent, { static: true }) grid: GridComponent;

  @Input() columns: LvAdvancedGridColumn[];
  @Input() records: any[];

  @Input() skipRecordOnDeleteFn: (record: any) => boolean;

  @Input() sortBy: string;
  @Input() sortComparerFn: (value: any) => any;

  @Input() createFormGroup: (args: CreateFormGroupArgs) => FormGroup;

  @Input() parseFn: (records: string[]) => any[];
  @Input() editable: boolean;

  @Output() didDataChange: EventEmitter<any[]>;
  @Output() doReload: EventEmitter<void>;
  @Output() didDropdownItemSelected: EventEmitter<string>;
  @Output() didError: EventEmitter<LvError>;

  @Input() lvId: string;

  @Input() scrollRows: number;
  @Input() width: number;
  @Input() height: number;

  @Input() contextMenuItems: GridContextMenuItem[];
  @Input() selectedColumns: any;
  @Input() editDisabled: boolean;

  @Input() showXlLabel: boolean;
  @Input() excelFieldAlias: string;
  @Input() isFieldFromExcelEnabled: boolean;
  @Input() useNegativeLabel : boolean;
  @Input() hasThreeDecimalPlaces : boolean;

  get rowColsMap(): IGridAreaSelectionMap {
    return this._selectedArea ? this._selectedArea.rowColsMap : {};
  }

  get recordItems(): any[] {
    return this._records;
  }

  get columnItems(): LvAdvancedGridColumn[] {
    return this._columns;
  }

  get isDeleteMenuItemVisible(): boolean {
    return this._records.length > 1;
  }

  get editorPopupOpened(): Observable<boolean> {
    return this._editorPopupOpened
      .pipe(
        debounceTime(100)
      );
  }

  get isScrollable(): boolean {
    return this.isVerticalScrollable || this.width !== null;
  }

  get isVerticalScrollable(): boolean {
    return this._records.length > this.scrollRows;
  }

  get scrollMode(): string {
    return this.isScrollable ? 'scrollable' : 'none';
  }

  get gridWidth(): string {
    return this.width ? `${this.width}px` : 'auto';
  }

  get gridHeight() {
    return this.isVerticalScrollable ? `${this.height}px` : 'auto';
  }

  get self(): IGridCellContextMenu {
    return this;
  }

  recordIdField: string;
  constants = constants;

  private _selectedArea: IGridAreaSelectionChangeEvent;
  private _subscriptions: Subscription[];

  private _columns: LvAdvancedGridColumn[];
  private _records: any[];

  private _editorPopupOpened: Subject<boolean>;

  private _contextMenuOpenedEvent: IGridContextMenuSelectEvent;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.editable = true;
    this.scrollRows = 6;
    this.width = null;
    this.height = 182;
    this._records = [];
    this._columns = [];
    this.recordIdField = '_recordId_';
    this.editDisabled = false;

    this.columns = [];
    this.records = [];

    this.skipRecordOnDeleteFn = null;

    this._editorPopupOpened = new Subject<boolean>();

    this.didDataChange = new EventEmitter<any[]>();
    this.doReload = new EventEmitter<void>();
    this.didError = new EventEmitter<LvError>();
    this.didDropdownItemSelected = new EventEmitter<string>();

    this._subscriptions = [];
    this.showXlLabel = false;
    this.isFieldFromExcelEnabled = true;
  }

  @HostBinding('class.lv-advanced-grid')
  get isLvAdvancedGrid(): boolean {
    return true;
  }

  ngOnInit() {
    this._subscriptions = [
      this.areaSelection.didSelectionAreaChanged.subscribe(a => this.onSelectionAreaChanged(a)),
      this.keyboardRemove.doRemove.subscribe(rowIndex => this.onRemoveRows(rowIndex, false)),

      this.cellEditing.didSaveCell.subscribe(() => this.onSaveCell()),
      this.cellEditing.didEditCell.subscribe(() => this.detectChanges()),
      this.cellEditing.didCloseCell.subscribe(() => this.detectChanges()),

      this.rowEditing.didAddRow.subscribe((rec: any) => this.onAddRow(rec)),
      this.rowEditing.didEditRow.subscribe(() => this.detectChanges()),
      this.rowEditing.didCloseRow.subscribe(() => this.detectChanges()),
      this.rowEditing.didValidateRow.subscribe(() => this.detectChanges()),

      this.contextMenu.didSelectMenuItem.subscribe((evt: IGridContextMenuSelectEvent) => this.onSelectMenuItem(evt)),
      this.contextMenu.didContextMenuOpen.subscribe((evt: IGridContextMenuSelectEvent) => this.onContextMenuOpen(evt)),

      this.paste.didParse.subscribe((evt: IGridPasteEvent) => this.onParse(evt))
    ];
  }

  ngOnChanges() {
    this.init();
  }

  updateColumns(update: (c: LvAdvancedGridColumn) => void) {
    this._columns.forEach(c => update(c));
    this.detectChanges();
  }

  onPopupToggled(opened: boolean) {
    this._editorPopupOpened.next(opened);
  }

  applyChanges(update: (records: any[]) => void) {
    /*
    * Apply rowEditing first because cell editing
    * removes last record
    */
    let dataChanged = false;
    if (this.rowEditing.applyChanges()) {
      this.setNewRecordId();
      dataChanged = true;
    }

    if (this.cellEditing.applyChanges()) {
      this.removeLastRecord();
      dataChanged = true;
    }

    if (dataChanged) {
      this.sortRecords();

      this.detectChanges();

      update(this.copyRecords());
    }
  }

  isContextMenuItemVisible(actionVisible: (event: IGridContextMenuSelectEvent) => boolean) {
    if (!this._contextMenuOpenedEvent) {
      return false;
    }

    return actionVisible(this._contextMenuOpenedEvent);
  }

  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  onEditCellContextMenuClick(event: MouseEvent) {
    this.grid.cellClick.next({
      type: 'contextmenu',
      columnIndex: this.grid.activeCell.colIndex,
      rowIndex: this.cellEditing.currentRowIndex,
      dataItem: this.grid.activeCell.dataItem,
      originalEvent: event
    } as CellClickEvent);
  }

  onDropdownCellValueCange(event: string) {
    this.didDropdownItemSelected.next(event);
  }

  /**
   * Get excel field alias for xl label tooltip
   * @param columnIndex Column index value
   * @returns Xl label field alias for xl label tooltip
   */
  getExcelFieldAlias(columnIndex: number = null): string {
    const excelFieldAliasValue = this.excelFieldAlias.split(',');
    if (columnIndex !== null && excelFieldAliasValue.length > 1) {
      return excelFieldAliasValue[columnIndex] ?? '';
    }
    else {
      return this.excelFieldAlias;
    }
  }

  /**
   * Should tooltip be showed in case of multiple alias for one grid
   * @param columnIndex Column index value
   * @returns Flag that describes should xl label be showed
   */
  shouldShowXlLabel(columnIndex: number): boolean {
    const excelFieldAliasValue = this.excelFieldAlias.split(',');
    if (columnIndex !== null && excelFieldAliasValue.length > 1) {
      return !!excelFieldAliasValue[columnIndex];
    }
    else {
      return true;
    }
  }

  /**
   * Method checks if grid numeric value is negative
   * @param value grid value
   * @param columnIndex column index
   * @returns 
   */
  isNegative(value: any) {
    return !Number.isNaN(value) && Number(value) < 0 && this.useNegativeLabel;
  }

  private init() {
    if (!this.parseFn) {
      this.parseFn = () => [];
    }

    this._columns = this.columns.map(a => LvUtil.cloneClass(a));

    this.initRecords(this.records);
    this.areaSelection.clearSelection();
  }

  private fireChangeEvent() {
    this.didDataChange.next(this.copyRecords());
  }

  private detectChanges() {
    this._changeDetectorRef.detectChanges();
  }

  private onSelectionAreaChanged(evt: IGridAreaSelectionChangeEvent) {
    this._selectedArea = evt;
    this.detectChanges();
  }

  private onRemoveRows(rowIndex: number, removeAll = false) {
      if (this.editable && !this.editDisabled ) {
        this._records = this._records.filter((a, i) => {
          if (!a[this.recordIdField]) {
            return true;
          }

          if (this.skipRecordOnDeleteFn && this.skipRecordOnDeleteFn(a)) {
            return true;
          }

          if (removeAll) {
            return false;
          }

          if (!this._selectedArea) {
            return i !== rowIndex;
          }
          else {
            return i < this._selectedArea.rowFrom || i > this._selectedArea.rowTo;
          }
        });

        this._records.pop();
        this.sortRecords();

        this.areaSelection.clearSelection();

        this.detectChanges();
        this.fireChangeEvent();
    }
  }

  private onSaveCell() {
    this.removeLastRecord();

    this.sortRecords();

    this.detectChanges();
    this.fireChangeEvent();
  }

  private onAddRow(rec: any) {
    this.setNewRecordId();

    this.sortRecords();

    this.detectChanges();
    this.fireChangeEvent();
  }

  private onSelectMenuItem(evt: IGridContextMenuSelectEvent) {
    const found = (this.contextMenuItems || [])
      .find(a => a.actionId === evt.item.data);

    if (found) {
      found.action(evt);
      return;
    }

    switch (evt.item.data) {
      case 'delete': {
        this.onRemoveRows(evt.rowIndex, false);
        break;
      }
      case 'delete-all': {
        this.onRemoveRows(evt.rowIndex, true);
        break;
      }
      // case 'reload': {
      //   this.doReload.next();
      //   break;
      // }
    }
  }

  public onParseDataDialog(records: any[]) {
    this.initRecords(records);

    this.detectChanges();
    this.fireChangeEvent();
  }

  private onContextMenuOpen(evt: IGridContextMenuSelectEvent) {
    this._contextMenuOpenedEvent = evt;
    this._changeDetectorRef.detectChanges();
  }

  private onParse(evt: IGridPasteEvent) {
    if (this.editDisabled) {
      return;
    }

    if (evt.error) {
      this.didError.next(evt.error);
      return;
    }

    this.initRecords(evt.records);

    this.detectChanges();
    this.fireChangeEvent();
  }

  private initRecords(records: any[]) {
    this._records = records.map(a => ({ ...a, [this.recordIdField]: this.getRandomId() }));
    this.sortRecords();
  }

  private sortRecords() {
    if (this.sortBy) {
      this._records.sort(LvUtil.sortBy(this.sortBy, false, this.sortComparerFn));
    }

    if (this.editable) {
      this.addEmptyRecord();
    }
  }

  private addEmptyRecord() {
    this._records.push({ [this.recordIdField]: null});
  }

  private setNewRecordId() {
    const found = this._records.find(a => !a[this.recordIdField]);
    found[this.recordIdField] = this.getRandomId();
  }

  private removeLastRecord() {
    this._records.pop();
  }

  private copyRecords(): any[] {
    return this._records
      .filter(rec => rec[this.recordIdField])
      .map(rec => {
        const copy = { ...rec };
        delete copy[this.recordIdField];
        return copy;
      });
  }

  private getRandomId(): number {
    return Math.floor(Math.random() * 10000);
  }
}
