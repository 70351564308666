import { ChangeDetectionStrategy, Component, ViewEncapsulation, Input } from '@angular/core';
import { LvDataMaster } from '@lv-core-ui/models/lv-data-master';
@Component({
  selector: 'lv-tooltip',
  templateUrl: './lv-tooltip.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})

/**
 * class in charge of displaying tooltps
 */
export class LvTooltipComponent {

  @Input() dm: string;

  constructor() {
    this.dm = '';
  }

  /**
   * method in charge of getting tooltip from DataMaster model
   * @returns tooltip content for given DM
   */
  getTooltipByDm(): string {
    return LvDataMaster.getTooltip(this.dm);
  }
}
