<div #differentDeltaExecution>
<lv-flex-box direction="row" class="lv-different-delta-execution" data-cy="DM-1217">

  <lv-flex-box class="different-delta-execution-top-content">
    <lv-flex-box class="different-delta-execution-quantity-section">
      <!-- Convertible Quantity -->
      <lv-flex-box
        direction="row"
        data-cy="DM-1081"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="convertibleQuantityTooltip"
        filter=".lv-label"
        *ngIf="_state.isConvertibleValue">
        <div class="lv-label">Convertible Quantity</div>
        <lv-numeric-text-box
          name="view.input.convertibleQuantity"
          showNegativeLabel="true"
          format="n0"
          [disabled]="!view.hasConvertible"
          [(ngModel)]="view.input.convertibleQuantity"
          (ngModelChange)="onDifferentDeltaFieldChange()">
        </lv-numeric-text-box>
      </lv-flex-box>

      <!-- Delta -->
      <lv-flex-box
        direction="row"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="mySharedDeltaTooltip"
        filter=".lv-label-to-bottom">
        <div class="lv-label lv-label-to-bottom">Delta</div>

        <lv-flex-box data-cy="DM-3951">
          <div class="lv-label lv-label-top">My</div>
          <lv-numeric-text-box name="myDelta"

            format="n3"
            [disabled]="!view.hasConvertible"
            [(ngModel)]="view.input.myDelta"
            (ngModelChange)="onDifferentDeltaFieldChange()">
          </lv-numeric-text-box>
        </lv-flex-box>

        <lv-flex-box  data-cy="DM-3952">
          <div class="lv-label lv-label-top">Counterparty</div>
          <lv-numeric-text-box name="cptyDelta"
            format="n3"
            [disabled]="!view.hasConvertible"
            [(ngModel)]="view.input.cptyDelta"
            (ngModelChange)="onDifferentDeltaFieldChange()">
          </lv-numeric-text-box>
        </lv-flex-box>

        <div class="lv-label lv-label-to-bottom lv-label-suffix">{{view.suffixDelta}}</div>

      </lv-flex-box>

      <!-- Stock Quantity -->
      <lv-flex-box
        direction="row"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="stockQuantityTooltip"
        filter=".lv-label">
        <div class="lv-label">Stock Quantity</div>
        <div class="lv-output">
          <lv-negative-label
            data-cy="DM-3953"
            [value]="view.output.myStockQuantity"
            shouldShowBorder=true
            lvId="myStockQuantity">
          </lv-negative-label>
        </div>
        <div class="lv-output">
          <lv-negative-label
            data-cy="DM-3954"
            [value]="view.output.cptyStockQuantity"
            shouldShowBorder=true
            lvId="cptyStockQuantity">
          </lv-negative-label>
        </div>
      </lv-flex-box>

      <!-- Stock CB CCY -->
      <lv-flex-box
        direction="row"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="stockCBCCYTooltip"
        filter=".lv-label-to-bottom">
        <div
          *ngIf="view.isCrossFx && _state.isStockEQCcyAndCrossFxFDde"
          class="lv-label lv-label-to-bottom">
          Stock CB CCY ({{view.cbCurrency}})
        </div>
        <div
          *ngIf="!(view.isCrossFx && _state.isStockEQCcyAndCrossFxFDde)"
          class="lv-label lv-label-to-bottom">
          Stock
        </div>

        <lv-flex-box data-cy="DM-3955">
          <div class="lv-label lv-label-top">Stock Ref</div>
          <lv-numeric-text-box name="stockRefCB"
            format="n4"
            [disabled]="!view.hasConvertible"
            [(ngModel)]="view.input.stockRefCB"
            (ngModelChange)="onStockRefCBChange()">
          </lv-numeric-text-box>
        </lv-flex-box>

        <lv-flex-box
          direction="column"
          showAfter="1500"
          kendoTooltip
          filter=".lv-label" data-cy="DM-3956">
          <div class="lv-label lv-label-top">Stock Live Price</div>
          <lv-numeric-text-box name="livePriceCB"
            format="n4"
            [disabled]="!view.hasConvertible"
            [(ngModel)]="view.input.livePriceCB"
            (ngModelChange)="onLivePriceCBChange()">
          </lv-numeric-text-box>
        </lv-flex-box>

        <lv-flex-box
          direction="column"
          showAfter="1500"
          kendoTooltip
          filter=".lv-label"
          *ngIf="!isNarrow && _state.isPriceChanges">
          <div class="lv-label lv-label-top">Price Change</div>
          <div class="lv-output lv-output--right">
            <lv-negative-label
              data-cy="DM-3957"
              [value]="view.output.priceChangeCB"
              shouldShowBorder=true
              lvId="priceChangeCB">
            </lv-negative-label>
          </div>
        </lv-flex-box>

        <div
          class="lv-label lv-label-to-bottom lv-label-suffix"
          *ngIf="!isNarrow && _state.isPriceChanges">
          %
        </div>
      </lv-flex-box>

      <!-- Stock EQ CCY -->
      <lv-flex-box
        direction="row"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="stockEQCCYTooltip"
        filter=".lv-label"
        *ngIf="view.isCrossFx">

        <ng-container *ngIf="_state.isStockEQCcyAndCrossFxFDde">
          <div class="lv-label">Stock EQ CCY ({{view.eqCurrency}})</div>
          <div data-cy="DM-3958">
            <lv-numeric-text-box name="stockRefEQ"
              format="n4"
              [disabled]="!view.hasConvertible"
              [(ngModel)]="view.input.stockRefEQ"
              (ngModelChange)="onStockRefEQChange()">
            </lv-numeric-text-box>
          </div>
          <div data-cy="DM-3959">
          <lv-numeric-text-box
            name="livePriceEQ"
            format="n4"
            [disabled]="!view.hasConvertible"
            [(ngModel)]="view.input.livePriceEQ"
            (ngModelChange)="onLivePriceEQChange()">
          </lv-numeric-text-box>
        </div>
          <ng-container *ngIf="!isNarrow && _state.isPriceChanges">
            <div
              class="lv-output lv-output--right">
              <lv-negative-label
                data-cy="DM-3960"
                [value]="view.output.priceChangeEQ"
                shouldShowBorder=true
                lvId="priceChangeEQ">
              </lv-negative-label>
            </div>

            <div
              class="lv-label lv-label-suffix">
              %
            </div>
          </ng-container>
        </ng-container>

      </lv-flex-box>

      <!-- Cross FX -->
      <lv-flex-box
        direction="row"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="crossFXTooltip"
        filter=".lv-label"
        *ngIf="view.isCrossFx && _state.isStockEQCcyAndCrossFxFDde">

        <div class="lv-label">Cross FX</div>

        <div data-cy="DM-3961">
        <lv-numeric-text-box
          name="crossFXT0"
          format="n4"
          [disabled]="!view.hasConvertible"
          [(ngModel)]="view.input.crossFXT0"
          (ngModelChange)="onCrossFXT0Change()">
        </lv-numeric-text-box>
      </div>
      <div data-cy="DM-3962">
        <lv-numeric-text-box
          name="crossFXT1"
          format="n4"
          [disabled]="!view.hasConvertible"
          [(ngModel)]="view.input.crossFXT1"
          (ngModelChange)="onCrossFXT1Change()">
        </lv-numeric-text-box>
      </div>
      </lv-flex-box>

      <!-- Narrow section -->
      <lv-flex-box *ngIf="isNarrow && _state.isPriceChanges">
        <lv-flex-box direction="row">

          <div
            *ngIf="view.isCrossFx && _state.isStockEQCcyAndCrossFxFDde"
            class="lv-label lv-label-to-bottom">
            Stock CB CCY ({{view.cbCurrency}})
          </div>
          <div
            *ngIf="!(view.isCrossFx && _state.isStockEQCcyAndCrossFxFDde)"
            class="lv-label lv-label-to-bottom">
            Stock
          </div>

          <lv-flex-box
            direction="column"
            showAfter="1500"
            kendoTooltip
            filter=".lv-label">
            <div class="lv-label lv-label-top">Price Change</div>
            <div class="lv-output lv-output--right">
              <lv-negative-label
                [value]="view.output.priceChangeCB"
                shouldShowBorder=true
                lvId="priceChangeCB"
                suffix="%">
              </lv-negative-label>
            </div>
          </lv-flex-box>
        </lv-flex-box>

        <lv-flex-box
          direction="row"
          *ngIf="view.isCrossFx && _state.isStockEQCcyAndCrossFxFDde">
          <div class="lv-label">Stock EQ CCY ({{view.eqCurrency}})</div>

          <div class="lv-output lv-output--right">
            <lv-negative-label
              [value]="view.output.priceChangeEQ"
              shouldShowBorder=true
              lvId="priceChangeEQ"
              suffix="%">
            </lv-negative-label>
          </div>
        </lv-flex-box>
      </lv-flex-box>

      <!-- Commission -->
      <lv-flex-box
        direction="row"
        class="different-delta-execution-commision"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="commissionTooltip"
        filter=".lv-label"
        *ngIf="_state.isCommission">

        <div class="lv-label">Commission</div>

        <lv-numeric-text-box
          lvId="commisionDiff"
          data-cy="DM-1082"
          name="commision"
          format="n4"
          [disabled]="!view.hasConvertible"
          [(ngModel)]="view.input.commision"
          (ngModelChange)="onCommisionChange()">
        </lv-numeric-text-box>

        <div class="lv-field lv-field--radio">
          <input type="radio" class="k-radio"
            [attr.id]="bpCommision"
            [name]="bpCommision"
            [attr.name]="bpCommision"
            value="bp"
            [(ngModel)]="view.input.commisionRadio"
            (change)="onCommisionChange()" />
          <label
            class="k-radio-label"
            lvId="bpCommisionRadio"
            [attr.for]="bpCommision">bps</label>
        </div>
        <div class="lv-field lv-field--radio">
          <input type="radio" class="k-radio"
            [attr.id]="perShareCommision"
            [attr.name]="perShareCommision"
            [name]="perShareCommision"
            value="perShare"
            [(ngModel)]="view.input.commisionRadio"
            (change)="onCommisionChange()" />
          <label class="k-radio-label"
            lvId="perShareCommisionRadio"
            [attr.for]="perShareCommision">per share</label>
        </div>
      </lv-flex-box>
    </lv-flex-box>
  </lv-flex-box>

  <lv-flex-box class="different-delta-execution-pnl-section">

    <!-- Stock to BUY/SELL -->
    <lv-flex-box
      direction="row"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="stockToBuySellTooltip"
      filter=".lv-label">
      <div class="lv-label">{{view.sharesToBuySell}}</div>

      <div class="lv-output">
        <lv-negative-label
          data-cy="DM-1083"
          [value]="view.output.stockToBuySellQty"
          shouldShowBorder=true
          lvId="stockToBuySellQty"
          suffix="Qty">
        </lv-negative-label>
      </div>
    </lv-flex-box>

    <!-- Stock to BUY/SELL -->
    <lv-flex-box
      direction="row"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="stockToBuySellCCYTooltip"
      filter=".lv-label">
      <div class="lv-label">{{view.sharesToBuySell}}</div>

      <div class="lv-output">
        <lv-negative-label
          data-cy="DM-1084"
          [value]="view.output.stockToBuySellPrice"
          shouldShowBorder=true
          lvId="stockToBuySellPrice"
          [suffix]="view.currentCurrency">
        </lv-negative-label>
      </div>
    </lv-flex-box>

    <!-- Total P&L -->
    <lv-flex-box
      direction="row"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="totalPlCCYTooltip"
      filter=".lv-label"
      *ngIf="_state.isPnLDde">
      <div class="lv-label">Total P&L</div>

      <div class="lv-output">
        <lv-negative-label
          data-cy="DM-1085"
          [value]="view.output.totalPnL"
          shouldShowBorder=true
          lvId="totalPnL"
          [suffix]="view.currentCurrency">
        </lv-negative-label>
      </div>
    </lv-flex-box>

    <!-- Total P&L -->
    <lv-flex-box
      direction="row"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="totalPlPtsTooltip"
      filter=".lv-label"
      *ngIf="_state.isPnLDde">
      <div class="lv-label">Total P&L</div>

      <div class="lv-output">
        <lv-negative-label
          data-cy="DM-1086"
          [value]="view.output.ptsPnL"
          shouldShowBorder=true
          lvId="ptsPnL"
          suffix="pts">
        </lv-negative-label>
      </div>
    </lv-flex-box>

    <!-- Radio buttons -->
    <lv-flex-box 
      data-cy="DM-1087"
      direction="row"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="displayValuesInTooltip"
      filter=".lv-label"
      class="lv-different-delta-execution-display-values-in"
      *ngIf="view.radioFields.length > 1">
      <div class="lv-label">Display Values in</div>
      <div *ngFor="let rf of view.radioFields"
        class="lv-field lv-field--radio">
        <input type="radio" class="k-radio"
          [attr.id]="rf.id"
          [attr.name]="rf.id"
          [name]="rf.id"
          [value]="rf.value"
          [(ngModel)]="view.currentCCY"
          [disabled]="!view.hasConvertible"
          (ngModelChange)="onDifferentDeltaFieldChange()" />
        <label
          class="k-radio-label"
          [lvId]="rf.lvId"
          [attr.for]="rf.id">{{ rf.label }}
        </label>
      </div>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>
</div>

<ng-template #convertibleQuantityTooltip>
  <lv-tooltip dm="dM-1081"></lv-tooltip>
</ng-template>
<ng-template #mySharedDeltaTooltip>
  <lv-tooltip dm="dM-1088"></lv-tooltip>
</ng-template>
<ng-template #stockQuantityTooltip>
  <lv-tooltip dm="dM-1089"></lv-tooltip>
</ng-template>
<ng-template #stockCBCCYTooltip>
  <lv-tooltip dm="dM-1090"></lv-tooltip>
</ng-template>
<ng-template #stockEQCCYTooltip>
  <lv-tooltip dm="dM-1091"></lv-tooltip>
</ng-template>
<ng-template #crossFXTooltip>
  <lv-tooltip dm="dM-1092"></lv-tooltip>
</ng-template>
<ng-template #commissionTooltip>
  <lv-tooltip dm="dM-1082"></lv-tooltip>
</ng-template>
<ng-template #stockToBuySellTooltip>
  <lv-tooltip dm="dM-1083"></lv-tooltip>
</ng-template>
<ng-template #stockToBuySellCCYTooltip>
  <lv-tooltip dm="dM-1084"></lv-tooltip>
</ng-template>
<ng-template #totalPlCCYTooltip>
  <lv-tooltip dm="dM-1085"></lv-tooltip>
</ng-template>
<ng-template #totalPlPtsTooltip>
  <lv-tooltip dm="dM-1086"></lv-tooltip>
</ng-template>
<ng-template #displayValuesInTooltip>
  <lv-tooltip dm="dM-1087"></lv-tooltip>
</ng-template>

