
export enum Frequency {
	Annual = 'Annual',
	SemiAnnual = 'SemiAnnual',
	Quarterly = 'Quarterly',
	Bimonthly = 'Bimonthly',
	Monthly = 'Monthly'
}

export enum FrequencyDescription {
	Annual = 'Annual',
	SemiAnnual = 'Semi Annual',
	Quarterly = 'Quarterly',
	Bimonthly = 'Bimonthly',
	Monthly = 'Monthly'
}
