<lv-flex-box class="user-settings">
  <div class="user-settings-title">
    Settings
  </div>
  <kendo-tabstrip class="lv-tabstrip user-settings-tabstrip"
    [keepTabContent]="true">

    <kendo-tabstrip-tab
      selected="true"
      title="Environments"
      cssClass="DM-1276">
      <ng-template kendoTabContent>
       <lv-environments-settings
        data-cy="DM-1276"
        (didCancel)="redirectToDashboard()"
        (didSave)="redirectToDashboard()">
       </lv-environments-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="Valuation"
      cssClass="DM-1242">
      <ng-template kendoTabContent>
        <lv-valuation-settings
          (didCancel)="redirectToDashboard()"
          (didSave)="redirectToDashboard()">
        </lv-valuation-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="Market Data"
      cssClass="DM-1245">
      <ng-template kendoTabContent>
        <lv-market-data-settings
          (didCancel)="redirectToDashboard()"
          (didSave)="redirectToDashboard()">
        </lv-market-data-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="Terms"
      cssClass="DM-1266">
      <ng-template kendoTabContent>
        <lv-terms-settings
          (didCancel)="redirectToDashboard()"
          (didSave)="redirectToDashboard()">
        </lv-terms-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="Model Customization"
      cssClass="DM-1253">
      <ng-template kendoTabContent>
        <lv-model-customization-settings
          data-cy="DM-1253"
          (didCancel)="redirectToDashboard()"
          (didSave)="redirectToDashboard()">
        </lv-model-customization-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="Estimates"
      cssClass="DM-1254">
      <ng-template kendoTabContent>
        <lv-estimates-settings
          data-cy="DM-1254"
          (didCancel)="redirectToDashboard()"
          (didSave)="redirectToDashboard()">
        </lv-estimates-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="New Issues"
      cssClass="DM-1255">
      <ng-template kendoTabContent>
        <lv-new-issue-settings
          data-cy="DM-1255"
          (didCancel)="redirectToDashboard()"
          (didSave)="redirectToDashboard()">
        </lv-new-issue-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="Notifications">
      <ng-template kendoTabContent>
        <lv-notifications-settings
          (didCancel)="redirectToDashboard()"
          (didSave)="redirectToDashboard()">
        </lv-notifications-settings>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      title="Other"
      cssClass="DM-1259">
      <ng-template kendoTabContent>
        <lv-other-settings
          data-cy="DM-1259"
          (didCancel)="redirectToDashboard()"
          (didSave)="redirectToDashboard()">
        </lv-other-settings>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</lv-flex-box>
