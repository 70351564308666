import { ContingentConversionSectionSettings, IConversionSectionSettings,
  PepsConversionSectionSettings,
  ResetSectionSettings,
  VariableConversionSectionSettings} from '../conversion-settings/conversion-section-settings';
import { ConversionSettlement, CurrencyType } from '@lv-convertible-bond/models/convertible-bond-terms';
// tslint:disable-next-line: max-line-length
import { AcquisitionOptionDateInputType } from '@lv-convertible-bond/models/convertible-bond-terms/enum-descriptions/conversion/AcquisitionOptionDateInputType';
// tslint:disable-next-line: max-line-length
import { AcquisitionSharesSettlType } from '@lv-convertible-bond/models/convertible-bond-terms/enum-descriptions/conversion/AcquisitionSharesSettlType';
// tslint:disable-next-line: max-line-length
import { AcquisitionVWAPPeriodInputType } from '@lv-convertible-bond/models/convertible-bond-terms/enum-descriptions/conversion/AcquisitionVWAPPeriodInputType';
import { CapExpressedAs, ContingentConversionPeriodType, ContingentConversionScheduleTriggerType, ContingentConversionTriggerParityLimit,
  FloorExpressedAs, PepsConversionBeforeMaturityType, QuarterType } from '@lv-convertible-bond/models/convertible-bond-terms/Enums (2)';

export const getDefaultConversionSectionSettings = (): IConversionSectionSettings => {
  return {
    keepAccrued: false,
    forfeitCouponOnConversionAtMaturity: false,
    forfeitCouponOnOptionalConversion: false,
    conversionIntoNewShares: false,
    fxAdjustedConversionAmount: false,
    isContigentConversion: false,
    isVariableConversion: false,
    isResetable: false,
    reset: getDefaultResetSectionSettings(),
    contingentConversion: getDefaultContingentConversionSectionSettings(),
    variableConversion: getDefaultVariableConversionSectionSettings(),
    pepsConversion: getDefaultPepsConversionSectionSettings(),
    recieveMakeWholeConversion: false,
    conversionSettlement: ConversionSettlement.CashOrShares,
    conversionSettlementNumberFixingDays: null,
    conversionSettlementObservPeriodStartBeforeMatDate: null,
    conversionSettlementObservPeriodStartBeforeIssuerCall: null,
    conversionSettlementEarlyConversionObservationLag: null,
    acquisitionOptionDateInputType: AcquisitionOptionDateInputType.FixedDate,
    acquisitionNoticeMinDays: null,
    acquisitionNoticeMaxDays: null,
    acquisitionVWAPPeriodInputType: AcquisitionVWAPPeriodInputType.FixedDate,
    vwapPeriodObservationLag: null,
    conversionSettlementMaximumAcquisitionShares: 100,
    acquisitionSharesSettlType: AcquisitionSharesSettlType.PrincipalPlusAS,
    acquisitionASVAdjustmentPerc: 100,
    useAcquisitionSharesSettlement: false,
    conversionStartDate: null,
    conversionEndDate: null,
    conversionPriceCCY: CurrencyType.Convertible,
    rebateCCY: CurrencyType.Convertible
  };
};

const getDefaultResetSectionSettings = (): ResetSectionSettings => {
  return {
    conversionPriceCurrencyType: CurrencyType.Convertible,
    resetCurrencyType: CurrencyType.Convertible,
    floorExpressedRelativeTo: FloorExpressedAs.PercOfInitialConversionPrice,
    capExpressedRelativeTo: CapExpressedAs.PercOfPrevailingConversionPrice,
    globalCap: 100,
    useResetAveraging: true,
    resetAveraging: {
      numberOfAveragingDays: null,
      startLag: null
    },
    globalFloor: null,
    optionalReset: false
  };
};

const getDefaultContingentConversionSectionSettings = (): ContingentConversionSectionSettings => {
  return {
    convertibleOnCall: true,
    quarterType: QuarterType.Calendar,
    triggerPeriodType: ContingentConversionPeriodType.NOutOfMDays,
    cbPriceTriggerParityLimit: ContingentConversionTriggerParityLimit.None,
    buyoutAtCoCoTrigger: false,
    convPriceTrigger: null,
    outOfDays: null,
    triggerPeriodDays: null,
    triggerType: ContingentConversionScheduleTriggerType.AsPercentOfPar
  };
};

const getDefaultVariableConversionSectionSettings = (): VariableConversionSectionSettings => {
  return {
    incrementalShareFactor: 100,
    strike: 100,
    strikeCurrency: CurrencyType.Convertible,
    capRatio: null
  };
};

const getDefaultPepsConversionSectionSettings = (): PepsConversionSectionSettings => {
  return {
    conversionRebateCurrency: CurrencyType.Convertible,
    conversionBeforeMaturityType: PepsConversionBeforeMaturityType.MinimumRatio,
    endDate: null,
    parallelPeps: false,
    startDate: null
  };
};
