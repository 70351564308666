import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, HostBinding, Input } from '@angular/core';

/**
 * Xignite component.
 */
@Component({
  selector: 'lv-xignite',
  templateUrl: './lv-xignite.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvXigniteComponent implements OnInit {

  @Input() top: number;
  @Input() right: number;
  @Input() bottom: number;
  @Input() left: number;

  @HostBinding('class.lv-xignite')
  get isWidget() {
    return true;
  }

  @HostBinding('style.top.px')
  get topPosition() {
    return this.top;
  }

  @HostBinding('style.right.px')
  get rightPosition() {
    return this.right;
  }

  @HostBinding('style.bottom.px')
  get bottomPosition() {
    return this.bottom;
  }

  @HostBinding('style.left.px')
  get leftPosition() {
    return this.left;
  }

  constructor() {
    this.right = 20;
    this.bottom = 20;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }
}
