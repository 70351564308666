import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
         Input, Output, EventEmitter, ViewChild, OnDestroy, OnChanges, Optional } from '@angular/core';
import { Subscription } from 'rxjs';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { constants, LvLookupEnum } from '@lv-core-ui/util';
import { LvAdvancedGridComponent, LvAdvancedGridColumn, LvAdvancedGridDateColumn,
         LvAdvancedGridEnumColumn, LvAdvancedGridNumericColumn } from '@lv-core-ui/components';
import { LvDataMaster, LvError, LvErrorType } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { MarketDataClipboard } from '@lv-analytics/components';
import { DividendProtectionScheduleItem, DividendProtectionCustomData, DividendProtectionTresholdFormulaType,
         DividendProtectionTresholdValueType, DividendProtectionThresholdValueTypeDescription } from '@lv-convertible-bond/models';
import { LvExcelService } from '@lv-excel/services';

@Component({
  selector: 'lv-dividend-protection-schedule',
  templateUrl: './lv-dividend-protection-schedule.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvDividendProtectionScheduleComponent implements OnInit, OnDestroy, OnChanges {

  @Input() model: DividendProtectionScheduleItem[];
  @Input() dividendProtectionCustom: DividendProtectionCustomData;

  @Output() didDividendProtectionScheduleChange: EventEmitter<DividendProtectionScheduleItem[]>;
  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  excelFieldAlias = 'DVD_PROT_THR_SCHED_RANGE';

  get hasScheduleInExcelOverride(): boolean {
    return this._excelSvc && this._excelSvc.containsField(this.excelFieldAlias); 
      // || (this._excelSvc.containsField('DVD_PROT_THR')
      // && this._excelSvc.containsField('DVD_PROT_THR_VALUE_TYPE')
      // && this._excelSvc.containsField('DVD_PROT_THR_FRQ')));
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAlias)?.editable;
  }

  private _modelSubscription: Subscription[];

  columns: LvAdvancedGridColumn[];
  dividendProtectionScheduleItems: DividendProtectionScheduleItem[];
  parseFn: any;
  sufix: string;

  formatSix = '#,###.######';
  decimalSix = '6';

  constructor(
    private _errorService: LvErrorService,
    @Optional() private _excelSvc: LvExcelService ) {

    this.parseFn = this.parserFunction.bind(this);

    this.dividendProtectionScheduleItems = [];
    this._modelSubscription = [];
    this.didDividendProtectionScheduleChange = new EventEmitter<DividendProtectionScheduleItem[]>();
  }

  ngOnInit() {
    this._modelSubscription = [
      this.advancedGrid.didDataChange.subscribe((records: DividendProtectionScheduleItem[]) => this.onScheduleChange(records)),
      this.advancedGrid.doReload.subscribe(() => this.onScheduleReload()),
      this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];

    this.init();
  }

  ngOnChanges() {
    if (this.hasScheduleInExcelOverride) {
      this.initColumns();
    }
    this.init();
  }

  private updateColumns() {
    this.advancedGrid.updateColumns(c => this.updateColumn(c));
  }

  private updateColumn(c: LvAdvancedGridColumn) {
    if (c.field === 'triggerValue') {
      c.visible = this.dividendProtectionCustom ? this.dividendProtectionCustom.includeTrigger : false;
    }
    if (c.field === 'tresholdValue2Type' || c.field === 'tresholdValue2') {
       // tslint:disable-next-line:max-line-length
       c.visible = this.dividendProtectionCustom && this.dividendProtectionCustom.tresholdFormulaType !== DividendProtectionTresholdFormulaType.Single ?  true : false;
    }
    if (c.field === 'triggerValue2') {
      // tslint:disable-next-line:max-line-length
      c.visible = this.dividendProtectionCustom ? this.dividendProtectionCustom.includeTrigger && this.dividendProtectionCustom.tresholdFormulaType !== DividendProtectionTresholdFormulaType.Single : false;
    }

  }

  ngOnDestroy() {
    this._modelSubscription.forEach(s => s.unsubscribe());
  }

  createFormGroup(args: CreateFormGroupArgs): FormGroup {
    // tslint:disable-next-line:max-line-length
    args.dataItem.tresholdValueType = args.dataItem.tresholdValueType ? args.dataItem.tresholdValueType : DividendProtectionTresholdValueType.Cash;
    // tslint:disable-next-line:max-line-length
    args.dataItem.tresholdValue2Type = args.dataItem.tresholdValue2Type ? args.dataItem.tresholdValue2Type : DividendProtectionTresholdValueType.Cash;
    return  new FormGroup({
      'startDate': new FormControl(args.isNew ? new Date() : args.dataItem.startDate, Validators.required),
      'tresholdValueType': new FormControl(args.dataItem.tresholdValueType, Validators.required),
      'tresholdValue': new FormControl(args.dataItem.tresholdValue, Validators.required),
      'triggerValue': new FormControl(args.dataItem.triggerValue),
      'tresholdValue2Type': new FormControl(args.dataItem.tresholdValue2Type),
      'tresholdValue2': new FormControl(args.dataItem.tresholdValue2),
      'triggerValue2': new FormControl(args.dataItem.triggerValue2),
    });
  }

  applyAdvancedGridChanges() {
    this.advancedGrid.applyChanges(records => this.applyRecords(records));
  }

  private onScheduleReload() {
    this.dividendProtectionScheduleItems = this.dividendProtectionScheduleItems.map(a => ({ ...a }));
  }

  private onError(error: LvError) {
    this._errorService.handleError(error);
  }

  private onScheduleChange(scheduleItems: DividendProtectionScheduleItem[]) {
    this.applyRecords(scheduleItems);
    // this.didDividendProtectionScheduleChange.next(this.model);
  }

  private applyRecords(records: any[]) {
    this.model.splice(0, this.model.length);
    this.model.push(...records);
    this.didDividendProtectionScheduleChange.next(this.model);
  }

  private init() {
    this.initColumns();
    this.dividendProtectionScheduleItems = this.model.map(a => ({ ...a }));
  }

  private initColumns() {

    this.sufix = this.dividendProtectionCustom &&
    this.dividendProtectionCustom.tresholdFormulaType !== DividendProtectionTresholdFormulaType.Single ? '1' : '';
    this.columns = [];

    const startDateColumn = new LvAdvancedGridDateColumn();
    startDateColumn.title = 'Start Date';
    startDateColumn.field = 'startDate';
    startDateColumn.dmKey = 'DM-2324';

    const thresholdValueTypeColumn = new LvAdvancedGridEnumColumn();
    thresholdValueTypeColumn.enumDescription = DividendProtectionThresholdValueTypeDescription;
    thresholdValueTypeColumn.title = 'Threshold Value Type ' + this.sufix;
    thresholdValueTypeColumn.field = 'tresholdValueType';
    thresholdValueTypeColumn.displayField = 'text';
    thresholdValueTypeColumn.valueField = 'id';
    thresholdValueTypeColumn.valuePrimitive = true;
    thresholdValueTypeColumn.data = new LvLookupEnum(DividendProtectionThresholdValueTypeDescription).items;
    thresholdValueTypeColumn.width = 154;
    thresholdValueTypeColumn.dmKey = 'DM-2325';

    const thresholdValueColumn = new LvAdvancedGridNumericColumn();
    thresholdValueColumn.title = 'Threshold Value ' + this.sufix;
    thresholdValueColumn.field = 'tresholdValue';
    thresholdValueColumn.outputFormat = constants.numberFormat.upToSixDigits;
    thresholdValueColumn.format = this.formatSix;
    thresholdValueColumn.decimals = this.decimalSix;
    thresholdValueColumn.width = 120;
    thresholdValueColumn.dmKey = 'DM-2326';

    const triggerValueColumn = new LvAdvancedGridNumericColumn();
    triggerValueColumn.title = 'Trigger ' + this.sufix;
    triggerValueColumn.field = 'triggerValue';
    triggerValueColumn.outputFormat = constants.numberFormat.upToSixDigits;
    triggerValueColumn.format = this.formatSix;
    triggerValueColumn.decimals = this.decimalSix;
    triggerValueColumn.width = 120;
    triggerValueColumn.dmKey = 'DM-2327';

    const tresholdValue2TypeColumn = new LvAdvancedGridEnumColumn();
    tresholdValue2TypeColumn.enumDescription = DividendProtectionThresholdValueTypeDescription;
    tresholdValue2TypeColumn.title = 'Threshold Value Type 2';
    tresholdValue2TypeColumn.field = 'tresholdValue2Type';
    tresholdValue2TypeColumn.displayField = 'text';
    tresholdValue2TypeColumn.valueField = 'id';
    tresholdValue2TypeColumn.valuePrimitive = true;
    tresholdValue2TypeColumn.data = new LvLookupEnum(DividendProtectionThresholdValueTypeDescription).items;
    tresholdValue2TypeColumn.width = 154;
    tresholdValue2TypeColumn.dmKey = 'DM-2328';

    const thresholdValue2Column = new LvAdvancedGridNumericColumn();
    thresholdValue2Column.title = 'Threshold Value 2';
    thresholdValue2Column.field = 'tresholdValue2';
    thresholdValue2Column.outputFormat = constants.numberFormat.upToSixDigits;
    thresholdValue2Column.format = this.formatSix;
    thresholdValue2Column.decimals = this.decimalSix;
    thresholdValue2Column.width = 120;
    thresholdValue2Column.dmKey = 'DM-2329';

    const triggerValue2Column = new LvAdvancedGridNumericColumn();
    triggerValue2Column.title = 'Trigger 2';
    triggerValue2Column.field = 'triggerValue2';
    triggerValue2Column.outputFormat = constants.numberFormat.upToSixDigits;
    triggerValue2Column.format = this.formatSix;
    triggerValue2Column.decimals = this.decimalSix;
    triggerValue2Column.width = 120;
    triggerValue2Column.dmKey = 'DM-2330';

    this.updateColumn(triggerValueColumn);
    this.updateColumn(tresholdValue2TypeColumn);
    this.updateColumn(thresholdValue2Column);
    this.updateColumn(triggerValue2Column);

    this.columns.push(startDateColumn);
    this.columns.push(thresholdValueTypeColumn);
    this.columns.push(thresholdValueColumn);
    this.columns.push(triggerValueColumn);
    this.columns.push(tresholdValue2TypeColumn);
    this.columns.push(thresholdValue2Column);
    this.columns.push(triggerValue2Column);
  }

  private parserFunction(pastedDataRecords: string[]): DividendProtectionScheduleItem[] {
    const scheduleItems: DividendProtectionScheduleItem[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');
      const startDateValue = items[0];
      const thresholdValueTypeValue = items[1];
      const thresholdValueValue = items[2];
      const triggerValue = items[3];
      const thresholdValue2TypeValue = items[4];
      const thresholdValue2Value = items[5];
      const trigger2Value = items[6];

      const startDate = MarketDataClipboard.parseDateValue(startDateValue, 'Start Date');
      const thresholdValueType = this.parseDividendProtectionThresholdValueType(thresholdValueTypeValue);
      const thresholdValue = MarketDataClipboard.parseNumberValue(thresholdValueValue, 'Threshold Value');
      const trigger = MarketDataClipboard.tryParseNumberValue(triggerValue, 'Trigger');
      const thresholdValue2Type = this.parseDividendProtectionThresholdValueType(thresholdValue2TypeValue);
      const thresholdValue2 = MarketDataClipboard.tryParseNumberValue(thresholdValue2Value, 'Threshold Value 2');
      const trigger2 = MarketDataClipboard.tryParseNumberValue(trigger2Value, 'Trigger 2');

      scheduleItems.push({
        startDate: startDate,
        tresholdValueType: thresholdValueType,
        tresholdValue: thresholdValue,
        triggerValue: trigger,
        tresholdValue2Type: thresholdValue2Type,
        tresholdValue2: thresholdValue2,
        triggerValue2: trigger2
      } as DividendProtectionScheduleItem);
    });

    return scheduleItems;
  }

  private parseDividendProtectionThresholdValueType(thresholdValueTypeValue: string): DividendProtectionTresholdValueType {

    if (MarketDataClipboard.isEmpty(thresholdValueTypeValue)) {
      return DividendProtectionTresholdValueType.Cash;
    }

    const lvDividendProtectionThresholdValueTypeDescription = new LvLookupEnum(DividendProtectionThresholdValueTypeDescription);
    const lvDividendProtectionThresholdValueTypeDescriptionEnum = lvDividendProtectionThresholdValueTypeDescription.items.find(
      a => a.text === thresholdValueTypeValue
    );
    if (!lvDividendProtectionThresholdValueTypeDescriptionEnum) {
      const errorMessage = LvDataMaster.getErrorWithParameters('dM-2003', new Map([['{ value}', thresholdValueTypeValue], ['{field}', 'Threshold Value Type']]));
      throw new LvError(errorMessage, 'Paste Error', LvErrorType.USER_FRIENDLY);
    }

    return DividendProtectionTresholdValueType[lvDividendProtectionThresholdValueTypeDescriptionEnum.id];
  }
}
