export enum PricingType {
  Assumed = 'Assumed',
  Best = 'Best',
  Mid = 'Mid',
  Worst = 'Worst',
  AssumedRedemption = 'AssumedRedemption',
  BestRedemption = 'BestRedemption',
  WorstRedemption = 'WorstRedemption'
}

export enum SourceType {
  General = 'General',
  QuickTerms = 'QuickTerms'
}