import { Injectable } from '@angular/core';

import { TokenService } from './services/token/token.service';
import { UserService } from './services/user/user.service';

/**
 * Security service.
 */
@Injectable()
export class SecurityService {
  constructor(
    public tokenService: TokenService,
    public userService: UserService
  ) {}
}
