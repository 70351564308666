import { Component, OnInit, Input, ViewEncapsulation, ChangeDetectionStrategy, HostBinding } from '@angular/core';

@Component({
  selector: 'lv-fieldset-panel',
  templateUrl: './lv-fieldset-panel.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvFieldsetPanelComponent implements OnInit {

  @Input() legend: string;
  @Input() fullHeight: boolean;
  @Input() isLoading: boolean;

  constructor() {
    this.legend = 'Legend';
    this.fullHeight = false;
    this.isLoading = false;
  }

  @HostBinding('class.lv-fieldset-panel')
  get isLvFieldsetPanel(): boolean {
    return true;
  }

  @HostBinding('class.lv-fieldset-panel--full-height')
  get isFullHeight(): boolean {
    return this.fullHeight;
  }

  ngOnInit() {
  }

}
