/**
 * Conversion settlement.
 */
export enum ConversionSettlement {
    None = 'None',
    Cash = 'Cash',
    Shares = 'Shares',
    CashOrShares = 'CashOrShares',
    SharesBasedOnAveraging = 'SharesBasedOnAveraging',
  }
