import { Directive, Input } from '@angular/core';
import { IMarketDataComponent } from '../../components/market-data/market-data-component';

/**
 * Market data component directive.
 */
@Directive({
  selector: '[marketDataComponent]'
})
export class MarketDataComponentDirective {

  @Input() marketDataComponent: IMarketDataComponent<any>;

  constructor() { }

}
