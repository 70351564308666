<lv-flex-box direction="row" class="lv-break-even" data-cy="DM-1218">
  <lv-flex-box class="break-even-top-content">

    <!-- Convertible Quantity -->
    <lv-flex-box direction="row" showAfter="1500" kendoTooltip [tooltipTemplate]="convertibleQuantityTooltip" filter=".lv-label">
      <div class="lv-label">Convertible Quantity</div>
      <lv-numeric-text-box name="convertiblePosition"
        data-cy="DM-1069"
        format="n0"
        showNegativeLabel="true"
        [disabled]="view.isDisabled"
        [(ngModel)]="view.input.convertiblePosition"
        (ngModelChange)="onDeltaChange()">
      </lv-numeric-text-box>
    </lv-flex-box>

    <!-- Stock Quantity -->
    <lv-flex-box direction="row" showAfter="1500" kendoTooltip [tooltipTemplate]="stockQuantityTooltip" filter=".lv-label">
      <div class="lv-label">Stock Quantity</div>
      <lv-numeric-text-box
        data-cy="DM-1070"
        name="stockPositon"
        showNegativeLabel="true"
        format="n0"
        [disabled]="view.isDisabled"
        [(ngModel)]="view.input.stockPositon"
        (ngModelChange)="onStockPositionChange()">
      </lv-numeric-text-box>
    </lv-flex-box>

    <!-- Delta -->
    <lv-flex-box direction="row"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="deltaTooltip"
      filter=".lv-label">
      <div class="lv-label">Delta</div>
      <lv-numeric-text-box name="deltaHedge"
        data-cy="DM-1071"
        format="n3"
        decimals="3"
        [disabled] = "view.isDisabled"
        [(ngModel)]="view.input.deltaHedge"
        (ngModelChange)="onDeltaChange()">
      </lv-numeric-text-box>
      <div class="lv-label lv-label-suffix">%</div>
    </lv-flex-box>

    <!-- Used Accrued Interest -->
    <lv-flex-box
      data-cy="DM-1072"
      direction="row"
      showAfter="1500" 
      kendoTooltip [tooltipTemplate]="useAccruedInterestTooltip" 
      filter=".lv-label">
      <div class="lv-label">Use Accrued Interest</div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="accuredInterestCbId"
          name="useAccruedInterestCheckBox"
          [disabled] = "view.isUseAccruedInterestDisabled"
          [(ngModel)]="view.input.useAccruedInterest"
          (change)="onUseAccruedInterestChange($event.target.checked)" />
        <label class="k-checkbox-label"
          lvId="useAccruedInterestCheckBox"
          [attr.for]="accuredInterestCbId"></label>
        </div>
    </lv-flex-box>

    <ng-container *ngIf="_state.isAtParity">
      <!-- AT PARITY -->
      <div showAfter="1500"
         kendoTooltip [tooltipTemplate]="atParityTooltip" 
         class="lv-label lv-label--title" 
         filter=".lv-label">AT PARITY</div>

      <!-- Stock Price -->
      <lv-flex-box direction="row" showAfter="1500" kendoTooltip [tooltipTemplate]="stockPriceAPY" filter=".lv-label">
        <div class="lv-label">Stock Price</div>
        <div class="lv-output">
          <lv-negative-label
           data-cy="DM-1073"
           name="atParityStockPrice" 
           lvId="atParityStockPrice" 
           shouldShowBorder="true"
          [value]="view.output.stockPriceAtParity" 
          [suffix]="view.ccySuffix">
          </lv-negative-label>
        </div>
      </lv-flex-box>

      <!-- Parity -->
      <lv-flex-box direction="row" showAfter="1500" kendoTooltip [tooltipTemplate]="parityAPY" filter=".lv-label">
        <div class="lv-label">{{view.parityText}}</div>
        <div class="lv-output">
          <lv-negative-label
            data-cy="DM-1074"
            name="atParityParity" 
            lvId="atParityParity" 
            shouldShowBorder="true"
            [value]="view.output.parityAtParity" 
            [suffix]="view.paritySuffix">
          </lv-negative-label>
        </div>
      </lv-flex-box>

      <!-- Stock Price Change -->
      <lv-flex-box direction="row" showAfter="1500" kendoTooltip [tooltipTemplate]="stockPriceChangeAPY" filter=".lv-label">
        <div class="lv-label">Stock Price Change</div>
        <div class="lv-output">
          <lv-negative-label
            data-cy="DM-1075"
            name="atParityStockPriceChange" 
            lvId="atParityStockPriceChange" 
            shouldShowBorder="true"
            [value]="view.output.stockPriceChangeAtParity" 
            suffix="%">
          </lv-negative-label>
        </div>
      </lv-flex-box>
    </ng-container>
  </lv-flex-box>

  <lv-flex-box class="break-even-bottom-content">

    <ng-container *ngIf="_state.isAtPar">
      <!-- AT PAR -->
      <div
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="atParTooltip"
        class="lv-label lv-label--title"
        filter=".lv-label">AT PAR</div>

      <!-- Stock Price -->
      <lv-flex-box
        direction="row"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="stockPriceAP"
        filter=".lv-label">
        <div class="lv-label">Stock Price</div>
        <div class="lv-output">
          <lv-negative-label
            data-cy="DM-1076"
            name="atParStockPrice"
            lvId="atParStockPrice"
            shouldShowBorder="true"
            [value]="view.output.stockPriceAtPar"
            [suffix]="view.ccySuffix">
          </lv-negative-label>
        </div>
      </lv-flex-box>

      <!-- Parity -->
      <lv-flex-box
        direction="row"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="parityAP"
        filter=".lv-label">
        <div class="lv-label">{{view.parityText}}</div>
        <div class="lv-output">
          <lv-negative-label
            data-cy="DM-1077"
            name="atParParity"
            lvId="atParParity"
            shouldShowBorder="true"
            [value]="view.output.parityAtPar"
            [suffix]="view.paritySuffix">
          </lv-negative-label>
        </div>
      </lv-flex-box>

      <!-- Premium -->
      <lv-flex-box
        direction="row"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="premiumAP"
        filter=".lv-label">
        <div class="lv-label">Premium</div>
        <div class="lv-output">
          <lv-negative-label
            data-cy="DM-1078"
            name="atParPremium"
            lvId="atParPremium"
            shouldShowBorder="true"
            [value]="view.output.premium"
            suffix="%">
          </lv-negative-label>
        </div>
      </lv-flex-box>

      <!-- Stock Price Change -->
      <lv-flex-box
        direction="row"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="stockPriceChangeAP"
        filter=".lv-label">
        <div class="lv-label">Stock Price Change</div>
        <div class="lv-output">
          <lv-negative-label
            data-cy="DM-1079"
            name="atParStockPriceChange"
            lvId="atParStockPriceChange"
            shouldShowBorder="true"
            [value]="view.output.stockPriceChangeAtPar"
            suffix="%">
          </lv-negative-label>
        </div>
      </lv-flex-box>
    </ng-container>
    <ng-container *ngIf="_state.isAtZeroStockPrice">
      <!-- AT 0 STOCK PRICE -->
      <div showAfter="1500" kendoTooltip [tooltipTemplate]="atStockPriceTooltip" class="lv-label lv-label--title" filter=".lv-label">
        AT 0 STOCK PRICE</div>

      <!-- Convertible Price -->
      <lv-flex-box direction="row" showAfter="1500" kendoTooltip [tooltipTemplate]="convertiblePriceAZ" filter=".lv-label">
        <div class="lv-label">Convertible Price</div>
        <div class="lv-output">
          <lv-negative-label
            data-cy="DM-1080"
            name="atZeroStockPriceCBPrice" 
            lvId="atZeroStockPriceCBPrice" 
            shouldShowBorder="true"
            [value]="view.output.convertiblePrice" 
            [suffix]="view.paritySuffix">
          </lv-negative-label>
        </div>
      </lv-flex-box>
    </ng-container>

  </lv-flex-box>
</lv-flex-box>

<ng-template #atParityTooltip>
  <!-- uncomment lv-tooltip and remove hardcoded tooltip bellow when fileVersion date is updated; current version "2022-08-05T13:03:43.3478485Z" -->
  <!-- <lv-tooltip dm="3347"></lv-tooltip> -->
  <div class="popup-breakevan-content">
    Breakeven at parity satisfies the relation that the convertible's estimated delta-adjusted (nuked) price is equal to parity
  </div>
</ng-template>

<ng-template #stockPriceAPY>
  <lv-tooltip dm="dM-1073"></lv-tooltip>
</ng-template>

<ng-template #parityAPY>
  <lv-tooltip dm="dM-1074"></lv-tooltip>
</ng-template>

<ng-template #stockPriceChangeAPY>
  <lv-tooltip dm="dM-1075"></lv-tooltip>
</ng-template>

<ng-template #atParTooltip>
  <!-- uncomment lv-tooltip and remove hardcoded tooltip bellow when fileVersion date is updated; current version "2022-08-05T13:03:43.3478485Z" -->
  <!-- <lv-tooltip dm="3348"></lv-tooltip> -->
  <div class="popup-breakevan-content">
    Breakeven at par satisfies the relation that the convertible's estimated delta-neutral (nuked) price is equal to par (nominal)
  </div>
</ng-template>

<ng-template #stockPriceAP>
  <lv-tooltip dm="dM-1076"></lv-tooltip>
</ng-template>

<ng-template #parityAP>
  <lv-tooltip dm="dM-1077"></lv-tooltip>
</ng-template>

<ng-template #premiumAP>
  <lv-tooltip dm="dM-1078"></lv-tooltip>
</ng-template>

<ng-template #stockPriceChangeAP>
  <lv-tooltip dm="dM-1079"></lv-tooltip>
</ng-template>

<ng-template #atStockPriceTooltip>
    <!-- uncomment lv-tooltip and remove hardcoded tooltip bellow when fileVersion date is updated; current version "2022-08-05T13:03:43.3478485Z" -->
    <!-- <lv-tooltip dm="3349"></lv-tooltip> -->
  <div class="popup-breakevan-content">
    Represents a theoretical concept that determines total loss for a delta-hedged position should the underlying stock price collapse to zero
  </div>
</ng-template>

<ng-template #convertiblePriceAZ>
  <lv-tooltip dm="dM-1080"></lv-tooltip>
</ng-template>

<ng-template #convertibleQuantityTooltip>
  <lv-tooltip dm="dM-1069"></lv-tooltip>
</ng-template>

<ng-template #stockQuantityTooltip>
  <lv-tooltip dm="dM-1070"></lv-tooltip>
</ng-template>

<ng-template #deltaTooltip>
  <lv-tooltip dm="dM-1071"></lv-tooltip>
</ng-template>

<ng-template #useAccruedInterestTooltip>
  <lv-tooltip dm="dM-1072"></lv-tooltip>
</ng-template>
