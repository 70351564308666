import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, TemplateRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lv-tabular-outputs',
  templateUrl: './lv-tabular-outputs.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * Component for presenting Tabular Outputs
 */
export class LvTabularOutputsComponent {
  @Input() tabularTooltips: TemplateRef<any>;
  @Input() tabularOutputsContent: any[];
  @Input() areTenorTabularOutputsVisible: boolean;
  @Input() showAsPriceAsPar: boolean;

  constructor(private changeDetector: ChangeDetectorRef) {
    this.tabularOutputsContent = [];
    this.areTenorTabularOutputsVisible = false;
    this.showAsPriceAsPar = false;
  }

  /**
   * Check if outputs are visible. 
   * Outputs that are dependent on bucketing settings will not be visible if list is empty.
   * @param outputType 
   * @returns 
   */
  isOutputVisible(outputType: string): boolean {
    if (outputType === 'rhoBucket' || outputType === 'cSSensBucket') {
      return this.areTenorTabularOutputsVisible;
    }

    return true;
  }
}
