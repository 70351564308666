
export enum DayCountDescription {
	
	DayCount_ACT_ACT_ICMA = 'ACT/ACT ICMA',
	DayCount_ACT_365F = 'ACT/365F',
	DayCount_ACT_360 = 'ACT/360',
	DayCount_30_360 = '30/360',
	DayCount_30E_360 = '30E/360',
	DayCount_NL_365 = 'NL/365',
	DayCount_30E_360_ISDA = '30E/360 ISDA',
	DayCount_ACT_365 = 'ACT/365',
}