import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter,
  OnInit, Input} from '@angular/core';
import { AccessScopeType } from '@lv-core-ui/models/enum/access-scope-type';

import { LvNotificationManagerService } from '../../../services/lv-notification-manager/lv-notification-manager.service';
import { LvSubscriber, ILvSubscriberState } from '../../../services/lv-notification-manager/lv-notifier.subscriber';

export type LvSubscriberPanelOptonsPosition = 'top' | 'bottom';

@Component({
  selector: 'lv-subscriber-panel',
  templateUrl: './lv-subscriber-panel.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvSubscriberPanelComponent extends LvSubscriber implements OnInit {

  @Input() headerVisible: boolean;
  @Input() floatingHeader: boolean;
  @Input() state?: ILvSubscriberState;
  @Input() optionsPosition: LvSubscriberPanelOptonsPosition;
  @Input() accessScope: AccessScopeType;

  @Output() didReceiveMessage: EventEmitter<any>;
  @Output() didStateChange: EventEmitter<ILvSubscriberState>;

  get backgroundLinkColor(): string {
    if (!this.linkedPublisher) {
      return  '#8F9094';
    }

    return this.color;
  }

  get linkRequested(): boolean {
    return !!this.readyToLinkId && this.readyToLinkId !== this.linkedPublisher;
  }

  get linkEstablished(): boolean {
    return !!this.linkedPublisher;
  }

  get showLinkOption(): boolean {
    return this.linkRequested || this.linkEstablished;
  }

  get shouldShowAccessScope() {
    return this.accessScope && this.accessScope !== AccessScopeType.System;
  }

  readyToLinkId: string;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    lvNotificationManagerService: LvNotificationManagerService
  ) {
    super(lvNotificationManagerService);

    this.headerVisible = true;
    this.floatingHeader = false;
    this.optionsPosition = null;

    this.didReceiveMessage = new EventEmitter<any>();
    this.didStateChange = new EventEmitter<ILvSubscriberState>();

    this.state = {
      id: null,
      type: 'subscriber',
      color: null,
      linkedPublisher: null
    };

    this.publisherReadyToLink.subscribe(event => {
      this.readyToLinkId = event.from.id;
      this.changeDetectorRef.detectChanges();
    });

    this.publisherReadyToLinkCanceled.subscribe(event => {
      this.readyToLinkId = null;
      this.changeDetectorRef.detectChanges();
    });

    this.publisherCanceled.subscribe(event => {
      this.readyToLinkId = null;

      this.emitState();

      this.changeDetectorRef.detectChanges();
    });

    this.messageReceived.subscribe(event => {
      this.didReceiveMessage.emit(event.data);
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnInit() {
    this.type = this.state.type || this.type;
    this.color = this.state.color || null;

    this.linkedPublisher = this.state.linkedPublisher || this.linkedPublisher;

    this.init(this.state ? this.state.id : null);

    this.changeDetectorRef.detectChanges();
  }

  setPublisher(publisherId: string) {
    this.linkPublisher(publisherId);
    this.emitState();
    this.changeDetectorRef.detectChanges();
  }

  emitState() {
    this.didStateChange.emit({
      id: this.id,
      type: 'subscriber',
      color: this.color,
      linkedPublisher: this.linkedPublisher
    });
  }

  onLinkOptionClick() {
    if (this.linkEstablished) {
      this.readyToLinkId = null;
      this.sendCancelLink();
    }
    else {
      this.sendAcceptLink(this.readyToLinkId);
    }

    this.emitState();

    this.changeDetectorRef.detectChanges();
  }
}
