import { Injectable } from '@angular/core';
import { ILvHubClient, LvHub } from '@lv-core-ui/util';
import { Subject, Observable } from 'rxjs';
import { IPrivateInstrumentDeletedEvent } from './events/custom-instrument-updated-event';

/**
 * Custom instrument hub service.
 */
@Injectable()
export class CustomInstrumentHubService implements ILvHubClient {

  private _privateInstrumentDeletedEvent: Subject<IPrivateInstrumentDeletedEvent>;

  constructor() {
    this._privateInstrumentDeletedEvent = new Subject<IPrivateInstrumentDeletedEvent>();
  }

  get privateInstrumentDeletedEvent(): Observable<IPrivateInstrumentDeletedEvent> {
    return this._privateInstrumentDeletedEvent;
  }

  /**
   * Subscribes to a custom instrument deleted event.
   * @param hub Hub.
   */
  attachEvents(hub: LvHub): void {
    hub.subscribeOn('privateInstrumentDeletedEvent', this._privateInstrumentDeletedEvent);
  }
}
