<lv-flex-box class="lv-lws-event" direction="row">
  <lv-flex-box class="lv-label-field lv-label lv-mail-notifications-settings-label"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="lwsEventTooltip"
    filter="span">
    <span [attr.data-tooltip-id]="getLwsEventTooltipIdValue">
      {{ eventType }}
    </span>
  </lv-flex-box>

  <lv-flex-box class="lv-lws-event-regions" direction="row">
    <ng-container *ngFor="let region of regionsEnum.items">
      <div class="lv-lws-event-regions-checkbox lv-label-field lv-field--checkbox">
        <input type="checkbox"
          class="k-checkbox"
          tabindex="-1"
          [attr.id]="getCheckBoxId(region.id)"
          [(ngModel)]="lwsEvent.regions[region.id]"
          [disabled]="isDisabled(region.text)"/>
        <label class="k-checkbox-label" [attr.for]="getCheckBoxId(region.id)">{{ region.text }}</label>
      </div>

    </ng-container>
  </lv-flex-box>

  <lv-flex-box class="lv-lws-event-notification-type" direction="row">
      <div class="lv-lws-event-notification-type-item lv-label-field lv-field--radio">
        <input class="k-radio"
          type="radio"
          [id]="getRadioButtonId('single')"
          [name]="getRadioButtonId('single-name')"
          value="Single"
          [(ngModel)]="lwsEvent.frequency">
        <label class="k-radio-label"
          [attr.for]="getRadioButtonId('single')"
          lvId="lv-lws-event-notification-type-single">
            Single
        </label>
      </div>
      <div class="lv-lws-event-notification-type-item lv-label-field lv-field--radio">
        <input class="k-radio"
          type="radio"
          [id]="getRadioButtonId('bulk')"
          [name]="getRadioButtonId('bulk-name')"
          value="Bulk"
          [(ngModel)]="lwsEvent.frequency">
        <label class="k-radio-label lv-lws-event-notification-type-item-bulk-label"
          [attr.for]="getRadioButtonId('bulk')"
          lvId="lv-lws-event-notification-type-bulk">
            Bulk
        </label>
      </div>
      <div class="lv-lws-event-notification-type-item lv-label-field lv-field--radio">
        <input class="k-radio"
          type="radio"
          [id]="getRadioButtonId('singleAndBulk')"
          [name]="getRadioButtonId('single-bulk-name')"
          value="SingleAndBulk"
          [(ngModel)]="lwsEvent.frequency">
        <label class="k-radio-label lv-lws-event-notification-type-item-single-bulk-label"
          [attr.for]="getRadioButtonId('singleAndBulk')"
          lvId="lv-lws-event-notification-type-single-and-bulk">
            Single &amp; Bulk
        </label>
      </div>
    </lv-flex-box>
</lv-flex-box>


<ng-template #lwsEventTooltip let-anchor>
  <div class="lws-event-tooltip">
    <ng-container *ngIf="getLwsEventTooltipId(anchor, 'lwsEventNewIssueSetup')">
      <lv-tooltip dm="dM-1128"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getLwsEventTooltipId(anchor, 'lwsEventNewIssueFixed')">
      <lv-tooltip dm="dM-1130"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getLwsEventTooltipId(anchor, 'lwsEventNewIssueSetupCorrected')">
      <lv-tooltip dm="dM-1129"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getLwsEventTooltipId(anchor, 'lwsEventConversionRatioEstimate')">
      <lv-tooltip dm="dM-1131"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getLwsEventTooltipId(anchor, 'lwsEventConversionRatioConfirmed')">
      <lv-tooltip dm="dM-1132"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getLwsEventTooltipId(anchor, 'lwsEventInstrumentStatusChanged')">
      <lv-tooltip dm="dM-1133"></lv-tooltip>
    </ng-container>
    <ng-container *ngIf="getLwsEventTooltipId(anchor, 'lwsEventISINChanged')">
      <lv-tooltip dm="dM-1134"></lv-tooltip>
    </ng-container>
  </div>
</ng-template>
