import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter,
  ChangeDetectorRef, Renderer2 } from '@angular/core';

import { LvModalComponent } from '../lv-modal';
import { mask, modal } from './../lv-modal.animations';

@Component({
  selector: 'lv-authentication-modal',
  templateUrl: './lv-authentication-modal.component.html',
  animations: [
    mask,
    modal
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvAuthenticationModalComponent extends LvModalComponent implements OnInit {

  private _isLoading: boolean;
  @Input()
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.changeDetectorRef.detectChanges();
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  @Output() doLogInAgain: EventEmitter<boolean>;
  @Output() doRetry: EventEmitter<void>;
  @Output() doRetryLogInAgain: EventEmitter<void>;

  sessionExpiredError: boolean;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    renderer: Renderer2
  ) {
    super(changeDetectorRef, renderer);

    this.sessionExpiredError = false;

    this.doLogInAgain = new EventEmitter<boolean>();
    this.doRetry = new EventEmitter<void>();
    this.doRetryLogInAgain = new EventEmitter<void>();
  }

  ngOnInit() {
    this.isLoading = false;
  }

  // override from LvModalComponent
  public open(sessionExpiredError?: boolean): void {
    if (!this.isOpened) {
      this.sessionExpiredError = !!sessionExpiredError;
      this.isOpened = true;

      this.disableScrolling();
      this.changeDetectorRef.detectChanges();
    }
  }

  onLogInAgain() {
    if (this.sessionExpiredError) {
      this.doLogInAgain.emit(this.sessionExpiredError);
    }
    else {
      this.doRetryLogInAgain.emit();
    }
  }

  onRetry() {
    this.doRetry.emit();
  }

}
