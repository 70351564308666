import { StockReferenceType } from './Enums (9)';

export class StockPriceReference {
    
    referenceType: StockReferenceType;
    fixedStockRef: number;
    fixedFXRef: number;
    stockVWAP: number;
    vwapFrom: Date;
    vwapTo: Date;
    timeZone: string;
    stockUsedRef: number;
    fxUsedRef: number;
}

