import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { throwError, Observable, firstValueFrom } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { LvAuthorizationError, LvConnectionError, LvAuthenticationError } from '../models/lv-error/auth';
import { LvBadRequestError } from '../models/lv-error/user-friendly';
import { LvForbiddenError } from '../models/lv-error/forbidden';
import { LvNotFoundError } from '../models/lv-error/not-found';
import { LvInternalServerError, LvApplicationError } from '../models/lv-error/application';
import { ILvError, LvError } from '../models/lv-error/base';
import { LvErrorType } from '../models/lv-error/error-type';
import { LvRequestTimeoutError } from '../models/lv-error/request-timeout';
import { LvConflictError } from '../models/lv-error/conflict';

import { refreshAccessToken, defaultHeaders } from './http-client-base2.helpers';

export interface IQueryParams {
  [other: string]: any;
}

export class HttpClientBase2 {

  constructor(
    public http: HttpClient,
    public resourceUrl: string
  ) {}

  public handleError(
    error: ILvError,
    processError: (error: ILvError) => LvError,
    checkAuthorization = false): LvError {
    if (error.type === LvErrorType.AUTHENTICATION
      || error.type === LvErrorType.CONNECTION
      || error.type === LvErrorType.CONFLICT) {
      return error;
    }

    if (error.type === LvErrorType.AUTHORIZATION && checkAuthorization) {
      return new LvAuthenticationError();
    }

    if (error.type === LvErrorType.REQUEST_TIMEOOUT) {
      const processedError = processError(error);
      processedError.message = `Requested operation timed out. Please, try again.`;

      return processedError;
    }

    return processError(error);
  }

  public getContentDispositionFileName(response: HttpResponse<ArrayBuffer>): string {
    const header = response.headers.get('content-disposition');

    if (!header) {
      return '';
    }

    const regex = new RegExp(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/g);
    const matches = regex.exec(header);

    return (matches[1] || '').replace(/['"]/g, '');
  }

  public async downloadAsync(
    queryParams?: IQueryParams,
    resourcePath?: string,
    excludeAuthenticationHeaders = false,
    requestTimeout = environment.requestTimeout
  ): Promise<HttpResponse<ArrayBuffer>> {
    try {
      const reqOpts = await this.getRequestOptions(queryParams, excludeAuthenticationHeaders);
      return this.http.get(`${this.resourceUrl}${resourcePath || ''}`, {
        ...reqOpts,
        responseType: 'arraybuffer',
        observe: 'response'
      }).pipe(
        timeout(requestTimeout),
        catchError(error => this.handleHttpError(error))
      ).toPromise();
    }
    catch (error) {
      throw error;
    }
  }

  public async downloadImage(
    queryParams?: IQueryParams,
    resourcePath?: string,
    excludeAuthenticationHeaders = false,
    requestTimeout = environment.requestTimeout
  ): Promise<HttpResponse<Blob>> {
    try {
      const reqOpts = await this.getRequestOptions(queryParams, excludeAuthenticationHeaders);
      return this.http.get(`${this.resourceUrl}${resourcePath || ''}`, {
        ...reqOpts,
        responseType: 'blob',
        observe: 'response'
      }).pipe(
        timeout(requestTimeout),
        catchError(error => this.handleHttpError(error))
      ).toPromise();
    }
    catch (error) {
      throw error;
    }
  }

  public async getAsync<T>(
    queryParams?: IQueryParams,
    resourcePath?: string,
    excludeAuthenticationHeaders = false,
    requestTimeout = environment.requestTimeout
  ): Promise<T> {
    try {
      const reqOpts = await this.getRequestOptions(queryParams, excludeAuthenticationHeaders);
      return firstValueFrom(this.http.get<T>(`${this.resourceUrl}${resourcePath || ''}`, reqOpts)
        .pipe(
          timeout(requestTimeout),
          catchError(error => this.handleHttpError(error))
        ));
    }
    catch (error) {
      throw error;
    }
  }

  public async postAsync<T>(
    body?: IQueryParams,
    resourcePath?: string,
    queryParams?: IQueryParams,
    excludeAuthenticationHeaders = false,
    requestTimeout = environment.requestTimeout
  ): Promise<T> {
    try {
      const reqOpts = await this.getRequestOptions(queryParams, excludeAuthenticationHeaders);
      return this.http.post<T>(`${this.resourceUrl}${resourcePath || ''}`, body, reqOpts)
        .pipe(
          timeout(requestTimeout),
          catchError(error => this.handleHttpError(error))
        ).toPromise();
    }
    catch (error) {
      throw error;
    }
  }

  public async putAsync<T>(
    body?: IQueryParams,
    resourcePath?: string,
    queryParams?: IQueryParams,
    excludeAuthenticationHeaders = false,
    requestTimeout = environment.requestTimeout
  ): Promise<T> {
    try {
      const reqOpts = await this.getRequestOptions(queryParams, excludeAuthenticationHeaders);
      return this.http.put<T>(`${this.resourceUrl}${resourcePath || ''}`, body, reqOpts)
        .pipe(
          timeout(requestTimeout),
          catchError(error => this.handleHttpError(error))
        ).toPromise();
    }
    catch (error) {
      throw error;
    }
  }

  public async deleteAsync<T>(
    resourcePath?: string,
    queryParams?: IQueryParams,
    excludeAuthenticationHeaders = false,
    requestTimeout = environment.requestTimeout
  ): Promise<T> {
    try {
      const reqOpts = await this.getRequestOptions(queryParams, excludeAuthenticationHeaders);
      return this.http.delete<T>(`${this.resourceUrl}${resourcePath || ''}`, reqOpts)
        .pipe(
          timeout(requestTimeout),
          catchError(error => this.handleHttpError(error))
        ).toPromise();
    }
    catch (error) {
      throw error;
    }
  }

  private async getRequestOptions(
    queryParams?: IQueryParams,
    excludeAuthenticationHeaders = false
  ): Promise<{
    headers: HttpHeaders,
    params: HttpParams,
    withCredentials: boolean
  }> {
    try {
      const headers = defaultHeaders;

      if (!excludeAuthenticationHeaders) {
        const tokenResponse = await refreshAccessToken(this.http);

        headers['Authorization'] = `Bearer ${tokenResponse.accessToken}`;
      }

      return {
        headers: new HttpHeaders(headers),
        params: new HttpParams({
          fromObject: queryParams
        }),
        withCredentials: true
      };
    }
    catch (error) {
      throw error;
    }
  }

  private handleHttpError(error: HttpErrorResponse): Observable<never> {
    let message = null;

    if (error.error) {
      message = error.error.message;
    }

    if (!message) {
      message = error.message;
    }

    if (error.status === 400) {
      return throwError(new LvBadRequestError(message));
    }

    if (error.status === 401) {
      return throwError(new LvAuthorizationError(message));
    }

    if (error.status === 403) {
      return throwError(new LvForbiddenError(message));
    }

    if (error.status === 404) {
      return throwError(new LvNotFoundError(message));
    }

    if (error.status === 408
      || error.name.toLowerCase() === 'TimeoutError'.toLowerCase() ) {
      return throwError(new LvRequestTimeoutError(message));
    }

    if (error.status === 409) {
      return throwError(new LvConflictError(message));
    }

    if (error.status === 500) {
      return throwError(new LvInternalServerError(message));
    }

    if (error.status === 503) {
      return throwError(new LvConnectionError(message));
    }

    return throwError(new LvApplicationError(error.message));
  }
}
