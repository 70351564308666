
export enum AdjustmentReasonDescription {
	CashDividend = 'Cash Dividend',
	StockDividend = 'Stock Dividend',
	StockSplit = 'Stock Split',
	RightsIssue = 'Rights Issue',
	CarryForwardReset = 'Carry Forward Reset',
	SpinOff = 'Spin Off',
	ConversionPriceReset = 'Conversion Price Reset',
	Other = 'Other',
	Initial = 'Initial',
	BonusIssue = 'Bonus Issue',
	ReturnOfCapital = 'Return Of Capital',
	Takeover = 'Takeover',
	Merger = 'Merger',
	CapitalIncrease = 'Capital Increase',
	Restructuring = 'Restructuring',
	CorporateAction = 'Corporate Action'
}
