import { StockReferenceType } from "@lv-analytics/models";
import { ConvertibleBondSubType, QuickAndFullTermsDocument } from "@lv-convertible-bond/models";
import { ExcelFieldDataTypes } from "@lv-excel/models/enums/excel-field-data-types";

/**
 * Custom new issue fields mapper.
 * @param fieldDictionary field dictionary.
 * @param model Model data.
 * @param isNotNewIssue Is instrument new issue or not.
 * @param isCrossFx Is instrument cross currency instrument or not.
 */
export function mapNewIssueFields(fieldDictionary: any, model: QuickAndFullTermsDocument, isNotNewIssue: boolean, isCrossFx: boolean) {
  fieldDictionary['NI_PREM_PRC_RANGE'] = {
    mapFn: (value: any) => {
      if (isNotNewIssue) {
        return;
      }

      if (model.fullTerms.issueAndRedemption.subType === ConvertibleBondSubType.PEPS) {
        model.fullTerms.priceTalk.higherStrikePremiumBest = value[0];
        model.fullTerms.priceTalk.higherStrikePremiumWorst = value[1];
      }
      else {
        model.fullTerms.priceTalk.premiumBest = value[0];
        model.fullTerms.priceTalk.premiumWorst = value[1];
      }

      model.quickTerms.priceTalk = model.fullTerms.priceTalk;
    },
    reverseMapFn: () => {
      if (isNotNewIssue) {
        if (model.fullTerms.issueAndRedemption.subType === ConvertibleBondSubType.PEPS) {
          return model.fullTerms.priceTalk.higherStrikePremiumBest, model.fullTerms.priceTalk.higherStrikePremiumWorst;
        }
        else {
          return [model.fullTerms.priceTalk.premiumBest, model.fullTerms.priceTalk.premiumWorst];
        }
      }
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['NI_ISSUE_PX_RANGE'] = {
    mapFn: (value: any) => {
      if (isNotNewIssue) {
        return;
      }

      model.fullTerms.priceTalk.issuePriceBest = value[0];
      model.fullTerms.priceTalk.issuePriceWorst = value[1];
      model.quickTerms.priceTalk = model.fullTerms.priceTalk;
    },
    reverseMapFn: () => {
      if (isNotNewIssue) {
        const result = [];
        result.push[model.fullTerms.priceTalk.issuePriceBest];
        result.push[model.fullTerms.priceTalk.issuePriceWorst];
        return result;
      }
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['NI_CPN_RT_RANGE'] = {
    mapFn: (value: any) => {
      if (isNotNewIssue) {
        return;
      }

      model.fullTerms.priceTalk.couponBest = value[0];
      model.fullTerms.priceTalk.couponWorst = value[1];
      model.quickTerms.priceTalk = model.fullTerms.priceTalk;
    },
    reverseMapFn: () => {
      if (isNotNewIssue) {
        const result = [];
        result.push[model.fullTerms.priceTalk.couponBest];
        result.push[model.fullTerms.priceTalk.couponWorst];
        return result;
      }
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['NI_REDEM_PX_RANGE'] = {
    mapFn: (value: any) => {
      if (isNotNewIssue) {
        return;
      }

      model.fullTerms.priceTalk.redemptionValueBest = value[0];
      model.fullTerms.priceTalk.redemptionValueWorst = value[1];
      model.quickTerms.priceTalk = model.fullTerms.priceTalk;
    },
    reverseMapFn: () => {
      if (isNotNewIssue) {
        const result = [];
        result.push[model.fullTerms.priceTalk.redemptionValueBest];
        result.push[model.fullTerms.priceTalk.redemptionValueWorst];
        return result;
      }
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['NI_YLD_RANGE'] = {
    mapFn: (value: any) => {
      if (isNotNewIssue) {
        return;
      }

      model.fullTerms.priceTalk.issueYieldBest = value[0];
      model.fullTerms.priceTalk.issueYieldWorst = value[1];
      model.quickTerms.priceTalk = model.fullTerms.priceTalk;
    },
    reverseMapFn: () => {
      if (isNotNewIssue) {
        const result = [];
        result.push[model.fullTerms.priceTalk.issueYieldBest];
        result.push[model.fullTerms.priceTalk.issueYieldWorst];
        return result;
      }
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['FIXED_STK_REF_EQCCY'] = {
    mapFn: (value: any) => {
      if (isNotNewIssue) {
        return;
      }
      model.fullTerms.stockPriceReference.referenceType = StockReferenceType.Fixed; 
      model.fullTerms.stockPriceReference.fixedStockRef = value;
      model.quickTerms.stockPriceReference = model.fullTerms.stockPriceReference;
    },
    reverseMapFn: () => {
      if (isNotNewIssue) {
        return model.fullTerms.stockPriceReference.fixedStockRef;
      }
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['FIXED_STK_REF_CBCCY'] = {
    mapFn: (value: any) => {

    },
    reverseMapFn: () => {
      if (isNotNewIssue) {
        if (isCrossFx) {
          return model.fullTerms.stockPriceReference.fixedStockRef / model.fullTerms.issueAndRedemption.fixedFXRate;
        }
        else {
          return model.fullTerms.stockPriceReference.fixedStockRef;
        }
      }
    },
    type: ExcelFieldDataTypes.Number
  };
}