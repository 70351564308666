<kendo-tabstrip class="lv-tabstrip"
  [keepTabContent]="true">

  <kendo-tabstrip-tab
    selected="true"
    title="Shift Parameters">
    <ng-template kendoTabContent>
      <lv-shift-parameters-settings [settings]="settings.shiftParametersSettings"></lv-shift-parameters-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="Warnings">
    <ng-template kendoTabContent>
      <lv-warnings-settings [settings]="settings.warningsSettings"></lv-warnings-settings>
    </ng-template>
  </kendo-tabstrip-tab>
  
  <kendo-tabstrip-tab
    title="Bucketing"
    cssClass="DM-4558">
    <ng-template kendoTabContent>
      <lv-bucketing-settings
        [tenors]="tenors"
        [settings]="settings.bucketingSettings"
        data-cy="DM-4558">
      </lv-bucketing-settings>
    </ng-template>
  </kendo-tabstrip-tab>

</kendo-tabstrip>
<lv-flex-box class="lv-valuation-settings-control-panel" direction="row">
  <button kendoButton
    class="reset-button"
    [primary]="true"
    lvId="reset-to-default-valuation-settings"
    (click)="onResetToDefault()">
    Reset To Default
    </button>
  <button kendoButton
    [primary]="true"
    lvId="cancel-valuation-settings"
    (click)="onCancel()">
    Cancel
  </button>
  <button kendoButton
    [primary]="true"
    lvId="save-valuation-settings"
    (click)="onSave()">
    Save</button>
</lv-flex-box>
