<lv-advanced-grid
  data-cy="DM-4560"
  [columns]="columns"
  [records]="settings.bucketList"
  lvId="lvBucketingSettingsGrid"
  [createFormGroup]="createFormGroup"
  [editable]="true"
  sortBy="tenor"
  [sortComparerFn]="convertTenorToNumber"
  class="lv-bucketing-section-settings-table">
</lv-advanced-grid>