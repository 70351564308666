import { Directive, ElementRef, Input, Renderer2,
  OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ResizeHandlerService } from '@lv-core-ui/services';


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[breakGrid]'
})
export class BreakGridDirective implements OnInit, OnChanges, OnDestroy {

  @Input() breakGrid: number;

  listenerId: string;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private resizeHandlerService: ResizeHandlerService
  ) {
    this.breakGrid = 0;
  }

  ngOnInit() {
    const rect = (this.element.nativeElement as HTMLElement).getBoundingClientRect();
    this.appendClassToElement(rect.width < this.breakGrid);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.breakGrid.currentValue !== changes.breakGrid.previousValue) {
      this.doAttach();
    }
  }

  appendClassToElement(brokeBelow: boolean) {
    if (brokeBelow) {
      this.renderer.addClass(this.element.nativeElement, 'break-grid');
    } else {
      this.renderer.removeClass(this.element.nativeElement, 'break-grid');
    }
  }

  ngOnDestroy() {
    this.resizeHandlerService.removeAllListeners(this.element);
  }

  private doAttach() {
    this.resizeHandlerService.removeListener(this.listenerId);

    this.listenerId = this.resizeHandlerService.breakOn(this.element, this.breakGrid, (brokeBelow: boolean) => {
      this.appendClassToElement(brokeBelow);
    });

    const rect = (this.element.nativeElement as HTMLElement).getBoundingClientRect();
    this.appendClassToElement(rect.width < this.breakGrid);
  }
}
