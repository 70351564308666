import { Directive, TemplateRef } from '@angular/core';

/**
 * Environment settings directive.
 */
@Directive({
    selector: '[environmentSettings]'
})

export class LvEnvironmentSettingsDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}
