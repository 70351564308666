<lv-flex-box class="lv-puts" data-cy="DM-1228">

    <lv-flex-box direction="row" data-cy="DM-387">
      <div class="lv-label lv-label-field lv-label-puttable"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="puttableSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Puttable
        </span>
        <lv-xl-label [field]="'PUTTABLE'"></lv-xl-label>
      </div>
      <div class="lv-field lv-field--checkbox lv-checkbox-left">
        <input
          class="k-checkbox"
          type="checkbox"
          name="puts-puttable"
          [attr.id]= view.puttableId
          [(ngModel)]="view.issueAndRedemption.isPuttable"
          (ngModelChange)="onPutChange()" />
        <label
          class="k-checkbox-label"
          lvId="puttableCheckBox"
          [attr.for]="view.puttableId"></label>
      </div>
    </lv-flex-box>

  <lv-flex-box *ngIf="view.issueAndRedemption.isPuttable">
    <lv-flex-box>
      <lv-puts-put-parameters
        [model]="view.model"
        [couponType]="view.couponType"
        (didSetParameters)="onModelChange()"
        (didSetPutValueType)="onPutValueTypeChange()">
      </lv-puts-put-parameters>
    </lv-flex-box>
    <lv-flex-box>
      <div showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="putScheduleTooltip"
      filter=".lv-label"
      class="lv-label lv-label-field lv-label--title">PUT SCHEDULE</div>
    </lv-flex-box>
    <lv-flex-box>
      <lv-puts-put-schedule #putSchedule
        [valueType]="view.model.valueType"
        [model]="view.model.scheduleItems"
        [duringPeriod]="view.model.puttableDuringPeriod"
        [partialPut]="view.model.partialPut"
        [currentNotionalPct]="view.model.currentNotionalPct"
        (didPutScheduleChange)="onModelChange()"
        (didTotalTranchePctSumChange)="onTotalTranchePctSumChanged($event)">
      </lv-puts-put-schedule>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<ng-template #puttableSourceTooltip>
  <lv-tooltip dm="dM-387"></lv-tooltip>
</ng-template>
<ng-template #putScheduleTooltip>
  <lv-tooltip dm="dM-910"></lv-tooltip>
</ng-template>
