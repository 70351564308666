import {
  Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
  ViewChild, Input, Output, EventEmitter, OnChanges, HostBinding, OnDestroy, Optional
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MarketDataClipboard } from '@lv-analytics/components/market-data/market-data-clipboard';
import { CreditSource, ICreditTermStructureItem } from '@lv-analytics/models/market-data/credit';
import {
  LvAdvancedGridComponent, LvAdvancedGridColumn, LvAdvancedGridDateColumn,
  LvAdvancedGridNumericColumn
} from '@lv-core-ui/components';
import { LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { constants } from '@lv-core-ui/util';
import { LvExcelService } from '@lv-excel/services';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';

/**
 * Credit terms structure component.
 */
@Component({
  selector: 'lv-credit-terms-structure',
  templateUrl: './lv-credit-terms-structure.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
// tslint:disable-next-line:max-line-length
export class LvCreditTermsStructureComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  @Input() scheduleItems: ICreditTermStructureItem[];
  @Input() creditSource: CreditSource;
  @Input() lvId: string;
  @Input() isXlLabelVisible: boolean;
  @Input() creditParametersSource: string;

  @Output() creditScheduleValueChange: EventEmitter<void>;

  columns: LvAdvancedGridColumn[];
  parseFn: any;
  credSchdRange = 'CREDIT_SCHED_RANGE';
  credCdsSchdRange = 'CREDIT_CDS_SCHED_RANGE';
  credDfltSchdRange = 'CREDIT_DFLT_SCHED_RANGE';
  credSrvprobSchdRange = 'CREDIT_SRVPROB_SCHED_RANGE';
  credSchdRangeUnd = 'CREDIT_SCHED_RANGE_UND';
  credCdsSchdRangeUnd = 'CREDIT_CDS_SCHED_RANGE_UND';
  credDfltSchdRangeUnd = 'CREDIT_DFLT_SCHED_RANGE_UND';
  credSrvprobSchdRangeUnd = 'CREDIT_SRVPROB_SCHED_RANGE_UND';

  private _subscriptions: Subscription[];

  get excelFieldAliasLabel(): string {
    if (this.creditParametersSource === 'issuer' || !this.creditParametersSource) {
      if (!!this._excelSvc?.containsField(this.credSchdRange)
        && this.creditSource === CreditSource.TermStructure) {
        return this.credSchdRange;
      } else if (!!this._excelSvc?.containsField(this.credCdsSchdRange)
        && this.creditSource === CreditSource.CDSTermStructure) {
        return this.credCdsSchdRange;
      } else if (!!this._excelSvc?.containsField(this.credDfltSchdRange)
        && this.creditSource === CreditSource.DefaultRateTermStructure) {
        return this.credDfltSchdRange;
      } else if (!!this._excelSvc?.containsField(this.credSrvprobSchdRange)
        && this.creditSource === CreditSource.SurvivalProbabilityTermStructure) {
        return this.credSrvprobSchdRange;
      }
    }
    else {
      if (!!this._excelSvc?.containsField(this.credSchdRangeUnd)
        && this.creditSource === CreditSource.TermStructure) {
        return this.credSchdRangeUnd;
      } else if (!!this._excelSvc?.containsField(this.credCdsSchdRangeUnd)
        && this.creditSource === CreditSource.CDSTermStructure) {
        return this.credCdsSchdRangeUnd;
      } else if (!!this._excelSvc?.containsField(this.credDfltSchdRangeUnd)
        && this.creditSource === CreditSource.DefaultRateTermStructure) {
        return this.credDfltSchdRangeUnd;
      } else if (!!this._excelSvc?.containsField(this.credSrvprobSchdRangeUnd)
        && this.creditSource === CreditSource.SurvivalProbabilityTermStructure) {
        return this.credSrvprobSchdRangeUnd;
      }
    }
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAliasLabel)?.editable;
  }

  constructor(
    private _errorService: LvErrorService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.scheduleItems = [];
    this.creditScheduleValueChange = new EventEmitter();

    this.parseFn = this.parserFunction.bind(this);
    this._subscriptions = [];
    this.isXlLabelVisible = false;
    this.creditParametersSource = '';
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this.advancedGrid.didDataChange.subscribe((records: ICreditTermStructureItem[]) => this.onDataChange(records)),
      this.advancedGrid.doReload.subscribe(() => this.onReload()),
      this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];

    this.initColumns();
  }

  /**
   * Occurs on changes.
   */
  ngOnChanges() {
    this.initColumns();
    this.updateColumns();
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe);
  }

  /**
   * Gets LvId.
   * @returns LvId.
   */
  getLvId(): string {
    return `advGrid-${this.lvId}`;
  }

  @HostBinding('class.lv-credit-term-structure')
  get isLvCreditTermsStructure(): boolean {
    return true;
  }

  /**
   * Creates form group.
   * @param args CreateFormGroupArgs object.
   * @returns FormGroup object.
   */
  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return new FormGroup({
      'date': new FormControl(args.isNew ? new Date() : args.dataItem.date, Validators.required),
      'value': new FormControl(args.dataItem.value, Validators.required),
      'upfront': new FormControl(args.dataItem.upfront, Validators.nullValidator)
    });
  }

  /**
   * Applies advanced grid changes.
   */
  public applyAdvancedGridChanges() {
    this.advancedGrid.applyChanges(records => this.applyRecords(records));
  }

  /**
   * Occurs on data change.
   * @param records List of ICreditTermStructureItem objects.
   */
  private onDataChange(records: ICreditTermStructureItem[]) {
    // TODO: Refactor this so it does not update model directly

    this.applyRecords(records);
    this.creditScheduleValueChange.next();
  }

  /**
   * Handles error.
   * @param error LvError object.
   */
  public onError(error: LvError) {
    this._errorService.handleError(error);
  }

  /**
   * Occurs on reload.
   */
  public onReload() {
    // TODO: Reload should not override session.
    //       It should be initialized from server

    this.scheduleItems = this.scheduleItems.map(a => ({ ...a }));
  }

  /**
   * Applies records.
   * @param records List of records.
   */
  private applyRecords(records: any[]) {
    this.scheduleItems.splice(0, this.scheduleItems.length);
    this.scheduleItems.push(...records);
  }

  /**
   * Parse data and push items to list.
   * @param pastedDataRecords List of pasted data records.
   * @returns List of ICreditTermStructureItem object.
   */
  private parserFunction(pastedDataRecords: string[]): ICreditTermStructureItem[] {
    const creditTermStructureItems: ICreditTermStructureItem[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');
      let upFront: number;

      const dateValue = items[0];
      const valueValue = items[1];
      const upFrontValue = items[2];

      const date = MarketDataClipboard.parseDateValue(dateValue, 'Date');
      const value = MarketDataClipboard.parseNumberValue(valueValue, 'Value');

      if (this.creditSource === CreditSource.CDSTermStructure) {
        upFront = MarketDataClipboard.parseNumberValue(upFrontValue, 'Upfront');
      }

      creditTermStructureItems.push({
        date: date,
        value: value,
        upfront: upFront
      });
    });

    return creditTermStructureItems;
  }

  /**
   * Updates columns.
   */
  private updateColumns() {
    this.advancedGrid.updateColumns(c => this.updateColumn(c));
  }

  /**
   * Column initialization.
   */
  private initColumns() {
    this.columns = [];

    const dateColumn = new LvAdvancedGridDateColumn();
    dateColumn.title = 'End Date';
    dateColumn.field = 'date';
    dateColumn.dmKey = this.setDMKey('date');

    const valueColumn = new LvAdvancedGridNumericColumn();
    valueColumn.title = 'Value';
    valueColumn.field = 'value';
    valueColumn.dmKey = this.setDMKey('value');

    this.updateColumn(valueColumn);

    const upfrontColumn = new LvAdvancedGridNumericColumn();
    upfrontColumn.title = 'Upfront';
    upfrontColumn.field = 'upfront';
    upfrontColumn.dmKey = this.setDMKey('upfront'); 

    this.updateColumn(upfrontColumn);

    this.columns.push(dateColumn);
    this.columns.push(valueColumn);
    this.columns.push(upfrontColumn);
  }

  /**
   * Updates column.
   * @param c LvAdvancedGridColumn object.
   */
  private updateColumn(c: LvAdvancedGridColumn) {
    if (c.field === 'value') {
      switch (this.creditSource) {
        case CreditSource.TermStructure:
        case CreditSource.CDSTermStructure: {
          c.title = 'Value (bps)';
          (c as LvAdvancedGridNumericColumn).format = 'n0';
          (c as LvAdvancedGridNumericColumn).decimals = '0';
          break;
        }
        case CreditSource.DefaultRateTermStructure: {
          c.title = 'Default Rate (%)';
          (c as LvAdvancedGridNumericColumn).format = '#.##';
          (c as LvAdvancedGridNumericColumn).decimals = '2';
          (c as LvAdvancedGridNumericColumn).outputFormat = constants.numberFormat.upToTwoDigits;
          break;
        }
        default: {
          c.title = 'Probability (%)';
          (c as LvAdvancedGridNumericColumn).format = '#.##';
          (c as LvAdvancedGridNumericColumn).decimals = '2';
          (c as LvAdvancedGridNumericColumn).outputFormat = constants.numberFormat.upToTwoDigits;
        }
      }
    }

    if (c.field === 'upfront') {
      c.visible = this.creditSource === CreditSource.CDSTermStructure;
    }
  }

  /**
   * Set DM Key for Credit schedule columns.
   * @param field field type
   */
  private setDMKey(field: string): string {
    let dmKey = '';

    if (this.creditParametersSource === 'issuer' || !this.creditParametersSource) {
      if (this.creditSource === CreditSource.TermStructure) {
        switch (field) {
          case 'date':
            dmKey = 'DM-576';
            break;
          case 'value':
            dmKey = 'DM-577';
            break;
        }
      } else if (this.creditSource === CreditSource.CDSTermStructure) {
        switch (field) {
          case 'date':
            dmKey = 'DM-578';
            break;
          case 'value':
            dmKey = 'DM-579';
            break;
          case 'upfront':
            dmKey = 'DM-580';
            break;
        }
      } else if (this.creditSource === CreditSource.DefaultRateTermStructure) {
        switch (field) {
          case 'date':
            dmKey = 'DM-581';
            break;
          case 'value':
            dmKey = 'DM-582';
            break;
        }
      } else if (this.creditSource === CreditSource.SurvivalProbabilityTermStructure) {
        switch (field) {
          case 'date':
            dmKey = 'DM-583';
            break;
          case 'value':
            dmKey = 'DM-584';
            break;
        }
      }
    } else {
      if (this.creditSource === CreditSource.TermStructure) {
        switch (field) {
          case 'date':
            dmKey = 'DM-585';
            break;
          case 'value':
            dmKey = 'DM-586';
            break;
        }
      } else if (this.creditSource === CreditSource.CDSTermStructure) {
        switch (field) {
          case 'date':
            dmKey = 'DM-591';
            break;
          case 'value':
            dmKey = 'DM-592';
            break;
          case 'upfront':
            dmKey = 'DM-593';
            break;
        }
      } else if (this.creditSource === CreditSource.DefaultRateTermStructure) {
        switch (field) {
          case 'date':
            dmKey = 'DM-587';
            break;
          case 'value':
            dmKey = 'DM-588';
            break;
        }
      } else if (this.creditSource === CreditSource.SurvivalProbabilityTermStructure) {
        switch (field) {
          case 'date':
            dmKey = 'DM-589';
            break;
          case 'value':
            dmKey = 'DM-590';
            break;
        }
      }
    }

    return dmKey;
  }
}
