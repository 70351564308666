import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';
import { LvAdvancedGridColumn, LvAdvancedGridDateColumn, LvAdvancedGridTextColumn,
  LvAdvancedGridNumericColumn } from 'src/app/leversys-core-ui/components/lv-advanced-grid/lv-advanced-grid';
import { constants } from 'src/app/leversys-core-ui/util/constants';
import { DividendProtectionData } from '../../../models/convertible-bond-terms/DividendProtectionData';
import { ExpectedCR } from '../../../models/convertible-bond-terms/ExpectedCR';
import { Reset } from '../../../models/convertible-bond-terms/Reset';

export class LvEstimatesView {

  dividendProtection: DividendProtectionData;
  conversionReset: Reset;
  expectedCr: ExpectedCR;
  columns: LvAdvancedGridColumn[];

  constructor(
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService
  ) {
    this.columns = [];
    
    this.conversionReset = {
      schedule: [],
    } as Reset;
    
    this.dividendProtection = {
      conversionRatioAdjustment: {},
      cashDistribution: {},
      schedule: [],
    } as DividendProtectionData;

    this.expectedCr = {
      schedule: [],
    } as ExpectedCR;

    this.init(this.dividendProtection, this.conversionReset, this.expectedCr);
  }

  /**
   * Initializes view for estimates tab and sets default values if none is provided
   * @param {DividendProtectionData} dividendProtection
   * @param {Reset} conversionReset
   * @param  {ExpectedCR} expectedCr
   */
  init(dividendProtection?: DividendProtectionData, conversionReset?: Reset, expectedCr?: ExpectedCR): void {
    this.dividendProtection = dividendProtection ?? this._lvConvertibleBondTermsService.convertibleBondDocument.dividendProtection;
    this.conversionReset = conversionReset ?? this._lvConvertibleBondTermsService.convertibleBondDocument.conversion.reset;
    this.expectedCr = expectedCr ?? this._lvConvertibleBondTermsService.convertibleBondDocument.expectedCR;
  }

  /**
   * Initializes columns for dividend protection schedule table
   */
  initColumns(): void {
    const startDate = new LvAdvancedGridDateColumn();
    startDate.title = 'Start Date';
    startDate.field = 'startDate';
    startDate.width = 74;
    startDate.editable = false;

    const tresholdValueType = new LvAdvancedGridTextColumn();
    tresholdValueType.title = 'Treshold Value Type';
    tresholdValueType.field = 'tresholdValueType';
    tresholdValueType.width = 134;
    tresholdValueType.editable = false;

    const tresholdValue = new LvAdvancedGridNumericColumn();
    tresholdValue.title = 'Threshold Value';
    tresholdValue.field = 'tresholdValue';
    tresholdValue.width = 101;
    tresholdValue.outputFormat = constants.numberFormat.sixDigits;
    tresholdValue.editable = false;

    const triggerValue = new LvAdvancedGridNumericColumn();
    triggerValue.title = 'Trigger Value';
    triggerValue.field = 'triggerValue';
    triggerValue.width = 103;
    triggerValue.outputFormat = constants.numberFormat.fourDigits;
    triggerValue.editable = false;

    this.columns = [startDate, tresholdValueType, tresholdValue, triggerValue];
  }
}
