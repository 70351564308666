import { Component, OnInit, Input, EventEmitter, Output,
  ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, OnChanges, ViewChild } from '@angular/core';

export interface IWorkspaceEvent {
  id: string;
  name: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'workspace',
  templateUrl: './workspace.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceComponent implements OnInit, OnChanges {
  @ViewChild('inputElement') inputElement: ElementRef;
  @ViewChild('nameElement') nameElement: ElementRef;

  @Input() id: any;

  @Input() name: string;
  @Input() editable: boolean;
  @Input() closable: boolean;

  @Input() disabled: boolean;
  @Input() selected: boolean;

  @Output() doSelect: EventEmitter<IWorkspaceEvent>;
  @Output() didEdit: EventEmitter<IWorkspaceEvent>;
  @Output() doClose: EventEmitter<IWorkspaceEvent>;

  editMode: boolean;
  newName: string;
  nameWidth: number;

  get showOptions(): boolean {
    if (this.editMode) {
      return false;
    }

    return this.editable || this.closable;
  }

  get showEditMode(): boolean {
    if (!this.editMode) {
      return false;
    }

    return this.editMode && this.selected;
  }

  get showCloseIcon(): boolean {
    return this.closable && this.selected;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.doSelect = new EventEmitter<IWorkspaceEvent>();
    this.didEdit = new EventEmitter<IWorkspaceEvent>();
    this.doClose = new EventEmitter<IWorkspaceEvent>();
    this.editMode = false;
    this.editable = true;
    this.closable = true;
    this.disabled = false;
    this.selected = false;
    this.id = 'worspace-rand';
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (!this.selected) {
      this.editMode = false;
    }
  }

  onSelect() {
    if (!this.disabled) {
      this.doSelect.emit({
        id: this.id,
        name: this.name
      });
    }
  }

  onEdit() {
    if (!this.disabled) {
      this.newName = this.name;
      this.editMode = true;

      const nameRect = this.nameElement.nativeElement.getBoundingClientRect();
      if (nameRect.width > 126) {
        this.nameWidth = 126;
      }
      else {
        this.nameWidth = nameRect.width + 14;
      }

      this.changeDetectorRef.markForCheck();

      setTimeout(() => {
        this.inputElement.nativeElement.focus();
      }, 10);
    }
  }

  onClose() {
    if (!this.disabled && this.showCloseIcon) {
      this.doClose.emit({
        id: this.id,
        name: this.name
      });
    }
  }

  doUpdateName(name: string) {
    this.name = name;
    this.newName = this.name;
    this.editMode = false;
    this.didEdit.emit({
      id: this.id,
      name: this.name
    });
    this.changeDetectorRef.markForCheck();
  }

  onInputKeyPress(event: KeyboardEvent) {
    // tslint:disable-next-line
    if (!this.disabled && event.keyCode === 13) { // return key
      this.doUpdateName(this.newName || this.name);
    }
  }

  onInputKeyUp(event: KeyboardEvent) {
    // tslint:disable-next-line
    if (!this.disabled && event.keyCode === 27) { // esc key
      this.doUpdateName(this.name);
    }
  }

  onInputBlur(event: KeyboardEvent) {
    this.doUpdateName(this.newName || this.name);
  }
}
