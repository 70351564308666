<div class="lv-sensitivity-analysis-panel-dialog">
  <div class="lv-sensitivity-analysis-panel-dialog-mask"></div>
  <div class="lv-sensitivity-analysis-panel-dialog-content">
    <div>You have unsaved changes on <span class="continue-editing-item-name">{{ selectedScenarioTemplate.scenarioTemplateName }}</span>.</div>
    <div>You can continue editing <span class="continue-editing-item-name">{{ selectedScenarioTemplate.scenarioTemplateName }}</span> or discard it's changes.</div>

    <div class="lv-sensitivity-analysis-panel-dialog-options">
        <button kendoButton
          look="flat"
          lvId="edit-watch-lists-continue-editing"
          (click)="onContinueEditing()">Continue editing</button>
        <button kendoButton
          [primary]="true"
          lvId="edit-watch-lists-discard-changes"
          (click)="onDicardChanges()">Discard changes</button>
      </div>
  </div>
</div>