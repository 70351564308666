/**
 * Event type enum
 */
export enum EventType {
  NewIssueSetup = 'NewIssueSetup',
  NewIssueFixed = 'NewIssueFixed',
  NewIssueSetupCorrected = 'NewIssueSetupCorrected',
  ConversionRatioEstimate = 'ConversionRatioEstimate',
  ConversionRatioConfirmed = 'ConversionRatioConfirmed',
  InstrumentStatusChanged = 'InstrumentStatusChanged',
  ISINChanged = 'ISINChanged'
}

/**
 * Event type enum description
 */
export enum EventTypeDescription {
  NewIssueSetup = 'When a new issue has been set up',
  NewIssueFixed = 'When a new issue has been fixed',
  NewIssueSetupCorrected = 'When a new issue setup has been corrected',
  ConversionRatioEstimate = 'When there is a new conversion ratio estimate',
  ConversionRatioConfirmed = 'When a new conversion ratio has been confirmed',
  InstrumentStatusChanged = 'When instrument status has been changed',
  ISINChanged = 'When ISIN has been changed'
}
