<lv-advanced-grid
data-cy="DM-932"
[columns]="columns"
[records]="scheduleItems"
[createFormGroup]="createFormGroup"
[parseFn]="parseFn"
sortBy="startDate"
lvId="lvTermsCallsSchedule"
[showXlLabel]="hasScheduleInExcelOverride"
[excelFieldAlias]="excelFieldAlias"
[isFieldFromExcelEnabled]="isFieldFromExcelEnabled"
class="lv-terms-calls-schedule">
</lv-advanced-grid>
