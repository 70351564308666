import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input,
  ViewChild, Output, EventEmitter, OnDestroy, HostBinding, Optional, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';

import { Subscription } from 'rxjs';

import { constants, LvLookupEnum } from '@lv-core-ui/util';
import { LvAdvancedGridComponent,
         LvAdvancedGridColumn,
         LvAdvancedGridDateColumn,
         LvAdvancedGridNumericColumn,
         LvAdvancedGridEnumColumn,
         LvAdvancedGridTextColumn } from '@lv-core-ui/components';
import { LvDataMaster, LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { IScheduledDividend } from '@lv-analytics/models/market-data/dividends/scheduled-dividend';
import { DividendTypeEnum, DividendTypeEnumDescription } from '@lv-analytics/models/market-data/dividends';
import { MarketDataClipboard } from '@lv-analytics/components/market-data/market-data-clipboard';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
// tslint:disable-next-line: max-line-length
import { LvCopyAndPasteDialogComponent } from '@lv-analytics/components/market-data/lv-market-data/lv-copy-and-paste-dialog/lv-copy-and-paste-dialog.component';
import { IDividendsCopyAndPasteSectionSettings } from '@lv-analytics/models';
import { LvMarketDataError } from '@lv-market-data/models';
import { LvExcelService } from '@lv-excel/services';

/**
 * Schedule dividends table component.
 */
@Component({
  selector: 'lv-schedule-dividends-table',
  templateUrl: './lv-schedule-dividends-table.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvScheduleDividendsTableComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  @Input() editEnabled: boolean;
  @Input() isParametersTable: boolean;
  @Input() scheduleDividends: IScheduledDividend[];
  @Input() pricingDividendYield?: number;
  @Input() dividendsCopyAndPasteSettings: IDividendsCopyAndPasteSectionSettings;

  @Output() dividendScheduleChange: EventEmitter<void>;

  columns: LvAdvancedGridColumn[];
  parseFn: any;
  excelFieldAlias = 'DVD_SCHED_RANGE_1';
  excelFieldAlias2 = 'DVD_SCHED_RANGE_2';

  private _subscriptions: Subscription[];

  get isDividendYieldOverrideUsed() {
    return !!this.pricingDividendYield;
  }

  get excelFieldAliasLabel(): string {
    return !!this._excelSvc?.containsField(this.excelFieldAlias) ? this.excelFieldAlias : this.excelFieldAlias2;
  }

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias) ||  !!this._excelSvc?.containsField(this.excelFieldAlias2);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias) ?
      !!this._excelSvc?.getField(this.excelFieldAlias)?.editable :
      !!this._excelSvc?.getField(this.excelFieldAlias2)?.editable;
  }

  constructor(
    private _errorService: LvErrorService,
    private dialogService: DialogService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.scheduleDividends = [];
    this.parseFn = this.parsePastedData.bind(this);
    this.dividendScheduleChange = new EventEmitter();
    this.dividendsCopyAndPasteSettings = {} as IDividendsCopyAndPasteSectionSettings;
  }

  @HostBinding('class.schedule-dividends-table-wrapper')
  get isScheduleDividendTable() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this.advancedGrid.didDataChange.subscribe((records: IScheduledDividend[]) => this.onDataChange(records)),
      this.advancedGrid.doReload.subscribe(() => this.onReload()),
      this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];

    this.initColumns();
  }

  /**
   * Occurs on changes and initializes columns if there is schedule in Excel override.
   */
  ngOnChanges() {
    if (this.hasScheduleInExcelOverride) {
      this.initColumns();
    }
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe);
  }

  /**
   * Occurs on data change.
   * @param records List of IScheduledDividend objects.
   */
  public onDataChange(records: IScheduledDividend[]) {
    this.applyRecords(records);
    this.dividendScheduleChange.next();
  }

  /**
   * Applies advanced grid changes.
   */
  public applyAdvancedGridChanges() {
    this.advancedGrid.applyChanges(records => this.applyRecords(records));
  }

  /**
   * Occurs on reload.
   */
  public onReload() {
    this.scheduleDividends = this.scheduleDividends.map(x => ({ ...x }));
  }

  /**
   * Handles error.
   * @param error LvError object.
   */
  public onError(error: LvError) {
    this._errorService.handleError(error);
  }

  /**
   * Creates form group.
   * @param args CreateFormGroupArgs object.
   * @returns FormGroup object.
   */
  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return new FormGroup({
      'date': new FormControl(args.dataItem.date || new Date(), Validators.required),
      'value': new FormControl(args.dataItem.value, Validators.required),
      'type': new FormControl(args.dataItem.type || DividendTypeEnum.Cash, Validators.nullValidator),
      'netFactor': new FormControl(args.dataItem.netFactor, Validators.nullValidator),
      'dividendsCcy': new FormControl(args.dataItem.dividendsCcy, Validators.nullValidator),
    });
  }

  /**
   * Applies records.
   * @param records List of records.
   */
  private applyRecords(records: any[]) {
    this.scheduleDividends.splice(0, this.scheduleDividends.length);
    this.scheduleDividends.push(...records);
  }

  /**
   * Columns initialization.
   */
  private initColumns() {
    this.columns = [];
    const dateColumn = new LvAdvancedGridDateColumn();
    dateColumn.title = 'Date';
    dateColumn.field = 'date';
    dateColumn.width = 104;
    dateColumn.editable = this.editEnabled;
    dateColumn.dmKey = 'DM-601';

    // This could be the private method that returns boolean and also checks if excel service is initalized..

    const valueColumn = new LvAdvancedGridNumericColumn();
    valueColumn.title = 'Value';
    valueColumn.field = 'value';
    valueColumn.width = 85;
    valueColumn.decimals = '4';
    valueColumn.format = '#.####';
    valueColumn.outputFormat = constants.numberFormat.upToFourDigits;
    valueColumn.editable = this.editEnabled;
    valueColumn.dmKey = 'DM-602';

    const typeColumn = new LvAdvancedGridEnumColumn();
    typeColumn.enumDescription = DividendTypeEnumDescription;
    typeColumn.title = 'Type';
    typeColumn.field = 'type';
    typeColumn.displayField = 'text',
    typeColumn.valueField = 'id',
    typeColumn.valuePrimitive = true,
    typeColumn.editable = this.editEnabled;
    typeColumn.width = 139;
    typeColumn.data = new LvLookupEnum(DividendTypeEnumDescription).items;
    typeColumn.dmKey = 'DM-603'

    const netFactorColumn = new LvAdvancedGridNumericColumn();
    netFactorColumn.title  = this.hasScheduleInExcelOverride ? 'N. Fact' : 'Net Factor';
    netFactorColumn.field = 'netFactor';
    netFactorColumn.width = 60;
    netFactorColumn.decimals = '3';
    netFactorColumn.format = '#.###';
    netFactorColumn.outputFormat = constants.numberFormat.upToThreeDigits;
    netFactorColumn.editable = this.editEnabled;
    netFactorColumn.dmKey = 'DM-604'

    const ccyColumn = new LvAdvancedGridTextColumn();
    ccyColumn.title = 'Currency';
    ccyColumn.field = 'dividendsCcy';
    ccyColumn.editable = this.editEnabled;
    ccyColumn.visible = this.isParametersTable;

    this.columns.push(dateColumn);
    this.columns.push(valueColumn);
    this.columns.push(typeColumn);
    this.columns.push(netFactorColumn);
    this.columns.push(ccyColumn);
  }

  /**
   * Based on saved copy-paste settings this method can show dialog with copied elements
   * from outside of app and select which element to paste to app or directly paste data to grid
   * @param pastedDataRecords records copied outside of application
   */
  private parsePastedData(pastedDataRecords: string[]): IScheduledDividend[] {
    if (this.dividendsCopyAndPasteSettings.displayValuesBeforePaste) {

      try {
        if (pastedDataRecords.length <= 1 && pastedDataRecords[0].split('\t').length <= 1) {
          throw new LvError('');
        }

        let scheduledDividends: IScheduledDividend[] = [];

        const dialogRef: DialogRef = this.dialogService.open({
          title: 'Dividends Copy & Paste',
          content: LvCopyAndPasteDialogComponent,
          width: 584,
          height: 657
        });

        const dialog = (dialogRef.content.instance as LvCopyAndPasteDialogComponent);
        dialog.data = pastedDataRecords;
        dialog.componentName = 'dividends';
        dialog.selectionStart = this.dividendsCopyAndPasteSettings.startFromRow;
        dialog.selectionEnd = pastedDataRecords.length;
        dialog.firstColumnSelectedIndex = this.dividendsCopyAndPasteSettings.forDateValueUseColumn;
        dialog.secondColumnSelectedIndex = this.dividendsCopyAndPasteSettings.forDividendValueUseColumn;

        this._subscriptions.push(dialog.didPaste.subscribe(res => {
          try {
            scheduledDividends = [];

            this.parseData(res, scheduledDividends);

            this.advancedGrid.onParseDataDialog(scheduledDividends);
            dialogRef.close();
          }
          catch (e) {
            this._errorService.handleError(new LvMarketDataError(e.message));
          }
        }));

        return this.advancedGrid.records;
      }
      catch (e) {
        this._errorService.handleError(new LvMarketDataError(LvDataMaster.getError('dM-3393')));
      }
    }
    else {
      if (pastedDataRecords.length <= 1 && pastedDataRecords[0].split('\t').length <= 1) {
        throw new LvMarketDataError(LvDataMaster.getError('dM-3393'));
      }

      const scheduledDividends: IScheduledDividend[] = [];

      const startFromRow = this.dividendsCopyAndPasteSettings.startFromRow;
      const selectionEnd = pastedDataRecords.length;
      const firstColumnSelectedIndex = this.dividendsCopyAndPasteSettings.forDateValueUseColumn - 1;
      const secondColumnSelectedIndex = this.dividendsCopyAndPasteSettings.forDividendValueUseColumn - 1;

      const fields = [];

      const columnLength =
        MarketDataClipboard.getNumberOfColumnsAndSetFieldsForCopyAndPasteScheduledData(pastedDataRecords, fields);
      const formatedPastedDataRecords =
        MarketDataClipboard.preparePastedDataToParse(pastedDataRecords, fields, columnLength);

      const selectedRowsData = formatedPastedDataRecords.filter((item, index) => {
        return index >= startFromRow - 1 && index <= selectionEnd;
      });

      const result = selectedRowsData.map(item => {
        return `${item['field' + firstColumnSelectedIndex]}\t${item['field' + secondColumnSelectedIndex]}`;
      });

      this.parseData(result, scheduledDividends);

      return scheduledDividends;
    }
  }

  /**
   * Parse formated data into scheduled dividends table
   * @param formatedData Formated pasted data
   * @param scheduledDividends Scheduled dividends tabel data
   */
  private parseData(formatedData: string[], scheduledDividends: IScheduledDividend[]) {
    formatedData.forEach((pasteResult) => {
      const items = pasteResult.split('\t');

      const dateValue = items[0];
      const valueValue = items[1];

      const date = MarketDataClipboard.parseDateValue(dateValue, 'Date');
      const value = MarketDataClipboard.parseNumberValue(valueValue, 'Value');
      const netFactor = 100;
      const dividendType = DividendTypeEnum.Cash;

      scheduledDividends.push({
        date: date,
        value: value,
        netFactor: netFactor,
        type: dividendType,
        dividendsCcy: null
      });
    });
  }
}
