<div class="lv-watch-list-panel-dialog-item">
  <div class="lv-watch-list-panel-dialog-item-column im-column">
    <div class="lv-watch-list-panel-dialog-item-column-header">
      <label>Instruments</label>
      <lv-text-box
        name="editWatchListsSearchAllInstruments"
        [searchIconVisible]="true"
        placeholder="Search for instrument"
        [(ngModel)]="view.instrumentsGridQuery"
        (ngModelChange)="onFilterInstrumentsGrid()">
      </lv-text-box>
    </div>
    <div class="lv-watch-list-panel-dialog-item-column-content">
      <kendo-grid
        lvId="edit-watch-lists-all-instruments"
        [data]="view.instrumentsGridView.recordsView"
        [skip]="view.instrumentsGridView.skip"
        [pageSize]="view.instrumentsGridView.pageSize"
        scrollable="virtual"
        [rowHeight]="24"
        (pageChange)="view.instrumentsGridView.onPageChange($event)"
        [selectable]="view.instrumentsGridView.selectableSettings"
        [kendoGridSelectBy]="view.instrumentsGridView.recordIdentifier"
        [selectedKeys]="view.instrumentsGridView.selectedIdentifiers"
        (selectedKeysChange)="onInstrumentGridViewSelectedKeysChange($event)">
        <!-- <kendo-grid-column [width]="80"
          title="Type">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="text-overflow-ellipsis" kendoTooltip filter="span[data-title]">
              <span [attr.data-title]="dataItem.instrumentType">{{ dataItem.instrumentType }}</span>
            </div>
          </ng-template>
        </kendo-grid-column> -->
        <kendo-grid-column title="Instrument Name">
          <ng-template kendoGridCellTemplate let-dataItem>
            <lv-flex-box direction="row">
              <div class="text-overflow-ellipsis" kendoTooltip filter="span[data-title]">
                <span [attr.data-title]="dataItem.name">{{ dataItem.name }}</span>
              </div>
              <div *ngIf="dataItem.isPrivateInstrument" class="lv-instrument-monitor-icon" kendoTooltip>
                <i lvAccessScope [accessScope]="dataItem.accessScope"></i>
              </div>
            </lv-flex-box>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="100"
          title="Short Name"
          field="shortName">
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>

  <div class="lv-watch-list-panel-dialog-item-column-spacer"></div>

  <div class="lv-watch-list-panel-dialog-item-column">
    <div class="lv-watch-list-panel-dialog-item-column-header">
      <label>Available watchlists</label>
      <lv-text-box
        name="editWatchListsSearchAvailableWatchlists"
        [searchIconVisible]="true"
        placeholder="Search for watchlist"
        [(ngModel)]="view.watchListsQuery">
      </lv-text-box>
    </div>
    <div class="lv-watch-list-panel-dialog-item-column-content watch-list">
      <div lvId="edit-watch-lists-available-watch-lists"
        class="watch-list-container">
        <div *ngFor="let wl of view.watchLists | searchArray : view.watchListsQuery : 'listName' : 'isGroup'; trackBy:view.watchListsTrackBy"
          class="lv-watch-list-item--normal-padding"
          [class.lv-watch-list-item]="!wl.isGroup"
          [class.lv-watch-list-group]="wl.isGroup"
          [class.lv-watch-list-item--selected]="view.isWatchListSelected(wl)"
          (click)="onSelectWatchList(wl, false)">
          <span>{{ wl.listName }}</span>
          <div *ngIf="!wl.isGroup"
            class="lv-icon-square"
            [class.lv-icon-square--disabled]="!editEnabled"
            lvId="edit-watch-lists-add-wl-to-instr"
            (click)="onAddInstrumentToWatchList(wl)">
            <i class="k-icon k-i-add"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="lv-watch-list-panel-dialog-item-column-footer">
      <button kendoButton
      [primary]="true"
      [disabled]="view.isSelectAllDisabled"
      lvId="edit-watch-lists-add-all-wls"
      (click)="onSaveAll()">Select All</button>
    </div>
  </div>

  <div class="lv-watch-list-panel-dialog-item-column-spacer"></div>

  <div class="lv-watch-list-panel-dialog-item-column">
    <div class="lv-watch-list-panel-dialog-item-column-header">
      <label>Selected watchlists</label>
      <lv-text-box
        name="editWatchListsSearchSelectedWatchlists"
        [searchIconVisible]="true"
        placeholder="Search for watchlist"
        [(ngModel)]="view.selectedWatchListsQuery">
      </lv-text-box>
    </div>
    <div class="lv-watch-list-panel-dialog-item-column-content watch-list">
      <div lvId="edit-watch-lists-selected-watch-lists"
        class="watch-list-container">
        <div *ngFor="let wl of view.selectedWatchLists?.current | searchArray : view.selectedWatchListsQuery : 'listName' : 'isGroup'; trackBy:view.watchListsTrackBy"
          class="lv-watch-list-item--normal-padding"
          [class.lv-watch-list-item]="!wl.isGroup"
          [class.lv-watch-list-group]="wl.isGroup"
          [class.lv-watch-list-item--selected]="view.isWatchListSelected(wl)"
          (click)="onSelectWatchList(wl, true)">
          <span>{{ wl.listName }}</span>
          <div *ngIf="!wl.isGroup"
            class="lv-icon-square lv-icon-square--danger"
            [class.lv-icon-square--disabled]="!editEnabled"
            lvId="edit-watch-lists-remove-wl-from-instr"
            (click)="onRemoveInstrumentFromWatchList(wl)">
            <i class="k-icon k-i-delete"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="lv-watch-list-panel-dialog-item-column-footer">
      <button kendoButton
      [primary]="true"
      [disabled]="view.isClearAllDisabled"
      lvId="edit-watch-lists-remove-all-wls"
      (click)="onClearAll()">Clear All</button>
    </div>
  </div>
</div>
