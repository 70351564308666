<kendo-tabstrip class="lv-tabstrip marketData-settings-tabstrip"
  [keepTabContent]="true">

  <kendo-tabstrip-tab
    selected="true"
    title="Credit"
    cssClass="DM-1246">
    <ng-template kendoTabContent>
      <lv-credit-settings
        [settings]="settings.creditSettings"
        data-cy="DM-1246">
    </lv-credit-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="Volatility"
    cssClass="DM-1247">
    <ng-template kendoTabContent>
      <lv-volatility-settings
        [settings]="settings.volatilitySettings"
        data-cy="DM-1247">
      </lv-volatility-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="Borrow"
    cssClass="DM-1248">
    <ng-template kendoTabContent>
      <lv-borrow-settings
        [settings]="settings.borrowSettings"
        data-cy="DM-1248">
      </lv-borrow-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="Dividends"
    cssClass="DM-1249">
    <ng-template kendoTabContent>
      <lv-dividends-settings
        [settings]="settings.dividendsSettings"
        data-cy="DM-1249">
      </lv-dividends-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="Funding"
    cssClass="DM-1250">
    <ng-template kendoTabContent>
      <lv-funding-settings
      [settings]="settings.fundingSettings"
      data-cy="DM-1250">
    </lv-funding-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="Withholding Tax"
    cssClass="DM-1251">
    <ng-template kendoTabContent>
      <lv-withholding-tax-settings
        [settings]="settings.withholdingTaxSettings"
        data-cy="DM-1251">
      </lv-withholding-tax-settings>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab
    title="Interest Rates"
    cssClass="DM-1252">
    <ng-template kendoTabContent>
      <lv-interest-rates-settings
        [settings]="settings.interestRatesSettings"
        [systemYieldCurvesSettingsData]="systemYieldCurvesSettingsData"
        data-cy="DM-1252">
      </lv-interest-rates-settings>
    </ng-template>
  </kendo-tabstrip-tab>

</kendo-tabstrip>
<lv-flex-box class="lv-market-data-settings-control-panel" direction="row">
    <button kendoButton
     class="reset-button"
     [primary]="true"
     lvId="reset-to-default-market-data-settings"
     (click)="onResetToDefault()">
     Reset To Default
    </button>
    <button kendoButton 
     class= "cancel-btn"
     [primary]="true"
     lvId="cancel-market-data-settings"
     (click)="onCancel()">
     Cancel
   </button>
   <button kendoButton
     [primary]="true"
     lvId="save-market-data-settings"
     (click)="onSave()">
     Save
   </button>  
</lv-flex-box>
