/**
 * Volatility surface schedule.
 */
export class VolatilitySurfaceSchedule {
  date: Date;
  dateValue: number;
  v80: number;
  v85: number;
  v90: number;
  v95: number;
  v100: number;
  v105: number;
  v110: number;
  v115: number;
  v120: number;

  constructor() {
    this.date = null;
    this.dateValue = null;
    this.v80 = null;
    this.v85 = null;
    this.v90 = null;
    this.v95 = null;
    this.v100 = null;
    this.v105 = null;
    this.v110 = null;
    this.v115 = null;
    this.v120 = null;
  }

  /**
   * Initializes default values.
   */
  public initializeDefaults() {
    this.date = null;
    this.dateValue = null;
    this.v80 = 80;
    this.v85 = 85;
    this.v90 = 90;
    this.v95 = 95;
    this.v100 = 100;
    this.v105 = 105;
    this.v110 = 110;
    this.v115 = 115;
    this.v120 = 120;
  }
}
