<div class="lv-modal-mask" [@mask]
  *ngIf="isOpened"
  [ngClass]="maskClass">
</div>
<div class="lv-modal" [@modal]
  *ngIf="isOpened"
  [ngClass]="modalClass">
  <div class="lv-modal-table">
    <div class="lv-modal-table-cell">
      <div class="lv-modal-table-cell-content">
        <div class="lv-modal-container lv-error-modal">
            <div class="lv-modal-title">
              Unexpected error
            </div>
            <div class="lv-modal-content">
              <div>An unexpected error happened.
                <span class="link" (click)="toggleShowStackTrace()">{{ spanText }}</span>
              </div>
              <div *ngIf="showStackTrace">
                <div class="lv-modal-error-message">Stack Trace</div>
                <div class="lv-error-modal-stack-trace">{{ unexpectedError.stack }}</div>
              </div>
            </div>
            <div class="lv-modal-footer">
              <button
                *ngIf="isErrorHappenedMoreThanOneTime"
                kendoButton
                [primary]="true"
                (click)="onDestroyWorkspace()">
                Destroy Workspace
              </button>
              <button
                kendoButton
                [primary]="true"
                (click)="onClose()">
                Close
              </button>
              <button
                kendoButton
                class="lv-modal-error-button"
                [primary]="true"
                (click)="onDownloadErrorReport()">
                Download Error Report
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
