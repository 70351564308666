<div class="lv-label-field lv-label lv-label--title">DEFAULT</div>
<div class="lv-environment-system-settings-content">
  <div class="lv-label lv-environment-system-settings-content-label"
       data-cy="DM-1151"
       showAfter="1500"
       kendoTooltip
       [tooltipTemplate]="allSectionsTooltipTemplate"
       filter=".lv-environment-system-settings-content-label">All Sections</div>
  <ng-container *ngFor="let item of availableSettings">
    <div class="lv-environment-system-settings-content-item">
      <div class="k-icon k-i-more-vertical"></div>
      <div class="lv-label lv-environment-system-settings-content-item-label">{{item.name}}</div>
    </div>
  </ng-container>
  <div class="lv-environment-system-settings-content-edit"
    (click)="openEnvironmentOrderEdit()">
    <div class="k-icon k-i-pencil"></div>
  </div>
</div>

<ng-template #allSectionsTooltipTemplate>
  <lv-tooltip dm="dM-1151"></lv-tooltip>
</ng-template>
