import { LvUserFriendlyError } from '@lv-core-ui/models';

/**
 * Notifications error type
 */
export class LvNotificationsError extends LvUserFriendlyError {
  constructor(message: string) {
    super(message, 'Notifications');
  }
}
