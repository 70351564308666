<lv-flex-box direction="row">
  <lv-flex-box class="general-settings-column">
    <lv-flex-box
      data-cy="DM-1516"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="convertibleTypeSourceTooltipTemplate"
        filter="span">
        <span> Convertible Type </span>
    </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          name="instrument-subtype"
          [disabled]="!overrideDefaults"
          [data]="instrumentSubTypeLookup.items"
          valueField="id"
          textField="text"
          valuePrimitive="true"
          [popupSettings]="{ width: 237 }"
          [(ngModel)]="settings.settings.subType">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box
      data-cy="DM-1517"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="CCYSourceTooltipTemplate"
        filter="span">
        <span> CCY </span>
      </div>
      <div class="lv-input-field">
        <kendo-dropdownlist
          lvId="lv-general-ccy"
          class="lv-terms-general-small-input"
          [disabled]="!overrideDefaults"
          name="ccy"
          [data]="currencyLookup.items"
          valueField="id"
          textField="text"
          valuePrimitive="true"
          [(ngModel)]="settings.settings.currencyCode">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box
      data-cy="DM-1518"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="underlyingCCYSourceTooltipTemplate"
        filter="span">
        <span> Underlying CCY </span>
      </div>
      <div class="lv-input-field">
        <kendo-dropdownlist
          lvId="lv-general-underlying-ccy"
          class="lv-terms-general-small-input"
          [disabled]="!overrideDefaults"
          name="underlying-ccy"
          [data]="currencyLookup.items"
          valueField="id"
          textField="text"
          valuePrimitive="true"
          [(ngModel)]="settings.settings.underlyingEquity.currencyCode">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box
      data-cy="DM-1519"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="maturityCCYSourceTooltipTemplate"
        filter="span">
        <span>
          Maturity CCY
        </span>
      </div>
      <div class="lv-input-field">
        <kendo-dropdownlist
          lvId="lv-general-maturity-ccy"
          class="lv-terms-general-small-input"
          [disabled]="!overrideDefaults"
          name="ccy"
          [data]="currencyLookup.items"
          valueField="id"
          textField="text"
          valuePrimitive="true"
          [(ngModel)]="settings.settings.maturingCurrencyCode">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box
      data-cy="DM-1520"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="exchangeableTypeSourceTooltipTemplate"
        filter="span">
        <span> Exchangeable Type </span>
      </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          lvId="lv-general-exchangeable-type"
          name="exchangeable-type"
          [disabled]="!overrideDefaults"
          [data]="exchangeableTypeLookup.items"
          valueField="id"
          textField="text"
          valuePrimitive="true"
          [popupSettings]="{ width: 206 }"
          [(ngModel)]="settings.settings.exhangeableParameters.exhangeableType">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box
      data-cy="DM-1521"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="percentOfPledgedSharesSourceTooltipTemplate"
        filter="span">
        <span> % Pledged Shares </span>
      </div>
      <div class="lv-input-field align-sufix">
        <lv-numeric-text-box
          [format]="formatTwo"
          [decimals]="decimalsTwo"
          [disabled]="!overrideDefaults"
          name="pledged-shares"
          [(ngModel)]="settings.settings.exhangeableParameters.percentOfPledgedShares">
        </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>
    </lv-flex-box>
    <lv-flex-box
      data-cy="DM-1522"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="nominalValueSourceTooltipTemplate"
        filter="span">
        <span> Nominal Value </span>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          [format]="formatFour"
          [decimals]="decimalsFour"
          [disabled]="!overrideDefaults"
          name="nominal-value-general"
          [(ngModel)]="settings.settings.nominalValue">
        </lv-numeric-text-box>
      </div>
    </lv-flex-box>
    <lv-flex-box 
      data-cy="DM-1523"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="issueValueSourceTooltipTemplate"
        filter="span">
        <span> Issue Price </span>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          [format]="formatTwo"
          [decimals]="decimalsTwo"
          [disabled]="!overrideDefaults"
          name="issue-value-general"
          [(ngModel)]="settings.settings.issueValue">
        </lv-numeric-text-box>
      </div>
      <div 
        data-cy="DM-2409"
        class="lv-field lv-field--checkbox lv-terms-general-checkbox">

        <input class="k-checkbox"
          type="checkbox"
          [disabled]="!overrideDefaults"
          [attr.id]="issueValueNominalCheckboxId"
          [(ngModel)]="settings.settings.issueValueIsPercentOfNominal"
          name="issue-value-nominal">
        <label class="k-checkbox-label"
          lvId="percentOfParIssueValueGeneralSettings"
          [attr.for]="issueValueNominalCheckboxId">
          % of Par
        </label>
      </div>
    </lv-flex-box>
  
    <lv-flex-box
      data-cy="DM-1524"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="redemptionValueSourceTooltipTemplate"
        filter="span">
        <span> Redemption Value </span>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          [format]="formatTwo"
          [disabled]="!overrideDefaults"
          [decimals]="decimalsTwo"
          name="redemption-value"
          [(ngModel)]="settings.settings.redemptionValue">
        </lv-numeric-text-box>
      </div>
      <div 
        data-cy="DM-2410"
        class="lv-field lv-field--checkbox lv-terms-general-checkbox">
    
        <input class="k-checkbox"
          type="checkbox"
          [disabled]="!overrideDefaults"
          [attr.id]="issueValueRedemptionCheckboxId"
          [(ngModel)]="settings.settings.redemptionValueIsPercentOfNominal"
          name="issue-value-redemption">
        <label class="k-checkbox-label"
          lvId="percentOfParRedemptionGeneral"
          [attr.for]="issueValueRedemptionCheckboxId">
          % of Par
        </label>
      </div>
    </lv-flex-box>
    <lv-flex-box 
      data-cy="DM-1525"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="quotedSourceTooltipTemplate"
        filter="span">
        <span> Quoted </span>
      </div>
      <div class="lv-input-field">
        <kendo-dropdownlist
          lvId="lv-general-quoted"
          name="quoted"
          [data]="isQuotedCleanLookup.items"
          valueField="id"
          [disabled]="!overrideDefaults"
          [(ngModel)]="quotedValue"
          textField="text"
          valuePrimitive="true"
          (ngModelChange)="quotedValueChange()">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box 
      data-cy="DM-1526"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="priceAsParSourceTooltipTemplate"
        filter="span">
        <span> Price as Par </span>
      </div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="priceAsParCheckboxId"
          [disabled]="!overrideDefaults"
          [(ngModel)]="settings.settings.isPriceAsPar"
          name="price-as-par">
        <label class="k-checkbox-label"
          lvId="priceAsParGeneral"
          [attr.for]="priceAsParCheckboxId">
        </label>
      </div>
    </lv-flex-box>
    <lv-flex-box 
      data-cy="DM-1527"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="accruedUponDefaultSourceTooltipTemplate"
        filter="span">
        <span> Accrued Upon Default </span>
      </div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [disabled]="!overrideDefaults"
          [attr.id]="accruedUponDefaultCheckboxId"
          [(ngModel)]="settings.settings.isAccruedUponDefault"
          name="accrued-upon-default">
        <label class="k-checkbox-label"
          lvId="accruedUpon"
          [attr.for]="accruedUponDefaultCheckboxId">
        </label>
      </div>
    </lv-flex-box>
    <lv-flex-box 
      data-cy="DM-1528"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="recoveryUponDefaultSourceTooltipTemplate"
        filter="span">
        <span> Recovery Upon Default </span>
      </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          lvId="lv-general-recovery"
          name="underlying-ccy"
          [disabled]="!overrideDefaults"
          [data]="recoveryUponDefaultLookup.items"
          [(ngModel)]="settings.settings.recoveryUponDefault"
          valueField="id"
          textField="text"
          valuePrimitive="true"
          [popupSettings]="{ width: 128 }">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box 
      data-cy="DM-1529"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="percentageOfCoveredWarrantsSourceTooltipTemplate"
        filter="span">
        <span> % of Covered Warrants </span>
      </div>
      <div class="lv-input-field align-sufix">
        <lv-numeric-text-box
          [format]="formatTwo"
          [decimals]="decimalsTwo"
          [disabled]="!overrideDefaults"
          [(ngModel)]="settings.settings.percCoveredWarrants"
          name="cov-warrant">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>
    <lv-flex-box 
      data-cy="DM-1530"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="daysToSettleSourceTooltipTemplate"
        filter="span">
        <span> Days To Settle </span>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          [format]="formatZero"
          [decimals]="decimalsZero"
          [disabled]="!overrideDefaults"
          name="days-to-settle"
          [(ngModel)]="settings.settings.daysToSettle">
        </lv-numeric-text-box>
      </div>
    </lv-flex-box>
  </lv-flex-box>
  <lv-flex-box>
    <div class="lv-label-field lv-label lv-label--title">APPLICATION ONLY</div>
    <lv-flex-box 
      data-cy="DM-3800"
      direction="row">

      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="statusSourceTooltipTemplate"
        filter="span">
        <span> Status </span>
      </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          lvId="lv-general-settings-status"
          name="lv-general-settings-status"
          [data]="setupStatusLookup.items"
          [disabled]="!overrideDefaults"
          valueField="id"
          textField="text"
          valuePrimitive="true"
          [popupSettings]="{ width: 136 }"
          [(ngModel)]="settings.settings.status">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<ng-template #convertibleTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1516"></lv-tooltip>
</ng-template>
<ng-template #CCYSourceTooltipTemplate>
  <lv-tooltip dm="dM-1517"></lv-tooltip>
</ng-template>
<ng-template #underlyingCCYSourceTooltipTemplate>
  <lv-tooltip dm="dM-1519"></lv-tooltip>
</ng-template>
<ng-template #maturityCCYSourceTooltipTemplate>
  <lv-tooltip dm="dM-1519"></lv-tooltip>
</ng-template>
<ng-template #exchangeableTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1520"></lv-tooltip>
</ng-template>
<ng-template #percentOfPledgedSharesSourceTooltipTemplate>
  <lv-tooltip dm="dM-1521"></lv-tooltip>
</ng-template>
<ng-template #nominalValueSourceTooltipTemplate>
  <lv-tooltip dm="dM-1522"></lv-tooltip>
</ng-template>
<ng-template #issueValueSourceTooltipTemplate>
  <lv-tooltip dm="dM-1523"></lv-tooltip>
</ng-template>
<ng-template #redemptionValueSourceTooltipTemplate>
  <lv-tooltip dm="dM-1524"></lv-tooltip>
</ng-template>
<ng-template #quotedSourceTooltipTemplate>
  <lv-tooltip dm="dM-1525"></lv-tooltip>
</ng-template>
<ng-template #priceAsParSourceTooltipTemplate>
  <lv-tooltip dm="dM-1526"></lv-tooltip>
</ng-template>
<ng-template #accruedUponDefaultSourceTooltipTemplate>
  <lv-tooltip dm="dM-1527"></lv-tooltip>
</ng-template>
<ng-template #recoveryUponDefaultSourceTooltipTemplate>
  <lv-tooltip dm="dM-1528"></lv-tooltip>
</ng-template>
<ng-template #percentageOfCoveredWarrantsSourceTooltipTemplate>
  <lv-tooltip dm="dM-1529"></lv-tooltip>
</ng-template>
<ng-template #daysToSettleSourceTooltipTemplate>
  <lv-tooltip dm="dM-1530"></lv-tooltip>
</ng-template>
<ng-template #statusSourceTooltipTemplate>
  <lv-tooltip dm="dM-3800"></lv-tooltip>
</ng-template>
