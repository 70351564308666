import { v4 } from 'uuid';

export interface IWorkspaceItem {
  id?: string;
  name?: string;
  selected?: boolean;
  disabled?: boolean;

  editable?: boolean;
  closable?: boolean;
}

export const getDefaultConfig = (config?: IWorkspaceItem): IWorkspaceItem => {
  const id = v4();
  return Object.assign({
    id: `${id}`,
    name: `${id}`,
    selected: false,
    disabled: false,
    editable: true,
    closable: true
  } as IWorkspaceItem, config || {});
};
