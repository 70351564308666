

import { ExchangeableType } from './ExchangeableType';

export class ExhangeableParameters {
    
    exhangeableType: ExchangeableType;
    percentOfPledgedShares: number;
    defaultOnLowParity: boolean;
}

