import { Injectable } from '@angular/core';
import { IToolsLogEvent } from '../models/tools-log/events/tools-log-event';
import { ITermsSummaryLogEvent } from '../models/tools-log/events/terms-summary-log-event';
import { IOpenInExcelFormEvent } from '@lv-shared/models/tools-log/events/open-in-excel-form-event';
import { IOpenInstrumentEvent } from '@lv-shared/models/tools-log/events/open-instrument-event';

@Injectable()
export abstract class LvSharedUserActivityService {
  abstract logToolsEvent(toolsLogEvent: IToolsLogEvent);
  abstract logTermsSummaryEvent(termsSummaryLogEvent: ITermsSummaryLogEvent);
  abstract logOpenInExcelFormEvent(openInExcelFormEvent: IOpenInExcelFormEvent);
  abstract logOpenInstrumentEvent(openInstrumentEvent: IOpenInstrumentEvent);
}
