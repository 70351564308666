
  <lv-flex-box class="lv-make-whole">
    <lv-flex-box direction="row" data-cy="DM-911">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="callMakeWholeSourceTooltipTemplate"
        filter="span:not(lv-xl-label span)">
        <span [attr.data-tooltip-id]="'callMakeWholeSourceTooltip'">
          Call Make-Whole
        </span>
        <lv-xl-label [field]="'CALL_MW'"></lv-xl-label>
      </div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [disabled]="callMakeWholeCheckBoxEnabled"
          [id]="view.callMakeWholeCheckBoxId"
          name="callMakeWholeMWTab"
          [(ngModel)]="view.call.recieveMakeWhole"
          (ngModelChange)="callMakeWholeChange()"/>
        <label class="k-checkbox-label" lvId="callMakeWholeCheckBox" [attr.for]="view.callMakeWholeCheckBoxId"></label>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-882">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="conversionMakeWholeSourceTooltipTemplate"
        filter="span:not(lv-xl-label span)">
        <span [attr.data-tooltip-id]="'conversionMakeWholeSourceTooltip'">
          Conversion Make-Whole
        </span>
        <lv-xl-label [field]="'CONV_MW'"></lv-xl-label>
      </div>
      <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        [disabled]="conversionMakeWholeCheckBoxEnabled"
        [id]="view.conversionMakeWholeCheckBoxId"
        name="conversionMakeWhole"
        [(ngModel)]="view.conversion.recieveMakeWholeConversion"
        (ngModelChange)="conversionMakeWholeChange()"/>
        <label class="k-checkbox-label" lvId="conversionMakeWholeCheckBox" [attr.for]="view.conversionMakeWholeCheckBoxId"></label>
      </div>
    </lv-flex-box>
    <lv-flex-box>
    <lv-flex-box *ngIf="view.makeWholeCallVisible">
      <lv-make-whole-call-parameters
        [model]="view.call.callMakeWhole"
        [issueAndRedemption]="view.issueAndRedemption"
        (didSetParameters)="onCallMakeWholeChange()">
      </lv-make-whole-call-parameters>
    </lv-flex-box>
    <lv-flex-box *ngIf="view.makeWholeConversionVisible">
      <lv-flex-box direction="row" data-cy="DM-883">
        <div class="lv-label lv-label-field lv-label-before-checkbox"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="startDateSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'startDateSourceTooltip'">
            Start Date
          </span>
          <lv-xl-label [field]="'CONV_MW_START_DATE'"></lv-xl-label>
        </div>
        <lv-datepicker
          checkBoxLvId = "mwConversionStartDateCheckBoxId"
          name="mwConversionStartDatePickerId"
          [(ngModel)]="view.startDate"
          [checked]="view.startDateEnabled"
          (didCheckboxValueChange)="starDateChecboxChanged($event)"
          (ngModelChange)="onModelChanged()">
        </lv-datepicker>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-884">
        <div class="lv-label lv-label-field lv-label-before-checkbox"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="endDateSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'endDateSourceTooltip'">
            End Date
          </span>
          <lv-xl-label [field]="'CONV_MW_END_DATE'"></lv-xl-label>
        </div>
        <lv-datepicker
          checkBoxLvId = "mwConversionEndDateCheckBoxId"
          name="mwConversionEndDatePickerId"
          [(ngModel)]="view.endDate"
          [checked]="view.endDateEnabled"
          (didCheckboxValueChange)="endDateChecboxChanged($event)"
          (ngModelChange)="onModelChanged()">
        </lv-datepicker>
      </lv-flex-box>
      <lv-make-whole-conversion-parameters
        [model]="view.conversion.callMakeWhole"
        [issueAndRedemption]="view.issueAndRedemption"
        (didSetParameters)="onCallMakeWholeConversionChange()">
      </lv-make-whole-conversion-parameters>
    </lv-flex-box>
    <lv-flex-box *ngIf="view.callRatchetMatrixVisible">
      <lv-flex-box direction="row" data-cy="DM-780">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="offerValueTypeRatchetSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'offerValueTypeRatchetSourceTooltip'">
            Offer Value Type
          </span>
          <lv-xl-label [field]="'MW_RTCH_VALUE_TYPE'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <kendo-dropdownlist
            name="offerValueTypeCall"
            [data]="view.offerValueTypeLookup.items"
            valueField="id"
            textField="text"
            [valuePrimitive]="true"
            [(ngModel)]="view.call.callMakeWhole.ratchetMatrixOfferValueType"
            (ngModelChange)="onCallMakeWholeChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-781">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="compensationValueTypeRatchetSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'compensationValueTypeRatchetSourceTooltip'">
            {{compensationValueType}}
          </span>
          <lv-xl-label [field]="'MW_RTCH_COMP_TYPE'"></lv-xl-label>
        </div>
        <div class="lv-input-field lv-input-field--auto">
          <kendo-dropdownlist
            name="compensationValueTypeConversion"
            [data]="view.compensationValueTypeLookup.items"
            valueField="id"
            textField="text"
            [valuePrimitive]="true"
            [popupSettings]="{ width: 188 }"
            [(ngModel)]="view.call.callMakeWhole.ratchetMatrixCompensationValueType"
            (ngModelChange)="onCallMakeWholeChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" class="lv-label-ratchet-matrix">
        <div class="lv-label lv-label-field lv-label--title"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="ratchetMatrixSourceTooltipTemplate"
          filter=".lv-label--title">RATCHET MATRIX</div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-782">
        <div class="lv-vertical-text"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="ratchetMatrixSourceTooltipTemplate"
            filter=".lv-vertical-text">
            <span [attr.data-tooltip-id]="'startDateRatchetSourceTooltip'">
              Start Date
            </span>
          </div>
        <!--Fix for model mapping from takeover to callMakeWhole on task SYSTEM--3036-Ratchet Matrix-->
        <lv-make-whole-ratchet-matrix
          class="lv-ratchet-matrix"
          [model]="view.call.callMakeWhole.ratchetMatrixMembers" 
          [customMakeWholeData]="view.customMakeWholeData"
          (didSetRatchetMatrix)="onCallMakeWholeChange()">
        </lv-make-whole-ratchet-matrix>
      </lv-flex-box>
    </lv-flex-box>
    <lv-flex-box *ngIf="view.conversionRatchetMatrixVisible">
      <lv-flex-box direction="row" data-cy="DM-780">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="offerValueTypeRatchetSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'offerValueTypeRatchetSourceTooltip'">
            Offer Value Type
          </span>
          <lv-xl-label [field]="'MW_RTCH_VALUE_TYPE'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <kendo-dropdownlist
            name="offerValueTypeConversion"
            [data]="view.offerValueTypeLookup.items"
            valueField="id"
            textField="text"
            [valuePrimitive]="true"
            [(ngModel)]="view.conversion.callMakeWhole.ratchetMatrixOfferValueType"
            (ngModelChange)="onCallMakeWholeConversionChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-781">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="compensationValueTypeRatchetSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'compensationValueTypeRatchetSourceTooltip'">
            {{compensationValueType}}
          </span>
          <lv-xl-label [field]="'MW_RTCH_COMP_TYPE'"></lv-xl-label>
        </div>
        <div class="lv-input-field lv-input-field--auto">
          <kendo-dropdownlist
            name="compensationValueTypeConversion"
            [data]="view.compensationValueTypeLookup.items"
            valueField="id"
            textField="text"
            [valuePrimitive]="true"
            [popupSettings]="{ width: 188 }"
            [(ngModel)]="view.conversion.callMakeWhole.ratchetMatrixCompensationValueType"
            (ngModelChange)="onCallMakeWholeConversionChange()">
          </kendo-dropdownlist>
         </div>
        </lv-flex-box>
        <lv-flex-box direction="row" class="lv-conversion-make-whole-label-ratchet-matrix">
          <div class="lv-label lv-label-field lv-label--title"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="ratchetMatrixSourceTooltipTemplate"
          filter=".lv-label--title">RATCHET MATRIX</div>
        </lv-flex-box>
        <lv-flex-box direction="row" data-cy="DM-782">
          <div class="lv-vertical-text"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="ratchetMatrixSourceTooltipTemplate"
            filter=".lv-vertical-text">
            <span [attr.data-tooltip-id]="'startDateRatchetSourceTooltip'">
              Start Date
            </span>
          </div>
          <!--Fix for model mapping from takeover to conversionMakeWhole on task SYSTEM--3036-Ratchet Matrix-->
          <lv-make-whole-ratchet-matrix
            class="lv-ratchet-matrix"
            [model] = "view.conversion.callMakeWhole.ratchetMatrixMembers"
            [customMakeWholeData]="view.customMakeWholeData"
            (didSetRatchetMatrix)="onCallMakeWholeConversionChange()">
          </lv-make-whole-ratchet-matrix>
      </lv-flex-box>
      </lv-flex-box>
    </lv-flex-box>
  </lv-flex-box>

  <ng-template #callMakeWholeSourceTooltipTemplate>
    <lv-tooltip dm="dM-911"></lv-tooltip>
  </ng-template>
  <ng-template #conversionMakeWholeSourceTooltipTemplate>
    <lv-tooltip dm="dM-882"></lv-tooltip>
  </ng-template>
  <ng-template #startDateSourceTooltipTemplate>
    <lv-tooltip dm="dM-912"></lv-tooltip>
  </ng-template>
  <ng-template #endDateSourceTooltipTemplate>
    <lv-tooltip dm="dM-913"></lv-tooltip>
  </ng-template>
  <ng-template #endDateSourceTooltipTemplate>
    <lv-tooltip dm="dM-913"></lv-tooltip>
  </ng-template>
  <ng-template #offerValueTypeRatchetSourceTooltipTemplate>
    <lv-tooltip dm="dM-780"></lv-tooltip>
  </ng-template>
  <ng-template #compensationValueTypeRatchetSourceTooltipTemplate>
    <lv-tooltip dm="dM-781"></lv-tooltip>
  </ng-template>
  <ng-template #ratchetMatrixSourceTooltipTemplate>
    <lv-tooltip dm="dM-782"></lv-tooltip>
  </ng-template>
