import { v4 } from 'uuid';

import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { LvLookupEnum } from '@lv-core-ui/util';
import { IModelCustomizationSectionSettings, ICreditModelSettings, ICallAdjustmentModelSettings, DividendProtectionModelDescription,
         PepsParityConventionDescription } from '@lv-analytics/models';

/**
 * Model customization section settings component.
 */
@Component({
  selector: 'lv-model-customization-section-settings',
  templateUrl: './lv-model-customization-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvModelCustomizationSectionSettingsComponent implements OnInit {

  @Input() settings: IModelCustomizationSectionSettings;
  @Input() overrideSystemDefaults: boolean;

  dividendProtectionModelEnum: LvLookupEnum;
  pepsParityConvention: LvLookupEnum;

  checkBoxId: string;

  constructor() {
    this.overrideSystemDefaults = true;
    this.settings = {
      creditModelSettings: {} as ICreditModelSettings,
      callAdjustmentModelSettings: {} as ICallAdjustmentModelSettings
    } as IModelCustomizationSectionSettings;

    this.dividendProtectionModelEnum = new LvLookupEnum(DividendProtectionModelDescription);
    this.pepsParityConvention = new LvLookupEnum(PepsParityConventionDescription);

    this.checkBoxId = v4();
  }

  @HostBinding('class.lv-model-customization-section-settings')
  get isModelCustomizationSectionSettingsComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return false;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Gets checkbox ID..
   * @param name Checkbox name.
   * @returns Checkbox ID.
   */
  getCheckBoxId(name: string): string {
    return `${name}-${this.checkBoxId}`;
  }
}
