import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter,
  HostBinding, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { LvTextBoxComponent } from '@lv-core-ui/components';
import { ICustomTermsDetailsData } from '@lv-custom-instruments/models/custom-terms-details-data';
import { v4 } from 'uuid';

/**
 * Save terms detailed modal component.
 */
@Component({
  selector: 'lv-save-terms-detailed-modal',
  templateUrl: './lv-save-terms-detailed-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvSaveTermsDetailedModalComponent implements OnInit, AfterViewInit {

  @Output() didSave: EventEmitter<ICustomTermsDetailsData>;
  @Output() didCancel: EventEmitter<void>;

  @Input() customTermsDetailsData: ICustomTermsDetailsData;

  @ViewChild('input') inputEl: LvTextBoxComponent;

  useUserRadioButtonId: string;
  useCompanyRadioButtonId: string;

  constructor() {
    this.didCancel = new EventEmitter<void>();
    this.didSave = new EventEmitter<ICustomTermsDetailsData>();
    this.useUserRadioButtonId = v4();
    this.useCompanyRadioButtonId = v4();
  }

  @HostBinding('class.lv-save-terms-detailed-modal')
  get isLvSaveTermsDetailedModalComponent() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.customTermsDetailsData.instrumentName = `Copy of ${this.customTermsDetailsData.instrumentName}`;
  }

  /**
   * Handles additional tasks after a component's view is fully initialized.
   */
  ngAfterViewInit() {
    setTimeout(() => {
      this.inputEl.inputField.nativeElement.focus();
    }, 100);
  }

  /**
   * Occurs on save.
   */
  onSave() {
    this.didSave.emit(this.customTermsDetailsData);
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
    this.didCancel.emit();
  }
}
