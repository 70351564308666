import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CallTriggerPeriodTypeDescription, CallTriggerTypeDescription, CallValueType, CallValueTypeDescription,
  CurrencyType, DayCount, DayCountDescription, FrequencyDescription, IBaseTermsSettings, ICallSectionSettings, InterpolationType,
  NoticePeriod } from '@lv-convertible-bond/models';
import { LvLookupEnum } from '@lv-core-ui/util';
import { v4 } from 'uuid';

@Component({
  selector: 'lv-call-section-settings',
  templateUrl: './lv-call-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvCallSectionSettingsComponent implements OnInit {

  @Input() settings: IBaseTermsSettings<ICallSectionSettings>;
  @Input() overrideDefaults: boolean;

  numberOfZeroDecimals = '0';
  numberZeroFormat = 'n0';

  callValueTypeRegularLookup: LvLookupEnum;
  callValueTypePepsLookup: LvLookupEnum;
  callYieldFrequencyLookup: LvLookupEnum;
  callYieldDayCountLookup: LvLookupEnum;
  callNoticeLookup: LvLookupEnum;
  interpolationTypeLookup: LvLookupEnum;
  triggerTypeLookup: LvLookupEnum;
  currencyOfTriggerLookup: LvLookupEnum;
  triggerPeriodTypeLookup: LvLookupEnum;

  keepAccruedConversionId: string;
  forfeitCouponId: string;
  includeCacheInCallTrigger: string;

  constructor() {
    this.callValueTypeRegularLookup = new LvLookupEnum(CallValueTypeDescription);
    this.callValueTypePepsLookup = new LvLookupEnum(CallValueTypeDescription);
    this.callYieldFrequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.callYieldDayCountLookup = new LvLookupEnum(DayCountDescription);
    this.callNoticeLookup = new LvLookupEnum(NoticePeriod);
    this.interpolationTypeLookup = new LvLookupEnum(InterpolationType);
    this.triggerTypeLookup = new LvLookupEnum(CallTriggerTypeDescription);
    this.currencyOfTriggerLookup = new LvLookupEnum(CurrencyType);
    this.triggerPeriodTypeLookup = new LvLookupEnum(CallTriggerPeriodTypeDescription);

    this.keepAccruedConversionId = v4();
    this.forfeitCouponId = v4();
    this.includeCacheInCallTrigger = v4();
    this.overrideDefaults = true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  ngOnInit() {
    this.handleConvertibleSubtype();
  }

  getCallTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

  private handleConvertibleSubtype() {
    this.callValueTypeRegularLookup.setFilterFn(item => {
      if (item.id === CallValueType.PepsMaximumRatio || item.id === CallValueType.PepsMinimumRatio
          || item.id === CallValueType.PepsThresholdDependentRatio || item.id === CallValueType.PepsVariableRatio) {

        return false;
      }

      return true;
    });

    this.callValueTypePepsLookup.setFilterFn(item => {
      if (item.id === CallValueType.NetYield || item.id === CallValueType.GrossYield || item.id === CallValueType.AccretedValue) {
        return false;
      }
      return true;
    });
  }

}
