<div class="lv-instrument-monitor-search">
  <div class="lv-instrument-monitor-search-autocomplete-container"
    [class.instrument-selected]="isInstrumentSelected">
    <i class="k-icon k-i-search"></i>
    <span #autoCompleteWidthElement class="lv-instrument-monitor-hidden">{{ selectedInstrument }}</span>
    <kendo-autocomplete
      class="lv-instrument-monitor-search-autocomplete"
      [style.width.px]="autoCompleteWidth"
      [data]="data"
      valueField="text"
      [readonly] = "readonly"
      placeholder="Search by Name or ISIN or Underlying or Lev ID"
      [filterable]="true"
      [popupSettings]="popupSettings"
      [listHeight]="300"      
      [(ngModel)]="selectedInstrument"
      (ngModelChange)="onValueChange($event)"
      (click)= " onInstrumentSearchFieldClick()">

      <ng-template kendoDropDownListHeaderTemplate>
        <div class="lv-instrument-monitor-search-autocomplete-header">
          <div class="lv-instrument-monitor-search-autocomplete-table">
            <div class="name">Name</div>
            <div class="isin">ISIN</div>
            <div class="leversys-id">Leversys ID</div>
          </div>
        </div>
      </ng-template>

      <ng-template kendoDropDownListItemTemplate let-dataItem>
        <div class="lv-instrument-monitor-search-autocomplete-table">
          <div class="name">{{dataItem.text}}</div>
          <div class="isin">{{dataItem.isin}}</div>
          <div class="leversys-id">{{dataItem.leversysId}}</div>
        </div>
      </ng-template>

    </kendo-autocomplete>
  </div>

</div>
