<lv-flex-box direction="row" class="lv-coupon-co-pay">
  <lv-flex-box class="lv-co-pay-upside-col">
    <lv-flex-box direction="row" data-cy="DM-941">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="accrualCoPayStartDateSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Accrual Start Date
        </span>
        <lv-xl-label [field]="'CPN_COPAY_ACC_START_DATE'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <kendo-datepicker
          name="terms-general-copay-accrual-start-date"
          [(ngModel)]="model.coPayAccrualStartDate"
          (ngModelChange)="coPayModelChange()">
        </kendo-datepicker>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-942">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="couponCoPayFrequencySourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Frequency
        </span>
        <lv-xl-label [field]="'CPN_COPAY_FREQ'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <kendo-dropdownlist
          name="terms-general-copay-frequency"
          [data]="coPayFrequencyLookup.items"
          valueField="id"
          valuePrimitive="true"
          textField="text"
          [(ngModel)]="model.coPayFrequency"
          (ngModelChange)="coPayModelChange()">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-943">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="dayCountCoPaySourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Day Count
        </span>
        <lv-xl-label [field]="'CPN_COPAY_DAY_COUNT'"></lv-xl-label>
      </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          name="terms-general-copay-day-count"
          [data]="coPayDayCountLookup.items"
          valueField="id"
          valuePrimitive="true"
          textField="text"
          [(ngModel)]="model.coPayDayCount"
          (ngModelChange)="coPayModelChange()">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-944">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="coPayCouponTypeSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Co Pay Coupon Type
        </span>
        <lv-xl-label [field]="'CPN_COPAY_TYPE'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <kendo-dropdownlist
          name="terms-general-copay-coupon-type"
          [data]="coPayCouponTypeLookup.items"
          valueField="id"
          valuePrimitive="true"
          textField="text"
          [(ngModel)]="model.coPayCouponType"
          (ngModelChange)="coPayModelChange()"
          [popupSettings]="{ width: 215 }">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-945">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="coPayTriggerTypeSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Co Pay Trigger Type
        </span>
        <lv-xl-label [field]="'CPN_COPAY_TRGGR_TYPE'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <kendo-dropdownlist
          class="lv-co-pay-trigger-type"
          name="terms-general-copay-trigger-type"
          [data]="coPayTriggerTypeLookup.items"
          valueField="id"
          valuePrimitive="true"
          textField="text"
          [(ngModel)]="model.coPayTriggerType"
          (ngModelChange)="coPayModelChange()">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-946">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="lastCBFixingSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Last CB Fixing
        </span>
        <lv-xl-label [field]="'CPN_COPAY_LST_FXNG'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="terms-general-copay-last-cb-fixing"
          [format]="formatNumber"
          [decimals]="decimalsNumber"
          [(ngModel)]="model.coPayLastCBFixing"
          (didBlur)="coPayModelChange()"
          (didKeyDownEnter)="coPayModelChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">pts</span>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-947">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="upsideCoPaySourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Upside Co Pay
        </span>
        <lv-xl-label [field]="'CPN_COPAY_UPSIDE'"></lv-xl-label>
      </div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="upsideCoPayCheckboxId"
          name="terms-general-copay-coupon-upside"
          [(ngModel)]="model.coPayUpside"
          (ngModelChange)="coPayModelChange()">
        <label class="k-checkbox-label"
          [attr.for]="upsideCoPayCheckboxId">
        </label>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-950">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="downsideCoPaySourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Downside Co Pay
        </span>
        <lv-xl-label [field]="'CPN_COPAY_DWNSIDE'"></lv-xl-label>
      </div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="downsideCoPayCheckboxId"
          name="terms-general-copay-coupon-downside"
          [(ngModel)]="model.coPayDownside"
          (ngModelChange)="coPayModelChange()">
        <label class="k-checkbox-label"
          [attr.for]="downsideCoPayCheckboxId">
        </label>
      </div>
    </lv-flex-box>
    <ng-container *ngIf="model.coPayUpside">
      <lv-flex-box direction="row" class="lv-upside-panel">
        <div class="lv-label lv-label-field lv-label--title">UPSIDE CO PAY</div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-948">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="upCouponSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Coupon
          </span>
          <lv-xl-label [field]="'CPN_COPAY_UPSIDE_RATE'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
        <lv-numeric-text-box
          name="terms-general-copay-coupon-coupon"
          [format]="formatNumber"
          [decimals]="decimalsNumber"
          [(ngModel)]="model.coPayUpsideCoupon"
          (didBlur)="coPayModelChange()"
          (didKeyDownEnter)="coPayModelChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-949">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="upTriggerSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Trigger
          </span>
          <lv-xl-label [field]="'CPN_COPAY_UPSIDE_TRGGR'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            name="terms-general-copay-coupon-trigger"
            [format]="formatNumber"
            [decimals]="decimalsNumber"
            [(ngModel)]="model.coPayUpsideTrigger"
            (didBlur)="coPayModelChange()"
            (didKeyDownEnter)="coPayModelChange()">
          </lv-numeric-text-box>
          <span class="lv-sufix-label">%</span>
        </div>
      </lv-flex-box>
  </ng-container>
  </lv-flex-box>

  <lv-flex-box *ngIf="model.coPayDownside">
    <lv-flex-box direction="row">
      <div class="lv-label lv-label-field lv-label--title">DOWNSIDE CO PAY</div>
    </lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-951">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="downCouponSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Coupon
        </span>
        <lv-xl-label [field]="'CPN_COPAY_DWNSIDE_RATE'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="terms-general-copay-coupon-downside-coupon"
          [format]="formatNumber"
          [decimals]="decimalsNumber"
          [(ngModel)]="model.coPayDownsideCoupon"
          (didBlur)="coPayModelChange()"
          (didKeyDownEnter)="coPayModelChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-952">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="downTriggerSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Trigger
        </span>
        <lv-xl-label [field]="'CPN_COPAY_DWNSIDE_TRGGR'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="terms-general-copay-coupon-downside-trigger"
          [format]="formatNumber"
          [decimals]="decimalsNumber"
          [disabled]="model.coPayDownsideUseSchedule"
          [(ngModel)]="model.coPayDownsideTrigger"
          (didBlur)="coPayModelChange()"
          (didKeyDownEnter)="coPayModelChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-953">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="incrementperCouponSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Increment per Coupon
        </span>
        <lv-xl-label [field]="'CPN_COPAY_DWNSIDE_INCR'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="terms-general-copay-coupon-downside-increment"
          [format]="formatNumber"
          [decimals]="decimalsNumber"
          [(ngModel)]="model.coPayDownsideIncrementPerCoupon"
          (didBlur)="coPayModelChange()"
          (didKeyDownEnter)="coPayModelChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-954">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="triggerCapSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Trigger Cap
        </span>
        <lv-xl-label [field]="'CPN_COPAY_DWNSIDE_CAP'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="terms-general-copay-coupon-downside-trigger-cap"
          [format]="formatNumber"
          [decimals]="decimalsNumber"
          [(ngModel)]="model.coPayDownsideTriggerCap"
          (didBlur)="coPayModelChange()"
          (didKeyDownEnter)="coPayModelChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row" data-cy="DM-955">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="UseAScheduleSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Use a Schedule
        </span>
        <lv-xl-label [field]="'CPN_COPAY_TRGR_SCHED'"></lv-xl-label>
      </div>
      <div class="lv-field lv-field--checkbox">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="downsideUseScheduleCoPayCheckboxId"
          name="terms-general-copay-coupon-downside-use-schedule"
          [(ngModel)]="model.coPayDownsideUseSchedule"
          (ngModelChange)="coPayModelChange()">
        <label class="k-checkbox-label"
          [attr.for]="downsideUseScheduleCoPayCheckboxId">
        </label>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row" *ngIf="model.coPayDownsideUseSchedule">
      <lv-co-pay-schedule
        [model]="model.coPayDownsideTriggerSchedule"
        (didCoPayScheduleChange)="coPayModelChange()">
      </lv-co-pay-schedule>
    </lv-flex-box>
    <lv-flex-box class="lv-downside-button" *ngIf="model.coPayDownsideUseSchedule">
      <button
        kendoButton
        [primary]="true"
        lvId="copay-generate-downside-triggers">
          Generate Downside Triggers
      </button>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<ng-template #accrualCoPayStartDateSourceTooltip>
  <lv-tooltip dm="dM-941"></lv-tooltip>
</ng-template>
<ng-template #couponCoPayFrequencySourceTooltip>
  <lv-tooltip dm="dM-942"></lv-tooltip>
</ng-template>
<ng-template #dayCountCoPaySourceTooltip>
  <lv-tooltip dm="dM-943"></lv-tooltip>
</ng-template>
<ng-template #coPayCouponTypeSourceTooltip>
  <lv-tooltip dm="dM-944"></lv-tooltip>
</ng-template>
<ng-template #coPayTriggerTypeSourceTooltip>
  <lv-tooltip dm="dM-945"></lv-tooltip>
</ng-template>
<ng-template #lastCBFixingSourceTooltip>
  <lv-tooltip dm="dM-946"></lv-tooltip>
</ng-template>
<ng-template #upsideCoPaySourceTooltip>
  <lv-tooltip dm="dM-947"></lv-tooltip>
</ng-template>
<ng-template #downsideCoPaySourceTooltip>
  <lv-tooltip dm="dM-950"></lv-tooltip>
</ng-template>
<ng-template #upCouponSourceTooltip>
  <lv-tooltip dm="dM-948"></lv-tooltip>
</ng-template>
<ng-template #upTriggerSourceTooltip>
  <lv-tooltip dm="dM-949"></lv-tooltip>
</ng-template>
<ng-template #downCouponSourceTooltip>
  <lv-tooltip dm="dM-951"></lv-tooltip>
</ng-template>
<ng-template #downTriggerSourceTooltip>
  <lv-tooltip dm="dM-952"></lv-tooltip>
</ng-template>
<ng-template #incrementperCouponSourceTooltip>
  <lv-tooltip dm="dM-953"></lv-tooltip>
</ng-template>
<ng-template #triggerCapSourceTooltip>
  <lv-tooltip dm="dM-954"></lv-tooltip>
</ng-template>
<ng-template #UseAScheduleSourceTooltip>
  <lv-tooltip dm="dM-955"></lv-tooltip>
</ng-template>
