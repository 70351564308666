<lv-flex-box direction="row" class="lv-copy-and-paste">
  <lv-flex-box>
    <div class="lv-label-field lv-label lv-label--title">DIVIDENDS</div>
    <lv-flex-box direction="row">
      <div class="lv-input-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="startfromRowTooltipTemplate"
      filter="div">
        <lv-input-field
          [label]="'Start From Row'"
          [labelWidth]="190"
          [checked]="isDividendStartFromRowChecked"
          [checkbox]="true"
          [disabled]="!overrideSystemDefaults"
          [inputValue]="settings.dividends.startFromRow"
          (didCheckboxChange)="handleCheckboxChange($event, 'dividends')">

          <lv-numeric-text-box
            name="startFromRow"
            [decimals]="'0'"
            [format]="'n0'"
            [disabled]="!overrideSystemDefaults"
            [(ngModel)]="settings.dividends.startFromRow">
          </lv-numeric-text-box>

        </lv-input-field>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="forDateValueUseColumnTooltipTemplate"
        filter="div">
        For Date Value Use Column
      </div>

      <div class="lv-input-field">
        <lv-numeric-text-box
          name="forDateValueUseColumn"
          [decimals]="'0'"
          [format]="'n0'"
          [disabled]="!overrideSystemDefaults"
          [(ngModel)]="settings.dividends.forDateValueUseColumn">
        </lv-numeric-text-box>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="forDividendValueUseColumnTooltipTemplate"
        filter="div">
        For Dividend Value Use Column
      </div>

      <div class="lv-input-field">
        <lv-numeric-text-box
          name="forDividendValueUseColumn"
          [decimals]="'0'"
          [format]="'n0'"
          [disabled]="!overrideSystemDefaults"
          [(ngModel)]="settings.dividends.forDividendValueUseColumn">
        </lv-numeric-text-box>
      </div>
    </lv-flex-box>
    <lv-flex-box class="lv-input-field" direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="displayValuesBeforePasteTooltipTemplate"
        filter="div">
        Display Values Before Paste
      </div>
      <div class="lv-field lv-field--checkbox">
        <input type="checkbox"
          name="displayValuesBeforePaste"
          class="k-checkbox"
          [disabled]="!overrideSystemDefaults"
          [attr.id]="getCheckBoxId('display-values-before-paste-dividend')"
          [(ngModel)]="settings.dividends.displayValuesBeforePaste"/>
        <label class="k-checkbox-label"
          [attr.for]="getCheckBoxId('display-values-before-paste-dividend')"></label>
      </div>
    </lv-flex-box>
  </lv-flex-box>

  <lv-flex-box class="lv-copy-and-paste-rates">
    <div class="lv-label-field lv-label lv-label--title">INTEREST RATES</div>
    <lv-flex-box direction="row">
      <div class="lv-input-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="startFromRowInterestRatesTooltipTemplate"
        filter="div">
        <lv-input-field
        [label]="'Start From Row'"
        [labelWidth]="190"
        [checked]="isInterestRatesStartFromRowChecked"
        [checkbox]="true"
        [disabled]="!overrideSystemDefaults"
        [inputValue]="settings.interestRates.startFromRow"
        (didCheckboxChange)="handleCheckboxChange($event, 'interestRates')">
        <lv-numeric-text-box
          name="startFromRow"
          [decimals]="'0'"
          [format]="'n0'"
          [disabled]="!overrideSystemDefaults"
          [(ngModel)]="settings.interestRates.startFromRow">
        </lv-numeric-text-box>
      </lv-input-field>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="forTenorValueUseColumnTooltipTemplate"
        filter="div">
        For Tenor Value Use Column
      </div>

      <div class="lv-input-field">
        <lv-numeric-text-box
          name="forTenorValueUseColumn"
          [decimals]="'0'"
          [format]="'n0'"
          [disabled]="!overrideSystemDefaults"        
          [(ngModel)]="settings.interestRates.forTenorValueUseColumn">
        </lv-numeric-text-box>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="forRateValueUseColumnTooltipTemplate"
        filter="div">
        For Rate Value Use Column
      </div>

      <div class="lv-input-field">
        <lv-numeric-text-box
          name="forRateValueUseColumn"
          [decimals]="'0'"
          [format]="'n0'"
          [disabled]="!overrideSystemDefaults"
          [(ngModel)]="settings.interestRates.forRateValueUseColumn">
        </lv-numeric-text-box>
      </div>
    </lv-flex-box>
    <lv-flex-box class="lv-input-field" direction="row">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="displayValuesBeforePasteInterestRatesTooltipTemplate"
        filter="div">
        Display Values Before Paste
      </div>
      <div class="lv-field lv-field--checkbox">
        <input type="checkbox"
          name="displayValuesBeforePaste"
          class="k-checkbox"
          [attr.id]="getCheckBoxId('display-values-before-paste-dividend')"
          [disabled]="!overrideSystemDefaults"
          [(ngModel)]="settings.interestRates.displayValuesBeforePaste"/>
        <label class="k-checkbox-label"
          [attr.for]="getCheckBoxId('display-values-before-paste-dividend')"></label>
      </div>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<ng-template #startfromRowTooltipTemplate>
  <lv-tooltip dm="dM-1110"></lv-tooltip>
</ng-template>
<ng-template #forDateValueUseColumnTooltipTemplate>
  <lv-tooltip dm="dM-1111"></lv-tooltip>
</ng-template>
<ng-template #forDividendValueUseColumnTooltipTemplate>
  <lv-tooltip dm="dM-1112"></lv-tooltip>
</ng-template>
<ng-template #displayValuesBeforePasteTooltipTemplate>
  <lv-tooltip dm="dM-1113"></lv-tooltip>
</ng-template>
<ng-template #startFromRowInterestRatesTooltipTemplate>
  <lv-tooltip dm="dM-1116"></lv-tooltip>
</ng-template>
<ng-template #forTenorValueUseColumnTooltipTemplate>
  <lv-tooltip dm="dM-1117"></lv-tooltip>
</ng-template>
<ng-template #forRateValueUseColumnTooltipTemplate>
  <lv-tooltip dm="dM-1118"></lv-tooltip>
</ng-template>
<ng-template #displayValuesBeforePasteInterestRatesTooltipTemplate>
  <lv-tooltip dm="dM-1119"></lv-tooltip>
</ng-template>