import { Component, OnInit, ViewChild, ChangeDetectorRef, ViewEncapsulation,
  ChangeDetectionStrategy, Output, EventEmitter, Optional, inject, DestroyRef} from '@angular/core';
import * as _ from 'lodash';

// tslint:disable-next-line:max-line-length
import { LvEnvironmentSettingsComponent, IEnvironmentSettingsItem } from '../../../lv-environment-settings/lv-environment-settings.component';
import { MarketDataService } from '../../../../services/market-data/market-data.service';
import { IMarketDataComponent } from '../../market-data-component';
import { LvMarketDataPresenter } from '../lv-market-data.presenter';
import { LvErrorService } from '@lv-core-ui/services';
import { AnalyticsSettingsEvents } from '@lv-analytics/models/enum/analytics-settings-events';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { PricingEnvironmentSections } from '@lv-analytics/models/enum/pricing-environment-sections';
import { LvExcelService } from '@lv-excel/services';
import { IOtherMarketDataRequest } from '@lv-analytics/models/market-data/other/other-market-data-request';
import { IOtherMarketData } from '@lv-analytics/models/market-data/other/other-market-data';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * Withholding tax component.
 */
@Component({
  selector: 'lv-other-market-data',
  templateUrl: './lv-other-market-data.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LVOtherMarketDataComponent implements OnInit, IMarketDataComponent<IOtherMarketDataRequest> {

  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;  

  @Output() didSessionUpdatedEvent: EventEmitter<void>;

  numberOfDecimalsPercentage = '4';
  numberFormatPercentage = '#.##';
  otherMarketDataSection = PricingEnvironmentSections.Other;
  otherMarketData: IOtherMarketData;
  originalValue: IOtherMarketData;

  private _destroyRef = inject(DestroyRef)

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _marketDataService: MarketDataService,
    private _presenter: LvMarketDataPresenter,
    private _analyticsPresenter: LvAnalyticsPresenter,
    @Optional() private _excelSvc: LvExcelService
  ) {  
    this.didSessionUpdatedEvent = new EventEmitter<void>();
    this.otherMarketData = {} as IOtherMarketData;
   }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._analyticsPresenter.onModelLoading.pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(isLoading => this.setLoadingState(isLoading));

    this._analyticsPresenter.onModelUpdated.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(evt => {
      if (evt && evt.eventId === AnalyticsSettingsEvents.MarketDataUpdated) { 
        this.otherMarketData = evt.data.marketData.other;
        this.originalValue = _.cloneDeep(this.otherMarketData); 
        this._changeDetectorRef.detectChanges();
      }
    });
  }

  /**
   * Occurs on change environment.
   * @param environment IEnvironmentSettingsItem object.
   */
  onChangedEnvironment(environment: IEnvironmentSettingsItem) {
    this.loadOtherData(environment);
  }

  /**
   * Occurs on other date change.
   */
  onOtherChange() {
    this.overrideOtherData();
  }

  /**
   * Gets selected environment.
   * @returns Environment ID.
   */
  getSelectedEnvironmentId(): string {
    const env = this.envSettings.getSelectedEnvironment();
    return env.id;
  }

  /**
   * Gets settings.
   * @returns Other market data object.
   */
  getSettings(): IOtherMarketDataRequest {
    return this.getOtherDataSaveRequest();
  }

  /**
   * Applies current changes on grids.
   * This must be here because this is interface method.
   */
  applyCurrentChanges() {

  }

  /**
   * Gets section.
   * @returns PricingEnvironmentSections object.
   */
  getSection(): PricingEnvironmentSections {
    return this.otherMarketDataSection;
  }

  /**
   * Overrides other data in valuation session.
   */
  private async overrideOtherData() {
    try {
      await this._marketDataService.overrideOtherMarketData({
        sessionId: this._analyticsPresenter.asHelper.sessionId,
        lwsIdentifier: this._analyticsPresenter.asHelper.lwsIdentifier,
        other: this.otherMarketData
      });

      this.sendOtherDataUpdateEvent();

      if (!_.isEqual(this.otherMarketData, this.originalValue)) {
        this.didSessionUpdatedEvent.next();
        this.originalValue = _.cloneDeep(this.otherMarketData);
      }
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
    }
  }

  /**
   * Gets other data save request.
   * @returns IOtherMarketDataRequest object.
   */
  private getOtherDataSaveRequest(): IOtherMarketDataRequest {
    const env = this.envSettings.getSelectedEnvironment();

    return {
      environmentId: env.id,
      lwsIdentifier: this._analyticsPresenter.asHelper.lwsIdentifier,
      other: this.otherMarketData
    } as IOtherMarketDataRequest;
  }

  /**
   * Loads with other data.
   * @param environment IEnvironmentSettingsItem object.
   */
  private async loadOtherData(environment: IEnvironmentSettingsItem) {
    try {
      this.setLoadingState(true);

      this.otherMarketData = await this._marketDataService.loadOtherMarketData(
        this._analyticsPresenter.asHelper.sessionId,
        this._analyticsPresenter.asHelper.lwsIdentifier,
        environment.id
      );

      this.sendOtherDataUpdateEvent();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Send other data update event.
   */
  private sendOtherDataUpdateEvent() {
    this._analyticsPresenter.updateModel({
      eventId: AnalyticsSettingsEvents.OtherMarketDataUpdated,
      data: this.otherMarketData
    });
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  private setLoadingState(isLoading: boolean) {
    this.envSettings.setLoadingState(isLoading);
    this._presenter.setLoadingState(isLoading);
    this._changeDetectorRef.detectChanges();
  }
}
