import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'lv-grid-loader',
  templateUrl: './lv-grid-loader.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvGridLoaderComponent implements OnInit {

  @Input() isLoading: boolean;

  constructor() {
    this.isLoading = true;
  }

  @HostBinding('class.lv-grid-loader')
  get isLvGridLoaderComponent(): boolean {
    return true;
  }

  ngOnInit() {
  }

}
