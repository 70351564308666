import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, HostBinding, ElementRef } from '@angular/core';
import { IWithholdingTaxSettings, IWithholdingTaxSectionSettings } from '@lv-analytics/models';

/**
 * Withholding tax settings component.
 */
@Component({
  selector: 'lv-withholding-tax-settings',
  templateUrl: './lv-withholding-tax-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvWithholdingTaxSettingsComponent implements OnInit {

  @Input() settings: IWithholdingTaxSettings;

  constructor() {
    this.settings = {
      systemDefaults: {} as IWithholdingTaxSectionSettings,
      environments: []
    } as IWithholdingTaxSettings;
  }

  @HostBinding('class.lv-withholding-tax-settings')
  get isLvWithholdingTaxSettingsComponent() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
   getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }
}
