<div class="lv-convertible-bond-terms-make-whole-call-parameters">
  <lv-flex-box direction="row" data-cy="DM-912">
    <div class="lv-label lv-label-field lv-label-before-checkbox"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="startDateSourceTooltipTemplate"
        filter="span:not(lv-xl-label span)">
        <span [attr.data-tooltip-id]="'startDateSourceTooltip'">
           Start Date
        </span>
        <lv-xl-label [field]="'CALL_MW_START_DATE'"></lv-xl-label>
      </div>
    <lv-datepicker
      [(ngModel)]="startDate"
      [checkBoxLvId] = "dpStartDateCheckBoxId"
      [datePickerLvId] = "dpStartDatePickerId"
      [checked]="startDateEnabled"
      (didCheckboxValueChange)="startDateCheckboxChange($event)"
      (ngModelChange)="doModelChange()">
    </lv-datepicker>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-913">
    <div class="lv-label lv-label-field lv-label-before-checkbox"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="endDateSourceTooltipTemplate"
        filter="span:not(lv-xl-label span)">
        <span [attr.data-tooltip-id]="'endDateSourceTooltip'">
          End Date
        </span>
        <lv-xl-label [field]="'CALL_MW_END_DATE'"></lv-xl-label>
      </div>
    <lv-datepicker
      [(ngModel)]="endDate"
      [checkBoxLvId] = "dpEndDateCheckBoxId"
      [datePickerLvId] = "dpEndDatePickerId"
      [checked]="endDateEnabled"
      (didCheckboxValueChange)="endDateCheckboxChange($event)"
      (ngModelChange)="doModelChange()">
    </lv-datepicker>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-774">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="makeWholeTypeSourceTooltipTemplate"
      filter="span:not(lv-xl-label span)">
      <span [attr.data-tooltip-id]="'makeWholeTypeSourceTooltip'">
        Make-Whole Type
      </span>
      <lv-xl-label [field]="'MW_TYPE'"></lv-xl-label>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        lvId="makeWholeType"
        name="makeWholeType"
        [data]="makeWholeTypeLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 160 }"
        [(ngModel)]="model.type"
        (ngModelChange)="makeWholeTypeChanged()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box *ngIf="couponsIncludedUntilVisible" direction="row" data-cy="DM-777">
    <div class="lv-label lv-label-field lv-label-before-checkbox"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="couponsIncludedUntilSourceTooltipTemplate"
      filter="span:not(lv-xl-label span)">
      <span [attr.data-tooltip-id]="'couponsIncludedUntilSourceTooltip'">
        {{couponIncludedUntil}}
      </span>
      <lv-xl-label [field]="'MW_CPN_END_DATE'"></lv-xl-label>
    </div>
    <lv-datepicker
      [(ngModel)]="couponsIncludedUntil"
      [checked]="couponsIncludedUntilEnabled"
      (didCheckboxValueChange)="couponsIncludedUntilCheckboxChange($event)"
      (ngModelChange)="doModelChange()">
    </lv-datepicker>
  </lv-flex-box>
  <lv-flex-box *ngIf="discountFieldsVisible" direction="row" data-cy="DM-778">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="discountRateTypeSourceTooltipTemplate"
      filter="span:not(lv-xl-label span)">
      <span [attr.data-tooltip-id]="'discountRateTypeSourceTooltip'">
        Discount Rate Type
      </span>
      <lv-xl-label [field]="'MW_CPN_DSC_TYPE'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="discountRateType"
        [data]="discountRateTypeLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="model.discountRateType"
        (ngModelChange)="doModelChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box *ngIf="discountFieldsVisible" direction="row" data-cy="DM-779">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="discountSpreadRateSourceTooltipTemplate"
      filter="span:not(lv-xl-label span)">
      <span [attr.data-tooltip-id]="'discountSpreadRateSourceTooltip'">
        Discount Spread / Rate
      </span>
      <lv-xl-label [field]="'MW_CPN_DSC_VALUE'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="discountSpreadMWC"
        [format]='formatZero'
        [decimals]='decimalsZero'
        [(ngModel)]="model.discountSpread"
        (didBlur)="doModelChange()"
        (didKeyDownEnter)="doModelChange()">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">bps</span>
    </div>
  </lv-flex-box>
  <lv-flex-box *ngIf="initialPremiumVisible"
    direction="row" data-cy="DM-775">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="initialPremiumPercentageSourceTooltipTemplate"
      filter="span:not(lv-xl-label span)">
      <span [attr.data-tooltip-id]="'initialPremiumPercentageSourceTooltip'">
        Initial Premium
      </span>
      <lv-xl-label [field]="'MW_INTL_PRM'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="initialPremium"
        [format]='formatNumber'
        [decimals]='decimalsNumber'
        [(ngModel)]="model.initialPremium"
        (didBlur)="doModelChange()"
        (didKeyDownEnter)="doModelChange()">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>
  </lv-flex-box>
</div>

<ng-template #startDateSourceTooltipTemplate>
  <lv-tooltip dm="dM-912"></lv-tooltip>
</ng-template>
<ng-template #endDateSourceTooltipTemplate>
  <lv-tooltip dm="dM-913"></lv-tooltip>
</ng-template>
<ng-template #makeWholeTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-774"></lv-tooltip>
</ng-template>
<ng-template #couponsIncludedUntilSourceTooltipTemplate>
  <lv-tooltip dm="dM-777"></lv-tooltip>
</ng-template>
<ng-template #discountRateTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-778"></lv-tooltip>
</ng-template>
<ng-template #discountSpreadRateSourceTooltipTemplate>
  <lv-tooltip dm="dM-779"></lv-tooltip>
</ng-template>
<ng-template #initialPremiumPercentageSourceTooltipTemplate>
  <lv-tooltip dm="dM-775"></lv-tooltip>
</ng-template>
