export enum CallValueTypeDescription {
  PerOfPar = '% of Par',
  GrossYield = 'Gross Yield',
  NetYield = 'Net Yield',
  AccretedValue = 'Accreted Value',
  PepsMinimumRatio = 'PEPS - Minimum Ratio',
  PepsMaximumRatio = 'PEPS - Maximum Ratio',
  PepsVariableRatio = 'PEPS - Variable Ratio',
  PepsThresholdDependentRatio = 'PEPS - Threshold Dependent Ratio'
}
