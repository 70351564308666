import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LabelModule } from '@progress/kendo-angular-label';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { CustomInstrumentsService } from './services/custom-instruments.service';
import { ConvertibleBondTermsModule } from '../convertible-bond-terms/convertible-bond-terms.module';
// tslint:disable-next-line:max-line-length
import { LvCustomInstrumentComponent } from './components/lv-custom-instrument/lv-custom-instrument.component';
import { CustomInstrumentHubService } from './hubs/custom-instrument-hub.service';
import { LeversysCoreUIModule } from '@lv-core-ui/leversys-core-ui.module';
import { LvTermsSettingsComponent } from './components/lv-terms-settings/lv-terms-settings.component';
import { TabStripModule } from '@progress/kendo-angular-layout';
// tslint:disable-next-line: max-line-length
import { LvSaveTermsDetailedModalComponent } from './components/lv-custom-instrument/lv-save-terms-detailed-modal/lv-save-terms-detailed-modal.component';
import { LvAccessRightsModalComponent } from './components/lv-custom-instrument/lv-access-rights-modal/lv-access-rights-modal.component';

/**
 * Custom instrument module deffinition that contains registration of providers.
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    // Kendo modules
    TabStripModule,
    LabelModule,
    PopupModule,
    InputsModule,
    DropDownsModule,
    ButtonsModule,
    DatePickerModule,
    DialogModule,
    SortableModule,
    TooltipModule,

    LeversysCoreUIModule,
    ConvertibleBondTermsModule
  ],

  declarations: [
    LvCustomInstrumentComponent,
    LvTermsSettingsComponent,
    LvCustomInstrumentComponent,
    LvSaveTermsDetailedModalComponent,
    LvAccessRightsModalComponent
  ],
  exports: [
    LvCustomInstrumentComponent,
    LvTermsSettingsComponent
  ],
  providers: [
    CustomInstrumentsService,
    CustomInstrumentHubService
  ]
}
)
export class CustomInstrumentsModule {

}
