import { Type } from '@angular/core';
import { AccessScopeType } from '@lv-core-ui/models/enum/access-scope-type';
import { ILvSubscriberState } from '@lv-core-ui/services';

export interface IValuationWidgetTypes {
  [widgetId: string]: Type<any>;
}

export interface IValuationGridsterItemConfig {
  widgetId: string;
  maxItemRows: number;
  maxItemCols: number;
  minItemRows?: number;
  minItemCols?: number;
  rows: number;
  cols: number;
  y: number;
  x: number;
}

export enum IInstrumentWidgetVisibility {
  AS_TAB = 'as_tab',
  AS_WIDGET = 'as_widget',
  NONE = 'none'
}

export interface IInstrumentWidgetState {
  id: string;
  title: string;
  visibility: IInstrumentWidgetVisibility;
  visibilityOptions: IInstrumentWidgetVisibility[];
  gridsterItemConfig?: IValuationGridsterItemConfig;
  state: any;
}

export interface IInstrumentComponentState extends ILvSubscriberState {
  instrumentId: number;
  instrumentName: string;
  instrumentLwsIdentifier: string;
  instrumentIsin: string;
  sessionId: string;
  widgets: IInstrumentWidgetState[];
  region: string;
  sensitivityAnalysisState: {
    scenarioTemplateId?: number;
    isSystem: boolean;
  };
  isPrivateInstrument: boolean;
  createPrivateInstrument: {
    isPrivateIntrument: boolean;
    isExactCopy: boolean;
    isUserAccessScope: boolean;
  };
  useDefaultView: boolean;
  accessScope: AccessScopeType;
}
