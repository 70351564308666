<lv-flex-box class="lv-other">
  <lv-flex-box
    class="lv-fieldset-panel"
    direction="row">
    <lv-fieldset-panel class="issue-info-fieldset" legend="ISSUE INFO">
      <lv-flex-box
        direction="row"
        class="issue-info-fieldset-output"
        data-cy="DM-1375">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherPricingDateSourceTooltip"
        filter=".lv-label"
        class="lv-label"> Pricing Date </div>
        <div class="lv-other-output issue-info-fieldset-output-value">{{view.model.pricingDate | lvLocalDate}}</div>
      </lv-flex-box>
      <lv-flex-box
        direction="row"
        class="issue-info-fieldset-output"
        data-cy="DM-1376">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherAnnouncementDateSourceTooltip"
        filter=".lv-label"
        class="lv-label"> Announcement Date </div>
        <div class="lv-other-output  issue-info-fieldset-output-value">{{view.model.announcementDate | lvLocalDate}}</div>
      </lv-flex-box>
      <lv-flex-box
        direction="row"
        class="issue-info-fieldset-output"
        data-cy="DM-1377">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherSlippageSourceTooltip"
        filter=".lv-label" 
        class="lv-label"> Slippage (%) </div>
        <div class="lv-other-output  issue-info-fieldset-output-value">{{view.model.slippage | number:constants.numberFormat.twoDigits}}</div>
      </lv-flex-box>
      <lv-flex-box
        direction="row"
        class="issue-info-fieldset-output"
        data-cy="DM-1378">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherPotentialDilutionSourceTooltip"
        filter=".lv-label"
        class="lv-label"> Potential Dilution (%) </div>
        <div class="lv-other-output  issue-info-fieldset-output-value">{{view.model.potentialDilution | number:constants.numberFormat.twoDigits}}</div>
      </lv-flex-box>
      <lv-flex-box
        direction="row"
        class="issue-info-fieldset-output">
        <div class="lv-label"></div>
        <div class="lv-label-header">Instrument CCY</div>
        <div class="lv-label-header">USD</div>
      </lv-flex-box>
      <lv-flex-box direction="row" class="issue-info-fieldset-output">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherIssueSizeSourceTooltip"
        filter=".lv-label"
        class="lv-label"> Issue Size </div>
        <div class="lv-other-output  issue-info-fieldset-output-value-cell" data-cy="DM-1379">{{view.model.issueSize | number:constants.numberFormat.twoDigits}}</div>
        <div class="lv-other-output  issue-info-fieldset-output-value-cell" data-cy="DM-2365">{{view.model.issueSizeUSD | number:constants.numberFormat.twoDigits}}</div>
      </lv-flex-box>
      <lv-flex-box direction="row" class="issue-info-fieldset-output">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherGreenshoeOptionCCYSourceTooltip"
        filter=".lv-label"
        class="lv-label"> Greenshoe Option </div>
        <div class="lv-other-output  issue-info-fieldset-output-value-cell" data-cy="DM-1380">{{view.model.greenshoeOptionCCY | number:constants.numberFormat.twoDigits}}</div>
        <div class="lv-other-output  issue-info-fieldset-output-value-cell" data-cy="DM-2367">{{view.model.greenshoeOptionUSD | number:constants.numberFormat.twoDigits}}</div>
      </lv-flex-box>
      <lv-flex-box direction="row" class="issue-info-fieldset-output">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherGreenshoeExercisedCCYSourceTooltip"
        filter=".lv-label"
        class="lv-label"> Greenshoe Exercised </div>
        <div class="lv-other-output  issue-info-fieldset-output-value-cell" data-cy="DM-1381">{{view.model.greenshoeExercisedCCY | number:constants.numberFormat.twoDigits}}</div>
        <div class="lv-other-output  issue-info-fieldset-output-value-cell" data-cy="DM-2369">{{view.model.greenshoeExercisedUSD | number:constants.numberFormat.twoDigits}}</div>
      </lv-flex-box>
      <lv-flex-box direction="row" class="issue-info-fieldset-output">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherFinalIssueSizeCcySourceTooltip"
        filter=".lv-label"
        class="lv-label"> Final Issue Size </div>
        <div class="lv-other-output  issue-info-fieldset-output-value-cell" data-cy="DM-1382">{{view.model.finalIssueSizeCcy | number:constants.numberFormat.twoDigits}}</div>
        <div class="lv-other-output  issue-info-fieldset-output-value-cell" data-cy="DM-386">{{view.model.finalIssueSizeUSD | number:constants.numberFormat.twoDigits}}</div>
      </lv-flex-box>
      <lv-flex-box
        direction="row"
        class="issue-info-fieldset-output"
        data-cy="DM-1383">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherUseOfProceedsSourceTooltip"
        filter=".lv-label"
        class="lv-label">Use of Proceeds</div>
        <div class="lv-other-output  issue-info-fieldset-output-value-use">{{view.model.useOfProceeds}}</div>
      </lv-flex-box>
    </lv-fieldset-panel>
    <lv-fieldset-panel class="other-fieldset" legend="OTHER">
      <lv-flex-box direction="row" class="other-fieldset-output">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherCollateralTypeSourceTooltip"
        filter=".lv-label"
        class="lv-label"> Collateral Type </div>
        <div class="lv-other-output other-fieldset-output-value">{{view.model.collateralType}}</div>
      </lv-flex-box>
      <lv-flex-box direction="row" class="other-fieldset-output">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherConvertibleClassificationSourceTooltip"
        filter=".lv-label"
        class="lv-label"> Convertible Classification </div>
        <div class="lv-other-output other-fieldset-output-value">{{view.model.convertibleClassification | enum:convertibleClassificationDescription}}</div>
      </lv-flex-box>
      <lv-flex-box direction="row" class="other-fieldset-output">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherSubordinatedSourceTooltip"
        filter=".lv-label"
        class="lv-label"> Subordinated </div>
        <div class="lv-other-output other-fieldset-output-value">{{view.model.subordinated | lvYesNo}}</div>
      </lv-flex-box>
      <lv-flex-box direction="row" class="other-fieldset-output">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherPariPassuSourceTooltip"
        filter=".lv-label"
         class="lv-label"> Pari Passu </div>
        <div class="lv-other-output other-fieldset-output-value">{{view.model.pariPassu | lvYesNo}}</div>
      </lv-flex-box>
      <lv-flex-box direction="row" class="other-fieldset-output">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherSeniorSourceTooltip"
        filter=".lv-label"
         class="lv-label"> Senior </div>
        <div class="lv-other-output other-fieldset-output-value">{{view.model.senior | lvYesNo}}</div>
      </lv-flex-box>
      <lv-flex-box direction="row" class="other-fieldset-output">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherSecuredSourceTooltip"
        filter=".lv-label"
        class="lv-label"> Secured </div>
        <div class="lv-other-output other-fieldset-output-value">{{view.model.secured | lvYesNo}}</div>
      </lv-flex-box>
      <lv-flex-box direction="row" class="other-fieldset-output">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherSukukConvertibleSourceTooltip"
        filter=".lv-label"
        class="lv-label"> Sukuk Convertible </div>
        <div class="lv-other-output other-fieldset-output-value">{{view.model.sukukConvertible | lvYesNo}}</div>
      </lv-flex-box>
      <lv-flex-box direction="row" class="other-fieldset-output">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherSyntheticConvertibleSourceTooltip"
        filter=".lv-label"
        class="lv-label"> Synthetic Convertible </div>
        <div class="lv-other-output other-fieldset-output-value">{{view.model.syntheticConvertible | lvYesNo}}</div>
      </lv-flex-box>
    </lv-fieldset-panel>
  </lv-flex-box>
  <lv-flex-box class="lv-fieldset-panel no-padding" direction="row">
    <lv-fieldset-panel
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="otherInvolvedPartieSourceTooltip"
      filter=".involved-parties-fieldset" 
      class="involved-parties-fieldset"
      legend="INVOLVED PARTIES"
      data-cy="DM-1392">
      <lv-flex-box
        direction="row"
        class="involved-parties-fieldset-output">
        <div class="lv-label-header">Role</div>
        <div class="lv-label-header">Organization</div>
      </lv-flex-box>
      <lv-flex-box
        *ngFor="let party of view.model.involvedParties"
        direction="row" class="involved-parties-fieldset-output">
        <div class="lv-other-output involved-parties-fieldset-role-value">{{party.role | enum:roleOrganisationDescription}}</div>
        <div class="lv-other-output involved-parties-fieldset-organization-value">{{party.organisationName}}</div>
      </lv-flex-box>
    </lv-fieldset-panel>
    <lv-fieldset-panel class="issue-price-fieldset" legend="AT ISSUE PRICE">
      <lv-flex-box direction="row" class="issue-price-fieldset-output">
        <div class="lv-label"></div>
        <div class="lv-label-header">Fixed</div>
        <div class="lv-label-header">Best</div>
        <div class="lv-label-header">Worst</div>
      </lv-flex-box>
      <lv-flex-box direction="row" class="issue-price-fieldset-output">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherIssuePriceSourceTooltip"
        filter=".lv-label"
        class="lv-label">Issue Price</div>
        <div class="lv-other-output issue-price-fieldset-output-value" data-cy="DM-3987">{{view.model.issueValueFixed | number:constants.numberFormat.threeDigits}}</div>
        <div class="lv-other-output issue-price-fieldset-output-value" data-cy="DM-3988">{{view.model.issueValueBest | number:constants.numberFormat.threeDigits}}</div>
        <div class="lv-other-output issue-price-fieldset-output-value" data-cy="DM-3989">{{view.model.issueValueWorst | number:constants.numberFormat.threeDigits}}</div>
      </lv-flex-box>
      <lv-flex-box direction="row" class="issue-price-fieldset-output">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherPremiumSourceTooltip"
        filter=".lv-label"
        class="lv-label">Premium</div>
        <div class="lv-other-output issue-price-fieldset-output-value" data-cy="DM-3990">{{view.model.premiumFixed | number:constants.numberFormat.threeDigits}}</div>
        <div class="lv-other-output issue-price-fieldset-output-value" data-cy="DM-3991">{{view.model.premiumBest | number:constants.numberFormat.threeDigits}}</div>
        <div class="lv-other-output issue-price-fieldset-output-value" data-cy="DM-3992">{{view.model.premiumWorst | number:constants.numberFormat.threeDigits}}</div>
      </lv-flex-box>
      <lv-flex-box direction="row" class="issue-price-fieldset-output">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherCouponSourceTooltip"
        filter=".lv-label"
        class="lv-label">Coupon</div>
        <div class="lv-other-output issue-price-fieldset-output-value" data-cy="DM-3993">{{view.model.couponFixed | number:constants.numberFormat.threeDigits}}</div>
        <div class="lv-other-output issue-price-fieldset-output-value" data-cy="DM-3994">{{view.model.couponBest | number:constants.numberFormat.threeDigits}}</div>
        <div class="lv-other-output issue-price-fieldset-output-value" data-cy="DM-3995">{{view.model.couponWorst | number:constants.numberFormat.threeDigits}}</div>
      </lv-flex-box>
      <lv-flex-box direction="row" class="issue-price-fieldset-output">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherIssueYieldSourceTooltip"
        filter=".lv-label"
        class="lv-label">Issue Yield</div>
        <div class="lv-other-output issue-price-fieldset-output-value" data-cy="DM-3996">{{view.model.issueYieldFixed | number:constants.numberFormat.threeDigits}}</div>
        <div class="lv-other-output issue-price-fieldset-output-value" data-cy="DM-3997">{{view.model.issueYieldBest | number:constants.numberFormat.threeDigits}}</div>
        <div class="lv-other-output issue-price-fieldset-output-value" data-cy="DM-3998">{{view.model.issueYieldWorst | number:constants.numberFormat.threeDigits}}</div>
      </lv-flex-box>
      <lv-flex-box direction="row" class="issue-price-fieldset-output">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="otherRedemptionValueSourceTooltip"
        filter=".lv-label"
        class="lv-label">Redemption Value</div>
        <div class="lv-other-output issue-price-fieldset-output-value" data-cy="DM-3999">{{view.model.redemptionValueFixed | number:constants.numberFormat.threeDigits}}</div>
        <div class="lv-other-output issue-price-fieldset-output-value" data-cy="DM-4000">{{view.model.redemptionValueBest | number:constants.numberFormat.threeDigits}}</div>
        <div class="lv-other-output issue-price-fieldset-output-value" data-cy="DM-4001">{{view.model.redemptionValueWorst | number:constants.numberFormat.threeDigits}}</div>
      </lv-flex-box>
    </lv-fieldset-panel>
  </lv-flex-box>
</lv-flex-box>

<ng-template #otherPricingDateSourceTooltip>
  <lv-tooltip dm="dM-1375"></lv-tooltip>
</ng-template>
<ng-template #otherAnnouncementDateSourceTooltip>
  <lv-tooltip dm="dM-1376"></lv-tooltip>
</ng-template>
<ng-template #otherSlippageSourceTooltip>
  <lv-tooltip dm="dM-1377"></lv-tooltip>
</ng-template>
<ng-template #otherPotentialDilutionSourceTooltip>
  <lv-tooltip dm="dM-1378"></lv-tooltip>
</ng-template>
<ng-template #otherIssueSizeSourceTooltip>
  <lv-tooltip dm="dM-1379"></lv-tooltip>
</ng-template>
<ng-template #otherGreenshoeOptionCCYSourceTooltip>
  <lv-tooltip dm="dM-1380"></lv-tooltip>
</ng-template>
<ng-template #otherGreenshoeExercisedCCYSourceTooltip>
  <lv-tooltip dm="dM-1381"></lv-tooltip>
</ng-template>
<ng-template #otherFinalIssueSizeCcySourceTooltip>
  <lv-tooltip dm="dM-1382"></lv-tooltip>
</ng-template>
<ng-template #otherUseOfProceedsSourceTooltip>
  <lv-tooltip dm="dM-1383"></lv-tooltip>
</ng-template>
<ng-template #otherUseOfProceedsSourceTooltip>
  <lv-tooltip dm="dM-1383"></lv-tooltip>
</ng-template>
<ng-template #otherCollateralTypeSourceTooltip>
  <lv-tooltip dm="dM-1384"></lv-tooltip>
</ng-template>
<ng-template #otherConvertibleClassificationSourceTooltip>
  <lv-tooltip dm="dM-1385"></lv-tooltip>
</ng-template>
<ng-template #otherSubordinatedSourceTooltip>
  <lv-tooltip dm="dM-1386"></lv-tooltip>
</ng-template>
<ng-template #otherPariPassuSourceTooltip>
  <lv-tooltip dm="dM-1387"></lv-tooltip>
</ng-template>
<ng-template #otherSeniorSourceTooltip>
  <lv-tooltip dm="dM-1388"></lv-tooltip>
</ng-template>
<ng-template #otherSecuredSourceTooltip>
  <lv-tooltip dm="dM-1389"></lv-tooltip>
</ng-template>
<ng-template #otherSukukConvertibleSourceTooltip>
  <lv-tooltip dm="dM-1390"></lv-tooltip>
</ng-template>
<ng-template #otherSyntheticConvertibleSourceTooltip>
  <lv-tooltip dm="dM-1391"></lv-tooltip>
</ng-template>
<ng-template #otherInvolvedPartieSourceTooltip>
  <lv-tooltip dm="dM-1392"></lv-tooltip>
</ng-template>
<ng-template #otherIssuePriceSourceTooltip>
  <lv-tooltip dm="dM-1394"></lv-tooltip>
</ng-template>
<ng-template #otherPremiumSourceTooltip>
  <lv-tooltip dm="dM-1395"></lv-tooltip>
</ng-template>
<ng-template #otherCouponSourceTooltip>
  <lv-tooltip dm="dM-1396"></lv-tooltip>
</ng-template>
<ng-template #otherIssueYieldSourceTooltip>
  <lv-tooltip dm="dM-1397"></lv-tooltip>
</ng-template>
<ng-template #otherRedemptionValueSourceTooltip>
  <lv-tooltip dm="dM-1398"></lv-tooltip>
</ng-template>