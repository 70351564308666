import { IMarketDataComponent } from '../../components/market-data/market-data-component';
import { LvVolatilityComponent } from '../../components/market-data/lv-market-data/lv-volatility/lv-volatility.component';
import { LvDividendsComponent } from '../../components/market-data/lv-market-data/lv-dividends/lv-dividends.component';
import { LvBorrowComponent } from '../../components/market-data/lv-market-data/lv-borrow/lv-borrow.component';
import { LvCreditComponent } from '../../components/market-data/lv-market-data/lv-credit/lv-credit.component';
import { LvInterestRatesComponent } from '../../components/market-data/lv-market-data/lv-interest-rates/lv-interest-rates.component';
import { LvFundingComponent } from '../../components/market-data/lv-market-data/lv-funding/lv-funding.component';
import { LvWithholdingTaxComponent } from '../../components/market-data/lv-market-data/lv-withholding-tax/lv-withholding-tax.component';
import { LvAnalyticsError } from '../errors';
import { IDividendsRequest } from './dividends/requests/dividends-request';
import { IVolatilityRequest } from './volatility/volatility-request';
import { IBorrowSaveRequest } from './borrow/borrow-request';
import { ICreditRequest } from './credit/credit-request';
import { IFundingSaveRequest } from './funding/funding-request';
import { IWitholdingTaxSaveRequest } from './witholding-tax/witholding-tax-request';
import { IDataEntitlement } from '../../../shared/interfaces/data-entitlement';
import { IAveragingDataSaveRequest } from './averaging-data/averaging-data-request';
import { LvAveragingDataComponent } from '../../components/market-data/lv-market-data/lv-averaging-data/lv-averaging-data.component';
import { ISaveInterestRatesRequest } from './interest-rates/interest-rates-request';
import { IOtherMarketDataRequest } from './other/other-market-data-request';
import { LVOtherMarketDataComponent } from '@lv-analytics/components/market-data/lv-market-data/lv-other/lv-other-market-data.component';

/**
 * Save market data request.
 */
export class SaveMarketDataRequest implements IDataEntitlement {
  dividends: IDividendsRequest;
  volatility: IVolatilityRequest;
  borrow: IBorrowSaveRequest;
  credit: ICreditRequest;
  interestRates: ISaveInterestRatesRequest;
  funding: IFundingSaveRequest;
  witholdingTax: IWitholdingTaxSaveRequest;
  averagingData: IAveragingDataSaveRequest;
  other: IOtherMarketDataRequest;
  sourceId: string;
  isPrivateInstrument: boolean;
  sessionId: string;
  lwsIdentifier: string;

  constructor() {
  }

  /**
   * Applies market data section.
   * @param marketDataComponent IMarketDataComponent objects.
   */
  applyMarketDataSection(marketDataComponent: IMarketDataComponent<any>) {

    const requestSettings = marketDataComponent.getSettings();

    if (marketDataComponent instanceof LvDividendsComponent) {
      this.dividends = requestSettings;
    } else if (marketDataComponent instanceof LvVolatilityComponent) {
      this.volatility = requestSettings;
    } else if (marketDataComponent instanceof LvBorrowComponent) {
      this.borrow = requestSettings;
    } else if (marketDataComponent instanceof LvCreditComponent) {
      this.credit = requestSettings;
    } else if (marketDataComponent instanceof LvInterestRatesComponent) {
      this.interestRates = requestSettings;
    } else if (marketDataComponent instanceof LvFundingComponent) {
      this.funding = requestSettings;
    } else if (marketDataComponent instanceof LvWithholdingTaxComponent) {
      this.witholdingTax = requestSettings;
    } else if (marketDataComponent instanceof LvAveragingDataComponent) {
      this.averagingData = requestSettings;
    } else if (marketDataComponent instanceof LVOtherMarketDataComponent) {
      this.other = requestSettings;
    } else {
      throw new LvAnalyticsError('Unknown Market Data component');
    }
  }

  /**
   * Applies current changes.
   * @param marketDataComponent IMarketDataComponent object.
   */
  applyCurrentChanges(marketDataComponent: IMarketDataComponent<any>) {
    marketDataComponent.applyCurrentChanges();
  }
}
