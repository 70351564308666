<div class="lv-negative-label"
  [class.lv-negative-label-color]="isNegative">

  <div [class.lv-negative-label-border]="shouldShowBorder">
    <ng-container *ngIf="visible">
      <div class="prefix">{{ prefix }}{{ transformed }}</div>
    </ng-container>
  </div> 
  <ng-container *ngIf="isSuffixVisible">
    <div *ngIf="suffix" class="suffix"
      [class.auto-width]="autoSuffixWidth">{{ suffix }}</div>
  </ng-container>
</div>