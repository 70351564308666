import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { LvLocalization } from '../../util/localization';

@Pipe({
  name: 'lvLocalDate'
})
export class LvLocalDatePipe implements PipeTransform {


  transform(value?: any, defaultValue = null, withTime: boolean = false): any {
    if (!value) {
      return defaultValue;
    }

    if (!(value instanceof Date)) {
      value = new Date(value);
    }

    moment.locale(LvLocalization.getBrowserLocale());
    let format = null;
    if (withTime) {
      format = LvLocalization.getLocalDateTimeFormat();
    } else {
      format = LvLocalization.getLocalDateFormat();
    }
    return moment(value).format(format);
  }
}
