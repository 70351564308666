import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, OnChanges, OnDestroy, SimpleChanges,
  ChangeDetectorRef } from '@angular/core';

import { Subscription } from 'rxjs';
import { IPaginatedResult } from '@lv-core-ui/models';
import { LvErrorService, LvPermissionService } from '@lv-core-ui/services';
import { IInstrumentInfo, IWatchList, IInstrumentInfoWatchList } from '@lv-instrument-monitor/models';
import { WatchListService } from '@lv-instrument-monitor/services';
import { ILvWatchListPanelDialogItem } from '../lv-watch-list-panel-dialog-item';
import { LvWatchListPanelDialogComponent } from '../lv-watch-list-panel-dialog.component';
import { LvInstrumentWatchListsView } from './lv-instrument-watch-lists.view';
import { AuthorizationService } from '@lv-core-ui/services/authorization/authorization.service';

@Component({
  selector: 'lv-instrument-watch-lists',
  templateUrl: './lv-instrument-watch-lists.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvInstrumentWatchListsComponent implements OnInit, OnChanges, OnDestroy, ILvWatchListPanelDialogItem {

  @Input() availableInstrumentInfos: IPaginatedResult<IInstrumentInfo>;

  get editEnabled(): boolean {
    return !this.dialog.isLoading && !!this.view.selectedInstrumentInfo;
  }

  view: LvInstrumentWatchListsView;

  continueEditingSubscription: Subscription;

  private selectedWatchListsFocused: boolean;

  constructor(
    private authorizationService: AuthorizationService,
    private changeDetectorRef: ChangeDetectorRef,
    private errorService: LvErrorService,
    private permissionService: LvPermissionService,
    private service: WatchListService,
    private dialog: LvWatchListPanelDialogComponent
  ) {
    this.view = new LvInstrumentWatchListsView(this.authorizationService, this.permissionService, this.service);

    this.dialog.iWatchLists = this;

    this.selectedWatchListsFocused = false;
  }

  ngOnInit() {
    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.availableInstrumentInfos.previousValue !== changes.availableInstrumentInfos.currentValue) {
      this.view.instrumentsGridView.initView(this.availableInstrumentInfos.records);
      this.changeDetectorRef.detectChanges();
    }
  }

  async refresh(): Promise<void> {
    try {
      this.dialog.setLoadingState(true);

      const wls = await this.view.watchListView.getView({
        excludeSystemWatchLists: true,
        excludeSharedWatchLists: true,
        excludeWatchListGroups: true
      });
      this.view.initialWatchLists = wls.filter(x => !x.isSystem);

      this.view.setWatchLists();

      const firstRecord = (this.view.instrumentsGridView.recordsView.data[0] as IInstrumentInfo);
      if (firstRecord) {
        const selected = [firstRecord.id];
        this.onInstrumentGridViewSelectedKeysChange(selected);
        this.view.instrumentsGridView.setSelectedIdentifiers(selected);
      }
    }
    catch (error) {
      this.errorService.handleError(error);
    }
    finally {
      this.dialog.setLoadingState(false);
      this.changeDetectorRef.detectChanges();
    }
  }

  onFilterInstrumentsGrid() {
    this.view.instrumentsGridView.reloadView();
    this.changeDetectorRef.detectChanges();
  }

  onInstrumentGridViewSelectedKeysChange(recordIdentifiers: number[]) {
    this.validateInstrument(async () => {
      try {
        this.dialog.setLoadingState(true);

        await this.view.setSelectedInstrumentInfo(recordIdentifiers[0]);
      }
      catch (error) {
        this.errorService.handleError(error);
      }
      finally {
        this.dialog.setLoadingState(false);

        this.changeDetectorRef.detectChanges();
      }
    });
  }

  onSelectWatchList(wl: IWatchList, focusSelectedWatchLists: boolean) {
    if (wl.isGroup) {
      return false;
    }

    this.selectedWatchListsFocused = focusSelectedWatchLists;
    this.view.setSelectedWatchList(wl);

    this.changeDetectorRef.detectChanges();
  }

  onAddInstrumentToWatchList(wl: IWatchList) {
    this.view.addInstrumentToWatchList(wl);
    this.view.setWatchLists();

    this.changeDetectorRef.detectChanges();
  }

  onRemoveInstrumentFromWatchList(wl: IInstrumentInfoWatchList) {
    this.view.removeInstrumentFromWatchList(wl);
    this.view.setWatchLists();

    this.changeDetectorRef.detectChanges();
  }

  onSaveAll() {
    this.view.addInstrumentsToWatchList(this.view.watchLists);
    this.view.setWatchLists();

    this.changeDetectorRef.detectChanges();
  }

  onClearAll() {
    this.view.removeInstrumentsFromWatchList(this.view.selectedWatchLists.current);
    this.view.setWatchLists();

    this.changeDetectorRef.detectChanges();
  }

  isDirty(): boolean {
    return this.view.selectedWatchLists && this.view.selectedWatchLists.isDirty;
  }

  async saveItem(): Promise<void> {
    try {
      await this.view.updateInstrumentWatchLists();

      this.view.selectedWatchLists.update();

      this.changeDetectorRef.detectChanges();
    }
    catch (error) {
      throw error;
    }
  }

  onCtrlA(event: KeyboardEvent) {
    if (this.view.selectedWatchList && !this.selectedWatchListsFocused) {
      this.onAddInstrumentToWatchList(this.view.selectedWatchList);
      this.selectedWatchListsFocused = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  onCtrlR(event: KeyboardEvent) {
    if (this.view.selectedWatchList && this.selectedWatchListsFocused) {
      this.onRemoveInstrumentFromWatchList(this.view.selectedWatchList as any);
      this.selectedWatchListsFocused = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  getCurrentRecordEditingItemName(): string {
    if (this.view.selectedInstrumentInfo) {
      return this.view.selectedInstrumentInfo.name;
    }

    return null;
  }

  ngOnDestroy() {
    if (this.continueEditingSubscription) {
      this.continueEditingSubscription.unsubscribe();
    }
  }

  private validateInstrument(action: () => void) {
    if (this.isDirty()) {
      this.continueEditingSubscription = this.dialog.showContinueEditingPanel(this.view.selectedInstrumentInfo.name)
        .subscribe(continueEditing => {
          if (continueEditing) {
            this.dialog.hideContinueEditingPanel();

            if (this.view.selectedInstrumentInfo) {
              this.view.instrumentsGridView.setSelectedIdentifiers([this.view.selectedInstrumentInfo.id]);
              this.changeDetectorRef.detectChanges();
            }
          }
          else {
            action();
          }
        });
    }
    else {
      action();
    }
  }
}
