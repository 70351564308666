<div class="lv-sensitivity-analysis">
  <div class="lv-sensitivity-analysis-header">
    <lv-pricing [eventSource]="eventSource">
    </lv-pricing>
  </div>
  <div class="lv-sensitivity-analysis-content">
    <div class="lv-scenario-template">
      <div class="lv-scenario-template-title">{{ scenarioTemplateName }}</div>
      <div class="lv-scenario-template-separator">&nbsp;</div>
    </div>
    <div class="lv-scenarios">
      <lv-workspace-panel
        defaultName="Scenario"
        [items]="view.workspaceItems"
        (didSelect)="onSelectScenario($event)"
        (didAdd)="onAddScenario($event)"
        (didEdit)="onEditScenario($event)"
        (didClose)="onCloseScenario($event)">
        <lv-scenario
          [scenario]="view.currentScenario"
          [convertible]="cHelper.convertible">
        </lv-scenario>
      </lv-workspace-panel>
    </div>
    <div class="lv-sensitivity-analysis-options">
      <button kendoButton
        [primary]="true"
        [disabled]="calculateDisabled"
        (click)="onCalculate()">
          Calculate
      </button>

      <div class="lv-field"
        (click)="onToggleScenarioTemplatePanel()">
          <i class="lv-icon k-icon k-i-arrow-chevron-left"></i>
      </div>
    </div>

    <lv-side-panel class="lv-sensitivity-analysis-templates-panel"
      position="right"
      [width]="320"
      #scenarioTemplateListPanel>
      <div class="lv-sensitivity-analysis-templates-panel-header">
        <span>Scenario Templates</span>
      </div>

      <div class="lv-sensitivity-analysis-templates-panel-content">
        <div lvId="sensitivity-analysis-settings-all-templates"
          class="scenario-template-container">
          <div *ngFor="let st of view.scenarioTemplateInfoList"
            [class.lv-scenario-template-item]="!st.isGroup"
            [class.lv-scenario-template-group]="st.isGroup"
            [class.lv-scenario-template-item--selected]="view.isScenarioTemplateSelected(st)"
            [class.lv-scenario-template-item--edited]="view.isEdited(st.id)"
            (click)="onSelectScenarioTemplate(st)">
              {{ st.scenarioTemplateName }}

              <div *ngIf="!st.isGroup" class="spacer"></div>
              <div *ngIf="!st.isGroup && view.shoowSaveIcon(st.id)"
                class="lv-icon-square"
                title="Save"
                (click)="onShowSaveScenarioTemplate(st.isDraft)">
                  <i class="k-icon k-i-save"></i>
              </div>
          </div>
        </div>

        <lv-side-panel class="lv-sensitivity-analysis-template-editor-panel"
          position="right"
          [width]="307"
          [manualClosing]="true"
          (didSlide)="onScenarioTemplateEditPanelOpen()"
          #scenarioTemplateEditPanel>

          <ng-container *ngIf="!showDeleteScenarioTemplate">
            <div class="lv-sensitivity-analysis-template-editor-panel-header">
              {{ view.originalModel.name }}
            </div>
            
            <form #form="ngForm"
              class="lv-sensitivity-analysis-template-editor-panel-content">
              <div class="inner-row">
                <div class="col-5 lv-label">
                  Name
                </div>
                <div class="col-7">
                  <input #scenarioTemplateNameElement
                    kendoTextBox
                    name="editScenarioTemplate"
                    [(ngModel)]="view.model.name">
                </div>
              </div>
            </form>

            <div class="lv-sensitivity-analysis-template-editor-panel-footer">
              <button kendoButton
                type="button"
                look="flat"
                lvId="edit-scenario-template-cancel-sl-edit"
                (click)="onCancelEditTemplate()">
                Cancel
              </button>
              <button kendoButton
                type="button"
                [primary]="true"
                lvId="edit-scenario-template-save-s1"
                (click)="onSaveTemplate()">
                Save
              </button>
            </div>
          </ng-container>


          <ng-container *ngIf="showDeleteScenarioTemplate">
            <div class="lv-sensitivity-analysis-template-editor-panel-content">
              <span>Are you sure you want to delete </span>
              <span class="lv-output">{{ view.originalModel.name }}</span>
              <span> Scenario Template</span>?
            </div>

            <div class="lv-sensitivity-analysis-template-editor-panel-footer">
              <button kendoButton
                type="button"
                look="flat"
                lvId="edit-scenario-template-cancel-wl-delete"
                (click)="onToggleScenarioTemplateEditPanel()">
                Cancel
              </button>
              <button kendoButton
                type="button"
                [primary]="true"
                lvId="edit-scenario-template-delete-wl"
                (click)="onDeleteScenarioTemplate()">
                Delete
              </button>
            </div>
          </ng-container>
        </lv-side-panel>
      </div>
      
      <div class="lv-sensitivity-analysis-templates-panel-footer">    
        <div class="lv-icon-square"
          [class.lv-icon-square--disabled]="editEnabled"
          title="Edit Scenario Template"
          lvId="sensitivity-analysis-panel-edit-scenario-template"
          (click)="onShowEditScenarioTemplate()">
          <i class="k-icon k-i-pencil"></i>
        </div>

        <div class="lv-icon-square"
          [class.lv-icon-square--disabled]="editEnabled"
          title="Copy Scenario Template"
          lvId="sensitivity-analysis-panel-edit-scenario-template"
          (click)="onShowCopyScenarioTemplate()">
          <i class="k-icon k-i-copy"></i>
        </div>

        <div class="lv-sensitivity-analysis-templates-panel-footer-spacer"></div>
  
        <div class="lv-icon-square lv-icon-square--danger"
          [class.lv-icon-square--disabled]="editEnabled"
          title="Delete Scenario Template"
          lvId="sensitivity-analysis-panel-delete-scenario-template"
          (click)="onShowDeleteScenarioTemplate()">
          <i class="k-icon k-i-delete"></i>
        </div>
      </div>
    </lv-side-panel>

    <lv-xignite></lv-xignite>
  </div>
</div>