import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpClientBase } from '@lv-core-ui/api';
import { LvSecurityManagementError } from '@lv-security/models';

/**
 * User service.
 */
@Injectable()
export class UserService extends HttpClientBase {

  constructor(
    http: HttpClient
  ) {
    const resourceUrl = `/security/user`;
    super(http, resourceUrl);
  }

  /**
   * Changes password.
   * @param newPassword New password value
   * @returns True indicating that password is successfully changed
   */
  async changePassword(newPassword: string): Promise<boolean> {
    try {
      await this.putAsync<any>({ password: newPassword}, '/password', null);
      return true;
    }
    catch (error) {
      throw this.handleError(error, e => new LvSecurityManagementError(e.message));
    }
  }

}
