import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { ICopyAndPasteSectionSettings } from 'src/app/modules/analytics/models/company-and-user-settings/other-settings/copy-and-paste-settings/copy-and-paste-section-settings';
// tslint:disable-next-line: max-line-length
import { ICopyAndPasteSettings } from 'src/app/modules/analytics/models/company-and-user-settings/other-settings/copy-and-paste-settings/copy-and-paste-settings';

/**
 * Copy and paste settings component.
 */
@Component({
  selector: 'lv-copy-and-paste-settings',
  templateUrl: './lv-copy-and-paste-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCopyAndPasteSettingsComponent implements OnInit {

  @Input() settings: ICopyAndPasteSettings;

  constructor() {
    this.settings = {
      systemDefaults: {} as ICopyAndPasteSectionSettings,
      environments: []
    } as ICopyAndPasteSettings;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
   getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }
}
