import { Component, OnInit, Input, Output, EventEmitter, OnChanges,
        ViewEncapsulation, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { v4 } from 'uuid';
import { LvLookupEnum } from '@lv-core-ui/util';
import { TakeoverProtection, TakeoverPutValueType, TakeoverPutValueTypeDescription } from '@lv-convertible-bond/models';

@Component({
  selector: 'lv-takeover-put-parameters',
  templateUrl: './lv-takeover-put-parameters.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvTakeoverPutParametersComponent implements OnInit, OnChanges {
  @Input() model: TakeoverProtection;

  @Output() didSetTakeoverPutParameters: EventEmitter<TakeoverProtection>;

  takeoverPutValueTypeLookup: LvLookupEnum;
  takeoverPutCheckBoxId: string;
  isTakeoverPutValueVisible: boolean;
  isTakeoverPutValuePercentVisible: boolean;
  isTakeoverPutValueTypeVisible: boolean;

  numberOfDecimals = '4';
  numberFormat = '#,###.####';

  get isAccreated(): boolean {
    return this.model.takeoverPutValueType === TakeoverPutValueType.AccretedValue;
  }

  constructor() {

    this.didSetTakeoverPutParameters = new EventEmitter<TakeoverProtection>();

    this.takeoverPutValueTypeLookup = new LvLookupEnum(TakeoverPutValueTypeDescription);
    this.takeoverPutCheckBoxId = v4();
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.setFieldsVisibility();
  }

  doModelChange() {
    this.setFieldsVisibility();
    this.didSetTakeoverPutParameters.next(this.model);
  }

  getTakeoverPutTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

  private setFieldsVisibility() {
    this.isTakeoverPutValueVisible = this.model.takeoverPut && this.model.takeoverPutValueType === TakeoverPutValueType.AccretedValue;
    this.isTakeoverPutValuePercentVisible = this.model.takeoverPut && this.model.takeoverPutValueType === TakeoverPutValueType.PercentOfPar;
    this.isTakeoverPutValueTypeVisible = this.model.takeoverPut;
  }

}
