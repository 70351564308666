import { Injectable } from '@angular/core';
import { ITermStructureItem } from '..';

/**
 * Iterest rates mapper.
 */
@Injectable()
export class InterestRatesMapper {
    /**
     * Maps interest rates terms structure to API.
     * @param items List of ITermStructureItem objects.
     * @returns List of ITermStructureItem objects.
     */
    mapInterestRatesTermStructureToApi(items: ITermStructureItem[]): ITermStructureItem[] {
        items.forEach((item) => {
            item.updatedRateDate = Date.now();
        });

        return items;
    }
}
