<lv-flex-box class="lv-averaging-period">
  <lv-flex-box direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="averagingPeriodSourceTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'averagingPeriodSourceTooltip'">
        Averaging Period
      </span>
    </div>
    <div class="lv-input-field" data-cy="DM-2399">
      <lv-numeric-text-box
        name="averagingPeriodAveragingPeriodValue"
        [format]="numberFormat"
        [decimals]="numberOfDecimals"
        [(ngModel)]="model.averagingPeriodAveragingPeriodValue"
        (didBlur)="doModelChange()"
        (didKeyDownEnter)="doModelChange()">
      </lv-numeric-text-box>
    </div>
    <div class="lv-input-field" data-cy="DM-2400">
      <kendo-dropdownlist
        name="averagingPeriodAveragingPeriodPeriod"
        [data]="averagingPeriodAveragingPeriodPeriodLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="model.averagingPeriodAveragingPeriodPeriod"
        (ngModelChange)="doModelChange()">
      </kendo-dropdownlist>
    </div>
    <div class="lv-input-field" data-cy="DM-2401">
      <kendo-dropdownlist
        name="averagingPeriodAveragingPeriodCalendar"
        [data]="averagingPeriodAveragingPeriodCalendarLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="model.averagingPeriodAveragingPeriodCalendar"
        (ngModelChange)="doModelChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="timeBeforeReferenceDateSourceTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'timeBeforeReferenceDateSourceTooltip'">
        Time Before Reference Date
      </span>
    </div>
    <div class="lv-input-field" data-cy="DM-2402">
      <lv-numeric-text-box
        name="averagingPeriodTimeBeforeReferenceDateValue"
        [format]="numberFormat"
        [decimals]="numberOfDecimals"
        [(ngModel)]="model.averagingPeriodTimeBeforeReferenceDateValue"
        (didBlur)="doModelChange()"
        (didKeyDownEnter)="doModelChange()">
      </lv-numeric-text-box>
    </div>
    <div class="lv-input-field" data-cy="DM-2403">
      <kendo-dropdownlist
        name="averagingPeriodTimeBeforeReferenceDatePeriod"
        [data]="averagingPeriodTimeBeforeReferenceDatePeriodLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="model.averagingPeriodTimeBeforeReferenceDatePeriod"
        (ngModelChange)="doModelChange()">
      </kendo-dropdownlist>
    </div>
    <div class="lv-input-field" data-cy="DM-2404">
      <kendo-dropdownlist
        name="averagingPeriodTimeBeforeReferenceDateCalendar"
        [data]="averagingPeriodTimeBeforeReferenceDateCalendarLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="model.averagingPeriodTimeBeforeReferenceDateCalendar"
        (ngModelChange)="doModelChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-1432">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="completeMonthSourceTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'completeMonthSourceTooltip'">
        Complete Month
      </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        [attr.id]="averagingPeriodCompleteMonthCheckBoxId"
        name="takeoverPut"
        [(ngModel)]="model.averagingPeriodCompleteMonth"
        (ngModelChange)="doModelChange()"/>
      <label class="k-checkbox-label" [attr.for]="averagingPeriodCompleteMonthCheckBoxId"></label>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-1433">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="fxRateUsedSourceTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'fxRateUsedSourceTooltip'">
        FX Rate Used
      </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="averagingPeriodFxRateUsed"
        [data]="averagingPeriodFxRateUsedLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="model.averagingPeriodFxRateUsed"
        (ngModelChange)="doModelChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
</lv-flex-box>

<ng-template #averagingPeriodSourceTooltipTemplate>
  <lv-tooltip dm="dM-1430"></lv-tooltip>
</ng-template>
<ng-template #timeBeforeReferenceDateSourceTooltipTemplate>
  <lv-tooltip dm="dM-1431"></lv-tooltip>
</ng-template>
<ng-template #completeMonthSourceTooltipTemplate>
  <lv-tooltip dm="dM-1432"></lv-tooltip>
</ng-template>
<ng-template #fxRateUsedSourceTooltipTemplate>
  <lv-tooltip dm="dM-1433"></lv-tooltip>
</ng-template>
