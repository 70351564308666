
export enum CallTriggerPeriodType {
	
	NOutOfMDays = 'NOutOfMDays',
	AverageOfConsecutiveDays = 'AverageOfConsecutiveDays'
}
export enum InterpolationType {
	
	Staircase = 'Staircase',
	Linear = 'Linear',
	Exponential = 'Exponential'
}
export enum CallMakeWholeType {
	
	None = 'None',
	Premium = 'Premium',
	Coupon = 'Coupon',
	CouponPV = 'CouponPV',
	RatchetMatrix = 'RatchetMatrix',
	PremiumLinDecreasing = 'PremiumLinDecreasing'
}