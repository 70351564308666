<div class="lv-label lv-label-field lv-label--title">
  Model Type
</div>
<lv-flex-box
  direction="row"
  data-cy="DM-1442">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="creditModelTypeSettingsTooltipTemplate"
    filter="span">
    <span> Credit Model Type </span>
  </div>

  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      name="modelCustomizationModelTypeCreditModelType"
      valueField="id"
      textField="text"
      [data]="creditModelType.items"
      [valuePrimitive]="true"
      [(ngModel)]="settings.creditModelType"
      [disabled]="!overrideSystemDefaults"
      [popupSettings]="{ width: 151 }">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box
  direction="row"
  data-cy="DM-1443">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="equityModelTypeSettingsTooltipTemplate"
    filter="span">
    <span>
      Equity Model Type
    </span>
  </div>

  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      name="modelCustomizationModelTypeEquityModelType"
      valueField="id"
      textField="text"
      [data]="equityModelType.items"
      [valuePrimitive]="true"
      [(ngModel)]="settings.equityModelType"
      [disabled]="!overrideSystemDefaults"
      [popupSettings]="{ width: 130 }">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box
  direction="row"
  data-cy="DM-1444">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="undCreditModelSettingsTooltipTemplate"
    filter="span">
    <span> Und. Credit Model </span>
  </div>

  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      name="modelCustomizationModelTypUndCreditModelType"
      valueField="id"
      textField="text"
      [data]="creditModelType.items"
      [valuePrimitive]="true"
      [(ngModel)]="settings.underlyingCreditModelType"
      [disabled]="!overrideSystemDefaults"
      [popupSettings]="{ width: 152 }">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box
  direction="row"
  data-cy="DM-1445">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="stochasticCreditSettingsTooltipTemplate"
    filter="span">
    <span> Stochastic Credit </span>
  </div>

  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      name="modelCustomizationModelTypeStochasticCredit"
      valueField="id"
      textField="text"
      [data]="stochasticCreditModelType.items"
      [valuePrimitive]="true"
      [(ngModel)]="settings.stochasticCreditModelType"
      [disabled]="!overrideSystemDefaults"
      [popupSettings]="{ width: 133 }">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box
  direction="row"
  data-cy="DM-1446">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="noCreditPointsSettingsTooltipTemplate"
    filter="span">
    <span> No. Credit Points </span>
  </div>

  <div class="lv-input-field">
    <lv-numeric-text-box
      name="modelCustomizationModelTypeNoCreditPoints"
      [format]="numberFormat"
      [decimals]="numberOfDecimals"
      [(ngModel)]="settings.numPointsStochasticCredit"
      [disabled]="!overrideSystemDefaults">
    </lv-numeric-text-box>
  </div>
</lv-flex-box>

<lv-flex-box
  direction="row"
  data-cy="DM-1447">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="stochasticCreditBCSettingsTooltipTemplate"
    filter="span">
    <span> Stochastic Credit BC </span>
  </div>

  <div class="lv-input-field lv-input-field--auto">
    <kendo-dropdownlist
      name="modelCustomizationModelTypeStochasticCreditBC"
      valueField="id"
      textField="text"
      [data]="stochasticCreditBCType.items"
      [valuePrimitive]="true"
      [(ngModel)]="settings.stochasticCreditBCType"
      [disabled]="!overrideSystemDefaults">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<ng-template #creditModelTypeSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1442"></lv-tooltip>
</ng-template>
<ng-template #equityModelTypeSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1443"></lv-tooltip>
</ng-template>
<ng-template #undCreditModelSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1444"></lv-tooltip>
</ng-template>
<ng-template #stochasticCreditSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1445"></lv-tooltip>
</ng-template>
<ng-template #noCreditPointsSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1446"></lv-tooltip>
</ng-template>
<ng-template #stochasticCreditBCSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1447"></lv-tooltip>
</ng-template>
