import { LvUserFriendlyError } from '@lv-core-ui/models';

/**
 *  User-friendly error for documents module.
 */
export class LvDocumentsError extends LvUserFriendlyError {
  constructor(message: string) {
    super(message, 'Documents');
  }
}
