import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ICrEstimateSettings } from '@lv-analytics/models';
import { v4 } from 'uuid';

/**
 * Estimates settings section component.
 */
@Component({
  selector: 'lv-estimates-settings-section',
  templateUrl: './lv-estimates-settings-section.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEstimatesSettingsSectionComponent implements OnInit, OnChanges {

  @Input() estimatesSetup: ICrEstimateSettings;
  @Input() settingsDisabled: boolean;

  get ratioSelected(): boolean {
    return !this.estimatesSetup.showConversionPrice;
  }

  useCurrentConversionPriceRadioButtonId: string;
  useEstimateConversionPriceRadioButtonId: string;
  displayConversionPriceByDefaultRadioButtonId: string;
  displayConversionRatioByDefaultRadioButtonId: string;
  convertibleCcyRadioButtonId: string;
  underlyingCcyRadioButtonId: string;
  conversionPriceCcyRadioButtonId: string;

  conversionPriceCcyRadioButtonName: string;
  displayConversionPriceRationRadioButtonName: string;
  displayConversionPriceInRadioButtonName: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.useCurrentConversionPriceRadioButtonId = v4();
    this.useEstimateConversionPriceRadioButtonId = v4();
    this.displayConversionPriceByDefaultRadioButtonId = v4();
    this.displayConversionRatioByDefaultRadioButtonId = v4();
    this.convertibleCcyRadioButtonId = v4();
    this.underlyingCcyRadioButtonId = v4();
    this.conversionPriceCcyRadioButtonId = v4();

    this.conversionPriceCcyRadioButtonName = v4();
    this.displayConversionPriceRationRadioButtonName = v4();
    this.displayConversionPriceInRadioButtonName = v4();
   }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Detects changes.
   */
  ngOnChanges() {
    this.changeDetectorRef.detectChanges();
  }
}
