

import { ConvertibleBondSubType } from './ConvertibleBondSubType';
import { EquityData } from './EquityData';
import { ExhangeableParameters } from './ExhangeableParameters';
import { RecoveryUponDefault } from './RecoveryUponDefault';
import { SinkingFundScheduleItem } from './SinkingFundScheduleItem';
import { SetupStatus } from './Enums (5)';

export class IssueAndRedemption {

    subType: ConvertibleBondSubType;
    name: string;
    shortName: string;
    issuerName: string;
    underlyingEquity: EquityData;
    countryCode: string;
    riskCountryCode: string;
    currencyCode: string;
    maturingCurrencyCode: string;
    firstSettlementDate: Date;
    maturityDate: Date;
    isPerpetual: boolean;
    nominalValue: number;
    issueValue: number;
    issueValueIsPercentOfNominal: boolean;
    redemptionValue: number;
    redemptionValueIsPercentOfNominal: boolean;
    isPriceAsPar: boolean;
    isQuotedClean: boolean;
    fixedFXRate: number;
    underlyingCurrencyLinked: boolean;
    exhangeableParameters: ExhangeableParameters;
    isin: string;
    bloomberUniqueID: string;
    cusip: string;
    figi: string;
    issueSeries: string;
    exhangeCode: string;
    daysToSettle: number;
    shareRedemption: boolean;
    fiscalYearStartDate: Date;
    recoveryUponDefault: RecoveryUponDefault;
    isAccruedUponDefault: boolean;
    isCallable: boolean;
    isPuttable: boolean;
    useDividendProtection: boolean;
    fxQuanto: boolean;
    setupStatus: SetupStatus;
    percCoveredWarrants: number;
    sinkable: boolean;
    sinkingFundSchedule: SinkingFundScheduleItem[];
    isCleanUpCall: boolean;
    notes: string;
}

