import { Component, OnInit, ViewChild, ChangeDetectorRef, ViewEncapsulation, ChangeDetectionStrategy,
  ElementRef, OnDestroy, Output, EventEmitter} from '@angular/core';

import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { IMarketDataComponent } from '../../market-data-component';
import { LvErrorService } from '@lv-core-ui/services';
import { IFundingSaveRequest } from '@lv-analytics/models/market-data/funding/funding-request';
import { IEnvironmentSettingsItem,
         LvEnvironmentSettingsComponent } from '@lv-analytics/components/lv-environment-settings/lv-environment-settings.component';
import { PricingEnvironmentSections } from '@lv-analytics/models/enum/pricing-environment-sections';
import { IFunding } from '@lv-analytics/models/market-data/funding/funding';
import { MarketDataService } from '@lv-analytics/services/market-data/market-data.service';
import { LvMarketDataPresenter } from '../lv-market-data.presenter';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { AnalyticsSettingsEvents } from '@lv-analytics/models/enum/analytics-settings-events';

/**
 * Funding component.
 */
@Component({
  selector: 'lv-funding',
  templateUrl: './lv-funding.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvFundingComponent implements OnInit, OnDestroy, IMarketDataComponent<IFundingSaveRequest> {

  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;

  @Output() didSessionUpdatedEvent: EventEmitter<void>;

  get isFundingSectionDisabled(): boolean {
    return !this._analyticsPresenter.asHelper.instrumentLoaded;
  }

  fundingSection = PricingEnvironmentSections.Funding;

  fundingSettings: IFunding;
  originalValue: IFunding;

  numberOfDecimalsBps = '0';
  numberFormatBps = 'n0';
  numberOfDecimalsPercentage = '2';
  numberFormatPercentage = '#.##';

  private _subscriptions: Subscription[];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _marketDataService: MarketDataService,
    private _presenter: LvMarketDataPresenter,
    private _analyticsPresenter: LvAnalyticsPresenter
  ) {

    this.fundingSettings = {} as IFunding;
    this.didSessionUpdatedEvent = new EventEmitter<void>();
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this._analyticsPresenter.onModelLoading.subscribe(isLoading => this.setLoadingState(isLoading)),

      this._analyticsPresenter.onModelUpdated.subscribe(evt => {
        if (evt && evt.eventId === AnalyticsSettingsEvents.MarketDataUpdated) {
          this.fundingSettings = evt.data.marketData.funding;
          this.originalValue = _.cloneDeep(this.fundingSettings);
          this._changeDetectorRef.detectChanges();
        }
      })
    ];
  }

  /**
   * Occurs on funding section change.
   */
  onFundingSectionChange() {
    this.overrideFunding();
  }

  /**
   * Occurs on changed environment.
   * @param environment IEnvironmentSettingsItem object.
   */
  onChangedEnvironment(environment: IEnvironmentSettingsItem) {
    this.loadFunding(environment);
  }

  /**
   * Gets selected environment ID.
   * @returns Environment ID.
   */
  getSelectedEnvironmentId(): string {
    const env = this.envSettings.getSelectedEnvironment();
    return env.id;
  }

  /**
   * Gets settings.
   * @returns IFundingSaveRequest object.
   */
  getSettings(): IFundingSaveRequest {
    return this.getFundingSaveRequest();
  }

  /**
   * Applies current changes.
   */
  applyCurrentChanges() {

  }

  /**
   * Gets section.
   * @returns PricingEnvironmentSections object.
   */
  getSection(): PricingEnvironmentSections {
    return this.fundingSection;
  }

  /**
   * Gets funding tooltip ID.
   * @param element HTML element.
   * @param sectionId Section ID.
   * @returns Funding tooltip ID.
   */
  getFundingTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('funding-tooltip-id') === sectionId;
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Gets funding save request.
   * @returns IFundingSaveRequest object.
   */
  private getFundingSaveRequest(): IFundingSaveRequest {
    const env = this.envSettings.getSelectedEnvironment();

    return {
      lwsIdentifier: this._analyticsPresenter.asHelper.lwsIdentifier,
      environmentId: env.id,
      funding: this.fundingSettings
    } as IFundingSaveRequest;
  }

  /**
   * Override funding.
   */
  private async overrideFunding() {
    try {
      await this._marketDataService.overrideFunding({
        lwsIdentifier: this._analyticsPresenter.asHelper.lwsIdentifier,
        sessionId: this._analyticsPresenter.asHelper.sessionId,
        funding: this.fundingSettings
      });

      if (!_.isEqual(this.fundingSettings, this.originalValue)) {
        this.didSessionUpdatedEvent.next();
        this.originalValue = _.cloneDeep(this.fundingSettings);
      }
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
    }
  }

  /**
   * Loading funding.
   * @param environment IEnvironmentSettingsItem object.
   */
  private async loadFunding(environment: IEnvironmentSettingsItem) {
    try {
      this.setLoadingState(true);

      this.fundingSettings = await this._marketDataService.loadFunding(
        this._analyticsPresenter.asHelper.sessionId,
        this._analyticsPresenter.asHelper.lwsIdentifier,
        environment.id
      );

      this._analyticsPresenter.updateModel({
        eventId: AnalyticsSettingsEvents.FundingUpdated,
        data: this.fundingSection
      });
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Checks if funding is valid.
   * @returns A flag indicating if funding is valid.
   */
  private isFundingValid(): boolean {
    // nothing to validate.
    return true;
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  private setLoadingState(isLoading: boolean) {
    this.envSettings.setLoadingState(isLoading);
    this._presenter.setLoadingState(isLoading);

    this._changeDetectorRef.detectChanges();
  }
}
