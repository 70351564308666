<div class="terms-summary">
  <lv-subscriber-panel
  [state]="state"
  (didReceiveMessage)="onMessageReceived($event)"
  (didStateChange)="onStateChange($event)">
    <ng-container header>
      <lv-instrument-search
        [instrumentName]="state.instrumentName"
        (didSelectInstrument)="onInstrumentSelect($event)">
      </lv-instrument-search>
    </ng-container>
    <ng-container content>
      <instrument-authorization *ngIf="notAuthorized"
        [instrumentName]="state.instrumentName">
      </instrument-authorization>

      <lv-basic-terms *ngIf="!notAuthorized"
        [instrumentInfo]="instrumentInfo"
        (didNotAuthorized)="onNotAuthorized($event)">
      </lv-basic-terms>
    </ng-container>
  </lv-subscriber-panel>
</div>
