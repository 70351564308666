import { LvLookupEnum } from '@lv-core-ui/util';
import { InterestRateSource, InterestRateSourceDescription, YieldCurveSource } from '@lv-analytics/models/market-data/interest-rates/interest-rates-enum';
import { IInterestRate } from '@lv-analytics/models/market-data/interest-rates/interest-rate';
import { YieldCurveTermsStructureSettings } from '@lv-analytics/models/market-data/yield-curve/yield-curve-terms-structure-settings';
import { ITenor } from '@lv-analytics/models/market-data/tenor';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { ISystemYieldCurveInfo } from '@lv-analytics/models/market-data/yield-curve/system-yield-curve-info';

/**
 * Interest rates view.
 */
export class InterestRatesView {

  get isCustomInstrumentYieldSource(): boolean {
    return this.interestRatesSettings.instrumentYieldCurveSource === YieldCurveSource.Custom;
  }

  get isCustomUnderlyingYieldSource(): boolean {
    return this.interestRatesSettings.underlyingYieldCurveSource === YieldCurveSource.Custom;
  }

  get isInstrumentInterestRateSourceFlat(): boolean {
    return this.interestRatesSettings.instrumentInterestRateSource === InterestRateSource.Flat;
  }

  get isUnderlyingInterestRateSourceFlat(): boolean {
    return this.interestRatesSettings.underlyingInterestRateSource === InterestRateSource.Flat;
  }

  get isCrossFx(): boolean {
    return this._analayticsPresenter.cHelper.isCrossFx;
  }

  interestRatesSettings: IInterestRate;

  instrumentYieldCurveSettings: YieldCurveTermsStructureSettings;
  underlyingYieldCurveSettings: YieldCurveTermsStructureSettings;

  instrumentInterestRatesSource: LvLookupEnum;
  underlyingInterestRatesSource: LvLookupEnum;
  instrumentYieldCurve: ISystemYieldCurveInfo[];
  underlyingYieldCurve: ISystemYieldCurveInfo[];

  private _tenors: ITenor[];

  constructor(
    private _analayticsPresenter: LvAnalyticsPresenter
  ) {
    this.instrumentInterestRatesSource = new LvLookupEnum(InterestRateSourceDescription);
    this.underlyingInterestRatesSource = new LvLookupEnum(InterestRateSourceDescription);
    this.instrumentYieldCurve = [];
    this.underlyingYieldCurve = [];

    this._tenors = [];
    this.interestRatesSettings = {} as IInterestRate;
  }

  /**
   * Initializes interst rates settings.
   * @param settings IInterestRate object.
   * @param envId Environment ID.
   */
  initInterestRatesSettings(settings: IInterestRate, envId: string) {
    this.interestRatesSettings = settings || {} as IInterestRate;
    this.initTermStructureSettings(envId);
  }

  /**
   * Initializes term structure settings.
   * @param environmentId Environment ID.
   */
  initTermStructureSettings(environmentId: string) {
    this.setInstrumentYieldCurveTermsStructureSettings(environmentId);
    this.setUnderlyingYieldCurveTermsStructureSettings(environmentId);
  }

  /**
   * Initializes tenors.
   * @param tenors List of ITenor objects.
   */
  initTenors(tenors: ITenor[]) {
    this._tenors = tenors;
  }

  /**
   * Initializes available system yield curves.
   * @param availableInstrumentSystemYielCurves List of ISystemYieldCurveInfo objects.
   * @param availableUnderlyingSystemYielCurves List of ISystemYieldCurveInfo objects.
   */
  initAvailableSystemYieldCurves(availableInstrumentSystemYielCurves: ISystemYieldCurveInfo[],
                                 availableUnderlyingSystemYielCurves: ISystemYieldCurveInfo[]) {
    this.instrumentYieldCurve = [];
    availableInstrumentSystemYielCurves.forEach(item => {
      item.name = item.name + ' - ' + item.leversysId;
      this.instrumentYieldCurve.push(item);
    });

    this.instrumentYieldCurve.push({
      id: -1,
      name: 'Custom'
    } as ISystemYieldCurveInfo);

    if (this.isCrossFx) {
      this.underlyingYieldCurve = [];
      availableUnderlyingSystemYielCurves.forEach(item => {
        item.name = item.name + ' - ' + item.leversysId;
        this.underlyingYieldCurve.push(item);
      });

      this.underlyingYieldCurve.push({
        id: -1,
        name: 'Custom'
      } as ISystemYieldCurveInfo);
    }
  }

  /**
   * Sets instrument yield curve terms structure settings.
   * @param environmentId Environment ID.
   */
  setInstrumentYieldCurveTermsStructureSettings(environmentId: string) {
    if (this.isInstrumentInterestRateSourceFlat) {
      return;
    }

    this.instrumentYieldCurveSettings = new YieldCurveTermsStructureSettings();
    this.instrumentYieldCurveSettings.curveId = this.interestRatesSettings.yieldCurveId;
    this.instrumentYieldCurveSettings.source = this.interestRatesSettings.instrumentYieldCurveSource;
    this.instrumentYieldCurveSettings.setInstrumentTypeTitle(this._analayticsPresenter.cHelper.currencyCode);
    this.instrumentYieldCurveSettings.environmentId = environmentId;
    this.instrumentYieldCurveSettings.tenors = this._tenors;
  }

  /**
   * Set underlying yield curve terms structure settings.
   * @param environmentId Environment ID.
   */
  setUnderlyingYieldCurveTermsStructureSettings(environmentId: string) {
    if (this.isUnderlyingInterestRateSourceFlat) {
      return;
    }

    this.underlyingYieldCurveSettings = new YieldCurveTermsStructureSettings();
    this.underlyingYieldCurveSettings.curveId = this.interestRatesSettings.underlyingYieldCurveId;
    this.underlyingYieldCurveSettings.source = this.interestRatesSettings.underlyingYieldCurveSource,
    this.underlyingYieldCurveSettings.setInstrumentTypeTitle(this._analayticsPresenter.cHelper.underlyingCurrencyCode);
    this.underlyingYieldCurveSettings.environmentId = environmentId;
    this.underlyingYieldCurveSettings.tenors = this._tenors;
  }
}
