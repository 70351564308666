<lv-flex-box class="lv-convertible-bond-terms-calls">
  <lv-flex-box direction="row" class="lv-convertible-bond-terms-calls-top-options" data-cy="DM-1227">
    <lv-flex-box direction="row" data-cy="DM-392">
      <div class="lv-label lv-label-field lv-label-callable"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="callableSourceTooltip"
        filter="span:not(lv-xl-label span)">
        <span>
          Callable
        </span>
        <lv-xl-label [field]="'CALLABLE'"></lv-xl-label>
      </div>
      <div class="lv-field lv-field--checkbox lv-checkbox-left">
        <input
          class="k-checkbox"
          type="checkbox"
          name="calls-callable"
          [id]=view.callableId
          [(ngModel)]="view.issueAndRedemption.isCallable"
          (ngModelChange)="onCallChanged()" />
        <label
        lvId="callableCheckbox"
        class="k-checkbox-label"
        [for]="view.callableId"></label>
      </div>
    </lv-flex-box>
    <lv-flex-box class="lv-clean-up-margin" direction="row" data-cy="DM-1299">
      <div class="lv-label lv-label-field lv-label-clean-up"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="cleanUpCallSourceTooltip"
        filter="span">
        <span>
          Clean-Up Call
        </span>
      </div>
      <div class="lv-field lv-field--checkbox lv-checkbox-left">
        <input
          class="k-checkbox"
          type="checkbox"
          name="calls-clean-up-call"
          [attr.id]="view.cleanUpCallId"
          [(ngModel)]="view.issueAndRedemption.isCleanUpCall"
          (ngModelChange)="onCleanUpCallChanged()" />
        <label class="k-checkbox-label" [attr.for]="view.cleanUpCallId"></label>
      </div>
    </lv-flex-box>
  </lv-flex-box>
  <lv-flex-box class="lv-call-fieldset" *ngIf="view.issueAndRedemption.isCallable">
    <lv-fieldset-panel legend="CALL PARAMETERS">
      <lv-flex-box direction="row">
        <lv-flex-box class="lv-call-first-col">
          <lv-flex-box direction="row" data-cy="DM-927">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="callValueTypeSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Call Value Type
              </span>
              <lv-xl-label [field]="'CALL_VALUE_TYPE'"></lv-xl-label>
            </div>
            <div class="lv-input-field lv-input-field--auto">
              <kendo-dropdownlist
                name="callValueType"
                [data]="view.callValueTypeLookup.items"
                valueField="id"
                textField="text"
                [valuePrimitive]="true"
                [popupSettings]="{ width: 216 }"
                [(ngModel)]="view.call.valueType"
                (ngModelChange)="onCallValueTypeChanges()">
              </kendo-dropdownlist>
            </div>
          </lv-flex-box>
          <lv-flex-box *ngIf="view.isYieldFieldVisible" direction="row" data-cy="DM-928">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="callYieldFrequencySourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Call Yield Frequency
              </span>
              <lv-xl-label [field]="'CALL_YLD_FRQ'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <kendo-dropdownlist
                name="callYieldFrequency"
                [data]="view.callYieldFrequencyLookup.items"
                valueField="id"
                textField="text"
                [valuePrimitive]="true"
                [(ngModel)]="view.call.yieldFrequency"
                (ngModelChange)="onModelChange()">
              </kendo-dropdownlist>
            </div>
          </lv-flex-box>
          <lv-flex-box *ngIf="view.isYieldFieldVisible" direction="row" data-cy="DM-929">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="callYieldDayCountSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Call Yield Day Count
              </span>
              <lv-xl-label [field]="'CALL_DAY_COUNT'"></lv-xl-label>
            </div>
            <div class="lv-input-field">
              <kendo-dropdownlist
                name="callYieldDayCount"
                [data]="view.callYieldDayCountLookup.items"
                valueField="id"
                textField="text"
                [valuePrimitive]="true"
                [(ngModel)]="view.call.yieldDayCount"
                (ngModelChange)="onModelChange()">
              </kendo-dropdownlist>
            </div>
          </lv-flex-box>
        </lv-flex-box>
        <lv-flex-box>
          <lv-flex-box direction="row" *ngIf="!view.isZeroCouponType" data-cy="DM-930">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="keepAccruedRedemptionSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                {{keepAccruedRedemptionLabel}}
              </span>
              <lv-xl-label [field]="'CALL_KEEP_ACCRD_RDMP'"></lv-xl-label>
            </div>
            <div class="lv-field lv-field--checkbox">
              <input
                class="k-checkbox"
                type="checkbox"
                [attr.id]="view.keepAccruedRedemptionId"
                [(ngModel)]="view.call.keepAccruedRedemption"
                (ngModelChange)="onModelChange()">
              <label class="k-checkbox-label" [attr.for]="view.keepAccruedRedemptionId"></label>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" *ngIf="!view.isZeroCouponType" data-cy="DM-933">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="keepAccruedConversionSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                {{keepAccruedConversionLabel}}
              </span>
              <lv-xl-label [field]="'CALL_KEEP_ACCRD_CONV'"></lv-xl-label>
            </div>
            <div class="lv-field lv-field--checkbox">
              <input
                class="k-checkbox"
                type="checkbox"
                [attr.id]="view.keepAccruedConversionId"
                [(ngModel)]="view.call.keepAccruedConversion"
                (ngModelChange)="onModelChange()">
              <label
                class="k-checkbox-label"
                lvId="keepAccruedConversionCheckBox"
                [attr.for]="view.keepAccruedConversionId"
              ></label>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" *ngIf="!view.isZeroCouponType" data-cy="DM-931">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="forfeitCouponSourceTooltip"
              filter="span:not(lv-xl-label span)">
              <span>
                Forfeit Coupon
              </span>
              <lv-xl-label [field]="'CALL_FRFT_CPN'"></lv-xl-label>
            </div>
            <div class="lv-field lv-field--checkbox">
              <input
                class="k-checkbox"
                type="checkbox"
                [attr.id]="view.forfeitCouponId"
                [(ngModel)]="view.call.forfeitCoupon"
                (ngModelChange)="onModelChange()">
              <label class="k-checkbox-label" [attr.for]="view.forfeitCouponId"></label>
            </div>
          </lv-flex-box>
        </lv-flex-box>
      </lv-flex-box>
      <lv-flex-box class="lv-call-trigger-section">
        <lv-flex-box>
          <div class="lv-label lv-label-field lv-label--title">CALL TRIGGER</div>
        </lv-flex-box>
        <lv-flex-box direction="row" class="lv-convertible-bond-terms-calls-bottom-row">
          <lv-flex-box class="lv-call-first-col">
            <lv-flex-box direction="row" data-cy="DM-914">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="triggerTypeSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Trigger Type
                </span>
                <lv-xl-label [field]="'CALL_TRGGR_TYPE'"></lv-xl-label>
              </div>
              <div class="lv-input-field lv-input-field--auto">
                <kendo-dropdownlist
                  [data]="view.triggerTypeLookup.items"
                  valueField="id"
                  lvId="callTriggerType"
                  textField="text"
                  [valuePrimitive]="true"
                  [popupSettings]="{ width: 199 }"
                  [(ngModel)]="view.call.softCall.triggerType"
                  (ngModelChange)="onModelChange()">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>
            <lv-flex-box direction="row" class="lv-input-field" data-cy="DM-915">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="currencyOfTriggerSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Currency of Trigger
                </span>
                <lv-xl-label [field]="'CALL_TRGGR_CCY'"></lv-xl-label>
              </div>
              <div class="lv-input-field">
                <kendo-dropdownlist
                  [data]="view.currencyOfTriggerLookup.items"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.call.softCall.currencyOfTrigger"
                  (ngModelChange)="onModelChange()">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>
            <lv-flex-box direction="row" class="lv-input-field" data-cy="DM-917">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="triggerPeriodTypeSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Trigger Period Type
                </span>
                <lv-xl-label [field]="'CALL_TRGGR_PERIOD_TYPE'"></lv-xl-label>
              </div>
              <div class="lv-input-field lv-input-field--auto">
                <kendo-dropdownlist
                  [data]="view.triggerPeriodTypeLookup.items"
                  lvId="triggerPeriodTypeCall"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [popupSettings]="{ width: 199 }"
                  [(ngModel)]="view.call.softCall.triggerPeriodType"
                  (ngModelChange)="onModelChange()">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>
          </lv-flex-box>
          <lv-flex-box>
            <lv-flex-box direction="row" data-cy="DM-918">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="triggerPeriodDaysSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Trigger Period Days
                </span>
                <lv-xl-label [field]="'CALL_TRGGR_DAYS'"></lv-xl-label>
              </div>
              <lv-numeric-text-box
                name="triggerPeriodDays"
                [format]="view.numberZeroFormat"
                [decimals]="view.numberOfZeroDecimals"
                [(ngModel)]="view.call.softCall.triggerPeriodDays"
                (didBlur)="onModelChange()"
                (didKeyDownEnter)="onModelChange()">
              </lv-numeric-text-box>
            </lv-flex-box>
            <lv-flex-box direction="row" data-cy="DM-919">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="outOfDaysSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Out of Days
                </span>
                <lv-xl-label [field]="'CALL_TRGGR_OUT_OF_DAYS'"></lv-xl-label>
              </div>
              <lv-numeric-text-box
                name="outOfDays"
                [format]="view.numberZeroFormat"
                [decimals]="view.numberOfZeroDecimals"
                [(ngModel)]="view.call.softCall.outOfDays"
                (didBlur)="recalculateReadOnlyDates()"
                (didKeyDownEnter)="recalculateReadOnlyDates()">
              </lv-numeric-text-box>
            </lv-flex-box>
            <lv-flex-box direction="row" data-cy="DM-916">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="includeCashInCallTriggerSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  {{includeCashLabel}}
                </span>
                <lv-xl-label [field]="'CALL_TRGGR_EXCG_PROP'"></lv-xl-label>
              </div>
              <div class="lv-field lv-field--checkbox">
                <input
                  class="k-checkbox"
                  type="checkbox"
                  [attr.id]="view.includeCacheInCallTrigger"
                  [(ngModel)]="view.call.softCall.callTriggerBasedOnFullExchgProperty"
                  (ngModelChange)="onModelChange()">
                <label class="k-checkbox-label" lvId="includeCashInCallTrigger" [attr.for]="view.includeCacheInCallTrigger"></label>
              </div>
            </lv-flex-box>
          </lv-flex-box>
        </lv-flex-box>
      </lv-flex-box>
      <lv-flex-box class="lv-call-trigger-section">
        <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="callScheduleTooltip"
        filter=".lv-label"
        class="lv-label lv-label-field lv-label--title">CALL SCHEDULE</div>
      </lv-flex-box>
      <lv-flex-box direction="row">
        <lv-calls-schedule #callSchedule
          [valueType]="view.call.valueType"
          [model]="view.call.scheduleItems"
          (didCallsScheduleChange)="recalculateReadOnlyDates()">
        </lv-calls-schedule>
      </lv-flex-box>
      <lv-flex-box class="lv-call-trigger-section">
        <lv-flex-box>
          <div class="lv-label lv-label-field lv-label--title">CALL NOTICE</div>
        </lv-flex-box>
        <lv-flex-box direction="row" class="lv-convertible-bond-terms-calls-bottom-row">
          <lv-flex-box class="lv-call-first-col">
            <lv-flex-box direction="row" class="lv-input-field" data-cy="DM-920">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="callNoticeDaysSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Call Notice Days (min)
                </span>
                <lv-xl-label [field]="'CALL_NTC_MIN_DAYS'"></lv-xl-label>
              </div>
                <lv-numeric-text-box
                  name="callNoticeDays"
                  [format]="view.numberZeroFormat"
                  [decimals]="view.numberOfZeroDecimals"
                  [(ngModel)]="view.call.notice"
                  (didBlur)="onModelChange()"
                  (didKeyDownEnter)="onModelChange()">
                </lv-numeric-text-box>
            </lv-flex-box>
            <lv-flex-box direction="row" class="lv-input-field" data-cy="DM-921">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="callNoticeDaysMaxSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Call Notice Days (max)
                </span>
                <lv-xl-label [field]="'CALL_NTC_MAX_DAYS'"></lv-xl-label>
              </div>
                <lv-numeric-text-box
                  name="callNoticeDaysMax"
                  [format]="view.numberZeroFormat"
                  [decimals]="view.numberOfZeroDecimals"
                  [(ngModel)]="view.call.callNoticeDaysMax"
                  (didBlur)="recalculateReadOnlyDates()"
                  (didKeyDownEnter)="recalculateReadOnlyDates()">
                </lv-numeric-text-box>
            </lv-flex-box>
            <lv-flex-box direction="row" data-cy="DM-922">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="callNoticeDaysTypeSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Call Notice Days (type)
                </span>
                <lv-xl-label [field]="'CALL_NTC_DAYS_TYPE'"></lv-xl-label>
              </div>
              <div class="lv-input-field">
                <kendo-dropdownlist
                  name="callNoticeBusinessCalendar"
                  [data]="view.callNoticeLookup.items"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.call.noticePeriod"
                  (ngModelChange)="recalculateReadOnlyDates()">
                </kendo-dropdownlist>
            </div>
            </lv-flex-box>
            <lv-flex-box direction="row" class="lv-input-field" data-cy="DM-923">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="considerationDaysMaxSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Consideration Days (max)
                </span>
                <lv-xl-label [field]="'CALL_NTC_CNSDR_DAYS'"></lv-xl-label>
              </div>
                <lv-numeric-text-box
                  name="considerationDaysMax"
                  [format]="view.numberZeroFormat"
                  [decimals]="view.numberOfZeroDecimals"
                  [(ngModel)]="view.call.considerationDaysMax"
                  (didBlur)="recalculateReadOnlyDates()"
                  (didKeyDownEnter)="recalculateReadOnlyDates()">
                </lv-numeric-text-box>
            </lv-flex-box>
            <lv-flex-box direction="row" data-cy="DM-924">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="considerationDaysBusinessCalendarTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Consideration Days (type)
                </span>
                <lv-xl-label [field]="'CALL_NTC_CNSDR_DAYS_TYPE'"></lv-xl-label>
              </div>
              <div class="lv-input-field">
                <kendo-dropdownlist
                  name="considerationDaysBusinessCalendar"
                  [data]="view.callNoticeLookup.items"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [(ngModel)]="view.call.considerationDaysBusinessCalendar"
                  (ngModelChange)="recalculateReadOnlyDates()">
                </kendo-dropdownlist>
            </div>
            </lv-flex-box>
          </lv-flex-box>
          <lv-flex-box>
            <lv-flex-box
              data-cy="DM-1301"
              direction="row"
              class="lv-call-readonly-date">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="callFirstCallConsiderationDateTooltip"
                filter="span">
                <span>
                  First Call Consideration Date
                </span>
              </div>
              <lv-datepicker
                class="lv-datepicker-readonly"
                name="callFirstCallConsiderationDate"
                [datePickerLvId] = "dpCallFirstCallConsiderationDatePickerId"
                [(ngModel)]="view.call.callFirstCallConsiderationDate"
                [checked]="false">
              </lv-datepicker>
            </lv-flex-box>
            <lv-flex-box
              data-cy="DM-1302"
              direction="row"
              class="lv-call-readonly-date">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="callTriggerObservationStartDateTooltip"
                filter="span">
                <span>
                  Call Trigger Obs. Start Date
                </span>
              </div>
              <lv-datepicker
                class="lv-datepicker-readonly"
                name="callTriggerObservationStartDate"
                [datePickerLvId] = "dpCallTriggerObservationStartDatePickerId"
                [(ngModel)]="view.call.callTriggerObservationStartDate"
                [checked]="false">
              </lv-datepicker>
            </lv-flex-box>
            <lv-flex-box direction="row" data-cy="DM-925">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="callNoticeGivenSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Call Notice Given
                </span>
                <lv-xl-label [field]="'CALL_NTC_GVN'"></lv-xl-label>
              </div>
              <div class="lv-field lv-field--checkbox">
                <input class="k-checkbox"
                  type="checkbox"
                  [attr.id]="view.noticeGivenId"
                  [(ngModel)]="view.call.callNoticeGiven"
                  (ngModelChange)="onCallNoticeGivenChange()" />
                <label class="k-checkbox-label" [attr.for]="view.noticeGivenId"></label>
              </div>
            </lv-flex-box>
            <lv-flex-box direction="row" *ngIf="view.call.callNoticeGiven" data-cy="DM-926">
              <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="callDateSourceTooltip"
                filter="span:not(lv-xl-label span)">
                <span>
                  Call Date
                </span>
                <lv-xl-label [field]="'CALL_DATE'"></lv-xl-label>
              </div>
              <div class="lv-input-field">
                <kendo-datepicker
                  [disabled]="!view.call.callNoticeGiven"
                  [(ngModel)]="view.call.callDate"
                  (ngModelChange)="onModelChange()">
                </kendo-datepicker>
              </div>
            </lv-flex-box>
          </lv-flex-box>
        </lv-flex-box>
      </lv-flex-box>
    </lv-fieldset-panel>
  </lv-flex-box>
  <lv-flex-box class="lv-clean-up-section" *ngIf="view.issueAndRedemption.isCleanUpCall">
    <lv-fieldset-panel
      legend="CLEAN-UP CALL">
      <lv-flex-box direction="row" data-cy="DM-1300">
        <div class="lv-label lv-label-field"
                showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="valuePercentageSourceTooltip"
                filter="span">
                <span>
                  Value (%)
                </span>
              </div>
        <lv-numeric-text-box
          name="value"
          [format]="view.numberFormat"
          [decimals]="view.numberOfDecimalsPercentage"
          [(ngModel)]="view.cleanUpCall.cleanUpCallValue"
          (didBlur)="onCleanUpCallChanged()"
          (didKeyDownEnter)="onCleanUpCallChanged()">
        </lv-numeric-text-box>
      </lv-flex-box>
    </lv-fieldset-panel>
  </lv-flex-box>
</lv-flex-box>

<ng-template #callableSourceTooltip>
  <lv-tooltip dm="dM-392"></lv-tooltip>
</ng-template>
<ng-template #cleanUpCallSourceTooltip>
  <lv-tooltip dm="dM-1299"></lv-tooltip>
</ng-template>
<ng-template #callValueTypeSourceTooltip>
  <lv-tooltip dm="dM-927"></lv-tooltip>
</ng-template>
<ng-template #callNoticeDaysSourceTooltip>
  <lv-tooltip dm="dM-920"></lv-tooltip>
</ng-template>
<ng-template #callNoticeDaysMaxSourceTooltip>
  <lv-tooltip dm="dM-921"></lv-tooltip>
</ng-template>
<ng-template #considerationDaysMaxSourceTooltip>
  <lv-tooltip dm="dM-923"></lv-tooltip>
</ng-template>
<ng-template #callNoticeDaysTypeSourceTooltip>
  <lv-tooltip dm="dM-922"></lv-tooltip>
</ng-template>
<ng-template #considerationDaysBusinessCalendarTooltip>
  <lv-tooltip dm="dM-924"></lv-tooltip>
</ng-template>
<ng-template #callFirstCallConsiderationDateTooltip>
  <lv-tooltip dm="dM-1301"></lv-tooltip>
</ng-template>
<ng-template #callTriggerObservationStartDateTooltip>
  <lv-tooltip dm="dM-1302"></lv-tooltip>
</ng-template>
<ng-template #callNoticeGivenSourceTooltip>
  <lv-tooltip dm="dM-925"></lv-tooltip>
</ng-template>
<ng-template #callDateSourceTooltip>
  <lv-tooltip dm="dM-926"></lv-tooltip>
</ng-template>
<ng-template #callYieldFrequencySourceTooltip>
  <lv-tooltip dm="dM-928"></lv-tooltip>
</ng-template>
<ng-template #callYieldDayCountSourceTooltip>
  <lv-tooltip dm="dM-929"></lv-tooltip>
</ng-template>
<ng-template #keepAccruedRedemptionSourceTooltip>
  <lv-tooltip dm="dM-930"></lv-tooltip>
</ng-template>
<ng-template #keepAccruedConversionSourceTooltip>
  <lv-tooltip dm="dM-933"></lv-tooltip>
</ng-template>
<ng-template #forfeitCouponSourceTooltip>
  <lv-tooltip dm="dM-931"></lv-tooltip>
</ng-template>
<ng-template #includeCashInCallTriggerSourceTooltip>
  <lv-tooltip dm="dM-916"></lv-tooltip>
</ng-template>
<ng-template #triggerTypeSourceTooltip>
  <lv-tooltip dm="dM-914"></lv-tooltip>
</ng-template>
<ng-template #currencyOfTriggerSourceTooltip>
  <lv-tooltip dm="dM-915"></lv-tooltip>
</ng-template>
<ng-template #triggerPeriodTypeSourceTooltip>
  <lv-tooltip dm="dM-917"></lv-tooltip>
</ng-template>
<ng-template #triggerPeriodDaysSourceTooltip>
  <lv-tooltip dm="dM-918"></lv-tooltip>
</ng-template>
<ng-template #outOfDaysSourceTooltip>
  <lv-tooltip dm="dM-919"></lv-tooltip>
</ng-template>
<ng-template #valuePercentageSourceTooltip>
  <lv-tooltip dm="dM-1300"></lv-tooltip>
</ng-template>
<ng-template #callScheduleTooltip>
  <lv-tooltip dm="dM-932"></lv-tooltip>
</ng-template>

<ng-template #callTooltipTemplate let-anchor>
  <div class="popup-call-content">

  </div>
</ng-template>

