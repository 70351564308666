<div class="lv-documents">
  <lv-loader *ngIf="isLoading"></lv-loader>
  <table class="lv-table">
    <thead>
      <tr>
        <th>Document Type</th>
        <th>Language</th>
        <th>Date</th>
        <th>Notes</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let docMeta of documentsMetadata; let i = index">
        <td [class.link]="canViewDocument" (click)="onSelectDocument(docMeta)">{{ docMeta.documentType }}</td>
        <td>{{ docMeta.language }}</td>
        <td>{{ docMeta.dateUpdated | lvLocalDate }}</td>
        <td class="width30">{{ docMeta.notes }}</td>
      </tr>
    </tbody>
  </table>

  <div class="lv-documents-contribute">
    <button kendoButton
      *ngIf="canContributeDocument"
      [disabled]="isLoading"
      [primary]="true"
      (click)="onContributeDocument()">
        Contribute Document
    </button>
  </div>
</div>
