import { GridsterConfig } from 'angular-gridster2';

export class DefaultGridsterConfigFactory {
  static getDefaultGristerConfig(): GridsterConfig {
    return {
      displayGrid: 'none',
      disableWindowResize: true,
      mobileBreakpoint: 0,
      keepFixedHeightInMobile: true,
      draggable: {
        enabled: false // enabled on edit
      },
      resizable: {
        enabled: false, // enabled on edit
        handles: {
          e: false,
          n: false,
          ne: false,
          nw: false,
          s: false,
          se: true,
          sw: false,
          w: false
        }
      },
      margin: 1,
      outerMargin: true,
      maxCols: 2000,
      maxRows: 2000,
      gridType: 'fixed',
      fixedColWidth: 10,
      fixedRowHeight: 10,

      defaultItemRows: 20,
      defaultItemCols: 20,
      minItemRows: 10,
      minItemCols: 20,
      maxItemCols: 150,
      maxItemRows: 150,
      maxItemArea: 20000,

      pushItems: false, // enabled on edit
      disablePushOnDrag: true,
      pushDirections: {
        east: true,
        north: true,
        south: true,
        west: true
      },
      swap: false
    };
  }
}
