



export class PriceTalk {
    premiumBest: number;
    premiumWorst: number;
    couponBest: number;
    couponWorst: number;
    issueYieldBest: number;
    issueYieldWorst: number;
    redemptionValueBest: number;
    redemptionValueWorst: number;
    higherStrikePremiumBest: number;
    higherStrikePremiumWorst: number;
    spreadBest: number;
    spreadWorst: number;
    issuePriceBest: number;
    issuePriceWorst: number;
}

