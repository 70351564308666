import { ChangeDetectorRef, ElementRef, Injectable, OnDestroy } from '@angular/core';
import { ResizeHandlerService } from '@lv-core-ui/services';
import { TabStripComponent } from '@progress/kendo-angular-layout';

/**
 * This class hanles resize on tabstrip component
 * and manage whitch class should be used in style calculation
 */
@Injectable()
export class LvConvertibleBondTermsUtil implements OnDestroy {

  public hasconvertibleBondTermsTwoRows: boolean;
  public hasconvertibleBondTermsThreeRows: boolean;
  public hasconvertibleBondTermsFourRows: boolean;
  public hasconvertibleBondTermsFiveRows: boolean;
  public hasconvertibleBondTermsSixRows: boolean;
  public hasconvertibleBondTermsSevenRows: boolean;
  public hasconvertibleBondTermsEightRows: boolean;
  public listening: boolean;
  public listenerId: string;

  private _element: TabStripComponent;
  private _elementRef: ElementRef;
  private _changeDetectorRef: ChangeDetectorRef;
  private _resizeHandlerService: ResizeHandlerService;


  constructor(resizeHandlerService: ResizeHandlerService, element: TabStripComponent, changeDetectorRef: ChangeDetectorRef) {
    this._elementRef = (element as any).wrapper;
    this._changeDetectorRef = changeDetectorRef;
    this._resizeHandlerService = resizeHandlerService;
    this._element = element;

    this.hasconvertibleBondTermsTwoRows = false;
    this.hasconvertibleBondTermsThreeRows = false;
    this.hasconvertibleBondTermsFourRows = false;
    this.hasconvertibleBondTermsFiveRows = false;
    this.hasconvertibleBondTermsSixRows = false;
    this.hasconvertibleBondTermsSevenRows = false;
    this.hasconvertibleBondTermsEightRows = false;
  }

  /**
   * Start listening to resize event
   */
  startListening() {
    this._resizeHandlerService.listenTo(this._elementRef, (elementRect, listenerId) => {
      this.listenerId = listenerId;
      this.listening = true;

      const termsElements = Array.from(document.getElementsByClassName('lv-convertible-bond-terms'));
      const nativeElement = this._elementRef.nativeElement;
      const nativeElementIndex = termsElements.findIndex(x => x === nativeElement);

      if (nativeElementIndex < 0) {
        return;
      }

      let rows = 0;
      let top = 0;

      const elements = termsElements[nativeElementIndex].getElementsByTagName('ul')[0].getElementsByTagName('li');


      for (let i = 0; i < elements.length; i++) {
        const elementPosition = elements[i].getBoundingClientRect();
        if (top !== elementPosition.top) {
          rows++;
          top = elementPosition.top;
        }
      }

      switch (rows) {
        case 1: {
          this.hasconvertibleBondTermsTwoRows = false;
          this.hasconvertibleBondTermsThreeRows = false;
          this.hasconvertibleBondTermsFourRows = false;
          this.hasconvertibleBondTermsFiveRows = false;
          this.hasconvertibleBondTermsSixRows = false;
          this.hasconvertibleBondTermsSevenRows = false;
          this.hasconvertibleBondTermsEightRows = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
        case 2: {
          this.hasconvertibleBondTermsTwoRows = true;
          this.hasconvertibleBondTermsThreeRows = false;
          this.hasconvertibleBondTermsFourRows = false;
          this.hasconvertibleBondTermsFiveRows = false;
          this.hasconvertibleBondTermsSixRows = false;
          this.hasconvertibleBondTermsSevenRows = false;
          this.hasconvertibleBondTermsEightRows = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
        case 3: {
          this.hasconvertibleBondTermsThreeRows = true;
          this.hasconvertibleBondTermsTwoRows = false;
          this.hasconvertibleBondTermsFourRows = false;
          this.hasconvertibleBondTermsFiveRows = false;
          this.hasconvertibleBondTermsSixRows = false;
          this.hasconvertibleBondTermsSevenRows = false;
          this.hasconvertibleBondTermsEightRows = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
        case 4: {
          this.hasconvertibleBondTermsFourRows = true;
          this.hasconvertibleBondTermsTwoRows = false;
          this.hasconvertibleBondTermsThreeRows = false;
          this.hasconvertibleBondTermsFiveRows = false;
          this.hasconvertibleBondTermsSixRows = false;
          this.hasconvertibleBondTermsSevenRows = false;
          this.hasconvertibleBondTermsEightRows = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
        case 5: {
          this.hasconvertibleBondTermsFiveRows = true;
          this.hasconvertibleBondTermsTwoRows = false;
          this.hasconvertibleBondTermsThreeRows = false;
          this.hasconvertibleBondTermsFourRows = false;
          this.hasconvertibleBondTermsSixRows = false;
          this.hasconvertibleBondTermsSevenRows = false;
          this.hasconvertibleBondTermsEightRows = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
        case 6: {
          this.hasconvertibleBondTermsSixRows = true;
          this.hasconvertibleBondTermsTwoRows = false;
          this.hasconvertibleBondTermsThreeRows = false;
          this.hasconvertibleBondTermsFourRows = false;
          this.hasconvertibleBondTermsFiveRows = false;
          this.hasconvertibleBondTermsSevenRows = false;
          this.hasconvertibleBondTermsEightRows = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
        case 7: {
          this.hasconvertibleBondTermsSevenRows = true;
          this.hasconvertibleBondTermsTwoRows = false;
          this.hasconvertibleBondTermsThreeRows = false;
          this.hasconvertibleBondTermsFourRows = false;
          this.hasconvertibleBondTermsFiveRows = false;
          this.hasconvertibleBondTermsSixRows = false;
          this.hasconvertibleBondTermsEightRows = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
        case 8: {
          this.hasconvertibleBondTermsEightRows = true;
          this.hasconvertibleBondTermsTwoRows = false;
          this.hasconvertibleBondTermsThreeRows = false;
          this.hasconvertibleBondTermsFourRows = false;
          this.hasconvertibleBondTermsFiveRows = false;
          this.hasconvertibleBondTermsSixRows = false;
          this.hasconvertibleBondTermsSevenRows = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
        default: {
          this.hasconvertibleBondTermsTwoRows = false;
          this.hasconvertibleBondTermsThreeRows = false;
          this.hasconvertibleBondTermsFourRows = false;
          this.hasconvertibleBondTermsFiveRows = false;
          this.hasconvertibleBondTermsSixRows = false;
          this.hasconvertibleBondTermsSevenRows = false;
          this.hasconvertibleBondTermsEightRows = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
      }
    });
  }

  stopListening() {
    this._resizeHandlerService.removeListener(this.listenerId);
  }

  ngOnDestroy() {
    this.stopListening();
  }
}
