<div class="workspace-panel">
  <div class="panel-items">
    <workspace *ngFor="let a of items"
      [id]="a.id"
      [name]="a.name"
      [selected]="a.selected"
      (doSelect)="onSelect($event)"
      (didEdit)="onEdit($event)"
      (doClose)="onWorkspaceClose($event)">
    </workspace>
    <div class="lv-icon-square"
      (click)="onAdd()">
      <i class="k-icon k-i-plus"></i>
    </div>
    <div class="lv-icon-square"
    (click)="onReorder()">
      <i class="k-icon k-i-edit"></i>
    </div>
  </div>
  <div class="panel-spacer"></div>
  <div class="panel-item-content">
    <router-outlet></router-outlet>
  </div>
</div>
