import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { LvError, ILvError } from '../../models/lv-error/base';
import { LvErrorType } from '../../models/lv-error/error-type';

@Injectable()
export class LvErrorService {

  authenticationError: Subject<LvError>;
  unexpectedError: Subject<Error>;
  closeWorkspace: Subject<boolean>;

  constructor(
    public toastrService: ToastrService
  ) {
    this.authenticationError = new Subject<LvError>();
    this.unexpectedError = new Subject<Error>();
    this.closeWorkspace = new Subject<boolean>();
  }

  handleError(error: LvError, processError: (error: LvError) => void = null) {
    switch (error.type) {
      case LvErrorType.APPLICATION:
      case LvErrorType.NOT_FOUND:
      case LvErrorType.USER_FRIENDLY:
      case LvErrorType.AUTHORIZATION:
      case LvErrorType.FORBIDDEN: {
        if (processError) {
          processError(error);
        }
        else {
          this.toastrService.error(error.message, error.name);
        }

        break;
      }
      case LvErrorType.AUTHENTICATION:
      case LvErrorType.CONNECTION: {
        if (processError) {
          processError(error);
        }

        this.authenticationError.next(error);

        break;
      }
      case LvErrorType.CONFLICT: {
        if (processError) {
          processError(error);
        }

        break;
      }

      default: {
        this.unexpectedError.next(error);
        break;
      }
    }
  }

  toJSON(error: LvError): ILvError {
    return {
      name: error.name,
      message: error.message,
      type: error.type,
      displayMessage: this.prettify(error)
    };
  }

  prettify(error: LvError): string {
    return `${error.name}: ${error.message}`;
  }
}
