import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input,
         Output, EventEmitter, ChangeDetectorRef, OnChanges, Optional } from '@angular/core';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { ICreditAssetParameters } from '@lv-analytics/models/market-data/credit';
import { LvExcelService } from '@lv-excel/services';

/**
 * Asset parameters component.
 */
@Component({
  selector: 'lv-asset-parameters',
  templateUrl: './lv-asset-parameters.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvAssetParametersComponent implements OnInit, OnChanges {

  @Input() assetParameters: ICreditAssetParameters;

  @Output() doSaveSession: EventEmitter<void>;

  currency: string;

  constructor(
    private _analyticsPresenter: LvAnalyticsPresenter,
    private _changeDetectorRef: ChangeDetectorRef,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.doSaveSession = new EventEmitter<void>();
    this.currency = '';
  }

  get hasCreditAssetParametersOverridedFromExcel(): boolean {
    return !!this._excelSvc?.hasCreditAssetParametersOverridedFromExcel();
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Occures on changes.
   */
  ngOnChanges() {
    this.currency = this._analyticsPresenter.cHelper.underlyingCurrencyCode;
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occures on asset parameters change.
   */
  onAssetParametersChange() {
    this.doSaveSession.emit();
  }
}
