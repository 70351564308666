import { LvUserFriendlyError } from '@lv-core-ui/models';

/**
 * Market data user friendly error class
 */
export class LvMarketDataError extends LvUserFriendlyError {
  constructor(message: string) {
    super(message, 'Market Data');
  }
}
