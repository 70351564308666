import { Subject } from 'rxjs';

import { ILvNotifierState, LvNotifier, ILvNotificationEvent, ILvNotificationEventType, LvNotifierType } from './lv-notifier';
import { LvNotificationManagerService } from './lv-notification-manager.service';

export interface ILvSubscriberState extends ILvNotifierState {
  id: string;
  type: LvNotifierType;
  color?: string;
  linkedPublisher?: string;
}

export class LvSubscriber extends LvNotifier {

  linkedPublisher?: string;

  publisherReadyToLink: Subject<ILvNotificationEvent>;
  publisherReadyToLinkCanceled: Subject<ILvNotificationEvent>;
  publisherCanceled: Subject<ILvNotificationEvent>;

  constructor(lvNotificationManagerService: LvNotificationManagerService) {
    super(lvNotificationManagerService);

    this.type = 'subscriber';

    this.linkedPublisher = null;

    this.publisherReadyToLink = new Subject<ILvNotificationEvent>();
    this.publisherReadyToLinkCanceled = new Subject<ILvNotificationEvent>();
    this.publisherCanceled = new Subject<ILvNotificationEvent>();

    this.receive.subscribe(event => {
      switch (event.type) {
        case ILvNotificationEventType.READY_TO_LINK: {
          if (!this.linkedPublisher) {
            this.color = event.from.color;
            this.publisherReadyToLink.next(event);
          }
          break;
        }
        case ILvNotificationEventType.READY_TO_LINK_CANCELED: {
          if (!this.linkedPublisher) {
            this.color = null;
            this.publisherReadyToLinkCanceled.next(event);
          }
          break;
        }
        case ILvNotificationEventType.LINK_CANCELED: {
          if (event.to[this.id] && this.linkedPublisher === event.from.id) {
            this.linkedPublisher = null;
            this.color = null;
            this.publisherCanceled.next(event);
          }
          break;
        }
        case ILvNotificationEventType.MESSAGE: {
          if (event.to[this.id] && this.linkedPublisher === event.from.id) {
            this.messageReceived.next(event);
          }
          break;
        }
      }
    });
  }

  public linkPublisher(publisherId: string) {
    this.linkedPublisher = publisherId;
  }

  public sendAcceptLink(publisherId: string) {
    const to = {
      [publisherId]: true
    };

    this.notify.next({
      type: ILvNotificationEventType.LINK_ACCEPTED,
      from: {
        id: this.id
      },
      to: to
    } as ILvNotificationEvent);

    this.linkedPublisher = publisherId;
  }

  public sendCancelLink() {
    const to = {
      [this.linkedPublisher]: true
    };

    this.linkedPublisher = null;
    this.color = null;

    this.notify.next({
      type: ILvNotificationEventType.LINK_CANCELED,
      from: {
        id: this.id
      },
      to: to
    } as ILvNotificationEvent);
  }
}
