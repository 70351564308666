<lv-advanced-grid
  data-cy="DM-293"
  lvId="gridVolatilityTerm"
  [columns]="columns"
  [records]="volatilityTermStructureSchedule"
  lvId="lvMarketDataVolatilityScheduleGrid"
  [createFormGroup]="createFormGroup"
  [parseFn]="parseFn"
  sortBy="date"
  [showXlLabel]="hasScheduleInExcelOverride"
  [excelFieldAlias]="excelFieldAlias"
  [isFieldFromExcelEnabled]="isFieldFromExcelEnabled">
</lv-advanced-grid>
