<lv-flex-box
  class="lv-estimates-settings-dialog"
  data-cy="DM-4432"
  >
    <lv-flex-box class="lv-estimates-settings-dialog-content">
      <div class="lv-label-field lv-label lv-label--title">
        System Defaults
      </div>
      <lv-flex-box direction="row">

          <lv-estimates-settings-section [estimatesSetup]="estimateSettings.systemDefaults">

          </lv-estimates-settings-section>

      </lv-flex-box>
      <div class="lv-label-field lv-label lv-label--title">
        Environment Defaults
      </div>
    </lv-flex-box>

    <kendo-tabstrip class="lv-pricing-estimates-settings-dialog-tabstrip" #tabstrip
      [keepTabContent]="true">

      <ng-container *ngFor="let tab of estimateSettings.environments">
        <kendo-tabstrip-tab [title]="tab.environmentName"
          [selected]="tab.default">
          <ng-template kendoTabContent>
            <lv-flex-box>

              <div class="lv-field lv-field--checkbox" data-cy="DM-1155">
                <label class="lv-estimates-settings-environment-checkbox-label">
                  Override System Defaults
                </label>
                <input name="overrideSystemDefaultsMyCheckBoxId"
                  class="k-checkbox"
                  type="checkbox"
                  [attr.id]="getOverrideSystemDefaultsMyCheckBoxId(tab.environmentName)"
                  [(ngModel)]="tab.overrideSystemDefaults"/>
                <label class="k-checkbox-label"
                  lvId="lv-estimates-settings-override-system-defaults-my-environment"
                  [attr.for]="getOverrideSystemDefaultsMyCheckBoxId(tab.environmentName)">
                </label>
              </div>
              <lv-estimates-settings-section [estimatesSetup]="tab.settings" [settingsDisabled]="!tab.overrideSystemDefaults">

              </lv-estimates-settings-section>

              <div class="lv-estimates-settings-dialog-separator"></div>

              <div class="lv-field lv-field--checkbox">
                <label class="lv-estimates-settings-environment-checkbox-label">
                  Instrument Level Override
                </label>
                <input name="instrumentLabelOverrideMyCheckBoxId"
                  class="k-checkbox"
                  type="checkbox"
                  [attr.id]="getInstrumentLabelOverrideMyCheckBoxId(tab.environmentName)"
                  [(ngModel)]="tab.instrumentLevelOverride"/>
                <label class="k-checkbox-label"
                  lvId="lv-estimates-settings-override-instrument-level-my-environment"
                  [attr.for]="getInstrumentLabelOverrideMyCheckBoxId(tab.environmentName)">
                </label>
              </div>
              <lv-estimates-settings-section [estimatesSetup]="tab.instrument" [settingsDisabled]="!tab.instrumentLevelOverride">

              </lv-estimates-settings-section>
            </lv-flex-box>
          </ng-template>
        </kendo-tabstrip-tab>
      </ng-container>
    </kendo-tabstrip>

    <lv-flex-box class="lv-pricing-estimates-settings-dialog-footer" direction="row">
      <button kendoButton
        [primary]="true"
        lvId="convertibleValuationOutputsCancel"
        (click)="onCancel()">
          Cancel
      </button>
      <button kendoButton
        [primary]="true"
        lvId="convertibleValuationOutputsSave"
        (click)="onSave()">
          Save
      </button>
    </lv-flex-box>
  </lv-flex-box>
