<lv-section-settings
  [settings]="settings"
  showAfter="1500"
  kendoTooltip
  [tooltipTemplate]="warningsOverrideSystemDefaultsSettingsTooltip"
  filter="div[data-tooltip-id]">
  <ng-template systemDefaultsSettings let-systemSettings>
    <lv-warnings-section-settings [settings]="systemSettings"></lv-warnings-section-settings>
  </ng-template>
  <ng-template environmentSettings let-settings let-overrideSystemDefaults="overrideSystemDefaults">
    <lv-warnings-section-settings
      [settings]="settings"
      [overrideSystemDefaults]="overrideSystemDefaults">
    </lv-warnings-section-settings>
  </ng-template>
</lv-section-settings>

<ng-template #warningsOverrideSystemDefaultsSettingsTooltip let-anchor>
  <ng-container *ngIf="getTootlipId(anchor, 'overrideSystemDefaultsLabel')">
    <lv-tooltip dm="dM-1144"></lv-tooltip>
  </ng-container>
</ng-template>
