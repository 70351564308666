<lv-flex-box class="call-settings-column">
  <div class="lv-label-field lv-label lv-label--title">CALL PARAMETERS</div>

  <lv-flex-box 
    data-cy="DM-1560"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="callValueTypeRegularSourceTooltipTemplate"
      filter="span">
      <span> Call Value Type (Regular) </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="callValueType"
        [disabled]="!overrideDefaults"
        [data]="callValueTypeRegularLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 216 }"
        [(ngModel)]="settings.settings.valueTypeRegular">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1561"
    direction="row">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="callValueTypePepsSourceTooltipTemplate"
      filter="span">
      <span> Call Value Type (PEPS) </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="callValueType"
        [disabled]="!overrideDefaults"
        [data]="callValueTypePepsLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 216 }"
        [(ngModel)]="settings.settings.valueTypePeps">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1562"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="callYieldFrequencySourceTooltipTemplate"
      filter="span">
      <span> Call Yield Frequency </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="callYieldFrequency"
        [data]="callYieldFrequencyLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.yieldFrequency">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1563"
    direction="row"
    class="space-bottom">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="callYieldDayCountSourceTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'callYieldDayCountSourceTooltip'">
        Call Yield Day Count
      </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="callYieldDayCount"
        [data]="callYieldDayCountLookup.items"
        valueField="id"
        textField="text"
        [disabled]="!overrideDefaults"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.yieldDayCount">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <div class="lv-label-field lv-label lv-label--title">CALL TRIGGER</div>

  <lv-flex-box 
    data-cy="DM-1564"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="triggerTypeSourceTooltipTemplate"
      filter="span">
      <span> Trigger Type </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        [data]="triggerTypeLookup.items"
        valueField="id"
        lvId="callTriggerType"
        textField="text"
        [disabled]="!overrideDefaults"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 199 }"
        [(ngModel)]="settings.settings.softCall.triggerType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1565"
    direction="row"
    class="lv-input-field">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="currencyOfTriggerSourceTooltipTemplate"
      filter="span">
      <span> Currency of Trigger </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        [data]="currencyOfTriggerLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.softCall.currencyOfTrigger">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1566"
    direction="row"
    class="lv-input-field">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="triggerPeriodTypeSourceTooltipTemplate"
      filter="span">
      <span> Trigger Period Type </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        [data]="triggerPeriodTypeLookup.items"
        lvId="triggerPeriodTypeCall"
        valueField="id"
        textField="text"
        [disabled]="!overrideDefaults"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 199 }"
        [(ngModel)]="settings.settings.softCall.triggerPeriodType">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
</lv-flex-box>
<lv-flex-box class="call-settings-column">
  <div class="lv-label-field lv-label lv-label--title">CALL NOTICE</div>

  <lv-flex-box 
    data-cy="DM-1567"
    direction="row"
    class="lv-input-field">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="callNoticeDaysSourceTooltipTemplate"
      filter="span">
      <span> Call Notice Days (min) </span>
    </div>
      <lv-numeric-text-box
        name="callNoticeDays"
        [disabled]="!overrideDefaults"
        [format]="numberZeroFormat"
        [decimals]="numberOfZeroDecimals"
        [(ngModel)]="settings.settings.notice">
      </lv-numeric-text-box>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1568"
    direction="row"
    class="lv-input-field">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="callNoticeDaysMaxSourceTooltipTemplate"
      filter="span">
      <span> Call Notice Days (max) </span>
    </div>
      <lv-numeric-text-box
        name="callNoticeDaysMax"
        [disabled]="!overrideDefaults"
        [format]="numberZeroFormat"
        [decimals]="numberOfZeroDecimals"
        [(ngModel)]="settings.settings.callNoticeDaysMax">
      </lv-numeric-text-box>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1569"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="callNoticeDaysTypeSourceTooltipTemplate"
      filter="span">
      <span> Call Notice Days (type) </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="callNoticeBusinessCalendar"
        [data]="callNoticeLookup.items"
        [disabled]="!overrideDefaults"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.noticePeriod">
      </kendo-dropdownlist>
  </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1570"
    direction="row"
    class="lv-input-field">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="considerationDaysMaxSourceTooltipTemplate"
      filter="span">
      <span> Consideration Days (max) </span>
    </div>
      <lv-numeric-text-box
        name="considerationDaysMax"
        [format]="numberZeroFormat"
        [disabled]="!overrideDefaults"
        [decimals]="numberOfZeroDecimals"
        [(ngModel)]="settings.settings.considerationDaysMax">
      </lv-numeric-text-box>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1571"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="considerationDaysBusinessCalendarTooltipTemplate"
      filter="span">
      <span> Consideration Days (type) </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="considerationDaysBusinessCalendar"
        [data]="callNoticeLookup.items"
        valueField="id"
        [disabled]="!overrideDefaults"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="settings.settings.considerationDaysBusinessCalendar">
      </kendo-dropdownlist>
  </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1572"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="keepAccruedConversionSourceTooltipTemplate"
      filter="span">
      <span> Keep Accrued (conversion) </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input
        class="k-checkbox"
        type="checkbox"
        [disabled]="!overrideDefaults"
        [attr.id]="keepAccruedConversionId"
        [(ngModel)]="settings.settings.keepAccruedConversion">
      <label
        class="k-checkbox-label"
        lvId="keepAccruedConversionCheckBox"
        [attr.for]="keepAccruedConversionId"
      ></label>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1573"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="forfeitCouponSourceTooltipTemplate"
      filter="span">
      <span> Forfeit Coupon </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input
        class="k-checkbox"
        type="checkbox"
        [disabled]="!overrideDefaults"
        [attr.id]="forfeitCouponId"
        [(ngModel)]="settings.settings.forfeitCoupon">
      <label class="k-checkbox-label" [attr.for]="forfeitCouponId"></label>
    </div>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1574"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="triggerPeriodDaysSourceTooltipTemplate"
      filter="span">
      <span> Trigger Period Days </span>
    </div>
    <lv-numeric-text-box
      name="triggerPeriodDays"
      [disabled]="!overrideDefaults"
      [format]="numberZeroFormat"
      [decimals]="numberOfZeroDecimals"
      [(ngModel)]="settings.settings.softCall.triggerPeriodDays">
    </lv-numeric-text-box>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1575"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="outOfDaysSourceTooltipTemplate"
      filter="span">
      <span> Out of Days </span>
    </div>
    <lv-numeric-text-box
      name="outOfDays"
      [format]="numberZeroFormat"
      [disabled]="!overrideDefaults"
      [decimals]="numberOfZeroDecimals"
      [(ngModel)]="settings.settings.softCall.outOfDays">
    </lv-numeric-text-box>
  </lv-flex-box>

  <lv-flex-box 
    data-cy="DM-1576"
    direction="row">
    
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="includeCashInCallTriggerSourceTooltipTemplate"
      filter="span">
      <span> Include Cash in Call Trigger </span>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input
        class="k-checkbox"
        type="checkbox"
        [disabled]="!overrideDefaults"
        [attr.id]="includeCacheInCallTrigger"
        [(ngModel)]="settings.settings.softCall.callTriggerBasedOnFullExchgProperty">
      <label class="k-checkbox-label" lvId="includeCashInCallTrigger" [attr.for]="includeCacheInCallTrigger"></label>
    </div>
  </lv-flex-box>
</lv-flex-box>

<ng-template #callValueTypeRegularSourceTooltipTemplate>
  <lv-tooltip dm="dM-1560"></lv-tooltip>
</ng-template>
<ng-template #callValueTypePepsSourceTooltipTemplate>
  <lv-tooltip dm="dM-1561"></lv-tooltip>
</ng-template>
<ng-template #callYieldFrequencySourceTooltipTemplate>
  <lv-tooltip dm="dM-1562"></lv-tooltip>
</ng-template>
<ng-template #callYieldDayCountSourceTooltipTemplate>
  <lv-tooltip dm="dM-1563"></lv-tooltip>
</ng-template>
<ng-template #triggerTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1564"></lv-tooltip>
</ng-template>
<ng-template #currencyOfTriggerSourceTooltipTemplate>
  <lv-tooltip dm="dM-1565"></lv-tooltip>
</ng-template>
<ng-template #triggerPeriodTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1566"></lv-tooltip>
</ng-template>
<ng-template #callNoticeDaysSourceTooltipTemplate>
  <lv-tooltip dm="dM-1567"></lv-tooltip>
</ng-template>
<ng-template #callNoticeDaysMaxSourceTooltipTemplate>
  <lv-tooltip dm="dM-1568"></lv-tooltip>
</ng-template>
<ng-template #callNoticeDaysTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-1569"></lv-tooltip>
</ng-template>
<ng-template #considerationDaysMaxSourceTooltipTemplate>
  <lv-tooltip dm="dM-1570"></lv-tooltip>
</ng-template>
<ng-template #considerationDaysBusinessCalendarTooltipTemplate>
  <lv-tooltip dm="dM-1571"></lv-tooltip>
</ng-template>
<ng-template #keepAccruedConversionSourceTooltipTemplate>
  <lv-tooltip dm="dM-1572"></lv-tooltip>
</ng-template>
<ng-template #forfeitCouponSourceTooltipTemplate>
  <lv-tooltip dm="dM-1573"></lv-tooltip>
</ng-template>
<ng-template #triggerPeriodDaysSourceTooltipTemplate>
  <lv-tooltip dm="dM-1574"></lv-tooltip>
</ng-template>
<ng-template #outOfDaysSourceTooltipTemplate>
  <lv-tooltip dm="dM-1575"></lv-tooltip>
</ng-template>
<ng-template #includeCashInCallTriggerSourceTooltipTemplate>
  <lv-tooltip dm="dM-1576"></lv-tooltip>
</ng-template>
