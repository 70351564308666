<kendo-popup *ngIf="itemsVisible"
  [offset]="offset">
  <ul class="lv-grid-context-menu">
    <ng-container *ngFor="let item of items, trackBy:itemsTrackBy">
      <li *ngIf="item.isVisible()"
        (click)="onItemSelect(item)"
        (mouseenter)="onMouseEnter(item)">
        <ng-template *ngIf="item.template"
          [ngTemplateOutlet]="item.template"
          [ngTemplateOutletContext]="{ item: item }">
        </ng-template>
        <ng-container *ngIf="!item.template">
          <span>{{ item.text }}</span>
        </ng-container>
      </li>
    </ng-container>
  </ul>
</kendo-popup>
