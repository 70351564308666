import { QuickAndFullTermsDocument } from '../../convertible-bond-terms/models/quick-and-full-terms-document';

/**
 * Custom Instrument.
 */
export class CustomInstrument {
  id: number;
  sessionId: string;
  privateInstrumentDocument: QuickAndFullTermsDocument;
  draftId: string;
  originalInstrumentId: string;
  leversysId: string;
}
