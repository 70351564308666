export enum AcquisitionSharesSettlType {
    PrincipalPlusAS = 'PrincipalPlusAS',
    ASPlusAA = 'ASPlusAA',
    PrincipalPlusASASPlusAA = 'PrincipalPlusASASPlusAA'
}

export enum AcquisitionSharesSettlTypeDescription {
    PrincipalPlusAS = 'Principal + AS',
    ASPlusAA = 'AS + AA',
    PrincipalPlusASASPlusAA = 'Principal + AS/AS + AA'
}