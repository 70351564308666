import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef,
   OnDestroy, ViewChild, ViewRef, Input, Optional } from '@angular/core';

import { Subscription, Subject } from 'rxjs';

import { TabStripComponent } from '@progress/kendo-angular-layout';

// tslint:disable-next-line:max-line-length
import { LvConvertibleBondTermsPresenter, IPricingSectionEvent, ConvertibleBondTermsPresenterCommand } from './lv-convertible-bond-terms.presenter';
import { LvCallsComponent } from './lv-calls/lv-calls.component';
import { LvConversionComponent } from './lv-conversion/lv-conversion.component';
import { LvDividendProtectionComponent } from './lv-dividend-protection/lv-dividend-protection.component';
import { LvConvertibleBondTermsUtil } from './lv-convertible-bond-terms.util';
import { ResizeHandlerService } from '@lv-core-ui/services';
import { LvPutsComponent } from './lv-puts/lv-puts.component';
import { QuickAndFullTermsDocument, ConvertibleBondNewIssueDocument, ConvertibleBondTermsEvent, ConvertibleBondTermsSectionEvent,
         PriceTalk, StockPriceReference, SetupStatus, ITermsSectionSettings, SetupStatusQuickTerms, ConvertibleBondTermsDocument } from '@lv-convertible-bond/models';
import { LvIdentifiersComponent } from './lv-identifiers/lv-identifiers.component';
import { LvExcelService } from '@lv-excel/services';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';
import { LvCouponComponent } from './lv-coupon';
import { StockReferenceSignalStore } from './stores/lv-stock-reference.store';

@Component({
  selector: 'lv-convertible-bond-terms',
  templateUrl: './lv-convertible-bond-terms.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    LvConvertibleBondTermsPresenter
  ]
})
export class LvConvertibleBondTermsComponent implements OnInit, OnDestroy {

  @ViewChild('tabStrip', { static: true }) tabStrip: TabStripComponent;
  @ViewChild('callComponent', { static: false }) callComponent: LvCallsComponent;
  @ViewChild('couponComponent', { static: false }) couponComponent: LvCouponComponent;
  @ViewChild('putComponent', { static: false }) putComponent: LvPutsComponent;
  @ViewChild('conversionComponent', { static: false }) conversionComponent: LvConversionComponent;
  @ViewChild('dividendProtectionComponent', { static: false }) dividendProtectionComponent: LvDividendProtectionComponent;
  @ViewChild('identifiersComponent', { static: false }) identifiersComponent: LvIdentifiersComponent;

  @Input() isPrivateInstrument: boolean;
  
  get isMakeWholeEnabled() {
    return this._presenter.makeWholeEnabled();
  }

  get isQuickTermsVisible() {
    return this._presenter.quickTermsVisibile() && this.isPrivateInstrument;
  }

  /**
   * Is loaded cb cross fx.
   */
  get isCrossFx() {
    return this._presenter.isCrossFx;
  }

  public isLoading: boolean;
  public lvConvertibleBondTermsUtil: LvConvertibleBondTermsUtil;
  stockReferenceSignalStore: StockReferenceSignalStore;

  private _subscriptions: Subscription[];
  private _resizeHandlerService: ResizeHandlerService;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvConvertibleBondTermsPresenter,
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this._subscriptions = [];
    this.lvConvertibleBondTermsUtil = {} as LvConvertibleBondTermsUtil;
    this._resizeHandlerService = new ResizeHandlerService();
    this.isPrivateInstrument = true;
    this.stockReferenceSignalStore = new StockReferenceSignalStore();
  }

  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  get isDraftFromExcel(): boolean {
    return this.isOpenedFromExcel && !!this._excelSvc?.isIdentifierDraft();
  }

  ngOnInit() {
    this._subscriptions.push(this._presenter.isLoading.subscribe(isLoading => {
      this.isLoading = isLoading;
      this._changeDetectorRef.detectChanges();
    }));

    this.lvConvertibleBondTermsUtil = new LvConvertibleBondTermsUtil(this._resizeHandlerService, this.tabStrip, this._changeDetectorRef);
    this.lvConvertibleBondTermsUtil.startListening();
  }

  executeCommand(command: ConvertibleBondTermsPresenterCommand) {
    this._presenter.executeCommand(command);
  }

  /**
   * Load terms into presenter
   * @param privateDocument Quick and full terms document
   */
  loadTerms(privateDocument: QuickAndFullTermsDocument) {
    this._presenter.load(privateDocument);
    this.stockReferenceSignalStore = new StockReferenceSignalStore();
    this.stockReferenceSignalStore.initializeState(privateDocument.fullTerms?.stockPriceReference?.fixedStockRef, privateDocument.fullTerms.issueAndRedemption.fixedFXRate);
    this.detectChanges();
    this.setActiveTab();
  }

  /**
   * Create and load initial terms
   */
  createTerms(cbTerms: ConvertibleBondNewIssueDocument, settings: ITermsSectionSettings) {
    const privateInstrumentDocument = new QuickAndFullTermsDocument();
    privateInstrumentDocument.fullTerms = new ConvertibleBondNewIssueDocument();
    privateInstrumentDocument.fullTerms = cbTerms;
    privateInstrumentDocument.fixDatesInQuickTermsEntry = true;
    privateInstrumentDocument.identifiers = [];
    
    let status = null;
    if (settings.myDefaults.generalSettings.overrideSystemDefaults) {
      status = settings.myDefaults.generalSettings.settings.status;
    }
    else {
      status = settings.systemDefaults.generalSettings.settings.status;
    }    

    if (status === SetupStatusQuickTerms.Fixed) {
      privateInstrumentDocument.fullTerms.issueAndRedemption.setupStatus = SetupStatus.PartiallyCompleted;
    }
    else {
      privateInstrumentDocument.fullTerms.issueAndRedemption.setupStatus = SetupStatus.NewIssue;
    }  
    
    privateInstrumentDocument.fullTerms.priceTalk.premiumBest = null;
    privateInstrumentDocument.fullTerms.priceTalk.premiumWorst = null;
    
    privateInstrumentDocument.fullTerms.priceTalk.couponBest = null;
    privateInstrumentDocument.fullTerms.priceTalk.couponWorst = null;

    privateInstrumentDocument.fullTerms.priceTalk.issueYieldBest = null;
    privateInstrumentDocument.fullTerms.priceTalk.issueYieldWorst = null;

    this._presenter.load(privateInstrumentDocument);

    this.detectChanges();
    this.setActiveTab();
  }

  /**
   * Create and load initial terms from settings and publish event when settings are loaded
   */
  async loadTermsFromExcel(cbTerms: ConvertibleBondNewIssueDocument) {
    const privateInstrumentDocument = new QuickAndFullTermsDocument();
    privateInstrumentDocument.fullTerms = new ConvertibleBondNewIssueDocument();
    privateInstrumentDocument.fullTerms = cbTerms;
    privateInstrumentDocument.fixDatesInQuickTermsEntry = true;

    const terms = await this._presenter.loadFromExcel(privateInstrumentDocument, true);
    this.stockReferenceSignalStore.initializeState(terms.fullTerms?.stockPriceReference?.fixedStockRef, terms.fullTerms.issueAndRedemption.fixedFXRate);

    this.detectChanges();
    this.setActiveTab();
    this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.TermsSettingsLoaded);
  }

  getQuickAndFullTermsForApi(): QuickAndFullTermsDocument {
    return this._presenter.getQuickAndFullTermsForApi();
  }

  applyGridChanges() {
    this.callComponent.applyScheduleChanges();
    this.putComponent.applyScheduleChanges();
    this.conversionComponent.applyScheduleChanges();
    this.couponComponent.applyScheduleChanges();
    this.dividendProtectionComponent.applyScheduleChanges();
    if (!this.isDraftFromExcel) {
      this.identifiersComponent.applyScheduleChanges();
    }
  }

  getConvertibleBondTermsEvent(): Subject<ConvertibleBondTermsEvent> {
    return this._presenter.getConvertibleBondTermsEvent();
  }

  getPricingSectionUpdated(): Subject<IPricingSectionEvent> {
    return this._presenter.getPricingSectionUpdated();
  }

  ngOnDestroy() {
    this.lvConvertibleBondTermsUtil.stopListening();
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  getMappedExcelFieldsFromExcel() {
    if (!this._excelSvc) {
      return null;
    }

    const fullTermsIssueAndRedemption = this._presenter.getModel().fullTerms.issueAndRedemption;
    this._presenter.termsMapper.createMap(fullTermsIssueAndRedemption.name === 'Draft',
      fullTermsIssueAndRedemption.setupStatus === SetupStatus.NewIssue);
    
    const result = this._presenter.termsMapper.reverseMap(this._excelSvc.getMappedFields('Terms'), 'Terms');

    if (!!this._excelSvc.getFieldValue('CONV_SCHED_RANGE')) {
      
      // If there is override for conversion schedule range, Conversion ratio and price can't be saved in same time.

      result.find(x => x.alias === 'CONV_SCHED_RANGE').value.forEach((element, index) => {
        if (this._excelSvc.getFieldValue('CONV_SCHED_RANGE')[index]) {
          const ratio = this._excelSvc.getFieldValue('CONV_SCHED_RANGE')[index].ratio;
          const price = this._excelSvc.getFieldValue('CONV_SCHED_RANGE')[index].conversionPrice;
  
          // If previous value of schedule item has ratio, both values or both zero values save ratio to excel
          // in other case save conversion price to excel
          element.ratio = !!ratio ? element.ratio : !price ? element.ratio : null;
          element.conversionPrice = !price ? null : !!ratio ? null : element.conversionPrice;
        }
        else {
          element.conversionPrice = null;
        }
      });
    }

    return result; 
  }

  /**
   * Populate service convertible bond terms data from settings
   * @param convertibleBondTermsDocumentFromSettings Convertible bond terms data
   */
  populateServiceConvertibleBondTermsData(
    convertibleBondTermsDocumentFromSettings: ConvertibleBondTermsDocument | ConvertibleBondNewIssueDocument,
    termsSettigns: ITermsSectionSettings
  ): void {
    this._lvConvertibleBondTermsService.setConvertibleBondTermsFromSettings(convertibleBondTermsDocumentFromSettings, termsSettigns);
  }

  /**
   * Set active tab on tabstrip component
   * If instrument has changed code check which tab was selected and remain at the same tab on new instrument
   * If there was no selected tab first one is selected (this happens on first load of tabstrip component)
   */
  private setActiveTab() {
    if (this.tabStrip) {
      const termsTab = this.tabStrip.tabs.find(a => a.selected === true);

      termsTab && !termsTab?.disabled ? this.tabStrip.selectTab(this.tabStrip.tabs.toArray().findIndex(x => x === termsTab)) : setTimeout(() => {
        this.tabStrip.selectTab(0);
        this.detectChanges();
      }, 300);

      this.detectChanges();
    }
  }

  private detectChanges() {
    if (!(this._changeDetectorRef as ViewRef).destroyed) {
      this._changeDetectorRef.detectChanges();
    }
  }
}
