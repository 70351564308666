<div class="lv-input-field-component lv-input-field lv-input-field--right">
  <div [style.width]="labelWidth + 'px'" class="lv-input-field-label">
    <div class="lv-input-field-label-content">
      <div class="lv-label">
        {{ label }}
      </div>
      <div class="lv-field lv-field--checkbox" *ngIf="checkbox">
        <input type="checkbox"
          class="k-checkbox"
          tabindex="-1"
          [attr.id]="checkboxId"
          [disabled]="disabled"
          [(ngModel)]="checked"
          (ngModelChange)="onCheckboxChange()"/>
        <label class="k-checkbox-label" [attr.for]="checkboxId"></label>
      </div>
    </div>
  </div>
  <div *ngIf="checked" class="lv-input-field-content">
    <ng-content></ng-content>
  </div>
  <span class="lv-input-field-value" *ngIf="!checked">
    {{inputValue}}
  </span>
  <div *ngIf="suffix" class="lv-input-field-suffix">
    {{ suffix }}
  </div>
</div>
