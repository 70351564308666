import { Injectable } from '@angular/core';

import { IBorrow } from './borrow';
import { IBorrowScheduleItem } from './borrow-schedule-item';
import { IBorrowSaveRequest, IBorrowUpdateRequest } from './borrow-request';
import { DateExtensions } from '@lv-core-ui/util';

/**
 * Borrow mapper.
 */
@Injectable()
export class BorrowMapper {

  /**
   * Maps save request to API.
   * @param borrowSaveRequest IBorrowSaveRequest object.
   * @returns IBorrowSaveRequest object.
   */
  mapSaveRequestToApi(borrowSaveRequest: IBorrowSaveRequest): IBorrowSaveRequest {
    borrowSaveRequest.borrow = this.mapBorrowToApi(borrowSaveRequest.borrow);
    return borrowSaveRequest;
  }

  /**
   * Maps update request to API.
   * @param borrowUpdateRequest IBorrowUpdateRequest object.
   * @returns IBorrowUpdateRequest object.
   */
  mapUpdateRequestToApi(borrowUpdateRequest: IBorrowUpdateRequest): IBorrowUpdateRequest {
    borrowUpdateRequest.borrow = this.mapBorrowToApi(borrowUpdateRequest.borrow);
    return borrowUpdateRequest;
  }

  /**
   * Maps borrow to UI.
   * @param borrowRequest IBorrow object.
   * @returns IBorrow object.
   */
  mapBorrowToUi(borrowRequest: IBorrow): IBorrow {
    const borrowSettings = {
      borrowSource: borrowRequest.borrowSource,
      borrow: borrowRequest.borrow,
      rebateRate: borrowRequest.rebateRate,
      fundingRate: borrowRequest.fundingRate,
      termStructureItems: this.mapTermStructureItemsToUi(borrowRequest.termStructureItems)
    } as IBorrow;

    return borrowSettings;
  }

  /**
   * Maps borrow request to be in form readable to C# code (dates)
   * @param borrow Borrow request
   * @returns mapped request
   */
  mapBorrowToApi(borrow: IBorrow): IBorrow {
    const borrowSettingsRequest = {
      borrowSource: borrow.borrowSource,
      borrow: borrow.borrow,
      rebateRate: borrow.rebateRate,
      fundingRate: borrow.fundingRate,
      termStructureItems: this.mapTermStructureItemsToApi(borrow.termStructureItems)
    } as IBorrow;

    return borrowSettingsRequest;
  }

  /**
   * Maps term structure items to API.
   * @param scheduleItems List of IBorrowScheduleItem objects.
   * @returns List of IBorrowScheduleItem objects.
   */
  private mapTermStructureItemsToApi(scheduleItems?: IBorrowScheduleItem[]): IBorrowScheduleItem[] {
    if (!scheduleItems) {
      return null;
    }

    const scheduleItemsRequest = [] as IBorrowScheduleItem[];
    scheduleItems.forEach(a => {
      const scheduleItemRequest = {
        endDate: DateExtensions.getTimeWithOffset(a.endDate as Date),
        borrowFee: a.borrowFee
      } as IBorrowScheduleItem;
      scheduleItemsRequest.push(scheduleItemRequest);
    });

    return scheduleItemsRequest;
  }

  /**
   * Maps terms structure items to UI.
   * @param scheduleItems List of IBorrowScheduleItem objects.
   * @returns List of IBorrowScheduleItem objects.
   */
  private mapTermStructureItemsToUi(scheduleItems: IBorrowScheduleItem[]): IBorrowScheduleItem[] {
    if (!scheduleItems) {
      return null;
    }

    const scheduleItemsRequest = [] as IBorrowScheduleItem[];
    scheduleItems.forEach(a => {
      const scheduleItemRequest = {
        endDate: DateExtensions.getDateWithoutOffset(a.endDate as number),
        borrowFee: a.borrowFee
      } as IBorrowScheduleItem;
      scheduleItemsRequest.push(scheduleItemRequest);
    });

    return scheduleItemsRequest;
  }
}
