import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { IVolatilitySectionSettings, VolatilityTypeDescription, VolatilitySurfaceRiskyTypeDescription,
         VolatilityType } from '@lv-analytics/models';
import { LvLookupEnum } from '@lv-core-ui/util';

/**
 * Volatility section settings component.
 */
@Component({
  selector: 'lv-volatility-section-settings',
  templateUrl: './lv-volatility-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvVolatilitySectionSettingsComponent implements OnInit {

  @Input() settings: IVolatilitySectionSettings;
  @Input() overrideSystemDefaults: boolean;

  volTypeEnum: LvLookupEnum;
  riskyTypeEnum: LvLookupEnum;

  constructor() {
    this.volTypeEnum = new LvLookupEnum(VolatilityTypeDescription);
    this.riskyTypeEnum = new LvLookupEnum(VolatilitySurfaceRiskyTypeDescription);
    this.overrideSystemDefaults = true;

    this.volTypeEnum.setFilterFn(item => {
      if (item.id === VolatilityType.UpsideDownside) {
        return false;
      }
      return true;
    });
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }
}
