<lv-flex-box class="lv-convertible-bond-terms-estimates">
  <lv-fieldset-panel legend="CONVERSION RATIO ESTIMATE PARAMETERS">
    <lv-flex-box direction="row">
      <lv-flex-box>
          <div class="lv-label lv-label-field lv-label--title">
            CURRENT VALUE
          </div>
          <div class="lv-estimates--padded">
            <lv-flex-box>
              <div class="lv-label lv-label-field lv-label--title">
                SECTION: RESET
              </div>
            </lv-flex-box>
            <div class="lv-estimates--padded">
              <lv-flex-box direction="row">
                <div showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="resettableInitialConversionPriceTooltip"
                  filter=".lv-label"
                  class="lv-label lv-label-field lv-estimates-label">
                  <span>
                    Initial Conversion Price
                  </span>
                </div>
                <div class="lv-estimates-value">
                  <span>{{ view.conversionReset.initialConversionPrice | lvDecimal:'n6' }}</span>
                </div>
              </lv-flex-box>
            </div>
          </div>
          <div class="lv-estimates--padded">
            <lv-flex-box>
              <div class="lv-label lv-label-field lv-label--title">
                SECTION: DIVIDEND PROTECTION
              </div>
            </lv-flex-box>
            <div class="lv-estimates--padded">
              <lv-flex-box
                direction="row"
                data-cy="DM-824">
                <div showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="dvdProtectionCRCapTooltip"
                  filter=".lv-label"
                  class="lv-label lv-label-field lv-estimates-label">
                  <span>
                    Conversion Ratio Cap
                  </span>
                </div>
                <div class="lv-estimates-value">
                  <span>{{view.dividendProtection.conversionRatioAdjustment.conversionRatioCap | lvDecimal:'n6'}}</span>
                </div>
              </lv-flex-box>
              <lv-flex-box
                direction="row"
                data-cy="DM-830">
                <div showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="dvdProtectionMaxPaymentTooltip"
                  filter=".lv-label"
                  class="lv-label lv-label-field lv-estimates-label">
                  <span>
                    Maximum Payment
                  </span>
                </div>
                <div class="lv-estimates-value">
                  <span>{{view.dividendProtection.cashDistribution.maximumPayment | lvDecimal:'n4'}}</span>
                </div>
              </lv-flex-box>
              <lv-flex-box
                direction="row"
                data-cy="DM-814">
                <div showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="dvdProtectionCurrentPeriodTriggerBreachedTooltip"
                  filter=".lv-label"
                  class="lv-label lv-label-field lv-estimates-label">
                  <span>
                    Current Period Trigger Breached
                  </span>
                </div>
                <div class="lv-estimates-value">
                  <div class="lv-field lv-field--checkbox lv-checkbox-left">
                    <input
                      class="k-checkbox"
                      type="checkbox"
                      name="puts-puttable"
                      disabled
                      [checked]="view.dividendProtection.isCurrentPeriodTriggerBreached"
                      id="currentPeriodTriggerBreached"/>
                    <label
                      class="k-checkbox-label"
                      lvId="puttableCheckBox"
                      for="currentPeriodTriggerBreached"></label>
                  </div>
                </div>
              </lv-flex-box>
              <lv-flex-box
                direction="row"
                data-cy="DM-815">
                <div showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="dvdProtectionCurrentThresholdAdjTooltip"
                  filter=".lv-label"
                  class="lv-label lv-label-field lv-estimates-label">
                  <span>
                    Current Threshold Adjustment
                  </span>
                </div>
                <div class="lv-estimates-value">
                  <span>{{view.dividendProtection.currentThresholdAdjustment | lvDecimal:'n4'}}</span>
                </div>
              </lv-flex-box>
              <lv-flex-box
                direction="row"
                data-cy="DM-817">
                <div showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="dvdProtectionCarryForwardPerTooltip"
                  filter=".lv-label"
                  class="lv-label lv-label-field lv-estimates-label">
                  <span>
                    Carried Forward Value (% of CR)
                  </span>
                </div>
                <div class="lv-estimates-value">
                  <span>{{view.dividendProtection.carriedForwardValue | lvDecimal:'n4'}}</span>
                </div>
              </lv-flex-box>
              <lv-flex-box
                direction="row"
                data-cy="DM-831">
                <div showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="dvdProtectionCarryForwardTooltip"
                  filter=".lv-label"
                  class="lv-label lv-label-field lv-estimates-label">
                  <span>
                    Carried Forward Value
                  </span>
                </div>
                <div class="lv-estimates-value">
                  <span>{{view.dividendProtection.cashDistribution.carriedForwardValue | lvDecimal:'n4'}}
                  </span>
                </div>
              </lv-flex-box>
            </div>
          </div>
          <div class="lv-estimates--padded lv-estimates-schedule">
          <lv-flex-box>
            <div showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="dvdProtectionScheduleTooltip"
              filter=".lv-label"
              class="lv-label lv-label-field lv-label--title">
              DIVIDEND PROTECTION SCHEDULE
            </div>
            <lv-advanced-grid
            data-cy="DM-834"
            [editDisabled]="true"
            [columns]="view.columns"
            [records]="view.dividendProtection.schedule"
            [editable]="false"
            [ngClass]="'lv-advanced-grid-stripped'"
            lvId="lvIdentifiersSchedule">
            </lv-advanced-grid>
          </lv-flex-box>
        </div>
      </lv-flex-box>
      <lv-flex-box class="lv-estimates-space">
          <div class="lv-label lv-label-field lv-label--title">
            NEW VALUE
          </div>
          <div class="lv-estimates--padded">
            <lv-flex-box>
              <div class="lv-label lv-label-field lv-label--title">
                SECTION: RESET
              </div>
            </lv-flex-box>
            <div class="lv-estimates--padded">
              <lv-flex-box
                direction="row"
                data-cy="DM-1407">
                <div showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="resettableInitialConversionPriceExpTooltip"
                  filter=".lv-label"
                  class="lv-label lv-label-field lv-estimates-label">
                  <span>
                    Initial Conversion Price
                  </span>
                </div>
                <div class="lv-estimates-value">
                  <span>{{view.expectedCr.initialConversionPrice | lvDecimal:'n6'}}</span>
                </div>
              </lv-flex-box>
            </div>
          </div>
          <div class="lv-estimates--padded">
            <lv-flex-box>
              <div class="lv-label lv-label-field lv-label--title">
                SECTION: DIVIDEND PROTECTION
              </div>
            </lv-flex-box>
            <div class="lv-estimates--padded">
              <lv-flex-box
                direction="row"
                data-cy="DM-1410"
                >
                <div showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="dvdProtectionCRCapExpTooltip"
                  filter=".lv-label"
                  class="lv-label lv-label-field lv-estimates-label">
                  <span>
                    Conversion Ratio Cap
                  </span>
                </div>
                <div class="lv-estimates-value">
                  <span>{{view.expectedCr.conversionRatioCap | lvDecimal:'n6'}}</span>
                </div>
              </lv-flex-box>
              <lv-flex-box
                direction="row"
                data-cy="DM-1409"
                >
                <div showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="dvdProtectionMaxPaymentExpTooltip"
                  filter=".lv-label"
                  class="lv-label lv-label-field lv-estimates-label">
                  <span>
                    Maximum Payment
                  </span>
                </div>
                <div class="lv-estimates-value">
                  <span>{{view.expectedCr.maximumPayment | lvDecimal:'n4'}}</span>
                </div>
              </lv-flex-box>
              <lv-flex-box
                direction="row"
                data-cy="DM-1411">
                <div showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="dvdProtectionCurrentPeriodTriggerBreachedExpTooltip"
                  filter=".lv-label"
                  class="lv-label lv-label-field lv-estimates-label">
                  <span>
                    Current Period Trigger Breached
                  </span>
                </div>
                <div class="lv-estimates-value">
                  <div class="lv-field lv-field--checkbox lv-checkbox-left">
                    <input
                      class="k-checkbox"
                      type="checkbox"
                      name="puts-puttable"
                      disabled
                      [checked]="view.expectedCr.isCurrentPeriodTriggerBreached"
                      id="currentPeriodTriggerBreached"/>
                    <label
                      class="k-checkbox-label"
                      lvId="puttableCheckBox"
                      for="currentPeriodTriggerBreached"></label>
                  </div>
                </div>
              </lv-flex-box>
              <lv-flex-box
                direction="row"
                data-cy="DM-1412"
                >
                <div showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="dvdProtectionCurrentThresholdAdjExpTooltip"
                  filter=".lv-label" class="lv-label lv-label-field lv-estimates-label">
                  <span>
                    Current Threshold Adjustment
                  </span>
                </div>
                <div class="lv-estimates-value">
                  <span>{{view.expectedCr.currentThresholdAdjustment | lvDecimal:'n4'}}</span>
                </div>
              </lv-flex-box>
              <lv-flex-box
                direction="row"
                data-cy="DM-1413"
                >
                <div showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="dvdProtectionCarryForwardPerExpTooltip"
                  filter=".lv-label"
                  class="lv-label lv-label-field lv-estimates-label">
                  <span>
                    Carried Forward Value (% of CR)
                  </span>
                </div>
                <div class="lv-estimates-value">
                  <span>{{view.expectedCr.carriedForwardValue | lvDecimal:'n4'}}</span>
                </div>
              </lv-flex-box>
              <lv-flex-box
                direction="row"
                data-cy="DM-1414"
                >
                <div showAfter="1500"
                  kendoTooltip
                  [tooltipTemplate]="dvdProtectionCarryForwardExpTooltip"
                  filter=".lv-label"
                  class="lv-label lv-label-field lv-estimates-label">
                  <span>
                    Carried Forward Value
                  </span>
                </div>
                <div class="lv-estimates-value">
                  <span>{{view.expectedCr.carriedForwardCashValue | lvDecimal:'n4'}}</span>
                </div>
              </lv-flex-box>
            </div>
          </div>
          <div class="lv-estimates--padded lv-estimates-schedule">
            <lv-flex-box>
              <div showAfter="1500"
                kendoTooltip
                [tooltipTemplate]="dvdProtectionScheduleExpTooltip"
                filter=".lv-label"
                class="lv-label lv-label-field lv-label--title">
                DIVIDEND PROTECTION SCHEDULE
              </div>
              <lv-advanced-grid
                data-cy="DM-1408"
                [editDisabled]="true"
                [columns]="view.columns"
                [records]="view.expectedCr.schedule"
                [editable]="false"
                [ngClass]="'lv-advanced-grid-stripped'"
                lvId="lvIdentifiersSchedule">
              </lv-advanced-grid>
            </lv-flex-box>
          </div>
        </lv-flex-box>
      </lv-flex-box>
  </lv-fieldset-panel>
</lv-flex-box>

<ng-template #resettableInitialConversionPriceTooltip>
  <lv-tooltip dm="dM-857"></lv-tooltip>
</ng-template>
<ng-template #dvdProtectionCRCapTooltip>
  <lv-tooltip dm="dM-824"></lv-tooltip>
</ng-template>
<ng-template #dvdProtectionMaxPaymentTooltip>
  <lv-tooltip dm="dM-830"></lv-tooltip>
</ng-template>
<ng-template #dvdProtectionCurrentPeriodTriggerBreachedTooltip>
  <lv-tooltip dm="dM-814"></lv-tooltip>
</ng-template>
<ng-template #dvdProtectionCurrentThresholdAdjTooltip>
  <lv-tooltip dm="dM-815"></lv-tooltip>
</ng-template>
<ng-template #dvdProtectionCarryForwardPerTooltip>
  <lv-tooltip dm="dM-817"></lv-tooltip>
</ng-template>
<ng-template #dvdProtectionCarryForwardTooltip>
  <lv-tooltip dm="dM-831"></lv-tooltip>
</ng-template>
<ng-template #dvdProtectionScheduleTooltip>
  <lv-tooltip dm="dM-834"></lv-tooltip>
</ng-template>
<ng-template #dvdProtectionScheduleExpTooltip>
  <lv-tooltip dm="dM-1408"></lv-tooltip>
</ng-template>
<ng-template #resettableInitialConversionPriceExpTooltip>
  <lv-tooltip dm="dM-1407"></lv-tooltip>
</ng-template>
<ng-template #dvdProtectionCRCapExpTooltip>
  <lv-tooltip dm="dM-1410"></lv-tooltip>
</ng-template>
<ng-template #dvdProtectionMaxPaymentExpTooltip>
  <lv-tooltip dm="dM-1409"></lv-tooltip>
</ng-template>
<ng-template #dvdProtectionCurrentPeriodTriggerBreachedExpTooltip>
  <lv-tooltip dm="dM-1411"></lv-tooltip>
</ng-template>
<ng-template #dvdProtectionCurrentThresholdAdjExpTooltip>
  <lv-tooltip dm="dM-1412"></lv-tooltip>
</ng-template>
<ng-template #dvdProtectionCarryForwardPerExpTooltip>
  <lv-tooltip dm="dM-1413"></lv-tooltip>
</ng-template>
<ng-template #dvdProtectionCarryForwardExpTooltip>
  <lv-tooltip dm="dM-1414"></lv-tooltip>
</ng-template>
