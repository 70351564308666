import { Injectable } from '@angular/core';

/**
 * Sensitivity analysis panel service.
 */
@Injectable()
export class LvSensitivityAnalysisPanelService {

  private _calculationResults: Map<string, any[]>;

  constructor() {
    this._calculationResults = new Map<string, any[]>();
  }

  /**
   * Sets results.
   * @param scenarioId Scenario ID.
   * @param data Data.
   */
  setResult(scenarioId: string, data: any[]): void {
    this._calculationResults.set(scenarioId, data);
  }

  /**
   * Gets result.
   * @param scenarioId Scenario ID.
   * @returns Calculation result.
   */
  getResult(scenarioId: string): any[] | null {
    if (!this._calculationResults.has(scenarioId)) {
      return null;
    }

    return this._calculationResults.get(scenarioId);
  }

  /**
   * Remove result.
   * @param scenarioId Scenario ID.
   */
  removeResult(scenarioId: string) {
    this._calculationResults.delete(scenarioId);
  }

  /**
   * Invalidates calculation results.
   */
  invalidate() {
    this._calculationResults.clear();
  }
}
