<div class="lv-edit-system-environment-settings-dialog-content">
  <kendo-sortable
    class="lv-sortable-list lv-multi-select-list"
    [class.lv-edit-system-environment-settings-dialog-content--active]="dragInProcces"
    itemClass="lv-edit-system-environment-settings-dialog-content-item"
    activeItemClass="lv-edit-system-environment-settings-dialog-content-item--active"
    [animation] = "true"
    (dragStart)="onDragStart()"
    (dragEnd)="onDragEnd()"
    [kendoSortableBinding]="settings">
    <ng-template let-item="item">
      <div class="k-icon k-i-more-vertical"></div>
      <div class="lv-edit-system-environment-settings-dialog-content-item-label">{{item.name}}</div>
    </ng-template>
  </kendo-sortable>

  <div class="lv-edit-system-environment-settings-dialog-content-checkbox">
    <div class="lv-label-field lv-label">Apply Changes to Existing Instruments</div>
    <div class="lv-edit-system-environment-settings-dialog-content-checkbox-wrapper">
      <input 
        name="lv-edit-system-environment-settings-dialog-checkbox"
        class="k-checkbox"
        type="checkbox"
        [(ngModel)]="applyChangesToExistingInstruments"
        attr.id="lv-edit-system-environment-settings-dialog-checkbox"/>
      <label class="k-checkbox-label"
        lvId="lv-edit-system-environment-settings-dialog-checkbox-lv-id"
        attr.for="lv-edit-system-environment-settings-dialog-checkbox">
      </label>
    </div>
  </div>
</div>
<lv-flex-box 
    class="lv-edit-system-environment-settings-dialog-control-panel"
    direction="row"
    data-cy="DM-2062">
  <button kendoButton
    [primary]="true"
    lvId="cancel-system-environment-order-settings"
    (click)="onCancel()">Cancel</button>
  <button kendoButton
    [primary]="true"
    lvId="save-system-environment-order-setting"
    (click)="onSave()"
    data-cy="DM-2062"
    >Save</button>
</lv-flex-box>