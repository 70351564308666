import { Injectable } from '@angular/core';

import { ConvertiblesService } from './services/convertibles/convertibles.service';
import { ValuationSessionService } from './services/valuation-session/valuation-session.service';
import { ScenarioTemplateService } from './services/sensitivity-analysis/scenario-template/scenario-template.service';
import { ScenarioCalculationService } from './services/sensitivity-analysis/scenario-calculation/scenario-calculation.service';
import { CompanyAndUserSettingsService } from './services/company-settings/company-settings.service';

/**
 * Analytics service.
 */
@Injectable()
export class AnalyticsService {

  public sensitivityAnalysis: any;

  constructor(
    public convertiblesService: ConvertiblesService,
    public valuationSessionService: ValuationSessionService,
    public companySettingsService: CompanyAndUserSettingsService,

    scenarioTemplateService: ScenarioTemplateService,
    scenarioCalculationService: ScenarioCalculationService
  ) {
    this.sensitivityAnalysis = {
      scenarioTemplateService: scenarioTemplateService,
      scenarioCalculationService: scenarioCalculationService
    };
  }

}
