import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';

import { LvScenarioOneDimPriceTalkGridView } from './lv-scenario-one-dim-price-talk-grid.view';
import { ILvScenarioReport } from '../../lv-scenario.view';

/**
 * Scenario one dim price talk grid component.
 */
@Component({
  selector: 'lv-scenario-one-dim-price-talk-grid',
  templateUrl: './lv-scenario-one-dim-price-talk-grid.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvScenarioOneDimPriceTalkGridComponent implements OnChanges {

  @Input() report: ILvScenarioReport;

  view: LvScenarioOneDimPriceTalkGridView;

  constructor() {
    this.view = new LvScenarioOneDimPriceTalkGridView();
    this.view.init();
  }

  /**
   * Does view initialization.
   */
  ngOnChanges() {
    this.view.init(this.report);
  }
}
