import {
  Component, ViewEncapsulation, ChangeDetectionStrategy, ViewChildren, QueryList,
  ChangeDetectorRef, HostBinding, OnInit, OnDestroy, ViewRef, ViewChild, Optional
} from '@angular/core';

import { Subscription } from 'rxjs';
import { LvMarketDataPresenter } from './lv-market-data.presenter';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { LvDataMaster, LvErrorType } from '@lv-core-ui/models';
import { LvErrorService, LvLoggerService } from '@lv-core-ui/services';
import { MarketDataComponentDirective } from '@lv-analytics/directives';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import {
  ConversionSettlement, AnalyticsCommands, AnalyticsSettingsEvents, SaveMarketDataRequest, IReloadMarketDataRequest,
  IDividends, ICredit, IInterestRate, IVolatility, BorrowSource, IBorrow, IFunding, IWitholdingTax, IAveragingData,
  IMarketData
} from '@lv-analytics/models';
import { MarketDataService } from '@lv-analytics/services';
import { LvExcelService } from 'src/app/modules/excel/services/excel-service';
import { LvExcelSaveModalComponent } from '@lv-excel/components/lv-excel-save-modal/lv-excel-save-modal.component';
import { DialogService } from '@progress/kendo-angular-dialog';
import { MarketDataExcelMapper } from '@lv-analytics/helpers/market-data-mapper';
import { IOtherMarketData } from '@lv-analytics/models/market-data/other/other-market-data';

/**
 * Market data component.
 */
@Component({
  selector: 'lv-market-data',
  templateUrl: './lv-market-data.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    LvMarketDataPresenter
  ]
})
export class LvMarketDataComponent implements OnInit, OnDestroy {

  @ViewChildren(MarketDataComponentDirective) marketDataComponents: QueryList<MarketDataComponentDirective>;
  @ViewChild('tabstrip', { static: true }) tabstrip: TabStripComponent;

  get isDisabled(): boolean {
    return this.isLoading || !this._analyticsPresenter.asHelper.instrumentLoaded;
  }

  get shouldDisableAveragingTab(): boolean {
    const _cHelper = this._analyticsPresenter.cHelper;
    return !(_cHelper.isCallable
      ||
      ((_cHelper.conversionSettlement === ConversionSettlement.Cash ||
        _cHelper.conversionSettlement === ConversionSettlement.CashOrShares ||
        _cHelper.conversionSettlement === ConversionSettlement.SharesBasedOnAveraging)
        ||
        _cHelper.useAcquisitionSharesSettlement
      )
      ||
      _cHelper.contingentConversion
      ||
      _cHelper.resettable);
  }

  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  isLoading: boolean;
  isSessionStateChanged: boolean;
  isModelStateChanged: boolean;

  private _subscriptions: Subscription[];
  private _marketDataMapper: MarketDataExcelMapper;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _service: MarketDataService,
    private _presenter: LvMarketDataPresenter,
    private _analyticsPresenter: LvAnalyticsPresenter,
    private _logger: LvLoggerService,
    private _dialogService: DialogService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.isLoading = false;
    this.isSessionStateChanged = false;
    this.isModelStateChanged = false;
    this._marketDataMapper = new MarketDataExcelMapper();
  }

  @HostBinding('class.lv-market-data')
  get isLvMarktetData(): boolean {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this._presenter.isLoading.subscribe(isLoading => this.setLoadingState(isLoading)),

      this._analyticsPresenter.onCommandExecuted.subscribe(command => {
        if (command === AnalyticsCommands.ReloadInstrumentInfo) {

          if (this.shouldDisableAveragingTab) {
            this.tabstrip.selectTab(0);
          }
          this._changeDetectorRef.detectChanges();
        }
      }),

      this._analyticsPresenter.onModelLoading.subscribe(isLoading => this.setLoadingState(isLoading)),

      this._analyticsPresenter.onAnalyticsSettingsUpdated.subscribe(evt => {
        if (evt) {
          this.loadMarketData();
        }
        else {
          this.loadEmptyMarketData();
        }

        this.setModelStateChanged(false);
      }),

      this._analyticsPresenter.onEventPublished.subscribe(event => {
        if (event.eventId === AnalyticsSettingsEvents.ValuationSettingsModelUpdated) {
          this.setModelStateChanged();
        }
      })
    ];

    if (this._analyticsPresenter.isModelLoaded()) {
      this.loadMarketData();
    }
  }

  /**
   * Occurs on market data save all option.
   */
  async onMarketDataSaveAll(shouldSaveMarketData: boolean = true) {
    try {
      this._analyticsPresenter.validateBeforeSave();
      if (!this.isDisabled) {
        this.setLoadingState(true);

        const saveMarketDataRequest = new SaveMarketDataRequest();
        saveMarketDataRequest.isPrivateInstrument = this._analyticsPresenter.asHelper.valuationSession.isPrivateInstrument;
        saveMarketDataRequest.sessionId = this._analyticsPresenter.sessionId;
        saveMarketDataRequest.lwsIdentifier = this._analyticsPresenter.asHelper.lwsIdentifier;

        this.marketDataComponents.forEach(d => saveMarketDataRequest.applyCurrentChanges(d.marketDataComponent));
        this.marketDataComponents.forEach(d => saveMarketDataRequest.applyMarketDataSection(d.marketDataComponent));
        const saveMarketDataResult = await this._service.saveMarketData(saveMarketDataRequest);
        this._analyticsPresenter.updateMarketData(saveMarketDataResult, shouldSaveMarketData);
        this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1819'));
        this.setModelStateChanged(false);
        this.setSectionStateChanged(false);
        return saveMarketDataResult;
      }
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on reload all.
   */
  async onReloadAll() {

    try {
      if (!this.isDisabled) {
        this.setLoadingState(true);

        await this._analyticsPresenter.reloadMarketData();

        const mData = this._analyticsPresenter.marketData;

        this._analyticsPresenter.updateModel({
          eventId: AnalyticsSettingsEvents.MarketDataUpdated,
          data: mData
        });

        this.setModelStateChanged(false);
        this.setSectionStateChanged(false);
      }
    }
    catch (error) {
      this._errorService.handleError(error, e => {
        if (e.type === LvErrorType.CONFLICT) {
          this._logger.logError(e);
        }
      });
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  public setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
    if (!(this._changeDetectorRef as ViewRef).destroyed) {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Sets section state changed flag.
   * @param sessionStateChange A flag indicating if session state is changed.
   */
  setSectionStateChanged(sessionStateChange: boolean = true) {
    this.isSessionStateChanged = sessionStateChange;
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Sets model state changed flag.
   * @param modelStateChange A flag indicating if model state is changed.
   */
  setModelStateChanged(modelStateChange: boolean = true) {
    this.isModelStateChanged = modelStateChange;
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Loads market data.
   */
  private async loadMarketData() {
    try {
      this.setLoadingState(true);

      const mData = this._analyticsPresenter.marketData;
      this._marketDataMapper.setIsExchangeable(this._analyticsPresenter.cHelper.isExchangeable);
      this._marketDataMapper.init(mData);

      this._analyticsPresenter.updateModel({
        eventId: AnalyticsSettingsEvents.MarketDataUpdated,
        data: mData
      });
    }
    catch (error) {
      this._errorService.handleError(error, e => {
        if (e.type === LvErrorType.CONFLICT) {
          this._logger.logError(e);
        }
      });
    }
    finally {
      this.setSectionStateChanged(false);
      this.setLoadingState(false);
    }
  }

  /**
   * Loads empty market data.
   */
  loadEmptyMarketData() {
    this._analyticsPresenter.updateModel({
      eventId: AnalyticsSettingsEvents.MarketDataUpdated,
      data: {
        dividends: {
          dividendParameters: {}
        } as IDividends,
        credit: {
          assetParameters: {},
          issuerCreditParameters: {},
          underlyingCreditParameters: {},
          equityLinkedCredit: {},
          stochasticCreditModel: {},
          underlyingEquityLinkedCredit: {},
          underlyingStochasticCreditModel: {}
        } as ICredit,
        interestRates: {} as IInterestRate,
        volatility: {
          volatilityTermStructureSchedule: [],
          volatilitySurface: {}
        } as IVolatility,
        borrow: {
          borrowSource: BorrowSource.Flat
        } as IBorrow,
        funding: {} as IFunding,
        witholdingTax: {} as IWitholdingTax,
        averagingData: {} as IAveragingData,
        other: {} as IOtherMarketData
      } as IMarketData,
    });
  }

  /**
   * Occurs on market data saving all in Excel.
   */
  onMarketDataSaveAllExcel() {
    const dialogRef = this._dialogService.open({
      title: 'Save Destination',
      content: LvExcelSaveModalComponent,
      width: 240,
      height: this._analyticsPresenter.isInstrumentDraft ? 90 : 180
    });

    dialogRef.dialog.location.nativeElement.classList.add('lv-pricing-save-excel');

    const dialog = dialogRef.content.instance as LvExcelSaveModalComponent;
    dialog.isTermsSave = this._analyticsPresenter.isInstrumentDraft;

    dialog.doSaveToApplication.subscribe(() => {
      this.onMarketDataSaveAll();
    });

    dialog.doSaveToAll.subscribe(async () => {
      const result = await this.onMarketDataSaveAll(false);
      this.saveDataToExcel();
      this._analyticsPresenter.updateMarketData(result);
    });

    dialog.doSaveToExcel.subscribe(() => {
      this.saveDataToExcel();
    });
  }

  /**
   * Saves data to Excel.
   */
  private async saveDataToExcel() {
    const fields = this._excelSvc.getMappedFields('Market Data');
    const field = this._excelSvc.getField('ISSUE_STK_REF_CBCCY');
    const stockRef = this._excelSvc.getFieldValue('ISSUE_STK_REF_CBCCY');

    if (field && !stockRef) {
      fields.push({
        key: field.alias,
        value: field.value,
        editable: field.editable
      });
    }

    const saveMarketDataRequest = new SaveMarketDataRequest();

    this.marketDataComponents.forEach(d => saveMarketDataRequest.applyCurrentChanges(d.marketDataComponent));
    this.marketDataComponents.forEach(d => saveMarketDataRequest.applyMarketDataSection(d.marketDataComponent));

    this._marketDataMapper.setInterestRates(saveMarketDataRequest.interestRates.instrumentTermsStructure,
      saveMarketDataRequest.interestRates.underlyingTermsStructure);

    this._analyticsPresenter.overwriteMarketdataExcelOriginalData();

    const allSystemCurves = await this._service.GetAllSystemYieldCurves();
    this._marketDataMapper.setSystemYieldCurves(allSystemCurves);
    this._marketDataMapper.createMap();

    this._marketDataMapper.setIsExchangeable(this._analyticsPresenter.cHelper.isExchangeable);

    const fieldsToSave = this._marketDataMapper.getFields(fields, 'Market Data');

    await this._excelSvc.saveDataToExcelV2(fieldsToSave,
      this._analyticsPresenter.sessionId,
      this._analyticsPresenter.getInstrumentIdentifier(),
      'MarketData',
      null,
      false,
      this._analyticsPresenter.cHelper.isCrossFx,
      true);
    }
}
