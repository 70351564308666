import { Subscription, Subject, Observable, timer } from 'rxjs';

import { environment } from 'src/environments/environment';

import { IApplicationSettings } from '../models/application-settings';
import { SettingsService } from '../services/settings/settings.service';
import { ILvError } from '@lv-core-ui/models';
import { LocalStorage, LvUtil } from '@lv-core-ui/util';
import { LvSharedDefaultWidgetStateService } from '@lv-shared/services';

/**
 * Application settings state class
 */
export abstract class State {

  private _subscriptions: Subscription[];

  private _lastSyncTime: Date;
  private _syncTask: Observable<number>;

  private _stateVersion: string;
  private _stateInitialized: boolean;

  public didError: Subject<ILvError>;

  get appInstanceId() {
    return this._applicationInstanceId;
  }

  get stateVersion(): string {
    return this._stateVersion;
  }

  constructor(
    private _settingsService: SettingsService,
    private _defaultWidgetStateService: LvSharedDefaultWidgetStateService,
    private _applicationInstanceId: string
  ) {
    this._syncTask = timer(3000, 5000);

    this._stateInitialized = false;

    this.didError = new Subject<ILvError>();

    this._subscriptions = [
      this._syncTask
        .subscribe(
          async () => {
            try {
              const timeFrame = 5000; // ms
              const offset = new Date(Date.now() - timeFrame);
              if (this._stateInitialized && this._lastSyncTime > offset) {
                await this.saveState(this.getState());
              }
            }
            catch (error) {
              this.processError(error);
            }
          }
        ),

      LocalStorage.doClearLocalStorage
        .subscribe(
          () => {
            localStorage.removeItem(environment.applicationSettings.stateKey);
            localStorage.removeItem(environment.applicationSettings.versionKey);
          }
        )
    ];
  }

  /**
   * Unsubscribe from all subscriptions
   */
  stopStateUpdates() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Initialize state
   * @param {boolean} isOpenedFromExcel is application opened from excel
   * @returns promise based boolean value
   */
  async initialize(isOpenedFromExcel: boolean): Promise<boolean> {
    try {
      // TODO: Remove this check after internal component
      // is merged into the app component.
      if (this._stateInitialized) {
        return true;
      }

      let previousState = LvUtil.jsonParse(localStorage.getItem(environment.applicationSettings.stateKey));

      if (previousState === null) {
        previousState = await this.loadState();
        localStorage.setItem(environment.applicationSettings.stateKey, LvUtil.jsonStringify(previousState));
      }
      else {
        this._stateVersion = localStorage.getItem(environment.applicationSettings.versionKey);
      }

      await this._defaultWidgetStateService.loadDefaultWidgetStates(isOpenedFromExcel);

      this._stateInitialized = true;
    }
    catch (error) {
      this.processError(error);
    }

    return this._stateInitialized;
  }

  /**
   * Get application settings state
   * @returns IApplicationSettings object
   */
  getState(): IApplicationSettings {
    return LvUtil.jsonParse(localStorage.getItem(environment.applicationSettings.stateKey));
  }

  /**
   * Sets application settings state into local storage
   * @param update Callback function that accepts IApplicationSettings as argument
   */
  setState(update: (state: IApplicationSettings) => void) {
    try {
      const currentState = this.getState();
      update(currentState);

      localStorage.setItem(environment.applicationSettings.stateKey, LvUtil.jsonStringify(currentState));

      // reset last sync time to fire timer event
      this._lastSyncTime = new Date();
    }
    catch (error) {
      this.processError(error);
    }
  }

  /**
   * Lifecycle hook that is triggered immediately before service is destroyed
   */
  protected doDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Save application settings state
   * @param {IApplicationSettings} state application settings state
   */
  protected async saveState(state: IApplicationSettings): Promise<void> {
    try {
      const response = await this._settingsService.saveApplicationState({
        sourceId: this._applicationInstanceId,
        version: this.stateVersion,
        state: state,
        userId: LocalStorage.getJwtUser().UserId.toString()
      });

      this.setApplicationSettingsVersion(response.version);
    }
    catch (error) {
      LocalStorage.clear();
      throw error;
    }
  }

  /**
   * Load application settings state from database
   * @returns Promise based IApplicationSettings object
   */
  private async loadState(): Promise<IApplicationSettings> {
    try {
      const response = await this._settingsService.getApplicationState();

      this.setApplicationSettingsVersion(response.version);

      return response.state;
    }
    catch (error) {
      throw error;
    }
  }

  /**
   * Sets application settings version
   * @param {string} version Application settings version
   */
  private setApplicationSettingsVersion(version: string) {
    this._stateVersion = version;
    localStorage.setItem(environment.applicationSettings.versionKey, version);
  }

  /**
   * Processes thrown error
   * @param {ILvError} error Error object
   */
  private processError(error: ILvError) {
    this.didError.next(error);
  }
}
