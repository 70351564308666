<lv-flex-box class="lv-premium-compensation">
  <lv-flex-box direction="row" data-cy="DM-1427">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="compensationFormulaSourceTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'compensationFormulaSourceTooltip'">
        Compensation Formula
      </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        name="premiumCompensationFormulaType"
        [data]="premiumCompensationFormulaTypeLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [popupSettings]="{ width: 405 }"
        [(ngModel)]="model.premiumCompensationFormulaType"
        (ngModelChange)="doModelChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box *ngIf="isPremiumCompensationInitialPremiumPercentVisible" direction="row" data-cy="DM-1428">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="initialPremiumSourceTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'initialPremiumSourceTooltip'">
        Initial Premium
      </span>
    </div>   
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="premiumCompensationInitialPremiumPercent"
        [format]="numberFormat"
        [decimals]="numberOfDecimals"
        [(ngModel)]="model.premiumCompensationInitialPremiumPercent"
        (didBlur)="doModelChange()"
        (didKeyDownEnter)="doModelChange()">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">%</span>
    </div>    
  </lv-flex-box>
  <lv-flex-box *ngIf="isPremiumCompensationReferencePriceCBCCYVisible"
    direction="row" data-cy="DM-1429">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="referencePriceCBCCYSourceTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'referencePriceCBCCYSourceTooltip'">
        Reference Price (CB CCY)
      </span>
    </div>     
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="premiumCompensationReferencePriceCBCCY"
        [format]="numberFormat"
        [decimals]="numberOfDecimals"
        [(ngModel)]="model.premiumCompensationReferencePriceCBCCY"
        (didBlur)="doModelChange()"
        (didKeyDownEnter)="doModelChange()">
      </lv-numeric-text-box>
    </div>    
  </lv-flex-box>
  <lv-flex-box *ngIf="isPremiumCompensationInitialParityCBCCYVisible" direction="row" data-cy="DM-1434">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="initialParityCBCCYSourceTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'initialParityCBCCYSourceTooltip'">
        Initial Parity (CB CCY)
      </span>
    </div>    
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="premiumCompensationInitialParityCBCCY"
        [format]="numberFormat"
        [decimals]="numberOfDecimals"
        [(ngModel)]="model.premiumCompensationInitialParityCBCCY"
        (didBlur)="doModelChange()"
        (didKeyDownEnter)="doModelChange()">
      </lv-numeric-text-box>
    </div>   
  </lv-flex-box>
  <lv-flex-box *ngIf="isDaysBeforeMaturityVisible" direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="daysBeforeMaturityCTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'daysBeforeMaturityCTooltip'">
        Days before Maturity (C)
      </span>
    </div>
    <div class="lv-input-field" data-cy="DM-2405">
      <lv-numeric-text-box
        name="premiumCompensationDaysBeforeMaturityC"
        [format]="numberFormat"
        [decimals]="numberOfDecimals"
        [(ngModel)]="model.premiumCompensationDaysBeforeMaturityC"
        (didBlur)="doModelChange()"
        (didKeyDownEnter)="doModelChange()">
      </lv-numeric-text-box>
    </div>
    <div class="lv-input-field" data-cy="DM-2406">
      <kendo-dropdownlist
        name="premiumCompensationDaysBeforeMaturityCCalendar"
        lvId="premiumCompensationDaysBeforeMaturityCCalendar"
        [data]="averagingPeriodCalendarTypeLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="model.premiumCompensationDaysBeforeMaturityCCalendar"
        (ngModelChange)="doModelChange()">
      </kendo-dropdownlist>
    </div>   
  </lv-flex-box>
  <lv-flex-box *ngIf="isDaysBeforeMaturityVisible" direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="daysBeforeMaturityTTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'daysBeforeMaturityTTooltip'">
        Days before Maturity (T)
      </span>
    </div>    
    <div class="lv-input-field" data-cy="DM-2407">
      <lv-numeric-text-box
        name="premiumCompensationDaysBeforeMaturityT"
        [format]="numberFormat"
        [decimals]="numberOfDecimals"
        [(ngModel)]="model.premiumCompensationDaysBeforeMaturityT"
        (didBlur)="doModelChange()"
        (didKeyDownEnter)="doModelChange()">
      </lv-numeric-text-box>
    </div>
    <div class="lv-input-field" data-cy="DM-2408">
      <kendo-dropdownlist
        name="premiumCompensationDaysBeforeMaturityTCalendar"
        lvId="premiumCompensationDaysBeforeMaturityTCalendar"
        [data]="averagingPeriodCalendarTypeLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="model.premiumCompensationDaysBeforeMaturityTCalendar"
        (ngModelChange)="doModelChange()">
      </kendo-dropdownlist>
    </div>   
  </lv-flex-box>
</lv-flex-box>

<ng-template #compensationFormulaSourceTooltipTemplate>
  <lv-tooltip dm="dM-1427"></lv-tooltip>
</ng-template>
<ng-template #initialPremiumSourceTooltipTemplate>
  <lv-tooltip dm="dM-1428"></lv-tooltip>
</ng-template>
<ng-template #referencePriceCBCCYSourceTooltipTemplate>
  <lv-tooltip dm="dM-1429"></lv-tooltip>
</ng-template>
<ng-template #initialParityCBCCYSourceTooltipTemplate>
  <lv-tooltip dm="dM-1434"></lv-tooltip>
</ng-template>
<ng-template #daysBeforeMaturityCTooltipTemplate>
  <lv-tooltip dm="dM-1435"></lv-tooltip>
</ng-template>
<ng-template #daysBeforeMaturityTTooltipTemplate>
  <lv-tooltip dm="dM-1436"></lv-tooltip>
</ng-template>
