import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, OnDestroy, ViewEncapsulation,
  ChangeDetectionStrategy, Output, EventEmitter, Optional} from '@angular/core';

import { Subscription } from 'rxjs';
import * as _ from 'lodash';

// tslint:disable-next-line:max-line-length
import { LvEnvironmentSettingsComponent, IEnvironmentSettingsItem } from '../../../lv-environment-settings/lv-environment-settings.component';
import { IWitholdingTax } from '../../../../models/market-data/witholding-tax/witholding-tax';
import { MarketDataService } from '../../../../services/market-data/market-data.service';
import { IMarketDataComponent } from '../../market-data-component';
import { LvMarketDataPresenter } from '../lv-market-data.presenter';
import { LvErrorService } from '@lv-core-ui/services';
import { AnalyticsSettingsEvents } from '@lv-analytics/models/enum/analytics-settings-events';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { PricingEnvironmentSections } from '@lv-analytics/models/enum/pricing-environment-sections';
import { IWitholdingTaxSaveRequest } from '@lv-analytics/models';
import { LvExcelService } from '@lv-excel/services';

/**
 * Withholding tax component.
 */
@Component({
  selector: 'lv-withholding-tax',
  templateUrl: './lv-withholding-tax.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvWithholdingTaxComponent implements OnInit, OnDestroy, IMarketDataComponent<IWitholdingTaxSaveRequest> {

  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;

  get isWitholdingTaxSectionDisabled(): boolean {
    return !this._analyticsPresenter.asHelper.instrumentLoaded;
  }

  get cbCcy(): string {
    return this._analyticsPresenter.cHelper.currencyCode;
  }

  get eqCcy(): string {
    return this._analyticsPresenter.cHelper.underlyingCurrencyCode;
  }
  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc && this._excelSvc.isInitialized();
  }
  get dividendNetFactor(): string {
    return this.isOpenedFromExcel ? 'Dvd. Net Factor' : 'Dividend Net Factor';
  }

  @Output() didSessionUpdatedEvent: EventEmitter<void>;

  withholdingTaxSection = PricingEnvironmentSections.WitholdingTax;
  witholdingTaxSectionDisabled: boolean;
  witholdingTaxSettings: IWitholdingTax;
  originalValue: IWitholdingTax;

  numberOfDecimalsPercentage = '2';
  numberFormatPercentage = '#.##';

  private _subscriptions: Subscription[];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _marketDataService: MarketDataService,
    private _presenter: LvMarketDataPresenter,
    private _analyticsPresenter: LvAnalyticsPresenter,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.witholdingTaxSectionDisabled = false;
    this.witholdingTaxSettings = {} as IWitholdingTax;
    this.didSessionUpdatedEvent = new EventEmitter<void>();
   }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this._analyticsPresenter.onModelLoading.subscribe(isLoading => this.setLoadingState(isLoading)),

      this._analyticsPresenter.onModelUpdated.subscribe(evt => {
        if (evt && evt.eventId === AnalyticsSettingsEvents.MarketDataUpdated) {
          this.witholdingTaxSettings = evt.data.marketData.witholdingTax;
          this.originalValue = _.cloneDeep(this.witholdingTaxSettings);
          this._changeDetectorRef.detectChanges();
        }
      })
    ];
  }

  /**
   * Occurs on change environment.
   * @param environment IEnvironmentSettingsItem object.
   */
  onChangedEnvironment(environment: IEnvironmentSettingsItem) {
    this.loadWithHoldingTax(environment);
  }

  /**
   * Occurs on witholding tax change.
   */
  onWitholdingTaxChange() {
    this.overrideWitholdingTax();
  }

  /**
   * Gets selected environment.
   * @returns Environment ID.
   */
  getSelectedEnvironmentId(): string {
    const env = this.envSettings.getSelectedEnvironment();
    return env.id;
  }

  /**
   * Gets settings.
   * @returns IWitholdingTaxSaveRequest object.
   */
  getSettings(): IWitholdingTaxSaveRequest {
    return this.getWitholdingTaxSaveRequest();
  }

  /**
   * Applies current changes.
   */
  applyCurrentChanges() {

  }

  /**
   * Gets section.
   * @returns PricingEnvironmentSections object.
   */
  getSection(): PricingEnvironmentSections {
    return this.withholdingTaxSection;
  }

  /**
   * Gets withholding tax tootlip ID.
   * @param element HTML element.
   * @param sectionId Section ID.
   * @returns Withholding tax tootlip ID.
   */
  getWithholdingTaxTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('withholding-tax-tooltip-id') === sectionId;
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Overrides witholding tax.
   */
  private async overrideWitholdingTax() {
    try {
      await this._marketDataService.overrideWitholdingTax({
        sessionId: this._analyticsPresenter.asHelper.sessionId,
        lwsIdentifier: this._analyticsPresenter.asHelper.lwsIdentifier,
        witholdingTax: this.witholdingTaxSettings
      });

      if (!_.isEqual(this.witholdingTaxSettings, this.originalValue)) {
        this.didSessionUpdatedEvent.next();
        this.originalValue = _.cloneDeep(this.witholdingTaxSettings);
      }
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
    }
  }

  /**
   * Gets witholding tax save request.
   * @returns IWitholdingTaxSaveRequest object.
   */
  private getWitholdingTaxSaveRequest(): IWitholdingTaxSaveRequest {
    const env = this.envSettings.getSelectedEnvironment();

    return {
      environmentId: env.id,
      lwsIdentifier: this._analyticsPresenter.asHelper.lwsIdentifier,
      witholdingTax: this.witholdingTaxSettings
    } as IWitholdingTaxSaveRequest;
  }

  /**
   * Loads with holding tax.
   * @param environment IEnvironmentSettingsItem object.
   */
  private async loadWithHoldingTax(environment: IEnvironmentSettingsItem) {
    try {
      this.setLoadingState(true);

      this.witholdingTaxSettings = await this._marketDataService.loadWitholdingTax(
        this._analyticsPresenter.asHelper.sessionId,
        this._analyticsPresenter.asHelper.lwsIdentifier,
        environment.id
      );
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  private setLoadingState(isLoading: boolean) {
    this.envSettings.setLoadingState(isLoading);
    this._presenter.setLoadingState(isLoading);
    this._changeDetectorRef.detectChanges();
  }
}
