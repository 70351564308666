import { LvDataMaster } from '../lv-data-master';
import { LvError } from './base';
import { LvErrorType } from './error-type';
import { constants } from '../../util/constants';

export class LvAuthorizationError extends LvError {
  constructor(message = LvDataMaster.getError('dM-1952'), name = 'Authorization') {
    super(message, name, LvErrorType.AUTHORIZATION);
  }
}

export class LvAuthenticationError extends LvError {
  constructor(message = constants.errors.unexpectedError, name = 'Authentication') {
    super(message, name, LvErrorType.AUTHENTICATION);
  }
}

export class LvConnectionError extends LvError {
  constructor(message = constants.errors.unexpectedError, name = 'Connection') {
    super(message, name, LvErrorType.CONNECTION);
  }
}
