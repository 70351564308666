import { Injectable } from '@angular/core';
import { WebViewService } from '@lv-core-ui/services/webview2/webview2-service';
import { IField } from '@lv-excel/models/field';
import { IExcelWebViewData } from '@lv-excel/models/excel-webview-data';
// import { WebViewMessageType } from '@lv-core-ui/models/web-view-message';
import { ApiService } from './api-service';
import { LvErrorService, LvLoggerService } from '@lv-core-ui/services';
import { StockRefSaveBackToExcelHelper } from '@lv-excel/helpers/stock-ref-save-back-to-excel.helper';

@Injectable()
export class LvExcelService {
  private _data: IExcelWebViewData = {
    environment: null,
    excelVersion: 'v3',
    fields: [],
    identifier: null
  };
  _isInitialzed: boolean;

  get isCypress(): boolean {
    // when navigator.webDriver is set to true then app is run by playwright.
    return ((window as any).Cypress || (navigator as any).webdriver) !== undefined && sessionStorage.getItem('serviceIsMocked') === 'true';
  }

  constructor(
    private wvService: WebViewService,
    private apiService: ApiService,
    private _errorService: LvErrorService,
    private _logger: LvLoggerService
  ) {
    this._isInitialzed = false;
    wvService.webViewMessage.subscribe(async message => {
      try {
        const input: IExcelWebViewData = message;
        this._isInitialzed = true;
        this._data = {
          identifier: input.identifier,
          environment: input.environment,
          fields: input.fields.length > 0 && message.excelVersion === 'v3' ? await apiService.mapFromApi(input.fields) : input.fields,
          excelVersion: message.excelVersion,
        };
        }
        catch (error) {
          console.log(error);
          this._errorService.handleError(error);
        }
    });

    wvService.postMessage({
      data: 'load',
      type: WebViewMessageType.load
    });

    (async () => {
      await _logger.log(`Is Cypress: ${this.isCypress}`);
    })();

    if (this.isCypress) {
      this._isInitialzed = true;

      (async () => {
        await _logger.log(`Excel service from docker is initialized: ${this._isInitialzed}`);
      })();

      (async () => {
        const data = JSON.parse(sessionStorage.getItem('serviceMockedData'));

        if (data.fields.length > 0) {
          data.fields.forEach(item => {
            if (this._niRangeFields[item.alias] && !!item.value) {
              item.value = item.value[0];
            }
          });

          data.fields = await this.apiService.mapFromApi(data.fields);
        }
        this._data = data;

        await _logger.log(`Mocked data: ${this._data}`);
      })();
    }
  }

  /**
   * @returns true if instrument is draft
   */
  isIdentifierDraft(): boolean {
    if (this._data.identifier && this._data.identifier.toUpperCase() === 'DRAFT') {
      return true;
    }
    return false;
  }

  /**
   * @retuns true if new version of api
   */
  isV3() {
    return this._data ? this._data.excelVersion === 'v3' : null;
  }

  /**
   * @param module Module name
   * @returns List of key value fields from specified data filtered by module name
   */
  getMappedFields(module: string) {
    return this._data.fields.filter(x => x.group === module || (module === 'Terms' && this._pricingNewIssueFields[x.alias])).map(x => ({
      key: x.alias,
      value: x.value,
      editable: x.editable
    }));
  }

  /**
   * Get fields for save to excel.
   * @param module Module name/
   * @returns List of items that will be saved to excel.
   */
  getMappedFieldsForSave(module: string) {
    return this._data.fields.filter(x => x.group === module).map(x => ({
      key: x.alias,
      value: x.value,
      editable: x.editable
    }));
  }

  /**
   * @returns Mapped fields from data that belongs to flat market data section
   */
  getFlatMarketDataFields() {
    return this._data.fields.filter(x => x.group === 'Market Data'
      && x.alias === 'DVD_FLAT').map(x => ({
          key: x.alias,
          value: x.value
        }));
  }

  /**
   * @returns Mapped fields from data that belongs to prices data section
   */
  getPricesDataFields() {
    return this._data.fields.filter(x => x.group === 'Pricing'
      || x.alias === 'EST_USE').map(x => ({
        key: x.alias,
        value: x.value
      }));
  }

  async saveDataToExcelV2(fields: any,
                          sessionId: string,
                          lwsIdentifier: string,
                          section: string,
                          draftId: string,
                          isQuickTermsVisible: boolean = false,
                          isCrossFx: boolean = false,
                          isMarketDataSave: boolean = false) {

    const apiResult = await this.apiService.mapForSave(fields.filter(x => x.editable).map(f => f.key),
      sessionId,
      lwsIdentifier,
      section,
      draftId);

    this.saveDataToExcel(apiResult, true, isQuickTermsVisible, isCrossFx, isMarketDataSave);
  }

  /**
   * Find edited fields in instrument form and send that changed values to excel
   * @param fields All fields that belong to one section
   */
  saveDataToExcel(fields: IField[],
                  mapForV3: boolean = false,
                  isQuickTermsVisible: boolean = true,
                  isCrossFx: boolean = false,
                  isMarketDataSave: boolean = false) {

    this.setVolatilitySourceForSaveToExcel(fields);

    const conversionScheduleCurrent = { ... this.getField('CONV_SCHED_RANGE')};

    /**
     * These fields have sched in their names
     * and when we need to filter that fields because emptz arrays and null values
     * must be sent back to excel we need to care about these fields
     */
    const notSchedFields = ['CPN_USE_STEPUP_SCHED',
      'CPN_FLOAT_DAYS_SCHED',
      'CPN_FLOAT_SPREAD_SCHED',
      'CPN_COPAY_TRGR_SCHED'];

    fields.filter(x =>
      !x.value
      && notSchedFields.findIndex(y => y === x.alias) < 0
      && (x.alias.includes('SCHED')
        || x.alias.includes('RANGE'))).forEach(item => {
          item.value = [];
        });

    const objectFields = fields.filter(x => typeof (x.value) === 'object');

    const nullFields = fields.filter(x => (x.value === null || x.value === undefined)
      && ((!x.alias.includes('SCHED')
        && !x.alias.includes('RANGE'))
        || notSchedFields.findIndex(y => y === x.alias) > -1));

    fields = fields.filter(x => this._data.fields.findIndex(y =>
      y.alias === x.alias
      && x.value !== undefined
      && x.value !== null
      && y.editable
      && typeof (x.value) !== 'object'
      && y.value?.toString() !== x.value?.toString()) > -1);

    const pepsFieldsCannotBeModified = this._data.fields.filter(x =>
      (x.alias === 'PEPS_HGR_CP' || x.alias === 'PEPS_MIN_CR' || x.alias === 'PEPS_LWR_CP' || x.alias === 'PEPS_MAX_CR')
      && (x.value === undefined || x.value === null)).map(x => x.alias);

    fields = fields.filter(x => !pepsFieldsCannotBeModified.includes(x.alias));

    fields.push(...objectFields.filter(x => this._data.fields.findIndex(y =>
      y.alias === x.alias
      && x.value !== undefined
      && x.value !== null
      && y.editable
      && JSON.stringify(y.value) !== JSON.stringify(x.value)) > -1));

    fields.push(...nullFields);

    fields = this.reverseMapInterestRatesCustom(fields, objectFields, isCrossFx, isMarketDataSave);
    fields = this.reverseMapDividendsCustom(fields);
    fields = this.reverseMapEquityLinkedCreditCustom(fields);
    fields = this.reverseMapStochasticCreditCustom(fields);
    fields = this.reverseMapAssetParametersCreditCustom(fields);
    fields = this.customReverseMapPricingFields(fields);
    fields = this.customReverseMapNewIssueAssumptionsFields(fields);

    if (isQuickTermsVisible) {
      fields = this.customReverseMapPriceTalkFields(fields);
    }

    fields.forEach(field => {
      const fieldForEdit = this._data.fields.find(x => x.alias === field.alias);
      if (!!fieldForEdit) {
        fieldForEdit.value = field.value;
      }
    });

    if (mapForV3) {
      fields.filter(x =>
        notSchedFields.findIndex(y => y === x.alias) < 0
        && (x.alias.includes('SCHED')
          || x.alias.includes('RANGE'))).forEach(item => {
            if ((item.value as [])?.length === 0) {
              item.value = null;
            }
          });

      if (!!conversionScheduleCurrent && !!fields.find(x => x.alias === 'CONV_SCHED_RANGE')) {

        // If there is override for conversion schedule range, Conversion ratio and price can't be saved in same time.

        fields.find(x => x.alias === 'CONV_SCHED_RANGE').value?.forEach((element, index) => {
          if (this.getFieldValue('CONV_SCHED_RANGE')[index]) {            
            const ratio = conversionScheduleCurrent?.value ? conversionScheduleCurrent.value[index]?.ratio : element[2];
            const price = conversionScheduleCurrent?.value ? conversionScheduleCurrent.value[index]?.price : element[3];

            // If terms in New Issue status (isQuickTermsVisible) than reset to null ratio and price
            // If previous value of schedule item has ratio, both values or both zero values save ratio to excel
            // in other case save conversion price to excel
            // 2 - ratio 3 - price

            if (isQuickTermsVisible) {
              element[2] = null;
              element[3] = null;
              element[4] = null;
            }
            else {
              element[2] = !!ratio ? element[2] : !price ? element[2] : null;
              element[3] = !price ? null : !!ratio ? null : element[3];
            }
          }
          else {
            element[3] = null;
          }
        });
      }
    }

    if (!isCrossFx) {
      fields = fields.filter(x => x.alias !== 'FIXED_STK_REF_EQCCY');
      fields = fields.filter(x => x.alias !== 'STK_REF_EQCCY');
      fields = fields.filter(x => x.alias !== 'ISSUE_STK_REF_EQCCY');
      fields = fields.filter(x => x.alias !== 'DM-4630');
    }

    if (this._data.fields.find(x => x.alias === 'FIXED_FX')?.value) {
      fields = fields.filter(x => x.alias !== 'ISSUE_FX_REF');
    }

    if (this._data.fields.find(x => x.alias === 'ISSUE_FX_REF' && !!x.value) && fields.find(x => x.alias === 'ISSUE_FX_REF' && !!x.value)) {
      fields = fields.filter(x => x.alias !== 'FIXED_FX');
    }

    const dataToSend = {
      fields: fields,
      environment: this._data.environment,
      identifier: this._data.identifier,
    } as IExcelWebViewData;

    if (dataToSend && dataToSend.fields && dataToSend.fields.length > 0) {
      if (this.isCypress) {
        sessionStorage.setItem('mockedResponse', JSON.stringify(dataToSend));
      }

      this.wvService.postMessage({
        type: WebViewMessageType.data,
        data: dataToSend
      });
    }
  }

  /**
   * Checks if Excel service is initialized
   * @returns flag that descirbes if excel service initialized
   */
  isInitialized(): boolean {
    return this._isInitialzed;
  }

  /**
   * Method to get excel addin data provided by excel addin
   * @returns IExcelWebViewData
   */
  getData(): IExcelWebViewData {
    return this._data;
  }

  /**
   * Checks if data from excel has field with specific alias
   * @param alias Alias of specific field
   * @returns flag that describes if field with alias exists in data fields and if this field is editable
   */
  containsField(alias: string): boolean {
    if (this._data && this._data.fields) {
      return this._data.fields.findIndex(x => x.alias === alias) > -1;
    }
    return false;
  }

  /**
   * Checks if data from excel has field with specific alias and return his value
   * @param alias Alias of specific field
   * @returns Value of specified field
   */
  getFieldValue(alias: string): any {
    if (this._data && this._data.fields) {
      const field = this._data.fields.find(x => x.alias === alias);

      if (field) {
        return field.value;
      }
    }

    return null;
  }

  /**
   * Checks if data from excel has field with specific alias and return this field
   * @param alias Alias of specific field
   * @returns Specified field
   */
  getField(alias: string): IField {
    if (this._data && this._data.fields) {
      const field = this._data.fields.find(x => x.alias === alias);

      if (field) {
        return field;
      }
    }

    return null;
  }

  /**
   * Checks if any field is overrided from excel if flat field is sent
   * @param sectionPrefix Section prefix
   * @param flatFields Flat override fields
   * @returns Flag that describes is any field except flat overrided
   */
  hasAnyFieldFromSection(sectionPrefix: string, flatFields: string[]) {
    if (this._data && this._data.fields) {
      if (sectionPrefix === 'DVD') {
        return this._data.fields.findIndex(x => x.alias.startsWith(sectionPrefix)
          && !x.alias.startsWith('DVD_PROT')
          && !!x.value && flatFields.findIndex(y => y === x.alias) < 0) > -1;
      }

      return this._data.fields.findIndex(x => x.alias.startsWith(sectionPrefix)
        && !!x.value && flatFields.findIndex(y => y === x.alias) < 0) > -1;
    }

    return false;
  }

  /**
   * Check if DVD_PARAMS is overrided from excel
   * @returns flag that describes to which field data would be saved DVD_PARAMS or to singleFieds
   */
  hasDividendsParamRangeOverridedFromExcel(): boolean {
    const paramRange = this.getFieldValue('DVD_PARAM_RANGE');
    const dvdValue = this.getFieldValue('DVD_VALUE');
    const dvdType = this.getFieldValue('DVD_TYPE');
    const dvdFreq = this.getFieldValue('DVD_FREQ');
    const dvdGrowth = this.getFieldValue('DVD_GROWTH');
    const dvdFirstDate = this.getFieldValue('DVD_FIRST_DATE');
    const dvdYieldStartTime = this.getFieldValue('DVD_YLD_START');
    const dvdCcy = this.getFieldValue('DVD_CCY');

    return (!!paramRange
      || (!paramRange
        && !!this.containsField('DVD_PARAM_RANGE')
        && !dvdValue
        && !dvdType
        && !dvdFreq
        && !dvdGrowth
        && !dvdFirstDate
        && !dvdYieldStartTime
        && !dvdCcy));
  }

  /**
   * Check if CREDIT_EQLNKD_RANGE is overrided from excel
   * @returns flag that describes to which field data would be saved CREDIT_EQLNKD_RANGE or to singleFieds
   */
    hasCreditEquityLinkedRangeOverridedFromExcel(): boolean {
      const paramRange = this.getFieldValue('CREDIT_EQLNKD_RANGE');
      const calibrationType = this.getFieldValue('CREDIT_PARM');
      const creditParm = this.getFieldValue('CREDIT_CALIB_TYPE');
      const creditRefSpot = this.getFieldValue('CREDIT_REF_SPOT');
      const creditBondFloor = this.getFieldValue('CREDIT_BOND_FLOOR');

      return (!!paramRange
        || (!paramRange
          && !!this.containsField('CREDIT_EQLNKD_RANGE')
          && !calibrationType
          && !creditParm
          && !creditRefSpot
          && !creditBondFloor));
    }

  /**
   * Check if CREDIT_STOCH_RANGE is overrided from excel
   * @returns flag that describes to which field data would be saved CREDIT_STOCH_RANGE or to singleFieds
   */
    hasCreditStochasticCreditRangeOverridedFromExcel(): boolean {
      const paramRange = this.getFieldValue('CREDIT_STOCH_RANGE');
      const creditVol = this.getFieldValue('CREDIT_VOL');
      const creditRStockCorr = this.getFieldValue('CREDIT_STCK_CORR');
      const creditRvrInt = this.getFieldValue('CREDIT_RVR_INTENSITY');
      const creditBMean = this.getFieldValue('CREDIT_MEAN');

      return (!!paramRange
        || (!paramRange
          && !!this.containsField('CREDIT_STOCH_RANGE')
          && !creditVol
          && !creditRStockCorr
          && !creditRvrInt
          && !creditBMean));
    }

  /**
   * Check if CREDIT_EQLNKD_RANGE is overrided from excel
   * @returns flag that describes to which field data would be saved CREDIT_EQLNKD_RANGE or to singleFieds
   */
    hasCreditAssetParametersOverridedFromExcel(): boolean {
      const paramRange = this.getFieldValue('CREDIT_ISS_ASST_PARM_RANGE');
      const creditDebt = this.getFieldValue('CREDIT_DBT_PER_SHARE');
      const creditRcovery = this.getFieldValue('CREDIT_GLB_RECVRY');
      const creditVol = this.getFieldValue('CREDIT_ASST_VOL_OVRRIDE');

      return (!!paramRange
        || (!paramRange
          && !!this.containsField('CREDIT_ISS_ASST_PARM_RANGE')
          && !creditDebt
          && !creditRcovery
          && !creditVol));
    }

  onSessionExpired() {
    this.wvService.postMessage({
      type: WebViewMessageType.sessionExpired,
      data: 'Session expired'
    });
  }

  /**
   * This method has a custom logic for mapping volatility source
   * Fix for bug noticed on SYSTEM-2839
   * @param fields Fields that is needed to be saved to excel
   */
  private setVolatilitySourceForSaveToExcel(fields: any) {
    const flatVol = fields.find(x => x.alias === 'VOL_FLAT');
    const volSchedule = fields.find(x => x.alias === 'VOL_SCHED_RANGE');
    const volSurface = fields.find(x => x.alias === 'VOL_SURF_RANGE');
    const volSurfBaseDt = fields.find(x => x.alias === 'VOL_SURF_BASE_DT');
    const volSurfPrice = fields.find(x => x.alias === 'VOL_SURF_PRICE');
    const volUseStrike = fields.find(x => x.alias === 'VOL_USE_STRIKE');

    if (flatVol && !!flatVol.value) {
      if (volSchedule) {
        volSchedule.value = [];
      }

      if (volSurface) {
        volSurface.value = [];
      }

      if (volSurfBaseDt) {
        volSurfBaseDt.value = null;
      }

      if (volSurfPrice) {
        volSurfPrice.value = null;
      }

      if (volUseStrike) {
        volUseStrike.value = null;
      }
    } else if (volSchedule && !!volSchedule.value) {
      if (volSurface) {
        volSurface.value = [];
      }

      if (volSurfBaseDt) {
        volSurfBaseDt.value = null;
      }

      if (volSurfPrice) {
        volSurfPrice.value = null;
      }

      if (volUseStrike) {
        volUseStrike.value = null;
      }
    } else if (volSurface && !!volSurface.value) {
      if (volSchedule) {
        volSchedule.value = [];
      }
    } else {
      if (volSurfBaseDt) {
        volSurfBaseDt.value = null;
      }

      if (volSurfPrice) {
        volSurfPrice.value = null;
      }

      if (volUseStrike) {
        volUseStrike.value = null;
      }
    }
  }

  /**
   * Map interest rates schedules
   * @param fields Fields for saving to excel
   * @param objectFields schedule fields for saveto excel
   * @param isCrossFx Flag that describes whether instrument is cross fx.
   * @param isMarketDataSave Flag that describes whether save function is called for market data fields.
   */
  private reverseMapInterestRatesCustom(fields: IField[], objectFields: IField[], isCrossFx: boolean, isMarketDataSave: boolean) {
    if (fields.findIndex(x => x.alias === 'IR_CURVE_RANGE_T_INST') < 0) {
      fields.push(...objectFields.filter(x => x.alias === 'IR_CURVE_RANGE_T_INST'));
    }

    if (fields.findIndex(x => x.alias === 'IR_CURVE_RANGE_R_INST') < 0) {
      fields.push(...objectFields.filter(x => x.alias === 'IR_CURVE_RANGE_R_INST'));
    }

    if (fields.findIndex(x => x.alias === 'IR_CURVE_RANGE_DS_INST') < 0) {
      fields.push(...objectFields.filter(x => x.alias === 'IR_CURVE_RANGE_DS_INST'));
    }

    if (fields.findIndex(x => x.alias === 'IR_CURVE_RANGE_INST') < 0) {
      fields.push(...objectFields.filter(x => x.alias === 'IR_CURVE_RANGE_INST'));
    }

    if (fields.findIndex(x => x.alias === 'IR_CURVE_RANGE_INST_SPECIAL') < 0) {
      fields.push(...objectFields.filter(x => x.alias === 'IR_CURVE_RANGE_INST_SPECIAL'));
    }

    if (fields.findIndex(x => x.alias === 'IR_CURVE_RANGE_T_UND_INST') < 0) {
      fields.push(...objectFields.filter(x => x.alias === 'IR_CURVE_RANGE_T_UND_INST'));
    }

    if (fields.findIndex(x => x.alias === 'IR_CURVE_RANGE_R_UND_INST') < 0) {
      fields.push(...objectFields.filter(x => x.alias === 'IR_CURVE_RANGE_R_UND_INST'));
    }

    if (fields.findIndex(x => x.alias === 'IR_CURVE_RANGE_DS_UND_INST') < 0) {
      fields.push(...objectFields.filter(x => x.alias === 'IR_CURVE_RANGE_DS_UND_INST'));
    }

    if (fields.findIndex(x => x.alias === 'IR_CURVE_RANGE_UND_INST') < 0) {
      fields.push(...objectFields.filter(x => x.alias === 'IR_CURVE_RANGE_UND_INST'));
    }

    if (fields.findIndex(x => x.alias === 'IR_CURVE_RANGE_UND_INST_SPECIAL') < 0) {
      fields.push(...objectFields.filter(x => x.alias === 'IR_CURVE_RANGE_UND_INST_SPECIAL'));
    }

    // If flat interest rate is overridden remove rest of interest rates form save to excel except empty schedules. 
    if (this._data.fields.findIndex(x => x.alias === 'IR_FLAT_INST') > -1 && fields.findIndex(x => x.alias === 'IR_FLAT_INST' && x.value !== undefined && x.value !== null) > -1) {

      const fieldsToExclude = this._data.fields.filter(x => x.alias.startsWith('IR_')
        && !x.alias.includes('_UND_')
        && x.alias !== 'IR_FLAT_INST'
        && x.alias !== 'IR_SOURCE_INST'
        && !!fields.find(y => y.alias === x.alias)?.value).map(x => x.alias);
      
      const emptyScheduleFields = fields.filter(x => x.alias.startsWith('IR_CURVE_RANGE') && !x.alias.includes('_UND') && (!x.value || x.value?.length === 0));
      fields = fields.filter(x => fieldsToExclude.findIndex(y => y === x.alias) < 0);
      fields = fields.concat(emptyScheduleFields);
    }

    if (this._data.fields.findIndex(x => x.alias === 'IR_FLAT_UND_INST') > -1 && fields.findIndex(x => x.alias === 'IR_FLAT_UND_INST' && x.value !== undefined && x.value !== null) > -1) {
      
      const fieldsToExclude = this._data.fields.filter(x => x.alias.startsWith('IR_')
        && x.alias.includes('_UND_')
        && x.alias !== 'IR_FLAT_UND_INST'
        && x.alias !== 'IR_SOURCE_UND_INST'
        && !!fields.find(y => y.alias === x.alias)?.value).map(x => x.alias);

      const emptyScheduleFields = fields.filter(x => x.alias.startsWith('IR_CURVE_RANGE') && x.alias.includes('_UND') && (!x.value || x.value?.length === 0));
      fields = fields.filter(x => fieldsToExclude.findIndex(y => y === x.alias) < 0);
      fields = fields.concat(emptyScheduleFields);
    }

    // If yield curve is overridden and value of yield curve is different from custom
    // remove all fields except yield curve source and empty schedules.
    if (this._data.fields.findIndex(x => x.alias === 'IR_YC_INST') > -1) {
      if (fields.findIndex(x => x.alias === 'IR_YC_INST' && x.value !== 'Custom' && !!x.value) > -1) {

        const fieldsToExclude = this._data.fields.filter(x => x.alias.startsWith('IR_')
          && !x.alias.includes('_UND_')
          && x.alias !== 'IR_YC_INST'
          && !!fields.find(y => y.alias === x.alias)?.value).map(x => x.alias);

        const emptyScheduleFields = fields.filter(x => x.alias.startsWith('IR_CURVE_RANGE') && !x.alias.includes('_UND') && (!x.value || x.value?.length === 0));
        fields = fields.filter(x => fieldsToExclude.findIndex(y => y === x.alias) < 0);
        fields = fields.concat(emptyScheduleFields);
      }
    }

    if (this._data.fields.findIndex(x => x.alias === 'IR_YC_UND_INST') > -1) {
      if (fields.findIndex(x => x.alias === 'IR_YC_UND_INST' && x.value !== 'Custom' && !!x.value) > -1) {
        
        const fieldsToExclude = this._data.fields.filter(x => x.alias.startsWith('IR_')
          && x.alias.includes('_UND_')
          && x.alias !== 'IR_YC_UND_INST'
          && !!fields.find(y => y.alias === x.alias)?.value).map(x => x.alias);
        
        const emptyScheduleFields = fields.filter(x => x.alias.startsWith('IR_CURVE_RANGE') && x.alias.includes('_UND') && (!x.value || x.value?.length === 0));
        fields = fields.filter(x => fieldsToExclude.findIndex(y => y === x.alias) < 0);
        fields = fields.concat(emptyScheduleFields);
      }
    }

    const specialIntRatesAliases = ['IR_CURVE_RANGE_T_INST',
    'IR_CURVE_RANGE_R_INST',
    'IR_CURVE_RANGE_DS_INST',
    'IR_CURVE_RANGE_INST_SPECIAL'];

    const specialUndIntRatesAliases = ['IR_CURVE_RANGE_T_UND_INST',
    'IR_CURVE_RANGE_R_UND_INST',
    'IR_CURVE_RANGE_DS_UND_INST',
    'IR_CURVE_RANGE_UND_INST_SPECIAL'];

    const hasInterestRatesCurveValueInDataFields = this._data.fields.findIndex(x => x.alias === 'IR_CURVE_RANGE_INST' && !!x.value) > -1;
    const doInterestRatesCurveExistsWithoutValueInDataFields = this._data.fields.findIndex(x => x.alias === 'IR_CURVE_RANGE_INST' && !x.value) > -1;
    const hasInterestRatesCurveValueInResponseFields = fields.findIndex(x => x.alias === 'IR_CURVE_RANGE_INST' && !!x.value) > -1;
    const hasSpecialInterestRatesAliasesValueInDataFields = this._data.fields.findIndex(x => specialIntRatesAliases.findIndex(y => y === x.alias) > -1 && !!x.value) > -1;

    if ((hasInterestRatesCurveValueInDataFields && hasInterestRatesCurveValueInResponseFields) 
    || (doInterestRatesCurveExistsWithoutValueInDataFields && hasInterestRatesCurveValueInResponseFields && !hasSpecialInterestRatesAliasesValueInDataFields)) {

      specialIntRatesAliases.forEach(alias => {
        const field = fields.find(x => x.alias === alias);
        if (field) {
          field.value = null;
        }
      });
    }

    const hasUndInterestRatesCurveValueInDataFields = this._data.fields.findIndex(x => x.alias === 'IR_CURVE_RANGE_UND_INST' && !!x.value) > -1;
    const doUndInterestRatesCurveExistsWithoutValueInDataFields = this._data.fields.findIndex(x => x.alias === 'IR_CURVE_RANGE_UND_INST' && !x.value) > -1;
    const hasUndInterestRatesCurveValueInResponseFields = fields.findIndex(x => x.alias === 'IR_CURVE_RANGE_UND_INST' && !!x.value) > -1;
    const hasSpecialUndInterestRatesAliasesValueInDataFields = this._data.fields.findIndex(x => specialUndIntRatesAliases.findIndex(y => y === x.alias) > -1 && !!x.value) > -1;

    if ((hasUndInterestRatesCurveValueInDataFields && hasUndInterestRatesCurveValueInResponseFields) 
    || (doUndInterestRatesCurveExistsWithoutValueInDataFields && hasUndInterestRatesCurveValueInResponseFields && !hasSpecialUndInterestRatesAliasesValueInDataFields)) {

      specialUndIntRatesAliases.forEach(alias => {
        const field = fields.find(x => x.alias === alias);
        if (field) {
          field.value = null;
        }
      });
    }
    
    if (this._data.fields.findIndex(x => specialIntRatesAliases.findIndex(y => y === x.alias) > -1 && !!x.value) > -1
    && fields.findIndex(x => specialIntRatesAliases.findIndex(y => y === x.alias) > -1 && !!x.value) > -1) {

      const field = fields.find(x => x.alias === 'IR_CURVE_RANGE_INST');
      if (field) {
        field.value = null;
      }
    }

    if (this._data.fields.findIndex(x => specialUndIntRatesAliases.findIndex(y => y === x.alias) > -1 && !!x.value) > -1
    && fields.findIndex(x => specialUndIntRatesAliases.findIndex(y => y === x.alias) > -1 && !!x.value) > -1) {

      const field = fields.find(x => x.alias === 'IR_CURVE_RANGE_UND_INST');
      if (field) {
        field.value = null;
      }
    }

    if (!isCrossFx && isMarketDataSave) {
      this._data.fields.filter(x => x.alias.includes('IR_') && x.alias.includes('_UND')).forEach(field => {
        if (fields.findIndex(x => x.alias === field.alias) > -1) {
          fields.find(x => x.alias === field.alias).value = null;
        }
        else {
          fields.push({
            alias: field.alias,
            editable: true,
            group: field.group,
            value: null
          } as IField)
        }
      });
    }

    return fields;
  }

  /**
   * If dividends param range is sent from excel and have value and vice versa
   * don't use single fields in count from params rande
   */
  private reverseMapDividendsCustom(fields: IField[]) {
    if ((this._data.fields.findIndex(x => x.alias === 'DVD_PARAM_RANGE' && !!x.value) > -1)
    || (this._data.fields.findIndex(x => x.alias === 'DVD_PARAM_RANGE' && !x.value) > -1
    && (this._data.fields.findIndex(x => x.alias === 'DVD_VALUE' && !x.value) > -1
    && this._data.fields.findIndex(x => x.alias === 'DVD_TYPE' && !x.value) > -1
    && this._data.fields.findIndex(x => x.alias === 'DVD_FREQ' && !x.value) > -1
    && this._data.fields.findIndex(x => x.alias === 'DVD_GROWTH' && !x.value) > -1
    && this._data.fields.findIndex(x => x.alias === 'DVD_FIRST_DATE' && !x.value) > -1
    && this._data.fields.findIndex(x => x.alias === 'DVD_YLD_START' && !x.value) > -1
    && this._data.fields.findIndex(x => x.alias === 'DVD_CCY' && !x.value) > -1))) {
      const fieldsToExclude = ['DVD_TYPE',
        'DVD_VALUE',
        'DVD_FREQ',
        'DVD_GROWTH',
        'DVD_FIRST_DATE',
        'DVD_YLD_START',
        'DVD_CCY'
      ];

      fields = fields.filter(x => fieldsToExclude.findIndex(y => y === x.alias) < 0);
    }
    else if (this._data.fields.findIndex(x => x.alias === 'DVD_PARAM_RANGE' && !x.value) > -1
    && (this._data.fields.findIndex(x => x.alias === 'DVD_VALUE') > -1
    || this._data.fields.findIndex(x => x.alias === 'DVD_TYPE') > -1
    || this._data.fields.findIndex(x => x.alias === 'DVD_FREQ') > -1
    || this._data.fields.findIndex(x => x.alias === 'DVD_GROWTH') > -1
    || this._data.fields.findIndex(x => x.alias === 'DVD_FIRST_DATE') > -1
    || this._data.fields.findIndex(x => x.alias === 'DVD_YLD_START') > -1
    || this._data.fields.findIndex(x => x.alias === 'DVD_CCY') > -1)) {

      fields = fields.filter(x => x.alias !== 'DVD_PARAM_RANGE');
    }
    
    const hasDividendSchedule1WithValueInDataArray = this._data.fields.findIndex(x => x.alias === 'DVD_SCHED_RANGE_1' && !!x.value) > -1;
    const hasDividendSchedule1WithoutValueInDataArray = this._data.fields.findIndex(x => x.alias === 'DVD_SCHED_RANGE_1' && !x.value) > -1;
    const hasDividendSchedule1InFields = fields.findIndex(x => x.alias === 'DVD_SCHED_RANGE_1' && !!x.value) > -1;

    const hasDividendSchedule2WithValueInDataArray = this._data.fields.findIndex(x => x.alias === 'DVD_SCHED_RANGE_2' && !!x.value) > -1;
    const hasDividendSchedule2WithoutValueInDataArray = this._data.fields.findIndex(x => x.alias === 'DVD_SCHED_RANGE_2' && !x.value) > -1;
    const hasDividendSchedule2InFields = fields.findIndex(x => x.alias === 'DVD_SCHED_RANGE_2' && !!x.value) > -1;

    if ((hasDividendSchedule1WithValueInDataArray && hasDividendSchedule1InFields)
    || (hasDividendSchedule1WithoutValueInDataArray && hasDividendSchedule1InFields && !hasDividendSchedule2WithValueInDataArray)) {
      fields = fields.filter(x => x.alias !== 'DVD_SCHED_RANGE_2');
    }
    else if ((hasDividendSchedule2WithValueInDataArray && hasDividendSchedule2InFields)
    || (hasDividendSchedule2WithoutValueInDataArray && hasDividendSchedule2InFields && !hasDividendSchedule1WithValueInDataArray)) {
      fields = fields.filter(x => x.alias !== 'DVD_SCHED_RANGE_1');
    }
    
    return fields;
  }

  /**
   * If Equity Linked Credit param range is sent from excel and have value and vice versa
   * don't use single fields in count from params rande
   */
  private reverseMapEquityLinkedCreditCustom(fields: IField[]) {
    if ((this._data.fields.findIndex(x => x.alias === 'CREDIT_EQLNKD_RANGE' && !!x.value) > -1)
    || (this._data.fields.findIndex(x => x.alias === 'CREDIT_EQLNKD_RANGE' && !x.value) > -1
    && (this._data.fields.findIndex(x => x.alias === 'CREDIT_CALIB_TYPE' && !x.value) > -1
    && this._data.fields.findIndex(x => x.alias === 'CREDIT_PARM' && !x.value) > -1
    && this._data.fields.findIndex(x => x.alias === 'CREDIT_REF_SPOT' && !x.value) > -1
    && this._data.fields.findIndex(x => x.alias === 'CREDIT_BOND_FLOOR' && !x.value) > -1))) {
      const fieldsToExclude = ['CREDIT_CALIB_TYPE',
        'CREDIT_PARM',
        'CREDIT_REF_SPOT',
        'CREDIT_BOND_FLOOR',
      ];

      fields = fields.filter(x => fieldsToExclude.findIndex(y => y === x.alias) < 0);
    }
    else if (this._data.fields.findIndex(x => x.alias === 'CREDIT_EQLNKD_RANGE' && !x.value) > -1
    && (this._data.fields.findIndex(x => x.alias === 'CREDIT_CALIB_TYPE') > -1
    || this._data.fields.findIndex(x => x.alias === 'CREDIT_PARM') > -1
    || this._data.fields.findIndex(x => x.alias === 'CREDIT_REF_SPOT') > -1
    || this._data.fields.findIndex(x => x.alias === 'CREDIT_BOND_FLOOR') > -1)) {

      fields = fields.filter(x => x.alias !== 'CREDIT_EQLNKD_RANGE');
    }

    return fields;
  }

  /**
   * If Equity Linked Credit param range is sent from excel and have value and vice versa
   * don't use single fields in count from params rande
   */
  private reverseMapStochasticCreditCustom(fields: IField[]) {
    if ((this._data.fields.findIndex(x => x.alias === 'CREDIT_STOCH_RANGE' && !!x.value) > -1)
    || (this._data.fields.findIndex(x => x.alias === 'CREDIT_STOCH_RANGE' && !x.value) > -1
    && (this._data.fields.findIndex(x => x.alias === 'CREDIT_VOL' && !x.value) > -1
    && this._data.fields.findIndex(x => x.alias === 'CREDIT_STCK_CORR' && !x.value) > -1
    && this._data.fields.findIndex(x => x.alias === 'CREDIT_RVR_INTENSITY' && !x.value) > -1
    && this._data.fields.findIndex(x => x.alias === 'CREDIT_MEAN' && !x.value) > -1))) {
      const fieldsToExclude = ['CREDIT_VOL',
        'CREDIT_STCK_CORR',
        'CREDIT_RVR_INTENSITY',
        'CREDIT_MEAN',
      ];

      fields = fields.filter(x => fieldsToExclude.findIndex(y => y === x.alias) < 0);
    }
    else if (this._data.fields.findIndex(x => x.alias === 'CREDIT_STOCH_RANGE' && !x.value) > -1
    && (this._data.fields.findIndex(x => x.alias === 'CREDIT_VOL') > -1
    || this._data.fields.findIndex(x => x.alias === 'CREDIT_STCK_CORR') > -1
    || this._data.fields.findIndex(x => x.alias === 'CREDIT_RVR_INTENSITY') > -1
    || this._data.fields.findIndex(x => x.alias === 'CREDIT_MEAN') > -1)) {

      fields = fields.filter(x => x.alias !== 'CREDIT_STOCH_RANGE');
    }

    return fields;
  }

  /**
   * If Equity Linked Credit param range is sent from excel and have value and vice versa
   * don't use single fields in count from params rande
   */
  private reverseMapAssetParametersCreditCustom(fields: IField[]) {
    if ((this._data.fields.findIndex(x => x.alias === 'CREDIT_ISS_ASST_PARM_RANGE' && !!x.value) > -1)
    || (this._data.fields.findIndex(x => x.alias === 'CREDIT_ISS_ASST_PARM_RANGE' && !x.value) > -1
    && (this._data.fields.findIndex(x => x.alias === 'CREDIT_DBT_PER_SHARE' && !x.value) > -1
    && this._data.fields.findIndex(x => x.alias === 'CREDIT_GLB_RECVRY' && !x.value) > -1
    && this._data.fields.findIndex(x => x.alias === 'CREDIT_ASST_VOL_OVRRIDE' && !x.value) > -1))) {
      const fieldsToExclude = ['CREDIT_DBT_PER_SHARE',
        'CREDIT_GLB_RECVRY',
        'CREDIT_ASST_VOL_OVRRIDE',
      ];

      fields = fields.filter(x => fieldsToExclude.findIndex(y => y === x.alias) < 0);
    }
    else if (this._data.fields.findIndex(x => x.alias === 'CREDIT_ISS_ASST_PARM_RANGE' && !x.value) > -1
    && (this._data.fields.findIndex(x => x.alias === 'CREDIT_DBT_PER_SHARE') > -1
    || this._data.fields.findIndex(x => x.alias === 'CREDIT_GLB_RECVRY') > -1
    || this._data.fields.findIndex(x => x.alias === 'CREDIT_ASST_VOL_OVRRIDE') > -1)) {

      fields = fields.filter(x => x.alias !== 'CREDIT_ISS_ASST_PARM_RANGE');
    }

    return fields;
  }
  /**
   * Custom data filter for pricing fields
   * @param fields fields for save to excel
   */
  private customReverseMapPricingFields(fields: IField[]): IField[] {
    const issuePx = this._data.fields.find(x => x.alias === 'ISSUE_PX');
    const price = this._data.fields.find(x => x.alias === 'PRICE');

    if (!!issuePx && !!issuePx?.value) {
      fields = fields.filter(x => x.alias !== 'PRICE');
    }
    else if (!!price && !!price?.value) {
      fields = fields.filter(x => x.alias !== 'ISSUE_PX');
    }
    else if (!!issuePx && !issuePx?.value && !!price && !price?.value) {
      fields = fields.filter(x => x.alias !== 'PRICE');
    }

    fields = StockRefSaveBackToExcelHelper.customReverseMapStockRefFields(this._data.fields, fields);

    return fields;
  }

  /**
   * custom reverse maps for new issue assumptions.
   * @param fields List of fields.
   * @returns List of updated fields.
   */
  private customReverseMapNewIssueAssumptionsFields(fields: IField[]): IField[] {
    if (this._data.fields.findIndex(x => x.alias === 'ASSUMED_YLD' && !x.value) > 0
      && this._data.fields.findIndex(x => x.alias === 'ASSUMED_REDEM_PX' && x.value) > 0
      && fields.find(x => x.alias === 'ASSUMED_YLD')?.value) {
      fields = fields.filter(x => x.alias !== 'ASSUMED_YLD');
    }

    if (this._data.fields.findIndex(x => x.alias === 'ASSUMED_REDEM_PX' && !x.value) > 0
      && this._data.fields.findIndex(x => x.alias === 'ASSUMED_YLD' && x.value) > 0
      && fields.find(x => x.alias === 'ASSUMED_REDEM_PX')?.value) {
      fields = fields.filter(x => x.alias !== 'ASSUMED_REDEM_PX');
    }

    if (this._data.fields.findIndex(x => x.alias === 'ASSUMED_REDEM_PX' && !x.value) > 0
      && this._data.fields.findIndex(x => x.alias === 'ASSUMED_YLD' && !x.value) > 0
      && fields.find(x => x.alias === 'ASSUMED_YLD')?.value) {
      fields = fields.filter(x => x.alias !== 'ASSUMED_YLD');
    }

    if (!fields.find(x => x.alias === 'ASSUMED_PREM_PRC')?.value
      && this._data.fields.findIndex(x => x.alias === 'ASSUMED_PREM_PRC' && !x.value) > 0) {
      fields = fields.filter(x => x.alias !== 'ASSUMED_PREM_PRC');
    }

    if (this._data.fields.find(x => x.alias === 'ISSUE_STK_REF_CBCCY')?.value
    && !this._data.fields.find(x => x.alias === 'ISSUE_STK_REF_EQCCY')?.value) {
      fields = fields.filter(x => x.alias !== 'ISSUE_STK_REF_EQCCY');
    }

    if (this._data.fields.find(x => x.alias === 'ISSUE_STK_REF_EQCCY')?.value
    && !this._data.fields.find(x => x.alias === 'ISSUE_STK_REF_CBCCY')?.value) {
      fields = fields.filter(x => x.alias !== 'ISSUE_STK_REF_CBCCY');
    }

    if (!this._data.fields.find(x => x.alias === 'ISSUE_STK_REF_EQCCY')?.value
    && !this._data.fields.find(x => x.alias === 'ISSUE_STK_REF_CBCCY')?.value) {
      fields = fields.filter(x => x.alias !== 'ISSUE_STK_REF_CBCCY');
    }

    return fields;
  }

  /**
   * custom reverse maps for price talk fields.
   * @param fields List of fields.
   * @returns List of updated fields.
   */
  private customReverseMapPriceTalkFields(fields: IField[]): IField[] {
    if (this._data.fields.findIndex(x => x.alias === 'NI_YLD_RANGE' && !x.value) > 0) {
      fields = fields.filter(x => x.alias !== 'NI_YLD_RANGE');
    }

    if (this._data.fields.findIndex(x => x.alias === 'NI_REDEM_PX_RANGE' && !x.value) > 0) {
      fields = fields.filter(x => x.alias !== 'NI_REDEM_PX_RANGE');
    }

    if (!fields.find(x => x.alias === 'NI_PREM_PRC_RANGE')?.value
      && this._data.fields.findIndex(x => x.alias === 'NI_PREM_PRC_RANGE' && !x.value) > 0) {
      fields = fields.filter(x => x.alias !== 'NI_PREM_PRC_RANGE');
    }

    if (this._data.fields.find(x => x.alias === 'FIXED_STK_REF_CBCCY')?.value
    && !this._data.fields.find(x => x.alias === 'FIXED_STK_REF_EQCCY')?.value) {
      fields = fields.filter(x => x.alias !== 'FIXED_STK_REF_EQCCY');
    }

    if (this._data.fields.find(x => x.alias === 'FIXED_STK_REF_EQCCY')?.value
    && !this._data.fields.find(x => x.alias === 'FIXED_STK_REF_CBCCY')?.value) {
      fields = fields.filter(x => x.alias !== 'FIXED_STK_REF_CBCCY');
    }

    if (!this._data.fields.find(x => x.alias === 'FIXED_STK_REF_EQCCY')?.value
    && !this._data.fields.find(x => x.alias === 'FIXED_STK_REF_CBCCY')?.value) {
      fields = fields.filter(x => x.alias !== 'FIXED_STK_REF_CBCCY');
    }

    return fields;
  }

  /**
   * Pricing new issue fields.
   */
  private _pricingNewIssueFields: Record<string, boolean> = {
    'PRICE_TALK': true,
    'ASSUMED_CPN_RT': true,
    'ASSUMED_ISSUE_PX': true,
    'ASSUMED_REDEM_PX': true,
    'ISSUE_STK_REF_CBCCY': true,
    'ISSUE_FX_REF': true,
    'ISSUE_STK_REF_EQCCY': true,
    'ASSUMED_YLD': true,
    'ASSUMED_PREM_PRC': true,
  }

  /**
   * New issue assumptions fields.
   */
  private _niRangeFields: Record<string, boolean> = {
    'NI_YLD_RANGE': true,
    'NI_REDEM_PX_RANGE': true,
    'NI_CPN_RT_RANGE': true,
    'NI_ISSUE_PX_RANGE': true,
    'NI_PREM_PRC_RANGE': true,
  }
}

export enum WebViewMessageType {
  load = 'load',
  data = 'data',
  sessionExpired = 'sessionExpired',
  output = 'output'
}
