import {
  trigger,
  style,
  animate,
  transition, AnimationTriggerMetadata
} from '@angular/animations';

export const slide: AnimationTriggerMetadata = trigger('slide', [
  transition('void => *', [
    style({
      transform: 'translate3d(100%, 0, 0)'
    }),
    animate('250ms ease-in-out', style({
      transform: 'translate3d(0, 0, 0)'
    }))
  ]),
  transition('* => void', [
    animate('250ms ease-in-out', style({
      transform: 'translate3d(100%, 0, 0)'
    }))
  ])
]);
