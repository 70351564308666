import { Injectable, OnDestroy } from '@angular/core';
import { LvDataMaster } from '@lv-core-ui/models';

import { Subscription ,  Subject } from 'rxjs';
import { LvLoggerService } from '../lv-logger/lv-logger-service';

import { ILvNotificationEvent, ILvNotifier } from './lv-notifier';

@Injectable()
export class LvNotificationManagerService implements OnDestroy {

  private notifierSubscriptions: Map<string, Subscription>;
  private eventNotifier: Subject<ILvNotificationEvent>;

  private colorPallete: {
    [color: string]: string
  };

  constructor(
    private _logger: LvLoggerService
  ) {
    this.notifierSubscriptions = new Map();

    this.colorPallete = {
      '#1E8FE1': null,
      '#FF4A7A': null,
      '#73D500': null,
      '#C27AEB': null,
      '#FFDD00': null,
      '#03C4C6': null,
      '#0376E3': null
    };

    this.eventNotifier = new Subject<ILvNotificationEvent>();
  }

  getFreeColor(): string {
    const colors = Object.keys(this.colorPallete).filter(c => this.colorPallete[c] === null);

    if (colors.length === 0) {
      this._logger.logError(new Error('Maximum number of publishers'));
      throw new Error(LvDataMaster.getError('dM-1917'));
    }

    return colors[0];
  }

  register(notifier: ILvNotifier): void {
    if (!this.notifierSubscriptions.has(notifier.id)) {
      notifier.receiveSubscription = this.eventNotifier.subscribe(event => {
        if (event.from.id !== notifier.id) {
          notifier.receive.next(event);
        }
      });

      if (notifier.type === 'publisher') {
        if (!notifier.color) {
          const color = this.getFreeColor();
          this.colorPallete[color] = notifier.id;
          notifier.color = color;
        }
        else {
          this.colorPallete[notifier.color] = notifier.id;
        }
      }

      this.notifierSubscriptions.set(notifier.id, notifier.notify.subscribe(event => this.eventNotifier.next(event)));
    } else {
      this._logger.logError(new Error('Already subscribed'));
      throw new Error(LvDataMaster.getError('dM-1917'));
    }
  }

  unregister(notifier: ILvNotifier): void {
    if (this.notifierSubscriptions.has(notifier.id)) {
      const subscription = this.notifierSubscriptions.get(notifier.id);
      subscription.unsubscribe();

      if (notifier.type === 'publisher') {
        this.colorPallete[notifier.color] = null;
      }

      this.notifierSubscriptions.delete(notifier.id);
    }
  }

  unregisterAll() {
    this.notifierSubscriptions.forEach(ns => {
      ns.unsubscribe();
    });

    Object.keys(this.colorPallete).forEach(c => this.colorPallete[c] = null);

    this.notifierSubscriptions = new Map();
  }

  ngOnDestroy() {
    this.unregisterAll();

    this.notifierSubscriptions = null;
  }
}
