import { Component, OnInit, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';

/**
 * The dialog is used for sending request to backend to log calculation input parameters to XMl file
 * and send that file to Leversys support. User can optionally enter comment explaining what he/she
 * thinks is wrong with the calculation.
 */
@Component({
  selector: 'lv-send-valuation-query-dialog',
  templateUrl: './lv-send-valuation-query-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LvSendValuationQueryDialogComponent implements OnInit {

  @Output() didSend: EventEmitter<void>;

  valuationQueryNotes: string;

  /**
   * Constructor for the dialog window.
   * @param {DialogRef} dialogRef pass the dialog reference so it can be controlled from the inside
   */
  constructor(
    private dialogRef: DialogRef,
  ) {
    this.didSend = new EventEmitter<void>();
    this.valuationQueryNotes = '';
  }

  /**
   * Initializes the dialog window.
   */
  ngOnInit() {
  }

  /**
   * Closes dialog window on Cancel button pressed.
   */
  onCancel() {
    this.dialogRef.close();
  }

  /**
   * Emits didSend event on Send button pressed.
   * If user hasn't entered any commend, appropriate string will be passed.
   */
  onSend() {
    if (this.valuationQueryNotes === null || this.valuationQueryNotes.length === 0) {
      this.valuationQueryNotes = 'User hasn\'t entered any comment.';
    }
    this.didSend.emit();
  }
}
