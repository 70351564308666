import { DividendFrequencyEnum } from './../../models/market-data/dividends/enum/dividend-frequency-enum';
import { IMarketData } from './../../models/market-data/market-data';
import { DividendTypeEnum, DividendTypeEnumDescription } from './../../models/market-data/dividends/enum/dividend-type-enum';
import { IScheduledDividend } from './../../models/market-data/dividends/scheduled-dividend';
import { DateExtensions } from '@lv-core-ui/util';
import { ExcelFieldDataTypes } from '@lv-excel/models/enums/excel-field-data-types';

/**
 * Maps dividends.
 * @param fieldDictionary Field dictionary.
 * @param model IMarketData object.
 */
export function mapDividends(fieldDictionary: any, model: IMarketData): void {
  fieldDictionary['DVD_TYPE'] = {
    mapFn: (value: any) => {
      model.dividends.dividendParameters.dividendType = value;
    },
    reverseMapFn: () => {
      return DividendTypeEnumDescription[model.dividends.dividendParameters.dividendType];
    },
    type: ExcelFieldDataTypes.Enum,
    enumType: DividendTypeEnumDescription
  };

  fieldDictionary['DVD_VALUE'] = {
    mapFn: (value: any) => {
      model.dividends.dividendParameters.dividendValue = value;
      model.dividends.dividendParameters.dividendYield = value;
    },
    reverseMapFn: () => {
      return model.dividends.dividendParameters.dividendType === DividendTypeEnum.ContinuousYield ?
      model.dividends.dividendParameters.dividendYield : model.dividends.dividendParameters.dividendValue;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['DVD_FREQ'] = {
    mapFn: (value: any) => {
      model.dividends.dividendParameters.frequency = value;
    },
    reverseMapFn: () => {
      return DividendFrequencyEnum[model.dividends.dividendParameters.frequency];
    },
    type: ExcelFieldDataTypes.Enum,
    enumType: DividendFrequencyEnum
  };

  fieldDictionary['DVD_GROWTH'] = {
    mapFn: (value: any) => {
      model.dividends.dividendParameters.dividendGrowth = value;
    },
    reverseMapFn: () => {
      return model.dividends.dividendParameters.dividendGrowth;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['DVD_FIRST_DATE'] = {
    mapFn: (value: any) => {
      model.dividends.dividendParameters.firstDividendDate = value;
    },
    reverseMapFn: () => {
      return model.dividends.dividendParameters.firstDividendDate;
    },
    type: ExcelFieldDataTypes.Date
  };

  fieldDictionary['DVD_YLD_START'] = {
    mapFn: (value: any) => {
      model.dividends.dividendParameters.fixedDividendStartTime = value;
    },
    reverseMapFn: () => {
      return model.dividends.dividendParameters.fixedDividendStartTime;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['DVD_CCY'] = {
    mapFn: (value: any) => {
      model.dividends.dividendsCcy = value;
      model.dividends.dividendCurrencySelected = value;
    },
    reverseMapFn: () => {
      return model.dividends.dividendsCcy;
    },
    type: ExcelFieldDataTypes.String
  };

  fieldDictionary['DVD_SCHED_RANGE_1'] = {
    mapFn: (value: any) => {
      model.dividends.dividendsSchedule.dividendSchedule = value;
      model.dividends.dividendsSchedule.dividendSchedule = model.dividends.dividendsSchedule.dividendSchedule.map(item => {
        return {
          ...item,
          date: new Date(item.date)
        } as IScheduledDividend;
      });
    },
    reverseMapFn: () => {
      const result = model.dividends.dividendsSchedule.dividendSchedule.map(item => {
        return {
          ... item,
          date: DateExtensions.toOADate(item.date as Date),
          type: DividendTypeEnumDescription[item.type]
        };
      });

      return result;
    },
    type: ExcelFieldDataTypes.Schedule,
  };

  fieldDictionary['DVD_SCHED_RANGE_2'] = {
    mapFn: (value: any) => {
      model.dividends.dividendsSchedule.dividendSchedule = value;
      model.dividends.dividendsSchedule.dividendSchedule = model.dividends.dividendsSchedule.dividendSchedule.map(item => {
        return {
          ...item,
          date: new Date(item.date)
        } as IScheduledDividend;
      });
    },
    reverseMapFn: () => {
      const result = model.dividends.dividendsSchedule.dividendSchedule.map(item => {
        return {
          ... item,
          type: DividendTypeEnumDescription[item.type],
          date: DateExtensions.toOADate(item.date as Date)
        };
      });

      return result;
    },
    type: ExcelFieldDataTypes.Schedule,
  };

  fieldDictionary['DVD_PARAM_RANGE'] = {
    mapFn: (value: any) => {
      model.dividends.dividendParameters = value;

      model.dividends.dividendParameters.firstDividendDate =
        model.dividends.dividendParameters.firstDividendDate
        ? new Date(model.dividends.dividendParameters.firstDividendDate) : null;

      model.dividends.dividendParameters.fixedDividendStartTime = value.fixedDividendStartTime;
      model.dividends.dividendsCcy = value.dividendCCY;
      model.dividends.dividendCurrencySelected = value.dividendCCY;
      model.dividends.dividendParameters.dividendYield = value.dividendValue;
    },
    reverseMapFn: () => {
      if (model.dividends.dividendParameters.dividendType === DividendTypeEnum.ContinuousYield) {
        return [{
          dividendType: DividendTypeEnumDescription[model.dividends.dividendParameters.dividendType],
          dividendValue: model.dividends.dividendParameters.dividendYield,
          frequency: null,
          dividendGrowth: null,
          firstDividendDate: null,
          fixedDividendStartTime: null,
          dividendCCY: null
        }];
      }

      return [{
        dividendType: DividendTypeEnumDescription[model.dividends.dividendParameters.dividendType],
        dividendValue: model.dividends.dividendParameters.dividendValue,
        frequency: DividendFrequencyEnum[model.dividends.dividendParameters.frequency],
        dividendGrowth: model.dividends.dividendParameters.dividendGrowth,
        firstDividendDate: DateExtensions.toOADate(model.dividends.dividendParameters.firstDividendDate as Date),
        fixedDividendStartTime: model.dividends.dividendParameters.fixedDividendStartTime,
        dividendCCY: model.dividends.dividendCurrencySelected
      }];
    },
    type: ExcelFieldDataTypes.Schedule,
  };
}
