const buildNumber = '{{BUILD_NUMBER_WILL_BE_REPLACED_IN_POST_BUILD_STEP}}';

export class LvLicencingUtil {
  public static getCopyright(): string {
    const now = new Date();

    return `© ${now.getFullYear()} Leversys Ltd or its affiliates`;
  }

  public static parseBuildNumber(isDevelopmentEnvironment: boolean): string {
    if (isDevelopmentEnvironment) {
      return 'Development build';
    }

    try {
      const split = buildNumber.split('.');
      const buildVersion = split.pop();
      return `${split.join('.')} (build ${buildVersion})`;
    }
    catch (error) {
      return 'Build version not available';
    }
  }
}
