import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
  forwardRef, Input, ChangeDetectorRef, ViewRef, Injector, Type, HostBinding, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, ValidationErrors,
  AbstractControl, NgControl } from '@angular/forms';
import { v4 } from 'uuid';
import { LvLocalDatePipe } from '../../pipes/lv-local-date/lv-local-date.pipe';

@Component({
  selector: 'lv-datepicker',
  templateUrl: './lv-datepicker.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LvDatepickerComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => LvDatepickerComponent),
    multi: true
  }]
})
export class LvDatepickerComponent implements ControlValueAccessor, Validator, OnInit {

  @Input() checked: boolean;
  @Input() disabled: boolean;

  @Input() min: Date;
  @Input() max: Date;

  @Input() checkBoxLvId: string;
  @Input() datePickerLvId: string;

  public get innerValue(): Date {
    return this._innerValue;
  }
  public set innerValue(value: Date) {
    this._innerValue = value;
    this.onValueChange(this._innerValue);
  }

  @Input()
  set tabIndex(value: number) {
    this._tabIndex = value;
  }
  get tabIndex(): number {
    return this.disabled ? -1 : this._tabIndex;
  }

  @Output() didBlur: EventEmitter<void>;
  @Output() didFocus: EventEmitter<void>;
  @Output() didKeyDownEnter: EventEmitter<void>;
  @Output() didCheckboxValueChange: EventEmitter<boolean>;

  get dateValue(): string {
    return this._datePipe.transform(this.innerValue);
  }

  checkboxId: string;

  onValueChange: (value: any) => void;
  onControlTouched: () => void;

  private _suppressBlur: boolean;

  private _tabIndex: number;
  private _innerValue: Date;
  private _control: NgControl;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _datePipe: LvLocalDatePipe,
    private _injector: Injector
  ) {
    this.checkboxId = v4();
    this.checked = false;
    this.disabled = false;
    this.min = null;
    this.max = null;

    this.checkBoxLvId = '';
    this.datePickerLvId = '';

    this.didBlur = new EventEmitter<void>();
    this.didFocus = new EventEmitter<void>();
    this.didKeyDownEnter = new EventEmitter<void>();
    this.didCheckboxValueChange = new EventEmitter<boolean>();

    this.onValueChange = (value: any) => {};
    this.onControlTouched = () => {};
  }

  @HostBinding('class.lv-datepicker')
  get baseClass(): boolean {
    return true;
  }

  @HostBinding('class.lv-invalid')
  get isLvInvalid(): boolean {
    return false;
  }

  @HostBinding('class.lv-input-field')
  get isLvInputField(): boolean {
    return true;
  }

  ngOnInit() {
    this._control = this._injector.get<NgControl>(NgControl, null);

    if (this._control) {
      this._control.valueAccessor = this;
    }
  }

  writeValue(value: Date): void {
    this._innerValue = value;

    if (!(this._changeDetectorRef as ViewRef).destroyed) {
      this._changeDetectorRef.detectChanges();
    }
  }

  registerOnChange(fn: any): void {
    this.onValueChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onControlTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (!(this._changeDetectorRef as ViewRef).destroyed) {
      this._changeDetectorRef.detectChanges();
    }
  }

  validate(c: AbstractControl): ValidationErrors {
    const isMinValid = this.minValidator();
    const isMaxValid = this.maxValidator();

    if (isMinValid !== null) {
      return isMinValid;
    }

    if (isMaxValid !== null) {
      return isMaxValid;
    }

    return null;
  }

  onBlur() {
    if (!this.disabled && !this._suppressBlur) {
      this.onControlTouched();

      this.didBlur.next();
    }
    else {
      this._suppressBlur = false;
    }
  }

  onFocus() {
    if (!this.disabled) {
      this.didFocus.next();
    }
  }

  checkBoxValueChange() {
    this.didCheckboxValueChange.next(this.checked);
  }

  private minValidator(): any {
    const minErr: any = {
        minError: {
            minValue: this.min,
            value: this._control.value
        }
    };

    return (this._control.value !== null && this.min && this._control.value < this.min) ? minErr : null;
  }

  private maxValidator(): any {
    const maxErr: any = {
        maxError: {
            maxValue: this.max,
            value: this._control.value
        }
    };

    return (this._control.value !== null && this.max && this._control.value > this.max) ? maxErr : null;
  }
}
