// tslint:disable-next-line:max-line-length
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild, OnDestroy, Optional, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { constants } from '@lv-core-ui/util';
import { LvAdvancedGridComponent, LvAdvancedGridColumn, LvAdvancedGridDateColumn,
         LvAdvancedGridNumericColumn } from '@lv-core-ui/components';
import { LvError } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { MarketDataClipboard } from '@lv-analytics/components';
import { CustomCouponData, CustomCouponScheduleItem } from '@lv-convertible-bond/models';
import { LvExcelService } from '@lv-excel/services';

@Component({
  selector: 'lv-custom-coupon',
  templateUrl: './lv-custom-coupon.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCustomCouponComponent implements OnInit, OnChanges, OnDestroy {
  @Input() model: CustomCouponData;
  @Output() didCustomCouponChange: EventEmitter<CustomCouponData>;

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  private _modelSubscription: Subscription[];

  columns: LvAdvancedGridColumn[];
  scheduleItems: CustomCouponScheduleItem[];
  parseFn: any;
  excelFieldAlias = 'CPN_SCHED_CUSTOM_RANGE';

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAlias)?.editable;
  }

  constructor(
    private _errorService: LvErrorService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.initColumns();
    this.parseFn = this.parserFunction.bind(this);
    this.scheduleItems = [];

    this.didCustomCouponChange = new EventEmitter<CustomCouponData>();
   }

  ngOnInit() {
    this._modelSubscription = [
      this.advancedGrid.didDataChange.subscribe((records: CustomCouponScheduleItem[]) => this.onScheduleChange(records)),
      this.advancedGrid.doReload.subscribe(() => this.onScheduleReload()),
      this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];

    this.scheduleItems = this.model.schedule.map(a => ({ ...a }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.hasScheduleInExcelOverride) {
      this.initColumns();
    }
    if (changes.model.currentValue.schedule !== changes.model.previousValue?.schedule) {
      this.scheduleItems = changes.model.currentValue.schedule.map(a => ({ ...a }));
    }
  }

  customCouponModelChange() {
    this.didCustomCouponChange.next(this.model);
  }

  createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return  new FormGroup({
      'date': new FormControl(args.isNew ? new Date() : args.dataItem.date, Validators.required),
      'value': new FormControl(args.dataItem.value, Validators.required),
    });
  }

  private initColumns() {
    this.columns = [];

    const dateColumn = new LvAdvancedGridDateColumn();
    dateColumn.title = 'End Date';
    dateColumn.field = 'date';
    dateColumn.dmKey = 'DM-2259';

    const valueColumn = new LvAdvancedGridNumericColumn();
    valueColumn.title = 'Coupon (%)';
    valueColumn.field = 'value';
    valueColumn.outputFormat = constants.numberFormat.upToFourDigits;
    valueColumn.format = '#,###.####';
    valueColumn.decimals = '4';
    valueColumn.dmKey = 'DM-2260';

    this.columns.push(dateColumn);
    this.columns.push(valueColumn);
  }

  private parserFunction(pastedDataRecords: string[]): CustomCouponScheduleItem[] {
    const scheduleItems: CustomCouponScheduleItem[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');

      const dateValue = items[0];
      const valueValue = items[1];

      const date = MarketDataClipboard.parseDateValue(dateValue, 'Date');
      const value = MarketDataClipboard.parseNumberValue(valueValue, 'Value');

      scheduleItems.push({
        date: date,
        value: value
      } as CustomCouponScheduleItem);
    });

    return scheduleItems;
  }

  private onScheduleChange(scheduleItems: CustomCouponScheduleItem[]) {
    this.model.schedule.splice(0, this.model.schedule.length);
    this.model.schedule.push(...scheduleItems);
    this.customCouponModelChange();
  }

  private onScheduleReload() {
    this.scheduleItems = this.scheduleItems.map(a => ({ ...a }));
  }

  private onError(error: LvError) {
    this._errorService.handleError(error);
  }

  ngOnDestroy() {
    this._modelSubscription.forEach(s => s.unsubscribe());
  }

}
