import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
         OnDestroy, ChangeDetectorRef, OnChanges, ElementRef, Optional } from '@angular/core';
import { ConvertibleBondTermsSectionEvent, CallMakeWholeType } from '@lv-convertible-bond/models';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';
import { LvExcelService } from '@lv-excel/services';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { LvConvertibleBondTermsPresenter, UpdateRatioCommand } from '../lv-convertible-bond-terms.presenter';
import { LvMakeWholeView } from './lv-make-whole.view';

@Component({
  selector: 'lv-make-whole',
  templateUrl: './lv-make-whole.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvMakeWholeComponent implements OnInit, OnChanges, OnDestroy {

  get callMakeWholeCheckBoxEnabled(): boolean {
    return this.view.issueAndRedemption && !this.view.issueAndRedemption.isCallable;
  }

  get conversionMakeWholeCheckBoxEnabled(): boolean {
    return !this.view.isConversionMakeWholeEnabled;
  }

  get isExcelInstrument(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  get compensationValueType(): string {
    return this.isExcelInstrument ? 'Comp. Value Type' : 'Compensation Value Type';
  }

  view: LvMakeWholeView;
  conversionRatio: number;
  nominal: number;

  private _subscriptions: Subscription[];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvConvertibleBondTermsPresenter,
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.view = new LvMakeWholeView(_lvConvertibleBondTermsService);
  }

  async ngOnInit() {
    this._subscriptions = [
      this._presenter.onModelUpdated
        .pipe(
          filter(event => event.eventId !== ConvertibleBondTermsSectionEvent.MakeWholeEvent &&
                event.eventId !== ConvertibleBondTermsSectionEvent.MWCallsEvent &&
                event.eventId !== ConvertibleBondTermsSectionEvent.MWInitCallsEvent &&
                event.eventId !== ConvertibleBondTermsSectionEvent.CallIssueAndRedemptionEvent &&
                event.eventId !== ConvertibleBondTermsSectionEvent.MWConversionEvent &&
                event.eventId !== ConvertibleBondTermsSectionEvent.MWInitConversionEvent &&
                event.eventId !== ConvertibleBondTermsSectionEvent.MakeWholeConversionEvent &&
                event.eventId !== ConvertibleBondTermsSectionEvent.MWTakeoverProtectionEvent)
        )
        .subscribe(event => {
          if (event.data) {
            this.view.init(
              this._presenter.ratio,
              event.data.fullTerms.call,
              event.data.fullTerms.conversion,
              event.data.fullTerms.issueAndRedemption,
              event.data.fullTerms.coupon,
              event.data.fullTerms.takeoverProtection,
              this.isExcelInstrument
            );
          }

          this.onModelInitializedChange();
          this._changeDetectorRef.detectChanges();
        }),

      this._presenter.onCommandExecuted.subscribe(command => {
        if (command instanceof UpdateRatioCommand) {
          this.view.updateRatio(this._presenter.ratio);

          this._changeDetectorRef.detectChanges();
        }
      })
    ];

    if (this._presenter.isModelLoaded()) {
      const fullTerms = this._presenter.getModel().fullTerms;
      this.view.init(this._presenter.ratio,
                      fullTerms.call,
                      fullTerms.conversion,
                      fullTerms.issueAndRedemption,
                      fullTerms.coupon,
                      fullTerms.takeoverProtection,
                      this.isExcelInstrument);

      this._changeDetectorRef.detectChanges();
    }
  }

  ngOnChanges() {
    this.view.setConversionMWDates();
    this._changeDetectorRef.detectChanges();
  }

  callMakeWholeChange() {
    this.view.onCallMakeWholeChanged();
    this.onModelChanged();
  }

  conversionMakeWholeChange() {
    this.view.onConversionMakeWholeChanged();
    this.onModelChanged();
  }

  onCallMakeWholeChange() {
    if (this.view.call.callMakeWhole && this.view.call.callMakeWhole.type === CallMakeWholeType.RatchetMatrix) {
      this.view.takeoverProtection.ratchetMatrixMembers = this.view.call.callMakeWhole.ratchetMatrixMembers;
      this.view.customMakeWholeData.offerValueType = this.view.call.callMakeWhole.ratchetMatrixOfferValueType;
      this.view.customMakeWholeData = {...this.view.customMakeWholeData};

      this._presenter.updateModel({
        eventId: ConvertibleBondTermsSectionEvent.MWTakeoverProtectionEvent,
        data: this.view.takeoverProtection
      }, false);
    }

    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.MakeWholeEvent,
      data: this.view.call.callMakeWhole
    });
  }

  onCallMakeWholeConversionChange() {
    if (this.view.conversion.callMakeWhole && this.view.conversion.callMakeWhole.type === CallMakeWholeType.RatchetMatrix) {
      this.view.takeoverProtection.ratchetMatrixMembers = this.view.conversion.callMakeWhole.ratchetMatrixMembers;
      this.view.customMakeWholeData.offerValueType = this.view.conversion.callMakeWhole.ratchetMatrixOfferValueType;
      this.view.customMakeWholeData = {...this.view.customMakeWholeData};

      this._presenter.updateModel({
        eventId: ConvertibleBondTermsSectionEvent.MWTakeoverProtectionEvent,
        data: this.view.takeoverProtection
      }, false);
    }

    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.MakeWholeConversionEvent,
      data: this.view.conversion.callMakeWhole
    });
  }

  onModelChanged() {
    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.MWConversionEvent,
      data: this.view.conversion
    }, false);

    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.MWCallsEvent,
      data: this.view.call
    });
  }

  onModelInitializedChange() {
    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.MWInitConversionEvent,
      data: this.view.conversion
    }, false);

    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.MWInitCallsEvent,
      data: this.view.call
    });
  }

  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  getMakeWholeTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

  /**
   * Start date checkbox changed event.
   * @param value value of model.
   */
  starDateChecboxChanged(value: boolean): void {
    this.view.starDateChecboxChanged(value);
    this.onModelChanged();
  }

  /**
   * End date checkbox changed event.
   * @param value value of model.
   */
  endDateChecboxChanged(value: boolean): void {
    this.view.endDateChecboxChanged(value);
    this.onModelChanged();
  }
}
