// tslint:disable-next-line:max-line-length
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { v4 } from 'uuid';
import * as _ from 'lodash';
import { LvErrorService } from '@lv-core-ui/services';
import { IToolsState, IDeltaNeutralState, IDifferentDeltaExecutionState, IBreakEvenState } from '@lv-analytics/models';

/**
 * Tools custom selection component.s
 */
@Component({
  selector: 'lv-tools-custom-selection',
  templateUrl: './lv-tools-custom-selection.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvToolsCustomSelectionComponent implements OnInit {

  @Output()
  didSave: EventEmitter<IToolsState>;

  @Input()
  set toolsState(value: IToolsState) {
    if (value) {
      this._toolsState = _.cloneDeep(value);
      this._originalToolsState = value;
    }
  }
  get toolsState(): IToolsState {
    return this._toolsState;
  }
  _originalToolsState: IToolsState;
  _toolsState: IToolsState;

  isLoading: boolean;
  deltaNeutralId: string;
  convertibleAndStockValuesId: string;
  fxDeltaId: string;
  stockEQCcyAndCrossFxDnId: string;
  actualDeltaId: string;
  pnlDnId: string;
  differentDeltaExecutionId: string;
  convertibleValueId: string;
  stockEQCcyAndCrossFxDdeId: string;
  priceChangesId: string;
  commisionId: string;
  pnlDdeId: string;
  breakEvenId: string;
  atParityId: string;
  atParId: string;
  atZeroStockPriceId: string;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private dialogRef: DialogRef,
    private errorService: LvErrorService
  ) {
    this.didSave = new EventEmitter<IToolsState>();
    this._toolsState = {
      deltaNeutralState: {} as IDeltaNeutralState,
      differentDeltaExecutionState: {} as IDifferentDeltaExecutionState,
      breakEvenState: {} as IBreakEvenState
    } as IToolsState;

    this._originalToolsState = {} as IToolsState;
    this.isLoading = false;

    this.deltaNeutralId = v4();
    this.convertibleAndStockValuesId = v4();
    this.fxDeltaId = v4();
    this.stockEQCcyAndCrossFxDnId = v4();
    this.actualDeltaId = v4();
    this.pnlDnId = v4();
    this.differentDeltaExecutionId = v4();
    this.convertibleValueId = v4();
    this.stockEQCcyAndCrossFxDdeId = v4();
    this.priceChangesId = v4();
    this.commisionId = v4();
    this.pnlDdeId = v4();
    this.breakEvenId = v4();
    this.atParityId = v4();
    this.atParId = v4();
    this.atZeroStockPriceId = v4();
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {

  }

  /**
   * Closes dialog.
   */
  onCancel() {
    this.dialogRef.close();
  }

  /**
   * Occurs on save and saves tools state.
   */
  onSave() {
    this.isLoading = true;
    Object.keys(this._toolsState).forEach(key => {
      this._originalToolsState[key] = this._toolsState[key];
    });
    this.didSave.emit(this._originalToolsState);
    this.isLoading = false;
    this.dialogRef.close();
  }

  /**
   * Occurs on delta neutral check change and sets subsection fields state.
   */
  onDeltaNeutralCheckChange() {
    this.setSubsectionFieldsState(
      this.toolsState.deltaNeutralState,
      this.toolsState.deltaNeutralState.isDeltaNeutral
    );
  }

  /**
   * Occurs on different delta execution check change and sets subsection fields state.
   */
  onDifferentDeltaExecutionCheckChange() {
    this.setSubsectionFieldsState(
      this.toolsState.differentDeltaExecutionState,
      this.toolsState.differentDeltaExecutionState.isDifferentDeltaExecution
    );
  }

  /**
   * Occurs on breakeven check change and sets subsection fields state.
   */
  onBreakEvenCheckChange() {
    this.setSubsectionFieldsState(
      this.toolsState.breakEvenState,
      this.toolsState.breakEvenState.isBreakeven
    );
  }

  /**
   * Occurs on check change.
   */
  onCheckChange() {
    this.checkGroupCheckState(this.toolsState.deltaNeutralState, 'isDeltaNeutral');
    this.checkGroupCheckState(this.toolsState.differentDeltaExecutionState, 'isDifferentDeltaExecution');
    this.checkGroupCheckState(this.toolsState.breakEvenState, 'isBreakeven');
  }

  /**
   * Sets subsection fields state.
   * @param sectionState Section state.
   * @param status Status.
   */
  private setSubsectionFieldsState(sectionState: any, status: boolean) {
    Object.keys(sectionState).forEach(key => {
      sectionState[key] = status;
    });
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Checks group and state.
   * @param group Group.
   * @param groupPropertyName Group property name.
   */
  private checkGroupCheckState(group: any, groupPropertyName: string) {
    const isAnyGroupItemChecked = Object.keys(group)
      .filter(a => a !== groupPropertyName)
      .filter(a => group[a])
      .length !== 0;

    group[groupPropertyName] = isAnyGroupItemChecked;
  }
}
