import { LvDataMaster } from '../../../app/leversys-core-ui/models/lv-data-master';

export class LvUtil {

  static implementsInterface<T>(obj: any, functionName: string): obj is T {
    return (obj as T)[functionName] !== undefined;
  }

  static async timeout(ms = 0): Promise<any> {
    return new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }

  // param key {String}
  // param reverse {Boolean} OrderByDescending
  // param primer {Function} Transform function
  static sortBy(key: string, reverse: boolean, primer?: (value: any) => any): any {
    const prime = (value: any): any => {
      return primer ? primer(value[key]) : value[key];
    };

    const compareFunction: (a: any, b: any) => number = (a, b) => {
      const primeA = prime(a) || '';
      const primeB = prime(b) || '';
      const order = [1, -1][+!!reverse];

      return ((primeA < primeB) ? -1 : (primeA > primeB) ? 1 : 0) * order;
    };

    return compareFunction;
  }

  static toDictionary<T>(array: T[], field: string, isSimple = false): { [field: string]: T } {
    return array.reduce((acc: any, current: T, index: number) => {
      if (isSimple) {
        acc[current] = true;
      } else {
        acc[current[field]] = current;
      }

      return acc;
    }, {});
  }

  static clone<T>(context?: T, update?: (clonedContext: T) => void): T {
    const cloned: T = { ...context as any };

    if (update) {
      update(cloned);
    }

    return cloned;
  }

  static jsonStringify(obj: any): string | null {
    let asString = null;

    try {
      asString = JSON.stringify(obj);
    }
    catch (error) {
      asString = null;
    }

    return asString;
  }

  static jsonParse<T>(parsed: string): T | null {
    let asObject: T = null;

    try {
      asObject = JSON.parse(parsed);
    }
    catch (error) {
      asObject = null;
    }

    return asObject;
  }

  static convertToBase64(b: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();

      fr.onloadend = () => {
        resolve(fr.result.toString());
      };

      fr.onerror = () => {
        fr.abort();
        reject(new Error(LvDataMaster.getError('dM-1917')));
      };

      fr.readAsDataURL(b);
    });
  }

  static cloneClass<T>(obj: T): T {
    return Object.assign(Object.create(Object.getPrototypeOf(obj)), obj);
  }
}
