import { Injectable } from '@angular/core';

import { IDefaultWidgetState } from '../models/default-widget-state';

@Injectable()
export abstract class LvSharedDefaultWidgetStateService {
  abstract getDefaultWidgetState(widgetType: string): IDefaultWidgetState;
  abstract loadDefaultWidgetStates(isOpenedFromExcel: boolean): Promise<IDefaultWidgetState[]>;
  abstract saveDefaultWidgetState(state: IDefaultWidgetState): Promise<void>;
}
