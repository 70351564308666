<div class="external-wrapper">
  <div class="external-container">
    <div class="external-content">
      <ng-content select="[content]"></ng-content>
      <div class="external-application-info">
        <div>{{ buildNumber }}</div>
        <div>{{ copyright }}</div>
      </div>
    </div>
  </div>
</div>