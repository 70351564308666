import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter,
  HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { IPricingEnvironmentSettings } from '@lv-analytics/models/company-and-user-settings/environments-settings';
import { DialogService } from '@progress/kendo-angular-dialog';
import { LvEditEnvironmentSettingsDialogComponent } from './lv-edit-environment-settings-dialog/lv-edit-environment-settings-dialog.component';

/**
 * Environment setup settings component.
 */
@Component({
  selector: 'lv-environment-setup-settings',
  templateUrl: './lv-environment-setup-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEnvironmentSetupSettingsComponent {

  @Input() settings: IPricingEnvironmentSettings[];

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onSavePricingEnvironmentSettings: EventEmitter<IPricingEnvironmentSettings>;

  contextMenuItems: any[];

  constructor(
    private _dialogService: DialogService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    this.onSavePricingEnvironmentSettings = new EventEmitter<IPricingEnvironmentSettings>();
    this.settings = [];
    this.contextMenuItems = [
      {
        text: 'Edit environment'
      }
    ];
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-environment-setup-settings')
  get isLvEnvironmentSetupSettingsComponent() {
    return true;
  }

  /**
   * Open edit pricing evironment modal
   * @param item Pricing enironments identifier
   */
  onSelect(item: any) {
    const pricingEnvironment = {...this.settings.find(x => x.id === item.target.getAttribute('data-id'))};

    const dialogRef = this._dialogService.open({
      title: 'Environment Setup',
      content: LvEditEnvironmentSettingsDialogComponent,
      width: 545,
      height: 216
    });

    dialogRef.dialog.location.nativeElement.classList.add('lv-environment-setup-settings-dialog-wrapper');

    const dialog = dialogRef.content.instance as LvEditEnvironmentSettingsDialogComponent;
    dialog.pricingEnvironmentSettings = pricingEnvironment;

    dialog.onSavePricingEnvironmentSettings.subscribe(event => {
      this.onSavePricingEnvironmentSettings.next(event);
      this._changeDetectorRef.detectChanges();
    });
  }

  /**
   * Format environment status description for table representation.
   * Function is called from HTML
   * @param status string which is true or false that describes if environment is enabled or disabled
   * @returns Status string representation
   */
  getStatusDescription(status: string): string {
    return status ? 'Disabled' : 'Enabled';
  }
}
