import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { INewIssueSectionSettings } from '@lv-analytics/models';
import { v4 } from 'uuid';

/**
 * New Issue section settings.
 */
@Component({
  selector: 'lv-new-issue-section-settings',
  templateUrl: './lv-new-issue-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvNewIssueSectionSettingsComponent implements OnInit {

  @Input() settings: INewIssueSectionSettings;
  @Input() overrideSystemDefaults: boolean;

  useAssumedRadioButtonId: string;
  useBestRadioButtonId: string;
  useMidRadioButtonId: string;
  useWorstRadioButtonId: string;

  radioButtonName: string;

  constructor() {
    this.useAssumedRadioButtonId = v4();
    this.useBestRadioButtonId = v4();
    this.useMidRadioButtonId = v4();
    this.useWorstRadioButtonId = v4();
    this.radioButtonName = v4();

    this.overrideSystemDefaults = true;
  }

  @HostBinding('class.lv-new-issue-section-settings')
  get isNewIssueSectionSettingsComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return false;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Gets name.
   * @param name Name.
   * @returns New name.
   */
  getName(name: string): string {
    return `${name}-${this.radioButtonName}`;
  }
}
