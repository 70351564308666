import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
  Input, Output, EventEmitter, ChangeDetectorRef, ElementRef, Optional } from '@angular/core';
import { v4 } from 'uuid';
import { LvLookupEnum } from '@lv-core-ui/util';
import { EquityToCreditCalculationTypeDescription, IEquityLinkedCredit } from '@lv-analytics/models/market-data/credit';
import { LvExcelService } from '@lv-excel/services';

/**
 * Equity linked credit component.
 */
@Component({
  selector: 'lv-equity-linked-credit',
  templateUrl: './lv-equity-linked-credit.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEquityLinkedCreditComponent implements OnInit {

  @Output() doSaveSession: EventEmitter<void>;

  @Input()
  set equityLinkedCredit(value: IEquityLinkedCredit) {
    this._equityLinkedCredit = value;
    if (this._equityLinkedCredit) {
      this.changeDetectorRef.detectChanges();
    }
  }
  get equityLinkedCredit(): IEquityLinkedCredit {
    return this._equityLinkedCredit;
  }

  _equityLinkedCredit: IEquityLinkedCredit;

  equityToCreditModelId: string;

  numberOfDecimalsPercetenge = '2';
  numberFormat = '#.##';
  numberOfDecimalsBps = '0';
  numberFormatBps = 'n0';

  constructor(private changeDetectorRef: ChangeDetectorRef,
              @Optional() private _excelSvc: LvExcelService) {
    this.equityToCreditCalibrationTypeSource = new LvLookupEnum(EquityToCreditCalculationTypeDescription);
    this.equityToCreditModelId = v4();
    this.doSaveSession = new EventEmitter<void>();
  }

  equityToCreditCalibrationTypeSource: LvLookupEnum;

  get hasCreditEquityLinkedRangeOverridedFromExcel(): boolean {
    return !!this._excelSvc?.hasCreditEquityLinkedRangeOverridedFromExcel();
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Occurs equity lined credit change.
   */
  onEquityLinkedCreditChange() {
   this.doSaveSession.emit();
  }

  /**
   * Gets credit tooltip ID.
   * @param element HTML Element.
   * @param sectionId Section ID.
   * @returns Credit tooltip ID.
   */
  getCreditTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('credit-tooltip-id') === sectionId;
  }
}
