import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { ConversionAveragingEnum } from 'src/app/modules/analytics/models/company-and-user-settings/valuation-settings/warnings/conversion-averaging-enum';
// tslint:disable-next-line: max-line-length
import { IWarningsSectionSettings } from 'src/app/modules/analytics/models/company-and-user-settings/valuation-settings/warnings/warnings-section-settings';
import { v4 } from 'uuid';

/**
 * Warnings section settings component.
 */
@Component({
  selector: 'lv-warnings-section-settings',
  templateUrl: './lv-warnings-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvWarningsSectionSettingsComponent {

  @Input() settings: IWarningsSectionSettings;
  @Input() overrideSystemDefaults: boolean;

  radioButtonName: string;
  options: string[];

  constructor() {
    this.settings = {} as IWarningsSectionSettings;
    this.overrideSystemDefaults = true;
    this.radioButtonName = v4();
    this.options = Object.keys(ConversionAveragingEnum);
  }

  @HostBinding('class.lv-warnings-section-settings')
  get isLvWarningsSectionSettings() {
    return true;
  }

  /**
   * Gets id of radio button.
   * @param name Radio button name.
   * @returns Radio button ID.
   */
  getId(name: string) {
    return `${name.toLowerCase()}-${this.radioButtonName}`;
  }

  /**
   * Gets Lv ID.
   * @param name Name.
   * @returns Lv ID.
   */
  getLvId(name: string) {
    return `lv-id-warnings-section-settings-${name.toLowerCase()}`;
  }
}
