export {};

declare global {
  interface Array<T> {
      sortBy(sortBy: string): Array<T>;
      any(): boolean;
  }
}

/**
 * Sorts array based on property provided in string name
 */
Array.prototype.sortBy = function(property: string) {
  return this.sort((x: any, y: any) => {
    if (x[property] > y[property]) {
      return 1;
    } else if (x[property] < y[property]) {
      return -1;
    } else {
      return 0;
    }
  });
};

/**
 * Checks if array has any value.
 */
Array.prototype.any = function() {
  return this.length > 0;
}