import { LvDataMaster, LvError, LvErrorType } from '@lv-core-ui/models';
import { LvLocalization, LvMath } from '@lv-core-ui/util';
import moment from 'moment';

/**
 * Market data clipboard.
 */
export class MarketDataClipboard {

  /**
   * Occurs on paste data.
   * @param event ClipboardEvent object.
   * @param records List of records.
   * @param parsePastedData Parse pasted data.
   */
  static onPaste<S>(
    event: ClipboardEvent,
    records: S[],
    parsePastedData: (records: string[]) => S[]
  ) {
    const pastedData = event.clipboardData.getData('text');
    const pastedDataRecords = pastedData.split('\n')
      .map(r => r.trim())
      .filter(r => r.replace('\t', '') !== '');
    const pastedRecords = parsePastedData(pastedDataRecords);
    records.splice(0, records.length, ...pastedRecords);
  }

  /**
   * Parses date value.
   * @param dateValue Date value.
   * @param propertyName Property name.
   * @returns Parsed date.
   */
  static parseDateValue(dateValue: string, propertyName: string = ''): Date {
    this.validateNotEmpty(dateValue, propertyName);

    const userLocale = LvLocalization.getBrowserLocale();
    moment.locale(userLocale);
    const dateFormat = moment.localeData().longDateFormat('L');
    const momentDate = moment(dateValue, dateFormat);

    if (momentDate.isValid() && isNaN(Number(dateValue))) {
      return momentDate.toDate();
    }
    else {
      this.throwPasteError(LvDataMaster.getError('dM-2003', {
        'value': dateValue,
        'field': propertyName
      }));
    }
  }

  /**
   * This method checks if given value is empty. In that case it will return
   * undefined.
   * In case of non-empty numberValue, it'll try to parse it and to throw an exception
   * if parsing fails.
   * @param numberValue string value to parse to number.
   * @param propertyName Name of the property we're parsing the value for. Used for logging purposes.
   */
  static tryParseNumberValue(numberValue: string, propertyName: string = ''): number {
    if (this.isEmpty(numberValue)) {
      return undefined;
    } else {
      return this.parseNumberValue(numberValue, propertyName);
    }
  }

  /**
   * Parsing given string value to number.
   * It will fail in case of empty value or value that can't be parsed to number.
   * Returns float number.
   * @param numberValue string value to parse to number.
   * @param propertyName Name of the property we're parsing the value for. Used for logging purposes.
   */
  static parseNumberValue(numberValue: string, propertyName: string = ''): number {
    this.validateNotEmpty(numberValue, propertyName);

    if (!LvMath.isNumber(numberValue)) {
      this.throwPasteError(LvDataMaster.getError('dM-2003', {'value': numberValue, 'field': propertyName}));
    }

    return Number.parseFloat(numberValue);
  }

  /**
   * Checking if given string value is empty or not.
   * In case of empty value, it'll throw an exception.
   * Otherwise, given value is returned.
   * @param value Value to inspect.
   * @param propertyName Name of the property we're inspecting the value for. Used for logging purposes.
   */
  static validateNotEmpty(value: string, propertyName: string = ''): string {
    if (this.isEmpty(value)) {
      this.throwPasteError(LvDataMaster.getError('dM-3383', {'fieldName': propertyName}));
    }

    return value;
  }

  /**
   * Checks if value is empty.
   * @param value
   * @returns A flag indicating if value is empty.
   */
  static isEmpty(value: string) {
    return value === '' || value === undefined;
  }

  /**
   * Throws paste error.
   * @param message Error message.
   */
  static throwPasteError(message: string) {
    throw new LvError(message, 'Paste Error', LvErrorType.USER_FRIENDLY);
  }

  /**
   * Format and prepare data to trim after
   * copy paste settings are used to select data
   * @param data Pasted data
   * @param parsedData Data formated to specific format to we can continue with trim and format data for the app tables
   * @param fields Created names for the parsed data columns
   * @param numberOfColumns Maximum number of columns in pasted data
   */
  static preparePastedDataToParse(data: string[], fields: string[], numberOfColumns: number): any[] {
    return  data.map(item => {
      const row = item.split('\t');
      const response = {};

      for (let i = 0; i < numberOfColumns; i++) {
        response[fields[i]] = row[i] ? row[i] : '';
      }

      return response;
    });
  }

  /**
   * Calculate nubmer of pasted data columns and set field names for
   * fromated data
   * @param columnLength Pasted data column length
   * @param data Pasted data
   * @param fields Array of field names for formated data after paste
   */
  static getNumberOfColumnsAndSetFieldsForCopyAndPasteScheduledData(data: string[], fields: string[]): number {
    let columnLength = 0;

    if (data && data.length) {
      data.forEach((element) => {
        const rowColumn = element.split('\t').length;

        if (rowColumn > columnLength) {
          columnLength = rowColumn;
        }
      });
    }

    for (let i = 0; i < columnLength; i++) {
      fields.push(`field${i}`);
    }

    return columnLength;
  }
}
