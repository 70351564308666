import { v4 } from 'uuid';

import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { LvLookupEnum } from '@lv-core-ui/util';
import { ICallAdjustmentModelSettings, HardCallModelTypeDescription, SoftCallModelTypeDescription } from '@lv-analytics/models';

/**
 * Call adjustment section settings component.
 */
@Component({
  selector: 'lv-call-adjustment-section-settings',
  templateUrl: './lv-call-adjustment-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCallAdjustmentSectionSettingsComponent implements OnInit {

  @Input() settings: ICallAdjustmentModelSettings;
  @Input() overrideSystemDefaults: boolean;

  get isBarierModel(): boolean {
    return this.settings.hardCallModelType === HardCallModelTypeDescription.BarrierModel.split(' ').join('');
  }

  get isSoftCallTrigger(): boolean {
    return this.settings.softCallModelType === SoftCallModelTypeDescription.SoftCallTrigger.split(' ').join('');
  }

  numberOfDecimals = '4';
  numberFormat = '#.####';

  useCallNoticeCheckBoxId: string;

  hardCallModelType: LvLookupEnum;
  softCallModelType: LvLookupEnum;

  constructor() {
    this.hardCallModelType = new LvLookupEnum(HardCallModelTypeDescription);
    this.softCallModelType = new LvLookupEnum(SoftCallModelTypeDescription);
    this.useCallNoticeCheckBoxId = v4();
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }
}
