export enum InstrumentStatusDescription {
	NewIssue = 'NewIssue',
	NewIssueFixed = 'NewIssueFixed',
	Redeemed = 'Redeemed',
	Active = 'Active',
	Called = 'Called',
	Matured = 'Matured',
	Defaulted = 'Defaulted'
}

export enum InstrumentStatusGeneralDescription {
	NewIssue = 'New Issue (Range)',
	NewIssueFixed = 'New Issue (Fixed)',
	Redeemed = 'Redeemed',
	Active = 'Terms Fixed',
	Called = 'Called',
	Matured = 'Matured',
	Defaulted = 'Defaulted'
}