import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HubConnectionState } from '@microsoft/signalr';
import { LvLoggerService } from '@lv-core-ui/services';
import { LvHub } from '@lv-core-ui/util';
import { AnalyticsHubService } from '@lv-analytics/hubs';
import {  } from '@lv-application-settings/hubs';
import { InstrumentMonitorHubService } from '@lv-instrument-monitor/hubs';
import { CustomInstrumentHubService } from '@lv-custom-instruments/hubs';
import { ReferenceDataHubService } from '@lv-reference-data/hubs';

@Injectable()
export class OnlinePlatformHubService extends LvHub {

  constructor(
    httpClient: HttpClient,
    logger: LvLoggerService,
    ahs: AnalyticsHubService,
    imhs: InstrumentMonitorHubService,
    rdhs: ReferenceDataHubService,
    pihs: CustomInstrumentHubService,
  ) {
    super(httpClient, logger, 'OnlinePlatformHub');

    ahs.attachEvents(this);
    imhs.attachEvents(this);
    rdhs.attachEvents(this);
    pihs.attachEvents(this);
  }

  public sendAppInstance(instanceId: string) {
    console.log('send ..');
    if (this._connection.state === HubConnectionState.Connected) {
    this._connection.invoke('AppInstance', instanceId)
    .catch(err => console.error(err));
    }
  }
}
