import { Component, ViewEncapsulation, ChangeDetectionStrategy,
  ChangeDetectorRef, ViewChild, ElementRef, OnInit, OnDestroy, ViewRef, Output, EventEmitter, Input, Optional } from '@angular/core';

import { Subscription } from 'rxjs';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';
import * as _ from 'lodash';

// tslint:disable-next-line:max-line-length
import { LvYieldCurveTermsStructureComponent } from './lv-yield-curve-terms-structure/lv-yield-curve-terms-structure.component';
import { InterestRatesView } from './lv-interest-rates.view';
import { IMarketDataComponent } from '../../market-data-component';
import { LvMarketDataPresenter } from '../lv-market-data.presenter';
import { LvErrorService } from '@lv-core-ui/services';
import { IEnvironmentSettingsItem,
         LvEnvironmentSettingsComponent } from '@lv-analytics/components/lv-environment-settings/lv-environment-settings.component';
import { ISaveInterestRatesRequest } from '@lv-analytics/models/market-data/interest-rates/interest-rates-request';
import { ITermStructureItem } from '@lv-analytics/models/market-data/yield-curve/yield-curve-term-structure';
import { PricingEnvironmentSections } from '@lv-analytics/models/enum/pricing-environment-sections';
import { MarketDataService } from '@lv-analytics/services/market-data/market-data.service';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { AnalyticsSettingsEvents } from '@lv-analytics/models/enum/analytics-settings-events';
import { InterestRateSource, YieldCurveSource } from '@lv-analytics/models/market-data/interest-rates/interest-rates-enum';
import { IInterestRate } from '@lv-analytics/models/market-data/interest-rates/interest-rate';
import { IInterestRatesCopyAndPasteSectionSettings } from '@lv-analytics/models';
import { InterestRatesMapper } from '@lv-analytics/models/market-data/interest-rates/interest-rates-mapper';
import { LvExcelService } from '@lv-excel/services';

/**
 * Interest rates component.
 */
@Component({
  selector: 'lv-interest-rates',
  templateUrl: './lv-interest-rates.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvInterestRatesComponent implements OnInit, OnDestroy, IMarketDataComponent<ISaveInterestRatesRequest> {

  @ViewChild('instrumentYieldCurveTerms') instrumentYieldCurveTerms: LvYieldCurveTermsStructureComponent;
  @ViewChild('underlyingYieldCurveTerms') underlyingYieldCurveTerms: LvYieldCurveTermsStructureComponent;
  @ViewChild('flatRateInputInstrument') flatRateInputInstrument: NumericTextBoxComponent;
  @ViewChild('flatRateInputUnderlying') flatRateInputUnderlying: NumericTextBoxComponent;
  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;

  get instrumentLoaded(): boolean {
    return this._analyticsPresenter.asHelper.instrumentLoaded;
  }

  get isCrossFx(): boolean {
    return this._analyticsPresenter.cHelper.isCrossFx;
  }

  get cbCurrency(): string {
    return this._analyticsPresenter.cHelper.currencyCode;
  }

  get eqCurrency(): string {
    return this._analyticsPresenter.cHelper.underlyingCurrencyCode;
  }

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField('IR_CURVE_RANGE_INST')
    || (!!this._excelSvc?.containsField('IR_CURVE_RANGE_T_INST')
      && !!this._excelSvc?.containsField('IR_CURVE_RANGE_R_INST')
      && !!this._excelSvc?.containsField('IR_CURVE_RANGE_DS_INST'));
  }

  get hasScheduleInExcelOverrideUnderlying(): boolean {
    return !!this._excelSvc?.containsField('IR_CURVE_RANGE_UND_INST')
    || (!!this._excelSvc?.containsField('IR_CURVE_RANGE_T_UND_INST')
      && !!this._excelSvc?.containsField('IR_CURVE_RANGE_R_UND_INST')
      && !!this._excelSvc?.containsField('IR_CURVE_RANGE_DS_UND_INST'));
  }

  get scheduleInExcelOverrideAlias(): string {
    if (!!this._excelSvc?.containsField('IR_CURVE_RANGE_INST')) {
      return 'IR_CURVE_RANGE_INST';
    }

    if (!!this._excelSvc?.containsField('IR_CURVE_RANGE_T_INST')
      && !!this._excelSvc?.containsField('IR_CURVE_RANGE_R_INST')
      && !!this._excelSvc?.containsField('IR_CURVE_RANGE_DS_INST')) {
      return 'IR_CURVE_RANGE_T_INST,IR_CURVE_RANGE_R_INST,IR_CURVE_RANGE_DS_INST';
    }

    return '';
  }

  get scheduleInExcelOverrideUnderlyingAlias(): string {
    if (!!this._excelSvc?.containsField('IR_CURVE_RANGE_UND_INST')) {
      return 'IR_CURVE_RANGE_UND_INST';
    }

    if (!!this._excelSvc?.containsField('IR_CURVE_RANGE_T_UND_INST')
      && !!this._excelSvc?.containsField('IR_CURVE_RANGE_R_UND_INST')
      && !!this._excelSvc?.containsField('IR_CURVE_RANGE_DS_UND_INST')) {
      return 'IR_CURVE_RANGE_T_UND_INST,IR_CURVE_RANGE_R_UND_INST,IR_CURVE_RANGE_DS_UND_INST';
    }

    return '';
  }

  get isScheduleInExcelEditable(): boolean {
    return !!this._excelSvc?.getField('IR_CURVE_RANGE_INST')?.editable
    || (!!this._excelSvc?.getField('IR_CURVE_RANGE_T_INST')?.editable
      && !!this._excelSvc?.getField('IR_CURVE_RANGE_R_INST')?.editable
      && !!this._excelSvc?.getField('IR_CURVE_RANGE_DS_INST')?.editable);
  }

  get isScheduleInExcelEditableUnderlying(): boolean {
    return !!this._excelSvc?.getField('IR_CURVE_RANGE_UND_INST')?.editable
    || (!!this._excelSvc?.getField('IR_CURVE_RANGE_T_UND_INST')?.editable
      && !!this._excelSvc?.getField('IR_CURVE_RANGE_R_UND_INST')?.editable
      && !!this._excelSvc?.getField('IR_CURVE_RANGE_DS_UND_INST')?.editable);
  }

  @Output() didSessionUpdatedEvent: EventEmitter<void>;

  view: InterestRatesView;
  originalValue: ISaveInterestRatesRequest;

  instrumentYieldCurveScheduledItems: ITermStructureItem[];
  underlyingYieldCurveScheduledItems: ITermStructureItem[];

  interestRatesSection = PricingEnvironmentSections.InterestRates;

  numberOfDecimalsPercentage = '4';
  numberFormatPercentage = '#.####';

  interestRatesCopyAndPasteSettings: IInterestRatesCopyAndPasteSectionSettings;

  private _subscriptions: Subscription[];
  private readonly _customYieldCurveId = -1;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _marketDataService: MarketDataService,
    private _presenter: LvMarketDataPresenter,
    private _analyticsPresenter: LvAnalyticsPresenter,
    private _interestRatesMapper: InterestRatesMapper,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.instrumentYieldCurveScheduledItems = [];
    this.underlyingYieldCurveScheduledItems = [];

    this.didSessionUpdatedEvent = new EventEmitter<void>();
    this.view = new InterestRatesView(_analyticsPresenter);
    this.interestRatesCopyAndPasteSettings = {} as IInterestRatesCopyAndPasteSectionSettings;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this._subscriptions = [
      this._analyticsPresenter.onModelLoading.subscribe(isLoading => this.setLoadingState(isLoading)),

      this._analyticsPresenter.onOtherSettingsChanged.subscribe(() => {
        this.loadSettings();
      }),

      this._analyticsPresenter.onModelUpdated.subscribe(evt => {
        if (evt && evt.eventId === AnalyticsSettingsEvents.MarketDataUpdated) {
          this.init(evt.data.marketData.interestRates);
          this.loadSettings();
        }
      })
    ];

    if (this._analyticsPresenter.isModelLoaded()) {
      this.loadSettings();
    }
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Occurs on interest rates section change.
   * @param sendEvent A flag indicating if event should be sent.s
   */
  async onInterestRatesSectionChange(sendEvent: boolean = true) {
    try {
      this.view.initTermStructureSettings(this.getSelectedEnvironmentId());
      this.updateInterestRatesModel();

      const request = this.getInterestRatesRequest();
      await this._marketDataService.overrideInterestRates(request);

      if (!_.isEqual(request, this.originalValue)) {
        if (sendEvent) {
          this.didSessionUpdatedEvent.next();
        }

        this.originalValue = _.cloneDeep(request);
      }
    }
    catch (e) {
      this._errorService.handleError(e);
    }
    finally {
    }
  }

  /**
   * Loads settings.
   */
  loadSettings() {
    const modelData = this._analyticsPresenter.getModelData();

    if (this._analyticsPresenter.isModelLoaded()
      && modelData.otherSettings
      && modelData.otherSettings.copyAndPasteSettings) {

        this.interestRatesCopyAndPasteSettings = modelData.otherSettings.copyAndPasteSettings.interestRates;
    }

    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on environment change and loads interest rates.
   * @param environment IEnvironmentSettingsItem object.
   */
  onChangeEnvironment(environment: IEnvironmentSettingsItem) {
    this.loadInterestRates(environment);
  }

  /**
   * Occurs on instrumnent interest rate source change.
   */
  async onInstrumnentInterestRateSourceChange() {
    try {
      this.setLoadingState(true);
      this.view.setInstrumentYieldCurveTermsStructureSettings(this.getSelectedEnvironmentId());
      await this.loadInstrumentYieldCurves();

      this.updateInterestRatesModel();

      await this.onInterestRatesSectionChange();
    }
    catch (e) {
      this._errorService.handleError(e);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on underlying interest rate source change.
   */
  async onUnderlyingInterestRateSourceChange() {
    try {
      this.setLoadingState(true);
      this.view.setUnderlyingYieldCurveTermsStructureSettings(this.getSelectedEnvironmentId());
      await this.loadUnderlyingYieldCurves();

      this.updateInterestRatesModel();

      await this.onInterestRatesSectionChange();
    }
    catch (e) {
      this._errorService.handleError(e);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on instrument yield curve change.
   */
  async onInstrumentYieldCurveChange() {
    try {
      this.setLoadingState(true);
      await this.loadInstrumentYieldCurves();

      this.view.setInstrumentYieldCurveTermsStructureSettings(this.getSelectedEnvironmentId());
      this.updateInterestRatesModel();

      await this.onInterestRatesSectionChange();
    }
    catch (e) {
      this._errorService.handleError(e);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on underlying yield curve change.
   */
  async onUnderlyingYieldCurveChange() {
    try {
      this.setLoadingState(true);
      await this.loadUnderlyingYieldCurves();

      this.view.setUnderlyingYieldCurveTermsStructureSettings(this.getSelectedEnvironmentId());
      this.updateInterestRatesModel();

      await this.onInterestRatesSectionChange();
    }
    catch (e) {
      this._errorService.handleError(e);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Gets interest rates request.
   * @param isSessinSave A flag indicating if session is saved.
   * @returns ISaveInterestRatesRequest object.
   */
  getInterestRatesRequest(isSessinSave: boolean = true) {
    const request = {
      environmentId: this.getSelectedEnvironmentId(),
      lwsIdentifier: this._analyticsPresenter.asHelper.lwsIdentifier,
      instrumentCurrencyCode: this._analyticsPresenter.cHelper.currencyCode,
      underlyingCurrencyCode: this._analyticsPresenter.cHelper.underlyingCurrencyCode,
      interestRates: this.view.interestRatesSettings,
      sessionId: this._analyticsPresenter.asHelper.sessionId,
      instrumentTermsStructure: null,
      underlyingTermsStructure: null
    } as ISaveInterestRatesRequest;

    if (!!this.instrumentYieldCurveTerms) {
      if (!(this.view.interestRatesSettings.instrumentYieldCurveSource === YieldCurveSource.Leversys && isSessinSave === false)) {
        request.instrumentTermsStructure = this._interestRatesMapper
          .mapInterestRatesTermStructureToApi(JSON.parse(JSON.stringify(this.instrumentYieldCurveScheduledItems)));
      }
    }
    if (!!this.underlyingYieldCurveTerms) {
      if (!(this.view.interestRatesSettings.underlyingYieldCurveSource === YieldCurveSource.Leversys && isSessinSave === false)) {
        request.underlyingTermsStructure = this._interestRatesMapper
          .mapInterestRatesTermStructureToApi(JSON.parse(JSON.stringify(this.underlyingYieldCurveScheduledItems)));
      }

    }
    return request;
  }

  /**
   * Gets settings.
   * @returns ISaveInterestRatesRequest object.
   */
  getSettings(): ISaveInterestRatesRequest {
    return this.getInterestRatesRequest(false);
  }

  /**
   * Applies current changes.
   */
  applyCurrentChanges() {
    if (this.view.interestRatesSettings.instrumentInterestRateSource ===
      InterestRateSource.YieldCurve && this.instrumentYieldCurveTerms.advancedGrid) {
      this.instrumentYieldCurveTerms.applyAdvancedGridChanges();
    }

    if (this.isCrossFx && this.view.interestRatesSettings.underlyingInterestRateSource ===
      InterestRateSource.YieldCurve && this.underlyingYieldCurveTerms.advancedGrid) {
        this.underlyingYieldCurveTerms.applyAdvancedGridChanges();
    }
  }

  /**
   * Gets section.
   * @returns PricingEnvironmentSections
   */
  getSection(): PricingEnvironmentSections {
    return this.interestRatesSection;
  }

  /**
   * Get interest rates tootlip ID.
   * @param element HTML element.
   * @param sectionId Section ID.
   * @returns Interest rates tooltip ID.
   */
  getInterestRatesTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('interest-rates-tooltip-id') === sectionId;
  }

  /**
   * Gets selected environment ID.
   * @returns Selected environment ID.
   */
  getSelectedEnvironmentId(): string {
    const env = this.envSettings.getSelectedEnvironment();

    if (env) {
      return env.id;
    }

    return null;
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
   */
  setLoadingState(isLoading: boolean) {
    this.envSettings.setLoadingState(isLoading);
    this._presenter.setLoadingState(isLoading);

    if (!(this._changeDetectorRef as ViewRef).destroyed) {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Loads interest rates.
   * @param environment IEnvironmentSettingsItem object.
   */
  private async loadInterestRates(environment: IEnvironmentSettingsItem) {
    try {
      this.setLoadingState(true);

      const interestRates = await this._marketDataService.loadInterestRates(
        this._analyticsPresenter.asHelper.sessionId,
        this._analyticsPresenter.asHelper.lwsIdentifier,
        environment.id);

      const envId = this.getSelectedEnvironmentId();
      this.view.initInterestRatesSettings(interestRates, envId);

      await Promise.all([
        this.loadInstrumentYieldCurves(),
        this.loadUnderlyingYieldCurves()]
        );

      this.updateInterestRatesModel();
    }
    catch (e) {
      this._errorService.handleError(e);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Loads instrument yield curves.
   * @param init
   */
  private async loadInstrumentYieldCurves(): Promise<void> {
    try {
      this.setLoadingState(true);

      if (!!this.view.interestRatesSettings
        && this.view.interestRatesSettings.instrumentInterestRateSource === InterestRateSource.YieldCurve) {

        if (this.view.interestRatesSettings.yieldCurveId !== this._customYieldCurveId) {
          this.instrumentYieldCurveScheduledItems =
            await this._marketDataService.getSystemYieldCurve(this.cbCurrency, this.view.interestRatesSettings.yieldCurveId);
        }
        else {
          if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_INST')) {
            this.instrumentYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_INST') as ITermStructureItem[];
          }
          else if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_T_INST')) {
            this.instrumentYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_T_INST') as ITermStructureItem[];
          }
          else if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_R_INST')) {
            this.instrumentYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_R_INST') as ITermStructureItem[];
          }
          else if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_DS_INST')) {
            this.instrumentYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_DS_INST') as ITermStructureItem[];
          }
          else if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_INST_SPECIAL')) {
            this.instrumentYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_INST_SPECIAL') as ITermStructureItem[];
          }
          else {
            this.instrumentYieldCurveScheduledItems =
              await this._marketDataService.getCustomYieldCurve(
                this.cbCurrency,
                this.getSelectedEnvironmentId()
              );
          }

          if (!this.instrumentYieldCurveScheduledItems) {
            this.instrumentYieldCurveScheduledItems = [];
          }
        }       
      }
    }
    catch (e) {
      this.instrumentYieldCurveScheduledItems = [];
      throw e;
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Loads underlying yield curves.
   * @param init
   */
  private async loadUnderlyingYieldCurves(): Promise<void> {
    try {
      this.setLoadingState(true);

      if (!!this.view.interestRatesSettings
        && this.view.interestRatesSettings.underlyingInterestRateSource === InterestRateSource.YieldCurve) {

        if (this.view.interestRatesSettings.underlyingYieldCurveId !== this._customYieldCurveId) {
          this.underlyingYieldCurveScheduledItems =
            await this._marketDataService.getSystemYieldCurve(this.eqCurrency, this.view.interestRatesSettings.underlyingYieldCurveId);
        }
        else {
          if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_UND_INST')) {
            this.underlyingYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_UND_INST') as ITermStructureItem[];
          }
          else if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_T_UND_INST')) {
            this.underlyingYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_T_UND_INST') as ITermStructureItem[];
          }
          else if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_R_UND_INST')) {
            this.underlyingYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_R_UND_INST') as ITermStructureItem[];
          }
          else if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_DS_UND_INST')) {
            this.underlyingYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_DS_UND_INST') as ITermStructureItem[];
          }
          else if (!!this._excelSvc?.getFieldValue('IR_CURVE_RANGE_UND_INST_SPECIAL')) {
            // tslint:disable-next-line: max-line-length
            this.underlyingYieldCurveScheduledItems = this._excelSvc.getFieldValue('IR_CURVE_RANGE_UND_INST_SPECIAL') as ITermStructureItem[];
          }
          else {

            this.underlyingYieldCurveScheduledItems =
              await this._marketDataService.getCustomYieldCurve(
                this.eqCurrency,
                this.getSelectedEnvironmentId()
              );
          }

          if (!this.underlyingYieldCurveScheduledItems) {
            this.underlyingYieldCurveScheduledItems = [];
          }
        }
      }
    }
    catch (e) {
      this.underlyingYieldCurveScheduledItems = [];
      throw e;
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Does initialization.
   * @param settings IInterestRate object
   */
  private async init(settings: IInterestRate) {
    try {
      this.setLoadingState(true);

      const availableInstrumentSystemYielCurves = await this._marketDataService.getAvailableSystemYieldCurves(this.cbCurrency);

      //When you click on reload on draft private instrument that is not saved so it results with empty instrument
      if(availableInstrumentSystemYielCurves.length === 0){
        return;
      }

      if (settings.yieldCurveId && settings.yieldCurveId !== -1
        && availableInstrumentSystemYielCurves.findIndex(x => x.id === settings.yieldCurveId) < 0) {
        settings.yieldCurveId = availableInstrumentSystemYielCurves.find(x => x.isDefault).id;
      }

      if (!settings.yieldCurveId) {
        settings.yieldCurveId = availableInstrumentSystemYielCurves.find(x => x.isDefault)?.id;
      }

      let availableUnderlyingSystemYielCurves = [];

      if (this.isCrossFx) {
        availableUnderlyingSystemYielCurves = await this._marketDataService.getAvailableSystemYieldCurves(this.eqCurrency);
        if (settings.underlyingYieldCurveId  && settings.underlyingYieldCurveId !== -1
          && availableUnderlyingSystemYielCurves.findIndex(x => x.id === settings.underlyingYieldCurveId) < 0) {
          settings.underlyingYieldCurveId = availableUnderlyingSystemYielCurves.find(x => x.isDefault).id;
        }

        if (!settings.underlyingYieldCurveId) {
          settings.underlyingYieldCurveId = availableUnderlyingSystemYielCurves.find(x => x.isDefault)?.id;
        }
      }

      this.view.initAvailableSystemYieldCurves(availableInstrumentSystemYielCurves, availableUnderlyingSystemYielCurves);

      const tenors = await this._marketDataService.getAllTenors();
      this.view.initTenors(tenors);

      this.view.initInterestRatesSettings(settings, this.getSelectedEnvironmentId());

      await Promise.all([
        await this.loadInstrumentYieldCurves(),
        await this.loadUnderlyingYieldCurves()
      ]);

      this.originalValue = _.cloneDeep(this.getInterestRatesRequest());
    }
    catch (e) {
      this._errorService.handleError(e);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Updates interest rates model.
   */
  private async updateInterestRatesModel() {
    this._analyticsPresenter.updateModel({
      eventId: AnalyticsSettingsEvents.InterestRatesUpdated,
      data: this.view.interestRatesSettings
    });
  }
}
