import { Directive, Input, ElementRef, QueryList } from '@angular/core';

@Directive({
  selector: '[lvScrollTo]'
})
export class LvScrollToDirective {

  @Input() lvScrollTo: string;

  constructor(
    private elementRef: ElementRef<HTMLElement>
  ) { }

  static scrollTo(sectionElements: QueryList<LvScrollToDirective>, containerElement: ElementRef<HTMLElement>, sectionId: string) {
    if (sectionElements) {
      const sectionElement = sectionElements.find(a => a.lvScrollTo === sectionId);

      if (sectionElement) {
        const sectionRect = sectionElement.getElementRect();
        const containerRect = containerElement.nativeElement.getBoundingClientRect();

        const scrollTop = containerElement.nativeElement.scrollTop;

        containerElement.nativeElement.scroll({
          behavior: 'smooth',
          top: sectionRect.top - containerRect.top + scrollTop
        });
      }
    }
  }

  getElementRect(): ClientRect | DOMRect {
    return this.elementRef.nativeElement.getBoundingClientRect();
  }
}
