<div class="lv-multi-select">
  <div class="lv-multi-select-header">
    <input kendoTextBox
      lvId="multiSelectSearchInput"
      [placeholder]="searchPlaceholder"
      [(ngModel)]="searchQuery"/>
  </div>
  <lv-flex-box class="lv-multi-select-content" direction="row">
    <div class="lv-multi-select-left">
      <div class="lv-multi-select-list-title">
        {{ listTitle }}
      </div>
        <div *ngIf= "!showTooltips; else displaySelectedTooltip" class="lv-sortable-list lv-multi-select-list">
          <div  class="lv-sortable-list-item"
            *ngFor="let item of availableItems | searchArray : searchQuery : displayField"
            (click)="onMove(item)">
            {{ item[displayField] }}
          </div>
        </div>
      <ng-template #displaySelectedTooltip>
      <div class="lv-sortable-list lv-multi-select-list">
        <div class="lv-sortable-list-item"
        *ngFor="let item of availableItems | searchArray : searchQuery : displayField"
        (click)="onMove(item)"
        showAfter="1500"
        kendoTooltip
        filter=".lv-sortable-list-item"
        [attr.tooltip-id]="item.type"
        [tooltipTemplate]= "tooltipTemplate">
        <span class="lv-label">
          {{ item[displayField] }}
        </span> 
        </div>
      </div>
      </ng-template>
    </div>
    <div class="lv-multi-select-center">
      <lv-flex-box class="lv-multi-select-center-table">

        <div class="lv-multi-select-center-table-cell-empty-space">
          &nbsp;
        </div>

        <div class="lv-multi-select-center-table-cell lv-multi-select-center-table-cell-arrow-right">
          <i class="k-icon k-i-sort-asc-sm"
            [class.lv-multi-select-center-table-cell-icon--disabled]="isAvailableItemsEmpty"
            lvId="move-all-to-selected"
            (click)="onMoveAllToSelected()">
          </i>
        </div>

        <div class="lv-multi-select-center-table-cell lv-multi-select-center-table-cell-arrow-left">
          <i class="k-icon k-i-sort-desc-sm"
            [class.lv-multi-select-center-table-cell-icon--disabled]="isSelectedItemsEmpty"
            lvId="move-all-to-available"
            (click)="onMoveAllToAvailable()">
          </i>
        </div>

        <div class="lv-multi-select-center-table-cell-empty-space">
          &nbsp;
        </div>

      </lv-flex-box>
    </div>
    <div class="lv-multi-select-right">
      <div class="lv-multi-select-list-title">
          {{ selectedListTitle }}
      </div>
      <kendo-sortable *ngIf="isSortable"
        class="lv-sortable-list lv-multi-select-list"
        [animation] = "true"
        emptyText=""
        (dragStart)="onDragStart()"
        (dragEnd)="onDragEnd()"
        [kendoSortableBinding]="selectedItems">
        <ng-template let-item="item">
          <div *ngIf= "!showTooltips; else displaySelectedTooltip" class="lv-sortable-list-item"
            (click)="onMove(item, false)">
             <i class="k-icon k-i-more-vertical"></i>
             <span >{{ item[displayField] }}</span>
           </div>
           <ng-template #displaySelectedTooltip>
             <div class="lv-sortable-list-item"
             (click)="onMove(item, false)"
             showAfter="1500"
            kendoTooltip
            filter=".lv-sortable-list-item"
            [attr.tooltip-id]="item.type"
            [tooltipTemplate]= "tooltipTemplate"
            >
            <span  class="lv-label">
                {{ item[displayField] }}
             </span>
            </div>
           </ng-template>
        </ng-template>
      </kendo-sortable>
      <div *ngIf="!isSortable"
        class="lv-sortable-list lv-multi-select-list">
        <div class="lv-sortable-list-item"
          *ngFor="let item of selectedItems | searchArray : searchQuery : displayField"
          (click)="onMove(item, false)"
          howAfter="1500"
          kendoTooltip
          filter=".lv-sortable-list-item"
          [attr.tooltip-id]="item.type"
          [tooltipTemplate]= "tooltipTemplate">
          <span class="lv-label">
          {{ item[displayField] }}
          </span>
        </div>
      </div>
    </div>
  </lv-flex-box>
  <ng-template #tooltipTemplate let-anchor>
    <ng-container  [ngTemplateOutlet]= 'tooltips'
    [ngTemplateOutletContext]="{ $implicit: anchor}">
    </ng-container>
  </ng-template>