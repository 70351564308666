import { v4 } from 'uuid';
import { Injectable, OnDestroy } from '@angular/core';

import { Subscription ,  Subject } from 'rxjs';

import { LvNotificationManagerService } from './lv-notification-manager.service';

export type LvNotifierType = 'publisher' | 'subscriber';

export enum ILvNotificationEventType {
  READY_TO_LINK,
  READY_TO_LINK_CANCELED,
  LINK_ACCEPTED,
  LINK_CANCELED,
  MESSAGE,
}

export interface ILvNotificationEvent {
  type: ILvNotificationEventType;
  from: {
    id: string;
    color?: string;
  };
  to?: {
    [key: string]: boolean;
  };
  data?: any;
}

export interface ILvNotifier {
  id: string;
  type: LvNotifierType;
  color?: string;

  notify: Subject<ILvNotificationEvent>;
  receive: Subject<ILvNotificationEvent>;
  receiveSubscription: Subscription;
}

export interface ILvNotifierState {
  id: string;
  type: LvNotifierType;
  color?: string;
}
@Injectable()
export class LvNotifier implements ILvNotifier, OnDestroy {
  id: string;
  type: LvNotifierType;
  color?: string;

  notify: Subject<ILvNotificationEvent>;
  receive: Subject<ILvNotificationEvent>;
  receiveSubscription: Subscription;

  messageReceived: Subject<ILvNotificationEvent>;

  constructor(private lvNotificationManagerService: LvNotificationManagerService) {
    this.color = null;

    this.notify = new Subject<ILvNotificationEvent>();
    this.receive = new Subject<ILvNotificationEvent>();
    this.messageReceived = new Subject<ILvNotificationEvent>();
  }

  init(notifierId?: string) {
    this.id = notifierId || v4();
    this.lvNotificationManagerService.register(this);
  }

  sendCancelLink() {
    throw new Error('Not implemented');
  }

  removeSubscriptions() {
    if (this.receiveSubscription) {
      this.receiveSubscription.unsubscribe();
      this.receiveSubscription = null;
    }
  }

  unregister() {
    this.sendCancelLink();
    this.removeSubscriptions();
    this.lvNotificationManagerService.unregister(this);
  }

  ngOnDestroy() {
    this.removeSubscriptions();
  }
}
