import { DateExtensions, LvDateUtil } from '@lv-core-ui/util';
import * as moment from 'moment';
export function calculateDateFromYear(year: number, firstSettlementDate: Date): Date {
    let mFirst;
    const inputYear = Math.floor(year);
    const inputMonth = Math.floor(12 * (year - inputYear));
    const diff = (12 * ((Math.round(year * 10) / 10) - inputYear)) - inputMonth;


    const today = moment.utc(new Date());
    const adjustDays = getAdjustDays(today.weekday());

    mFirst = firstSettlementDate ?  moment.utc(firstSettlementDate) : today.add(adjustDays, 'days');


    const datum = moment.utc({...mFirst});
    mFirst = moment.utc(new Date(datum.year() + inputYear, datum.toDate().getMonth() + inputMonth, datum.toDate().getDate()));

    // mFirst = mFirst.add(inputYear, 'y');
    // mFirst = mFirst.add(inputMonth, 'M');

    if (diff !== 0) {
      const days = diff * mFirst.daysInMonth();
      const dateDiff = moment.utc({...mFirst});
      mFirst = moment.utc(new Date(dateDiff.year(), dateDiff.toDate().getMonth(), dateDiff.toDate().getDate() + Math.floor(days)));
      // mFirst = mFirst.add(Math.floor(days), 'd');
    }
    return mFirst.toDate();
  }

export function calculateEndDateFromYear(year: number, firstSettlementDate: Date): Date {
    let mFirst;
    const inputYear = Math.floor(year);
    const inputMonth = Math.floor(12 * (year - inputYear));
    const diff = (12 * ((Math.round(year * 10) / 10) - inputYear)) - inputMonth;

    const today = moment.utc(new Date());
    const adjustDays = getAdjustDays(today.weekday());

    mFirst = firstSettlementDate ?  moment.utc(firstSettlementDate) : today.add(adjustDays, 'days');

    const datum = moment.utc({...mFirst});
    mFirst = moment.utc(new Date(datum.year() + inputYear, datum.toDate().getMonth() + inputMonth, datum.toDate().getDate()));

    if (diff !== 0) {
      mFirst = mFirst.add(diff * mFirst.daysInMonth(), 'd');
    }
    
    const minus = -1;
    const endDate = new Date(mFirst);
    mFirst = moment.utc(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + minus));

    return mFirst.toDate();
  }

export function getAdjustDays(weekDay: number): number {
    if (weekDay === 6) {
      return 5;
    }
    if (weekDay === 7) {
      return 4;
    }

    return 3;
  }

/**
 * Return date for Terms date fields.
 * @param date - the date from terms.
 * If this parameter is null  we will return null.
 */ 
export function getDateValue(date: Date) {
  return date ? new Date(LvDateUtil.toISOString(date)) : date;
}

/**
 * Return First Settlement Date like number.
 * @param firstSettlement - the parameter from override lists.
 * If this parameter is null  we will use Today.
 */
export function getFirstSettlementDate(firstSettlement: any): number {
  const today = new Date(LvDateUtil.toISOString(new Date()));
  return firstSettlement ? DateExtensions.getTimeAtStartOfDay(new Date(firstSettlement)) : DateExtensions.getTimeAtStartOfDay(today);
}

/**
 * Return Maturity Date like number.
 * @param maturity - the parameter from override lists.
 * If this parameter is null  we will return null.
 */
export function getMaturityDate(maturity: any): number {
  return maturity ? DateExtensions.getTimeAtStartOfDay(new Date(maturity)) : null;
}