import { Injectable } from '@angular/core';

import { ICreditRequest } from './credit-request';
import { ICreditSessionRequest } from './credit-session-request';
import { ICredit } from './credit';
import { ICreditTermStructureItem } from './credit-term-structure-item';
import { ICreditParameters } from './credit-parameters';
import { DateExtensions } from '@lv-core-ui/util';

/**
 * Credit mapper.
 */
@Injectable()
export class CreditMapper {

  /**
   * Maps save request to API.
   * @param borrowSaveRequest ICreditRequest object.
   * @returns ICreditRequest object.
   */
  mapSaveRequestToApi(creditSaveRequest: ICreditRequest): ICreditRequest {
    creditSaveRequest.credit = this.mapCreditToApi(creditSaveRequest.credit);
    return creditSaveRequest;
  }
  /**
   * Maps update request to API.
   * @param borrowUpdateRequest ICreditSessionRequest object.
   * @returns ICreditSessionRequest object.
   */
  mapUpdateRequestToApi(creditUpdateRequest: ICreditSessionRequest): ICreditSessionRequest {
    creditUpdateRequest.credit = this.mapCreditToApi(creditUpdateRequest.credit);
    return creditUpdateRequest;
  }

  /**
   * Maps credit to UI.
   * @param borrowRequest ICredit object.
   * @returns ICredit object.
   */
  mapCreditToApi(credit: ICredit): ICredit {
    const request = {
      assetParameters: credit.assetParameters,
      creditSource: credit.creditSource,
      equityLinkedCredit: credit.equityLinkedCredit,
      stochasticCreditModel: credit.stochasticCreditModel,
      underlyingCreditSource: credit.underlyingCreditSource,
      issuerCreditParameters: this.mapCreditParametersToApi(credit.issuerCreditParameters),
      underlyingCreditParameters: this.mapCreditParametersToApi(credit.underlyingCreditParameters),
      underlyingEquityLinkedCredit: credit.underlyingEquityLinkedCredit,
      underlyingStochasticCreditModel: credit.underlyingStochasticCreditModel
    } as ICredit;

    return request;
  }

  /**
   * Maps credit request to be in form readable to C# code (dates)
   * @param creditRequest Credit request
   * @returns mapped request
   */
  mapCreditToUi(creditRequest: ICredit): ICredit {

    const request = {
      assetParameters: creditRequest.assetParameters,
      creditSource: creditRequest.creditSource,
      equityLinkedCredit: creditRequest.equityLinkedCredit,
      stochasticCreditModel: creditRequest.stochasticCreditModel,
      underlyingCreditSource: creditRequest.underlyingCreditSource,
      issuerCreditParameters: this.mapCreditParametersToUi(creditRequest.issuerCreditParameters),
      underlyingCreditParameters: this.mapCreditParametersToUi(creditRequest.underlyingCreditParameters),
      underlyingEquityLinkedCredit: creditRequest.underlyingEquityLinkedCredit,
      underlyingStochasticCreditModel: creditRequest.underlyingStochasticCreditModel
    } as ICredit;

    return request;
  }

  /**
   * Maps credit parameters to API.
   * @param parameters ICreditParameters object.
   * @returns ICreditParameters object.
   */
  private mapCreditParametersToApi(parameters: ICreditParameters): ICreditParameters {

    const creditParameters =  {
      accruedOnDefault: parameters.accruedOnDefault,
      badDayConversion: parameters.badDayConversion,
      basis: parameters.basis,
      cdsIndexLoss: parameters.cdsIndexLoss,
      cdsSpread: parameters.cdsSpread,
      cdsUpfront: parameters.cdsUpfront,
      creditModelFactor: parameters.creditModelFactor,
      creditSpreadType: parameters.creditSpreadType,
      defaultRate: parameters.defaultRate,
      flatCreditSpread: parameters.flatCreditSpread,
      frequency: parameters.frequency,
      jumpOnUnderlyingDefault: parameters.jumpOnUnderlyingDefault,
      recoveryRateBond: parameters.recoveryRateBond,
      recoveryRateCDS: parameters.recoveryRateCDS,
      valuationDate: parameters.valuationDate,
      creditTermStructure: this.mapTermStructureItemsToApi(parameters.creditTermStructure),
      creditCDSTermStructure: this.mapTermStructureItemsToApi(parameters.creditCDSTermStructure),
      creditDefaultRateTermStructure: this.mapTermStructureItemsToApi(parameters.creditDefaultRateTermStructure),
      creditSurvivalProbabilityTermStructure: this.mapTermStructureItemsToApi(parameters.creditSurvivalProbabilityTermStructure)
    } as ICreditParameters;

    return creditParameters;
  }

  /**
   * Maps credit parameters to UI.
   * @param parameters ICreditParameters object.
   * @returns ICreditParameters object.
   */
  private mapCreditParametersToUi(parameters: ICreditParameters): ICreditParameters {

    const creditParameters =  {
      accruedOnDefault: parameters.accruedOnDefault,
      badDayConversion: parameters.badDayConversion,
      basis: parameters.basis,
      cdsIndexLoss: parameters.cdsIndexLoss,
      cdsSpread: parameters.cdsSpread,
      cdsUpfront: parameters.cdsUpfront,
      creditModelFactor: parameters.creditModelFactor,
      creditSpreadType: parameters.creditSpreadType,
      defaultRate: parameters.defaultRate,
      flatCreditSpread: parameters.flatCreditSpread,
      frequency: parameters.frequency,
      jumpOnUnderlyingDefault: parameters.jumpOnUnderlyingDefault,
      recoveryRateBond: parameters.recoveryRateBond,
      recoveryRateCDS: parameters.recoveryRateCDS,
      valuationDate: parameters.valuationDate,
      creditTermStructure: this.mapTermStructureItemsToUi(parameters.creditTermStructure),
      creditCDSTermStructure: this.mapTermStructureItemsToUi(parameters.creditCDSTermStructure),
      creditDefaultRateTermStructure: this.mapTermStructureItemsToUi(parameters.creditDefaultRateTermStructure),
      creditSurvivalProbabilityTermStructure: this.mapTermStructureItemsToUi(parameters.creditSurvivalProbabilityTermStructure)

    } as ICreditParameters;

    return creditParameters;
  }

  /**
   * Maps terms structure items to API.
   * @param scheduleItems List of ICreditTermStructureItem objects.
   * @returns List of ICreditTermStructureItem objects.
   */
  private mapTermStructureItemsToApi(scheduleItems: ICreditTermStructureItem[]): ICreditTermStructureItem[] {
    const scheduleItemsRequest = [] as ICreditTermStructureItem[];
    scheduleItems?.forEach(a => {
      const scheduleItemRequest = {
        date: DateExtensions.getTimeWithOffset(a.date as Date),
        upfront: a.upfront,
        value: a.value
      } as ICreditTermStructureItem;
      scheduleItemsRequest.push(scheduleItemRequest);
    });

    return scheduleItemsRequest;
  }
  /**
   * Maps terms structure items to UI.
   * @param scheduleItems List of ICreditTermStructureItem objects.
   * @returns List of ICreditTermStructureItem objects.
   */
  private mapTermStructureItemsToUi(scheduleItems: ICreditTermStructureItem[]): ICreditTermStructureItem[] {
    const scheduleItemsRequest = [] as ICreditTermStructureItem[];
    scheduleItems.forEach(a => {
      const scheduleItemRequest = {
        date: DateExtensions.getDateWithoutOffset(a.date as number),
        upfront: a.upfront,
        value: a.value
      } as ICreditTermStructureItem;
      scheduleItemsRequest.push(scheduleItemRequest);
    });

    return scheduleItemsRequest;
  }

}
