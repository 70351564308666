<lv-flex-box class="lv-estimates-settings-section">
  <lv-flex-box
    class="lv-estimates-settings-section-price-format"
    direction="row">
    
    <div class="lv-label lv-label-field lv-checkbox-row-label"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="useCurrentOrEstimatedCPSettingsTooltipTemplate"
      filter="label">
      <label>Use Current or Estimated Conversion Price</label>
    </div>

    <lv-flex-box
      data-cy="DM-1156"
      class="lv-estimates-radio-buttons"
      direction="row">

      <div class="lv-input-field lv-field lv-field--radio">
        <input class="k-radio"
          type="radio"
          lvId="lv-estimates-radio-buttons-current"
          [disabled]="!overrideSystemDefaults"
          [attr.id]="useCurrentConversionPriceRadioButtonId"
          [name]="conversionPriceCcyRadioButtonName"
          [value]="false"
          [(ngModel)]="settings.useEstimatedRatio">
        <label class="k-radio-label"
          [attr.for]="useCurrentConversionPriceRadioButtonId"
          lvId="lv-new-issue-settings-system-defaults-use-best">
            Current
        </label>
      </div>
      <div class="lv-input-field lv-field lv-field--radio">
        <input class="k-radio"
          type="radio"
          lvId="lv-estimates-radio-buttons-estimate"
          [disabled]="!overrideSystemDefaults"
          [attr.id]="useEstimateConversionPriceRadioButtonId"
          [name]="conversionPriceCcyRadioButtonName"
          [value]="true"
          [(ngModel)]="settings.useEstimatedRatio">
        <label class="k-radio-label"
          [attr.for]="useEstimateConversionPriceRadioButtonId"
          lvId="lv-new-issue-settings-system-defaults-use-worst">
            Estimate
        </label>
      </div>
    </lv-flex-box>
  </lv-flex-box>

  <lv-flex-box
    class="lv-estimates-settings-section-price-format"
    direction="row">

    <div class="lv-label lv-label-field lv-checkbox-row-label"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="displayCPOrRatioSettingsTooltipTemplate"
      filter="label">
      <label>Display Conversion Price or Ratio</label>
    </div>

    <lv-flex-box
      data-cy="DM-1157"
      class="lv-estimates-radio-buttons"
      direction="row">

      <div class="lv-input-field lv-field lv-field--radio">
        <input class="k-radio"
          type="radio"
          lvId="lv-estimates-radio-buttons-conversion-price"
          [disabled]="!overrideSystemDefaults"
          [attr.id]="displayConversionPriceByDefaultRadioButtonId"
          [name]="displayConversionPriceRationRadioButtonName"
          [value]="true"
          [(ngModel)]="settings.showConversionPrice">
        <label class="k-radio-label"
          [attr.for]="displayConversionPriceByDefaultRadioButtonId"
          lvId="lv-new-issue-settings-system-defaults-use-best">
            Conversion Price
        </label>
      </div>
      <div class="lv-input-field lv-field lv-field--radio">
        <input class="k-radio"
          type="radio"
          lvId="lv-estimates-radio-buttons-conversion-ratio"
          [disabled]="!overrideSystemDefaults"
          [attr.id]="displayConversionRatioByDefaultRadioButtonId"
          [name]="displayConversionPriceRationRadioButtonName"
          [value]="false"
          [(ngModel)]="settings.showConversionPrice">
        <label class="k-radio-label"
          [attr.for]="displayConversionRatioByDefaultRadioButtonId"
          lvId="lv-new-issue-settings-system-defaults-use-worst">
            Conversion Ratio
        </label>
      </div>
    </lv-flex-box>
  </lv-flex-box>
  
  <lv-flex-box
    data-cy="DM-1154"
    class="lv-estimates-settings-section-price-format"
    direction="row">

    <div class="lv-label lv-label-field lv-checkbox-row-label"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="displayCPInSettingsTooltipTemplate"
        filter="label">
      <label>Display Conversion Price in</label>
    </div>

    <div class="lv-input-field lv-field lv-field--radio">
      <input class="k-radio"
        type="radio"
        lvId="lv-estimates-radio-buttons-convertible-ccy"
        [disabled]="!overrideSystemDefaults || ratioSelected"
        [attr.id]="convertibleCcyRadioButtonId"
        [name]="displayConversionPriceInRadioButtonName"
        [value]="'ConvertibleCurrency'"
        [(ngModel)]="settings.selectedCurrency">
      <label class="k-radio-label"
        [attr.for]="convertibleCcyRadioButtonId"
        lvId="lv-new-issue-settings-system-defaults-use-best">
          Convertible CCY
      </label>
    </div>
    <div class="lv-input-field lv-field lv-field--radio">
      <input class="k-radio"
        type="radio"
        lvId="lv-estimates-radio-buttons-underlying-ccy"
        [disabled]="!overrideSystemDefaults || ratioSelected"
        [attr.id]="underlyingCcyRadioButtonId"
        [name]="displayConversionPriceInRadioButtonName"
        [value]="'UnderlyingCurrency'"
        [(ngModel)]="settings.selectedCurrency">
      <label class="k-radio-label"
        [attr.for]="underlyingCcyRadioButtonId"
        lvId="lv-new-issue-settings-system-defaults-use-mid">
          Underlying CCY
      </label>
    </div>
    <div class="lv-input-field lv-field  lv-field--radio">
      <input class="k-radio"
        type="radio"
        lvId="lv-estimates-radio-buttons-convertible-price-ccy"
        [disabled]="!overrideSystemDefaults || ratioSelected"
        [attr.id]="conversionPriceCcyRadioButtonId"
        [name]="displayConversionPriceInRadioButtonName"
        [value]="'ConversionPriceCurrency'"
        [(ngModel)]="settings.selectedCurrency">
      <label class="k-radio-label"
        [attr.for]="conversionPriceCcyRadioButtonId"
        lvId="lv-new-issue-settings-system-defaults-use-worst">
          Conversion Price CCY
      </label>
    </div>
  </lv-flex-box>
</lv-flex-box>

<ng-template #useCurrentOrEstimatedCPSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1156"></lv-tooltip>
</ng-template>
<ng-template #displayCPOrRatioSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1157"></lv-tooltip>
</ng-template>
<ng-template #displayCPInSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1154"></lv-tooltip>
</ng-template>
