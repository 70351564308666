/**
 * Outputs percent of par.
 */
export enum OutputsPercentOfPar {
  theoreticalValue = 'pts',
  cheapness = 'pts',
  cheapnessPerYear = 'pts',
  cheapnessPerExpLife = 'pts',
  parity = 'pts',
  parityPremium = 'pts',
  parityPremiumClean = 'pts',
  bondValue = 'pts',
  bondPremium = 'pts',
  accretedValue = 'pts',
  redemptionValue = 'pts',
  accruedInterest = 'pts',
  fXDelta = 'pts',
  vega = 'pts',
  volga = 'pts',
  theta = 'pts',
  cSSensIss = 'pts',
  rho = 'pts',
  rhoUnd = 'pts',
  cSSens = 'pts',
  creditVega = 'pts',
  stockBorrowSens = 'pts',
  dividendSens = 'pts',
  recoveryRateSens = 'pts',
  deltaFXImplVol = 'pts',
  vegaImplVol = 'pts',
  volgaImplVol = 'pts',
  thetaImplVol = 'pts',
  rhoImplVol = 'pts',
  cSSensIssImplVol = 'pts',
  creditVegaImplVol = 'pts',
  rhoUndImplVol = 'pts',
  cSSensImplVol = 'pts',
  stockBorrowSensImplVol = 'pts',
  dividendSensImplVol = 'pts',
  recoveryRateSensImplVol = 'pts',
  deltaFXImplSpread = 'pts',
  vegaImplSpread = 'pts',
  volgaImplSpread = 'pts',
  thetaImplSpread = 'pts',
  rhoImplSpread = 'pts',
  cSSensIssImplSpread = 'pts',
  creditVegaImplSpread = 'pts',
  rhoUndImplSpread = 'pts',
  cSSensImplSpread = 'pts',
  stockBorrowSensImplSpread = 'pts',
  dividendSensImplSpread = 'pts',
  recoveryRateSensImplSpread = 'pts',
  remainingMW = 'pts',
}
