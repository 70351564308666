import { LvUtil } from './../../util/util';

export interface ILvGridLayoutColumn {
  cells: any[];
  isLast: boolean;
}

export interface ILvGridLayout {
  columns: ILvGridLayoutColumn[];
  columnWidth: number;

  initialize: (cells: any[]) => void;
  calculateColumns: (newWidth?: number) => void;
}

export class LvGridLayout implements ILvGridLayout {

  columns: ILvGridLayoutColumn[];
  columnWidth: number;

  private columnCount: number;
  private cells: any[];
  private gridWidth: number;
  private forceColumnsCalculation: boolean;

  constructor(columnWidth: number) {
    this.cells = [];
    this.columns = [];
    this.columnCount = 0;
    this.columnWidth = columnWidth;
    this.gridWidth = 0;
    this.forceColumnsCalculation = false;
  }

  initialize(cells: any[]) {
    this.cells = cells.map(a => {
      if (typeof a === 'object' || typeof a === 'function') {
        return Object.assign({}, a);
      }

      return a;
    });

    this.forceColumnsCalculation = true;
    this.calculateInternal();
    this.forceColumnsCalculation = false;
  }

  update(cells: any[], update: (previous: any, current: any) => void, fieldName: string = null) {
    const isSimple = !fieldName;
    const cellsDict = LvUtil.toDictionary(cells, fieldName, isSimple);

    this.cells.forEach(a => {
      const currentValue = cellsDict[`${isSimple ? a : a[fieldName]}`];
      if (currentValue) {
        update(a, currentValue);
      }
    });
  }

  calculateColumns(newWidth?: number) {

    if (newWidth) {
      this.gridWidth = newWidth || 0;
    }

    this.calculateInternal();
  }

  private calculateInternal() {
    const newColumnCount = Math.floor(this.gridWidth / this.columnWidth) || 1; // it cannot have zero columns

    if (newColumnCount === this.columnCount && !this.forceColumnsCalculation) {
      return;
    }

    const cellsPerColumn = Math.ceil(this.cells.length / newColumnCount);
    this.columns = [];

    Array.from({ length: newColumnCount }).forEach((c, i) => {
      const offset = i * cellsPerColumn;
      this.columns.push({
        cells: this.cells.slice(offset, offset + cellsPerColumn),
        isLast: i === (newColumnCount - 1)
      });
    });

    this.columnCount = newColumnCount;
  }
}
