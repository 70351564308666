import * as _ from 'lodash';

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientBase } from '@lv-core-ui/api';
import { APPLICATION_INSTANCE_ID } from '@lv-core-ui/models';
import { IDefaultWidgetStatesResponse, LvDefaultWidgetStateError } from '@lv-application-settings/models';
import { IDefaultWidgetState } from '@lv-shared/models';
import { LvSharedDefaultWidgetStateService } from '@lv-shared/services';

/**
 * Default widget state service containing methods for handling widget state
 */
@Injectable()
export class DefaultWidgetStateService extends HttpClientBase implements LvSharedDefaultWidgetStateService {

  public _defaultWidgetStates: IDefaultWidgetState[];

  private _applicationInstanceId: string;

  constructor(
    http: HttpClient,
    @Inject(APPLICATION_INSTANCE_ID) applicationInstanceId: string
  ) {
    super(http, '/defaultWidgetState');

    this._applicationInstanceId = applicationInstanceId;

    this._defaultWidgetStates = [];
  }

  /**
   * Get default widget state
   * @param {string} widgetType Type of a widget
   * @returns IDefaultWidgetState object
   */
  getDefaultWidgetState(widgetType: string): IDefaultWidgetState {
    const found = this._defaultWidgetStates.find(a => a.widgetType === widgetType);
    if (found) {
      return _.cloneDeep(found);
    }

    return null;
  }

  /**
   * Load user default widget states
   * @param {boolean} isOpenedFromExcel Boolean value whether is instrument opened in excel form or not
   * @returns Promise based IDefaultWidgetState list of items
   */
  async loadDefaultWidgetStates(isOpenedFromExcel: boolean): Promise<IDefaultWidgetState[]> {
    try {
      const response = await this.getAsync<IDefaultWidgetStatesResponse>({isOpenedFromExcel: isOpenedFromExcel});

      this._defaultWidgetStates = response.widgetStates;

      return response.widgetStates;
    }
    catch (error) {
      throw this.handleError(
        error,
        e => new LvDefaultWidgetStateError(e.message),
        true);
    }
  }

  /**
   * Saves widget state as default
   * @param {IDefaultWidgetState} state Default widget state
   */
  async saveDefaultWidgetState(state: IDefaultWidgetState): Promise<void> {
    try {
      await this.postAsync<void>({
        sourceId: this._applicationInstanceId,
        state: state
      }, null, null);

      const cloned = _.cloneDeep(state);
      const found = this._defaultWidgetStates.find(a => a.widgetType === state.widgetType);

      if (found) {
        found.state = cloned.state;
      }
      else {
        this._defaultWidgetStates.push(state);
      }
    }
    catch (error) {
      throw this.handleError(
        error,
        e => new LvDefaultWidgetStateError(e.message),
        true);
    }
  }
}
