import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, HostBinding, ElementRef } from '@angular/core';
import { IFundingSettings, IFundingSectionSettings } from '@lv-analytics/models';

/**
 * Funding settings component.
 */
@Component({
  selector: 'lv-funding-settings',
  templateUrl: './lv-funding-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvFundingSettingsComponent implements OnInit {

  @Input() settings: IFundingSettings;

  constructor() {
    this.settings = {
      systemDefaults: {} as IFundingSectionSettings,
      environments: []
    };
  }

  @HostBinding('class.lv-funding-settings')
  get isLvFundingSettingsComponent() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
   getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }
}
