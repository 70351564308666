<div class="lv-convertible-bond-terms-make-whole-conversion-parameters">
    <lv-flex-box direction="row" data-cy="DM-774">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="makeWholeTypeSourceTooltipTemplate"
        filter="span:not(lv-xl-label span)">
        <span [attr.data-tooltip-id]="'makeWholeTypeSourceTooltip'">
          Make-Whole Type
        </span>
        <lv-xl-label [field]="'MW_TYPE'"></lv-xl-label>
      </div>
      <div class="lv-input-field lv-input-field--auto">
        <kendo-dropdownlist
          class="lv-make-whole-type"
          name="makeWholeType"
          [data]="makeWholeTypeLookup.items"
          valueField="id"
          textField="text"
          [popupSettings]="{ width: 160 }"
          [valuePrimitive]="true"
          [(ngModel)]="model.type"
          (ngModelChange)="makeWholeTypeChanged()">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box *ngIf="couponsIncludedUntilVisible" direction="row" data-cy="DM-777">
      <div class="lv-label lv-label-field lv-label-before-checkbox"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="couponsIncludedUntilSourceTooltipTemplate"
        filter="span:not(lv-xl-label span)">
        <span [attr.data-tooltip-id]="'couponsIncludedUntilSourceTooltip'">
          {{couponIncludedUntil}}
        </span>
        <lv-xl-label [field]="'MW_CPN_END_DATE'"></lv-xl-label>
      </div>
      <lv-datepicker
        checkBoxLvId = "mwConversionCouponIncludeCheckBoxId"
        name="mwConversionCouponIncludePickerId"
        [(ngModel)]="couponsIncludedUntil"
        [checked]="couponsIncludedUntilEnabled"
        (ngModelChange)="doModelChange()"
        (didCheckboxValueChange)="couponIncludedUntilCheckBoxChanged($event)">
      </lv-datepicker>
    </lv-flex-box>
    <lv-flex-box *ngIf="discountFieldsVisible" direction="row" data-cy="DM-778">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="discountRateTypeSourceTooltipTemplate"
        filter="span:not(lv-xl-label span)">
        <span [attr.data-tooltip-id]="'discountRateTypeSourceTooltip'">
          Discount Rate Type
        </span>
        <lv-xl-label [field]="'MW_CPN_DSC_TYPE'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <kendo-dropdownlist
          name="discountRateType"
          [data]="discountRateTypeLookup.items"
          valueField="id"
          textField="text"
          [valuePrimitive]="true"
          [(ngModel)]="model.discountRateType"
          (ngModelChange)="doModelChange()">
        </kendo-dropdownlist>
      </div>
    </lv-flex-box>
    <lv-flex-box *ngIf="discountFieldsVisible" direction="row" data-cy="DM-779">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="discountSpreadRateSourceTooltipTemplate"
        filter="span:not(lv-xl-label span)">
        <span [attr.data-tooltip-id]="'discountSpreadRateSourceTooltip'">
          Discount Spread / Rate
        </span>
        <lv-xl-label [field]="'MW_CPN_DSC_VALUE'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="discountSpread"
          [format]='formatZero'
          [decimals]='decimalsZero'
          [(ngModel)]="model.discountSpread"
          (didBlur)="doModelChange()"
          (didKeyDownEnter)="doModelChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">bps</span>
      </div>
    </lv-flex-box>
    <lv-flex-box *ngIf="initialPremiumVisible" direction="row" data-cy="DM-775">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="initialPremiumPercentageSourceTooltipTemplate"
        filter="span:not(lv-xl-label span)">
        <span [attr.data-tooltip-id]="'initialPremiumPercentageSourceTooltip'">
          Initial Premium
        </span>
        <lv-xl-label [field]="'MW_INTL_PRM'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          name="initialPremium"
          [format]="formatNumber"
          [decimals]="decimalsNumber"
          [(ngModel)]="model.initialPremium"
          (didBlur)="doModelChange()"
          (didKeyDownEnter)="doModelChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
    </lv-flex-box>
    <lv-flex-box direction="row" *ngIf="couponsIncludedUntilVisible" data-cy="DM-776">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="rollingMakeWholePeriodSourceTooltipTemplate"
        filter="span:not(lv-xl-label span)">
        <span [attr.data-tooltip-id]="'rollingMakeWholePeriodSourceTooltip'">
          {{rollingMWPeriod}}
        </span>
        <lv-xl-label [field]="'MW_ROLLING_PERIOD'"></lv-xl-label>
      </div>
      <div class="lv-label lv-label-field">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="rollingMakeWholePeriodCheckBoxId"
          name="rollingMakeWholePeriod"
          [(ngModel)]="model.rollingMWPeriod"
          (ngModelChange)="doModelChange()"/>
        <label class="k-checkbox-label" [attr.for]="rollingMakeWholePeriodCheckBoxId"></label>
      </div>
    </lv-flex-box>
    <lv-flex-box *ngIf="couponsIncludedUntilVisible && model.rollingMWPeriod" direction="row" data-cy="DM-2425">
      <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="rollingCouponMWPeriodSourceTooltipTemplate"
        filter="span:not(lv-xl-label span)">
        <span [attr.data-tooltip-id]="'rollingCouponMWPeriodSourceTooltip'">
          {{rollingCouponMWPeriod}}
        </span>
        <lv-xl-label [field]="'MW_ROLLING_PERIOD'"></lv-xl-label>
      </div>
      <div class="lv-input-field">
        <lv-numeric-text-box
          [format]="formatNumber"
          [decimals]="decimalsNumber"
          name="remainingCouponMWPeriod"
          [(ngModel)]="model.remainingCouponMWPeriod"
          (didBlur)="doModelChange()"
          (didKeyDownEnter)="doModelChange()">
        </lv-numeric-text-box>
        <span class="lv-sufix-label">yrs</span>
      </div>
    </lv-flex-box>
</div>

<ng-template #makeWholeTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-774"></lv-tooltip>
</ng-template>
<ng-template #couponsIncludedUntilSourceTooltipTemplate>
  <lv-tooltip dm="dM-777"></lv-tooltip>
</ng-template>
<ng-template #discountRateTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-778"></lv-tooltip>
</ng-template>
<ng-template #discountSpreadRateSourceTooltipTemplate>
  <lv-tooltip dm="dM-779"></lv-tooltip>
</ng-template>
<ng-template #initialPremiumPercentageSourceTooltipTemplate>
  <lv-tooltip dm="dM-775"></lv-tooltip>
</ng-template>
<ng-template #rollingMakeWholePeriodSourceTooltipTemplate>
  <lv-tooltip dm="dM-776"></lv-tooltip>
</ng-template>
<ng-template #rollingCouponMWPeriodSourceTooltipTemplate>
  <lv-tooltip dm="dM-2425"></lv-tooltip>
</ng-template>
