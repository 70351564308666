import { ChangeDetectionStrategy, Component, ContentChild, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IBaseTermsSettings } from '@lv-convertible-bond/models';
import { LvMyDefaultsSettingsDirective } from './directives/lv-my-defaults.directive';
import { LvSystemDefaultsSettingsDirective } from './directives/lv-system-defaults.directive';

export type TermsSectionSettings = IBaseTermsSettings<any>;

@Component({
  selector: 'lv-terms-section-settings',
  templateUrl: './lv-terms-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvTermsSectionSettingsComponent implements OnInit {

  @ContentChild(LvSystemDefaultsSettingsDirective)
  public systemDefaults: LvSystemDefaultsSettingsDirective;

  @ContentChild(LvMyDefaultsSettingsDirective)
  public myDefaultsSettings: LvMyDefaultsSettingsDirective;

  @Input() systemDefault: TermsSectionSettings;
  @Input() myDefault: TermsSectionSettings;

  constructor() {
    this.systemDefault = {
      overrideSystemDefaults: false,
      settings: {}
    };
    this.myDefault = {
      overrideSystemDefaults: false,
      settings: {}
    };
  }

  ngOnInit() {
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.overflow-sections')
  get isShownScrollbar() {
    return document.documentElement.clientHeight > 800;
  }

}
