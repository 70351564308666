<div class="documents">
  <lv-subscriber-panel
    [state]="state" 
    (didReceiveMessage)="onMessageReceived($event)" 
    (didStateChange)="onStateChange($event)">
    <ng-container header>
      <lv-instrument-search
        [instrumentName]="state.instrumentName" 
        (didSelectInstrument)="onInstrumentSelect($event)">
      </lv-instrument-search>
    </ng-container>
    <ng-container content>
      <lv-documents-sliding-panel
        [lwsIdentifier]="state.instrumentLwsIdentifier"
        [isin]="state.instrumentIsin"
        [instrumentName]="state.instrumentName">
      </lv-documents-sliding-panel>
    </ng-container>
  </lv-subscriber-panel>
</div>