import { LvUserFriendlyError } from '@lv-core-ui/models';

/**
 * Application settings error class that defines error service origin
 */
export class LvApplicationSettingsError extends LvUserFriendlyError {
  constructor(message: string) {
    super(message, 'Application Settings');
  }
}

/**
 * Default widget state error class that defines error service origin
 */
export class LvDefaultWidgetStateError extends LvUserFriendlyError {
  constructor(message: string) {
    super(message, 'Default Widget State');
  }
}
