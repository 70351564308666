import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Output,
  EventEmitter, Input } from '@angular/core';
import { IScenarioTemplateInfoResponse } from '@lv-analytics/models';

/**
 * Sensitivity analysis panel dialog component.
 */
@Component({
  selector: 'lv-sensitivity-analysis-panel-dialog',
  templateUrl: './lv-sensitivity-analysis-panel-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvSensitivityAnalysisPanelDialogComponent implements OnInit {
  @Input() selectedScenarioTemplate: IScenarioTemplateInfoResponse;

  @Output() didContinueEditing: EventEmitter<void>;
  @Output() didDiscardChanges: EventEmitter<void>;

  constructor(
  ) {
    this.didContinueEditing = new EventEmitter<void>();
    this.didDiscardChanges = new EventEmitter<void>();
   }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Occurs on continue editing.
   */
  onContinueEditing() {
    this.didContinueEditing.emit();
  }

  /**
   * Occurs on discard changes.
   */
  onDicardChanges() {
    this.didDiscardChanges.emit();
  }
}
