<kendo-tabstrip class="lv-tabstrip other-settings-tabstrip"
  [keepTabContent]="true">

  <kendo-tabstrip-tab
    selected="true"
    title="Quantity"
    cssClass="DM-1260">
    <ng-template kendoTabContent>
      <lv-quantity-settings
        [settings]="settings.quantitySettings"
        data-cy="DM-1260"
        ></lv-quantity-settings>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab
  title="Copy & Paste">
  <ng-template kendoTabContent>
    <lv-copy-and-paste-settings [settings]="settings.copyAndPasteSettings"></lv-copy-and-paste-settings>
  </ng-template>
 </kendo-tabstrip-tab>
 <kendo-tabstrip-tab
  title="ISIN">
  <ng-template kendoTabContent>
    <lv-instrument-source-settings [settings]="settings.instrumentSourceSettings"></lv-instrument-source-settings>
  </ng-template>
  </kendo-tabstrip-tab>

</kendo-tabstrip>
<lv-flex-box class="lv-quantity-settings-control-panel" direction="row">
  <button kendoButton
    class="reset-button"
    [primary]="true"
    lvId="reset-to-default-quantity-settings"
    (click)="onResetToDefault()">
    Reset To Default
  </button>
  <button kendoButton
    [primary]="true"
    lvId="cancel-other-settings"
    (click)="onCancel()">
    Cancel
  </button>
  <button kendoButton
    [primary]="true"
    lvId="save-other-settings"
    (click)="onSave()">
    Save
  </button>
</lv-flex-box>
