import { LvError } from './base';
import { LvErrorType } from './error-type';
import { constants } from '../../util/constants';

export class LvUserFriendlyError extends LvError {
  constructor(message = constants.errors.unexpectedError, name = 'User Friendly') {
    super(message, name, LvErrorType.USER_FRIENDLY);
  }
}

export class LvBadRequestError extends LvUserFriendlyError {
  constructor(message = constants.errors.unexpectedError) {
    super(message, 'Bad Request');
  }
}

export class LvNotImplementedError extends LvUserFriendlyError {
  constructor(message = constants.errors.unexpectedError) {
    super(message, 'Not Implemented');
  }
}
