export enum ConversionSettlement {
  None = 'None',
  Cash = 'Cash',
  Shares = 'Shares',
  CashOrShares = 'CashOrShares',
  PrincipalAcqShares = 'PrincipalAcqShares',
  SharesBasedOnAveraging = 'SharesBasedOnAveraging'
}

export enum ConversionSettlementDescripton {
  None = '',
  Cash = 'Cash',
  Shares = 'Shares',
  CashOrShares = 'Combination',
  SharesBasedOnAveraging = "Shares Based on Averaging"
}
