<form #form="ngForm"  #deltaNeutral>
  <lv-flex-box direction="row" class="lv-delta-neutral" data-cy="DM-1216">

    <lv-flex-box class="delta-neutral-top-content">
      <lv-flex-box class="delta-neutral-quantity-section">
        <!-- Convertible Quantity -->
        <lv-flex-box
          data-cy="DM-3864"
          *ngIf="state.isConvertibleAndStockValues"
          direction="row"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="convertibleQuantityTooltip"
          filter=".lv-label">
          <div class="lv-label">Convertible Quantity</div>
          <lv-numeric-text-box name="convertible"
            format="n0"
            decimals="0"
            showNegativeLabel="true"
            [disabled]="!view.hasConvertible"
            [(ngModel)]="view.input.convertible"
            (ngModelChange)="onDeltaConvertibleChange()">
          </lv-numeric-text-box>
          <div class="lv-output lv-output--right">
            <lv-negative-label
              data-cy="DM-3863"
              [value]="view.output.convertibleValue"
              shouldShowBorder="true"
              [suffix]="view.currentCurrency"></lv-negative-label>
          </div>
        </lv-flex-box>

        <!-- Stock Quantity -->
        <lv-flex-box
          *ngIf="state.isConvertibleAndStockValues"
          direction="row"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="stockQuantityTooltip"
          filter=".lv-label">
          <div class="lv-label">Stock Quantity</div>
          <lv-numeric-text-box
            name="stock"
            data-cy="DM-3865"
            showNegativeLabel="true"
            format="n0"
            [disabled]="!view.hasConvertible"
            [(ngModel)]="view.input.stock"
            (ngModelChange)="onStockChange()">
          </lv-numeric-text-box>
          <div class="lv-output lv-output--right">
            <lv-negative-label
              data-cy="DM-3866"
              [value]="view.output.stockValue"
              shouldShowBorder="true"
              [suffix]="view.currentCurrency">
            </lv-negative-label>
          </div>
        </lv-flex-box>

        <!-- Delta -->
        <lv-flex-box
          direction="row"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="deltaTooltip"
          filter=".lv-label">
          <div class="lv-label">Delta</div>
          <lv-numeric-text-box name="delta"
            data-cy="DM-1094"
            spinners="false"
            format="n3"
            decimals="3"
            [disabled]="!view.hasConvertible"
            [(ngModel)]="view.input.delta"
            (ngModelChange)="onDeltaChange()">
          </lv-numeric-text-box>
          <div class="lv-label lv-label-suffix">{{view.suffixDelta}}</div>
        </lv-flex-box>

        <!-- FX Delta -->
        <lv-flex-box
          *ngIf="view.isDetachableOrDeltaNeutral && state.isFxDelta"
          direction="row"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="fxDeltaTooltip"
          filter=".lv-label">
          <div class="lv-label">FX Delta</div>
          <lv-numeric-text-box name="deltaFX"
            data-cy="DM-1105"
            spinners="false"
            format="n4"
            decimals="4"
            [disabled]="!view.hasConvertible"
            [(ngModel)]="view.input.deltaFX"
            (ngModelChange)="onDeltaFXChange()">
          </lv-numeric-text-box>
          <div class="lv-label lv-label-suffix">{{view.paritySuffix}}</div>
        </lv-flex-box>
      </lv-flex-box>

      <lv-flex-box class="delta-neutral-price-section">
        <!-- Convertible  -->
        <lv-flex-box
          direction="row"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="convertibleTooltip"
          filter=".lv-label-to-bottom">
          <div class="lv-label lv-label-to-bottom">Convertible</div>

          <lv-flex-box
            direction="column">
            <div class="lv-label lv-label-top">Price 1</div>
            <lv-numeric-text-box
              data-cy="DM-3867"
              name="convertibleT0"
              spinners="false"
              format="n4"
              [decimals]="4"
              [disabled]="!view.hasConvertible"
              [(ngModel)]="view.input.convertibleT0"
              (ngModelChange)="onConvertibleT0Change()">
            </lv-numeric-text-box>
          </lv-flex-box>

          <lv-flex-box
            direction="column">
            <div class="lv-label lv-label-top">Price 2</div>
            <lv-numeric-text-box name="convertibleT1"
              data-cy="DM-3868"
              spinners="false"
              format="n4"
              [decimals]="4"
              [disabled]="!view.hasConvertible"
              [(ngModel)]="view.input.convertibleT1"
              (ngModelChange)="onConvertibleT1Change()">
            </lv-numeric-text-box>
          </lv-flex-box>

          <lv-flex-box
            direction="column"
            *ngIf="!isNarrow"
            showAfter="1500"
            kendoTooltip
            filter=".lv-label">
            <div class="lv-label lv-label-top">Delta Neutral</div>
            <div class="lv-output lv-output--right">
              <lv-negative-label
                data-cy="DM-3869"
                [value]="view.output.neutral"
                shouldShowBorder="true"
                lvId="convertibleDeltaNeutral">
              </lv-negative-label>
            </div>
          </lv-flex-box>
        </lv-flex-box>

        <!-- Stock CB CCY -->
        <lv-flex-box
          direction="row"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="stockCbTooltip"
          filter=".lv-label">
          <div
            *ngIf="view.isCrossFx && state.isStockEQCcyAndCrossFxDn"
            class="lv-label">
            Stock CB CCY ({{view.stockPriceCBCCY}})
          </div>
          <div
            *ngIf="!(view.isCrossFx && state.isStockEQCcyAndCrossFxDn)"
            class="lv-label">
            Stock
          </div>
          <lv-numeric-text-box name="stockCBT0"
            data-cy="DM-3870"
            spinners="false"
            format="n4"
            [decimals]="4"
            [disabled]="!view.hasConvertible"
            [(ngModel)]="view.input.stockCBT0"
            (ngModelChange)="onStockCBT0Change()">
          </lv-numeric-text-box>
          <lv-numeric-text-box name="stockCBT1"
            data-cy="DM-3871"
            spinners="false"
            format="n4"
            [decimals]="4"
            [disabled]="!view.hasConvertible"
            [(ngModel)]="view.input.stockCBT1"
            (ngModelChange)="onStockCBT1Change()">
          </lv-numeric-text-box>
          <div class="lv-output lv-output--right" *ngIf="!isNarrow">
            <lv-negative-label
              data-cy="DM-3872"
              [value]="view.output.neutralStockCB"
              shouldShowBorder="true"
              lvId="stockCBDeltaNeutral">
            </lv-negative-label>
          </div>
        </lv-flex-box>

        <!-- Stock EQ CCY -->
        <lv-flex-box
          *ngIf="view.isCrossFx && state.isStockEQCcyAndCrossFxDn"
          direction="row"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="stockEqTooltip"
          filter=".lv-label">
          <div class="lv-label">Stock EQ CCY ({{view.stockPriceEQCCY}})</div>
          <lv-numeric-text-box name="stockEQT0"
            data-cy="DM-3873"
            spinners="false"
            format="n4"
            [decimals]="4"
            [disabled]="!view.hasConvertible"
            [(ngModel)]="view.input.stockEQT0"
            (ngModelChange)="onStockEQT0Change()">
          </lv-numeric-text-box>
          <lv-numeric-text-box name="stockEQT1"
            data-cy="DM-3874"
            spinners="false"
            format="n4"
            [decimals]="4"
            [disabled]="!view.hasConvertible"
            [(ngModel)]="view.input.stockEQT1"
            (ngModelChange)="onStockEQT1Change()">
          </lv-numeric-text-box>
          <div class="lv-output lv-output--right" *ngIf="!isNarrow">
            <lv-negative-label
              data-cy="DM-3875"
              [value]="view.output.neutralStockEQ"
              shouldShowBorder="true"
              lvId="stockEQDeltaNeutral">
            </lv-negative-label>
          </div>
        </lv-flex-box>

        <!-- Cross FX -->
        <lv-flex-box
          *ngIf="view.isCrossFx && state.isStockEQCcyAndCrossFxDn"
          direction="row"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="crossFxTooltip"
          filter=".lv-label">
          <div class="lv-label">Cross FX</div>
          <lv-numeric-text-box name="crossFxT0"
            data-cy="DM-3876"
            spinners="false"
            format="n4"
            [decimals]="4"
            [disabled]="!view.hasConvertible"
            [(ngModel)]="view.input.crossFxT0"
            (ngModelChange)="onCrossFxT0Change()">
          </lv-numeric-text-box>
          <lv-numeric-text-box name="crossFxT1"
            data-cy="DM-3877"
            spinners="false"
            format="n4"
            [decimals]="4"
            [disabled]="!view.hasConvertible"
            [(ngModel)]="view.input.crossFxT1"
            (ngModelChange)="onCrossFxT1Change()">
          </lv-numeric-text-box>
        </lv-flex-box>

        <!-- Narrow response -->
        <lv-flex-box *ngIf="isNarrow">
          <!-- Convertible  -->
          <lv-flex-box
            direction="row">
            <div class="lv-label lv-label-to-bottom">Convertible</div>

            <lv-flex-box
              direction="column"
              showAfter="1500"
              kendoTooltip
              filter=".lv-label">
              <div class="lv-label lv-label-top">Delta Neutral</div>
              <div class="lv-output lv-output--right">
                <lv-negative-label
                  data-cy="DM-3869"
                  [value]="view.output.neutral"
                  shouldShowBorder="true"
                  lvId="convertibleDeltaNeutral">
                </lv-negative-label>
              </div>
            </lv-flex-box>
          </lv-flex-box>

          <!-- Stock CB CCY -->
          <lv-flex-box
            direction="row"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="stockCbTooltip"
            filter=".lv-label">
            <div
              *ngIf="view.isCrossFx && state.isStockEQCcyAndCrossFxDn"
              class="lv-label">
              Stock CB CCY ({{view.stockPriceCBCCY}})
            </div>
            <div
              *ngIf="!(view.isCrossFx && state.isStockEQCcyAndCrossFxDn)"
              class="lv-label">
              Stock
            </div>
            <div class="lv-output lv-output--right">
              <lv-negative-label
                data-cy="DM-3872"
                [value]="view.output.neutralStockCB"
                shouldShowBorder="true"
                lvId="stockCBDeltaNeutral">
              </lv-negative-label>
            </div>
          </lv-flex-box>

          <!-- Stock EQ CCY -->
          <lv-flex-box
            *ngIf="view.isCrossFx && state.isStockEQCcyAndCrossFxDn"
            direction="row"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="stockEqTooltip"
            filter=".lv-label">
            <div class="lv-label">Stock EQ CCY ({{view.stockPriceEQCCY}})</div>
            <div class="lv-output lv-output--right">
              <lv-negative-label
                data-cy="DM-3875"
                [value]="view.output.neutralStockEQ"
                shouldShowBorder="true"
                lvId="stockEQDeltaNeutral">
              </lv-negative-label>
            </div>
          </lv-flex-box>

        </lv-flex-box>
      </lv-flex-box>
    </lv-flex-box>

    <lv-flex-box class="delta-neutral-pnl-section">
      <!-- Actual Delta -->
      <lv-flex-box
        direction="row"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="actualDeltaTooltip"
        filter=".lv-label"
        *ngIf="state.isActualDelta">
        <div class="lv-label">Actual Delta</div>
        <div class="lv-output lv-output--right">
          <lv-negative-label
              data-cy="DM-1099"
              [value]="view.output.actualValue"
              shouldShowBorder="true"
              lvId="actualDeltaDeltaNeutral"
              [suffix]="view.suffixDelta">
            </lv-negative-label>
        </div>
      </lv-flex-box>

      <!-- Convertible P&L -->
      <lv-flex-box
        direction="row"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="convertiblePlTooltip"
        filter=".lv-label"
        *ngIf="state.isPnLDn">
        <div class="lv-label">Convertible P&L</div>
        <div class="lv-output lv-output--right">
          <lv-negative-label
            data-cy="DM-1100"
            [value]="view.output.convertiblePnL"
            shouldShowBorder="true"
            lvId="convertiblePnlDeltaNeutral"
            [suffix]="view.currentCurrency">
          </lv-negative-label>
        </div>
      </lv-flex-box>

      <!-- Stock P&L -->
      <lv-flex-box
        direction="row"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="stockPlTooltip"
        filter=".lv-label"
        *ngIf="state.isPnLDn">
        <div class="lv-label">Stock P&L</div>
        <div class="lv-output lv-output--right">
          <lv-negative-label
            data-cy="DM-1101"
            [value]="view.output.stockPnL"
            shouldShowBorder="true"
            lvId="stockPnlDeltaNeutral"
            [suffix]="view.currentCurrency">
          </lv-negative-label>
        </div>
      </lv-flex-box>

      <!-- Total P&L -->
      <lv-flex-box
        direction="row"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="totalPlTooltip"
        filter=".lv-label"
        *ngIf="state.isPnLDn">
        <div class="lv-label">Total P&L</div>
        <div class="lv-output lv-output--right">
          <lv-negative-label
            data-cy="DM-1102"
            [value]="view.output.totalPnL"
            shouldShowBorder="true"
            lvId="totalPnlDeltaNeutral"
            [suffix]="view.currentCurrency">
          </lv-negative-label>
        </div>
      </lv-flex-box>

      <!-- Total P&L -->
      <lv-flex-box
        direction="row"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="totalPlPtsTooltip"
        filter=".lv-label"
        *ngIf="state.isPnLDn">
        <div class="lv-label">Total P&L</div>
        <div class="lv-output lv-output--right">
          <lv-negative-label
            data-cy="DM-1103"
            [value]="view.output.ptsPnL"
            shouldShowBorder="true"
            lvId="totalPtsPnlDeltaNeutral"
            suffix="pts">
          </lv-negative-label>
        </div>
      </lv-flex-box>

      <!-- Radio buttons -->
      <lv-flex-box
        data-cy="DM-1104"
        direction="row"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="valuesInTooltip"
        filter=".lv-label"
        class="lv-delta-neutral-display-values-in"
        *ngIf="view.radioFields.length > 1">
        <div class="lv-label">Display Values in</div>
        <div *ngFor="let rf of view.radioFields"
          class="lv-field lv-field--radio">
          <input type="radio" class="k-radio"
            [attr.id]="rf.id"
            [attr.name]="rf.id"
            [name]="rf.id"
            [value]="rf.value"
            [(ngModel)]="view.currentCCY"
            [disabled]="!view.hasConvertible"
            (ngModelChange)="onDeltaNeutralCCYChange()" />
          <label
            class="k-radio-label"
            [lvId]="rf.lvId"
            [attr.for]="rf.id">{{ rf.label }}
          </label>
        </div>
      </lv-flex-box>
    </lv-flex-box>
  </lv-flex-box>
</form>

<ng-template #deltaTooltip>
  <lv-tooltip dm="dM-1094"></lv-tooltip>
</ng-template>
<ng-template #fxDeltaTooltip>
  <lv-tooltip dm="dM-1105"></lv-tooltip>
</ng-template>
<ng-template #convertibleQuantityTooltip>
  <lv-tooltip dm="dM-1068"></lv-tooltip>
</ng-template>
<ng-template #stockQuantityTooltip>
  <lv-tooltip dm="dM-1093"></lv-tooltip>
</ng-template>
<ng-template #crossFxTooltip>
  <lv-tooltip dm="dM-1098"></lv-tooltip>
</ng-template>
<ng-template #stockEqTooltip>
  <lv-tooltip dm="dM-1097"></lv-tooltip>
</ng-template>
<ng-template #stockCbTooltip>
  <lv-tooltip dm="dM-1096"></lv-tooltip>
</ng-template>
<ng-template #convertibleTooltip>
  <lv-tooltip dm="dM-1095"></lv-tooltip>
</ng-template>
<ng-template #actualDeltaTooltip>
  <lv-tooltip dm="dM-1099"></lv-tooltip>
</ng-template>
<ng-template #convertiblePlTooltip>
  <lv-tooltip dm="dM-1100"></lv-tooltip>
</ng-template>
<ng-template #stockPlTooltip>
  <lv-tooltip dm="dM-1101"></lv-tooltip>
</ng-template>
<ng-template #totalPlTooltip>
  <lv-tooltip dm="dM-1102"></lv-tooltip>
</ng-template>
<ng-template #totalPlPtsTooltip>
  <lv-tooltip dm="dM-1103"></lv-tooltip>
</ng-template>
<ng-template #valuesInTooltip>
  <lv-tooltip dm="dM-1104"></lv-tooltip>
</ng-template>
