<lv-flex-box class="lv-volatility-surface">
  <lv-flex-box direction="row" data-cy="DM-294">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="referenceStockLevelTooltipTemplate"
      filter="span:not(lv-xl-label span)"
      lvId="lbPriceSurface">
      <span [attr.volatility-tooltip-id]="'referenceStockLevelTooltip'">
        Reference Stock Level
      </span>
      <lv-xl-label [field]="'VOL_SURF_PRICE'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box name="priceSurface"
        [(ngModel)]="volatilitySurface.refUnderlying"
        (didBlur)="onChangeSurfaceSetup()"
        (didKeyDownEnter)="onChangeSurfaceSetup()">
      </lv-numeric-text-box>
      <span class="lv-sufix-label" lvId="priceSurfaceSuffix">{{ currencyCode }}</span>
    </div>
  </lv-flex-box>

  <lv-flex-box direction="row" data-cy="DM-295">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="baseDateTooltipTemplate"
      filter="span:not(lv-xl-label span)"
      lvId="lbUpdateDate">
      <span [attr.volatility-tooltip-id]="'baseDateTooltip'">
        Base Date
      </span>
      <lv-xl-label [field]="'VOL_SURF_BASE_DT'"></lv-xl-label>
    </div>
    <div class="lv-input-field lv-datePicker-container">
      <kendo-datepicker name="updateDate"
        [(ngModel)]="volatilitySurface.updateDate"
        (ngModelChange)="onChangeSurfaceSetup()"
        [attr.aria-valuetext]="dateFormated">
      </kendo-datepicker>
    </div>
  </lv-flex-box>

  <lv-flex-box direction="row" data-cy="DM-296">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="useStrikeVolTooltipTemplate"
      filter="span:not(lv-xl-label span)"
      lvId="lbUseStrikeVol">
      <span [attr.volatility-tooltip-id]="'useStrikeVolTooltip'">
        Use Strike Vol
      </span>
      <lv-xl-label [field]="'VOL_USE_STRIKE'"></lv-xl-label>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input name="useStrikeVol"
        class="k-checkbox"
        type="checkbox"
        [attr.id]="view.useStrikeVolId"
        [(ngModel)]="volatilitySurface.useStrikeVol"
        (ngModelChange)="onChangeSurfaceSetup()"/>
      <label class="k-checkbox-label"
        lvId="useStrikeVol"
        [attr.for]="view.useStrikeVolId">
      </label>
    </div>
    <div *ngIf="isHidegMoneyness" class="lv-volatility-surface-radio-buttons">
      <input type="radio" class="k-radio"
        [attr.id]="view.maturityDateSurface"
        [name]="view.maturityDateSurface"
        [attr.name]="view.maturityDateSurface"
        value="Maturity"
        [(ngModel)]="volatilitySurface.dateTenorType"
        (ngModelChange)="onChangeSurfaceSetup()" />
      <label class="k-radio-label"
        lvId="maturityDateSurfaceRadio"
        [attr.for]="view.maturityDateSurface">
        <div
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="volatilityTooltipTemplate"
          filter="span">
          <span [attr.volatility-tooltip-id]="'maturityDateTooltip'">
            Maturity Date
          </span>
        </div>
      </label>
    </div>
    <div *ngIf="isHidegMoneyness" class="lv-volatility-surface-radio-buttons">
      <input type="radio" class="k-radio"
        [attr.id]="view.tenorTypeSurface"
        [name]="view.tenorTypeSurface"
        [attr.name]="view.tenorTypeSurface"
        value="Tenor"
        [(ngModel)]="volatilitySurface.dateTenorType"
        (ngModelChange)="onChangeSurfaceSetup()" />
      <label class="k-radio-label"
        lvId="tenorTypeSurfaceRadio"
        [attr.for]="view.tenorTypeSurface">
        <div
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="volatilityTooltipTemplate"
          filter="span">
          <span [attr.volatility-tooltip-id]="'maturityDateTooltip'">
            Tenor
          </span>
        </div>
      </label>
    </div>
    <div *ngIf="isHidegMoneyness" class="lv-volatility-surface-radio-buttons">
      <input type="radio" class="k-radio"
        [attr.id]="view.moneyneesSurface"
        [name]="view.moneyneesSurface"
        [attr.name]="view.moneyneesSurface"
        value="Moneyness"
        [(ngModel)]="volatilitySurface.moneynessStrikeType"
        (ngModelChange)="onChangeSurfaceSetup()" />
      <label class="k-radio-label"
        lvId="mmoneyneesSurfaceRadio"
        [attr.for]="view.moneyneesSurface">
        <div
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="volatilityTooltipTemplate"
          filter="span">
          <span [attr.volatility-tooltip-id]="'moneynessTooltip'">
            Moneyness
          </span>
        </div>
      </label>
    </div>
    <div *ngIf="isHidegMoneyness" class="lv-volatility-surface-radio-buttons">
      <input type="radio" class="k-radio"
        [attr.id]="view.strikeSurface"
        [name]="view.strikeSurface"
        [attr.name]="view.strikeSurface"
        value="Strike"
        [(ngModel)]="volatilitySurface.moneynessStrikeType"
        (ngModelChange)="onChangeSurfaceSetup()" />
      <label class="k-radio-label"
        lvId="strikeSurfaceRadio"
        [attr.for]="view.strikeSurface">
        <div
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="volatilityTooltipTemplate"
          filter="span">
          <span [attr.volatility-tooltip-id]="'moneynessTooltip'">
            Strike
          </span>
        </div>
      </label>
    </div>
  </lv-flex-box>

  <lv-advanced-grid
    data-cy="DM-297"
    [columns]="columns"
    [records]="volatilitySurfaceSchedule"
    lvId="lvMarketDataVolatilitySurfaceGrid"
    [createFormGroup]="createFormGroup"
    [skipRecordOnDeleteFn]="skipRecordOnDeleteFn"
    [parseFn]="parseFn"
    sortBy="date"
    [showXlLabel]="hasScheduleInExcelOverride"
    [excelFieldAlias]="excelFieldAlias"
    [isFieldFromExcelEnabled]="isFieldFromExcelEnabled">
  </lv-advanced-grid>
</lv-flex-box>


<ng-template #referenceStockLevelTooltipTemplate>
  <lv-tooltip dm="dM-294"></lv-tooltip>
</ng-template>
<ng-template #baseDateTooltipTemplate>
  <lv-tooltip dm="dM-295"></lv-tooltip>
</ng-template>
<ng-template #useStrikeVolTooltipTemplate>
  <lv-tooltip dm="dM-296"></lv-tooltip>
</ng-template>

<ng-template #volatilityTooltipTemplate let-anchor>
  <div class="popup-volatility-content">
    <ng-container *ngIf="getVolatilityTootlipId(anchor, 'maturityDateTooltip')">
      Direction parameterization type for volatility surface.
    </ng-container>
    <ng-container *ngIf="getVolatilityTootlipId(anchor, 'moneynessTooltip')">
      Direction parametrization type for volatility surface.
    </ng-container>
  </div>
</ng-template>
