<div class="analytics" data-cy="DM-1202">
 <div class="analytics-content">
    <gridster id="id" class="lv-gridster"
      [options]="gridsterConfig"> 
      <ng-container *ngFor="let w of gridsterWidgets">
        <gridster-item *ngIf="isWidgetVisible(w)" 
          [item]="w.gridsterItemConfig">
          <div class="lv-gridster-item"
            [class.lv-gridster-item--editable]="editable">
            <lv-pricing *ngIf="shouldShowWidget(w,'pricing')"></lv-pricing>
            <lv-market-data-widget *ngIf="shouldShowWidget(w,'market_data')"></lv-market-data-widget>        
            <lv-model-customization *ngIf="shouldShowWidget(w,'model_customization')"></lv-model-customization>
            <lv-outputs-widget *ngIf="shouldShowWidget(w,'outputs')"></lv-outputs-widget>
            <lv-basic-terms *ngIf="shouldShowWidget(w,'instrument_info')"></lv-basic-terms>
            <lv-tools-widget *ngIf="shouldShowWidget(w,'tools')"></lv-tools-widget>
          </div>
        </gridster-item>  
      </ng-container>
    </gridster>
  </div>
  <lv-xignite></lv-xignite>
</div>