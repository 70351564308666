<div class="lv-dialog">
    <div class="lv-dialog-content">
      <lv-grid-loader [isLoading]="isLoading">
        <kendo-grid #grid
          class="lv-estimates-history-dialog"
          lvId="lvEstimatesHistoryGrid"
          [data]="recordsView"
          [selectable]="selectableSettings">
          <kendo-grid-column field="effectiveDate" title="Effective Date">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.effectiveDate | lvLocalDate}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="adjustmentReason" title="Adjustment Reason">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.adjustmentReason | enum:adjustmentReasonEnum}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="confirmedRatio" title="Confirmed Ratio">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.confirmedRatio | number:constants.numberFormat.threeDigits}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="leversysEstimate" title="Leversys Estimate">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.leversysEstimate | number:constants.numberFormat.threeDigits}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="relativeDiff" title="Relative Diff (%)">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.relativeDiff | number:constants.numberFormat.twoDigits}}
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </lv-grid-loader>
    </div>
  </div>
