import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { ICopyAndPasteSectionSettings } from 'src/app/modules/analytics/models/company-and-user-settings/other-settings/copy-and-paste-settings/copy-and-paste-section-settings';
import { v4 } from 'uuid';

/**
 * Copy and paste section settings component.
 */
@Component({
  selector: 'lv-copy-and-paste-section-settings',
  templateUrl: './lv-copy-and-paste-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCopyAndPasteSectionSettingsComponent implements OnInit {

  @Input() settings: ICopyAndPasteSectionSettings;
  @Input() overrideSystemDefaults: boolean;

  get isDividendStartFromRowChecked(): boolean {
    return this.settings.dividends.startFromRow && this.settings.dividends.startFromRow !== 1;
  }

  get isInterestRatesStartFromRowChecked(): boolean {
    return this.settings.interestRates.startFromRow && this.settings.interestRates.startFromRow !== 1;
  }

  checkBoxId: string;

  constructor() {
    this.settings = {
    } as ICopyAndPasteSectionSettings;
    this.checkBoxId = v4();
    this.overrideSystemDefaults = true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
  }

  /**
   * Generates id for checkbox with name and uuid combination
   * @param {string} name Name of input
   */
  getCheckBoxId(name: string): string {
    return `${name}-${this.checkBoxId}`;
  }

  /**
   * Handle change for checkbox and if it's unchecked remove value form startFromRow
   * @param {boolean} checked Checkbox checked or unchecked status
   */
  handleCheckboxChange(checked: boolean, model: string): void {
    if (!checked) {
      this.settings[model].startFromRow = 1;
    }
  }
}
