/**
 * Date adjustment description.
 */
export enum DateAdjustmentDescription {
  None = 'None',
  Previous = 'Previous',
  Following = 'Following',
  ModifiedFollowing = 'Modified Following'
}

/**
 * Data adjustment.
 */
export enum DateAdjustment {
  None = 'None',
  Previous = 'Previous',
  Following = 'Following',
  ModifiedFollowing = 'Modified Following'
}
