import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvUserFriendlyError } from '@lv-core-ui/models';
import { IField } from '@lv-excel/models/field';
@Injectable()
export class ApiService extends HttpClientBase {
  constructor(http: HttpClient) {
    const resourceUrl = '/fieldsmetadata';
    super(http, resourceUrl);
  }

  async mapFromApi(fromExcel: IField[]): Promise<IField[]> {
    const result: IField[] = [];

    return await this.mapFields(fromExcel);
  }

  async mapForSave(
    fields: string[],
    sessionId: string,
    lwsIdentifier: string,
    section: string,
    draftId: string
  ): Promise<IField[]> {
    try {
      return await this.postAsync<IField[]>(
        {
          fields: fields,
          lwsIdentifier: lwsIdentifier,
          sessionId: sessionId,
          section: section,
          draftId: draftId,
        },
        '/mapForSave'
      );
    } catch (error) {
      throw this.handleError(error, (e) => new LvUserFriendlyError(e.message));
    }
  }

  async mapFields(request: IField[]): Promise<IField[]> {
    try {
      return await this.postAsync<IField[]>({ fields: request }, '/mapfields');
    } catch (error) {
      throw this.handleError(error, (e) => new LvUserFriendlyError(e.message));
    }
  }
}

export interface IMapFieldsForExcelFormRequest {
  fields: IField[];
}
