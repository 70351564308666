import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
  HostBinding, Output, EventEmitter, ChangeDetectorRef, ElementRef } from '@angular/core';
import { IModelCustomizationSettings, ICreditModelSettings, ICallAdjustmentModelSettings, IModelCustomizationSectionSettings,
         ISaveModelCustomizationSettingsRequest } from '@lv-analytics/models';
import { CompanyAndUserSettingsService } from '@lv-analytics/services';
import { LvErrorService } from '@lv-core-ui/services';

/**
 * Model customization settings component.
 */
@Component({
  selector: 'lv-model-customization-settings',
  templateUrl: './lv-model-customization-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvModelCustomizationSettingsComponent implements OnInit {

  @Output() didCancel: EventEmitter<void>;
  @Output() didSave: EventEmitter<void>;

  settings: IModelCustomizationSettings;

  constructor(
    private _companyAndUserSettingsService: CompanyAndUserSettingsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService
  ) {
    this.didCancel = new EventEmitter<void>();
    this.didSave = new EventEmitter<void>();

    this.settings = {
      systemDefaults: {
        creditModelSettings: {} as ICreditModelSettings,
        callAdjustmentModelSettings: {} as ICallAdjustmentModelSettings
      } as IModelCustomizationSectionSettings,
      environments: []
    } as IModelCustomizationSettings;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-model-customization-settings')
  get isLvModelCustomizationSettingsComponent() {
    return true;
  }

  @HostBinding('class.default-settings-height')
  get isDefaultSettingsHeight() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    this.getSettings();
  }

  /**
   * Gets settings.
   */
  async getSettings() {
    try {
      this.settings = await this._companyAndUserSettingsService.getModelCustomizationSettings();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on save and saves model customization settings.
   */
  async onSave() {
    try {
      await this._companyAndUserSettingsService.saveModelCustomizationSettings({
        settings: this.settings
      } as ISaveModelCustomizationSettingsRequest);

      this._errorService.toastrService.success('Model Customization Settings successfully Saved');
      this.didSave.emit();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.markForCheck();
    }
  }

  /**
   * Resets model customization settings.
   */
  async onResetToDefault() {
    try {
      this.settings = await this._companyAndUserSettingsService.resetModelCustomizationSettings();
      this._errorService.toastrService.success('Model Customization Settings reseted to default');
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on cancel.
   */
  onCancel() {
   this.didCancel.emit();
  }

  /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
  getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }
}
