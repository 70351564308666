import { LvScenarioGridView, ILvScenarioGridView, ILvScenarioGridViewGroup, ILvScenarioGridViewRecord } from '../lv-scenario-grid.view';
import { ILvScenarioReport } from '../../lv-scenario.view';
import { ScenarioOutput, ICalculateScenarioOutputVector } from '@lv-analytics/models';

export interface ITwoDimGrid extends ILvScenarioGridView {
  dimensionDescription: string;
  groups: ILvScenarioGridViewGroup[];
  outputs: ScenarioOutput[];
  currentOutput: ScenarioOutput;
}

/**
 * Scenario two dim grid view.
 */
export class LvScenarioTwoDimGridView extends LvScenarioGridView<ITwoDimGrid> {

  /**
   * Gets default model.
   * @returns ITwoDimGrid object.
   */
  getDefaultModel(): ITwoDimGrid {
    return {
      dimensionDescription: null,
      groups: [],
      outputs: [],
      currentOutput: null,
      records: []
    };
  }

  /**
   * Does initialization.
   * @param report ILvScenarioReport object.
   */
  doInit(report: ILvScenarioReport) {
    const mapped = (report.data as ICalculateScenarioOutputVector[]);

    mapped.forEach((fDim, i) => {
      if (this.isFirstIndex(i)) {
        this.model.dimensionDescription = this.getFirstDimensionDescription(fDim.dimension);
      }

      const rec: ILvScenarioGridViewRecord = {
        dimension: fDim.dimension,
        dimensionValue: fDim.value
      };

      fDim.outputs.forEach((sDim, j) => {
        if (this.areFirstIndexes(i, j)) {
          this.model.groups.push({
            groupId: sDim.dimension,
            title: this.getFirstDimensionDescription(sDim.dimension),
            width: fDim.outputs.length * this.defaultColumnWidth,
            columns: []
          });
        }

        if (this.isFirstIndex(i)) {
          this.model.groups[i].columns.push({
            columnId: `${sDim.value}`,
            data: sDim.value,
            width: this.defaultColumnWidth
          });
        }

        rec[sDim.value] = sDim.outputs.map((output, k) => {
          if (this.areFirstIndexes(i, j)) {
            this.model.outputs.push(output.output);
          }

          if (this.areFirstIndexes(i, j, k)) {
            this.model.currentOutput = output.output;
          }

          const assumed = output.values[0];

          return {
            output: output.output,
            value: assumed.value
          };
        });
      });

      this.model.records.push(rec);
    });
  }

  /**
   * Checks if output is visible.
   * @param output Scenario output.
   * @returns A flag indicating if output is visible.
   */
  isOutputVisible(output: ScenarioOutput) {
    return this.model.currentOutput === output;
  }
}
