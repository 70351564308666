import { QuickTerms } from './quick-terms/quick-terms';
// tslint:disable-next-line:max-line-length
import { ConvertibleBondNewIssueDocument } from './convertible-bond-terms/ConvertibleBondNewIssueDocument';
import { Identifiers } from './convertible-bond-terms/Identifiers';
import { AccessScopeType } from '@lv-core-ui/models/enum/access-scope-type';

export class QuickAndFullTermsDocument {
  fullTerms: ConvertibleBondNewIssueDocument;
  quickTerms: QuickTerms;
  identifiers: Identifiers[];
  fixDatesInQuickTermsEntry: boolean;
  accessScope: AccessScopeType;
}
