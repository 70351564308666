<lv-publisher-panel
  class="instrument-monitor"
  [state]="state"
  [floatingHeader]="true"
  (didStateChange)="onStateChange($event)">
  <ng-container content>
    <lv-instrument-monitor-panel
      class="instrument-monitor-panel"
      [state]="state"
      (didSelectInstrument)="onSelectInstrument($event)"
      (doOpenInstrument)="onOpenInstrument($event)"
      (doOpenDocuments)="onOpenDocuments($event)"
      (doOpenBasicTerms)="onOpenBasicTerms($event)"
      (doCreateNewIssueFromExistingInstrument)="accessScopeCopyInstrument($event, true)"
      (doCopyInstrument)="accessScopeCopyInstrument($event, false)"
      (doDeleteInstrument)="onDeleteInstrument($event)"
      >
    </lv-instrument-monitor-panel>
  </ng-container>
</lv-publisher-panel>