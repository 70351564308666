import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

import { LvSubscriberPanelComponent } from '@lv-core-ui/components';
import { IInstrumentSelectedEvent } from '@lv-core-ui/models';
import { ILvSubscriberState } from '@lv-core-ui/services';
import { ApplicationSettingsService } from '@lv-application-settings/application-settings.service';
import { NotificationPanelComponent } from '@lv-common/views/notification-panel.component';
import { LvDocumentsSlidingPanelComponent } from '@lv-documents/components';
import { IInstrumentLookup } from '@lv-instrument-monitor/models';
import { RouteCacheService } from '@lv-cache/route-cache';

export interface IDocumentsComponentState extends ILvSubscriberState {
  instrumentId: number;
  instrumentName: string;
  instrumentLwsIdentifier: string;
  instrumentIsin: string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'documents',
  templateUrl: './documents.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsComponent extends NotificationPanelComponent<IDocumentsComponentState> implements OnDestroy {

  @ViewChild(LvSubscriberPanelComponent, { static: true }) notificationPanel: LvSubscriberPanelComponent;
  @ViewChild(LvDocumentsSlidingPanelComponent, { static: true }) documentsSlidingPanel: LvDocumentsSlidingPanelComponent;

  private _workspaceReatachedSubscription: Subscription;

  constructor(
    private _router: Router,
    private _routeCacheService: RouteCacheService,
    changeDetectorRef: ChangeDetectorRef,
    applicationSettingsService: ApplicationSettingsService
  ) {
    super(changeDetectorRef, applicationSettingsService);

    this._workspaceReatachedSubscription = this._routeCacheService.workspaceReatached
      .pipe(
        delay(100)
      )
      .subscribe(wsId => {
        const regex = new RegExp(`\\b${wsId}\\b`, 'ig');
        if (this._router.url.match(regex) && this.applicationSettingsService.isWidgetInWorkspace(wsId, this.stateId)) {
          this.documentsSlidingPanel.forceRerender();
        }
      });
  }

  doOnInit() {}

  onInstrumentSelect(info: IInstrumentLookup) {
    this.state.instrumentId = info.id;
    this.state.instrumentName = info.text;
    this.state.instrumentLwsIdentifier = info.lwsIdentifier;
    this.state.instrumentIsin = info.isin;

    this.setGoldenLayoutContainerTitle(info.text || 'Documents');

    this.saveState();
  }

  // Implementations from parent class
  didReceiveMessage(monitor: IInstrumentSelectedEvent) {
    this.state.instrumentId = monitor.id;
    this.state.instrumentName = monitor.name;
    this.state.instrumentLwsIdentifier = monitor.lwsIdentifier;
    this.state.instrumentIsin = monitor.isin;

    this.setGoldenLayoutContainerTitle(monitor.name);

    this.saveState();
  }

  didlvGlOnDestroy() {
    this.notificationPanel.unregister();
  }

  ngOnDestroy() {
    this.unsubscribeFromWorkspaceRemoved();
    this._workspaceReatachedSubscription.unsubscribe();
  }
}
