import { Directive, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { fromEvent, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ReactiveEditingDirective, CreateFormGroupArgs, CellClickEvent } from '@progress/kendo-angular-grid';

import { LvGridUtil } from '../../util/lv-grid.util';
import { LvFormUtil } from '../../util/form';

@Directive({
  selector: '[gridRowEditing]'
})
export class GridRowEditingDirective extends ReactiveEditingDirective implements OnInit {

  // tslint:disable-next-line: no-input-rename
  @Input('gridRowEditing')
  declare createFormGroup: (args: CreateFormGroupArgs) => FormGroup;

  @Input() dataItemKey: string;
  @Input() editorPopupOpened: Observable<boolean>;
  @Input() editDisabled: boolean;

  @Output() didAddRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() didEditRow: EventEmitter<void> = new EventEmitter();
  @Output() didCloseRow: EventEmitter<void> = new EventEmitter();
  @Output() didValidateRow: EventEmitter<void> = new EventEmitter();

  public get currentRowIndex(): number {
    return this._currentRowIndex;
  }

  private _currentRowIndex: number;
  private _currentFormGroup: FormGroup;
  private _popupOpened: boolean;

  /**
   * @hidden
   */
  public ngOnInit(): void {
    super.ngOnInit();

    this.subscriptions.add(
      this.grid.cellClick
      .pipe(debounceTime(10))
      .subscribe(this.cellClickHandler.bind(this))
    );
    this.subscriptions.add(fromEvent(this.grid.wrapper.nativeElement, 'keydown').subscribe(this.keyDownHandler.bind(this)));
    this.subscriptions.add(
      fromEvent(document.body, 'click')
      .pipe(debounceTime(10))
      .subscribe(this.mouseClickHandler.bind(this))
    );

    this.subscriptions.add(this.editorPopupOpened.subscribe(opened => this._popupOpened = opened));
  }

  public applyChanges(): boolean {
    if (this.grid.isEditing()
        && !this.grid.isEditingCell()
        && this.currentRowIndex > -1
        && this._currentFormGroup.valid) {
      LvGridUtil.save(this.grid, this.currentRowIndex);
      return true;
    }

    return false;
  }

  protected cellClickHandler(evt: CellClickEvent): void {
    if (this.editDisabled) {
      return;
    }

    if (evt.type === 'contextmenu') {
      return;
    }

    if (!this.grid.isEditing() && !evt.dataItem[this.dataItemKey]) {
      this._currentRowIndex = evt.rowIndex;
      this._currentFormGroup = this.createFormGroup({
        dataItem: evt.dataItem
      } as any);

      this.grid.editRow(evt.rowIndex, this._currentFormGroup);

      // Timeout used when entering new row
      setTimeout(() => LvGridUtil.openCellEditor(this.grid, evt, true), 100);

      this.didEditRow.next();
    }
  }

  protected keyDownHandler(e: KeyboardEvent): void {
    if (!this.grid.isEditing() || this.grid.isEditingCell()) {
      return;
    }

    if (e.key === 'Escape') {
      LvGridUtil.endEdit(this.grid, this._currentRowIndex);
      this._currentRowIndex = null;
      this.didCloseRow.next();
    }

    if (e.key === 'Enter') {
      if (this._currentFormGroup.valid) {
        LvGridUtil.save(this.grid, this._currentRowIndex);
        this.didAddRow.next(this._currentFormGroup.value);
      }
      else {
        LvFormUtil.markAllControlsAsTouched(this._currentFormGroup as any);
        this.didValidateRow.next();
      }
    }
  }

  private mouseClickHandler(evt: MouseEvent) {
    if (this.editDisabled) {
      return;
    }

    if (!this._popupOpened
      && this.grid.isEditing()
      && !this.grid.isEditingCell()
      && LvGridUtil.clickedOutsideEditedRow(this.grid, evt.target)) {

      if (this._currentFormGroup.valid) {
        LvGridUtil.save(this.grid, this._currentRowIndex);
        this.didAddRow.next(this._currentFormGroup.value);
      }
      else {
        LvGridUtil.endEdit(this.grid, this._currentRowIndex);
        this.didCloseRow.next();
      }
    }
  }
}
