import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { DefaultWidgetType } from '@lv-shared/models';
import { LvSharedDefaultWidgetStateService } from '@lv-shared/services';
import { IInstrumentComponentState } from '.';
import { IBasicTermsComponentState } from '..';
import { defaultWidgets } from './instrument.widgets';

@Injectable()
export class DefaultComponentStateService {

  constructor(
    private _defaultWidgetStateService: LvSharedDefaultWidgetStateService
  ) { }

  getDefaultInstrumentComponentState = (update?: (s: IInstrumentComponentState) => void) => {
    const dfState = this._defaultWidgetStateService.getDefaultWidgetState(DefaultWidgetType.Instrument);

    if (dfState && dfState.state) {
      dfState.state.forEach(element => {
        element.state = null;
      });
    }

    const initial = {
      id: null,
      instrumentId: null,
      instrumentName: null,
      instrumentLwsIdentifier: null,
      instrumentIsin: null,
      sessionId: null,
      widgets: dfState ? dfState.state : _.cloneDeep(defaultWidgets),
      sensitivityAnalysisState: {
        scenarioTemplateId: null,
        isSystem: false
      },
      isPrivateInstrument: false,
      useDefaultView: true,
      accessScope: null
    } as IInstrumentComponentState;

    if (update) {
      update(initial);
    }

    return initial;
  }

  getDefaultBasicTermsComponentState = (update?: (s: IBasicTermsComponentState) => void) => {
    const initial =  {
      instrumentId: null,
      instrumentInfoState: { state: null },
      instrumentName: null,
      isPrivateInstrument: null,
      lwsIdenitifier: null,
      id: null,
      type: null,
    };
    if (update) {
      update(initial);
    }
    return initial;
  }
}
