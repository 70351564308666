import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IPricingEnvironmentSettings } from '@lv-analytics/models/company-and-user-settings/environments-settings';
import { IPricingEnvironmentOrderSettings } from '@lv-analytics/models/company-and-user-settings/environments-settings/setup-settings/pricing-evironment-order-settings';
import { CompanyAndUserSettingsService } from '@lv-analytics/services';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';

/**
 * Environment settings component.
 */
@Component({
  selector: 'lv-environments-settings',
  templateUrl: './lv-environments-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvEnvironmentsSettingsComponent implements OnInit {

  @Output() didSave: EventEmitter<void>;
  @Output() didCancel: EventEmitter<void>;

  settings: IPricingEnvironmentSettings[];

  constructor(
    private _companyAndUserSettingsService: CompanyAndUserSettingsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService
  ) {
    this.didSave = new EventEmitter<void>();
    this.didCancel = new EventEmitter<void>();
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-environments-settings')
  get isLvValuationSettingsComponent() {
    return true;
  }

  @HostBinding('class.default-settings-height')
  get isDefaultSettingsHeight() {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  async ngOnInit() {
    try {
      this.settings = await this._companyAndUserSettingsService.getEnvironmentsSettings();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Save pricing envronment settings to company settings
   * @param settings Pricing environments settins for save
   */
  async doSavePricingEnvironmentSettings(settings: IPricingEnvironmentSettings) {
    try {
      await this._companyAndUserSettingsService.savePricingEnvironmentSettings(settings);
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1810'));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.settings = await this._companyAndUserSettingsService.getEnvironmentsSettings();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * When pricing environment settings are changed save them to database
   * @param settings Pricing environment order settings save request
   */
  async onSavePricingEnvironmentSaveRequestEmitted(settings: IPricingEnvironmentOrderSettings) {
    try {
      await this._companyAndUserSettingsService.savePricingEnvironmentOrderSettings(settings);
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1811'));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.settings = await this._companyAndUserSettingsService.getEnvironmentsSettings();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Emit on cancel event
   */
  onBack() {
    this.didCancel.emit();
  }
}
