import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
  ChangeDetectorRef, EventEmitter, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { DialogService } from '@progress/kendo-angular-dialog';

import { LvGoldenLayoutService } from '@lv-golden-layout/services';
import { ApplicationSettingsService } from '@lv-application-settings/application-settings.service';
import { RouteCacheService } from '@lv-cache/route-cache';
import { IWorkspaceItem, InternalComponentService, IWorkspaceEvent, WorkspacePanelDialogComponent, getDefaultConfig } from '..';
import { WorkspaceReorderDialogComponent } from './workspace-reorder-dialog/workspace-reorder-dialog.component';
import { LvErrorService } from 'src/app/leversys-core-ui/services/lv-error/lv-error.service';

export const excelWorkspaceName = 'Excel';
export const mailNotificationsWorkspaceName = 'Mail Notifications';

const isExternalWorkspace = (name?: string) => {
  if (!name) {
    return false;
  }

  return name === excelWorkspaceName || name === mailNotificationsWorkspaceName;
};

@Component({
  selector: 'workspace-panel',
  templateUrl: './workspace-panel.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspacePanelComponent implements OnInit, OnDestroy {

  items: IWorkspaceItem[];

  didWorkspaceClose: EventEmitter<void>;

  private _subscriptions: Subscription[];

  constructor(
    private _changeDetectionRef: ChangeDetectorRef,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _routeCache: RouteCacheService,
    private _dialogService: DialogService,
    private _applicationSettingsService: ApplicationSettingsService,
    private _lvGoldenLayoutService: LvGoldenLayoutService,
    private _internalComponentService: InternalComponentService,
    private _errorService: LvErrorService
  ) {
    this.items = [];

    this.didWorkspaceClose = new EventEmitter<void>();
  }

  ngOnInit() {
    const state = this._applicationSettingsService.getState();
    this.items = state.workspaces.map(a => ({
      id: a.id,
      name: a.name,
      selected: a.selected
    }));

    this._subscriptions = [
      this._errorService.closeWorkspace.subscribe(() => {
        const workspace = this.items.filter(item => item.selected)[0];
        this.onDeleteWorkspace({id: workspace.id, name: workspace.name});
      }),
      this._activatedRoute.queryParamMap.subscribe(() => {
        if (!this.trySetSelectedWorkspace(this.getWorkspaceIdFromUrl())) {
          const selected = this.getSelectedOrFirstWorkspace();
          this.onSelect({
            id: selected.id
          } as any);
        }
      }),
    ];

    this._internalComponentService.toggleMenu(true);

    this._changeDetectionRef.markForCheck();
  }

  onSelect(event: IWorkspaceEvent) {
    this.items.forEach(a => a.selected = a.id === event.id);

    this._applicationSettingsService.updateWorkspaces(
      workpace => {
        workpace.selected = workpace.id === event.id;
      }
    );

    this._changeDetectionRef.markForCheck();

    this.navigate(`dashboard/workspaces/${event.id}`);
  }

  onEdit(event: IWorkspaceEvent) {
    this.items.forEach(a => {
      if (a.id === event.id) {
        a.name = event.name;
      }
    });

    this._applicationSettingsService.updateWorkspace(event.id,
      workpace => {
        workpace.name = event.name;
      }
    );

    this._changeDetectionRef.markForCheck();
  }

  onWorkspaceClose(event: IWorkspaceEvent) {
    const dialogRef = this._dialogService.open({
      title: 'Delete workspace',
      content: WorkspacePanelDialogComponent,
      actions: [{
        text: 'No',
        primary: true
      }, {
        text: 'Yes',
        doClose: true,
      }]
    });

    dialogRef.dialog.location.nativeElement.classList.add('workspace-panel-dialog');
    (dialogRef.content.instance as WorkspacePanelDialogComponent).eventToDelete = event;

    dialogRef.result.subscribe(result => {
      if ((result as any).doClose) {
        this.onDeleteWorkspace(event);
      }
    });
  }

  onDeleteWorkspace(eventToDelete: IWorkspaceEvent) {
    this.items = this.items.filter(a => a.id !== eventToDelete.id);

    this._routeCache.removeWorkspace(eventToDelete.id);

    this.didWorkspaceClose.next();

    this._applicationSettingsService.removeWorkspace(eventToDelete.id);

    if (this.items.length === 0) {
      this.onAdd();
      return;
    }

    const selected = this.getSelectedOrFirstWorkspace();
    this.onSelect({
      id: selected.id
    } as any);
  }

  onAdd() {
    const newItem = this.addWorkspace();

    this._changeDetectionRef.markForCheck();

    this.navigate(`dashboard/workspaces/${newItem.id}`);
  }

  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  /**
   * Open dialog to reorder workspaces and change current application workplace state
   */
  onReorder(): void {
    const dialogRef = this._dialogService.open({
      title: 'Reorder workspaces',
      content: WorkspaceReorderDialogComponent
    });

    const instance = dialogRef.content.instance as WorkspaceReorderDialogComponent;
    instance.workspaces = this.items.map(workspace => ({ ...workspace }));

    instance.didSave.subscribe((result: IWorkspaceItem[]) => {
      if (result) {
        this.items = result;

        const itemIds = this.items.map(el => el.id);
        this._applicationSettingsService.reorderWorspaces(itemIds);
        this._changeDetectionRef.detectChanges();
      }
    });
  }

  private getWorkspaceIdFromUrl(): string {
    const parts = this._router.url.split('/');
    return parts[3];
  }

  private navigate(route: string) {
    this._router.navigate([route]);
  }

  private addWorkspace(name?: string): IWorkspaceItem {
    const newItem = getDefaultConfig({
      name: name || `Workspace ${this.items.length + 1}`,
      selected: true
    });

    this.items.forEach(a => a.selected = false);
    this.items.push(newItem);

    this._applicationSettingsService.updateWorkspaces(
      workpace => {
        workpace.selected = false;
      }
    );

    this._applicationSettingsService.addWorkspace(
      newWorkspace => {
        const empty = this._lvGoldenLayoutService.createEmptyLayout(newItem.id);
        newWorkspace.id = newItem.id;
        newWorkspace.name = newItem.name;
        newWorkspace.selected = true;
        newWorkspace.goldenLayoutState = empty;
      }
    );

    return newItem;
  }

  private getSelectedOrFirstWorkspace(): IWorkspaceItem {
    let found = this.items.find(a => a.selected);
    if (!found && this.items.length > 0) {
      found = this.items[0];
    }

    found.selected = true;

    return found;
  }

  private trySetSelectedWorkspace(workspaceId: string): boolean {
    const found = this.items.find(a => a.id === workspaceId);

    if (found) {
      this.items.forEach(a => a.selected = a.id === found.id);
      return true;
    }

    return false;
  }
}
