import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component,
  Input, OnChanges, OnDestroy, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
// tslint:disable-next-line: max-line-length
import { LvAdvancedGridColumn, LvAdvancedGridEnumColumn, LvAdvancedGridNumericColumn } from 'src/app/leversys-core-ui/components/lv-advanced-grid/lv-advanced-grid';
import { LvAdvancedGridComponent } from 'src/app/leversys-core-ui/components/lv-advanced-grid/lv-advanced-grid.component';
import { LvError } from 'src/app/leversys-core-ui/models/lv-error/base';
import { LvErrorService } from 'src/app/leversys-core-ui/services/lv-error/lv-error.service';
import { constants } from 'src/app/leversys-core-ui/util/constants';
import { LvLookupEnum } from 'src/app/leversys-core-ui/util/lv-lookup-enum';
// tslint:disable-next-line: max-line-length
import { IQuantityCurrencyOverrides } from 'src/app/modules/analytics/models/company-and-user-settings/other-settings/quantity-settings/quantity-currency-overrides';
// tslint:disable-next-line: max-line-length
import { IQuantitySectionSettings } from 'src/app/modules/analytics/models/company-and-user-settings/other-settings/quantity-settings/quantity-section-settings';
import { Currency } from 'src/app/modules/convertible-bond-terms/models/convertible-bond-terms/custom-enum/currency-enum';
import { v4 } from 'uuid';

/**
 * Quantity section settings component.
 */
@Component({
  selector: 'lv-quantity-section-settings',
  templateUrl: './lv-quantity-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvQuantitySectionSettingsComponent implements OnDestroy, AfterViewInit, OnChanges {

  @ViewChild(LvAdvancedGridComponent) advancedGrid: LvAdvancedGridComponent;
  @Input() settings: IQuantitySectionSettings;
  @Input() overrideSystemDefaults: boolean;
  @Input() env: string;
  checkBoxId: string;
  columns: LvAdvancedGridColumn[];

  private _subscriptions: Subscription[];

  get isDisabled(): boolean {
    return !this.overrideSystemDefaults || !this.settings.useQuantityValue;
  }

  constructor(private _errorService: LvErrorService,
              private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.overrideSystemDefaults = true;
    this.checkBoxId = v4();
    this.columns = [];
    this.settings = {
      currencyOverrides: []
    } as IQuantitySectionSettings;
    this._subscriptions = [];
    this.env = '';
  }

  /**
   * Occurs on changes.
   * @param changes SimpleChanges object.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.overrideSystemDefaults) {
      this.initColumns();
    }
  }

  /**
   * Occurs after view init.
   */
  ngAfterViewInit() {
    this.initColumns();

    this._subscriptions = [
      this.advancedGrid.didDataChange.subscribe((records: IQuantityCurrencyOverrides[]) => this.onDataChange(records)),
      this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];

    this._changeDetectorRef.detectChanges();
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Gets checkbox ID.
   * @param name Checkbox name.
   * @returns Checkbox ID.
   */
  getCheckBoxId(name: string): string {
    return `${name}-${this.checkBoxId}`;
  }

  /**
   * Creates form group.
   * @param args CreateFormGroupArgs object.
   * @returns FormGroup object.
   */
  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    return new FormGroup({
      'currencyCode': new FormControl(args.dataItem.currencyCode, Validators.required),
      'value': new FormControl(args.dataItem.value, Validators.required)
    });
  }

  /**
   * Handles error.
   * @param error LvError object.
   */
  public onError(error: LvError) {
    this._errorService.handleError(error);
  }

  /**
   * Occurs on data change.
   * @param records List of IQuantityCurrencyOverrides objects.
   */
  public onDataChange(records: IQuantityCurrencyOverrides[]) {
    this.settings.currencyOverrides.splice(0, this.settings.currencyOverrides.length);
    this.settings.currencyOverrides.push(...records);
  }

  /**
   * Columns initialization.
   */
  private initColumns() {
    this.columns = [];

    const quantityCurrencyColumn = new LvAdvancedGridEnumColumn();
    quantityCurrencyColumn.enumDescription = Currency;
    quantityCurrencyColumn.title = '';
    quantityCurrencyColumn.field = 'currencyCode';
    quantityCurrencyColumn.data = new LvLookupEnum(Currency).items;
    quantityCurrencyColumn.width = 80;
    quantityCurrencyColumn.editable = this.overrideSystemDefaults;
    quantityCurrencyColumn.dmKey = 'DM-2253';

    const quantityCurrencyOverrideColumn = new LvAdvancedGridNumericColumn();
    quantityCurrencyOverrideColumn.title = '';
    quantityCurrencyOverrideColumn.field = 'value';
    quantityCurrencyOverrideColumn.decimals = '0';
    quantityCurrencyOverrideColumn.format = 'n0';
    quantityCurrencyOverrideColumn.outputFormat = constants.numberFormat.zeroDigits;
    quantityCurrencyOverrideColumn.width = 104;
    quantityCurrencyOverrideColumn.editable = this.overrideSystemDefaults;
    quantityCurrencyOverrideColumn.dmKey = 'DM-2254';

    this.columns = [quantityCurrencyColumn, quantityCurrencyOverrideColumn];
  }
}
