<lv-flex-box class="lv-dividends-schedule-dividends">
  <lv-schedule-dividends-table
    [scheduleDividends]="dividendsSettings.dividendsSchedule.dividendSchedule"
    [dividendsCopyAndPasteSettings]="dividendsCopyAndPasteSettings"
    (dividendScheduleChange)="doDividendScheduleChange()"
    [editEnabled]="true">
  </lv-schedule-dividends-table>
  <lv-flex-box direction="row" class="lv-dividends-schedule-source-controls">
    <button kendoButton
      lvId="marketDataDividendsScheduleDisplayDividendsUsed"
      (click)="doDisplayDividendUseButtonClick()">
        Display Dividends Used
    </button>
  </lv-flex-box>
</lv-flex-box>
