import { Component, OnInit, Output, Input, EventEmitter, ViewChild,
  ChangeDetectorRef, OnDestroy, OnChanges, ViewEncapsulation, ChangeDetectionStrategy, Optional } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { constants, LvLookupEnum } from '@lv-core-ui/util';
import { LvConvertibleBondTermsPresenter } from '../../lv-convertible-bond-terms.presenter';
import { LvAdvancedGridComponent, LvAdvancedGridColumn, LvAdvancedGridDateColumn,
         LvAdvancedGridNumericColumn, LvAdvancedGridEnumColumn } from '@lv-core-ui/components';
import { LvDataMaster, LvError, LvErrorType } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { MarketDataClipboard } from '@lv-analytics/components';
import { ConversionScheduleItem, ConversionCustomData, SetupStatus, CurrencyType,
         ConvertibleBondTermsEvent } from '@lv-convertible-bond/models';
import { LvExcelService } from '@lv-excel/services';

@Component({
  selector: 'lv-conversion-schedule',
  templateUrl: './lv-conversion-schedule.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvConversionScheduleComponent implements OnInit, OnDestroy, OnChanges {
  @Input() model: ConversionScheduleItem[];
  @Input() priceCurrency: string;
  @Input() conversionCustomData: ConversionCustomData;
  @Output() didConversionScheduleChange: EventEmitter<ConversionScheduleItem[]>;

  @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;

  createFormGroup: (args: CreateFormGroupArgs) => FormGroup;

  private _modelSubscription: Subscription[];
  private _formGroupSubscription: Subscription[];

  columns: LvAdvancedGridColumn[];
  conversionScheduleItems: ConversionScheduleItem[];
  parseFn: any;
  ratio: number;
  price: number;

  formatSix = '#,###.######';
  decimalsSix = '6';
  excelFieldAlias = 'CONV_SCHED_RANGE';

  get hasScheduleInExcelOverride(): boolean {
    return !!this._excelSvc?.containsField(this.excelFieldAlias);
  }

  get isFieldFromExcelEnabled(): boolean {
    return !!this._excelSvc?.getField(this.excelFieldAlias)?.editable;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _errorService: LvErrorService,
    private _presenter: LvConvertibleBondTermsPresenter,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.parseFn = this.parserFunction.bind(this);
    this.initColumns();
    this.conversionScheduleItems = [];
    this._modelSubscription = [];
    this._formGroupSubscription = [];
    this.didConversionScheduleChange = new EventEmitter<ConversionScheduleItem[]>();
    this.createFormGroup = this.doCreateFormGroup.bind(this);
  }

  ngOnInit() {
    this._modelSubscription = [
        this.advancedGrid.didDataChange.subscribe((records: ConversionScheduleItem[]) => this.onScheduleChange(records)),
        this.advancedGrid.doReload.subscribe(() => this.onScheduleReload()),
        this.advancedGrid.didError.subscribe((error: LvError) => this.onError(error))
    ];

    this.init();
  }

  ngOnChanges() {
    this.init();
    this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this._modelSubscription.forEach(s => s.unsubscribe());
    this._formGroupSubscription.forEach(s => s.unsubscribe());
  }

  applyAdvancedGridChanges() {
    this.advancedGrid.applyChanges(records => this.applyRecords(records));
  }

  calculateRatioOrPrice(scheduleItems: ConversionScheduleItem[]): ConversionScheduleItem[] {
  return scheduleItems = scheduleItems.map(a => ({
      ...a,
      conversionPriceCCY: this.conversionCustomData?.conversionPriceCurrency,
      rebateCurrencyType: this.conversionCustomData?.rebateCurrency,
      ratio: this.calculateRatio(a.ratio, a.conversionPrice),
      conversionPrice: this.calculatePrice(a.ratio, a.conversionPrice)
    })
    );
  }

  calculateRatio(ratio: number, price: number): number {
    if (price > 0) {
        // tslint:disable-next-line:max-line-length
      return this.conversionCustomData.conversionPriceCurrency === CurrencyType.Underlying && this.conversionCustomData.fixedFx ?
            (this.conversionCustomData.fixedFx * this.conversionCustomData.nominal) / price : this.conversionCustomData.nominal / price;
    } else {
      return ratio;
    }
  }

  calculatePrice(ratio: number, price: number): number {
    if (ratio > 0) {
      return this.conversionCustomData.conversionPriceCurrency === CurrencyType.Underlying && this.conversionCustomData.fixedFx ?
            (this.conversionCustomData.fixedFx * this.conversionCustomData.nominal) / ratio : this.conversionCustomData.nominal / ratio;
    } else {
      return price;
    }
  }

  private doCreateFormGroup(args: CreateFormGroupArgs): FormGroup {
    const fg =  new FormGroup({
      'startDate': new FormControl(args.isNew ? new Date() : args.dataItem.startDate, Validators.required),
      'endDate': new FormControl(args.isNew ? new Date() : args.dataItem.endDate),
      'ratio': new FormControl(args.dataItem.ratio),
      'conversionPrice': new FormControl(args.dataItem.conversionPrice),
      'rebate': new FormControl(args.dataItem.rebate),
      'rebateCurrencyType': new FormControl(args.dataItem.rebateCurrencyType),
      'conversionPriceCCY': new FormControl(args.dataItem.conversionPriceCCY)
    });

    this._formGroupSubscription.push(
      fg.get('ratio').valueChanges.subscribe(val => {
        const price = this.calculatePrice(val, fg.get('conversionPrice').value);
        fg.get('conversionPrice').patchValue(price, { emitEvent: false });
      }),
      fg.get('conversionPrice').valueChanges.subscribe(val => {
        const ratio = this.calculateRatio(fg.get('ratio').value, val);
        fg.get('ratio').patchValue(ratio, { emitEvent: false } );
      })
    );

    return fg;
  }

  private init() {
    if (this.model) {
      this.initColumns();
      this.conversionScheduleItems = this.model.map(a => ({ ...a }));
      this.conversionScheduleItems = this.calculateRatioOrPrice(this.conversionScheduleItems);
    }
  
    this._changeDetectorRef.detectChanges();
  }

  private updateColumns() {
    this.advancedGrid.updateColumns(c => this.updateColumn(c));
  }

  private updateColumn(c: LvAdvancedGridColumn) {
    if (c.field === 'conversionPrice') {
      c.title = this.priceCurrency ? 'Price (' + this.priceCurrency + ')' : 'Price (N/A)';
      c.visible = this.conversionCustomData && this.conversionCustomData.status !== SetupStatus.NewIssue;
    }
    if (c.field === 'ratio' || c.field === 'rebate') {
      c.visible = this.conversionCustomData && this.conversionCustomData.status !== SetupStatus.NewIssue;
    }
  }

  private initColumns() {
    this.columns = [];

    const startDateColumn = new LvAdvancedGridDateColumn();
    startDateColumn.title = 'Start Date';
    startDateColumn.field = 'startDate';
    startDateColumn.dmKey = 'DM-2288';

    const endDateColumn = new LvAdvancedGridDateColumn();
    endDateColumn.title = 'End Date';
    endDateColumn.field = 'endDate';
    endDateColumn.dmKey = 'DM-2289'

    const ratioColumn = new LvAdvancedGridNumericColumn();
    ratioColumn.title = 'Ratio';
    ratioColumn.field = 'ratio';
    ratioColumn.dmKey = 'DM-2290';
    ratioColumn.format = this.formatSix;
    ratioColumn.outputFormat = constants.numberFormat.upToSixDigits;
    ratioColumn.decimals = this.decimalsSix;
    this.updateColumn(ratioColumn);

    const priceColumn = new LvAdvancedGridNumericColumn();
    priceColumn.title = 'Price';
    priceColumn.field = 'conversionPrice';
    priceColumn.dmKey = 'DM-2291';
    priceColumn.format = this.formatSix;
    priceColumn.outputFormat = constants.numberFormat.upToSixDigits;
    priceColumn.decimals = this.decimalsSix;
    this.updateColumn(priceColumn);

    const rebateColumn = new LvAdvancedGridNumericColumn();
    rebateColumn.title = 'Rebate';
    rebateColumn.field = 'rebate';
    rebateColumn.dmKey = 'DM-2292'
    rebateColumn.outputFormat = constants.numberFormat.upToTwoDigits;
    rebateColumn.format = '#,###.##';
    rebateColumn.decimals = '2';
    this.updateColumn(rebateColumn);

    const rebateCCYTypeColumn = new LvAdvancedGridEnumColumn();
    rebateCCYTypeColumn.enumDescription = CurrencyType;
    rebateCCYTypeColumn.title = 'Rebate CCY';
    rebateCCYTypeColumn.field = 'rebateCurrencyType';
    rebateCCYTypeColumn.displayField = 'text';
    rebateCCYTypeColumn.valueField = 'id';
    rebateCCYTypeColumn.valuePrimitive = true;
    rebateCCYTypeColumn.visible = false;
    rebateCCYTypeColumn.data = new LvLookupEnum(CurrencyType).items;

    const conversionPriceCCYColumn = new LvAdvancedGridEnumColumn();
    conversionPriceCCYColumn.enumDescription = CurrencyType;
    conversionPriceCCYColumn.title = 'Conversion CCY';
    conversionPriceCCYColumn.field = 'conversionPriceCCY';
    conversionPriceCCYColumn.displayField = 'text';
    conversionPriceCCYColumn.valueField = 'id';
    conversionPriceCCYColumn.valuePrimitive = true;
    conversionPriceCCYColumn.visible = false;
    conversionPriceCCYColumn.data = new LvLookupEnum(CurrencyType).items;

    this.columns.push(startDateColumn);
    this.columns.push(endDateColumn);
    this.columns.push(ratioColumn);
    this.columns.push(priceColumn);
    this.columns.push(rebateColumn);
    this.columns.push(rebateCCYTypeColumn);
    this.columns.push(conversionPriceCCYColumn);
  }

  private parserFunction(pastedDataRecords: string[]): ConversionScheduleItem[] {
    const scheduleItems: ConversionScheduleItem[] = [];

    pastedDataRecords.forEach(r => {
      const items = r.split('\t');

      const startDateValue = items[0];
      const endDateValue = items[1];
      const ratioValue = items[2];
      const conversionPriceValue = items[3];
      const rebateValue = items[4];
      const rebateCurrencyTypeValue = items[5];
      const conversionPriceCCYValue = items[6];

      const startDate = MarketDataClipboard.parseDateValue(startDateValue, 'Start Date');
      const endDate = MarketDataClipboard.parseDateValue(endDateValue, 'End Date');
      const ratio = MarketDataClipboard.parseNumberValue(ratioValue, 'Ratio');
      const conversionPrice = MarketDataClipboard.parseNumberValue(conversionPriceValue, 'Price');
      const rebate = MarketDataClipboard.parseNumberValue(rebateValue, 'Rebate');
      const rebateCurrencyType = this.parseRebateCurrencyValueType(rebateCurrencyTypeValue);
      const conversionPriceCCYType = this.parseRebateCurrencyValueType(conversionPriceCCYValue);

      scheduleItems.push({
        startDate: startDate,
        endDate: endDate,
        ratio: ratio,
        conversionPrice: conversionPrice,
        rebate: rebate,
        rebateCurrencyType: rebateCurrencyType,
        conversionPriceCCY: conversionPriceCCYType
      } as ConversionScheduleItem);
    });

    return scheduleItems;
  }

  private parseRebateCurrencyValueType(rebateCurrencyTypeValue: string): CurrencyType {

    if (MarketDataClipboard.isEmpty(rebateCurrencyTypeValue)) {
      return CurrencyType.Convertible;
    }

    const rebateCurrencyTypeEnum = CurrencyType[rebateCurrencyTypeValue];
    if (!rebateCurrencyTypeEnum) {
      const errorMessage = LvDataMaster.getErrorWithParameters('dM-2003', new Map([['{ value}', rebateCurrencyTypeValue], ['{field}', 'Rebate Currency']]));
      throw new LvError(errorMessage, 'Paste Error', LvErrorType.USER_FRIENDLY);
    }

    return rebateCurrencyTypeEnum;
  }

  private onScheduleChange(scheduleItems: ConversionScheduleItem[]) {
    this.applyRecords(scheduleItems);
    // this.didConversionScheduleChange.next(this.model);
  }

  private applyRecords(records: any[]) {
    records = this.calculateRatioOrPrice(records);
    this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.ConversionRatioOrRebateUpdated);

    this.conversionScheduleItems = records;

    this.model.splice(0, this.model.length);
    this.model.push(...records);
    this.didConversionScheduleChange.next(this.model);
  }

  private onScheduleReload() {
    this.conversionScheduleItems = this.conversionScheduleItems.map(a => ({ ...a }));
  }

  private onError(error: LvError) {
    this._errorService.handleError(error);
  }
}
