<lv-flex-box class="lv-convertible-bond-terms-general-issue">
  <lv-flex-box direction="row" data-cy="DM-334">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="instrumentNameSourceTooltipTemplate"
      filter="span">
      <span [attr.data-tooltip-id]="'instrumentNameSourceTooltip'">
        Instrument Name
      </span>
    </div>
    <div *ngIf="!isLvsConvertible;else lvsConvertibleName" class=" lv-input-field lv-input-field--full">
      <lv-text-box
        class="lv-terms-general-instrument"
        [(ngModel)]="model.name"
        (didKeyDownEnter)="doModelChange()"
        (didBlur)="doModelChange()"
        name="instrument-name-general">
      </lv-text-box>
    </div>
    <ng-template #lvsConvertibleName>
      <div class="lv-terms-general-output">
        {{model.name}}
      </div>
    </ng-template>
  </lv-flex-box>
  <lv-flex-box direction="row">
    <lv-flex-box class="lv-general-terms-first-col">
      <lv-flex-box direction="row" data-cy="DM-681">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="instrumentShortNameSourceTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'instrumentShortNameSourceTooltip'">
            Instrument Short Name
          </span>
        </div>
        <div *ngIf="!isLvsConvertible;else lvsConvertibleShortname" class=" lv-input-field lv-input-field--full">
          <lv-text-box
            class="lv-terms-general-instrument-short"
            [(ngModel)]="model.shortName"
            (didKeyDownEnter)="doModelChange()"
            (didBlur)="doModelChange()"
            name="short-name">
          </lv-text-box>
        </div>
        <ng-template #lvsConvertibleShortname>
          <div class="lv-terms-general-output">
            {{model.shortName}}
          </div>
        </ng-template>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-348">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="issuerNameSourceTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'issuerNameSourceTooltip'">
            Issuer Name
          </span>
        </div>
        <div *ngIf="!isLvsConvertible;else lvsConvertibleIssuername" class=" lv-input-field lv-input-field--full">
          <lv-text-box class="lv-terms-general-instrument-issuer"
            [(ngModel)]="model.issuerName"
            (didBlur)="doModelChange()"
            (didKeyDownEnter)="doModelChange()"
            name="issuer-name-general">
          </lv-text-box>
        </div>
        <ng-template #lvsConvertibleIssuername>
          <div class="lv-terms-general-output">
            {{model.issuerName}}
          </div>
        </ng-template>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-1670">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="underlyingNameSourceTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'underlyingNameSourceTooltip'">
            Underlying Name
          </span>
        </div>
        <div *ngIf="!isLvsConvertible;else lvsConvertibleUnderlyingname" class=" lv-input-field lv-input-field--full">
        <lv-text-box
          class="lv-terms-general-underlying"
          [(ngModel)]="model.underlyingEquity.name"
          (didBlur)="doModelChange()"
          (didKeyDownEnter)="doModelChange()"
          name="underlying-name-general">
        </lv-text-box>
        </div>
        <ng-template #lvsConvertibleUnderlyingname>
          <div class="lv-terms-general-output">
            {{model.underlyingEquity.name}}
          </div>
        </ng-template>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-335">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="convertibleTypeSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'convertibleTypeSourceTooltip'">
            Convertible Type
          </span>
          <lv-xl-label [field]="'CB_TYPE'"></lv-xl-label>
        </div>
        <div class="lv-input-field lv-input-field--auto">
          <kendo-dropdownlist
            lvId="lv-general-instrument-subtype"
            name="instrument-subtype"
            [data]="instrumentSubTypeLookup.items"
            valueField="id"
            textField="text"
            valuePrimitive="true"
            [popupSettings]="{ width: 237 }"
            [(ngModel)]="model.subType"
            (ngModelChange)="onConvertibleTypeChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-379">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="CCYSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'CCYSourceTooltip'">
            CCY
          </span>
          <lv-xl-label data-cy="CCY" [field]="'CCY'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <kendo-dropdownlist
            lvId="lv-general-ccy"
            class="lv-terms-general-small-input"
            name="ccy"
            [data]="currencyLookup.items"
            valueField="id"
            textField="text"
            valuePrimitive="true"
            [(ngModel)]="this.model.currencyCode"
            (ngModelChange)="onCCYChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-380">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="underlyingCCYSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'underlyingCCYSourceTooltip'">
            Underlying CCY
          </span>
          <lv-xl-label data-cy="UND_CCY" [field]="'UND_CCY'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <kendo-dropdownlist
            lvId="lv-general-underlying-ccy"
            class="lv-terms-general-small-input"
            name="underlying-ccy"
            [data]="currencyLookup.items"
            valueField="id"
            textField="text"
            valuePrimitive="true"
            [(ngModel)]="model.underlyingEquity.currencyCode"
            (ngModelChange)="onCCYChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-989">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="maturityCCYSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'maturityCCYSourceTooltip'">
            Maturity CCY
          </span>
          <lv-xl-label [field]="'MAT_CCY'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <kendo-dropdownlist
            lvId="lv-general-maturity-ccy"
            class="lv-terms-general-small-input"
            name="ccy"
            [data]="currencyLookup.items"
            valueField="id"
            textField="text"
            valuePrimitive="true"
            [(ngModel)]="this.model.maturingCurrencyCode"
            (ngModelChange)="doModelChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-370">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="firstSettlementSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'firstSettlementSourceTooltip'">
            First Settlement
          </span>
          <lv-xl-label [field]="'FRST_STL'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <kendo-datepicker
            [(ngModel)]="model.firstSettlementDate"
            (ngModelChange)="onFirstSettlementDateChange()"
            (blur)="onDateBlur()"
            name="first-settlement">
          </kendo-datepicker>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-371">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="maturityDateSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'maturityDateSourceTooltip'">
            Maturity Date
          </span>
          <lv-xl-label data-cy="MAT" [field]="'MAT'"></lv-xl-label>
        </div>
        <div class="lv-input-field" *ngIf="!model.isPerpetual">
          <kendo-datepicker
            placeholder=""
            [disabled]="model.isPerpetual"
            [(ngModel)]="model.maturityDate"
            (ngModelChange)="onMaturityDateChange()"
            (blur)="onDateBlur()"
            [attr.aria-valuetext]="dateFormated"
            name="general-maturity-date">
          </kendo-datepicker>
        </div>
        <div data-cy="DM-990" class="lv-field lv-field--checkbox
              lv-terms-general-checkbox"
              *ngIf="!model.isPerpetual"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="perpetualTooltipTemplate"
              filter="label:not(lv-xl-label label)">
            <input class="k-checkbox"
              type="checkbox"
              [attr.id]="maturityDatePerpetualCheckboxId"
              [(ngModel)]="model.isPerpetual"
              (ngModelChange)="onPerpetualChange()"
              name="maturity-date-perpetual">
            <label class="k-checkbox-label xl-label"
              lvId="perpetual-general"
              [attr.for]="maturityDatePerpetualCheckboxId">
              Perpetual
            </label>
            <lv-xl-label [field]="'PERP'"></lv-xl-label>
        </div>
        <div data-cy="DM-990" class="lv-field lv-field--checkbox"
            *ngIf="model.isPerpetual"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="perpetualTooltipTemplate"
            filter="label:not(lv-xl-label label)">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="maturityDatePerpetualCheckboxId"
            [(ngModel)]="model.isPerpetual"
            (ngModelChange)="onPerpetualChange()"
            name="maturity-date-perpetual">
          <label class="k-checkbox-label xl-label"
            lvId="perpetual-general"
            [attr.for]="maturityDatePerpetualCheckboxId">
            Perpetual
          </label>
          <lv-xl-label [field]="'PERP'"></lv-xl-label>
        </div>
      </lv-flex-box>
      <lv-flex-box data-cy="DM-996" direction="row">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="fiscalYearStartDateSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'fiscalYearStartDateSourceTooltip'">
            Fiscal Year Start Date
          </span>
          <lv-xl-label [field]="'FSCL_YEAR_START_DATE'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <kendo-datepicker
            placeholder=""
            [(ngModel)]="model.fiscalYearStartDate"
            (ngModelChange)="doModelChange()"
            name="fiscal-year-start-date">
          </kendo-datepicker>
        </div>
      </lv-flex-box>
      <lv-flex-box data-cy="DM-999" direction="row">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="exchangeableTypeSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'exchangeableTypeSourceTooltip'">
            Exchangeable Type
          </span>
          <lv-xl-label [field]="'EXCH_TYPE'"></lv-xl-label>
        </div>
        <div class="lv-input-field lv-input-field--auto">
          <kendo-dropdownlist
            lvId="lv-general-exchangeable-type"
            name="exchangeable-type"
            [data]="exchangeableTypeLookup.items"
            valueField="id"
            textField="text"
            valuePrimitive="true"
            [popupSettings]="{ width: 206 }"
            [(ngModel)]="model.exhangeableParameters.exhangeableType"
            (ngModelChange)="onExchangeableTypeChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box *ngIf="isPledgedShares" direction="row" data-cy="DM-401">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="percentOfPledgedSharesSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'percentOfPledgedSharesSourceTooltip'">
            % Pledged Shares
          </span>
          <lv-xl-label [field]="'PLGD_SHRS'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            [format]="formatTwo"
            [decimals]="decimalsTwo"
            [(ngModel)]="model.exhangeableParameters.percentOfPledgedShares"
            (didBlur)="doModelChange()"
            (didKeyDownEnter)="doModelChange()"
            name="pledged-shares">
          </lv-numeric-text-box>
        <span class="lv-sufix-label">%</span>
      </div>
      </lv-flex-box>
      <lv-flex-box
        *ngIf="isLvsConvertible"
          direction="row"
          data-cy="DM-349">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="countryTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'countryTooltip'">
            Country
          </span>
        </div>
        <div class="lv-terms-general-output">
          {{country}}
        </div>
      </lv-flex-box>
      <lv-flex-box 
        *ngIf="isLvsConvertible"
        direction="row"
        data-cy="DM-350">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="riskCountryTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'riskCountryTooltip'">
            Risk Country
          </span>
        </div>
        <div class="lv-terms-general-output">
          {{riskCountry}}
        </div>
      </lv-flex-box>
      <lv-flex-box
        *ngIf="isLvsConvertible"
        direction="row"
        data-cy="DM-1286">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="instrumentStatusTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'InstrumentStatusTooltip'">
            Instrument Status
          </span>
        </div>
        <div class="lv-input-field lv-input-field--auto">
          <div class="lv-terms-general-output">
            {{other.instrumentStatus}}
          </div>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-1403">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="statusSourceTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'StatusSourceTooltip'">
            Setup Status
          </span>
        </div>
        <div *ngIf="!isLvsConvertible;else lvsConvertibleStatus" class="lv-input-field lv-input-field--auto">
          <kendo-dropdownlist
            lvId="lv-general-status"
            name="underlying-ccy"
            [data]="setupStatusLookup.items"
            valueField="id"
            textField="text"
            valuePrimitive="true"
            [popupSettings]="{ width: 136 }"
            [(ngModel)]="status"
            (ngModelChange)="doStatusChange()">
          </kendo-dropdownlist>
        </div>
        <ng-template #lvsConvertibleStatus>
          <div class="lv-terms-general-output">
            {{ view.getStatusDescription(status) }}
          </div>
        </ng-template>
      </lv-flex-box>
      <lv-flex-box
        *ngIf="isLvsConvertible"
        direction="row"
        data-cy="DM-354"
        >
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="issueSeriesTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'issueSeriesTooltip'">
            Issue Series
          </span>
        </div>
        <div class="lv-terms-general-output">
          {{model.issueSeries}}
        </div>
      </lv-flex-box>
      <lv-flex-box
        *ngIf="isLvsConvertible"
        direction="row"
        data-cy="DM-993"
        >
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="daystoSettleTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'daystoSettleTooltip'">
            Days to Settle
          </span>
          <lv-xl-label [field]="'STL_DAYS'"></lv-xl-label>
        </div>
        <div class="lv-terms-general-output">
          {{model.daysToSettle}}
        </div>
      </lv-flex-box>
      <lv-flex-box
        *ngIf="isLvsConvertible"
        direction="row"
        data-cy="DM-1287"
        >
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="exchangeTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'exchangeTooltip'">
            Exchange
          </span>
        </div>
        <div class="lv-terms-general-output">
          {{model.exhangeCode}}
        </div>
      </lv-flex-box>
      <lv-flex-box
        *ngIf="isLvsConvertible"
        direction="row"
        data-cy="DM-1288">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="underlyingTypeTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'underlyingTypeTooltip'">
            Underlying Type
          </span>
        </div>
        <div class="lv-terms-general-output">
          {{underlyingType}}
        </div>
      </lv-flex-box>
      <lv-flex-box
        *ngIf="isLvsConvertible"
         direction="row"
         data-cy="DM-346">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="underlyingMICTooltipTemplate"
          filter="span">
          <span [attr.data-tooltip-id]="'underlyingMICTooltip'">
            Underlying MIC
          </span>
        </div>
        <div class="lv-terms-general-output">
          {{model.underlyingEquity.exchangeCode}}
        </div>
      </lv-flex-box>
    </lv-flex-box>
    <lv-flex-box class="lv-general-terms-second-col">
      <lv-flex-box direction="row">
        <div class="lv-label lv-label-field">&nbsp;</div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-385">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="nominalValueSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'nominalValueSourceTooltip'">
            Nominal Value
          </span>
          <lv-xl-label [field]="'NOM'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            [format]="formatFour"
            [decimals]="decimalsFour"
            [(ngModel)]="model.nominalValue"
            (didBlur)="onNominalChanged()"
            (didKeyDownEnter)="onNominalChanged()"
            name="nominal-value-general">
          </lv-numeric-text-box>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-383">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="issueValueSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'issueValueSourceTooltip'">
            Issue Price
          </span>
          <lv-xl-label [field]="'ISSUE_PX'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            [format]="formatTwo"
            [decimals]="decimalsTwo"
            [(ngModel)]="model.issueValue"
            (didBlur)="onIssueValueChange()"
            (didKeyDownEnter)="onIssueValueChange()"
            name="issue-value-general">
          </lv-numeric-text-box>
        </div>
        <div data-cy="DM-991" class="lv-field lv-field--checkbox lv-terms-general-checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="issueValueNominalCheckboxId"
            [(ngModel)]="model.issueValueIsPercentOfNominal"
            (ngModelChange)="doModelChange()"
            name="issue-value-nominal">
          <label class="k-checkbox-label xl-label"
            lvId="percentOfParIssueValueGeneral"
            [attr.for]="issueValueNominalCheckboxId">
            % of Par
          </label>
          <lv-xl-label [field]="'ISSUE_PX_AS_PAR'"></lv-xl-label>
        </div>
      </lv-flex-box>
      <lv-flex-box data-cy="DM-384" direction="row" *ngIf="!isRedemptionHide">
        <div class="lv-label lv-label-field"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="redemptionValueSourceTooltipTemplate"
        filter="span:not(lv-xl-label span)">
        <span [attr.data-tooltip-id]="'redemptionValueSourceTooltip'">
          Redemption Value
        </span>
        <lv-xl-label [field]="'REDEM_PX'"></lv-xl-label>
      </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            [format]="formatTwo"
            [decimals]="decimalsTwo"
            [(ngModel)]="model.redemptionValue"
            (didBlur)="doModelChange()"
            (didKeyDownEnter)="doModelChange()"
            name="redemption-value">
          </lv-numeric-text-box>
        </div>
        <div  data-cy="DM-992" class="lv-field lv-field--checkbox lv-terms-general-checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="issueValueRedemptionCheckboxId"
            [(ngModel)]="model.redemptionValueIsPercentOfNominal"
            (ngModelChange)="doModelChange()"
            name="issue-value-redemption">
          <label class="k-checkbox-label xl-label"
            lvId="percentOfParRedemptionGeneral"
            [attr.for]="issueValueRedemptionCheckboxId">
            % of Par
          </label>
          <lv-xl-label [field]="'REDEM_PX_AS_PAR'"></lv-xl-label>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-374">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="quotedSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'quotedSourceTooltip'">
            Quoted
          </span>
          <lv-xl-label [field]="'QUOTED'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <kendo-dropdownlist
            lvId="lv-general-quoted"
            name="quoted"
            [data]="isQuotedCleanLookup.items"
            valueField="id"
            textField="text"
            valuePrimitive="true"
            [(ngModel)]="quotedValue"
            (valueChange)="quotedValueChange($event)">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-382">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="priceAsParSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'priceAsParSourceTooltip'">
            Price as Par
          </span>
          <lv-xl-label [field]="'PRICED_AS_PAR'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="priceAsParCheckboxId"
            [(ngModel)]="model.isPriceAsPar"
            (ngModelChange)="onPriceAsParChange()"
            name="price-as-par">
          <label class="k-checkbox-label"
            lvId="priceAsParGeneral"
            [attr.for]="priceAsParCheckboxId">
          </label>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-998">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="accruedUponDefaultSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'accruedUponDefaultSourceTooltip'">
            Accrued Upon Default
          </span>
          <lv-xl-label [field]="'ACCR_DEFAULT'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="accruedUponDefaultCheckboxId"
            [(ngModel)]="model.isAccruedUponDefault"
            (ngModelChange)="doModelChange()"
            name="accrued-upon-default">
          <label class="k-checkbox-label"
            lvId="accruedUpon"
            [attr.for]="accruedUponDefaultCheckboxId">
          </label>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-997">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="recoveryUponDefaultSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'recoveryUponDefaultSourceTooltip'">
            Recovery Upon Default
          </span>
          <lv-xl-label [field]="'RCVR_DEFAULT'"></lv-xl-label>
        </div>
        <div class="lv-input-field lv-input-field--auto">
          <kendo-dropdownlist
            lvId="lv-general-recovery"
            name="underlying-ccy"
            [data]="recoveryUponDefaultLookup.items"
            valueField="id"
            textField="text"
            valuePrimitive="true"
            [popupSettings]="{ width: 128 }"
            [(ngModel)]="model.recoveryUponDefault"
            (ngModelChange)="doModelChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" *ngIf="isDeatachable" data-cy="DM-1001">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="percentageOfCoveredWarrantsSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'percentageOfCoveredWarrantsSourceTooltip'">
            % of Covered Warrants
          </span>
          <lv-xl-label [field]="'WRRNTS_COVERED'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            [format]="formatTwo"
            [decimals]="decimalsTwo"
            [(ngModel)]="model.percCoveredWarrants"
            (didBlur)="doModelChange()"
            (didKeyDownEnter)="doModelChange()"
            name="cov-warrant">
          </lv-numeric-text-box>
          <span class="lv-sufix-label">%</span>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-402">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="sinkableSourceTooltipTemplate"
          filter="span:not(lv-xl-label span)">
          <span [attr.data-tooltip-id]="'SinkableSourceTooltip'">
            Sinkable
          </span>
          <lv-xl-label [field]="'SINKABLE'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="sinkableCheckboxId"
            [(ngModel)]="model.sinkable"
            (ngModelChange)="doModelChange()"
            name="sinkable">
          <label
            lvId="sinkableCheckboxGeneral"
            class="k-checkbox-label"
            [attr.for]="sinkableCheckboxId"></label>
        </div>
      </lv-flex-box>
      <lv-flex-box class="lv-fx-section" *ngIf="isCrossFx" direction="row">
        <lv-fieldset-panel legend="FX">
          <lv-flex-box direction="row" data-cy="DM-994">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="fxQuantoSourceTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.data-tooltip-id]="'fxQuantoSourceTooltip'">
                FX Quanto
              </span>
              <lv-xl-label [field]="'FX_QUANTO'"></lv-xl-label>
            </div>
            <div class="lv-field lv-field--checkbox">
            <input class="k-checkbox"
                type="checkbox"
                [attr.id]="fxQuantoCheckboxId"
                [(ngModel)]="model.fxQuanto"
                (ngModelChange)="doModelChange()"
                name="fx-quanto">
              <label class="k-checkbox-label"
                lvId="fxQuanto"
                [attr.for]="fxQuantoCheckboxId">
              </label>
            </div>
          </lv-flex-box>
          <lv-flex-box direction="row" data-cy="DM-995">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="undCCYLinkedSourceTooltipTemplate"
              filter="span:not(lv-xl-label span)">
              <span [attr.data-tooltip-id]="'undCCYLinkedSourceTooltip'">
                Und CCY Linked
              </span>
              <lv-xl-label [field]="'CCY_LINKED'"></lv-xl-label>
            </div>
            <div class="lv-field lv-field--checkbox">
              <input class="k-checkbox"
                type="checkbox"
                [attr.id]="undCcyLinkedCheckboxId"
                [(ngModel)]="model.underlyingCurrencyLinked"
                (ngModelChange)="doModelChange()"
                name="und-ccy-linked">
              <label class="k-checkbox-label"
                lvId="undCCYLinked"
                [attr.for]="undCcyLinkedCheckboxId">
              </label>
            </div>
          </lv-flex-box>
          <lv-terms-general-fx-information
            [stockReferenceSignalStore]="stockReferenceSignalStore"
            (didSetFxRate)="doSetFxRate($event)">
          </lv-terms-general-fx-information>
          <lv-flex-box
            *ngIf="isLvsConvertible"
            direction="row"
            data-cy="DM-1292">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="fXPrevailingRateTooltipTemplate"
              filter="span">
              <span [attr.data-tooltip-id]="'fXPrevailingRateTooltip'">
                FX Prevailing Rate
              </span>
            </div>
            <div class="lv-terms-general-output">
              {{prevailingRate}}
            </div>
          </lv-flex-box>
          <lv-flex-box
            *ngIf="isLvsConvertible"
            direction="row"
            data-cy="DM-1293">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="fXTimeTooltipTemplate"
              filter="span">
              <span [attr.data-tooltip-id]="'fXTimeTooltip'">
                FX Time
              </span>
            </div>
            <div class="lv-terms-general-output">
              {{other.fxRateTime | lvLocalDate}}
            </div>
          </lv-flex-box>
          <lv-flex-box
            *ngIf="isLvsConvertible" 
            direction="row"
            data-cy="DM-1294">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="fXTimeZoneTooltipTemplate"
              filter="span">
              <span [attr.data-tooltip-id]="'fXTimeZoneTooltip'">
                FX Time Zone
              </span>
            </div>
            <div class="lv-terms-general-output">
              {{other.fxRateTimeZone}}
            </div>
          </lv-flex-box>
          <lv-flex-box
            *ngIf="isLvsConvertible"
            direction="row"
            data-cy="DM-1295">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="fXRatePriceTypeTooltipTemplate"
              filter="span">
              <span [attr.data-tooltip-id]="'fXRatePriceTypeTooltip'">
                FX Rate Price Type
              </span>
            </div>
            <div class="lv-terms-general-output">
              {{fxRateTypes}}
            </div>
          </lv-flex-box>
          <lv-flex-box
            *ngIf="isLvsConvertible"
            direction="row"
            data-cy="DM-1296">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="fXSourceTooltipTemplate"
              filter="span">
              <span [attr.data-tooltip-id]="'fXSourceTooltip'">
                FX Source
              </span>
            </div>
            <div class="lv-terms-general-output">
              {{other.fxRateSource}}
            </div>
          </lv-flex-box>
          <lv-flex-box
            *ngIf="isLvsConvertible"
            direction="row"
            data-cy="DM-1297">
            <div class="lv-label lv-label-field"
              showAfter="1500"
              kendoTooltip
              [tooltipTemplate]="fXPageTooltipTemplate"
              filter="span">
              <span [attr.data-tooltip-id]="'fXPageTooltip'">
                FX Page
              </span>
            </div>
            <div class="lv-terms-general-output">
              {{other.fxRatePage}}
            </div>
          </lv-flex-box>
        </lv-fieldset-panel>
      </lv-flex-box>
    </lv-flex-box>
  </lv-flex-box>

  <lv-flex-box *ngIf="isNewIssue" class="lv-terms-general-fieldset lv-pricing-range" direction="row">
    <lv-fieldset-panel legend="PRICING RANGE">
      <lv-flex-box direction="row">
        <lv-flex-box>
          <div class="lv-label lv-label-field">&nbsp;</div>
        </lv-flex-box>
        <lv-flex-box class="lv-terms-general-small-input">
          <div class="lv-label lv-label-field">Best</div>
        </lv-flex-box>
        <lv-flex-box class="lv-terms-general-small-input">
          <div class="lv-label lv-label-field">Mid</div>
        </lv-flex-box>
        <lv-flex-box class="lv-terms-general-small-input">
          <div class="lv-label lv-label-field">Worst</div>
        </lv-flex-box>
      </lv-flex-box>

      <lv-flex-box
        class="lv-terms-quick-terms-assumption"
        direction="row"
        data-cy="DM-4348">

        <lv-flex-box>
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="premiumLabelSourceTooltipTemplate"
            filter="span:not(lv-xl-label span)">
            <span [attr.data-tooltip-id]="'premiumLabelSourceTooltip'">
              {{premiumLabel}}
            </span>
            <lv-xl-label data-cy="NI_PREM_PRC_RANGE" [field]="'ISSUE_PREM_PRC'"></lv-xl-label>
          </div>
        </lv-flex-box>

        <lv-flex-box
          class="lv-terms-general-small-input"
          data-cy="DM-4351">

          <lv-numeric-text-box
            *ngIf="!isPeps"
            [autoWidth]="true"
            [format]="numberFormatAssumption"
            [decimals]="numberOfDecimalsPercentageAssumption"
            name="premiumBestGeneral"
            [(ngModel)]="priceTalk.premiumBest"
            (didBlur)="doPremiumBestOrWorstChange()"
            (didKeyDownEnter)="doPremiumBestOrWorstChange()">
          </lv-numeric-text-box>

          <lv-numeric-text-box
            *ngIf="isPeps"
            [autoWidth]="true"
            [format]="numberFormatAssumption"
            [decimals]="numberOfDecimalsPercentageAssumption"
            name="higherStrikePremiumBestGeneral"
            [(ngModel)]="priceTalk.higherStrikePremiumBest"
            (didBlur)="doPremiumBestOrWorstChange()"
            (didKeyDownEnter)="doPremiumBestOrWorstChange()">
          </lv-numeric-text-box>

        </lv-flex-box>
        <lv-flex-box
          class="lv-terms-general-small-input"
          data-cy="DM-4352">

          <lv-numeric-text-box
            *ngIf="!isPeps"
            [autoWidth]="true"
            [format]="numberFormatAssumption"
            [decimals]="numberOfDecimalsPercentageAssumption"
            name="premiumMidGeneral"
            [(ngModel)]="premiumMid"
            [readonly]="true"
            [disabled]="true">
          </lv-numeric-text-box>

          <lv-numeric-text-box
            *ngIf="isPeps"
            [autoWidth]="true"
            [format]="numberFormatAssumption"
            [decimals]="numberOfDecimalsPercentageAssumption"
            name="higherStrikePremiumMidGeneral"
            [(ngModel)]="higherStrikePremiumMid"
            [readonly]="true"
            [disabled]="true">
          </lv-numeric-text-box>

        </lv-flex-box>
        <lv-flex-box
          class="lv-terms-general-small-input"
          data-cy="DM-4353">

          <lv-numeric-text-box
            *ngIf="!isPeps"
            [autoWidth]="true"
            [format]="numberFormatAssumption"
            [decimals]="numberOfDecimalsPercentageAssumption"
            name="premiumWorstGeneral"
            [(ngModel)]="priceTalk.premiumWorst"
            (didBlur)="doPremiumBestOrWorstChange()"
            (didKeyDownEnter)="doPremiumBestOrWorstChange()">
          </lv-numeric-text-box>

          <lv-numeric-text-box
            *ngIf="isPeps"
            [autoWidth]="true"
            [format]="numberFormatAssumption"
            [decimals]="numberOfDecimalsPercentageAssumption"
            name="higherStrikePremiumWorstGeneral"
            [(ngModel)]="priceTalk.higherStrikePremiumWorst"
            (didBlur)="doPremiumBestOrWorstChange()"
            (didKeyDownEnter)="doPremiumBestOrWorstChange()">
          </lv-numeric-text-box>

        </lv-flex-box>
      </lv-flex-box>

      <lv-flex-box
        direction="row"
        data-cy="DM-1304">

        <lv-flex-box>
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="couponPriceRangeSourceTooltiTemplate"
            filter="span:not(lv-xl-label span)">
            <span [attr.data-tooltip-id]="'couponPriceRangeSourceTooltip'">
              Coupon
            </span>
            <lv-xl-label [field]="'NI_CPN_RT_RANGE'"></lv-xl-label>
          </div>
        </lv-flex-box>
        <lv-flex-box
          class="lv-terms-general-small-input"
          data-cy="DM-4317">
          <lv-numeric-text-box
            [autoWidth]="true"
            [format]="numberFormatAssumption"
            [decimals]="numberOfDecimalsPercentageAssumption"
            name="couponBestGeneral"
            [(ngModel)]="priceTalk.couponBest"
            (didBlur)="onCouponBestChange()"
            (didKeyDownEnter)="onCouponBestChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
        <lv-flex-box
          class="lv-terms-general-small-input"
          data-cy="DM-4318">
          <lv-numeric-text-box
            [autoWidth]="true"
            [format]="numberFormatAssumption"
            [decimals]="numberOfDecimalsPercentageAssumption"
            name="couponMidGeneral"
            [readonly]="true"
            [disabled]="true"
            [(ngModel)]="couponMid">
          </lv-numeric-text-box>
        </lv-flex-box>
        <lv-flex-box
          class="lv-terms-general-small-input"
          data-cy="DM-4319">
          <lv-numeric-text-box
            [autoWidth]="true"
            [format]="numberFormatAssumption"
            [decimals]="numberOfDecimalsPercentageAssumption"
            name="couponWorstGeneral"
            [(ngModel)]="priceTalk.couponWorst"
            (didBlur)="onCouponWorstChange()"
            (didKeyDownEnter)="onCouponWorstChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
      </lv-flex-box>

      <lv-flex-box
        direction="row"
        data-cy="DM-1305">

        <lv-flex-box>
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="issuePricePRSourceTooltipTemplate"
            filter="span:not(lv-xl-label span)">
            <span [attr.data-tooltip-id]="'issuePricePRSourceTooltip'">
              Issue Price
            </span>
            <lv-xl-label [field]="'NI_ISSUE_PX_RANGE'"></lv-xl-label>
          </div>
        </lv-flex-box>
        <lv-flex-box
          class="lv-terms-general-small-input"
          data-cy="DM-4322">
          <lv-numeric-text-box
            [autoWidth]="true"
            [format]="numberFormatAssumption"
            [decimals]="numberOfDecimalsPercentageAssumption"
            name="issuePriceBestGeneral"
            [(ngModel)]="priceTalk.issuePriceBest"
            (didBlur)="onIssuePriceBestChange()"
            (didKeyDownEnter)="onIssuePriceBestChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
        <lv-flex-box
          class="lv-terms-general-small-input"
          data-cy="DM-4323">
          <lv-numeric-text-box
            [autoWidth]="true"
            [format]="numberFormatAssumption"
            [decimals]="numberOfDecimalsPercentageAssumption"
            name="issuePriceMidGeneral"
            [disabled]="true"
            [readonly]="true"
            [(ngModel)]="issuePriceMid">
          </lv-numeric-text-box>
        </lv-flex-box>
        <lv-flex-box
          class="lv-terms-general-small-input"
          data-cy="DM-4324">
          <lv-numeric-text-box
            [autoWidth]="true"
            [format]="numberFormatAssumption"
            [decimals]="numberOfDecimalsPercentageAssumption"
            name="issuePriceWorstGeneral"
            [(ngModel)]="priceTalk.issuePriceWorst"
            (didBlur)="onIssuePriceWorstChange()"
            (didKeyDownEnter)="onIssuePriceWorstChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
      </lv-flex-box>

      <lv-flex-box
        direction="row"
        data-cy="DM-1306">
        <lv-flex-box>
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="redemptionValuePRSourceTooltipTemplate"
            filter="span:not(lv-xl-label span)">
            <span [attr.data-tooltip-id]="'redemptionValuePRSourceTooltip'">
              Redemption Value
            </span>
            <lv-xl-label [field]="'NI_REDEM_PX_RANGE'"></lv-xl-label>
          </div>
        </lv-flex-box>
        <lv-flex-box
          class="lv-terms-general-small-input"
          data-cy="DM-4326">
          <lv-numeric-text-box
            [autoWidth]="true"
            [format]="numberFormatAssumption"
            [decimals]="numberOfDecimalsPercentageAssumption"
            [disabled]="isPeps || isPerpetual || isPercs"
            name="redemptionBestGeneral"
            [(ngModel)]="priceTalk.redemptionValueBest"
            (didBlur)="onRedemptionBestChange()"
            (didKeyDownEnter)="onRedemptionBestChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
        <lv-flex-box
          class="lv-terms-general-small-input"
          data-cy="DM-4327">
          <lv-numeric-text-box
            [autoWidth]="true"
            [format]="numberFormatAssumption"
            [readonly]="true"
            [disabled]="true"
            [decimals]="numberOfDecimalsPercentageAssumption"
            name="redemptionMidGeneral"
            [(ngModel)]="redemptionValueMid">
          </lv-numeric-text-box>
        </lv-flex-box>
        <lv-flex-box
          class="lv-terms-general-small-input"
          data-cy="DM-4328">
          <lv-numeric-text-box
            [autoWidth]="true"
            [format]="numberFormatAssumption"
            [disabled]="isPeps || isPerpetual || isPercs"
            [decimals]="numberOfDecimalsPercentageAssumption"
            name="redemptionWorstGeneral"
            [(ngModel)]="priceTalk.redemptionValueWorst"
            (didBlur)="onRedemptionWorstChange()"
            (didKeyDownEnter)="onRedemptionWorstChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
      </lv-flex-box>

      <lv-flex-box
        direction="row"
        data-cy="DM-4349">

        <lv-flex-box>
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="issueYieldPRSourceTooltipTemplate"
            filter="span:not(lv-xl-label span)">
            <span [attr.data-tooltip-id]="'issueYieldPRSourceTooltip'">
              Issue Yield
            </span>
            <lv-xl-label [field]="'NI_YLD_RANGE'"></lv-xl-label>
          </div>
        </lv-flex-box>
        <lv-flex-box
          class="lv-terms-general-small-input"
          data-cy="DM-4355">
          <lv-numeric-text-box
            [autoWidth]="true"
            [format]="numberFormatAssumption"
            [disabled]="isPeps || isPerpetual || isPercs"
            [decimals]="numberOfDecimalsPercentageAssumption"
            name="issueYieldBestGeneral"
            [(ngModel)]="priceTalk.issueYieldBest"
            (didBlur)="onIssueYieldBestChange()"
            (didKeyDownEnter)="onIssueYieldBestChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
        <lv-flex-box
          class="lv-terms-general-small-input"
          data-cy="DM-4356">
          <lv-numeric-text-box
            [autoWidth]="true"
            [format]="numberFormatAssumption"
            [disabled]="true"
            [decimals]="numberOfDecimalsPercentageAssumption"
            [readonly]="true"
            name="issueYieldMidGeneral"
            [(ngModel)]="issueYieldMid">
          </lv-numeric-text-box>
        </lv-flex-box>
        <lv-flex-box
          class="lv-terms-general-small-input"
          data-cy="DM-4357">
          <lv-numeric-text-box
            [autoWidth]="true"
            [format]="numberFormatAssumption"
            [disabled]="isPeps || isPerpetual || isPercs"
            [decimals]="numberOfDecimalsPercentageAssumption"
            name="issueYieldWorstGeneral"
            [(ngModel)]="priceTalk.issueYieldWorst"
            (didBlur)="onIssueYieldWorstChange()"
            (didKeyDownEnter)="onIssueYieldWorstChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
      </lv-flex-box>

      <!-- Stock Ref ({{model.currencyCode}}) -->

      <lv-flex-box
        *ngIf="isStockRef"
        direction="row">
        <lv-flex-box>
          <div class="lv-label lv-label-field">&nbsp;</div>
        </lv-flex-box>
        <lv-flex-box class="lv-terms-general-small-input">
          <div class="lv-label lv-label-field lv-label-field-left">Stock ({{model.currencyCode}})</div>
        </lv-flex-box>
        <lv-flex-box 
          *ngIf="isCrossFx"
          class="lv-terms-general-small-input">
          <div class="lv-label lv-label-field lv-label-field-left">Stock ({{model.underlyingEquity.currencyCode}})</div>
        </lv-flex-box>
      </lv-flex-box>

      <lv-flex-box
        class="lv-terms-general-stock-ref"
        direction="row">

        <lv-flex-box>
          <div class="lv-label lv-label-field lv-label-before-checkbox"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="stockRefPRSourceTooltipTemplate"
            filter="span:not(lv-xl-label span)">
            <span [attr.data-tooltip-id]="'stockRefPRSourceTooltip'">
              Fixed Reference
            </span>
            <lv-xl-label [field]="'FIXED_STK_REF_CBCCY'"></lv-xl-label>
          </div>
        </lv-flex-box>

        <lv-flex-box
          data-cy="DM-4618">
          <div class="lv-field lv-field--checkbox lv-terms-general-checkbox">
            <input class="k-checkbox"
              type="checkbox"
              [attr.id]="stockRefCheckBoxId"
              name="stockRefGeneralCheckBox"
              [(ngModel)]="isStockRef"
              (ngModelChange)="onStockPriceReferenceChange()">
            <label class="k-checkbox-label"
              lvId="lv-general-stock-ref"
              [attr.for]="stockRefCheckBoxId">
            </label>
          </div>
        </lv-flex-box>


        <ng-container *ngIf="isStockRef">
          <lv-flex-box 
            class="lv-terms-general-small-input"
            data-cy="DM-4618">

            <lv-numeric-text-box
              [autoWidth]="true"
              [format]="numberFormatAssumption"
              [decimals]="numberOfDecimalsPercentageAssumption"
              name="stockRefGeneral"
              [ngModel]="stockReferenceSignalStore.stockRef()"
              (ngModelChange)="onStockRefChange($event)">
            </lv-numeric-text-box>
          </lv-flex-box>

          <lv-flex-box
            *ngIf="isCrossFx"
            class="lv-terms-general-small-input"
            data-cy="DM-4636">

            <lv-numeric-text-box
              [autoWidth]="true"
              [format]="numberFormatAssumption"
              [decimals]="numberOfDecimalsPercentageAssumption"
              name="stockRefGeneralUnd"
              [ngModel]="stockReferenceSignalStore.stockRefEquity()"
              (ngModelChange)="onStockRefEquityChange($event)">
            </lv-numeric-text-box>
          </lv-flex-box>
        </ng-container>
        
      </lv-flex-box>

    </lv-fieldset-panel>
  </lv-flex-box>
</lv-flex-box>

<ng-template #instrumentNameSourceTooltipTemplate>
  <lv-tooltip dm="dM-334"></lv-tooltip>
</ng-template>
<ng-template #instrumentShortNameSourceTooltipTemplate>
  <lv-tooltip dm="dM-681"></lv-tooltip>
</ng-template>
<ng-template #issuerNameSourceTooltipTemplate>
  <lv-tooltip dm="dM-348"></lv-tooltip>
</ng-template>
<ng-template #issuerNameSourceTooltipTemplate>
  <lv-tooltip dm="dM-348"></lv-tooltip>
</ng-template>
<ng-template #underlyingNameSourceTooltipTemplate>
  <lv-tooltip dm="dM-1670"></lv-tooltip>
</ng-template>
<ng-template #convertibleTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-335"></lv-tooltip>
</ng-template>
<ng-template #convertibleTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-335"></lv-tooltip>
</ng-template>
<ng-template #CCYSourceTooltipTemplate>
  <lv-tooltip dm="dM-379"></lv-tooltip>
</ng-template>
<ng-template #underlyingCCYSourceTooltipTemplate>
  <lv-tooltip dm="dM-380"></lv-tooltip>
</ng-template>
<ng-template #maturityCCYSourceTooltipTemplate>
  <lv-tooltip dm="dM-989"></lv-tooltip>
</ng-template>
<ng-template #firstSettlementSourceTooltipTemplate>
  <lv-tooltip dm="dM-370"></lv-tooltip>
</ng-template>
<ng-template #maturityDateSourceTooltipTemplate>
  <lv-tooltip dm="dM-371"></lv-tooltip>
</ng-template>
<ng-template #fiscalYearStartDateSourceTooltipTemplate>
  <lv-tooltip dm="dM-996"></lv-tooltip>
</ng-template>
<ng-template #exchangeableTypeSourceTooltipTemplate>
  <lv-tooltip dm="dM-999"></lv-tooltip>
</ng-template>
<ng-template #percentOfPledgedSharesSourceTooltipTemplate>
  <lv-tooltip dm="dM-401"></lv-tooltip>
</ng-template>
<ng-template #statusSourceTooltipTemplate>
  <lv-tooltip dm="dM-1403"></lv-tooltip>
</ng-template>
<ng-template #statusSourceTooltipTemplate>
  <lv-tooltip dm="dM-1403"></lv-tooltip>
</ng-template>
<ng-template #nominalValueSourceTooltipTemplate>
  <lv-tooltip dm="dM-385"></lv-tooltip>
</ng-template>
<ng-template #issueValueSourceTooltipTemplate>
  <lv-tooltip dm="dM-383"></lv-tooltip>
</ng-template>
<ng-template #redemptionValueSourceTooltipTemplate>
  <lv-tooltip dm="dM-384"></lv-tooltip>
</ng-template>
<ng-template #quotedSourceTooltipTemplate>
  <lv-tooltip dm="dM-374"></lv-tooltip>
</ng-template>
<ng-template #priceAsParSourceTooltipTemplate>
  <lv-tooltip dm="dM-382"></lv-tooltip>
</ng-template>
<ng-template #accruedUponDefaultSourceTooltipTemplate>
  <lv-tooltip dm="dM-998"></lv-tooltip>
</ng-template>
<ng-template #recoveryUponDefaultSourceTooltipTemplate>
  <lv-tooltip dm="dM-997"></lv-tooltip>
</ng-template>
<ng-template #percentageOfCoveredWarrantsSourceTooltipTemplate>
  <lv-tooltip dm="dM-1001"></lv-tooltip>
</ng-template>
<ng-template #sinkableSourceTooltipTemplate>
  <lv-tooltip dm="dM-402"></lv-tooltip>
</ng-template>
<ng-template #fxQuantoSourceTooltipTemplate>
  <lv-tooltip dm="dM-994"></lv-tooltip>
</ng-template>
<ng-template #undCCYLinkedSourceTooltipTemplate>
  <lv-tooltip dm="dM-995"></lv-tooltip>
</ng-template>
<ng-template #premiumLabelSourceTooltipTemplate>
  <lv-tooltip dm="dM-357"></lv-tooltip>
</ng-template>
<ng-template #couponPriceRangeSourceTooltiTemplate>
  <lv-tooltip dm="dM-1304"></lv-tooltip>
</ng-template>
<ng-template #issuePricePRSourceTooltipTemplate>
  <lv-tooltip dm="dM-1305"></lv-tooltip>
</ng-template>
<ng-template #redemptionValuePRSourceTooltipTemplate>
  <lv-tooltip dm="dM-1306"></lv-tooltip>
</ng-template>
<ng-template #issueYieldPRSourceTooltipTemplate>
  <lv-tooltip dm="dM-358"></lv-tooltip>
</ng-template>
<ng-template #stockRefPRSourceTooltipTemplate>
  <lv-tooltip dm="dM-360"></lv-tooltip>
</ng-template>
<ng-template #countryTooltipTemplate>
  <lv-tooltip dm="dM-349"></lv-tooltip>
</ng-template>
<ng-template #riskCountryTooltipTemplate>
  <lv-tooltip dm="dM-350"></lv-tooltip>
</ng-template>
<ng-template #issueSeriesTooltipTemplate>
  <lv-tooltip dm="dM-354"></lv-tooltip>
</ng-template>
<ng-template #daystoSettleTooltipTemplate>
  <lv-tooltip dm="dM-993"></lv-tooltip>
</ng-template>
<ng-template #exchangeTooltipTemplate>
  <lv-tooltip dm="dM-1287"></lv-tooltip>
</ng-template>
<ng-template #underlyingTypeTooltipTemplate>
  <lv-tooltip dm="dM-1288"></lv-tooltip>
</ng-template>
<ng-template #fXPrevailingRateTooltipTemplate>
  <lv-tooltip dm="dM-1292"></lv-tooltip>
</ng-template>
<ng-template #fXTimeTooltipTemplate>
  <lv-tooltip dm="dM-1293"></lv-tooltip>
</ng-template>
<ng-template #fXTimeZoneTooltipTemplate>
  <lv-tooltip dm="dM-1294"></lv-tooltip>
</ng-template>
<ng-template #underlyingMICTooltipTemplate>
  <lv-tooltip dm="dM-346"></lv-tooltip>
</ng-template>
<ng-template #fXRatePriceTypeTooltipTemplate>
  <lv-tooltip dm="dM-1295"></lv-tooltip>
</ng-template>
<ng-template #fXSourceTooltipTemplate>
  <lv-tooltip dm="dM-1296"></lv-tooltip>
</ng-template>
<ng-template #fXPageTooltipTemplate>
  <lv-tooltip dm="dM-1297"></lv-tooltip>
</ng-template>
<ng-template #perpetualTooltipTemplate>
  <lv-tooltip dm="dM-990"></lv-tooltip>
</ng-template>
<ng-template #instrumentStatusTooltipTemplate>
  <lv-tooltip dm="dM-1286"></lv-tooltip>
</ng-template>
