import { Component, ChangeDetectionStrategy, Input, ViewEncapsulation, HostBinding } from '@angular/core';

import { ILvScenarioReport } from '../lv-scenario.view';

/**
 * Scenario grid component.
 */
@Component({
  selector: 'lv-scenario-grid',
  templateUrl: './lv-scenario-grid.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvScenarioGridComponent {

  @Input() report: ILvScenarioReport;

  get hasData(): boolean {
    return !!this.report;
  }

  get isOneDimGrid(): boolean {
    return !this.report.isTwoDimensionalScenario && !this.report.isNewIssueScenario;
  }

  get isOneDimPriceTalkGrid(): boolean {
    return !this.report.isTwoDimensionalScenario && this.report.isNewIssueScenario;
  }

  get isTwoDimGrid(): boolean {
    return this.report.isTwoDimensionalScenario && !this.report.isNewIssueScenario;
  }

  get isTwoDimPriceTalkGrid(): boolean {
    return this.report.isTwoDimensionalScenario && this.report.isNewIssueScenario;
  }

  constructor() {
    this.report = null;
  }

  @HostBinding('class.lv-scenario-grid-wrapper')
  get isScenarioGrid() {
    return true;
  }
}
