// tslint:disable-next-line:max-line-length
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, HostBinding, Input, ChangeDetectorRef, OnChanges, ViewChild, AfterViewInit } from '@angular/core';
import { IToolsState } from '../../../models/widget-state/tools/tools-state';
import { TabStripComponent } from '@progress/kendo-angular-layout';

/**
 * Tools component.
 */
@Component({
  selector: 'lv-tools',
  templateUrl: './lv-tools.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvToolsComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild('tabStrip', { static: true }) tabStrip: TabStripComponent;

  @Input()
  set state(value: IToolsState) {
    if (!value) {
    }
    this._state = value;
    if (this.tabStrip && this.tabStrip.tabs && this.tabStrip.tabs.length > 0) {
      this.tabStrip.selectTab(0);
      const tab = this.tabStrip.tabs.toArray()[0];
      tab.focused = true;
    }
    this._changeDetectorRef.detectChanges();
  }
  get state(): IToolsState {
    return this._state;
  }
  _state: IToolsState;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) {
   }

  @HostBinding('class.lv-tools')
  get isLvMarktetData(): boolean {
    return true;
  }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {
    if (this.tabStrip && this.tabStrip.tabs && this.tabStrip.tabs.length > 0) {
      this.tabStrip.selectTab(0);
      const tab = this.tabStrip.tabs.toArray()[0];
      tab.focused = true;
    }
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs after view initialization.
   */
  ngAfterViewInit(): void {
    this.tabStrip.selectTab(0);
  }

  /**
   * Occurs on changes.
   */
  ngOnChanges() {
  }
}
