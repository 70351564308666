export interface IDefaultWidgetState {
  widgetType: DefaultWidgetType;
  state: any;
  isOpenedFromExcel: boolean;
}

export enum DefaultWidgetType {
  Instrument = 'instrument',
  Tools = 'tools',
  ModelCustomization = 'model_customization',
  Pricing = 'pricing',
  InstrumentInfo = 'instrument_info'
}

export enum DefaultWidgetTypeDescription {
  instrument = 'Instrument',
  tools = 'tools',
  model_customization = 'Model Customization',
  pricing = 'Pricing',
  instrument_info = 'Instrument Info'
}
