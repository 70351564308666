import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class InternalComponentService {

  menuToggled: Subject<boolean>;

  constructor() {
    this.menuToggled = new Subject<boolean>();
  }

  toggleMenu(isMenuVisible: boolean) {
    this.menuToggled.next(isMenuVisible);
  }
}
