import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectionStrategy, ElementRef, ChangeDetectorRef, OnChanges } from '@angular/core';
import { VariableConversion, CurrencyType } from '@lv-convertible-bond/models';
import { LvLookupEnum } from '@lv-core-ui/util';

@Component({
  selector: 'lv-variable-conversion',
  templateUrl: './lv-variable-conversion.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvVariableConversionComponent implements OnInit, OnChanges {
  @Input() model: VariableConversion;
  @Output() didVariableConversionChange: EventEmitter<VariableConversion>;

  strikeCCYLookup: LvLookupEnum;
  useLockInDate: boolean;

  formatThree = '#,###.###';
  decimalsThree = '3';

  get lockInDate(): Date {
    return this._lockInDate;
  }

  set lockInDate(value: Date) {
    this._lockInDate = value;

    if (this.model && this.useLockInDate) {
      this.model.lockinDate = value;
    }
  }

  private _lockInDate: Date;
  
  constructor(private _changeDetectorRef: ChangeDetectorRef) {
    this.strikeCCYLookup = new LvLookupEnum(CurrencyType);
    this.didVariableConversionChange = new EventEmitter<VariableConversion>();
    this.useLockInDate = false;
   }

  ngOnInit() {
    if (this.model.lockinDate) {
      this.useLockInDate = true;
      this.lockInDate = this.model.lockinDate;
    }
  }

  ngOnChanges() {
    if (this.model && this.model.lockinDate) {
      this.lockInDate = this.model.lockinDate;
    }
  }
  
  onUseLockinChange(value: boolean) {
    this.useLockInDate = value;

    if (this.model) {
      if (value) {
        this.model.lockinDate = this._lockInDate ? this._lockInDate : new Date();

      } else {
        this.model.lockinDate = null
      }
      this.lockInDate = this.model.lockinDate;
    }

    this.onVariableConversionChange();
    this._changeDetectorRef.detectChanges();
  }

  onVariableConversionChange() {
    this.didVariableConversionChange.next(this.model);
  }
}
