<div class="lv-field lv-field--checkbox">
    <input type="checkbox"
      class="k-checkbox"
      tabindex="-1"
      [attr.id]="checkboxId"
      [(ngModel)]="checked"
      (ngModelChange)="checkBoxValueChange()"/>
    <label class="k-checkbox-label" [lvId]="checkBoxLvId" [attr.for]="checkboxId"></label>
</div>

<kendo-datepicker *ngIf="checked"
  class="lv-datepicker-datepicker"
  [lvId]="datePickerLvId"
  [(value)]="innerValue"
  placeholder=""
  (blur)="onBlur()"
  (focus)="onFocus()">>
</kendo-datepicker>

<label *ngIf="!checked"
  class="lv-label lv-label-field">
  {{ dateValue }}
</label>
