import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input,
  HostBinding, ViewChild, OnDestroy, ChangeDetectorRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Subscription, Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { CreateFormGroupArgs, GridComponent, CellClickEvent, SelectableSettings } from '@progress/kendo-angular-grid';

import { constants } from '../../util/constants';
import { LvUtil } from '../../util/util';
import { LvError } from '../../models/lv-error/base';
import { GridAreaSelectionDirective, IGridAreaSelectionMap,
  IGridAreaSelectionChangeEvent } from '../../directives/advanced-grid/grid-area-selection.directive';
import { GridKeyboardRemoveDirective } from '../../directives/advanced-grid/grid-keyboard-remove.directive';
import { GridRowEditingDirective } from '../../directives/advanced-grid/grid-row-editing.directive';
import { GridCellEditingDirective } from '../../directives/advanced-grid/grid-cell-editing.directive';
import { GridContextMenuDirective, IGridContextMenuSelectEvent,
  GridContextMenuItem } from '../../directives/advanced-grid/grid-context-menu.directive';
import { GridPasteDirective, IGridPasteEvent } from '../../directives/advanced-grid/grid-paste.directive';
import { IGridCellContextMenu } from '../../directives/advanced-grid/grid-cell-context-menu.directive';
import { LvAdvancedGridColumn } from '..';

@Component({
  selector: 'lv-advanced-copy-paste-grid',
  templateUrl: './lv-advanced-copy-paste-grid.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvAdvancedCopyPasteGridComponent implements OnInit, OnChanges, OnDestroy, IGridCellContextMenu {

  @ViewChild(GridAreaSelectionDirective, { static: true }) areaSelection: GridAreaSelectionDirective;
  @ViewChild(GridKeyboardRemoveDirective, { static: true }) keyboardRemove: GridKeyboardRemoveDirective;
  @ViewChild(GridCellEditingDirective, { static: true }) cellEditing: GridCellEditingDirective;
  @ViewChild(GridRowEditingDirective, { static: true }) rowEditing: GridRowEditingDirective;
  @ViewChild(GridContextMenuDirective, { static: true }) contextMenu: GridContextMenuDirective;
  @ViewChild(GridComponent, { static: true }) grid: GridComponent;

  @Input() columns: LvAdvancedGridColumn[];
  @Input() records: any[];

  @Input() skipRecordOnDeleteFn: (record: any) => boolean;

  @Input() sortBy: string;
  @Input() sortComparerFn: (value: any) => any;

  @Input() createFormGroup: (args: CreateFormGroupArgs) => FormGroup;

  @Input() parseFn: (records: string[]) => any[];
  @Input() editable: boolean;

  @Output() didDataChange: EventEmitter<any[]>;
  @Output() doReload: EventEmitter<void>;
  @Output() didError: EventEmitter<LvError>;

  @Input() lvId: string;

  @Input() scrollRows: number;
  @Input() width: number;
  @Input() height: number;

  @Input() contextMenuItems: GridContextMenuItem[];

  @Input() selectionStart: number;
  @Input() selectionEnd: number;
  @Input() firstColumnSelectedIndex: number;
  @Input() secondColumnSelectedIndex: number;

  selectable: SelectableSettings;

  // new items
  @Input() pasteItems: string[];
  selectedColumns: {};
  selectedRows: number[];
  checkBoxColumns: {};

  get rowColsMap(): IGridAreaSelectionMap {
    return this._selectedArea ? this._selectedArea.rowColsMap : {};
  }

  get recordItems(): any[] {
    return this._records;
  }

  get columnItems(): LvAdvancedGridColumn[] {
    return this._columns;
  }

  get isDeleteMenuItemVisible(): boolean {
    return this._records.length > 1;
  }

  get editorPopupOpened(): Observable<boolean> {
    return this._editorPopupOpened
      .pipe(
        debounceTime(100)
      );
  }

  get isScrollable(): boolean {
    return true;
  }

  get isVerticalScrollable(): boolean {
    return this._records.length > this.scrollRows;
  }

  get scrollMode(): string {
    return 'scrollable';
  }

  get self(): IGridCellContextMenu {
    return this;
  }

  recordIdField: string;
  constants = constants;

  private _selectedArea: IGridAreaSelectionChangeEvent;
  private _subscriptions: Subscription[];

  private _columns: LvAdvancedGridColumn[];
  private _records: any[];

  private _editorPopupOpened: Subject<boolean>;

  private _contextMenuOpenedEvent: IGridContextMenuSelectEvent;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.editable = true;
    this.scrollRows = 6;
    this.width = null;
    this.height = 182;
    this._records = [];
    this._columns = [];
    this.recordIdField = '_recordId_';

    this.columns = [];
    this.records = [];

    this.skipRecordOnDeleteFn = null;

    this._editorPopupOpened = new Subject<boolean>();

    this.didDataChange = new EventEmitter<any[]>();
    this.doReload = new EventEmitter<void>();
    this.didError = new EventEmitter<LvError>();

    this.selectedRows = [];

    this.selectable = { enabled: true, checkboxOnly: true };
    this._subscriptions = [];

    this.pasteItems = [];

    this.selectedColumns = {};
    this.checkBoxColumns = {};

    this.selectionStart = 0;
    this.selectionEnd = 0;
    this.firstColumnSelectedIndex = 0;
    this.secondColumnSelectedIndex = 0;
  }

  @HostBinding('class.lv-advanced-grid')
  get isLvAdvancedGrid(): boolean {
    return true;
  }

  @HostBinding('class.lv-advanced-copy-paste-grid')
  get isLvAdvancedCopyPasteGrid(): boolean {
    return true;
  }

  ngOnInit() {
    this._subscriptions = [
      this.areaSelection.didSelectionAreaChanged.subscribe(a => this.onSelectionAreaChanged(a)),
      this.keyboardRemove.doRemove.subscribe(rowIndex => this.onRemoveRows(rowIndex, false)),

      this.cellEditing.didSaveCell.subscribe(() => this.onSaveCell()),
      this.cellEditing.didEditCell.subscribe(() => this.detectChanges()),
      this.cellEditing.didCloseCell.subscribe(() => this.detectChanges()),

      this.rowEditing.didAddRow.subscribe((rec: any) => this.onAddRow(rec)),
      this.rowEditing.didEditRow.subscribe(() => this.detectChanges()),
      this.rowEditing.didCloseRow.subscribe(() => this.detectChanges()),
      this.rowEditing.didValidateRow.subscribe(() => this.detectChanges()),

      this.contextMenu.didSelectMenuItem.subscribe((evt: IGridContextMenuSelectEvent) => this.onSelectMenuItem(evt)),
      this.contextMenu.didContextMenuOpen.subscribe((evt: IGridContextMenuSelectEvent) => this.onContextMenuOpen(evt)),
    ];

    this.pasteItems.forEach((item, index) => {
      if (index === 0) {
        this.selectedColumns[item] = this.firstColumnSelectedIndex - 1;
      }
      else {
        this.selectedColumns[item] = this.secondColumnSelectedIndex - 1;
      }
    });

    this.setCheckBoxSelection();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  updateColumns(update: (c: LvAdvancedGridColumn) => void) {
    this._columns.forEach(c => update(c));
    this.detectChanges();
  }

  onPopupToggled(opened: boolean) {
    this._editorPopupOpened.next(opened);
  }

  applyChanges(update: (records: any[]) => void) {
    /*
    * Apply rowEditing first because cell editing
    */
    let dataChanged = false;
    if (this.rowEditing.applyChanges()) {
      this.setNewRecordId();
      dataChanged = true;
    }

    if (this.cellEditing.applyChanges()) {
      dataChanged = true;
    }

    if (dataChanged) {
      this.sortRecords();

      this.detectChanges();

      update(this.copyRecords());
    }
  }

  isContextMenuItemVisible(actionVisible: (event: IGridContextMenuSelectEvent) => boolean) {
    if (!this._contextMenuOpenedEvent) {
      return false;
    }

    return actionVisible(this._contextMenuOpenedEvent);
  }

  ngOnDestroy() {
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  onEditCellContextMenuClick(event: MouseEvent) {
    this.grid.cellClick.next({
      type: 'contextmenu',
      columnIndex: this.grid.activeCell.colIndex,
      rowIndex: this.cellEditing.currentRowIndex,
      dataItem: this.grid.activeCell.dataItem,
      originalEvent: event
    } as CellClickEvent);
  }

  onCheckBoxChange(event, rowIndex: number) {
    if (event.srcElement.checked) {
      this.selectedRows.push(rowIndex);
    } else {
      this.selectedRows = this.selectedRows.filter(x => x !== rowIndex);
    }
    this._changeDetectorRef.detectChanges();
  }

  setCheckBoxSelection() {
    this.records.forEach((data, index) => {
      if (index >= (this.selectionStart - 1) && index <= (this.selectionEnd - 1)) {
        this.selectedRows.push(index);
        this.checkBoxColumns[index] = true;
      }
      else {
        this.checkBoxColumns[index] = false;
      }
    });
    this._changeDetectorRef.detectChanges();
  }

  getRadioButtonId(item: string, orderNumber: number): string {
    return `rb-id-${item}-${orderNumber}`;
  }

  getCheckBoxId(orderNumber: number): string {
    return `cb-id-${orderNumber}`;
  }

  getRbName(componentName: string, item: string): string {
    return `radio-button-${componentName}`;
  }

  public handleColumnSelection(value: number, type: string): void {
    this.selectedColumns[type] = value;
    this.selectedColumns = JSON.parse(JSON.stringify(this.selectedColumns));
    this._changeDetectorRef.detectChanges();
  }

  isCellSelected(rowIndex: number, columnIndex: number) {
    return this.selectedRows.findIndex(x => x === rowIndex) > -1
      && Object.values(this.selectedColumns).findIndex(x => x === columnIndex) > -1;
  }

  private init() {
    if (!this.parseFn) {
      this.parseFn = () => [];
    }

    this._columns = this.columns.map(a => LvUtil.cloneClass(a));

    this.initRecords(this.records);
    this.areaSelection.clearSelection();
  }

  private fireChangeEvent() {
    this.didDataChange.next(this.copyRecords());
  }

  private detectChanges() {
    this._changeDetectorRef.detectChanges();
  }

  private onSelectionAreaChanged(evt: IGridAreaSelectionChangeEvent) {
    this._selectedArea = evt;
    this.detectChanges();
  }

  private onRemoveRows(rowIndex: number, removeAll = false) {
    if (this.editable) {
      this._records = this._records.filter((a, i) => {
        if (!a[this.recordIdField]) {
          return true;
        }

        if (this.skipRecordOnDeleteFn && this.skipRecordOnDeleteFn(a)) {
          return true;
        }

        if (removeAll) {
          return false;
        }

        if (!this._selectedArea) {
          return i !== rowIndex;
        }
        else {
          return i < this._selectedArea.rowFrom || i > this._selectedArea.rowTo;
        }
      });

      this._records.pop();
      this.sortRecords();

      this.areaSelection.clearSelection();

      this.detectChanges();
      this.fireChangeEvent();
    }
  }

  private onSaveCell() {

    this.sortRecords();

    this.detectChanges();
    this.fireChangeEvent();
  }

  private onAddRow(rec: any) {
    this.setNewRecordId();

    this.sortRecords();

    this.detectChanges();
    this.fireChangeEvent();
  }

  private onSelectMenuItem(evt: IGridContextMenuSelectEvent) {
    const found = (this.contextMenuItems || [])
      .find(a => a.actionId === evt.item.data);

    if (found) {
      found.action(evt);
      return;
    }

    switch (evt.item.data) {
      case 'delete': {
        this.onRemoveRows(evt.rowIndex, false);
        break;
      }
      case 'delete-all': {
        this.onRemoveRows(evt.rowIndex, true);
        break;
      }
      // case 'reload': {
      //   this.doReload.next();
      //   break;
      // }
    }
  }

  private onContextMenuOpen(evt: IGridContextMenuSelectEvent) {
    this._contextMenuOpenedEvent = evt;
    this._changeDetectorRef.detectChanges();
  }

  private initRecords(records: any[]) {
    this._records = records.map(a => ({ ...a, [this.recordIdField]: this.getRandomId() }));
    this.sortRecords();
  }

  private sortRecords() {
    if (this.sortBy) {
      this._records.sort(LvUtil.sortBy(this.sortBy, false, this.sortComparerFn));
    }
  }

  private setNewRecordId() {
    const found = this._records.find(a => !a[this.recordIdField]);
    found[this.recordIdField] = this.getRandomId();
  }

  private copyRecords(): any[] {
    return this._records
      .filter(rec => rec[this.recordIdField])
      .map(rec => {
        const copy = { ...rec };
        delete copy[this.recordIdField];
        return copy;
      });
  }

  private getRandomId(): number {
    return Math.floor(Math.random() * 10000);
  }
}
