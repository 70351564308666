<lv-flex-box class="lv-excel-save-modal lv-dialog-actions" direction="column">
  <button
    *ngIf="!isTermsSave"
    kendoButton
    [primary]="true"
    (click)="saveToAll()">
      Save to Application and Excel
  </button>
  <button
    *ngIf="!isTermsSave"
    kendoButton
    [primary]="true"
    (click)="saveToApplication()">
      Save to Application
  </button>
  <button
    kendoButton
    [primary]="true"
    (click)="saveToExcel()">
      Save to Excel
  </button>
</lv-flex-box>
