import { BreakEvenCalculation } from './break-even-calculation';
import { LvMath } from '@lv-core-ui/util';
import { ConvertibleHelper, AnalyticsSettingsHelper } from '@lv-analytics/helpers';
import { IConvertible, IBrakeEvenInput, IBreakeEvenOutput, INewIssueAssumptions, IBrakeEvenCalculateInput, IBreakEvenCalculationOutput,
         IAnalyticsSettings, IPricing, IValuationResult, ConvertibleSubType } from '@lv-analytics/models';

/**
 * Break-even view.
 */
export class BreakEvenView {
  convertible?: IConvertible;
  input: IBrakeEvenInput;
  output: IBreakeEvenOutput;
  curencySuffix: string;
  parityText: string;
  stockRef: number;
  cbRef: number;
  stockRefCB: number;
  crossFX: number;
  priceAsPar: boolean;
  accruedInterest: number;

  newIssueAssumptions: INewIssueAssumptions;

  breakEvenCalculation: BreakEvenCalculation;
  breakEvenCalculationInput: IBrakeEvenCalculateInput;
  beAtParityCalculationOutput: IBreakEvenCalculationOutput;
  beAtParCalculationOutput: IBreakEvenCalculationOutput;
  beAtZeroCalculationOutput: IBreakEvenCalculationOutput;

  private _cHelper: ConvertibleHelper;
  private _asHelper: AnalyticsSettingsHelper;

  constructor() {
    this.convertible = {} as IConvertible;
    this._cHelper = new ConvertibleHelper();
    this._asHelper = new AnalyticsSettingsHelper();
    this.init();
  }

  /**
   * Does initialization.
   * @param settings IAnalyticsSettings objects.
   */
  init(settings?: IAnalyticsSettings) {
    this._asHelper.init(settings);
    this._cHelper.init(this._asHelper.convertible);

    this.input = {} as IBrakeEvenInput;
    this.output = {} as IBreakeEvenOutput;

    this.convertible = this._asHelper.convertible || {} as IConvertible;

    this.input.convertiblePosition = this.convertiblePosition;
    this.curencySuffix = this.ccySuffix;
    this.input.accruedInterest = 0;
    this.input.useAccruedInterest = false;
    this.breakEvenCalculation = new BreakEvenCalculation();
    this.cbRef = 0;
    this.stockRef = 0;
    this.stockRefCB = 0;
    this.crossFX = 0;
    this.priceAsPar = false;
    this.parityText = this.isDetachableOrDeltaNeutral ? 'Conv. amount' : 'Parity';

    this.onPricingUpdated(this._asHelper.pricing);
  }

  /**
   * Occurs on pricing updated.
   * @param pricing IPricing object.
   */
  onPricingUpdated(pricing: IPricing) {
    if (!this.isPeps &&  pricing) {
      this.cbRef = pricing.price;
      this.stockRef = pricing.stockPriceUndCcy;
      this.crossFX = pricing.crossFx;
      this.priceAsPar = this._cHelper.isPriceAsPar;
      this.stockRefCB = pricing.stockPriceCbCcy;
      this.calculateBreakEvenAnalysis();
    }
  }

  /**
   * Occurs on valuation update.
   * @param data IValuationResult object.
   */
  onValuationUpdate(data: IValuationResult): any {
    if (!this.isPeps) {
      this.input.accruedInterest = LvMath.isNumber(data.outputs.accruedInterest) ? data.outputs.accruedInterest : 0;
      if (!LvMath.isNumber(this.input.deltaHedge) || LvMath.isZeroNumber(this.input.deltaHedge)) {
        this.input.deltaHedge = LvMath.isNumber(data.outputs.delta) ? data.outputs.delta : 0;
        this.calculateStockPosition();
      }
    }
  }

  get includeCashRebateInParity(): boolean {
    return this._asHelper.includeCashRebateInParity;
  }

  get isPeps(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.subType === ConvertibleSubType[ConvertibleSubType.PEPS];
  }

  get isPriceAsPar(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.isPriceAsPar;
  }

  get isQuotedClean(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this.convertible.isQuotedClean;
  }

  get convertiblePosition(): number {
    return this._asHelper.getConvertiblePosition;
  }

  get nominal(): number {
    if (!this.convertible) {
      return null;
    }

    return this.convertible.nominal;
  }

  get conversionRatio(): number {
    return this._asHelper.getConversionRatio();
  }

  get isDetachableOrDeltaNeutral(): boolean {
    if (!this.convertible) {
      return false;
    }

    return this._cHelper.isDetachableWarrant || this._cHelper.isDeltaNeutral;
  }

  get ccySuffix(): string {
    if (!this.convertible) {
      return null;
    }

    return this.isDetachableOrDeltaNeutral ? this.convertible.underlying.currency : this.convertible.currencyCode;
  }

  get paritySuffix(): string {
    if (!this.convertible) {
      return null;
    }

    return this.priceAsPar ? 'pts' : this.convertible.currencyCode;
  }

  get fixedFXRate(): number {
    if (!this.convertible) {
      return null;
    }

    if (!this.convertible.isCrossFx) {
      return 1;
    }

    return this.convertible.fixedFxRate !== 0 ? this.convertible.fixedFxRate : 0;
  }

  get eqPosition(): number {
    return this.isDetachableOrDeltaNeutral ? this.input.stockPositon : this.input.stockPositon * -1;
  }

  get delta(): number {
    return this.input.deltaHedge !== null ?  this.input.deltaHedge / 100 : 0;
  }

  get isDisabled(): boolean {
    return this.isPeps || !this._cHelper.convertible;
  }

  get isUseAccruedInterestDisabled(): boolean {
    return this.isPeps || !this.isQuotedClean || !this._cHelper.convertible;
  }

  get convertibleCountryCode(): string {
    if (!this.convertible || !this.convertible.countryCode) {
      return '';
    }
    return this.convertible.countryCode;
  }

  get convertibleStatus(): string {
    if (!this.convertible || !this.convertible.status) {
      return '';
    }
    return this.convertible.status;
  }

  get isPrivate(): boolean {
    if (!this._asHelper || !this._asHelper.settings || !this._asHelper.settings.valuationSession) {
      return false;
    }
    return this._asHelper.settings.valuationSession.isPrivateInstrument;
  }

  get calculateRebate(): number {
    if (!this._asHelper.rebate) {
      return null;
    }
    return this._asHelper.calculateRebate();
  }

  /**
   * Calculates all.
   */
  calculateAll() {
    this.calculateStockPosition();
    this.calculateBreakEvenAnalysis();
    this.calculateDeltaHedge();
  }

  /**
   * Calculates breakeven analysis.
   */
  calculateBreakEvenAnalysis() {
    if (this.cbRef > 0 && this.stockRefCB > 0) {
      this.setBreakEvenCalculationInput();
      this.setAtParityCalculationOutput();
      this.setAtParCalculationOutput();
      this.setAtZeroCalculationOutput();
    }
  }

   /**
    * Calculate Stock Position
    */
  calculateStockPosition() {
    if (this.input.deltaHedge > 0 && this.input.convertiblePosition > 0) {
      let result = (this.input.deltaHedge / 100) * this.conversionRatio * this.input.convertiblePosition;
      if (this.isPriceAsPar) {
        result = this.nominal !== 0 ? result / this.nominal : 0;
      }
      this.input.stockPositon = Math.round(result) * -1 ;
    } else {
      this.input.stockPositon = 0;
    }
  }

   /**
    * Calculate Delta Hedge
    */
  calculateDeltaHedge() {
    let deltaHedge;
    const eqPosition = (this.input.stockPositon * -1) | 0;
    if (this.conversionRatio === 0 || this.input.convertiblePosition === 0) {
      deltaHedge = 0;
    }
    const nominalValue = this.isPriceAsPar ? this.nominal : 1;
    deltaHedge = (100 * eqPosition * nominalValue) / (this.conversionRatio * this.input.convertiblePosition);
    this.input.deltaHedge = deltaHedge;
  }

   /**
    * Set input fields for Break Even calculation
    */
  private setBreakEvenCalculationInput() {
    this.breakEvenCalculationInput = {
      accruedInterest: this.input.useAccruedInterest ? this.input.accruedInterest : 0,
      cbPosition: this.input.convertiblePosition,
      cbRef: this.cbRef,
      conversionRatio: this.conversionRatio,
      crossFX: this.crossFX !== 0 ? this.crossFX : 1,
      delta: this.delta,
      detachable: this.isDetachableOrDeltaNeutral,
      eqPosition: LvMath.isNumber(this.eqPosition) ? this.eqPosition : 0,
      nominal: this.nominal,
      priceAsPar: this.priceAsPar === null ? this.isPriceAsPar : this.priceAsPar,
      refFX: this.fixedFXRate,
      stockRef: this.isDetachableOrDeltaNeutral ? this.stockRef : this.stockRefCB,
      includeCashRabate: this.includeCashRebateInParity,
      rebate: this.includeCashRebateInParity ? this.calculateRebate : 0,
    } as IBrakeEvenCalculateInput;
  }

   /**
    * Set at Parity outputs (stockPriceAtParity, parityAtParity, stockPriceChangeAtParity)
    */
  private setAtParityCalculationOutput() {
    this.beAtParityCalculationOutput = this.breakEvenCalculation.calculateAtParity(this.breakEvenCalculationInput);

    const resultPositive = this.beAtParityCalculationOutput.eqPrice > 0 && Math.abs(this.delta) !== 100;
    this.output.stockPriceAtParity = resultPositive ? this.beAtParityCalculationOutput.eqPrice : 0;
    this.output.parityAtParity = resultPositive ? this.beAtParityCalculationOutput.parity : 0;
    this.output.stockPriceChangeAtParity = resultPositive ? this.beAtParityCalculationOutput.eqPriceChange : 0;
  }

   /**
    * Set at Par outputs (stockPriceAtPar, parityAtPar, stockPriceChangeAtPar, premium)
    */
  private setAtParCalculationOutput() {
    this.beAtParCalculationOutput = this.breakEvenCalculation.calculateAtPar(this.breakEvenCalculationInput);

    const resultPositive = this.beAtParCalculationOutput.eqPrice > 0;
    this.output.stockPriceAtPar = resultPositive ? this.beAtParCalculationOutput.eqPrice : 0;
    this.output.parityAtPar = resultPositive ? this.beAtParCalculationOutput.parity : 0;
    this.output.premium = resultPositive ? this.beAtParCalculationOutput.premium : 0;
    this.output.stockPriceChangeAtPar = resultPositive ? this.beAtParCalculationOutput.eqPriceChange : 0;
  }

   /**
    * Set at zero convertiblePrice output
    */
  private setAtZeroCalculationOutput() {
    this.beAtZeroCalculationOutput = this.breakEvenCalculation.calculateAtZero(this.breakEvenCalculationInput);

    const resultPositive = this.beAtZeroCalculationOutput.cbPrice > 0;
    this.output.convertiblePrice = resultPositive ? this.beAtZeroCalculationOutput.cbPrice : 0;
  }
}
