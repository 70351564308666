import { Component, OnInit, ViewChild, ViewEncapsulation, EventEmitter, Output, Input, ChangeDetectionStrategy,
  ChangeDetectorRef } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { DialogRef } from '@progress/kendo-angular-dialog';
import * as _ from 'lodash';
import { LvMultiSelectComponent } from '@lv-core-ui/components';
import { ITermsSummaryState } from '@lv-application-settings/models';
import { BasicTermsEnum } from '@lv-analytics/models/basic-terms';
import { LvDataMaster } from '@lv-core-ui/models';

/**
 * Basic terms dialog component.
 */
@Component({
  selector: 'lv-basic-terms-dialog',
  templateUrl: './lv-basic-terms-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvBasicTermsDialogComponent implements OnInit {

  @ViewChild(LvMultiSelectComponent, { static: true }) multiSelect: LvMultiSelectComponent;

  @Input() isPeps: boolean;
  @Input() widgetState: ITermsSummaryState;
  @Output() doClose: EventEmitter<void>;
  @Output() didSave: EventEmitter<ITermsSummaryState>;

  availableItems: any[];
  selectedItems: any[];
  pepsFields: string[];
  isLoading: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialogRef: DialogRef,
    private toastrService: ToastrService,
  ) {
    this.selectedItems = [];
    this.availableItems = [];

    this.isPeps = false;
    this.pepsFields = ['minConversionPrice', 'maxConversionRatio', 'minExpectedConversionPrice', 'maxExpectedConversionRatio'];

    this.doClose = new EventEmitter<void>();
    this.didSave = new EventEmitter<ITermsSummaryState>();
    this.isLoading = false;
   }

  /**
   * Handles any additional initialization tasks.
   */
  ngOnInit() {

    const allInstrumentInfoItems = Object.keys(BasicTermsEnum);
    const currentAvailableItems = _.difference(allInstrumentInfoItems, this.widgetState.selectedItems);

    this.widgetState.selectedItems.forEach((item, i) => {
      this.selectedItems.push({
        order: i,
        type: item,
        label: BasicTermsEnum[item]
      });
    });

    currentAvailableItems.forEach((item, i) => {
      this.availableItems.push({
        order: i,
        type: item,
        label: BasicTermsEnum[item]
      });
    });

    this.setItemLabel('conversionPrice', 'Conversion Price', 'Max. Conversion Price');
    this.setItemLabel('conversionRatio', 'Conversion Ratio', 'Min. Conversion Ratio');
    this.setItemLabel('expectedCP', 'Expected CP', 'Max. Expected CP');
    this.setItemLabel('expectedCR', 'Expected CR', 'Min. Expected CR');
  }

  /**
   * Sets item label.
   * @param field Field.
   * @param name Name.
   * @param pepsName PEPS name.
   */
  setItemLabel(field: string, name: string, pepsName: string) {
    let item = this.selectedItems.find(x => x.type === field);

    if (!item) {
      item = this.availableItems.find(x => x.type === field);
    }

    item.label = this.isPeps ? pepsName : name;
  }

  /**
   * Occurs on close.
   */
  onClose() {
    if (!this.isLoading) {
      this.doClose.emit();
      this.dialogRef.close();
    }
  }

  /**
   * Saves terms configuration.
   */
  onSave() {
    try {
      this.isLoading = true;
      this.changeDetectorRef.detectChanges();

      const items = this.multiSelect.getItemsMap();

      this.availableItems = [];
      this.selectedItems = [];

      this.toastrService.success(
        LvDataMaster.getInfo('dM-3388',
        {'value': 'Terms Configuration'}),
        'Terms Configuration'
      );

      const widgetState = {
        availableItems: Object.values(items.availableItems).map(a => a.item.type),
        selectedItems: Object.values(items.selectedItems).map(a => a.item.type)
      } as ITermsSummaryState;

      this.didSave.emit(widgetState);
      this.dialogRef.close();

    }
    catch (error) {
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
    }
  }
}
