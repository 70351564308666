import { IScenarioTemplateInfoResponse } from '@lv-analytics/models';
import { ScenarioTemplateService } from '@lv-analytics/services';
import { LocalStorage, LvUtil } from '@lv-core-ui/util';

/**
 * Sensitivity analysys panel util.
 */
export class LvSensitivityAnalysysPanelUtil {

  private _service: ScenarioTemplateService;

  constructor(service: ScenarioTemplateService) {
    this._service = service;
  }

  /**
   * Gets scenario templates list.
   * @returns List of IScenarioTemplateInfoResponse objects.
   */
  async getScenarioTemplatesList(): Promise<IScenarioTemplateInfoResponse[]> {
    const result = [] as IScenarioTemplateInfoResponse[];
    const user = LocalStorage.getJwtUser();
    const userId = user.UserId.toString();

    try {
      const response = await this._service.getScenarioTemplateInfos();

      const my = response.filter(x => x.ownerId.toString() === userId && !x.isSystem && !x.isShared)
        .sort(LvUtil.sortBy('scenarioTemplateName', false));

      const shared = response.filter(x => !x.isSystem && x.isShared)
        .sort(LvUtil.sortBy('scenarioTemplateName', false));

      const system = response.filter(x => x.isSystem)
        .sort(LvUtil.sortBy('scenarioTemplateName', false));

      // result.push(this.getGroupItem('draft'));
      const draft = {
        isGroup: false,
        ownerId: user.UserId,
        isShared: false,
        isSystem: false,
        scenarioTemplateName: 'Create New Scenario Template',
        subjectId: user.SubjectId,
        isDraft: true
      } as IScenarioTemplateInfoResponse;

      result.push(draft);

      if (my.length > 0) {
        result.push(this.getGroupItem('my'));
        result.push(...my);
      }

      if (shared.length > 0) {
        result.push(this.getGroupItem('shared'));
        result.push(...shared);
      }

      if (system.length > 0) {
        result.push(this.getGroupItem('leversys'));
        result.push(...system);
      }

      return result;
    }
    catch (error) {
      throw error;
    }
  }

  /**
   * Gets group item.
   * @param name Scenario template name.
   * @returns IScenarioTemplateInfoResponse object.
   */
  private getGroupItem(name: string) {
    return {
      id: Math.ceil(Math.random() * 100000000),
      isGroup: true,
      scenarioTemplateName: name
    } as IScenarioTemplateInfoResponse;
  }
}
