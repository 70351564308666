import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Modules
import { LeversysCoreUIModule } from '@lv-core-ui/leversys-core-ui.module';
import { AnalyticsModule } from '@lv-analytics/analytics.module';
import { DocumentsModule } from '@lv-documents/documents.module';
import { InstrumentMonitorModule } from '@lv-instrument-monitor/instrument-monitor.module';
import { LeversysGoldenLayoutModule } from '@lv-golden-layout/leversys-golden-layout.module';

// kendo modules
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

// Gridster
import { GridsterModule } from 'angular-gridster2';

// Components
import { ILvGlComponentMap } from '@lv-golden-layout/models';
import { ConvertibleBondTermsModule } from '@lv-convertible-bond/convertible-bond-terms.module';
import { NotificationsModule } from '@lv-notifications/notifications.module';
import { CustomInstrumentsModule } from '@lv-custom-instruments/custom-instruments.module';
import { ReferenceDataModule } from '@lv-reference-data/reference-data.module';
import { BasicTermsComponent, DocumentsComponent, WorkspacePanelDialogComponent,
         InstrumentAuthorizationComponent, WorkspaceComponent, WorkspaceContentComponent,
         ExternalInstrumentViewComponent } from '.';
import { LayoutStateStorageService } from '@lv-services/layout-state-storage/layout-state-storage.service';
import { InstrumentMonitorComponent } from './instrument-monitor/instrument-monitor.component';
import { InternalComponent } from './internal.component';
import { SettingsComponent } from './settings/settings/settings.component';
import { WorkspacePanelComponent } from './workspace-panel/workspace-panel.component';
import { AnalyticsComponent } from './instrument/analytics/analytics.component';
import { InstrumentComponent } from './instrument/instrument.component';
import { WorkspaceReorderDialogComponent } from './workspace-panel/workspace-reorder-dialog/workspace-reorder-dialog.component';
import { ImageLogoComponent } from './image-logo';

export const componentMap: ILvGlComponentMap = {
  'instrument-monitor': InstrumentMonitorComponent,
  'terms-summary': BasicTermsComponent,
  'analytics': InstrumentComponent,
  'documents': DocumentsComponent
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    // Modules
    LeversysCoreUIModule,
    LeversysGoldenLayoutModule.forRoot(componentMap, LayoutStateStorageService),
    InstrumentMonitorModule,
    AnalyticsModule,
    DocumentsModule,
    ReferenceDataModule,
    ConvertibleBondTermsModule,
    CustomInstrumentsModule,
    NotificationsModule,

    // Kendo modules
    LabelModule,
    ButtonsModule,
    InputsModule,
    DropDownsModule,
    DateInputsModule,
    GridModule,
    LayoutModule,
    SortableModule,
    DialogsModule,
    TooltipModule,
    // Gridster
    GridsterModule
  ],
  declarations: [
    InternalComponent,
    WorkspacePanelComponent,
    WorkspaceComponent,
    WorkspaceContentComponent,
    AnalyticsComponent,
    // ENTRY COMPONENTS
    InstrumentMonitorComponent,
    BasicTermsComponent,
    InstrumentComponent,
    DocumentsComponent,
    WorkspacePanelDialogComponent,
    InstrumentAuthorizationComponent,
    SettingsComponent,
    ExternalInstrumentViewComponent,
    WorkspaceReorderDialogComponent,
    ImageLogoComponent,
  ]
})
export class InternalModule { }
