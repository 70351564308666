import { v4 } from 'uuid';
import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation,
         ChangeDetectionStrategy, OnChanges, ElementRef, Optional, ChangeDetectorRef } from '@angular/core';
import { LvConvertibleBondTermsPresenter } from '../../lv-convertible-bond-terms.presenter';
import { LvLookupEnum } from '@lv-core-ui/util';
import { ConversionCustomData, SetupStatus, CurrencyType, PepsConversionBeforeMaturityTypeDescription,
         ConvertibleBondTermsEvent } from '@lv-convertible-bond/models';
import { PepsConversion } from '@lv-convertible-bond/models/convertible-bond-terms/PEPSConversion';
import { LvExcelService } from '@lv-excel/services';

@Component({
  selector: 'lv-peps-conversion',
  templateUrl: './lv-peps-conversion.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvPepsConversionComponent implements OnInit, OnChanges {

  @Input() model: PepsConversion;
  @Input() conversionCustomData: ConversionCustomData;
  @Output() didPepsConversionChange: EventEmitter<PepsConversion>;

  get isNewIssue(): boolean {
    return this.conversionCustomData && this.conversionCustomData.status === SetupStatus.NewIssue;
  }

  private _endDate: Date;
  private _startDate: Date;

  get endDate(): Date {
    return this._endDate;
  }

  set endDate(value: Date) {
    this._endDate = value;

    if (this.model && this.endDateEnabled) {
      this.model.endDate = value;
    }
  }

  get startDate(): Date {
    return this._startDate;
  }

  set startDate(value: Date) {
    this._startDate = value;

    if (this.model && this.startDateEnabled) {
      this.model.startDate = value;
    }
  }

  endDateEnabled: boolean;
  startDateEnabled: boolean;

  conversionRebateCCYLookup: LvLookupEnum;
  conversionBeforeMaturityLookup: LvLookupEnum;

  paralelPepsId: string;
  lowerStrikeLabel: string;
  lowerStrikeSufix: string;
  dpCheckBoxId: string;
  dpDatePickerId: string;

  formatSix = '#,###.######';
  decimalsSix = '6';

  formatTwo = '#,###.##';
  decimalsTwo = '2';

  constructor(
    private _presenter: LvConvertibleBondTermsPresenter,
    private _changeDetectorRef: ChangeDetectorRef,
    @Optional() private _excelService: LvExcelService) {
    this.conversionRebateCCYLookup = new LvLookupEnum(CurrencyType);
    this.conversionBeforeMaturityLookup = new LvLookupEnum(PepsConversionBeforeMaturityTypeDescription);
    this.didPepsConversionChange = new EventEmitter<PepsConversion>();

    this.paralelPepsId = v4();
    this.dpCheckBoxId = 'startDateCheckBoxId';
    this.dpDatePickerId = 'startDatePickerId';
  }

  ngOnInit() {
    this.calculateRatioFields();
    this.setDateFields();
    this.setStrikeField();
  }

  ngOnChanges() {
    if (this._excelService?.isInitialized()) {
      this.model.maxRatio = !this.model.maxRatio && this.model.lowerStrike > 0 ?
        this.conversionCustomData.nominal / this.model.lowerStrike : this.model.maxRatio;

      this.model.customMinRatio = !this.model.customMinRatio && this.model.higherStrike > 0 ?
        this.conversionCustomData.nominal / this.model.higherStrike : this.model.customMinRatio;

      this.model.lowerStrike = !this.model.lowerStrike && this.model.maxRatio > 0 ?
        this.conversionCustomData.nominal / this.model.maxRatio : this.model.lowerStrike;

      this.model.higherStrike = !this.model.higherStrike && this.model.customMinRatio > 0 ?
        this.conversionCustomData.nominal / this.model.customMinRatio : this.model.higherStrike;
    }

    this.calculateRatioFields();
    this.setDateFields();
    this.setStrikeField();

    this._changeDetectorRef.detectChanges();
  }

  onPepsConversionStartDateChange() {
    this.model.startDate = this.startDate;
    this.onPepsConversionChange();
  }

  onPepsConversionEndDateChange() {
    this.model.endDate = this.endDate;
    this.onPepsConversionChange();
  }

  onPepsConversionChange() {
    this.didPepsConversionChange.next(this.model);
  }

  onStrikeFieldsChange() {
    this.calculateRatioFields();
    this.onPepsConversionChange();

    this._presenter.publishConvertibleBondTermsEvent(ConvertibleBondTermsEvent.HigherOrLowerStrikeUpdated);
  }

  onRatioFieldsChange() {
    this.calculateStrikeFields();
    this.onPepsConversionChange();
  }

  setStrikeField() {
    this.lowerStrikeLabel = this.isNewIssue ? 'Lower Strike Premium' : 'Lower Strike';
    this.lowerStrikeSufix = this.isNewIssue && this.conversionCustomData ? '%' : this.conversionCustomData.currency;
    this.model.lowerStrike = this.isNewIssue ? 0 : this.model.lowerStrike;
  }

  setDateFields() {
    if (!this.model.endDate) {
      this._endDate = this.conversionCustomData.endDate;
      this.endDateEnabled = false;
    } else {
      this._endDate = this.model.endDate;
      this.endDateEnabled = true;
    }

    if (!this.model.startDate) {
      this._startDate = this.conversionCustomData.startDate;
      this.startDateEnabled = false;
    } else {
      this._startDate = this.model.startDate;
      this.startDateEnabled = true;
    }
  }

  calculateRatioFields() {
    this.model.maxRatio = this.model.lowerStrike > 0 ? this.conversionCustomData.nominal / this.model.lowerStrike : 0;
    this.model.customMinRatio = this.model.higherStrike > 0 ? this.conversionCustomData.nominal / this.model.higherStrike : 0;
  }

  calculateStrikeFields() {
    this.model.lowerStrike = this.model.maxRatio > 0 ? this.conversionCustomData.nominal / this.model.maxRatio : 0;
    this.model.higherStrike = this.model.customMinRatio > 0 ? this.conversionCustomData.nominal / this.model.customMinRatio : 0;
  }

  conversionStartDateCheckBoxChanged(value: boolean): void {
    this.startDateEnabled = value;

    if (this.model) {
      this.model.startDate = value ? this._startDate : null;
      this.onPepsConversionChange();
    }
  }

  conversionEndDateCheckBoxChanged(value: boolean): void {
    this.endDateEnabled = value;

    if (this.model) {
      this.model.endDate = value ? this._endDate : null;
      this.onPepsConversionChange();
    }
  }
}
