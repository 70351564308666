import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Output,
  EventEmitter, Input, OnChanges, ViewRef } from '@angular/core';

import { Permissions } from './../../documents.permissions';
import { DocumentsService } from '../../documents.service';
import { IDocumentMetadata } from '../../models/document-metadata';
import { LvErrorService, LvPermissionService } from '@lv-core-ui/services';

/**
 * Document component
 */
@Component({
  selector: 'lv-documents',
  templateUrl: './lv-documents.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvDocumentsComponent implements OnChanges {

  @Input() lwsIdentifier: string;
  @Input() isin: string;

  @Output() didSelect: EventEmitter<IDocumentMetadata>;

  get canContributeDocument(): boolean {
    return this.permissionService.hasPermission(Permissions.MODULE, Permissions.CONTRIBUTE);
  }

  get canViewDocument(): boolean {
    return this.permissionService.hasPermission(Permissions.MODULE, Permissions.VIEW);
  }

  documentsMetadata: IDocumentMetadata[];
  isLoading: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private documentsService: DocumentsService,
    private errorService: LvErrorService,
    private permissionService: LvPermissionService
  ) {
    this.isLoading = true;
    this.documentsMetadata = [];
    this.didSelect = new EventEmitter<IDocumentMetadata>();
  }

  /**
   * Occurs on changes.
   */
  ngOnChanges() {
    this.refresh();
  }

  /**
   * Refresh document component.
   */
  async refresh() {
    try {
      if (this.permissionService.hasPermission(Permissions.MODULE, Permissions.LIST_DOCUMENTS)) {
        this.isLoading = true;
        if (!(this.changeDetectorRef as ViewRef).destroyed) {
        this.changeDetectorRef.detectChanges();
        }

        this.documentsMetadata = [];

        if (this.lwsIdentifier) {
          const docsMetadata = await this.documentsService.metadataService.getDocumentsMetadata(this.lwsIdentifier);
          this.documentsMetadata = docsMetadata;
        }
      }
    }
    catch (error) {
      this.documentsMetadata = [];
      this.errorService.handleError(error);
    }
    finally {
      this.isLoading = false;
      if (!(this.changeDetectorRef as ViewRef).destroyed) {
      this.changeDetectorRef.detectChanges();
      }
    }
  }

  /**
   * Occurs on select document.
   * @param document IDocumentMetadata object.
   */
  onSelectDocument(document: IDocumentMetadata) {
    if (this.canViewDocument) {
      this.didSelect.emit(document);
    }
  }

  /**
   * Occurs on contribute document.
   */
  onContributeDocument() {
    let msg = 'Document contribution';

    if (this.isin) {
      msg = `${msg} for "${this.isin}"`;
    }

    window.location.href = `mailto:support@leversys.com?subject=${msg}`;
  }
}
