<ng-container *ngIf="hasData">
  <lv-scenario-one-dim-grid *ngIf="isOneDimGrid"
    [report]="report">
  </lv-scenario-one-dim-grid>
  
  <lv-scenario-one-dim-price-talk-grid *ngIf="isOneDimPriceTalkGrid"
    [report]="report">
  </lv-scenario-one-dim-price-talk-grid>

  <lv-scenario-two-dim-grid *ngIf="isTwoDimGrid"
    [report]="report">
  </lv-scenario-two-dim-grid>

  <lv-scenario-two-dim-price-talk-grid *ngIf="isTwoDimPriceTalkGrid"
    [report]="report">
  </lv-scenario-two-dim-price-talk-grid>
</ng-container>