import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, Input,
  EventEmitter, Output } from '@angular/core';

import { Subscription, timer, Observable } from 'rxjs';

import { LvNotificationManagerService } from '../../../services/lv-notification-manager/lv-notification-manager.service';
import { LvPublisher, ILvPublisherState } from '../../../services/lv-notification-manager/lv-notifier.publisher';

export type LvPublisherPanelOptonsPosition = 'top' | 'bottom';

@Component({
  selector: 'lv-publisher-panel',
  templateUrl: './lv-publisher-panel.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvPublisherPanelComponent extends LvPublisher implements OnInit {

  @Input() headerVisible: boolean;
  @Input() floatingHeader: boolean;
  @Input() state?: ILvPublisherState;
  @Input() optionsPosition: LvPublisherPanelOptonsPosition;

  @Output() didReceiveMessage: EventEmitter<any>;
  @Output() didStateChange: EventEmitter<ILvPublisherState>;

  private _timerSubscription?: Subscription;
  private _timer: Observable<number>;

  get backgroundLinkColor(): string {
    return this.color;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    lvNotificationManagerService: LvNotificationManagerService
  ) {
    super(lvNotificationManagerService);

    this.headerVisible = true;
    this.floatingHeader = false;
    this.optionsPosition = null;

    this.didReceiveMessage = new EventEmitter<any>();
    this.didStateChange = new EventEmitter<ILvPublisherState>();

    this.state = {
      id: null,
      type: 'publisher',
      color: null,
      linkedSubscribers: null
    };

    this._timer = timer(5000, 5000);

    this.subscriberAccepted.subscribe(event => {
      if (this._timerSubscription) {
        this.onLinkOptionClick();
      }

      this.emitState();
      this.changeDetectorRef.detectChanges();
    });

    this.subsciberCanceled.subscribe(event => {
      this.emitState();
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnInit() {
    this.type = this.state.type || this.type;
    this.color = this.state.color || null;

    this.linkedSubscribers = this.state.linkedSubscribers || this.linkedSubscribers;

    this.init(this.state ? this.state.id : null);

    this.changeDetectorRef.detectChanges();
  }

  emitState() {
    this.didStateChange.emit({
      id: this.id,
      type: 'publisher',
      color: this.color,
      linkedSubscribers: this.linkedSubscribers
    });
  }

  addSubscriber(subscriberId: string) {
    this.linkSubscriber(subscriberId);
    this.emitState();
    this.changeDetectorRef.detectChanges();
  }

  createTimer() {
    if (this._timerSubscription) {
      this._timerSubscription.unsubscribe();
      this._timerSubscription = null;
    }

    this._timerSubscription = this._timer.subscribe(() => {
      this.sendCancelReadyToLink();

      this._timerSubscription.unsubscribe();
      this._timerSubscription = null;
    });
  }

  onLinkOptionClick() {
    this.createTimer();

    this.sendReadyToLink();
    this.changeDetectorRef.detectChanges();
  }
}
