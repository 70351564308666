import {
  Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges,
  ChangeDetectorRef,
  TemplateRef
} from '@angular/core';

import { LvUtil } from '../../util/util';

export interface IItemMap {
  [valueField: string]: {
    order: number;
    item: any
  };
}

export interface IItemsMap {
  availableItems: IItemMap;
  selectedItems: IItemMap;
}

@Component({
  selector: 'lv-multi-select',
  templateUrl: './lv-multi-select.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvMultiSelectComponent implements OnInit, OnChanges {

  get availableItems(): any[] {
    return this._availableItems;
  }

  get isAvailableItemsEmpty(): boolean {
    return this.availableItems.length === 0;
  }

  get isSelectedItemsEmpty(): boolean {
    return this.selectedItems.length === 0;
  }

  @Input()
  set availableItems(items: any[]) {
    this._availableItems = items.map(a => a);
  }
  private _availableItems: any[];

  get selectedItems(): any[] {

    if (this.searchQuery) {
      return this._selectedItems.filter((item) => {
        return (item[this.displayField] || '').toLowerCase().lastIndexOf(this.searchQuery.toLowerCase()) !== -1;
      });
    }

    return this._selectedItems;
  }

  get originalSelectedItems(): any[] {
    return this._selectedItems;
  }

  @Input()
  set selectedItems(items: any[]) {
    this._selectedItems = items.map(a => a);
  }

  private _selectedItems: any[];

  @Input() isSortable: boolean;

  @Input() valueField: string;
  @Input() displayField: string;

  @Input() searchPlaceholder: string;
  @Input() listTitle: string;
  @Input() selectedListTitle: string;

  @Input() showTooltips: boolean;
  @Input() tooltips: TemplateRef<any>;

  searchQuery: string;
  dragStarted: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.availableItems = [];
    this._selectedItems = [];

    this.isSortable = true;
    this.showTooltips = false;
    this.valueField = 'id';
    this.displayField = 'text';

    this.searchPlaceholder = 'Search';
    this.listTitle = 'Available Items';
    this.selectedListTitle = 'Selected Items';

    this.searchQuery = '';
    this.dragStarted = false;
  }

  ngOnInit() {
    this.sortItems();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.availableItems.previousValue !== changes.availableItems.currentValue
      || changes.selectedItems.previousValue !== changes.selectedItems.currentValue) {
      this.sortItems();
    }
  }

  onMove(item: any, toSelected = true) {
    if (!this.dragStarted) {
      if (toSelected) {
        this.selectedItems = this.originalSelectedItems.concat(item);
        this.availableItems = this.availableItems.filter(a => a[this.valueField] !== item[this.valueField]);
      }
      else {
        this.availableItems = this.availableItems.concat(item);
        this.selectedItems = this.originalSelectedItems.filter(a => a[this.valueField] !== item[this.valueField]);
      }
      this.sortItems();
    }
  }

  onMoveAllToSelected() {
    if (this.isAvailableItemsEmpty) {
      return;
    }

    this.selectedItems = this.originalSelectedItems.concat(this.availableItems);
    this.availableItems = [];

    this.sortItems();
  }

  onMoveAllToAvailable() {
    if (this.isSelectedItemsEmpty) {
      return;
    }
    this.availableItems = this.availableItems.concat(this.selectedItems);
    this.selectedItems = [];
    this.sortItems();
  }

  getItemsMap(): IItemsMap {
    const map: IItemsMap = {
      availableItems: null,
      selectedItems: null
    };

    map.availableItems = this.availableItems.reduce((acc: IItemMap, current: any, index: number) => {
      acc[current[this.valueField]] = {
        order: index,
        item: current
      };

      return acc;
    }, {});

    map.selectedItems = this.originalSelectedItems.reduce((acc: IItemMap, current: any, index: number) => {
      acc[current[this.valueField]] = {
        order: index,
        item: current
      };

      return acc;
    }, {});

    return map;
  }

  onDragStart() {
    this.dragStarted = true;
    this.changeDetectorRef.detectChanges();
  }

  onDragEnd() {
    setTimeout(() => {
      this.dragStarted = false;
    });
    this.changeDetectorRef.detectChanges();
  }

  private sortItems() {
    this.availableItems.sort(LvUtil.sortBy(this.displayField, false));

    if (!this.isSortable) {
      this.selectedItems.sort(LvUtil.sortBy(this.displayField, false));
    }

    this.changeDetectorRef.detectChanges();
  }
}

