<lv-flex-box class="lv-reset-conversion">
  <lv-flex-box direction="row">
    <lv-flex-box class="lv-reset-first-col">
      <lv-flex-box direction="row" data-cy="DM-861">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="conversionPriceCCYSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Conversion Price CCY
          </span>
          <lv-xl-label [field]="'RES_CONV_PRICE_CCY'"></lv-xl-label>
        </div>
        <div class="lv-input-field ">
          <kendo-dropdownlist
            name="reset-conversion-price"
            [data]="conversionPriceCCYLookup.items"
            valueField="id"
            textField="text"
            [valuePrimitive]="true"
            [(ngModel)]="model.conversionPriceCurrencyType"
            (ngModelChange)="onResetChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-857">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="initialConversionPriceSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Initial Conversion Price
          </span>
          <lv-xl-label [field]="'RES_PRICE_INTL'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            [format]="formatSix"
            [decimals]="decimalsSix"
            [disabled]="isInitalCPDisabled"
            name="reset-initial-conversion-price"
            [(ngModel)]="model.initialConversionPrice"
            (didBlur)="onResetChange()"
            (didKeyDownEnter)="onResetChange()">
          </lv-numeric-text-box>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-858">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="resetCurrencySourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Reset Currency
          </span>
          <lv-xl-label [field]="'RES_CCY'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <kendo-dropdownlist
            name="reset-currency"
            [data]="conversionPriceCCYLookup.items"
            valueField="id"
            textField="text"
            [valuePrimitive]="true"
            [(ngModel)]="model.resetCurrencyType"
            (ngModelChange)="onResetChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-859">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="numberAveragingDaysTooltip"
          filter="span:not(lv-xl-label span)">
          <span *ngIf="!isOpenedFromExcel">
            Number of Averaging Days
          </span>
          <span *ngIf="isOpenedFromExcel">
            No. of Averaging Days
          </span>
          <lv-xl-label [field]="'RES_NUM_AVG_DAYS'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            [format]="formatZero"
            [decimals]="decimalsZero"
            name="number-averaging-days"
            [(ngModel)]="model.resetAveraging.numberOfAveragingDays"
            (didBlur)="onResetAveragingChange()"
            (didKeyDownEnter)="onResetAveragingChange()">
          </lv-numeric-text-box>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-860">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="resetFixingLagTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Reset Fixing Lag
          </span>
          <lv-xl-label [field]="'RES_FIX_LAG'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            [format]="formatZero"
            [decimals]="decimalsZero"
            name="reset-fixing-lag"
            [(ngModel)]="model.resetAveraging.startLag"
            (didBlur)="onResetAveragingChange()"
            (didKeyDownEnter)="onResetAveragingChange()">
          </lv-numeric-text-box>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-867">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="resetableDuringPeriodSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Resettable During Period
          </span>
          <lv-xl-label [field]="'RES_CONV_DRNG_PERIOD'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="resetableDuringPeriod"
            name="reset-resetable-during-period"
            [(ngModel)]="model.resettableDuringPeriod"
            (ngModelChange)="onResetChange()">
          <label class="k-checkbox-label"
            [attr.for]="resetableDuringPeriod"
            lvId="resetableDuringLabel">
          </label>
        </div>
      </lv-flex-box>
    </lv-flex-box>
    <lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-862">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="floorExpressedSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span *ngIf="!isOpenedFromExcel">
            Floor Expressed Relative To
          </span>
          <span *ngIf="isOpenedFromExcel">
            Floor Expr. Relative To
          </span>
          <lv-xl-label [field]="'RES_CONV_FLOOR_AS'"></lv-xl-label>
        </div>
        <div class="lv-input-field lv-input-field--auto">
          <kendo-dropdownlist
            name="reset-floor-expressed-as"
            [data]="floorExpressedRelativeToLookup.items"
            valueField="id"
            textField="text"
            [valuePrimitive]="true"
            [popupSettings]="{ width: 211 }"
            [(ngModel)]="model.floorExpressedRelativeTo"
            (ngModelChange)="onFlorExpresedRelativeToChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-863">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="capExpressedSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span *ngIf="!isOpenedFromExcel">
            Cap Expressed Relative To
          </span>
          <span *ngIf="isOpenedFromExcel">
            Cap Expr. Relative To
          </span>
          <lv-xl-label [field]="'RES_CONV_CAP_AS'"></lv-xl-label>
        </div>
        <div class="lv-input-field lv-input-field--auto">
          <kendo-dropdownlist
            name="reset-cap-expressed-as"
            [data]="capExpressedRelativeToLookup.items"
            valueField="id"
            textField="text"
            [valuePrimitive]="true"
            [popupSettings]="{ width: 211 }"
            [(ngModel)]="model.capExpressedRelativeTo"
            (ngModelChange)="onResetChange()">
          </kendo-dropdownlist>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-864">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="globalFloorSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Global Floor
          </span>
          <lv-xl-label [field]="'RES_CONV_GLBL_FLOOR'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            name="reset-global-floor"
            [format]="formatThree"
            [decimals]="decimalsThree"
            [(ngModel)]="model.globalFloor"
            (didBlur)="onResetChange()"
            (didKeyDownEnter)="onResetChange()">
          </lv-numeric-text-box>
          <span class="lv-sufix-label">{{currentCCY}}</span>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-865">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="globalCapSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Global Cap
          </span>
          <lv-xl-label [field]="'RES_CONV_GLBL_CAP'"></lv-xl-label>
        </div>
        <div class="lv-input-field">
          <lv-numeric-text-box
            name="reset-global-cap"
            [format]="formatThree"
            [decimals]="decimalsThree"
            [(ngModel)]="model.globalCap"
            (didBlur)="onResetChange()"
            (didKeyDownEnter)="onResetChange()">
          </lv-numeric-text-box>
          <span class="lv-sufix-label">%</span>
        </div>
      </lv-flex-box>
      <lv-flex-box direction="row" data-cy="DM-866">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="optionalResetSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Optional Reset
          </span>
          <lv-xl-label [field]="'RES_CONV_OPTIONAL'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox">
          <input class="k-checkbox"
            type="checkbox"
            [attr.id]="issuerOptionalResetId"
            name="reset-issuer-optional-reset"
            [(ngModel)]="model.optionalReset"
            (ngModelChange)="onResetChange()">
          <label class="k-checkbox-label"
            [attr.for]="issuerOptionalResetId">
          </label>
        </div>
      </lv-flex-box>
    </lv-flex-box>
  </lv-flex-box>
  <lv-flex-box class="lv-reset-schedule">
    <lv-flex-box direction="row">
      <div showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="resetScheduleSourceTooltip"
        filter=".lv-label"
        class="lv-label lv-label-field lv-label--title">
        <span>RESET SCHEDULE</span>
        <lv-xl-label [field]="'RES_SCHED_RANGE'"></lv-xl-label>
      </div>
    </lv-flex-box>
    <lv-flex-box>
      <lv-advanced-grid
        data-cy="DM-901"
        lvId="lvTermsResetConversionSchedule"
        [columns]="columns"
        [records]="scheduleItems"
        [createFormGroup]="createFormGroup"
        [parseFn]="parseFn"
        sortBy="startDate"
        [showXlLabel]="hasScheduleInExcelOverride"
        [excelFieldAlias]="excelFieldAlias"
        [isFieldFromExcelEnabled]="isFieldFromExcelEnabled"
        class="lv-reset-schedule-grid">
      </lv-advanced-grid>
    </lv-flex-box>
  </lv-flex-box>
</lv-flex-box>

<ng-template #resetScheduleSourceTooltip>
  <lv-tooltip dm="dM-901"></lv-tooltip>
</ng-template>
<ng-template #conversionPriceCCYSourceTooltip>
  <lv-tooltip dm="dM-861"></lv-tooltip>
</ng-template>
<ng-template #initialConversionPriceSourceTooltip>
  <lv-tooltip dm="dM-857"></lv-tooltip>
</ng-template>
<ng-template #resetCurrencySourceTooltip>
  <lv-tooltip dm="dM-858"></lv-tooltip>
</ng-template>
<ng-template #optionalResetSourceTooltip>
  <lv-tooltip dm="dM-866"></lv-tooltip>
</ng-template>
<ng-template #resetableDuringPeriodSourceTooltip>
  <lv-tooltip dm="dM-867"></lv-tooltip>
</ng-template>
<ng-template #floorExpressedSourceTooltip>
  <lv-tooltip dm="dM-862"></lv-tooltip>
</ng-template>
<ng-template #capExpressedSourceTooltip>
  <lv-tooltip dm="dM-863"></lv-tooltip>
</ng-template>
<ng-template #globalFloorSourceTooltip>
  <lv-tooltip dm="dM-864"></lv-tooltip>
</ng-template>
<ng-template #globalCapSourceTooltip>
  <lv-tooltip dm="dM-865"></lv-tooltip>
</ng-template>
<ng-template #numberAveragingDaysTooltip>
  <lv-tooltip dm="dM-859"></lv-tooltip>
</ng-template>
<ng-template #resetFixingLagTooltip>
  <lv-tooltip dm="dM-860"></lv-tooltip>
</ng-template>
