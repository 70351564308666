<div class="lv-documents-sliding-panel">
  <div class="lv-documents-sliding-panel-documents">
    <lv-documents
      [lwsIdentifier]="lwsIdentifier"
      [isin]="isin"
      (didSelect)="onSelectDocument($event)">
    </lv-documents>
  </div>
  <div *ngIf="selectedDocument"
    class="lv-documents-sliding-panel-document"
    [@slide]>    
    <lv-document
      [documentId]="selectedDocument.id"
      [instrumentName]="instrumentName"
      (doClose)="onBack()">
    </lv-document>    
  </div>
</div>