<lv-advanced-grid
  [columns]="columns"
  [records]="rows"
  [createFormGroup]="createFormGroup"
  [scrollRows]="8"
  [skipRecordOnDeleteFn]="skipRecordOnDeleteFn"
  [parseFn]="parseFn"
  sortBy="date"
  [contextMenuItems]="contextMenuItems"
  [width]="880"
  [height]="242"
  [showXlLabel]="hasScheduleInExcelOverride"
  [excelFieldAlias]="excelFieldAlias"
  [isFieldFromExcelEnabled]="isFieldFromExcelEnabled"
  lvId="lvMakeWholeRatchetMatrixGrid"
  class="lv-ratchet-matrix-grid">
</lv-advanced-grid>