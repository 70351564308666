import { NgModule, ModuleWithProviders, Optional, SkipSelf, Type } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LvGoldenLayoutComponent } from './components/lv-golden-layout/lv-golden-layout.component';
import { LvGoldenLayoutService } from './services/lv-golden-layout/lv-golden-layout.service';
import { LVGL_COMPONENT_MAP, ILvGlComponentMap } from './models/token';
import { LvGoldenLayoutStorage } from './util/lv-golden-layout-storage';
import { LvGoldenLayoutStorageService } from './services/lv-golden-layout-storage/lv-golden-layout-storage.service';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvLoggerService } from '@lv-core-ui/services';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    LvGoldenLayoutComponent
  ],
  exports: [
    LvGoldenLayoutComponent
  ],
  providers: [
    LvGoldenLayoutService
  ]
})
export class LeversysGoldenLayoutModule {

  constructor(
    private _logger: LvLoggerService,
    @Optional() @SkipSelf() parentModule: LeversysGoldenLayoutModule
  ) {
    if (parentModule) {
      _logger.logError('LeversysGoldenLayoutModule is already loaded. It should only be imported in your application\'s main module.');
      throw new Error(LvDataMaster.getError('dM-1917'));
    }
  }

  public static forRoot(config: ILvGlComponentMap, storageService?: Type<any>): ModuleWithProviders<LeversysGoldenLayoutModule> {
    return {
      ngModule: LeversysGoldenLayoutModule,
      providers: [{
        provide: LVGL_COMPONENT_MAP,
        useValue: config
      }, {
        provide: LvGoldenLayoutStorage,
        useClass: storageService || LvGoldenLayoutStorageService
      }]
    };
  }
}
