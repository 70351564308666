import { Injectable } from '@angular/core';
import { ILvHubClient, LvHub } from '@lv-core-ui/util';

import { Observable, Subject } from 'rxjs';

import { InstrumentInfoCreatedEvent } from './events/instrument-info-created.event';

@Injectable()
export class InstrumentMonitorHubService implements ILvHubClient {

  private _instrumentInfoCreatedEvent: Subject<InstrumentInfoCreatedEvent>;

  constructor() {
    this._instrumentInfoCreatedEvent = new Subject<InstrumentInfoCreatedEvent>();
  }

  get privateInstrumentCreatedEvent(): Observable<InstrumentInfoCreatedEvent> {
    return this._instrumentInfoCreatedEvent;
  }

  attachEvents(hub: LvHub): void {
    hub.subscribeOn('instrumentMonitorUpdatedEvent', this._instrumentInfoCreatedEvent);
  }
}
