import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DayCount, DayCountDescription, FrequencyDescription, IBaseTermsSettings, IPutSectionSettings, NoticePeriod,
  PutValueTypeDescription } from '@lv-convertible-bond/models';
import { LvLookupEnum } from '@lv-core-ui/util';
import { v4 } from 'uuid';

@Component({
  selector: 'lv-put-section-settings',
  templateUrl: './lv-put-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvPutSectionSettingsComponent implements OnInit {

  @Input() settings: IBaseTermsSettings<IPutSectionSettings>;
  @Input() overrideDefaults: boolean;

  putValueTypeLookup: LvLookupEnum;
  putYieldFrequencyLookup: LvLookupEnum;
  yieldDayCountLookup: LvLookupEnum;
  noticePeriodLookup: LvLookupEnum;

  keepAccruedCheckBoxId: string;
  forfeitCouponCheckBoxId: string;

  numberOfDecimals = '0';
  numberFormat = 'n0';

  constructor() {
    this.putValueTypeLookup = new LvLookupEnum(PutValueTypeDescription);
    this.putYieldFrequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.yieldDayCountLookup = new LvLookupEnum(DayCountDescription);
    this.noticePeriodLookup = new LvLookupEnum(NoticePeriod);

    this.keepAccruedCheckBoxId = v4();
    this.forfeitCouponCheckBoxId = v4();
    this.overrideDefaults = true;
  }

  ngOnInit() {
  }

  getPutTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

}
