<external>
  <ng-container content>
    <div class="not-supported-browser">
      <div class="text">You're using a web browser that we don't support.</div>
      <div class="text">Try one of these to have a better experience on our platform.</div>
      <div class="browsers">
        <a class="browser" href="https://www.google.com/chrome/">
          <img src="app/leversys-core-ui/assets/img/browsers/chrome-logo-64.png" />
          <div class="browser-name">Google Chrome 53+</div>
        </a>
  
        <a class="browser" href="https://www.mozilla.org/en-US/firefox/">
          <img src="app/leversys-core-ui/assets/img/browsers/firefox-logo-64.png" />
          <div class="browser-name">Mozilla Firefox 48+</div>
        </a>
  
        <a class="browser" href="https://www.microsoft.com/en-us/windows/microsoft-edge">
          <img src="app/leversys-core-ui/assets/img/browsers/microsoft-edge-logo-64.png" />
          <div class="browser-name">Microsoft Edge 79+</div>
        </a>
      </div>
    </div>
  </ng-container>
</external>