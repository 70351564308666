import * as _ from 'lodash';

// tslint:disable-next-line:max-line-length
import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, OnInit,
  OnDestroy, AfterViewInit, ViewRef, Input, Optional } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { SelectEvent, TabStripComponent } from '@progress/kendo-angular-layout';

import { LvSubscriberPanelComponent, LvSidePanelComponent } from '@lv-core-ui/components';
import { IInstrumentSelectedEvent, LvDataMaster, LvError, LvErrorType } from '@lv-core-ui/models';
import { LvErrorService, LvLoggerService } from '@lv-core-ui/services';
import { LvMath, LvUtil } from '@lv-core-ui/util';
import { LvSensitivityAnalysisPanelComponent, ILvSensitivityAnalysisPanelState } from '@lv-analytics/components';
import { AnalyticsHubService } from '@lv-analytics/hubs';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { AnalyticsCommands, IPricing } from '@lv-analytics/models';
import { PricingService, ModelCustomizationService, MarketDataService } from '@lv-analytics/services';
import { ApplicationSettingsService } from '@lv-application-settings/application-settings.service';
import { WidgetStateManagerService } from '@lv-application-settings/services';
import { RouteCacheService } from '@lv-cache/route-cache';
import { NotificationPanelComponent } from '@lv-common/views/notification-panel.component';
import { QuickAndFullTermsDocument } from '@lv-convertible-bond/models';
import { ConvertibleBondTermsEvent } from '@lv-convertible-bond/models/convertible-bond-terms/custom-enum';
import { IInstrumentLookup } from '@lv-instrument-monitor/models';
import { LvCustomInstrumentComponent, PrivateInstrumentEvent } from '@lv-custom-instruments/components';
import { CustomInstrumentHubService } from '@lv-custom-instruments/hubs/custom-instrument-hub.service';
import { CustomInstrumentsService } from '@lv-custom-instruments/services/custom-instruments.service';
import { LvTermsSummaryComponent } from '@lv-reference-data/components';
import { ReferenceDataHubService } from '@lv-reference-data/hubs';
import { DefaultWidgetType } from '@lv-shared/models';
import { LvSharedDefaultWidgetStateService } from '@lv-shared/services';
import { AppComponent, ApplicationMode } from 'src/app/app.component';
import { IInstrumentComponentState, IInstrumentWidgetVisibility, IInstrumentWidgetState } from '.';
import { AnalyticsComponent } from './analytics/analytics.component';
import { DefaultComponentStateService } from './instrument.state';
import { ResizeHandlerService } from 'src/app/leversys-core-ui/services/resize-handler/resize-handler.service';
import { InstrumentUtil } from './instrument.util';
import { EnvironmentSettingsService } from 'src/app/modules/analytics/services/environment-settings/environment-settings.service';
import { CompanyAndUserSettingsService } from 'src/app/modules/analytics/services/company-settings/company-settings.service';

import { InstrumentView } from './instrument.view';
import { LvPermissionService } from '@lv-core-ui/services';
import { AuthorizationService } from '@lv-core-ui/services/authorization/authorization.service';
import { IResource } from '@lv-core-ui/models/authorization/resource';
import { IFullTermsDataEvent } from '@lv-custom-instruments/models/full-terms-data-event';
import { LvExcelService } from '@lv-excel/services';
import { AccessScopeType } from '@lv-core-ui/models/enum/access-scope-type';
import { RestrictedResourceType } from '@lv-core-ui/models/authorization/restricted-resource-type-enum';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'instrument',
  templateUrl: './instrument.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    PricingService,
    LvAnalyticsPresenter,
    WidgetStateManagerService,
    ModelCustomizationService,
    MarketDataService,
    EnvironmentSettingsService
  ]
})
// tslint:disable-next-line:max-line-length
export class InstrumentComponent extends NotificationPanelComponent<IInstrumentComponentState> implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(LvSubscriberPanelComponent, { static: true }) notificationPanel: LvSubscriberPanelComponent;
  @ViewChild(LvSidePanelComponent) settingsPanel: LvSidePanelComponent;

  @ViewChild(LvCustomInstrumentComponent) privateInstrumentComponent: LvCustomInstrumentComponent;
  @ViewChild(LvSensitivityAnalysisPanelComponent) sensitivityPanelComponent: LvSensitivityAnalysisPanelComponent;
  @ViewChild(LvTermsSummaryComponent) termsSummaryComponent: LvTermsSummaryComponent;
  @ViewChild(AnalyticsComponent) analyticsComponent: AnalyticsComponent;
  @ViewChild('tabStrip') tabStrip: TabStripComponent;
  @Input() isOpenedFromExcel: boolean;

  get instrumentReloadVisible(): boolean {
    return this._hubEventOccured;
  }

  get hubReloadEventMessage(): string {
    return this._hubEventMessage;
  }

  get showDocuments(): boolean {
    return !this.isDraftFromExcel && (this.showAnalytisWidgets && this._permissionService.hasPermission('DOCUMENTS', 'LIST_DOCUMENTS'));
  }

  get editWidgetButtonText(): string {
    return this.isOpenedFromExcel ? 'Edit Excel View' : 'Edit Custom View';
  }

  get saveWidgetButtonText(): string {
    return this.isOpenedFromExcel ? 'Save as Excel View' : 'Save as Default View';
  }

  get isPermissionChecked(): boolean {
    return this._isPermissionChecked;
  }

  get shouldShowEditAccessScopeLink(): boolean {
    return this.state.isPrivateInstrument && this._presenter.cHelper.convertible &&
           (this._presenter.cHelper.accessScope === AccessScopeType.User && this._presenter.cHelper.convertible.isOwner);
  }

  get isDraftFromExcel(): boolean {
    return this.isOpenedFromExcel && !!this._excelService?.isIdentifierDraft();
  }

  initWidgetsOnTabSelect: boolean;
  keepTabContent: boolean;

  isLoading: boolean;

  view: InstrumentView;

  // Layout Configuration
  model: {
    editCustomView: boolean;
  };

  notAuthorized: boolean;
  init: boolean;
  isPrivateInstrumentInit: boolean;
  instrumentUtil: InstrumentUtil;

  hasFullTermsAccess: boolean;
  showAnalytisWidgets: boolean;
  isEditingExcelView: boolean;
  draftChanged: boolean;

  @Input() defaultStateOnly: boolean;

  private _hubEventOccured: boolean;
  private _hubEventMessage: string;
  private _resizeHandlerService: ResizeHandlerService;
  private _subscriptions: Subscription[];
  private _isPermissionChecked: boolean;


  /*  Additional identifier field instead of state.instrumentLwsIdentifier
   since it is changed by custom instrument event and then propagated back to custom
     instrument via Input so this is to break circular depenency */
  public _customInstrumentIdentifier: string;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    applicationSettingsService: ApplicationSettingsService,
    private _router: Router,
    private _logger: LvLoggerService,
    private _errorService: LvErrorService,
    private _routeCacheService: RouteCacheService,
    private _pricingService: PricingService,
    private _analyticsHubService: AnalyticsHubService,
    private _referenceDataHubService: ReferenceDataHubService,
    public _presenter: LvAnalyticsPresenter,
    private _defaultWidgetStateService: LvSharedDefaultWidgetStateService,
    private _instrumentStateService: DefaultComponentStateService,
    private _widgetStateManagerService: WidgetStateManagerService,
    private _privateInstrumentService: CustomInstrumentsService,
    private _marketDataService: MarketDataService,
    private _privateInstrumentHubService: CustomInstrumentHubService,
    private _modelCustomizationService: ModelCustomizationService,
    private _appComponent: AppComponent,
    private _environmentSettingsService: EnvironmentSettingsService,
    private _companyAndUserSettingsService: CompanyAndUserSettingsService,
    private _authorizationService: AuthorizationService,
    private _permissionService: LvPermissionService,
    private authorizationService: AuthorizationService,
    @Optional() private _excelService: LvExcelService
  ) {
    super(changeDetectorRef, applicationSettingsService);

    this.view = new InstrumentView();
    this.keepTabContent = false;
    this.init = true;

    this.isLoading = false;
    this._hubEventOccured = false;
    this._hubEventMessage = null;

    this.state = this._instrumentStateService.getDefaultInstrumentComponentState();

    this.view.init(this.state.widgets);

    this._widgetStateManagerService.setDashboardState(this.state, 'analytics');

    this.model = {
      editCustomView: false
    };

    this.defaultStateOnly = false;
    this.notAuthorized = false;
    this.instrumentUtil = {} as InstrumentUtil;
    this._resizeHandlerService = new ResizeHandlerService();

    this.hasFullTermsAccess = false;
    this.showAnalytisWidgets = false;
    this._customInstrumentIdentifier = null;

    // this flag represents if custom instrument is opened for the first time
    // if it is first time set terms as active tab, otherwise select valuation or some other previously selected tab
    this.isPrivateInstrumentInit = false;
    this.isOpenedFromExcel = false;
    this.isEditingExcelView = false;
    this._isPermissionChecked = false;
    this.draftChanged = false;
  }

  async doOnInit() {
    this.createSubscriptions();
    this.checkAnalyticsValuationAuthorization();

    if (this.state.useDefaultView) {
      const dfState = this._instrumentStateService.getDefaultInstrumentComponentState();
      this.view.init(dfState.widgets);
    }
    else {
      this.view.init(this.state.widgets);
    }

    this._widgetStateManagerService.setDashboardState(this.state, 'analytics');
    this._widgetStateManagerService.setStateId(this.state.id);

    if (this._appComponent.mode === ApplicationMode.Normal) {
      try {
        await this.loadInstrument(this.state.instrumentName);
      }
      catch (error) {
        this.processLoadInstrumentError(error);
      }
      finally {
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  public disableFieldsInEditCustomView(widgetId: string): boolean {
    if (widgetId === 'terms' ||  (widgetId === 'documents' && !!this._excelService?.isIdentifierDraft())) {
      return true;
    }
    return false;
  }

  private createSubscriptions() {
    this._subscriptions = [
      this._routeCacheService.workspaceReatached.subscribe(wsId => {
        const regex = new RegExp(`\\b${wsId}\\b`, 'ig');
        if (this._router.url.match(regex) && this.applicationSettingsService.isWidgetInWorkspace(wsId, this.stateId)) {
          if (this.sensitivityPanelComponent) {
            this.sensitivityPanelComponent.rerenderChart();
          }
        }
      }),

      this._widgetStateManagerService.onCustomViewUsed.subscribe(s => {
        this.state.useDefaultView = false;
        this.changeDetectorRef.markForCheck();
        this.updateComponentState();
      }),

      this._analyticsHubService.valuationSettingsUpdatedEvent.subscribe(evt => {
        if ((this._pricingService.instanceId !== evt.sourceId &&
            this._marketDataService.instanceId !== evt.sourceId &&
            this._modelCustomizationService.instanceId !== evt.sourceId) &&
            this._presenter.sessionId !== evt.sourceId &&
            this._presenter.asHelper.lwsIdentifier === evt.lwsIdentifier) {
              this._hubEventOccured = true;
              this._hubEventMessage = 'Instrument Inputs have been changed';
              this._presenter.notifySettingsChanged();
              this.changeDetectorRef.markForCheck();
        }
      }),
      this._analyticsHubService.customYieldCurveUpdatedEvent.subscribe(evt => {

        if ((this._pricingService.instanceId !== evt.sourceId && this._marketDataService.instanceId !== evt.sourceId) &&
          (this._presenter.cHelper.currencyCode === evt.customYieldCurveCcy ||
          this._presenter.cHelper.underlyingCurrencyCode === evt.customYieldCurveCcy)) {
            this._hubEventOccured = true;
            this._hubEventMessage = 'custom yield curve have been changed';
            this._presenter.notifySettingsChanged();
            this.changeDetectorRef.markForCheck();
          }
      }),
      this._analyticsHubService.instrumentEnvironmentSettingsUpdatedEvent.subscribe(evt => {

        if (this._presenter.asHelper.lwsIdentifier === evt.lwsIdentifier &&
          this._environmentSettingsService.svcInstanceId !== evt.sourceId) {
          this._hubEventOccured = true;
          this._hubEventMessage = 'Environment order have been changed';
          this.changeDetectorRef.markForCheck();
        }
      }),

      this._analyticsHubService.otherSettingsUpdatedEvent.subscribe(async evt => {
        if (this._companyAndUserSettingsService.instanceId === evt.sourceId) {
          await this._presenter.loadOtherSettings();

          this.changeDetectorRef.markForCheck();
        }
      }),

      this._referenceDataHubService.convertibleTermsUpdatedEvent.subscribe(evt => {

        if (this._presenter.asHelper.lwsIdentifier === evt.lwsIdentifier) {
          this._hubEventOccured = true;
          this._hubEventMessage = 'Convertible Terms have been changed';
          this.changeDetectorRef.markForCheck();
        }
      }),

      this._privateInstrumentHubService.privateInstrumentDeletedEvent.subscribe(evt => {
        if (this._presenter.asHelper.lwsIdentifier === evt.id) {
          this.onPrivateInstrumentDeleted();
          this.changeDetectorRef.markForCheck();
        }
      }),

      this._presenter.onPricingModelUpdated.subscribe(evt => {
        if (evt && evt.data) {
          this.setPrivateInstrumentStockRef(evt.data.pricing);
        }
      })
    ];
  }

  setStateId(stateId: string) {
    super.setStateId(stateId);
    this._widgetStateManagerService.setStateId(stateId);
  }

  onStateChange(changedState: IInstrumentComponentState) {
    super.onStateChange(changedState);
    this._widgetStateManagerService.setDashboardState(this.state, 'analytics');
  }

  ngAfterViewInit() {
    let selectedTab = 0;
    this.tabStrip.tabs.forEach((a, i) => {
      if (this.state.isPrivateInstrument && a.cssClass?.includes('terms-class')) {
        selectedTab = i;
      }
    });
    this.view.setSelectedTab(selectedTab);

    this.instrumentUtil = new InstrumentUtil(this._resizeHandlerService, this.tabStrip, this.changeDetectorRef);
    this.instrumentUtil.startListening();
  }

  async onPrivateInstrumentChanged(event: PrivateInstrumentEvent | ConvertibleBondTermsEvent) {
    try {
      this.setLoadingState(true);
      this.setDraftId();

      switch (event) {
        case PrivateInstrumentEvent.DraftSaved:
        case PrivateInstrumentEvent.InstrumentSaved: {
          this.state.isPrivateInstrument = true;
          this.state.instrumentLwsIdentifier = this.privateInstrumentComponent.instrumentIdentifier;
          await this.loadInstrument(null, null, null, false);
          break;
      }
        case PrivateInstrumentEvent.InstrumentLoaded:
        case ConvertibleBondTermsEvent.CCYUpdated:
        case ConvertibleBondTermsEvent.InstrumentStatusUpdated:
        case ConvertibleBondTermsEvent.InstrumentTypeUpdated:
        case ConvertibleBondTermsEvent.TermsSettingsLoaded:
        case ConvertibleBondTermsEvent.ExchangeableTypeUpdated: {
          await this.loadInstrument(null, null, null, false);
          break;
        }
        case ConvertibleBondTermsEvent.OtherDataUpdated: {
          await this._presenter.executeCommand(AnalyticsCommands.ReloadInstrumentInfo);
          if (this._presenter.isModelLoaded()) {
            this.applyInstrumentName(this._presenter.cHelper.convertible.name);
          }

          this.changeDetectorRef.detectChanges();
          break;
        }
        case ConvertibleBondTermsEvent.DeltaNeutralConversionAmountUpdated:
        case ConvertibleBondTermsEvent.ConversionRatioOrRebateUpdated:
        case ConvertibleBondTermsEvent.HigherOrLowerStrikeUpdated:
        case ConvertibleBondTermsEvent.FixedFXUpdated: {
          await this._presenter.executeCommand(AnalyticsCommands.ReloadPricingAndTools);
          break;
        }
        case ConvertibleBondTermsEvent.PriceTalkUpdated: {
          if (!this.init || this.state.isPrivateInstrument) {
            this.keepTabContent = true;
            this.changeDetectorRef.detectChanges();
          }
          
          await this._presenter.executeCommand(AnalyticsCommands.ReloadAssumptionsAndToolsAndAnalysis);
          
          break;
        }
        case ConvertibleBondTermsEvent.RebateCCYUpdated:
        case ConvertibleBondTermsEvent.PriceAsParUpdated: {
          await this._presenter.executeCommand(AnalyticsCommands.ReloadPricingAndTools);
          if (this._presenter.isModelLoaded()) {
            this.applyInstrumentName(this._presenter.cHelper.convertible.name);
          }
          this.changeDetectorRef.detectChanges();
          break;
        }
      }
    }
    catch (error) {
      this.processLoadInstrumentError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  onPrivateInstrumentLoaded(termsDocument: QuickAndFullTermsDocument) {
    if (this.setGoldenLayoutContainerTitle) {
      this.setGoldenLayoutContainerTitle(termsDocument.fullTerms.issueAndRedemption.name || 'Instrument');
    }

    if (this.state.instrumentName !== termsDocument.fullTerms.issueAndRedemption.name) {
      this.state.instrumentName = termsDocument.fullTerms.issueAndRedemption.name || 'Instrument';
    }
  }

  onPrivateInstrumentReloaded(termsDocument: QuickAndFullTermsDocument) {

    if (!!this._excelService?.isInitialized()) {
      this.loadInstrument();
      return;
    }

    if (termsDocument && this.setGoldenLayoutContainerTitle) {
      this.setGoldenLayoutContainerTitle(termsDocument.fullTerms.issueAndRedemption.name || 'Instrument');
    }
    else {
      this.state.instrumentId = null;
      this.state.instrumentName = null;
      this.state.instrumentLwsIdentifier = null;
      this.state.instrumentIsin = null;
      this.state.isPrivateInstrument = true;
      this.state.region = null;
      this.draftChanged = false;

      if (this.setGoldenLayoutContainerTitle) {
        this.setGoldenLayoutContainerTitle('Instrument');
      }
    }

    this.loadInstrument();
  }

  /**
   * Set draft changed when draft is reloaded. Because when draft is
   * reloaded classic reload event is not sent to instrument component from custom instrument component.
   */
  onDraftReloaded() {
    this.draftChanged = false;
  }

  onPrivateInstrumentDeleted() {
    this.resetState();
    this.state.isPrivateInstrument = false;
    this.loadInstrument();
  }


  // Implementations from parent class
  async didReceiveMessage(monitor: IInstrumentSelectedEvent) {
    try {
      this.keepTabContent = false;
      this.setLoadingState(true);

      if (this.privateInstrumentComponent && this.state.instrumentLwsIdentifier) {
        this._presenter.setDraftId(null);
      }

      this.state.instrumentId = monitor.id || null;
      this.state.instrumentName = monitor.name || null;
      this.state.instrumentLwsIdentifier = monitor.lwsIdentifier || null;
      this.state.instrumentIsin = monitor.isin || null;
      this.state.isPrivateInstrument = monitor.isPrivateInstrument;
      this.state.region = monitor.region;

      this.draftChanged = false;

      await this.loadInstrument(monitor.name, true);

      this._hubEventOccured = false;
    }
    catch (error) {
      this.processLoadInstrumentError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  didlvGlOnDestroy() {
    if (this.state.sessionId) {
      this._presenter.closeSession();
    }

    if (this.state.isPrivateInstrument && this.privateInstrumentComponent) {
      this.privateInstrumentComponent.clearDraft(this.state.instrumentLwsIdentifier);
    }

    this.notificationPanel.unregister();
  }

  /**
   * Populate instrument state and load instrumetn when instrument was
   * found through instrument text search
   * @param info instrument basic data retrived from instrument lookup
   */
  async onInstrumentSelect(info?: IInstrumentLookup) {
    try {
      this.keepTabContent = false;
      this.setLoadingState(true);

      if (this.privateInstrumentComponent && this.state.instrumentLwsIdentifier) {
        this._presenter.setDraftId(null);
      }

      this.state.instrumentId = info.id || null;
      this.state.instrumentName = info.text || null;
      this.state.instrumentLwsIdentifier = info.lwsIdentifier || null;
      this.state.instrumentIsin = info.isin || null;
      this.state.isPrivateInstrument = info.isPrivateInstrument;
      this.state.region = info.region;

      this.draftChanged = false;
      
      await this.loadInstrument(info.text || 'Instrument', true);

      this._hubEventOccured = false;
    }
    catch (error) {
      this.processLoadInstrumentError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  onSensitivityAnalysisStateUpdated(state: ILvSensitivityAnalysisPanelState) {
    this.state.sensitivityAnalysisState.scenarioTemplateId = state.scenarioTemplateId;
    this.state.sensitivityAnalysisState.isSystem = state.isSystem;
    this.updateComponentState();
  }

  async onTabSelect(event: SelectEvent) {
    if (!this.init || this.state.isPrivateInstrument) {
      this.keepTabContent = true;
    }
    else {
      this.init = false;
    }

    // We want to update draft (if draft is changed) after we change tab from terms to something other.
    // Then draft will be updated and analytics convertible will be refreshed from database.
    if (this.view.currentlySelectedTab === 'terms'
      && !!this.privateInstrumentComponent
      && this.draftChanged) {

      await this.privateInstrumentComponent.saveDraft(ConvertibleBondTermsEvent.OtherDataUpdated, true);
      this.draftChanged = false;
      this.changeDetectorRef.detectChanges();
    }

    this.view.setSelectedTab(event.index);
    this.changeDetectorRef.detectChanges();

    if (event.title === 'Terms') {
      setTimeout(() => {
        this.setPrivateInstrumentStockRef(this._presenter.asHelper.pricing);
      }, 100);
    }
  }

  onShowSettings() {
    this.settingsPanel.togglePanel();
  }

  onDismissInstrumentReload() {
    this._hubEventOccured = false;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Triggered when we approve notification for update Instrument from LWS
   */
  async onReloadInstrument(): Promise<void> {
    try {
      this.setLoadingState(true);

      await this.loadInstrument(null, true);

      /* When lws notification event has been received, then reload of instrument must pull fresh data for leversys convertlbe not draft */
      if (this.hasFullTermsAccess &&  this.privateInstrumentComponent) {
        this.privateInstrumentComponent.onReload(true);
      }

      if (this.termsSummaryComponent) {
        this.termsSummaryComponent.loadSections();
      }

      this._hubEventOccured = false;
    }
    catch (error) {
      this.processLoadInstrumentError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  // Layout Configuration
  onDisplayDefaultView() {
    const dfState = this._instrumentStateService.getDefaultInstrumentComponentState();
    this.view.init(dfState.widgets);
    this.state.useDefaultView = true;
    this.changeDetectorRef.markForCheck();
    this.updateComponentState();
    this._widgetStateManagerService.setAllWidgetsToUseDefaultView();
  }

  onDisplayCustomView() {
    this.view.init(this.state.widgets);
    this.state.useDefaultView = false;
    this.changeDetectorRef.markForCheck();
    this.updateComponentState();
  }

  onEditCustomView(event: MouseEvent) {
    this.state.useDefaultView = false;
    event.preventDefault();
    event.stopPropagation();
    this.toggleEditCustomView();
  }

  onEditInstrumentAccessRights() {
    this.privateInstrumentComponent.onSaveAccessRigths(this._presenter.cHelper.accessScope, 'Edit Instrument Access Rights');
  }


  getSidePanelWidth(editCustomView: boolean): number {
    return editCustomView ? 232 : 225;
  }

  /**
   * Save default instrument widget state for excel or non excel instrument
   */
  async onSaveAsDefaultView() {
    try {
      this.setLoadingState(true);

      await this._defaultWidgetStateService.saveDefaultWidgetState({
        widgetType: DefaultWidgetType.Instrument,
        state: this.state.widgets.map(w => ({ ...w, state: null })),
        isOpenedFromExcel: this.isOpenedFromExcel
      });

      this._widgetStateManagerService.saveAsDefaultViewAll();
      this.state.useDefaultView = true;

      this.updateComponentState();

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1831'),  DefaultWidgetType.Instrument);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  onCancelEditCustomView(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.toggleEditCustomView();

    // On cancel restore previous state of widgets
    this.view.init(this.state.widgets);
  }

  onApplyEditCustomView(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.toggleEditCustomView();

    // instrument apply changes shouldn't affect widget inner state
    this.state.widgets = this.view.widgets.map(a => {
      const tempState = _.cloneDeep(this.state.widgets.find(x => x.id === a.id).state);
      const wgt = _.cloneDeep(a);
      wgt.state = tempState;
      return wgt;
    });

    this.updateComponentState();
  }

  onSetWidgetVisibility(vo: IInstrumentWidgetVisibility, widget: IInstrumentWidgetState) {
    let shouldActivateFirstTab = false;
    this.view.setWidgetVisibility(vo, widget, (activateFirstTab) => {
      shouldActivateFirstTab = activateFirstTab;
    });
    this.changeDetectorRef.markForCheck();

    if (shouldActivateFirstTab) {
      setTimeout(() => {
        if (this.tabStrip) {
          this.tabStrip.selectTab(0);
          this.changeDetectorRef.detectChanges();
        }}, 50);
    }
  }
  // End Layout Configuration
  
  /**
   * React if draft changed event is published from custom instrument component and set property draft changed values.
   * @param isDraftChanged flag that describes whether draft updated.
   */
  async onDraftChanged() {
    // update draft when first other event is fired.
    if (!this.draftChanged) {
      await this.privateInstrumentComponent.saveDraft(ConvertibleBondTermsEvent.OtherDataUpdated, true);
      this.draftChanged = true;
    }    

    this.keepTabContent = true;
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribeFromWorkspaceRemoved();
    this._subscriptions.forEach(a => a.unsubscribe());
  }

  // Layout Configuration
  private toggleEditCustomView() {
    this.model.editCustomView = !this.model.editCustomView;
    if (this.isOpenedFromExcel) {
      this.isEditingExcelView = !this.isEditingExcelView;
    }
    this.changeDetectorRef.markForCheck();
  }

  private resetState() {
    this.state.instrumentId = null;
    this.state.instrumentName = null;
    this.state.instrumentLwsIdentifier = null;
    this.state.instrumentIsin = null;
    this.state.region = null;

    if (this.sensitivityPanelComponent) {
      this.sensitivityPanelComponent.currentScenario.onClear();
    }

    if (!!this.setGoldenLayoutContainerTitle) {
      this.setGoldenLayoutContainerTitle('Instrument');
    }

    this.changeDetectorRef.markForCheck();
  }

  async onTermsDetailedSaved(fullTermsEventData: IFullTermsDataEvent) {
    this.state.instrumentLwsIdentifier = fullTermsEventData.lwsIdentified;
    this.state.isPrivateInstrument = true;

    await this.loadInstrument(fullTermsEventData.instrumentName);
  }

  // tslint:disable-next-line: max-line-length
  public async loadInstrument(instrumentName?: string, ignoreDraft: boolean = false, region: string = null, changeCustomInstrumentIdentifier: boolean = true): Promise<void> {
    try {

      this._isPermissionChecked = false;
      this.notAuthorized = false;

      if (!this.state.isPrivateInstrument) {
        this.hasFullTermsAccess =  await this._authorizationService.hasResourcePermission('TERMS', 'VIEW_FULL_TERMS',
          {identifier : this.state.instrumentLwsIdentifier, region: region ?? this.state.region } as IResource);

        this._isPermissionChecked = true;
      }
      else {
        this.hasFullTermsAccess =  await this._permissionService.hasPermission('PRIVATE_INSTRUMENTS', 'CREATE_PRIVATE_INSTRUMENT');
      }

      // load only selected tab content on load instrument
      this.init = true;
      this.changeDetectorRef.markForCheck();

      await this._logger.log('insrument loaded ' + this.state.instrumentLwsIdentifier);

      /* Copy or exact copy */
      await this.CopyInstrumentIfNeeded();

      if (changeCustomInstrumentIdentifier) {
        this._customInstrumentIdentifier =  this.state.instrumentLwsIdentifier || null;
      }

      if (this.state.isPrivateInstrument && !this.state.instrumentId) {
        this.isPrivateInstrumentInit = true;
      }

      let loadSessionResult = null;

      let draftId = null;
      if (this.privateInstrumentComponent && this.privateInstrumentComponent.isDraft && !ignoreDraft) {
        draftId = this.privateInstrumentComponent.draftId;
      }

      this.checkAnalyticsValuationAuthorization();

      if (this.showAnalytisWidgets) {
        loadSessionResult = await this._presenter.load({
          identifier: this.state.instrumentLwsIdentifier,
          isPrivate: this.state.isPrivateInstrument,
          sessionId: this.state.sessionId,
          draftId: draftId,
        });
      }

      if (loadSessionResult) {
        if (!loadSessionResult.convertible && !this.hasFullTermsAccess) {
          this.resetState();
          instrumentName = null;
        }

        if (!this.state.sessionId) {
          this.state.sessionId = loadSessionResult.sessionId;
        }
        this.state.accessScope = loadSessionResult.convertible ? loadSessionResult.convertible.accessScope : null;
      }

      this.setPrivateInstrumentStockRef(this._presenter.asHelper.pricing);

      this.applyChildComponentsState();

      instrumentName = this.applyInstrumentName(instrumentName);

      this.selectedTabTermsSetup();

      if (!this.hasFullTermsAccess && !this.showAnalytisWidgets) {
        this.notAuthorized = true;
        this.changeDetectorRef.detectChanges();
      }

      this.updateComponentState();
    }
    catch (error) {
      throw error;
    }
    finally {
      if (!(this.changeDetectorRef as ViewRef).destroyed) {
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  private async CopyInstrumentIfNeeded() {
    if (this.state.instrumentLwsIdentifier
      && this.state.isPrivateInstrument
      && this.state.createPrivateInstrument) {

      let copyResult = null;
      const isLeversysConvertible = !this.state.createPrivateInstrument.isPrivateIntrument;

      this.isPrivateInstrumentInit = true;

      if (this.state.createPrivateInstrument.isExactCopy) {
        // exact copy of instrument
        copyResult = await this._privateInstrumentService.copyInstrument(this.state.instrumentLwsIdentifier,
          isLeversysConvertible,
          this.state.createPrivateInstrument.isUserAccessScope);
      }
      else {
        // always create new issue
        // tslint:disable-next-line: max-line-length
        copyResult = await this._privateInstrumentService.copyFromExistingInstrument(this.state.instrumentLwsIdentifier,
            isLeversysConvertible,
            this.state.createPrivateInstrument.isUserAccessScope);
      }

      this.state.instrumentLwsIdentifier = copyResult.id;
      this.state.createPrivateInstrument = undefined;
    }
  }

  private selectedTabTermsSetup() {
    const tabIndex = 0;
    if (this.state.isPrivateInstrument && this.isPrivateInstrumentInit) {

      const selectedTabIndex = this.tabStrip.tabs.toArray().findIndex(x => x.selected);
      const activeTabIndex = this.tabStrip.tabs.toArray().findIndex(x => x.cssClass?.includes('terms-class'));

      if (activeTabIndex !== selectedTabIndex) {
        if (activeTabIndex >= 0) {
          this.tabStrip.tabs.forEach(a => a.selected = false);
          this.tabStrip.selectTab(activeTabIndex);
          this.changeDetectorRef.detectChanges();
        }
        else {
          this.tabStrip.selectTab(tabIndex);
          this.changeDetectorRef.detectChanges();
        }
      }
    }
    else {
      if (this.state.isPrivateInstrument) { // SYSTEM-1949-Issue with first opening of private instrument
        this.keepTabContent = true;
      }
      const activeTabIndex = this.tabStrip.tabs.toArray().findIndex(x => x.selected);
      let tabLength = this.tabStrip.tabs.length;
      if (activeTabIndex >= 0 && tabLength > 1) {
        this.tabStrip.tabs.forEach(a => a.selected = false);
        tabLength = this.tabStrip.tabs.length;
        tabLength > 1 ? this.tabStrip.selectTab(activeTabIndex) : this.tabStrip.selectTab(0);
        this.changeDetectorRef.detectChanges();
      }
      else {
        setTimeout(() => {
          if (this.tabStrip) {
            this.tabStrip.selectTab(tabIndex);
            this.changeDetectorRef.detectChanges();
          }
        }, 10);
      }
    }
  }

  dataServicetrialPermission(permission: string): string {
    return `${permission}${this.state.instrumentLwsIdentifier}`;
  }

  dataServicetrialPermissionNotAnalytics(permission: string): string {
    return `${permission}${this.state.instrumentLwsIdentifier},!ANALYTICS`;
  }

  /**
   * Apply instrument name in state.
   * We added (Draft) on instrument name only if draftId exist (only if we change some terms fields before we save Custom instrument)
   * @param instrumentName
   */
  private applyInstrumentName(instrumentName: string) {
    if (this._presenter.isModelLoaded()) {
      instrumentName = this._presenter.cHelper.convertible.name;
      if (this.privateInstrumentComponent && this.privateInstrumentComponent.isDraft && this._presenter.draftId) {
        instrumentName += ' (Draft)';
      }
      this.state.instrumentName = instrumentName;
    }
    if (instrumentName && !this.defaultStateOnly) {
      this.setGoldenLayoutContainerTitle(instrumentName);
    }
    return instrumentName;
  }

  private setPrivateInstrumentStockRef(pricing?: IPricing) {
    if (!this.state.isPrivateInstrument) {
      return;
    }

    let stockRef = 0;

    if (pricing) {
      stockRef = pricing.stockPriceUndCcy || pricing.stockPriceCbCcy;

      if (!LvMath.isNumber(stockRef)) {
        stockRef = 0;
      }
    }
  }

  private setDraftId(): void {
    if (this._presenter.getModelData() && this._presenter.getModelData().convertible) {
      const isDraft = !!this.privateInstrumentComponent ? this.privateInstrumentComponent.isDraft : false;

      this._presenter.getModelData().convertible.isDraft = isDraft;
      if (isDraft) {
        this._presenter.setDraftId(this.privateInstrumentComponent.draftId);
      }
    }
  }

  private applyChildComponentsState() {
    this.applySensitivityAnalysisState();
  }

  private applySensitivityAnalysisState() {
    this.state.sensitivityAnalysisState = LvUtil.clone(this.state.sensitivityAnalysisState);
  }

  private updateComponentState() {
    this.applicationSettingsService.updateWidget(this.stateId, wgt => {
      wgt.state = this.state;
    });
  }

  private setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
    this.changeDetectorRef.markForCheck();
  }

  private processLoadInstrumentError(error: LvError) {
    this._errorService.handleError(error, e => {
      this.notAuthorized = e.type === LvErrorType.AUTHORIZATION;
      if (this.notAuthorized) {
        if (this.hasFullTermsAccess) {
          this.showTerms();
        }
        else {
          this.view.clearTabSelection();
          if (!this.notAuthorized) {
            this._errorService.toastrService.error(e.message, e.name);
          }
        }
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  private showTerms() {
    this.checkAnalyticsValuationAuthorization();
    this.notAuthorized = false;

    setTimeout(() => {
      if (this.tabStrip) {
        this.tabStrip.selectTab(0);
        this.changeDetectorRef.detectChanges();
      }
    }, 400);
  }

  private checkAnalyticsValuationAuthorization() {
    this.showAnalytisWidgets = this.authorizationService.authorize('ANALYTICS', 'VALUATION',
    {
      restrictedResourceType: RestrictedResourceType.Instrument,
      identifier: this.state.instrumentLwsIdentifier,
    } as IResource);
  }
}
