import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, HostBinding, Input } from '@angular/core';

export type LvFlexBoxDirection = 'column' | 'row';

@Component({
  selector: 'lv-flex-box',
  templateUrl: './lv-flex-box.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvFlexBoxComponent {

  @Input() direction: LvFlexBoxDirection;
  @Input() flex?: string;
  @Input() alignItems?: string;

  @HostBinding('class.lv-flex-box')
  get isComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isDirectionColum() {
    return this.direction === 'column';
  }

  @HostBinding('class.lv-flex-box--row')
  get isDirectionRow() {
    return this.direction === 'row';
  }

  @HostBinding('style.flex')
  get flexConfig(): string {
    return this.flex;
  }

  @HostBinding('style.align-items')
  get itemsAlignment(): string {
    return this.alignItems;
  }

  constructor() {
    this.direction = 'column';
    this.flex = null;
    this.alignItems = null;
  }
}
