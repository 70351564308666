import { LvScenarioGridView, ILvScenarioGridView, ILvScenarioGridViewRecord, ILvScenarioGridViewColumn } from '../lv-scenario-grid.view';
import { ILvScenarioReport } from '../../lv-scenario.view';
import { ICalculateScenarioOutput } from '@lv-analytics/models';

export interface IOneDimGrid extends ILvScenarioGridView {
  dimensionDescription: string;
  columns: ILvScenarioGridViewColumn[];
}

/**
 * Scenario one dim grid view.
 */
export class LvScenarioOneDimGridView extends LvScenarioGridView<IOneDimGrid> {

  /**
   * Gets default model.
   * @returns IOneDimGrid object.
   */
  getDefaultModel(): IOneDimGrid {
    return {
      dimensionDescription: null,
      records: [],
      columns: []
    };
  }

  /**
   * Does initialization.
   * @param report ILvScenarioReport object.
   */
  doInit(report: ILvScenarioReport) {
    const mapped = (report.data as ICalculateScenarioOutput[]);

    mapped.forEach((fDim, i) => {

      if (this.isFirstIndex(i)) {
        this.model.dimensionDescription = this.getFirstDimensionDescription(fDim.dimension);
      }

      const rec: ILvScenarioGridViewRecord = {
        dimension: fDim.dimension,
        dimensionValue: fDim.value
      };

      fDim.outputs.forEach((output, j) => {
        if (this.isFirstIndex(i)) {
          this.model.columns.push({
            columnId: output.output,
            title: this.scenarioOutputDescription[output.output],
            width: this.defaultColumnWidth
          });
        }

        const assumed = output.values[0];
        rec[output.output] = assumed.value;
      });

      this.model.records.push(rec);
    });
  }
}
