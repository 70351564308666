<kendo-grid
  [dataItemKey]="recordIdField"
  [editorPopupOpened]="editorPopupOpened"
  [gridCellEditing]="createFormGroup"
  [gridRowEditing]="createFormGroup"
  gridAreaSelection
  gridKeyboardRemove
  [gridContextMenu]="gridMenu"
  [data]="recordItems"
  [navigable]="true"
  [lvId]="lvId"
  [class.lv-advanced-grid-scrollable]="isScrollable"
  [scrollable]="scrollMode">
  
  <kendo-grid-column
  [locked]="true"
  width="40"
  class="row-selection lv-advanced-copy-paste-grid-cb-cell">
    <ng-template kendoGridHeaderTemplate let-column>
      <div *ngFor="let item of pasteItems" class="lv-advanced-copy-paste-grid-header-item text-field">
        {{ item | titlecase }}
      </div>
    </ng-template>

    <ng-template kendoGridCellTemplate
      let-rowIndex="rowIndex">
      <div class="lv-copy-and-paste-dialog-checkbox-wrapper">
        <input 
          name="overrideSystemDefaultsMyCheckBoxId"
          class="k-checkbox"
          type="checkbox"
          [(ngModel)]="checkBoxColumns[rowIndex]"
          [attr.id]="getCheckBoxId(rowIndex)"
          (change)="onCheckBoxChange($event, rowIndex)"/>
        <label class="k-checkbox-label"
          lvId="lv-estimates-settings-override-system-defaults-my-environment"
          [attr.for]="getCheckBoxId(rowIndex)">
        </label>
        </div>
    </ng-template>
  </kendo-grid-column>

  <ng-container *ngFor="let c of columnItems; let columnIndex = index">
    <kendo-grid-column *ngIf="c.visible"
      [class]="c.ngClass"
      [title]="c.title"
      [width]="c.width"
      [field]="c.field"
      [editable]="c.editable">

      <ng-template kendoGridHeaderTemplate let-column>
        
        <lv-flex-box class="lv-advanced-copy-paste-grid-header">
          <div *ngFor="let item of pasteItems" class="lv-advanced-copy-paste-grid-header-item">
  
            <input (ngModelChange)="handleColumnSelection(columnIndex, item)"
              [(ngModel)]="selectedColumns[item]"
              [attr.id]="getRadioButtonId(item, columnIndex)"
              class="k-radio"
              [name]="getRbName(item, columnIndex)"
              [value]="columnIndex"
              type="radio">
            <label class="k-ratio-label"
              [attr.for]="getRadioButtonId(item, columnIndex)"
              [lvId]="getRadioButtonId(item, columnIndex)">
            </label>
          </div>

        </lv-flex-box>
      </ng-template>

      <ng-template kendoGridCellTemplate
        let-dataItem
        let-rowIndex="rowIndex">
        <div *ngIf="c.isCellVisible(rowIndex, columnIndex)"
          class="lv-advanced-grid-cell"
          [class.lv-advanced-grid-cell--right]="c.isRightAligned"
          [ngStyle]="c.getStyleWidth(c.width)">
          <ng-container *ngIf="dataItem[recordIdField]">

            <div [class.lv-advanced-grid-cell-enabled]="isCellSelected(rowIndex, columnIndex)" 
              *ngIf="c.isTextColumn">
              {{ dataItem[c.field] }}
            </div>

          </ng-container>
        </div>
        <div *ngIf="!c.isCellVisible(rowIndex, columnIndex) && c.isCellPlaceholder(rowIndex, columnIndex)"
          class="right-align-placeholder">
          {{ c.isCellPlaceholder(rowIndex, columnIndex) }}
        </div>
      </ng-template>

      <ng-template kendoGridEditTemplate
        let-dataItem="dataItem"
        let-formGroup="formGroup"
        let-column="column"
        let-rowIndex="rowIndex">

        <ng-container *ngIf="c.isCellVisible(rowIndex, columnIndex)">
          <lv-text-box *ngIf="c.isTextColumn"
            [gridCellContextMenu]="self"
            [ngStyle]="c.getStyleWidth(c.width)"
            [attr.name]="column.field"
            disabled="true"
            [searchIconVisible]="false"
            [formControl]="formGroup.get(column.field)">
          </lv-text-box>
        </ng-container>
      </ng-template>

    </kendo-grid-column>
  </ng-container>

</kendo-grid>

<kendo-contextmenu #gridMenu>
  <ng-container *ngIf="contextMenuItems">
    <ng-container *ngFor="let item of contextMenuItems">
      <kendo-menu-item *ngIf="isContextMenuItemVisible(item.actionVisible)"
        [text]="item.actionText"
        [data]="item.actionId">
      </kendo-menu-item>
    </ng-container>
  </ng-container>
  <kendo-menu-item *ngIf="isDeleteMenuItemVisible"
    text="Delete Row"
    data="delete">
  </kendo-menu-item>
  <kendo-menu-item *ngIf="isDeleteMenuItemVisible"
    text="Delete All"
    data="delete-all">
  </kendo-menu-item>
</kendo-contextmenu>

<ng-template #headerCellTemplate>
  <div>Enter values and press <strong>Enter</strong> to confirm</div>
  <div>Press <strong>Ctrl</strong>&nbsp;<strong>V</strong> to paste data to the table</div>
  <div>Press <strong>Shift</strong>&nbsp;<strong>-</strong> to delete selected row</div>
  <div>Use <strong>Right</strong>&nbsp;<strong>Click</strong> to delete selected or all rows</div>
</ng-template>
